import { Link } from '@reach/router';
import { useState } from 'react';
import * as React from 'react';

import { Text } from '@atoms';
import { ShadowScroller } from '@new/ShadowScroller';

import { BottomSheetTabsProps } from './bottom-sheet.tabs.interfaces';

import './modal-tabs.css';

export const BottomSheetTabs = ({ tabs, modalRef }: BottomSheetTabsProps) => {
  const [active, setActive] = useState(0);

  function handleTabClick(anchorTag: string, index: number) {
    return (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();

      setActive(index);
      if (modalRef.current) {
        const main = modalRef.current.querySelector(
          '#bottom-sheet-main'
        ) as HTMLDivElement;
        const section = modalRef.current.querySelector(
          anchorTag
        ) as HTMLDivElement;
        if (main && section) {
          main.scrollTo({
            top: section.offsetTop - main.offsetTop,
            behavior: 'smooth',
          });
        } else if (section) {
          section.scrollIntoView({ behavior: 'smooth' });
        }
      }
    };
  }

  return (
    <ShadowScroller
      axis="x"
      className="tw-flex-shrink-0 tw-mt-6"
      scrollClassName="modal-tabs"
    >
      <div className="tw-flex tw-space-x-8 tw-whitespace-nowrap">
        {tabs.map(({ label, anchorTag, count }, index) => (
          <Link
            key={index}
            className={`tw-inline-block tw-cursor-pointer  ${
              active === index
                ? 'tw-border-b-[3px] tw-border-solid tw-border-theme-primary-500-300'
                : ''
            }`}
            onClick={handleTabClick(anchorTag, index)}
            to={anchorTag}
          >
            <Text
              font="body-md"
              color={active === index ? 'hi-contrast' : 'neutral-offset'}
              className="tw-w-full"
            >
              {label}
              {count !== undefined ? ` (${count.toLocaleString()})` : ''}
            </Text>
          </Link>
        ))}
      </div>
    </ShadowScroller>
  );
};
