import { Observable } from 'rxjs';

// services
import { ComplaintsSummary, ComplaintsSummaryParams } from '@api-interfaces';
import { Params } from '@core/helpers';
import { virtualBrownClient } from '@services/django-client';

// helpers

// interfaces

const BASE_URL = 'complaints/summary';

const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

class ComplaintsSummaryService {
	getSummaries<T extends ComplaintsSummary.Chart>(
		params: ComplaintsSummaryParams<T>
	): Observable<ComplaintsSummary.SummaryReturnType[T][]> {
		const query = {
			...params,
			chart: params.chart.join(','),
			time_zone: params.time_zone || timeZone,
		};

		return virtualBrownClient.get(
			`${BASE_URL}/${Params.makeQueryString(query)}`,
			{
				noCustomer: params.customer != undefined,
				noContract: params.contract != undefined,
				noTag: params.tag != undefined,
			}
		);
	}
}

export const complaintsSummaryService = new ComplaintsSummaryService();
