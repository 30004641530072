import { useEffect, useState } from 'react';

// components
import { Icon, Text } from '@atoms';

// services
import { imageService } from '@services';

// utils
import { AvatarProps } from './avatar.interfaces';
import {
  avatarBorderTypeMap,
  avatarSizeMap,
  avatarBgTypeMap,
  avatarSizeToTextSizeMap,
} from './avatar.utils';

// interfaces

export const Avatar = ({
  person,
  type,
  size,
  className,
  hasNoBorder,
  isInitials,
  customColor,
  style,
  ...props
}: AvatarProps) => {
  const avatarRingColor = hasNoBorder ? '' : avatarBorderTypeMap[type];
  const avatarSize = avatarSizeMap[size];
  const [photoLoadFailed, setPhotoLoadFailed] = useState(false);

  useEffect(() => {
    setPhotoLoadFailed(false);
  }, [person?.photo_url]);

  if (isInitials || !person?.photo_url || photoLoadFailed) {
    const firstNameInitial = person?.first_name?.slice(0, 1) ?? '';
    const lastNameInitial = person?.last_name?.slice(0, 1) ?? '';
    const bgColor = avatarBgTypeMap[type];
    return (
      <div
        className={`tw-flex-shrink-0 tw-rounded-full tw-grid tw-place-items-center tw-uppercase tw-font-medium tw-text-neutral-100 tw-shadow-inner ${avatarSize} ${bgColor} ${className}`}
        style={{
          ...style,
          backgroundColor: customColor,
        }}
        {...props}
      >
        <Text font={avatarSizeToTextSizeMap[size]} color="white">
          {firstNameInitial}
          {lastNameInitial}
          {!firstNameInitial && !lastNameInitial && <Icon icon="user-slash" />}
        </Text>
      </div>
    );
  }

  return (
    <div className="tw-min-w-max">
      <img
        className={`tw-flex-shrink-0 tw-rounded-full tw-shadow-inner tw-object-cover ${avatarRingColor} ${avatarSize} ${className}`}
        src={imageService.getImageByUniqueId(person?.photo_url)}
        style={{
          ...style,
          borderColor: customColor,
        }}
        onError={() => {
          setPhotoLoadFailed(true);
        }}
        alt=""
        {...props}
      />
    </div>
  );
};

Avatar.defaultProps = {
  className: '',
  size: 'md',
  type: 'primary',
};
