import { Options } from 'highcharts';
import { useMemo, useState } from 'react';

// helpers
import { Icon, Text } from '@atoms';
import Chart from '@components/Chart/Chart';
import {
  getDataVizColorByIndex,
  abbreviateNumber,
  friendlyPercentage,
  truncate,
} from '@helpers';

// components

// interfaces
import { PieChartListProps } from './pie-chart-list.interfaces';

const emptyColor = 'var(--theme-neutral-300-700)';

export const PieChartList = ({
  items,
  name,
  valueType,
  className,
}: PieChartListProps) => {
  const [showAll, setShowAll] = useState(false);

  const zerosFiltered = items?.filter((item) => item.value !== 0);
  const itemsZeroValue = items?.filter((item) => item.value === 0);

  const chartData: { options: Options } = useMemo(() => {
    const itemsCopy =
      items
        ?.map((item) => ({
          ...item,
          value: item.value || 0,
        }))
        .sort((a, b) => b.value - a.value) ?? [];
    const sum = itemsCopy.reduce((sum, item) => sum + item.value, 0);
    const itemsColors = itemsCopy.map((item, index) =>
      item.value > 0 ? getDataVizColorByIndex({ index }) : emptyColor
    );
    const itemsData = itemsCopy.map((item) => item.value);

    const chartColors =
      sum === 0
        ? [emptyColor]
        : sum > 0 && sum < 100
        ? [...itemsColors, emptyColor]
        : itemsColors;

    const data =
      sum == 0
        ? [0, 100]
        : sum > 0 && sum < 100
        ? [...itemsData, 100 - sum]
        : itemsData;

    return {
      options: {
        chart: {
          width: 124,
          height: 124,
          margin: 0,
        },
        legend: {
          enabled: false,
        },
        plotOptions: {
          series: {
            animation: false,
          },
          pie: {
            cursor: undefined,
            innerSize: 52,
            colors: chartColors,
            states: {
              hover: {
                enabled: false,
              },
            },
          },
        },
        series: [
          {
            name,
            data,
            type: 'pie',
          },
        ],
      },
    };
  }, [items, name]);

  function toggleShow() {
    setShowAll(!showAll);
  }

  return (
    <div className={`tw-flex tw-space-x-2 ${className}`}>
      <Chart
        style={{ width: 'auto' }}
        data={chartData}
        settings={{
          background: false,
          hideChartTooltip: true,
          resize: false,
        }}
      />
      <ul className="tw-space-y-1">
        {zerosFiltered
          ?.sort((a, b) => b.value - a.value)
          ?.map(({ value, name, id }, index) => (
            <li key={id}>
              <span
                className={`tw-inline-block tw-h-2 tw-w-2 tw-rounded-full ${
                  value > 0
                    ? getDataVizColorByIndex({
                        index,
                        withTailwind: 'bg',
                      })
                    : 'tw-bg-theme-neutral-300-700'
                }`}
              />{' '}
              <Text
                font="body-sm"
                tag="span"
                color="hi-contrast"
                className="tw-capitalize"
                title={name}
              >
                {truncate(name, 17)}
              </Text>{' '}
              <Text font="body-sm" tag="span" color="neutral-offset">
                {!value
                  ? ''
                  : valueType === 'percent'
                  ? friendlyPercentage({ percent: value })
                  : abbreviateNumber(value)}
              </Text>
            </li>
          ))}
        {showAll &&
          itemsZeroValue?.map(({ value, name, id }, index) => (
            <li key={id}>
              <span className="tw-inline-block tw-h-2 tw-w-2 tw-rounded-full tw-bg-theme-neutral-300-700" />{' '}
              <Text
                font="body-sm"
                tag="span"
                color="hi-contrast"
                className="tw-capitalize"
                title={name}
              >
                {truncate(name, 17)}
              </Text>{' '}
              <Text font="body-sm" tag="span" color="neutral-offset">
                {!value
                  ? ''
                  : valueType === 'percent'
                  ? friendlyPercentage({ percent: value })
                  : abbreviateNumber(value)}
              </Text>
            </li>
          ))}
        {itemsZeroValue?.length > 0 && (
          <div className="tw-flex tw-cursor-pointer" onClick={toggleShow}>
            <Icon
              className="tw-pr-1"
              icon={showAll ? 'caret-up' : 'caret-down'}
            />
            <Text font="body-sm" color="neutral-flat">
              {showAll ? 'Hide' : 'Show All'}
            </Text>
          </div>
        )}
      </ul>
    </div>
  );
};

PieChartList.defaultProps = {
  className: '',
};
