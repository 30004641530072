import { Observable } from 'rxjs';

// interfaces
import { RoutesSummary } from '@api-interfaces';

// services
import { virtualBrownClient } from '@core/services/django-client';

// helpers
import { Params } from '@helpers';

// constants
const BASE_URL = 'routes/summary';
const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

class RoutesSummaryService {
	getSummaries<T extends RoutesSummary.Chart>(params: {
		chart: T[];
		from?: Date;
		to?: Date;
		time_zone?: string;
		customer?: string | number;
		contract?: string | number;
		tag?: string | number;
		tag_group?: string | number;
	}): Observable<RoutesSummary.SummaryReturnType[T][]> {
		const query = {
			...params,
			chart: params.chart.join(','),
			time_zone: params.time_zone || timeZone,
		};
		return virtualBrownClient.get(
			`${BASE_URL}/${Params.makeQueryString(query)}`
		);
	}
}

export const routesSummaryService = new RoutesSummaryService();
