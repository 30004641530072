import { Media } from '@api-interfaces';
import { InputRadio } from '@atoms/form-ds2/input-radio';
import { Label } from '@atoms/form-ds2/label';
import { LabelProps } from '@atoms/form-ds2/label/label.interfaces';
import { Icon } from '@atoms/icon';

// services
import { awsService } from '@services';

// interfaces

interface Props {
  attachment: Media & { isAfter?: boolean };
  className?: string;
  labelFont?: LabelProps['font'];
  onDelete: (id: number) => void;
  onClick?: (id: number) => void;
  handleStatus?: (payload: { id: number; status: string }) => void;
}

export const Attachment = ({
  attachment,
  onClick,
  onDelete,
  handleStatus,
  className,
  labelFont,
}: Props) => {
  return (
    <div
      onClick={() => onClick?.(attachment.id)}
      className={`tw-flex tw-rounded tw-justify-between tw-items-center tw-cursor-pointer tw-w-full tw-bg-theme-neutral-200-700 tw-space-x-2 tw-pr-[13px] tw-min-h-[40px] ${className}`}
    >
      <div className="tw-flex tw-items-center tw-truncate">
        {attachment?.mime_type.includes('image') ? (
          <img
            className="tw-flex-shrink-0 tw-inline-block tw-w-10 tw-h-10 tw-mr-4 tw-rounded-l"
            src={awsService.getUrl(attachment.file_name)}
          />
        ) : (
          <div className="tw-flex tw-items-center tw-h-10 tw-mr-4 tw-bg-theme-neutral-300-600 tw-rounded-l">
            <Icon
              icon="paperclip"
              className="tw-text-neutral-900-100 tw-w-10"
            />
          </div>
        )}
        <Label
          label={attachment.file_name}
          className="tw-inline-block"
          font={labelFont}
        />
      </div>
      <div className="tw-flex tw-space-x-6 tw-items-center">
        {handleStatus && (
          <>
            <InputRadio
              onChange={() =>
                handleStatus({ id: attachment.id, status: 'before' })
              }
              name={`status-${attachment.id}`}
              checked={!attachment.isAfter}
              label="Before"
            />
            <InputRadio
              onChange={() =>
                handleStatus({ id: attachment.id, status: 'after' })
              }
              name={`status-${attachment.id}`}
              checked={attachment.isAfter}
              label="After"
            />
          </>
        )}
        <Icon
          className="tw-flex-shrink-0 tw-text-neutral-600-100"
          onClick={() => onDelete(attachment.id)}
          icon={['far', 'times']}
        />
      </div>
    </div>
  );
};

Attachment.defaultProps = {
  className: '',
  labelFont: 'body-lg',
};
