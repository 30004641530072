import { WorkOrderBottomSheetModal } from './_components/bottom-sheet';

// modals
import { CloseWorkOrderPromptModal } from './_components/close-work-order-prompt';
import { DeleteWorkOrderPromptModal } from './_components/delete-work-order-prompt';
import { RejectWorkOrderForm } from './_components/reject-work-order-form';
import { WorkOrderModalProvider, useWorkOrderModalContext } from './_context';

// interfaces
import { WorkOrderModalProps } from './_context/work-order.context.interfaces';
import { useMixpanelQualitySpecificWorkOrder } from '@app/mixpanel/MixpanelPageTrack.tsx';

export const WorkOrderModal = (props: WorkOrderModalProps) => {
    useMixpanelQualitySpecificWorkOrder();
    return (
        <WorkOrderModalProvider {...props}>
            <WorkModalModalStack />
        </WorkOrderModalProvider>
    );
};

const WorkModalModalStack = () => {
    const { workOrder, isLoading } = useWorkOrderModalContext();

    return workOrder && !isLoading ? (
        <>
            <WorkOrderBottomSheetModal />
            <DeleteWorkOrderPromptModal />
            <CloseWorkOrderPromptModal />
            <RejectWorkOrderForm />
        </>
    ) : null;
};
