import { SummaryChart, SummaryParams } from '@models';

export namespace OrganizationUserSummary {
	export enum Chart {
		GetOrganizationUserAccountCounts = 0,
	}

	export interface OrganiztionEmployeeCounts {
		active_employee_count: string | number;
		inactive_employee_count?: string | number;
		total_users: string | number;
		pending_user_requests: string | number;
	}

	export interface OrganiztionEmployeeCountsSummary
		extends SummaryChart<OrganiztionEmployeeCounts> {
		chart: 'GetOrganizationUserAccounCounts';
		number: '0';
	}

	export interface OrganizationEmployeeSummaryParams<T>
		extends Omit<SummaryParams<T>, 'from' | 'to'> {
		chart: T[];
	}

	export interface SummaryReturnType {
		[Chart.GetOrganizationUserAccountCounts]: OrganiztionEmployeeCountsSummary;
	}
}
