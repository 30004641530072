import { createContext, useContext, useEffect, useState } from 'react';
import * as React from 'react';

// interfaces
import { useSurveyESATModalContext } from '@modals/_ds2/survey-esat/_context';

import { SurveyResultsState } from './questions.interfaces';

const surveyResultsSection = createContext(null as SurveyResultsState);

export const SurveyResultsSectionProvider = (
  props: React.PropsWithChildren<{}>
) => {
  const { surveyResults } = useSurveyESATModalContext();
  const [
    isSurveyResponseDetailsModalOpen,
    setIsSurveyResponseDetailsModalOpen,
  ] = useState(false);
  const [selectedQuestion, setSelectedQuestion] =
    useState<SurveyResultsState['selectedQuestion']>(null);
  const [selectedSite, setSelectedSite] =
    useState<SurveyResultsState['selectedSite']>(null);
  const [questionNumber, setQuestionNumber] = useState(0);

  useEffect(() => {
    if (selectedQuestion) {
      setQuestionNumber(
        surveyResults.findIndex((result) => result.id === selectedQuestion.id) +
          1
      );
    }
  }, [selectedQuestion, surveyResults]);

  return (
    <surveyResultsSection.Provider
      value={{
        isSurveyResponseDetailsModalOpen,
        setIsSurveyResponseDetailsModalOpen,
        selectedQuestion,
        setSelectedQuestion,
        selectedSite,
        setSelectedSite,
        questionNumber,
      }}
      {...props}
    />
  );
};

export function useSurveyResultsSectionContext() {
  return useContext(surveyResultsSection);
}
