const screens = {
	xs: '360px',
	// => @media (min-width: 360px) { ... }

	sm: '640px',
	// => @media (min-width: 640px) { ... }

	md: '768px',
	// => @media (min-width: 768px) { ... }

	lg: '1024px',
	// => @media (min-width: 1024px) { ... }

	xl: '1280px',
	// => @media (min-width: 1280px) { ... }

	'2xl': '1440px',
	// => @media (min-width: 1440px) { ... }

	'3xl': '2560px',
	// => @media (min-width: 2560px) { ... }
};

export default screens;
