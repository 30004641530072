/* Array operators/manipulation */

/**
 * move
 * @param from
 * @param to
 *
 * Moves an array element to a specific index
 */

export function move(array, from, to) {
	array.splice(to, 0, array.splice(from, 1)[0]);
}

export function getIndexOrDefaultOrZero<T>(
	arr: T[],
	predicate: (value: T) => boolean,
	defaultIndex: number = 0
) {
	const foundIndex = arr.findIndex(predicate);
	return Math.max(
		0,
		foundIndex !== -1 ? foundIndex : Math.min(arr.length - 1, defaultIndex)
	);
}

export function hasSameElementsInOrder<T, K>(
	arr1: Array<T>,
	arr2: Array<T>,
	equalityCheck: (obj1: T, obj2: T) => boolean = (obj1: T, obj2: T) =>
		obj1 === obj2
) {
	if (!arr1?.length && !arr2?.length) {
		return true;
	}
	if (!!arr1 !== !!arr2 || arr1.length !== arr2.length) {
		return false;
	}

	for (let i = 0; i !== arr1.length; i++) {
		if (!equalityCheck(arr1[i], arr2[i])) {
			return false;
		}
	}
	return true;
}

export function hasSameElements<T, K>(
	arr1: Array<T>,
	arr2: Array<T>,
	uniqueMapper?: (anObj: T) => K,
	equalityCheck: (obj1: T, obj2: T) => boolean = (obj1: T, obj2: T) =>
		obj1 === obj2
) {
	if (!arr1?.length && !arr2?.length) {
		return true;
	}
	if (!!arr1 !== !!arr2 || arr1.length !== arr2.length) {
		return false;
	}

	if (uniqueMapper) {
		const uniques = new Set<K>(arr1.map((anObj1: T) => uniqueMapper(anObj1)));
		return arr2.every((anObj2: T) => uniques.has(uniqueMapper(anObj2)));
	}
	return arr1.every((anObj1: T) =>
		arr2.find((anObj2: T) => equalityCheck(anObj1, anObj2))
	);
}
