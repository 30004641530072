// interfaces
import { PartialKpiConfig, GoalText } from '../site-scorecard.config.interface';

export const FINANCIAL_KPIS: PartialKpiConfig[] = [
	{
		icon: 'chart-line',
		title: 'Actuals to Budget',
		subtitle: 'Compliance %',
		goalType: 'percentage',
		goalText: GoalText.atLeast,
		key: 'actuals_vs_budget',
	},
	{
		icon: 'user-helmet-safety',
		title: 'Staffing Levels',
		subtitle: 'Total %',
		goalType: 'percentage',
		goalText: GoalText.atLeast,
		key: 'staffing_levels',
	},
	{
		icon: 'file-contract',
		title: 'Invoicing is accurate and timely',
		subtitle: 'Accurate on time invoicing %',
		goalType: 'percentage',
		goalText: GoalText.atLeast,
		key: 'accurate_invoicing',
	},
	{
		icon: 'file-contract',
		title: 'Weekly Financial Reporting',
		subtitle: 'On time reporting %',
		goalType: 'percentage',
		goalText: GoalText.atLeast,
		key: 'weekly_financial_report',
	},
	{
		icon: 'lightbulb',
		title: 'Cost Savings Recommendations',
		subtitle: 'Total recommendations',
		goalType: 'int',
		goalText: GoalText.atLeast,
		key: 'cost_saving',
	},
	{
		icon: 'medal',
		title: 'On Time Billing',
		subtitle: '',
		goalType: 'percentage',
		goalText: GoalText.moreThan,
		key: 'on_time_billing',
	},
	{
		icon: 'medal',
		title: 'Quarterly Forecasting',
		subtitle: '',
		goalType: 'percentage',
		goalText: GoalText.moreThan,
		key: 'quarterly_forecasting',
	},
	{
		icon: 'medal',
		title: 'Cost Avoidance Savings',
		subtitle: '',
		goalType: 'percentage',
		goalText: GoalText.moreThan,
		key: 'cost_avoidance_savings',
	},
];
