export enum S3Folders {
	image,
	file,
	video,
	audio,
}
export enum S3ACL {
	private,
	publicRead,
	publicReadWrite,
	authenticatedRead,
	bucketOwnerRead,
	bucketOwnerFullControl,
}

export enum AwsAttachmentTypes {
	image = 1,
	video = 2,
	audio = 3,
	pdf = 4,
	file = 5,
}

export enum ALL_ATTACHMENT_TYPES {
	ProfilePicture,
	SocialPicture,
	BackgroundPicture,
	Misc,
}
