import { Subject } from 'rxjs';

import {
	FETCH_ID,
	RefetchCallback,
	RefetchSubject,
} from './refetch-service.interfaces';

class RefetchService {
	private subject = new Subject<RefetchSubject>();

	public fetch = (fetchId: FETCH_ID = null) => {
		this.subject.next(fetchId);
	};

	public subscribe(cb: RefetchCallback) {
		return this.subject.asObservable().subscribe(cb);
	}
}

export const refetchService = new RefetchService();
