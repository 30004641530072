import { SummaryChart } from '@models';

export namespace PortfoliosSummary {
	export enum Chart {
		GetPortfoliosCounts = 0,
	}

	export interface PortfoliosCounts {
		unique_portfolios_counts: number;
		active_portfolios_counts: number;
	}

	export interface PortfoliosCountsSummary
		extends SummaryChart<PortfoliosCounts> {
		chart: 'GetPortfoliosCounts';
		number: '0';
	}

	export interface SummaryReturnType {
		[Chart.GetPortfoliosCounts]: PortfoliosCountsSummary;
	}
}
