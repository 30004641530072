// services
import isNil from 'lodash/isNil';
import { Observable } from 'rxjs';

import { ComplimentsSummary, ComplimentsSummaryParams } from '@api-interfaces';
import { virtualBrownClient } from '@core/services/django-client';

// helpers
import { Params } from '@helpers';

// interface

const BASE_URL = 'compliments/summary';
const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

class ComplimentsSummaryService {
	public getSummaries<T extends ComplimentsSummary.Chart>(
		params: ComplimentsSummaryParams<T>
	): Observable<ComplimentsSummary.SummaryReturnType[T][]> {
		const query = {
			...params,
			chart: params.chart.join(','),
			time_zone: params.time_zone || timeZone,
		};

		return virtualBrownClient.get(
			`${BASE_URL}/${Params.makeQueryString(query)}`,
			{
				noCustomer: !isNil(params.customer),
				noContract: !isNil(params.contract),
				noTag: !isNil(params.tag),
			}
		);
	}
}

export const complimentsSummaryService = new ComplimentsSummaryService();
