import { WorkOrderStatusKey, WorkOrderDueStatusKey, Todo } from '@api-interfaces';
import { OverflowMenuButton } from '@components/_globals/components';
import { OverflowMenuButtonProps } from '@components/_globals/components/overflow-menu-button/overflow-menu-button.interfaces';
import { useAppContext } from '@core/hooks';
import { TimeUtils } from '@helpers';
import { StatusBadge } from '@modals/_ds2/work-orders/_components/status-badge';
import { TodoModal } from '@modals/detail-modals';
import { MiniProfile } from '@new';
import { modalService } from '@services';

// helpers
import { useWorkOrderModalContext } from '../../../../_context';

// components

// interfaces

const tableHeaders = [
  '#',
  'Name',
  'Service Provider',
  'Project Work',
  'Assigned To',
  'Due Date',
  'Status',
  '',
];

export const TodoTable = () => {
  const {
    state: { user },
  } = useAppContext();
  const {
    workOrder,
    setWorkOrder,
    activeModals,
    setActiveModals,
    setSelectedTodo,
    hasBeenUpdated,
  } = useWorkOrderModalContext();
  const hasTodos = workOrder.todos.length > 0;

  return hasTodos ? (
    <div className="form-common-scroll tw-overflow-x-scroll tw-overflow-y-hidden tw-whitespace-nowrap">
      <table className={`tw-w-full ${hasTodos ? 'tw-mt-11' : 'tw-mt-8'}`}>
        <thead className="tw-w-full">
          <tr>
            {tableHeaders.map((header) => (
              <th
                key={header}
                className="tw-text-left tw-text-sm tw-text-theme-neutral-600-500 tw-font-medium tw-pb-4 tw-pr-4 tw-border-b-2 tw-border-solid tw-border-neutral-300 dark:tw-border-neutral-600 tw-leading-normal"
              >
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {workOrder.todos.map((todo, i) => {
            const {
              id,
              title,
              contract,
              is_project_work,
              assigned_to,
              due_date,
              closed_date,
              is_complete,
            } = todo;
            const currentDate = TimeUtils.formatToISO(new Date());
            const isPastDue =
              (!is_complete && TimeUtils.isBefore(due_date, currentDate)) ||
              (is_complete && TimeUtils.isBefore(due_date, closed_date));
            const isLastRow = i === workOrder.todos.length - 1;
            const hasDeletePermissions = user.hasPermission(3, 'Work Orders');
            const overflowItems: OverflowMenuButtonProps['items'] = [
              {
                name: 'Edit',
                onClick: () => {
                  setSelectedTodo(todo);
                  setActiveModals({
                    ...activeModals,
                    'editing-todo': true,
                  });
                },
              },
            ];

            if (!is_complete) {
              overflowItems.unshift({
                name: 'Mark Closed',
                onClick: () => {
                  setSelectedTodo(todo);
                  setActiveModals({
                    ...activeModals,
                    'completing-todo': true,
                  });
                },
              });
            }

            if (hasDeletePermissions) {
              overflowItems.push({
                name: 'Delete',
                onClick: () => {
                  setSelectedTodo(todo);
                  setActiveModals({
                    ...activeModals,
                    'deleting-todo': true,
                  });
                },
              });
            }

            return (
              <tr
                key={i}
                onClick={() => {
                  modalService.open({
                    component: <TodoModal todo={todo} />,
                    callback: (data?: {
                      refetch?: boolean;
                      updatedTodo?: Todo;
                      deletedTodo?: Todo;
                    }) => {
                      setActiveModals({
                        ...activeModals,
                        'bottom-sheet': true,
                      });
                      if (data?.refetch) {
                        hasBeenUpdated.current = true;
                      }
                      if (data?.updatedTodo) {
                        const todoList = [...workOrder.todos];
                        const index = todoList.findIndex(
                          (todo) => todo.id === data.updatedTodo.id
                        );
                        todoList[index] = data.updatedTodo;

                        setWorkOrder({
                          ...workOrder,
                          todos: todoList,
                        });
                      }
                      if (data?.deletedTodo) {
                        const todoList = [...workOrder.todos];
                        const index = todoList.findIndex(
                          (todo) => todo.id === data.deletedTodo.id
                        );
                        todoList.splice(index, 1);

                        setWorkOrder({
                          ...workOrder,
                          todos: todoList,
                        });
                      }
                    },
                  });
                  setActiveModals({
                    ...activeModals,
                    'bottom-sheet': false,
                  });
                  setSelectedTodo(todo);
                }}
                className={`tw-h-[54px] tw-text-sm tw-text-neutral-900-100
                        ${
                          !isLastRow
                            ? 'tw-border-solid tw-border-b-2 tw-border-neutral-300 dark:tw-border-neutral-600'
                            : ''
                        }`}
              >
                <td className="tw-pr-4">{id}</td>
                <td className="tw-pr-4">{title}</td>
                <td className="tw-pr-4">{contract?.service_provider?.name}</td>
                <td className="tw-pr-4">{is_project_work ? 'Yes' : 'No'}</td>
                <td className="tw-pr-4">
                  <MiniProfile.Sm person={assigned_to?.person} />
                </td>
                <td className="tw-pr-4">
                  {TimeUtils.format(due_date, 'MM/DD/YYYY')}
                </td>
                <td className="tw-whitespace-nowrap tw-py-1 tw-pr-4">
                  <StatusBadge
                    className="tw-space-y-1"
                    status={
                      is_complete
                        ? WorkOrderStatusKey.CLOSED
                        : WorkOrderDueStatusKey.OPEN
                    }
                    isPastDue={isPastDue}
                    tooltipPosition="left"
                  />
                </td>
                <td onClick={(e) => e.stopPropagation()}>
                  <OverflowMenuButton
                    items={overflowItems}
                    flowDirection="left-up"
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  ) : null;
};
