// interfaces
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { UnusedArea } from '@api-interfaces';

// services
import { virtualBrownClient } from '@core/services/django-client';

// helpers
import { Params } from '@helpers';
import { PaginationParams } from '@models';

// operators

// constants
const BASE_URL = 'routes/unusedareas';

class RoutesUnusedAreasService {
	public getUnusedAreas(
		params: {
			boundary?: number;
			search?: string;
			building?: number;
			floor?: number;
			frequency?: number;
			position?: number;
			areaType?: number;
		} & PaginationParams
	) {
		const queryString = Params.makeQueryString(params);
		return virtualBrownClient.get<UnusedArea[]>(`${BASE_URL}/${queryString}`);
	}

	public getUnusedAreaById(params: {
		contract: number;
		servicearea: number;
		include_zero_occurrences?: boolean;
	}): Observable<UnusedArea> {
		const queryString = Params.makeQueryString(params);
		return (
			virtualBrownClient
				.get<UnusedArea[]>(`${BASE_URL}/${queryString}`)
				// returns an array but we only want one
				.pipe(
					map((unusedAreas) => {
						if (unusedAreas) {
							if (unusedAreas.length) {
								return unusedAreas[0];
							}
							return {} as UnusedArea;
						}
						return null;
					})
				)
		);
	}
}

export const routesUnusedAreasService = new RoutesUnusedAreasService();
