export class TimeDuration {
	private first = {
		// start time (not used now but might be useful in future)
		hours: 0,
		minutes: 0,
		seconds: 0,
	};

	private last = {
		// end time (not used now but might be useful in future)
		hours: 0,
		minutes: 0,
		seconds: 0,
	};

	private previous = {
		// in between
		hours: 0,
		minutes: 0,
		seconds: 0,
	};

	private current = {
		// in between
		hours: 0,
		minutes: 0,
		seconds: 0,
	};

	private duration = 0;

	private day = 0;

	private nextDay = false;

	constructor(militaryTime: string) {
		// Expects military time string '13:00:00'
		let timeString = militaryTime;
		if (!timeString) timeString = '00:00:00';

		const [hours, minutes, seconds] = timeString
			.split(':')
			.map((doubleDigit) => parseInt(doubleDigit));
		this.first = { hours, minutes, seconds };
		this.current = { hours, minutes, seconds };
		this.recalibrate(this.first);
		this.recalibrate(this.current);
		this.updatePreviousToCurrent();
	}

	private updatePreviousToCurrent() {
		this.previous = Object.assign(this.previous, this.current);
		this.checkNextDay();
	}

	private updateCurrentToLast() {
		this.last = Object.assign(this.last, this.current);
	}

	public addHours(hours: number) {
		this.updatePreviousToCurrent();
		this.current.hours += hours;
		this.duration = hours * 60 * 60;
		this.recalibrate(this.current);
		this.updateCurrentToLast();
	}

	public addMinutes(minutes: number) {
		this.updatePreviousToCurrent();
		this.current.minutes += minutes;
		this.duration = minutes * 60;
		this.recalibrate(this.current);
		this.updateCurrentToLast();
	}

	public addSeconds(seconds: number) {
		this.updatePreviousToCurrent();
		this.current.seconds += seconds;
		this.duration = seconds;
		this.recalibrate(this.current);
		this.updateCurrentToLast();
	}

	private recalibrate(time) {
		if (time.seconds >= 60) {
			time.minutes += Math.floor(time.seconds / 60);
			time.seconds %= 60;
		}

		if (time.minutes >= 60) {
			time.hours += Math.floor(time.minutes / 60);
			time.minutes %= 60;
		}

		if (time.hours >= 24) {
			time.hours %= 24;
			this.nextDay = true;
		}
	}

	private checkNextDay() {
		if (this.nextDay) {
			this.day++;
			this.nextDay = false;
		}
	}

	get firstTime(): string {
		return `${String(this.first.hours).padStart(2, '0')}:${String(
			this.first.minutes
		).padStart(2, '0')}:${String(this.first.seconds).padStart(2, '0')}`;
	}

	get lastTime(): string {
		return `${String(this.last.hours).padStart(2, '0')}:${String(
			this.last.minutes
		).padStart(2, '0')}:${String(this.last.seconds).padStart(2, '0')}`;
	}

	get previousTime(): string {
		return `${String(this.previous.hours).padStart(2, '0')}:${String(
			this.previous.minutes
		).padStart(2, '0')}:${String(this.previous.seconds).padStart(2, '0')}`;
	}

	get currentTime(): string {
		return `${String(this.current.hours).padStart(2, '0')}:${String(
			this.current.minutes
		).padStart(2, '0')}:${String(this.current.seconds).padStart(2, '0')}`;
	}

	get durationMinutes(): number {
		return this.duration / 60;
	}

	get currentDay(): number {
		return this.day;
	}
}
