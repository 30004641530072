export function titleCase(str: string): string {
	if (str) {
		const words = str
			.trim()
			.split('/')
			.map((part) =>
				part
					.split(' ')
					.map(
						(word) =>
							word.charAt(0).toUpperCase() +
							word.slice(1).toLowerCase()
					)
					.join(' ')
			);
		return words.join(' / ');
	}
	return '';
}

export function capitalize(str: string): string {
	if (!str) {
		return '';
	}

	return str
		.split(' ')
		.map(
			(word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
		)
		.join(' ');
}
