import { Page } from './interfaces';

export function reducer(state: Page.State, action: Page.Action): Page.State {
	switch (action.type) {
		case 'SET_MODALS': {
			return {
				...state,
				modals: action.payload,
			};
		}
		case 'SET_IS_LOADING': {
			return {
				...state,
				isLoading: action.payload,
			};
		}
		case 'SET_INSPECTION': {
			return {
				...state,
				inspection: action.payload,
			};
		}
		case 'SET_AREAS': {
			return {
				...state,
				areas: action.payload,
			};
		}
		case 'SET_AREA_TYPES': {
			return {
				...state,
				area_types: action.payload,
			};
		}
		case 'SET_SECTIONS': {
			return {
				...state,
				sections: action.payload,
			};
		}
		case 'SET_EMPLOYEES': {
			return {
				...state,
				employees: action.payload,
			};
		}
		case 'SET_ATTACHMENTS': {
			return {
				...state,
				attachments: action.payload,
			};
		}
		case 'SET_TODOS': {
			return {
				...state,
				todos: action.payload,
			};
		}
		case 'SET_COMMENTS': {
			return {
				...state,
				comments: action.payload,
			};
		}
		case 'SET_SELECTED_AREA_TYPE': {
			return {
				...state,
				selectedAreaType: action.payload,
			};
		}
		case 'SET_SELECTED_SECTION': {
			return {
				...state,
				selectedSection: action.payload,
			};
		}
		case 'SET_SELECTED_AREA': {
			return {
				...state,
				selectedArea: action.payload,
				selectedAreaId: undefined,
			};
		}
		case 'SET_SELECTED_AREA_LIST': {
			return {
				...state,
				selectedAreaList: action.payload,
			};
		}
		case 'SET_SELECTED_SERVICE': {
			return {
				...state,
				selectedService: action.payload,
			};
		}
		case 'SET_PHOTO_VIEWER_AREAS': {
			return {
				...state,
				photoViewerAreas: action.payload,
			};
		}
		case 'SET_PHOTO_VIEWER_INDEX': {
			return {
				...state,
				photoViewerIndex: action.payload,
			};
		}
		default:
			return { ...state };
	}
}
