import {
	ContractGoallines,
	OrganizationGoallines,
	Service,
} from '@api-interfaces';
import { FiltersState } from '@hooks/useFilters/filters.interfaces';
import {
	R4LoginResult,
	R4PortfolioContract,
	R4PortfolioCustomerOrganization,
	R4PortfolioAreaTag,
	R4PortfolioAreaTagGroup,
	R4PortfolioCustomerContractGroup,
	R4PortfolioCustomerContractGroupType,
	R4OrganizationGroupType,
	R4OrganizationGroup,
	R4Preset,
} from '@models';
import { User } from '@models/user';

export const LocalStorageName = 'app';

export type DateRangeType =
	| '1mo'
	| '3mo'
	| '6mo'
	| '1yr'
	| 'custom'
	| 'today'
	| 'yesterday'
	| '7-days'
	| 'current-week'
	| '30-days'
	| 'this-month'
	| 'last-month';

export interface DateRange {
	from: Date;
	to: Date;
	range: DateRangeType;
	months?: number;
	quartersSelected?: Array<string>;
	customStartTime?: string;
	customEndTime?: string;
}

type StoredDateRange = {
	from: string;
	to: string;
	range: DateRangeType;
};

export interface StoredAppData {
	refreshToken: string;
	dateRange: StoredDateRange;
	selectedOrgGroupTypes: R4OrganizationGroupType[];
	selectedOrgGroups: R4OrganizationGroup[];
	selectedCustomers: R4PortfolioCustomerOrganization[];
	selectedContracts: R4PortfolioContract[];
	selectedAreaTags: R4PortfolioAreaTag[];
	selectedAreaTagGroups: R4PortfolioAreaTagGroup[];
	selectedParentProgram: Service;
	selectedSiteGroupTypes: R4PortfolioCustomerContractGroupType[];
	selectedSiteGroups: R4PortfolioCustomerContractGroup[];
	selectedPreset: R4Preset;
}

export declare namespace App {
	export interface State {
		user: User;
		dateRange: DateRange;
		selectedOrgGroupTypes: R4OrganizationGroupType[];
		selectedOrgGroups: R4OrganizationGroup[];
		selectedCustomers: R4PortfolioCustomerOrganization[];
		selectedContracts: R4PortfolioContract[];
		selectedAreaTags: R4PortfolioAreaTag[];
		selectedAreaTagGroups: R4PortfolioAreaTagGroup[];
		selectedParentProgram: Service;
		selectedSiteGroupTypes: R4PortfolioCustomerContractGroupType[];
		selectedSiteGroups: R4PortfolioCustomerContractGroup[];
		selectedPreset: R4Preset;
		parentPrograms: Service[];
		contractsGoalLines: ContractGoallines[];
		organizationsGoalLines: OrganizationGoallines[];
		isMultiClient: boolean;
		isSingleClient: boolean;
		isMultiSite: boolean;
		isSingleSite: boolean;
		isMultiSiteGroup: boolean;
		isSingleSiteGroup: boolean;
		isMenuOpen: boolean; // for mobile
		isConnectionsPanelOpen: boolean;
		isModalStackOpen: boolean;
		isPresentationMode: boolean;
		isDs2: boolean;
		isSinglePage: boolean;
		isSitePickerOpen?: boolean;
		forwardedFilters?: FiltersState['appliedFilters'];

		logout(): void;
	}

	export type Action =
		| {
				type: 'SET_CURRENT_USER';
				payload:
					| null
					| (Partial<R4LoginResult> & { noRedirect?: boolean });
		  }
		| {
				type: 'SET_DATE_RANGE';
				payload: Pick<DateRange, 'from' | 'to' | 'range'>;
		  }
		| {
				type: 'SET_PICKER';
				payload: {
					selectedOrgGroupTypes: State['selectedOrgGroupTypes'];
					selectedOrgGroups: State['selectedOrgGroups'];
					selectedCustomers: State['selectedCustomers'];
					selectedContracts: State['selectedContracts'];
					selectedAreaTags: State['selectedAreaTags'];
					selectedAreaTagGroups: State['selectedAreaTagGroups'];
					selectedSiteGroupTypes: State['selectedSiteGroupTypes'];
					selectedSiteGroups: State['selectedSiteGroups'];
					selectedPreset: State['selectedPreset'];
					omitMixpanel?: boolean;
				};
		  }
		| {
				type: 'SET_PROGRAM';
				payload: number;
		  }
		| { type: 'SET_PROGRAM_FROM_LOCAL_STORAGE'; payload: Service }
		| {
				type: 'SET_PROGRAMS_ORGS_AND_CONTRACTS_GOALLINES';
				payload: {
					parentPrograms: Service[];
					contractsGoalLines: ContractGoallines[];
					organizationsGoalLines: OrganizationGoallines[];
				};
		  }
		| { type: 'SET_LOGOUT_CALLBACK'; payload: () => void }
		| { type: 'UPDATE_USER_EMPLOYEE_INFO'; payload: User['employee'] }
		| {
				type: 'TOGGLE_MENU';
				payload?: boolean;
		  }
		| {
				type:
					| 'TOGGLE_PRESENTATION_MODE'
					| 'TOGGLE_IS_DS2'
					| 'TOGGLE_IS_SINGLE_PAGE'
					| 'TOGGLE_CONNECTIONS_PANEL'
					| 'SET_IS_MODAL_STACK_OPEN';
				payload: boolean;
		  }
		| {
				type: 'SET_FORWARDED_FILTERS';
				payload: FiltersState['appliedFilters'];
		  }
		| {
				type: 'CLEAR_FORWARDED_FILTERS';
		  };
}
