import { useMemo, useState, useRef, useEffect } from 'react';
import * as React from 'react';

// components
import { IconButtonFlat } from '@atoms';
import { Menu } from '@molecules';

import { OverflowMenuButtonProps } from './overflow-menu-button.interfaces';

export const OverflowMenuButton = ({
  items,
  flowDirection,
}: OverflowMenuButtonProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const overflowMenuButtonRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (overflowMenuButtonRef.current) {
      document.addEventListener('mousedown', handleOutsideClick);
    }
    return () => {
      document.removeEventListener('mousedown', (e) => {});
    };
  }, [isOpen]);

  const menuItems = useMemo(() => {
    return items.map((item) => ({
      ...item,
      onClick: () => {
        if (item.onClick) item.onClick();
        setIsOpen(false);
      },
    }));
  }, [items]);

  function handleOutsideClick(e: MouseEvent) {
    const target = e.target as HTMLElement;
    if (!overflowMenuButtonRef.current?.contains(target) && isOpen) {
      setIsOpen(false);
    }
  }

  function handleMenuToggle(e: React.MouseEvent) {
    e.preventDefault();
    setIsOpen(!isOpen);
  }

  let flowDirectionClassName;

  switch (flowDirection) {
    case 'top-left':
      flowDirectionClassName = 'tw-right-0 tw-bottom-[24px]';
      break;
    case 'top-right':
      flowDirectionClassName = 'tw-bottom-[24px]';
      break;
    case 'bottom-left':
      flowDirectionClassName = 'tw-top-full tw-right-0';
      break;
    case 'left-up':
      flowDirectionClassName = 'tw-right-[24px] tw-bottom-0';
      break;
    default:
      flowDirectionClassName = 'tw-top-full tw-left-0';
  }

  return (
    <div className="tw-relative tw-inline-block" ref={overflowMenuButtonRef}>
      <IconButtonFlat icon="ellipsis-v" onClick={handleMenuToggle} />
      {isOpen && (
        <Menu
          menuItems={menuItems}
          className={`tw-absolute tw-z-10 ${flowDirectionClassName}`}
        />
      )}
    </div>
  );
};

OverflowMenuButton.defaultProps = {
  flowDirection: 'bottom-right',
};
