import { ModalStackProps } from './modal-stack.interfaces';

export const modalPositionMap: {
	[Position in ModalStackProps['position']]: string;
} = {
	cover: 'tw-h-full tw-w-full',
	'top-center': '', // purpose is to indicate where modal will position itself
	'top-half-center': 'tw-relative tw-top-1/4 tw--translate-y-1/4',
	center: 'tw-h-full tw-w-full tw-grid tw-place-items-center',
	'bottom-center': 'tw-max-h-min tw-w-full tw-flex tw-items-end sm:tw-h-full',
};
