import { useRef, useState, useEffect } from 'react';
import * as React from 'react';

// svg
import { createPortal } from 'react-dom';
import SVG from 'react-inlinesvg';

import { Box, Text, Tooltip } from '@atoms';

import { DateRangePicker } from '@components/_globals/components/date-range-picker';
import {
    DEFAULT_END_TIME,
    DEFAULT_START_TIME,
} from '@components/_globals/components/date-range-picker/date-range-picker.interfaces';

import { DateRange } from '@core/app-context/app.interfaces';

import { TimeUtils } from '@helpers';

import { useBreakpoints } from '@hooks';

import CustomTimeSVG from './customTime.svg';

// helpers
import dateConfig, { DateRangeConfig, DatePickerTypes } from './date-config';

// components
import { Button } from '../Button';

// interfaces
import './index.scss';

interface IProps {
    dateRange: DateRange;
    setDateRange: (dateRange: DateRange) => void;
    className?: string;
    alignPopup?: 'left' | 'right'; // align dropdown and calendar
    disabled?: boolean;
    allowFutureDates?: boolean; // future dates blocked by default
    inverted?: boolean;
    style?: React.CSSProperties;
    locales?: string;
    lockRange?: DatePickerTypes;
    customTime?: boolean;
    onClick?: () => void;
}

export const DatePickerV2 = (props: IProps) => {
    const bp = useBreakpoints();
    const isMobile = !bp.md;
    const { dateRange, setDateRange, className = '', onClick } = props;
    const root = useRef<HTMLDivElement>(null);
    const [dropdownShow, setDropdownShow] = useState(false);
    const [dropdownSelected, setDropdownSelected] = useState<DateRangeConfig>(
        () => {
            if (props.lockRange) {
                return dateConfig[props.lockRange];
            }
            return null;
        }
    );
    const [calendarShow, setCalendarShow] = useState(false);
    const [showButton, setShowButton] = useState(false);
    const fromLegacyPicker = useRef(false);
    const alignPopup =
        props?.alignPopup === 'right' ? 'tw-right-0' : 'tw-left-0';

    /* Set States & Events */
    useEffect(() => {
        setDropdownShow(false);
        setShowButton(true);
        fromLegacyPicker.current = !Object.keys(dateConfig).includes(
            dateRange.range
        );

        const handleOutsideClick = (e: Event) => {
            const target = e.target as HTMLElement;
            if (!root?.current?.contains(target)) {
                closeAll();
            }
        };

        /* Events */
        window.addEventListener('click', handleOutsideClick);
        window.addEventListener('resize', closeAll);
        return () => {
            window.removeEventListener('click', handleOutsideClick);
            window.removeEventListener('resize', closeAll);
        };
    }, []);

    useEffect(() => {
        setDropdownSelected(
            dateConfig[
                Object.keys(dateConfig).includes(dateRange.range)
                    ? dateRange.range
                    : 'today'
            ]
        );
    }, [dateRange.range]);

    /* Set States & Events */
    useEffect(() => {
        setDropdownShow(false);
    }, [dateRange]);

    /* Toggle Off Dropdown on Mouse Event */
    const closeAll = () => {
        setDropdownShow(false);
    };

    /* Toggle Dropdown  */
    const toggleDropdown = (e: React.MouseEvent<HTMLDivElement>) => {
        if (props.lockRange === 'today') {
            setCalendarShow(!calendarShow);
            return;
        }
        if (props.lockRange) return;

        e.stopPropagation();
        if (calendarShow) {
            setDropdownShow(false);
            setCalendarShow(false);
        } else {
            setDropdownShow(!dropdownShow);
        }
    };

    /**
     * if user selected a future date in a feature that allows that behavior,
     * then navigates to a feature that does not allow future dates,
     * reset dateRange to current day to prevent viewing futures dates elsewhere
     */
    useEffect(() => {
        if (
            !props.allowFutureDates &&
            !TimeUtils.isDateInRange(dateRange.to, dateRange.from, new Date())
        ) {
            setDateRange({
                ...dateRange,
                from: TimeUtils.startOfDay(new Date()),
                to: TimeUtils.endOfDay(new Date()),
                range: 'custom',
            });
        }
    }, []);

    /* Update Date */
    const updateDate = (
        action: string,
        name?: string,
        item?: DateRangeConfig
    ) => {
        /* Prevent Next If >= Today */
        if (
            !props.allowFutureDates &&
            action === 'next' &&
            dateRange.to >= new Date()
        )
            return false;

        /* Set Data */
        const data: DateRangeConfig =
            action === 'switch' ? item : dropdownSelected;

        /* Calculate Date Information */
        const { allowFutureDates } = props;
        const results: any = data.function({
            action,
            dateRange,
            allowFutureDates,
        });

        /* Update Date State */
        if (
            !props.allowFutureDates &&
            TimeUtils.compareAsc(
                results.from,
                TimeUtils.startOfDay(new Date())
            ) > 0
        )
            results.from = TimeUtils.startOfDay(new Date());
        if (
            !props.allowFutureDates &&
            TimeUtils.compareAsc(results.to, TimeUtils.startOfDay(new Date())) >
                0
        )
            results.to = TimeUtils.endOfDay(new Date());
        setDateRange({ ...results, range: name || dateRange.range });

        /* Update Selected Dropdown */
        if (data) setDropdownSelected(data);
    };

    /* Toggle Open Custom Calendar Picker Mode */
    const custom = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();

        /* Convert Old Date Picker Data to New, with "To" Limited to Today's Date */
        if (fromLegacyPicker.current) {
            const setNewRange = {
                ...dateRange,
                to: new Date(),
                range: 'custom' as const,
            };
            setDateRange(setNewRange);
        }
        setDropdownSelected(dateConfig.custom);
        setDropdownShow(false);
        setCalendarShow(true);
    };

    const customizedTime =
        dateRange.range === 'custom' &&
        ((dateRange.customStartTime &&
            dateRange.customStartTime !== DEFAULT_START_TIME) ||
            (dateRange.customEndTime &&
                dateRange.customEndTime !== DEFAULT_END_TIME));

    return showButton ? (
        <div
            ref={root}
            className={`date-picker-v2 ${isMobile ? 'full-width' : ''} ${
                props.disabled ? 'tw-opacity-50 tw-pointer-events-none' : ''
            } ${className}`}
            style={props.style}
            data-is-mobile={isMobile}
        >
            <div
                className={`button tw-rounded ${
                    props.inverted
                        ? 'tw-bg-neutral-200-900'
                        : 'tw-bg-neutral-100-800'
                }`}
            >
                <Button
                    color={props.inverted ? 'inverted' : 'secondary'}
                    icon="caret-left"
                    iconSize="lg"
                    label=""
                    className="prev tw-space-x-0 tw-px-0 tw-w-[40px]"
                    onClick={() => updateDate('prev')}
                />
                <div
                    className="label tw-text-theme-primary-500-300 hover:tw-text-theme-primary-300-100 tw-h-10 tw-items-center"
                    onClick={onClick ? onClick : toggleDropdown}
                >
                    <Tooltip
                        render={
                            <Box
                                rounded
                                className="tw-p-2 tw-whitespace-nowrap tw-shadow-lg tw-w-min"
                            >
                                <Text color="hi-contrast" font="body-md">
                                    Custom time applied.
                                </Text>
                            </Box>
                        }
                        position="bottom"
                        disabled={!customizedTime}
                    >
                        {customizedTime && (
                            <SVG
                                src={CustomTimeSVG}
                                height={19}
                                width={19}
                                uniquifyIDs
                                className="tw-mr-2"
                            />
                        )}
                    </Tooltip>
                    {TimeUtils.isSameDay(dateRange.from, dateRange.to)
                        ? TimeUtils.format(dateRange.from, 'dddd, MMM DD, YYYY')
                        : TimeUtils.isFullMonth(dateRange.from, dateRange.to)
                          ? TimeUtils.format(dateRange.from, 'MMMM YYYY')
                          : `${TimeUtils.format(
                                dateRange.from,
                                'MMM DD, YYYY'
                            )} - ${TimeUtils.format(
                                dateRange.to,
                                'MMM DD, YYYY'
                            )}`}
                </div>
                <Button
                    color={props.inverted ? 'inverted' : 'secondary'}
                    icon="caret-right"
                    iconSize="lg"
                    label=""
                    className="next tw-space-x-0 tw-px-0 tw-w-[40px]"
                    disabled={
                        !props.allowFutureDates &&
                        TimeUtils.endOfDay(dateRange.to) >=
                            TimeUtils.endOfDay(new Date())
                    }
                    onClick={() => updateDate('next')}
                />
            </div>

            {/* Drop Down Menu */}
            {dropdownShow &&
                createPortal(
                    <div
                        className={`dropdown ${alignPopup} tw-pointer-events-auto`}
                        style={{
                            top:
                                root.current.getBoundingClientRect().top +
                                root.current.getBoundingClientRect().height +
                                window.pageYOffset,
                            left: root.current.getBoundingClientRect().left,
                            width: root.current.getBoundingClientRect().width,
                        }}
                        onClick={() => setDropdownShow(false)}
                    >
                        <div className="items">
                            {Object.keys(dateConfig).map((item, i) => {
                                return (
                                    <div
                                        key={i}
                                        className="item"
                                        onClick={(e) =>
                                            item === 'custom'
                                                ? custom(e)
                                                : updateDate(
                                                      'switch',
                                                      item,
                                                      dateConfig[item]
                                                  )
                                        }
                                    >
                                        {dateConfig[item].label}
                                    </div>
                                );
                            })}
                        </div>
                    </div>,
                    document.getElementById('datepicker-dropdown')
                )}

            {/* Calendar Picker */}
            {calendarShow &&
                createPortal(
                    <div className="range-container tw-w-full tw-h-full">
                        <DateRangePicker
                            style={{
                                top:
                                    root.current.getBoundingClientRect().top +
                                    root.current.getBoundingClientRect()
                                        .height +
                                    window.pageYOffset,
                                left: isMobile
                                    ? 0
                                    : root.current.getBoundingClientRect()
                                          .left - (props.lockRange ? 58 : 358),
                                height: isMobile ? 450 : ``,
                            }}
                            rounded
                            shadow
                            className={`dropdown tw-mt-1.5 tw-w-max tw-absolute tw-z-[999999] ${alignPopup} tw-pointer-events-auto`}
                            dateRange={{
                                startDate: dateRange.from,
                                endDate: dateRange.to,
                                customStartTime:
                                    dateRange.customStartTime ??
                                    TimeUtils.format(dateRange.from, 'HH:mm'),
                                customEndTime:
                                    dateRange.customEndTime ??
                                    TimeUtils.format(dateRange.to, 'HH:mm'),
                            }}
                            lockRange={props.lockRange}
                            onApplyDateRange={(dateRange) => {
                                setDateRange({
                                    from: dateRange.startDate,
                                    to: dateRange.endDate,
                                    range:
                                        props.lockRange === 'today'
                                            ? 'today'
                                            : 'custom',
                                    customStartTime: dateRange.customStartTime,
                                    customEndTime: dateRange.customEndTime,
                                });
                                setCalendarShow(false);
                            }}
                            customTime={props.customTime}
                            onCancel={() => {
                                setCalendarShow(false);
                            }}
                            disableFuture={!props.allowFutureDates}
                            disabled={props.disabled}
                            locales={props.locales}
                        />
                    </div>,
                    document.getElementById('datepicker-range')
                )}
        </div>
    ) : null;
};

DatePickerV2.defaultProps = {
    alignPopup: 'left',
    locales: 'en',
};
