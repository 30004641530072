import * as React from 'react';

import { Text } from '@atoms';

interface CommunicationFormsFooterProps {
  step: 1 | 2;
  leftSide: React.ReactNode;
  rightSide: React.ReactNode;
}

export default function CommunicationFormsFooter(
  props: CommunicationFormsFooterProps
) {
  const { step, leftSide, rightSide } = props;

  function createStepStyles(stepCount: number) {
    return `tw-flex tw-justify-center tw-w-8 tw-h-8 tw-pt-[5px] tw-rounded-full ${
      step === stepCount
        ? 'tw-bg-theme-primary-500-300'
        : 'tw-bg-theme-neutral-900-100 tw-bg-opacity-15'
    }`;
  }

  return (
    <div className="tw-fixed tw-left-0 tw-bottom-0 tw-z-50 tw-w-full tw-px-8 tw-py-4 tw-border-t tw-border-solid tw-border-theme-neutral-900-100 tw-border-opacity-15 tw-bg-neutral-100 dark:tw-bg-neutral-850">
      <div className="tw-flex tw-justify-center">
        <div className="tw-grid tw-grid-cols-2 tw-w-full tw-max-w-[1920px] sm:tw-grid-cols-3">
          <div className="tw-max-w-min">{leftSide}</div>
          <div className="tw-hidden tw-items-center tw-justify-center sm:tw-flex">
            <div className={createStepStyles(1)}>
              <Text
                font="body-md"
                color="hi-contrast-inverted"
                className="tw-text-center tw-h-8"
              >
                1
              </Text>
            </div>
            <div className="tw-bg-theme-neutral-900-100 tw-bg-opacity-15 tw-w-10 tw-h-[2px]" />
            <div className={createStepStyles(2)}>
              <Text
                font="body-md"
                color="hi-contrast-inverted"
                className="tw-text-center tw-h-8"
              >
                2
              </Text>
            </div>
          </div>
          <div className="tw-flex tw-justify-end">{rightSide}</div>
        </div>
      </div>
    </div>
  );
}
