import { IconButtonProps } from './icon-button.interfaces';

export const iconButtonTypeMap: {
	[Type in IconButtonProps['type']]: string;
} = {
	primary: `
	 tw-bg-theme-primary-500-300 tw-text-neutral-100-900
	 hover:tw-bg-theme-primary-300-100
	 focus:tw-bg-theme-primary-300-100
	 active:tw-bg-theme-primary-900-700
	 disabled:tw-opacity-40 disabled:tw-pointer-events-none disabled:tw-cursor-not-allowed
	`,
	secondary: `
	 tw-bg-theme-neutral-100-800 tw-text-theme-primary-500-300
	 hover:tw-text-primary-100 dark:hover:tw-bg-neutral-600
	 focus:tw-text-primary-100 dark:focus:tw-bg-neutral-600
	 active:tw-bg-theme-neutral-300-700 active:tw-text-primary-500
	 disabled:tw-opacity-40 disabled:tw-pointer-events-none disabled:tw-cursor-not-allowed
	`,
	purple: `
	 tw-bg-secondary-500 dark:tw-bg-secondary-300 tw-text-neutral-100-900
	 hover:tw-bg-secondary-300 dark:hover:tw-bg-secondary-100
	 focus:tw-bg-secondary-300 dark:focus:tw-bg-secondary-100
	 active:tw-bg-secondary-900 dark:active:tw-bg-secondary-700
	 disabled:tw-opacity-40 disabled:tw-pointer-events-none disabled:tw-cursor-not-allowed
	`,
	inverted: `
	tw-bg-neutral-200-900 tw-text-theme-primary-500-300
	hover:tw-text-primary-100 dark:hover:tw-bg-neutral-600
	focus:tw-text-primary-100 dark:focus:tw-bg-neutral-600
	active:tw-bg-theme-neutral-300-700 active:tw-text-primary-500
	disabled:tw-opacity-40 disabled:tw-pointer-events-none disabled:tw-cursor-not-allowed
   `,
	danger: `
	 tw-bg-theme-danger-700-300 tw-text-neutral-100-900
	 hover:tw-bg-theme-danger-300-100
	 focus:tw-bg-theme-danger-300-100
	 active:tw-bg-theme-danger-900-700
	 disabled:tw-opacity-40 disabled:tw-pointer-events-none disabled:tw-cursor-not-allowed
	`,
	'danger-alternate': `
	 tw-bg-theme-danger-700-300 tw-bg-opacity-15 tw-text-theme-danger-700-300
	 hover:tw-bg-opacity-8
	 focus:tw-bg-opacity-8
	 active:tw-bg-danger-900 dark:active:tw-bg-danger-500 active:tw-bg-opacity-8 dark:active:tw-bg-opacity-8
	 disabled:tw-bg-theme-danger-700-300 disabled:tw-opacity-40 disabled:tw-text-opacity-40 disabled:tw-pointer-events-none
	`,
	alternate: `
	tw-bg-theme-primary-500-300 tw-bg-opacity-15 tw-text-theme-primary-500-300
	hover:tw-bg-primary-100 hover:tw-bg-opacity-8 dark:hover:tw-text-primary-100
	focus:tw-bg-primary-100 focus:tw-bg-opacity-8 dark:focus:tw-text-primary-100
	active:tw-bg-theme-primary-900-500 active:tw-bg-opacity-8 dark:active:tw-text-primary-500
	disabled:tw-bg-opacity-[0.06] disabled:tw-text-opacity-40 disabled:tw-pointer-events-none disabled:tw-cursor-not-allowed
   `,
	'text-button': `
   tw-px-0 tw-text-theme-primary-500-300 hover:tw-text-theme-primary-700-100 active:tw-text-primary-500 dark:active:tw-text-primary-700 disabled:tw-cursor-not-allowed disabled:tw-pointer-events-none disabled:tw-opacity-40
   `,
};
