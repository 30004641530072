import { forwardRef } from 'react';

// components
import { ErrorText } from '../error-text';

import { InputPhone } from '../input-phone';
import { Label } from '../label';

// interfaces
import { FieldInputPhoneProps } from './field-input-phone.interfaces';

import './field-input-phone.css';

export const FieldInputPhone = forwardRef<
  HTMLInputElement,
  FieldInputPhoneProps
>(({ id, label, labelFont, error, required, ...props }, ref) => (
  <fieldset data-ds2="field-input-phone" className="tw-w-full tw-space-y-1.5">
    <Label htmlFor={id} label={label} font={labelFont} required={required} />
    <InputPhone ref={ref} id={id} error={!!error} {...props} />
    <ErrorText error={error} />
  </fieldset>
));

FieldInputPhone.defaultProps = {
  labelFont: 'body-sm',
};
