import { Employee } from '@api-interfaces';
import { LoaderBlocks } from '@atoms/loader-blocks';
import { UserSelectRow } from '@components/_new/_form/dropdowns/user-select/components/UserSelectRow';

// interface

interface Props {
  users?: Employee[];
  selectedUser: Employee;
  onClick?: (item) => void;
  isUserFormOpen: boolean;
  isLoading: boolean;
  'data-test'?: string;
}

export const UserSelectList = ({
  users,
  onClick,
  isUserFormOpen,
  isLoading,
  selectedUser,
  ...props
}: Props) => {
  if (isUserFormOpen) {
    return null;
  }

  if (isLoading) {
    return (
      <div className="tw-flex tw-justify-center tw-items-center tw-min-h-[82px] tw-bg-neutral-100 dark:tw-bg-neutral-800 tw-shadow">
        <LoaderBlocks />
      </div>
    );
  }

  function getUsersList() {
    if (isUserFormOpen && !!selectedUser) {
      return [];
    }
    const matches: Employee[] = [];
    const nonMatches: Employee[] = [];
    users.forEach((user) => {
      const isMatch = user.id === selectedUser?.id;
      if (isMatch) {
        matches.push(user);
      } else {
        nonMatches.push(user);
      }
    });
    return [...matches, ...nonMatches];
  }

  return (
    <ul className="tw-max-h-[255px] tw-w-full tw-overflow-y-scroll tw-rounded-bl tw-rounded-br tw-overflow-x-hidden tw-cursor-pointer">
      {!getUsersList()?.length ? (
        <li className="tw-flex tw-items-center tw-text-sm tw-h-full tw-ml-4 tw-my-2">
          No Users Found
        </li>
      ) : (
        getUsersList().map((user, index) => (
          <UserSelectRow
            data-test={`${
              props?.['data-test'] ? `${props['data-test']}-` : ``
            }user-select-row`}
            selectedUser={selectedUser}
            key={user.id}
            onClick={onClick}
            user={user}
          />
        ))
      )}
    </ul>
  );
};
