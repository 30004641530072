import { useState, useRef, useEffect } from 'react';
import * as React from 'react';

// services

// helpers
import { Icon, ModalStack, Text } from '@atoms';
import { TimeUtils } from '@helpers';

// components
import { ImageBlock } from '@new';
import { awsService } from '@services';

import { ImageWithDetails } from './components/ImageWithDetails/ImageWithDetails';

// assets

// interfaces
import { ImageCarouselProps } from './ImageCarousel.interfaces';

import './ImageCarousel.css';

export const ImageCarousel = ({
    media,
    onClose,
    selectedImage,
}: ImageCarouselProps) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const scrollContainerRef = useRef<HTMLDivElement>(null);
    const imageRefs = useRef<HTMLDivElement[]>([]);

    useEffect(() => {
        if (scrollContainerRef.current && imageRefs.current.length) {
            const selectedImageClientWidth =
                imageRefs.current[activeIndex].clientWidth;
            const scrollLeft = selectedImageClientWidth * activeIndex;
            scrollContainerRef.current.style.transform = `translateX(-${scrollLeft}px)`;
        }
    }, [activeIndex]);

    useEffect(() => {
        if (selectedImage) {
            setActiveIndex(media.findIndex((m) => m.id === selectedImage.id));
        }
    }, [selectedImage]);

    const onLeft = () => {
        setActiveIndex((prevIndex: number) =>
            prevIndex !== 0 ? prevIndex - 1 : media.length - 1
        );
    };

    const onRight = () => {
        setActiveIndex((prevIndex: number) => (prevIndex + 1) % media.length);
    };

    const onIndexChange = (activeIndex: number) => {
        setActiveIndex(activeIndex);
    };

    const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'ArrowLeft') {
            onLeft();
        } else if (event.key === 'ArrowRight') {
            onRight();
        } else if (event.key === 'Escape') {
            onClose();
        }
    };

    return (
        <ModalStack position="cover" overlayOpacity="tw-bg-opacity-90">
            <div
                className="tw-mx-auto tw-flex tw-h-full tw-w-full tw-flex-col
        tw-py-6 sm:tw-py-8 lg:tw-py-10 2xl:tw-py-16"
                tabIndex={-1}
                onKeyDown={onKeyDown}
            >
                <div className="tw-flex tw-justify-between tw-space-x-4 tw-mb-6 tw-px-4 sm:tw-px-8 md:tw-px-10 2xl:tw-px-16">
                    <div className="tw-space-y-3 tw-truncate">
                        <Text font="h2" color="white">
                            {media[activeIndex]?.file_name ?? null}
                        </Text>
                        <Text font="h4" color="neutral-flat">
                            {media[activeIndex]?.update_date
                                ? `Uploaded: ${TimeUtils.format(
                                      media[activeIndex].update_date,
                                      'M/D/YYYY'
                                  )}`
                                : null}
                        </Text>
                        <Text font="h4" color="neutral-flat">
                            {media[activeIndex]?.origin
                                ? `Origin: ${media[activeIndex].origin}`
                                : null}
                        </Text>
                    </div>
                    <div>
                        <button onClick={onClose}>
                            <Icon
                                fixedWidth
                                className="tw-text-neutral-100 tw-text-3xl"
                                icon="times"
                            />
                        </button>
                    </div>
                </div>
                <div className="tw-flex-grow tw-flex tw-flex-col tw-pb-4">
                    <div className="image-carousel-container tw-relative tw-flex-grow tw-grid tw-place-items-center tw-mx-auto tw-overflow-hidden">
                        {media.length > 1 && (
                            <>
                                <div className="tw-absolute tw-top-1/2 tw-left-4 tw-transform tw--translate-y-1/2 tw-z-10">
                                    <button onClick={onLeft}>
                                        <Icon
                                            icon="angle-left"
                                            className="tw-text-neutral-100"
                                            size="3x"
                                        />
                                    </button>
                                </div>
                                <div className="tw-absolute tw-top-1/2 tw-right-4 tw-transform tw--translate-y-1/2 tw-z-10">
                                    <button onClick={onRight}>
                                        <Icon
                                            icon="angle-right"
                                            className="tw-text-neutral-100"
                                            size="3x"
                                        />
                                    </button>
                                </div>
                            </>
                        )}
                        <div
                            className="image-carousel-height tw-flex tw-flex-nowrap tw-items-center tw-transition-transform tw-delay-500 tw-ease-linear"
                            ref={scrollContainerRef}
                        >
                            {media.map((m, index) => (
                                <div
                                    key={m.link ?? m.file_name}
                                    ref={(el) => {
                                        imageRefs.current[index] = el;
                                    }}
                                    className="tw-min-w-full"
                                >
                                    <div
                                        className="image-carousel-height tw-relative tw-mx-auto"
                                        style={{ width: 'fit-content' }}
                                    >
                                        {m.scoreChip?.location ? (
                                            <ImageWithDetails
                                                imageUrl={m.link ?? m.file_name}
                                                imageTextValues={{
                                                    areaName:
                                                        m.scoreChip.location
                                                            .name,
                                                    areaType:
                                                        m.scoreChip.location
                                                            .area_type.name,
                                                    locationType:
                                                        m.scoreChip.location
                                                            .type.name,
                                                    locationValue:
                                                        m.scoreChip.location
                                                            .parent.name,
                                                }}
                                                scoreChip={{
                                                    score: m.scoreChip.score,
                                                    type:
                                                        m.scoreChip.isPass ==
                                                        undefined
                                                            ? 'neutral'
                                                            : m.scoreChip.isPass
                                                              ? 'success'
                                                              : 'danger',
                                                }}
                                            />
                                        ) : (
                                            <img
                                                className="image-carousel-height tw-w-full tw-object-contain tw-mx-auto"
                                                src={
                                                    m.link ??
                                                    awsService.getUrl(
                                                        m.file_name
                                                    )
                                                }
                                            />
                                        )}
                                        <div className="tw-absolute tw-bottom-4 tw-left-4">
                                            {m.tags?.map((tag, idx) => (
                                                <div
                                                    key={idx}
                                                    className="tw-bg-neutral-900 tw-bg-opacity-50 tw-rounded-lg tw-py-3 tw-px-4"
                                                >
                                                    <Text
                                                        font="h3"
                                                        color="white"
                                                    >
                                                        {tag}
                                                    </Text>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <Text
                        font="h3"
                        color="white"
                        className="tw-text-center tw-mb-4 tw-mt-6"
                    >
                        {activeIndex + 1} of {media.length}
                    </Text>
                    <div className="tw-flex tw-justify-center tw-items-center tw-space-x-5 tw-overflow-x-auto tw-pb-2">
                        {media.map((media, _index) => (
                            <ImageBlock
                                key={media.id}
                                imageUrl={media.link ?? media.file_name}
                                onClick={() => onIndexChange(_index)}
                                mainClassName={`tw-flex-shrink-0 hover:tw-cursor-pointer tw-h-[80px] sm:tw-h-[120px] tw-w-[80px] sm:tw-w-[120px] tw-rounded-md tw-border-solid tw-border-2 ${
                                    activeIndex === _index
                                        ? 'tw-border-primary-500'
                                        : 'tw-border-neutral-300'
                                }`}
                                imageClassName="tw-rounded-sm tw-bg-cover tw-bg-center"
                                tags={media?.tags}
                                scoreChip={
                                    media.scoreChip
                                        ? {
                                              score: media.scoreChip.score,
                                              type:
                                                  media.scoreChip.isPass ==
                                                  undefined
                                                      ? 'neutral'
                                                      : media.scoreChip.isPass
                                                        ? 'success'
                                                        : 'danger',
                                          }
                                        : null
                                }
                            />
                        ))}
                    </div>
                </div>
            </div>
        </ModalStack>
    );
};
