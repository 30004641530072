import { Text } from '@atoms';
import { useAppContext } from '@hooks';
import { Button } from '@new';

import { useWorkOrderModalContext } from '../../../../_context';

import { CompleteTodo } from './todo.complete';
import { CreateTodo } from './todo.create';
import { DeleteTodo } from './todo.delete';
import { EditTodo } from './todo.edit';
import { TodoTable } from './todo.table';

// helpers;

export const WorkOrderTodos = () => {
  const { activeModals, setActiveModals, setSelectedTodo } =
    useWorkOrderModalContext();

  const {
    state: { user },
  } = useAppContext();

  // const [isLoading, setIsLoading] = useState<boolean>(false);

  const hasCreatePermission = user.hasPermission(2, 'ToDos');

  function handleCreateTodo() {
    // setIsLoading(true);
    setActiveModals({
      ...activeModals,
      'creating-todo': true,
    });
    setSelectedTodo(null);
    // setIsLoading(false);
  }

  return (
    <>
      <div className="tw-flex tw-justify-between tw-items-center">
        <Text font="h2" color="primary">
          To-Dos
        </Text>

        {hasCreatePermission && (
          <Button
            color="alternate"
            icon={['far', 'plus']}
            label="Create New To-Do"
            // disabled={isLoading}
            onClick={handleCreateTodo}
          />
        )}
      </div>

      <TodoTable />

      <EditTodo />
      <CreateTodo />
      <DeleteTodo />
      <CompleteTodo />
    </>
  );
};
