type TimerCallback = () => void;

export class Daemon {
	private timer: NodeJS.Timeout;

	private rate: number;

	private isPaused: boolean = true;

	private isCalling: boolean = false;

	private callback: (timerCb: TimerCallback) => void;

	private _forced: boolean = false;

	constructor({ rate }: { rate?: number }) {
		const fiveMinutes = 1000 * 60 * 5;
		this.rate = rate || fiveMinutes;
	}

	private timerCallback = () => {
		if (!this.isPaused && !this.isCalling) {
			this.isCalling = true;
			this.callback(() => {
				this.isCalling = false;
			});
		}
	};

	init = (cb: (timerCb: TimerCallback) => void) => {
		// if daemon gets reinitialized, be sure to clear out of setInterval
		if (this.timer) clearInterval(this.timer);
		this.callback = cb;
		this.timer = setInterval(this.timerCallback, this.rate);
	};

	start = () => {
		this.isPaused = false;
	};

	stop = () => {
		this.isPaused = true;
	};

	close = () => {
		if (this.isInitialized) {
			clearInterval(this.timer);
		}
	};

	get forced() {
		return this._forced;
	}

	forceCall = () => {
		if (this.isInitialized && !this.isCalling && !this.forced) {
			this.close();
			this.isCalling = true;
			this._forced = true;
			this.callback(() => {
				this.isCalling = false;
				this._forced = false;
				// close previous setInterval and reset timer
				this.timer = setInterval(this.timerCallback, this.rate);
			});
		}
	};

	get isInitialized() {
		return Number.isInteger(this.timer);
	}
}
