import { Observable } from 'rxjs';

import { Department } from '@api-interfaces';
import { Params } from '@core/helpers';
import { virtualBrownClient } from '@core/services/django-client';

// helpers

// interfaces
import { PaginationParams, PaginationResponse } from '@models';

const BASE_URL = `departments`;

const noOpts = {
	noContract: true,
	noCustomer: true,
	noTag: true,
};

class DepartmentsService {
	getDepartments = (
		params: {
			organization: number;
			terminiated?: boolean;
		} & PaginationParams
	): Observable<PaginationResponse<Department>> =>
		virtualBrownClient.get(
			`${BASE_URL}/${Params.makeQueryString(params || {})}`
		);

	getDepartmentById = (departmentId: number): Observable<Department> =>
		virtualBrownClient.get(`${BASE_URL}/${departmentId}/`, noOpts);
}

export const departmentsService = new DepartmentsService();
