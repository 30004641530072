// interfaces
import { Shift, ShiftPayload, ShiftType } from '@api-interfaces';

// services
import { virtualBrownClient } from '@core/services/django-client';

// helpers
import { Params } from '@helpers';
import { PaginationParams, PaginationResponse } from '@models';

// constants
const BASE_URL = 'shifts';

const noOpts = {
	noProvider: true,
	noCustomer: true,
	noContract: true,
	noTag: true,
};

class ShiftsService {
	public getShifts(
		params: {
			contract?: number | string | number[] | string[];
			customer?: number | string | number[] | string[];
			organization?: number;
			corporate?: boolean;
			detail_level?: 'simple';
		} & PaginationParams,
		settings?: {
			noOptions?: boolean;
		}
	) {
		const queryString = Params.makeQueryString(params);

		return virtualBrownClient.get<PaginationResponse<Shift>>(
			`${BASE_URL}/${queryString}`,
			settings && settings.noOptions ? noOpts : {}
		);
	}

	public getShiftById(params: { id: number }) {
		return virtualBrownClient.get<Shift>(`${BASE_URL}/${params.id}/`, noOpts);
	}

	public updateShift(params: { id: number }, payload: ShiftPayload) {
		return virtualBrownClient.put<Shift>(`${BASE_URL}/${params.id}/`, payload);
	}

	public getShiftTypes(
		params: {
			organization: number;
		} & PaginationParams
	) {
		const queryString = Params.makeQueryString(params);

		return virtualBrownClient.get<PaginationResponse<ShiftType>>(
			`${BASE_URL}/types/${queryString}`,
			noOpts
		);
	}
}

export const shiftsService = new ShiftsService();
