// reference material: https://insitenext.atlassian.net/wiki/spaces/DT/pages/2131853352/Create+Modal
// *Note - this component only renders your modals so you have full control over it
import { useEffect, useState, useRef } from 'react';
import { createPortal } from 'react-dom';

// helpers
import { ModalStackProps } from './modal-stack.interfaces';
import { modalPositionMap } from './modal-stack.utils';

// interfaces

export const ModalStack = ({
    children,
    position,
    onOverlayClick,
    overlayOpacity,
    hasSpacingY,
}: ModalStackProps) => {
    const [backdropRef, setBackdropRef] = useState<HTMLDivElement | null>(null);
    const modalContentRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        const body = document.querySelector('body');
        const modalContainer = document.createElement('div');
        modalContainer.className =
            'tw-fixed tw-top-0 tw-left-0 tw-h-full tw-w-full tw-overflow-hidden tw-z-50';
        modalContainer.dataset.ds2 = 'modal-stack';
        modalContainer.dataset.position = position;

        const backdrop = document.createElement('div');
        backdrop.className = `tw-absolute tw-h-dvh tw-w-full tw-bg-neutral-900 ${overlayOpacity} tw-backdrop-filter tw-backdrop-blur-sm tw-overflow-x-hidden tw-overflow-y-auto tw-z-[-1] sm:tw-h-full`;

        modalContainer.appendChild(backdrop);
        setBackdropRef(backdrop);

        if (body) {
            body.style.overflowY = 'hidden';
            body.appendChild(modalContainer);
        }

        return () => {
            modalContainer.remove();
            if (body) {
                // we only want to remove the overflow y on the body
                // if there are not more modal stacks in the body
                const modalStacks = body.querySelectorAll(
                    '[data-ds2="modal-stack"]'
                );
                const r4Modal = body.querySelectorAll('#main-modal .modal');

                if (!modalStacks.length && !r4Modal.length) {
                    body.style.overflowY = 'auto';
                }
            }
        };
    }, []);

    useEffect(() => {
        if (!onOverlayClick) {
            return;
        }

        function handleOverlayClick(e: MouseEvent) {
            const target = e.target as HTMLElement;
            if (modalContentRef.current === target) {
                onOverlayClick?.();
            }
        }

        window.addEventListener('mousedown', handleOverlayClick);

        return () => {
            window.removeEventListener('mousedown', handleOverlayClick);
        };
    }, [onOverlayClick]);

    return backdropRef
        ? createPortal(
              <div
                  ref={modalContentRef}
                  className={`${hasSpacingY ? 'sm:tw-py-12' : ''} ${
                      modalPositionMap[position]
                  }`}
              >
                  {children}
              </div>,
              backdropRef
          )
        : null;
};

ModalStack.defaultProps = {
    position: 'top-center',
    overlayOpacity: 'tw-bg-opacity-50',
};
