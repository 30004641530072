import { SummaryChart } from '@models';

export namespace ScorecardsSummary {
	export enum Chart {
		GetSiteEmployeeScorecard = 0,
		GetSiteTeamStats = 1,
		GetEmployeeScorecardHistory = 2,
		GetScorecardMonthlyTrend = 3,
		GetTotalBonusAwarded = 4,
		GetScorecardByOrganization = 6,
	}

	export interface SiteEmployeeScorecard {
		additional_bonus_amount: number;
		attendance_count_to_display: number;
		attendance_goalline: number;
		attendance_pass: boolean;
		audit_score_to_display: number;
		base_bonus_amount: number;
		comment: string;
		complaints_count_to_display: number;
		complaints_goalline: number;
		complaints_pass: boolean;
		conduct_count_to_display: number;
		conduct_goalline: number;
		conduct_pass: boolean;
		contract_name: string;
		customer_name: string;
		earned_bonus: boolean;
		first_name: string;
		hired_date: string;
		id: number;
		inspection_pass: boolean;
		inspections_goalline: number;
		is_attendance_exempt_from_scorecard: boolean;
		is_closed: boolean;
		is_complaints_exempt_from_scorecard: boolean;
		is_conduct_exempt_from_scorecard: boolean;
		is_inspection_exempt_from_scorecard: boolean;
		is_professionalism_exempt_from_scorecard: boolean;
		is_reportits_exempt_from_scorecard: boolean;
		is_safety_exempt_from_scorecard: boolean;
		is_training_exempt_from_scorecard: boolean;
		language_preference: string;
		last_name: string;
		photo_url: string;
		position: string;
		professionalismaudit_pass: boolean;
		reportits_count_to_display: number;
		reportits_goalline: number;
		reportits_pass: boolean;
		safety_count_to_display: number;
		safety_goalline: number;
		safety_pass: boolean;
		scorecard_id: number;
		scorecard_month: string;
		training_goalline: number;
		training_pass: boolean;
		training_percentage_to_display: number;
	}

	export interface SiteEmployeeScorecardSummary
		extends SummaryChart<SiteEmployeeScorecard[]> {
		chart: 'GetSiteEmployeeScorecard';
		quality_average_to_display: number;
		total_additional_bonus_amount: number;
		total_attendance_count_to_display: number;
		total_base_bonus_amount: number;
		total_complaints_count_to_display: number;
		total_conduct_count_to_display: number;
		total_earned_bonus: number;
		total_employee_count: number;
		total_professionalism_count_to_display: number;
		total_reportits_count_to_display: number;
		total_safety_count_to_display: number;
		training_percentage_average_to_display: number;
	}

	export interface SiteTeamStats {
		attendance_count_to_display: number;
		audit_score_to_display: number;
		complaints_count_to_display: number;
		compliments_count_to_display: number;
		conduct_count_to_display: number;
		contract_id: number;
		contract_name: string;
		customer_name: string;
		first_name: string;
		hired_date: string;
		id: number;
		language_preference: string;
		last_name: string;
		photo_url: string;
		position: string;
		professionalismaudit_count: number;
		professionalismaudit_pass: boolean;
		reportits_count_to_display: number;
		safety_count_to_display: number;
		training_percentage_to_display: number;
		unique_compliments_count_to_display: number;
	}

	export interface SiteTeamStatsSummary extends SummaryChart<SiteTeamStats[]> {
		chart: 'GetSiteTeamStats';
		quality_average_to_display: number;
		total_attendance_count_to_display: number;
		total_complaints_count_to_display: number;
		total_compliments_count_to_display: number;
		total_conduct_count_to_display: number;
		total_employee_count: number;
		total_professionalism_count_to_display: number;
		total_reportits_count_to_display: number;
		total_safety_count_to_display: number;
		total_unique_compliments_count_to_display: number;
		training_percentage_average_to_display: number;
	}

	export interface EmployeeScorecardHistory extends SiteEmployeeScorecard {
		additional_bonus_amount: number;
		attendance_count_to_display: number;
		attendance_goalline: number;
		attendance_pass: boolean;
		audit_score_to_display: number;
		base_bonus_amount: number;
		comment: string;
		complaints_count_to_display: number;
		complaints_goalline: number;
		complaints_pass: boolean;
		conduct_count_to_display: number;
		conduct_goalline: number;
		conduct_pass: boolean;
		contract_name: string;
		customer_name: string;
		first_name: string;
		hired_date: string;
		id: number;
		inspection_pass: boolean;
		inspections_goalline: number;
		is_attendance_exempt_from_scorecard: boolean;
		is_closed: boolean;
		is_complaints_exempt_from_scorecard: boolean;
		is_conduct_exempt_from_scorecard: boolean;
		is_inspection_exempt_from_scorecard: boolean;
		is_professionalism_exempt_from_scorecard: boolean;
		is_reportits_exempt_from_scorecard: boolean;
		is_safety_exempt_from_scorecard: boolean;
		is_training_exempt_from_scorecard: boolean;
		last_name: string;
		photo_url: string;
		positions: string;
		professionalismaudit_pass: boolean;
		reportits_count_to_display: number;
		reportits_goalline: number;
		reportits_pass: boolean;
		safety_count_to_display: number;
		safety_goalline: number;
		safety_pass: boolean;
		scorecard_id: number;
		scorecard_month: string;
		training_goalline: number;
		training_pass: boolean;
		training_percentage_to_display: number;
	}

	export interface EmployeeScorecardHistorySummary
		extends SummaryChart<EmployeeScorecardHistory[]> {
		chart: 'GetEmployeeScorecardHistory';
		quality_average_to_display: number;
		total_attendance_count_to_display: number;
		total_bonus: number;
		total_complaints_count_to_display: number;
		total_conduct_count_to_display: number;
		total_earned_bonus: number;
		total_failed_professionalism_count_to_display: number;
		total_reportits_count_to_display: number;
		total_safety_count_to_display: number;
		training_percentage_average_to_display: number;
	}

	export interface ScorecardMonthlyTrend {
		fail_count: number;
		incomplete_count: number;
		month: string;
		pass_count: number;
	}

	export interface ScorecardMonthlyTrendSummary
		extends SummaryChart<ScorecardMonthlyTrend[]> {
		chart: 'GetScorecardMonthlyTrend';
		number: '3';
	}

	export interface TotalBonusAwarded {
		scorecard_count: number;
		total_bonus_awarded: number;
	}

	export interface TotalBonusAwardedSummary
		extends SummaryChart<TotalBonusAwarded> {
		chart: 'GetTotalBonusAwarded';
		number: '4';
	}

	export interface ScorecardByOrganization {
		customer_id: number;
		customer_name: string;
		total_attendance_count: number;
		total_audit_score: number;
		total_complaints_count: number;
		total_conduct_count: number;
		total_reportits_count: number;
		total_safety_count: number;
	}

	export interface ScorecardByOrganizationSummary
		extends SummaryChart<ScorecardByOrganization[]> {
		chart: 'GetScorecardByOrganization';
		number: '6';
	}

	export interface SummaryReturnType {
		[Chart.GetSiteEmployeeScorecard]: SiteEmployeeScorecardSummary;
		[Chart.GetSiteTeamStats]: SiteTeamStatsSummary;
		[Chart.GetEmployeeScorecardHistory]: EmployeeScorecardHistorySummary;
		[Chart.GetScorecardMonthlyTrend]: ScorecardMonthlyTrendSummary;
		[Chart.GetTotalBonusAwarded]: TotalBonusAwardedSummary;
		[Chart.GetScorecardByOrganization]: ScorecardByOrganizationSummary;
	}
}
