export const youtubeUrlRegex =
	/(?:https?:\/\/)?(?:www\.)?youtu\.?be(?:\.com)?\/?.*(?:watch|embed)?(?:.*v=|v\/|\/)([\w\-_]+)\&?/;
export const vimeoUrlRegex =
	/(?:https?:\/\/)?(www.)?(player.)?vimeo.com\/([a-z]*\/)*([0-9]{6,11})[?]?.*/;
export const sbmThinkzoomUrlRegex =
	/(?:https?:\/\/)?(sbm.)?thinkzoom.com\/([a-z]*\/)*([0-9])[?]?.*/;
export const imageFileRegex =
	/(\.jpg|\.jpeg|\.png|\.gif|\.apng|\.svg|\.bmp|\.ico)$/i;
export const urlRegex =
	/((?<=\s|^)(?:https?:\/\/)?(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b[-a-zA-Z0-9()@:%_+.~#?&/=]*\b)/;
