import { Observable } from 'rxjs';

// interfaces
import { map } from 'rxjs/operators';

import {
	GetEmployeeParams,
	Employee,
	SimpleEmployeeDetails,
	EmployeePayload,
	ReferenceItem,
	TeamMember,
} from '@api-interfaces';
import { virtualBrownClient } from '@core/services/django-client';
import { Params } from '@helpers';
import { CommonParams, PaginationResponse } from '@models';

// services

import { employeesHierarchyService } from './hierarchy';
import { employeesSummaryService } from './summary';

// helpers

// operators

// constants
const BASE_URL = 'employees';
const noOpts = {
	noContract: true,
	noCustomer: true,
	noTag: true,
};

class EmployeesService {
	readonly summary = employeesSummaryService;

	readonly hierarchy = employeesHierarchyService;

	/**
	 * Provider Employees: (default)
	 * Omit customer because we want service provider's employees not the customer's employees (managers).
	 * In the future we might actually need to provide the provider id if we end up handling multi providers
	 */
	public getEmployees(
		params: GetEmployeeParams & CommonParams,
		options?: {
			noContract?: boolean;
			noCustomer?: boolean;
			noTag?: boolean;
			noProvider?: boolean;
		}
	) {
		const defaultOptions = {
			noCustomer: true,
			noContract: !!params.contract,
		};

		if (
			params.export_to_excel
				? !params.export_to_excel
				: !params.hasOwnProperty('limit') || !params.limit
		) {
			params.limit = 1000;
		}

		const queryString = Params.makeQueryString({ ...params });

		return virtualBrownClient.get<PaginationResponse<Employee>>(
			`${BASE_URL}/${queryString}`,
			options || defaultOptions
		);
	}

	public adminGetEmployees(
		params: {
			ordering?: string;
			terminated?: boolean;
			managers?: boolean;
		} & CommonParams
	) {
		if (!params.hasOwnProperty('limit') || !params.limit) {
			params.limit = 1000;
		}
		const queryString = Params.makeQueryString({ ...params });

		return virtualBrownClient.get<PaginationResponse<Employee>>(
			`${BASE_URL}/${queryString}`,
			{
				noCustomer: true,
				noContract: true,
				noTag: true,
			}
		);
	}

	getEmployeeById(
		employeeId: number,
		params: { detail_level: 'simple' }
	): Observable<SimpleEmployeeDetails>;

	getEmployeeById(employeeId: number): Observable<Employee>;

	getEmployeeById(
		employeeId: number,
		params?: { detail_level?: 'simple' }
	): Observable<Employee | SimpleEmployeeDetails> {
		return virtualBrownClient.get(
			`${BASE_URL}/${employeeId}/${Params.makeQueryString(params || {})}`,
			{
				noCustomer: true,
				noContract: true,
				noTag: true,
			}
		);
	}

	public getFullEmployeeById = (employeeId: number): Observable<any> =>
		virtualBrownClient.get<Employee>(
			`${BASE_URL}/${employeeId}/?detail_level=full`,
			{
				noCustomer: true,
				noContract: true,
				noTag: true,
			}
		);

	public getEmployeeByEDBID = (
		edbId: number,
		detail_level: 'person_position'
	): Observable<any> =>
		virtualBrownClient
			.get<PaginationResponse<Employee>>(
				`${BASE_URL}/?correlation_id=${edbId}&limit=200&detail_level=${detail_level}`,
				{
					noCustomer: true,
				}
			)
			// we want contract employees not customer employees so need to omit customer
			// should only expect one employee to come back since only 1 id should be correlated
			.pipe(
				map((res) => {
					if (res && res.results && res.results.length) {
						return res.results[0];
					}
					return null;
				})
			);

	/**
	 * Customer Employees:
	 * These are employees that have a contract role at the customers' contracts or at the contracts,
	 * rather than the service providers's or customer provider's employee of a contract.
	 * We do this by adding the customer or contract parameter to our /employees/contract_role request.
	 * We should be omitting provider from this call.
	 */
	public getCustomerEmployees(
		params: {
			organization?: number;
			email_address?: string;
		} & CommonParams,
		options?: {
			noContract?: boolean;
			noCustomer?: boolean;
			noTag?: boolean;
		}
	) {
		const defaultOptions = {
			noTag: true,
		};

		if (!params.hasOwnProperty('limit') || !params.limit) {
			params.limit = 1000;
		}

		const queryString = Params.makeQueryString({ ...params });

		return virtualBrownClient.get<PaginationResponse<Employee>>(
			`${BASE_URL}/contract_role/${queryString}`,
			options || defaultOptions
		);
	}

	public createEmployee = (payload: EmployeePayload): Observable<Employee> =>
		virtualBrownClient.post<Employee>(`${BASE_URL}/`, payload);

	// Creates an employee at the designated organization if there is no employee, otherwise sets the contract role of a found employee
	public createCustomerEmployee = (
		payload: EmployeePayload
	): Observable<Employee & { is_new: boolean }> =>
		virtualBrownClient.post<Employee & { is_new: boolean }>(
			`${BASE_URL}/contract_role/`,
			payload
		);

	public updateEmployee(params: { id: number; payload: EmployeePayload }) {
		return virtualBrownClient.put<Employee>(
			`${BASE_URL}/${params.id}/`,
			params.payload
		);
	}

	public patchEmployee(params: { id: number; payload: EmployeePayload }) {
		return virtualBrownClient.patch<Employee>(
			`${BASE_URL}/${params.id}/`,
			params.payload
		);
	}

	public deleteEmployee(params: { id: number }) {
		return virtualBrownClient.delete(`${BASE_URL}/${params.id}/`);
	}

	public updatePassword = (id, password) => {
		return virtualBrownClient.put(`accounts/setPassword/${id}/`, {
			password,
		});
	};

	public getEmployeePositionsEquipment = (
		employeeId: number,
		positionId: number
	) => {
		return virtualBrownClient.get<
			PaginationResponse<{ id: number; equipment: ReferenceItem[] }>
		>(`${BASE_URL}/${employeeId}/positions/${positionId}/equipment/`, {
			noCustomer: true,
			noContract: true,
		});
	};

	public addEmployeePositionsEquipment = (
		{ employeeId, positionId }: { employeeId: number; positionId: number },
		payload: { equipment: Array<{ id: number }> }
	): Observable<any> =>
		virtualBrownClient.post(
			`${BASE_URL}/${employeeId}/positions/${positionId}/equipment/`,
			payload
		);

	public deleteEmployeePositionsEquipment = ({
		employeeId,
		positionId,
		equipmentId,
	}: {
		employeeId: number;
		positionId: number;
		equipmentId: number;
	}): Observable<any> =>
		virtualBrownClient.delete(
			`${BASE_URL}/${employeeId}/positions/${positionId}/equipment/${equipmentId}/`
		);

	$getEmployees(
		params: {
			organization: number;
			corporate: boolean;
		} & CommonParams
	): Observable<PaginationResponse<TeamMember>>;

	$getEmployees(
		params?: {
			ordering?: string;
			terminated?: boolean;
			organization?: number;
			area?: number;
			contract?: number | string;
			customer?: number | string;
			field_set?: string;
			position?: number | string;
			detail_level?:
				| 'person_only'
				| 'person_position'
				| 'full'
				| 'has_me_video'
				| 'routes'
				| 'position_profiles'
				| 'for_dropdown'
				| 'manager'
				| 'turnover';
			managers?: boolean;
			contract_role?: number;
			tag?: number | string;
			dept_name?: string;
			use_ifm?: boolean;
			is_survey_employee?: boolean;
		} & CommonParams
	): Observable<PaginationResponse<Employee>>;

	$getEmployees(
		params?: {
			ordering?: string;
			terminated?: boolean;
			organization?: number;
			area?: number;
			contract?: number | string;
			customer?: number | string;
			field_set?: string;
			position?: number | string;
			detail_level?:
				| 'person_only'
				| 'person_position'
				| 'full'
				| 'has_me_video'
				| 'routes'
				| 'position_profiles'
				| 'for_dropdown'
				| 'manager'
				| 'turnover';
			managers?: boolean;
			contract_role?: number;
			tag?: number | string;
			dept_name?: string;
		} & CommonParams
	): Observable<PaginationResponse<Employee | TeamMember>> {
		return virtualBrownClient.get(
			`${BASE_URL}/${Params.makeQueryString(params || {})}`,
			noOpts
		);
	}

	public saveEmployeePositionsSignature = (
		{ employeeId, positionId }: { employeeId: number; positionId: number },
		payload: { media: number; media_type: string }
	): Observable<any> =>
		virtualBrownClient.post(
			`${BASE_URL}/${employeeId}/positions/${positionId}/media/`,
			payload
		);

	public deleteEmployeePositionsSignature = (
		employeeId: number,
		positionId: number,
		signatureId: number
	): Observable<any> =>
		virtualBrownClient.delete(
			`${BASE_URL}/${employeeId}/positions/${positionId}/media/${signatureId}/`
		);

	public getEmployeePositionsSignature = (
		employeeId: number,
		positionId: number
	): Observable<any> => {
		return virtualBrownClient.get(
			`${BASE_URL}/${employeeId}/positions/${positionId}/media/`,
			{
				noCustomer: true,
				noContract: true,
			}
		);
	};
}

export const employeesService = new EmployeesService();
