import { forwardRef } from 'react';

// components
import { ErrorText } from '../error-text';
import { InputTime } from '../input-time';
import { Label } from '../label';

// interfaces
import { FieldInputTimeProps } from './field-input-time.interfaces';

export const FieldInputTime = forwardRef<HTMLInputElement, FieldInputTimeProps>(
  ({ id, label, labelFont, error, required, ...props }, ref) => (
    <fieldset data-ds2="field-input-time" className="tw-w-full tw-space-y-1.5">
      <Label
        htmlFor={id}
        label={label}
        font={labelFont}
        className="tw-leading-4"
        required={required}
      />
      <InputTime id={id} ref={ref} error={!!error} {...props} />
      <ErrorText error={error} />
    </fieldset>
  )
);

FieldInputTime.defaultProps = {
  labelFont: 'body-sm',
};
