import { Observable } from 'rxjs';

import {
	ServiceValidationV2CountsParams,
	ServiceValidationV2CountsResponse,
	ServiceValidationV2DailyLog,
	ServiceValidationV2DailyLogParams,
	ServiceValidationV2ServiceLog,
	ServiceValidationV2ServiceLogParams,
} from '@api-interfaces';
import { Params, TimeUtils } from '@helpers';
import { PaginationParams, PaginationResponse } from '@models';
import { virtualBrownClient } from '@services';

const BASE_URL = 'servicevalidation/v2';

class V2ServiceValidationService {
	private paramsWithDateRange(
		params: Pick<ServiceValidationV2CountsParams, 'from' | 'to'>
	) {
		return Params.makeQueryString({
			...params,
			from: TimeUtils.format(params.from, 'YYYY-MM-DD'),
			to: TimeUtils.format(TimeUtils.addDays(params.to, 1), 'YYYY-MM-DD'),
		});
	}

	public getCounts(
		params: ServiceValidationV2CountsParams
	): Observable<ServiceValidationV2CountsResponse> {
		const queryString = this.paramsWithDateRange(params);
		return virtualBrownClient.get(`${BASE_URL}/counts/${queryString}`, {
			noContract: !!params.contract,
			noCustomer: !!params.customer,
		});
	}

	public getDailyLog(
		params: ServiceValidationV2DailyLogParams
	): Observable<ServiceValidationV2DailyLog[]> {
		const queryString = this.paramsWithDateRange(params);
		return virtualBrownClient.get(`${BASE_URL}/daily_log/${queryString}`, {
			noContract: true,
			noCustomer: true,
		});
	}

	public getServiceLog(
		params: ServiceValidationV2ServiceLogParams & PaginationParams
	): Observable<PaginationResponse<ServiceValidationV2ServiceLog>> {
		const queryString = Params.makeQueryString({
			...params,
			from: TimeUtils.format(params.from, 'YYYY-MM-DD'),
			to: TimeUtils.format(params.to, 'YYYY-MM-DD'),
		});
		return virtualBrownClient.get(
			`${BASE_URL}/service_log/${queryString}`,
			{
				noContract: true,
				noCustomer: true,
			}
		);
	}
}

export const v2ServiceValidationService = new V2ServiceValidationService();
