import { Formik, FormikActions } from 'formik';
import { Component } from 'react';

// services
import { isPossiblePhoneNumber } from 'react-phone-number-input/input';
import { forkJoin } from 'rxjs';

import {
    Compliment,
    Employee,
    ServiceArea,
    ReferenceItem,
    Shift,
} from '@api-interfaces';
import {
    complimentsService,
    employeesService,
    serviceAreasService,
    referenceService,
    shiftsService,
} from '@apis';

// helpers
import { Text } from '@atoms';
import { Dropdown } from '@atoms/form-ds2/dropdown';
import { DropdownItem } from '@atoms/form-ds2/dropdown/dropdown.interfaces';
import { ErrorText } from '@atoms/form-ds2/error-text';
import { FieldDropdown } from '@atoms/form-ds2/field-dropdown';
import { FieldInputDate } from '@atoms/form-ds2/field-input-date';
import {
    getFullName,
    sendError,
    getLocations,
    TimeUtils,
    Locations,
} from '@helpers';
import { useAppContext } from '@hooks';

// components
import { Button } from '@new';
import { toasterService, modalService, refetchService } from '@services';

import { NewCustomer } from '../NewCustomer';

import { Label } from '@atoms/form-ds2/label';
import { FieldInputText } from '@atoms/form-ds2/field-input-text';
import { FieldInputPhone } from '@atoms/form-ds2/field-input-phone';
import { FieldMultiSelect } from '@atoms/form-ds2/field-multi-select';

// interfaces
import { App } from '@core/app-context/app.interfaces';
import {
    mixpanelCreateQualityCompliment,
    mixpanelEditQualityCompliment,
} from '@app/mixpanel/MixpanelPageTrack.tsx';

type ServiceAreaDropdownItem = DropdownItem<ServiceArea>;
type EmployeeDropdownItem = DropdownItem<Employee>;

interface State {
    buildings: ServiceAreaDropdownItem[];
    floors: ServiceAreaDropdownItem[];
    complimentTypes: DropdownItem<ReferenceItem>[];
    employees: EmployeeDropdownItem[];
    customers: EmployeeDropdownItem[];
    displayAddNewCustomer: boolean;
    shifts?: Shift[];
}

interface Props {
    selectedProgram?: {
        id: number;
        name: string;
    };
    selectedEmployee?: Employee;
    compliment?: Compliment;
}

class ComplimentFormComponent extends Component<Props & App.State, State> {
    public state: State = {
        buildings: [],
        floors: [],
        complimentTypes: [],
        employees: [],
        customers: [],
        displayAddNewCustomer: false,
        shifts: [],
    };

    public componentDidMount() {
        const contract =
            this.props.compliment?.contract?.id ??
            this.props.selectedContracts?.[0]?.id;

        forkJoin([
            referenceService.getItems({ group_code: 'Compliment_Types' }),
            serviceAreasService.getServiceAreas({
                contract,
                quick_landing: true,
            }),
            employeesService.getEmployees({
                contract,
                detail_level: 'for_dropdown',
            }),
            employeesService.getCustomerEmployees({}),
        ]).subscribe({
            next: ([
                complimentTypesRes,
                buildingsRes,
                employeesRes,
                customersRes,
            ]) => {
                const complimentTypes = complimentTypesRes.map((type) => ({
                    ...type,
                    name: type.display_name,
                }));
                const employees = employeesRes.results.map((employee) => ({
                    ...employee,
                    name: getFullName(employee.person),
                    subtext: employee.person.email_address,
                }));
                const customers = customersRes.results.map((customer) => ({
                    ...customer,
                    name: getFullName(customer.person),
                    subtext: `${customer.person.email_address} - ${customer.organization.name}`,
                }));

                this.setState({
                    complimentTypes,
                    employees,
                    customers,
                    buildings: buildingsRes.results,
                });
            },
            error: sendError(),
        });
        if (this.props.compliment) {
            const locations = getLocations(
                this.props.compliment.location_of_compliment
            );
            if (locations.building) {
                this.getFloorList(locations.building);
            }
        }
        this.fetchShifts();
    }

    private handleSuccess = (message: string) => {
        toasterService.newToast({
            status: 'success',
            message,
        });
        refetchService.fetch('compliments');
        modalService.closeAll();
    };

    public submitForm = (
        values: {
            complimentType: DropdownItem<ReferenceItem>;
            description: string;
            date: Date;
            customerPhoneNumber: string;
            selectedEmployees: EmployeeDropdownItem[];
            customer: EmployeeDropdownItem;
            shifts: Shift[];
        } & Locations,
        { setSubmitting }: FormikActions<any>
    ) => {
        const payload = {
            ...this.props.compliment,
            contract: {
                id:
                    this.props.compliment?.contract?.id ??
                    this.props.selectedContracts?.[0]?.id,
            },
            date_of_compliment: values.date,
            employees: values.selectedEmployees.map((e) => ({ id: e.id })),
            submitter: {
                id: values.customer.id,
            },
            creator: {
                id: values.customer.id,
            },
            location_of_compliment: {
                id: values.floor ? values.floor.id : values.building.id,
            },
            service: {
                id:
                    this.props.compliment?.service?.id ||
                    this.props.selectedProgram?.id,
            },
            shifts: values.shifts.map((shift) => ({ id: shift.id })),
            type: { id: values.complimentType.id },
            phone_number: values.customerPhoneNumber,
            comments: values.description,
        };

        if (this.props.compliment) {
            complimentsService
                .updateCompliment(this.props.compliment.id, payload)
                .subscribe({
                    next: () => {
                        mixpanelEditQualityCompliment();
                        this.handleSuccess(
                            'The compliment was successfully updated.'
                        );
                    },
                    error: sendError({
                        toastMessage:
                            'There was an error updating the compliment.',
                        callback: () => {
                            setSubmitting(false);
                        },
                    }),
                });
        } else {
            complimentsService.createCompliment(payload).subscribe({
                next: () => {
                    mixpanelCreateQualityCompliment();
                    this.handleSuccess(
                        'The compliment was successfully created.'
                    );
                },
                error: sendError({
                    toastMessage: 'There was an error creating the compliment.',
                    callback: () => {
                        setSubmitting(false);
                    },
                }),
            });
        }
    };

    public getFloorList = (building: ServiceAreaDropdownItem) => {
        serviceAreasService
            .getServiceAreas({ parent: building.id, quick_landing: true })
            .subscribe({
                next: (res) => {
                    this.setState({
                        ...this.state,
                        floors: res.results,
                    });
                },
                error: sendError(),
            });
    };

    private fetchShifts = () => {
        shiftsService
            .getShifts(
                {
                    limit: 1000,
                    offset: 0,
                    ...(!!this.props.selectedCustomers.length && {
                        customer: this.props.selectedCustomers
                            .map((client) => client.id)
                            .join(','),
                    }),
                    ...(!!this.props.selectedContracts.length && {
                        contract: this.props.selectedContracts
                            .map((site) => site.id)
                            .join(','),
                    }),
                },
                { noOptions: true }
            )
            .subscribe({
                next: ({ results: Shifts }) => {
                    this.setState({ shifts: Shifts ?? [] });
                },
                error: sendError({
                    toastMessage:
                        'There was an error retrieving Shifts type data',
                }),
            });
    };

    private fetchEmployees = (shifts: Shift[]) => {
        let updatedEmployees: Employee[];
        employeesService
            .getEmployees({
                limit: 1000,
                contract: this.props?.selectedContracts?.[0]?.id,
                detail_level: 'for_dropdown',
            })
            .subscribe({
                next: (employees) => {
                    if (!employees?.results?.length) {
                        return;
                    }
                    // filter employees by selected shifts
                    if (shifts.length) {
                        updatedEmployees = employees.results.filter(
                            (employee) =>
                                shifts?.some(
                                    (shift) =>
                                        shift.id === employee?.shifts?.[0]?.id
                                )
                        );
                        this.setState({
                            employees: updatedEmployees?.map((employee) => ({
                                ...employee,
                                name: getFullName(employee.person),
                                subtext: employee.person.email_address,
                            })),
                        });
                    } else {
                        this.setState({
                            employees: employees.results.map((employee) => ({
                                ...employee,
                                name: getFullName(employee.person),
                                subtext: employee.person.email_address,
                            })),
                        });
                    }
                },
                error: sendError(),
            });
    };

    public render() {
        return (
            <Formik
                initialValues={{
                    complimentType: this.props.compliment?.type
                        ? {
                              ...this.props.compliment.type,
                              name: this.props.compliment.type.display_name,
                          }
                        : null,
                    customer: this.props.compliment?.creator
                        ? {
                              ...this.props.compliment.creator,
                              name: getFullName(
                                  this.props.compliment.creator.person
                              ),
                              subtext:
                                  this.props.compliment.creator.person
                                      .email_address,
                          }
                        : null,
                    customerPhoneNumber:
                        this.props.compliment?.phone_number ?? '',
                    description: this.props.compliment?.comments ?? '',
                    date: this.props.compliment?.date_of_compliment
                        ? new Date(this.props.compliment.date_of_compliment)
                        : new Date(),
                    selectedEmployees: this.props.compliment
                        ? this.props.compliment.employees.map((emp) => ({
                              ...emp,
                              name: getFullName(emp.person),
                              subtext: emp.person.email_address,
                          }))
                        : this.props.selectedEmployee
                        ? [
                              {
                                  ...this.props.selectedEmployee,
                                  name: getFullName(
                                      this.props.selectedEmployee.person
                                  ),
                                  subtext:
                                      this.props.selectedEmployee.person
                                          .email_address,
                              },
                          ]
                        : [],
                    ...getLocations(
                        this.props.compliment?.location_of_compliment
                    ),
                    shifts: this.props.compliment?.shifts ?? [],
                }}
                validate={(values) => {
                    const errors: { [Key in keyof typeof values]: string } =
                        {} as any;
                    if (!values.customer) {
                        errors.customer = 'A customer is required.';
                    }
                    if (!values.description) {
                        errors.description = 'A description is required.';
                    }
                    if (!values.complimentType) {
                        errors.complimentType =
                            'A compliment type is required.';
                    }

                    if (!values.building) {
                        errors.building = 'A building is required.';
                    }

                    if (!values.selectedEmployees.length) {
                        errors.selectedEmployees = 'An employee is required.';
                    }

                    if (
                        values.customerPhoneNumber.length &&
                        !isPossiblePhoneNumber(values.customerPhoneNumber)
                    ) {
                        errors.customerPhoneNumber =
                            'Please enter a number in a valid format i.e. (123) 456-7890';
                    }

                    return errors;
                }}
                onSubmit={(values, formikActions) => {
                    this.submitForm(values, formikActions);
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                }) => (
                    <form
                        className="tw-space-y-6 tw-p-4 sm:tw-p-6"
                        data-testid="compliment-form"
                        onSubmit={handleSubmit}
                    >
                        <Text font="h2" color="hi-contrast">
                            Enter a New Compliment
                        </Text>
                        <fieldset className="tw-space-y-1.5">
                            <div className="tw-flex tw-justify-between">
                                <Label required label="Customer" />
                                <div
                                    className="t-text-link t-text-medium"
                                    onClick={() =>
                                        this.setState({
                                            displayAddNewCustomer: true,
                                        })
                                    }
                                >
                                    + NEW CUSTOMER
                                </div>
                            </div>

                            <Dropdown
                                data-testid="customerField"
                                items={this.state.customers}
                                selectedItem={values.customer}
                                onChange={(customer) => {
                                    setFieldValue('customer', customer);
                                }}
                                error={touched.customer && !!errors.customer}
                            />
                            {this.state.displayAddNewCustomer && (
                                <NewCustomer
                                    methods={{
                                        callback: (emp: Employee) => {
                                            const employee = {
                                                ...emp,
                                                name: getFullName(emp.person),
                                                subtext: `${emp.person.email_address} - ${emp.organization.name}`,
                                            };
                                            this.setState(
                                                {
                                                    displayAddNewCustomer:
                                                        false,
                                                    customers: [
                                                        ...this.state.customers,
                                                        employee,
                                                    ],
                                                },
                                                () => {
                                                    setFieldValue(
                                                        'submitter',
                                                        employee
                                                    );
                                                }
                                            );
                                        },
                                        closeForm: () =>
                                            this.setState({
                                                displayAddNewCustomer: false,
                                            }),
                                    }}
                                />
                            )}
                            <ErrorText
                                error={
                                    touched.customer &&
                                    (errors.customer as string)
                                }
                            />
                        </fieldset>

                        <div className="tw-grid tw-gap-6 sm:tw-grid-cols-2">
                            <FieldInputDate
                                data-testid="dateField"
                                required
                                disableFuture
                                id="compliment_date"
                                label="Compliment Date"
                                value={TimeUtils.format(
                                    values.date,
                                    'MM/DD/YYYY'
                                )}
                                onChange={(date) => {
                                    setFieldValue('date', date);
                                }}
                            />

                            <FieldDropdown
                                data-testid="complimentTypeField"
                                required
                                id="compliment-type"
                                label="Compliment Type"
                                items={this.state.complimentTypes}
                                selectedItem={values.complimentType}
                                onChange={(type) => {
                                    setFieldValue('complimentType', type);
                                }}
                                disabled={!this.state.complimentTypes.length}
                                error={
                                    touched.complimentType &&
                                    (errors.complimentType as string)
                                }
                            />

                            <div className="tw-col-span-full">
                                <FieldInputText
                                    data-testid="descriptionField"
                                    required
                                    fieldType="textarea"
                                    id="description"
                                    name="description"
                                    label="Description"
                                    placeholder="Enter description"
                                    error={
                                        touched.description &&
                                        errors.description
                                    }
                                    onChange={handleChange}
                                    defaultValue={values.description}
                                    rows={3}
                                />
                            </div>

                            <div className="tw-col-span-full">
                                <FieldMultiSelect
                                    data-testid="shiftsField"
                                    id="shifts"
                                    label="Shifts"
                                    items={this.state?.shifts}
                                    selectedItems={values.shifts}
                                    onChange={(shifts) => {
                                        setFieldValue('shifts', shifts);
                                        this.fetchEmployees(shifts as Shift[]);
                                        // when reducing number of selected shifts, unselect all employees
                                        shifts.length < values.shifts.length &&
                                            setFieldValue(
                                                'selectedEmployees',
                                                []
                                            );
                                    }}
                                />
                            </div>

                            <FieldInputPhone
                                data-testid="customerPhoneField"
                                id="customerPhoneNumber"
                                name="customerPhoneNumber"
                                label="Phone Number"
                                placeholder="(XXX)-XXX-XXXX"
                                onChange={(number) => {
                                    setFieldValue(
                                        'customerPhoneNumber',
                                        number
                                    );
                                }}
                                onBlur={handleBlur}
                                value={values.customerPhoneNumber}
                                error={
                                    touched.customerPhoneNumber &&
                                    errors.customerPhoneNumber
                                }
                            />
                            <FieldMultiSelect
                                data-testid="employeesField"
                                hasSelectAll
                                required
                                id="employees"
                                name="employees"
                                label="Employees"
                                items={this.state.employees}
                                selectedItems={values.selectedEmployees}
                                selectedCountLabel="employee"
                                onChange={(
                                    selectedEmployees: typeof this.state.employees
                                ) =>
                                    setFieldValue(
                                        'selectedEmployees',
                                        selectedEmployees
                                    )
                                }
                                error={
                                    touched.selectedEmployees &&
                                    (errors.selectedEmployees as unknown as string)
                                }
                            />
                            <FieldDropdown
                                data-testid="buildingField"
                                required
                                id="building"
                                label="Building"
                                items={this.state.buildings}
                                selectedItem={values.building}
                                onChange={(
                                    building: ServiceAreaDropdownItem
                                ) => {
                                    this.getFloorList(building);
                                    setFieldValue('building', building);
                                    setFieldValue('floor', null);
                                }}
                                error={
                                    touched.building &&
                                    (errors.building as string)
                                }
                            />
                            <FieldDropdown
                                data-testid="floorField"
                                id="floor"
                                label="Floor"
                                items={this.state.floors}
                                selectedItem={values.floor}
                                onChange={(floor) => {
                                    setFieldValue('floor', floor);
                                }}
                                disabled={!values.building}
                            />
                        </div>

                        <div className="tw-flex tw-justify-end">
                            <Button
                                label="Submit"
                                onClick={handleSubmit}
                                disabled={isSubmitting}
                            />
                        </div>
                    </form>
                )}
            </Formik>
        );
    }
}

export const ComplimentForm = (props: Props) => {
    const { state } = useAppContext();
    return <ComplimentFormComponent {...state} {...props} />;
};
