// interfaces
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
	Attendance,
	AttendanceIssueOrderingParams,
	AttendanceIssueByEmployee,
	AttendancePayload,
} from '@api-interfaces';

// services
import { TimeUtils, Params } from '@core/helpers';
import { virtualBrownClient } from '@core/services/django-client';

// helpers

// operators

import { CommonParams, PaginationParams, PaginationResponse } from '@models';

const defaultTo = TimeUtils.formatToISO(new Date());
const defaultFrom = TimeUtils.formatToISO(TimeUtils.subMonths(new Date(), 6));

const zone = Intl.DateTimeFormat().resolvedOptions().timeZone;

// constants
const BASE_URL = 'attendanceissues';

class AttendanceIssuesService {
	getAttendance = (
		params: PaginationParams & {
			from: Date;
			to: Date;
			ordering?: string;
			search?: string;
			export_to_excel?: boolean;
		}
	) => {
		const qs = Params.makeQueryString(params);
		return virtualBrownClient.get<PaginationResponse<Attendance>>(
			`${BASE_URL}/${qs}`
		);
	};

	public getAttendance2 = (
		params: {
			issue_employee?: string | number;
		} & CommonParams
	) => {
		const query = params;
		query.from = params.from ? TimeUtils.formatToISO(params.from) : undefined;
		query.to = params.to ? TimeUtils.formatToISO(params.to) : undefined;

		const queryString = Params.makeQueryString(query);

		return virtualBrownClient.get<PaginationResponse<Attendance>>(
			`${BASE_URL}/${queryString}`
		);
	};

	updateAttendance = (id, body, options?): Observable<Attendance> =>
		virtualBrownClient.put(`${BASE_URL}/${id}/`, body, options);

	patchAttendance = (id, body, options?): Observable<Attendance> =>
		virtualBrownClient.patch(`${BASE_URL}/${id}/`, body, options);

	createAttendance = (
		body: AttendancePayload,
		options?
	): Observable<Attendance> => {
		const payload = {
			...body,
			date_of_occurrence: body.date_of_occurrence.toISOString(),
		};
		return virtualBrownClient.post(`${BASE_URL}/`, payload, options);
	};

	deleteAttendance = (id: number): Observable<any> =>
		virtualBrownClient.delete(`${BASE_URL}/${id}/`);

	getAllCharts = (to = defaultTo, from = defaultFrom, time_zone = zone) => {
		const query1 = {
			to,
			from,
			time_zone,
			chart: '0,1,2,4',
		};
		const qs1 = Params.makeQueryString(query1);

		return virtualBrownClient.get(`${BASE_URL}/summary/${qs1}`);
	};

	getCounts = (
		to = defaultTo,
		from = defaultFrom,
		time_zone = zone,
		filter = {}
	) => {
		const query = {
			to,
			from,
			time_zone,
			chart: '0',
			...filter,
		};
		const qs = Params.makeQueryString(query);
		return virtualBrownClient.get(`${BASE_URL}/summary/${qs}`).pipe(
			map((res: any) =>
				res && res.length
					? {
							...res[0].results,
							updatedAt: TimeUtils.isValid(new Date(res[0].most_recent_record))
								? new Date(res[0].most_recent_record)
								: new Date(),
					  }
					: {
							total_count: 0,
							absent_count: 0,
							partial_day_count: 0,
							updatedAt: new Date(),
					  }
			)
		);
	};

	issuesByEmployee = (
		params: {
			from: string;
			to: string;
			limit?: number;
			order_by?: AttendanceIssueOrderingParams;
			time_zone?: string | number;
		} & PaginationParams = {
			from: defaultFrom,
			to: defaultTo,
			time_zone: zone,
			order_by: 'name',
		}
	): Observable<AttendanceIssueByEmployee[]> => {
		const qs = Params.makeQueryString(params);
		return virtualBrownClient
			.get(`${BASE_URL}/employees/${qs}`, { noCustomer: true })
			.pipe(
				map((res: PaginationResponse<AttendanceIssueByEmployee>) =>
					res ? res.results : []
				)
			);
	};

	public getChart(from, to, time_zone, chart) {
		const query = {
			to,
			from,
			time_zone,
			chart,
		};
		const params = Params.makeQueryString(query);

		return virtualBrownClient.get(`${BASE_URL}/summary/${params}`);
	}

	getAttendanceById = (id: number) =>
		virtualBrownClient.get(`${BASE_URL}/${id}`);

	getMonthlyTrends = (to = defaultTo, from = defaultFrom, time_zone = zone) => {
		const query = {
			to,
			from,
			time_zone,
			chart: '1',
		};
		const qs = Params.makeQueryString(query);
		return virtualBrownClient.get(`${BASE_URL}/summary/${qs}`).pipe(
			map((res: any) =>
				res && res.length
					? res[0].results
					: {
							month: 2,
							year: 2019,
							sickness_or_medical: 0,
							vacation: 0,
							other: 0,
					  }
			)
		);
	};

	getAttendanceByDay = (
		to = defaultTo,
		from = defaultFrom,
		time_zone = zone
	) => {
		const query = {
			to,
			from,
			time_zone,
			chart: '6',
		};
		const qs = Params.makeQueryString(query);
		return virtualBrownClient.get(`${BASE_URL}/summary/${qs}`).pipe(
			map((res) => {
				return res;
			})
		);
	};

	getAttendanceByShift = (params: { from: string; to: string }) => {
		const query = {
			...params,
			chart: '7',
		};
		const qs = Params.makeQueryString(query);
		return virtualBrownClient.get(`${BASE_URL}/summary/${qs}`).pipe(
			map((res) => {
				return res;
			})
		);
	};

	getAttendanceByShiftType = (params: { from: string; to: string }) => {
		const query = {
			...params,
			chart: '8',
		};
		const qs = Params.makeQueryString(query);
		return virtualBrownClient.get(`${BASE_URL}/summary/${qs}`).pipe(
			map((res) => {
				return res;
			})
		);
	};
}

export const attendanceIssuesService = new AttendanceIssuesService();
