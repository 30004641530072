import { forwardRef } from 'react';
import * as React from 'react';

// components
import { MenuItem } from '@atoms';
import { useAppContext } from '@core/hooks';

import { MenuProps } from './menu.interfaces';

export const Menu = forwardRef(
  (
    { className, menuItems, ...props }: MenuProps,
    ref: React.MutableRefObject<HTMLUListElement>
  ) => {
    const {
      state: { user },
    } = useAppContext();
    return (
      <ul
        data-ds2="menu"
        ref={ref}
        role="menu"
        className={`tw-rounded tw-bg-neutral-100-800 tw-text-neutral-900-100 tw-shadow-md tw-overflow-hidden ${className}`}
        {...props}
      >
        {menuItems.map((menuItem, index) => {
          if (menuItem?.permission !== undefined) {
            return (
              user.hasPermission(1, menuItem?.permission) && (
                <MenuItem key={index} {...menuItem} />
              )
            );
          }
          return <MenuItem key={index} {...menuItem} />;
        })}
      </ul>
    );
  }
);

Menu.defaultProps = {
  className: '',
};
