import * as React from 'react';

// interfaces
import { ScoreChip, Text } from '@atoms';
import { Mode } from '@modals/_ds2/audit/_context/interfaces';

// components
import { TextChip } from '@modals/_ds2/audit/helpers';

interface PhotoViewerDetailsProps {
    children: React.ReactNode;
    badge?: React.ReactNode;
    title: string;
    subtitle?: string;
    scoreChip?: {
        title?: string;
        mode?: Mode;
        score: number | string;
        type: 'success' | 'danger' | 'neutral';
    };
}

export const Details = ({
    children,
    badge,
    title,
    subtitle,
    scoreChip,
}: PhotoViewerDetailsProps) => {
    return (
        <div className="tw-bg-neutral-100-800 tw-py-7 tw-px-6 lg:tw-basis-[460px] lg:tw-h-full tw-shrink-0 tw-relative tw-z-10 lg:tw-overflow-auto">
            {badge}
            <div className="tw-flex tw-justify-between tw-items-start tw-gap-2">
                <Text color="hi-contrast" font="h2">
                    {title}
                </Text>
                {scoreChip && (
                    <div className="tw-flex tw-items-center tw-gap-2.5">
                        {scoreChip.title && (
                            <Text
                                color="hi-contrast"
                                font="h3"
                                className="tw-whitespace-nowrap"
                            >
                                {scoreChip.title}
                            </Text>
                        )}
                        {!scoreChip.mode ? (
                            <ScoreChip
                                score={scoreChip.score}
                                type={scoreChip.type}
                                size="sm"
                                className="tw-hidden lg:tw-flex"
                            />
                        ) : scoreChip.mode === 'INSPECTION' ? (
                            <TextChip
                                text={Number(scoreChip.score).toFixed(2)}
                                type={scoreChip.type}
                                size="sm"
                                className="tw-hidden lg:tw-flex"
                            />
                        ) : (
                            <ScoreChip
                                score={scoreChip.score}
                                prettyNumberOptions={{ decimals: 2 }}
                                type={scoreChip.type}
                                size="sm"
                                className="tw-hidden lg:tw-flex"
                            />
                        )}
                    </div>
                )}
            </div>
            {subtitle || scoreChip ? (
                <div className="tw-flex tw-justify-between tw-items-start">
                    <Text color="neutral-offset" font="body-md">
                        {subtitle ?? ''}
                    </Text>
                    {scoreChip &&
                        (scoreChip.mode === 'INSPECTION' ? (
                            <TextChip
                                text={Number(scoreChip.score).toFixed(2)}
                                type={scoreChip.type}
                                size="sm"
                                className="lg:tw-hidden tw-flex"
                            />
                        ) : (
                            <ScoreChip
                                score={scoreChip.score}
                                prettyNumberOptions={{ decimals: 2 }}
                                type={scoreChip.type}
                                size="sm"
                                className="lg:tw-hidden tw-flex"
                            />
                        ))}
                </div>
            ) : null}

            {children}
        </div>
    );
};
