// services
import { compressToEncodedURIComponent } from 'lz-string';
import { Observable } from 'rxjs';

import { App } from '@core/app-context/app.interfaces';
import { http, RequestOptions } from '@services';

// operators

// helpers

export interface IDjangoResponse<T> {
	count: number;
	next: string;
	previous: string;
	results: Array<T>;
}

type RequestMethods = 'get' | 'put' | 'post' | 'delete' | 'patch';

class DjangoApiHelper {
	private _customers: App.State['selectedCustomers'];

	private _contracts: App.State['selectedContracts'];

	private _areaTags: App.State['selectedAreaTags'];

	constructor() {
		this._customers = [];
		this._contracts = [];
		this._areaTags = [];
	}

	public set customers(selectedCustomers: App.State['selectedCustomers']) {
		this._customers = selectedCustomers;
	}

	public get customers() {
		return this._customers;
	}

	public set contracts(selectedContracts: App.State['selectedContracts']) {
		this._contracts = selectedContracts;
	}

	public get contracts() {
		return this._contracts;
	}

	public set areaTags(selectedAreaTags: App.State['selectedAreaTags']) {
		this._areaTags = selectedAreaTags;
	}

	public get areaTags() {
		return this._areaTags;
	}

	public customersCount = () => {
		return this.customers.length;
	};

	public contractsCount = () => {
		return this.contracts.length;
	};

	private addHeaderToParams(url, options) {
		let query = '';

		// quick solution until we can figure out better way to add global params to request
		const foundQuestionMark = url.indexOf('?') !== -1;

		let contracts;
		let customers;
		let areaTags;

		// if url already has contract paramenter, don't need to add extra
		if (this.contracts.length && !url.includes('contract=')) {
			contracts = compressToEncodedURIComponent(
				this.contracts.map((contract) => contract.id).join(',')
			);
		}

		if (this.customers.length) {
			customers = this.customers.map((customer) => customer.id).join(',');
		}

		if (this.areaTags.length) {
			areaTags = this.areaTags.map((areaTag) => areaTag.id).join(',');
		}

		if (!options.noContract && contracts) {
			if (query === '' && !foundQuestionMark) {
				query += '?';
			} else {
				query += '&';
			}
			query += `contract=${contracts}`;
		}

		if (!options.noCustomer && customers?.length) {
			if (query === '' && !foundQuestionMark) {
				query += '?';
			} else {
				query += '&';
			}
			query += `customer=${customers}`;
		}

		if (!options.noTag && areaTags?.length) {
			if (query === '' && !foundQuestionMark) {
				query += '?';
			} else {
				query += '&';
			}
			query += `tag=${areaTags}`;
		}

		return query;
	}

	private getRequestMethod<T>(
		type: RequestMethods,
		url: string,
		options?: RequestOptions,
		body?
	): Observable<T> {
		if (type === 'get') {
			url += this.addHeaderToParams(url, {
				noContract: options.hasOwnProperty('noContract')
					? options.noContract
					: false,
				noCustomer: options.hasOwnProperty('noCustomer')
					? options.noCustomer
					: false,
				noTag: options.hasOwnProperty('noTag') ? options.noTag : false,
			});
			return http.get<T>(url, options);
		}
		if (type === 'put') {
			return http.put<T>(url, body, options);
		}
		if (type === 'post') {
			return http.post<T>(url, body, options);
		}
		if (type === 'patch') {
			return http.patch<T>(url, body, options);
		}
		return http.delete<T>(url, options);
	}

	// Makes a request and handles authentication if needed
	private makeRequest<T>(
		url: string,
		type: RequestMethods,
		options = {} as any,
		body = {}
	): Observable<T> {
		return this.getRequestMethod<T>(type, url, options, body);
	}

	// HTTP methods
	public get<T>(url: string, options: RequestOptions = {}): Observable<T> {
		return this.makeRequest<T>(url, 'get', options);
	}

	public put<T>(
		url: string,
		body?,
		options: RequestOptions = {}
	): Observable<T> {
		return this.makeRequest<T>(url, 'put', options, body);
	}

	public post<T>(
		url: string,
		body,
		options: RequestOptions = {}
	): Observable<T> {
		return this.makeRequest<T>(url, 'post', options, body);
	}

	public delete<T>(
		url: string,
		body?,
		options: RequestOptions = {}
	): Observable<T> {
		return this.makeRequest<T>(url, 'delete', options, body);
	}

	public patch<T>(
		url: string,
		body,
		options: RequestOptions = {}
	): Observable<T> {
		return this.makeRequest<T>(url, 'patch', options, body);
	}
}

export const virtualBrownClient = new DjangoApiHelper();
