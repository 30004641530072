import env from '@core/environments';
import { virtualBrownClient } from '@core/services';

const productionDomains = [
	'https://app.4insite.com',
	'https://app.insitenext.com',
];

const searchUserAgent = (keywords) => {
	const { userAgent } = navigator;
	for (const keyword of keywords) {
		if (userAgent.includes(keyword)) {
			return keyword;
		}
	}
	return 'N/A';
};

const getBrowser = () => {
	const browser = searchUserAgent(['Chrome', 'Safari', 'Firefox']);
	if (browser !== 'N/A') return browser;
	return 'Other';
};

const getDevice = () => {
	const deviceType = searchUserAgent(['iPhone', 'Android']);
	if (deviceType !== 'N/A') return 'Mobile';
	const desktopType = searchUserAgent(['Windows', 'Mac']);
	if (desktopType !== 'N/A') return 'Desktop';
	return 'N/A';
};

const getOS = () => {
	return searchUserAgent(['Windows', 'Android', 'iPhone', 'Mac']);
};

const joinNames = (arr) => arr.map((item) => item.name).join(',');

class MixpanelService {
	_customers = [];
	_contracts: any = [];
	_orgGroups = [];
	_siteGroups = [];
	_user: any = null;

	set user(user) {
		this._user = user;
	}

	set contracts(contracts) {
		this._contracts = contracts ?? [];
	}

	set customers(customers) {
		this._customers = customers ?? [];
	}

	set orgGroups(orgGroups) {
		this._orgGroups = orgGroups ?? [];
	}

	set siteGroups(siteGroups) {
		this._siteGroups = siteGroups ?? [];
	}

	callMixpanel = (config) => virtualBrownClient.post('mixpanel/', config);

	postMixpanel = async (event: string, data?: any) => {
		const {
			employeeId,
			organization: { name: organization_name },
			firstName: first_name,
			lastName: last_name,
			person: { photo_url },
			positions,
			email,
			phoneNumber: phone,
		} = this._user;

		const customerNames = joinNames(this._customers);
		const clientGroupNames = joinNames(this._orgGroups);
		const siteGroupNames = joinNames(this._siteGroups);

		const isProduction = productionDomains.includes(window.location.origin);
		const token = isProduction
			? env.mixpanel.prod.key
			: env.mixpanel.dev.key;

		const siteIds = this._contracts
			.map((contract) => contract.id)
			.join(',');
		const siteNames = this._contracts
			.map((contract) => contract.name)
			.join(',');

		const config = {
			user_id: employeeId,
			event_list: [
				{
					event,
				},
			],
			properties: {
				customer_names: customerNames,
				Device: getDevice(),
				distinct_id: employeeId,
				email: email !== '' ? email : 'N/A',
				employee_id: employeeId,
				environment: isProduction ? 'prod' : 'dev',
				first_name,
				last_name,
				organization_name,
				OS: getOS(),
				phone: phone !== '' ? phone : 'N/A',
				photo_url: photo_url !== '' ? photo_url : 'N/A',
				position: positions?.[0]?.name ?? 'N/A',
				'Request Referring_Domain': window.location.href,
				'Request Browser': getBrowser(),
				site_names: siteNames,
				Site_ids: siteIds,
				token,
				...(clientGroupNames && { 'Client Group': clientGroupNames }),
				...(siteGroupNames && { 'Site Group': siteGroupNames }),
				...(data && { ...data }),
			},
		};
		await this.callMixpanel(config).toPromise();
	};
}

export const mixpanelService = new MixpanelService();
