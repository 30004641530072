import { Observable } from 'rxjs';

// interfaces
import { InspectionsSummary, InspectionsSummaryParams } from '@api-interfaces';

// services
import { virtualBrownClient } from '@core/services/django-client';
import { Params } from '@helpers';

import { inspectionsCombinerService } from './_combiner';

// helpers

const BASE_URL = `inspections/summary`;

const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

class InspectionsSummaryService {
	readonly combiner = inspectionsCombinerService;

	public getSummaries<T extends InspectionsSummary.Chart>(
		params: InspectionsSummaryParams<T>
	): Observable<InspectionsSummary.SummaryReturnType[T][]> {
		const query = {
			...params,
			chart: params.chart.join(','),
			time_zone: params.time_zone || timeZone,
		};
		return virtualBrownClient.get(
			`${BASE_URL}/${Params.makeQueryString(query)}`,
			{
				noCustomer: params.customer !== undefined,
				noContract: params.contract !== undefined,
				noTag: params.tag !== undefined,
			}
		);
	}
}

export const inspectionsSummaryService = new InspectionsSummaryService();
