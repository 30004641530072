// services

// helpers
import isNil from 'lodash/isNil';
import { Observable } from 'rxjs';

import { AttestationsSummary } from '@api-interfaces';
import { Params } from '@core/helpers';

// interfaces
import { virtualBrownClient } from '@core/services/django-client';

// constants
const BASE_URL = 'attestations/summary';
const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

class AttestationsSummaryService {
	getSummaries<T extends AttestationsSummary.Chart>(params?: {
		chart?: T[];
		from?: Date;
		to?: Date;
		time_zone?: string;
		contract?: number | string;
		customer?: number | string;
		tag_group?: number | string;
		provider?: number;
		is_closed?: boolean;
	}): Observable<AttestationsSummary.SummaryReturnType[T][]> {
		const query = {
			...params,
			chart: params.chart.join(','),
			time_zone: params.time_zone || timeZone,
		};

		return virtualBrownClient.get(
			`${BASE_URL}/${Params.makeQueryString(query)}`,
			{
				noCustomer: !isNil(params.customer),
				noContract: !isNil(params.contract),
				noTag: !isNil(params.tag_group),
			}
		);
	}
}

export const attestationsSummaryService = new AttestationsSummaryService();
