import {
	WorkOrder,
	WorkOrderPayload,
	CostLineItem,
	WorkOrderCostLineItemPayload,
	BusinessUnitPurchaseOrder,
} from '@api-interfaces';

import {
	WorkOrderFormAction,
	WorkOrderFormActionType,
} from '../_context/workorder-interface';

export const nullPOOptionName = 'N/A';

export const createWorkOrderPayload = (workOrder: WorkOrder) => {
	const tempWorkOrderPayload: WorkOrderPayload = {
		contract: {
			id: workOrder.contract.id,
		},
		created_by: {
			id: workOrder.created_by?.id,
		},
		assigned_date: workOrder.assigned_date,
		assigned_to: {
			id: workOrder.assigned_to?.id,
		},
		title: workOrder.title,
		description: workOrder.description,
		summary: workOrder.description?.substring(0, 2048),
		status: {
			id: workOrder.status?.id,
		},
		reporter: {
			id: workOrder.reporter?.id,
		},
		contact_number_country_code: workOrder.contact_number_country_code,
		contact_number: workOrder.contact_number,
		due_date: workOrder.due_date,
		work_complete_date: workOrder.work_complete_date,
		location: {
			id: workOrder.location?.id, // set to five right now for testing purposes
		},
		location_name: workOrder?.location_name ?? null,
		service: {
			id: workOrder.service?.id,
		},
		service_type: {
			id: workOrder.service_type?.id,
		},
		priority: {
			id: workOrder.priority?.id,
		},
		equipment: workOrder?.equipment ?? null,
		is_in_scope: workOrder.is_in_scope,
		is_billable: workOrder.is_billable,
		is_production_effected: workOrder.is_production_effected,
		quantity: workOrder.quantity,
		unitcost: workOrder.unitcost,
		po_number: workOrder.po_number,
		po_number_id: workOrder.po_number_id ? +workOrder.po_number_id : null,
		bu_name: workOrder.bu_name,
		net_suite: {
			id: +workOrder.net_suite_id,
		},
		work_order_status: workOrder.work_order_status,
		billing_description: workOrder.billing_description,
		work_type: workOrder.work_type,
		todos: workOrder.todos,
		media: workOrder.media_list.map((m) => {
			m.workordermedia_properties = [
				{ is_after_work_order: m.is_after_work_order },
			];
			return m;
		}),
		rejection_other: workOrder.rejection_other,
		rejection_type_id: workOrder.rejection_type?.id,
		cost_line_items: workOrder?.cost_line_items?.filter((x) => x.id != null),
		customer_work_order: workOrder?.customer_work_order,
		is_project_work: workOrder?.is_project_work,
	};

	return tempWorkOrderPayload;
};

export const createWorkOrderCostLineItemPayload = (
	costLineItem: CostLineItem
): WorkOrderCostLineItemPayload => {
	return {
		cost: +costLineItem?.cost,
		unitcost: +costLineItem?.unitcost,
		quantity: +costLineItem?.quantity,
		work_type: costLineItem?.work_type,
		service: {
			id: +costLineItem?.service?.id,
		},
		billing_description: costLineItem?.billing_description,
		work_order: {
			id: +costLineItem?.workorder_id,
		},
	};
};

export const calculatePOFundsRemaining = (
	cost_line_items_param: CostLineItem[],
	purchaseOrder: BusinessUnitPurchaseOrder
): number => {
	let remainingFunds = purchaseOrder?.funds_remaining || 0;

	if (purchaseOrder?.funds_remaining) {
		cost_line_items_param.forEach(
			(cost_line_item) => (remainingFunds -= cost_line_item?.cost)
		);
	}

	return remainingFunds;
};

export const saveToProvider = ({
	dispatch,
	payload,
	workOrder,
}: {
	dispatch: React.Dispatch<WorkOrderFormAction>;
	payload: Partial<WorkOrderPayload>;
	workOrder?: WorkOrder;
}) => {
	dispatch({
		type: WorkOrderFormActionType.SET_WORK_ORDER_FORM,
		payload: {
			...payload,
			// Reformat dates to required WorkOrderPayload date object
			assigned_date: workOrder?.assigned_date
				? new Date(workOrder.assigned_date)
				: new Date(),
			work_complete_date: workOrder?.work_complete_date
				? new Date(workOrder.work_complete_date)
				: null,
			request_due_date: workOrder?.request_due_date
				? new Date(workOrder.request_due_date)
				: null,
			close_date: workOrder?.close_date ? new Date(workOrder.close_date) : null,
		},
	});
};

export const submitIsValidCheck = (payload: {
	title: string;
	summary: string;
	reporter: {
		id: number;
	};
	description: string;
}) => {
	const { title, summary, reporter, description } = payload;

	if (title?.length && description?.length && summary?.length && reporter?.id) {
		return true;
	}
	return false;
};
