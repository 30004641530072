import * as React from 'react';

export const BottomSheetMain = ({
  children,
  className,
}: React.PropsWithChildren<{ className?: string }>) => {
  return (
    <div
      id="bottom-sheet-main"
      className={`tw-space-y-4 tw-w-full tw-overflow-y-auto tw-overflow-hidden ${className}`}
    >
      {children}
    </div>
  );
};

BottomSheetMain.defaultProps = {
  className: '',
};
