import { uniqBy } from 'lodash';
import { useEffect, useState, useRef } from 'react';

// interfaces
import { Subscription } from 'rxjs';
import { forkJoin } from 'rxjs';

import {
  InspectionComment,
  Media,
  Employee,
  Service,
  Todo,
} from '@api-interfaces';

// services
import { inspectionsService } from '@apis';

// components
import { LoaderBlocks } from '@atoms';
import { sendError } from '@helpers';
import { useBreakpoints } from '@hooks';
import { useAuditModalContext } from '@modals/_ds2/audit/_context/context';
import { Modal } from '@templates';

import {
  Comments,
  Programs,
  Employees,
  Attachments,
  ToDos,
} from './_components';

// helpers

export const DetailsTab = () => {
  const {
    state: { selectedArea },
  } = useAuditModalContext();
  const bp = useBreakpoints();
  const subscriptions = useRef<{ [key: string]: Subscription }>({});
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [todos, setTodos] = useState<Todo[]>([]);
  const [attachments, setAttachments] = useState<Media[]>([]);
  const [comments, setComments] = useState<InspectionComment[]>([]);
  const employees: Employee[] = selectedArea?.service_employees ?? [];
  const services: Service[] = uniqBy(
    selectedArea?.task_inspections.map(
      (task_inspection) => task_inspection.service
    ) ?? [],
    'id'
  );

  useEffect(() => {
    subscriptions.current['audit-area-modal-details-init'] = forkJoin([
      inspectionsService.inspectionAreas.getTodos({
        inspectionId: selectedArea.inspection_id,
        inspectionareaId: selectedArea.id,
      }),
      inspectionsService.inspectionAreas.getPhotos({
        inspectionId: selectedArea.inspection_id,
        inspectionareaId: selectedArea.id,
      }),
      inspectionsService.inspectionAreas.getComments({
        inspectionId: selectedArea.inspection_id,
        inspectionareaId: selectedArea.id,
      }),
    ]).subscribe({
      next: ([todos, attachments, comments]) => {
        setTodos(todos.results);
        setAttachments(attachments.results);
        setComments(comments.results);
        setIsLoading(false);
      },
      error: sendError({
        toastMessage: 'There was an error retrieving Audit Area Details data.',
        callback: () => {
          setIsLoading(false);
        },
      }),
    });

    return () =>
      Object.values(subscriptions.current).forEach((subscription) =>
        subscription.unsubscribe()
      );
  }, [selectedArea?.id]);

  return !isLoading ? (
    <div>
      <div className="tw-mb-6">
        <Comments comments={comments} />
      </div>

      {bp.md ? (
        <div className="tw-mb-6">
          <Modal.Information.Divider
            className="tw--mx-5 tw-px-5"
            orientation="horizontal"
          />

          <div className="tw-flex tw-justify-between">
            <div className="tw-flex-1 tw-mt-4 tw-mb-8 tw-mr-6">
              <Programs services={services} />
            </div>

            <Modal.Information.Divider orientation="vertical" />

            <div className="tw-flex-1 tw-mt-4 tw-mb-8 tw-ml-6">
              <Employees employees={employees} />
            </div>
          </div>

          <Modal.Information.Divider
            className="tw--mx-5 tw-px-5"
            orientation="horizontal"
          />
        </div>
      ) : (
        <>
          <Modal.Information.Divider
            className="tw--mx-5 tw-px-5"
            orientation="horizontal"
          />

          <div className="tw-mt-4 tw-mb-6">
            <Programs services={services} />
          </div>

          <Modal.Information.Divider
            className="tw--mx-5 tw-px-5"
            orientation="horizontal"
          />

          <div className="tw-mt-4 tw-mb-6">
            <Employees employees={employees} />
          </div>

          <Modal.Information.Divider
            className="tw--mx-5 tw-px-5"
            orientation="horizontal"
          />
        </>
      )}

      <div className="tw-mt-4 tw-mb-6">
        <Attachments attachments={attachments} />
      </div>

      <Modal.Information.Divider
        className="tw--mx-5 tw-px-5"
        orientation="horizontal"
      />

      <div className="tw-mt-4">
        <ToDos todos={todos} />
      </div>
    </div>
  ) : (
    <LoaderBlocks className="tw-w-full tw-py-10" />
  );
};
