// base
import { Subject } from 'rxjs';

// interface
import { Toast } from '@app/misc/Toaster/Toaster';

/**
 * @description - a service that allows pop-up messages to be sent
 */
class ToasterService {
	private subject$ = new Subject<Toast>();

	get subject() {
		return this.subject$.asObservable();
	}

	public newToast(toast: Toast = {}) {
		this.subject$.next(toast);
	}
}

export const toasterService = new ToasterService();

/* 
Send toast as follows:
toastService.newToast({
    message: 'There was a problem',
    status: 'fail', (success/warning/fail default is fail)
});
*/
