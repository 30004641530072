import { useEffect } from 'react';

// services
import { mixpanelService } from '@apis';

type MixpanelTypes =
	| 'Viewed'
	| 'Created'
	| 'Clicked'
	| 'Added'
	| 'Removed'
	| 'Updated';

type MixpanelAction = {
	event: string;
};

export function useMixpanel(
	actions: string | MixpanelAction | MixpanelAction[]
) {
	useEffect(() => {
		mixpanelService.postMixpanel(actions);
	}, []);
}

export function useMixpanelV2(feature: string, type: MixpanelTypes) {
	useEffect(() => {
		mixpanelService.postMixpanel(`${type}_${feature}`);
	}, []);
}
