import { CSSProperties, useEffect, useLayoutEffect, useRef, useState } from 'react';
import * as React from 'react';

import './ShadowScroller.css';

type ShadowScrollerProps = {
  children: React.ReactNode;
  className?: string;
  scrollClassName?: string;
  style?: React.CSSProperties;
  rounded?: boolean;
  axis?: 'x' | 'y';
} & Pick<CSSProperties, 'height' | 'maxHeight' | 'width' | 'maxWidth'>;

export const ShadowScroller = ({
  children,
  className,
  scrollClassName,
  style,
  rounded,
  axis = 'x',
  height,
  maxHeight,
  width,
  maxWidth,
}: ShadowScrollerProps) => {
  const [hasScrollLeft, setHasScrollLeft] = useState(false);
  const [hasScrollRight, setHasScrollRight] = useState(false);
  const [hasScrollUp, setHasScrollUp] = useState(false);
  const [hasScrollDown, setHasScrollDown] = useState(false);
  const scrollRef = useRef<HTMLDivElement>();

  const updateShadows = (target: HTMLDivElement) => {
    if (axis === 'x') {
      const { scrollLeft, scrollWidth, clientWidth } = target;
      const divWidth = scrollWidth - clientWidth;
      setHasScrollLeft(scrollLeft > 0);
      setHasScrollRight(divWidth !== scrollLeft);
    }
    if (axis === 'y') {
      const { scrollTop, scrollHeight, clientHeight } = target;
      const divHeight = scrollHeight - clientHeight;
      setHasScrollUp(scrollTop > 0);
      setHasScrollDown(divHeight !== scrollTop);
    }
  };

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    updateShadows(e.currentTarget);
  };

  const handleResize = () => {
    updateShadows(scrollRef.current);
  };

  useEffect(() => {
    if (scrollRef.current) {
      updateShadows(scrollRef.current);
    } else {
      setHasScrollLeft(false);
      setHasScrollRight(false);
      setHasScrollUp(false);
      setHasScrollDown(false);
    }
  }, [scrollRef.current, children]);

  useLayoutEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div
      className={`tw-relative tw-pointer-events-none shadow-scroller axis-${axis} ${
        hasScrollLeft ? 'shadow-left' : ''
      } ${hasScrollRight ? 'shadow-right' : ''} ${
        hasScrollUp ? 'shadow-up' : ''
      } ${hasScrollDown ? 'shadow-down' : ''} ${
        rounded ? 'tw-rounded-lg after:tw-rounded-lg before:tw-rounded-lg' : ''
      } ${className}`}
      style={style}
    >
      <div
        className={`tw-pointer-events-auto ${
          axis.includes('x') ? 'tw-overflow-x-auto' : ''
        } ${axis.includes('y') ? 'tw-overflow-y-auto' : ''} ${scrollClassName}`}
        onScroll={handleScroll}
        style={{
          ...{ width: axis === 'x' && width ? width : undefined },
          ...{ maxWidth: axis === 'x' && maxWidth ? maxWidth : undefined },
          ...{ height: axis === 'y' && height ? height : undefined },
          ...{ maxHeight: axis === 'y' && maxHeight ? maxHeight : undefined },
        }}
        ref={scrollRef}
      >
        {children}
      </div>
    </div>
  );
};
