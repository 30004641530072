// components
import { Icon, LoaderBlocks, Text } from '@atoms';
import Empty from '@components/Empty/Empty';
import { InputCheckbox } from '@atoms/form-ds2/input-checkbox';

// interfaces
import { TableSimpleProps } from './table-simple.interfaces';
import { textAlignMap } from './table-simple.utils';

/**
 * @description - A simple table that gives user some control over
 * styling through class names and styles. By default, the background is transparent.
 * This table features sorting, loading by row, and also showing empty states by row.
 */
export const TableSimple = ({
    headers,
    rows,
    className,
    theadClassName,
    selectableRows,
    selectedRows,
    setSelectedRows,
    disableSelectAll,
    ...props
}: TableSimpleProps) => {
    return (
        <table className={`tw-w-full ${className}`} {...props}>
            <thead className={theadClassName}>
                <tr>
                    {headers.map(
                        ({
                            label,
                            labelDecorator,
                            labelClassName,
                            headerClassName = '',
                            align = 'left',
                            sortCb,
                            sortDir,
                            headerStyle,
                            summary,
                            'data-test': dataTest,
                        }) => (
                            <th
                                key={label}
                                className={`tw-pb-4 tw-px-6 ${textAlignMap[align]} ${headerClassName}`}
                                style={headerStyle}
                                data-test={dataTest ?? ''}
                            >
                                <button onClick={sortCb} disabled={!sortCb}>
                                    <Text
                                        font="h4"
                                        color="neutral-offset"
                                        className={`tw-inline-block ${
                                            labelClassName ?? ''
                                        }`}
                                    >
                                        {label}
                                        {labelDecorator}
                                        {sortDir && (
                                            <Icon
                                                className="tw-ml-2"
                                                icon={
                                                    sortDir === 'asc'
                                                        ? 'caret-up'
                                                        : 'caret-down'
                                                }
                                                size="lg"
                                            />
                                        )}
                                    </Text>
                                </button>
                                {summary}
                            </th>
                        )
                    )}
                    {!disableSelectAll &&
                        selectableRows &&
                        selectedRows &&
                        setSelectedRows && (
                            <th
                                key="select-all"
                                className={`tw-pb-4 tw-px-6 ${textAlignMap['left']} tw-whitespace-nowrap`}
                            >
                                <button
                                    onClick={() => {
                                        if (
                                            selectedRows.length === rows.length
                                        ) {
                                            setSelectedRows([]);
                                        } else {
                                            setSelectedRows(
                                                rows.map((row) => row.id)
                                            );
                                        }
                                    }}
                                >
                                    <Text
                                        font="h4"
                                        color="primary"
                                        className="tw-inline-block tw-underline"
                                    >
                                        {selectedRows.length !== rows.length
                                            ? 'Select All'
                                            : selectedRows.length === 0
                                              ? 'Select All'
                                              : 'Deselect All'}
                                    </Text>
                                </button>
                            </th>
                        )}
                </tr>
            </thead>
            <tbody>
                {rows.map(
                    (
                        {
                            headerLabel,
                            className = '',
                            classNameByHeaderLabel,
                            styleByHeaderLabel,
                            isLoading,
                            loaderOptions,
                            isEmpty,
                            emptyOptions,
                            fullRow,
                            fullRowClass = '',
                            fullRowStyle,
                            id,
                            'data-test': dataTest,
                            ...props
                        },
                        index
                    ) => {
                        const hoverClass = props.onClick
                            ? 'tw-cursor-pointer hover:tw-bg-neutral-500 hover:tw-bg-opacity-15'
                            : '';

                        if (isLoading) {
                            return (
                                <tr key={index}>
                                    <td colSpan={headers.length}>
                                        <LoaderBlocks {...loaderOptions} />
                                    </td>
                                </tr>
                            );
                        }

                        if (isEmpty) {
                            return (
                                <tr key={index}>
                                    <td colSpan={headers.length}>
                                        <Empty {...(emptyOptions as object)} />
                                    </td>
                                </tr>
                            );
                        }

                        if (fullRow) {
                            return (
                                <tr
                                    key={index}
                                    className={`${hoverClass} ${className}`}
                                    data-test={dataTest ?? ''}
                                    {...props}
                                >
                                    <td
                                        colSpan={headers.length}
                                        className={`tw-border-solid tw-border-t tw-border-theme-neutral-300-700 tw-px-6 tw-py-4 ${fullRowClass}`}
                                        style={fullRowStyle}
                                    >
                                        {fullRow}
                                    </td>
                                </tr>
                            );
                        }

                        return (
                            <tr
                                key={index}
                                className={`${hoverClass} ${className}`}
                                data-test={dataTest ?? ''}
                                {...props}
                            >
                                {headers.map(
                                    (
                                        {
                                            label,
                                            cellClassName = '',
                                            cellStyle,
                                            align = 'left',
                                        },
                                        index
                                    ) => (
                                        <td
                                            key={index}
                                            className={`tw-border-solid tw-border-t tw-border-theme-neutral-300-700 tw-px-6 tw-py-4 ${
                                                textAlignMap[align]
                                            } ${cellClassName} ${
                                                (classNameByHeaderLabel &&
                                                    classNameByHeaderLabel[
                                                        label
                                                    ]) ||
                                                ''
                                            }`}
                                            style={{
                                                ...cellStyle,
                                                ...((styleByHeaderLabel as object) &&
                                                    ((styleByHeaderLabel ?? {})[
                                                        label
                                                    ] as object)),
                                            }}
                                        >
                                            {headerLabel[label]}
                                        </td>
                                    )
                                )}
                                {selectableRows &&
                                    selectedRows &&
                                    setSelectedRows && (
                                        <td
                                            className={`tw-border-solid tw-border-t tw-border-theme-neutral-300-700 tw-px-6 tw-py-4 ${textAlignMap['center']}`}
                                        >
                                            <InputCheckbox
                                                checked={selectedRows?.includes(
                                                    id
                                                )}
                                                onChange={() => {
                                                    const isSelected =
                                                        selectedRows.includes(
                                                            id
                                                        );
                                                    if (isSelected) {
                                                        setSelectedRows(
                                                            selectedRows.filter(
                                                                (rowId) =>
                                                                    rowId !== id
                                                            )
                                                        );
                                                    } else {
                                                        setSelectedRows([
                                                            ...selectedRows,
                                                            id,
                                                        ]);
                                                    }
                                                }}
                                            />
                                        </td>
                                    )}
                            </tr>
                        );
                    }
                )}
            </tbody>
        </table>
    );
};

TableSimple.defaultProps = {
    className: '',
    theadClassName: '',
};
