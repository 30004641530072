import { useState, useEffect } from 'react';

// services
import { Todo } from '@api-interfaces';
import { todosService } from '@apis';

// interfaces

// helpers
import { Avatar, Badge, LoaderBlocks, Text } from '@atoms';
import Empty from '@components/Empty/Empty';
import { sendError, TimeUtils, titleCase } from '@helpers';

// components
import { Modal } from '@templates';

interface ToDoDetailsProps {
  todoId: number;
  className?: string;
  area?: {
    name: string;
    type: string;
    floor: string;
    building: string;
  };
}

export const ToDoDetails = ({ todoId, className, area }: ToDoDetailsProps) => {
  const [todo, setTodo] = useState<Todo>(null);
  const [isLoading, setIsLoading] = useState(true);

  const areaName = area?.name ?? todo?.areainspection_todo_location?.[0]?.name;
  const areaType = area?.type;
  const floorName = area?.floor ?? todo?.location?.name;
  const buildingName = area?.building ?? todo?.location?.parent?.name;

  useEffect(() => {
    setIsLoading(true);
    const subscription = todosService.getTodoById(todoId).subscribe(
      (todo) => {
        if (todo?.id) setTodo(todo);
      },
      sendError,
      () => setIsLoading(false)
    );

    return () => subscription.unsubscribe();
  }, [todoId]);

  return (
    <Modal.PhotoViewer.Details
      badge={
        <Badge
          color="orange"
          label={`${
            todo?.due_status.includes('CLOSED') ? 'Closed ' : ''
          } To-Do`}
          className="tw-mb-6"
        />
      }
      title={areaName}
      subtitle={
        todo
          ? `${areaType ? `${areaType} · ` : ''}${
              buildingName ?? '-'
            } · Floor ${floorName ?? '-'}`
          : ''
      }
    >
      {!isLoading && Boolean(todo) ? (
        <div className={`tw-space-y-6 tw-mt-6 ${className}`}>
          <div>
            <Text font="h4" color="neutral-offset" className="tw-mb-2.5">
              Assigned To
            </Text>
            <div className="tw-flex tw-gap-4 tw-items-center">
              <Avatar person={todo.assigned_to?.person} size="lg" />
              <div className="tw-h-full tw-flex tw-flex-col tw-justify-center">
                <Text
                  font="h3"
                  color="hi-contrast"
                  style={{ lineHeight: 1.25 }}
                >
                  {todo.assigned_to?.person?.first_name}{' '}
                  {todo.assigned_to?.person?.last_name}
                </Text>
                {(todo.assigned_to?.positions?.length ||
                  todo.assigned_to?.hired_date) && (
                  <Text font="h4" color="neutral-offset">
                    {todo.assigned_to?.positions?.[0]?.name}
                    {todo.assigned_to?.positions?.length &&
                      todo.assigned_to?.hired_date &&
                      ' | '}
                    {todo.assigned_to?.hired_date &&
                      `Hired: ${TimeUtils.format(
                        todo.assigned_to?.hired_date,
                        'MM/DD/YYYY'
                      )}`}
                  </Text>
                )}
                <Text font="body-md" color="neutral-offset">
                  {todo.contract.customer.name} — {todo.contract.name}
                </Text>
              </div>
            </div>
          </div>

          <div>
            <Text font="h4" color="neutral-offset" className="tw-mb-2.5">
              Created By
            </Text>
            <div className="tw-flex tw-gap-4 tw-items-center">
              <Avatar person={todo.creator?.person} size="lg" />
              <div className="tw-h-full tw-flex tw-flex-col tw-justify-center">
                <Text
                  font="h3"
                  color="hi-contrast"
                  style={{ lineHeight: 1.25 }}
                >
                  {todo.creator?.person?.first_name}{' '}
                  {todo.creator?.person?.last_name}
                </Text>
                {(todo.creator?.positions?.length ||
                  todo.creator?.hired_date) && (
                  <Text font="h4" color="neutral-offset">
                    {todo.creator?.positions?.[0]?.name}
                    {todo.creator?.positions?.length &&
                      todo.creator?.hired_date &&
                      ' | '}
                    {todo.creator?.hired_date &&
                      `Hired: ${TimeUtils.format(
                        todo.creator?.hired_date,
                        'MM/DD/YYYY'
                      )}`}
                  </Text>
                )}
                <Text font="body-md" color="neutral-offset">
                  {todo.contract.customer.name} — {todo.contract.name}
                </Text>
              </div>
            </div>
          </div>

          <div>
            <Text color="neutral-offset" font="h4">
              Created Source
            </Text>
            <Text color="hi-contrast" font="h4">
              {titleCase(todo.source)}
            </Text>
          </div>

          <div>
            <Text color="neutral-offset" font="h4">
              Created On
            </Text>
            <Text color="hi-contrast" font="h4">
              {TimeUtils.format(
                todo.created_on_date,
                'dddd, MMMM D, YYYY \\a\\t h:mm A'
              )}
            </Text>
          </div>

          <div>
            <Text color="neutral-offset" font="h4">
              Due Date
            </Text>
            <Text color="hi-contrast" font="h4">
              {TimeUtils.format(
                todo.due_date,
                'dddd, MMMM D, YYYY \\a\\t h:mm A'
              )}
            </Text>
          </div>

          <div>
            <Text color="neutral-offset" font="h4">
              Seen
            </Text>
            <Text color="hi-contrast" font="h4">
              {todo.last_seen_date
                ? TimeUtils.format(
                    todo.last_seen_date,
                    'dddd, MMMM D, YYYY \\a\\t h:mm A'
                  )
                : '– – –'}
            </Text>
          </div>

          <div>
            <Text color="neutral-offset" font="h4">
              Status
            </Text>
            <Badge
              color={
                todo.due_status.includes('PAST_DUE') ? 'danger' : 'success'
              }
              label={titleCase(todo.due_status.replaceAll('_', ' '))}
            />
          </div>

          <div>
            <Text color="neutral-offset" font="h4">
              Closed Date
            </Text>
            <Text color="hi-contrast" font="h4">
              {todo.closed_date
                ? TimeUtils.format(
                    todo.closed_date,
                    'dddd, MMMM D, YYYY \\a\\t h:mm A'
                  )
                : '– – –'}
            </Text>
          </div>

          <div>
            <Text color="neutral-offset" font="h4">
              Description
            </Text>
            <Text color="hi-contrast" font="h4">
              {todo.comment}
            </Text>
          </div>
        </div>
      ) : !todo ? (
        <Empty />
      ) : (
        <LoaderBlocks className="tw-pb-8" />
      )}
    </Modal.PhotoViewer.Details>
  );
};
