import { datadogRum } from '@datadog/browser-rum';
import { HistoryListenerParameter } from '@reach/router';
import * as React from 'react';

// services
import { App } from '@core/app-context/app.interfaces';
import SomethingWentWrong from '@core/base/structure/something-went-wrong';
import { useAppContext } from '@hooks';
import { historyService } from '@services';

// hooks

// component

// interfaces

interface Props {
  fallback?: React.ReactNode;
}
interface State {
  hasError: boolean;
  retryCount: number;
}

const HISTORY_LISTENER_ID = 'error-boundary';

export class ErrorBoundaryComponent extends React.Component<
  Props & App.State,
  State
> {
  constructor(props) {
    super(props);

    this.removeHistoryListener = this.removeHistoryListener.bind(this);
    this.handleNavigationChange = this.handleNavigationChange.bind(this);
    this.handleReload = this.handleReload.bind(this);

    this.state = {
      hasError: false,
      retryCount: 0,
    };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    const listener = this.historyListener;
    if (!listener) {
      historyService.addListener({
        id: HISTORY_LISTENER_ID,
        listener: this.handleNavigationChange,
      });
    }

    if (process.env.HOST_ENV === 'prod') {
      datadogRum.addUserAction('react error boundary', {
        user: {
          id: this.props.user.employeeId,
          name: this.props.user.fullName,
        },
        sitePickerContext: {
          customers: this.props.selectedCustomers.map((c) => ({
            id: c.id,
            name: c.name,
          })),
          contracts: this.props.selectedContracts.map((c) => ({
            id: c.id,
            name: c.name,
          })),
          tagGroups: this.props.selectedAreaTagGroups.map((a) => ({
            id: a.id,
            name: a.name,
          })),
          tags: this.props.selectedAreaTags.map((a) => ({
            id: a.id,
            name: a.name,
          })),
        },
        error,
        errorInfo,
      });
    }
  }

  componentWillUnmount() {
    this.removeHistoryListener();
  }

  get historyListener() {
    return historyService.findListener(HISTORY_LISTENER_ID);
  }

  handleNavigationChange(listenerParam: HistoryListenerParameter) {
    // we need to wrap this to give the router time to register
    // that's it's on a new page, otherwise we'll be stuck showing
    // the "something is wrong" message
    setTimeout(() => {
      if (this.state.hasError) {
        this.setState(
          {
            hasError: false,
            retryCount:
              listenerParam.location.pathname === '/'
                ? this.state.retryCount + 1
                : 0,
          },
          () => {
            this.removeHistoryListener();
          }
        );
      }
    }, 0);
  }

  handleReload() {
    this.setState({
      hasError: false,
      retryCount: this.state.retryCount + 1,
    });
  }

  removeHistoryListener() {
    const listener = this.historyListener;
    if (listener) {
      listener.unsubscribe();
      historyService.removeListener(HISTORY_LISTENER_ID);
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        this.props.fallback || (
          <div className="tw-h-full tw-w-full">
            <SomethingWentWrong
              handleReload={this.handleReload}
              retryCount={this.state.retryCount}
            />
          </div>
        )
      );
    }

    return this.props.children;
  }
}

export const ErrorBoundary = (props: React.PropsWithChildren<Props>) => {
  const { state } = useAppContext();
  return <ErrorBoundaryComponent {...state} {...props} />;
};
