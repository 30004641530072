import { Observable } from 'rxjs';

// interfaces
import {
	Questionnaire,
	QuestionnairePayload,
	QuestionPayload,
	Question,
	QuestionnaireQuestion,
} from '@api-interfaces';

// services
import { virtualBrownClient } from '@core/services/django-client';

// helpers
import { Params } from '@helpers';
import { PaginationParams, PaginationResponse } from '@models';
import { RequestOptions } from '@services/http-client';

// constants
const BASE_URL = 'questionnaires';

class QuestionnairesService {
	public createQuestionnaire = (
		payload: QuestionnairePayload
	): Observable<Questionnaire> =>
		virtualBrownClient.post(`${BASE_URL}/`, payload);

	public getQuestionnaires(
		params: {
			name?: 'ESat v2' | 'BehavioralAudit' | 'gmp_audit';
			organization?: number;
		} & PaginationParams
	) {
		const queryString = Params.makeQueryString({ ...params });

		return virtualBrownClient.get<PaginationResponse<Questionnaire>>(
			`${BASE_URL}/${queryString}`
		);
	}

	public updateQuestionnaire = (
		questionnaireId: number,
		payload: QuestionnairePayload
	): Observable<Questionnaire> =>
		virtualBrownClient.put(`${BASE_URL}/${questionnaireId}/`, payload);

	public deleteQuestionnaire = (questionnaireId: number) =>
		virtualBrownClient.delete(`${BASE_URL}/${questionnaireId}/`);

	getQuestionnaireById = (
		questionnaireId: number,
		options?: RequestOptions
	): Observable<Questionnaire> =>
		virtualBrownClient.get(`${BASE_URL}/${questionnaireId}/`, options);

	addQuestionToQuestionnaire = (
		payload: QuestionPayload
	): Observable<Question> => {
		return virtualBrownClient.post(`${BASE_URL}/questions/`, payload);
	};

	getQuestionFromQuestionnaireById = (
		questionId: number
	): Observable<Question> => {
		return virtualBrownClient.get(`${BASE_URL}/questions/${questionId}/`);
	};

	updateQuestionToQuestionnaireById = (
		questionId: number,
		payload: QuestionPayload
	): Observable<Question> => {
		return virtualBrownClient.put(
			`${BASE_URL}/questions/${questionId}/`,
			payload
		);
	};

	deleteQuestionFromQuestionnaireById = (
		questionId: number
	): Observable<''> => {
		return virtualBrownClient.delete(`${BASE_URL}/questions/${questionId}/`);
	};

	getQuestionsByQuestionnaireId(
		questionnaireId: number,
		params?: { limit?: number }
	) {
		return virtualBrownClient.get<PaginationResponse<QuestionnaireQuestion>>(
			`${BASE_URL}/${questionnaireId}/questions/${Params.makeQueryString(
				params || {}
			)}`
		);
	}
}

export const questionnairesService = new QuestionnairesService();
