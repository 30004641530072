import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ReferenceItem, ReferenceItemGroupCode } from '@api-interfaces';
import { Params } from '@core/helpers';
import { virtualBrownClient } from '@core/services/django-client';
import { PaginationResponse } from '@models';

const BASE_URL = 'reference';

class ReferenceService {
	getItems = (params: {
		group_code: ReferenceItemGroupCode;
		detail_level?: 'simple';
		limit?: number;
		offset?: number;
	}): Observable<ReferenceItem[]> => {
		const qs = Params.makeQueryString({
			...params,
			limit: params?.limit ?? 1000,
		});
		return virtualBrownClient
			.get<PaginationResponse<ReferenceItem>>(`${BASE_URL}/items/${qs}`, {
				noProvider: true,
				noContract: true,
				noCustomer: true,
			})
			.pipe(map((res) => (res ? res.results : [])));
	};
}

export const referenceService = new ReferenceService();
