import { cloneDeep } from 'lodash';
import { createContext, useContext, useState, useEffect, useRef } from 'react';

// services

// helpers
import { Subscription } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';

import {
  questionnairesService,
  surveysService,
  positionsService,
  employeesService,
} from '@apis';
import { sendError, getFullName } from '@helpers';
import { useAppContext } from '@hooks';
import { refetchService } from '@services';

// interfaces
import {
  SurveyESATModalState,
  SurveyESATProps,
  SurveyESATActiveModals,
} from './survey-esat.interfaces';

const surveyESATModalContext = createContext(null as SurveyESATModalState);

export const SurveyESATModalProvider = ({
  surveyId,
  closeModal,
  ...props
}: SurveyESATProps) => {
  const {
    state: { user },
  } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);
  const [survey, setSurvey] = useState<SurveyESATModalState['survey']>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [surveyEdit, setSurveyEdit] =
    useState<SurveyESATModalState['surveyEdit']>(null);
  const [questionnaireId, setQuestionnaireId] =
    useState<SurveyESATModalState['questionnaireId']>(null);
  const [questions, setQuestions] = useState<SurveyESATModalState['questions']>(
    []
  );
  const [surveyResults, setSurveyResults] = useState<
    SurveyESATModalState['surveyResults']
  >([]);
  const [activeModals, setActiveModals] = useState<SurveyESATActiveModals>({
    'bottom-sheet': true,
    'delete-survey': false,
    'cancel-survey': false,
    'send-survey': false,
  });
  const [clients, setClients] = useState<SurveyESATModalState['clients']>([]);
  const [selectedClients, setSelectedClients] = useState<
    SurveyESATModalState['selectedClients']
  >([]);
  const [sites, setSites] = useState<SurveyESATModalState['sites']>([]);
  const [selectedSites, setSelectedSites] = useState<
    SurveyESATModalState['selectedSites']
  >([]);
  const [employees, setEmployees] = useState<SurveyESATModalState['employees']>(
    []
  );
  const [selectedEmployees, setSelectedEmployees] = useState<
    SurveyESATModalState['selectedEmployees']
  >([]);
  const [positions, setPositions] = useState<SurveyESATModalState['positions']>(
    []
  );
  const [selectedPositions, setSelectedPositions] = useState<
    SurveyESATModalState['selectedPositions']
  >([]);
  const [floatingSummary, setFloatingSummary] =
    useState<SurveyESATModalState['floatingSummary']>(null);
  const [errors, setErrors] = useState<SurveyESATModalState['errors']>({});
  const [isPositionsLoading, setIsPositionsLoading] = useState(false);
  const [isEmployeesLoading, setIsEmployeesLoading] = useState(false);
  const hasBeenUpdated = useRef(false);
  const hasBeenEdited = useRef(false);

  useEffect(() => {
    let subscription = new Subscription();
    if (isLoading) {
      // if a survey id is passed it, retrieve survey and make call to get survey's questions else, we just default to esat questions
      if (surveyId != undefined) {
        subscription = surveysService
          .getSurveyById(surveyId)
          .pipe(
            switchMap((survey) => {
              setSurvey(survey);
              if (survey.questionnaire != undefined) {
                setQuestionnaireId(survey.questionnaire);

                if (
                  survey.status === 'COMPLETE' ||
                  survey.status === 'ACTIVE'
                ) {
                  return surveysService.summary
                    .getSummariesBySurveyId(survey.id, { chart: [4] })
                    .pipe(map(([res]) => res));
                }
                return questionnairesService
                  .getQuestionsByQuestionnaireId(survey.questionnaire, {
                    limit: 50,
                  })
                  .pipe(map((res) => res?.results ?? []));
              }
              return questionnairesService
                .getQuestionnaires({ name: 'ESat v2' })
                .pipe(
                  switchMap(({ results }) => {
                    if (results.length) {
                      setQuestionnaireId(results[0].id);
                      return questionnairesService
                        .getQuestionsByQuestionnaireId(results[0].id, {
                          limit: 50,
                        })
                        .pipe(map((res) => res?.results ?? []));
                    }
                    throw Error();
                  })
                );
            })
          )
          .subscribe({
            next: (questions) => {
              if ('results' in questions) {
                setSurveyResults(questions.results.question_summaries || []);
              } else {
                setQuestions(questions);
              }
              setIsLoading(false);
            },
            error: sendError({
              toastMessage: 'There was an error retrieving the Survey',
              callback: () => {
                setIsEditing(true);
                setIsLoading(false);
              },
            }),
          });
      } else {
        subscription = questionnairesService
          .getQuestionnaires({ name: 'ESat v2' })
          .pipe(
            switchMap(({ results }) => {
              if (results.length) {
                setQuestionnaireId(results[0].id);
                return questionnairesService
                  .getQuestionsByQuestionnaireId(results[0].id, { limit: 50 })
                  .pipe(map((res) => res?.results ?? []));
              }
              throw Error();
            })
          )
          .subscribe({
            next: (questions) => {
              setQuestions(questions);
              setIsEditing(true);
              setIsLoading(false);
            },
            error: sendError({
              toastMessage:
                'There was an error retrieving the Survey Questions',
              callback: () => {
                setIsEditing(true);
                setIsLoading(false);
              },
            }),
          });
      }
    }
    return () => {
      subscription.unsubscribe();
    };
  }, [isLoading, surveyId]);

  useEffect(() => {
    const clients = user.roleProfile.portfolios.flatMap(
      (p) => p.customer_organizations
    );
    const sites = user.roleProfile.portfolios.flatMap((p) => p.contracts);
    setClients(clients);
    setSites(sites);

    const selectedClients = clients.filter(
      (client) =>
        survey?.survey_customers?.findIndex(
          (survey) => survey.customer.id === client.id
        ) > -1
    );

    const selectedSites = sites.filter(
      (site) =>
        survey?.survey_contracts?.findIndex(
          (survey) => survey.contract.id === site.id
        ) > -1
    );
    setSelectedClients(selectedClients);
    setSelectedSites(selectedSites);
    setSelectedPositions(
      survey?.survey_positions?.map(({ position }) => position) ?? []
    );
    setSelectedEmployees(
      survey?.survey_recipients?.map(({ recipient }) => ({
        ...recipient,
        name: getFullName(recipient.person),
        subtext: [recipient.person?.email_address, recipient.organization?.name]
          .filter(Boolean)
          .join(' - '),
      })) ?? []
    );
    if (selectedClients.length) {
      setIsPositionsLoading(true);
    }
  }, [user, survey]);

  useEffect(() => {
    if (!survey?.id) return;
    setSurveyEdit(cloneDeep(survey));
  }, [survey]);

  useEffect(() => {
    if (!survey?.id) return;
    const subscription = surveysService.summary
      .getSummariesBySurveyId(survey.id, { chart: [2] })
      .subscribe({
        next: ([{ results }]) => {
          setFloatingSummary(results);
        },
      });
    return () => {
      subscription.unsubscribe();
    };
  }, [survey]);

  useEffect(() => {
    if (!selectedClients.length || !isPositionsLoading) return;
    const subscription = positionsService
      .getPositions(
        {
          limit: 1000,
          customer: selectedClients.map((client) => client.id).join(','),
          ...(selectedSites.length > 0 && {
            contract: selectedSites.map((site) => site.id).join(','),
          }),
        },
        true
      )
      .subscribe({
        next: ({ results }) => {
          setPositions(results);
          setSelectedPositions(
            selectedPositions.filter((position) =>
              results.map((pos) => pos.id).includes(position.id)
            )
          );
          setIsPositionsLoading(false);
          setIsEmployeesLoading(true);
        },
        error: sendError({
          toastMessage: 'There was an error getting Positions.',
          callback: () => {
            setPositions([]);
            setSelectedPositions([]);
            setIsPositionsLoading(false);
          },
        }),
      });

    return () => {
      subscription.unsubscribe();
    };
  }, [isPositionsLoading]);

  // this useEffect fetches new employees whenever new sites are selected
  useEffect(() => {
    if (!selectedClients.length || !isEmployeesLoading) return;
    const subscription = employeesService
      .$getEmployees({
        limit: 999999,
        use_ifm: false,
        is_survey_employee: true,
        customer: selectedClients.map((client) => client.id).join(','),
        ...(selectedSites.length > 0 && {
          contract: selectedSites.map((site) => site.id).join(','),
        }),
        ...(selectedPositions.length > 0 && {
          position: selectedPositions.map((position) => position.id).join(','),
        }),
      })
      .subscribe({
        next: ({ results: employeesRes }) => {
          const fetchedEmployees = employeesRes.map((employee) => ({
            ...employee,
            name: getFullName(employee.person),
            subtext: [
              employee.person?.email_address,
              employee.organization?.name,
            ]
              .filter(Boolean)
              .join(' - '),
          }));
          setEmployees(fetchedEmployees);
          const employees = selectedEmployees.filter((employee) =>
            fetchedEmployees.map((emp) => emp.id).includes(employee.id)
          );
          setSelectedEmployees(employees);
          setIsEmployeesLoading(false);
        },
        error: sendError({
          toastMessage: 'There was an error getting employees',
          callback: () => {
            setEmployees([]);
            setSelectedEmployees([]);
            setIsEmployeesLoading(false);
          },
        }),
      });
    return () => {
      subscription.unsubscribe();
    };
  }, [isEmployeesLoading]);

  function handleErrorChange(key: keyof typeof errors) {
    if (errors[key]) {
      const errorsCopy = {
        ...errors,
      };
      delete errorsCopy[key];
      setErrors(errorsCopy);
    }
    hasBeenEdited.current = true;
  }

  return (
    <surveyESATModalContext.Provider
      value={{
        closeModal: () => {
          if (hasBeenUpdated.current) {
            refetchService.fetch('esat');
          }
          closeModal();
        },
        survey,
        setSurvey,
        surveyEdit,
        setSurveyEdit,
        questionnaireId,
        questions,
        surveyResults,
        clients,
        setClients,
        sites,
        setSites,
        employees,
        setEmployees,
        positions,
        setPositions,
        errors,
        setErrors,
        isEditing,
        setIsEditing,
        isLoading,
        setIsLoading,
        activeModals,
        setActiveModals,
        hasBeenUpdated,
        hasBeenEdited,
        handleErrorChange,
        floatingSummary,
        selectedClients,
        selectedSites,
        setSelectedClients,
        setSelectedSites,
        selectedEmployees,
        setSelectedEmployees,
        selectedPositions,
        setSelectedPositions,
        isPositionsLoading,
        setIsPositionsLoading,
        isEmployeesLoading,
        setIsEmployeesLoading,
      }}
      {...props}
    />
  );
};

export function useSurveyESATModalContext() {
  return useContext(surveyESATModalContext);
}
