import {
	FrequencyPeriod,
	RouteDefinition,
	RecurrenceType,
	StopDefinition,
} from '@api-interfaces';

export enum TIME_TABLE_ROW_TYPE {
	START_SHIFT,
	END_SHIFT,
	BREAK,
	LUNCH,
	TRAINING,
	PERIODIC, // break out to different periods when implemented
	REGULAR,
	EMPTY, // this is probably temporary until ISS sites fill in their empty stops with tasks
}

export type TimeTableGroupsName =
	| 'Start'
	| 'End'
	| 'Daily'
	| 'Weekly'
	| 'Monthly'
	| 'Quarterly'
	| 'Yearly'
	| 'AsNeeded';

export enum PERIOD_MAP_TIME_TABLE_GROUP {
	DAILY = 'Daily',
	WEEKLY = 'Weekly',
	MONTHLY = 'Monthly',
	QUARTERLY = 'Quarterly',
	YEARLY = 'Yearly',
	AS_NEEDED = 'AsNeeded',
}

export interface TimeTableRowTaskRepeat {
	period: FrequencyPeriod; // declared again so we can pass individually into getTaskRepeatText()
	day: number;
	month?: number;
}

export interface TimeTableRow {
	id: number; // stopId; not unique because could have daily and weekly at same stop
	area: {
		id: number;
		is_verifiable: boolean;
		name: string;
		type: string;
		building: {
			id: number;
			name: string;
			type: string;
		};
		floor: {
			id: number;
			name: string;
			type: string;
		};
	};
	special?: {
		name: string;
		sub?: string;
		icon: string;
		color: string;
	};
	tasks?: {
		data: Array<{
			id: number; // stop_frequency_id
			frequencyId: number;
			name: string;
			duration: number;
			repeat?: TimeTableRowTaskRepeat;
			recurrence_type: RecurrenceType;
		}>;
		count: number; // minus NAs for now
		duration: number;
		allNA: boolean;
	};
	time: {
		start?: string; // military time
		end?: string; // military time
		total: number; // minutes
		day?: number; // can go into next day, so get current day (starts on 0)
	};
	periodicRepeatText?: string;
	actions: {
		isViewable: boolean;
		isReorderable: boolean;
	};
	type: TIME_TABLE_ROW_TYPE;
	sequence: number;
	period?: FrequencyPeriod;
	nonWork?: {
		day: number;
		month: number;
	};
	new_coverage_count: number;
	references: {
		stop: StopDefinition; // original stop def used to pass down instead of making new call
	};
}

export interface TimeTable {
	ContractId: number;
	RouteId: number;
	StartTime: string;
	EndTime: string;
	Groups: {
		Start: TimeTableRow;
		End: TimeTableRow;
		Daily: TimeTableRow[];
		Weekly: TimeTableRow[];
		Monthly: TimeTableRow[];
		Quarterly: TimeTableRow[];
		Yearly: TimeTableRow[];
		AsNeeded: TimeTableRow[];
	};
	Summary: {
		Covered: {
			Daily: number; // minutes
			ShiftPrep: number; // minutes
			Break: number; // minutes
			Lunch: number; // minutes
			DailyTotal: number; // minutes

			Weekly: number; // minutes
			Monthly: number; // minutes
			Quarterly: number; // minutes
			Yearly: number; // minutes
			AsNeeded: number; // minutes
			PeriodicTotal: number; // minutes

			Training: number; // minutes

			All: number; // minutes
		};
		Total: {
			Daily: number; // minutes
			ShiftPrep: number; // minutes
			Break: number; // minutes
			Lunch: number; // minutes
			DailyTotal: number; // minutes

			Weekly: number; // minutes
			Monthly: number; // minutes
			Quarterly: number; // minutes
			Yearly: number; // minutes
			AsNeeded: number; // minutes
			PeriodicTotal: number; // minutes

			Training: number; // minutes

			All: number; // minutes
		};
	};
	references: {
		route: RouteDefinition; // original route def used to pass down instead of making new call
	};
}

export const DAYS_OF_WEEK = [
	'Sunday',
	'Monday',
	'Tuesday',
	'Wednesday',
	'Thursday',
	'Friday',
	'Saturday',
];
export const MONTHS = [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December',
];
