import { useMemo, useState } from 'react';

// context

// components
import { Text } from '@atoms';
import { ScoreChipProps } from '@atoms/score-chip/score-chip.interfaces';
import { EmployeeDetails } from '@components/_new/MiniProfiles/_components/employee-details';

// helpers
import { useBreakpoints } from '@hooks';
import { useAuditModalContext } from '@modals/_ds2/audit/_context/context';
import { MiniProfile } from '@new';

import { Divider } from './divider';
import { round, floor } from 'lodash';

export const Employees = () => {
    const {
        state: { employees, areas, mode, inspection },
    } = useAuditModalContext();
    const [showOverflow, setShowOverflow] = useState<boolean>(false);
    const bp = useBreakpoints();
    const displayCount = bp['2xl'] ? 3 : bp.sm ? 2 : 1;
    const displayEmployees = showOverflow
        ? employees
        : employees.slice(0, displayCount);
    const overflowCount =
        employees.length - displayCount > 0
            ? employees.length - displayCount
            : 0;
    const isBpXl = bp.xl;
    const miniProfileWidth = isBpXl ? 240 : 190;
    const miniProfileMargin = bp['2xl']
        ? 24
        : bp.xl
          ? 74
          : bp.lg
            ? 12
            : bp.md
              ? 94
              : bp.sm
                ? 46
                : 0;
    const allProfilesWidth =
        displayCount * miniProfileWidth + displayCount * miniProfileMargin;

    const goalline = areas?.[0]?.goalline || 0;
    const employeeScores = useMemo(() => {
        if (!employees || !areas) return {};

        const scores: {
            [employeeId: number]: Pick<ScoreChipProps, 'score' | 'type'>;
        } = {};

        employees.forEach((employee) => {
            const employeeAreas = areas.filter((area) =>
                area.service_employees.some((e) => e.id === employee.id)
            );

            if (employeeAreas.length) {
                let score: null | number;
                let lengthav = employeeAreas.length;

                if (mode === 'AUDIT') {
                    const auditedAreas = employeeAreas.filter(
                        (area) => area.audit_score_calculated
                    );
                    lengthav = auditedAreas.length;
                    score =
                        auditedAreas.reduce(
                            (a, b) => a + b.audit_score_calculated,
                            0
                        ) / lengthav;
                } else {
                    const inspectedAreas = employeeAreas.filter(
                        (area) => area.total_inspected
                    );

                    if (inspectedAreas.length > 0) {
                        const totVal = inspectedAreas.reduce((acc, area) => {
                            return (
                                acc +
                                (area.total_pass / area.total_inspected) *
                                    area.audit_scale
                            );
                        }, 0);
                        score = totVal / inspectedAreas.length;
                    }

                    if (!score) {
                        score =
                            employeeAreas.reduce(
                                (a, b) =>
                                    a +
                                    (b.total_pass / b.total_inspected) *
                                        b.audit_scale,
                                0
                            ) / lengthav;
                    }
                }

                let strScore = 'N/A';
                if (!isNaN(score)) {
                    strScore = floor(round(score, 3), 2);
                } else {
                    score = 0;
                }

                scores[employee.id] = {
                    score: strScore,
                    type: score >= goalline ? 'success' : 'danger',
                };
            }
        });

        return scores;
    }, [employees, areas]);

    return (
        Boolean(employees?.length) && (
            <>
                <Divider />
                <div className="tw-relative">
                    <Text font="h3" color="hi-contrast" className="tw-mb-4">
                        Accountable Employees
                    </Text>

                    {showOverflow && (
                        <Text
                            font="h4"
                            color="primary"
                            className="tw-absolute tw-top-0 tw-right-0 tw-cursor-pointer"
                            onClick={() => setShowOverflow(false)}
                        >
                            View Less
                        </Text>
                    )}

                    <div className="tw-w-full tw-flex tw-justify-between">
                        <div
                            className={`tw-flex tw-flex-wrap tw-justify-start ${
                                isBpXl ? 'tw--mb-10' : 'tw--mb-6'
                            }`}
                            style={{ width: allProfilesWidth }}
                        >
                            {displayEmployees.map((employee, i) =>
                                isBpXl ? (
                                    <MiniProfile.Lg
                                        key={i}
                                        person={employee.person}
                                        detail={
                                            <EmployeeDetails
                                                jobTitle={
                                                    employee.positions?.[0]
                                                        ?.name ?? ''
                                                }
                                                hiredDate={
                                                    employee?.hired_date ?? null
                                                }
                                                client={
                                                    inspection?.contract
                                                        ?.customer?.name ?? ''
                                                }
                                                site={
                                                    inspection?.contract
                                                        ?.name ?? ''
                                                }
                                            />
                                        }
                                        className="tw-mb-10 tw-mr-6"
                                        scoreChip={employeeScores[employee.id]}
                                    />
                                ) : (
                                    <MiniProfile.Md
                                        key={i}
                                        person={employee.person}
                                        detail={employee.positions?.[0]?.name}
                                        className="tw-mb-6 tw-mr-3"
                                        scoreChip={employeeScores[employee.id]}
                                    />
                                )
                            )}
                        </div>
                        <div
                            className={`tw-flex-shrink-0 ${
                                isBpXl ? 'tw-h-14 tw-w-14' : 'tw-h-9 tw-w-9'
                            }`}
                        >
                            {Boolean(overflowCount && !showOverflow) && (
                                <div
                                    className={`tw-cursor-pointer
                                        tw-flex tw-justify-center tw-items-center
                                        tw-rounded-full tw-bg-neutral-300
                                        tw-h-full tw-w-full
                                        `}
                                    onClick={() => setShowOverflow(true)}
                                >
                                    <Text font="h3" color="neutral-offset-flat">
                                        {`+${overflowCount}`}
                                    </Text>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </>
        )
    );
};
