import dateFns from 'date-fns';
import { cloneDeep } from 'lodash';
import { Observable } from 'rxjs';

// interfaces
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

import {
	RouteDefinition,
	RouteDefinitionPayload,
	RouteDefinitionDuplicate,
	RouteCoverage,
	RouteEmployee,
	Route,
	RouteTasksCountSummary,
	IncompleteTask,
	RoutesChart,
	StopFrequencyPayload,
	RouteCountSummary,
	RouteEmployeeTask,
	RouteWithServiceValidation,
	VerifiedIncomplete,
	VerificationHistory,
	RouteEmployeesSortingTypes,
	RouteEmployeeTasksOrderingParams,
	StopFrequency,
	GetRoutesParams,
	GetRouteWithVerifiableTasksParams,
} from '@api-interfaces';
import { virtualBrownClient } from '@core/services/django-client';
import { Params } from '@helpers';
import { PaginationParams, PaginationResponse } from '@models';

// services

import { routesStopsService } from './stops';
import { routesSummaryService } from './summary';
import { routesTrackerService } from './tracker';
import { routesUnusedAreasService } from './unused-areas';
import { routesUnusedTaskService } from './unused-tasks';
import { routesVerifiableAreasService } from './verifiable-areas';
import { routesVerifiableTasksService } from './verifiable-tasks';

// helpers

// operators

// constants
const BASE_URL = 'routes';

const noOpts = {
	noContract: true,
	noCustomer: true,
	noTag: true,
};

function addStopFrequenciesToStops(
	routeDefinition: RouteDefinition,
	stopFrequencies: StopFrequency[]
): RouteDefinition {
	const stopMap: Map<number, StopFrequency[]> = new Map();
	stopFrequencies.forEach((stopFrequency) => {
		if (!stopMap.has(stopFrequency.routestopdef_id)) {
			stopMap.set(stopFrequency.routestopdef_id, [stopFrequency]);
		} else {
			stopMap.set(
				stopFrequency.routestopdef_id,
				stopMap
					?.get(stopFrequency?.routestopdef_id)
					.concat(stopFrequency)
			);
		}
	});

	const routeDefinitionWithStopFrequencies = cloneDeep(routeDefinition);

	if (
		!routeDefinitionWithStopFrequencies ||
		!routeDefinitionWithStopFrequencies.stops
	)
		return null;

	routeDefinitionWithStopFrequencies.stops.map((stop) => {
		const array = stopMap.get(stop.id);

		if (array && array.length) {
			stop.stop_frequencies = stopMap.get(stop.id);
		} else {
			stop.stop_frequencies = [];
		}
	});

	return routeDefinitionWithStopFrequencies;
}

class RoutesService {
	readonly summary = routesSummaryService;

	readonly verifiableAreas = routesVerifiableAreasService;

	readonly verifiableTasks = routesVerifiableTasksService;

	readonly stops = routesStopsService;

	readonly unusedAreas = routesUnusedAreasService;

	readonly unusedTasks = routesUnusedTaskService;

	readonly tracker = routesTrackerService;

	// Routes
	public getRoutes = (params?: GetRoutesParams) => {
		const queryString = Params.makeQueryString(params);
		return virtualBrownClient.get<PaginationResponse<Route>>(
			`${BASE_URL}/${queryString}`
		);
	};

	public getRouteById(params: { id: number }) {
		return virtualBrownClient.get<Route>(`${BASE_URL}/${params.id}/`);
	}

	public updateRoute = (routeId: number, payload): Observable<any> =>
		virtualBrownClient.put(`${BASE_URL}/${routeId}/`, payload);

	// Route Definitions
	public getRouteDefinitions(params) {
		const queryString = Params.makeQueryString(params);
		return virtualBrownClient.get<PaginationResponse<RouteDefinition>>(
			`${BASE_URL}/definitions/${queryString}`,
			{
				noCustomer: params.customer != undefined,
				noContract: params.contract != undefined,
			}
		);
	}

	public getRouteDefinitionById(params: { id: number }) {
		return virtualBrownClient.get<RouteDefinition>(
			`${BASE_URL}/definitions/${params.id}/`
		);
	}

	public createRouteDefinition(params: { payload: RouteDefinitionPayload }) {
		return virtualBrownClient.post<RouteDefinition>(
			`${BASE_URL}/definitions/`,
			params.payload
		);
	}

	public updateRouteDefinition(params: {
		id: number;
		payload: RouteDefinitionPayload;
		detail_level?: 'quick_save';
	}) {
		const query = { field_set: 'no_stop_frequencies' } as any;
		if (params.detail_level) query.detail_level = params.detail_level;
		const queryString = Params.makeQueryString(query);

		return virtualBrownClient.put<RouteDefinition>(
			`${BASE_URL}/definitions/${params.id}/${queryString}`,
			params.payload
		);
	}

	public deleteRouteDefinition(params: { id: number }) {
		return virtualBrownClient.delete(
			`${BASE_URL}/definitions/${params.id}/`
		);
	}

	public copyRouteDefinition(params: {
		payload: { contract_id: number; route_id: number };
	}) {
		return virtualBrownClient.post<RouteDefinitionDuplicate>(
			`${BASE_URL}/definitions/duplicate/`,
			params.payload
		);
	}

	// {{API:REDO}} Route Definition With Stops And Stop Frequencies (optimized split calls)
	public getRouteDefinitionWithStopsAndStopFrequenciesById(
		routeDefinitionId: number,
		routeParams?: {
			field_set?: 'no_stop_frequencies';
		},
		stopFrequenciesParams?: {
			offset?: number;
			limit?: number;
			exclude_task_bundle_name?: boolean;
		}
	): Observable<RouteDefinition> {
		const routeQueryString = Params.makeQueryString(routeParams || {});
		const stopQuery = {
			...stopFrequenciesParams,
			route: routeDefinitionId,
		};

		const stopFrequenciesQueryString = Params.makeQueryString(stopQuery);

		return forkJoin([
			virtualBrownClient.get<RouteDefinition>(
				`${BASE_URL}/definitions2/${routeDefinitionId}/${routeQueryString}`
			),
			virtualBrownClient.get<PaginationResponse<StopFrequency>>(
				`${BASE_URL}/stops/frequencies2/${stopFrequenciesQueryString}`
			),
		]).pipe(
			map(([routeDefinition, paginationStopFrequencies]) => {
				const stopFrequencies =
					paginationStopFrequencies &&
					paginationStopFrequencies.results &&
					paginationStopFrequencies.results.length
						? paginationStopFrequencies.results
						: [];

				const routeDefinitionWithStopFrequencies =
					addStopFrequenciesToStops(routeDefinition, stopFrequencies);

				return routeDefinitionWithStopFrequencies;
			})
		);
	}

	// Adding a single task to a Route Definition
	public createRouteDefinitionTask(params: {
		routeId: number;
		payload: {
			area: number;
			frequency: number;
		};
	}) {
		return virtualBrownClient.post(
			`${BASE_URL}/definitions/${params.routeId}/task/`,
			params.payload
		);
	}

	// Adding multiple tasks to a Route Definition
	public createRouteDefinitionTasks(params: {
		routeId: number;
		payload: {
			tasks: Array<{
				area: number;
				frequency: number;
			}>;
		};
	}) {
		return virtualBrownClient.post(
			`${BASE_URL}/definitions/${params.routeId}/tasks/`,
			params.payload
		);
	}

	public getRoutesSummary = (params: {
		contract?: number;
		from?: Date;
		to?: Date;
		chart: string | number;
		time_zone?: number | string;
		customer?: number;
		ordering?:
			| 'create_date'
			| 'delete_date'
			| 'id'
			| 'manager_response'
			| 'manager_response_date'
			| 'route_date'
			| 'route_def'
			| 'route_def_id'
			| 'route_manager'
			| 'route_manager_id'
			| 'status'
			| 'stops'
			| 'task_count'
			| 'update_date';
		is_complete?: boolean;
	}): Observable<any> => {
		const query = { ...params } as any;
		if (params.from && params.to) {
			query.from = dateFns.parse(params.from).toISOString();
			query.to = dateFns.parse(params.to).toISOString();
		}

		const queryString = Params.makeQueryString(query);
		return virtualBrownClient.get(`${BASE_URL}/summary/${queryString}`);
	};

	// Summaries (not sure why there is multiple)
	public getRouteTasksCountSummary = (params: {
		contract: number;
		chart: RoutesChart.GET_ROUTE_TASKS_COUNT;
	}): Observable<RouteTasksCountSummary[]> =>
		virtualBrownClient.get(
			`${BASE_URL}/summary/${Params.makeQueryString(params)}`,
			noOpts
		);

	// Route Coverage
	public getRouteCoverage = (params: {
		area: number;
		task?: string | number;
	}) => {
		const queryString = Params.makeQueryString(params);

		return virtualBrownClient.get<RouteCoverage[]>(
			`${BASE_URL}/coverage/${queryString}`
		);
	};

	// Route Employees
	public getRoutesEmployees = (
		params?: {
			ordering?: RouteEmployeesSortingTypes;
			exclude_occurrences_by_frequency?: boolean;
		} & PaginationParams
	) => {
		const query = {
			...params,
		};
		const queryString = Params.makeQueryString(query);
		return virtualBrownClient.get<PaginationResponse<RouteEmployee>>(
			`${BASE_URL}/employees/${queryString}`,
			{
				noCustomer: true,
			}
		);
	};

	// Route Stop Frequencies
	public getStopFrequencies = (
		params: {
			contract: number;
			recurrence?: number | string; // 0, 1, '0', '1'
			task_status?:
				| 'NOT_APPLICABLE'
				| 'IN_SCOPE'
				| 'IN_SCOPE_NOT_DOING'
				| 'OUT_OF_SCOPE_DOING';
			ordering?:
				| 'building'
				| '-building'
				| 'area_type'
				| '-area_type'
				| 'area'
				| '-area'
				| 'task_bundle'
				| '-task_bundle'
				| 'frequency_recurrence'
				| '-frequency_recurrence'
				| 'recurrence_type'
				| '-recurrence_type'
				| 'incomplete_obligation_reason'
				| '-incomplete_obligation_reason';
			areatype?: number;
			servicearea?: number;
			areatemplate?: number;
			frequency?: number;
		} & PaginationParams
	): Observable<PaginationResponse<StopFrequency>> =>
		virtualBrownClient.get(
			`${BASE_URL}/stops/frequencies/${Params.makeQueryString(params)}`,
			noOpts
		);

	public updateStopFrequency = (
		stopFreqId: number,
		payload: StopFrequencyPayload
	): Observable<StopFrequencyPayload> =>
		virtualBrownClient.put(
			`${BASE_URL}/stops/frequencies/${stopFreqId}/`,
			payload,
			noOpts
		);

	// Incomplete Tasks
	public getIncompleteTasks = (
		params: {
			contract?: number;
			from: Date;
			to: Date;
			time_zone: number | string;
			ordering?:
				| 'building'
				| '-building'
				| 'area'
				| '-area'
				| 'task_bundle'
				| '-task_bundle'
				| 'create_date'
				| '-create_date'
				| 'description'
				| '-description'
				| 'associate'
				| '-associate';
		} & PaginationParams
	): Observable<PaginationResponse<IncompleteTask>> =>
		virtualBrownClient.get(
			`${BASE_URL}/incomplete_tasks/${Params.makeQueryString({
				...params,
				from: params.from.toISOString(),
				to: params.to.toISOString(),
			})}`,
			noOpts
		);

	getRouteCountSummary = (params: {
		contract: number;
		chart: RoutesChart.GET_ROUTE_COUNT;
		time_zone?: number | string;
		from: any;
		to: any;
	}): Observable<RouteCountSummary[]> =>
		virtualBrownClient.get(
			`${BASE_URL}/summary/${Params.makeQueryString({
				...params,
				from: params.from.toISOString(),
				to: params.to.toISOString(),
			})}`,
			noOpts
		);

	public updateRouteTask = (
		routeId: number,
		taskId: number,
		payload
	): Observable<any> =>
		virtualBrownClient.put(
			`${BASE_URL}/${routeId}/incomplete_tasks/${taskId}/`,
			payload
		);

	getRouteEmployeeTasks = (
		params: {
			contract: number;
			from: Date;
			to: Date;
			time_zone?: string | number;
			ordering?: RouteEmployeeTasksOrderingParams;
		} & PaginationParams
	): Observable<PaginationResponse<RouteEmployeeTask>> =>
		virtualBrownClient.get(
			`${BASE_URL}/employeetasks/${Params.makeQueryString({
				...params,
				from: dateFns.format(params.from, 'YYYY-MM-DD'),
				to: dateFns.format(params.to, 'YYYY-MM-DD'),
			})}`,
			noOpts
		);

	public getVerifiedIncomplete = (params: {
		from: Date;
		to: Date;
		contract?: number;
		limit?: number;
		offset?: number;
		status?: any;
		search?: string;
		time_period?: 'month' | 'day' | 'hour';
	}): Observable<PaginationResponse<VerifiedIncomplete>> =>
		virtualBrownClient.get(
			`${BASE_URL}/verifiable_tasks/incomplete_verifications/${Params.makeQueryString(
				{
					...params,
				}
			)}`,
			{
				noCustomer: true,
				noTag: true,
			}
		);

	public putVerifiedStatus = (params: {
		contract_id?: number[];
		status?: string;
		employee_id?: number;
		task_id?: number;
		update_time?: Date;
		status_score?: string;
	}): Observable<{
		Success: string;
		'Task ids': number[];
		'Employee id': number;
		update_time: Date;
		time_updated: Date;
		status: string;
	}> =>
		virtualBrownClient.put(
			`${BASE_URL}/verifiable_tasks/statuses/`,
			params
		);

	getRoutesWithVerifiableTasks(params: GetRouteWithVerifiableTasksParams) {
		return virtualBrownClient.get<
			PaginationResponse<RouteWithServiceValidation>
		>(
			`${BASE_URL}/routes_with_verifiable_tasks/${Params.makeQueryString(
				params
			)}`,
			{
				noContract: params.contract != undefined,
			}
		);
	}

	public getVerificationHistory = (params: {
		contract?: number | string;
		customer?: string | number;
		from: Date;
		to: Date;
		time_zone?: number | string;
		limit?: number | string;
		offset?: number | string;
		area_id?: number;
		area_type_id?: number;
		ordering?: string;
		tag?: string | number;
		shift?: string;
		employee_id?: number;
	}) => {
		if (!params.shift) {
			delete params.shift;
		}

		return virtualBrownClient.get<PaginationResponse<VerificationHistory>>(
			`${BASE_URL}/verifiable_areas/verification_history/${Params.makeQueryString(
				{
					...params,
				}
			)}`,
			{
				noCustomer: params.customer != undefined,
				noContract: params.contract != undefined,
				noTag: params.tag != undefined,
			}
		);
	};

	public getEmployeesWithVerifications = (params: {
		contract?: number;
		time_zone?: number | string;
		limit?: number | string;
		offset?: number | string;
	}): Observable<PaginationResponse<{ id: number; employee_name: string }>> =>
		virtualBrownClient.get(
			`${BASE_URL}/employees_with_verifications/${Params.makeQueryString({
				...params,
			})}`,
			{
				noCustomer: true,
				noTag: true,
			}
		);

	public updateRouteDefinitionSequence = (
		routeId: number,
		payload: number[]
	): Observable<any> =>
		virtualBrownClient.put(
			`${BASE_URL}/definitions/${routeId}/sequence/`,
			payload
		);
}

export const routesService = new RoutesService();
