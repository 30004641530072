import { SimplePreset } from '@api-interfaces';

// components
import { Text, Avatar, Box, Tooltip, Icon } from '@atoms';

// helpers
import { getFullName, TimeUtils } from '@helpers';

const Profile = ({ person, title, org, site }) => {
  return (
    <div className="tw-flex tw-items-center tw-space-x-3">
      <Avatar size="lg" person={person} />
      <div className="tw-text-left tw-truncate">
        <Text
          font="h4"
          color="hi-contrast"
          className="tw-capitalize tw-truncate"
        >
          {getFullName(person)}
        </Text>
        <Text font="body-md" color="neutral-offset" className="tw-truncate">
          {title}
        </Text>
        <Text font="body-md" color="neutral-offset" className="tw-truncate">
          {site ? `${org}, ${site}` : `${org}`}
        </Text>
      </div>
    </div>
  );
};

interface Props {
  preset: SimplePreset;
  isInModal?: boolean;
}

export const InfoTooltip = ({ preset, isInModal }: Props) => {
  return (
    <Tooltip
      position={isInModal ? 'bottomLeft' : 'bottomRight'}
      render={
        <Box
          rounded
          className="tw-p-4 tw-text-left tw-shadow-lg tw-space-y-4"
          style={{ minWidth: '250px', maxWidth: '325px' }}
        >
          <div className="tw-space-y-1">
            <Text font="h4" color="primary" className="tw-truncate">
              {`Created: ${TimeUtils.format(
                preset.create_date,
                'MM/DD/YYYY h:mm A'
              )}`}
            </Text>
            <Profile
              person={preset.employee_owner?.person}
              title={preset.employee_owner?.positions
                ?.map((pos) => pos.name)
                .join(', ')}
              org={preset.organization_owner?.name}
              site={preset.employee_owner?.contracts
                ?.map((contract) => contract.name)
                .join(', ')}
            />
          </div>
          <div className="tw-space-y-1">
            <Text font="h4" color="primary" className="tw-truncate">
              {`Last Updated: ${TimeUtils.format(
                preset.update_date,
                'MM/DD/YYYY h:mm A'
              )}`}
            </Text>
            <Profile
              person={
                preset.last_update_by?.person || preset.employee_owner?.person
              }
              title={
                preset.last_update_by?.positions
                  ?.map((pos) => pos.name)
                  .join(', ') ||
                preset.employee_owner?.positions
                  ?.map((pos) => pos.name)
                  .join(', ')
              }
              org={preset.organization_owner?.name}
              site={
                preset.last_update_by?.contracts
                  ?.map((contract) => contract.name)
                  .join(', ') ||
                preset.employee_owner?.contracts
                  ?.map((contract) => contract.name)
                  .join(', ')
              }
            />
          </div>
        </Box>
      }
    >
      <Icon
        className={`tw-text-neutral-500 ${isInModal ? '' : 'tw-ml-1'}`}
        icon="question-circle"
        size={isInModal ? 'lg' : 'sm'}
      />
    </Tooltip>
  );
};
