import { useEffect, useRef, useState } from 'react';
import { forkJoin, Subscription } from 'rxjs';
import { InspectionLocation } from '@api-interfaces';
import { inspectionsService } from '@apis';
import { LoaderBlocks, ModalStack } from '@atoms';
import { sendError, TimeUtils, titleCase } from '@helpers';
import { useAppContext, useBreakpoints } from '@hooks';
import { useAuditModalContext } from '@modals/_ds2/audit/_context/context';
import {
    filterValidAreas,
    getAreaTypesFromAreas,
    getEmployeesFromAreas,
    getSectionsFromAreas,
} from '@modals/_ds2/audit/helpers';
import { toasterService, historyService, modalService } from '@services';
import { Modal } from '@templates';
import { BottomSheetContainerProps } from '@templates/modal/bottom-sheet/container/bottom-sheet.container.interfaces';
import { mixpanelQualityUnfinalizeAudit } from '@app/mixpanel/MixpanelPageTrack';
import { Overview, ToDos, Areas, Details } from './_sections';

export const AuditBottomSheet = () => {
    const {
        state: { user },
    } = useAppContext();
    const {
        state: {
            id,
            mode,
            modals,
            isLoading,
            inspection,
            onClose,
            selectedAreaId,
            selectedAreaTypeId,
            photoIndexOnLoad,
        },
        dispatch,
    } = useAuditModalContext();
    const [fetchOnClose, setFetchOnClose] = useState(false);
    const bp = useBreakpoints();
    const isBpLg = bp.lg;
    const subscriptions = useRef<{ [key: string]: Subscription }>({});
    const hasCreatePermissions =
        mode === 'INSPECTION'
            ? user.hasPermission(2, 'Inspections')
            : user.hasPermission(2, 'Quality Audits');
    const hasDeletePermissions =
        mode === 'INSPECTION'
            ? user.hasPermission(3, 'Inspections')
            : user.hasPermission(3, 'Quality Audits');
    const overflowItems: { name: string; onClick: () => void }[] = [];

    // construct the audit title
    let title = `${
        inspection?.joint_inspected_by?.id ? 'Joint' : 'Internal'
    } ${titleCase(mode)}`;
    // create array of unique top level "building" names
    const buildings = Array.from(
        new Set(
            (inspection?.location as InspectionLocation[][])?.map(
                (loc) => loc[0]?.name
            )
        )
    );
    // show building name of areas; show 'Multiple Areas' if multiple buildings
    if (buildings.length) {
        title += ` of ${
            buildings.length === 1 ? buildings[0] : 'Multiple Areas'
        }`;
    }
    // Drafts start with 'Draft', don't have a date
    if (inspection?.status === 'DRAFT') {
        title = `Draft ${title} `;
    } else {
        title += ` on ${TimeUtils.format(
            inspection?.inspection_date,
            'MM/DD/YYYY'
        )}`;
    }

    const tabs: BottomSheetContainerProps['tabs'] = [
        { label: 'Overview', anchorTag: '#audit-modal-overview' },
        { label: 'Areas', anchorTag: '#audit-modal-areas' },
        { label: 'To-Dos', anchorTag: '#audit-modal-todos' },
    ];

    if (!bp.lg) {
        tabs.unshift({ label: 'Details', anchorTag: '#audit-modal-details' });
    }

    if (hasCreatePermissions) {
        if (inspection?.status === 'COMPLETE') {
            overflowItems.push({
                name: 'Unfinalize',
                onClick: () => {
                    inspectionsService
                        .updateInspection({
                            id,
                            payload: {
                                status: 'DRAFT',
                            },
                        })
                        .subscribe((res) => {
                            if (res && res.id) {
                                mixpanelQualityUnfinalizeAudit();
                                reloadInspection();
                                setFetchOnClose(true);
                            } else {
                                toasterService.newToast({
                                    status: 'fail',
                                    message: 'The audit could not be updated.',
                                });
                            }
                        });
                },
            });
        } else {
            overflowItems.push({
                name: 'Edit',
                onClick: () => {
                    if (mode === 'INSPECTION')
                        historyService.navigate(
                            `/quality/inspection-run/${id}`
                        );
                    else historyService.navigate(`/quality/audit-run/${id}`);
                    modalService.closeAll();
                },
            });
        }
    }

    if (hasDeletePermissions) {
        overflowItems.push({
            name: 'Delete',
            onClick: () => {
                dispatch({
                    type: 'SET_MODALS',
                    payload: {
                        ...modals,
                        'audit-delete-confirmation': true,
                    },
                });
            },
        });
    }

    const reloadInspection = () => {
        dispatch({ type: 'SET_IS_LOADING', payload: true });

        subscriptions.current['audit-modal-init'] = inspectionsService
            .getInspectionById(id)
            .subscribe({
                next: (inspectionData) => {
                    dispatch({
                        type: 'SET_INSPECTION',
                        payload: inspectionData,
                    });
                    dispatch({ type: 'SET_IS_LOADING', payload: false });
                },
                error: sendError({
                    toastMessage: `There was an error retrieving ${titleCase(
                        mode
                    )} Modal data.`,
                    callback: () =>
                        dispatch({ type: 'SET_IS_LOADING', payload: false }),
                }),
            });
    };

    useEffect(() => {
        if (selectedAreaId && !selectedAreaTypeId) {
            dispatch({
                type: 'SET_MODALS',
                payload: {
                    ...modals,
                    'audit-bottom-sheet': false,
                    'area-information': true,
                },
            });
        }

        const subscriptionsRef = subscriptions;

        subscriptionsRef.current['audit-modal-init'] = forkJoin([
            inspectionsService.getInspectionById(id),
            inspectionsService.inspectionAreas.getInspectionAreas({
                inspectionId: id,
            }),
        ]).subscribe({
            next: ([inspectionById, areas]) => {
                const validAreas = filterValidAreas(mode, areas.results);
                dispatch({ type: 'SET_INSPECTION', payload: inspectionById });
                dispatch({ type: 'SET_AREAS', payload: validAreas });
                const areaTypes = getAreaTypesFromAreas(
                    validAreas,
                    validAreas.find((area) => area.goalline)?.goalline ?? 0
                );
                dispatch({
                    type: 'SET_AREA_TYPES',
                    payload: areaTypes,
                });

                const sections = getSectionsFromAreas(
                    validAreas,
                    validAreas.find((area) => area.goalline)?.goalline ?? 0
                );
                dispatch({
                    type: 'SET_SECTIONS',
                    payload: sections,
                });
                if (inspectionById.type === 'ROUTE') {
                    dispatch({
                        type: 'SET_EMPLOYEES',
                        payload: getEmployeesFromAreas(areas.results).filter(
                            (emp) =>
                                emp.id === inspectionById.route_def.employee?.id
                        ),
                    });
                } else {
                    dispatch({
                        type: 'SET_EMPLOYEES',
                        payload: getEmployeesFromAreas(areas.results),
                    });
                }
                if (photoIndexOnLoad != null) {
                    dispatch({
                        type: 'SET_PHOTO_VIEWER_INDEX',
                        payload: photoIndexOnLoad,
                    });
                    dispatch({
                        type: 'SET_PHOTO_VIEWER_AREAS',
                        payload: validAreas,
                    });
                    dispatch({
                        type: 'SET_MODALS',
                        payload: { ...modals, 'audit-attachments': true },
                    });
                }
                if (selectedAreaId) {
                    const selectedArea = areas.results.find(
                        (area) => area.location.id === selectedAreaId
                    );
                    if (selectedArea) {
                        dispatch({
                            type: 'SET_SELECTED_AREA',
                            payload: selectedArea,
                        });
                    } else {
                        dispatch({
                            type: 'SET_MODALS',
                            payload: {
                                ...modals,
                                'audit-bottom-sheet': true,
                                'area-information': false,
                            },
                        });
                    }
                }
                if (selectedAreaTypeId) {
                    const selectedAreaType = areaTypes.find(
                        (areaType) => areaType.id === selectedAreaTypeId
                    );
                    if (selectedAreaType) {
                        dispatch({
                            type: 'SET_SELECTED_AREA_TYPE',
                            payload: selectedAreaType,
                        });

                        const filteredAreas = areas.results.filter(
                            (area) =>
                                area.location?.area_type?.id ===
                                selectedAreaType?.id
                        );
                        const orderedAreas = filteredAreas.sort((a, b) => {
                            const nameA = a?.location?.name
                                ? a.location.name.toUpperCase()
                                : '';
                            const nameB = b?.location?.name
                                ? b.location.name.toUpperCase()
                                : '';
                            return nameA.localeCompare(nameB);
                        });
                        if (orderedAreas.length) {
                            dispatch({
                                type: 'SET_SELECTED_AREA_LIST',
                                payload: orderedAreas.map((area) => area.id),
                            });
                            dispatch({
                                type: 'SET_SELECTED_AREA',
                                payload: orderedAreas[0],
                            });
                            dispatch({
                                type: 'SET_MODALS',
                                payload: {
                                    ...modals,
                                    'area-information': true,
                                },
                            });
                        }
                    }
                }
                dispatch({ type: 'SET_IS_LOADING', payload: false });
            },
            error: sendError({
                toastMessage: `There was an error retrieving ${titleCase(
                    mode
                )} Modal data.`,
                callback: () => {
                    dispatch({ type: 'SET_AREAS', payload: [] });
                    dispatch({ type: 'SET_IS_LOADING', payload: false });
                },
            }),
        });

        return () =>
            Object.values(subscriptionsRef.current).forEach((subscription) =>
                subscription.unsubscribe()
            );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        modals['audit-bottom-sheet'] && (
            <ModalStack
                position="bottom-center"
                onOverlayClick={() => {
                    if (
                        !modals['area-information'] &&
                        !modals['audit-delete-confirmation'] &&
                        !modals['audit-attachments'] &&
                        !modals.todo
                    ) {
                        if (onClose) {
                            onClose();
                        } else {
                            modalService.close({ refetch: fetchOnClose });
                        }
                    }
                }}
            >
                {!isLoading ? (
                    <Modal.BottomSheet.Container
                        title={title}
                        icon="clipboard-check"
                        tabs={tabs}
                        overflowItems={overflowItems}
                        onClose={() => {
                            if (onClose) {
                                onClose();
                            } else {
                                modalService.close({ refetch: fetchOnClose });
                            }
                        }}
                    >
                        <Modal.BottomSheet.Main>
                            <div className="tw-flex tw-flex-col tw-space-y-4">
                                {!isBpLg && <Details />}

                                <Overview />

                                <Areas />

                                <ToDos />
                            </div>
                        </Modal.BottomSheet.Main>

                        {isBpLg && <Details />}
                    </Modal.BottomSheet.Container>
                ) : (
                    <Modal.BottomSheet.Container
                        onClose={() =>
                            modalService.close({ refetch: fetchOnClose })
                        }
                    >
                        <LoaderBlocks className="tw-w-full tw-py-10" />
                    </Modal.BottomSheet.Container>
                )}
            </ModalStack>
        )
    );
};
