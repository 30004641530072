// helpers
import { Observable } from 'rxjs';

// interfaces
import { MessagesSummary, MessagesSummaryParams } from '@api-interfaces';
import { Params } from '@helpers';
import { virtualBrownClient } from '@services';

const BASE_URL = 'messages/summary';
const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

class MessagesSummaryService {
	public getSummaries<T extends MessagesSummary.Chart>(
		params: MessagesSummaryParams<T>
	): Observable<MessagesSummary.SummaryReturnType[T][]> {
		const query = {
			...params,
			chart: params.chart.join(','),

			time_zone: params.time_zone || timeZone,
		};

		return virtualBrownClient.get(
			`${BASE_URL}/${Params.makeQueryString(query)}`
		);
	}
}

export const messagesSummaryService = new MessagesSummaryService();
