// services

// helpers
import { debounce, isEqual } from 'lodash';
import { useState, useRef, useEffect } from 'react';
import * as React from 'react';
import { Subscription } from 'rxjs';

// components
import { UserSelect } from '@components/_new/_form/dropdowns/user-select';
import { ModalStack } from '@atoms';
import { FieldInputDate } from '@atoms/form-ds2/field-input-date';
import { FieldInputTime } from '@atoms/form-ds2/field-input-time';
import { FieldDropdown } from '@atoms/form-ds2/field-dropdown';
import { FieldInputText } from '@atoms/form-ds2/field-input-text';

// interfaces
import {
    Media,
    Employee,
    Contract,
    ServiceArea,
    Service,
    Shift,
} from '@api-interfaces';
import { serviceAreasService, shiftsService, todosService } from '@apis';
import { DropdownItem } from '@atoms/form-ds2/dropdown/dropdown.interfaces';
import { FieldMultiSelect } from '@atoms/form-ds2/field-multi-select';
import { AddAttachment } from '@components/_new/_form/attachments';
import { sendError, TimeUtils } from '@helpers';
import { useAppContext, useBreakpoints, useLocationsRef } from '@hooks';
import { Button } from '@new';
import { toasterService } from '@services';
import { Modal } from '@templates';

import { useWorkOrderModalContext } from '../../../work-orders/_context';
import { useTodoFormContext } from '../../_context';
import { isValidTodo, createTodoPayload } from '../../_helpers';

type LocationDropdownItem = DropdownItem<ServiceArea>;

export const TodoForm = () => {
    const {
        state: { user },
    } = useAppContext();
    const {
        closeModal,
        serviceProviderOptions,
        serviceOptions,
        todoEdit,
        setTodoEdit,
        onSuccess,
        externalDueDate,
        setActiveModals,
        hasBeenEdited,
        occupantReportsPopulate,
        description,
        passedMediaList,
        passedShiftsList,
        verificationId,
    } = useTodoFormContext();

    let workOrder;

    const isAttachedToWorkOrder = useWorkOrderModalContext();

    if (isAttachedToWorkOrder) {
        const { workOrder: workOrderModalContext } = useWorkOrderModalContext();
        workOrder = workOrderModalContext;
    }

    const [submitError, setSubmitError] = useState(false);
    const [buildings, setBuildings] = useState<LocationDropdownItem[]>([]);
    const [floors, setFloors] = useState<LocationDropdownItem[]>([]);
    const [rooms, setRooms] = useState<LocationDropdownItem[]>([]);
    const [shifts, setShifts] = useState<Shift[]>([]);
    const [selectedShifts, setSelectedShifts] = useState<Shift[]>(
        passedShiftsList || []
    );
    const [isFloorsLoading, setIsFloorsLoading] = useState(false);
    const [isRoomsLoading, setIsRoomsLoading] = useState(false);
    const subscriptions = useRef<{ [key: string]: Subscription }>({});
    const bp = useBreakpoints();

    // locations will either be existing todo location
    // if no todo location then get WO location instead
    const locations = useLocationsRef(
        todoEdit?.location || workOrder?.location
    );

    useEffect(() => {
        subscriptions.current.get_shifts = shiftsService
            .getShifts({
                limit: 1000,
                offset: 0,
            })
            .subscribe({
                next: (res) => {
                    if (res?.results) setShifts(res.results);
                },
                error: sendError(),
            });

        subscriptions.current.get_buildings = serviceAreasService
            .getServiceAreas({
                quick_landing: true,
                limit: 1000,
            })
            .subscribe({
                next: (res) => {
                    if (res?.results) setBuildings(res.results);
                },
                error: sendError(),
            });

        // set todo with WO if no existing todo location
        if (!todoEdit?.location?.id && workOrder?.location?.id) {
            setTodoEdit({
                ...todoEdit,
                location: workOrder.location,
            });
        }

        if (locations.building) {
            setIsFloorsLoading(true);
        }
        if (locations.floor) {
            setIsRoomsLoading(true);
        }

        return () =>
            Object.values(subscriptions.current).forEach((subscription) =>
                subscription.unsubscribe()
            );
    }, []);

    useEffect(() => {
        if (!isFloorsLoading || locations.building?.id == undefined) return;

        subscriptions.current.get_floors = serviceAreasService
            .getServiceAreas({
                parent: locations.building.id,
                quick_landing: true,
                limit: 1000,
            })
            .subscribe({
                next: (res) => {
                    setFloors(res.results);
                    setIsFloorsLoading(false);
                },
                error: (err) =>
                    sendError({
                        toastMessage: err.error
                            ? err.error
                            : 'Cannot retrieve getFloorList API information',
                        callback: () => {
                            setIsFloorsLoading(false);
                        },
                    }),
            });

        return () => {
            subscriptions.current.get_floors.unsubscribe();
        };
    }, [isFloorsLoading]);

    useEffect(() => {
        if (!isRoomsLoading || locations.floor?.id == undefined) return;

        subscriptions.current.get_rooms = serviceAreasService
            .getServiceAreas({
                parent: locations.floor.id,
                quick_landing: true,
                limit: 1000,
            })
            .subscribe({
                next: (res) => {
                    setRooms(res.results);
                    setIsRoomsLoading(false);
                },
                error: (err) =>
                    sendError({
                        toastMessage: err.error
                            ? err.error
                            : 'Cannot retrieve getRoomList API information',
                        callback: () => {
                            setIsRoomsLoading(false);
                        },
                    }),
            });

        return () => {
            subscriptions.current.get_rooms.unsubscribe();
        };
    }, [isRoomsLoading]);

    function handleServiceProviderChange(
        selectedProvider: DropdownItem<Contract>
    ) {
        setTodoEdit({
            ...todoEdit,
            contract: selectedProvider,
        });
        hasBeenEdited.current = true;
    }

    function handleServiceChange(selectedService: DropdownItem<Service>) {
        setTodoEdit({
            ...todoEdit,
            service: selectedService,
        });
        hasBeenEdited.current = true;
    }

    function handleAssignedToSelect(employee: Employee) {
        setTodoEdit({
            ...todoEdit,
            assigned_to: {
                id: employee.id,
            },
        });
        setSelectedShifts(employee.shifts);
        hasBeenEdited.current = true;
    }

    function handleProjectWorkChange(
        selectedProjectWork: DropdownItem<Service>
    ) {
        setTodoEdit({
            ...todoEdit,
            is_project_work: selectedProjectWork?.name === 'Yes',
        });
        hasBeenEdited.current = true;
    }

    useEffect(() => {
        if (occupantReportsPopulate) {
            autoSelectTime();
        }
    }, []);

    function autoSelectTime() {
        setTodoEdit({
            ...todoEdit,
            due_time: TimeUtils.format(
                TimeUtils.addMinutes(externalDueDate, 60),
                'HH:mm'
            ),
        });
        handleDueDateSelect(TimeUtils.addMinutes(externalDueDate, 60));
    }

    function handleDueDateSelect(dueDate: Date) {
        setTodoEdit({
            ...todoEdit,
            due_date: dueDate.toISOString(),
        });
        hasBeenEdited.current = true;
    }

    function handleDueTimeSelect(e: React.ChangeEvent<HTMLInputElement>) {
        const prevTime = todoEdit?.due_date
            ? new Date(todoEdit.due_date)
            : TimeUtils.endOfDay(new Date());

        const [hour, minute] = e.target.value.split(':');

        setTodoEdit({
            ...todoEdit,
            due_date: e.target.value
                ? TimeUtils.setMinutes(
                      TimeUtils.setHours(
                          TimeUtils.startOfDay(prevTime),
                          parseInt(hour, 10)
                      ),
                      parseInt(minute, 10)
                  ).toISOString()
                : '',
        });
        hasBeenEdited.current = true;
    }

    function handleShiftsChange(shifts) {
        setTodoEdit({
            ...todoEdit,
            shifts,
        });
        setSelectedShifts(shifts);
        hasBeenEdited.current = true;
    }

    function handleLocationChange(
        location: LocationDropdownItem,
        type: 'BUILDING' | 'FLOOR' | 'ROOM'
    ) {
        let newLocation = location;

        if (type == 'FLOOR') {
            newLocation = {
                ...location,
                ...(locations?.building?.id
                    ? { parent: locations.building }
                    : {}),
            };
        } else if (type == 'ROOM') {
            newLocation = {
                ...location,
                ...(locations?.floor?.id && locations?.building?.id
                    ? {
                          parent: {
                              ...locations.floor,
                              parent: locations.building,
                          },
                      }
                    : {}),
            };
        }

        setTodoEdit({
            ...todoEdit,
            location: newLocation,
        });

        hasBeenEdited.current = true;
    }

    function handleTitleChange(e: React.ChangeEvent<HTMLInputElement>) {
        setTodoEdit({
            ...todoEdit,
            title: e.target.value,
        });
        hasBeenEdited.current = true;
    }

    function handleDescriptionChange(
        e: React.ChangeEvent<HTMLTextAreaElement>
    ) {
        setTodoEdit({
            ...todoEdit,
            comment: e.target.value,
        });
        hasBeenEdited.current = true;
    }

    function handleAttachmentUpload(attachment: Media) {
        const mediaList = todoEdit?.media_list ?? [];
        setTodoEdit({
            ...todoEdit,
            media_list: [...mediaList, attachment],
        });
        hasBeenEdited.current = true;
    }

    function deleteAttachment(attachmentId: number) {
        setTodoEdit({
            ...todoEdit,
            media_list: todoEdit.media_list.filter(
                (media) => media.id === attachmentId
            ),
        });
        hasBeenEdited.current = true;
    }

    function isValidDueDate() {
        if (occupantReportsPopulate) {
            // handle occupant report auto fill
            return !!todoEdit?.due_date;
        }

        if (externalDueDate && todoEdit?.due_date) {
            const tdDueDate = new Date(todoEdit.due_date);

            return TimeUtils.isBefore(tdDueDate, externalDueDate);
        }
        return !!todoEdit?.due_date;
    }

    useEffect(() => {
        if (description) {
            setTodoEdit({
                ...todoEdit,
                comment: description ?? '',
            });
        }
    }, []);

    function handleSubmit() {
        const requiredPayload: {
            site: number;
            service: number;
            assignedTo: number;
            title: string;
            location: number;
            description: string;
        } = {
            site: todoEdit.contract?.id,
            service: todoEdit.service?.id,
            assignedTo: todoEdit.assigned_to?.id,
            title: todoEdit.title,
            location: todoEdit.location?.id,
            description: !isEqual(todoEdit?.comment, description)
                ? todoEdit?.comment
                : description,
        };
        if (isValidTodo(requiredPayload) && isValidDueDate()) {
            setSubmitError(false);
            const todoPayload = createTodoPayload({
                ...todoEdit,
                comment: !isEqual(todoEdit?.comment, description)
                    ? todoEdit?.comment
                    : description,
                work_order_id: workOrder?.id,
                verification_id: verificationId,
                shifts: selectedShifts,
                is_project_work:
                    isAttachedToWorkOrder?.workOrderEdit?.is_project_work ||
                    isAttachedToWorkOrder?.workOrder?.is_project_work ||
                    todoEdit.is_project_work,
            });

            if (todoPayload?.id != undefined) {
                subscriptions.current.update_todo = todosService
                    .updateTodoById(todoPayload.id, todoPayload)
                    .subscribe({
                        next: (todo) => {
                            toasterService.newToast({
                                status: 'success',
                                message: 'Todo successfully updated',
                            });
                            if (onSuccess) onSuccess(todo);
                            closeModal();
                        },
                        error: sendError(),
                    });
            } else {
                todoPayload.creator.id = user.id;
                subscriptions.current.create_todo = todosService
                    .createTodo(todoPayload)
                    .subscribe({
                        next: (todo) => {
                            toasterService.newToast({
                                status: 'success',
                                message: 'Todo successfully created',
                            });
                            if (onSuccess) onSuccess(todo);
                            closeModal();
                        },
                        error: sendError(),
                    });
            }
        } else {
            setSubmitError(true);
        }
    }

    function handleClose() {
        if (!hasBeenEdited.current) {
            closeModal();
        } else {
            setActiveModals({
                'cancel-todo': true,
            });
        }
    }

    const debouncedTitleChange = debounce(handleTitleChange, 500);
    const debouncedDescriptionChange = debounce(handleDescriptionChange, 500);

    const projectWorkItems = [
        { id: 0, name: 'No' },
        { id: 1, name: 'Yes' },
    ];

    return (
        <ModalStack onOverlayClick={handleClose} hasSpacingY>
            <Modal.Generic
                title={
                    todoEdit?.id != undefined
                        ? `Edit To-do #${todoEdit.id}`
                        : 'Create To-Do'
                }
                onCancel={handleClose}
                className="tw-mx-auto tw-w-full tw-space-y-6 tw-overflow-y-scroll tw-relative"
                style={{
                    maxWidth: '640px',
                    maxHeight: '100vh',
                    top: `${bp.sm ? '-48px' : ''}`,
                }}
            >
                <div className="tw-w-full tw-space-y-6 sm:tw-space-y-0 sm:tw-gap-4 sm:tw-grid sm:tw-grid-cols-2">
                    <FieldDropdown
                        required
                        id="todo_service_provider"
                        label="Service Provider"
                        items={serviceProviderOptions}
                        selectedItem={
                            serviceProviderOptions.find(
                                (provider) =>
                                    provider.id === todoEdit?.contract?.id
                            ) || null
                        }
                        onChange={handleServiceProviderChange}
                        error={
                            submitError &&
                            !todoEdit.contract?.id &&
                            'This field is required'
                        }
                    />

                    <FieldDropdown
                        required
                        id="todo_service_type"
                        label="Service Type"
                        items={serviceOptions}
                        selectedItem={
                            serviceOptions.find(
                                (service) =>
                                    service.id === todoEdit?.service?.id
                            ) || null
                        }
                        onChange={handleServiceChange}
                        error={
                            submitError &&
                            !todoEdit.service?.id &&
                            'This field is required'
                        }
                    />
                </div>
                <div className="tw-flex tw-w-full tw-space-x-4">
                    <FieldDropdown
                        required
                        id="todo_is_project_work"
                        label="Project Work"
                        items={projectWorkItems}
                        selectedItem={
                            isAttachedToWorkOrder?.workOrderEdit
                                ?.is_project_work ||
                            isAttachedToWorkOrder?.workOrder?.is_project_work ||
                            todoEdit?.is_project_work
                                ? projectWorkItems[1]
                                : projectWorkItems[0]
                        }
                        onChange={handleProjectWorkChange}
                        // controlled by parent workorder
                        disabled={
                            isAttachedToWorkOrder?.workOrderEdit?.id != null ||
                            isAttachedToWorkOrder?.workOrder?.id != null
                        }
                    />

                    <UserSelect
                        allowAddUser={false}
                        userType="employee"
                        title="Assigned To"
                        onSelect={handleAssignedToSelect}
                        saveError={submitError && !todoEdit.assigned_to?.id}
                        defaultValue={
                            (todoEdit && todoEdit.assigned_to?.id) || undefined
                        }
                        required
                    />
                </div>
                <div className="tw-w-full tw-space-y-6 sm:tw-space-y-0 sm:tw-gap-4 sm:tw-grid sm:tw-grid-cols-2">
                    <FieldInputDate
                        required
                        id="todo_due_date"
                        label="Due Date"
                        value={
                            todoEdit?.due_date
                                ? TimeUtils.format(
                                      todoEdit.due_date,
                                      'MM/DD/YYYY'
                                  )
                                : null
                        }
                        onChange={handleDueDateSelect}
                        error={
                            submitError &&
                            !isValidDueDate() &&
                            !todoEdit?.due_date
                                ? 'This field is required'
                                : todoEdit?.due_date && !isValidDueDate()
                                  ? `To-Do due date cannot be later than ${
                                        externalDueDate
                                            ? TimeUtils.format(
                                                  externalDueDate,
                                                  'M/D/YYYY hh:ss A'
                                              )
                                            : ''
                                    } due date`
                                  : undefined
                        }
                    />
                    <FieldInputTime
                        required
                        id="todo_due_time"
                        label="Due At"
                        onChange={handleDueTimeSelect}
                        defaultValue={
                            occupantReportsPopulate
                                ? TimeUtils.format(
                                      TimeUtils.addMinutes(externalDueDate, 60),
                                      'HH:mm'
                                  )
                                : todoEdit?.due_date
                                  ? TimeUtils.format(todoEdit.due_date, 'HH:mm')
                                  : ''
                        }
                    />
                </div>
                <FieldInputText
                    required
                    id="todo_title"
                    label="Todo Title"
                    defaultValue={todoEdit?.title ?? ''}
                    onChange={debouncedTitleChange}
                    error={
                        submitError &&
                        !todoEdit.title &&
                        'This field is required'
                    }
                />
                <div className="tw-w-full tw-space-y-6">
                    <FieldDropdown
                        id="todo_building"
                        label="Building"
                        items={buildings}
                        selectedItem={
                            buildings.find(
                                (b) => b.id === locations.building?.id
                            ) || null
                        }
                        onChange={(building: LocationDropdownItem) => {
                            handleLocationChange(building, 'BUILDING');
                            setFloors([]);
                            setRooms([]);
                            setIsFloorsLoading(true);
                        }}
                        disabled={!buildings.length}
                    />

                    <FieldDropdown
                        id="todo_floor"
                        label="Floor"
                        items={floors}
                        selectedItem={
                            floors.find(
                                (floor) => floor.id === locations.floor?.id
                            ) || null
                        }
                        onChange={(floor: LocationDropdownItem) => {
                            handleLocationChange(floor, 'FLOOR');
                            setRooms([]);
                            setIsRoomsLoading(true);
                        }}
                        disabled={!floors.length}
                        error={
                            submitError &&
                            !todoEdit.location &&
                            'This field is required'
                        }
                    />

                    <FieldDropdown
                        id="todo_room"
                        label="Area (Optional)"
                        items={rooms}
                        selectedItem={
                            rooms.find(
                                (room) => room.id === locations.room?.id
                            ) || null
                        }
                        onChange={(room: LocationDropdownItem) => {
                            handleLocationChange(room, 'ROOM');
                        }}
                        disabled={!rooms.length}
                        error={
                            submitError &&
                            !todoEdit.location &&
                            'This field is required'
                        }
                    />
                </div>
                <FieldMultiSelect
                    id="shifts"
                    label="Shifts"
                    items={shifts}
                    selectedItems={selectedShifts}
                    onChange={handleShiftsChange}
                    disabled
                    selectAllFont="h4"
                    placeholder="Search"
                    labelFont="body-md"
                />
                <FieldInputText
                    required
                    fieldType="textarea"
                    id="todo_description"
                    label="Description"
                    defaultValue={description || (todoEdit?.comment ?? '')}
                    onChange={debouncedDescriptionChange}
                    max={4096}
                    rows={10}
                    error={
                        submitError &&
                        !todoEdit.comment &&
                        'This field is required'
                    }
                />
                <AddAttachment
                    accept="images/*"
                    buttonProps={{
                        label: 'Add Photo',
                        color: 'alternate',
                    }}
                    onDelete={deleteAttachment}
                    onUpload={handleAttachmentUpload}
                    attachments={
                        occupantReportsPopulate
                            ? passedMediaList?.map((m) => ({
                                  ...m,
                                  isAfter: false,
                              }))
                            : todoEdit?.media_list?.map((m) => ({
                                  ...m,
                                  isAfter: false,
                              })) ?? []
                    }
                />
                <div className="tw-flex tw-gap-4 sm:tw-justify-end">
                    <Button
                        label="Cancel"
                        onClick={handleClose}
                        color="secondary"
                    />
                    <Button
                        label={
                            todoEdit?.id != undefined
                                ? `Save Todo`
                                : 'Create To-Do'
                        }
                        onClick={handleSubmit}
                        className="tw-w-full sm:tw-w-auto tw-flex tw-justify-center"
                        color="primary"
                    />
                </div>
            </Modal.Generic>
        </ModalStack>
    );
};
