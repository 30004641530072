import { Employee } from '@api-interfaces';

// components
import { Text, Avatar, Icon } from '@atoms';
import { getFullName } from '@helpers/strings';

interface Props {
  onClick?: (user: Employee) => void;
  user: Employee;
  selectedUser?: Employee;
  hover?: boolean;
  className?: string;
}

export const UserSelectRow = ({
  onClick,
  user,
  hover = true,
  className = 'tw-py-2 tw-px-3',
  selectedUser,
}: Props) => {
  const isSelected = selectedUser?.id == user.id;
  return (
    <li
      onClick={() => onClick && onClick(user)}
      key={user.id}
      data-id={user.id}
      className={`tw-flex tw-items-center tw-text-sm tw-h-full ${
        hover
          ? ' hover:tw-bg-neutral-200 dark:hover:tw-bg-neutral-700 last:tw-rounded-br last:tw-rounded-bl active:tw-bg-primary-500 active:tw-bg-opacity-15'
          : ''
      }
                 ${
                   isSelected
                     ? 'tw-bg-theme-primary-500-300 tw-bg-opacity-15'
                     : ''
                 } ${className}`}
    >
      <span className="tw-flex tw-items-center tw-w-full">
        <div className="tw-flex tw-flex-shrink-0">
          <Avatar size="sm" person={user.person} type="primary" />
        </div>
        <div className="tw-flex tw-items-baseline tw-truncate">
          <Text
            className="tw-flex-auto tw-whitespace-nowrap tw-ml-2"
            font="body-md"
            color="hi-contrast"
            tag="span"
          >
            {getFullName(user.person)}
          </Text>
          <Text
            className="tw-flex-auto tw-ml-1 dark:tw-text-neutral-500 tw-text-neutral-600 tw-truncate"
            font="body-sm"
            color="neutral-offset"
            tag="span"
          >
            {user.person.email_address}
          </Text>
        </div>
      </span>
      {isSelected && (
        <Icon
          icon={['far', 'check']}
          className="tw-text-theme-primary-500-300 tw-mr-4 tw-text-xl tw-font-black"
        />
      )}
    </li>
  );
};
