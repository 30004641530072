/** ************************************************
 * Imports
 ************************************************* */

import { CardGoalProps } from './CardGoal.interfaces';

import './CardGoal.scss';

/** ************************************************
 * Render Component
 ************************************************* */

export const CardGoal = (props: CardGoalProps) => {
  const { className = '', goal } = props;

  return (
    <span
      className={`_new-cards-contents-goals tw-absolute tw-text-neutral-600 dark:tw-text-neutral-500 ${className}`}
    >
      / {goal}
    </span>
  );
};
