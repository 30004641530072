import { distanceInWordsStrict } from 'date-fns';
import { useState } from 'react';

// components
import { Icon, Text } from '@atoms';

import { IComment } from '../../comment.utils';

type IFooter = Pick<
  IComment,
  'toggleChildren' | 'showReply' | 'childCount' | 'isReplying' | 'className'
> & {
  comment: Pick<IComment['comment'], 'comment_date' | 'parent'>;
};

export const Footer = ({
  comment,
  toggleChildren,
  showReply,
  childCount,
  isReplying,
  className,
}: IFooter) => {
  const [childrenLoaded, setChildrenLoaded] = useState(false);

  function formatDate() {
    let dateDistance = distanceInWordsStrict(comment.comment_date, new Date());
    if (dateDistance.includes('minutes')) {
      dateDistance = dateDistance.replace('minutes', 'm');
    }
    if (dateDistance.includes('seconds')) {
      dateDistance = dateDistance.replace('seconds', 's');
    }
    if (dateDistance.includes('second')) {
      dateDistance = dateDistance.replace('second', 's');
    }
    if (dateDistance.includes('minute')) {
      dateDistance = dateDistance.replace('minute', 'm');
    }
    if (dateDistance.includes('hours')) {
      dateDistance = dateDistance.replace('hours', 'h');
    }
    if (dateDistance.includes('hour')) {
      dateDistance = dateDistance.replace('hour', 'h');
    }
    if (dateDistance.includes('day')) {
      dateDistance = dateDistance.replace('day', 'd');
    }
    if (dateDistance.includes('ds')) {
      dateDistance = dateDistance.replace('ds', 'd');
    }
    if (dateDistance.includes('months')) {
      dateDistance = dateDistance.replace('months', 'M');
    }
    if (dateDistance.includes('month')) {
      dateDistance = dateDistance.replace('month', 'M');
    }
    if (dateDistance.includes('year')) {
      dateDistance = dateDistance.replace('year', 'Y');
    }
    if (dateDistance.includes('years')) {
      dateDistance = dateDistance.replace('years', 'Y');
    }
    return dateDistance.replace(' ', '');
  }

  const showChildren = () => {
    setChildrenLoaded(!childrenLoaded);
    toggleChildren();
  };

  const arrowIcon = () => {
    if (childrenLoaded) {
      return 'caret-up';
    }
    return 'caret-down';
  };

  return (
    <div
      className={`tw-flex tw-pt-2 tw-space-x-1 tw-items-center ${className}`}
    >
      {!comment.parent && !!showReply && (
        <Text
          className={`${
            isReplying ? 'tw-opacity-15' : ''
          } tw-cursor-pointer tw-font-normal tw-normal-case tw-px-2 tw-rounded hover:tw-bg-theme-primary-500-300 
                    hover:tw-bg-opacity-15 dark:hover:tw-bg-opacity-15`}
          font="body-sm"
          color="primary"
          onClick={showReply}
        >
          <Icon icon="reply" className="tw-mr-1" />
          Reply
        </Text>
      )}
      {childCount > 0 && (
        <Text
          className="tw-cursor-pointer tw-font-normal tw-normal-case tw-px-2 tw-rounded
                    hover:tw-bg-theme-primary-500-300 hover:tw-bg-opacity-15
                    dark:hover:tw-bg-opacity-15"
          font="body-sm"
          color="primary"
          onClick={showChildren}
        >
          <Icon className="tw-mr-1" icon={arrowIcon()} />
          {childrenLoaded ? 'Hide' : 'Show'} Replies ({childCount})
        </Text>
      )}
      <div>
        <Text font="body-sm" color="neutral-offset-white">
          {formatDate()}
        </Text>
      </div>
    </div>
  );
};
