import { Observable } from 'rxjs';

// services
import { WorkDaySummary, WorkDaySummaryParams } from '@api-interfaces';
import { Params, TimeUtils } from '@core/helpers';
import { virtualBrownClient } from '@core/services';

// helpers

// interfaces

// constants
const BASE_URL = `workdays/summary`;
const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

class WorkDaysSummaryService {
	getSummaries<T extends WorkDaySummary.Chart>(
		params: WorkDaySummaryParams<T>
	): Observable<WorkDaySummary.SummaryReturnType[T][]> {
		const query: any = {
			...params,
			chart: params.chart.join(','),
			time_zone: params.time_zone || timeZone,
		};
		if (params.chart.includes(WorkDaySummary.Chart.GetDailyStats as T)) {
			if (query.from) {
				query.from = TimeUtils.format(query.from, 'YYYY-MM-DD');
			}
			if (query.to) {
				query.to = TimeUtils.format(query.to, 'YYYY-MM-DD');
			}
		}
		return virtualBrownClient.get(
			`${BASE_URL}/${Params.makeQueryString(query)}`,
			{
				noCustomer: params.customer !== undefined,
				noContract: params.contract !== undefined,
				noTag: params.tag !== undefined,
			}
		);
	}
}

export const workDaysSummaryService = new WorkDaysSummaryService();
