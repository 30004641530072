import { OrgLogoProps } from '@atoms/org-logo/org-logo.interfaces';
import { TextProps } from '@atoms/text/text.interfaces';

export const orgLogoSizeMap: {
	[key in NonNullable<OrgLogoProps['size']>]: string;
} = {
	sm: 'tw-h-10 tw-w-10',
	md: 'tw-h-14 tw-w-14',
};

export const orgLogoFontMap: {
	[key in NonNullable<OrgLogoProps['size']>]: TextProps['font'];
} = {
	sm: 'h2',
	md: 'h1',
};
