import { Text } from '@atoms';

// interfaces
import { SubmitButtonProps } from './submit-button.interfaces';

export const SubmitButton = ({
  label,
  className,
  ...props
}: SubmitButtonProps) => {
  return (
    <button
      data-ds2="submit-button"
      type="submit"
      className={`tw-h-10 tw-px-3 tw-rounded tw-bg-theme-primary-500-300 tw-text-neutral-100-900
      hover:tw-bg-theme-primary-300-100 active:tw-bg-theme-primary-900-700
      disabled:tw-opacity-50 disabled:tw-cursor-not-allowed
      ${className}`}
      {...props}
    >
      <Text font="h4" color="hi-contrast-inverted">
        {label || 'Submit'}
      </Text>
    </button>
  );
};

SubmitButton.defaultProps = {
  className: '',
};
