import { Params, TimeUtils } from '@helpers';
import { virtualBrownClient } from '@services';
import { PaginationParams, PaginationResponse } from '@core/models';
import {
	GetSalesOrdersLineItemsParams,
	GetSalesOrdersParams,
	SalesOrders,
	SalesOrdersApprovalPayload,
	SalesOrdersLineItemsSummary,
	SalesOrdersMultiClient,
} from './interfaces';

const BASE_URL = 'financials/salesorders';

function getSalesOrders(params: GetSalesOrdersParams) {
	const query: {
		from?: string | Date;
		to?: string | Date;
	} & PaginationParams = {
		...params,
	};
	if (params?.from) {
		query.from = TimeUtils.format(params.from, 'YYYY-MM-DD');
	}
	if (params?.to) {
		query.to = TimeUtils.format(params.to, 'YYYY-MM-DD');
	}
	return virtualBrownClient.get<
		PaginationResponse<SalesOrders | SalesOrdersMultiClient>
	>(`${BASE_URL}/${Params.makeQueryString(query)}`);
}

function getSalesOrdersLineItems(params: GetSalesOrdersLineItemsParams) {
	return virtualBrownClient.get<SalesOrdersLineItemsSummary>(
		`${BASE_URL}lineitems/${Params.makeQueryString(params)}`
	);
}

function updateSalesOrder(id: number, payload: SalesOrdersApprovalPayload) {
	return virtualBrownClient.post<SalesOrders>(
		`${BASE_URL}/${id}/update/`,
		payload
	);
}
class FinancialSalesOrdersService {
	getSalesOrders = getSalesOrders;

	getSalesOrdersLineItems = getSalesOrdersLineItems;

	updateSalesOrder = updateSalesOrder;
}

export const financialSalesOrdersService = new FinancialSalesOrdersService();
