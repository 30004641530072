import { TodoForm as Form } from './_components/form';
import { TodoCancelPrompt } from './_components/todo-cancel-prompt';
import { TodoFormProvider, useTodoFormContext } from './_context';

// interfaces
import { TodoFormProps } from './_context/todo-form.context.interfaces';

export const TodoForm = (props: TodoFormProps) => {
  return (
    <TodoFormProvider {...props}>
      <TodoFormConsumer />
    </TodoFormProvider>
  );
};

const TodoFormConsumer = () => {
  const { isLoading } = useTodoFormContext();

  return (
    !isLoading && (
      <>
        <Form />
        <TodoCancelPrompt />
      </>
    )
  );
};
