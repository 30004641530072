import { useState, useEffect } from 'react';
import * as React from 'react';

// interfaces
import { Text, IconButton } from '@atoms';
import { ButtonProps } from '@new/Button/button.interfaces';

// components

interface Props {
  value?: number;
  onChange?: (year: number) => void;
  className?: string;
  style?: React.CSSProperties;
  buttonType?: ButtonProps['color'];
  disabled?: boolean;
  allowFutureDates?: boolean;
}

export const YearPicker = ({
  value,
  onChange,
  className,
  style,
  disabled,
  allowFutureDates,
  buttonType,
}: Props) => {
  const CURRENT_YEAR = new Date().getFullYear();
  const [year, setYear] = useState<number>(
    allowFutureDates || value <= CURRENT_YEAR ? value : CURRENT_YEAR
  );
  const [disableNext, setDisableNext] = useState<boolean>(
    allowFutureDates ? false : value == CURRENT_YEAR
  );

  useEffect(() => {
    if (value != year) {
      setYear(allowFutureDates || value <= CURRENT_YEAR ? value : CURRENT_YEAR);
    }
  }, [value]);

  useEffect(() => {
    if (!allowFutureDates) {
      if (year == CURRENT_YEAR) {
        setDisableNext(true);
      } else if (disableNext) {
        setDisableNext(false);
      }
    }
  }, [year]);

  return (
    <div
      className={`year-picker ${
        disabled ? 'tw-opacity-50 tw-pointer-events-none' : ''
      } ${className}`}
      style={style}
    >
      <IconButton
        type={buttonType}
        icon={['fas', 'caret-left']}
        iconSize="1x"
        onClick={() => {
          if (year > 1) {
            setYear(year - 1);
            onChange(year - 1);
          }
        }}
        style={{ width: 30 }}
      />
      <Text font="h4" color="primary" className="tw-px-2">
        {year}
      </Text>
      <IconButton
        type={buttonType}
        icon={['fas', 'caret-right']}
        iconSize="1x"
        disabled={disableNext}
        onClick={() => {
          if (allowFutureDates || year < CURRENT_YEAR) {
            setYear(year + 1);
            onChange(year + 1);
          }
        }}
        style={{ width: 30 }}
      />
    </div>
  );
};

YearPicker.defaultProps = {
  value: new Date().getFullYear(),
  onChange: () => {},
  className: 'tw-flex tw-items-center tw-rounded tw-bg-neutral-100-800',
  style: {},
  buttonType: 'secondary',
  disabled: false,
  allowFutureDates: true,
};
