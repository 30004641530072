// interfaces
import { Observable } from 'rxjs';

import {
	Request,
	RequestPayload,
	RequestSummaryChart,
	ParamsGetRequests,
} from '@api-interfaces';

// services
import {
	virtualBrownClient,
	IDjangoResponse,
} from '@core/services/django-client';

// helpers
import { Params, TimeUtils } from '@helpers';
import { CommonParams, PaginationResponse } from '@models';

import { requestsSummaryService } from './summary';

const defaultTo = TimeUtils.formatToISO(new Date());
const defaultFrom = TimeUtils.formatToISO(TimeUtils.subMonths(new Date(), 6));

// constants
const BASE_URL = 'requests';

class RequestsService {
	readonly summary = requestsSummaryService;

	public getRequests(params: ParamsGetRequests) {
		const query: any = params;
		if (params.from) {
			query.from = TimeUtils.startOfDay(params.from).toISOString();
		}
		if (params.to) {
			query.to = TimeUtils.endOfDay(params.to).toISOString();
		}

		const queryString = Params.makeQueryString(query);
		return virtualBrownClient.get<PaginationResponse<Request>>(
			`${BASE_URL}/${queryString}`,
			{
				noContract: !!params?.contract?.toString(),
				noCustomer: !!params?.customer?.toString(),
				noTag: !!params?.tag?.toString(),
			}
		);
	}

	public createRequest(params: { payload: RequestPayload }) {
		return virtualBrownClient.post<Request>(`${BASE_URL}/`, params.payload);
	}

	public getRequestById(params: { id: number }) {
		return virtualBrownClient.get<Request>(`${BASE_URL}/${params.id}/`);
	}

	public updateRequest(params: { id: number; payload: RequestPayload }) {
		return virtualBrownClient.put<Request>(
			`${BASE_URL}/${params.id}/`,
			params.payload
		);
	}

	public patchRequest(params: { id: number; payload: RequestPayload }) {
		return virtualBrownClient.patch<Request>(
			`${BASE_URL}/${params.id}/`,
			params.payload
		);
	}

	public deleteRequest(params: { id: number }) {
		return virtualBrownClient.delete(`${BASE_URL}/${params.id}/`);
	}

	public getRequestsSummary(
		params: {
			chart?: string; // e.g. '0' or '0,1'
		} & CommonParams
	): Observable<any> {
		const query = params;
		const queryString = Params.makeQueryString(query);

		return virtualBrownClient.get<RequestSummaryChart[]>(
			`${BASE_URL}/summary/${queryString}`
		);
	}

	public getEmployeeData = (
		params = {
			to: defaultTo,
			from: defaultFrom,
			order_by: 'name',
		}
	) => {
		const { to, from, order_by } = params;

		const query = {
			limit: 500,
			to,
			from,
			order_by,
		};

		const qs = Params.makeQueryString(query);
		return virtualBrownClient.get<IDjangoResponse<any>>(
			`requests/employees/${qs}`,
			{ noCustomer: true }
		);
	};
}

export const requestsService = new RequestsService();
