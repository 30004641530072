import * as React from 'react';

export type ModalSize =
	| 'sm'
	| 'md'
	| 'lg'
	| 'xl'
	| 'full'
	| 'stretch'
	| 'full-stretch';

export type ModalCallbackParams = {
	[key: string]: any;
};

export interface ModalOptions {
	component: React.ReactNode;
	size?: ModalSize;
	width?: string;
	callback?: (args?: ModalCallbackParams) => void;
	passPropsOnClose?: object | (() => object);
	customCloseHandler?: (...args: Array<any>) => any;
	containerId?: string;
	backdrop?: boolean;
	hideCloseButton?: boolean;
	showDefaultBackground?: boolean; // true by default
	closeAllOnBackground?: boolean;
	isFullWidth?: boolean;
	isDs2?: boolean; // if true prevents old one's border radius from overflowing
}

export type ModalState = {
	[key: string]: any;
};

// @TODO: Simplify modalService so that it only needs to send 1 parameter for bottom sheet modal,
// then we wouldn't need this. Or we can create a new BottomSheetModal component.
export const DS2_BOTTOM_SHEET_OPTIONS: Omit<
	ModalOptions,
	'component' | 'callback'
> = {
	size: 'full',
	hideCloseButton: true,
	isFullWidth: true,
	isDs2: true,
};
