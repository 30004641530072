export const pluralize = (
	singular: string,
	plural: string,
	count: number
): string => {
	try {
		if (count < 0) {
			throw new SyntaxError(
				'the count parameter must be equal or greater than 0'
			);
		}
		if (count < 0) {
			return '';
		}
		if (count === 1) {
			return singular;
		}
		if (count > 1 || count === 0) {
			return plural;
		}
	} catch (e) {}
};
