import { R4PortfolioCustomerOrganization } from '@core/models';

export const LIMIT = 5000;

// client and site values for select-all workflow - needs BE, may need to be updated after
export const ALL_CLIENTS_ITEM: R4PortfolioCustomerOrganization[] = [
	{
		id: 0,
		name: 'All Clients',
		type: 'CUSTOMER',
		correlation_id: '0',
		addresses: [],
		status: 'Unsuspended',
		organization_contract_group_types: null,
	},
];

export const ALL_SITES_ITEM = [
	{
		id: 0,
		name: 'All Sites',
		correlation_id: '',
		status: 'Unsuspended',
		organization_contract_group_types: null,
		customer_name: 'All Sites',
		customer: 0,
		has_SOW: false,
		chatbot_is_active: false,
		chatbot_sms_phone_number: '',
		chatbot_sms_country_code: '',
		service_provider: null,
		use_area_links: false,
		uuid: '',
		local_timezone: '',
	},
];

export const ALL_EMPLOYEES_ITEM = [{ id: null, name: 'All Employees' }];
