import { Employee as IEmployee } from '@api-interfaces';

import { Person } from './person';

export class Employee extends Person {
	protected _employee: IEmployee;

	constructor(_employee: IEmployee) {
		super(_employee.person);
		this.employee = _employee;
	}

	set employee(employee: IEmployee) {
		this._employee = employee;
	}

	get employee() {
		return this._employee;
	}

	get employeeId() {
		return this.employee.id;
	}

	get organization() {
		return this.employee.organization;
	}

	get positions() {
		return this.employee.positions || [];
	}

	get shifts() {
		return this.employee.shifts || [];
	}

	set manager(manager) {
		this.employee.manager = manager;
	}

	get manager(): null | object {
		return this.employee.manager;
	}

	get language() {
		return this.employee.language_preference;
	}
}
