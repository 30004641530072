import { useState, useEffect } from 'react';

import { CommentPayload, Comment as CommentInterface } from '@api-interfaces';
import { commentService } from '@apis';
import { toasterService } from '@core/services';

// hooks
import { useAppContext } from '@hooks';

// utils
import { IThread } from '../../comment.utils';
import { Comment } from '../Comment';

// interfaces

export const Thread = ({
  comment,
  item_id,
  editComment,
  deleteComment,
  submitterPhoneNumber,
}: IThread) => {
  const {
    state: { user },
  } = useAppContext();

  const [children, setChildren] = useState([]);
  const [addedChildren, setAddedChildren] = useState([]);
  const [count, setCount] = useState(comment.child_comments);

  useEffect(() => {
    setCount(comment.child_comments);
  }, [comment]);

  const toggleChildren = () => {
    setAddedChildren([]);
    if (children.length > 0) {
      setChildren([]);
    } else {
      commentService
        .getComments({
          limit: 100,
          parent: comment.id,
          work_item: item_id,
          work_item_type: comment.channel.work_item_type,
        })
        .subscribe({
          next: (res) => {
            setChildren(res.results);
            setCount(res.count);
          },
          error: (err) => {
            toasterService.newToast({
              status: 'fail',
              message: 'Could not load child comments.',
            });
          },
        });
    }
  };

  const createChild = (input: string) => {
    const payload: CommentPayload = {
      comment: {
        commenter: {
          id: user.employeeId,
        },
        body: input,
        work_item: {
          type: comment.channel.work_item_type.toLowerCase(),
          id: item_id,
        },
        submitter_phone_number: submitterPhoneNumber,
        parent_comment: comment.id,
      },
    };

    commentService.createComment(payload).subscribe({
      next: (res) => {
        setAddedChildren([res, ...addedChildren]);
        setCount(count + 1);
      },
      error: (err) => {
        toasterService.newToast({
          status: 'fail',
          message: 'Could not create reply.',
        });
      },
    });
  };

  const deleteChildComment = (id: number) => {
    commentService.deleteCommentById(id).subscribe({
      next: () => {
        toasterService.newToast({
          status: 'success',
          message: 'The comment was deleted successfully.',
        });
        // update children
        const newChildren = children.filter((child) => child.id !== id);
        const newAddedChildren = addedChildren.filter(
          (child) => child.id !== id
        );
        setChildren(newChildren);
        setAddedChildren(newAddedChildren);
        setCount(count - 1);
      },
      error: () => {
        toasterService.newToast({
          status: 'fail',
          message: 'There was an error trying to delete the comment.',
        });
      },
    });
  };

  return (
    <div style={{ marginTop: '25px' }}>
      <Comment
        comment={comment}
        toggleChildren={toggleChildren}
        editComment={editComment}
        createChild={createChild}
        childCount={count}
        threadToggled={children.length > 0 || addedChildren.length > 0}
        deleteComment={deleteComment}
      />

      {addedChildren.length > 0 &&
        addedChildren.map((comment: CommentInterface, index) => (
          <Comment
            key={comment.id}
            is_child
            comment={comment}
            isLastChild={
              index === addedChildren.length - 1 && children.length === 0
            }
            deleteComment={deleteChildComment}
            editComment={editComment}
          />
        ))}
      {children.length > 0 &&
        children.map((comment: CommentInterface, index) => (
          <Comment
            key={comment.id}
            is_child
            comment={comment}
            isLastChild={index === children.length - 1}
            deleteComment={deleteChildComment}
            editComment={editComment}
          />
        ))}
    </div>
  );
};
