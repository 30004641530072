import {
	SummaryChart,
	SummaryParams,
	IsPass,
	TimeScale,
	GeoLocation,
	Location,
} from '@models';

export interface CustomerSurveysSummaryParams<T> extends SummaryParams<T> {
	is_poc?: boolean; // true for poc, false or null for All and General end user
	verticals?: string | number; // list of client groups to be used in verticals charts
}

export namespace CustomerSurveysSummary {
	export enum Chart {
		GetCustomerSurveyCounts = 0,
		GetCustomerSurveyCountsByMonth = 1,
		GetCustomerSurveyCountsBySite = 2,
		GetCustomerSurveyCountsByProgram = 3,
		GetCustomerSurveyCountsByOrganization = 4,
		GetSurveyRecipientFrontPageSummary = 5,
		GetCustomerSurveyCountsByProgramByMonth = 6,
		GetCustomerSurveyCountsByVendor = 7,
		GetCustomerSurveyCountsByVerticals = 8,
		GetCustomerSurveyCountsByVerticalsOrganizationList = 9,
	}

	export interface CustomerSurveyCounts extends IsPass {
		average_score: number;
		completed_count: number;
		goal: number;
		latest_survey_date: string;
		pending_count: number;
		total_count: number;
	}

	export interface CustomerSurveyCountsSummary
		extends SummaryChart<CustomerSurveyCounts[]> {
		chart: 'GetCustomerSurveyCounts';
		number: '0';
	}

	export interface CustomerSurveyCountsByMonth {
		answer_count: number;
		avg_score: number;
		customer_avg_score: number;
		poc_avg_score: number;
		general_end_user_avg_score: number;
		completed_count: number;
		mo: string;
	}

	export interface CustomerSurveyCountsByMonthSummary
		extends SummaryChart<CustomerSurveyCountsByMonth[]> {
		chart: 'GetCustomerSurveyCountsByMonth';
		number: '1';
		overall_avg_score: number;
		time_scale: TimeScale;
	}

	export interface CustomerSurveyCountsBySite
		extends Location,
			GeoLocation,
			IsPass {
		avg_score: number;
		completed_count: number;
		customer_avg_score: number;
		general_enduser_avg_score: number;
		goal: number;
		id: number;
		last_survey_date: string;
		name: string;
		organization_name: string;
		pending_count: number;
		poc_avg_score: number;
		total_count: number;
	}

	export interface CustomerSurveyCountsBySiteSummary
		extends SummaryChart<CustomerSurveyCountsBySite[]> {
		chart: 'GetCustomerSurveyCountsBySite';
		number: '2';
	}

	export interface CustomerSurveyCountsByProgram {
		id: null | number;
		month: string;
		name: null | string;
		avg_score: null | number;
		customer_avg_score: null | number;
		poc_avg_score: null | number;
		general_end_user_avg_score: null | number;
	}

	export interface CustomerSurveyCountsByProgramSummary
		extends SummaryChart<CustomerSurveyCountsByProgram[]> {
		chart: 'GetCustomerSurveyCountsByProgram';
		number: '3';
	}

	export interface CustomersurveyCountsByOrganization {
		customer_id: number;
		customer_name: string;
		average_score: number;
		poc_avg_score: number;
		customer_avg_score: number;
		general_end_user_avg_score: number;
		complete_count: number;
		pending_count: number;
		total_count: number;
	}

	export interface CustomersurveyCountsByOrganizationSummary
		extends SummaryChart<CustomersurveyCountsByOrganization[]> {
		chart: 'GetCustomersurveyCountsByOrganization';
		number: '4';
	}

	export interface SurveyRecipientFrontPage {
		days_since: number;
		latest_date: string;
		latest_id: number;
		latest_score: number;
		latest_survey_recipient: string;
		latest_survey_recipient_initials: string;
		latest_survey_recipient_photo_url: string;
		survey_is_pass: boolean;
		is_pass: boolean;
	}

	export interface SurveyRecipientFrontPageSummary
		extends SummaryChart<SurveyRecipientFrontPage> {
		chart: 'GetSurveyRecipientFrontPageSummary';
		number: '5';
	}

	export interface CustomerSurveyCountsByProgramByMonth {
		month?: string;
		day?: string;
		results: {
			service_name: string;
			average_score: number;
			customer_avg_score: number;
			poc_avg_score: number;
			general_end_user_avg_score: number;
		}[];
	}

	export interface CustomerSurveyCountsByVendor {
		id: number;
		name: string;
		count: number;
		customer_avg_score: number;
		poc_avg_score: number;
		general_end_user_avg_score: number;
	}

	export interface CustomerSurveyCountsByProgramByMonthSummary
		extends SummaryChart<CustomerSurveyCountsByProgramByMonth[]> {
		chart: 'GetCustomerSurveyCountsByProgramByMonth';
		number: '6';
	}

	export interface CustomerSurveyCountsByVendorSummary
		extends SummaryChart<CustomerSurveyCountsByVendor[]> {
		chart: 'GetIncidentCountsByVendor';
		number: '24';
	}

	export interface CustomerSurveyCountsByVerticals {
		id: number;
		name: string;
		count: number;
		customer_avg_score: number;
		poc_avg_score: number;
		general_end_user_avg_score: number;
	}

	export interface CustomerSurveyCountsByVerticalsSummary
		extends SummaryChart<CustomerSurveyCountsByVerticals[]> {
		chart: 'GetIncidentCountsByVerticals ';
		number: '8';
	}

	export interface CustomerSurveyCountsByVerticalsOrganizationList {
		id: number;
		name: string;
		count: number;
		customer_avg_score: number;
		poc_avg_score: number;
		general_end_user_avg_score: number;
	}

	export interface CustomerSurveyCountsByVerticalsOrganizationListSummary
		extends SummaryChart<
			CustomerSurveyCountsByVerticalsOrganizationList[]
		> {
		chart: 'GetIncidentCountsByVerticalsOrganizationList';
		number: '9';
	}

	export interface SummaryReturnType {
		[Chart.GetCustomerSurveyCounts]: CustomerSurveyCountsSummary;
		[Chart.GetCustomerSurveyCountsByMonth]: CustomerSurveyCountsByMonthSummary;
		[Chart.GetCustomerSurveyCountsBySite]: CustomerSurveyCountsBySiteSummary;
		[Chart.GetCustomerSurveyCountsByProgram]: CustomerSurveyCountsByProgramSummary;
		[Chart.GetCustomerSurveyCountsByOrganization]: CustomersurveyCountsByOrganizationSummary;
		[Chart.GetSurveyRecipientFrontPageSummary]: SurveyRecipientFrontPageSummary;
		[Chart.GetCustomerSurveyCountsByProgramByMonth]: CustomerSurveyCountsByProgramByMonthSummary;
		[Chart.GetCustomerSurveyCountsByVendor]: CustomerSurveyCountsByVendorSummary;
		[Chart.GetCustomerSurveyCountsByVerticals]: CustomerSurveyCountsByVerticalsSummary;
		[Chart.GetCustomerSurveyCountsByVerticalsOrganizationList]: CustomerSurveyCountsByVerticalsOrganizationListSummary;
	}
}
