import { Service } from '@api-interfaces';

// components
import { Text, Badge } from '@atoms';

interface Props {
  services: Service[];
}

export const Programs = ({ services }: Props) => {
  return (
    <div>
      <Text font="h4" color="hi-contrast" className="tw-mb-2">
        Programs
      </Text>
      {services?.length ? (
        <div className="tw-flex tw-flex-wrap tw-gap-2">
          {services.map((service, i) => (
            <Badge key={i} label={service.name} />
          ))}
        </div>
      ) : (
        <div className="tw-w-full">
          <Text font="body-md" color="neutral-offset">
            None
          </Text>
        </div>
      )}
    </div>
  );
};
