import { LoaderCircleProps } from './loader-circle.interfaces';

import './loader-circle.css';

export const LoaderCircle = ({
  ring: { className: ringClassName, ...ringProps },
  className,
  size,
  type,
  ...props
}: LoaderCircleProps) => {
  return (
    <div
      className={`loader-circle loader-circle-${size} ${className}`}
      {...props}
    >
      {Array(4)
        .fill(null)
        .map((_, i) => (
          <div
            key={i}
            className={`loader-circle-${type} ${ringClassName}`}
            {...ringProps}
          />
        ))}
    </div>
  );
};

LoaderCircle.defaultProps = {
  ring: {
    className: '',
  },
  className: '',
  size: 'md',
  type: 'default',
};
