import { ModalStack } from '@atoms';
import { Modal } from '@templates';

import { useTodoFormContext } from '../../_context';

export const TodoCancelPrompt = () => {
  const { activeModals, setActiveModals, closeModal } = useTodoFormContext();

  function handleCancel() {
    setActiveModals({
      'cancel-todo': false,
    });
  }

  function handleConfirm() {
    setActiveModals({
      'cancel-todo': false,
    });
    closeModal();
  }

  return (
    activeModals['cancel-todo'] && (
      <ModalStack position="top-half-center">
        <Modal.Prompt
          key="cancel-prompt"
          prompt="Any changes will not be saved"
          title="Cancel?"
          onCancel={handleCancel}
          buttons={[
            {
              label: 'No',
              color: 'secondary',
              onClick: handleCancel,
            },
            {
              label: 'Yes',
              color: 'danger',
              onClick: handleConfirm,
            },
          ]}
        />
      </ModalStack>
    )
  );
};
