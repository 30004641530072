import { Person as IPerson } from '@api-interfaces';
import { titleCase } from '@helpers';
import { imageService } from '@services';

// helpers

export class Person {
	readonly person: IPerson;

	constructor(
		person: IPerson = {
			id: null,
			email_address: '',
			first_name: '',
			last_name: '',
			phone_number: '',
			photo_url: '',
		}
	) {
		this.person = person;
	}

	get personId() {
		return this.person.id;
	}

	get firstName() {
		return this.person.first_name || '';
	}

	get lastName() {
		return this.person.last_name || '';
	}

	get fullName() {
		return titleCase(`${this.firstName} ${this.lastName}`);
	}

	get email() {
		return this.person.email_address || '';
	}

	get phoneNumber() {
		return this.person.phone_number || '';
	}

	get photo() {
		return imageService.getImageByUniqueId(this.person.photo_url);
	}
}
