import { Icon } from '@atoms';

import { TagProps } from './tag.interfaces';

import './tag.css';

export const Tag = ({
  size,
  type,
  className,
  handleRemove,
  children,
  ...props
}: TagProps) => {
  return (
    <div
      className={`tag tw-flex tw-items-center tw-justify-center tw-space-x-2 tw-rounded-full tag-${size} tag-${type} ${className}`}
      {...props}
    >
      {children}
      {handleRemove && (
        <span onClick={handleRemove}>
          <Icon icon={['far', 'times']} className="tag-remove" />
        </span>
      )}
    </div>
  );
};

Tag.defaultProps = {
  className: '',
  size: 'sm',
  type: 'default',
};
