import { useState } from 'react';

// helpers
import { useBreakpoints } from '@hooks';

// components
import { Box, Icon, Text } from '@atoms';
import { Calendar } from '@atoms/calendar';
import { FieldInputDate } from '@atoms/form-ds2/field-input-date';
import { FieldInputTime } from '@atoms/form-ds2/field-input-time';
import { TimeUtils } from '@helpers';
import { Button } from '@new';

// interfaces
import {
    CustomDateRange,
    DateRangePickerProps,
    DEFAULT_START_TIME,
    DEFAULT_END_TIME,
} from './date-range-picker.interfaces';

export const DateRangePicker = ({
    dateRange: { startDate, endDate, customStartTime, customEndTime },
    rounded,
    shadow,
    onApplyDateRange,
    onCancel,
    className,
    style,
    customTime,
    lockRange,
    ...props
}: DateRangePickerProps) => {
    const [dateRange, setDateRange] = useState<CustomDateRange>({
        startDate,
        endDate,
        customStartTime: customStartTime || DEFAULT_START_TIME,
        customEndTime: customEndTime || DEFAULT_END_TIME,
    });
    const [error, setError] = useState<{
        startDate: string;
        endDate: string;
        startTime: string;
    }>({
        startDate: '',
        endDate: '',
        startTime: '',
    });
    const isSingleDay = lockRange === 'today';
    const { sm } = useBreakpoints();

    function handleDateRangeChange(type: 'startDate' | 'endDate') {
        return (date: Date) => {
            if (type === 'startDate' && isSingleDay) {
                setDateRange({
                    ...dateRange,
                    startDate: TimeUtils.startOfDay(date),
                    endDate: TimeUtils.endOfDay(date),
                });
                return;
            }
            if (
                type === 'startDate' &&
                TimeUtils.isAfter(date, dateRange.endDate)
            ) {
                setError({
                    ...error,
                    startDate: 'Start Date must come before End Date',
                });
                return;
            }
            if (
                type === 'endDate' &&
                TimeUtils.isBefore(date, dateRange.startDate)
            ) {
                setError({
                    ...error,
                    endDate: 'End Date must come after Start Date',
                });
                return;
            }

            if (error.startDate || error.endDate) {
                setError({ ...error, startDate: '', endDate: '' });
            }

            if (type === 'startDate') {
                const [startHour, startMinute] = dateRange.customStartTime
                    .split(':')
                    .map(Number);
                const startDate = new Date(TimeUtils.startOfDay(date));
                if (customTime) {
                    startDate.setHours(startHour ?? 0, startMinute ?? 0, 0, 0);
                }
                setDateRange({
                    ...dateRange,
                    startDate,
                });
            } else {
                const [endHour, endMinute] = dateRange.customEndTime
                    .split(':')
                    .map(Number);
                const endDate = new Date(TimeUtils.endOfDay(date));
                if (customTime) {
                    endDate.setHours(endHour ?? 23, endMinute ?? 59, 59, 999);
                }
                setDateRange({
                    ...dateRange,
                    endDate,
                });
            }
        };
    }

    function handleDateTimeChange(type: 'customStartTime' | 'customEndTime') {
        return (e) => {
            let date;
            let dateKey;
            if (type === 'customStartTime') {
                const [hour, minute] = e.target.value.split(':').map(Number);
                date = new Date(dateRange.startDate);
                date.setHours(hour ?? 0, minute ?? 0, 0, 0);
                dateKey = 'startDate';
            } else {
                const [hour, minute] = e.target.value.split(':').map(Number);
                date = new Date(dateRange.endDate);
                date.setHours(hour ?? 23, minute ?? 59, 59, 999);
                dateKey = 'endDate';
            }
            setError({ ...error, startTime: '' });
            setDateRange({
                ...dateRange,
                [type]: e.target.value,
                [dateKey]: date,
            });
        };
    }

    function handleResetTimeSettings() {
        setError({ ...error, startTime: '' });
        const startDate = TimeUtils.startOfDay(dateRange.startDate);
        const endDate = TimeUtils.endOfDay(dateRange.endDate);
        setDateRange({
            startDate,
            endDate,
            customStartTime: DEFAULT_START_TIME,
            customEndTime: DEFAULT_END_TIME,
        });
    }

    function validateTime() {
        setError({ ...error, startTime: '' });
        if (
            !customTime ||
            !TimeUtils.isSameDay(dateRange.startDate, dateRange.endDate)
        )
            return true;
        const [startHour, startMinute] = dateRange.customStartTime
            .split(':')
            .map(Number);
        const [endHour, endMinute] = dateRange.customEndTime
            .split(':')
            .map(Number);
        if (
            startHour > endHour ||
            (startHour === endHour && startMinute > endMinute)
        ) {
            setError({
                ...error,
                startTime: 'Start Date Time must come before End Date Time',
            });
            return false;
        }
        return true;
    }

    return (
        <Box
            data-ds2="date-range-picker"
            rounded={rounded}
            className={`tw-p-4 tw-max-w-[calc(100vw_-_32px)] tw-mx-4 sm:tw-mx-0 sm:tw-px-0 sm:tw-py-10 ${
                shadow ? 'tw-shadow-lg' : ''
            } ${
                isSingleDay ? ' sm:tw-max-w-[308px]' : 'sm:tw-max-w-[608px]'
            } ${className}`}
            style={style}
        >
            <div className="tw-flex tw-flex-col tw-gap-4 sm:tw-flex-row sm:tw-justify-between sm:tw-px-10">
                <FieldInputDate
                    required
                    disableCalender={sm}
                    id="start-date"
                    label={isSingleDay ? 'Date' : 'Start Date'}
                    value={TimeUtils.format(dateRange.startDate, 'MM/DD/YYYY')}
                    onChange={handleDateRangeChange('startDate')}
                    onBlur={handleDateRangeChange('startDate')}
                    error={error.startDate}
                    {...props}
                />
                {!isSingleDay && (
                    <FieldInputDate
                        required
                        disableCalender={sm}
                        id="end-date"
                        label="End Date"
                        value={TimeUtils.format(
                            dateRange.endDate,
                            'MM/DD/YYYY'
                        )}
                        onChange={handleDateRangeChange('endDate')}
                        onBlur={handleDateRangeChange('endDate')}
                        error={error.endDate}
                        {...props}
                    />
                )}
            </div>
            <hr className="tw-hidden tw-bg-theme-neutral-900-100 tw-bg-opacity-15 tw-my-5 tw-h-[1px] sm:tw-block" />
            <div className="tw-hidden tw-px-10 sm:tw-flex sm:tw-justify-between sm:tw-space-x-4">
                <Calendar
                    date={dateRange.startDate}
                    highlightToDate={dateRange.endDate}
                    onDateChange={handleDateRangeChange('startDate')}
                    {...props}
                />
                {!isSingleDay && (
                    <Calendar
                        date={dateRange.endDate}
                        highlightToDate={dateRange.startDate}
                        onDateChange={handleDateRangeChange('endDate')}
                        {...props}
                    />
                )}
            </div>
            {customTime && (
                <>
                    <hr className="tw-bg-theme-neutral-900-100 tw-bg-opacity-15 tw-my-5 tw-h-[1px]" />
                    <div className="tw-flex tw-flex-col tw-gap-4 sm:tw-px-10">
                        <div className="tw-flex tw-items-center tw-gap-2">
                            <Text color="hi-contrast" font="h3">
                                Time Settings (Optional)
                            </Text>
                            {(dateRange.customStartTime !==
                                DEFAULT_START_TIME ||
                                dateRange.customEndTime !==
                                    DEFAULT_END_TIME) && (
                                <Text color="warning" font="body-md">
                                    <Icon icon="triangle-exclamation" /> Custom
                                    Time Applied
                                </Text>
                            )}
                        </div>
                        <div className="tw-flex tw-flex-col sm:tw-flex-row tw-gap-4">
                            <FieldInputTime
                                label="Start Date Time"
                                id="start-time"
                                onChange={handleDateTimeChange(
                                    'customStartTime'
                                )}
                                value={dateRange.customStartTime}
                                error={error.startTime}
                            />
                            <FieldInputTime
                                label="End Date Time"
                                id="end-time"
                                onChange={handleDateTimeChange('customEndTime')}
                                value={dateRange.customEndTime}
                            />
                        </div>
                        <Button
                            label="Reset to Default Times"
                            onClick={handleResetTimeSettings}
                            color="alternate"
                            className="tw-w-full tw-justify-center"
                        />
                    </div>
                </>
            )}
            <div className="tw-mt-5 tw-flex tw-justify-end tw-space-x-4 sm:tw-px-10">
                <Button
                    label="Cancel"
                    onClick={(e) => {
                        e.preventDefault();
                        onCancel();
                    }}
                    color="secondary"
                />
                <Button
                    label="Apply"
                    onClick={(e) => {
                        e.preventDefault();
                        if (!validateTime()) return;
                        onApplyDateRange(dateRange);
                    }}
                />
            </div>
        </Box>
    );
};

DateRangePicker.defaultProps = {
    locales: 'en',
    className: '',
};
