// interfaces
import { cloneDeep } from 'lodash';

import {
	Questionnaire,
	Answer,
	BehavioralAudit,
	BehavioralAuditPayload,
	ReferenceItem,
} from '@api-interfaces';
import { App } from '@core/app-context/app.interfaces';

// helpers
import { isValidValue, isBoolean } from '@helpers/validation';

import { GMP_AUDIT_BADGE_TYPE } from './_components';
import { GmpAuditForm } from './interfaces';

export function populateFormFromData(
	questionnaire: Questionnaire,
	data: BehavioralAudit
) {
	let form: GmpAuditForm;
	let answers: {
		[question_id: string]: Answer;
	} = {};
	const isEmpty = !data;

	if (!isEmpty) {
		answers = data.answer_sheet?.answers?.reduce(
			(obj, answer) =>
				Object.assign(obj, {
					[answer.question.id]: answer,
				}),
			{}
		);
	}

	form = {
		subjectId: !isEmpty ? data.subject?.id : null,
		answerSheet: !isEmpty ? data.answer_sheet : null,
		isEventBased: !isEmpty ? data.is_event_based : null, // default is null, radio buttons should both be empty
		overallComment: !isEmpty ? data.audit_comment : null,
		isExempt: !isEmpty ? data.is_exempt_from_scorecard : null,
		audit: {
			...questionnaire?.sections.reduce(
				(obj, section) =>
					Object.assign(obj, {
						[section.id]: section.questions.reduce(
							(obj, question) =>
								Object.assign(obj, {
									[question.id]: {
										mark: !isEmpty
											? answers?.[question.id]?.boolean_value
											: null,
									},
								}),
							{}
						),
					}),
				{}
			),
		},
	};

	return form;
}

// Moves questions into a Professionalism section
export function updateProfessionalismQuestionnaire(
	questionnaire: Questionnaire
): Questionnaire {
	const newQuestionnaire = cloneDeep(questionnaire);
	newQuestionnaire.sections = [
		{
			id: 0,
			name: 'Professionalism',
			description: '',
			questions: newQuestionnaire.questions.map((question) => {
				question.name = question.text;
				return question;
			}),
		},
	];
	newQuestionnaire.questions = [];
	return newQuestionnaire;
}

export function getCorrectiveActionOtherId(
	correctiveActions: ReferenceItem[]
): number {
	return (
		correctiveActions?.filter(
			(correctiveAction) => correctiveAction.code?.toLowerCase() === 'other'
		)?.[0]?.id ?? null
	);
}

export function isValidForm(form: GmpAuditForm): boolean {
	// validate all fields for non-exempt, and only comment for exempt
	if (form?.isExempt !== true) {
		if (!isBoolean(form?.isEventBased)) return false;
		if (!isValidValue(form?.overallComment)) return false;

		for (const section of Object.values(form?.audit)) {
			for (const answer of Object.values(section)) {
				if (!isBoolean(answer?.mark)) return false;
			}
		}
	} else if (!isValidValue(form?.overallComment)) return false;

	return true;
}

// enable Save button if exempt checkbox is true: requires user selects Scheduled or UnScheduled AND an overallComment
export function isExemptForm(form: GmpAuditForm): boolean {
	if (
		form?.isExempt === true &&
		form?.overallComment &&
		form?.isEventBased !== null
	)
		return false;
	return true;
}

export function generatePayload(
	appContext: App.State,
	form: GmpAuditForm,
	employeeContractId: number
): BehavioralAuditPayload {
	const { user, isSingleSite, selectedContracts: sites } = appContext;

	/*
        Priority:
        1) If a single site - Send first contract id.
        2) If has employee shift contract id - Send employee shift contract id.
        3) If neither above - Send first contract id.
    */
	const firstContractId = sites?.[0]?.id;
	const contractId =
		isSingleSite || !employeeContractId ? firstContractId : employeeContractId;

	const answersWithQId = Object.values(form.audit)
		.map((section) =>
			Object.entries(section).map(([questionKey, answer]) => ({
				questionId: Number(questionKey),
				...answer,
			}))
		)
		.flat();

	const isPass = answersWithQId.every((answer) => answer.mark);
	const isExempt = form.isExempt === true;

	return {
		contract: {
			id: contractId,
		},
		subject: {
			id: form.subjectId,
		},
		auditor: {
			id: user?.id,
		},
		creator: {
			id: user?.id,
		},
		audit_date: new Date().toISOString(),
		is_event_based: isExempt ? false : form.isEventBased,
		is_exempt_from_scorecard: form.isExempt !== null ? form.isExempt : false,
		is_pass: isPass,
		type: 'GMP',
		audit_comment: form.overallComment,
		answer_sheet: {
			id: form.answerSheet?.id,
			answers: answersWithQId.map((answerWithQId) => ({
				id:
					form.answerSheet?.answers?.filter(
						(answer) => answer.question.id === answerWithQId.questionId
					)[0]?.id ?? null,
				boolean_value: isExempt ? null : answerWithQId.mark,
				question: {
					id: answerWithQId.questionId,
				},
			})),
		},
	};
}

export function getBadgeType(
	mark: boolean,
	isExempt: boolean
): GMP_AUDIT_BADGE_TYPE {
	if (isExempt) return 'exempt';
	if (mark === true) return 'pass';
	if (mark === false) return 'fail';
	return null;
}
