import { Media } from '@api-interfaces';

// components
import { Text } from '@atoms';
import { useAuditModalContext } from '@modals/_ds2/audit/_context/context';
import { ImageList } from '@new';

interface Props {
  attachments: Media[];
}

export const Attachments = ({ attachments }: Props) => {
  const {
    state: { modals, selectedArea },
    dispatch,
  } = useAuditModalContext();

  const openPhotoIndex = (index) => {
    dispatch({
      type: 'SET_PHOTO_VIEWER_AREAS',
      payload: [selectedArea],
    });
    dispatch({
      type: 'SET_PHOTO_VIEWER_INDEX',
      payload: index,
    });
    dispatch({
      type: 'SET_MODALS',
      payload: {
        ...modals,
        'audit-attachments': true,
      },
    });
  };

  return (
    <div>
      <Text font="h3" color="hi-contrast" className="tw-mb-4">
        Attachments
      </Text>

      {attachments?.length ? (
        <ImageList
          media={attachments.map((attachment) => ({
            ...attachment,
            origin: null,
          }))}
          imageBlockClassName="tw-h-32 tw-w-32"
          overlayClassName="tw-text-3xl"
          maxPictures={7}
          onMediaClick={(_media, index) => openPhotoIndex(index)}
        />
      ) : (
        <div className="tw-w-full">
          <Text font="body-md" color="neutral-offset">
            None
          </Text>
        </div>
      )}
    </div>
  );
};
