import { Observable } from 'rxjs';

// services

// helpers
import { map } from 'rxjs/operators';

// interfaces
import {
	EmployeeWorkDay,
	RealPunch,
	WorkDayTypes,
	WorkDaysOrdering,
	BulkWorkDayPunchPayload,
	BulkWorkDayPayload,
	WorkDay,
	PunchPayload,
	WorkDayPunch,
	EmployeesClockedInSummary,
	WorkDayTrackerParams,
	WorkDayTrackerTotalCountResponse,
	WorkDayTrackerClockedInResponse,
	WorkDayTrackerClockedOutResponse,
	WorkDayTrackerAbsentResponse,
	WorkDayTrackerHoursResponse,
	WorkDayTrackerClockedInInfoResponse,
} from '@api-interfaces';
import { App } from '@core/app-context/app.interfaces';
import { Params, TimeUtils } from '@core/helpers';
import { virtualBrownClient, IDjangoResponse } from '@core/services';
import { PaginationParams, PaginationResponse } from '@models';

import { workDaysSummaryService } from './summary';

const zone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const BASE_URL = `workdays`;

const noOpts = {
	noContract: true,
	noCustomer: true,
	noTag: true,
};

class WorkDaysService {
	readonly summary = workDaysSummaryService;

	public getWorkDays = (params: {
		work_date: Date;
		from?: Date;
		to?: Date;
		limit?: number;
		offset?: number;
		customer?: string | number;
		contract?: string | number;
		ordering?: WorkDaysOrdering;
		punch_type?: 'clocked_in' | 'clocked_out';
		workday_type?: 'clocked_in' | 'last_punch' | 'no_clock';
	}): Observable<IDjangoResponse<EmployeeWorkDay>> => {
		const query: any = {
			...params,
		};
		if (query.from) {
			query.from = TimeUtils.format(query.from, 'YYYY-MM-DD');
		}
		if (query.to) {
			query.to = TimeUtils.format(query.to, 'YYYY-MM-DD');
		}
		if (query.work_date) {
			query.work_date = TimeUtils.format(query.work_date, 'YYYY-MM-DD');
		}
		return virtualBrownClient.get(
			`${BASE_URL}/employees/${Params.makeQueryString(query)}`,
			{
				noContract: query.contract != undefined,
				noCustomer: query.customer != undefined,
			}
		);
	};

	public getEmployeesClockedInSummary = (params: {
		contract: string | number;
		work_date: Date;
	}): Observable<IDjangoResponse<EmployeesClockedInSummary>> => {
		const query: any = {
			...params,
			work_date: TimeUtils.format(params.work_date, 'YYYY-MM-DD'),
		};

		return virtualBrownClient.get(
			`${BASE_URL}/employees/clockedin/summary/${Params.makeQueryString(
				query
			)}`,
			{
				noContract: query.contract != undefined,
			}
		);
	};

	public getWorkDaysHM = (params: any) => {
		const queryString = params ? Params.makeQueryString(params) : '';

		return virtualBrownClient
			.get<
				IDjangoResponse<EmployeeWorkDay>
			>(`workdays/employees/${queryString}`, { noCustomer: true })
			.pipe(map((res: any) => (res ? res.results : [])));
	};

	public deletePunch = (id: number): Observable<any> =>
		virtualBrownClient.delete(`workdays/punches/${id}/`);

	public createWorkDay = (
		d: string,
		empId: number,
		{ selectedContracts }: App.State
	): Observable<WorkDay> => {
		const body = {
			contract: {
				id: selectedContracts?.[0]?.id,
			},
			employee: {
				id: empId,
			},
			status: 'PENDING',
			work_date: TimeUtils.format(d, 'YYYY-MM-DD'),
		};
		return virtualBrownClient.post(`workdays/`, body);
	};

	public getEmployeeWorkDays = (
		params: {
			contract?: string | number;
			customer?: string | number;
			tag?: Array<number> | string;
			from: Date;
			to: Date;
			work_date: Date;
			workday_type?: WorkDayTypes;
			punch_type?: 'clocked_in' | 'clocked_out';
			ordering?: WorkDaysOrdering;
		} & PaginationParams
	): Observable<PaginationResponse<EmployeeWorkDay>> => {
		const queryString = Params.makeQueryString({
			...params,
			from: TimeUtils.format(params.from, 'YYYY-MM-DD'),
			to: TimeUtils.format(params.to, 'YYYY-MM-DD'),
			work_date: TimeUtils.format(params.work_date, 'YYYY-MM-DD'),
		});
		return virtualBrownClient.get(`${BASE_URL}/employees/${queryString}`, {
			noCustomer: params.customer != undefined,
			noContract: params.contract != undefined,
			noTag: params.tag != undefined,
		});
	};

	public updateWorkDay = (day: EmployeeWorkDay) => {
		return virtualBrownClient.put(
			`workdays/${day.employee_workdays[0].id}/`,
			day
		);
	};

	// dont drink the koolaid "Ohhhh Yeahhhh"
	public createOrUpdatePunch = (punch: RealPunch) => {
		if (punch.id !== null && punch.id !== 0) {
			return virtualBrownClient.put(
				`workdays/punches/${punch.id}/`,
				punch
			);
		}
		return virtualBrownClient.post(`workdays/punches/`, punch);
	};

	public createPunch = (
		punchPayload: PunchPayload
	): Observable<WorkDayPunch> => {
		const payload = {
			...punchPayload,
			in_manager_approved_punchtime:
				punchPayload.in_manager_approved_punchtime.toISOString(),
			...(punchPayload.out_manager_approved_punchtime && {
				out_manager_approved_punchtime:
					punchPayload.out_manager_approved_punchtime.toISOString(),
			}),
		};

		return virtualBrownClient.post(`${BASE_URL}/punches/`, payload, noOpts);
	};

	public updatePunch = (
		id: number,
		punchPayload: PunchPayload
	): Observable<WorkDayPunch> => {
		const payload = {
			...punchPayload,
			in_manager_approved_punchtime:
				punchPayload.in_manager_approved_punchtime.toISOString(),
			out_manager_approved_punchtime:
				punchPayload.out_manager_approved_punchtime
					? punchPayload.out_manager_approved_punchtime.toISOString()
					: null,
		};
		return virtualBrownClient.put(
			`${BASE_URL}/punches/${id}/`,
			payload,
			noOpts
		);
	};

	public getHourCounts = (d: Date) => {
		const qs = Params.makeQueryString({
			to: TimeUtils.format(d, 'YYYY-MM-DD'),
			from: TimeUtils.format(d, 'YYYY-MM-DD'),
			chart: 0,
			time_zone: zone,
		});

		return virtualBrownClient.get(`workdays/summary/${qs}`).pipe(
			map((res: any) =>
				res && res.length
					? { ...res[0].results, fetched: true }
					: {
							total_hours: 0,
							total_work_hours: 0,
							total_vacation_hours: 0,
							total_unpaid_hours: 0,
							total_sick_hours: 0,
							total_holiday_hours: 0,
							total_other_hours: 0,
							fetched: true,
						}
			)
		);
	};

	public getDailyCounts = (params: { to: Date; from: Date }) => {
		const qs = Params.makeQueryString({
			to: TimeUtils.format(params.to, 'YYYY-MM-DD'),
			from: TimeUtils.format(params.from, 'YYYY-MM-DD'),
			chart: 2,
			time_zone: zone,
		});

		return virtualBrownClient.get(`workdays/summary/${qs}`).pipe(
			map((res: any) =>
				res && res.length
					? res[0].results
					: [
							{
								day: new Date().toISOString(),
								status: 'PENDING',
								total_ms: '0.0',
								finalized_on: null,
							},
						]
			)
		);
	};

	public finalizeWorkDay = (
		d: Date,
		{ user, selectedContracts }: App.State
	) => {
		const qs = Params.makeQueryString({
			contract: selectedContracts?.[0]?.id,
			work_date: TimeUtils.format(d, 'YYYY-MM-DD'),
			approving_mgr: user.id,
		});

		return virtualBrownClient.put(`workdays/employees/finalize/${qs}`, {});
	};

	public getClockedIn = (params: { from: Date; to: Date }) => {
		const qs = Params.makeQueryString({
			from: TimeUtils.formatToISO(params.from),
			to: TimeUtils.formatToISO(params.to),
			chart: 3,
		});

		return virtualBrownClient.get(`workdays/summary/${qs}`).pipe(
			map((res: any) =>
				res && res.length
					? { ...res[0].results }
					: {
							clocked_in_count: 0,
							recently_clocked_in: [],
						}
			)
		);
	};

	public statusCounts = (params: { from: Date; to: Date }) => {
		const qs = Params.makeQueryString({
			from: TimeUtils.formatToISO(params.from),
			to: TimeUtils.formatToISO(params.to),
			chart: 1,
		});

		return virtualBrownClient.get(`workdays/summary/${qs}`).pipe(
			map((res: any) =>
				res && res.length
					? { ...res[0].results }
					: {
							total_workdays: 0,
							pending_workdays: 0,
							manager_approved_workdays: 0,
							payroll_submitted_workdays: 0,
							payroll_approved_workdays: 0,
						}
			)
		);
	};

	public createBulkWorkDays = (
		payload: BulkWorkDayPayload[]
	): Observable<WorkDay[]> => {
		const serializedPayload = payload.map((item) => ({
			...item,
			work_date: TimeUtils.format(item.work_date, 'YYYY-MM-DD'),
		}));

		return virtualBrownClient.post(
			`${BASE_URL}/bulk/`,
			serializedPayload,
			noOpts
		);
	};

	public createBulkWorkDayPunches = (
		payload: BulkWorkDayPunchPayload[]
	): Observable<EmployeeWorkDay[]> => {
		const serializedPayload = payload.map((item) => ({
			...item,
			in_manager_approved_punchtime:
				item.in_manager_approved_punchtime.toISOString(),
			out_manager_approved_punchtime:
				item.out_manager_approved_punchtime.toISOString(),
		}));
		return virtualBrownClient.post(
			`${BASE_URL}/punches/bulk/`,
			serializedPayload,
			noOpts
		);
	};

	getTracker(
		params: Omit<WorkDayTrackerParams, 'category'> & {
			category?: 'total_count';
		}
	): Observable<PaginationResponse<WorkDayTrackerTotalCountResponse>>;

	getTracker(
		params: Omit<WorkDayTrackerParams, 'category'> & {
			category?: 'clocked_in';
		}
	): Observable<PaginationResponse<WorkDayTrackerClockedInResponse>>;

	getTracker(
		params: Omit<WorkDayTrackerParams, 'category'> & {
			category?: 'clocked_out';
		}
	): Observable<PaginationResponse<WorkDayTrackerClockedOutResponse>>;

	getTracker(
		params: Omit<WorkDayTrackerParams, 'category'> & {
			category?: 'absent';
		}
	): Observable<PaginationResponse<WorkDayTrackerAbsentResponse>>;

	getTracker(
		params: Omit<WorkDayTrackerParams, 'category'> & {
			category?: 'hours';
		}
	): Observable<PaginationResponse<WorkDayTrackerHoursResponse>>;

	getTracker(
		params: Omit<WorkDayTrackerParams, 'category'> & {
			category?: 'clocked_in_info';
		}
	): Observable<PaginationResponse<WorkDayTrackerClockedInInfoResponse>>;

	getTracker(params: WorkDayTrackerParams) {
		const { work_date, ...otherParams } = params;
		const qs = Params.makeQueryString({
			...otherParams,
			from: TimeUtils.format(work_date, 'YYYY-MM-DD'),
			to: TimeUtils.format(TimeUtils.addDays(work_date, 1), 'YYYY-MM-DD'),
		});
		return virtualBrownClient.get<
			PaginationResponse<
				| WorkDayTrackerTotalCountResponse
				| WorkDayTrackerClockedInResponse
				| WorkDayTrackerClockedOutResponse
				| WorkDayTrackerAbsentResponse
				| WorkDayTrackerHoursResponse
				| WorkDayTrackerClockedInInfoResponse
			>
		>(`${BASE_URL}/tracker/${qs}`, {
			noContract: !!params.contract,
			noCustomer: !!params.customer,
		});
	}
}

export const workDaysService = new WorkDaysService();
