import { Person } from '@api-interfaces';
import imageProfileDefault from '@assets/images/profile/default.png';

import { awsService } from './aws/aws';

import env from '@core/environments';
const domain = env.insite.domain;

// images
class ImageService {
	public defaultImage = '';

	/**
	 * @description Resolve UUID vs JPG
	 */
	public urlResolver(img) {
		if (img) {
			const url =
				img.indexOf('jpg') !== -1 || img.indexOf('png') !== -1
					? awsService.getUrl(img)
					: this.getImageByUniqueId(img);
			return url;
		}
		return img;
	}

	public getImageByUniqueId = (
		uniqueId: string,
		getThumbnail: boolean = true
	): string => {
		if (!uniqueId) {
			return imageProfileDefault;
		}
		// In case the link provided is a direct image URL
		if (uniqueId.includes('.com') && !uniqueId.includes(domain))
			return uniqueId;

		// In case the link provided is already with cloudfront URL
		uniqueId = uniqueId.includes(domain)
			? uniqueId.substring(uniqueId.indexOf(domain) + 12)
			: uniqueId;

		let key: string = uniqueId;
		const parts = uniqueId.split('.');
		const partsName = key.split('/');

		if (!key.includes('assets') && !key.includes('ai/')) {
			if (partsName.length > 1) {
				// all older files are jpgs migrated from cloudinary
				key = getThumbnail ? `${parts[0]}_t.` + `jpg` : key;
			} else {
				key = getThumbnail ? `${parts[0]}_t.${parts[1]}` : key;
			}
		}

		return awsService.getUrl(key);
	};

	public getImageByPerson = (p: Person): string => {
		return awsService.getUrl(p.photo_url);
	};

	/**
	 * @description Returns standard name for thumbnail.
	 */
	public getThumbnailName = (guid: string): string => {
		const parts = guid.split('.');

		return `${parts[0]}_t.${parts[1]}`;
	};

	private getTypeFromAttachments(attachments: Array<any>, type) {
		const filtered = attachments.filter((attachment) => {
			if (attachment) {
				return attachment.UserAttachmentType === type;
			}
			return false;
		});
		return filtered && filtered.length ? filtered[0] : null;
	}

	public getProfileImageFromAttachments(
		attachments: Array<any>,
		getThumbnail: boolean = true
	): string {
		if (!attachments || !attachments.length) return imageProfileDefault;
		const attachment = this.getTypeFromAttachments(
			attachments,
			'ProfilePicture'
		);
		if (!attachment || !attachment.UniqueId) return imageProfileDefault;
		return this.getImageByUniqueId(attachment.UniqueId, getThumbnail);
	}
}

export const imageService = new ImageService();
