import { Complaint, Service } from '@api-interfaces';

// helpers
import { ServiceIssueForm } from '@modals/_ds2/service-issue-form';
import { useWorkOrderModalContext } from '@modals/_ds2/work-orders/_context';

// components

export const CreateServiceIssue = () => {
  const {
    workOrder,
    setWorkOrder,
    workOrderEdit,
    setWorkOrderEdit,
    activeModals,
    setActiveModals,
    hasBeenUpdated,
  } = useWorkOrderModalContext();

  const handleSuccess = (serviceIssue: Complaint) => {
    const workOrderEditCopy: typeof workOrderEdit = {
      ...workOrderEdit,
      complaints: (workOrderEdit.complaints ?? []).concat(serviceIssue),
    };

    setWorkOrder(workOrderEditCopy);
    setWorkOrderEdit(workOrderEditCopy);
    hasBeenUpdated.current = true;
  };

  const handleCancel = () => {
    setActiveModals({
      ...activeModals,
      'creating-service-issue': false,
    });
  };

  return (
    activeModals['creating-service-issue'] && (
      <ServiceIssueForm
        closeModal={handleCancel}
        onSuccess={handleSuccess}
        service={
          (workOrderEdit.service || workOrder.service) as Pick<
            Service,
            'id' | 'name'
          >
        }
      />
    )
  );
};
