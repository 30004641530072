import { TextProps } from '@atoms/text/text.interfaces';

import { ScoreChipProps } from './score-chip.interfaces';

export const scoreChipTypeMap: { [Type in ScoreChipProps['type']]: string } = {
	success: 'tw-bg-theme-success-100-900',
	warning: 'tw-bg-theme-warning-100-900',
	danger: 'tw-bg-theme-danger-100-900',
	neutral: 'tw-bg-theme-neutral-300-700',
	'offset-success': 'tw-bg-theme-neutral-300-700',
};

export const scoreBarTypeMap: { [Type in ScoreChipProps['type']]: string } = {
	success: 'tw-bg-theme-success-700-900',
	warning: 'tw-bg-theme-warning-500-700',
	danger: 'tw-bg-theme-danger-500-700',
	neutral: 'tw-bg-theme-neutral-300-700',
};

export const scoreChipSizeMap: { [Size in ScoreChipProps['size']]: string } = {
	xs: 'tw-h-6 tw-min-w-[40px]',
	sm: 'tw-h-8 tw-w-max tw-min-w-[54px]',
	'extra-md': 'tw-w-[88px] tw-h-16',
	md: 'tw-h-[88px] sm:tw-h-auto sm:tw-w-36',
	lg: 'sm:tw-w-44',
};

export const scoreChipBorderRadiusMap: {
	[Size in ScoreChipProps['size']]: string;
} = {
	xs: 'tw-rounded-md',
	sm: 'tw-rounded-md',
	md: 'tw-rounded-lg',
	'extra-md': 'tw-rounded-lg',
	lg: 'tw-rounded-lg',
};

export const scoreChipLabelSizeMap: {
	[Size in ScoreChipProps['size']]: TextProps['font'];
} = {
	xs: null, // label is hidden for xs
	sm: null, // label is hidden for sm
	md: 'h3',
	'extra-md': 'h2',
	lg: 'h2',
};

export const scoreChipScoreSizeMap: {
	[Size in ScoreChipProps['size']]: TextProps['font'];
} = {
	xs: 'h4',
	sm: 'h3',
	md: 'h1',
	'extra-md': 'h1',
	lg: 'hero',
};

export const scoreChipPaddingMap: {
	[Size in ScoreChipProps['size']]: string;
} = {
	xs: 'tw-px-1',
	sm: 'tw-py-1 tw-px-2',
	md: 'tw-p-4',
	'extra-md': 'tw-py-3 tw-px-4',
	lg: 'tw-p-4',
};
