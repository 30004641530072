import { Formik } from 'formik';
import { Component } from 'react';
import { forkJoin } from 'rxjs';

// services

// helpers
import { of, Subscription } from 'rxjs';

// components
import {
    Complaint,
    Employee,
    ServiceArea,
    ReferenceItem,
    Shift,
} from '@api-interfaces';
import {
    complaintsService,
    employeesService,
    serviceAreasService,
    shiftsService,
} from '@apis';
import { NewCustomer } from '@app/forms/NewCustomer';
import { Text, Tooltip, Box } from '@atoms';
import { Dropdown } from '@atoms/form-ds2/dropdown';
import { ErrorText } from '@atoms/form-ds2/error-text';
import { FieldDropdown } from '@atoms/form-ds2/field-dropdown';
import { FieldInputDate } from '@atoms/form-ds2/field-input-date';
import { FieldInputText } from '@atoms/form-ds2/field-input-text';
import { Label } from '@atoms/form-ds2/label';
import { FieldMultiSelect } from '@atoms/form-ds2/field-multi-select';
import { FieldRadioGroup } from '@atoms/form-ds2/field-radio-group';

// interfaces
import { App } from '@core/app-context/app.interfaces';
import { DropdownItem } from '@atoms/form-ds2/dropdown/dropdown.interfaces';
import {
    getFullName,
    sendError,
    getLocations,
    TimeUtils,
    Locations,
} from '@helpers';
import { useAppContext } from '@hooks';
import { PaginationResponse } from '@models';
import { Button } from '@new';
import { toasterService, modalService, refetchService } from '@services';
import {
    mixpanelCreateQualityComplaint,
    mixpanelEditQualityComplaint,
} from '@app/mixpanel/MixpanelPageTrack.tsx';

type ReferenceDropdownItem = DropdownItem<ReferenceItem>;
type ServiceAreaDropdownItem = DropdownItem<ServiceArea>;

const repeatOpts: DropdownItem[] = ['No', 'Yes'].map((item, idx) => ({
    id: idx,
    name: item,
}));
const preventableOptions: DropdownItem[] = ['Preventable', 'Unpreventable'].map(
    (item, index) => ({
        id: index,
        name: item,
    })
);

interface IProps {
    selectedProgram: {
        id: number;
        name: string;
    };
    editComplaint?: Complaint;
    employee?: Employee;
    selectedEmployee?: {
        employeeId: number;
        employeeName: string;
    };
}

interface State {
    complaint_types: ReferenceDropdownItem[];
    classifications: ReferenceDropdownItem[];
    floors: ServiceAreaDropdownItem[];
    areas: ServiceAreaDropdownItem[];
    employees: DropdownItem<Employee>[];
    service_areas: ServiceAreaDropdownItem[];
    customerEmployees: DropdownItem<Employee>[];
    displayAddNewCustomer: boolean;
    closeNow: boolean;
    shifts?: Shift[];
    isSubmitting: boolean;
}

class Form extends Component<IProps & App.State, State> {
    private subscription: { [key: string]: Subscription } = {};

    public state: State = {
        classifications: [],
        complaint_types: [],
        customerEmployees: [],
        employees: [],
        floors: [],
        areas: [],
        service_areas: [],
        displayAddNewCustomer: false,
        closeNow: false,
        shifts: [],
        isSubmitting: false,
    };

    fetchLocations = () => {
        const paginatedResponse: PaginationResponse<ServiceArea> = {
            next: null,
            previous: null,
            results: [],
            count: 0,
        };
        const fetchLocations: {
            [Key in keyof Locations]: ReturnType<
                typeof serviceAreasService.getServiceAreas
            >;
        } = {
            building: of(paginatedResponse),
            floor: of(paginatedResponse),
            room: of(paginatedResponse),
        };
        Object.entries(getLocations(this.props.editComplaint.location)).forEach(
            ([type, serviceArea]: [keyof Locations, ServiceArea]) => {
                if (serviceArea) {
                    const request = serviceAreasService.getServiceAreas({
                        parent: serviceArea.id,
                        quick_landing: true,
                    });
                    switch (type) {
                        case 'building':
                            fetchLocations.floor = request;
                            break;
                        case 'floor':
                            fetchLocations.room = request;
                            break;
                    }
                }
            }
        );

        forkJoin(fetchLocations).subscribe({
            next: ({
                floor,
                room,
            }: {
                [Key in keyof Locations]: PaginationResponse<ServiceArea>;
            }) => {
                this.setState({
                    floors: floor.results,
                    areas: room.results.map((area) => ({
                        ...area,
                        subtext:
                            area.area_type?.name ??
                            area.area_types?.[0]?.area_type_name ??
                            area.type?.name ??
                            '',
                    })),
                });
            },
        });
    };

    public componentDidMount() {
        const contractId = this.props.editComplaint
            ? this.props.editComplaint.contract.id
            : this.props.selectedContracts?.[0]?.id;
        forkJoin([
            complaintsService.getComplaintTypes(),
            complaintsService.getClassificationTypes(),
            serviceAreasService.getServiceAreas({
                contract: contractId,
                quick_landing: true,
            }),
            employeesService.getEmployees({
                limit: 1000,
                contract: contractId,
                detail_level: 'for_dropdown',
            }),
            employeesService.getCustomerEmployees({ limit: 1000 }),
        ]).subscribe({
            next: ([
                complaint_types,
                classifications,
                service_areas,
                employees,
                customerEmployees,
            ]) => {
                this.setState({
                    complaint_types: complaint_types.map((type) => ({
                        ...type,
                        name: type.display_name,
                    })),
                    classifications: classifications.map((type) => ({
                        ...type,
                        name: type.display_name,
                    })),
                    service_areas: service_areas.results,
                    employees: employees.results.map((employee) => ({
                        ...employee,
                        name: getFullName(employee.person),
                        subtext: employee.person.email_address,
                    })),
                    customerEmployees: customerEmployees.results.map(
                        (employee) => ({
                            ...employee,
                            name: getFullName(employee.person),
                            subtext: `${employee.person.email_address} - ${employee.organization.name}`,
                        })
                    ),
                });
            },
            error: sendError(),
        });

        if (this.props.editComplaint) {
            this.fetchLocations();
        }

        this.fetchShifts();

        this.setState({
            closeNow: !!this.props.employee || !!this.props.selectedEmployee,
        });
    }

    componentWillUnmount() {
        Object.values(this.subscription).forEach((subscription) =>
            subscription.unsubscribe()
        );
    }

    public getFloorList = (building: ServiceAreaDropdownItem) => {
        this.subscription.floors = serviceAreasService
            .getServiceAreas({ parent: building.id, quick_landing: true })
            .subscribe((res) => {
                this.setState({
                    floors: res.results,
                    areas: [],
                });
            });
    };

    public getAreaList = (floor: ServiceAreaDropdownItem) => {
        this.subscription.areas = serviceAreasService
            .getServiceAreas({ parent: floor.id, quick_landing: true })
            .subscribe((res) => {
                this.setState({
                    areas: res.results.map((area) => ({
                        ...area,
                        subtext:
                            area.area_type?.name ??
                            area.area_types?.[0]?.area_type_name ??
                            area.type?.name ??
                            '',
                    })),
                });
            });
    };

    private fetchShifts = () => {
        shiftsService
            .getShifts(
                {
                    limit: 1000,
                    offset: 0,
                    ...(!!this.props.selectedCustomers.length && {
                        customer: this.props.selectedCustomers
                            .map((client) => client.id)
                            .join(','),
                    }),
                    ...(!!this.props.selectedContracts.length && {
                        contract: this.props.selectedContracts
                            .map((site) => site.id)
                            .join(','),
                    }),
                },
                { noOptions: true }
            )
            .subscribe({
                next: ({ results: Shifts }) => {
                    this.setState({ shifts: Shifts ?? [] });
                },
                error: sendError({
                    toastMessage:
                        'There was an error retrieving Shifts type data',
                }),
            });
    };

    private fetchEmployees = (shifts: Shift[]) => {
        const contractId = this.props.editComplaint
            ? this.props.editComplaint.contract.id
            : this.props.selectedContracts?.[0]?.id;
        let updatedEmployees: Employee[];
        employeesService
            .getEmployees({
                limit: 1000,
                contract: contractId,
                detail_level: 'for_dropdown',
            })
            .subscribe({
                next: (employees) => {
                    if (!employees?.results?.length) {
                        return;
                    }
                    // filter employees by selected shifts
                    if (shifts.length) {
                        updatedEmployees = employees.results.filter(
                            (employee) =>
                                shifts?.some(
                                    (shift) =>
                                        shift.id === employee?.shifts?.[0]?.id
                                )
                        );
                    }
                    if (updatedEmployees?.length > 0) {
                        this.setState({
                            employees: updatedEmployees?.map((employee) => ({
                                ...employee,
                                name: getFullName(employee.person),
                                subtext: employee.person.email_address,
                            })),
                        });
                    } else {
                        this.setState({
                            employees: employees.results.map((employee) => ({
                                ...employee,
                                name: getFullName(employee.person),
                                subtext: employee.person.email_address,
                            })),
                        });
                    }
                },
                error: sendError(),
            });
    };

    private handleSuccess = (message: string) => {
        toasterService.newToast({ status: 'success', message });
        refetchService.fetch('complaints');
        modalService.closeAll();
    };

    public submitForm = (
        values: {
            accountableEmps: DropdownItem<Employee>[];
            classification: ReferenceDropdownItem;
            complaint_date: Date;
            complaint_type: ReferenceDropdownItem;
            completed_date: Date | null;
            description: string;
            immediate_action: string;
            is_escalated: boolean;
            is_exempt_from_scorecard: boolean;
            is_preventable: DropdownItem;
            is_repeat_complaint: DropdownItem;
            preventative_action: string;
            root_cause: string;
            submitter: DropdownItem;
            shifts: Shift[];
        } & Locations
    ) => {
        const { user, selectedContracts: contracts } = this.props;
        const contractId = this.props.editComplaint
            ? this.props.editComplaint.contract.id
            : contracts[0].id;

        const complaintBody = {
            immediate_action: values.immediate_action,
            description: values.description,
            classification: {
                id: values.classification.id,
            },
            type: {
                id: values.complaint_type.id,
            },
            submitter: {
                id:
                    (values.submitter && values.submitter.id) ||
                    user.employeeId,
            },
            creator: {
                id: user.employeeId,
            },
            contract: {
                id: contractId,
            },
            location: {
                id: values.room
                    ? values.room.id
                    : values.floor
                      ? values.floor.id
                      : values.building.id,
            },
            status: values.completed_date ? 'CLOSED' : 'OPEN',
            complaint_date: values.complaint_date,
            is_preventable: values.is_preventable?.name === 'Preventable',
            preventative_action: values.preventative_action,
            is_repeat_complaint: values.is_repeat_complaint.name === 'Yes',
            is_escalated: values.is_escalated,
            is_exempt_from_scorecard: values.is_exempt_from_scorecard,
            root_cause: values.root_cause,
            at_fault_employees: values.accountableEmps,
            service: {
                id: this.props.editComplaint
                    ? this.props.editComplaint.service.id
                    : this.props.selectedProgram.id,
            },
            shifts: values.shifts.map((shift) => ({ id: shift.id })),
            completed_date: values.completed_date,
            todos: [],
            work_orders: [],
        };

        const handler = (type: 'update' | 'create') => ({
            next: () => {
                if (type === 'update') {
                    mixpanelEditQualityComplaint();
                } else if (type === 'create') {
                    mixpanelCreateQualityComplaint();
                }
                this.handleSuccess(`The Complaint was successfully ${type}d.`);
                this.setState({ isSubmitting: false });
            },
            error: sendError({
                toastMessage: `There was an error ${
                    type === 'create' ? 'creating' : 'updating'
                } the Complaint.`,
            }),
        });

        if (this.props.editComplaint) {
            const updatedComplaintBody = {
                ...this.props.editComplaint,
                ...complaintBody,
            };

            this.subscription.updateComplaint = complaintsService
                .updateComplaintByComplaintId(
                    updatedComplaintBody.id,
                    updatedComplaintBody
                )
                .subscribe(handler('update'));

            return;
        }

        this.subscription.createComplaint = complaintsService
            .createComplaint(complaintBody)
            .subscribe(handler('create'));
    };

    public render() {
        const isAllTodosComplete = this.props.editComplaint?.todos?.every(
            (todo) => todo.is_complete
        );
        const disableClose =
            !this.state.closeNow &&
            !isAllTodosComplete &&
            this.props.editComplaint !== undefined;

        return (
            <Formik
                initialValues={{
                    immediate_action:
                        this.props.editComplaint?.immediate_action ?? '',
                    root_cause: this.props.editComplaint?.root_cause ?? '',
                    preventative_action:
                        this.props.editComplaint?.preventative_action ?? '',
                    classification: this.props.editComplaint?.classification
                        ? {
                              ...this.props.editComplaint.classification,
                              name: this.props.editComplaint.classification
                                  .display_name,
                          }
                        : null,
                    complaint_type: this.props.editComplaint?.type
                        ? {
                              ...this.props.editComplaint.type,
                              name: this.props.editComplaint.type.display_name,
                          }
                        : null,
                    description: this.props.editComplaint?.description ?? '',
                    accountableEmps: this.props.editComplaint
                        ? this.props.editComplaint.at_fault_employees.map(
                              (employee) => ({
                                  ...employee,
                                  name: getFullName(employee.person),
                                  subtext: employee.person.email_address,
                              })
                          )
                        : this.props.employee
                          ? [
                                {
                                    ...this.props.employee,
                                    name: getFullName(
                                        this.props.employee.person
                                    ),
                                    subtext:
                                        this.props.employee.person
                                            .email_address,
                                },
                            ]
                          : [],
                    is_repeat_complaint:
                        repeatOpts.find(
                            (opt) =>
                                this.props.editComplaint
                                    ?.is_repeat_complaint === Boolean(opt.id)
                        ) ?? repeatOpts[0],
                    is_escalated:
                        this.props.editComplaint?.is_escalated ?? false,
                    submitter: this.props.editComplaint
                        ? {
                              ...this.props.editComplaint.submitter,
                              name: getFullName(
                                  this.props.editComplaint.submitter.person
                              ),
                          }
                        : null,
                    is_preventable:
                        preventableOptions.find(
                            (opt) =>
                                this.props.editComplaint?.is_preventable ===
                                Boolean(opt.id)
                        ) ?? null,
                    complaint_date:
                        this.props.editComplaint?.complaint_date ?? new Date(),
                    completed_date: this.props.editComplaint?.completed_date
                        ? new Date(this.props.editComplaint.completed_date)
                        : null,
                    is_exempt_from_scorecard:
                        this.props.editComplaint?.is_exempt_from_scorecard ??
                        false,
                    ...getLocations(this.props.editComplaint?.location),
                    shifts: this.props.editComplaint?.shifts ?? [],
                }}
                validate={(values) => {
                    const errors: { [Key in keyof typeof values]: string } =
                        {} as any;

                    if (
                        this.state.customerEmployees.length > 0 &&
                        !values.submitter
                    ) {
                        errors.submitter =
                            'A person who wished to submit the Complaint is required.';
                    }

                    if (!values.description.length) {
                        errors.description = 'A description is required.';
                    }
                    if (!values.building) {
                        errors.building = 'A building is required.';
                    }
                    if (!values.floor) {
                        errors.floor = 'A floor is required';
                    }
                    if (!values.classification) {
                        errors.classification =
                            'A classification type is required.';
                    }
                    if (!values.is_repeat_complaint) {
                        errors.is_repeat_complaint = 'A selection is required.';
                    }
                    if (!values.complaint_type) {
                        errors.complaint_type = 'A Complaint type is required.';
                    }

                    if (this.state.closeNow && !values.is_preventable) {
                        errors.is_preventable =
                            'A preventable status is required.';
                    }
                    if (
                        this.state.closeNow &&
                        !values.immediate_action.length
                    ) {
                        errors.immediate_action =
                            'A corrective action description is required.';
                    }
                    if (this.state.closeNow && !values.root_cause.length) {
                        errors.root_cause =
                            'A corrective action description is required.';
                    }
                    if (
                        this.state.closeNow &&
                        !values.preventative_action.length
                    ) {
                        errors.preventative_action =
                            'A corrective action description is required.';
                    }
                    if (
                        !values.is_exempt_from_scorecard &&
                        this.state.closeNow &&
                        !values.accountableEmps.length
                    ) {
                        errors.accountableEmps =
                            'Accountable employee is required.';
                    }

                    return errors;
                }}
                onSubmit={(values) => {
                    this.setState({ isSubmitting: true });
                    this.submitForm(values);
                }}
            >
                {({
                    errors,
                    touched,
                    values,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    setFieldValue,
                    setValues,
                }) => (
                    <form
                        className="tw-space-y-6 tw-p-4 sm:tw-p-6"
                        onSubmit={handleSubmit}
                        data-testid="complaint-form"
                    >
                        <Text font="h2" color="hi-contrast">
                            {this.props.editComplaint ? 'Edit' : 'Enter a New'}{' '}
                            Complaint
                        </Text>

                        <FieldInputText
                            required
                            fieldType="textarea"
                            id="description"
                            name="description"
                            label="Description"
                            placeholder="Enter description details"
                            rows={3}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            defaultValue={values.description}
                            error={touched.description && errors.description}
                            data-testid="descriptionField"
                        />

                        <fieldset className="tw-space-y-1.5">
                            <div className="tw-flex tw-justify-between">
                                <Label required label="Customer" />
                                <div
                                    className="t-text-link t-text-medium"
                                    onClick={() =>
                                        this.setState({
                                            displayAddNewCustomer: true,
                                        })
                                    }
                                >
                                    + NEW CUSTOMER
                                </div>
                            </div>
                            <Dropdown
                                name="submitter"
                                items={this.state.customerEmployees}
                                selectedItem={values.submitter}
                                onChange={(employee) => {
                                    setFieldValue('submitter', employee);
                                }}
                                disabled={!this.state.customerEmployees.length}
                                error={touched.submitter && !!errors.submitter}
                                data-testid="submitterField"
                            />
                            <ErrorText
                                error={
                                    touched.submitter &&
                                    (errors.submitter as string)
                                }
                            />
                        </fieldset>

                        {this.state.displayAddNewCustomer && (
                            <NewCustomer
                                methods={{
                                    callback: (emp: Employee) => {
                                        const employee: DropdownItem<Employee> =
                                            {
                                                ...emp,
                                                name: getFullName(emp.person),
                                                subtext: `${emp.person.email_address} - ${emp.organization.name}`,
                                            };

                                        const customerEmployeesCopy =
                                            this.state.customerEmployees.findIndex(
                                                (customer) =>
                                                    customer.id === emp.id
                                            ) === -1
                                                ? [
                                                      employee,
                                                      ...this.state
                                                          .customerEmployees,
                                                  ]
                                                : this.state.customerEmployees;

                                        this.setState(
                                            {
                                                customerEmployees:
                                                    customerEmployeesCopy,
                                                displayAddNewCustomer: false,
                                            },
                                            () => {
                                                setFieldValue(
                                                    'submitter',
                                                    employee
                                                );
                                            }
                                        );
                                    },
                                    closeForm: () =>
                                        this.setState({
                                            displayAddNewCustomer: false,
                                        }),
                                }}
                            />
                        )}

                        <div className="tw-grid tw-gap-6 sm:tw-grid-cols-3">
                            <FieldInputDate
                                required
                                disableFuture
                                id="complaint_date"
                                label="Date"
                                value={TimeUtils.format(
                                    values.complaint_date,
                                    'MM/DD/YYYY'
                                )}
                                onChange={(date) => {
                                    setFieldValue('complaint_date', date);
                                }}
                                data-testid="dateField"
                            />
                            <FieldDropdown
                                required
                                id="complaint-type"
                                label="Complaint Type"
                                items={this.state.complaint_types}
                                selectedItem={values.complaint_type}
                                onChange={(type) => {
                                    setFieldValue('complaint_type', type);
                                }}
                                disabled={!this.state.complaint_types.length}
                                error={
                                    touched.complaint_type &&
                                    (errors.complaint_type as string)
                                }
                                data-testid="complaintTypeField"
                            />

                            <FieldDropdown
                                required
                                id="classification"
                                label="Classification"
                                items={this.state.classifications}
                                selectedItem={values.classification}
                                onChange={(
                                    classification: ReferenceDropdownItem
                                ) => {
                                    if (classification.code == 'OUT_OF_SCOPE') {
                                        setFieldValue(
                                            'is_exempt_from_scorecard',
                                            true
                                        );
                                    }
                                    if (classification.code == 'IN_SCOPE') {
                                        setFieldValue(
                                            'is_exempt_from_scorecard',
                                            false
                                        );
                                    }
                                    setFieldValue(
                                        'classification',
                                        classification
                                    );
                                }}
                                disabled={!this.state.classifications.length}
                                error={
                                    touched.classification &&
                                    (errors.classification as string)
                                }
                                data-testid="classificationField"
                            />

                            <FieldDropdown
                                required
                                id="building"
                                label="Building"
                                items={this.state.service_areas}
                                selectedItem={values.building}
                                onChange={(
                                    building: DropdownItem<ServiceArea>
                                ) => {
                                    this.getFloorList(building);
                                    setFieldValue('building', building);
                                    setFieldValue('floor', null);
                                    setFieldValue('room', null);
                                }}
                                disabled={!this.state.service_areas.length}
                                error={
                                    touched.building &&
                                    (errors.building as string)
                                }
                                data-testid="buildingField"
                            />
                            <FieldDropdown
                                required
                                id="floor"
                                label="Floor"
                                items={this.state.floors}
                                selectedItem={values.floor}
                                onChange={(floor: ServiceAreaDropdownItem) => {
                                    this.getAreaList(floor);
                                    setFieldValue('floor', floor);
                                    setFieldValue('room', null);
                                }}
                                disabled={!this.state.floors}
                                error={
                                    touched.floor && (errors.floor as string)
                                }
                                data-testid="floorField"
                            />
                            <FieldDropdown
                                id="area"
                                label="Area (Optional)"
                                items={this.state.areas}
                                selectedItem={values.room}
                                onChange={(room) => {
                                    setFieldValue('room', room);
                                }}
                                disabled={!this.state.areas.length}
                                error={touched.room && (errors.room as string)}
                                data-testid="areaField"
                            />
                        </div>

                        <div className="tw-grid tw-gap-6 sm:tw-grid-cols-2">
                            <FieldDropdown
                                required
                                id="is_repeat_complaint"
                                label="Repeat"
                                items={repeatOpts}
                                selectedItem={values.is_repeat_complaint}
                                onChange={(option) => {
                                    setFieldValue(
                                        'is_repeat_complaint',
                                        option
                                    );
                                }}
                                disabled={!repeatOpts.length}
                                error={
                                    touched.is_repeat_complaint &&
                                    (errors.is_repeat_complaint as string)
                                }
                                data-testid="isRepeatField"
                            />
                            {this.state.closeNow ||
                            (this.props.editComplaint &&
                                this.props.editComplaint.status == 'CLOSED') ? (
                                <FieldMultiSelect
                                    id="shifts"
                                    label="Shifts(Optional)"
                                    items={this.state.shifts}
                                    selectedItems={values.shifts}
                                    onChange={(shifts) => {
                                        setFieldValue('shifts', shifts);
                                        this.fetchEmployees(shifts as Shift[]);
                                        // when reducing number of selected shifts, unselect all employees
                                        shifts.length < values.shifts.length &&
                                            setFieldValue(
                                                'accountableEmps',
                                                []
                                            );
                                    }}
                                    data-testid="shiftsField"
                                />
                            ) : null}
                        </div>

                        <FieldMultiSelect
                            hasSelectAll
                            id="accountable-employees"
                            name="accountable-employees"
                            label="Accountable Employees"
                            items={this.state.employees}
                            selectedItems={values.accountableEmps}
                            selectedCountLabel="employee"
                            onChange={(
                                selectedEmployees: typeof this.state.employees
                            ) =>
                                setFieldValue(
                                    'accountableEmps',
                                    selectedEmployees
                                )
                            }
                            required={
                                !!(
                                    !values.is_exempt_from_scorecard &&
                                    this.state.closeNow
                                )
                            }
                            error={
                                touched.accountableEmps &&
                                (errors.accountableEmps as unknown as string)
                            }
                            data-testid="accountableEmployeesField"
                        />

                        {this.state.closeNow ||
                        (this.props.editComplaint &&
                            this.props.editComplaint.status == 'CLOSED') ? (
                            <>
                                <div className="tw-grid tw-gap-6 sm:tw-grid-cols-2">
                                    <FieldInputDate
                                        required
                                        disableFuture
                                        id="completed_date"
                                        label="Completed Date"
                                        value={
                                            values.completed_date
                                                ? TimeUtils.format(
                                                      values.completed_date,
                                                      'MM/DD/YYYY'
                                                  )
                                                : null
                                        }
                                        onChange={(date) => {
                                            setFieldValue(
                                                'completed_date',
                                                date
                                            );
                                        }}
                                        data-testid="completedDateField"
                                    />

                                    <FieldDropdown
                                        id="is_preventable"
                                        label="Preventable Status"
                                        items={preventableOptions}
                                        selectedItem={values.is_preventable}
                                        onChange={(status) => {
                                            setFieldValue(
                                                'is_preventable',
                                                status
                                            );
                                        }}
                                        error={
                                            touched.is_preventable &&
                                            (errors.is_preventable as string)
                                        }
                                        data-testid="isPreventableField"
                                    />
                                </div>

                                <FieldInputText
                                    required
                                    fieldType="textarea"
                                    id="immediate_action"
                                    name="immediate_action"
                                    label="Immediate Corrective Action Taken"
                                    rows={3}
                                    onChange={handleChange}
                                    defaultValue={values.immediate_action}
                                    error={
                                        touched.immediate_action &&
                                        errors.immediate_action
                                    }
                                    data-testid="immediateActionField"
                                />

                                <FieldInputText
                                    required
                                    fieldType="textarea"
                                    id="root_cause"
                                    name="root_cause"
                                    label="Actual Root Cause"
                                    rows={3}
                                    onChange={handleChange}
                                    defaultValue={values.root_cause}
                                    error={
                                        touched.root_cause && errors.root_cause
                                    }
                                    data-testid="rootCauseField"
                                />

                                <FieldInputText
                                    required
                                    fieldType="textarea"
                                    id="preventative_action"
                                    name="preventative_action"
                                    label="Preventative Action"
                                    rows={3}
                                    onChange={handleChange}
                                    defaultValue={values.preventative_action}
                                    error={
                                        touched.preventative_action &&
                                        errors.preventative_action
                                    }
                                    data-testid="preventativeActionField"
                                />

                                <FieldRadioGroup
                                    id="escalated"
                                    name="escalated"
                                    label="Has this Complaint been escalated?"
                                    radios={[
                                        { label: 'Yes', value: 'true' },
                                        { label: 'No', value: 'false' },
                                    ]}
                                    onChange={(e) => {
                                        const { value } = e.target;
                                        setFieldValue(
                                            'is_escalated',
                                            value === 'true'
                                        );
                                    }}
                                    defaultChecked={
                                        values.is_escalated ? 'true' : 'false'
                                    }
                                    data-testid="escalatedField"
                                />
                            </>
                        ) : null}

                        <Tooltip
                            className="tw-inline-block"
                            position="bottomLeft"
                            disabled={!disableClose}
                            render={
                                <Box className="tw-p-4" rounded>
                                    <Text font="body-sm" color="hi-contrast">
                                        Must complete all todos before closing
                                    </Text>
                                </Box>
                            }
                        >
                            {this.props.editComplaint?.status !== 'CLOSED' && (
                                <Button
                                    label={
                                        !this.state.closeNow
                                            ? 'Close Complaint Now'
                                            : 'Continue Without Closing'
                                    }
                                    color={
                                        !this.state.closeNow
                                            ? 'primary'
                                            : 'alternate'
                                    }
                                    onClick={(e) => {
                                        e.preventDefault();
                                        this.setState(
                                            { closeNow: !this.state.closeNow },
                                            () => {
                                                if (!this.state.closeNow) {
                                                    setValues({
                                                        ...values,
                                                        immediate_action: '',
                                                        root_cause: '',
                                                        preventative_action: '',
                                                        is_preventable: null,
                                                        is_escalated: false,
                                                        completed_date: null,
                                                    });

                                                    return;
                                                }
                                                setFieldValue(
                                                    'is_preventable',
                                                    preventableOptions[0]
                                                );
                                                setFieldValue(
                                                    'completed_date',
                                                    new Date()
                                                );
                                            }
                                        );
                                    }}
                                    disabled={disableClose}
                                />
                            )}
                        </Tooltip>

                        <div className="tw-flex tw-justify-end tw-space-x-4">
                            <Button
                                label="Cancel"
                                onClick={modalService.closeAll}
                                color="secondary"
                            />
                            <Button
                                label="Save"
                                onClick={handleSubmit}
                                disabled={this.state.isSubmitting}
                            />
                        </div>
                    </form>
                )}
            </Formik>
        );
    }
}

export const ComplaintForm = (props: IProps) => {
    const { state } = useAppContext();
    return <Form {...state} {...props} />;
};
