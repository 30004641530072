import { useContext } from 'react';

// interface
import { createCtx } from '@helpers';

import { GmpAuditModal } from './interfaces';

// helpers

// utils
import { gmpAuditReducer } from './reducer';

const initialState: GmpAuditModal.State = {
	isLoading: true,
	mode: null,
	selectedEmployee: null,
	data: null,
	form: null,
	questionnaire: null,
	dropdowns: {
		correctiveActions: null,
	},
	attemptSubmit: false,
	isSubmitting: false,
	isEditing: false,
};

function init(state: GmpAuditModal.State) {
	return state;
}

export const [gmpAuditModalContext, GmpAuditModalProvider] = createCtx(
	gmpAuditReducer,
	initialState,
	init
);

export function useGmpAuditsModalContext() {
	return useContext(gmpAuditModalContext);
}
