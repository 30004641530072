import { useState, useMemo } from 'react';

// services
import { Media, Todo } from '@api-interfaces';
import { mixpanelService, todosService } from '@apis';
import { WorkOrderModal } from '@app/modals/_ds2';
import { Badge, Box, ModalStack, Text } from '@atoms';
import { OverflowMenuButton } from '@components/_globals/components';
import { OverflowMenuButtonProps } from '@components/_globals/components/overflow-menu-button/overflow-menu-button.interfaces';
import MiniProfile from '@components/MiniProfile/MiniProfile';
import {
    DetailsModalWrapper,
    LeftSection,
    RightSection,
    ModalControl,
    ModalTitle,
    ModalContent,
    ModalHeading,
} from '@components/ModalDetail/ModalDetail';
import { TodoForm } from '@forms';
import { Button, MiniProfile as NewMiniProfile, ImageList } from '@new';
import {
    imageService,
    toasterService,
    modalService,
    refetchService,
} from '@services';

// components
import { Comments } from '@new/Comments';
import { Modal } from '@templates';

// helpers
import {
    TimeUtils,
    truncate,
    getFullName,
    sendError,
    getLocations,
} from '@helpers';
import { useAppContext } from '@hooks';
import {
    mixpanelCompleteQualityTodo,
    mixpanelDeleteQualityTodo,
    useMixpanelViewSpecificQualityTodo,
} from '@app/mixpanel/MixpanelPageTrack.tsx';

// interfaces
import { ImageListMedia } from '@new/ImageList/ImageList.interfaces';

interface Props {
    todo: Todo;
}

export const TodoModal = ({ todo }: Props) => {
    const {
        state: { user },
        dispatch,
    } = useAppContext();

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isCompleting, setIsCompleting] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);

    useMixpanelViewSpecificQualityTodo();

    function getSourceText(sourceType: 'MANAGER' | 'AUDIT' | 'REAUDIT') {
        switch (sourceType) {
            case 'MANAGER':
                return 'Manager';
            case 'AUDIT':
                return 'Audit';
            case 'REAUDIT':
                return 'Re-Audit';
            default:
                return 'None';
        }
    }

    function handleSuccess(message: string) {
        toasterService.newToast({
            status: 'success',
            message,
        });
        refetchService.fetch('todos');
    }

    function handleError(err) {
        return sendError({
            callback: () => setIsSubmitting(false),
        })(err);
    }

    function handleCompletingConfirmationClose() {
        setIsCompleting(false);
    }

    function handleDeleteConfirmationClose() {
        setIsDeleting(false);
    }

    const locations = getLocations(todo.location);

    let extralocation = '';

    if (todo.areainspection_todo_location.length > 0) {
        extralocation = truncate(todo.areainspection_todo_location[0].name);
    } else if (todo.service_task_todo_location.length > 0) {
        extralocation = truncate(todo.service_task_todo_location[0].name);
    }

    const {
        is_complete: isComplete,
        due_date: dueDate,
        closed_date: closedDate,
        due_status: dueStatus,
    } = todo;

    const pastDue = isComplete
        ? TimeUtils.isBefore(dueDate, closedDate)
        : TimeUtils.isBefore(dueDate, new Date());

    let statusText = '';

    if (dueStatus === 'OPEN' || (!isComplete && !pastDue)) {
        statusText = 'Open';
    } else if (dueStatus === 'CLOSED' || (isComplete && !pastDue)) {
        statusText = 'Closed';
    } else if (dueStatus === 'OPEN_PAST_DUE' || (!isComplete && pastDue)) {
        statusText = 'Open Past Due';
    } else if (dueStatus === 'CLOSED_PAST_DUE' || (isComplete && pastDue)) {
        statusText = 'Closed Past Due';
    } else {
        statusText = 'N/A';
    }

    const hasCreatePermissions = user.hasPermission(2, 'ToDos');
    const hasDeletePermissions = user.hasPermission(3, 'ToDos');

    const menuItems = useMemo(() => {
        const menuItems: OverflowMenuButtonProps['items'] = [];
        if (hasCreatePermissions) {
            menuItems.push({
                name: 'Update',
                onClick: () => {
                    dispatch({
                        type: 'SET_IS_MODAL_STACK_OPEN',
                        payload: true,
                    });
                    modalService.open({
                        component: <TodoForm data={{ todo }} />,
                        callback: () => {
                            dispatch({
                                type: 'SET_IS_MODAL_STACK_OPEN',
                                payload: false,
                            });
                        },
                    });
                },
            });
        }
        if (hasDeletePermissions) {
            menuItems.push({
                name: 'Delete',
                onClick: () => {
                    setIsDeleting(true);
                },
                className: 'tw-text-theme-danger-500-300',
            });
        }

        return menuItems;
    }, [user]);

    const allMedia = useMemo(() => {
        const images: ImageListMedia[] = [];

        for (const media of todo.media_list) {
            images.push({
                ...media,
                origin: `Todo #${todo.id}`,
                tags: [media.is_after_todo ? 'After' : 'Before'],
            });
        }

        // Sort the images to have Before Images first
        images.sort((a, b) => {
            if (a.is_after_todo === b.is_after_todo) {
                return 0;
            }
            return a.is_after_todo ? 1 : -1;
        });

        return images;
    }, [todo]);

    return (
        <DetailsModalWrapper>
            <LeftSection>
                <ModalControl>
                    <ModalTitle>To-Do Details</ModalTitle>
                </ModalControl>

                <ModalControl>
                    <ModalHeading text="Source" />
                    <div className="t-header-on-dark t-text-small tw-mb-1 sm:tw-mb-2 t-text-on-dark">
                        {getSourceText(todo.source)}
                    </div>
                </ModalControl>

                <ModalControl>
                    <ModalHeading text="Assigned By" />
                    <Box rounded className="tw-p-2">
                        <NewMiniProfile.Md person={todo.creator?.person} />
                    </Box>
                </ModalControl>

                <ModalControl>
                    <ModalHeading text="Location" />
                    {locations.building?.id ? (
                        <Text color="hi-contrast" font="body-sm">
                            Building: {locations.building.name}
                        </Text>
                    ) : null}
                    {locations.floor?.id ? (
                        <Text color="hi-contrast" font="body-sm">
                            Floor: {locations.floor.name}
                        </Text>
                    ) : null}
                    {locations.room?.id ? (
                        <Text color="hi-contrast" font="body-sm">
                            Area: {locations.room.name}
                        </Text>
                    ) : null}
                    {extralocation.length > 0 ? (
                        <div className="t-header-on-dark t-text-small tw-mb-1 sm:tw-mb-2">
                            {extralocation}
                        </div>
                    ) : null}
                    <div className="t-header-on-dark t-text-small tw-mb-1 sm:tw-mb-2">
                        {todo.contract.customer.name}, {todo.contract.name}
                    </div>
                </ModalControl>

                <ModalControl>
                    <ModalHeading text="Project Work" />
                    <div className="t-header-on-dark t-text-small tw-mb-1 sm:tw-mb-2">
                        {todo?.is_project_work ? 'Yes' : 'No'}
                    </div>
                </ModalControl>

                <ModalControl>
                    <ModalHeading text="Work Order" />
                    {todo?.work_order_id ? (
                        <Button
                            color="text-button"
                            label={
                                todo?.work_order_id
                                    ? `#${todo.work_order_id}`
                                    : 'N/A'
                            }
                            className="tw-px-0 tw-py-0 tw-h-min"
                            onClick={() =>
                                modalService.open({
                                    component: (
                                        <WorkOrderModal
                                            workOrderId={todo?.work_order_id}
                                            closeModal={() =>
                                                modalService.close()
                                            }
                                        />
                                    ),
                                })
                            }
                        />
                    ) : (
                        <div className="t-header-on-dark t-text-small tw-mb-1 sm:tw-mb-2">
                            {todo?.work_order_id
                                ? `#${todo.work_order_id}`
                                : 'N/A'}
                        </div>
                    )}
                </ModalControl>
            </LeftSection>

            <RightSection>
                <ModalControl>
                    <div className="tw-flex tw-items-center tw-justify-between">
                        <ModalContent>
                            Created on:{' '}
                            {TimeUtils.format(
                                todo.created_on_date,
                                'MM/DD/YYYY hh:mm A'
                            )}
                        </ModalContent>
                        <OverflowMenuButton
                            items={menuItems}
                            flowDirection="bottom-left"
                        />
                    </div>
                </ModalControl>

                <ModalControl>
                    <ModalHeading text="Description" />
                    <ModalContent>{todo.comment}</ModalContent>
                </ModalControl>

                <ModalControl>
                    <ModalHeading text="Due Date" />
                    <ModalContent>
                        {TimeUtils.format(todo.due_date, 'MM/DD/YYYY hh:mm A')}
                    </ModalContent>
                </ModalControl>

                <ModalControl>
                    <ModalHeading text="Seen" />
                    <ModalContent>
                        {todo.last_seen_date
                            ? TimeUtils.format(
                                  todo.last_seen_date,
                                  'MM/DD/YYYY hh:mm A'
                              )
                            : 'Not seen yet.'}
                    </ModalContent>
                </ModalControl>

                <ModalControl>
                    <ModalHeading text="Status" />
                    <ModalContent
                        className={`${
                            pastDue ? 'tw-text-theme-danger-500-300' : ''
                        }`}
                    >
                        {statusText}
                    </ModalContent>
                </ModalControl>

                {todo.closed_date && (
                    <ModalControl>
                        <ModalHeading text="Closed Date" />
                        <ModalContent>
                            {TimeUtils.format(
                                todo.closed_date,
                                'MM/DD/YYYY hh:mm A'
                            )}
                        </ModalContent>
                    </ModalControl>
                )}

                {Boolean(todo && todo.media_list && todo.media_list.length) && (
                    <ModalControl>
                        <ModalHeading text="Photos" />
                        <ImageList media={allMedia} maxPictures={3} />
                    </ModalControl>
                )}

                {!!todo.shifts.length && (
                    <ModalControl>
                        <ModalHeading text="Shifts" />
                        <ModalContent>
                            <div className="tw-flex tw-items-center tw-flex-wrap tw-gap-1">
                                {todo.shifts.map((shift) => (
                                    <Badge
                                        key={shift.name}
                                        label={shift.name}
                                        color="primary"
                                    />
                                ))}
                            </div>
                        </ModalContent>
                    </ModalControl>
                )}

                <ModalControl>
                    <ModalHeading text="Assigned Employees" />
                    <MiniProfile
                        data={{
                            employeeName: getFullName(todo.assigned_to?.person),
                            imgUrl: imageService.getImageByUniqueId(
                                todo.assigned_to.person.photo_url
                            ),
                            siteName: todo.contract.name,
                            clientName: todo.contract.customer.name,
                            jobTitle:
                                todo?.assigned_to?.positions[0]?.name ?? '',
                            hiredDate: todo?.assigned_to?.hired_date ?? null,
                            employee: todo?.assigned_to,
                        }}
                        settings={{ size: 'lg', bg: true }}
                    />
                </ModalControl>

                <Comments work_item_type="todo" item_id={todo.id} />

                {!todo.is_complete && hasCreatePermissions && (
                    <ModalControl>
                        <div className="tw-flex tw-justify-end">
                            <Button
                                label="Complete"
                                onClick={() => {
                                    setIsCompleting(true);
                                }}
                                disabled={isSubmitting}
                            />
                        </div>
                    </ModalControl>
                )}
            </RightSection>
            {isCompleting && (
                <ModalStack
                    position="top-half-center"
                    onOverlayClick={handleCompletingConfirmationClose}
                >
                    <Modal.Prompt
                        title="Close Todo?"
                        prompt="Are you sure you want to close the to-do item?"
                        onCancel={handleCompletingConfirmationClose}
                        buttons={[
                            {
                                label: 'No',
                                onClick: handleCompletingConfirmationClose,
                                color: 'secondary',
                            },
                            {
                                label: 'Yes',
                                onClick: () => {
                                    todosService
                                        .completeTodo(todo.id)
                                        .subscribe({
                                            next: (res) => {
                                                handleCompletingConfirmationClose();
                                                handleSuccess(
                                                    'The to-do item was closed successfully'
                                                );
                                                mixpanelCompleteQualityTodo();
                                                modalService.closeAll({
                                                    refetch: true,
                                                    updatedTodo: res,
                                                });
                                            },
                                            error: handleError,
                                        });
                                },
                            },
                        ]}
                    />
                </ModalStack>
            )}
            {isDeleting && (
                <ModalStack
                    position="top-half-center"
                    onOverlayClick={handleDeleteConfirmationClose}
                >
                    <Modal.Prompt
                        title="Delete?"
                        prompt="Are you sure you want to delete this to-do item?"
                        onCancel={handleDeleteConfirmationClose}
                        buttons={[
                            {
                                label: 'No',
                                onClick: handleDeleteConfirmationClose,
                                color: 'secondary',
                            },
                            {
                                label: 'Yes',
                                onClick: () => {
                                    setIsSubmitting(true);
                                    todosService.deleteTodo(todo.id).subscribe({
                                        next: () => {
                                            handleDeleteConfirmationClose();
                                            mixpanelDeleteQualityTodo();
                                            handleSuccess(
                                                'The to-do item was deleted successfully'
                                            );
                                            modalService.closeAll({
                                                refetch: true,
                                                deletedTodo: todo,
                                            });
                                        },
                                        error: handleError,
                                    });
                                },
                                color: 'danger',
                            },
                        ]}
                    />
                </ModalStack>
            )}
        </DetailsModalWrapper>
    );
};
