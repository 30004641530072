/**
 * Service for processing time table (routes and their stops frequencies) data sets.
 * Currently used in:
 * - Index -> Route Manager -> Route Details Modal
 * - Route Builder -> Components -> Time Table
 */

// interfaces
import { has } from 'lodash';

import {
	FrequencyPeriod,
	RouteDefinition,
	StopFrequency,
} from '@api-interfaces';

import {
	getGroupedPeriodicTaskRepeatText,
	getTrainingText,
	getNewCoverageCount,
	sortPeriodicByServiceDate,
	sortPeriodicByName,
} from './helpers';
import {
	TIME_TABLE_ROW_TYPE,
	TimeTable,
	TimeTableGroupsName,
	TimeTableRow,
} from './interfaces';

// services

// helpers
import { TimeDuration } from './time-duration';

class TimeTableProcessor {
	public processTimeTable(
		routeDefinition: RouteDefinition,
		settings: {
			sortPeriodic?: 'name' | 'service-date'; // optional setting to sort time table rows by periodic service date (default: none)
			hideAllNA?: boolean; // optional setting to filter out stops with tasks marked all NA (default: false)
			useAreaLinks?: boolean; // currently setting true will show empty stops
		} = {}
	): TimeTable {
		const { sortPeriodic, hideAllNA, useAreaLinks } = settings;

		let timetable: TimeTable = {
			ContractId: routeDefinition.contract.id,
			RouteId: routeDefinition.id,
			StartTime: routeDefinition.start_time,
			EndTime: routeDefinition.end_time,
			Groups: {
				Start: null,
				End: null,
				Daily: [],
				Weekly: [],
				Monthly: [],
				Quarterly: [],
				Yearly: [],
				AsNeeded: [],
			},
			Summary: {
				Covered: {
					Daily: 0,
					ShiftPrep: 0,
					Break: 0,
					Lunch: 0,
					DailyTotal: 0,

					Weekly: 0,
					Monthly: 0,
					Quarterly: 0,
					Yearly: 0,
					AsNeeded: 0,
					PeriodicTotal: 0,

					Training: 0,

					All: 0,
				},
				Total: {
					Daily: 0,
					ShiftPrep: 0,
					Break: 0,
					Lunch: 0,
					DailyTotal: 0,

					Weekly: 0,
					Monthly: 0,
					Quarterly: 0,
					Yearly: 0,
					AsNeeded: 0,
					PeriodicTotal: 0,

					Training: 0,

					All: 0,
				},
			},
			references: {
				route: routeDefinition,
			},
		};

		// Daily Counts
		let breakCount = 0;
		let lunchCount = 0;

		// Periodic Counts
		let trainingCount = 0;

		// Sort Dailies by id (new stops should be sent/created by alphanumeric, so id works here to sort by)
		routeDefinition.stops.sort((a, b) => {
			if (!a.sequence && !b.sequence) {
				return a.id - b.id;
			}
			if (!a.sequence) {
				return 1;
			}
			if (!b.sequence) {
				return -1;
			}
			return 0;
		});

		// Daily (Start)
		if (routeDefinition.start_shift) {
			let area = {
				id: null,
				type: null,
				name: null,
				building: null,
				floor: null,
				is_verifiable: false,
			};

			if (routeDefinition.start_shift.area) {
				const building = routeDefinition.start_shift.area.location.find(
					(location) => location.type === 'Building'
				);
				const floor = routeDefinition.start_shift.area.location.find(
					(location) => location.type === 'Floor'
				);

				area = {
					id: routeDefinition.start_shift.area.id,
					type: routeDefinition.start_shift.area.type,
					name: routeDefinition.start_shift.area.name,
					building: building ? building.name : null,
					floor: floor ? floor.name : null,
					is_verifiable: false,
				};
			}

			const startRow: TimeTableRow = {
				id: routeDefinition.start_shift.id,
				area,
				special: {
					name: 'Start Shift',
					icon: 'fas fa-clock',
					color: 'var(--success-300)',
				},
				time: {
					total: routeDefinition.start_shift.duration, // stop duration is correct for start, end, breaks
				},
				actions: {
					isViewable: false,
					isReorderable: false,
				},
				type: TIME_TABLE_ROW_TYPE.START_SHIFT,
				sequence: null,
				new_coverage_count: 0,
				references: {
					stop: routeDefinition.start_shift,
				},
			};

			timetable.Groups.Start = startRow;
		}

		routeDefinition.stops.forEach((stopDefinition) => {
			const building = stopDefinition.area.location.find(
				(location) => location.type === 'Building'
			);
			const floor = stopDefinition.area.location.find(
				(location) => location.type === 'Floor'
			);

			if (stopDefinition.type === 'BREAK' || stopDefinition.type === 'LUNCH') {
				// Daily Break or Lunch
				if (stopDefinition.type === 'BREAK') {
					breakCount++;
				} else if (stopDefinition.type === 'LUNCH') {
					lunchCount++;
				}

				const breakRow: TimeTableRow = {
					id: stopDefinition.id,
					area: {
						id: stopDefinition.area.id,
						type: stopDefinition.area.type,
						name: stopDefinition.area.name,
						building: building || null,
						floor: floor || null,
						is_verifiable: false, // START, BREAK, LUNCH arent verifiable
					},
					special: {
						name:
							stopDefinition.type === 'BREAK'
								? `Break${breakCount > 1 ? ` ${breakCount}` : ''}`
								: stopDefinition.type === 'LUNCH'
								? `Lunch${lunchCount > 1 ? ` ${lunchCount}` : ''}`
								: '',
						sub: stopDefinition.is_paid ? 'Paid' : 'Unpaid',
						icon:
							stopDefinition.type === 'BREAK'
								? 'fas fa-coffee'
								: stopDefinition.type === 'LUNCH'
								? 'fas fa-utensils'
								: '',
						color: 'var(--warning-100)',
					},
					time: {
						total: stopDefinition.duration, // stop duration is correct for start, end, breaks
					},
					actions: {
						isViewable: false,
						isReorderable: false,
					},
					type:
						stopDefinition.type === 'BREAK'
							? TIME_TABLE_ROW_TYPE.BREAK
							: stopDefinition.type === 'LUNCH'
							? TIME_TABLE_ROW_TYPE.LUNCH
							: null,
					sequence: stopDefinition.sequence,
					period: 'DAILY',
					new_coverage_count: 0,
					references: {
						stop: stopDefinition,
					},
				};

				timetable.Groups.Daily.push(breakRow);
			} else if (stopDefinition.type === 'TRAINING') {
				// Training
				trainingCount++;

				const trainingRow: TimeTableRow = {
					id: stopDefinition.id,
					area: {
						id: stopDefinition.area.id,
						type: stopDefinition.area.type,
						name: stopDefinition.area.name,
						building: building || null,
						floor: floor || null,
						is_verifiable: false, // Training is verifiable
					},
					special: {
						name: `Training${trainingCount > 1 ? ` ${trainingCount}` : ''}`,
						sub: has(stopDefinition, 'area.name')
							? stopDefinition.area.name
							: 'N/A',
						icon: 'fas fa-graduation-cap',
						color: 'var(--primary-100)',
					},
					time: {
						total: stopDefinition.duration,
					},
					actions: {
						isViewable: false,
						isReorderable: false,
					},
					type: TIME_TABLE_ROW_TYPE.TRAINING,
					sequence: null, // stopDefinition.sequence
					period: 'WEEKLY',
					nonWork: {
						day: stopDefinition.stop_day,
						month: stopDefinition.stop_month,
					},
					new_coverage_count: 0,
					references: {
						stop: stopDefinition,
					},
				};

				trainingRow.periodicRepeatText = getTrainingText(trainingRow, true);

				timetable.Groups.Weekly.push(trainingRow);
			} else {
				const stopFrequencies = stopDefinition.stop_frequencies.filter(
					(stopFrequency) => stopFrequency.recurrence > 0
				);
				const stopFrequenciesDaily = stopFrequencies.filter(
					(stopFrequency) => stopFrequency.period === 'DAILY'
				);
				const stopFrequenciesWeekly = stopFrequencies.filter(
					(stopFrequency) => stopFrequency.period === 'WEEKLY'
				);
				const stopFrequenciesMonthly = stopFrequencies.filter(
					(stopFrequency) => stopFrequency.period === 'MONTHLY'
				);
				const stopFrequenciesQuarterly = stopFrequencies.filter(
					(stopFrequency) => stopFrequency.period === 'QUARTERLY'
				);
				const stopFrequenciesYearly = stopFrequencies.filter(
					(stopFrequency) => stopFrequency.period === 'YEARLY'
				);
				const stopFrequenciesAsNeeded = stopFrequencies.filter(
					(stopFrequency) => stopFrequency.period === 'AS_NEEDED'
				);

				const noStopFrequencies = !stopDefinition.stop_frequencies.length;
				const noStopFrequenciesTaken = !stopFrequencies.length;

				// Empty Stop (No Stop Frequencies or all RSDF recurrences 0)
				if (
					useAreaLinks &&
					stopDefinition.type == 'WORK' &&
					(noStopFrequencies || noStopFrequenciesTaken)
				) {
					const row: TimeTableRow = {
						id: stopDefinition.id,
						area: {
							id: stopDefinition.area.id,
							type: stopDefinition.area.type,
							name: stopDefinition.area.name,
							building: building || null,
							floor: floor || null,
							is_verifiable: stopDefinition.is_verifiable,
						},
						time: {
							total: stopDefinition.duration,
						},
						actions: {
							isViewable: true,
							isReorderable: true,
						},
						type: TIME_TABLE_ROW_TYPE.EMPTY,
						sequence: stopDefinition.sequence,
						period: 'DAILY',
						new_coverage_count: 0,
						references: {
							stop: stopDefinition,
						},
					};

					timetable.Groups.Daily.push(row);
				}

				// Daily Normal
				if (stopFrequenciesDaily.length) {
					const totalTime = stopFrequenciesDaily.reduce(
						(acc, cur) => acc + cur.duration,
						0
					);

					const row: TimeTableRow = {
						id: stopDefinition.id,
						area: {
							id: stopDefinition.area.id,
							type: stopDefinition.area.type,
							name: stopDefinition.area.name,
							building: building || null,
							floor: floor || null,
							is_verifiable: stopDefinition.is_verifiable,
						},
						time: {
							total: totalTime,
						},
						actions: {
							isViewable: true,
							isReorderable: true,
						},
						type: TIME_TABLE_ROW_TYPE.REGULAR,
						sequence: stopDefinition.sequence,
						period: 'DAILY',
						new_coverage_count: getNewCoverageCount(stopFrequenciesDaily),
						references: {
							stop: stopDefinition,
						},
					};

					let data = [];

					stopFrequenciesDaily.forEach((stopFrequency) => {
						if (stopFrequency.recurrence >= 1) {
							data.push({
								id: stopFrequency.id,
								frequencyId: stopFrequency.frequency_id,
								name: stopFrequency.task_bundle?.name ?? 'N/A',
								duration: stopFrequency.duration,
								recurrence_type: stopFrequency.recurrence_type
									? stopFrequency.recurrence_type
									: 'IS',
							});
						}
					});

					data = data.sort((a, b) => a.frequencyId - b.frequencyId);

					const count = stopFrequenciesDaily.reduce(
						(acc, cur) =>
							acc +
							(cur.recurrence > 0 && cur.recurrence_type !== 'NA' ? 1 : 0),
						0
					);

					const duration = stopFrequenciesDaily.reduce(
						(acc, cur) => acc + cur.duration,
						0
					);

					const allNA = data.every((task) => task.recurrence_type === 'NA');

					row.tasks = {
						data,
						count,
						duration,
						allNA,
					};
					timetable.Groups.Daily.push(row);
				}

				// Periodic function for Weekly, Monthly, Quarterly, Yearly, As Needed
				const periodicRow = (
					groupName: TimeTableGroupsName,
					frequencyPeriod: FrequencyPeriod,
					stopFrequencies: StopFrequency[]
				) => {
					if (stopFrequencies.length) {
						const totalTime = stopFrequencies.reduce(
							(acc, cur) => acc + cur.duration,
							0
						);

						const row: TimeTableRow = {
							id: stopDefinition.id,
							area: {
								id: stopDefinition.area.id,
								type: stopDefinition.area.type,
								name: stopDefinition.area.name,
								building: building || null,
								floor: floor || null,
								is_verifiable: stopDefinition.is_verifiable,
							},
							time: {
								total: totalTime,
							},
							actions: {
								isViewable: true,
								isReorderable: false,
							},
							type: TIME_TABLE_ROW_TYPE.PERIODIC,
							sequence: null, // stopDefinition.sequence
							period: frequencyPeriod,
							new_coverage_count: getNewCoverageCount(stopFrequencies),
							references: {
								stop: stopDefinition,
							},
						};

						let data = [];

						stopFrequencies.forEach((stopFrequency) => {
							if (stopFrequency.recurrence >= 1) {
								data.push({
									id: stopFrequency.id,
									frequencyId: stopFrequency.frequency_id,
									name: stopFrequency.task_bundle?.name ?? 'N/A',
									duration: stopFrequency.duration,
									repeat: {
										period: stopFrequency.period,
										day: stopFrequency.service_day,
										month: stopFrequency.service_month,
									},
									recurrence_type: stopFrequency.recurrence_type
										? stopFrequency.recurrence_type
										: 'IS',
								});
							}
						});

						data = data.sort((a, b) => a.frequencyId - b.frequencyId);

						const count = stopFrequencies.reduce(
							(acc, cur) =>
								acc +
								(cur.recurrence > 0 && cur.recurrence_type !== 'NA' ? 1 : 0),
							0
						);

						const duration = stopFrequencies.reduce(
							(acc, cur) => acc + cur.duration,
							0
						);

						const allNA = data.every((task) => task.recurrence_type === 'NA');

						row.tasks = {
							data,
							count,
							duration,
							allNA,
						};

						row.periodicRepeatText = getGroupedPeriodicTaskRepeatText(
							row,
							true
						);

						// @ts-ignore doesn't like fast group could be array or 1 object
						timetable.Groups[groupName].push(row);
					}
				};

				// Weekly
				periodicRow('Weekly', 'WEEKLY', stopFrequenciesWeekly);

				// Monthly
				periodicRow('Monthly', 'MONTHLY', stopFrequenciesMonthly);

				// Quarterly
				periodicRow('Quarterly', 'QUARTERLY', stopFrequenciesQuarterly);

				// Yearly
				periodicRow('Yearly', 'YEARLY', stopFrequenciesYearly);

				// As Needed
				periodicRow('AsNeeded', 'AS_NEEDED', stopFrequenciesAsNeeded);
			}
		});

		// Daily (End)
		if (routeDefinition.end_shift) {
			let area = {
				id: null,
				type: null,
				name: null,
				building: null,
				floor: null,
				is_verifiable: false,
			};

			if (routeDefinition.end_shift.area) {
				const building = routeDefinition.end_shift.area.location.find(
					(location) => location.type === 'Building'
				);
				const floor = routeDefinition.end_shift.area.location.find(
					(location) => location.type === 'Floor'
				);

				area = {
					id: routeDefinition.end_shift.area.id,
					type: routeDefinition.end_shift.area.type,
					name: routeDefinition.end_shift.area.name,
					building: building ? building.name : null,
					floor: floor ? floor.name : null,
					is_verifiable: false,
				};
			}

			const endRow: TimeTableRow = {
				id: routeDefinition.end_shift.id,
				area,
				special: {
					name: 'End Shift',
					icon: 'fas fa-clock',
					color: 'var(--danger-300)',
				},
				time: {
					total: routeDefinition.end_shift.duration, // stop duration is correct for start, end and breaks
				},
				actions: {
					isViewable: false,
					isReorderable: false,
				},
				type: TIME_TABLE_ROW_TYPE.END_SHIFT,
				sequence: null,
				new_coverage_count: 0,
				references: {
					stop: routeDefinition.end_shift,
				},
			};

			timetable.Groups.End = endRow;
		}

		// apply sort periodic if supplied
		if (sortPeriodic == 'service-date') {
			timetable.Groups.Weekly = sortPeriodicByServiceDate(
				timetable.Groups.Weekly
			);
			timetable.Groups.Monthly = sortPeriodicByServiceDate(
				timetable.Groups.Monthly
			);
			timetable.Groups.Quarterly = sortPeriodicByServiceDate(
				timetable.Groups.Quarterly
			);
			timetable.Groups.Yearly = sortPeriodicByServiceDate(
				timetable.Groups.Yearly
			);
		} else if (sortPeriodic == 'name') {
			// sort periodic by alphanumeric
			timetable.Groups.Weekly = sortPeriodicByName(timetable.Groups.Weekly);
			timetable.Groups.Monthly = sortPeriodicByName(timetable.Groups.Monthly);
			timetable.Groups.Quarterly = sortPeriodicByName(
				timetable.Groups.Quarterly
			);
			timetable.Groups.Yearly = sortPeriodicByName(timetable.Groups.Yearly);
			timetable.Groups.AsNeeded = sortPeriodicByName(timetable.Groups.AsNeeded);
		}

		// optional - apply filter out stops with all NA tasks
		if (hideAllNA === true) {
			timetable.Groups.Daily = timetable.Groups.Daily.filter(
				(row) => !row.tasks || (has(row, 'tasks.allNA') && !row.tasks.allNA)
			);
			timetable.Groups.Weekly = timetable.Groups.Weekly.filter(
				(row) => !row.tasks || (has(row, 'tasks.allNA') && !row.tasks.allNA)
			);
			timetable.Groups.Monthly = timetable.Groups.Monthly.filter(
				(row) => !row.tasks || (has(row, 'tasks.allNA') && !row.tasks.allNA)
			);
			timetable.Groups.Quarterly = timetable.Groups.Quarterly.filter(
				(row) => !row.tasks || (has(row, 'tasks.allNA') && !row.tasks.allNA)
			);
			timetable.Groups.Yearly = timetable.Groups.Yearly.filter(
				(row) => !row.tasks || (has(row, 'tasks.allNA') && !row.tasks.allNA)
			);
			timetable.Groups.AsNeeded = timetable.Groups.AsNeeded.filter(
				(row) => !row.tasks || (has(row, 'tasks.allNA') && !row.tasks.allNA)
			);
		}

		timetable = this.scheduleTimeTable(timetable);

		return timetable;
	}

	public scheduleTimeTable(timetable: TimeTable): TimeTable {
		// Daily Time Duration Tracker
		const timeDuration = new TimeDuration(timetable.StartTime);

		// Sequence ever update
		let sequence = 1;

		// start
		timeDuration.addMinutes(timetable.Groups.Start.time.total);
		timetable.Summary.Covered.ShiftPrep += timetable.Groups.Start.time.total;

		timetable.Groups.Start.time = {
			start: timeDuration.previousTime,
			end: timeDuration.currentTime,
			total: timetable.Groups.Start.time.total,
			day: timeDuration.currentDay,
		};

		// dailies
		timetable.Groups.Daily.forEach((row) => {
			const totalTime = row.time.total;

			if (row.type == TIME_TABLE_ROW_TYPE.BREAK) {
				timeDuration.addMinutes(totalTime);
				timetable.Summary.Covered.Break += totalTime;

				row.time = {
					start: timeDuration.previousTime,
					end: timeDuration.currentTime,
					total: totalTime,
					day: timeDuration.currentDay,
				};
			} else if (row.type == TIME_TABLE_ROW_TYPE.LUNCH) {
				timeDuration.addMinutes(totalTime);
				timetable.Summary.Covered.Lunch += totalTime;

				row.time = {
					start: timeDuration.previousTime,
					end: timeDuration.currentTime,
					total: totalTime,
					day: timeDuration.currentDay,
				};
			} else if (row.type == TIME_TABLE_ROW_TYPE.TRAINING) {
				timetable.Summary.Covered.Training += totalTime;
				timetable.Summary.Covered.Weekly += totalTime; // training considered periodic weekly for now
			} else if (row.type == TIME_TABLE_ROW_TYPE.EMPTY) {
				timeDuration.addMinutes(totalTime);
				timetable.Summary.Covered.Daily += totalTime;

				row.time = {
					start: timeDuration.previousTime,
					end: timeDuration.currentTime,
					total: totalTime,
					day: timeDuration.currentDay,
				};
			} else if (row.type == TIME_TABLE_ROW_TYPE.REGULAR) {
				timeDuration.addMinutes(totalTime);
				timetable.Summary.Covered.Daily += totalTime;

				row.time = {
					start: timeDuration.previousTime,
					end: timeDuration.currentTime,
					total: totalTime,
					day: timeDuration.currentDay,
				};
			}

			row.sequence = sequence;
			sequence++;
		});

		// end
		timeDuration.addMinutes(timetable.Groups.End.time.total);
		timetable.Summary.Covered.ShiftPrep += timetable.Groups.End.time.total;

		timetable.Groups.End.time = {
			start: timeDuration.previousTime,
			end: timeDuration.currentTime,
			total: timetable.Groups.End.time.total,
			day: timeDuration.currentDay,
		};

		// periodics
		const periodicRow = (rows: TimeTableRow[]) => {
			rows.forEach((row) => {
				const totalTime = row.time.total;
				row.sequence = null;

				if (row.type == TIME_TABLE_ROW_TYPE.PERIODIC) {
					timetable.Summary.Covered.Weekly += totalTime;
				}
			});
		};

		periodicRow(timetable.Groups.Weekly);
		periodicRow(timetable.Groups.Monthly);
		periodicRow(timetable.Groups.Quarterly);
		periodicRow(timetable.Groups.Yearly);
		periodicRow(timetable.Groups.AsNeeded);

		// Calculate Summary
		timetable.Summary.Covered.DailyTotal =
			timetable.Summary.Covered.Daily +
			timetable.Summary.Covered.ShiftPrep +
			timetable.Summary.Covered.Break +
			timetable.Summary.Covered.Lunch;

		timetable.Summary.Covered.PeriodicTotal =
			timetable.Summary.Covered.Weekly + // training included in weekly for now
			timetable.Summary.Covered.Monthly +
			timetable.Summary.Covered.Quarterly +
			timetable.Summary.Covered.Yearly +
			timetable.Summary.Covered.AsNeeded;

		timetable.Summary.Covered.All =
			timetable.Summary.Covered.DailyTotal +
			timetable.Summary.Covered.PeriodicTotal;

		return timetable;
	}
}

export const timetableProccessor = new TimeTableProcessor();
