import { useRef, useEffect } from 'react';

// services
import { Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { WorkOrderStatusKey } from '@api-interfaces';
import { todosService, workOrdersService } from '@apis';

// helpers

import { createWorkOrderPayload } from '@app/modals/_ds2/work-order-form/_helpers';

// components
import { ModalStack } from '@atoms';
import { sendError } from '@helpers';
import { toasterService } from '@services';
import { Modal } from '@templates';

import { useWorkOrderModalContext } from '../../../../_context';

// interfaces

export const DeleteTodo = () => {
  const {
    workOrder,
    setWorkOrder,
    activeModals,
    setActiveModals,
    selectedTodo,
    setSelectedTodo,
    hasBeenUpdated,
  } = useWorkOrderModalContext();
  const subscription = useRef(new Subscription());

  useEffect(() => {
    return () => {
      subscription.current.unsubscribe();
    };
  }, []);

  function handleDeleteTodo() {
    subscription.current = todosService
      .deleteTodo(selectedTodo.id)
      .pipe(
        switchMap(() => {
          toasterService.newToast({
            status: 'success',
            message: 'Todo has been deleted',
          });
          const todoList = [...workOrder.todos].filter(
            (todo) => todo.id !== selectedTodo.id
          );
          const woCopy = { ...workOrder, todo: [...todoList] };
          if (!todoList.length) {
            woCopy.work_order_status = WorkOrderStatusKey.PENDING;
          }
          const woPayload = createWorkOrderPayload(woCopy);
          return workOrdersService.updateWorkOrderById(workOrder.id, woPayload);
        })
      )
      .subscribe({
        next: (workOrder) => {
          toasterService.newToast({
            status: 'success',
            message: 'Work Order Successfully Updated',
          });
          hasBeenUpdated.current = true;
          setWorkOrder(workOrder);
          handleCancel();
        },
        error: sendError(),
      });
  }

  function handleCancel() {
    setActiveModals({
      ...activeModals,
      'deleting-todo': false,
    });
    setSelectedTodo(null);
  }

  return (
    activeModals['deleting-todo'] && (
      <ModalStack position="top-half-center">
        <Modal.Prompt
          title="Delete?"
          prompt="Are you sure?"
          onCancel={handleCancel}
          buttons={[
            {
              label: 'Cancel',
              color: 'secondary',
              onClick: handleCancel,
            },
            {
              label: 'Delete',
              color: 'danger',
              onClick: handleDeleteTodo,
            },
          ]}
        />
      </ModalStack>
    )
  );
};
