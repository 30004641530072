import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { SortBy } from './sort-button.interfaces';

export const sortButtonIcon: { [Key in SortBy]: IconProp } = {
	'alpha-asc': 'arrow-up-a-z',
	'alpha-desc': 'arrow-down-z-a',
	'numeric-asc': 'arrow-up-1-9',
	'numeric-desc': 'arrow-down-9-1',
} as const;
