import { Todo } from '@api-interfaces';
import { TodoForm } from '@modals/_ds2/todo-form';

import { useWorkOrderModalContext } from '../../../../_context';

// components

// interfaces

export const EditTodo = () => {
  const {
    workOrder,
    setWorkOrder,
    activeModals,
    setActiveModals,
    selectedTodo,
    setSelectedTodo,
    hasBeenUpdated,
  } = useWorkOrderModalContext();

  function handleSuccess(todo: Todo) {
    const workOrderCopy: typeof workOrder = {
      ...workOrder,
    };
    const todoIndex = workOrderCopy.todos.findIndex((t) => t.id === todo.id);
    if (todoIndex !== -1) {
      workOrderCopy.todos[todoIndex] = todo;
    }
    setWorkOrder(workOrderCopy);
    hasBeenUpdated.current = true;
  }

  function handleCancel() {
    setActiveModals({
      ...activeModals,
      'editing-todo': false,
    });
    setSelectedTodo(null);
  }

  return (
    activeModals['editing-todo'] && (
      <TodoForm
        todo={selectedTodo}
        closeModal={handleCancel}
        onSuccess={handleSuccess}
        externalDueDate={
          workOrder.due_date ? new Date(workOrder.due_date) : null
        }
        passedShiftsList={selectedTodo?.shifts}
      />
    )
  );
};
