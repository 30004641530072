import { isNumber } from 'lodash';

// interfaces
import { InspectionArea, Employee } from '@api-interfaces';
import { ScoreChipProps } from '@atoms/score-chip/score-chip.interfaces';

import { GroupInspectionArea, Mode } from './_context/interfaces';
import { round, floor } from 'lodash';

// helpers

/* Valid area if:
    - Score is a valid number or has a todo/photo/comment.
*/
export function filterValidAreas(mode, inspectionAreas: InspectionArea[]) {
    return inspectionAreas.filter((inspectionArea) => {
        return (
            Boolean(
                mode == 'AUDIT'
                    ? isNumber(inspectionArea?.audit_score_calculated)
                    : isNumber(inspectionArea?.inspection_score_calculated)
            ) ||
            Boolean(
                inspectionArea?.total_todos_attached > 0 ||
                    inspectionArea?.total_photos_attached > 0 ||
                    inspectionArea?.total_comments_attached > 0 ||
                    inspectionArea?.total_couldnt_score > 0
            ) ||
            Boolean(inspectionArea?.audit_couldnt_score)
        );
    });
}

export function getAreaTypesFromAreas(
    inspectionAreas: InspectionArea[],
    inspections_goalline: number
): GroupInspectionArea[] {
    const map: Map<
        number,
        InspectionArea & { count: number; divisor: number }
    > = new Map();

    inspectionAreas.forEach((inspectionArea) => {
        if (inspectionArea.location?.area_type?.id) {
            const areaTypeId = inspectionArea.location.area_type.id;
            let obj: InspectionArea & { count: number; divisor: number };
            const isValidScore =
                (isNumber(inspectionArea.audit_score_calculated) ||
                    isNumber(inspectionArea.inspection_score_calculated)) &&
                inspectionArea.audit_couldnt_score !== true;
            if (map.has(areaTypeId)) {
                obj = map.get(areaTypeId);
                obj.count += 1;
                obj.divisor += isValidScore ? 1 : 0;
                obj.total_todos_attached += inspectionArea.total_todos_attached;
                obj.total_photos_attached +=
                    inspectionArea.total_photos_attached;
                obj.total_comments_attached +=
                    inspectionArea.total_comments_attached;
                obj.audit_couldnt_score =
                    obj.audit_couldnt_score === true
                        ? inspectionArea.audit_couldnt_score
                        : false; // only set true if all true
                if (isValidScore)
                    if (isNumber(inspectionArea.audit_score_calculated)) {
                        obj.audit_score_calculated +=
                            inspectionArea.audit_score_calculated; // check if valid because we want keep null if null
                    }
                if (isNumber(inspectionArea.inspection_score_calculated)) {
                    obj.inspection_score_calculated +=
                        inspectionArea.inspection_score_calculated; // check if valid because we want keep null if null
                }
                obj.total_pass += inspectionArea.total_pass;
                obj.total_inspected += inspectionArea.total_inspected;
            } else {
                obj = {
                    ...inspectionArea,
                    count: 1,
                    divisor: isValidScore ? 1 : 0,
                };
            }

            map.set(areaTypeId, { ...obj });
        }
    });

    return [...map].map(([key, value]) => {
        const avgAuditScore = isNumber(value.audit_score_calculated)
            ? value.audit_score_calculated /
              (value.divisor > 0 ? value.divisor : 1)
            : null;

        const auditIsPass = isNumber(avgAuditScore)
            ? avgAuditScore >= (inspections_goalline || 0)
            : false;

        const avgInspectionScore = isNumber(value.inspection_score_calculated)
            ? value.inspection_score_calculated /
              (value.divisor > 0 ? value.divisor : 1)
            : null;

        const inspectionIsPass = isNumber(avgInspectionScore)
            ? avgInspectionScore >= (inspections_goalline || 0)
            : false;

        return {
            id: value.location.area_type.id,
            name: value.location.area_type.name,
            total_todos_attached: value.total_todos_attached,
            total_photos_attached: value.total_photos_attached,
            total_comments_attached: value.total_comments_attached,
            audit_score_calculated: avgAuditScore,
            audit_couldnt_score: value.audit_couldnt_score,
            audit_is_pass: isNumber(avgAuditScore)
                ? auditIsPass
                : isNumber(avgInspectionScore)
                  ? inspectionIsPass
                  : false,
            audit_scale: value.audit_scale,
            goalline: value.goalline,
            total_pass: value.total_pass,
            total_inspected: value.total_inspected,
            count: value.count,
            inspection_score_calculated: avgInspectionScore,
        };
    });
}

export function getSectionsFromAreas(
    inspectionAreas: InspectionArea[],
    inspections_goalline: number
): GroupInspectionArea[] {
    const map: Map<
        number,
        InspectionArea & {
            section_id: number;
            section_name: string;
            count: number;
            divisor: number;
        }
    > = new Map();

    inspectionAreas.forEach((inspectionArea) => {
        inspectionArea.area_groups.forEach((area_group) => {
            const sectionId = area_group.id;
            let obj: InspectionArea & {
                section_id: number;
                section_name: string;
                count: number;
                divisor: number;
            };
            const isValidScore =
                (isNumber(inspectionArea.audit_score_calculated) ||
                    isNumber(inspectionArea.inspection_score_calculated)) &&
                inspectionArea.audit_couldnt_score !== true;
            if (map.has(sectionId)) {
                obj = map.get(sectionId);
                obj.count += 1;
                obj.divisor += isValidScore ? 1 : 0;
                obj.total_todos_attached += inspectionArea.total_todos_attached;
                obj.total_photos_attached +=
                    inspectionArea.total_photos_attached;
                obj.total_comments_attached +=
                    inspectionArea.total_comments_attached;
                obj.audit_couldnt_score =
                    obj.audit_couldnt_score === true
                        ? inspectionArea.audit_couldnt_score
                        : false; // only set true if all true
                if (isValidScore)
                    if (isNumber(inspectionArea.audit_score_calculated)) {
                        obj.audit_score_calculated +=
                            inspectionArea.audit_score_calculated; // check if valid because we want keep null if null
                    }
                if (isNumber(inspectionArea.inspection_score_calculated)) {
                    obj.inspection_score_calculated +=
                        inspectionArea.inspection_score_calculated; // check if valid because we want keep null if null
                }
                obj.total_pass += inspectionArea.total_pass;
                obj.total_inspected += inspectionArea.total_inspected;
            } else {
                obj = {
                    ...inspectionArea,
                    section_id: area_group.id,
                    section_name: area_group.name,
                    count: 1,
                    divisor: isValidScore ? 1 : 0,
                };
            }

            map.set(sectionId, { ...obj });
        });
    });

    return [...map].map(([key, value]) => {
        const avgAuditScore = isNumber(value.audit_score_calculated)
            ? value.audit_score_calculated /
              (value.divisor > 0 ? value.divisor : 1)
            : null;

        const auditIsPass = isNumber(avgAuditScore)
            ? avgAuditScore >= (inspections_goalline || 0)
            : false;

        const avgInspectionScore = isNumber(value.inspection_score_calculated)
            ? value.inspection_score_calculated /
              (value.divisor > 0 ? value.divisor : 1)
            : null;

        const inspectionIsPass = isNumber(avgInspectionScore)
            ? avgInspectionScore >= (inspections_goalline || 0)
            : false;

        return {
            id: value.section_id,
            name: value.section_name,
            total_todos_attached: value.total_todos_attached,
            total_photos_attached: value.total_photos_attached,
            total_comments_attached: value.total_comments_attached,
            audit_score_calculated: avgAuditScore,
            audit_couldnt_score: value.audit_couldnt_score,
            audit_is_pass: isNumber(avgAuditScore)
                ? auditIsPass
                : isNumber(avgInspectionScore)
                  ? inspectionIsPass
                  : false,
            audit_scale: value.audit_scale,
            goalline: value.goalline,
            total_pass: value.total_pass,
            total_inspected: value.total_inspected,
            count: value.count,
            inspection_score_calculated: avgInspectionScore,
        };
    });
}

export function getEmployeesFromAreas(
    inspectionAreas: InspectionArea[]
): Employee[] {
    const map: Map<number, Employee> = new Map();

    inspectionAreas.forEach((inspectionArea) => {
        inspectionArea.service_employees?.forEach((employee) => {
            if (employee?.id && !map.has(employee.id)) {
                map.set(employee.id, { ...employee });
            }
        });
    });

    return [...map].map(([_key, value]) => value);
}

// Recursively build location name through it's parent
export function buildLocationName(
    location: InspectionArea['location']
): string {
    if (location?.parent?.id)
        return `${buildLocationName(location.parent)}, ${location.name}`;
    return location?.name ?? '';
}

export function getScoreChipParams({
    mode,
    audit_score_calculated,
    audit_is_pass,
    audit_couldnt_score,
    audit_scale,
    goalline,
    inspection_score_calculated,
}: {
    mode: Mode;
    audit_score_calculated: number;
    audit_is_pass: boolean;
    audit_couldnt_score: boolean;
    audit_scale: number;
    goalline: number;
    inspection_score_calculated: number;
}): Pick<ScoreChipProps, 'type' | 'score' | 'denominator'> {
    let type: ScoreChipProps['type'] = 'neutral';
    let score: ScoreChipProps['score'] = null;

    if (mode === 'INSPECTION') {
        // Inspection Mode
        if (audit_couldnt_score || !isNumber(inspection_score_calculated)) {
            return { type, score };
        }
        if (isNumber(inspection_score_calculated)) {
            type =
                inspection_score_calculated >= goalline ? 'success' : 'danger';
            score = floor(round(parseFloat(inspection_score_calculated), 3), 2);
        }
    } else {
        // Audit Mode
        if (audit_couldnt_score || !isNumber(audit_score_calculated))
            return { type, score };
        if (isNumber(audit_score_calculated)) {
            type = audit_is_pass ? 'success' : 'danger';
            score = floor(round(parseFloat(audit_score_calculated), 3), 2);
        }
    }

    return { type, score };
}

export const TextChip = ({
    text,
    type,
    size,
    className,
}: {
    text: number | string;
    type: 'success' | 'danger' | 'neutral';
    size: 'sm' | 'md';
    className?: string;
}) => {
    const textColor =
        type === 'neutral'
            ? 'tw-text-theme-neutral-600-500'
            : 'tw-text-neutral-900-100';
    const bgColor =
        type === 'success'
            ? 'tw-bg-theme-success-100-900'
            : type === 'danger'
              ? 'tw-bg-theme-danger-100-900'
              : 'tw-bg-theme-neutral-300-700';

    return (
        <div
            className={`tw-flex tw-justify-center tw-items-center
                ${size === 'sm' ? 'tw-py-1 tw-px-2' : 'tw-py-3 tw-px-4'}
                ${
                    size === 'sm'
                        ? 'tw-min-h-[32px] tw-min-w-[54px]'
                        : 'tw-min-h-[64px] tw-min-w-[88px]'
                }
                ${size === 'sm' ? 'tw-rounded-md' : 'tw-rounded-lg'}
                ${bgColor}
                ${className}
            `}
        >
            <div
                className={`tw-font-black
                ${size === 'sm' ? 'tw-text-base' : 'tw-text-[32px]'}
                ${textColor}`}
            >
                {text ?? 'N/A'}
            </div>
        </div>
    );
};
