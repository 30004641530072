import { Address } from '@api-interfaces';
import {
	SummaryChart,
	SummaryParams,
	GeoLocation,
	TimeScale,
	IsPass,
} from '@models';

export interface RequestsSummaryParams<T> extends SummaryParams<T> {}

export namespace RequestsSummary {
	export enum Chart {
		GetRequestCounts = 0,
		GetRequestsCountsBySite = 1,
		GetRequestsCountsDailyActivity = 2,
		GetRequestsCountsByMonth = 3,
		GetRequestCountsByOrganization = 4,
	}

	export interface RequestCounts {
		Accepted: number;
		Pending: number;
		Rejected: number;
		latest_request_date: string;
		open_past_due: number;
		total_requests: number;
	}

	export interface RequestCountsSummary extends SummaryChart<RequestCounts> {
		chart: 'GetRequestCounts';
		number: '0';
	}

	export interface RequestsCountsBySite extends Address, GeoLocation, IsPass {
		accepted_count: number;
		id: number;
		meeting_with_manager_count: number;
		name: string;
		organization_name: string;
		pending_count: number;
		question_count: number;
		rejected_count: number;
		supplies_or_equipment_count: number;
		time_off_count: number;
		total_count: number;
	}

	export interface RequestsCountsBySiteSummary
		extends SummaryChart<RequestsCountsBySite[]> {
		chart: 'GetRequestsCountsBySite';
		max_total_count: number;
	}

	export interface RequestsCountsDailyActivity {
		closed_on_time_count: number;
		closed_on_time_percentage: string;
		latest_request_date: string;
		open_past_due_count: number;
		past_due_count: number;
		total_count: number;
	}

	export interface RequestsCountsDailyActivitySummary
		extends SummaryChart<RequestsCountsDailyActivity> {
		chart: 'GetRequestsCountsDailyActivity';
		number: '2';
	}

	export interface RequestsCountsByMonth {
		meeting_with_manager_count: number | null;
		month: string;
		question_count: number | null;
		supplies_or_equipment_count: number | null;
		time_off_count: number | null;
		total_count: number | null;
	}
	export interface RequestsCountsByMonthSummary
		extends SummaryChart<RequestsCountsByMonth[]> {
		chart: 'GetRequestsCountsByMonth';
		number: '3';
		time_scale: TimeScale;
	}

	export interface RequestCountsByOrganization {
		Accepted: number;
		Pending: number;
		Rejected: number;
		customer_id: number;
		customer_name: string;
		latest_request_date: string;
		open_past_due: number;
		total_requests: number;
	}

	export interface RequestCountsByOrganizationSummary
		extends SummaryChart<RequestCountsByOrganization[]> {
		chart: 'GetRequestCountsByOrganization';
		number: '4';
	}

	export interface SummaryReturnType {
		[Chart.GetRequestCounts]: RequestCountsSummary;
		[Chart.GetRequestsCountsBySite]: RequestsCountsBySiteSummary;
		[Chart.GetRequestsCountsDailyActivity]: RequestsCountsDailyActivitySummary;
		[Chart.GetRequestsCountsByMonth]: RequestsCountsByMonthSummary;
		[Chart.GetRequestCountsByOrganization]: RequestCountsByOrganizationSummary;
	}
}
