// interfaces
import { Observable } from 'rxjs';

import { RootKpi, NonRootKpi } from '@api-interfaces';
import { RequestOptions } from '@core/services';
import { virtualBrownClient } from '@core/services/django-client';
import { Params } from '@helpers';
import { PaginationParams, PaginationResponse } from '@models';

// services

// helpers

// constants
const BASE_URL = 'contracts/kpis';

class ContractKpisService {
	getKpis(params: {
		from: Date;
		to: Date;
		is_root: true;
		mode: 'frontpage';
		limit?: number;
	}): Observable<RootKpi>;

	getKpis(params: {
		from: Date;
		to: Date;
		is_root: false;
		mode: 'frontpage';
		limit?: number;
	}): Observable<{ results: NonRootKpi[] }>;

	getKpis(
		params: PaginationParams & {
			from?: Date;
			to?: Date;
			parent?: number;
			is_root?: boolean;
			mode?: 'frontpage';
		}
	): Observable<RootKpi | { results: NonRootKpi[] }> {
		const query: any = { ...params };
		if (query.from) {
			query.from = query.from.toISOString();
		}
		if (query.to) {
			query.to = query.to.toISOString();
		}
		const queryString = Params.makeQueryString(query);
		return virtualBrownClient.get(`${BASE_URL}/${queryString}`);
	}

	public getProgramKpis(
		params: PaginationParams & {
			from?: Date;
			to?: Date;
			contract?: number;
			parent?: number; // parent service id
			is_root?: boolean; // return root services only
			mode?: 'frontpage';
		},
		opts?: RequestOptions
	) {
		const query: any = { ...params };
		if (query.from) {
			query.from = query.from.toISOString();
		}
		if (query.to) {
			query.to = query.to.toISOString();
		}
		const queryString = Params.makeQueryString(query);
		return virtualBrownClient.get<PaginationResponse<NonRootKpi>>(
			`services/kpis/${queryString}`,
			opts
		);
	}

	getKpisRecordable(
		params: PaginationParams & {
			from?: Date;
			to?: Date;
			parent?: number;
			is_root?: boolean;
			mode?: 'frontpage';
		}
	): Observable<RootKpi> {
		const query: any = { ...params };
		if (query.from) {
			query.from = query.from.toISOString();
		}
		if (query.to) {
			query.to = query.to.toISOString();
		}
		const queryString = Params.makeQueryString(query);
		return virtualBrownClient.get(`${BASE_URL}/recordable/${queryString}`);
	}
}

export const contractKpisService = new ContractKpisService();
