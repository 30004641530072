// services
import {
  SurveysSummary,
  GetSurveySummaryParams,
  SummaryBySurvey,
  GetSummaryBySurveyParams,
  SurveyResponseQuestionsSummary,
  GetSurveyResponseQuestionsParams,
} from '@api-interfaces';
import { Params } from '@helpers';
import { virtualBrownClient } from '@services';

// helpers

// interfaces

class SurveysSummaryService {
  getSummaries<T extends SurveysSummary.Chart>(
    params: GetSurveySummaryParams<T>
  ) {
    const query: any = {
      ...params,
      chart: params.chart.join(','),
    };
    if (
      !query.chart.includes(
        SurveysSummary.Chart.GetSurveyMonthlyAverageScore.toString()
      )
    ) {
      if (query.from) {
        query.from = query.from.toISOString();
      }
      if (query.to) {
        query.to = query.to.toISOString();
      }
    }

    return virtualBrownClient.get<SurveysSummary.SummaryReturnType[T][]>(
      `surveys/summary/${Params.makeQueryString(query)}`
    );
  }

  getSummariesBySurveyId<T extends SummaryBySurvey.Chart>(
    surveyId: number,
    params: GetSummaryBySurveyParams<T>
  ) {
    const query = {
      ...params,
      chart: params.chart.join(','),
    };
    return virtualBrownClient.get<SummaryBySurvey.SummaryReturnType[T][]>(
      `surveys/${surveyId}/summary/${Params.makeQueryString(query)}`
    );
  }

  getSurveyResponseQuestionsSummaries<
    T extends SurveyResponseQuestionsSummary.Chart,
  >({ surveyId, questionId, ...params }: GetSurveyResponseQuestionsParams<T>) {
    const query = {
      ...params,
      chart: params.chart.join(','),
    };
    return virtualBrownClient.get<
      SurveyResponseQuestionsSummary.SummaryReturnType[T][]
    >(
      `surveys/${surveyId}/responses/questions/${questionId}/summary/${Params.makeQueryString(
        query
      )}`
    );
  }
}

export const surveysSummaryService = new SurveysSummaryService();
