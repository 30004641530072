// base
import Highcharts from 'highcharts';
import highchartsMore from 'highcharts/highcharts-more.js';
// needed for ds2 rounding borders of bar/column charts - https://github.com/bellstrand/highcharts-border-radius
import highChartsDraggable from 'highcharts/modules/draggable-points';
import gantt from 'highcharts/modules/gantt';
import highChartsBorderRadius from 'highcharts-border-radius';
// styles

// interfaces

// operators
import { merge, cloneDeep, has, debounce } from 'lodash';
import { CSSProperties } from 'react';
import * as React from 'react';
import { fromEvent } from 'rxjs';

import { Themes } from '@core/models/types';
import { dataVizColors } from '@helpers';

import './Chart.scss';

gantt(Highcharts);

highchartsMore(Highcharts);
highChartsBorderRadius(Highcharts);

highChartsDraggable(Highcharts);

export const dataLabels: Highcharts.DataLabelsOptions = {
  enabled: true,
  formatter() {
    if (this.y || this.y === 0) {
      return this.y;
    }
  },
  // x: 6,
  // y: -10,
  padding: 5,
  style: {
    fontSize: '9px',
    fontWeight: '500',
    color: 'var(--gray-60)',
    textOutline: 'none',
  },
};

interface ChartProps {
  settings?: {
    gradients?: Array<Array<string>>; // array of tuples for gradients
    theme?: Themes; // might rework if theme comes from set global variable
    background?: boolean; // false if want to remove background
    hideChartTooltip?: boolean;
    resize?: boolean; // true by default
  };
  data: {
    id?: string;
    className?: string;
    options: Highcharts.Options; // refer to highcharts api docs
    timezoneOffset?: number;
  };
  methods?: {
    callback?: Highcharts.ChartCallbackFunction; // call after loaded
  };
  style?: CSSProperties;
  legendStyle?: any;
}

interface ChartState {
  container: React.RefObject<HTMLDivElement>; // highcharts needs a container element
  containerWidth: number; // used to check if should redraw
  highchart: any;
}

interface ChartVars {
  combineOptions: Highcharts.Options;
  subscriptions: any[];
}

export const ChartMultiTooltip = (point) => {
  const tooltips = point.points.map(
    (d) =>
      `
            <div class='tooltip-item'>
                <div class='charts-tooltip-swatch' style='background:${d.color};'></div>
                <div class='charts-tooltip-text'>${d.series.name}</div>
                <div class='charts-tooltip-pill'>${d.y}</div>
            </div>
        `
  );
  return `
        <div class='cm-charts-multi-tooltip'>
            ${tooltips.join('')}
        </div>
    `;
};

// Default Tooltip
export const ChartTooltip = (point, key, value) => {
  const isGradient = point.color && point.color.stops;
  const color = isGradient
    ? `background-image: linear-gradient(${point.color.stops[0][1]}, ${point.color.stops[1][1]})`
    : `background-color: ${point.color}`;

  return `
        <div class='cm-charts-tooltip'>
            <div class='charts-tooltip-swatch' style='${color}'></div>
            <div class='charts-tooltip-text'>${key}</div>
            ${
              value !== null
                ? `<div class='charts-tooltip-pill'>${value}</div>`
                : ''
            }
        </div>
    `;
};

// TODO: Split legend items (used for Financials: Billing)
// export const ChartLegendItemSplit = (highchartsLegend, key, value) => {
//     const isGradient = highchartsLegend.color && highchartsLegend.color.stops;
//     const color = isGradient
//         ? `background-image: linear-gradient(${highchartsLegend.color.stops[0][1]}, ${highchartsLegend.color.stops[1][1]})`
//         : `background-color: ${highchartsLegend.color}`;
//     return `
//         <div class='cm-charts-legend-item-split'>
//             <div class='charts-legend-item-split-swatch' style='${color}'></div>
//             <div class='charts-legend-item-split-text'>${key}</div>
//             <div class='charts-legend-item-split-pill'>${value}</div>
//         </div>
//     `;
// };

class Chart extends React.Component<ChartProps, ChartState> {
  constructor(props) {
    super(props);
    this.state = {
      // container element
      container: React.createRef<HTMLDivElement>(),
      // container's width
      containerWidth: null,
      // access to highchart functions
      highchart: null,
    };
  }

  // non altering state variables
  vars: ChartVars = {
    combineOptions: null,
    subscriptions: [],
  };

  private debouncedResize = debounce(() => {
    if (this.props.settings?.resize === false) return;

    if (this.state && this.state.container && this.state.container.current) {
      if (
        this.state.containerWidth !== null &&
        this.state.containerWidth === this.state.container.current.offsetWidth
      ) {
      } else {
        this.setState(
          {
            containerWidth: this.state.container.current.offsetWidth,
          },
          () => {
            this.state.highchart.setSize(null, undefined, false); // reset auto container
            const bounding =
              this.state.container.current.getBoundingClientRect(); // grab auto width
            this.state.highchart.setSize(bounding.width, undefined, false); // resize on toggle menu
          }
        );
      }
    }
  }, 500);

  public componentDidMount() {
    // initial draw
    this.drawChart();

    this.debouncedResize();
    // listener resize window
    this.vars.subscriptions.push(
      fromEvent(window, 'resize').subscribe((_event) => {
        this.debouncedResize();
      })
    );
  }

  public componentDidUpdate() {
    // updated props
    this.drawChart();
  }

  public shouldComponentUpdate(nextProps) {
    // see if should redraw with updated info
    // using JSON.stringify because lodash isEqual was returning false even though it was equal
    return JSON.stringify(this.props) !== JSON.stringify(nextProps); // careful with ordering props
  }

  public componentWillUnmount() {
    this.vars.subscriptions.forEach((subscription) =>
      subscription.unsubscribe()
    );
  }

  // set default gradient colors or custom provided gradient colors
  private setColorOptions = () => {
    let isDefault = true;
    const series = this.vars.combineOptions.series || [];
    const options = this.vars.combineOptions;

    // check if didn't set colors in options first
    if (options.colors && options.colors.length) {
      isDefault = false;
    } else if (series && series.length && series[0].color) {
      isDefault = false;
    }

    if (isDefault) {
      if (
        (has(options, 'chart.type') && options.chart.type === 'line') ||
        (has(options, 'chart.type') && options.chart.type === 'pie')
      ) {
        this.vars.combineOptions.colors = dataVizColors.map(
          (dvStart) => dvStart
        );
      } else if (this.vars.combineOptions?.series?.length) {
        // set colors (this works for bar/column but not pie charts)
        this.vars.combineOptions.series.forEach((set, i) => {
          // loop back to first color if not enough colors provided
          if (i >= dataVizColors.length) i = 0;

          if (dataVizColors.length > 0) {
            set.color = dataVizColors[i];
          }
        });
      }
    }
  };

  // merge theme options
  private setThemeOptions = () => {
    // merge each plotline default
    if (has(this, 'vars.combineOptions.yAxis.plotLines.length')) {
      const plotlineDefault = {
        color: 'var(--theme-neutral-600-500)',
        width: 2,
        zIndex: 1,
      };

      this.vars.combineOptions.yAxis.plotLines.forEach((plotline, i, arr) => {
        // merge creates new object reference so can't set 'plotline' directly
        arr[i] = merge({}, plotlineDefault, plotline);
      });
    }

    const themeOptions = {
      chart: {
        backgroundColor: 'transparent',
        style: {
          fontFamily: 'var(--roboto)',
        },
      },
      title: {
        style: {
          color: 'var(--theme-neutral-600-500)',
        },
      },
      subtitle: {
        style: {
          color: 'var(--theme-neutral-600-500)',
          fontWeiht: '400',
        },
      },
      legend: {
        itemHoverStyle: {
          color: 'var(--theme-primary-500-300)',
        },
        layout: 'horizontal',
        itemStyle: {
          color: 'var(--theme-neutral-900-100)',
          fontSize: '12px',
        },
        ...this.props.legendStyle,
      },
      xAxis: {
        labels: {
          style: {
            color: 'var(--theme-neutral-900-100)',
          },
        },
        lineColor: 'rgba(var(--theme-neutral-900-100-rgb), 0.15)',
      },
      yAxis: {
        gridLineColor: 'rgba(var(--theme-neutral-900-100-rgb), 0.15)',
        labels: {
          style: {
            color: 'var(--theme-neutral-600-500)',
          },
        },
      },
    };

    this.vars.combineOptions = merge(
      {},
      themeOptions,
      this.vars.combineOptions
    );
  };

  // merge default chart options
  private setChartOptions = (): Highcharts.Options => {
    const options: Highcharts.Options = this.vars.combineOptions;

    // height based on content for bars
    const barHeightBase = 100;
    const barHeightEach = 40;
    let barHeightTotal = null;

    if (options.chart.type == 'bar') {
      let count = 0;
      if ('categories' in options.xAxis) {
        count = options.xAxis.categories?.length || 0;
      } else if (
        options?.series?.[0] !== undefined &&
        'data' in options.series?.[0]
      ) {
        count = options.series[0].data?.length || 0;
      }
      const multiplier = count > 10 ? 0.5 : 1; // make smaller if too many
      barHeightTotal = barHeightBase + barHeightEach * count * multiplier;
    }

    // Adjust spacing top depending on combination of legend, title, subtitle (highcharts does weird stuff)
    // We are tweaking it to flush top
    const isLegendVerticalAlignTop = !!(
      !options.legend ||
      !options.legend.hasOwnProperty('verticalAlign') ||
      options.legend.verticalAlign === 'top'
    );
    const hasLegend = !(
      options.legend &&
      options.legend.hasOwnProperty('enabled') &&
      options.legend.enabled === false
    );
    const hasTitle = !!(options.title && options.title.text);
    const hasSubtitle = !!(options.subtitle && options.subtitle.text);

    let spacingTop = 24;

    // below code makes always have 24 spacing top
    if (isLegendVerticalAlignTop) {
      if (hasLegend) {
        if (hasTitle || hasSubtitle) {
          spacingTop = 16;
        } else {
          spacingTop = 32;
        }
      } else if (hasTitle || hasSubtitle) {
        spacingTop = 16;
      } else {
        spacingTop = 28; // 28 because no title, subtitle, or legend needs little extra space (4px) top
      }
    } else if (hasTitle || hasSubtitle) {
      spacingTop = 16;
    } else {
    }

    const hideChartTooltip =
      this.props.settings && this.props.settings.hideChartTooltip;

    const chartOptions: Highcharts.Options = {
      chart: {
        height: barHeightTotal,
        borderRadius: 4,
        spacing: [spacingTop, 24, 24, 24],
        styledMode: false,
      },
      time: {
        timezoneOffset: this.props.data.timezoneOffset
          ? this.props.data.timezoneOffset
          : null,
      },
      tooltip: {
        enabled: !hideChartTooltip,
        useHTML: true,
        backgroundColor: 'var(--chart-tooltip-bg)',
        padding: 4,
        borderRadius: 6,
        borderWidth: 0,
        hideDelay: 200,
        outside: true,
        shared: options.chart.type == 'line',
        formatter() {
          if (options.chart.type === 'pie') {
            return ChartTooltip(this, this.point.name, this.y);
          }
          if (options.chart.type == 'line') {
            return ChartMultiTooltip(this);
          }
          return ChartTooltip(this, this.series.name, this.y);
        },
      },
      title: {
        align: 'left',
        margin: 32, // keep 32 because we need space below
        style: {
          fontSize: '18px',
          fontWeight: '500',
        },
        text: undefined,
      },
      subtitle: {
        align: 'left',
        style: {
          margin: 32, // keep 32 because we need space below
          fontSize: '12px',
          fontWeight: 'normal',
        },
      },
      legend: (() => {
        // possible "y" needs to be added dynamically
        const legend: any = {
          // DON'T TOUCHED THIS UNLESS YOU WANT LEGEND TO BREAK
          verticalAlign: 'top',
          align: 'right',
          itemDistance: 10,
          itemStyle: {
            fontSize: '10px',
            fontWeight: 'normal',
          },
          itemMarginBottom: 8,
          itemMarginTop: 0, // DON'T MESS WITH THIS OR LEGEND SYMBOL DOT WILL BE OFF
          margin: 16,
          padding: 0,
          symbolHeight: 8,
          symbolPadding: 2,
          // useHTML: true, //DON'T USE THIS OR LEGEND WILL ALIGN LEFT ON RESIZE
        };

        if (isLegendVerticalAlignTop && hasLegend) legend.y = -40;
        return legend;
      })(),
      plotOptions: {
        // if you want borders set borderWidth and borderColor
        series: {
          borderWidth: 1,
          borderColor: 'var(--theme-neutral-100-800)', // transparent or null doesn't work so need to match background
          point: has(options, 'plotOptions.series.point')
            ? options.plotOptions.series.point
            : {},
          events: has(options, 'plotOptions.series.events')
            ? options.plotOptions.series.events
            : {
                legendItemClick() {
                  if (
                    has(this, 'group.toFront') &&
                    has(this, 'markerGroup.toFront')
                  ) {
                    this.group.toFront(); // move series and data dots to front
                    this.markerGroup.toFront();
                  }

                  return false;
                },
              },

          // marker: {
          //     symbol: 'circle',
          // },
        },
        column: {
          // can't have both pointWidth and pointPadding/groupPadding (only can have one)
          // pointWidth: 8,
          //  pointPadding: .1,
          //  groupPadding: .01,
          minPointLength: 3,
          events: has(options, 'plotOptions.column.events')
            ? options.plotOptions.column.events
            : {
                legendItemClick() {
                  return false;
                },
              },
        },
        bar: {
          // pointWidth: 8,
          // pointPadding: .1,
          // groupPadding: .01,
          minPointLength: 3,
          dataLabels: {
            overflow: 'allow',
            crop: false,
          },
        },
        pie: {
          dataLabels: {
            enabled: false,
            color: 'var(--theme-neutral-600-500)',
            style: {
              textOutline: 'none',
            },
          },
          point: {
            events: has(options, 'plotOptions.pie.point.events')
              ? options.plotOptions.pie.point.events
              : {
                  legendItemClick() {
                    return false;
                  },
                },
          },
          innerSize: '60%',
          shadow: false,
          showInLegend: true,
        },
        line: {
          marker: {
            symbol: 'circle',
          },
          stickyTracking: false,
          events: {
            mouseOver() {
              if (
                has(this, 'group.toFront') &&
                has(this, 'markerGroup.toFront')
              ) {
                this.group.toFront(); // move series and data dots to front
                this.markerGroup.toFront();
              }
            },
          },
        },
      },
      xAxis: {
        crosshair:
          options.chart.type == 'line'
            ? {
                color: 'rgba(var(--black-rgb), 0)',
              }
            : false,
        labels: {
          style: {
            fontSize: '12px',
            fontWeight: 'bold',
          },
        },
        tickLength: 0,
        title: {
          text: undefined,
        },
      },
      yAxis: {
        gridLineDashStyle: 'ShortDash',
        labels: {
          style: {
            fontSize: '12px',
            fontWeight: 'bold',
          },
        },
        min: 0,
        title: {
          text: undefined,
        },
      },
      credits: {
        enabled: false,
      },
    };

    return (this.vars.combineOptions = merge(
      {},
      chartOptions,
      this.vars.combineOptions
    ));
  };

  private drawChart = () => {
    if (
      Object.keys(this.props.data).length === 0 &&
      this.props.data.constructor === Object
    )
      return;

    this.vars.combineOptions = cloneDeep(this.props.data.options); // copy prop options before combining the three options

    this.setColorOptions(); // first combine options
    this.setThemeOptions(); // second combine options
    this.setChartOptions(); // third combine options

    // execute highcharts
    const highchart = Highcharts.chart(
      this.state.container.current, // container
      this.vars.combineOptions, // final combined options
      this.props.methods && this.props.methods.callback
        ? this.props.methods.callback
        : undefined // callback
    );

    this.setState({ highchart });
  };

  public render() {
    const noBackground = !!(
      this.props.settings &&
      this.props.settings.hasOwnProperty('background') &&
      this.props.settings.background === false
    );

    return (
      <div
        id={this.props.data.id ? this.props.data.id : null}
        className={`cm-chart tw-select-none ${
          this.props.data.className ? this.props.data.className : ''
        }`}
        style={this.props.style ? this.props.style : {}}
      >
        <div
          className={`chart-container${
            !noBackground ? ' t-background-alt' : ' no-background'
          }`}
          ref={this.state.container}
        />
      </div>
    );
  }
}

export default Chart;

/**
 * @description Create Chart components utilizing the Highcharts API.
 * @param { object } settings (Used for addomg custom gradients & setting theme)
 * @param { object } data.options (Utilize Highcharts option API)
 * @param { Function } methods.callback (Method for finished loading callback)
 *
 * @example const settings = { //optional
 *  gradients: [['colors.dvGradientStart6', 'colors.dvGradientEnd6'], ['colors.dvGradientStart7', 'colors.dvGradientEnd7']], //optional: provide your own custom colors for gradients
 *  theme: 'light', //optional
 * };
 *
 * @example const data = { //required
 *  options: {
 *      chart: {
 *          type: 'column'
 *      },
 *      title: {
 *          text: 'Complaints'
 *      },
 *      xAxis: {
 *          categories: ['Mar', 'Apr', 'May']
 *      },
 *      series: [
 *      {
 *          name: 'In Scope',
 *          data: [1, 0, 4]
 *      }, {
 *          name: 'Out of Scope',
 *          data: [5, 7, 3]
 *      }
 *  ]
 * };
 *

 *
 * @example DOM minimum (default gradient colors, default small & dark theme)
 * <Chart data={ data } />
 *
 * @example DOM custom (custom solid colors, medium & light theme)
 * <Chart
 *  settings={ settings }
 *  data={ data }
 *  methods={ methods }
 * />
 *
 * Note:
 *      If using inside MultiColumn component make sure to set width of the column
 *      or else it will overflow on menu toggle.
 */
