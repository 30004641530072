import { Employee } from '@api-interfaces';
import { Avatar, Text, Badge } from '@atoms';

// helpers;
import { TimeUtils, getFullName, getLocations } from '@helpers';
import { useBreakpoints, useAppContext } from '@hooks';
import { StatusBadge } from '@modals/_ds2/work-orders/_components/status-badge';
import { MiniProfile } from '@new/MiniProfiles';
import { Modal } from '@templates';
import { useWorkOrderModalContext } from '../../../../_context';

// services
import { WorkOrderDetailsPanelForm } from './detail-panel-form';

// interfaces

const PriorityBadge = ({ priority }) => {
    if (priority === 'Low') {
        return (
            <Badge
                className="tw-font-medium tw-bg-opacity-15 dark:tw-bg-opacity-15 tw-text-theme-primary-500-300 tw-bg-theme-primary-500-300"
                label="! Low"
            />
        );
    }
    if (priority === 'Normal') {
        return (
            <Badge
                className="tw-font-medium tw-bg-opacity-15 dark:tw-bg-opacity-15 tw-text-yelloworange-500 tw-bg-yelloworange-500 dark:tw-text-yelloworange-100 dark:tw-bg-yelloworange-100"
                label="!! Normal"
            />
        );
    }
    if (priority === 'Critical') {
        return (
            <Badge
                className="tw-font-medium tw-bg-opacity-15 dark:tw-bg-opacity-15 tw-text-theme-danger-700-300 tw-bg-theme-danger-700-300"
                label="!!! Critical"
            />
        );
    }
};

const NotDefined = () => (
    <Text font="body-md" color="neutral-offset">
        ---
    </Text>
);

export const WorkOrderDetailsPanel = () => {
    const { workOrder, isEditing } = useWorkOrderModalContext();
    const {
        state: { user },
    } = useAppContext();

    const {
        is_past_due,
        create_date,
        due_date,
        work_complete_date,
        priority,
        location,
        equipment,
        created_by,
        service_type,
        reporter,
        assigned_to,
        is_in_scope,
        contact_number,
        work_order_status,
        rejection_other,
        rejection_type,
        external_work_order_id,
        invoice_number,
        service,
        retrieve_from_vendor,
        is_project_work,
        source,
    } = workOrder ?? {};

    const { xs } = useBreakpoints();
    const isRejected = work_order_status === 'REJECTED';

    const Profile = ({ person }: { person: Employee }) =>
        person.person !== undefined &&
        (xs ? (
            <MiniProfile.Md
                person={person.person}
                detail={person.positions
                    ?.map((pos) => pos?.name ?? '')
                    ?.join(', ')}
            />
        ) : (
            <div className="tw-flex tw-items-center tw-space-x-2">
                <Avatar size="sm" person={person.person} />
                <Text
                    font="body-md"
                    color="hi-contrast"
                    className="tw-capitalize"
                >
                    {getFullName(person.person)}
                </Text>
                E
            </div>
        ));

    let locationName = '';
    let areaName = '';

    if (location) {
        const { building, floor, room } = getLocations(location);
        areaName = room?.name ?? '';
        const floorName = floor?.name;
        const buildingName = building?.name;

        locationName += buildingName;

        if (floorName) {
            locationName += `, ${floorName}`;
        }
    }

    const isCustomer = user.hasPermission(1, 'Submit Work Order');

    return (
        <>
            <Modal.BottomSheet.DetailsContent
                label="Status"
                content={
                    work_order_status ? (
                        <StatusBadge
                            className="tw-flex tw-gap-x-2"
                            status={work_order_status}
                            isPastDue={is_past_due}
                            showToolTip
                        />
                    ) : (
                        <NotDefined />
                    )
                }
            />
            {isRejected && (
                <Modal.BottomSheet.DetailsContent
                    label="Rejected Reason"
                    content={
                        rejection_other ||
                        rejection_type?.display_name || <NotDefined />
                    }
                />
            )}
            {!isEditing ? (
                <>
                    <Modal.BottomSheet.DetailsContent
                        label="Program"
                        content={
                            service ? (
                                <Badge
                                    className="tw-flex tw-gap-x-2"
                                    label={service.name}
                                    color="purple"
                                />
                            ) : (
                                <NotDefined />
                            )
                        }
                    />

                    <Modal.BottomSheet.DetailsContent
                        label="Source"
                        content={!source ? 'Customer' : source}
                    />

                    {!isCustomer && (
                        <Modal.BottomSheet.DetailsContent
                            label="In Scope"
                            content={
                                is_in_scope != null ? (
                                    `${is_in_scope ? 'Yes' : 'No'}`
                                ) : (
                                    <NotDefined />
                                )
                            }
                        />
                    )}

                    {/* SBM billing uses invoice_numbers to correlate with external systems. Non-SBM billing uses external_work_order_id */}
                    {!isCustomer || !isRejected ? (
                        retrieve_from_vendor ? (
                            <Modal.BottomSheet.DetailsContent
                                label="Third Party ID#"
                                content={
                                    external_work_order_id || <NotDefined />
                                }
                            />
                        ) : (
                            <Modal.BottomSheet.DetailsContent
                                label="Invoice Number"
                                content={invoice_number || <NotDefined />}
                            />
                        )
                    ) : null}

                    <Modal.BottomSheet.DetailsContent
                        label="Project Work"
                        content={is_project_work ? 'Yes' : 'No'}
                    />

                    <Modal.BottomSheet.DetailsContent
                        label="Date Reported"
                        content={
                            create_date ? (
                                TimeUtils.format(
                                    create_date,
                                    'MM/DD/YY hh:mm A'
                                )
                            ) : (
                                <NotDefined />
                            )
                        }
                    />
                    <Modal.BottomSheet.DetailsContent
                        label="Expected Due Date"
                        content={
                            due_date ? (
                                TimeUtils.format(due_date, 'MM/DD/YY hh:mm A')
                            ) : (
                                <NotDefined />
                            )
                        }
                    />
                    <Modal.BottomSheet.DetailsContent
                        label="Date Completed"
                        content={
                            work_complete_date ? (
                                TimeUtils.format(
                                    work_complete_date,
                                    'MM/DD/YY hh:mm A'
                                )
                            ) : (
                                <NotDefined />
                            )
                        }
                    />
                    {!isCustomer && (
                        <Modal.BottomSheet.DetailsContent
                            label="Request Type"
                            content={
                                service_type?.display_name || <NotDefined />
                            }
                        />
                    )}

                    {!isCustomer && (
                        <Modal.BottomSheet.DetailsContent
                            label="Priority"
                            content={
                                priority ? (
                                    <PriorityBadge
                                        priority={priority.display_name}
                                    />
                                ) : (
                                    <NotDefined />
                                )
                            }
                        />
                    )}

                    {!isCustomer && (
                        <Modal.BottomSheet.DetailsContent
                            label="Equipment"
                            content={equipment || <NotDefined />}
                        />
                    )}

                    {!isCustomer && (
                        <Modal.BottomSheet.DetailsContent
                            label="Location"
                            content={locationName || <NotDefined />}
                        />
                    )}
                    {!isCustomer && (
                        <Modal.BottomSheet.DetailsContent
                            label="Area"
                            content={areaName || <NotDefined />}
                        />
                    )}
                    <Modal.BottomSheet.DetailsContent
                        label="Created By"
                        className="tw-items-center"
                        content={
                            created_by ? (
                                <Profile person={created_by} />
                            ) : (
                                <NotDefined />
                            )
                        }
                    />
                    <Modal.BottomSheet.DetailsContent
                        label="Requested By"
                        className="tw-items-center"
                        content={
                            reporter ? (
                                <Profile person={reporter} />
                            ) : (
                                <NotDefined />
                            )
                        }
                    />
                    {!isCustomer && (
                        <Modal.BottomSheet.DetailsContent
                            label="Assigned To"
                            className="tw-items-center"
                            content={
                                assigned_to ? (
                                    <Profile person={assigned_to} />
                                ) : (
                                    <NotDefined />
                                )
                            }
                        />
                    )}

                    <Modal.BottomSheet.DetailsContent
                        label="Requester's Phone Number"
                        content={contact_number || <NotDefined />}
                    />
                </>
            ) : (
                <WorkOrderDetailsPanelForm />
            )}
        </>
    );
};
