import { useContext } from 'react';

// interface
import { createCtx } from '@helpers';

import { Page } from './interfaces';

// helpers

// utils
import { reducer } from './reducer';

const initialState: Page.State = {
	id: null,
	mode: null,
	modals: {
		'audit-bottom-sheet': true,
		'audit-delete-confirmation': false,
		'area-information': false,
		'audit-attachments': false,
		'service-log': false,
		todo: false,
	},
	isLoading: true,
	inspection: null,
	areas: null,
	area_types: null,
	sections: null,
	employees: null,
	todos: null,
	attachments: null,
	comments: null,
	selectedAreaType: null,
	selectedAreaTypeId: null,
	selectedSection: null,
	selectedArea: null,
	selectedAreaList: [],
	selectedAreaId: null,
	selectedService: null,
	photoViewerAreas: null,
	photoViewerIndex: 0,
	onClose: null,
	photoIndexOnLoad: null,
};

function init(state: Page.State, props: Partial<Page.State>) {
	return {
		...state,
		id: props.id,
		mode: props.mode,
		onClose: props.onClose,
		selectedAreaId: props.selectedAreaId,
		selectedAreaTypeId: props.selectedAreaTypeId,
		photoIndexOnLoad: props.photoIndexOnLoad,
	};
}

export const [context, Provider] = createCtx(reducer, initialState, init);

export function useAuditModalContext() {
	return useContext(context);
}
