import { GmpAuditModal } from './interfaces';

export function gmpAuditReducer(
	state: GmpAuditModal.State,
	action: GmpAuditModal.Action
): GmpAuditModal.State {
	switch (action.type) {
		case 'SET_IS_LOADING': {
			return {
				...state,
				isLoading: action.payload,
			};
		}
		case 'SET_MODE': {
			return {
				...state,
				mode: action.payload,
			};
		}
		case 'SET_SELECTED_EMPLOYEE': {
			return {
				...state,
				selectedEmployee: action.payload,
			};
		}
		case 'SET_DATA': {
			return {
				...state,
				data: action.payload,
			};
		}
		case 'SET_FORM': {
			return {
				...state,
				form: action.payload,
			};
		}
		case 'SET_QUESTIONNAIRE': {
			return {
				...state,
				questionnaire: action.payload,
			};
		}
		case 'SET_DROPDOWN_CORRECTIVE_ACTIONS': {
			return {
				...state,
				dropdowns: {
					...state.dropdowns,
					correctiveActions: action.payload,
				},
			};
		}
		case 'SET_ATTEMPT_SUBMIT': {
			return {
				...state,
				attemptSubmit: action.payload,
			};
		}
		case 'SET_IS_SUBMITTING': {
			return {
				...state,
				isSubmitting: action.payload,
			};
		}
		case 'SET_IS_EDITING': {
			return {
				...state,
				isEditing: action.payload,
			};
		}
	}
}
