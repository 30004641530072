import { Todo, WorkOrder, WorkOrderStatusKey } from '@api-interfaces';
import { createWorkOrderPayload } from '@modals/_ds2/work-order-form/_helpers';

export const updateWOStatus = (workOrder: WorkOrder, todoList: Todo[]) => {
	const woCopy = { ...workOrder };
	const isAllTodosComplete = todoList.every((todo) => todo.is_complete);
	if (isAllTodosComplete) {
		woCopy.work_order_status = WorkOrderStatusKey.WORK_COMPLETE;
	} else if (todoList.length && !isAllTodosComplete) {
		woCopy.work_order_status = WorkOrderStatusKey.ASSIGNED;
	}
	const woPayload = createWorkOrderPayload({ ...woCopy, todos: todoList });
	return woPayload;
};
