import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { Comment, WorkItemType, Media } from '@api-interfaces';

export interface IProps {
    item_id: number;
    work_item_type: WorkItemType;
    parent_id?: number;
    isReadOnly?: boolean;
    submitterPhoneNumber?: string;
    onCommentCountUpdate?: (count: number) => void;
    allowAttachment?: boolean;
}
export interface IParams {
    offset: number;
    limit: number;
    work_item: number;
    work_item_type: WorkItemType;
}

export interface CreateProps {
    defaultValue?: string;
    submit: (input) => void;
    title?: string;
    isEditBox?: boolean;
    className?: string;
    icon?: IconProp; // default paper-plane
    allowAttachment?: IProps['allowAttachment'];
    handleAddAttachment?: () => void;
    attachmentsState?: AttachmentsState;
    setAttachmentsState?: () => void;
}

export interface IComment {
    isLastChild?: boolean;
    is_child?: boolean;
    comment: Comment;
    childCount?: number;
    threadToggled?: boolean;
    toggleChildren?: () => void;
    showReply?: () => void;
    editComment?: (id: number, input: string) => void;
    createChild?: (input: string) => void;
    deleteComment?: (id: number) => void;
    isReplying?: boolean;
    className?: string;
}
export interface IThread {
    comment: Comment;
    item_id: number;
    editComment: (id: number, input: string) => void;
    deleteComment: (id: number) => void;
    submitterPhoneNumber?: string;
}

export type AttachmentsState = {
    attachments: Media[];
    attachmentSelected: Media[];
    attachmentsQueue: Media[];
    addQueue: Media[];
    deleteQueue: any;
    isLoading: boolean;
};

export type Attachment = {
    id: number | string;
    file_name: string;
    display_name: string;
};

export interface AttachmentTileProps {
    isEditable: boolean;
    isNewComment: boolean;
    attachmentsState: AttachmentsState;
    item: Attachment;
    setAttachmentsState?: ({}: AttachmentsState) => void;
    removeAttachment: (item: QueueAttachment) => void;
}

export const getFileType = (fileName: string) => {
    const extensionMatch = /\.([^.]+)$/.exec(fileName);

    if (extensionMatch && extensionMatch[1]) {
        const extension = extensionMatch[1].toLowerCase();

        if (['jpg', 'jpeg', 'png', 'gif'].includes(extension)) {
            return 'Image';
        } else if (
            ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'txt', 'rtf'].includes(
                extension
            )
        ) {
            return 'Document';
        } else {
            return 'Other';
        }
    } else {
        return 'Unknown';
    }
};

export const truncateFileName = (fullFileName, maxLength) => {
    const extensionMatch = /\.([^.]+)$/.exec(fullFileName);

    if (extensionMatch && extensionMatch[1]) {
        const extension = extensionMatch[1];

        const maxFileNameLength = maxLength - extension.length - 1;

        const truncatedFileName =
            fullFileName.length > maxLength
                ? fullFileName.slice(0, maxFileNameLength) + '...' + extension
                : fullFileName;

        return truncatedFileName;
    } else {
        return fullFileName;
    }
};

export const initialAttachmentsState = {
    attachments: [],
    attachmentSelected: {},
    attachmentsQueue: [],
    addQueue: [],
    deleteQueue: [],
    isLoading: false,
};
