import { JSX } from 'react';
import { has } from 'lodash';
import { InspectionLocation } from '@api-interfaces';
import { Box, Text, Badge, ScoreChip } from '@atoms';
import { EmployeeDetails } from '@components/_new/MiniProfiles/_components/employee-details';
import { TimeUtils, titleCase } from '@helpers';
import { useBreakpoints } from '@hooks';
import { useAuditModalContext } from '@modals/_ds2/audit/_context/context';
import { MiniProfile } from '@new/MiniProfiles';
import { Modal } from '@templates';

const NotDefined = () => (
    <Text font="body-md" tag="span" color="hi-contrast">
        &#8212; &#8212; &#8212;
    </Text>
);

function doesInclude(input: string, match: string): boolean {
    if (input && match) {
        const inputCasing = input.toUpperCase();
        const matchCasing = match.toUpperCase();
        return inputCasing.includes(matchCasing);
    }
    return false;
}

/**
 * Sort buildings in multidimensional building array alphabetically (locations
 * with no building are sorted to the bottom)
 */
function buildingSort(a, b) {
    const a_building = a.filter((itr) => doesInclude(itr.type, 'Building'));
    const b_building = b.filter((itr) => doesInclude(itr.type, 'Building'));

    const a_exists = Boolean(a_building && a_building.length > 0);
    const b_exists = Boolean(b_building && b_building.length > 0);

    if (a_exists && b_exists) {
        return a_building[0].name.localeCompare(b_building[0].name);
    }
    // Sort all floors with no building to the bottom of the list
    if (a_exists && !b_exists) return -1;
    if (!a_exists && b_exists) return 1;
    if (!a_exists && !b_exists) return 0;
}

const processLocations = (locations: InspectionLocation[][]): JSX.Element => {
    if (locations?.length > 0) {
        // Sort buildings alphabetically
        locations.sort(buildingSort);
        return (
            <div className="tw-flex tw-flex-col">
                {locations.map((loc, i) => {
                    const building = loc.filter(
                        (location) => !location.parent_id
                    );
                    const floors = loc
                        .filter((location) => location.parent_id)
                        .sort((a, b) => a.name.localeCompare(b.name));
                    return (
                        <div key={loc?.[i]?.id}>
                            {floors.map((floor, j) => {
                                if (has(floor, 'name')) {
                                    return has(building, '[0].name') ? (
                                        <Text
                                            key={floor?.[j]?.id}
                                            font="body-md"
                                            color="hi-contrast"
                                        >
                                            {building[0].name}, {floor.name}
                                        </Text>
                                    ) : (
                                        <Text
                                            key={floor?.[j]?.id}
                                            font="body-md"
                                            color="hi-contrast"
                                        >
                                            {floor.name}
                                        </Text>
                                    );
                                }
                                return null;
                            })}
                        </div>
                    );
                })}
            </div>
        );
    }
    return <NotDefined />;
};

const Body = () => {
    const {
        state: { mode, inspection },
    } = useAuditModalContext();
    const bp = useBreakpoints();
    const isBpLg = bp.lg;
    const isBpOnlyXs = !bp.sm;

    /* Could either be single object or array of objects */
    const services = inspection.services?.length
        ? inspection.services
        : inspection.service?.id
          ? [inspection.service]
          : [];
    return (
        <>
            <Modal.BottomSheet.DetailsContent
                label="Status"
                content={
                    inspection.status === 'COMPLETE' ? (
                        <Badge label="Finalized" />
                    ) : inspection.status === 'DRAFT' ? (
                        <Badge label="Draft" color="neutral" />
                    ) : (
                        <NotDefined />
                    )
                }
            />

            <Modal.BottomSheet.DetailsContent
                label={mode === 'INSPECTION' ? 'Inspected On' : 'Audited On'}
                content={
                    inspection.inspection_date ? (
                        TimeUtils.format(
                            inspection.inspection_date,
                            'MM/D/YY h:mm A'
                        )
                    ) : (
                        <NotDefined />
                    )
                }
            />

            <Modal.BottomSheet.DetailsContent
                label={mode === 'INSPECTION' ? 'Inspectors' : 'Auditors'}
                content={
                    <div className="tw-flex tw-flex-col tw-space-y-4">
                        {isBpLg || isBpOnlyXs ? (
                            <>
                                <MiniProfile.Md
                                    person={inspection.inspected_by.person}
                                    detail={
                                        <EmployeeDetails
                                            jobTitle={
                                                inspection?.inspected_by
                                                    ?.positions?.[0]?.name ?? ''
                                            }
                                            client={
                                                inspection?.contract?.customer
                                                    ?.name ?? ''
                                            }
                                            site={
                                                inspection?.contract?.name ?? ''
                                            }
                                            hiredDate={
                                                inspection?.inspected_by
                                                    ?.hired_date ?? ''
                                            }
                                        />
                                    }
                                />
                                {inspection.joint_inspected_by?.person && (
                                    <MiniProfile.Md
                                        person={
                                            inspection.joint_inspected_by.person
                                        }
                                        detail={
                                            <EmployeeDetails
                                                jobTitle={
                                                    inspection
                                                        ?.joint_inspected_by
                                                        ?.positions?.[0]
                                                        ?.name ?? ''
                                                }
                                                client={
                                                    inspection?.contract
                                                        ?.customer?.name ?? ''
                                                }
                                                site={
                                                    inspection?.contract
                                                        ?.name ?? ''
                                                }
                                                hiredDate={
                                                    inspection
                                                        ?.joint_inspected_by
                                                        ?.hired_date ?? ''
                                                }
                                            />
                                        }
                                    />
                                )}
                            </>
                        ) : (
                            <>
                                <MiniProfile.Lg
                                    person={inspection.inspected_by.person}
                                    detail={
                                        <EmployeeDetails
                                            jobTitle={
                                                inspection?.inspected_by
                                                    ?.positions?.[0]?.name ?? ''
                                            }
                                            client={
                                                inspection?.contract?.customer
                                                    ?.name ?? ''
                                            }
                                            site={
                                                inspection?.contract?.name ?? ''
                                            }
                                            hiredDate={
                                                inspection?.inspected_by
                                                    ?.hired_date ?? ''
                                            }
                                        />
                                    }
                                />
                                {inspection.joint_inspected_by?.person && (
                                    <MiniProfile.Lg
                                        person={
                                            inspection.joint_inspected_by.person
                                        }
                                        detail={
                                            <EmployeeDetails
                                                jobTitle={
                                                    inspection?.inspected_by
                                                        ?.positions?.[0]
                                                        ?.name ?? ''
                                                }
                                                client={
                                                    inspection?.contract
                                                        ?.customer?.name ?? ''
                                                }
                                                site={
                                                    inspection?.contract
                                                        ?.name ?? ''
                                                }
                                                hiredDate={
                                                    inspection?.inspected_by
                                                        ?.hired_date ?? ''
                                                }
                                            />
                                        }
                                    />
                                )}
                            </>
                        )}
                    </div>
                }
            />

            <Modal.BottomSheet.DetailsContent
                label="Type"
                content={
                    <div className="tw-flex tw-flex-row tw-space-x-2">
                        {inspection.type === 'ROUTE' ? (
                            <Badge label="Route" color="pinkle" />
                        ) : (
                            <Badge label="Flex" color="orange" />
                        )}
                        {inspection.joint_inspected_by?.id ? (
                            <Badge label="Joint" color="skyblue" />
                        ) : inspection.source === 'INTERNAL' ? (
                            <Badge label="Internal" color="purple" />
                        ) : (
                            <Badge label="Customer" color="warning" />
                        )}
                    </div>
                }
            />

            <Modal.BottomSheet.DetailsContent
                label="Site"
                content={
                    inspection.contract?.customer?.name &&
                    inspection.contract?.name ? (
                        `${inspection.contract.customer.name}, ${inspection.contract.name}`
                    ) : (
                        <NotDefined />
                    )
                }
            />

            <Modal.BottomSheet.DetailsContent
                label="Building, Floor"
                content={processLocations(
                    inspection.location as InspectionLocation[][]
                )}
            />

            <Modal.BottomSheet.DetailsContent
                label="Programs"
                content={
                    <div className="tw-flex tw-flex-row tw-flex-wrap">
                        {services.length ? (
                            services.map((service) => (
                                <Badge
                                    key={service.id}
                                    label={service.name}
                                    className="tw-mr-2 tw-mb-2"
                                />
                            ))
                        ) : (
                            <NotDefined />
                        )}
                    </div>
                }
            />

            <Modal.BottomSheet.DetailsContent
                label="Shifts"
                content={
                    <div className="tw-flex tw-flex-row tw-flex-wrap">
                        {inspection.shifts.length ? (
                            inspection.shifts.map((shift) => (
                                <Badge
                                    key={shift?.id}
                                    label={shift.name}
                                    className="tw-mr-2 tw-mb-2"
                                />
                            ))
                        ) : (
                            <NotDefined />
                        )}
                    </div>
                }
            />
        </>
    );
};

export const Details = () => {
    const {
        state: { mode, inspection },
    } = useAuditModalContext();
    const bp = useBreakpoints();
    const isBpLg = bp.lg;
    const isBpOnlyXs = !bp.sm;
    // Based on mode audit or inspection look at respective score/is_pass
    const score =
        mode === 'INSPECTION'
            ? inspection.inspection_score
            : inspection.audit_score;
    const is_pass =
        mode === 'INSPECTION'
            ? inspection.inspection_is_pass
            : inspection.audit_is_pass;

    const scoreChip = (
        <ScoreChip
            score={
                score !== null && score !== undefined
                    ? parseFloat(score?.toFixed(2) ?? '0')
                    : null
            }
            prettyNumberOptions={{
                decimals: 2,
                hasTrailingZeros: true,
            }}
            type={
                is_pass === true
                    ? 'success'
                    : is_pass === false
                      ? 'danger'
                      : 'neutral'
            }
            size="extra-md"
            className=""
        />
    );

    return isBpLg ? (
        <Modal.BottomSheet.Details>
            <div className="tw-flex tw-justify-between tw-items-center tw-mb-8">
                <Text font="h2" color="hi-contrast">
                    {titleCase(mode)} Score
                </Text>
                {scoreChip}
            </div>

            <Text font="h3" color="hi-contrast">
                {titleCase(mode)} Details
            </Text>

            <Body />
        </Modal.BottomSheet.Details>
    ) : (
        <Box id="audit-modal-details" rounded className="tw-p-4 sm:tw-p-10">
            <Text font="h2" color="primary" className="tw-mb-8">
                {titleCase(mode)} Details
            </Text>

            <div className="tw-space-y-6">
                <div className="tw-flex tw-justify-between tw-items-center">
                    <Text
                        font="h2"
                        color="hi-contrast"
                        className={`${!isBpOnlyXs ? 'tw-w-2/5' : ''}`}
                    >
                        {titleCase(mode)} Score
                    </Text>
                    <div className={!isBpOnlyXs ? 'tw-w-3/5' : ''}>
                        {scoreChip}
                    </div>
                </div>

                <Body />
            </div>
        </Box>
    );
};
