import { Params } from '@helpers';
import { http } from '@services/http-client';

// helpers

// interfaces
import { ServiceValidationStatus } from './interfaces';

const BASE_URL = 'public/service-logs';

class ServiceLogsService {
	getServiceLogs(params: {
		code?: number | string;
		contract: number | string;
		offset?: number;
		limit?: number;
		area?: number;
		max_date?: string | Date;
	}) {
		return http.get<{
			count: number;
			next: string | null;
			prev: string | null;
			results: ServiceValidationStatus;
		}>(`${BASE_URL}/${Params.makeQueryString(params)}`);
	}
}

export const serviceLogsService = new ServiceLogsService();
