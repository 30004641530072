import { SummaryChart, SummaryParams } from '@models';

export interface FinancialsCostsSummaryParams<T> extends SummaryParams<T> {
	chart_type?: string | number;
}

export namespace FinancialsCostsSummary {
	export enum Chart {
		GetCostItemsByCategory = 34,
		GetCostItemTrendsByCategory = 35,
		GetHourlyLabor = 36,
		GetManagementAndSupervision = 37,
		GetDepartmentInfo = 38,
	}

	export interface CostItemsByCategory {
		amount: number;
		category_id: number;
		category_name: string;
		percentage: number;
	}

	export interface CostItemsByCategorySummary
		extends SummaryChart<CostItemsByCategory[]> {
		chart: 'GetCostItemsByCategory';
		number: '0'; // should be 34
	}

	export interface CostItemTrendsByCategory {
		month: string;
		total_amounts: Array<{
			category_id: number;
			category_name: string;
			total_amount: number;
		}>;
	}

	export interface CostItemTrendsByCategorySummary
		extends SummaryChart<CostItemTrendsByCategory[]> {
		chart: 'GetCostItemTrendsByCategory';
		number: '1'; // should be 35
	}

	export interface HourlyLabor {
		positions: Array<{
			employee_count: number;
			hours_count: number;
			name: string;
		}>;
		total_employee_count: number;
		total_hours_count: number;
		total_position_pay: number;
	}

	export interface HourlyLaborSummary extends SummaryChart<HourlyLabor> {
		chart: 'GetHourlyLabor';
		number: '36';
	}

	export interface ManagementAndSupervision {
		total_employee_count: number;
		positions: Array<{
			manager_count: number;
			managers: Array<{
				first_name: string;
				last_name: string;
				managed_employee_count: number;
				photo_url: string;
				position_id: number;
			}>;
			name: string;
			total_managed_employees: number;
		}>;
	}

	export interface ManagementAndSupervisionSummary
		extends SummaryChart<ManagementAndSupervision> {
		chart: 'ManagementAndSupervision';
		number: '37';
	}

	export interface DepartmentInfo {
		departments: Array<{
			employee_count: number;
			employees: Array<{
				department_id: number;
				first_name: string;
				last_name: string;
				photo_url: string;
			}>;
			name: string;
			description: string;
		}>;
		total_employee_count: number;
	}

	export interface DepartmentInfoSummary extends SummaryChart<DepartmentInfo> {
		chart: 'GetDepartmentInfoSummary';
		number: '38';
	}

	export type SummaryReturnType = {
		[Chart.GetCostItemsByCategory]: CostItemsByCategorySummary;
		[Chart.GetCostItemTrendsByCategory]: CostItemTrendsByCategorySummary;
		[Chart.GetHourlyLabor]: HourlyLaborSummary;
		[Chart.GetManagementAndSupervision]: ManagementAndSupervisionSummary;
		[Chart.GetDepartmentInfo]: DepartmentInfoSummary;
	};
}
