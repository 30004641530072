interface DividerProps {
  className?: string;
}

export const Divider = ({ className }: DividerProps) => (
  <div
    className={`tw-mt-8 tw-mb-4 tw-h-[1px] tw-w-full tw-bg-neutral-900 dark:tw-bg-neutral-100 tw-bg-opacity-15 dark:tw-bg-opacity-15 ${
      className ?? ''
    }`}
  />
);
