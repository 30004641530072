// interfaces
import { Observable } from 'rxjs';
import {
	ServiceVerificationLimitedResponse,
	ServiceVerificationsActivity,
	ServiceVerificationsArea,
	ServiceVerificationsAreaTypeSummary,
	ServiceVerificationsFailedCount,
	ServiceVerificationsAreasOfAreaType,
	ServiceVerificationsLeaderboard,
	ServiceVerificationsSummary,
	ServiceVerificationsFailedVerification,
	ServiceVerificationsFailedVerificationDetails,
	ServiceVerificationsAllVerifications,
	ServiceVerificationsForReview,
	UpdatedImagesBody,
	UpdatedImagesResponse,
	ServiceVerificationsForReviewCount,
	ServiceVerificationsFailedArea,
	ServiceVerificationsFailedFinalization,
	FailedImagesCountResponse,
	RevisedImagesTotalCountResponse,
	EmployeeInfo,
	ServiceVerificationsFailedAreaAcknowledgement,
	AreaElementsByAverageScore,
	AreasListView,
	AreaElement,
	ServiceVerificationsFailedAreaSummary,
} from './interfaces';
import { PaginationParams, PaginationResponse } from '@models';

// services
import { virtualBrownClient } from '@services';
import { serviceVerificationsSchedulesService } from './schedules';

// helpers
import { Params } from '@helpers';
import env from '@core/environments';
import { TimeUtils } from '@insite-next/mocha';
import { ContractJustNames } from '../index.interfaces';

const BASE_URL = 'service_verifications';

const noOpts = {
	noContract: true,
	noCustomer: true,
	noTag: true,
};

class ServiceVerificationsService {
	public readonly schedules = serviceVerificationsSchedulesService;

	public getSummary(params: {
		from: Date | string;
		to: Date | string;
		type: 'site' | 'area_type' | 'area';
		contract?: number | string;
		area_type_id?: number;
		category?: string;
		area_id?: number;
	}): Observable<ServiceVerificationsSummary> {
		const queryString = Params.makeQueryString(params);

		return virtualBrownClient.get(
			`${BASE_URL}/area-types/summary${queryString}`,
			{
				noContract: params.contract !== undefined,
			}
		);
	}

	public getLatestActivity(params: {
		from: Date | string;
		to: Date | string;
		contract?: number | string;
		area_type?: number;
		category?: string;
	}): Observable<
		ServiceVerificationLimitedResponse<ServiceVerificationsActivity>
	> {
		const queryString = Params.makeQueryString(params);
		return virtualBrownClient.get(
			`${BASE_URL}/areas/latest-activity/${queryString}`
		);
	}

	public getVerification(
		areaId?: number | string | null,
		AuditId?: number | string | null,
		params?: {
			contract?: number | string;
		}
	): Observable<
		ServiceVerificationLimitedResponse<ServiceVerificationsArea>
	> {
		return virtualBrownClient.get(
			`${BASE_URL}/areas/${areaId}/audits/${AuditId}/${Params.makeQueryString(params)}`,
			{
				noContract: params?.contract !== undefined,
			}
		);
	}

	public getAreaTypeSummaries(params: {
		contract?: number | string;
		from: Date | string;
		to: Date | string;
		category?: string;
	}): Observable<ServiceVerificationsAreaTypeSummary> {
		return virtualBrownClient.get(
			`${BASE_URL}/inspection/area-types/card${Params.makeQueryString(params)}`,
			{
				noContract: params.contract !== undefined,
			}
		);
	}

	public getAreaElementsSummaries(params: {
		contract?: number | string;
		from: Date | string;
		to: Date | string;
		category?: string;
		limit?: number;
		offset?: number;
		areatype?: number;
	}): Observable<ServiceVerificationsAreaTypeSummary> {
		return virtualBrownClient.get(
			`${BASE_URL}/element-verifications/card${Params.makeQueryString(params)}`,
			{
				noContract: params.contract !== undefined,
			}
		);
	}

	public getAreaElementsRecentActivity(params: {
		from: Date | string;
		to: Date | string;
		contract?: number | string;
		element?: number;
		limit?: number;
		offset?: number;
		category: 'associate' | 'manager' | 'all'; // default is associate
		area_type_id?: number | string;
		area_id?: number | string;
		status?: string;
		ordering?: string;
		group?: 'area' | 'areatype' | 'associates';
	}): Observable<
		ServiceVerificationLimitedResponse<ServiceVerificationsActivity>
	> {
		const queryString = Params.makeQueryString(params);
		return virtualBrownClient.get(
			`${BASE_URL}/element-verifications/verifications${queryString}`
		);
	}

	public getAreaElementsSummary(params: {
		from: Date | string;
		to: Date | string;
		contract?: number | string;
		element?: number;
		category?: string;
		area_id?: number;
		areatype?: number | string;
	}): Observable<ServiceVerificationsSummary> {
		const queryString = Params.makeQueryString(params);

		return virtualBrownClient.get(
			`${BASE_URL}/element-verifications/summary${queryString}`,
			{
				noContract: params.contract !== undefined,
			}
		);
	}

	public getAreaElementsAllVerifications(params: {
		contract: number | string;
		to: Date | string;
		from: Date | string;
		category: string;
		group: string;
		element: number;
		limit?: number;
		offset?: number;
		area_type_id?: number | string;
		area_id?: number | string;
		ordering?: string;
		search?: string;
	}): Observable<PaginationResponse<AreaElement>> {
		const queryString = Params.makeQueryString(params);

		return virtualBrownClient.get(
			`${BASE_URL}/element-verifications/grouped${queryString}`
		);
	}

	public getFailedCount(params: {
		contract?: number | string;
		from: Date | string;
		to: Date | string;
	}): Observable<ServiceVerificationsFailedCount> {
		return virtualBrownClient.get(
			`${BASE_URL}/failed-verifications/summary${Params.makeQueryString(params)}`,
			{
				noContract: params.contract !== undefined,
			}
		);
	}

	public getFailedVerifications(
		params: {
			contract?: number | string;
			from: Date | string;
			to: Date | string;
			search?: string;
		} & PaginationParams
	): Observable<PaginationResponse<ServiceVerificationsFailedVerification>> {
		return virtualBrownClient.get(
			`${BASE_URL}/failed-verifications/${Params.makeQueryString(params)}`,
			{
				noContract: params.contract !== undefined,
			}
		);
	}

	public getFailedAreas(
		params: {
			contract?: number | string;
			from: Date | string;
			to: Date | string;
		} & PaginationParams
	): Observable<PaginationResponse<ServiceVerificationsFailedArea>> {
		params.from = TimeUtils.format(params.from, 'YYYY-MM-DD');
		params.to = TimeUtils.format(params.to, 'YYYY-MM-DD');
		return virtualBrownClient.get(
			`${BASE_URL}/failed-areas${Params.makeQueryString(params)}`,
			{
				noContract: params.contract !== undefined,
			}
		);
	}

	public getFailedAreasSummary(params: {
		contract?: number | string;
		from: Date | string;
		to: Date | string;
	}): Observable<ServiceVerificationsFailedAreaSummary> {
		const queryString = Params.makeQueryString(params);

		return virtualBrownClient.get(
			`${BASE_URL}/failed-areas/summary/${queryString}`
		);
	}

	public finalizeFailedArea(body: {
		area: number;
		comment: string;
		employee: number;
		verification_date: Date;
	}): Observable<ServiceVerificationsFailedAreaAcknowledgement> {
		return virtualBrownClient.post(`${BASE_URL}/failed-areas/`, {
			...body,
			verification_date: TimeUtils.format(
				body.verification_date,
				'YYYY-MM-DD'
			),
		});
	}

	public getFailedVerificationFinalization(
		params: {
			contract?: number | string;
			from: Date | string;
			to: Date | string;
		} & PaginationParams
	): Observable<PaginationResponse<ServiceVerificationsFailedFinalization>> {
		return virtualBrownClient.get(
			`${BASE_URL}/single-day-verifications/${Params.makeQueryString(params)}`,
			{
				noContract: params.contract !== undefined,
			}
		);
	}

	public finalizeFailedVerificationDay(body: {
		is_acknowledged: boolean;
		employee: number;
		contract: number;
		verification_date: Date;
	}): Observable<any> {
		return virtualBrownClient.post(
			`${BASE_URL}/single-day-verifications/`,
			{
				...body,
				verification_date: TimeUtils.format(
					body.verification_date,
					'YYYY-MM-DD'
				),
			}
		);
	}

	public getAuditDetails(params: {
		audit_id: string;
	}): Observable<ServiceVerificationsFailedVerificationDetails[]> {
		return virtualBrownClient.get(
			`${BASE_URL}/area-results/detail/${Params.makeQueryString(params)}`
		);
	}

	public getVerificationImages(params: {
		verification_id: string;
		element?: string;
	}): Observable<ServiceVerificationsFailedVerificationDetails> {
		return virtualBrownClient.get(
			`${BASE_URL}/latest-activity/card/${Params.makeQueryString(params)}`
		);
	}

	public acknowledgeVerification(body: {
		audit_id: string;
		is_acknowledged: boolean;
		employee_id: number;
	}): Observable<any> {
		return virtualBrownClient.post(
			`${BASE_URL}/area-results/detail/`,
			body
		);
	}

	public getAreasOfAreaType(params: {
		contract?: number | string;
		area_type_id: number;
		from: Date | string;
		to: Date | string;
		category?: string;
	}): Observable<ServiceVerificationsAreasOfAreaType[]> {
		const queryString = Params.makeQueryString(params);

		return virtualBrownClient.get(
			`${BASE_URL}/inspection/area-types/areas-list${queryString}`
		);
	}

	public getLeaderboard(params: {
		contract: number | string;
		to: Date | string;
		from: Date | string;
		category?: string;
	}): Observable<ServiceVerificationsLeaderboard> {
		const queryString = Params.makeQueryString(params);

		return virtualBrownClient.get(
			`${BASE_URL}/employee-ranking/${queryString}`
		);
	}

	public getEmployeeInfo(params: {
		contract: number | string;
		to: Date | string;
		from: Date | string;
		employee: number;
	}): Observable<EmployeeInfo> {
		const queryString = Params.makeQueryString(params);

		return virtualBrownClient.get(
			`${BASE_URL}/employee-verifications/summary/${queryString}`
		);
	}

	public getAllVerifications(params: {
		contract?: number | string;
		from: Date | string;
		to: Date | string;
		limit?: number;
		offset?: number;
		category: 'associate' | 'manager' | 'all'; // default is associate
		area_type_id?: number | string;
		area_id?: number | string;
		status?: 'pending' | 'reviewed' | 'all';
		ordering?:
			| 'score'
			| '-score'
			| '-Timestamp'
			| 'Timestamp'
			| 'site-name'
			| '-site-name';
	}): Observable<PaginationResponse<ServiceVerificationsAllVerifications>> {
		const queryString = Params.makeQueryString(params);

		return virtualBrownClient.get(
			`${BASE_URL}/verifications/${queryString}`,
			{
				noContract: params.contract === undefined,
			}
		);
	}

	public getVerificationsForReview(params: {
		contract: number | string;
		from: Date | string;
		to: Date | string;
		limit?: number;
		offset?: number;
		status?: 'pending' | 'reviewed' | string;
		search?: string;
	}): Observable<PaginationResponse<ServiceVerificationsForReview>> {
		const queryString = Params.makeQueryString(params);
		return virtualBrownClient.get(
			`${BASE_URL}/verification-adjustment/${queryString}`
		);
	}

	public ServiceVerificationsForReviewCount(params: {
		contract?: number | string;
		from: Date | string;
		to: Date | string;
		category?: string;
	}): Observable<ServiceVerificationsForReviewCount> {
		const queryString = Params.makeQueryString(params);
		return virtualBrownClient.get(
			`${BASE_URL}/verification-adjustment/count/${queryString}`,
			{
				noContract: params.contract === undefined,
			}
		);
	}

	public submitReviewedVerification(payload: {
		verification_id: string;
		area_id: number;
		user_id: number;
		updated_images: UpdatedImagesBody[];
	}): Observable<UpdatedImagesResponse> {
		return virtualBrownClient.put(
			`${BASE_URL}/verification-adjustment/`,
			payload,
			noOpts
		);
	}

	public failedImagesCount(payload: {
		audit_id: string;
	}): Observable<FailedImagesCountResponse> {
		const queryString = Params.makeQueryString(payload);
		return virtualBrownClient.get(
			`${BASE_URL}/verification-adjustment/failed-images/count/${queryString}`,
			noOpts
		);
	}

	public revisedImagesTotalCount(payload: {
		contract?: number | string;
		from: Date | string;
		to: Date | string;
		category?: string;
	}): Observable<RevisedImagesTotalCountResponse> {
		const queryString = Params.makeQueryString(payload);
		return virtualBrownClient.get(
			`${BASE_URL}/verification-adjustment/failed-images/aggregate-count/${queryString}`,
			noOpts
		);
	}

	public getElementsAverageScores(params: {
		contract?: string | number;
		from: Date | string;
		to: Date | string;
		area_type_id?: number;
		area_id?: number;
		category: string;
	}): Observable<PaginationResponse<AreaElementsByAverageScore>> {
		const queryString = Params.makeQueryString(params);
		return virtualBrownClient.get(
			`${BASE_URL}/area-elements/summary/${queryString}`
		);
	}

	public getAreasList(payload: {
		contract: number | string;
		from: Date | string;
		to: Date | string;
		area_type_id?: number;
		category?: string;
		filter?: 'pass' | 'fail';
		search?: string | number;
	}): Observable<AreasListView> {
		const queryString = Params.makeQueryString(payload);
		return virtualBrownClient.get(`${BASE_URL}/areas-list/${queryString}`);
	}

	public getContractsWithVerifications(): Observable<ContractJustNames[]> {
		return virtualBrownClient.get(`${BASE_URL}/verification-contracts/`);
	}

	public invokeLambdaForRevision(
		payload: { image_ids: string[] },
		auth: string
	) {
		return fetch(env.verificationsReview, {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Token ${auth}`,

				// `Token ${auth}`,
			},
			body: JSON.stringify(payload),
		})
			.then((res) => {
				if (!res.ok) {
					throw new Error(
						'Network response was not ok ' + res.statusText
					);
				}
				return res.json();
			})
			.then((data) => {
				return data;
			})
			.catch(() => {});
	}
}

export const serviceVerificationsService = new ServiceVerificationsService();
