import { DatePickerTypes } from '@new/DatePickerV2/date-config';

export interface CustomDateRange {
	startDate: Date;
	endDate: Date;
	customStartTime?: string;
	customEndTime?: string;
}

export interface DateRangePickerProps {
	dateRange: CustomDateRange;
	onApplyDateRange: (params: CustomDateRange) => void;
	onCancel: () => void;
	disabled?: boolean;
	disablePast?: boolean;
	disableFuture?: boolean;
	rounded?: boolean;
	shadow?: boolean;
	locales?: string;
	className?: string;
	style?: React.CSSProperties;
	lockRange?: DatePickerTypes;
	customTime?: boolean;
}

export const DEFAULT_START_TIME = '00:00';
export const DEFAULT_END_TIME = '23:59';
