import { SummaryChart, SummaryParams } from '@models';

export interface SafetyIncidentsSummaryParams<T> extends SummaryParams<T> {
	category?: number;
	mode?: 'SITE' | 'CLIENT' | 'VERTICAL'; // chart 33 - GetIncidentManager
}

export namespace SafetyIncidentsSummary {
	export enum Chart {
		IncidentsByMonth = 0,
		RecordableIncidentsByMonth = 1,
		IncidentsClassificationTotals = 2,
		DaysSinceLastRecordable = 3,
		WorkRelatedTotalsByMonth = 4,
		WorkRelatedTotals = 5,
		WorkRelatedTotalsByCategory = 6,
		CauseTotalsByCategory = 7,
		CauseCountByMonth = 8,
		IncidentClaimsByMonth = 9,
		IncidentTypesByMonth = 10,
		RecordablesTotalsByMonth = 11,
		RecordablesTypeTotals = 12,
		RecordableTypeCountByMonth = 13,
		IncidentsBySite = 14,
		IncidentsByTag = 15,
		IncidentsByBuilding = 16,
		IncidentCounts = 17,
		IncidentCountByOrganization = 18,
		RecordablesFrontPage = 19,
		RootCauseTotalsByCategory = 20,
		RootCauseCountByMonth = 21,
		GetSafetyUsageStats = 22,
		GetSafetyCountsByService = 23,
		GetIncidentCountsByVendor = 24,
		GetRecordableCountsBySite = 25,
		GetRecordablesCountsByCauseOfInjury = 26,
		GetRecordableCountsByCauseOfInjuryByMonth = 27,
		GetTrirTopTen = 28,
		GetRecordableCountsByVertical = 29,
		GetRecordableCountsByClient = 30,
		GetRecordableCountsByVerticalByMonth = 32,
		GetIncidentManager = 33,
		GetClaimsTotal = 34,
		GetClaimsByType = 35,
		GetClaimsTypeByMonth = 36,
		GetClaimsVerticalByMonth = 37,
		GetClaimsManager = 38,
		GetRecordablesTotal = 39,
		GetIncidentsVerticalByMonth = 40,
	}

	export interface IncidentsByMonth {
		incidents: number;
		mo: string | null;
	}

	export interface IncidentsByMonthSummary
		extends SummaryChart<IncidentsByMonth[]> {
		chart: 'GetIncidentsByMonth';
		number: '0';
		time_scale: 'month' | 'day';
	}

	export interface RecordableIncidentsByMonth {
		mo: string | null;
		recordables: number;
	}

	export interface RecordableIncidentsByMonthSummary
		extends SummaryChart<RecordableIncidentsByMonth[]> {
		chart: 'GetRecordableIncidentsByMonth';
		number: '1';
	}

	export interface IncidentsClassificationTotals {
		first_aid: number;
		recordable: number;
		report_only: number;
	}

	export interface IncidentsClassificationTotalsSummary
		extends SummaryChart<IncidentsClassificationTotals[]> {
		chart: 'GetIncidentsClassificationTotals';
		number: '2';
	}

	export interface DaysSinceLastRecordable {
		date_of_last_claim_filed: string;
		date_of_last_incident: string;
		date_of_last_recordable: string;
		date_of_last_work_related: string;
		days_since_last_claim_filed: number;
		days_since_last_incident: number;
		days_since_last_recordable: number;
		days_since_last_work_related: number;
	}

	export interface DaysSinceLastRecordableSummary
		extends SummaryChart<DaysSinceLastRecordable> {
		chart: 'GetDaysSinceLastRecordable';
		number: '3';
	}

	export interface WorkRelatedTotalsByMonth {
		mo: string | null;
		not_work_related: number;
		work_related: number;
	}

	export interface WorkRelatedTotalsByMonthSummary
		extends SummaryChart<WorkRelatedTotalsByMonth[]> {
		chart: 'GetWorkRelatedTotalsbyMonth';
		number: '4';
		time_scale: 'month' | 'day';
	}

	export interface WorkRelatedTotals {
		not_work_related: number;
		work_related: number;
	}

	export interface WorkRelatedTotalsSummary
		extends SummaryChart<WorkRelatedTotals> {
		chart: 'GetWorkRelatedTotals';
		number: '5';
		time_scale: 'month' | 'day';
	}

	export interface WorkRelatedTotalsByCategory {
		First_Aid: number;
		Near_Miss: number;
		Not_Defined: number;
		Recordable: number;
		Report_Only: number;
	}

	export interface WorkRelatedTotalsByCategorySummary
		extends SummaryChart<WorkRelatedTotalsByCategory> {
		chart: 'GetWorkRelatedTotalsByCategory';
		number: '6';
	}

	export interface CauseTotalsByCategory {
		Burn: number;
		Caught_in_or_Between: number;
		Chemical_contact: number;
		'Cut_/_Puncture': number;
		Lifting: number;
		Other: number;
		Pushing_Pulling: number;
		Reaching: number;
		Struck_By: number;
		Trip_or_Fall: number;
		other: number;
	}

	export interface CauseTotalsByCategorySummary
		extends SummaryChart<CauseTotalsByCategory> {
		chart: 'GetCauseTotalsByCategory';
		number: '7';
	}

	export interface CauseCountByMonth {
		mo: string | null;
		total: number | null;
	}

	export interface CauseCountByMonthSummary
		extends SummaryChart<CauseCountByMonth[]> {
		chart: 'GetCauseCountByMonth';
		number: '8';
		time_scale: 'month' | 'day';
	}

	export interface IncidentClaimsByMonth {
		claim_filed: number;
		claim_not_filed: number;
		mo: string | null;
	}

	export interface IncidentClaimsByMonthSummary
		extends SummaryChart<IncidentClaimsByMonth[]> {
		chart: 'GetIncidentClaimsByMonth';
		number: '9';
		time_scale: 'month' | 'day';
	}

	export interface IncidentTypesByMonth {
		first_aid: null | number;
		mo: string;
		near_miss: null | number;
		recordable: null | number;
		report_only: null | number;
	}

	export interface IncidentTypesByMonthSummary
		extends SummaryChart<IncidentTypesByMonth[]> {
		chart: 'GetIncidentTypesByMonth';
		number: '10';
		time_scale: 'month' | 'day';
	}

	export interface RecordablesTotalsByMonth {
		mo: string | null;
		recordables: number;
	}

	export interface RecordablesTotalsByMonthSummary
		extends SummaryChart<RecordablesTotalsByMonth[]> {
		chart: 'GetRecordablesTotalsbyMonth';
		number: '11';
	}

	export interface RecordablesTypeTotals {
		lost_time: number;
		modified_duty: number;
		other_recordable_types: number;
		total_recordables: number;
	}

	export interface RecordablesTypeTotalsSummary
		extends SummaryChart<RecordablesTypeTotals> {
		chart: 'GetRecordablesTypeTotals';
		number: '12';
	}

	export interface RecordableTypeCountByMonth {
		mo: string | null;
		lost_time_count: number;
		modified_duty_count: number;
		other_count: number;
		total: number;
	}
	export interface RecordableTypeCountByMonthSummary
		extends SummaryChart<RecordableTypeCountByMonth[]> {
		chart: 'GetRecordableTypeCountByMonth';
		number: '13';
		time_scale: 'month' | 'day';
	}

	export interface IncidentsBySite {
		city: string;
		has_tag_groups: boolean;
		id: number;
		incidents_days_since_last: number;
		incidents_max_date: string;
		is_pass_recordables: boolean;
		is_pass_work_related_incidents: boolean;
		lat: number;
		lon: number;
		name: string;
		non_work_related_incidents: number;
		open_incidents: number;
		organization_name: string;
		postal_code: string;
		recordables: number;
		recordables_days_since_last: number;
		recordables_max_date: string;
		region: string;
		square_feet: number;
		street1: string;
		street2: string;
		total_incidents: number;
		work_related_incidents: number;
	}

	export interface IncidentsBySiteSummary
		extends SummaryChart<IncidentsBySite[]> {
		chart: 'GetIncidentsBySite';
		max_incidents: number;
		max_recordables: number;
	}

	export interface IncidentsByTag {
		contract_id: number;
		contract_name: string;
		id: number;
		incidents: number;
		incidents_days_since_last: number;
		incidents_max_date: string;
		lat: number;
		lon: number;
		name: string;
		recordables: number;
		recordables_days_since_last: number;
		recordables_max_date: string;
	}

	export interface IncidentsByTagSummary
		extends SummaryChart<IncidentsByTag[]> {
		chart: 'GetIncidentsByTag';
		number: '15';
	}

	export interface IncidentsByBuilding {
		contract_id: number;
		contract_name: number;
		id: number;
		incidents: number;
		incidents_days_since_last: number;
		incidents_max_date: string;
		lat: number;
		lon: number;
		name: string;
		recordables: number;
		recordables_days_since_last: number;
		recordables_max_date: string;
	}

	export interface IncidentsByBuildingSummary
		extends SummaryChart<IncidentsByBuilding[]> {
		chart: 'GetIncidentsByBuilding';
		number: '16';
	}

	export interface IncidentCount {
		days_since_last_incident: number;
		last_date_of_incident: string;
		total_incident: number;
		total_non_work_related: number;
		total_open_incident: number;
		total_work_related: number;
	}

	export interface IncidentCountsSummary
		extends SummaryChart<IncidentCount[]> {
		chart: 'GetIncidentCounts';
		number: '17';
	}

	export interface IncidentCountByOrganization {
		customer_id: number;
		customer_name: string;
		days_since_last_incident: number;
		last_date_of_incident: string;
		total_incident: number;
		total_non_work_related: number;
		total_open_incident: number;
		total_work_related: number;
	}

	export interface IncidentCountByOrganizationSummary
		extends SummaryChart<IncidentCountByOrganization[]> {
		chart: 'GetIncidentCountByOrganization';
		number: '18';
	}

	export interface RecordablesFrontPage {
		days_since: number;
		latest_date: string;
		latest_id: number;
		total_in_date_range: number;
	}

	export interface RecordablesFrontPageSummary
		extends SummaryChart<RecordablesFrontPage> {
		chart: 'GetRecordablesFrontPageSummary';
		number: '19';
	}

	export interface RootCauseTotalsByCategory {
		'At Risk Behavior': number;
		Communication: number;
		Environment: number;
		Fatigue: number;
		Frustration: number;
		'Pre-Planning': number;
		Procedures: number;
		Rushing: number;
		Training: number;
		'Unsafe Action': number;
		'Work Practices': number;
	}

	export interface RootCauseTotalsByCategorySummary
		extends SummaryChart<RootCauseTotalsByCategory> {
		chart: 'GetRootCauseTotalsByCategory';
		number: '20';
	}

	export interface RootCauseCountByMonth {
		mo: string;
		total: number;
	}

	export interface RootCauseCountByMonthSummary
		extends SummaryChart<RootCauseCountByMonth[]> {
		chart: 'GetRootCauseCountByMonth';
		number: '21';
		time_scale: 'month' | 'day';
	}

	export interface SafetyUsageStats {
		total_incident_created: number;
		total_employees: number;
		total_good_catch_created: number;
	}

	export interface SafetyUsageStatsSummary
		extends SummaryChart<SafetyUsageStats> {
		chart: 'GetSafetyUsageStats';
		number: '22';
	}

	export interface SafetyCountsByService {
		count: number;
		id: number;
		name: string;
	}

	export interface IncidentCountsByVendor {
		id: number;
		name: string;
		count: number;
	}

	export interface SafetyCountsByServiceSummary
		extends SummaryChart<SafetyCountsByService[]> {
		chart: 'GetSafetyCountsByService';
		number: '23';
	}

	export interface IncidentCountsByVendorSummary
		extends SummaryChart<IncidentCountsByVendor[]> {
		chart: 'GetIncidentCountsByVendor';
		number: '24';
	}

	export interface RecordableCountsBySite {
		id: number;
		name: string;
		customer_id: number;
		customer_name: string;
		contract_start_date: string;
		manager_contact: ManagerContact;
		number_of_recordables: number;
		number_of_incidents: number;
		latest_recordable_date: string | null;
		days_free: number;
		trir: number;
	}

	export interface ManagerContact {
		last_name: string;
		first_name: string;
		employee_id: number;
	}

	export interface RecordableCountsBySiteSummary
		extends SummaryChart<RecordableCountsBySite[]> {
		chart: 'GetRecordableCountsBySite';
		number: '25';
	}

	export interface RecordablesCountsByCauseOfInjury {
		id: number;
		display_name: string;
		number_of_recordables: number;
		total: number;
		percentage: number;
	}

	export interface RecordablesCountsByCauseOfInjurySummary
		extends SummaryChart<RecordablesCountsByCauseOfInjury[]> {
		chart: 'GetRecordablesCountsByCauseOfInjury';
		number: '26';
	}

	export interface RecordableCountsByCauseOfInjuryByMonth {
		mo: string;
		cause_of_injuries: CauseOfInjuries[];
	}

	export interface CauseOfInjuries {
		id: number;
		display_name: string;
		number_of_recordables: number;
	}

	export interface RecordableCountsByCauseOfInjuryByMonthSummary
		extends SummaryChart<RecordableCountsByCauseOfInjuryByMonth[]> {
		chart: 'GetRecordableCountsByCauseOfInjuryByMonth';
		number: '27';
		time_scale: 'month' | 'day';
	}

	export interface TrirTopTen {
		chart: 'GetTrirTopTen';
		number: '28';
	}

	export interface RecordableCountsByVerticalSummary
		extends SummaryChart<RecordableDetailCount[]> {
		chart: 'GetRecordableCountsByVertical';
		number: '29';
	}

	export interface RecordableDetailCount {
		id: number;
		latest_recordable_date: string;
		name: string;
		number_of_incidents: number;
		number_of_recordables: number;
		trir: number;
	}

	export interface RecordableCountsByClientSummary
		extends SummaryChart<RecordableDetailCount[]> {
		chart: 'GetRecordableCountsByClient';
		number: '30';
	}

	export interface RecordableCountsByVerticalByMonth {
		mo: string;
		counts: {
			total: number;
			vertical_id: number | null;
			vertical_name: string;
		}[];
	}

	export interface RecordableCountsByVerticalByMonthSummary
		extends SummaryChart<RecordableCountsByVerticalByMonth[]> {
		chart: 'GetRecordableCountsByVerticalByMonth';
		number: '32';
		time_scale: 'month' | 'day';
	}

	export interface IncidentManager {
		id: number;
		name: string;
		total_incidents: number;
		first_aid: number;
		recordables: number;
		near_miss: number;
		report_only: number;
	}

	export interface IncidentManagerSummary
		extends SummaryChart<IncidentManager[]> {
		chart: 'GetIncidentManager';
		number: '33';
	}

	export interface ClaimsTotal {
		days_claims_free: number;
		total_claims: number;
	}

	export interface ClaimsTotalSummary extends SummaryChart<ClaimsTotal> {
		chart: 'GetClaimsTotal';
		number: '34';
	}

	export interface ClaimsByType {
		type_id: number;
		type_name: string;
		total_claims: number;
	}

	export interface ClaimsByTypeSummary extends SummaryChart<ClaimsByType[]> {
		chart: 'GetClaimsByType';
		number: '35';
	}

	export type ClaimsTypeByMonth = {
		types: {
			id: number;
			name: string;
			total_claims: number;
		}[];
	} & (
		| {
				month: string;
				day?: never;
		  }
		| {
				day: string;
				month?: never;
		  }
	);

	export interface ClaimsTypeByMonthSummary
		extends SummaryChart<ClaimsTypeByMonth[]> {
		chart: 'GetClaimsTypeByMonth';
		number: '36';
		time_scale: 'month' | 'day';
	}

	export type ClaimsVerticalByMonth = {
		verticals: {
			id: number;
			name: string;
			total_claims: number;
		}[];
	} & (
		| {
				month: string;
				day?: never;
		  }
		| {
				day: string;
				month?: never;
		  }
	);

	export interface ClaimsVerticalByMonthSummary
		extends SummaryChart<ClaimsVerticalByMonth[]> {
		chart: 'GetClaimsVerticalByMonth';
		number: '37';
		time_scale: 'month' | 'day';
	}

	export interface ClaimsManager {
		id: number;
		name: string;
		total_claims: number;
		days_claim_free: number;
		manager_contact: ManagerContact;
	}

	export interface ClaimsManagerSummary
		extends SummaryChart<ClaimsManager[]> {
		chart: 'GetClaimsManager';
		number: '38';
	}

	export interface RecordablesTotal {
		total_recordables: number;
		total_incidents: number;
		days_recordable_free: number;
	}

	export interface RecordablesTotalSummary
		extends SummaryChart<RecordablesTotal> {
		chart: 'GetRecordablesTotal';
		number: '39';
	}

	export type IncidentsVerticalByMonth = {
		verticals: {
			id: number;
			name: string;
			total_incidents: number;
		}[];
	} & (
		| {
				month: string;
				day?: never;
		  }
		| {
				day: string;
				month?: never;
		  }
	);

	export interface IncidentsVerticalByMonthSummary
		extends SummaryChart<IncidentsVerticalByMonth[]> {
		chart: 'GetIncidentsVerticalByMonth';
		number: '40';
		time_scale: 'month' | 'day';
	}

	export type SummaryReturnType = {
		[Chart.IncidentsByMonth]: IncidentsByMonthSummary;
		[Chart.RecordableIncidentsByMonth]: RecordableIncidentsByMonthSummary;
		[Chart.IncidentsClassificationTotals]: IncidentsClassificationTotalsSummary;
		[Chart.DaysSinceLastRecordable]: DaysSinceLastRecordableSummary;
		[Chart.WorkRelatedTotalsByMonth]: WorkRelatedTotalsByMonthSummary;
		[Chart.WorkRelatedTotals]: WorkRelatedTotalsSummary;
		[Chart.WorkRelatedTotalsByCategory]: WorkRelatedTotalsByCategorySummary;
		[Chart.CauseTotalsByCategory]: CauseTotalsByCategorySummary;
		[Chart.CauseCountByMonth]: CauseCountByMonthSummary;
		[Chart.IncidentClaimsByMonth]: IncidentClaimsByMonthSummary;
		[Chart.IncidentTypesByMonth]: IncidentTypesByMonthSummary;
		[Chart.RecordablesTotalsByMonth]: RecordablesTotalsByMonthSummary;
		[Chart.RecordablesTypeTotals]: RecordablesTypeTotalsSummary;
		[Chart.RecordableTypeCountByMonth]: RecordableTypeCountByMonthSummary;
		[Chart.IncidentsBySite]: IncidentsBySiteSummary;
		[Chart.IncidentsByTag]: IncidentsByTagSummary;
		[Chart.IncidentsByBuilding]: IncidentsByBuildingSummary;
		[Chart.IncidentCounts]: IncidentCountsSummary;
		[Chart.IncidentCountByOrganization]: IncidentCountByOrganizationSummary;
		[Chart.RecordablesFrontPage]: RecordablesFrontPageSummary;
		[Chart.RootCauseTotalsByCategory]: RootCauseTotalsByCategorySummary;
		[Chart.RootCauseCountByMonth]: RootCauseCountByMonthSummary;
		[Chart.GetSafetyUsageStats]: SafetyUsageStatsSummary;
		[Chart.GetSafetyCountsByService]: SafetyCountsByServiceSummary;
		[Chart.GetIncidentCountsByVendor]: IncidentCountsByVendorSummary;
		[Chart.GetRecordableCountsBySite]: RecordableCountsBySiteSummary;
		[Chart.GetRecordablesCountsByCauseOfInjury]: RecordablesCountsByCauseOfInjurySummary;
		[Chart.GetRecordableCountsByCauseOfInjuryByMonth]: RecordableCountsByCauseOfInjuryByMonthSummary;
		[Chart.GetTrirTopTen]: TrirTopTen;
		[Chart.GetRecordableCountsByVertical]: RecordableCountsByVerticalSummary;
		[Chart.GetRecordableCountsByClient]: RecordableCountsByClientSummary;
		[Chart.GetRecordableCountsByVerticalByMonth]: RecordableCountsByVerticalByMonthSummary;
		[Chart.GetIncidentManager]: IncidentManagerSummary;
		[Chart.GetClaimsTotal]: ClaimsTotalSummary;
		[Chart.GetClaimsByType]: ClaimsByTypeSummary;
		[Chart.GetClaimsTypeByMonth]: ClaimsTypeByMonthSummary;
		[Chart.GetClaimsVerticalByMonth]: ClaimsVerticalByMonthSummary;
		[Chart.GetClaimsManager]: ClaimsManagerSummary;
		[Chart.GetRecordablesTotal]: RecordablesTotalSummary;
		[Chart.GetIncidentsVerticalByMonth]: IncidentsVerticalByMonthSummary;
	};
}
