export function abbreviateNumber(
	number: number | string,
	options?: {
		downTo?: 'B' | 'M' | 'K'; // Billion, Million, Thousands
		decimal?: number; // Round original number this many decimal places
		abbreviatedDecimal?: number; // After abbreviation show this many decimal places
		trailingZeros?: boolean; // Choose to show trailing zeroes or not (e.g. 1.50 vs 1.5)
		isDollar?: boolean; // Choose whether to Put dollar sign in front
		commas?: boolean; // Choose whether to display commas
		multi?: boolean;
	}
): string {
	const defaultOptions = {
		downTo: 'M',
		decimal: 0,
		abbreviatedDecimal: 2,
		trailingZeros: false,
		isDollar: false,
		commas: true,
		multi: false,
	};

	const num = Number(number);
	const absNum = Math.abs(num);

	if (options?.multi) {
		if (absNum >= 1e9) options.downTo = 'B';
		else if (absNum >= 1e6) options.downTo = 'M';
		else if (absNum >= 1e3) options.downTo = 'K';
		// else -- don't do let it default/add more options of Trillions.. and more
		// Modify here to return the number without formatting if it's smaller than 1000.
	}

	const opts = { ...defaultOptions, ...options };

	function adjustNumber(num, divisor: number = 1): string {
		const roundNumber =
			Math.round(num * 10 ** opts.decimal + Number.EPSILON) /
			10 ** opts.decimal;
		const abbreviatedRoundNumber =
			Math.round(
				(roundNumber / divisor + Number.EPSILON) * 10 ** opts.abbreviatedDecimal
			) /
			10 ** opts.abbreviatedDecimal;
		const trailNumber = opts.trailingZeros
			? abbreviatedRoundNumber.toFixed(opts.abbreviatedDecimal)
			: String(abbreviatedRoundNumber);
		const commaNumber = opts.commas
			? trailNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
			: trailNumber;
		const prefixNumber = opts.isDollar ? `$${commaNumber}` : commaNumber;
		return prefixNumber;
	}

	return absNum >= 1.0e9 && ['B', 'M', 'K'].includes(opts.downTo)
		? `${adjustNumber(num, 1.0e9)}B`
		: absNum >= 1.0e6 && ['B', 'M'].includes(opts.downTo)
		? `${adjustNumber(num, 1.0e6)}M`
		: absNum >= 1.0e3 && ['K'].includes(opts.downTo)
		? `${adjustNumber(num, 1.0e3)}K`
		: adjustNumber(num);
}
// usage example
// abbreviateNumber(1349234.1199999999, { isDollar: true })
// output: $1.35M
