import { RoutesVerifiableTasksSummary } from '@api-interfaces';
import { Params } from '@helpers';
import { virtualBrownClient } from '@services';

// helpers

const BASE_URL = 'routes/verifiable_tasks/summaries';

class RoutesVerifiableTasksSummaryService {
	getSummaries<T extends RoutesVerifiableTasksSummary.Chart>(params: {
		from?: Date;
		to?: Date;
		chart: T[];
		time_period?: 'hour' | 'month' | 'day';
	}) {
		return virtualBrownClient.get<
			RoutesVerifiableTasksSummary.SummaryReturnType[T][]
		>(`${BASE_URL}/${Params.makeQueryString(params)}`);
	}
}

export const routesVerifiableTasksSummaryService =
	new RoutesVerifiableTasksSummaryService();
