// this library hates TimeZones and is broke AF, make sure yous trip the TZ off the date, string, number you give to any of the methods of this library
// number you give to any of the methods of this library, because it expects local date as the input parameter which it does tell, so it lies.

import {
	setYear,
	getMonth,
	setMonth,
	setHours,
	setMinutes,
	setSeconds,
	setMilliseconds,
	getISOWeek,
	addMonths,
	subMonths,
	subHours,
	isSameDay,
	parse,
	isSameMonth,
	startOfWeek,
	addDays,
	subDays,
	subMinutes,
	endOfWeek,
	endOfMonth,
	startOfMonth,
	isAfter,
	isValid,
	isFuture,
	isDate,
	addMilliseconds,
	subMilliseconds,
	differenceInYears,
	differenceInMonths,
	differenceInHours,
	differenceInMinutes,
	differenceInSeconds,
	distanceInWords,
	distanceInWordsStrict,
	startOfDay,
	endOfDay,
	addHours,
	addMinutes,
	isBefore,
	eachDay,
	format as formatDate,
	addSeconds,
	addWeeks,
	addYears,
	setDay,
	setISOWeek,
	subYears,
	endOfYear,
	differenceInDays,
	isSameYear,
	getDaysInMonth,
	startOfYear,
	isEqual,
	differenceInCalendarMonths,
	differenceInCalendarDays,
	isThisMonth,
	compareAsc,
	compareDesc,
	isWithinRange,
	differenceInMilliseconds,
	subSeconds,
} from 'date-fns';

// @ts-ignore
import { parseISO } from 'date-fns-latest';
import {
	parseFromTimeZone,
	formatToTimeZone,
	convertToTimeZone,
} from 'date-fns-timezone';

import { DateRange, DateRangeType } from '@core/app-context/app.interfaces';

type TimePeriods =
	| 'seconds'
	| 'minutes'
	| 'hours'
	| 'days'
	| 'weeks'
	| 'months'
	| 'years';

export class TimeUtils {
	public static add(
		date: string | number | Date,
		amount: number,
		period: TimePeriods
	) {
		const timeMap: {
			[key: string]: (
				date: string | number | Date,
				amount: number
			) => Date;
		} = {
			seconds: addSeconds,
			minutes: addMinutes,
			hours: addHours,
			days: addDays,
			weeks: addWeeks,
			months: addMonths,
			years: addYears,
		};
		return timeMap[period](date, amount);
	}

	public static set(
		date: string | number | Date,
		time: number,
		period: TimePeriods
	) {
		const timeMap: {
			[key: string]: (date: string | number | Date, time: number) => Date;
		} = {
			seconds: setSeconds,
			minutes: setMinutes,
			hours: setHours,
			days: setDay,
			weeks: setISOWeek,
			months: setMonth,
			years: setYear,
		};
		return timeMap[period](date, time);
	}

	public static isValid(date: Date): boolean {
		return isValid(date);
	}

	public static rangeToArray(date1: Date, date2: Date): Date[] {
		return eachDay(date1, date2);
	}

	public static isBefore(
		dateLeft: Date | string | number,
		dateRight: Date | string | number
	) {
		return isBefore(dateLeft, dateRight); // returns true if first date is before second
	}

	public static isSameRange(rangeLeft: DateRange, rangeRight: DateRange) {
		return (
			TimeUtils.isSameDay(rangeLeft.from, rangeRight.from) &&
			TimeUtils.isSameDay(rangeLeft.to, rangeRight.to)
		);
	}

	public static differenceInCalendarMonths = (
		leftDate: Date,
		rightDate: Date
	) => differenceInCalendarMonths(leftDate, rightDate);

	public static differenceInCalendarDays = (
		leftDate: Date,
		rightDate: Date
	) => differenceInCalendarDays(leftDate, rightDate);

	public static differenceInYears(
		dateLeft: Date | string | number,
		dateRight: Date | string | number
	) {
		return differenceInYears(dateLeft, dateRight);
	}

	public static differenceInMonths(
		dateLeft: Date | string | number,
		dateRight: Date | string | number
	) {
		return differenceInMonths(dateLeft, dateRight);
	}

	public static differenceInDays(
		dateLeft: Date | string | number,
		dateRight: Date | string | number
	) {
		return differenceInDays(dateLeft, dateRight);
	}

	public static differenceInHours(
		dateLeft: Date | string | number,
		dateRight: Date | string | number
	) {
		return differenceInHours(dateLeft, dateRight);
	}

	public static differenceInMinutes(
		dateLeft: Date | string | number,
		dateRight: Date | string | number
	) {
		return differenceInMinutes(dateLeft, dateRight);
	}

	public static differenceInSeconds(
		dateLeft: Date | string | number,
		dateRight: Date | string | number
	) {
		return differenceInSeconds(dateLeft, dateRight);
	}

	public static distanceInWords(
		dateLeft: Date | string | number,
		dateRight: Date | string | number = new Date()
	) {
		return distanceInWords(dateLeft, dateRight);
	}

	public static setHours(date: Date | string | number, hours: number): Date {
		return setHours(date, hours);
	}

	public static setMinutes(
		date: Date | string | number,
		minutes: number
	): Date {
		return setMinutes(date, minutes);
	}

	public static setSeconds(
		date: Date | string | number,
		seconds: number
	): Date {
		return setSeconds(date, seconds);
	}

	public static setMilliseconds(
		date: Date | string | number,
		milliseconds: number
	): Date {
		return setMilliseconds(date, milliseconds);
	}

	public static getMonth(date: Date | string | number): number {
		return getMonth(date);
	}

	public static getMonthsAsArray(date: Date | string | number): number {
		return getMonth(date);
	}

	/* Parse Date Props From Array of Objects; Make Unique; Sort In Order or In Reverse */
	public static getMonthsIntoArray(
		data: any[],
		key: string,
		reverse?: boolean
	) {
		const parsedDatesRanges = [
			...new Set(data.map((item: any) => item[key])),
		].sort((a: string | any, b: string | any) => {
			return TimeUtils.getMonth(a) - TimeUtils.getMonth(b);
		});
		if (reverse) parsedDatesRanges.reverse();
		return parsedDatesRanges;
	}

	public static setMonth(date: Date | string | number, month: number): Date {
		return setMonth(date, month);
	}

	public static setYear(date: Date | string | number, year: number): Date {
		return setYear(date, year);
	}

	public static getISOWeek(date: Date | string | number): number {
		return getISOWeek(date);
	}

	public static isSameMonth(
		leftDate: Date | string | number,
		rightDate: Date | string | number
	): boolean {
		return isSameMonth(leftDate, rightDate);
	}

	public static isSameDay(
		leftDate: Date | string | number,
		rightDate: Date | string | number
	): boolean {
		return isSameDay(leftDate, rightDate);
	}

	public static isSameYear(
		leftDate: Date | string | number,
		rightDate: Date | string | number
	): boolean {
		return isSameYear(leftDate, rightDate);
	}

	public static parse(argument: Date | string | number, opts?: any): Date {
		return parse(argument, opts);
	}

	public static startOfWeek(date: Date | string | number, opts?: any): Date {
		return startOfWeek(date, opts);
	}

	public static startOfMonth(date: Date | string | number): Date {
		return startOfMonth(date);
	}

	public static addMilliseconds(
		date: Date | string | number,
		amount: number
	): Date {
		return addMilliseconds(date, amount);
	}

	public static subMilliseconds(
		date: Date | string | number,
		amount: number
	): Date {
		return subMilliseconds(date, amount);
	}

	public static addSeconds(
		date: Date | string | number,
		amount: number
	): Date {
		return addSeconds(date, amount);
	}

	public static subSeconds(
		date: Date | string | number,
		amount: number
	): Date {
		return subSeconds(date, amount);
	}

	public static addMinutes(
		date: Date | string | number,
		amount: number
	): Date {
		return addMinutes(date, amount);
	}

	public static subMinutes(
		date: Date | string | number,
		amount: number
	): Date {
		return subMinutes(date, amount);
	}

	public static addHours(date: Date | string | number, amount: number): Date {
		return addHours(date, amount);
	}

	public static subHours(date: Date | string | number, amount: number): Date {
		return subHours(date, amount);
	}

	public static addDays(date: Date | string | number, amount: number): Date {
		return addDays(date, amount);
	}

	public static subDays(date: Date | string | number, amount: number): Date {
		return subDays(date, amount);
	}

	public static addMonths(
		date: Date | string | number,
		amount: number
	): Date {
		return addMonths(date, amount);
	}

	public static subMonths(
		date: Date | string | number,
		amount: number
	): Date {
		return subMonths(date, amount);
	}

	public static addYears(date: Date | string | number, amount: number): Date {
		return addYears(date, amount);
	}

	public static subYears(date: Date | string | number, amount: number): Date {
		return subYears(date, amount);
	}

	public static endOfWeek(date: Date | string | number, opts?: any): Date {
		return endOfWeek(date, opts);
	}

	public static startOfYear(date: Date) {
		return startOfYear(date);
	}

	public static endOfYear(date: Date | string | number): Date {
		return endOfYear(date);
	}

	public static startOfDay(date: Date | string | number): Date {
		return startOfDay(date);
	}

	public static endOfDay(date: Date | string | number): Date {
		return endOfDay(date);
	}

	public static endOfMonth(date: Date | string | number): Date {
		return endOfMonth(date);
	}

	public static format(
		date: Date | string | number,
		format = 'MM/DD/YYYY h:mm A',
		options?: any
	): string {
		return formatDate(date, format, options);
	}

	public static isFuture(date: Date | string | number): boolean {
		return isFuture(date);
	}

	public static isPast(date: Date | string | number): boolean {
		return !isFuture(date);
	}

	public static isDate(date: Date | string | number): boolean {
		return !isDate(date);
	}

	public static isAfter(
		firstDate: Date | string | number,
		secondDate: Date | string | number
	): boolean {
		return isAfter(firstDate, secondDate);
	}

	/**
	 * Timzone related helpers
	 * date string format example: 2018-09-01T18:01:36.386
	 */
	public static parseFromTimeZone(
		date: string,
		options: {
			timeZone: string;
		}
	): Date {
		// use 'Etc/GMT' for 00:00
		return parseFromTimeZone(date, options);
	}

	public static formatToTimeZone(
		date: Date | string | number,
		format = 'MM/DD/YYYY h:mm A',
		options: {
			timeZone: string;
		}
	): string {
		// use 'Etc/GMT' for 00:00
		return formatToTimeZone(date, format, options);
	}

	public static stripTimeZoneZSuffix(date: string): string {
		return date.length === 0 ||
			date.charAt(date.length - 1).toUpperCase() !== 'Z'
			? date
			: date.substring(0, date.length - 1);
	}

	public static formatToDay(date: Date | string | number): string {
		const strFormat = 'YYYY-MM-DD';
		return this.format(date, strFormat);
	}

	public static formatToISO = (date: Date | string | number): string =>
		new Date(date).toISOString();

	public static msToTime = (
		duration: number,
		showHoursLeadingZero: boolean = true
	) => {
		let seconds = Math.floor((duration / 1000) % 60) as any;
		let minutes = Math.floor((duration / (1000 * 60)) % 60) as any;
		let hours = Math.floor(duration / (1000 * 60 * 60)) as any;

		hours =
			hours < 10
				? `${showHoursLeadingZero ? '0' : ''}${hours}`
				: hours.toString();
		minutes = minutes < 10 ? `0${minutes}` : minutes.toString();
		seconds = seconds < 10 ? `0${seconds}` : seconds.toString();

		return `${hours}:${minutes}:${seconds}`;
	};

	public static ZERO_MS_TIME = TimeUtils.msToTime(0);

	public static isDateInRange = (
		date: Date,
		dateLeft: Date,
		dateRight: Date
	) => {
		const dateCopy = new Date(date);
		const dateLeftCopy = new Date(dateLeft);
		const dateRightCopy = new Date(dateRight);

		const check = dateCopy.setHours(0, 0, 0, 0);
		const start = dateLeftCopy.setHours(0, 0, 0, 0);
		const end = dateRightCopy.setHours(0, 0, 0, 0);

		return check >= start && check <= end;
	};

	public static toYMDDate = (date: Date) => formatDate(date, 'YYYY-MM-DD');

	public static toMDYDate = (date: Date) => formatDate(date, 'MM/DD/YYYY');

	public static isEqual = (leftDate: Date, rightDate: Date) =>
		isEqual(leftDate, rightDate);

	public static getDaysInMonth = (date: string | number | Date) =>
		getDaysInMonth(date);

	public static isThisMonth = (date: string | number | Date) =>
		isThisMonth(date);

	public static compareAsc = (
		leftDate: string | number | Date,
		rightDate: string | number | Date
	) => compareAsc(leftDate, rightDate);

	public static isFullMonth = (leftDate: Date, rightDate: Date) => {
		if (!leftDate || !rightDate) return false;
		if (
			isEqual(leftDate, startOfMonth(leftDate)) &&
			isEqual(rightDate, endOfMonth(rightDate)) &&
			isSameMonth(leftDate, rightDate)
		)
			return true;
		return false;
	};

	public static convertToTimezone = (
		date: string | number | Date,
		options: { timeZone: string }
	) => {
		return convertToTimeZone(date, options);
	};

	public static distanceInWordsStrict = distanceInWordsStrict;

	public static differenceInMilliseconds = differenceInMilliseconds;

	public static isWithinRange = isWithinRange;

	public static compareDesc = compareDesc;

	public static parseISO: (date: string) => Date = parseISO;

	public static formatToRangeLabel({
		from,
		to,
		range,
	}: {
		from: Date;
		to: Date;
		range: DateRangeType;
	}): string {
		return ['today', 'this-month', 'last-month'].includes(range)
			? '1 Month'
			: `${this.format(from, 'MMM DD, YYYY')} - ${this.format(
					to,
					'MMM DD, YYYY'
				)}`;
	}

	public static getOffset(timeZone: string, date = new Date()): number {
		const utcDate = new Date(
			date.toLocaleString('en-US', { timeZone: 'UTC' })
		);
		const tzDate = new Date(date.toLocaleString('en-US', { timeZone }));
		return (tzDate.getTime() - utcDate.getTime()) / 6e4;
	}

	public static shiftToTimeZone(date: Date, timeZone: string) {
		const browserOffset = date.getTimezoneOffset() * -1;
		const timezoneOffset = TimeUtils.getOffset(timeZone, date);
		const timezoneDifference = browserOffset - timezoneOffset;
		return TimeUtils.addMinutes(date, timezoneDifference);
	}
}
