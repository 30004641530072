import { has } from 'lodash';
import { Component, CSSProperties } from 'react';

// interfaces
import Empty from '@components/Empty/Empty';

import { KeyValue } from '@core/models';

// components
// operators

import './AccordionBase.scss';

interface Panel {
  bar: JSX.Element;
  content: JSX.Element;
  className?: string;
  methods?: {
    onSelect?: Function;
  };
}

interface PanelWithActive extends Panel {
  active: boolean;
}

interface State {
  panels: PanelWithActive[];
}

interface Props {
  // props should not be updated (read only)
  data: {
    panels: Panel[];
    defaultOpenIndex?: number; // open specific index
  };
  settings?: {
    defaultOpenAll?: boolean; // false by default
    autoClose?: boolean; // true by default
  };
  className?: string;
  style?: CSSProperties;
  customContainerProps?: KeyValue[];
}

export default class AccordionBase extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      panels: this.props.data.panels.map((panel: PanelWithActive) => {
        panel.active = has(this, 'props.settings.defaultOpenAll')
          ? this.props.settings.defaultOpenAll
          : false;
        return panel;
      }),
    };

    // single default open
    if (
      has(this, 'props.data.defaultOpenIndex') &&
      this.props.data.defaultOpenIndex >= 0 &&
      this.props.data.defaultOpenIndex < this.props.data.panels.length
    ) {
      this.state.panels[this.props.data.defaultOpenIndex].active = true;
    }
  }

  private togglePanel(index: number) {
    if (!this.state.panels[index].active) {
      this.setState(
        (state) => {
          if (
            !has(this.props, 'settings') ||
            !has(this.props.settings, 'autoClose') ||
            this.props.settings.autoClose
          ) {
            state.panels.map((panel) => {
              panel.active = false;
              return panel;
            });
          }

          state.panels[index].active = true;
          return state;
        },
        () => {
          const panel = this.state.panels[index];
          panel.methods && panel.methods.onSelect
            ? panel.methods.onSelect()
            : null;
        }
      );
    } else {
      this.setState((state) => {
        state.panels[index].active = false;
        return state;
      });
    }
  }

  public render() {
    const { className, style, customContainerProps } = this.props;
    const { panels } = this.state;

    return (
      <div
        className={`cm-accordion${className ? ` ${className}` : ''}`}
        style={style || {}}
      >
        {panels && panels.length ? (
          panels.map((panel: PanelWithActive, index: number) => (
            <div
              key={index}
              className={`cm-accordion-panel${
                panel.className ? ` ${panel.className}` : ''
              }${panel.active ? ' active' : ''}`}
              {...customContainerProps?.reduce((prev, curr) => {
                prev[curr.Key] = curr.Value;

                return prev;
              }, {})}
            >
              <div
                className="cm-accordion-bar tw-select-none"
                onClick={() => this.togglePanel(index)}
              >
                {panel.bar}
              </div>
              <div className="cm-accordion-content">{panel.content}</div>
            </div>
          ))
        ) : (
          <Empty>No panels to display.</Empty>
        )}
      </div>
    );
  }
}

/**  Example Usage
* styles must be created on your own (will create extended accordion designs in the future)

<AccordionBase
    className="awesome-accordion"
    data={{
        defaultOpenIndex: 0,
        panels: [
            {
                className: "awesome-accordion-panel",
                bar: (
                    <div className="awesome-accordion-bar t-brdr-main-btm">
                        <div className="awesome-accordion-bar-text">
                            <h3 className="t-text">Purpose</h3>
                            <span className="caption">The value of my job</span>
                        </div>
                    </div>
                ),
                content: (
                    <div className="awesome-accordion-content">
                        {employee.JobDescription.Purpose}
                    </div>
                )
            },
            {
                className: "awesome-accordion-panel",
                bar: (
                    <div className="awesome-accordion-bar t-brdr-main-btm">
                        <div className="awesome-accordion-bar-text">
                            <h3 className="t-text">My Supervisor</h3>
                            <span className="caption">Who I report to</span>
                        </div>
                    </div>
                ),
                content: (
                    <div className="awesome-accordion-content">
                        {employee.JobDescription.Purpose}
                    </div>
                )
            },
        ]
    }}
    settings={{
        autoClose: true,
    }}
/>
* */
