import { SummaryChart, SummaryParams } from '@models';

export interface PersonsSummaryParams<T> extends SummaryParams<T> {}

export namespace PersonsSummary {
	export enum Chart {
		GetPositionProfiles = 0,
	}

	export interface PersonPositionProfilesSummary
		extends SummaryChart<PersonPositionProfiles> {
		chart: 'GetPositionProfiles';
		number: '0';
	}

	export interface PersonPositionProfiles {
		total_profiles: number;
		total_new_hires: number;
		complete: number;
		percent_complete: number;
		incomplete_profiles: number;
	}

	export interface SummaryReturnType {
		[Chart.GetPositionProfiles]: PersonPositionProfilesSummary;
	}
}
