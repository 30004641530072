import { Formik } from 'formik';

// services
import { WorkItemPayload, WorkItem } from '@api-interfaces';
import { workItemsService } from '@apis';
import { Text } from '@atoms';
import { useAppContext } from '@hooks';
import { Button } from '@new';
import { toasterService } from '@services';

// helpers
import { getSubmitter } from '../../_helpers/getSubmitter';

// components

// interfaces

export const AcceptFeedbackModal = ({
  workItem,
  onAccept,
  onClose,
}: {
  workItem: WorkItem;
  onAccept: (workItem: WorkItem) => void;
  onClose: () => void;
}) => {
  const {
    state: { user },
  } = useAppContext();

  const employee = {
    id: user.employeeId,
    name: user.fullName,
  };

  const submitter = getSubmitter(workItem);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        actiontaken: '',
      }}
      onSubmit={(values, { setSubmitting }) => {
        const params: WorkItemPayload = {
          id: workItem.id,
          status: 'ACCEPTED',
          status_update_date: new Date(),
          status_update_message: '',
          status_update_by: employee.id,
          description: workItem.description,
          sms: false,
          user_phone_number: submitter.phone_number ?? '',
          advisor_phone_number: `${workItem.contract.chatbot_sms_country_code}${workItem.contract.chatbot_sms_phone_number}`,
        };

        workItemsService.patchWorkItem(workItem.id, params).subscribe({
          next: (res: WorkItem) => {
            toasterService.newToast({
              message: 'Occupant Report Accepted',
              status: 'success',
            });
            onAccept(res);
            onClose();
          },
          error: ({ message }) => {
            toasterService.newToast({ status: 'fail', message });
            setSubmitting(false);
          },
        });
      }}
    >
      {({ handleSubmit, isSubmitting }) => (
        <form
          className="tw-space-y-6"
          style={{ marginTop: '-16px' }}
          onSubmit={handleSubmit}
        >
          <Text font="body-md" color="hi-contrast">
            You are about to Accept this Occupant Report.
          </Text>

          <div className="tw-flex tw-justify-end tw-space-x-4">
            <Button label="Cancel" onClick={onClose} color="secondary" />
            <Button
              label="Accept"
              onClick={handleSubmit}
              disabled={isSubmitting}
            />
          </div>
        </form>
      )}
    </Formik>
  );
};

AcceptFeedbackModal.defaultProps = {
  onAccept: () => {},
  onClose: () => {},
};
