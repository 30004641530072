import { Icon, Text } from '@atoms';
import { TimeUtils } from '@helpers';

// components

interface ServiceTimeProps {
  startTime?: string | Date;
  endTime: string | Date;
  className?: string;
}

export const getServiceTimeDuration = (
  startTime: string | Date,
  endTime?: string | Date
) => {
  if (!startTime || !endTime) {
    return 'N/A';
  }
  const duration = TimeUtils.differenceInSeconds(endTime, startTime);
  if (duration < 60) {
    return `${duration} Sec`;
  }
  if (duration < 3600) {
    return `${Math.floor(duration / 60)} Min`;
  }
  return `${Math.floor(duration / 3600)} Hr ${Math.floor(
    (duration % 3600) / 60
  )} Min`;
};

export const ServiceTime = ({
  startTime,
  endTime,
  className,
}: ServiceTimeProps) => {
  const hideStartTime = startTime === undefined;
  return (
    <div className={`tw-flex tw-items-end tw-gap-2 ${className ?? ''}`}>
      {!hideStartTime && (
        <>
          <div>
            <Text color="hi-contrast" font="body-md">
              Start Time
            </Text>
            <Text color="primary" font="h4">
              {TimeUtils.format(startTime, 'h:mm A')}
            </Text>
          </div>
          <Icon
            icon={['fas', 'arrow-right']}
            className="tw-text-theme-primary-500-300 tw-pb-0.5"
          />
          <div>
            <Text color="hi-contrast" font="body-md">
              Service Time
            </Text>
            <Text color="primary" font="h4" className="tw-text-center">
              {getServiceTimeDuration(startTime, endTime)}
            </Text>
          </div>
          <Icon
            icon={['fas', 'arrow-right']}
            className="tw-text-theme-primary-500-300 tw-pb-0.5"
          />
        </>
      )}
      <div>
        <Text color="hi-contrast" font="body-md">
          {hideStartTime ? 'Service Time' : 'End Time'}
        </Text>
        <Text
          color="primary"
          font="h4"
          className={hideStartTime ? 'tw-text-center' : 'tw-text-center'}
        >
          {endTime ? TimeUtils.format(endTime, 'h:mm A') : 'N/A'}
        </Text>
      </div>
    </div>
  );
};
