import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { Employee } from '@api-interfaces';

export const enum GoalText {
	noMoreThan = 'No more than',
	moreThan = 'More than',
	atLeast = 'At least',
}

type QualityKpiKey =
	| 'audits'
	| 'internal_audit'
	| 'joint_audit'
	| 'complaint'
	| 'repeat_complaint'
	| 'escalation'
	| 'compliment'
	| 'reportit'
	| 'survey_score'
	| 'todo'
	| 'work_order'
	| 'total_missed_cleanings'
	| 'total_deviations'
	| 'total_gdp_errors'
	| 'total_sop_violations'
	| 'gmp_associate_audits_percent_complete'
	| 'training_compliance_client_percent_complete'
	| 'periodicals_completed'
	| 'forecast_accuracy'
	| 'business_interruptions'
	| 'compliance_tasks_due'
	| 'contractor_liaisons'
	| 'reliable_delivery'
	| 'jsa_required'
	| 'corporate_audit_findings'
	| 'strategic_initiatives'
	| 'cser_initiatives'
	| 'p1_wo_response'
	| 'wo_timeliness'
	| 'operational_report'
	| 'end_shift_report'
	| 'process_checmical_or_equipment_innovations';

type SafetyKpiKey =
	| 'good_catch'
	| 'recordable_count'
	| 'recordable_days_since'
	| 'work_related_incident_days_since'
	| 'critical_csia_report'
	| 'non_medical_incident_report'
	| 'chemical_mgmt'
	| 'interrupt_operations'
	| 'safety_training';

type PeopleKpiKey =
	| 'attendance'
	| 'conduct'
	| 'employee_scorecard'
	| 'position_profile'
	| 'professionalism'
	| 'request'
	| 'turnover'
	| 'training'
	| 'esat'
	| 'expected_hours_met'
	| 'routes_completed'
	| 'key_badge_credential_audit'
	| 'union_sanctioned_direct_escalations_reach_client';

type OtherKey =
	| 'total_base'
	| 'savings_initiatives'
	| 'proposed_innovations'
	| 'trir'
	| 'atb_charged'
	| 'on_time_atb_quotes'
	| 'impacts_to_production'
	| 'process_checmical_or_equipment_innovations'
	| 'key_badge_credential_audit'
	| 'union_sanctioned_direct_escalations_reach_client'
	| 'on_time_billing'
	| 'quarterly_forecasting'
	| 'cost_avoidance_savings'
	| 'presentation_on_sustainability_initiative'
	| 'present_carbon_negative_initiative'
	| 'true_zero_waste_milestones'
	| 'reporting_accuracy'
	| 'reporting_on_delivery_priorities'
	| 'service_validation'
	| 'presentation_on_technology_initiatives'
	| 'work_order_sla_compliance'
	| 'amazon_wk1_compliance'
	| 'amazon_wk2_compliance'
	| 'amazon_wk3_compliance'
	| 'amazon_wk4_compliance';

type FinancialKpiKey =
	| 'actuals_vs_budget'
	| 'staffing_levels'
	| 'accurate_invoicing'
	| 'weekly_financial_report'
	| 'cost_saving'
	| 'on_time_billing'
	| 'quarterly_forecasting'
	| 'cost_avoidance_savings';

export type KpiConfig = {
	active: boolean;
	criteria: string;
	goal: null | number;
	is_pass: boolean;
	process_met: boolean;
	score: number;
	weight: null | number;
	icon: IconProp;
	title: string;
	oldScore?: number;
	subtitle: string;
	goalType: 'int' | 'percentage';
	goalText: GoalText;
	key:
		| QualityKpiKey
		| SafetyKpiKey
		| PeopleKpiKey
		| FinancialKpiKey
		| OtherKey;
	recommendation?: number;
	useRecommendation?: boolean;
};

export type PartialKpiConfig = Omit<
	KpiConfig,
	| 'active'
	| 'is_pass'
	| 'criteria'
	| 'goal'
	| 'score'
	| 'process_met'
	| 'weight'
>;

export type DepartmentApproval = null | {
	approvedBy: Employee;
	approvalDate: Date;
	isPassing: boolean;
};

export interface Config {
	quality: { departmentApproval: DepartmentApproval; kpis: KpiConfig[] };
	safety: { departmentApproval: DepartmentApproval; kpis: KpiConfig[] };
	people: { departmentApproval: DepartmentApproval; kpis: KpiConfig[] };
	other: { departmentApproval: DepartmentApproval; kpis: KpiConfig[] };
}
