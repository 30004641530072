import { debounce } from 'lodash';
import { useEffect, useRef, useState, useMemo } from 'react';
import * as React from 'react';
import { Subscription, forkJoin, of } from 'rxjs';

// context
import { switchMap } from 'rxjs/operators';

import {
    Employee,
    Person,
    BehavioralAudit,
    BehavioralAuditPayload,
} from '@api-interfaces';

// interfaces

// services
import {
    questionnairesService,
    referenceService,
    behavioralAuditsService,
    employeesService,
    mixpanelService,
} from '@apis';

// components
import { Box, LoaderBlocks, ModalStack, Text } from '@atoms';

import { Details, Audit } from './_components';

import { InputCheckbox } from '@atoms/form-ds2/input-checkbox';
import { FieldInputText } from '@atoms/form-ds2/field-input-text';
import { FieldRadioGroup } from '@atoms/form-ds2/field-radio-group';
import { Label } from '@atoms/form-ds2/label';

// helpers
import { useBreakpoints } from '@hooks';
import { sendError, isValidValue } from '@helpers';
import { useAppContext } from '@hooks';
import { toasterService, refetchService, modalService } from '@services';
import { Modal } from '@templates';
import { BottomSheetContainerProps } from '@templates/modal/bottom-sheet/container/bottom-sheet.container.interfaces';

import {
    GmpAuditModalProvider,
    useGmpAuditsModalContext,
} from './_context/context';
import {
    populateFormFromData,
    updateProfessionalismQuestionnaire,
    isValidForm,
    generatePayload,
    isExemptForm,
} from './helpers';
import { GmpAuditMode } from './interfaces';
import {
    mixpanelPersonnelGMPAuditsCreateChart,
    mixpanelPersonnelGMPAuditsDeleteChart,
    mixpanelPersonnelGMPAuditsEditChart,
} from '@app/mixpanel/MixpanelPageTrack.tsx';

interface Props {
    mode: GmpAuditMode;
    gmpAuditId?: number; // supply if editing
    selectedEmployee?: Employee; // supply if creating new
    onClose: () => void;
}

export const GmpAuditModal = (props: Props) => {
    return (
        <GmpAuditModalProvider>
            <WrappedGmpAuditModal {...props} />
        </GmpAuditModalProvider>
    );
};

const WrappedGmpAuditModal = ({
    mode,
    gmpAuditId,
    selectedEmployee,
    onClose: handleCloseModal,
}: Props) => {
    const { state } = useAppContext();
    const {
        state: {
            isLoading,
            form,
            /* questionnaire, */ attemptSubmit,
            isSubmitting,
            data,
            isEditing,
        },
        dispatch,
    } = useGmpAuditsModalContext();
    // const [tabs, setTabs] = useState<Tab[]>(null); // feature enhancement: tabs
    const formRef = useRef<HTMLDivElement>(null);
    const bp = useBreakpoints();
    const isBpLg = bp.lg;
    const isCreateMode = mode === 'create';
    const [isErrorEventBased, setIsErrorEventBased] = useState<null | boolean>(
        null
    );
    const isErrorOverallComment =
        !isValidValue(form?.overallComment) &&
        (form?.overallComment !== null || attemptSubmit);
    const subscriptions = useRef<{ [key: string]: Subscription }>({});
    const hasEditPermissions = state.user.hasPermission(2, 'GMP Audits');
    const hasDeletePermissions = state.user.hasPermission(3, 'GMP Audits');
    const [isReadOnly, setIsReadOnly] = useState<boolean>(
        !isCreateMode && !isEditing
    );
    const isReadMode = mode === 'read' || isReadOnly;
    const [createdGmpAuditId, setCreatedGmpAuditId] = useState<number>();
    const buttons = [];

    let reviewedPerson: Person;
    if (isCreateMode) reviewedPerson = selectedEmployee?.person;
    if (isReadMode) reviewedPerson = data?.subject?.person;

    useEffect(() => {
        dispatch({ type: 'SET_MODE', payload: mode });

        if (isCreateMode)
            dispatch({
                type: 'SET_SELECTED_EMPLOYEE',
                payload: selectedEmployee,
            });

        subscriptions.current['behavioral-review-init'] = forkJoin([
            referenceService.getItems({
                group_code: 'behavioral_audit_corrective_actions',
            }),
            isReadMode
                ? behavioralAuditsService.getBehavioralAuditById(
                      gmpAuditId || createdGmpAuditId
                  )
                : of(null as BehavioralAudit),
            isCreateMode && selectedEmployee?.id
                ? employeesService.getEmployeeById(selectedEmployee.id)
                : of(null as Employee),
        ])
            .pipe(
                switchMap(([correctiveActions, data, employee]) => {
                    return forkJoin([
                        questionnairesService.getQuestionnaires({
                            name: 'gmp_audit',
                        }),
                        of(correctiveActions),
                        of(data),
                        of(employee),
                    ]);
                })
            )
            .subscribe({
                next: ([questionnaires, correctiveActions, data, employee]) => {
                    let questionnaire = questionnaires?.results?.[0];

                    if (questionnaire) {
                        // if it is an old professionalism audit convert it to the same format as new
                        if (questionnaire.name === 'Professionalism_Audit') {
                            questionnaire =
                                updateProfessionalismQuestionnaire(
                                    questionnaire
                                );
                        }

                        // sort questionnaire section questions by id
                        questionnaire.sections = questionnaire.sections.map(
                            (section) => {
                                section.questions = section.questions.sort(
                                    (a, b) => a.id - b.id
                                );
                                return section;
                            }
                        );

                        dispatch({
                            type: 'SET_QUESTIONNAIRE',
                            payload: questionnaire,
                        });
                    }

                    if (data) {
                        dispatch({ type: 'SET_DATA', payload: data });
                    }

                    const form = populateFormFromData(questionnaire, data); // null data will populate empty form
                    if (isCreateMode && selectedEmployee?.id)
                        form.subjectId = selectedEmployee.id; // populate subjectId provided for create
                    dispatch({ type: 'SET_FORM', payload: form });
                    dispatch({ type: 'SET_IS_LOADING', payload: false });
                },
                error: sendError({
                    toastMessage: 'There was an error retrieving audit data.',
                    callback: () =>
                        dispatch({ type: 'SET_IS_LOADING', payload: false }),
                }),
            });

        return () =>
            Object.values(subscriptions.current).forEach((subscription) =>
                subscription.unsubscribe()
            );
    }, [isReadOnly]);

    // feature enhancement: tabs
    // useEffect(() => {
    //     if (questionnaire?.sections?.length) {
    //         const tabs: Tab[] = [
    //             { label: 'Overview', anchorTag: '#behavioral-review-form-overview' },
    //             ...questionnaire.sections.map(section => ({
    //                 label: section.name,
    //                 anchorTag: `#behavioral-review-form-${section.id}`,
    //             })),
    //         ];

    //         if (!isBpLg) tabs.unshift({ label: 'Details', anchorTag: '#behavioral-review-form-details' });
    //         setTabs(tabs);
    //     }
    // }, [questionnaire]);

    useEffect(() => {
        if (attemptSubmit) validateForm();
    }, [attemptSubmit]);

    function scrollToFirstError(): boolean {
        const elementsWithError = formRef?.current?.querySelectorAll(
            '[data-scroll-error="true"]'
        );
        if (elementsWithError?.[0]) {
            elementsWithError[0].scrollIntoView();
            return true;
        }
        return false;
    }

    const validateForm = () => {
        const hasScrollError = scrollToFirstError();
        if (hasScrollError) return;

        const isValid = isValidForm(form);
        if (!isValid) return;

        const payload = generatePayload(state, form, selectedEmployee?.id);

        if (isEditing) {
            editBehavioralReview(payload);
        } else {
            createBehavioralReview(payload);
        }
    };

    const createBehavioralReview = (payload: BehavioralAuditPayload) => {
        dispatch({ type: 'SET_IS_SUBMITTING', payload: true });

        subscriptions.current['create-behavioral-review'] =
            behavioralAuditsService.createBehavioralAudit(payload).subscribe({
                next: (_res: BehavioralAudit) => {
                    mixpanelPersonnelGMPAuditsCreateChart();
                    setCreatedGmpAuditId(_res.id);
                    dispatch({ type: 'SET_DATA', payload: _res });
                    dispatch({ type: 'SET_MODE', payload: 'read' });
                    dispatch({ type: 'SET_ATTEMPT_SUBMIT', payload: false });
                    toasterService.newToast({
                        status: 'success',
                        message: 'The GMP Audit was successfully submitted!',
                    });
                    refetchService.fetch('behavioral-reviews');
                    setIsReadOnly(true);
                },
                error: sendError({
                    toastMessage:
                        'There was an error submitting the GMP Audit.',
                    callback: () =>
                        dispatch({ type: 'SET_IS_SUBMITTING', payload: false }),
                }),
            });
    };

    const editBehavioralReview = (payload: BehavioralAuditPayload) => {
        subscriptions.current['edit-behavioral-review'] =
            behavioralAuditsService
                .updateBehavioralAuditById(data.id, payload)
                .subscribe({
                    next: (res: BehavioralAudit) => {
                        mixpanelPersonnelGMPAuditsEditChart();
                        dispatch({ type: 'SET_DATA', payload: res });
                        dispatch({ type: 'SET_IS_EDITING', payload: false });
                        dispatch({ type: 'SET_MODE', payload: 'read' });
                        dispatch({
                            type: 'SET_ATTEMPT_SUBMIT',
                            payload: false,
                        });
                        setIsReadOnly(true);
                        toasterService.newToast({
                            status: 'success',
                            message: 'The GMP Audit was successfully updated.',
                        });
                    },
                    error: sendError({
                        toastMessage:
                            'There was an error saving the GMP Audit.',
                    }),
                });
    };

    const deleteBehavioralReview = () => {
        subscriptions.current['delete-behavioral-review'] =
            behavioralAuditsService
                .deleteBehavioralAuditById(data?.id)
                .subscribe({
                    next: () => {
                        mixpanelPersonnelGMPAuditsDeleteChart();
                        refetchService.fetch('behavioral-reviews');
                        modalService.close();
                        handleCloseModal();
                        toasterService.newToast({
                            status: 'success',
                            message: 'GMP Audit was successfully deleted',
                        });
                    },
                    error: sendError(),
                });
    };

    const handleSubmit = () => {
        if (!attemptSubmit)
            dispatch({ type: 'SET_ATTEMPT_SUBMIT', payload: true });
        else validateForm();
    };

    const handleCancel = () => {
        if (isCreateMode) {
            handleCloseModal();
        } else {
            dispatch({ type: 'SET_IS_EDITING', payload: false });
            setIsReadOnly(true);
        }
    };

    const handleClose = () => {
        handleCloseModal();
        refetchService.fetch('behavioral-reviews');
    };

    const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) =>
        dispatch({
            type: 'SET_FORM',
            payload: {
                ...form,
                overallComment: e.target.value,
            },
        });

    const debouncedTextArea = debounce(handleTextAreaChange, 500);

    const overflowItems = useMemo(() => {
        const overflowItems: BottomSheetContainerProps['overflowItems'] = [];

        if (hasEditPermissions) {
            overflowItems.push({
                name: 'Edit',
                onClick: () => {
                    dispatch({ type: 'SET_ATTEMPT_SUBMIT', payload: false });
                    dispatch({ type: 'SET_IS_EDITING', payload: true });
                    setIsReadOnly(false);
                },
            });
        }

        if (hasDeletePermissions) {
            const closePrompt = () => modalService.close();

            overflowItems.push({
                name: 'Delete',
                onClick: () => {
                    modalService.open({
                        component: (
                            <ModalStack
                                position="center"
                                onOverlayClick={closePrompt}
                            >
                                <Modal.Prompt
                                    title="Delete?"
                                    prompt="Are you sure you want to delete this GMP Audit?"
                                    onCancel={closePrompt}
                                    buttons={[
                                        {
                                            label: 'Cancel',
                                            color: 'alternate',
                                            onClick: closePrompt,
                                        },
                                        {
                                            label: 'Delete',
                                            color: 'primary',
                                            onClick: deleteBehavioralReview,
                                        },
                                    ]}
                                />
                            </ModalStack>
                        ),
                        size: 'md',
                        hideCloseButton: true,
                    });
                },
            });
        }

        return overflowItems;
    }, [isLoading]);

    if (!isReadOnly) {
        buttons.push(
            {
                label: 'Cancel',
                onClick: handleCancel,
                type: 'secondary',
                disabled: isSubmitting,
            },
            {
                label: 'Save',
                onClick: () => handleSubmit(),
                type: 'primary',
                disabled: !isValidForm(form) ? isExemptForm(form) : false,
            }
        );
    }

    return !isLoading ? (
        <ModalStack position="bottom-center" onOverlayClick={handleClose}>
            {/* removed `tabs={tabs}` per SLT, feature enhancement */}
            <Modal.BottomSheet.Container
                person={reviewedPerson}
                avatarSize="lg"
                onClose={handleClose}
                buttons={buttons}
                overflowItems={
                    !isCreateMode && hasEditPermissions && overflowItems
                }
            >
                <Modal.BottomSheet.Main>
                    {!isBpLg && <Details />}

                    <div ref={formRef} className="tw-pb-10">
                        <Box
                            id="behavioral-review-form-overview"
                            type="default"
                            className="tw-space-y-6 tw-p-4 tw-mt-4
                                sm:tw-p-10 sm:tw-mt-6
                                lg:tw-mt-0"
                            rounded
                        >
                            <div className="tw-flex tw-justify-between tw-items-center xs:tw-flex-col sm:tw-flex-row xs:tw-items-start">
                                <Text
                                    font="h2"
                                    color="primary"
                                    className="tw-mb-0 xs:tw-mb-4"
                                >
                                    Overview
                                </Text>
                                <div className="tw-flex">
                                    {!isReadOnly ? (
                                        <>
                                            <InputCheckbox
                                                id="is_review_exempt"
                                                onChange={(e) => {
                                                    dispatch({
                                                        type: 'SET_FORM',
                                                        payload: {
                                                            ...form,
                                                            isExempt:
                                                                e.target
                                                                    .checked,
                                                        },
                                                    });
                                                }}
                                                checked={form.isExempt}
                                                className="tw-cursor-pointer tw-mt-0.5 tw-mr-2"
                                            />
                                            <Label
                                                label="Exempt from audit"
                                                htmlFor="is_review_exempt"
                                                className="tw-cursor-pointer "
                                            />
                                        </>
                                    ) : (
                                        <Label
                                            label={
                                                form.isExempt
                                                    ? 'Exempt from Audit'
                                                    : 'Not Exempt from Audit'
                                            }
                                            htmlFor="is_review_exempt"
                                            className={`${
                                                form.isExempt
                                                    ? 'tw-cursor-not-allowed'
                                                    : 'tw-cursor-default'
                                            }`}
                                        />
                                    )}
                                </div>
                            </div>

                            <div>
                                {!isReadOnly && form.isExempt !== true ? (
                                    <div
                                        data-scroll-error={
                                            isErrorEventBased === null &&
                                            form.isEventBased === null
                                        }
                                    >
                                        <FieldRadioGroup
                                            id="type_of_audit"
                                            name="type_of_audit"
                                            label="Type of Audit"
                                            radios={[
                                                {
                                                    value: 'yes',
                                                    label: 'Scheduled',
                                                },
                                                {
                                                    value: 'no',
                                                    label: 'Unscheduled',
                                                },
                                            ]}
                                            onChange={(e) => {
                                                const { value } = e.target;
                                                setIsErrorEventBased(
                                                    value === 'yes'
                                                );
                                                dispatch({
                                                    type: 'SET_FORM',
                                                    payload: {
                                                        ...form,
                                                        isEventBased:
                                                            value === 'yes',
                                                    },
                                                });
                                            }}
                                            disabled={isSubmitting}
                                            defaultChecked={
                                                isEditing &&
                                                form.isEventBased !== null
                                                    ? data?.is_event_based
                                                        ? 'yes'
                                                        : 'no'
                                                    : null
                                            }
                                        />
                                    </div>
                                ) : form.isExempt !== true ? (
                                    <>
                                        <Text
                                            font="body-sm"
                                            color="neutral-offset"
                                            className="tw-mb-2"
                                        >
                                            Type of Audit{' '}
                                            <Text
                                                font="body-sm"
                                                color="primary"
                                                tag="span"
                                            >
                                                *
                                            </Text>
                                        </Text>
                                        <Text
                                            font="body-md"
                                            color="hi-contrast"
                                        >
                                            {data?.is_event_based
                                                ? 'Scheduled'
                                                : 'Unscheduled'}
                                        </Text>
                                    </>
                                ) : null}
                            </div>

                            <div>
                                {!isReadOnly ? (
                                    <div
                                        data-scroll-error={
                                            isErrorOverallComment
                                        }
                                    >
                                        <FieldInputText
                                            required
                                            fieldType="textarea"
                                            id="gmp_audit_comment"
                                            label="Comment"
                                            rows={
                                                bp['2xl'] ? 4 : bp.xs ? 5 : 10
                                            }
                                            onChange={(e) =>
                                                handleTextAreaChange(e)
                                            }
                                            onBlur={(e) => {
                                                handleTextAreaChange(e);
                                                debouncedTextArea.flush();
                                            }}
                                            error={
                                                isErrorOverallComment &&
                                                'Please enter overall comment.'
                                            }
                                            disabled={isSubmitting}
                                            value={form.overallComment}
                                        />
                                    </div>
                                ) : (
                                    <>
                                        <Text
                                            font="body-sm"
                                            color="neutral-offset"
                                            className="tw-mb-2"
                                        >
                                            Comment{' '}
                                            <span className="tw-text-theme-primary-500-300">
                                                *
                                            </span>
                                        </Text>
                                        <Text
                                            font="body-md"
                                            color="hi-contrast"
                                        >
                                            {data?.audit_comment ??
                                                'No comment available.'}
                                        </Text>
                                    </>
                                )}
                            </div>
                        </Box>

                        {!form.isExempt && <Audit isReadOnly={isReadOnly} />}
                    </div>
                </Modal.BottomSheet.Main>

                {isBpLg && <Details />}
            </Modal.BottomSheet.Container>
        </ModalStack>
    ) : (
        <ModalStack position="bottom-center" onOverlayClick={handleCloseModal}>
            <Modal.BottomSheet.Container
                person={reviewedPerson}
                onClose={handleCloseModal}
            >
                <LoaderBlocks className="tw-w-full tw-py-10" />
            </Modal.BottomSheet.Container>
        </ModalStack>
    );
};
