import { Link as RouterLink } from '@reach/router';
import * as React from 'react';

// interfaces
import { LinkProps } from './link.interfaces';

import './link.css';

export const Link: React.FC<LinkProps> = ({
  className,
  type,
  as,
  disabled,
  ...props
}) => {
  if (as === 'button') {
    return (
      <button
        className={`link link-${type} tw-cursor-pointer tw-font-bold tw-shadow-none ${className}`}
        data-disabled={disabled}
        {...props}
      />
    );
  }

  if (as === 'div') {
    return (
      <div
        className={`link link-${type} tw-cursor-pointer tw-font-bold tw-shadow-none ${className}`}
        data-disabled={disabled}
        {...props}
      />
    );
  }

  if (as === 'link') {
    return (
      // @ts-ignore
      <RouterLink
        className={`link link-${type} tw-cursor-pointer tw-font-bold tw-shadow-none ${className}`}
        data-disabled={disabled}
        {...props}
      />
    );
  }
};

Link.defaultProps = {
  className: '',
  type: 'primary',
  as: 'button',
};
