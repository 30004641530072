// services
import {
	GetVerifiableAreasParams,
	VerifiableArea,
	servicesValidatedCounts,
} from '@api-interfaces';
import { Params } from '@helpers';
import { PaginationResponse } from '@models';
import { virtualBrownClient } from '@services';

// helpers

// interfaces

const BASE_URL = 'routes';

class RoutesVerifiableAreasService {
	getVerifiableAreas(params: GetVerifiableAreasParams) {
		return virtualBrownClient.get<PaginationResponse<VerifiableArea>>(
			`${BASE_URL}/verifiable_areas/${Params.makeQueryString(params || {})}`,
			{
				noContract: params.contract != undefined,
				noCustomer: params.customer != undefined,
				noTag: params.tag != undefined,
			}
		);
	}

	getServicesValidatedCounts(params: GetVerifiableAreasParams) {
		return virtualBrownClient.get<PaginationResponse<servicesValidatedCounts>>(
			`${BASE_URL}/services_validated_counts/${Params.makeQueryString(
				params || {}
			)}`,
			{
				noContract: params.contract != undefined,
				noCustomer: params.customer != undefined,
				noTag: params.tag != undefined,
			}
		);
	}
}

export const routesVerifiableAreasService = new RoutesVerifiableAreasService();
