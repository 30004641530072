import { Observable } from 'rxjs';

// services
import {
	SafetyIncidentsSummary,
	SafetyIncidentsSummaryParams,
} from '@api-interfaces';
import { virtualBrownClient } from '@core/services/django-client';

// helpers
import { Params } from '@helpers';

// interfaces

// constants
const BASE_URL = 'safetyincidents/summary';

const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

class SafetyIncidentsSummaryService {
	getSummaries<T extends SafetyIncidentsSummary.Chart>(
		params: SafetyIncidentsSummaryParams<T>
	): Observable<SafetyIncidentsSummary.SummaryReturnType[T][]> {
		const query = {
			...params,
			chart: params.chart.join(','),
			time_zone: params.time_zone || timeZone,
		};

		return virtualBrownClient.get(
			`${BASE_URL}/${Params.makeQueryString(query)}`,
			{
				noCustomer: params.customer !== undefined,
				noContract: params.contract !== undefined,
				noTag: params.tag !== undefined,
			}
		);
	}
}

export const safetyIncidentsSummaryService =
	new SafetyIncidentsSummaryService();
