import { Text } from '@atoms';

// interfaces
import { BottomSheetDetailsContentProps } from './bottom-sheet.details-content.interfaces';

export const BottomSheetDetailsContent = ({
  label,
  content,
  className,
}: BottomSheetDetailsContentProps) => {
  return (
    <div className={`tw-flex tw-justify-between ${className}`}>
      <Text font="h4" color="neutral-offset" className="tw-w-2/5">
        {label}
      </Text>
      {typeof content === 'string' ? (
        <Text
          font="body-md"
          color="hi-contrast"
          className="tw-truncate tw-w-3/5"
        >
          {content}
        </Text>
      ) : (
        <div className="tw-w-3/5">{content}</div>
      )}
    </div>
  );
};

BottomSheetDetailsContent.defaultProps = {
  className: '',
};
