import { WorkOrderStatusKey } from '@api-interfaces';
import { BadgeProps } from '@atoms/badge/badge.interfaces';

export const workOrderStatusBadgeColors: {
	[Key in WorkOrderStatusKey]: BadgeProps['color'];
} = {
	ASSIGNED: 'primary',
	DRAFT: 'neutral',
	SUBMITTED: 'neutral',
	PENDING: 'neutral',
	WORK_COMPLETE: 'primary',
	CLOSED: 'success',
	REJECTED: 'purple',
	BILLED: 'success',
	PAST_DUE: 'danger',
	IS_PROJECT_WORK: 'purple',
};

export const workOrderStatusDescription: {
	[Key in WorkOrderStatusKey]: string;
} = {
	ASSIGNED: 'Work (To-Dos) has been assigned and has not been completed.',
	DRAFT: 'Missing required information needed to create Work Order',
	SUBMITTED:
		'Customer work order request that is pending acceptance or rejection.',
	PENDING: 'This work order is accepted, but no To-Dos have been created.',
	WORK_COMPLETE: 'All assigned work has been marked complete',
	CLOSED:
		'Site Manager has signed off on Work Order. No more work can be assigned.',
	REJECTED: 'The Site Manager has rejected the Work Order request.',
	BILLED: 'This work order has been billed to the customer.',
	PAST_DUE: 'The expected due date for this work order has past.',
	IS_PROJECT_WORK: 'This work order is part of a project.',
};
