import { useState } from 'react';

// interfaces
import { TrackerResponseStopArea } from '@api-interfaces';

// components
import { ModalStack, Text } from '@atoms';
import { ServiceValidationStop, ServiceValidationStopProps } from '@new';
import { Modal } from '@templates';

type ServiceStopLogProps = Omit<
    ServiceValidationStopProps,
    'className' | 'isFullHeight' | 'setArea'
> & {
    onClose: () => void;
};

export const ServiceStopLogModal = ({
    onClose,
    ...stopProps
}: ServiceStopLogProps) => {
    const [area, setArea] = useState<TrackerResponseStopArea>();

    const buildingName = area?.root_area_name;
    const floorName = area?.name;
    const areaType = area?.type;

    return (
        <ModalStack position="center" onOverlayClick={onClose}>
            <Modal.Generic
                title={area?.name}
                subtitle={
                    <Text
                        font="body-md"
                        color="neutral-offset"
                        className="tw-flex tw-gap-2 tw-flex-wrap tw-mt-1"
                    >
                        <span>{areaType}</span>
                        <span>·</span>
                        <span>{buildingName}</span>
                        <span>·</span>
                        <span>Floor {floorName}</span>
                    </Text>
                }
                className="tw-w-full sm:tw-w-[640px]"
                onCancel={onClose}
            >
                <ServiceValidationStop {...stopProps} setArea={setArea} />
            </Modal.Generic>
        </ModalStack>
    );
};
