import { isEqual } from 'lodash';
import { Context, Dispatch, lazy } from 'react';
import * as React from 'react';

import { LeastRecentKeyQueue } from './queue';

export const dynamic = (resolver: any, name: string = 'default') => {
  return lazy(async () => {
    const resolved = await resolver();
    return { default: resolved[name] };
  });
};

// useReducer context helper
// https://gist.github.com/sw-yx/f18fe6dd4c43fddb3a4971e80114a052
export function createCtx<StateType, ActionType>(
  reducer: React.Reducer<StateType, ActionType>,
  initialState: StateType,
  init?: (StateType: StateType, ProviderProps?: Partial<StateType>) => StateType
) {
  const defaultDispatch: React.Dispatch<ActionType> = () => initialState; // we never actually use this
  const ctx = React.createContext({
    state: initialState,
    dispatch: defaultDispatch, // just to mock out the dispatch type and make it mandatory
  });
  const Provider = (
    props: React.PropsWithChildren<{ defaultState?: Partial<StateType> }>
  ) => {
    const [state, dispatch] = React.useReducer<
      React.Reducer<StateType, ActionType>
    >(reducer, init ? init(initialState, props?.defaultState) : initialState);
    return <ctx.Provider value={{ state, dispatch }} {...props} />;
  };
  return [ctx, Provider] as const;
}

// Be careful if using outside of the helper
export interface WrappingState<Action> {
  wrappingDispatch: Dispatch<Action>;
}

export type WrappedActions<
  WrappedStateType,
  WrappingActionType /* | WrappedActionType */,
> =
  | {
      type: 'SET_WRAPPED_STATE';
      payload: WrappedStateType;
    }
  | {
      type: 'SET_WRAPPING_DISPATCH';
      payload: Dispatch<WrappingActionType>;
    };

// Make sure the wrapped context returns the initial state in default cases
export const createWrappingCtx = <
  WrappingStateType extends WrappedStateType,
  WrappingActionType /* | WrappedActionType */,
  WrappedStateType extends object,
  WrappedActionType,
>(
  reducer: React.Reducer<WrappingStateType, WrappingActionType>,
  initialState: WrappingStateType,
  innerContext: Context<{
    state: WrappedStateType;
    dispatch: Dispatch<WrappedActionType>;
  }>,
  // For possible async calls
  onSetWrappedState?: (
    wrappingDispatch: React.Dispatch<WrappingActionType>,
    wrappedState: WrappedStateType,
    wrappingState: WrappingStateType
  ) => void
) => {
  type FullWrappingActionType = WrappingActionType &
    WrappedActions<WrappedStateType, WrappingActionType>;
  type FullWrappingStateType = WrappingStateType &
    WrappingState<FullWrappingActionType>;

  const wrappingInitialState: FullWrappingStateType = {
    ...initialState,
    // For possible async calls
    wrappingDispatch: () => {},
  };

  const actionQueue = new LeastRecentKeyQueue<string, WrappedActionType>();
  const wrappingReducer = (
    state: FullWrappingStateType,
    action: FullWrappingActionType
  ): Readonly<FullWrappingStateType> => {
    if (action.type === 'SET_WRAPPED_STATE') {
      const newState = {
        ...state,
        ...action.payload,
      };
      return isEqual(state, newState) ? state : newState;
    }
    if (action.type === 'SET_WRAPPING_DISPATCH') {
      return {
        ...state,
        wrappingDispatch: action.payload,
      };
    }
    actionQueue.enqueue(action.type, action);
    return {
      ...state,
      ...reducer(state, action),
    };
  };
  const wrappingContext = React.createContext({
    state: initialState,
    dispatch: (() =>
      wrappingInitialState) as React.Dispatch<WrappingActionType>,
  });
  const WrappingProvider = (props: React.PropsWithChildren<{}>) => {
    const [wrappingState, wrappingDispatch] = React.useReducer<
      React.Reducer<FullWrappingStateType, FullWrappingActionType>
    >(wrappingReducer, wrappingInitialState);
    const { state: wrappedState, dispatch: wrappedDispatch } =
      React.useContext(innerContext);

    React.useEffect(() => {
      wrappingDispatch({
        type: 'SET_WRAPPING_DISPATCH',
        payload: wrappingDispatch,
      } as any);
    }, [wrappingDispatch]);

    React.useEffect(() => {
      wrappingDispatch({
        type: 'SET_WRAPPED_STATE',
        payload: wrappedState,
      } as any);
      onSetWrappedState &&
        onSetWrappedState(wrappingDispatch, wrappedState, wrappingState);
    }, [wrappedState]);

    React.useEffect(() => {
      while (!actionQueue.isEmpty()) {
        wrappedDispatch(actionQueue.dequeue());
      }
    }, [wrappingState]);

    return (
      <wrappingContext.Provider
        value={{ state: wrappingState, dispatch: wrappingDispatch }}
        {...props}
      />
    );
  };
  return [wrappingContext, WrappingProvider] as const;
};

// @TODO - move somewhere else, does not belong in file
export const detectmob = () => {
  if (
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i)
  ) {
    return true;
  }
  return false;
};
