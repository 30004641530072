import { mixpanelService } from '@apis';
import { useMixpanel } from '@hooks';

// Mixpanel Event Flags
// Add Mixpanel functions, then import and call in desired file.
// Make sure to add Mixpanel function to the correct section below.

// ----------------------Created Events---------------------

export const mixpanelSuccessfulLogin = () => {
    mixpanelService.postMixpanel('Successful_Login');
};

export const mixpanelQualityAuditFinalized = () => {
    mixpanelService.postMixpanel('Added_Finalized_Quality_Audit');
};

export const mixpanelRouteManagerCreate = () => {
    mixpanelService.postMixpanel('Added_Route_Manager');
};

export const mixpanelCreateSafetyIncident = () => {
    mixpanelService.postMixpanel('Created_Safety_Incident');
};

export const mixpanelCommsCenterCreate = (type: string) => {
    if (type === 'news') {
        mixpanelService.postMixpanel('Created_Communication_Center_News');
    } else if (type === 'releases') {
        mixpanelService.postMixpanel(
            'Created_Communication_Center_Release_Note'
        );
    } else {
        mixpanelService.postMixpanel('Created_Communication_Center_Message');
    }
};

export const mixpanelCreateQualityComplaint = () => {
    mixpanelService.postMixpanel('Created_Quality_Complaint');
};

export const mixpanelCreateQualityCompliment = () => {
    mixpanelService.postMixpanel('Created_Quality_Compliment');
};

export const mixpanelCreatePersonnelConductType = (type) => {
    mixpanelService.postMixpanel(`Created_Personnel_Conduct_${type}`);
};

export const mixpanelCreatePersonnelProfessionalismAudit = () => {
    mixpanelService.postMixpanel('Created_Personnel_Professionalism_Audit');
};

export const mixpanelCreatePersonnelAttendance = () => {
    mixpanelService.postMixpanel(`Created_Personnel_Attendance`);
};

export const mixpanelCreateQualityReportIts = () => {
    mixpanelService.postMixpanel(`Created_Quality_Report_Its`);
};

export const mixpanelCreatePersonnelRequest = () => {
    mixpanelService.postMixpanel(`Created_Personnel_Request`);
};

export const mixpanelCreateQualityTodo = () => {
    mixpanelService.postMixpanel(`Created_Quality_Todo`);
};

export const mixpanelPersonnelGMPAuditsCreateChart = () => {
    mixpanelService.postMixpanel('Created_Personnel_GMP_Audits_Chart');
};

export const mixpanelCreateUpdateWorkOrder = (value) => {
    if (value === true) {
        mixpanelService.postMixpanel('Created_Quality_WorkOrder');
    } else {
        mixpanelService.postMixpanel('Updated_Quality_WorkOrder');
    }
};

export const mixpanelCreateSafetyIncidentInvestigation = () => {
    mixpanelService.postMixpanel('Created_Safety_Incident_Investigation');
};

export const mixpanelCreateSafetyIncidentClaimsInvestigation = () => {
    mixpanelService.postMixpanel(
        'Created_Safety_Incident_Claims_Investigation'
    );
};

export const mixpanelAcceptedReportItSuccessfully = () => {
    mixpanelService.postMixpanel('Accepted_Report_It_Successfully');
};

// ----------------------Viewed Events----------------------

export const useMixpanelManagerQueue = () => {
    useMixpanel('Viewed_Manager_Queue');
};

export const mixpanelManagerQueueTodo = () => {
    mixpanelService.postMixpanel('Viewed_Manger_Queue_Todos');
};

export const mixpanelManagerQueueComplaints = () => {
    mixpanelService.postMixpanel('Viewed_Manger_Queue_Complaints');
};

export const mixpanelManagerQueueReportIts = () => {
    mixpanelService.postMixpanel('Viewed_Manger_Queue_Report_Its');
};

export const mixpanelManagerQueueRequest = () => {
    mixpanelService.postMixpanel('Viewed_Manger_Queue_Requests');
};

export const mixpanelManageShiftHours = () => {
    mixpanelService.postMixpanel('Viewed_Manage_Shift_Hours');
};

export const mixpanelIncompleteRoute = () => {
    mixpanelService.postMixpanel('Viewed_Incomplete_Routes_Details');
};

export const mixpanelAttestation = () => {
    mixpanelService.postMixpanel('Viewed_Attestation');
};

export const mixpanelPersonnelAttendanceCharts = () => {
    mixpanelService.postMixpanel('Viewed_Personnel_Attendance_Charts');
};

export const mixpanelPersonnelAttendanceDetails = () => {
    mixpanelService.postMixpanel('Viewed_Personnel_Attendance_Details');
};

export const mixpanelPersonnelConductCharts = () => {
    mixpanelService.postMixpanel('Viewed_Personnel_Conduct_Charts');
};

export const mixpanelPersonnelConductDetails = () => {
    mixpanelService.postMixpanel('Viewed_Personnel_Conduct_Details');
};

export const mixpanelPersonnelSep = () => {
    mixpanelService.postMixpanel('Viewed_Personnel_Employee_ScoreCard');
};

export const mixpanelPersonnelPositionProfile = () => {
    mixpanelService.postMixpanel('Viewed_Personnel_Position_Profile');
};

export const mixpanelPersonnelRequest = () => {
    mixpanelService.postMixpanel('Viewed_Personnel_Request_Details');
};

export const mixpanelPersonnelTeamStatus = () => {
    mixpanelService.postMixpanel('Viewed_Personnel_Team_Status');
};

export const mixpanelPersonnelTurnover = () => {
    mixpanelService.postMixpanel('Viewed_Personnel_Turn_Over');
};

export const mixpanelSowManager = () => {
    mixpanelService.postMixpanel('Viewed_Sow_Manager');
};

export const mixpanelSowManagerCoverage = () => {
    mixpanelService.postMixpanel('Viewed_Sow_Manager_Coverage');
};

export const useMixpanelPeopleEsatSurveysCharts = () => {
    useMixpanel('Viewed_People_ESAT_Surveys_Charts');
};

export const useMixpanelPeopleEsatSurveysDetails = () => {
    useMixpanel('Viewed_People_ESAT_Surveys_Details');
};

export const useMixpanelFinancialsSuppliesDetails = () => {
    useMixpanel('Viewed_Financials_Supplies_Details');
};

export const useMixpanelFinancialsSuppliesCharts = () => {
    useMixpanel('Viewed_Financials_Supplies_Charts');
};

export const useMixpanelFinancialsInvoices = () => {
    useMixpanel('Viewed_Financials_Invoices');
};

export const useMixpanelFinancialsHours = () => {
    useMixpanel('Viewed_Financials_Hours');
};

export const useMixpanelFinancialsHoursEmployeeTimeSheet = () => {
    useMixpanel('Viewed_Financials_Employee_Time_Sheet');
};

export const useMixpanelFinancialsCostCalculator = () => {
    useMixpanel('Viewed_Financials_Cost_Calculator');
};

export const useMixpanelFinancialsBillingDetails = () => {
    useMixpanel('Viewed_Financials_Billing_Details');
};

export const useMixpanelFinancialsBillingCharts = () => {
    useMixpanel('Viewed_Financials_Billing_Charts');
};

export const useMixpanelFinancialsAssetManagementOverview = () => {
    useMixpanel('Viewed_Financials_Asset_Management_Overview');
};

export const useMixpanelFinancialsAssetManagementInspections = () => {
    useMixpanel('Viewed_Financials_Asset_Management_Inspections');
};

export const useMixpanelFinancialsAssetManagementAsset = () => {
    useMixpanel('Viewed_Financials_Asset_Management_Single_Asset');
};

export const useMixpanelFinancialsAssetManagementAssetDoc = () => {
    useMixpanel('Viewed_Financials_Asset_Management_Single_Asset_Document');
};

export const useMixpanelFinancialsAssetManagementHistory = () => {
    useMixpanel('Viewed_Financials_Asset_Management_Single_Asset_History');
};

export const useMixpanelFinancialsAssetManagementAssetInspections = () => {
    useMixpanel('Viewed_Financials_Asset_Management_Single_Asset_Inspections');
};

export const mixpanelQualityServiceValidation = () => {
    mixpanelService.postMixpanel('Viewed_Quality_Service_Validation_Tracker');
};

export const useMixpanelOverviewSiteScorecards = () => {
    useMixpanel('Viewed_Overview_Site_Scorecards');
};

export const mixpanelOverviewOrgChartArchivedHierarchyChart = () => {
    mixpanelService.postMixpanel(
        'Viewed_Overview_Org_Chart_Archived_Hierarchy_Chart'
    );
};

export const useMixpanelOverviewOrgChartResponsibilityMatrix = () => {
    useMixpanel('Viewed_Overview_Org_Chart_Responsibility_Matrix');
};

export const mixpanelFinancialsCostOverview = () => {
    mixpanelService.postMixpanel('Viewed_Financials_Cost_Overview');
};

export const useMixpanelOverviewOrgChartResponsibilityMatrixMulti = () => {
    useMixpanel('Viewed_Overview_Org_Chart_Responsibility_Matrix_Multi_Site');
};

export const useMixpanelOverviewOrgChartHierarchyChart = () => {
    useMixpanel('Viewed_Overview_Org_Chart_Hierarchy_Chart');
};

export const useMixpanelSowManagerCompliance = () => {
    useMixpanel('Viewed_SOW_Manager_Compliance');
};

export const useMixpanelOverviewMaps = () => {
    useMixpanel('Viewed_Overview_Maps');
};

export const useMixpanelOverviewPresentation = () => {
    useMixpanel('Viewed_Presentation');
};

export const useMixpanelAttendanceTrackerDetails = () => {
    useMixpanel('Viewed_Attendance_Tracker_Details');
};

export const useMixpanelAttendanceTrackerMap = () => {
    useMixpanel('Viewed_Attendance_Tracker_Map');
};

export const mixpanelQualityComplaintsCharts = () => {
    mixpanelService.postMixpanel('Viewed_Quality_Complaints_Chart');
};

export const mixpanelQualityComplaintsDetails = () => {
    mixpanelService.postMixpanel('Viewed_Quality_Complaints_Details');
};

export const mixpanelQualityComplimentsCharts = () => {
    mixpanelService.postMixpanel('Viewed_Quality_Compliments_Chart');
};

export const mixpanelQualityComplimentsDetails = () => {
    mixpanelService.postMixpanel('Viewed_Quality_Compliments_Details');
};

export const mixpanelQualityOccupantReportsCharts = () => {
    mixpanelService.postMixpanel('Viewed_Quality_Occupant_Reports_Chart');
};

export const mixpanelQualityOccupantReportsDetails = () => {
    mixpanelService.postMixpanel('Viewed_Quality_Occupant_Reports_Details');
};

export const useMixpanelQualityReportItCharts = () => {
    useMixpanel('Viewed_Quality_Occupant_Report_It_Charts');
};

export const useMixpanelQualityReportItDetails = () => {
    useMixpanel('Viewed_Quality_Occupant_Report_It_Details');
};

export const useMixpanelQualityServiceValidationCharts = () => {
    useMixpanel('Viewed_Quality_Service_Validation_Charts');
};

export const useMixpanelQualitySurveysCharts = () => {
    useMixpanel('Viewed_Quality_Surveys_Charts');
};

export const useMixpanelQualitySurveysDetails = () => {
    useMixpanel('Viewed_Quality_Surveys_Details');
};

export const useMixpanelQualityTodoCharts = () => {
    useMixpanel('Viewed_Quality_Todo_Charts');
};

export const useMixpanelQualityTodoDetails = () => {
    useMixpanel('Viewed_Quality_Todo_Details');
};

export const useMixpanelQualityWorkOrderCharts = () => {
    useMixpanel('Viewed_Quality_Work_Order_Chart');
};

export const useMixpanelQualityWorkOrderDetails = () => {
    useMixpanel('Viewed_Quality_Work_Order_Details');
};

export const useMixpanelRouteManager = () => {
    useMixpanel('Viewed_Route_Manager');
};

export const useMixpanelIncompleteRoutes = () => {
    useMixpanel('Viewed_Incomplete_Routes_Manager');
};

export const useMixpanelSafetyGoodCatchesCharts = () => {
    useMixpanel('Viewed_Safety_Good_Catches_Charts');
};

export const useMixpanelSafetyGoodCatchesDetails = () => {
    useMixpanel('Viewed_Safety_Good_Catches_Details');
};

export const useMixpanelSafetyIncidentsCharts = () => {
    useMixpanel('Viewed_Safety_Incidents_Charts');
};

export const useMixpanelSafetyIncidentsRecordablesCharts = () => {
    useMixpanel('Viewed_Safety_Incidents_Recordables_Charts');
};

export const useMixpanelSafetyIncidentsClaimsCharts = () => {
    useMixpanel('Viewed_Safety_Incidents_Claims_Charts');
};

export const mixpanelSafetyIncidentsDetails = () => {
    mixpanelService.postMixpanel('Viewed_Safety_Incidents_Details');
};

export const useMixpanelPeopleTrainingAccreditTranscripts = () => {
    useMixpanel('Viewed_People_Training_Accredit_Transcripts');
};

export const useMixpanelPeopleTrainingAssignmentManager = () => {
    useMixpanel('Viewed_People_Assignment_Manager');
};

export const useMixpanelPeopleTrainingAssignmentManagerCourses = () => {
    useMixpanel('Viewed_People_Assignment_Manager_Courses');
};

export const useMixpanelPeopleTrainingAssignmentManagerSkills = () => {
    useMixpanel('Viewed_People_Training_Assignment_Manager_Skills');
};

export const useMixpanelPeopleTrainingCourseLibrary = () => {
    useMixpanel('Viewed_People_Training_Course_Library');
};

export const useMixpanelPeopleTrainingTranscripts = () => {
    useMixpanel('Viewed_People_Training_Transcripts');
};

export const mixpanelMyProfile = () => {
    mixpanelService.postMixpanel('Viewed_My_Profile');
};

export const useMixpanelQualityAuditsSpecificAudit = () => {
    useMixpanel('Viewed_Quality_Audits_Specific_Audit');
};

export const useMixpanelQualityAuditsSpecificInspection = () => {
    useMixpanel('Viewed_Quality_Audits_Specific_Inspection');
};

export const mixpanelQualitySpecificOccupantReport = () => {
    mixpanelService.postMixpanel('Viewed_Quality_Specific_Occupant_Report');
};

export const mixpanelServiceValidationHistory = () => {
    mixpanelService.postMixpanel('Viewed_Service_Validation_History');
};

export const useMixpanelQualitySpecificWorkOrder = () => {
    useMixpanel('Viewed_Quality_Specific_Work_Order');
};

export const mixpanelSpecificComplaint = () => {
    mixpanelService.postMixpanel('Viewed_Specific_Complaint');
};

export const useMixpanelSpecificCompliment = () => {
    useMixpanel('Viewed_Specific_Compliment');
};

export const mixpanelSpecificSafetyIncident = () => {
    mixpanelService.postMixpanel('Viewed_Specific_Safety_Incident');
};

export const useMixpanelViewSpecificCommsMessage = () => {
    useMixpanel('Viewed_Specific_Comms_Center_Message');
};

export const useMixpanelViewSpecificCommsNews = () => {
    useMixpanel('Viewed_Specific_Comms_Center_News');
};

export const useMixpanelViewSpecificReportItsReport = () => {
    useMixpanel('Viewed_Specific_Report_Its_Report');
};

export const useMixPanelViewSpecificQualitySurvey = () => {
    useMixpanel('Viewed_Specific_Quality_Survey');
};

export const useMixpanelViewSpecificQualityTodo = () => {
    useMixpanel('Viewed_Specific_Quality_Todo');
};

export const mixpanelQualityOriginalSow = () => {
    mixpanelService.postMixpanel('Viewed_Quality_Original_SOW');
};

export const mixpanelAnalyticsType = (type) => {
    if (type === 'desktop-web') {
        mixpanelService.postMixpanel('Viewed_Analytics_Desktop_Web');
    } else {
        mixpanelService.postMixpanel('Viewed_Analytics_Associate_Native');
    }
};

export const useMixpanelNavClassic = () => {
    useMixpanel('Viewed_Classic_Nav');
};

export const useMixpanelNavSingle = () => {
    useMixpanel('Viewed_Single_Nav');
};

export const useMixpanelNavNew = () => {
    useMixpanel('Viewed_New_Nav');
};

export const mixpanelPeopleGMPAuditsCharts = () => {
    mixpanelService.postMixpanel('Viewed_People_GMP_Audits_Charts');
};

export const mixpanelPeopleGMPAuditsDetails = () => {
    mixpanelService.postMixpanel('Viewed_People_GMP_Audits_Details');
};

export const mixpanelPeopleProAuditsCharts = () => {
    mixpanelService.postMixpanel('Viewed_People_Professionalism_Audits_Charts');
};

export const mixpanelPeopleProAuditsDetails = () => {
    mixpanelService.postMixpanel(
        'Viewed_People_Professionalism_Audits_Details'
    );
};

export const mixpanelPersonnelRequestCharts = () => {
    mixpanelService.postMixpanel('Viewed_People_Personnel_Request_Charts');
};

export const mixpanelPersonnelEmployeeScorecardFinalize = () => {
    mixpanelService.postMixpanel(
        'Viewed_Personnel_Employee_Scorecard_Finalize'
    );
};

export const mixpanelQualityInspectionsCharts = () => {
    mixpanelService.postMixpanel('Viewed_Quality_Inspections_Charts');
};

export const mixpanelQualityInspectionsDetails = () => {
    mixpanelService.postMixpanel('Viewed_Quality_Inspections_Details');
};

export const mixpanelQualityAuditsCharts = () => {
    mixpanelService.postMixpanel('Viewed_Quality_Audits_Charts');
};

export const mixpanelQualityAuditsDetails = () => {
    mixpanelService.postMixpanel('Viewed_Quality_Audits_Details');
};

export const mixpanelPeopleRoutesAreaDefinition = () => {
    mixpanelService.postMixpanel('Viewed_People_Routes_Area_Definition');
};

export const mixpanelFinancialsBillingInvoice = () => {
    mixpanelService.postMixpanel('Viewed_Financials_Billing_Invoice');
};

export const mixpanelEmployeesOnStaff = () => {
    mixpanelService.postMixpanel('Viewed_Employees_On_Staff');
};

export const mixpanelRecordables = () => {
    mixpanelService.postMixpanel('Viewed_Recordables');
};

export const mixpanelTurnover = () => {
    mixpanelService.postMixpanel('Viewed_Turnover');
};

export const mixpanelHyperLauncher = () => {
    mixpanelService.postMixpanel('Viewed_Hyper_Launcher');
};

export const mixpanelMenuNavMyProfile = () => {
    mixpanelService.postMixpanel('Viewed_Menu_Nav_My_Profile');
};

export const mixpanelMenuNavSiteSettings = () => {
    mixpanelService.postMixpanel('Viewed_Menu_Nav_Site_Settings');
};

export const mixpanelMenuNavUserSettings = () => {
    mixpanelService.postMixpanel('Viewed_Menu_Nav_User_Settings');
};

export const mixpanelMenuNavAdmin = () => {
    mixpanelService.postMixpanel('Viewed_Menu_Nav_Admin');
};

export const mixpanelMenuNavToolbox = () => {
    mixpanelService.postMixpanel('Viewed_Menu_Nav_Toolbox');
};

export const mixpanelMenuNavAnalytics = () => {
    mixpanelService.postMixpanel('Viewed_Menu_Analytics');
};

export const mixpanelMenuNavToggleTheme = () => {
    mixpanelService.postMixpanel('Viewed_Menu_Nav_Toggle_Theme');
};

export const mixpanelMenuNavToggleNavigation = () => {
    mixpanelService.postMixpanel('Viewed_Menu_Nav_Toggle_Navigation');
};

export const mixpanelSitePickerChangeOrg = () => {
    mixpanelService.postMixpanel('Viewed_Site_Picker_Change_Org');
};

export const mixpanelQuickEntryRunAudit = () => {
    mixpanelService.postMixpanel('Viewed_Quick_Entry_Run_Audit');
};

export const mixpanelQuickEntryTodo = () => {
    mixpanelService.postMixpanel('Viewed_Quick_Entry_Todo');
};

export const mixpanelQuickEntryComplaint = () => {
    mixpanelService.postMixpanel('Viewed_Quick_Entry_Complaint');
};

export const mixpanelQuickEntryCompliment = () => {
    mixpanelService.postMixpanel('Viewed_Quick_Entry_Complement');
};
export const mixpanelQuickEntrySurvey = () => {
    mixpanelService.postMixpanel('Viewed_Quick_Entry_Survey');
};

export const mixpanelQuickEntryReportIt = () => {
    mixpanelService.postMixpanel('Viewed_Quick_Entry_Report_It');
};

export const mixpanelquickEntryWorkOrder = () => {
    mixpanelService.postMixpanel('Viewed_Quick_Entry_Work_Order');
};

export const mixpanelQuickEntryAttendanceOccurrence = () => {
    mixpanelService.postMixpanel('Viewed_Quick_Entry_Attendance_Occurrence');
};

export const mixpanelQuickEntryConductOccurrence = () => {
    mixpanelService.postMixpanel('Viewed_Quick_Entry_Conduct_Occurrence');
};

export const mixpanelQuickEntryProReview = () => {
    mixpanelService.postMixpanel('Viewed_Quick_Entry_Professionalism_Reviews');
};

export const mixpanelQuickEntryRequest = () => {
    mixpanelService.postMixpanel('Viewed_Quick_Entry_Request');
};

export const mixpanelQuickEntryRoute = () => {
    mixpanelService.postMixpanel('Viewed_Quick_Entry_Route');
};

export const mixpanelQuickEntryGoodCatch = () => {
    mixpanelService.postMixpanel('Viewed_Quick_Entry_Good_Catch');
};

export const mixpanelQuickEntryIncident = () => {
    mixpanelService.postMixpanel('Viewed_Quick_Entry_Incident');
};

export const mixpanelQuickEntryMessage = () => {
    mixpanelService.postMixpanel('Viewed_Quick_Entry_Message');
};

export const mixpanelQuickEntryNewsBlast = () => {
    mixpanelService.postMixpanel('Viewed_Quick_News_Blast');
};

export const mixpanelCommsCenterView = (tab) => {
    mixpanelService.postMixpanel(`Viewed_Communication_Center_View-${tab}`);
};

export const mixpanelCommsCenterSidePanel = () => {
    mixpanelService.postMixpanel('Viewed_Communication_Center_Side_Panel');
};

export const mixpanelCommsCenterMessage = () => {
    mixpanelService.postMixpanel('Viewed_Communication_Center_Message');
};

export const useMixpanelCommsCenterReleaseNotes = () => {
    useMixpanel('Viewed_Communication_Center_Release_Notes');
};

export const mixpanelSinglePageFinancialsBillingPlatform = () => {
    mixpanelService.postMixpanel(
        'Viewed_Single_Page_Financials_Billing_Platform'
    );
};

export const mixpanelSinglePageFinancialsShoppingCart = () => {
    mixpanelService.postMixpanel('Viewed_Single_Page_Financials_Shopping_Cart');
};

export const mixpanelSinglePageFinancialsCreditCard = () => {
    mixpanelService.postMixpanel('Viewed_Single_Page_Financials_Credit_Card');
};

export const mixpanelClassicFinancialsBillingPlatform = () => {
    mixpanelService.postMixpanel('Viewed_Classic_Financials_Billing_Platform');
};

export const mixpanelClassicFinancialsShoppingCart = () => {
    mixpanelService.postMixpanel('Viewed_Classic_Financials_Shopping_Cart');
};

export const mixpanelClassicFinancialsCreditCard = () => {
    mixpanelService.postMixpanel('Viewed_Classic_Financials_Credit_Card');
};

export const mixpanelFinancialsCostCalculatorSaveEst = () => {
    mixpanelService.postMixpanel(
        'Viewed_Financials_Cost_Calculator_Save_Estimator'
    );
};

export const mixpanelFinancialsSupplyShop = () => {
    mixpanelService.postMixpanel('Viewed_Financials_Supply_Shop');
};

export const mixpanelNewsBlastRead = () => {
    mixpanelService.postMixpanel('Viewed_News_Blast');
};

export const mixpanelRouteManagerDuplicate = () => {
    mixpanelService.postMixpanel('Viewed_Route_Manager_Duplicate');
};

export const mixpanelPeopleTrainingAssignmentManagerAssignSkill = () => {
    mixpanelService.postMixpanel(
        'Viewed_People_Training_Assignment_Manager_Assigned_Skill'
    );
};

export const mixpanelPeopleTrainingAssignmentManagerUnassignedSkill = () => {
    mixpanelService.postMixpanel(
        'Viewed_People_Training_Assignment_Manager_Unassigned_Skill'
    );
};

export const mixpanelPeopleTrainingAssignmentManagerAssignCourse = () => {
    mixpanelService.postMixpanel(
        'Viewed_People_Training_Assignment_Manager_Assign_Course'
    );
};

export const mixpanelPeopleTrainingAssignmentManagerUnassignedCourse = () => {
    mixpanelService.postMixpanel(
        'Viewed_People_Training_Assignment_Manager_Unassigned_Course'
    );
};

export const mixpanelQualitySendSurveyEmail = () => {
    mixpanelService.postMixpanel('Viewed_Quality_Send_Email_Survey');
};

export const mixpanelQualitySendSurveyWeb = () => {
    mixpanelService.postMixpanel('Viewed_Quality_Send_Web_Survey');
};

export const mixpanelSitePickerChangeClientSite = () => {
    mixpanelService.postMixpanel('Viewed_Site_Picker_Change_Client_Site');
};

export const mixpanelSitePickerPreset = () => {
    mixpanelService.postMixpanel('Viewed_Site_Picker_Preset');
};

export const mixpanelQualityUnfinalizeAudit = () => {
    mixpanelService.postMixpanel('Viewed_Quality_Unfinalize_Audit');
};

export const mixpanelSentPersonnelEsatSurvey = () => {
    mixpanelService.postMixpanel('Viewed_Sent_Personnel_ESAT_Survey');
};

export const mixpanelCloseQualityWorkOrder = () => {
    mixpanelService.postMixpanel('Viewed_Quality_WorkOrder_Closed');
};

export const mixpanelCloseSafetyIncidentModal = (item) => {
    mixpanelService.postMixpanel(`Viewed_Closed_Safety_Incident_${item}`);
};

export const mixpanelSentQualityReportIts = () => {
    mixpanelService.postMixpanel('Viewed_Sent_Quality_Report_Its');
};

export const mixpanelCompleteQualityTodo = () => {
    mixpanelService.postMixpanel('Viewed_Complete_Quality_Todo');
};

export const mixpanelClickModalButton = (event) => {
    mixpanelService.postMixpanel(`Viewed_Button_Modal_${event}`);
};

export const mixpanelUnfinalizedQualityAuditsInspection = () => {
    mixpanelService.postMixpanel(
        'Viewed_Unfinalized_Quality_Audits_Inspection'
    );
};

export const mixpanelLatestSurveys = () => {
    mixpanelService.postMixpanel('Viewed_Latest_Surveys');
};

export const mixpanelEmployeesClockedIn = () => {
    mixpanelService.postMixpanel('Viewed_Employees_Clocked_In');
};

export const mixpanelWorkedOrders = () => {
    mixpanelService.postMixpanel('Viewed_Worked_Orders');
};

export const mixpanelLastComplaint = () => {
    mixpanelService.postMixpanel('Viewed_Last_Complaint');
};

export const mixpanelHoursWorked = () => {
    mixpanelService.postMixpanel('Viewed_Hours_Worked');
};

export const mixpanelAreasVerified = () => {
    mixpanelService.postMixpanel('Viewed_Areas_Verified');
};

export const mixpanelSiteScorecard = () => {
    mixpanelService.postMixpanel('Viewed_Site_Scorecard');
};

export const mixpanelClassicToSingle = () => {
    mixpanelService.postMixpanel('Viewed_Classic_To_Single');
};

export const mixpanelLastAudit = () => {
    mixpanelService.postMixpanel('Viewed_Last_Audit');
};

export const mixpanelScopeOfWork = () => {
    mixpanelService.postMixpanel('Viewed_Scope_of_Work');
};

export const useMixpanelScopeOfWorkManager = () => {
    useMixpanel('Viewed_Scope_of_Work_Manager');
};

export const mixpanelScopeOfWorkManager = () => {
    mixpanelService.postMixpanel('Viewed_Scope_of_Work_Manager');
};

export const mixpanelReportIts = () => {
    mixpanelService.postMixpanel('Viewed_Report_Its');
};

export const mixpanelLastReportIts = () => {
    mixpanelService.postMixpanel('Viewed_Last_Report_Its');
};

export const mixpanelDashboardView = () => {
    mixpanelService.postMixpanel('Viewed_Dashboard_View');
};

export const mixpanelLatestMessage = () => {
    mixpanelService.postMixpanel('Viewed_Latest_Message');
};

export const mixpanelNews = () => {
    mixpanelService.postMixpanel('Viewed_News');
};

export const mixpanelUserHits = () => {
    mixpanelService.postMixpanel('Viewed_User_Hits');
};

export const mixpanelStayUpToDate = () => {
    mixpanelService.postMixpanel('Viewed_Stay_Up_To_Date');
};

export const mixpanelPhotoBrowser = () => {
    mixpanelService.postMixpanel('Viewed_Tour_Site');
};

export const useMixpanelTourAreaReport = () => {
    useMixpanel('Viewed_Tour_Area_Report');
};

export const mixpanelRecentlyVisited = () => {
    mixpanelService.postMixpanel('Viewed_Recently_Visited');
};

// ----------------------Edited Events----------------------

export const mixpanelAboutMeEdit = () => {
    mixpanelService.postMixpanel('Edited_About_Me');
};

export const mixpanelRouteManagerEdit = () => {
    mixpanelService.postMixpanel('Edited_Route_Manager');
};

export const mixpanelEditQualityComplaint = () => {
    mixpanelService.postMixpanel(`Edited_Quality_Complaint`);
};

export const mixpanelEditQualityCompliment = () => {
    mixpanelService.postMixpanel(`Edited_Quality_Compliment`);
};

export const mixpanelEditPersonnelAttendance = () => {
    mixpanelService.postMixpanel(`Edited_Personnel_Attendance`);
};

export const mixpanelEditQualityReportIts = () => {
    mixpanelService.postMixpanel(`Edited_Quality_Report_Its`);
};

export const mixpanelEditPersonnelRequest = () => {
    mixpanelService.postMixpanel(`Edited_Personnel_Request`);
};

export const mixpanelEditQualityTodo = () => {
    mixpanelService.postMixpanel(`Edited_Quality_Todo`);
};

export const mixpanelPersonnelGMPAuditsEditChart = () => {
    mixpanelService.postMixpanel('Edited_Personnel_GMP_Audits_Chart');
};

export const mixpanelEditQualityOccupantReport = () => {
    mixpanelService.postMixpanel('Edited_Quality_Occupant_Report');
};

export const useMixpanelViewFailedVerifications = () => {
    useMixpanel('Viewed_Failed_Verifications');
};

// ----------------------Deleted Events---------------------

export const mixpanelQualityAuditDelete = () => {
    mixpanelService.postMixpanel('Deleted_Quality_Audits');
};

export const mixpanelQualityAuditDeleteModal = () => {
    mixpanelService.postMixpanel('Deleted_Quality_Audits_Modal');
};

export const mixpanelQualityAuditDeleteInspection = () => {
    mixpanelService.postMixpanel('Deleted_Quality_Audit_Inspection');
};

export const mixpanelDeletedRouteManager = () => {
    mixpanelService.postMixpanel('Deleted_Route_Manager');
};

export const mixpanelPersonnelGMPAuditsDeleteChart = () => {
    mixpanelService.postMixpanel('Deleted_Personnel_GMP_Audits_Chart');
};

export const mixpanelDeleteQualityWorkOrder = () => {
    mixpanelService.postMixpanel('Deleted_Quality_WorkOrder');
};

export const mixpanelDeletePersonnelAttendance = () => {
    mixpanelService.postMixpanel('Deleted_Personnel_Attendance');
};

export const mixpanelDeleteQualityComplaint = () => {
    mixpanelService.postMixpanel('Deleted_Quality_Complaint');
};

export const mixpanelDeleteQualityCompliment = () => {
    mixpanelService.postMixpanel('Deleted_Quality_Compliment');
};

export const mixpanelDeletePersonnelConductOccurrence = () => {
    mixpanelService.postMixpanel('Deleted_Personnel_Conduct_Occurrence');
};

export const mixpanelDeleteCommsCenterMessage = () => {
    mixpanelService.postMixpanel('Deleted_Communication_Center_Message');
};

export const mixpanelDeletePersonnelProAudit = () => {
    mixpanelService.postMixpanel('Deleted_Personnel_Professionalism_Audit');
};

export const mixpanelDeleteQualityReportIts = () => {
    mixpanelService.postMixpanel('Deleted_Quality_Report-Its');
};

export const mixpanelDeletePersonnelRequest = () => {
    mixpanelService.postMixpanel('Deleted_Personnel_Request');
};

export const mixpanelDeleteQualitySurvey = () => {
    mixpanelService.postMixpanel('Deleted_Quality_Survey');
};

export const mixpanelDeleteQualityTodo = () => {
    mixpanelService.postMixpanel('Deleted_Quality_Todo');
};

export const useMixpanelViewStockManagement = () => {
    useMixpanel('Viewed_Stock_Management');
};

/* Verifications Last Activity / Failed Verifications */

export const mixpanelVerificationsLatestActivity = (data) => {
    mixpanelService.postMixpanel('Viewed_Verifications_Latest_Activity');
};

export const useMixpanelViewLastActivityVerificationsAssociatePage = (
    data?: any
) => {
    mixpanelService.postMixpanel(
        'Viewed_Last_Activity_Verifications_Associate_Page',
        data
    );
};

export const useMixpanelViewLastActivityVerificationsManagerPage = (
    data?: any
) => {
    mixpanelService.postMixpanel(
        'Viewed_Last_Activity_Verifications_Manager_Page',
        data
    );
};

export const postMixpanelViewAllLastActivityVerificationsAssociatePage = (
    data?: any
) => {
    mixpanelService.postMixpanel(
        'Viewed_All_Last_Activity_Verifications_Associate_Page',
        data
    );
};

export const postMixpanelViewAllLastActivityVerificationsManagerPage = (
    data?: any
) => {
    mixpanelService.postMixpanel(
        'Viewed_All_Last_Activity_Verifications_Manager_Page',
        data
    );
};

export const postMixpanelViewAreaTypesAllLastActivityVerificationsAssociatePage =
    (data?: any) => {
        mixpanelService.postMixpanel(
            'Viewed_All_Area_Types_Last_Activity_Verifications_Associate_Page',
            data
        );
    };

export const postMixpanelViewAreaTypesAllLastActivityVerificationsManagerPage =
    (data?: any) => {
        mixpanelService.postMixpanel(
            'Viewed_All_Area_Types_Last_Activity_Verifications_Manager_Page',
            data
        );
    };

export const useMixpanelViewAreaTypeVerificationsAssociatePage = (
    data?: any
) => {
    mixpanelService.postMixpanel(
        'Viewed_Area_Type_Verifications_Associate_Page',
        data
    );
};

export const useMixpanelViewAreaTypeVerificationsManagerPage = (data?: any) => {
    mixpanelService.postMixpanel(
        'Viewed_Area_Type_Verifications_Manager_Page',
        data
    );
};

export const useMixpanelViewAreaElementVerificationsAssociatePage = (
    data?: any
) => {
    mixpanelService.postMixpanel(
        'Viewed_Area_Element_Verifications_Associate_Page',
        data
    );
};

export const useMixpanelViewAreaElementVerificationsManagerPage = (
    data?: any
) => {
    mixpanelService.postMixpanel(
        'Viewed_Area_Element_Verifications_Manager_Page',
        data
    );
};

export const useMixpanelViewSiteVerificationsAssociatePage = (data?: any) => {
    mixpanelService.postMixpanel(
        'Viewed_Site_Verifications_Associate_Page',
        data
    );
};

export const useMixpanelViewSiteVerificationsManagerPage = (data?: any) => {
    mixpanelService.postMixpanel(
        'Viewed_Site_Verifications_Manager_Page',
        data
    );
};

export const useMixpanelViewAllAreaTypeVerificationsAssociatePage = (
    data?: any
) => {
    mixpanelService.postMixpanel(
        'Viewed_All_Area_Type_Verifications_Associate_Page',
        data
    );
};

export const useMixpanelViewAllAreaTypeVerificationsManagerPage = (
    data?: any
) => {
    mixpanelService.postMixpanel(
        'Viewed_All_Area_Type_Verifications_Manager_Page',
        data
    );
};

export const mixpanelViewedAllAreaTypesVerifications = (data?: any) => {
    mixpanelService.postMixpanel('Viewed_All_Area_Types_Verifications', data);
};

export const mixpanelVerificationsViewedSingleVerificationDetailsAssociatePage =
    (data: any) => {
        mixpanelService.postMixpanel(
            'View_A_Single_Verification_Associate_Page',
            data
        );
    };

export const mixpanelVerificationsViewedSingleVerificationDetailsManagerPage = (
    data: any
) => {
    mixpanelService.postMixpanel(
        'View_A_Single_Verification_Manager_Page',
        data
    );
};

export const mixpanelVerificationsViewedAreaTypeSingleVerificationDetailsAssociatePage =
    (data?: any) => {
        mixpanelService.postMixpanel(
            'View_Area_Type_A_Single_Verification_Associate_Page',
            data
        );
    };

export const mixpanelVerificationsViewedAreaTypeSingleVerificationDetailsManagerPage =
    (data?: any) => {
        mixpanelService.postMixpanel(
            'View_Area_Type_A_Single_Verification_Manager_Page',
            data
        );
    };

export const mixpanelVerificationsViewedSingleVerificationDetails = (
    data: any
) => {
    mixpanelService.postMixpanel('View_A_Single_Verification', data);
};

export const mixpanelVerificationsElementPhotoClicked = (data: any) => {
    mixpanelService.postMixpanel(
        'Clicked_An_Element_Photo_In_Verification',
        data
    );
};

export const mixpanelVerificationsViewAllAreaTypesSortClicked = (data: any) => {
    mixpanelService.postMixpanel(
        'Clicked_Filter_In_Verifications_All_Area_Types',
        data
    );
};

export const mixpanelVerificationsAreaTypeAreasSortClicked = (data: any) => {
    mixpanelService.postMixpanel(
        'Clicked_Filter_In_Verifications_All_Areas_Of_An_Area_Type',
        data
    );
};

export const mixpanelVerificationsViewAllAreaElementsSortClicked = (
    data: any
) => {
    mixpanelService.postMixpanel(
        'Clicked_Filter_In_Verifications_All_Area_Elements',
        data
    );
};

export const mixpanelVerificationsAreaSelectedFromTypesScreen = (data: any) => {
    mixpanelService.postMixpanel('Viewed_A_Single_Areas_Verifications', data);
};

export const useMixpanelVisualSow = () => {
    useMixpanel('Viewed_Visual_SOW');
};

export const mixpanelNavClick = (data: {
    menu_type: 'Single_Page_Menu' | 'Classic_Menu';
    feature: string;
    sub_link?: string;
}) => {
    mixpanelService.postMixpanel(
        'Click_A_Navigation_Item_In_A_Mega_Menu',
        data
    );
};

export const mixpanelFailedVerificationsViewedSingleVerification = (
    data: any
) => {
    mixpanelService.postMixpanel('Viewed_Single_Failed_Verification', data);
};
