import ReactGA from 'react-ga4';

const title = document.querySelector('title');

if (title) {
	const observer = new MutationObserver((records) => {
		if (records.length && ReactGA.isInitialized) {
			ReactGA.send({
				hitType: 'pageview',
				page: window.location.pathname,
			});
		}
	});

	observer.observe(title, { childList: true });
}
