import { useState } from 'react';

// interfaces
import { InspectionArea } from '@api-interfaces';

// components
import { Text, ScoreChip, Icon } from '@atoms';

// helpers
import { useAuditModalContext } from '@modals/_ds2/audit/_context/context';
import {
    buildLocationName,
    getScoreChipParams,
} from '@modals/_ds2/audit/helpers';
import { Button } from '@new';

const limit = 10;

export const GroupAreasTable = () => {
    const {
        state: { mode, modals, areas, selectedAreaType, selectedSection },
        dispatch,
    } = useAuditModalContext();
    const [displayCount, setDisplayCount] = useState<number>(limit);
    const [nameOrder, setNameOrder] = useState<'ASC' | 'DESC'>('ASC');

    let filteredAreas = [];
    if (selectedAreaType) {
        filteredAreas = areas.filter(
            (area) => area.location?.area_type?.id === selectedAreaType?.id
        );
    } else {
        filteredAreas = areas.filter((area) =>
            area.area_groups.some(
                (area_group) => area_group.id == selectedSection?.id
            )
        );
    }

    const orderedAreas = filteredAreas.sort((a, b) => {
        const nameA = a?.location?.name ? a.location.name.toUpperCase() : '';
        const nameB = b?.location?.name ? b.location.name.toUpperCase() : '';
        return nameOrder === 'ASC'
            ? nameA.localeCompare(nameB)
            : nameB.localeCompare(nameA);
    });
    const displayAreas = orderedAreas.slice(0, displayCount);

    function handleAreaClick(inspectionArea: InspectionArea) {
        dispatch({
            type: 'SET_SELECTED_AREA',
            payload: inspectionArea,
        });
        dispatch({
            type: 'SET_SELECTED_AREA_LIST',
            payload: orderedAreas.map((area) => area.id),
        });
        dispatch({
            type: 'SET_MODALS',
            payload: {
                ...modals,
                'area-information': true,
            },
        });
    }

    return (
        <>
            <table className="tw-w-full">
                <thead>
                    <tr>
                        {[
                            'Name',
                            'Building',
                            'Photos',
                            'Comments',
                            'To-Dos',
                            'Score',
                        ].map((header, i, self) => (
                            <th
                                key={i}
                                className={`tw-min-w-[100px] tw-pb-4 tw-border-b tw-border-solid tw-border-neutral-300 dark:tw-border-neutral-600 ${
                                    i === 0
                                        ? 'tw-pr-2'
                                        : i === self.length - 1
                                          ? 'tw-pl-2'
                                          : 'tw-px-2'
                                } ${
                                    [2, 3, 4, 5].includes(i)
                                        ? 'tw-text-center'
                                        : 'tw-text-left'
                                }`}
                            >
                                <Text
                                    font="h4"
                                    color="neutral-offset"
                                    className="tw-relative"
                                >
                                    {header}
                                    {i === 0 && (
                                        <Icon
                                            size="lg"
                                            icon={[
                                                'fas',
                                                nameOrder === 'DESC'
                                                    ? 'caret-up'
                                                    : 'caret-down',
                                            ]}
                                            className="tw-absolute tw-cursor-pointer tw-ml-2 tw-text-theme-primary-500-300"
                                            onClick={() =>
                                                setNameOrder(
                                                    nameOrder === 'DESC'
                                                        ? 'ASC'
                                                        : 'DESC'
                                                )
                                            }
                                        />
                                    )}
                                </Text>
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {displayAreas?.map((area, i) => {
                        const {
                            location,
                            total_photos_attached,
                            total_comments_attached,
                            total_todos_attached,
                            audit_score_calculated,
                            audit_couldnt_score,
                            audit_is_pass,
                            audit_scale,
                            goalline,
                            inspection_score_calculated,
                        } = area;
                        let building = buildLocationName(location?.parent);
                        building = building || '-';
                        const { type, score } = getScoreChipParams({
                            mode,
                            audit_score_calculated,
                            audit_couldnt_score,
                            audit_is_pass,
                            audit_scale,
                            goalline,
                            inspection_score_calculated,
                        });

                        return (
                            <tr
                                key={i}
                                className={`tw-text-sm tw-text-neutral-900-100 
                                    tw-border-solid tw-border-b tw-border-neutral-300 dark:tw-border-neutral-600 last:tw-border-none
                                    tw-cursor-pointer hover:tw-bg-neutral-500 hover:tw-bg-opacity-15`}
                                onClick={() => handleAreaClick(area)}
                            >
                                <td className="tw-py-4 tw-pr-2">
                                    <Text
                                        font="h3"
                                        color="hi-contrast"
                                        className="tw-truncate tw-max-w-[175px]"
                                    >
                                        {location?.name}
                                    </Text>
                                </td>
                                <td className="tw-py-4 tw-px-2">
                                    <div className="tw-truncate tw-max-w-[175px]">
                                        {building}
                                    </div>
                                </td>
                                <td className="tw-py-4 tw-px-2 tw-text-center">
                                    {total_photos_attached > 0
                                        ? total_photos_attached
                                        : '-'}
                                </td>
                                <td className="tw-py-4 tw-px-2 tw-text-center">
                                    {total_comments_attached > 0
                                        ? total_comments_attached
                                        : '-'}
                                </td>
                                <td className="tw-py-4 tw-px-2 tw-text-center">
                                    {total_todos_attached > 0
                                        ? total_todos_attached
                                        : '-'}
                                </td>
                                <td className="tw-py-4 tw-pl-2 tw-text-center">
                                    <ScoreChip
                                        size="sm"
                                        type={type}
                                        className="tw-inline-flex"
                                        score={score}
                                    />
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            {displayCount < orderedAreas?.length && (
                <Button
                    color="alternate"
                    label="View More"
                    onClick={() => setDisplayCount(displayCount + limit)}
                    className="tw-my-2 tw-w-full tw-justify-center"
                />
            )}
        </>
    );
};
