import { ButtonProps } from './button.interfaces';

export const buttonTypeMap = (color?: ButtonProps['color']): string => {
	switch (color) {
		case 'primary':
			return `
        tw-bg-theme-primary-500-300 tw-text-neutral-100-900
        hover:tw-bg-theme-primary-300-100
        active:tw-bg-theme-primary-900-700
        disabled:tw-opacity-40 disabled:tw-pointer-events-none
      `;

		case 'secondary':
			return `
        tw-bg-theme-neutral-100-800 tw-text-theme-primary-500-300
        hover:tw-text-primary-100 dark:hover:tw-bg-neutral-600
        active:tw-bg-theme-neutral-300-700 active:tw-text-primary-500
        disabled:tw-opacity-40 disabled:tw-pointer-events-none
      `;

		case 'purple':
			return `
        tw-bg-secondary-500 dark:tw-bg-secondary-300 tw-text-neutral-100-900
        hover:tw-bg-secondary-300 dark:hover:tw-bg-secondary-100
        active:tw-bg-secondary-900 dark:active:tw-bg-secondary-700
        disabled:tw-opacity-40 disabled:tw-pointer-events-none
      `;

		case 'inverted':
			return `
        tw-bg-neutral-200-900 tw-text-theme-primary-500-300
        hover:tw-text-primary-100 dark:hover:tw-bg-neutral-600
        active:tw-bg-theme-neutral-300-700 active:tw-text-primary-500
        disabled:tw-opacity-40 disabled:tw-pointer-events-none
      `;

		case 'danger':
			return `
        tw-bg-theme-danger-700-300 tw-text-neutral-100-900
        hover:tw-bg-theme-danger-300-100
        active:tw-bg-theme-danger-500-300
        disabled:tw-opacity-40 disabled:tw-pointer-events-none
      `;

		case 'danger-alternate':
			return `
        tw-bg-theme-danger-700-300 tw-bg-opacity-15 tw-text-theme-danger-700-300
        hover:tw-bg-opacity-8
        active:tw-bg-danger-900 dark:active:tw-bg-danger-500 active:tw-bg-opacity-8 dark:active:tw-bg-opacity-8
        disabled:tw-bg-opacity-[0.06] disabled:tw-text-opacity-40 disabled:tw-pointer-events-none
      `;

		case 'alternate':
			return `
        tw-bg-theme-primary-500-300 tw-bg-opacity-15 tw-text-theme-primary-500-300
        hover:tw-bg-primary-100 hover:tw-bg-opacity-[0.08] dark:hover:tw-text-primary-100
        active:tw-bg-theme-primary-900-500 active:tw-bg-opacity-[0.08] dark:active:tw-text-primary-500
        disabled:tw-bg-opacity-[0.06] disabled:tw-text-opacity-40 disabled:tw-pointer-events-none
      `;

		case 'text-button':
			return `
        tw-px-0 tw-text-theme-primary-500-300
        hover:tw-text-theme-primary-700-100
        active:tw-text-primary-500 dark:active:tw-text-primary-700
        disabled:tw-cursor-not-allowed disabled:tw-pointer-events-none disabled:tw-opacity-40
      `;

		case 'link-button':
			return `
        !tw-px-0 !tw-py-0 tw-h-min tw-underline tw-text-theme-primary-500-300
        hover:tw-text-theme-primary-700-100
        active:tw-text-primary-500 dark:active:tw-text-primary-700
        disabled:tw-cursor-not-allowed disabled:tw-pointer-events-none disabled:tw-opacity-40  
      `;

		case 'success':
			return `
        tw-bg-success-900 tw-text-neutral-900-100
        hover:tw-bg-opacity-80
        active:tw-bg-opacity-70
        disabled:tw-opacity-40 disabled:tw-opacity-40 disabled:tw-pointer-events-none
        `;

		case 'warning':
			return `
        tw-bg-theme-warning-100-900 tw-text-neutral-900-100
        hover:tw-bg-opacity-80
        active:tw-bg-opacity-70
        disabled:tw-opacity-40 disabled:tw-pointer-events-none
        `;

		default:
			return '';
	}
};
