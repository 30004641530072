import { useState } from 'react';

// services
import { Text } from '@atoms';
import { awsService } from '@services';

// components

// interfaces
import { OrgLogoProps } from './org-logo.interfaces';

// helpers
import { orgLogoFontMap, orgLogoSizeMap } from './org-logo.utils';

export const OrgLogo = ({
    imageUrl,
    orgName,
    className = '',
    size = 'md',
    ...props
}: OrgLogoProps) => {
    const sizeClass = orgLogoSizeMap[size];
    const fontSize = orgLogoFontMap[size];
    const [error, setError] = useState(false);
    return imageUrl && !error ? (
        <img
            className={`tw-flex-shrink-0 tw-rounded-lg tw-object-cover ${sizeClass} ${className}`}
            src={awsService.getUrl(imageUrl) ?? undefined}
            onError={() => {
                setError(true);
            }}
            alt={orgName}
            {...props}
        />
    ) : (
        <div
            className={`tw-flex-shrink-0 tw-rounded-lg tw-grid tw-place-items-center tw-bg-neutral-850 ${sizeClass} ${className}`}
            {...props}
        >
            <Text font={fontSize} color="white">
                {orgName?.charAt(0)}
            </Text>
        </div>
    );
};

OrgLogo.defaultProps = {
    className: '',
};
