import { cloneDeep } from 'lodash';
import { Observable, forkJoin } from 'rxjs';

// interfaces
import { map } from 'rxjs/operators';

import { InspectionsSummary, InspectionsCombinerParams } from '@api-interfaces';

// services
import { virtualBrownClient } from '@core/services/django-client';

// helpers
import { Params, TimeUtils } from '@helpers';
import { round, floor } from 'lodash';

const BASE_URL = `inspections/summary`;

const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

/**
 * Combines both mode Audit and Inspection data into one
 * (can replace once endpoint gives back combined data).
 * Not all values are combined, you will need to add as needed.
 */
class InspectionsCombinerService {
	public getCombinedSummaries<T extends InspectionsSummary.Chart>(
		params: InspectionsCombinerParams<T>
	): Observable<InspectionsSummary.SummaryReturnType[T][]> {
		const query = {
			...params,
			chart: params.chart.join(','),
			time_zone: params.time_zone || timeZone,
		};
		const options = {
			noCustomer: params.customer !== undefined,
			noContract: params.contract !== undefined,
			noTag: params.tag !== undefined,
		};

		if (params.mode === 'AUDIT' || params.mode === 'INSPECTION') {
			return virtualBrownClient.get(
				`${BASE_URL}/${Params.makeQueryString(query)}`,
				options
			);
		}

		return forkJoin([
			virtualBrownClient.get(
				`${BASE_URL}/${Params.makeQueryString({
					...query,
					mode: 'AUDIT',
				})}`,
				options
			),
			virtualBrownClient.get(
				`${BASE_URL}/${Params.makeQueryString({
					...query,
					mode: 'INSPECTION',
				})}`,
				options
			),
		]).pipe(
			map(
				([resAudit, resInspection]: [
					InspectionsSummary.SummaryReturnType[T][],
					InspectionsSummary.SummaryReturnType[T][],
				]) => {
					const combinedRes = cloneDeep(resAudit);

					resAudit.forEach((auditSummaryChart, i) => {
						resInspection.forEach((inspectionSummaryChart) => {
							const charts = [
								auditSummaryChart.chart,
								inspectionSummaryChart.chart,
							];
							const results = [
								auditSummaryChart.results,
								inspectionSummaryChart.results,
							];

							if (
								charts.every(
									(chart) => chart === 'GetInspectionCounts'
								)
							)
								combinedRes[i].results =
									this.combineGetInspectionCounts(
										...(results as [
											InspectionsSummary.InspectionCounts,
											InspectionsSummary.InspectionCounts,
										])
									);
							else if (
								charts.every(
									(chart) =>
										chart === 'GetInspectionCountsByMonth'
								)
							)
								combinedRes[i].results =
									this.combineGetInspectionCountsByMonth(
										...(results as [
											InspectionsSummary.InspectionCountsByMonth[],
											InspectionsSummary.InspectionCountsByMonth[],
										])
									);
							else if (
								charts.every(
									(chart) =>
										chart === 'GetInspectionCountsBySite'
								)
							)
								combinedRes[i].results =
									this.combineGetInspectionCountsBySite(
										...(results as [
											InspectionsSummary.InspectionCountsBySite[],
											InspectionsSummary.InspectionCountsBySite[],
										])
									);
							else if (
								charts.every(
									(chart) =>
										chart === 'GetInspectionCountsByService'
								)
							)
								combinedRes[i].results =
									this.combineGetInspectionCountsByService(
										...(results as [
											InspectionsSummary.InspectionCountsByService[],
											InspectionsSummary.InspectionCountsByService[],
										])
									);
							else if (
								charts.every(
									(chart) =>
										chart === 'GetInspectionCountsByTag'
								)
							)
								combinedRes[i].results =
									this.combineGetInspectionCountsByTag(
										...(results as [
											InspectionsSummary.InspectionCountsByTag[],
											InspectionsSummary.InspectionCountsByTag[],
										])
									);
							else if (
								charts.every(
									(chart) =>
										chart ===
										'GetInspectionCountsByBuilding'
								)
							)
								combinedRes[i].results =
									this.combineGetInspectionCountsByBuilding(
										...(results as [
											InspectionsSummary.InspectionCountsByBuilding[],
											InspectionsSummary.InspectionCountsByBuilding[],
										])
									);
							else if (
								charts.every(
									(chart) =>
										chart ===
										'GetInspectionCountsByOrganization'
								)
							)
								combinedRes[i].results =
									this.combineGetInspectionCountsByOrganization(
										...(results as [
											InspectionsSummary.InspectionCountsByOrganization[],
											InspectionsSummary.InspectionCountsByOrganization[],
										])
									);
							else if (
								charts.every(
									(chart) =>
										chart ===
										'GetInspectionCountsByServiceAndLocation'
								)
							)
								combinedRes[i].results =
									this.combineGetInspectionCountsByServiceAndLocation(
										...(results as [
											InspectionsSummary.InspectionCountsByServiceAndLocation[],
											InspectionsSummary.InspectionCountsByServiceAndLocation[],
										])
									);
							else if (
								charts.every(
									(chart) =>
										chart === 'GetInspectionCountsByVendor'
								)
							)
								combinedRes[i].results =
									this.combineGetInspectionCountsByVendor(
										...(results as [
											InspectionsSummary.InspectionCountsByVendor[],
											InspectionsSummary.InspectionCountsByVendor[],
										])
									);
							else if (
								charts.every(
									(chart) => chart === 'InspectionsByAreaType'
								)
							)
								combinedRes[i].results =
									this.combineInspectionsByAreaType(
										...(results as [
											InspectionsSummary.InspectionsByAreaType[],
											InspectionsSummary.InspectionsByAreaType[],
										])
									);
							else if (
								charts.every(
									(chart) =>
										chart === 'InspectionsByMasterAreaType'
								)
							)
								combinedRes[i].results =
									this.combineInspectionsByMasterAreaType(
										...(results as [
											InspectionsSummary.InspectionsByMasterAreaType[],
											InspectionsSummary.InspectionsByMasterAreaType[],
										])
									);
							else if (
								charts.every(
									(chart) =>
										chart === 'InspectionsByAreaGroup'
								)
							)
								combinedRes[i].results =
									this.combineInspectionsByAreaGroup(
										...(results as [
											InspectionsSummary.InspectionsByAreaGroup[],
											InspectionsSummary.InspectionsByAreaGroup[],
										])
									);
							else if (
								charts.every(
									(chart) =>
										chart === 'GetInspectionCountsByShift'
								)
							)
								combinedRes[i].results =
									this.combineGetInspectionCountsByShift(
										...(results as [
											InspectionsSummary.InspectionCountsByShift[],
											InspectionsSummary.InspectionCountsByShift[],
										])
									);
							else if (
								charts.every(
									(chart) =>
										chart ===
										'GetInspectionCountsByShiftType'
								)
							)
								combinedRes[i].results =
									this.combineGetInspectionCountsByShiftType(
										...(results as [
											InspectionsSummary.InspectionCountsByShiftType[],
											InspectionsSummary.InspectionCountsByShiftType[],
										])
									);
							else if (
								charts.every(
									(chart) =>
										chart ===
										'GetInspectionCountsByVerticals'
								)
							)
								combinedRes[i].results =
									this.combineGetInspectionCountsByVerticals(
										...(results as [
											InspectionsSummary.InspectionsCountsByVerticals[],
											InspectionsSummary.InspectionsCountsByVerticals[],
										])
									);
							else if (
								charts.every(
									(chart) =>
										chart ===
										'GetInspectionCountsByVerticalsOrganizationList'
								)
							)
								combinedRes[i].results =
									this.combineGetInspectionCountsByVerticalsOrganizationList(
										...(results as [
											InspectionsSummary.InspectionsCountsByVerticalsOrganizationList[],
											InspectionsSummary.InspectionsCountsByVerticalsOrganizationList[],
										])
									);
							else if (
								charts.every(
									(chart) =>
										chart ===
										'GetDashboardInspectionCountsByMonth'
								)
							)
								combinedRes[i].results =
									this.combineGetDashboardInspectionCountsByMonth(
										...(results as [
											InspectionsSummary.DashboardInspectionCountsByMonth[],
											InspectionsSummary.DashboardInspectionCountsByMonth[],
										])
									);
						});
					});

					return combinedRes;
				}
			)
		);
	}

	private combineGetInspectionCounts(
		audits: InspectionsSummary.InspectionCounts,
		inspections: InspectionsSummary.InspectionCounts
	): InspectionsSummary.InspectionCounts {
		const combinedResults = cloneDeep(audits);
		combinedResults.avg_audit_score = this.combineAverageCalculator({
			xValue: audits.avg_audit_score_full,
			yValue: inspections.avg_audit_score_full,
			xCount: audits.total_area_scores,
			yCount: inspections.total_area_scores,
		});
		combinedResults.average_customer_score = this.combineAverageCalculator({
			xValue: audits.average_customer_score_full,
			yValue: inspections.average_customer_score_full,
			xCount: audits.total_customer_area_scores,
			yCount: inspections.total_customer_area_scores,
		});
		combinedResults.average_internal_score = this.combineAverageCalculator({
			xValue: audits.average_internal_score_full,
			yValue: inspections.average_internal_score_full,
			xCount: audits.total_internal_area_scores,
			yCount: inspections.total_internal_area_scores,
		});
		combinedResults.average_joint_score = this.combineAverageCalculator({
			xValue: audits.average_joint_score_full,
			yValue: inspections.average_joint_score_full,
			xCount: audits.total_joint_area_scores,
			yCount: inspections.total_joint_area_scores,
		});
		combinedResults.total_failed =
			audits.total_failed + inspections.total_failed;
		combinedResults.total_completed =
			audits.total_completed + inspections.total_completed;
		combinedResults.total_customer_inspections =
			audits.total_customer_inspections +
			inspections.total_customer_inspections;
		combinedResults.total_internal_inspections =
			audits.total_internal_inspections +
			inspections.total_internal_inspections;
		combinedResults.total_joint_inspections =
			audits.total_joint_inspections +
			inspections.total_joint_inspections;
		combinedResults.latest_inspection_date = TimeUtils.isBefore(
			audits.latest_inspection_date,
			inspections.latest_inspection_date
		)
			? inspections.latest_inspection_date
			: audits.latest_inspection_date;
		return combinedResults;
	}

	private combineGetInspectionCountsByMonth(
		audits: InspectionsSummary.InspectionCountsByMonth[],
		inspections: InspectionsSummary.InspectionCountsByMonth[]
	): InspectionsSummary.InspectionCountsByMonth[] {
		const combinedResults = audits.map((audit) => {
			const combinedItem = cloneDeep(audit);
			const inspection = inspections.find(
				(inspection) => inspection.month === audit.month
			);
			if (inspection) {
				combinedItem.average_customer_score =
					this.combineAverageCalculator({
						xValue: audit.average_customer_score_full
							? audit.average_customer_score_full
							: audit.average_customer_score,
						yValue: inspection.average_customer_score_full
							? inspection.average_customer_score_full
							: inspection.average_customer_score,
						xCount: audit.total_customer_area_scores
							? audit.total_customer_area_scores
							: audit.total_customer_inspections,
						yCount: inspection.total_customer_area_scores
							? inspection.total_customer_area_scores
							: inspection.total_customer_inspections,
					});
				combinedItem.average_internal_score =
					this.combineAverageCalculator({
						xValue: audit.average_internal_score_full
							? audit.average_internal_score_full
							: audit.average_internal_score,
						yValue: inspection.average_internal_score_full
							? inspection.average_internal_score_full
							: inspection.average_internal_score,
						xCount: audit.total_internal_area_scores
							? audit.total_internal_area_scores
							: audit.total_internal_inspections,
						yCount: inspection.total_internal_area_scores
							? inspection.total_internal_area_scores
							: inspection.total_internal_inspections,
					});
				combinedItem.average_joint_score =
					this.combineAverageCalculator({
						xValue: audit.average_joint_score_full
							? audit.average_joint_score_full
							: audit.average_joint_score,
						yValue: inspection.average_joint_score_full
							? inspection.average_joint_score_full
							: inspection.average_joint_score,
						xCount: audit.total_joint_area_scores
							? audit.total_joint_area_scores
							: audit.total_joint_inspections,
						yCount: inspection.total_joint_area_scores
							? inspection.total_joint_area_scores
							: inspection.total_joint_inspections,
					});
			}
			return combinedItem;
		});
		return combinedResults;
	}

	private combineGetInspectionCountsBySite(
		audits: InspectionsSummary.InspectionCountsBySite[],
		inspections: InspectionsSummary.InspectionCountsBySite[]
	): InspectionsSummary.InspectionCountsBySite[] {
		const combinedResults = audits.map((audit) => {
			const combinedItem = cloneDeep(audit);
			const inspection = inspections.find(
				(inspection) => inspection.id === audit.id
			);
			if (inspection) {
				combinedItem.average_customer_score =
					this.combineAverageCalculator({
						xValue: audit.average_customer_score_full
							? audit.average_customer_score_full
							: audit.average_customer_score,
						yValue: inspection.average_customer_score_full
							? inspection.average_customer_score_full
							: inspection.average_customer_score,
						xCount: audit.total_customer_area_scores
							? audit.total_customer_area_scores
							: audit.total_customer_inspections,
						yCount: inspection.total_customer_area_scores
							? inspection.total_customer_area_scores
							: inspection.total_customer_inspections,
					});
				combinedItem.average_internal_score =
					this.combineAverageCalculator({
						xValue: audit.average_internal_score_full
							? audit.average_internal_score_full
							: audit.average_internal_score,
						yValue: inspection.average_internal_score_full
							? inspection.average_internal_score_full
							: inspection.average_internal_score,
						xCount: audit.total_internal_area_scores
							? audit.total_internal_area_scores
							: audit.total_internal_inspections,
						yCount: inspection.total_internal_area_scores
							? inspection.total_internal_area_scores
							: inspection.total_internal_inspections,
					});
				combinedItem.average_joint_score =
					this.combineAverageCalculator({
						xValue: audit.average_joint_score_full
							? audit.average_joint_score_full
							: audit.average_joint_score,
						yValue: inspection.average_joint_score_full
							? inspection.average_joint_score_full
							: inspection.average_joint_score,
						xCount: audit.total_joint_area_scores
							? audit.total_joint_area_scores
							: audit.total_joint_inspections,
						yCount: inspection.total_joint_area_scores
							? inspection.total_joint_area_scores
							: inspection.total_joint_inspections,
					});
			}
			return combinedItem;
		});
		return combinedResults;
	}

	private combineGetInspectionCountsByService(
		audits: InspectionsSummary.InspectionCountsByService[],
		inspections: InspectionsSummary.InspectionCountsByService[]
	): InspectionsSummary.InspectionCountsByService[] {
		let combinedResults = audits.map((audit) => {
			const combinedItem = cloneDeep(audit);
			const inspection = inspections.find(
				(inspection) => inspection.id === audit.id
			);
			if (inspection) {
				combinedItem.average_customer_score =
					this.combineAverageCalculator({
						xValue: audit.average_customer_score_full
							? audit.average_customer_score_full
							: audit.average_customer_score,
						yValue: inspection.average_customer_score_full
							? inspection.average_customer_score_full
							: inspection.average_customer_score,
						xCount: audit.total_customer_area_scores
							? audit.total_customer_area_scores
							: audit.total_customer_inspections,
						yCount: inspection.total_customer_area_scores
							? inspection.total_customer_area_scores
							: inspection.total_customer_inspections,
					});
				combinedItem.average_internal_score =
					this.combineAverageCalculator({
						xValue: audit.average_internal_score_full
							? audit.average_internal_score_full
							: audit.average_internal_score,
						yValue: inspection.average_internal_score_full
							? inspection.average_internal_score_full
							: inspection.average_internal_score,
						xCount: audit.total_internal_area_scores
							? audit.total_internal_area_scores
							: audit.total_internal_inspections,
						yCount: inspection.total_internal_area_scores
							? inspection.total_internal_area_scores
							: inspection.total_internal_inspections,
					});
				combinedItem.average_joint_score =
					this.combineAverageCalculator({
						xValue: audit.average_joint_score_full
							? audit.average_joint_score_full
							: audit.average_joint_score,
						yValue: inspection.average_joint_score_full
							? inspection.average_joint_score_full
							: inspection.average_joint_score,
						xCount: audit.total_joint_area_scores
							? audit.total_joint_area_scores
							: audit.total_joint_inspections,
						yCount: inspection.total_joint_area_scores
							? inspection.total_joint_area_scores
							: inspection.total_joint_inspections,
					});
			}
			return combinedItem;
		});

		combinedResults = combinedResults.concat(
			inspections.filter((inspection) =>
				audits.every((audit) => audit.id !== inspection.id)
			)
		);

		combinedResults.sort((a, b) => a.name?.localeCompare(b.name));

		return combinedResults;
	}

	private combineGetInspectionCountsByTag(
		audits: InspectionsSummary.InspectionCountsByTag[],
		inspections: InspectionsSummary.InspectionCountsByTag[]
	): InspectionsSummary.InspectionCountsByTag[] {
		const combinedResults = audits.map((audit) => {
			const combinedItem = cloneDeep(audit);
			const inspection = inspections.find(
				(inspection) => inspection.id === audit.id
			);
			if (inspection) {
				combinedItem.average_customer_score =
					this.combineAverageCalculator({
						xValue: audit.average_customer_score_full
							? audit.average_customer_score_full
							: audit.average_customer_score,
						yValue: inspection.average_customer_score_full
							? inspection.average_customer_score_full
							: inspection.average_customer_score,
						xCount: audit.total_customer_area_scores
							? audit.total_customer_area_scores
							: audit.total_customer_inspections,
						yCount: inspection.total_customer_area_scores
							? inspection.total_customer_area_scores
							: inspection.total_customer_inspections,
					});
				combinedItem.average_internal_score =
					this.combineAverageCalculator({
						xValue: audit.average_internal_score_full
							? audit.average_internal_score_full
							: audit.average_internal_score,
						yValue: inspection.average_internal_score_full
							? inspection.average_internal_score_full
							: inspection.average_internal_score,
						xCount: audit.total_internal_area_scores
							? audit.total_internal_area_scores
							: audit.total_internal_inspections,
						yCount: inspection.total_internal_area_scores
							? inspection.total_internal_area_scores
							: inspection.total_internal_inspections,
					});
				combinedItem.average_joint_score =
					this.combineAverageCalculator({
						xValue: audit.average_joint_score_full
							? audit.average_joint_score_full
							: audit.average_joint_score,
						yValue: inspection.average_joint_score_full
							? inspection.average_joint_score_full
							: inspection.average_joint_score,
						xCount: audit.total_joint_area_scores
							? audit.total_joint_area_scores
							: audit.total_joint_inspections,
						yCount: inspection.total_joint_area_scores
							? inspection.total_joint_area_scores
							: inspection.total_joint_inspections,
					});
			}
			return combinedItem;
		});
		return combinedResults;
	}

	private combineGetInspectionCountsByBuilding(
		audits: InspectionsSummary.InspectionCountsByBuilding[],
		inspections: InspectionsSummary.InspectionCountsByBuilding[]
	): InspectionsSummary.InspectionCountsByBuilding[] {
		const combinedResults = audits.map((audit) => {
			const combinedItem = cloneDeep(audit);
			const inspection = inspections.find(
				(inspection) => inspection.building_id === audit.building_id
			);
			if (inspection) {
				combinedItem.average_customer_score =
					this.combineAverageCalculator({
						xValue: audit.average_customer_score_full
							? audit.average_customer_score_full
							: audit.average_customer_score,
						yValue: inspection.average_customer_score_full
							? inspection.average_customer_score_full
							: inspection.average_customer_score,
						xCount: audit.total_customer_area_scores
							? audit.total_customer_area_scores
							: audit.total_customer_inspections,
						yCount: inspection.total_customer_area_scores
							? inspection.total_customer_area_scores
							: inspection.total_customer_inspections,
					});
				combinedItem.average_internal_score =
					this.combineAverageCalculator({
						xValue: audit.average_internal_score_full
							? audit.average_internal_score_full
							: audit.average_internal_score,
						yValue: inspection.average_internal_score_full
							? inspection.average_internal_score_full
							: inspection.average_internal_score,
						xCount: audit.total_internal_area_scores
							? audit.total_internal_area_scores
							: audit.total_internal_inspections,
						yCount: inspection.total_internal_area_scores
							? inspection.total_internal_area_scores
							: inspection.total_internal_inspections,
					});
				combinedItem.average_joint_score =
					this.combineAverageCalculator({
						xValue: audit.average_joint_score_full
							? audit.average_joint_score_full
							: audit.average_joint_score,
						yValue: inspection.average_joint_score_full
							? inspection.average_joint_score_full
							: inspection.average_joint_score,
						xCount: audit.total_joint_area_scores
							? audit.total_joint_area_scores
							: audit.total_joint_inspections,
						yCount: inspection.total_joint_area_scores
							? inspection.total_joint_area_scores
							: inspection.total_joint_inspections,
					});
			}
			return combinedItem;
		});
		return combinedResults;
	}

	private combineGetInspectionCountsByServiceAndLocation(
		audits: InspectionsSummary.InspectionCountsByServiceAndLocation[],
		inspections: InspectionsSummary.InspectionCountsByServiceAndLocation[]
	): InspectionsSummary.InspectionCountsByServiceAndLocation[] {
		const combinedResults = audits.map((audit) => {
			const combinedItem = cloneDeep(audit);
			const inspection = inspections.find(
				(inspection) => inspection.id === audit.id
			);
			if (inspection) {
				combinedItem.average_score = this.combineAverageCalculator({
					xValue: audit.average_score_full
						? audit.average_score_full
						: audit.average_score,
					yValue: inspection.average_score_full
						? inspection.average_score_full
						: inspection.average_score,
					xCount: audit.total_area_scores
						? audit.total_area_scores
						: audit.total_inspections,
					yCount: inspection.total_area_scores
						? inspection.total_area_scores
						: inspection.total_inspections,
				});
				combinedItem.average_customer_score =
					this.combineAverageCalculator({
						xValue: audit.average_customer_score_full
							? audit.average_customer_score_full
							: audit.average_customer_score,
						yValue: inspection.average_customer_score_full
							? inspection.average_customer_score_full
							: inspection.average_customer_score,
						xCount: audit.total_customer_area_scores
							? audit.total_customer_area_scores
							: audit.total_customer_inspections,
						yCount: inspection.total_customer_area_scores
							? inspection.total_customer_area_scores
							: inspection.total_customer_inspections,
					});
				combinedItem.average_internal_score =
					this.combineAverageCalculator({
						xValue: audit.average_internal_score_full
							? audit.average_internal_score_full
							: audit.average_internal_score,
						yValue: inspection.average_internal_score_full
							? inspection.average_internal_score_full
							: inspection.average_internal_score,
						xCount: audit.total_internal_area_scores
							? audit.total_internal_area_scores
							: audit.total_internal_inspections,
						yCount: inspection.total_internal_area_scores
							? inspection.total_internal_area_scores
							: inspection.total_internal_inspections,
					});
				combinedItem.average_joint_score =
					this.combineAverageCalculator({
						xValue: audit.average_joint_score_full
							? audit.average_joint_score_full
							: audit.average_joint_score,
						yValue: inspection.average_joint_score_full
							? inspection.average_joint_score_full
							: inspection.average_joint_score,
						xCount: audit.total_joint_area_scores
							? audit.total_joint_area_scores
							: audit.total_joint_inspections,
						yCount: inspection.total_joint_area_scores
							? inspection.total_joint_area_scores
							: inspection.total_joint_inspections,
					});

				combinedItem.total_inspections =
					audit.total_inspections + inspection.total_inspections;
				combinedItem.total_customer_inspections =
					audit.total_customer_inspections +
					inspection.total_customer_inspections;
				combinedItem.total_internal_inspections =
					audit.total_internal_inspections +
					inspection.total_internal_inspections;
				combinedItem.total_joint_inspections =
					audit.total_joint_inspections +
					inspection.total_joint_inspections;
				combinedItem.last_audit_date = TimeUtils.isBefore(
					audit.last_audit_date,
					inspection.last_audit_date
				)
					? inspection.last_audit_date
					: audit.last_audit_date;
			}
			return combinedItem;
		});
		return combinedResults;
	}

	private combineGetInspectionCountsByOrganization(
		audits: InspectionsSummary.InspectionCountsByOrganization[],
		inspections: InspectionsSummary.InspectionCountsByOrganization[]
	): InspectionsSummary.InspectionCountsByOrganization[] {
		const combinedResults = audits.map((audit) => {
			const combinedItem = cloneDeep(audit);
			const inspection = inspections.find(
				(inspection) => inspection.customer_id === audit.customer_id
			);
			if (inspection) {
				combinedItem.average_customer_score =
					this.combineAverageCalculator({
						xValue: audit.average_customer_score_full
							? audit.average_customer_score_full
							: audit.average_customer_score,
						yValue: inspection.average_customer_score_full
							? inspection.average_customer_score_full
							: inspection.average_customer_score,
						xCount: audit.total_customer_area_scores
							? audit.total_customer_area_scores
							: audit.total_customer_inspections,
						yCount: inspection.total_customer_area_scores
							? inspection.total_customer_area_scores
							: inspection.total_customer_inspections,
					});
				combinedItem.average_internal_score =
					this.combineAverageCalculator({
						xValue: audit.average_internal_score_full
							? audit.average_internal_score_full
							: audit.average_internal_score,
						yValue: inspection.average_internal_score_full
							? inspection.average_internal_score_full
							: inspection.average_internal_score,
						xCount: audit.total_internal_area_scores
							? audit.total_internal_area_scores
							: audit.total_internal_inspections,
						yCount: inspection.total_internal_area_scores
							? inspection.total_internal_area_scores
							: inspection.total_internal_inspections,
					});
				combinedItem.average_joint_score =
					this.combineAverageCalculator({
						xValue: audit.average_joint_score_full
							? audit.average_joint_score_full
							: audit.average_joint_score,
						yValue: inspection.average_joint_score_full
							? inspection.average_joint_score_full
							: inspection.average_joint_score,
						xCount: audit.total_joint_area_scores
							? audit.total_joint_area_scores
							: audit.total_joint_inspections,
						yCount: inspection.total_joint_area_scores
							? inspection.total_joint_area_scores
							: inspection.total_joint_inspections,
					});
			}
			return combinedItem;
		});
		return combinedResults;
	}

	private combineGetInspectionCountsByVerticals(
		audits: InspectionsSummary.InspectionsCountsByVerticals[],
		inspections: InspectionsSummary.InspectionsCountsByVerticals[]
	): InspectionsSummary.InspectionsCountsByVerticals[] {
		const combinedResults = audits.map((audit) => {
			const combinedItem = cloneDeep(audit);
			const inspection = inspections.find(
				(inspection) => inspection.id === audit.id
			);
			if (inspection) {
				combinedItem.average_customer_score =
					this.combineAverageCalculator({
						xValue: audit.average_customer_score,
						yValue: inspection.average_customer_score,
						xCount: audit.total_customer_inspections,
						yCount: inspection.total_customer_inspections,
					});
				combinedItem.average_internal_score =
					this.combineAverageCalculator({
						xValue: audit.average_internal_score,
						yValue: inspection.average_internal_score,
						xCount: audit.total_internal_inspections,
						yCount: inspection.total_internal_inspections,
					});
				combinedItem.average_joint_score =
					this.combineAverageCalculator({
						xValue: audit.average_joint_score,
						yValue: inspection.average_joint_score,
						xCount: audit.total_joint_inspections,
						yCount: inspection.total_joint_inspections,
					});
			}
			return combinedItem;
		});
		return combinedResults;
	}

	private combineGetInspectionCountsByVerticalsOrganizationList(
		audits: InspectionsSummary.InspectionsCountsByVerticalsOrganizationList[],
		inspections: InspectionsSummary.InspectionsCountsByVerticalsOrganizationList[]
	): InspectionsSummary.InspectionsCountsByVerticalsOrganizationList[] {
		const combinedResults = audits.map((audit) => {
			const combinedItem = cloneDeep(audit);
			const inspection = inspections.find(
				(inspection) => inspection.id === audit.id
			);
			if (inspection) {
				combinedItem.average_customer_score =
					this.combineAverageCalculator({
						xValue: audit.average_customer_score,
						yValue: inspection.average_customer_score,
						xCount: audit.total_customer_inspections,
						yCount: inspection.total_customer_inspections,
					});
				combinedItem.average_internal_score =
					this.combineAverageCalculator({
						xValue: audit.average_internal_score,
						yValue: inspection.average_internal_score,
						xCount: audit.total_internal_inspections,
						yCount: inspection.total_internal_inspections,
					});
				combinedItem.average_joint_score =
					this.combineAverageCalculator({
						xValue: audit.average_joint_score,
						yValue: inspection.average_joint_score,
						xCount: audit.total_joint_inspections,
						yCount: inspection.total_joint_inspections,
					});
			}
			return combinedItem;
		});
		return combinedResults;
	}

	private combineGetInspectionCountsByVendor(
		audits: InspectionsSummary.InspectionCountsByVendor[],
		inspections: InspectionsSummary.InspectionCountsByVendor[]
	): InspectionsSummary.InspectionCountsByVendor[] {
		const combinedResults = audits.map((audit) => {
			const combinedItem = cloneDeep(audit);
			const inspection = inspections.find(
				(inspection) => inspection.id === audit.id
			);
			if (inspection) {
				combinedItem.average_customer_score =
					this.combineAverageCalculator({
						xValue: audit.average_customer_score,
						yValue: inspection.average_customer_score,
						xCount: audit.total_customer_inspections,
						yCount: inspection.total_customer_inspections,
					});
				combinedItem.average_internal_score =
					this.combineAverageCalculator({
						xValue: audit.average_internal_score,
						yValue: inspection.average_internal_score,
						xCount: audit.total_internal_inspections,
						yCount: inspection.total_internal_inspections,
					});
			}
			return combinedItem;
		});
		return combinedResults;
	}

	private combineInspectionsByAreaType(
		audits: InspectionsSummary.InspectionsByAreaType[],
		inspections: InspectionsSummary.InspectionsByAreaType[]
	): InspectionsSummary.InspectionsByAreaType[] {
		return audits.map((audit) => {
			const combinedItem = cloneDeep(audit);
			const inspection = inspections.find(
				(inspection) => inspection.area_type_id === audit.area_type_id
			);
			if (inspection) {
				combinedItem.average_customer_score =
					this.combineAverageCalculator({
						xValue: audit.average_customer_score_full
							? audit.average_customer_score_full
							: audit.average_customer_score,
						yValue: inspection.average_customer_score_full
							? inspection.average_customer_score_full
							: inspection.average_customer_score,
						xCount: audit.total_customer_area_scores
							? audit.total_customer_area_scores
							: audit.total_customer_inspections,
						yCount: inspection.total_customer_area_scores
							? inspection.total_customer_area_scores
							: inspection.total_customer_inspections,
					});
				combinedItem.average_internal_score =
					this.combineAverageCalculator({
						xValue: audit.average_internal_score_full
							? audit.average_internal_score_full
							: audit.average_internal_score,
						yValue: inspection.average_internal_score_full
							? inspection.average_internal_score_full
							: inspection.average_internal_score,
						xCount: audit.total_internal_area_scores
							? audit.total_internal_area_scores
							: audit.total_internal_inspections,
						yCount: inspection.total_internal_area_scores
							? inspection.total_internal_area_scores
							: inspection.total_internal_inspections,
					});
				combinedItem.average_joint_score =
					this.combineAverageCalculator({
						xValue: audit.average_joint_score_full
							? audit.average_joint_score_full
							: audit.average_joint_score,
						yValue: inspection.average_joint_score_full
							? inspection.average_joint_score_full
							: inspection.average_joint_score,
						xCount: audit.total_joint_area_scores
							? audit.total_joint_area_scores
							: audit.total_joint_inspections,
						yCount: inspection.total_joint_area_scores
							? inspection.total_joint_area_scores
							: inspection.total_joint_inspections,
					});
			}
			return combinedItem;
		});
	}

	private combineInspectionsByMasterAreaType(
		audits: InspectionsSummary.InspectionsByMasterAreaType[],
		inspections: InspectionsSummary.InspectionsByMasterAreaType[]
	): InspectionsSummary.InspectionsByMasterAreaType[] {
		return audits.map((audit) => {
			const combinedItem = cloneDeep(audit);
			const inspection = inspections.find(
				(inspection) =>
					inspection.master_area_type_id === audit.master_area_type_id
			);
			if (inspection) {
				combinedItem.average_customer_score =
					this.combineAverageCalculator({
						xValue: audit.average_customer_score_full
							? audit.average_customer_score_full
							: audit.average_customer_score,
						yValue: inspection.average_customer_score_full
							? inspection.average_customer_score_full
							: inspection.average_customer_score,
						xCount: audit.total_customer_area_scores
							? audit.total_customer_area_scores
							: audit.total_customer_inspections,
						yCount: inspection.total_customer_area_scores
							? inspection.total_customer_area_scores
							: inspection.total_customer_inspections,
					});
				combinedItem.average_internal_score =
					this.combineAverageCalculator({
						xValue: audit.average_internal_score_full
							? audit.average_internal_score_full
							: audit.average_internal_score,
						yValue: inspection.average_internal_score_full
							? inspection.average_internal_score_full
							: inspection.average_internal_score,
						xCount: audit.total_internal_area_scores
							? audit.total_internal_area_scores
							: audit.total_internal_inspections,
						yCount: inspection.total_internal_area_scores
							? inspection.total_internal_area_scores
							: inspection.total_internal_inspections,
					});
				combinedItem.average_joint_score =
					this.combineAverageCalculator({
						xValue: audit.average_joint_score_full
							? audit.average_joint_score_full
							: audit.average_joint_score,
						yValue: inspection.average_joint_score_full
							? inspection.average_joint_score_full
							: inspection.average_joint_score,
						xCount: audit.total_joint_area_scores
							? audit.total_joint_area_scores
							: audit.total_joint_inspections,
						yCount: inspection.total_joint_area_scores
							? inspection.total_joint_area_scores
							: inspection.total_joint_inspections,
					});
			}
			return combinedItem;
		});
	}

	private combineInspectionsByAreaGroup(
		audits: InspectionsSummary.InspectionsByAreaGroup[],
		inspections: InspectionsSummary.InspectionsByAreaGroup[]
	): InspectionsSummary.InspectionsByAreaGroup[] {
		return audits.map((audit) => {
			const combinedItem = cloneDeep(audit);
			const inspection = inspections.find(
				(inspection) =>
					inspection.contract_area_group_id ===
					audit.contract_area_group_id
			);
			if (inspection) {
				combinedItem.average_customer_score =
					this.combineAverageCalculator({
						xValue: audit.average_customer_score_full
							? audit.average_customer_score_full
							: audit.average_customer_score,
						yValue: inspection.average_customer_score_full
							? inspection.average_customer_score_full
							: inspection.average_customer_score,
						xCount: audit.total_customer_area_scores
							? audit.total_customer_area_scores
							: audit.total_customer_inspections,
						yCount: inspection.total_customer_area_scores
							? inspection.total_customer_area_scores
							: inspection.total_customer_inspections,
					});
				combinedItem.average_internal_score =
					this.combineAverageCalculator({
						xValue: audit.average_internal_score_full
							? audit.average_internal_score_full
							: audit.average_internal_score,
						yValue: inspection.average_internal_score_full
							? inspection.average_internal_score_full
							: inspection.average_internal_score,
						xCount: audit.total_internal_area_scores
							? audit.total_internal_area_scores
							: audit.total_internal_inspections,
						yCount: inspection.total_internal_area_scores
							? inspection.total_internal_area_scores
							: inspection.total_internal_inspections,
					});
				combinedItem.average_joint_score =
					this.combineAverageCalculator({
						xValue: audit.average_joint_score_full
							? audit.average_joint_score_full
							: audit.average_joint_score,
						yValue: inspection.average_joint_score_full
							? inspection.average_joint_score_full
							: inspection.average_joint_score,
						xCount: audit.total_joint_area_scores
							? audit.total_joint_area_scores
							: audit.total_joint_inspections,
						yCount: inspection.total_joint_area_scores
							? inspection.total_joint_area_scores
							: inspection.total_joint_inspections,
					});
				combinedItem.average_score = this.combineAverageCalculator({
					xValue: audit.average_score_full
						? audit.average_score_full
						: audit.average_score,
					yValue: inspection.average_score_full
						? inspection.average_score_full
						: inspection.average_score,
					xCount:
						(audit.total_customer_area_scores ??
							audit.total_customer_inspections ??
							0) +
						(audit.total_internal_area_scores ??
							audit.total_internal_inspections ??
							0) +
						(audit.total_joint_area_scores ??
							audit.total_joint_inspections ??
							0),
					yCount:
						(inspection.total_customer_area_scores ??
							inspection.total_customer_inspections ??
							0) +
						(inspection.total_internal_area_scores ??
							inspection.total_internal_inspections ??
							0) +
						(inspection.total_joint_area_scores ??
							inspection.total_joint_inspections ??
							0),
				});
				combinedItem.total_customer_inspections =
					(audit.total_customer_inspections ?? 0) +
					(inspection.total_customer_inspections ?? 0);
				combinedItem.total_internal_inspections =
					(audit.total_internal_inspections ?? 0) +
					(inspection.total_internal_inspections ?? 0);
				combinedItem.total_joint_inspections =
					(audit.total_joint_inspections ?? 0) +
					(inspection.total_joint_inspections ?? 0);
			}
			return combinedItem;
		});
	}

	private combineGetInspectionCountsByShift(
		audits: InspectionsSummary.InspectionCountsByShift[],
		inspections: InspectionsSummary.InspectionCountsByShift[]
	): InspectionsSummary.InspectionCountsByShift[] {
		return audits.map((audit) => {
			const combinedItem = cloneDeep(audit);
			const inspection = inspections.find(
				(inspection) => inspection.shift_id === audit.shift_id
			);
			if (inspection) {
				combinedItem.average_customer_score =
					this.combineAverageCalculator({
						xValue: audit.average_customer_score_full
							? audit.average_customer_score_full
							: audit.average_customer_score,
						yValue: inspection.average_customer_score_full
							? inspection.average_customer_score_full
							: inspection.average_customer_score,
						xCount: audit.total_customer_area_scores
							? audit.total_customer_area_scores
							: audit.total_customer_inspections,
						yCount: inspection.total_customer_area_scores
							? inspection.total_customer_area_scores
							: inspection.total_customer_inspections,
					});
				combinedItem.average_internal_score =
					this.combineAverageCalculator({
						xValue: audit.average_internal_score_full
							? audit.average_internal_score_full
							: audit.average_internal_score,
						yValue: inspection.average_internal_score_full
							? inspection.average_internal_score_full
							: inspection.average_internal_score,
						xCount: audit.total_internal_area_scores
							? audit.total_internal_area_scores
							: audit.total_internal_inspections,
						yCount: inspection.total_internal_area_scores
							? inspection.total_internal_area_scores
							: inspection.total_internal_inspections,
					});
				combinedItem.average_joint_score =
					this.combineAverageCalculator({
						xValue: audit.average_joint_score_full
							? audit.average_joint_score_full
							: audit.average_joint_score,
						yValue: inspection.average_joint_score_full
							? inspection.average_joint_score_full
							: inspection.average_joint_score,
						xCount: audit.total_joint_area_scores
							? audit.total_joint_area_scores
							: audit.total_joint_inspections,
						yCount: inspection.total_joint_area_scores
							? inspection.total_joint_area_scores
							: inspection.total_joint_inspections,
					});
			}
			return combinedItem;
		});
	}

	private combineGetInspectionCountsByShiftType(
		audits: InspectionsSummary.InspectionCountsByShiftType[],
		inspections: InspectionsSummary.InspectionCountsByShiftType[]
	): InspectionsSummary.InspectionCountsByShiftType[] {
		return audits.map((audit) => {
			const combinedItem = cloneDeep(audit);
			const inspection = inspections.find(
				(inspection) => inspection.shifttype_id === audit.shifttype_id
			);
			if (inspection) {
				combinedItem.average_customer_score =
					this.combineAverageCalculator({
						xValue: audit.average_customer_score,
						yValue: inspection.average_customer_score,
						xCount: audit.total_customer_inspections,
						yCount: inspection.total_customer_inspections,
					});
				combinedItem.average_internal_score =
					this.combineAverageCalculator({
						xValue: audit.average_internal_score,
						yValue: inspection.average_internal_score,
						xCount: audit.total_internal_inspections,
						yCount: inspection.total_internal_inspections,
					});
				combinedItem.average_joint_score =
					this.combineAverageCalculator({
						xValue: audit.average_joint_score,
						yValue: inspection.average_joint_score,
						xCount: audit.total_joint_inspections,
						yCount: inspection.total_joint_inspections,
					});
			}
			return combinedItem;
		});
	}

	private combineGetDashboardInspectionCountsByMonth(
		audits: InspectionsSummary.DashboardInspectionCountsByMonth[],
		inspections: InspectionsSummary.DashboardInspectionCountsByMonth[]
	): InspectionsSummary.DashboardInspectionCountsByMonth[] {
		const combinedResults = audits.map((audit) => {
			const combinedItem = cloneDeep(audit);
			const inspection = inspections.find(
				(inspection) => inspection.month === audit.month
			);
			if (inspection) {
				combinedItem.average_customer_score =
					this.combineAverageCalculator({
						xValue: audit.average_customer_score_full
							? audit.average_customer_score_full
							: audit.average_customer_score,
						yValue: inspection.average_customer_score_full
							? inspection.average_customer_score_full
							: inspection.average_customer_score,
						xCount: audit.total_customer_area_scores
							? audit.total_customer_area_scores
							: audit.total_customer_inspections,
						yCount: inspection.total_customer_area_scores
							? inspection.total_customer_area_scores
							: inspection.total_customer_inspections,
					});
				combinedItem.average_internal_score =
					this.combineAverageCalculator({
						xValue: audit.average_internal_score_full
							? audit.average_internal_score_full
							: audit.average_internal_score,
						yValue: inspection.average_internal_score_full
							? inspection.average_internal_score_full
							: inspection.average_internal_score,
						xCount: audit.total_internal_area_scores
							? audit.total_internal_area_scores
							: audit.total_internal_inspections,
						yCount: inspection.total_internal_area_scores
							? inspection.total_internal_area_scores
							: inspection.total_internal_inspections,
					});
				combinedItem.average_joint_score =
					this.combineAverageCalculator({
						xValue: audit.average_joint_score_full
							? audit.average_joint_score_full
							: audit.average_joint_score,
						yValue: inspection.average_joint_score_full
							? inspection.average_joint_score_full
							: inspection.average_joint_score,
						xCount: audit.total_joint_area_scores
							? audit.total_joint_area_scores
							: audit.total_joint_inspections,
						yCount: inspection.total_joint_area_scores
							? inspection.total_joint_area_scores
							: inspection.total_joint_inspections,
					});
			}
			return combinedItem;
		});
		return combinedResults;
	}

	private combineAverageCalculator(params: {
		xValue: number;
		yValue: number;
		xCount: number;
		yCount: number;
	}) {
		const totalCount = params.xCount + params.yCount;
		const xWeight = totalCount ? params.xCount / totalCount : 0;
		const yWeight = totalCount ? params.yCount / totalCount : 0;
		return floor(
			round(params.xValue * xWeight + params.yValue * yWeight, 3),
			2
		);
	}
}

export const inspectionsCombinerService = new InspectionsCombinerService();
