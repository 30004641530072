// interfaces
import {
	ContractService,
	ContractServicePosition,
	ContractServicePositionPayload,
	ContractServiceShift,
	ContractServiceShiftPayload,
} from '@api-interfaces';

// services
import { virtualBrownClient } from '@core/services/django-client';

// helpers
import { Params } from '@helpers';
import { PaginationParams, PaginationResponse } from '@models';

// constants
const BASE_URL = 'contracts';

const noOpts = {
	noProvider: true,
	noCustomer: true,
	noContract: true,
	noTag: true,
};

class ContractServicesService {
	public getContractServices(
		keys: {
			contract: number;
		},
		params?: {
			admin_landing?: boolean;
			parent?: number; // available when admin_landing is true
		} & PaginationParams,
		settings?: {
			noOptions?: boolean;
		}
	) {
		const queryString = Params.makeQueryString(params);
		return virtualBrownClient.get<PaginationResponse<ContractService>>(
			`${BASE_URL}/${keys.contract}/services/${queryString}`,
			settings && settings.noOptions ? noOpts : {}
		);
	}

	public getContractServiceById(keys: { contract: number; service: number }) {
		return virtualBrownClient.get<ContractService>(
			`${BASE_URL}/${keys.contract}/services/${keys.service}/`,
			noOpts
		);
	}

	public getContractServicePositions(
		keys: {
			contract: number;
			service: number;
		},
		params?: {
			admin_landing?: boolean;
		} & PaginationParams
	) {
		const queryString = Params.makeQueryString(params);
		return virtualBrownClient.get<PaginationResponse<ContractServicePosition>>(
			`${BASE_URL}/${keys.contract}/services/${keys.service}/positions/${queryString}`,
			noOpts
		);
	}

	public getContractServicePositionById(keys: {
		contract: number;
		service: number;
		position: number;
	}) {
		return virtualBrownClient.get<ContractServicePosition>(
			`${BASE_URL}/${keys.contract}/services/${keys.service}/positions/${keys.position}/`,
			noOpts
		);
	}

	public createContractServicePosition(
		keys: { contract: number; service: number },
		payload: ContractServicePositionPayload
	) {
		return virtualBrownClient.post<ContractServicePosition>(
			`${BASE_URL}/${keys.contract}/services/${keys.service}/positions/`,
			payload
		);
	}

	public updateContractServicePosition(
		keys: { contract: number; service: number; position: number },
		payload: ContractServicePositionPayload
	) {
		return virtualBrownClient.put<ContractServicePosition>(
			`${BASE_URL}/${keys.contract}/services/${keys.service}/positions/${keys.position}/`,
			payload
		);
	}

	public deleteContractServicePosition(keys: {
		contract: number;
		service: number;
		position: number;
	}) {
		return virtualBrownClient.delete(
			`${BASE_URL}/${keys.contract}/services/${keys.service}/positions/${keys.position}/`
		);
	}

	public getContractServiceShifts(
		keys: {
			contract: number;
			service: number;
		},
		params?: {
			admin_landing?: boolean;
		} & PaginationParams
	) {
		const queryString = Params.makeQueryString(params);
		return virtualBrownClient.get<PaginationResponse<ContractServiceShift>>(
			`${BASE_URL}/${keys.contract}/services/${keys.service}/shifts/${queryString}`,
			noOpts
		);
	}

	public getContractServiceShiftById(keys: {
		contract: number;
		service: number;
		shift: number;
	}) {
		return virtualBrownClient.get<ContractServiceShift>(
			`${BASE_URL}/${keys.contract}/services/${keys.service}/shifts/${keys.shift}/`,
			noOpts
		);
	}

	public createContractServiceShift(
		keys: { contract: number; service: number },
		payload: ContractServiceShiftPayload
	) {
		return virtualBrownClient.post<ContractServiceShift>(
			`${BASE_URL}/${keys.contract}/services/${keys.service}/shifts/`,
			payload
		);
	}

	public updateContractServiceShift(
		keys: { contract: number; service: number; shift: number },
		payload: ContractServiceShiftPayload
	) {
		return virtualBrownClient.put<ContractServiceShift>(
			`${BASE_URL}/${keys.contract}/services/${keys.service}/shifts/${keys.shift}/`,
			payload
		);
	}

	public deleteContractServiceShift(keys: {
		contract: number;
		service: number;
		shift: number;
	}) {
		return virtualBrownClient.delete(
			`${BASE_URL}/${keys.contract}/services/${keys.service}/shifts/${keys.shift}/`
		);
	}
}

export const contractServicesService = new ContractServicesService();
