import isUrl from 'validator/lib/isURL';
import { youtubeUrlRegex, vimeoUrlRegex, sbmThinkzoomUrlRegex } from '.';

export function validateValue(value) {
	// allows for 0
	let error;
	if (value === null || value === undefined || value === '') {
		error = 'Please enter a value.';
	}
	return error;
}

export function validateName(value) {
	let error;

	if (!value || !value.trim()) {
		error = 'Please enter a name.';
	}

	return error;
}

export function validateEmail(value) {
	let error;
	if (!value) {
		error = 'Please enter an email address.';
	} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
		error = 'Please enter a valid email address.';
	}
	return error;
}

export function validateNumber(value, minimum = 0) {
	let error;
	if (isNaN(parseFloat(value)) || !isFinite(value) || value < minimum) {
		error = `Please enter a number${
			minimum !== 0 ? ` that is ${minimum} or more` : ''
		}.`;
	}
	return error;
}

export function validatePhoneNumber(value) {
	let error;
	if (!value) {
		error = 'Please enter a phone number';
	} else if (!/^(\([0-9]{3}\)|[0-9]{3}) [0-9]{3}-[0-9]{4}$/.test(value)) {
		error = 'Please enter a valid phone number';
	}
	return error;
}

export function isValidValue(value) {
	return !validateValue(value);
}

export function isValidName(value) {
	return !validateName(value);
}

export function isValidEmail(value) {
	return !validateEmail(value);
}

export function isValidNumber(value) {
	return !validateNumber(value);
}

export function isValidPhoneNumber(value) {
	return !validatePhoneNumber(value);
}

export function isBoolean(value) {
	return value === true || value === false;
}

export function isExternalVideo(url: string) {
	return (
		youtubeUrlRegex.test(url) ||
		vimeoUrlRegex.test(url) ||
		sbmThinkzoomUrlRegex.test(url) ||
		isUrl(url)
	);
}
