import { useRef, useMemo } from 'react';

import { ServiceArea } from '@api-interfaces';
import { getLocations } from '@helpers';

/**
 * @description - GET /serviceareas/?quick_landing=true will not return full parent's location object
 * therefore making getLocations not properly setting location hierarchy. This hook helps track changes
 * to location especially when used with Dropdown-like components
 *
 */
export function useLocationsRef(location: ServiceArea) {
	const locationsRef = useRef(getLocations(location));
	const locations = useMemo(() => {
		const newLocations = getLocations(location);
		if (newLocations.building) {
			locationsRef.current.building = newLocations.building;
			locationsRef.current.floor = null;
			locationsRef.current.room = null;
		}
		if (newLocations.floor) {
			locationsRef.current.floor = newLocations.floor;
			locationsRef.current.room = null;
		}
		if (newLocations.room) {
			locationsRef.current.room = newLocations.room;
		}
		return locationsRef.current;
	}, [location]);

	return locations;
}
