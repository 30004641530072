import { useEffect, useRef } from 'react';

export function useEventListener(eventName, handler, element) {
  const currentHandler = useRef(null);
  useEffect(() => {
    currentHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    if (!element && !element.addEventListener) return;
    const eventListener = (event) => currentHandler.current(event);
    element.addEventListener(eventName, eventListener);
    return () => {
      element.removeEventListener(eventName, eventListener);
    };
  }, [eventName, element]);
}
