// interfaces
import { PartialKpiConfig, GoalText } from '../site-scorecard.config.interface';

export const PEOPLE_KPIS: PartialKpiConfig[] = [
	{
		icon: 'calendar-check',
		title: 'Attendance Issues',
		subtitle: '# of Occurrences',
		goalType: 'int',
		goalText: GoalText.noMoreThan,
		key: 'attendance',
	},
	{
		icon: 'star',
		title: 'Conduct',
		subtitle: '# of Occurrences',
		goalText: GoalText.noMoreThan,
		goalType: 'int',
		key: 'conduct',
	},
	{
		icon: 'star',
		title: 'Employee Scorecard',
		subtitle: '% Passing',
		goalType: 'percentage',
		goalText: GoalText.atLeast,
		key: 'employee_scorecard',
	},
	{
		icon: 'user',
		title: 'Position Profiles',
		subtitle: '% Completed',
		goalType: 'percentage',
		goalText: GoalText.atLeast,
		key: 'position_profile',
	},
	{
		icon: 'user-tie',
		title: 'Professionalism Audit',
		subtitle: '% Passing',
		goalType: 'percentage',
		goalText: GoalText.atLeast,
		key: 'professionalism',
	},
	{
		icon: 'comments',
		title: 'Requests',
		subtitle: 'Total #',
		goalType: 'int',
		goalText: GoalText.atLeast,
		key: 'request',
	},
	{
		icon: 'user-minus',
		title: 'Turnover',
		subtitle: 'Total %',
		goalType: 'percentage',
		goalText: GoalText.noMoreThan,
		key: 'turnover',
	},
	{
		icon: 'graduation-cap',
		title: 'Training',
		subtitle: 'Avg %',
		goalType: 'percentage',
		goalText: GoalText.atLeast,
		key: 'training',
	},
	{
		icon: 'medal',
		title: 'ESAT',
		subtitle: '',
		goalType: 'int',
		goalText: GoalText.atLeast,
		key: 'esat',
	},
	{
		icon: 'medal',
		title: 'Expected Hours Met',
		subtitle: '',
		goalType: 'int',
		goalText: GoalText.atLeast,
		key: 'expected_hours_met',
	},
	{
		icon: 'medal',
		title: 'Routes Completed',
		subtitle: '',
		goalType: 'percentage',
		goalText: GoalText.moreThan,
		key: 'routes_completed',
	},
	{
		icon: 'medal',
		title: 'Key Badge Credential Audit',
		subtitle: '',
		goalType: 'percentage',
		goalText: GoalText.moreThan,
		key: 'key_badge_credential_audit',
	},
	{
		icon: 'medal',
		title: 'Union Sanctioned Direct Escalations Reach Client',
		subtitle: '',
		goalType: 'percentage',
		goalText: GoalText.moreThan,
		key: 'union_sanctioned_direct_escalations_reach_client',
	},
];
