import { useRef, useEffect, CSSProperties } from 'react';

// components
import { LoaderBlocks, Text } from '@atoms';

interface Props extends IntersectionObserverInit {
    onIntersect: () => void;
    isLoading: boolean;
    isDisabled: boolean;
    hideNoDataText?: boolean;
    sentinelHeight?: number | string;
    className?: string;
    style?: CSSProperties;
}

export const InfinityEdge = (props: Props) => {
    const {
        onIntersect,
        isDisabled,
        isLoading,
        hideNoDataText,
        sentinelHeight,
        threshold = 1,
        rootMargin = '1px',
        root = null,
        ...otherProps
    } = props;

    const sentinelRef = useRef<HTMLDivElement>(null);
    const observer = useRef<IntersectionObserver>(null);

    function observerCallback(entries: IntersectionObserverEntry[]) {
        const [entry] = entries;
        if (
            entry.intersectionRatio === threshold &&
            !isLoading &&
            !isDisabled
        ) {
            onIntersect();
        }
    }

    function createObserver() {
        const options = {
            root,
            rootMargin,
            threshold,
        };

        return new IntersectionObserver(observerCallback, options);
    }

    useEffect(() => {
        if (!isLoading && !isDisabled) {
            observer.current = createObserver();
            if (sentinelRef.current) {
                observer.current.observe(sentinelRef.current);
            }
        } else if (observer.current) {
            observer.current.disconnect();
        }

        return () => {
            if (observer.current) {
                observer.current.disconnect();
            }
        };
    }, [isLoading, isDisabled, onIntersect, root, rootMargin, threshold]);

    return (
        <div {...otherProps}>
            {isLoading && <LoaderBlocks />}
            {!hideNoDataText && isDisabled && (
                <Text
                    font="body-md"
                    color="hi-contrast"
                    className="tw-text-center tw-my-2"
                >
                    No more data to retrieve.
                </Text>
            )}
            <div
                ref={sentinelRef}
                style={{
                    height: sentinelHeight || 1,
                    // margin is to prevent part of the infinity edge from being cut off when wrapped in a container
                    // with an overflow/fixed height, preventing the intersection observer from firing
                    marginBottom: 1,
                }}
            />
        </div>
    );
};
