import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

// interfaces
import {
	Organization,
	OrganizationPayload,
	OrganizationGoallines,
	OrganizationContractGroup,
	OrganizationGroup,
	OrganizationGroupPayload,
	OrganizationGroupType,
} from '@api-interfaces';

// services
import { virtualBrownClient } from '@core/services/django-client';

// helpers
import { Params } from '@helpers';
import { CommonParams, PaginationParams, PaginationResponse } from '@models';
import { organizationGroupsSummaryService } from './summary';

// constants
const BASE_URL = 'organizations';

const noOpts = {
	noCustomer: true,
	noContract: true,
	noTag: true,
};

class OrganizationsService {
	readonly organizationGroupSummary = organizationGroupsSummaryService;

	public getOrganizations(
		params: {
			provider?: number;
			status?: 'locked' | 'unsuspended' | 'activated' | 'deactivated';
			detail_level?: 'simple';
			type?: 'CUSTOMER' | 'SERVICE_PROVIDER';
			with_contracts?: boolean;
			without_group?: boolean;
		} & PaginationParams
	): Observable<PaginationResponse<Organization>> {
		const queryString = Params.makeQueryString(params);
		return virtualBrownClient.get(`${BASE_URL}/${queryString}`, {
			...noOpts,
			noProvider: true,
		});
	}

	public getOrganizationById = (
		organizationId: number
	): Observable<Organization> =>
		virtualBrownClient.get(`${BASE_URL}/${organizationId}/`);

	public getCustomerOrganizations = (
		params: { provider: number } & PaginationParams
	): Observable<Organization[]> => {
		const queryString = Params.makeQueryString(params);
		return virtualBrownClient
			.get(`${BASE_URL}/${queryString}`, noOpts)
			.pipe(
				map((res: PaginationResponse<Organization>) => {
					const customers = res.results.filter(
						(x) => x.type === 'CUSTOMER'
					);
					return customers;
				})
			);
	};

	public getAllOrgs = (
		params: { provider: number } & PaginationParams
	): Observable<Organization[]> => {
		const queryString = Params.makeQueryString(params);
		return virtualBrownClient
			.get(`${BASE_URL}/${queryString}`, noOpts)
			.pipe(
				map((res: PaginationResponse<Organization>) => {
					const customers = res.results;
					return customers;
				})
			);
	};

	public getOrganizationHierarchy = (params: {
		contract?: number | string;
	}) => {
		const queryString = Params.makeQueryString(params);
		return virtualBrownClient
			.get(`${BASE_URL}/hierarchy/${queryString}`)
			.pipe(
				map((res: PaginationResponse<Organization>) => {
					const organizations = res.results;
					return organizations;
				})
			);
	};

	public getProviderOrganizations = (
		params: { provider: number } & PaginationParams
	): Observable<Organization[]> => {
		const queryString = Params.makeQueryString(params);
		return virtualBrownClient
			.get(`${BASE_URL}/${queryString}`, noOpts)
			.pipe(
				map((res: PaginationResponse<Organization>) => {
					const providers = res.results.filter(
						(x) => x.type === 'SERVICE_PROVIDER'
					);
					return providers;
				})
			);
	};

	public createOrganization = (
		payload: OrganizationPayload
	): Observable<any> => virtualBrownClient.post(`${BASE_URL}/`, payload);

	public updateOrganization = (
		orgId: number,
		payload: OrganizationPayload
	): Observable<any> =>
		virtualBrownClient.put(`${BASE_URL}/${orgId}/`, payload);

	public getOrganizationsGoallines(
		params: {
			organization: number | string;
		} & PaginationParams
	): Observable<PaginationResponse<OrganizationGoallines>> {
		const queryString = Params.makeQueryString(params);
		return virtualBrownClient.get(
			`${BASE_URL}/goallines/${queryString}`,
			noOpts
		);
	}

	public createOrganizationsGoallines(payload): Observable<any> {
		return virtualBrownClient.post(`${BASE_URL}/goallines/`, payload);
	}

	public updateOrganizationsGoallines(orgId, payload): Observable<any> {
		return virtualBrownClient.put(
			`organizations/goallines/${orgId}/`,
			payload
		);
	}

	public getContractGroups = (params: {
		organization_ids?: string;
		include_contracts?: boolean;
	}): Observable<PaginationResponse<OrganizationContractGroup>> => {
		const queryString = Params.makeQueryString(params);
		return virtualBrownClient.get(
			`${BASE_URL}/contract_groups/${queryString}`
		);
	};

	public getOrganizationGroups = (
		params: {
			detail_level?: string;
			status?: 'ACTIVE' | 'INACTIVE';
			search?: string;
			org_group?: string;
		} & CommonParams
	): Observable<PaginationResponse<OrganizationGroup>> => {
		const queryString = Params.makeQueryString(params);
		return virtualBrownClient.get(
			`${BASE_URL}/organization_groups/${queryString}`,
			{
				...noOpts,
				noProvider: true,
			}
		);
	};

	public getOrganizationGroupById = (
		orgGroupId: string | number
	): Observable<OrganizationGroup> => {
		return virtualBrownClient.get(
			`${BASE_URL}/organization_groups/${orgGroupId}`,
			{
				...noOpts,
				noProvider: true,
			}
		);
	};

	public createOrganizationGroup = (
		payload: OrganizationGroupPayload
	): Observable<any> =>
		virtualBrownClient.post(`${BASE_URL}/organization_groups/`, payload);

	public updateOrganizationGroup = (
		orgGroupId: string,
		payload: OrganizationGroupPayload
	): Observable<any> =>
		virtualBrownClient.put(
			`${BASE_URL}/organization_groups/${orgGroupId}/`,
			payload
		);

	public updateOrganizationGroupBulkAction(params?: {
		org_group?: string;
		action?: 'activate' | 'deactivate';
	}) {
		return virtualBrownClient.put<string>(
			`${BASE_URL}/organization_groups/bulk_action/${Params.makeQueryString(
				params || {}
			)}`
		);
	}

	public getOrganizationGroupTypes = (
		params: {
			search?: string;
			status?: 'ACTIVE' | 'INACTIVE';
			detail_level?: 'simple';
			group_type?: string;
		} & CommonParams
	): Observable<PaginationResponse<OrganizationGroupType>> => {
		const queryString = Params.makeQueryString(params);
		return virtualBrownClient.get(
			`${BASE_URL}/organization_group_types/${queryString}`,
			{
				...noOpts,
				noProvider: true,
			}
		);
	};

	public createOrganizationGroupType = (payload: {
		name: string;
	}): Observable<any> =>
		virtualBrownClient.post(
			`${BASE_URL}/organization_group_types/`,
			payload
		);

	public updateOrganizationGroupType = (
		orgGroupTypeId: number,
		payload: { name: string }
	): Observable<any> =>
		virtualBrownClient.put(
			`${BASE_URL}/organization_group_types/${orgGroupTypeId}/`,
			payload
		);

	public updateOrganizationGroupTypeBulkActionType(params?: {
		org_group_type?: string;
		action?: 'activate' | 'deactivate';
	}) {
		return virtualBrownClient.put<string>(
			`${BASE_URL}/organization_group_types/bulk_action/${Params.makeQueryString(
				params || {}
			)}`
		);
	}
}

export const organizationsService = new OrganizationsService();
