import { Todo } from '@api-interfaces';
import { TodoForm } from '@modals/_ds2/todo-form';

import { useWorkOrderModalContext } from '../../../../_context';

// components

// interfaces

export const CreateTodo = () => {
    const {
        workOrder,
        setWorkOrder,
        workOrderEdit,
        setWorkOrderEdit,
        activeModals,
        setActiveModals,
        setSelectedTodo,
        hasBeenUpdated,
    } = useWorkOrderModalContext();

    function handleSuccess(todo: Todo) {
        const workOrderEditCopy: typeof workOrderEdit = {
            ...workOrderEdit,
            todos: workOrder.todos.concat(todo),
        };

        setWorkOrder(workOrderEditCopy);
        setWorkOrderEdit(workOrderEditCopy);
        hasBeenUpdated.current = true;
    }

    function handleCancel() {
        setActiveModals({
            ...activeModals,
            'creating-todo': false,
        });
        setSelectedTodo(null);
    }

    return (
        activeModals['creating-todo'] && (
            <TodoForm
                closeModal={handleCancel}
                onSuccess={handleSuccess}
                externalDueDate={
                    workOrderEdit.due_date
                        ? new Date(workOrderEdit.due_date)
                        : null
                }
            />
        )
    );
};
