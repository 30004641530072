import { cloneDeep } from 'lodash';
import { useMemo, useEffect, useRef, useState } from 'react';

// services
import { Observable, Subscription, forkJoin, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import {
    Todo,
    WorkOrder,
    WorkOrderStatusKey,
    CostLineItem,
    TodoPayload,
} from '@api-interfaces';
import { todosService, workOrdersService } from '@apis';
import {
    calculatePOFundsRemaining,
    createWorkOrderPayload,
    createWorkOrderCostLineItemPayload,
} from '@app/modals/_ds2/work-order-form/_helpers';
import { Box, Text, ModalStack } from '@atoms';
import { sendError } from '@helpers';
import { useAppContext, useBreakpoints } from '@hooks';
import { Comments } from '@new/Comments';
import { toasterService } from '@services';

// components
import { Modal } from '@templates';
import { BottomSheetContainerProps } from '@templates/modal/bottom-sheet/container/bottom-sheet.container.interfaces';

import {
    WorkOrderBilling,
    WorkOrderOverview,
    WorkOrderServiceIssues,
    WorkOrderTodos,
    WorkOrderDetailsPanel,
} from './_sections';

// helpers
import { createTodoPayload } from '@modals/_ds2/todo-form/_helpers';
import { useWorkOrderModalContext } from '@modals/_ds2/work-orders/_context';
import { mixpanelCreateUpdateWorkOrder } from '@app/mixpanel/MixpanelPageTrack.tsx';

// interfaces

export const WorkOrderBottomSheetModal = () => {
    const {
        state: { user },
    } = useAppContext();
    const {
        workOrder,
        setWorkOrder,
        isEditing,
        setIsEditing,
        isClosing,
        setIsClosing,
        closeModal,
        onSuccess,
        activeModals,
        setActiveModals,
        workOrderEdit,
        setWorkOrderEdit,
        activePurchaseOrder,
        hasBeenUpdated,
        submissionAttempt,
        setSubmissionAttempt,
    } = useWorkOrderModalContext();
    const subscription = useRef(new Subscription());
    const bp = useBreakpoints();
    const [commentCount, setCommentCount] = useState<number>(0);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const hasTodos = workOrder.todos.length;
    const isDraft = workOrder.work_order_status === WorkOrderStatusKey.DRAFT;
    const isSubmitted =
        workOrder.work_order_status === WorkOrderStatusKey.SUBMITTED;
    const isRejected =
        workOrder.work_order_status === WorkOrderStatusKey.REJECTED;
    const isPending =
        workOrder.work_order_status === WorkOrderStatusKey.PENDING;
    const isAssigned =
        workOrder.work_order_status === WorkOrderStatusKey.ASSIGNED;
    const isComplete =
        workOrder.work_order_status === WorkOrderStatusKey.WORK_COMPLETE;
    const isClosed = workOrder.work_order_status === WorkOrderStatusKey.CLOSED;
    // const isBilled = workOrder.work_order_status === WorkOrderStatusKey.BILLED;
    const isExported = !!workOrder?.export_date;
    const showEditButton = isPending || isComplete || isAssigned || isDraft;
    const isCustomer = user.hasPermission(1, 'Submit Work Order');

    const allToDosCompleted = workOrder.todos.every((todo) => todo.closed_date);

    // const saveWOBtnOptions = ['Create Work Order', 'Save'];

    useEffect(() => {
        if (isDraft) {
            setIsEditing(true);
        }
        return () => {
            subscription.current.unsubscribe();
        };
    }, [isDraft]);

    const tabs = useMemo(() => {
        const tabs: BottomSheetContainerProps['tabs'] = [
            { label: 'Overview', anchorTag: '#overview' },
            { label: 'Comments', anchorTag: '#comments', count: commentCount },
        ];
        if (!isCustomer) {
            tabs.splice(
                1,
                0,
                { label: 'To-Dos', anchorTag: '#todos' },
                {
                    label: 'Complaints',
                    anchorTag: '#service-issues',
                },
                { label: 'Billing', anchorTag: '#billing' }
            );
        }

        if (!bp.lg) {
            tabs.splice(1, 0, { label: 'Details', anchorTag: '#details' });
        }
        return tabs;
    }, [isCustomer, commentCount, bp.lg]);

    const overflowItems = useMemo(() => {
        const hasCreatePermissions = user.hasPermission(2, 'Work Orders');
        const hasDeletePermissions = user.hasPermission(3, 'Work Orders');

        const overflowItems: BottomSheetContainerProps['overflowItems'] = [];
        if (
            hasCreatePermissions &&
            !isClosed &&
            !showEditButton &&
            !isExported
        ) {
            overflowItems.push({
                name: 'Edit',
                onClick: () => {
                    setIsEditing(true);
                },
            });
        }

        if (
            (hasDeletePermissions && !isClosed && !isExported) ||
            (hasDeletePermissions && isDraft)
        ) {
            overflowItems.push({
                name: 'Delete',
                onClick: () => {
                    setActiveModals({
                        ...activeModals,
                        'delete-work-order': true,
                    });
                },
            });
        }
        return overflowItems;
    }, [user, isClosed, showEditButton, activeModals, isDraft, isExported]);

    // disable create or save buttons, reject submissions w/o all required details
    let isWorkOrderValid: boolean;

    if (workOrderEdit?.is_billable) {
        isWorkOrderValid =
            workOrderEdit?.title &&
            workOrderEdit?.description &&
            workOrderEdit?.due_date &&
            (!isClosing || workOrderEdit?.work_complete_date) &&
            workOrderEdit?.service?.id &&
            workOrderEdit?.service_type &&
            workOrderEdit?.cost_line_items?.length > 0;
    } else {
        isWorkOrderValid =
            workOrderEdit?.title &&
            workOrderEdit?.description &&
            workOrderEdit?.due_date &&
            (!isClosing || workOrderEdit?.work_complete_date) &&
            workOrderEdit?.service?.id &&
            workOrderEdit?.service_type;
    }

    const handleClick = () => {
        if (!isWorkOrderValid || isSubmitting) {
            setSubmissionAttempt(true);
            toasterService.newToast({
                message: 'Complete all of the required fields to continue.',
            });
        } else {
            setIsSubmitting(true);
            handleWOUpdate();
        }
    };

    const workOrderButtons = useMemo(() => {
        const buttons: BottomSheetContainerProps['buttons'] = [];
        if (!isEditing) {
            if (isSubmitted) {
                buttons.push({
                    label: 'Accept',
                    onClick: handleAccept,
                    icon: 'thumbs-up',
                });
                buttons.push({
                    label: 'Reject',
                    onClick: () => {
                        setActiveModals({
                            ...activeModals,
                            'reject-work-order': true,
                        });
                    },
                    icon: 'thumbs-down',
                });
            } else if (isComplete || isPending) {
                buttons.push({
                    label: 'Close Work Order',
                    disabled: !allToDosCompleted,
                    onClick: () => {
                        setActiveModals({
                            ...activeModals,
                            'close-work-order': true,
                        });
                    },
                    icon: 'check-circle',
                    tooltip: 'All To-Dos must be completed',
                });
            } else if (isClosed || isRejected) {
                buttons.push({
                    label: 'Reopen',
                    onClick: handleReopen,
                    tooltip: 'Reopen Work Order',
                });
            }

            if (showEditButton) {
                buttons.push({
                    label: 'Edit',
                    onClick: () => {
                        setIsEditing(true);
                        setIsClosing(false);
                    },
                });
            }
        } else {
            buttons.push({
                label: 'Cancel',
                color: 'secondary',
                onClick: () => {
                    if (isDraft) {
                        subscription.current = workOrdersService
                            .deleteWorkOrderById(workOrder.id)
                            .subscribe({
                                next: () => {
                                    hasBeenUpdated.current = true;
                                    closeModal();
                                },
                                error: sendError(),
                            });
                    } else {
                        setIsEditing(false);
                        setWorkOrderEdit(cloneDeep(workOrder));
                    }
                },
            });

            buttons.push({
                label: `${isDraft ? 'Create Work Order' : 'Save'}`,
                color: 'alternate',
                onClick: handleClick,
            });
        }
        return buttons;
    }, [
        workOrder,
        isEditing,
        isSubmitted,
        handleAccept,
        activeModals,
        isComplete,
        isPending,
        allToDosCompleted,
        isClosed,
        isRejected,
        showEditButton,
        isDraft,
        isExported,
        closeModal,
        handleWOUpdate,
        isSubmitting,
    ]);

    // useEffect(() => {
    //     const saveWoBtn = workOrderButtons.find(btn => saveWOBtnOptions.includes(btn.label));

    //     if (saveWoBtn !== undefined) {
    //         saveWoBtn.disabled = !isWorkOrderValid || isLoading
    //     }
    // }, [isLoading])

    function handleWOUpdate() {
        const { cost_line_items } = workOrderEdit;
        let hasErrors = false;

        cost_line_items.forEach((item) => {
            if (
                !item.billing_description ||
                !item.cost ||
                !item.unitcost ||
                !item.quantity ||
                !item.service ||
                !item.work_type
            ) {
                hasErrors = true;
            }
        });

        if (
            activePurchaseOrder?.funds_remaining &&
            calculatePOFundsRemaining(
                workOrderEdit?.cost_line_items,
                activePurchaseOrder
            ) < 0
        ) {
            toasterService.newToast({
                status: 'warning',
                message:
                    'Total cost of all line items exceeds remaining PO funds. Please resolve this before saving.',
            });
            setIsSubmitting(false);
        } else if (hasErrors) {
            toasterService.newToast({
                status: 'warning',
                message:
                    'All Cost Line Items must be filled out. Please resolve this before saving.',
            });
            setIsSubmitting(false);
        } else if (!isWorkOrderValid) {
            setIsSubmitting(false);
        } else {
            const workOrderPayload = createWorkOrderPayload(workOrderEdit);

            if (isDraft || isPending) {
                workOrderPayload.work_order_status = WorkOrderStatusKey.PENDING;
            }

            if (!workOrderPayload.assigned_to?.id) {
                workOrderPayload.assigned_to = {
                    id: user.employeeId,
                };
            }

            // Reset billing information at the save step so we can preserve the fields even when toggling "is_billable"
            if (!workOrderPayload.is_billable) {
                workOrderPayload.po_number = '';
                workOrderPayload.net_suite.id = null;
            }

            const removedCostLineItems = workOrder.cost_line_items.filter(
                (lineItem) =>
                    lineItem.id != null &&
                    workOrderEdit.cost_line_items.findIndex(
                        (item) => lineItem.id === item.id
                    ) < 0
            );
            const newCostLineItems = workOrderEdit.cost_line_items.filter(
                (lineItem) => lineItem.id === null
            );

            function updateCostLineItems(workOrder: WorkOrder) {
                return forkJoin({
                    removedLineItems: removedCostLineItems.length
                        ? forkJoin(
                              removedCostLineItems.map((item) =>
                                  workOrdersService.deleteWorkOrderCostLineItemById(
                                      item.id
                                  )
                              )
                          )
                        : of<any[]>([]),
                    newCostLineItems: newCostLineItems.length
                        ? forkJoin(
                              newCostLineItems.map((item) =>
                                  workOrdersService.createWorkOrderCostLineItem(
                                      createWorkOrderCostLineItemPayload(item)
                                  )
                              )
                          )
                        : of<CostLineItem[]>([]),
                    workOrder: of(workOrder),
                });
            }

            function updateWorkOrderCostLineItems({
                workOrder,
                newCostLineItems,
            }: {
                workOrder: WorkOrder;
                newCostLineItems: CostLineItem[];
            }) {
                const workOrderCopy = {
                    ...workOrder,
                    cost_line_items: [
                        ...workOrder.cost_line_items.filter(
                            (item) =>
                                removedCostLineItems.findIndex(
                                    (removed) => removed.id === item.id
                                ) < 0
                        ),
                        ...newCostLineItems,
                    ],
                };
                return of(workOrderCopy);
            }

            const observer = {
                next: (res: WorkOrder) => {
                    setWorkOrder(res);
                    setIsEditing(false);
                    // setIsLoading(false);

                    hasBeenUpdated.current = true;
                    if (onSuccess) {
                        onSuccess(res);
                    }
                    mixpanelCreateUpdateWorkOrder(isDraft);
                    toasterService.newToast({
                        status: 'success',
                        message: `Work order has been ${
                            isDraft ? 'created' : 'updated'
                        }`,
                    });
                    setIsSubmitting(false);
                },
                error: (res: any) => {
                    const resProperties = Object.getOwnPropertyNames(res);
                    const toastMessageList = [];

                    resProperties.forEach((prop) => {
                        if (res.hasOwnProperty(prop)) {
                            toastMessageList.push(
                                `${prop} -> ${res[prop]?.[0]}`
                            );
                        }
                    });

                    toasterService.newToast({
                        status: 'fail',
                        message:
                            toastMessageList.length > 0
                                ? toastMessageList.join('\n')
                                : 'Work Order save failed',
                    });
                    setIsSubmitting(false);
                },
            };

            // update associated todos - is_project_work and due_date
            if (workOrderPayload?.todos?.length) {
                let updateTodos: TodoPayload[] = workOrderPayload.todos.map(
                    (todo) => {
                        todo.is_project_work = workOrderPayload.is_project_work;
                        return todo;
                    }
                );

                // if the todo due date is after the work order, update to match work order
                if (workOrderPayload.due_date) {
                    updateTodos = updateTodos.map((todo) => ({
                        ...todo,
                        due_date:
                            !todo.is_complete &&
                            todo.due_date &&
                            new Date(todo.due_date).getTime() >
                                new Date(workOrderPayload.due_date).getTime()
                                ? workOrderPayload.due_date
                                : todo.due_date,
                    }));
                }

                const todoRequests: Observable<Todo>[] = updateTodos.map(
                    (todo: Todo) => {
                        const todoPayload = createTodoPayload(todo);
                        return todosService.updateTodoById(
                            todoPayload.id,
                            todoPayload
                        );
                    }
                );

                if (todoRequests.length) {
                    subscription.current = forkJoin(todoRequests)
                        .pipe(
                            switchMap(() =>
                                workOrdersService.updateWorkOrderById(
                                    workOrder.id,
                                    workOrderPayload
                                )
                            ),
                            switchMap(updateCostLineItems),
                            switchMap(updateWorkOrderCostLineItems)
                        )
                        .subscribe(observer);
                    return;
                }
            }

            subscription.current = workOrdersService
                .updateWorkOrderById(workOrder.id, workOrderPayload)
                .pipe(
                    switchMap(updateCostLineItems),
                    switchMap(updateWorkOrderCostLineItems)
                )
                .subscribe(observer);
        }
    }

    function handleAccept() {
        const workOrderPayload = createWorkOrderPayload(workOrder);
        workOrderPayload.work_order_status = WorkOrderStatusKey.PENDING;
        subscription.current = workOrdersService
            .updateWorkOrderById(workOrder.id, workOrderPayload)
            .subscribe({
                next: (workOrder) => {
                    setWorkOrder(workOrder);
                    setIsEditing(true);
                    hasBeenUpdated.current = true;
                    toasterService.newToast({
                        status: 'success',
                        message: `Work order has been accepted`,
                    });
                },
                error: sendError(),
            });
    }

    function handleReopen() {
        const workOrderPayload = createWorkOrderPayload(workOrder);
        if (
            workOrderPayload.todos == null ||
            workOrderPayload.todos.length == 0
        ) {
            // Pending = No To-dos assigned
            workOrderPayload.work_order_status = WorkOrderStatusKey.PENDING;
        } else if (allToDosCompleted) {
            // Work Complete = One or more To-dos assigned and they are all complete.
            workOrderPayload.work_order_status =
                WorkOrderStatusKey.WORK_COMPLETE;
        }
        workOrderPayload.close_date = null;
        subscription.current = workOrdersService
            .updateWorkOrderById(workOrder.id, workOrderPayload)
            .subscribe({
                next: (workOrder) => {
                    setWorkOrder(workOrder);
                    hasBeenUpdated.current = true;
                    toasterService.newToast({
                        status: 'success',
                        message: `Work Order #${workOrder.id} Successfully Reopened`,
                    });
                },
                error: sendError({
                    toastMessage: `Error in reopening work order #${workOrder.id}`,
                }),
            });
    }

    return (
        activeModals['bottom-sheet'] && (
            <ModalStack position="bottom-center">
                <Modal.BottomSheet.Container
                    title={
                        isDraft
                            ? 'Create New Work Order'
                            : `Work Order #${workOrder.id}`
                    }
                    icon="wrench"
                    tabs={tabs}
                    overflowItems={!isCustomer && overflowItems}
                    buttons={!isCustomer ? workOrderButtons : []}
                    onClose={() => closeModal(workOrder)}
                >
                    <Modal.BottomSheet.Main
                        className={`xl:tw-w-2/3 ${
                            hasTodos ? 'lg:tw-w-7/12' : ''
                        }`}
                    >
                        <Box
                            id="overview"
                            rounded
                            className="tw-p-4 sm:tw-p-10"
                        >
                            <WorkOrderOverview />
                        </Box>
                        {!bp.lg && (
                            <Box
                                id="details"
                                rounded
                                className="tw-space-y-6 tw-p-4 sm:tw-p-10"
                            >
                                <Text
                                    font="h2"
                                    color="primary"
                                    className="tw-mb-10"
                                >
                                    Work Order Details
                                </Text>
                                <WorkOrderDetailsPanel />
                            </Box>
                        )}
                        {!isCustomer && (
                            <>
                                <Box
                                    id="todos"
                                    rounded
                                    className="tw-p-4 sm:tw-p-10"
                                >
                                    <WorkOrderTodos />
                                </Box>
                                <Box
                                    id="service-issues"
                                    rounded
                                    className="tw-p-4 sm:tw-p-10"
                                >
                                    <WorkOrderServiceIssues />
                                </Box>
                                <Box
                                    id="billing"
                                    rounded
                                    className="tw-p-4 sm:tw-p-10"
                                >
                                    <WorkOrderBilling />
                                </Box>
                            </>
                        )}
                        <Box
                            id="comments"
                            rounded
                            className="tw-space-y-6 tw-p-4 sm:tw-p-10"
                        >
                            <Text font="h2" color="primary">
                                Comments
                            </Text>
                            <Comments
                                work_item_type="workorder"
                                item_id={workOrder.id}
                                isReadOnly={!isEditing}
                                onCommentCountUpdate={setCommentCount}
                            />
                        </Box>
                    </Modal.BottomSheet.Main>
                    {bp.lg && (
                        <Modal.BottomSheet.Details
                            className="lg:tw-w-[428px] xl:tw-w-[481px] 2xl:tw-w-[436px]"
                            title="Work Order Details"
                        >
                            <WorkOrderDetailsPanel />
                        </Modal.BottomSheet.Details>
                    )}
                </Modal.BottomSheet.Container>
            </ModalStack>
        )
    );
};
