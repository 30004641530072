// base
import { has } from 'lodash';
import { Component } from 'react';

// interfaces
import { Observable } from 'rxjs';
import { tap, map } from 'rxjs/operators';

import { FrequencyPeriod, RouteDefinition } from '@api-interfaces';
import { routesService } from '@apis';
import { timetableProccessor } from '@app/collections/routes/_helpers/time-table-processor';
import {
  TimeTable,
  TimeTableRow,
  TIME_TABLE_ROW_TYPE,
  PERIOD_MAP_TIME_TABLE_GROUP,
} from '@app/collections/routes/_helpers/time-table-processor/interfaces';
import { Badge, Icon, LoaderBlocks, Box, IconButtonFlat } from '@atoms';
import AccordionBase from '@components/AccordionBase/AccordionBase';
import Empty from '@components/Empty/Empty';
import {
  DetailsModalWrapper,
  ModalControl,
  ModalTitle,
  LeftSection,
  RightSection,
  ModalHeading,
  ModalContentSmall,
} from '@components/ModalDetail/ModalDetail';
import { App } from '@core/app-context/app.interfaces';

// services

// helpers
import {
  TimeUtils,
  isNumeric,
  numberWithCommas,
  toStandardTime,
} from '@helpers';
import { useAppContext } from '@hooks';

// components
import { MiniProfile } from '@new';

import AreaDetails from './area-details/area-details';
import { RouteModalServiceLog } from './service-log-tab';
import StartEndDetails from './start-end-details/start-end-details';

import Tabs from '@components/Tabs/Tabs';

import './RouteModal.scss';

interface AccordionGroup {
  groupName: string;
  groupPeriod: FrequencyPeriod;
  timeTableRows: TimeTableRow[];
}

type PeriodType = 'daily' | 'periodic';

interface Props {
  appState?: App.State;
  data: {
    id: number;
    highlightAreaId?: number;
    preselect?: {
      stopId: number;
      frequencyId: number;
      period: FrequencyPeriod;
    };
  };
  onClose?: () => void;
}

interface State {
  canViewVerifiableTasks: boolean;
  components: {
    [key: string]: {
      data: {
        route: RouteDefinition;
        timetable: TimeTable;
        coverage: {
          time: any;
          type: any;
          numberOfVerifiableAreas: number;
        };
      };
      fetched: boolean;
      failed: boolean;
      services: {
        load: Observable<any>;
      };
    };
  };
  isAreaView: boolean;
  selectedArea: TimeTableRow;
  isStartEndView: boolean;
  selectedStartEndRow: TimeTableRow;
}

interface Vars {
  subscriptions: {
    route: any;
  };
}

class RouteModalComponent extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      canViewVerifiableTasks: this.props.appState.user.hasPermission(
        1,
        'Verifiable Tasks'
      ),
      components: {
        route: {
          data: {
            route: null as RouteDefinition,
            timetable: null as TimeTable,
            coverage: null,
          },
          fetched: false,
          failed: false,
          services: {
            load: routesService
              .getRouteDefinitionWithStopsAndStopFrequenciesById(
                this.props.data.id,
                { field_set: 'no_stop_frequencies' },
                { limit: 100_000 }
              )
              .pipe(
                tap((routeDefinition) => {
                  if (!routeDefinition || !routeDefinition.id) {
                    this.setState((state) => {
                      state.components.route.failed = true;
                      return state;
                    });
                  }
                }),
                map((routeDefinition) => {
                  if (routeDefinition && routeDefinition.id) {
                    const timetable = timetableProccessor.processTimeTable(
                      routeDefinition,
                      {
                        sortPeriodic: 'service-date',
                        hideAllNA: true,
                        useAreaLinks:
                          this.props.appState.selectedContracts?.[0]
                            ?.use_area_links,
                      }
                    );

                    return {
                      route: routeDefinition,
                      timetable,
                      coverage: {
                        time: this.buildCoverageByTime(timetable),
                        type: this.buildCoverageByType(timetable),
                        numberOfVerifiableAreas: timetable.Groups.Daily.filter(
                          (item) => item.area.is_verifiable
                        ).length,
                      },
                    };
                  }
                  return {
                    route: null,
                    timetable: null,
                    coverage: {
                      time: null,
                      type: null,
                    },
                  };
                })
              ),
          },
        },
      },
      isAreaView: false,
      selectedArea: null,
      isStartEndView: false,
      selectedStartEndRow: null,
    };
  }

  vars: Vars = {
    subscriptions: {
      route: null,
    },
  };

  public componentDidMount() {
    this.vars.subscriptions.route =
      this.state.components.route.services.load.subscribe((res) => {
        this.setState(
          (state) => {
            state.components.route.data = res;
            state.components.route.fetched = true;
            return state;
          },
          () => {
            // if has preselect let's highlight and navigate to the task
            if (this.props.data.preselect) {
              const preselectRow = this.findPreselectRowByStopId(
                this.props.data.preselect.stopId
              );

              if (preselectRow) {
                this.setState({
                  isAreaView: true,
                  selectedArea: preselectRow,
                });
              }
            }
          }
        );
      });
  }

  public componentWillUnmount() {
    if (this.vars.subscriptions.route)
      this.vars.subscriptions.route.unsubscribe();
  }

  private findPreselectRowByStopId(stopId) {
    const mapProperty =
      PERIOD_MAP_TIME_TABLE_GROUP[this.props.data.preselect.period];

    if (stopId && mapProperty) {
      const found = this.state.components.route.data.timetable.Groups[
        mapProperty
      ].find((row) => row.id == stopId);
      if (found) return found;
    }

    return null;
  }

  public formatTotalTime(minutes) {
    if (isNumeric(minutes)) return `${minutes.toFixed(0)} mins`;
    return `${(0).toFixed(0)} mins`;
  }

  private buildCoverageByTime(timetable: TimeTable) {
    const dailyTaskTime = timetable.Summary.Covered.Daily;

    const periodic = {
      totalWeekly: timetable.Summary.Covered.Weekly,
      totalMonthly: timetable.Summary.Covered.Monthly,
      totalQuarterly: timetable.Summary.Covered.Quarterly,
      totalYearly: timetable.Summary.Covered.Yearly,
    };

    const averageDailyPeriodic = {
      averageWeekly: periodic.totalWeekly / 7,
      averageMonthly: periodic.totalMonthly / 30,
      averageQuarterly: periodic.totalQuarterly / 90,
      averageYearly: periodic.totalYearly / 365,
    };

    const averageDailyPeriodicTime =
      averageDailyPeriodic.averageWeekly +
      averageDailyPeriodic.averageMonthly +
      averageDailyPeriodic.averageQuarterly +
      averageDailyPeriodic.averageYearly;

    const other = {
      shiftPrep: timetable.Summary.Covered.ShiftPrep,
      break: timetable.Summary.Covered.Break,
      lunch: timetable.Summary.Covered.Lunch,
    };

    const otherTime = other.shiftPrep + other.break + other.lunch;

    const totalDailyTime = dailyTaskTime + averageDailyPeriodicTime + otherTime;

    return [
      {
        title: 'Daily Task Time',
        minutes: dailyTaskTime,
      },
      {
        title: 'Average Daily Periodic Time',
        minutes: Math.round(averageDailyPeriodicTime * 100) / 100,
        sub: [
          {
            title: 'Total Weekly',
            minutes: periodic.totalWeekly,
          },
          {
            title: 'Total Monthly',
            minutes: periodic.totalMonthly,
          },
          {
            title: 'Total Quarterly',
            minutes: periodic.totalQuarterly,
          },
          {
            title: 'Total Yearly',
            minutes: periodic.totalYearly,
          },
        ],
      },
      {
        title: 'Other Time',
        minutes: otherTime,
        sub: [
          {
            title: 'Shift Prep',
            minutes: other.shiftPrep,
          },
          {
            title: 'Break',
            minutes: other.break,
          },
          {
            title: 'Lunch',
            minutes: other.lunch,
          },
        ],
      },
      {
        title: 'Total Daily Time',
        hours: Math.round((totalDailyTime / 60) * 100) / 100,
        minutes: Math.round(totalDailyTime * 100) / 100,
      },
    ];
  }

  private buildCoverageByType(timetable: TimeTable) {
    const timeTableRows = [
      ...timetable.Groups.Daily,
      ...timetable.Groups.Weekly,
      ...timetable.Groups.Monthly,
      ...timetable.Groups.Quarterly,
      ...timetable.Groups.Yearly,
      ...timetable.Groups.AsNeeded,
    ];

    const mapAreas: Map<number, boolean> = new Map();
    let countAreas: number = 0;
    let countTasksDaily: number = 0;
    let countTasksPeriodic: number = 0;

    timeTableRows.forEach((timeTableRow) => {
      if (
        timeTableRow.type === TIME_TABLE_ROW_TYPE.REGULAR ||
        timeTableRow.type === TIME_TABLE_ROW_TYPE.PERIODIC
      ) {
        if (!mapAreas.has(timeTableRow.area.id))
          mapAreas.set(timeTableRow.area.id, true);

        if (has(timeTableRow, 'tasks.count')) {
          if (timeTableRow.type === TIME_TABLE_ROW_TYPE.REGULAR) {
            countTasksDaily += timeTableRow.tasks.count;
          } else {
            countTasksPeriodic += timeTableRow.tasks.count;
          }
        }
      }
    });

    countAreas = mapAreas.size;

    return {
      countAreas,
      countTasksDaily,
      countTasksPeriodic,
      verifiableStops: 0, // deprecated, replaced with numberOfVerifiableAreas method
    };
  }

  private convertTimeToDate(time): Date {
    const [hours, minutes, seconds] = time.split(':');

    let date = new Date();
    date = TimeUtils.setHours(new Date(), parseInt(hours));
    date = TimeUtils.setMinutes(date, parseInt(minutes));
    date = TimeUtils.setSeconds(date, parseInt(seconds));
    date = TimeUtils.setMilliseconds(date, 0);

    return date;
  }

  private convertDateToTime(date): string {
    return `${String(date.getHours()).padStart(2, '0')}:${String(
      date.getMinutes()
    ).padStart(2, '0')}:${String(date.getSeconds()).padStart(2, '0')}`;
  }

  private buildGroupsDaily(timetable: TimeTable): AccordionGroup[] {
    const timeTableRows = [
      timetable.Groups.Start,
      ...timetable.Groups.Daily,
      timetable.Groups.End,
    ];
    const accordionMap: Map<string, TimeTableRow[]> = new Map();

    // sometimes the task durations themselves are creater than 2 so lets check for that just incase
    const maxDuration = timeTableRows.reduce(
      (acc, cur) => (acc > cur.time.total ? acc : cur.time.total),
      0
    );
    const maxHours = Math.ceil(maxDuration / 60);
    const hourRange = maxHours > 2 ? maxHours : 2;

    let startDate: Date = this.convertTimeToDate(timeTableRows[0].time.start);
    let endDate: Date = TimeUtils.addHours(startDate, hourRange);
    let startTime = this.convertDateToTime(startDate);
    let endTime = this.convertDateToTime(endDate);
    let rangeKey = `${toStandardTime(startTime)} - ${toStandardTime(endTime)}`;

    timeTableRows.forEach((timeTableRow) => {
      const currentTime = timeTableRow.time.start;
      let currentDate = this.convertTimeToDate(currentTime);
      currentDate = TimeUtils.addDays(currentDate, timeTableRow.time.day); // can go into next day so check current day

      if (currentDate >= endDate) {
        startDate = new Date(endDate);
        endDate = TimeUtils.addHours(startDate, hourRange);
        startTime = this.convertDateToTime(startDate);
        endTime = this.convertDateToTime(endDate);
        rangeKey = `${toStandardTime(startTime)} - ${toStandardTime(endTime)}`;
      }

      if (!accordionMap.has(rangeKey)) {
        accordionMap.set(rangeKey, [timeTableRow]);
      } else {
        accordionMap.set(
          rangeKey,
          accordionMap.get(rangeKey).concat(timeTableRow)
        );
      }
    });

    const accordionGroups = [...accordionMap.entries()].map((entry) => {
      const [key, value] = entry;

      return {
        groupName: key,
        groupPeriod: 'DAILY' as FrequencyPeriod,
        timeTableRows: value,
      };
    });

    return accordionGroups;
  }

  private buildGroupsPeriodic(timetable: TimeTable): AccordionGroup[] {
    return [
      {
        groupName: 'Weekly',
        groupPeriod: 'WEEKLY' as FrequencyPeriod,
        timeTableRows: timetable.Groups.Weekly,
      },
      {
        groupName: 'Monthly',
        groupPeriod: 'MONTHLY' as FrequencyPeriod,
        timeTableRows: timetable.Groups.Monthly,
      },
      {
        groupName: 'Quarterly',
        groupPeriod: 'QUARTERLY' as FrequencyPeriod,
        timeTableRows: timetable.Groups.Quarterly,
      },
      {
        groupName: 'Yearly',
        groupPeriod: 'YEARLY' as FrequencyPeriod,
        timeTableRows: timetable.Groups.Yearly,
      },
      {
        groupName: 'As Needed',
        groupPeriod: 'AS_NEEDED' as FrequencyPeriod,
        timeTableRows: timetable.Groups.AsNeeded,
      },
    ].filter((accordionGroup: AccordionGroup) => {
      return accordionGroup.timeTableRows.length > 0;
    });
  }

  private buildAccordionContentDaily(
    accordionGroup: AccordionGroup
  ): JSX.Element {
    const useAreaLinks =
      this.props.appState.selectedContracts?.[0]?.use_area_links;

    return (
      <>
        {Boolean(accordionGroup.timeTableRows.length) &&
          accordionGroup.timeTableRows.map((row, i) => {
            /* Start, End */
            if (
              row.type === TIME_TABLE_ROW_TYPE.START_SHIFT ||
              row.type === TIME_TABLE_ROW_TYPE.END_SHIFT
            ) {
              return (
                <div
                  key={i}
                  className="row-clickable special-row div-tr"
                  onClick={() =>
                    this.setState({
                      isStartEndView: true,
                      selectedStartEndRow: row,
                    })
                  }
                >
                  <div className="div-td">
                    {`${toStandardTime(row.time.start)} - ${toStandardTime(
                      row.time.end
                    )} / `}
                    <span>{row.special.name}</span>
                  </div>
                  <div className="div-td">
                    {this.formatTotalTime(row.time.total)}
                  </div>
                  <div className="div-td">
                    <div>{has(row, 'area.name') ? row.area.name : 'N/A'}</div>
                  </div>
                  <div className="div-td" />
                </div>
              );
            }
            /* Regular, Break, Lunch */
            if (row.special) {
              // Break or Lunch
              return (
                <div key={i} className="special-row div-tr">
                  <div className="div-td">
                    {`${toStandardTime(row.time.start)} - ${toStandardTime(
                      row.time.end
                    )} / `}
                    <span>{row.special.name}</span>
                    {Boolean(row.special.sub === 'Unpaid') && (
                      <span> (Unpaid)</span>
                    )}
                  </div>
                  <div className="div-td">
                    {this.formatTotalTime(row.time.total)}
                  </div>
                  <div className="div-td flex-grow" />
                </div>
              );
            }
            const highlightRow =
              row.area.id === this.props.data.highlightAreaId;
            const preselectHighlight = Boolean(
              this.props.data.preselect &&
                this.props.data.preselect.period === 'DAILY' &&
                this.props.data.preselect.stopId == row.id
            );
            // Regular
            return (
              <div
                key={i}
                className={`div-tr ${
                  preselectHighlight ? 'preselect-highlight' : ''
                } ${!useAreaLinks ? 'row-clickable' : ''} ${
                  highlightRow
                    ? 'tw-bg-theme-primary-500-300 tw-bg-opacity-15'
                    : ''
                }`}
                onClick={() => {
                  if (!useAreaLinks) {
                    this.setState({
                      isAreaView: true,
                      selectedArea: row,
                    });
                  }
                }}
              >
                <div className="div-td">
                  {`${toStandardTime(row.time.start)} - ${toStandardTime(
                    row.time.end
                  )}`}
                </div>
                <div className="div-td">
                  {row.type == TIME_TABLE_ROW_TYPE.EMPTY
                    ? this.formatTotalTime(row.time.total ?? 0)
                    : this.formatTotalTime(row.tasks?.duration ?? 0)}
                </div>
                <div className="div-td">
                  <div>
                    {has(row, 'area.building.name')
                      ? row.area.building.name
                      : 'N/A'}
                  </div>
                  <div className="tw-italic">
                    {has(row, 'area.floor.name') ? row.area.floor.name : 'N/A'}
                  </div>
                </div>
                <div className="div-td">
                  <div>{row.area.name}</div>
                  <div className="tw-italic">{row.area.type}</div>
                </div>
                <div className="div-td tw-relative">
                  <div className="tw-relative">
                    {row.tasks ? row.tasks.count : '-'}
                  </div>
                </div>
                {row.area.is_verifiable && (
                  <div className="div-td">
                    <Icon
                      icon="qrcode"
                      className="tw-inset-y-0 tw-text-warning-500"
                    />
                  </div>
                )}
              </div>
            );
          })}
      </>
    );
  }

  private buildAccordionContentPeriodic(
    accordionGroup: AccordionGroup
  ): JSX.Element {
    return (
      <>
        {accordionGroup.timeTableRows.map((row, i) => {
          if (row.special) {
            // Training
            return (
              <div key={i} className="div-tr">
                <div className="training-title-row div-td">
                  {`${row.periodicRepeatText} / `}
                  <i className="fas fa-graduation-cap" />
                  <b>{row.special.name}</b>
                </div>
                <div className="div-td">
                  {this.formatTotalTime(row.time.total)}
                </div>
                <div className="training-desc-row div-td flex-grow">
                  {has(row, 'references.stop.description')
                    ? row.references.stop.description
                    : ''}
                </div>
              </div>
            );
          }
          const highlightRow = row.area.id === this.props.data.highlightAreaId;
          const preselectHighlight = Boolean(
            this.props.data.preselect &&
              this.props.data.preselect.period === accordionGroup.groupPeriod &&
              this.props.data.preselect.stopId == row.id
          );
          return (
            <div
              key={i}
              className={`row-clickable div-tr ${
                preselectHighlight ? 'preselect-highlight' : ''
              } ${
                highlightRow
                  ? 'tw-bg-theme-primary-500-300 tw-bg-opacity-15'
                  : ''
              }`}
              onClick={() =>
                this.setState({
                  isAreaView: true,
                  selectedArea: row,
                })
              }
            >
              <div className="div-td">{row.periodicRepeatText}</div>
              <div className="div-td">
                {this.formatTotalTime(row.time.total)}
              </div>
              <div className="div-td">
                <div>
                  {has(row, 'area.building.name')
                    ? row.area.building.name
                    : 'No Building'}
                </div>
                <div>
                  {has(row, 'area.floor.name')
                    ? row.area.floor.name
                    : 'No Floor'}
                </div>
              </div>
              <div className="div-td">
                <div>{row.area.name}</div>
                <div className="tw-italic">{row.area.type}</div>
              </div>
              <div className="div-td">{row.tasks ? row.tasks.count : '-'}</div>
            </div>
          );
        })}
      </>
    );
  }

  private buildAccordionPanels(
    accordionGroups: AccordionGroup[],
    periodType: PeriodType
  ): {
    className: string;
    bar: JSX.Element;
    content: JSX.Element;
  }[] {
    return accordionGroups.map((accordionGroup) => {
      return {
        className: 'accordion-panel',
        bar: (
          <div
            className={`t-background group-header div-tr${
              this.props.data.preselect &&
              accordionGroup.timeTableRows.some((timeTableRow) => {
                return Boolean(
                  timeTableRow.period == this.props.data.preselect.period &&
                    timeTableRow.id == this.props.data.preselect.stopId
                );
              })
                ? ' preselect-highlight'
                : ''
            }`}
          >
            <div className="div-td flex-grow">
              <b>{accordionGroup.groupName}</b>
              <i className="accordion-arrow fas fa-caret-up t-text-color" />
            </div>
          </div>
        ),
        content:
          periodType === 'daily'
            ? this.buildAccordionContentDaily(accordionGroup)
            : this.buildAccordionContentPeriodic(accordionGroup),
      };
    });
  }

  // Groups -> Accordion Content -> Accordion Panels -> Table
  private buildTable(
    timetable: TimeTable,
    periodType: PeriodType
  ): JSX.Element {
    const accordionGroups =
      periodType === 'daily'
        ? this.buildGroupsDaily(timetable)
        : this.buildGroupsPeriodic(timetable);
    const hasTasks =
      accordionGroups.reduce((acc, cur) => acc + cur.timeTableRows.length, 0) >
      0;

    return (
      <div className="table-scroll-wrapper t-brdr-alt">
        <div className="time-table-view div-table">
          <div className="div-thead">
            <div className="div-tr">
              <div className="div-th">Schedule</div>
              <div className="div-th">Total Estimated Time</div>
              <div className="div-th">Location</div>
              <div className="div-th">Area</div>
              <div className="div-th"># of Tasks</div>
              {this.state.canViewVerifiableTasks && (
                <div className="div-th">Verifiable</div>
              )}
            </div>
          </div>

          {hasTasks ? (
            <AccordionBase
              className="route-modal-accordion div-tbody"
              settings={{
                autoClose: false,
              }}
              data={{
                defaultOpenIndex: 0,
                panels: this.buildAccordionPanels(accordionGroups, periodType),
              }}
            />
          ) : (
            <Empty style={{ marginBottom: '24px' }}>
              No {periodType} found.
            </Empty>
          )}
        </div>
      </div>
    );
  }

  public render() {
    const { route, timetable, coverage } = this.state.components.route.data;

    return (
      <div id="routes_routes-detail">
        {!this.state.components.route.failed ? (
          this.state.components.route.fetched &&
          this.state.components.route.data.route &&
          this.state.components.route.data.route.id &&
          this.state.components.route.data.timetable ? (
            <DetailsModalWrapper>
              <LeftSection>
                <ModalControl>
                  <ModalTitle>{route?.name ?? 'N/A'}</ModalTitle>
                </ModalControl>

                <ModalControl>
                  <ModalHeading text="Program" />
                  {route.services?.length ? (
                    <Badge
                      label={route.services
                        .map((service) => service.name ?? 'N/A')
                        .join(', ')}
                    />
                  ) : (
                    <ModalContentSmall>N/A</ModalContentSmall>
                  )}
                </ModalControl>

                <ModalControl>
                  <ModalHeading text="Shift" />
                  {route.shift ? (
                    <Badge label={route.shift.name} color="warning" />
                  ) : (
                    <ModalContentSmall>N/A</ModalContentSmall>
                  )}

                  {/* <div className="coverage-hours">
                                        {coverage.time[3].hours} Hours ({coverage.time[3].minutes} Mins)
                                    </div> */}
                </ModalControl>

                <ModalControl>
                  <ModalHeading text="Coverage" />

                  <div className="coverage-group">
                    <div className="coverage-key">Areas</div>
                    <div className="coverage-value">
                      {coverage.type.countAreas}
                    </div>
                  </div>

                  <div className="coverage-group">
                    <div className="coverage-key">Daily Tasks</div>
                    <div className="coverage-value">
                      {
                        numberWithCommas(coverage.type.countTasksDaily).split(
                          '.'
                        )[0]
                      }
                    </div>
                  </div>

                  <div className="coverage-group">
                    <div className="coverage-key">Periodic Tasks</div>
                    <div className="coverage-value">
                      {
                        numberWithCommas(
                          coverage.type.countTasksPeriodic
                        ).split('.')[0]
                      }
                    </div>
                  </div>

                  <div className="coverage-group">
                    <div className="coverage-key">Verifiable Stops</div>
                    <div className="coverage-value">
                      {coverage.numberOfVerifiableAreas}
                    </div>
                  </div>
                </ModalControl>

                {/* <ModalHeading text="Coverage" /> */}

                {/* <AccordionBase
                                    className="coverage-accordion"
                                    settings={{
                                        autoClose: false,
                                    }}
                                    data={{
                                        panels: coverage.map(group => ({
                                            className: 'accordion-panel' + (Boolean(!group.sub || !group.sub.length) ? ' no-content' : ''),
                                            bar: (
                                                <div className="accordion-bar">
                                                    <div className="accordion-bar-title">{group.title}</div>
                                                    <div className="accordion-bar-minutes">{group.minutes} Mins</div>
                                                    {Boolean(group.sub && group.sub.length) && (
                                                        <i className="accordion-arrow fas fa-caret-left" />
                                                    )}
                                                </div>
                                            ),
                                            content: (
                                                <div className="accordion-content">
                                                    {Boolean(group.sub && group.sub.length) && (
                                                        <div className="accordion-content-items">
                                                            {group.sub.map((item, i) => (
                                                                <div key={i} className="accordion-content-item">
                                                                    {item.title}: {item.minutes} Mins
                                                                </div>
                                                            ))}
                                                        </div>
                                                    )}
                                                </div>
                                            ),
                                        })),
                                    }}
                                /> */}

                <ModalControl>
                  <ModalHeading text="Route Owner" />
                  {has(route, 'employee.person') ? (
                    <Box rounded className="tw-p-2">
                      <MiniProfile.Md person={route.employee.person} />
                    </Box>
                  ) : (
                    <ModalContentSmall>Unassigned</ModalContentSmall>
                  )}
                </ModalControl>

                <ModalControl>
                  <ModalHeading text="Route Supervisor" />
                  {has(route, 'supervisor.person') ? (
                    <Box rounded className="tw-p-2">
                      <MiniProfile.Md person={route.supervisor.person} />
                    </Box>
                  ) : (
                    <ModalContentSmall>Unassigned</ModalContentSmall>
                  )}
                </ModalControl>
              </LeftSection>

              <RightSection className="tw-relative">
                {this.props.onClose && (
                  <IconButtonFlat
                    icon="close"
                    type="secondary"
                    onClick={this.props.onClose}
                    className="tw-absolute tw-top-2 tw-right-2"
                  />
                )}
                {!this.state.isAreaView && !this.state.isStartEndView ? (
                  <>
                    <ModalControl>
                      <div className="tw-flex tw-justify-end">
                        <div
                          style={{ fontWeight: 600 }}
                          className="t-text-blue"
                        >
                          {`SHIFT: ${toStandardTime(
                            route.start_time
                          )} - ${toStandardTime(route.end_time)}`}
                        </div>
                      </div>
                    </ModalControl>
                    <Tabs
                      className="route-modal-tabs"
                      data={{
                        tabs: [
                          {
                            title: 'Daily Tasks',
                            content: this.buildTable(timetable, 'daily'),
                          },
                          {
                            title: 'Periodic Tasks',
                            content: this.buildTable(timetable, 'periodic'),
                          },
                          ...(this.props.data.highlightAreaId
                            ? [
                                {
                                  title: 'Service Log',
                                  content: (
                                    <RouteModalServiceLog
                                      routeId={this.props.data.id}
                                      areaId={this.props.data.highlightAreaId}
                                    />
                                  ),
                                },
                              ]
                            : []),
                        ],
                      }}
                      settings={{
                        design: 1,
                      }}
                    />
                  </>
                ) : this.state.isAreaView ? (
                  <AreaDetails
                    timetableRow={this.state.selectedArea}
                    preselect={this.props.data.preselect?.frequencyId}
                    onClickBack={() => {
                      this.setState({
                        isAreaView: false,
                        selectedArea: null,
                      });
                    }}
                  />
                ) : (
                  <StartEndDetails
                    timetableRow={this.state.selectedStartEndRow}
                    onClickBack={() =>
                      this.setState({
                        isStartEndView: false,
                        selectedStartEndRow: null,
                      })
                    }
                  />
                )}
              </RightSection>
            </DetailsModalWrapper>
          ) : this.state.components.route.fetched ? (
            <Empty>Could not display this route.</Empty>
          ) : (
            <LoaderBlocks />
          )
        ) : (
          <Empty>Could not find this route.</Empty>
        )}
      </div>
    );
  }
}

export const RouteModal = (props: Props) => {
  const { state } = useAppContext();
  return <RouteModalComponent appState={state} {...props} />;
};
