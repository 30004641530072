import {
	ServiceAreasSummary,
	GetServiceAreasSummaryParams,
	GetServiceAreaCardParams,
	ServiceAreaCardPagination,
} from '@api-interfaces';
import { Params } from '@helpers';
import { virtualBrownClient } from '@services';

// helpers

// interfaces

const BASE_URL = 'serviceareas/summary';

class ServiceAreasSummaryService {
	getSummaries<T extends ServiceAreasSummary.Chart>(
		params: GetServiceAreasSummaryParams<T>
	) {
		const query: any = {
			...params,
			chart: params.chart?.join(','),
		};
		return virtualBrownClient.get<ServiceAreasSummary.SummaryReturnType[T][]>(
			`${BASE_URL}/${Params.makeQueryString(query)}`
		);
	}

	getServiceAreaCards(params: GetServiceAreaCardParams) {
		const query = {
			...params,
			chart: 1,
		};
		return virtualBrownClient.get<ServiceAreaCardPagination>(
			`${BASE_URL}/${Params.makeQueryString(query)}`
		);
	}
}

export const serviceAreasSummaryService = new ServiceAreasSummaryService();
