import { Router, useLocation } from '@reach/router';
import {
    lazy,
    Suspense,
    useState,
    useEffect,
    useRef,
    useCallback,
} from 'react';
import ReactGA from 'react-ga4';

// services
import { Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { Account } from '@api-interfaces';
import { accountsService, tokenService } from '@apis';

// helpers

import { ESATTakeSurveyPage } from '@app/_new/pages/esat/survey';
import { DevTools } from '@components/_globals/dev-tools';
import { SplashScreen } from '@components/SplashScreen';
import {
    LocalStorageName,
    StoredAppData,
} from '@core/app-context/app.interfaces';
import { sendError } from '@core/helpers/send-error';
import { Storage } from '@helpers';
import { useAppContext } from '@hooks';
import {
    useRefreshTokenDaemon,
    useFetchContextGoalLinesAndParentPrograms,
} from '@hooks/core';
import { historyService, http, apiClient, toasterService } from '@services';

import Toaster from './misc/Toaster/Toaster';

// interfaces

// assets
import '@fortawesome/fontawesome-pro/scss/fontawesome.scss';
import '@fortawesome/fontawesome-pro/scss/light.scss';
import '@fortawesome/fontawesome-pro/scss/regular.scss';
import '@fortawesome/fontawesome-pro/scss/solid.scss';
import '@fortawesome/fontawesome-pro/scss/duotone.scss';
import '@fortawesome/fontawesome-pro/scss/v4-shims.scss';
import './app.css';
import '@insite-next/mocha/dist/index.css';
import { mixpanelSuccessfulLogin } from '@app/mixpanel/MixpanelPageTrack.tsx';

// components
const LazyLogin = lazy(() => import(/* webpackChunkName: "Login" */ './login'));
const LazyHome = lazy(() => import(/* webpackChunkName: "Home" */ './main'));
const ServiceValidationsStatusPage = lazy(() =>
    import('@app/_new/pages/servicevalidation/status').then(
        ({ ServiceValidationsStatusPage }) => ({
            default: ServiceValidationsStatusPage,
        })
    )
);

const newPaths: RegExp[] = [
    /^\/$/,
    /\/home$/,
    /\/governance$/,
    /\/quality$/,
    /\/people$/,
    /\/safety$/,
    /\/financials$/,
    /\/activity$/,
    /\/kpi$/,
    /\/gmp-audits/,
    /\/site-scorecards$/,
    /\/esat/,
    /\/quality\/work-orders/,
    /\/quality\/occupant-report/,
    /\/quality\/service-validations/,
    /\/quality\/failed-verifications/,
    /\/financials\/billing-approvals/,
    /\/financials\/billing\//,
    /\/financials\/hours/,
    /\/financials\/inventory-management/,
    /\/financials\/asset-management/,
    /\/safety\/incidents/,
    /\/photo-browser/,
    /\/quality\/visual-scope-of-work/,
];

// ⚠️ DO NOT CHANGE - configured by 4insite team
export const serviceValidationStatusPath = '/servicevalidation/status';

function initializeGoogleAnalytics(account: Account) {
    if (process.env.HOST_ENV === 'prod') {
        ReactGA.initialize('G-QXNR2F67X5', {
            gtagOptions: {
                user_id: account.user.id.toString(),
                position_title:
                    account.employee?.positions
                        ?.map((pos) => pos?.name)
                        ?.join(', ') ?? '',
                page: window.location.pathname,
            },
        });
    }
}

const publicPathsRegExes = [
    /esat\/survey\/?/gi,
    new RegExp(serviceValidationStatusPath, 'gi'),
];

const RootComponent = () => {
    useRefreshTokenDaemon();
    useFetchContextGoalLinesAndParentPrograms();
    const location = useLocation();
    const { state, dispatch } = useAppContext();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const subscriptions = useRef<{ [key: string]: Subscription }>({});

    // this useEffect will trigger when a new user is set by the org picker and will navigate back to /
    // useEffect(() => {
    //     if (state.user) {
    //         historyService.navigate('/');
    //     }
    // }, [state.user]);

    useEffect(() => {
        const localStorage: StoredAppData = Storage.get(LocalStorageName);
        if (publicPathsRegExes.some((regex) => regex.test(location.pathname))) {
            // do nothing since we want this path to be public facing
            return;
        }
        if (!localStorage?.refreshToken) {
            historyService.navigate('/login');
            return;
        }

        subscriptions.current['re-authenticate'] = tokenService
            .getAccessToken({ refresh: localStorage.refreshToken })
            .pipe(
                switchMap(({ access, refresh }) => {
                    http.token = access;
                    return accountsService.login({ refresh });
                })
            )
            .subscribe({
                next: (loginResults) => {
                    initializeGoogleAnalytics(loginResults);

                    dispatch({
                        type: 'SET_CURRENT_USER',
                        payload: loginResults,
                    });

                    setIsLoggedIn(true);
                    if (/\/login\/?$/.test(location.pathname)) {
                        historyService.navigate('/');
                    } else {
                        historyService.navigate(
                            location.pathname + location.search
                        );
                    }
                },
                error: (err: unknown) => {
                    if (
                        typeof err === 'object' &&
                        err?.error ===
                            "Login failed. local variable 'user' referenced before assignment"
                    ) {
                        sendError({
                            toastMessage: 'Session has expired',
                            callback: logout,
                        })(err);
                        return;
                    }
                    logout();
                },
            });

        return () => {
            Object.values(subscriptions.current).forEach((subscription) =>
                subscription.unsubscribe()
            );
        };
    }, []);

    const logout = useCallback(() => {
        setIsLoggedIn(false);
        Storage.remove(LocalStorageName);
        if (state.user) {
            subscriptions.current.logout = accountsService
                .logout({ refresh: state.user.refreshToken })
                .subscribe();
        }
        dispatch({
            type: 'SET_CURRENT_USER',
            payload: null,
        });
        dispatch({
            type: 'SET_LOGOUT_CALLBACK',
            payload: () => {},
        });
        http.token = null;
        http.logout = null;
        apiClient.setOptions(null);
        http.refreshTokenDaemon?.close();
        historyService.navigate('/login');
    }, [state.user]);

    useEffect(() => {
        if (isLoggedIn) {
            const { user } = state;

            // if no proper role profile set, log them out
            if (user && !Number.isInteger(user.roleProfile?.id)) {
                toasterService.newToast({
                    message: 'User does not have a role profile setup.',
                });
                logout();
            }
        }
        dispatch({
            type: 'SET_LOGOUT_CALLBACK',
            payload: logout,
        });
    }, [isLoggedIn, state.user, logout]);

    function login(email: string, password: string) {
        subscriptions.current.login = accountsService
            .login({ email, password })
            .subscribe({
                next: (user) => {
                    initializeGoogleAnalytics(user);
                    dispatch({
                        type: 'SET_CURRENT_USER',
                        payload: user,
                    });
                    mixpanelSuccessfulLogin();
                    setIsLoggedIn(true);
                    historyService.navigate('/');
                },
                error: sendError({
                    toastMessage: 'Email or Password is invalid',
                    callback: logout,
                }),
            });
    }

    function validate(email: string, password: string) {
        if (email && password) {
            login(email.trim(), password);
        } else {
            logout();
        }
    }

    const { pathname } = location;

    let appMainClassName = 'app-main';
    appMainClassName += newPaths.some((exp) => pathname.match(exp))
        ? ' app-main-new-page tw-bg-theme-neutral-200-900'
        : ' app-main-old-page t-background';

    return (
        <>
            {/* Render Components */}
            <Suspense fallback={<SplashScreen />}>
                <Router
                    primary
                    data-is-ds2={state.isDs2}
                    id="main-app-router"
                    className={appMainClassName}
                >
                    {isLoggedIn && state.user && <LazyHome path="*" />}
                    {!isLoggedIn && (
                        <LazyLogin validate={validate} path="/login" />
                    )}
                    <ESATTakeSurveyPage path="/esat/survey/*" />
                    <ServiceValidationsStatusPage
                        path={`${serviceValidationStatusPath}/*`}
                    />
                </Router>
            </Suspense>

            {/* Toaster */}
            <div className="global-toaster">
                <Toaster />
            </div>

            {process.env.NODE_ENV === 'development' && <DevTools />}
        </>
    );
};

export default RootComponent;
