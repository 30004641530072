import { Badge, ScoringThumb } from '@atoms';

export type GMP_AUDIT_BADGE_TYPE = 'pass' | 'fail' | 'pending' | 'exempt';

interface GmpAuditBadgeTypProps {
  type: GMP_AUDIT_BADGE_TYPE;
}

export const GmpAuditBadgeType = ({ type }: GmpAuditBadgeTypProps) => {
  switch (type) {
    case 'pass':
      return <ScoringThumb icon="thumbs-up" checked disabled />;
    case 'fail':
      return <ScoringThumb icon="thumbs-down" checked disabled />;
    default:
      return (
        <Badge
          label="N/A"
          className="tw-text-neutral-600 tw-bg-neutral-600 tw-bg-opacity-15
            dark:tw-text-neutral-400 dark:tw-bg-neutral-400 dark:tw-bg-opacity-15"
        />
      );
  }
};
