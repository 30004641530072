import { Badge } from '@insite-next/mocha';
import { ComponentProps } from 'react';

export type Platform = 'WEB' | 'MANAGER_MOBILE' | 'ASSOCIATE_MOBILE';

export type ReleaseType = 'FEATURE' | 'FEATURE_ENHANCEMENT' | 'BUG_FIX';

export const RELEASE_TYPES: {
	[key in ReleaseType]: {
		name: string;
		color: ComponentProps<typeof Badge>['color'];
	};
} = {
	FEATURE: {
		name: 'New Feature',
		color: 'success',
	},
	FEATURE_ENHANCEMENT: {
		name: 'Feature Enhancement',
		color: 'purple',
	},
	BUG_FIX: {
		name: 'Bug Fix',
		color: 'danger',
	},
};

export const PLATFORMS: {
	[key in Platform]: string;
} = {
	WEB: 'Web',
	MANAGER_MOBILE: 'Mobile - Manager App',
	ASSOCIATE_MOBILE: 'Mobile - Associate App',
};
