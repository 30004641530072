import { WorkItem } from '@api-interfaces';

const anonymous = 'Anonymous';

export function getSubmitter(
	workItem: WorkItem
): WorkItem['employee_submitted_by']['person'] {
	if (workItem?.employee_submitted_by?.person) {
		return workItem.employee_submitted_by.person;
	}
	if (workItem?.guest) {
		return {
			first_name: workItem.guest.first_name || anonymous,
			last_name: workItem.guest.last_name ?? '',
			email_address: workItem.guest.email ?? '',
			phone_number: workItem.guest.phone ?? '',
			photo_url: '',
			id: null,
		};
	}
	return {
		first_name: anonymous,
		last_name: '',
		email_address: '',
		phone_number: '',
		photo_url: '',
		id: null,
	};
}
