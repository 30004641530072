import { ForwardedRef, forwardRef, useState, useMemo } from 'react';
import * as React from 'react';

// components
import { CharacterCounter } from '../../character-counter';
import { ErrorText } from '../error-text';
import { InputText } from '../input-text';
import { Label } from '../label';
import { TextArea } from '../textarea';

// interfaces
import {
    FieldInputTextProps,
    FieldInput,
    FieldTextArea,
} from './field-input-text.interfaces';

export const FieldInputText = forwardRef<
    HTMLInputElement | HTMLTextAreaElement,
    FieldInputTextProps
>(
    (
        {
            id,
            label,
            labelFont,
            fieldType,
            error,
            required,
            onChange,
            max: maxChars,
            ...props
        },
        ref
    ) => {
        const max: number = useMemo(() => {
            if (typeof maxChars === 'string') {
                if (Number.isInteger(parseInt(maxChars, 10))) {
                    return parseInt(maxChars, 10);
                }
                return 0;
            }
            return maxChars || 0;
        }, [maxChars]);

        const [count, setCount] = useState(
            () =>
                ((props.value as string)?.length ||
                    (props.defaultValue as string)?.length) ??
                0
        );

        function handleOnChange(
            e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        ) {
            if (max > 0) {
                setCount(e.target.value.length);
            }
            if (onChange) {
                onChange(e as React.ChangeEvent<any>);
            }
        }

        return (
            <fieldset
                data-ds2="field-input-text"
                className="tw-w-full tw-space-y-1.5"
            >
                <div className="tw-flex tw-justify-between">
                    <Label
                        htmlFor={id}
                        label={label}
                        font={labelFont}
                        required={required}
                    />
                    {max > 0 && <CharacterCounter count={count} max={max} />}
                </div>
                {fieldType === 'input' ? (
                    <InputText
                        id={id}
                        ref={ref as ForwardedRef<HTMLInputElement>}
                        error={!!error}
                        onChange={handleOnChange}
                        {...(props as FieldInput)}
                    />
                ) : (
                    <TextArea
                        id={id}
                        ref={ref as ForwardedRef<HTMLTextAreaElement>}
                        error={!!error}
                        onChange={handleOnChange}
                        {...(props as FieldTextArea)}
                    />
                )}
                <ErrorText error={error} />
            </fieldset>
        );
    }
);

FieldInputText.defaultProps = {
    labelFont: 'body-sm',
    fieldType: 'input',
};
