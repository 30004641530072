// interfaces
import { ServiceLink } from '@api-interfaces';

// services
import { virtualBrownClient } from '@core/services/django-client';

// helpers
import { Params } from '@helpers';
import { PaginationParams, PaginationResponse } from '@models';

// constants
const BASE_URL = 'contracts';

const noOpts = {
	noProvider: true,
	noCustomer: true,
	noContract: true,
	noTag: true,
};

class ServiceLinksService {
	public getServiceLinks(
		keys: {
			contract: number;
		},
		params?: {} & PaginationParams,
		settings?: {
			noOptions?: boolean;
		}
	) {
		const queryString = Params.makeQueryString(params);
		return virtualBrownClient.get<PaginationResponse<ServiceLink>>(
			`${BASE_URL}/${keys.contract}/servicelinks/${queryString}`,
			settings && settings.noOptions ? noOpts : {}
		);
	}
}

export const serviceLinksService = new ServiceLinksService();
