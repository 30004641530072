import { useRef } from 'react';
import * as React from 'react';

// components
import { Subscription } from 'rxjs';

import { Media } from '@api-interfaces';
import { mediaService } from '@apis';
import { Label } from '@atoms/form-ds2/label';
import { Attachment } from '@components/_new/_form/attachments/components/Attachment';

// services
import { toasterService } from '@core/services';

// helpers
import { sendError } from '@helpers';
import { useAppContext } from '@hooks';
import { Button } from '@new';

// interfaces
import { ButtonProps } from '@new/Button/button.interfaces';

interface Props {
  attachments: (Media & { isAfter: boolean })[];
  onDelete: (id: number) => void;
  onUpload?: (media: Media & { isAfter: boolean }) => void;
  title?: string;
  className?: string;
  buttonProps?: Omit<ButtonProps, 'icon' | 'onClick'>;
  onClick?: (id: number) => void;
  onMediaUpdate?: (data: { id: number; status: string }) => void;
  accept?: string;
}

export const AddAttachment = ({
  onClick,
  onDelete,
  onUpload,
  title,
  className,
  attachments,
  buttonProps,
  onMediaUpdate,
  accept,
}: Props) => {
  const buttonRef = useRef<HTMLInputElement>(null);
  const {
    state: { user },
  } = useAppContext();
  const subscription = useRef(new Subscription());

  function onChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { files } = e.target;
    if (!files || files.length === 0) {
      return;
    }
    subscription.current = mediaService
      .upload(files[0], {
        employeeId: user.employeeId,
        organizationId: user.organization.id,
      })
      .subscribe({
        next: (res) => {
          toasterService.newToast({
            status: 'success',
            message: 'Image has been uploaded',
          });
          const attachment = {
            ...res,
            isAfter: false,
          };
          onUpload(attachment);
        },
        error: sendError(),
      });
  }

  function onUploadClick(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    if (buttonRef.current) {
      buttonRef.current.click();
    }
  }

  function fullOnDelete(id: number) {
    onDelete(id);
  }

  return (
    <div className={`tw-flex tw-flex-col tw-w-full ${className}`}>
      <Label label={title} />
      <div className="form-common-scroll tw-min-w-[200px] tw-w-full">
        {attachments.length > 0 && (
          <ul className="tw-space-y-2 tw-mb-2">
            {attachments.map((attachment) => (
              <li key={attachment.id}>
                <Attachment
                  handleStatus={onMediaUpdate ?? undefined}
                  onClick={onClick}
                  onDelete={fullOnDelete}
                  attachment={attachment}
                />
              </li>
            ))}
          </ul>
        )}
      </div>
      {onUpload && (
        <div className="tw-w-full">
          <input
            className="tw-hidden"
            ref={buttonRef}
            type="file"
            accept={accept ?? '*'}
            onChange={onChange}
          />
          <Button
            onClick={onUploadClick}
            icon={['far', 'plus']}
            {...buttonProps}
          />
        </div>
      )}
    </div>
  );
};

AddAttachment.defaultProps = {
  buttonProps: { label: 'Add File' },
  hasStatus: false,
  title: 'Add Attachment (optional)',
  className: 'tw-min-w-[312px]',
};
