// interfaces
import { Observable } from 'rxjs';

import {
	V2AreaTemplate,
	V2AreaLink,
	V2TaskBundleDef,
	TaskDef,
	Frequency,
	FrequencyPeriod,
	AreaGroup,
} from '@api-interfaces';

// services
import { virtualBrownClient } from '@core/services/django-client';

// helpers
import { Params } from '@helpers';
import { CommonParams, PaginationParams, PaginationResponse } from '@models';

import { sowOriginal } from './sow-original';

// constants
const BASE_URL = 'v2/contracts';

const noOpts = {
	noProvider: true,
	noContract: true,
	noCusomter: true,
	noTag: true,
};

class V2ContractsService {
	readonly sowOriginal = sowOriginal;

	/* Area Templates */

	public getV2ContractAreaTemplates(
		contract_id: number,
		params: CommonParams
	): Observable<PaginationResponse<V2AreaTemplate>> {
		return virtualBrownClient.get(
			`${BASE_URL}/${contract_id}/area_templates/${Params.makeQueryString({
				...params,
			})}`,
			noOpts
		);
	}

	public getV2ContractAreaTemplateById(
		contract_id: number,
		id: number
	): Observable<V2AreaTemplate> {
		return virtualBrownClient.get(
			`${BASE_URL}/${contract_id}/area_templates/${id}/`,
			noOpts
		);
	}

	public postV2ContractAreaTemplate(
		contract_id: number,
		payload: Pick<V2AreaTemplate, 'name'> & {
			master_area_type: Pick<V2AreaTemplate['master_area_type'], 'id'>;
		}
	): Observable<V2AreaTemplate> {
		return virtualBrownClient.post(
			`${BASE_URL}/${contract_id}/area_templates/`,
			payload
		);
	}

	public putV2ContractAreaTemplate(
		contract_id: number,
		id,
		payload: Pick<V2AreaTemplate, 'name'> & {
			master_area_type: Pick<V2AreaTemplate['master_area_type'], 'id'>;
		}
	): Observable<V2AreaTemplate> {
		return virtualBrownClient.put(
			`${BASE_URL}/${contract_id}/area_templates/${id}/`,
			payload
		);
	}

	/* Area Links */

	public getV2ContractAreaLinks(
		contract_id: number,
		params: {
			parent_area?: number;
		} & PaginationParams
	): Observable<PaginationResponse<V2AreaLink>> {
		return virtualBrownClient.get(
			`${BASE_URL}/${contract_id}/area_links/${Params.makeQueryString({
				...params,
			})}`,
			noOpts
		);
	}

	public postV2ContractAreaLink(
		contract_id: number,
		params: {
			include_children?: boolean;
		},
		payload: {
			areas: number[];
		}
	): Observable<V2AreaLink> {
		return virtualBrownClient.post(
			`${BASE_URL}/${contract_id}/area_links/${Params.makeQueryString({
				...params,
			})}`,
			payload
		);
	}

	public putV2ContractAreaLink(
		contract_id: number,
		id: number,
		payload: {
			area_template: Pick<V2AreaLink['area_template'], 'id'>;
		}
	): Observable<V2AreaLink> {
		return virtualBrownClient.put(
			`${BASE_URL}/${contract_id}/area_links/${id}/`,
			payload
		);
	}

	public deleteV2ContractAreaLink(
		contract_id: number,
		id: number,
		params: {
			include_children?: boolean;
		}
	): Observable<string> {
		return virtualBrownClient.delete(
			`${BASE_URL}/${contract_id}/area_links/${id}/${Params.makeQueryString({
				...params,
			})}`
		);
	}

	// Verifiable area

	public postV2ContractAreaVerifiable(
		contract_id: number,
		params: {
			include_children?: boolean;
		},
		payload: {
			areas: number[];
		}
	): Observable<V2AreaLink> {
		return virtualBrownClient.post(
			`${BASE_URL}/${contract_id}/area_verifiable/${Params.makeQueryString({
				...params,
			})}`,
			payload
		);
	}

	public deleteV2ContractAreaVerifiable(
		contract_id: number,
		id: number,
		params: {
			include_children?: boolean;
		}
	): Observable<string> {
		return virtualBrownClient.delete(
			`${BASE_URL}/${contract_id}/area_verifiable/${id}/${Params.makeQueryString(
				{ ...params }
			)}`
		);
	}

	/* Task Bundle Defs */

	public getV2ContractTaskBundles(
		contract_id: number,
		params: CommonParams
	): Observable<PaginationResponse<V2TaskBundleDef>> {
		return virtualBrownClient.get(
			`${BASE_URL}/${contract_id}/taskbundledefs/${Params.makeQueryString({
				...params,
			})}`,
			noOpts
		);
	}

	public getV2ContractTaskBundleDefById(
		contract_id: number,
		params: {
			id: number;
		}
	): Observable<V2TaskBundleDef> {
		return virtualBrownClient.get(
			`${BASE_URL}/${contract_id}/taskbundledefs/${params.id}/`,
			noOpts
		);
	}

	public postV2ContractTaskBundleDef(
		contract_id,
		payload: Pick<V2TaskBundleDef, 'name'> & {
			area_type: Pick<V2TaskBundleDef['area_type'], 'id'>;
			task_defs?: Pick<TaskDef, 'id'>[];
		}
	): Observable<V2TaskBundleDef> {
		return virtualBrownClient.post(
			`${BASE_URL}/${contract_id}/taskbundledefs/`,
			payload
		);
	}

	public putV2ContractTaskBundleDef(
		contract_id: number,
		id: number,
		payload: Pick<V2TaskBundleDef, 'name'> & {
			area_type?: Pick<V2TaskBundleDef['area_type'], 'id'>;
			task_defs?: Pick<V2TaskBundleDef['task_defs'][number], 'id'>[];
			frequencies?: Pick<V2TaskBundleDef['frequencies'][number], 'id'>[];
			shifts?: Pick<V2TaskBundleDef['shifts'][number], 'id'>[];
			positions?: Pick<V2TaskBundleDef['positions'][number], 'id'>[];
		}
	): Observable<V2TaskBundleDef> {
		return virtualBrownClient.put(
			`${BASE_URL}/${contract_id}/taskbundledefs/${id}/`,
			payload
		);
	}

	public deleteV2ContractTaskBundleDef(
		contract_id: number,
		id: number
	): Observable<string> {
		return virtualBrownClient.delete(
			`${BASE_URL}/${contract_id}/taskbundledefs/${id}/`
		);
	}

	/* Frequencies */

	public postV2ContractTaskBundleDefFrequency(
		contract_id,
		task_bundle_id,
		payload: {
			period: FrequencyPeriod;
			recurrence: number;
		}
	): Observable<Frequency> {
		return virtualBrownClient.post(
			`${BASE_URL}/${contract_id}/taskbundledefs/${task_bundle_id}/frequencies/`,
			payload
		);
	}

	public putV2ContractTaskBundleDefFrequency(
		contract_id: number,
		task_bundle_id: number,
		id: number,
		payload: {
			period: FrequencyPeriod;
			recurrence: number;
		}
	): Observable<Frequency> {
		return virtualBrownClient.put(
			`${BASE_URL}/${contract_id}/taskbundledefs/${task_bundle_id}/frequencies/${id}/`,
			payload
		);
	}

	/* Task Defs (possibly move to v2 service) */

	public getV2ServiceTaskDefs(
		params: {
			service?: number;
		} & PaginationParams
	): Observable<PaginationResponse<TaskDef>> {
		return virtualBrownClient.get(
			`v2/services/taskdefs/${Params.makeQueryString({ ...params })}`,
			noOpts
		);
	}

	public getContractAreaGroups(
		params: {
			contract?: number;
		} & PaginationParams
	): Observable<PaginationResponse<AreaGroup>> {
		return virtualBrownClient.get(
			`${BASE_URL}/area-groups/${Params.makeQueryString(params)}`
		);
	}

	getExcelReportSOW(): Observable<PaginationResponse<any>> {
		return virtualBrownClient.get(`${BASE_URL}/excel-report-sow/`);
	}
}

export const v2ContractService = new V2ContractsService();
