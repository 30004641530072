import { Address } from '@api-interfaces';
import {
	SummaryChart,
	TimeScale,
	GeoLocation,
	IsPass,
	SummaryParams,
} from '@models';

export interface TodosSummaryParams<T> extends SummaryParams<T> {
	parent_service?: number;
	customer_reports_only?: boolean;
	is_project_work?: boolean; // get project work counts
	servicearea?: number;
	areatype?: number;
	root_area?: number;
	verticals?: string | number;
}

export namespace TodosSummary {
	export enum Chart {
		GetTodoCounts = 0,
		GetTodoMonthlyTrend = 1,
		GetTodoCountsBySite = 2,
		GetTodoCountsByTag = 3,
		GetTodoCountsByBuilding = 4,
		GetTodoCountsByOrganization = 5,
		GetTodoCountsByShift = 6,
		GetTodoCountsByAreaType = 7,
		GetTodoCountsByMasterAreaType = 8,
		GetTodoCountsByVerticals = 9,
		GetTodoCountsByVerticalsOrganizationList = 10,
	}

	export interface TodoCounts extends IsPass {
		closed_count: number;
		closed_on_time_count: number;
		closed_on_time_percentage: string;
		closed_past_due_count: number;
		goal: number;
		last_closed_date: string;
		last_open_date: string;
		latest_todo_date: string;
		oldest_incomplete_date: string;
		open_count: number;
		open_past_due_count: number;
		open_past_due_percentage: string;
		past_due_count: number;
		total_count: number;
		is_project_work_count: number;
		is_project_work_open_count: number;
		is_project_work_open_past_due_count: number;
		is_project_work_closed_on_time_count: number;
		is_project_work_latest_todo_date: string;
	}

	export interface TodoCountsSummary extends SummaryChart<TodoCounts> {
		chart: 'GetTodoCounts';
		most_recent_record: '2020-09-09T19:53:09.226000Z';
		number: 'number';
	}

	export interface TodoMonthlyTrend {
		closed_count: number;
		closed_on_time_percent: number;
		closed_past_due_count: number;
		closed_past_due_percent: number;
		month: string;
		on_time_completion_count: number;
		past_due_count: number;
		total_count: number;
	}

	export interface TodoMonthlyTrendSummary
		extends SummaryChart<TodoMonthlyTrend[]> {
		chart: 'GetTodoMonthlyTrend';
		number: '1';
		time_scale: TimeScale;
	}

	export interface TodoCountsBySite extends Address, GeoLocation, IsPass {
		goal: number;
		has_tag_groups: boolean;
		id: number;
		name: string;
		on_time_completion_count: number;
		on_time_completion_percentage: number;
		organization_name: string;
		past_due_count: number;
		total_count: number;
	}

	export interface TodoCountsBySiteSummary
		extends SummaryChart<TodoCountsBySite[]> {
		chart: 'GetTodoCountsBySite';
		number: '2';
	}

	export interface TodoCountsByTag extends GeoLocation, IsPass {
		contract_id: number;
		id: number;
		name: string;
		on_time_completion_count: number;
		on_time_completion_percentage: number;
		past_due_count: number;
		total_count: number;
	}

	export interface TodoCountsByTagSummary
		extends SummaryChart<TodoCountsByTag[]> {
		chart: 'GetTodoCountsByTag';
		number: '3';
	}

	export interface TodoCountsByBuilding extends GeoLocation, IsPass {
		contract_id: number;
		id: number;

		name: string;
		on_time_completion_count: number;
		on_time_completion_percentage: number;
		past_due_count: number;
		total_count: number;
	}

	export interface TodoCountsByBuildingSummary
		extends SummaryChart<TodoCountsByBuilding[]> {
		chart: 'GetTodoCountsByBuilding';
		number: '4';
	}

	export interface TodoCountsByOrganization {
		closed_count: number;
		closed_on_time_count: number;
		closed_past_due_count: number;
		customer_id: number;
		customer_name: string;
		last_closed_date: string;
		last_open_date: string;
		oldest_incomplete_date: string;
		open_count: number;
		open_past_due_count: number;
		past_due_count: number;
		total_count: number;
	}

	export interface TodoCountsByOrganizationSummary
		extends SummaryChart<TodoCountsByOrganization[]> {
		chart: 'GetTodoCountsByOrganization';
		number: '5';
	}

	export interface TodoCountsByShift {
		customer_id: number;
		customer_name: string;
		shift_name: string;
		total_count: number;
		closed_past_due_count: number;
		closed_on_time_count: number;
	}

	export interface TodoCountsByShiftSummary
		extends SummaryChart<TodoCountsByShift[]> {
		chart: 'GetTodoCountsByShift';
		number: '6';
	}

	export interface TodoCountsByAreaType {
		area_type_id: number;
		area_type_name: string;
		total_count: number;
		closed_past_due_count: number;
		closed_on_time_count: number;
	}

	export interface TodoCountsByAreaTypeSummary
		extends SummaryChart<TodoCountsByAreaType[]> {
		chart: 'GetTodoCountsByAreaType';
		number: '7';
	}

	export interface TodoCountsByMasterAreaType {
		master_area_type_id: number;
		master_area_type_name: string;
		total_count: number;
		closed_past_due_count: number;
		closed_on_time_count: number;
	}

	export interface TodoCountsByMasterAreaTypeSummary
		extends SummaryChart<TodoCountsByMasterAreaType[]> {
		chart: 'GetTodoCountsByMasterAreaType';
		number: '8';
	}

	export interface TodoCountsByVerticals {
		id: number;
		name: string;
		count: number;
		closed_on_time_count: number;
		closed_past_due_count: number;
	}

	export interface TodoCountsByVerticalsSummary
		extends SummaryChart<TodoCountsByVerticals[]> {
		chart: 'GetTodoCountsByVerticals';
		number: '9';
	}

	export interface TodoCountsByVerticalsOrganizationList {
		id: number;
		name: string;
		count: number;
		closed_on_time_count: number;
		closed_past_due_count: number;
	}

	export interface TodoCountsByVerticalsOrganizationListSummary
		extends SummaryChart<TodoCountsByVerticalsOrganizationList[]> {
		chart: 'GetTodoCountsByVerticalsOrganizationList';
		number: '10';
	}
	export interface SummaryReturnType {
		[Chart.GetTodoCounts]: TodoCountsSummary;
		[Chart.GetTodoMonthlyTrend]: TodoMonthlyTrendSummary;
		[Chart.GetTodoCountsBySite]: TodoCountsBySiteSummary;
		[Chart.GetTodoCountsByTag]: TodoCountsByTagSummary;
		[Chart.GetTodoCountsByBuilding]: TodoCountsByBuildingSummary;
		[Chart.GetTodoCountsByOrganization]: TodoCountsByOrganizationSummary;
		[Chart.GetTodoCountsByShift]: TodoCountsByShiftSummary;
		[Chart.GetTodoCountsByAreaType]: TodoCountsByAreaTypeSummary;
		[Chart.GetTodoCountsByMasterAreaType]: TodoCountsByMasterAreaTypeSummary;
		[Chart.GetTodoCountsByVerticals]: TodoCountsByVerticalsSummary;
		[Chart.GetTodoCountsByVerticalsOrganizationList]: TodoCountsByVerticalsOrganizationListSummary;
	}
}
