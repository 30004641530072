import { TimeUtils } from '@core/helpers';
import { ComponentProps, ReactNode } from 'react';
import { Text } from '@insite-next/mocha';
import { S3Image } from '@api-interfaces';

export enum FeatureMode {
    AUDITS = 'Audits',
    VERIFICATIONS = 'Verifications',
}

export enum Filters {
    HIGHEST = 'highest',
    LOWEST = 'lowest',
    ALPHA = 'alphabetical',
    FAILING = 'failing',
    MISSING = 'missing',
    NEWEST = 'newest',
    OLDEST = 'oldest',
    MOST = 'most',
    LEAST = 'least',
}

export enum ReviewStatus {
    PENDING = 'In Review',
    REVIEWED = 'Reviewed',
    REVISED = 'Revised',
}

// use Macros to change defaults on load
export enum Macros {
    DEFAULT_FEATURE = FeatureMode.VERIFICATIONS,
    DEFAULT_FILTER = Filters.NEWEST,
}

export enum CommonValues {
    PASSING_VERIFICATION_SCORE = 4,
    PASSING_AREA_PERCENT_THRESHOLD = 90,
    PASSING_VERIFICATION_PPERCENT_THRESHOLD = 90,
}

export enum ViewModes {
    ASSOCIATES_VIEW = 'associate',
    MANAGERS_VIEW = 'manager',
    ALL_MODE = 'all',
}

export enum GroupModes {
    AREA_MODE = 'area',
    AREA_TYPE_MODE = 'areatype',
    ASSOCIATES_MODE = 'associates',
    MANAGERS_MODE = 'managers',
    ALL_MODE = 'All',
}

export const NA_REASONS = {
    IMAGE_BLURRY: 'Image is too blurry',
    IMAGE_DARK: 'Image is too dark',
    IMAGE_WRONG_AREA_ELEMENT: 'Image is wrong area element',
    IMAGE_UNUSABLE_FRAME: 'Unusable photo framing',
    IMAGE_INAPPROPRIATE: 'Inappropriate photo',
};

class VerificationHelpers {
    getDateFormat = (date) => {
        const daysSince = TimeUtils.differenceInCalendarDays(new Date(), date);
        const hoursSince = TimeUtils.differenceInHours(new Date(), date);
        const minutesSince = TimeUtils.differenceInMinutes(new Date(), date);
        const secondsSince = TimeUtils.differenceInSeconds(new Date(), date);

        const getDate = new Intl.DateTimeFormat('en-US', {
            month: 'short',
            day: '2-digit',
            year: 'numeric',
        }).format(new Date(date));

        if (minutesSince === 0) {
            return `${secondsSince} second${secondsSince !== 1 ? 's' : ''} ago`;
        }

        if (minutesSince < 60) {
            return `${minutesSince} minute${minutesSince !== 1 ? 's' : ''} ago`;
        }

        if (daysSince === 0) {
            return `${hoursSince} hour${hoursSince !== 1 ? 's' : ''} ago`;
        }

        if (daysSince === 1) {
            return `Yesterday`;
        }

        return `${getDate}`;
    };

    getDateRangeLabel = (dateRange) => {
        const day = (date) => {
            return new Date(date).getDate();
        };

        const month = (date) => {
            return new Date(date).getMonth();
        };

        const isToday =
            day(dateRange.from) === new Date().getDate() &&
            day(dateRange.to) === new Date().getDate();

        const isYesterday =
            day(dateRange.from) + 1 === new Date().getDate() &&
            day(dateRange.to) + 1 === new Date().getDate();

        const isThisWeek =
            TimeUtils.differenceInCalendarDays(new Date(), dateRange.from) <= 7;

        const isThisMonth =
            month(dateRange.from) === new Date().getMonth() &&
            month(dateRange.to) === new Date().getMonth();

        const isLastMonth =
            month(dateRange.from) + 1 === new Date().getMonth() &&
            month(dateRange.to) + 1 === new Date().getMonth();

        const isUpToCurrentDate =
            TimeUtils.differenceInCalendarDays(dateRange.to, new Date()) >= 0;

        const daysAgo = TimeUtils.differenceInCalendarDays(
            new Date(),
            dateRange.from
        );

        switch (dateRange.range) {
            case 'today':
                if (isToday) return 'Today';
                if (isYesterday) return 'Yesterday';
                if (daysAgo < 7)
                    return `Last ${dateRange.from.toLocaleDateString('en-US', { weekday: 'long' })}`;
                return dateRange.from.toLocaleDateString('en-US', {
                    weekday: 'long',
                    month: 'long',
                    day: 'numeric',
                });

            case 'current-week':
                if (isThisWeek) return 'This Week';
                return `${dateRange.from.toLocaleDateString('en-US', {
                    month: 'short',
                    day: 'numeric',
                })} to ${dateRange.to.toLocaleDateString('en-US', {
                    month: 'short',
                    day: 'numeric',
                })}`;

            case 'this-month':
                if (isThisMonth) return 'This Month';
                if (isLastMonth) return 'Last Month';
                return dateRange.from.toLocaleDateString('en-US', {
                    month: 'long',
                    year: 'numeric',
                });
            case 'last-month':
                if (isThisMonth) return 'This Month';
                if (isLastMonth) return 'Last Month';
                return dateRange.from.toLocaleDateString('en-US', {
                    month: 'long',
                    year: 'numeric',
                });

            case '3mo':
                if (isUpToCurrentDate) return 'Last 3 Months';
                return `${dateRange.from.toLocaleDateString('en-US', {
                    month: 'short',
                    year: '2-digit',
                })} to ${dateRange.to.toLocaleDateString('en-US', { month: 'short', year: '2-digit' })}`;
            case '6mo':
                if (isUpToCurrentDate) return 'Last 6 Months';
                return `${dateRange.from.toLocaleDateString('en-US', {
                    month: 'short',
                    year: '2-digit',
                })} to ${dateRange.to.toLocaleDateString('en-US', { month: 'short', year: '2-digit' })}`;
            case '1yr':
                if (isUpToCurrentDate) return 'Last 12 Months';
                return `${dateRange.from.toLocaleDateString('en-US', {
                    month: 'short',
                    year: 'numeric',
                })} to ${dateRange.to.toLocaleDateString('en-US', { month: 'short', year: 'numeric' })}`;
            case 'custom':
                return `${dateRange.from.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })} to ${dateRange.to.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })}`;
            default:
                return dateRange.range;
        }
    };

    public getAreaElementImageDescription = (
        image: Pick<
            S3Image,
            'ImageCaption' | 'IsRevised' | 'NotApplicable' | 'RevisedCaption'
        >,
        color: ComponentProps<typeof Text>['color'] = 'default'
    ): ReactNode => {
        if (!image) return '';
        if (!image.IsRevised) return image.ImageCaption;
        if (image.NotApplicable?.length && image.NotApplicable?.length > 0) {
            return (
                <ul className="tw-list-disc tw-pl-4 tw-text-neutral-100">
                    {image.NotApplicable.map((reason, id) => (
                        <li key={id}>
                            <Text font="body-md" color={color}>
                                {NA_REASONS[reason]}
                            </Text>
                        </li>
                    ))}
                </ul>
            );
        }
        return image.RevisedCaption;
    };
}

export const verificationHelpers = new VerificationHelpers();

export const sortAreaElements = (elements, filterMode) => {
    switch (filterMode) {
        case 'MOST':
            return [...elements].sort(
                (a, b) => b.failed_verifications - a.failed_verifications
            );
        case 'LOWEST':
            return [...elements].sort((a, b) => a.avg_score - b.avg_score);
        case 'HIGHEST':
            return [...elements].sort((a, b) => b.avg_score - a.avg_score);
        case 'ALPHA':
            return [...elements].sort((a, b) => a.name.localeCompare(b.name));
        default:
            return elements;
    }
};
