import { useState } from 'react';

// interfaces
import { Employee } from '@api-interfaces';

// components
import { Text } from '@atoms';
import { EmployeeDetails } from '@components/_new/MiniProfiles/_components/employee-details';

// helpers
import { useBreakpoints } from '@hooks';

// context
import { useAuditModalContext } from '@modals/_ds2/audit/_context/context';
import { MiniProfile } from '@new';

interface Props {
  employees: Employee[];
}

export const Employees = ({ employees }: Props) => {
  const [showOverflow, setShowOverflow] = useState<boolean>(false);
  const bp = useBreakpoints();
  const {
    state: { inspection },
  } = useAuditModalContext();
  const displayCount = 1;
  const uniqueCaseCount = bp.sm ? 2 : 1;
  const showUniqueCase = uniqueCaseCount === employees.length;
  const displayEmployees =
    showOverflow || showUniqueCase
      ? employees
      : employees.slice(0, displayCount);
  const overflowCount = showUniqueCase
    ? 0
    : employees.length - displayCount > 0
    ? employees.length - displayCount
    : 0;

  return (
    <div className="tw-relative">
      <Text font="h3" color="hi-contrast" className="tw-mb-4">
        Accountable Employees
      </Text>
      {employees?.length ? (
        <>
          {showOverflow && (
            <Text
              font="h4"
              color="primary"
              className="tw-absolute tw-top-0 tw-right-0 tw-cursor-pointer"
              onClick={() => setShowOverflow(false)}
            >
              View Less
            </Text>
          )}

          <div className="tw-w-full tw-flex tw-justify-between tw-items-center">
            <div className="tw-flex tw-flex-wrap tw--mb-4">
              {displayEmployees.map((employee, i) => (
                <MiniProfile.Md
                  key={i}
                  person={employee.person}
                  detail={
                    <EmployeeDetails
                      client={inspection?.contract?.customer?.name}
                      site={inspection?.contract?.name ?? ''}
                    />
                  }
                  className="tw-w-[186px] tw-mb-4 tw-mr-2"
                />
              ))}
            </div>
            {Boolean(overflowCount && !showOverflow) && (
              <div
                className="tw-cursor-pointer
                                    tw-h-9 tw-w-9 
                                    tw-flex tw-justify-center tw-items-center 
                                    tw-rounded-full tw-bg-neutral-300"
                onClick={() => setShowOverflow(true)}
              >
                <Text font="h3" color="neutral-offset-flat">
                  {`+${overflowCount}`}
                </Text>
              </div>
            )}
          </div>
        </>
      ) : (
        <div className="tw-w-full">
          <Text font="body-md" color="neutral-offset">
            None
          </Text>
        </div>
      )}
    </div>
  );
};
