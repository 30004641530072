// interfaces
import { Observable } from 'rxjs';

import { Params } from '@core/helpers';
import { virtualBrownClient } from '@core/services/django-client';
import { PaginationResponse, CommonParams } from '@models';

import {
	Comment,
	CommentPayload,
	TwoWayChatbotGetComments,
	TwoWayChatbotPayload,
	TwoWayComment,
	WorkItemType,
} from './interfaces';

// services

// helpers

// constants
const BASE_URL = 'comments';

class CommentService {
	public getComments = (
		params?: {
			related_to?: string;
			offset?: number;
			limit?: number;
			work_item: number;
			work_item_type: WorkItemType;
			parent?: number;
		} & CommonParams
	): Observable<PaginationResponse<Comment>> => {
		const queryString = Params.makeQueryString(params);
		return virtualBrownClient.get<PaginationResponse<Comment>>(
			`${BASE_URL}/${queryString}`
		);
	};

	public createComment = (commentBody: CommentPayload): Observable<any> => {
		return virtualBrownClient.post(`${BASE_URL}/`, commentBody.comment);
	};

	public createTwoWayComments = (
		commentBody: TwoWayChatbotPayload
	): Observable<Comment> => {
		return virtualBrownClient.post(`${BASE_URL}/`, commentBody);
	};

	public getTwoWayComments = (
		workItem: TwoWayChatbotGetComments
	): Observable<PaginationResponse<TwoWayComment>> => {
		return virtualBrownClient.get(
			`${BASE_URL}/${Params.makeQueryString({ ...workItem })}`
		);
	};

	public getCommentById = (commentId: number): Observable<Comment> => {
		return virtualBrownClient.get(`${BASE_URL}/${commentId}/`);
	};

	public updateCommentById = (
		commentId: number,
		commentBody?
	): Observable<any> => {
		console.log(commentBody);
		return virtualBrownClient.put(`${BASE_URL}/${commentId}/`, commentBody);
	};

	public deleteCommentById = (commentId: number): Observable<any> => {
		return virtualBrownClient.delete(`${BASE_URL}/${commentId}/`);
	};
}

export const commentService = new CommentService();
