type STORAGE_KEY =
	| 'theme'
	| 'app'
	| 'preset'
	| 'video_positions'
	| 'SV-DATE'
	| 'dashboard-site-scorecard-map-show'
	| 'maps-tabs-state'
	| 'sitescorecards-overview'
	| 'FRONT-PAGE-DATE'
	| 'kpiPageDateRange'
	| 'recently-visited'
	| 'ds2'
	| 'singlePage'
	| 'devtools';

export class Storage {
	static set(key: STORAGE_KEY, item: any) {
		const toSave = typeof item === 'string' ? item : JSON.stringify(item);
		localStorage.setItem(key, toSave);
		return true;
	}

	static get(key: STORAGE_KEY): any {
		const item = localStorage.getItem(key);
		// try catch is to prevent crashing if JSON.parse is parsing a string literal
		try {
			return typeof item === 'string' ? JSON.parse(item) : null;
		} catch (e) {
			// we still return item since it might be a string
			if (item) {
				return item;
			}
			return null;
		}
	}

	static remove(key: STORAGE_KEY) {
		localStorage.removeItem(key);
	}

	static clearAll() {
		localStorage.clear();
	}
}
