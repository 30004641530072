import { Link } from '@reach/router';

// components
import { Icon, Text } from '@atoms';

// assets

// interfaces
import { TabProps } from './tab.interfaces';

import './tab.css';

export const Tab = ({
  icon,
  path,
  title,
  active,
  hasRoundedTop,
  type,
  className,
  style,
  ...props
}: TabProps) => {
  return (
    <li
      className={`tab tw-list-none ${
        hasRoundedTop || type === 'stacked-tabs-top' ? 'tw-rounded-t-md' : ''
      } ${
        active ? 'tab-active tw-bg-neutral-300 dark:tw-bg-neutral-850' : ''
      } ${type} ${className}`}
      style={style}
    >
      <Link
        id={`tab-${title}`}
        role="tab"
        to={path}
        aria-selected={active}
        aria-controls={title}
        className="tw-py-4 tw-px-6 tw-flex tw-items-center"
        {...props}
      >
        {icon && <Icon icon={icon} className="tab-icon tw-mr-4" />}
        <Text font="h4" color={active ? 'primary' : 'neutral-offset'}>
          {title}
        </Text>
      </Link>
    </li>
  );
};

Tab.defaultProps = {
  type: 'transparent',
  className: '',
};
