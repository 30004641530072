/** ************************************************
 * Imports
 ************************************************* */

import { Icon } from '@atoms';
/** ************************************************
 * Interface
 ************************************************* */

import { CardLabelProps } from './CardLabel.interfaces';

import './CardLabel.scss';

/** ************************************************
 * Render Component
 ************************************************* */

export const CardLabel = ({
  className = '',
  iconClassName = '',
  label,
  isComingSoon = false,
  disabled,
  ...props
}: CardLabelProps) => {
  const fontColor =
    isComingSoon || disabled
      ? 'tw-text-theme-neutral-600-500'
      : 'tw-text-theme-neutral-900-100';

  return (
    <span
      className={`_new-cards-contents-label ${fontColor} ${className}`}
      {...props}
    >
      {label}
      {!isComingSoon && !disabled && (
        <Icon
          icon={['far', 'arrow-right']}
          className={`tw-ml-2 tw-text-theme-primary-500-300 tw-absolute ${iconClassName}`}
        />
      )}
    </span>
  );
};
