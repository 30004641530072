// interfaces
import { Observable } from 'rxjs';

import {
	ContractServiceArea,
	ItemWithFrequency,
	ServiceArea,
	AreaTemplate,
	TaskBundle,
	Position,
	Frequency,
	Service,
	ServiceAreaExpandedResponse,
	CoverageExpanded,
} from '@api-interfaces';

// services
import { virtualBrownClient } from '@core/services/django-client';

// helpers
import { Params } from '@helpers';
import { PaginationParams, PaginationResponse } from '@models';

// constants
const BASE_URL = 'v2/contracts';

const noOpts = {
	noProvider: true,
	noContract: true,
	noCusomter: true,
	noTag: true,
};

class V2SOWOriginal {
	public getV2SOWOriginalBuildings(
		contract_id: number,
		params: {
			calc: 'scope' | 'coverage';
		} & PaginationParams
	): Observable<PaginationResponse<ContractServiceArea>> {
		return virtualBrownClient.get(
			`${BASE_URL}/${contract_id}/sow-original/buildings/${Params.makeQueryString(
				{ ...params }
			)}`,
			noOpts
		);
	}

	public getV2SOWOriginalAreaTypes(
		contract_id: number,
		area_id: number,
		params: {
			calc: 'scope' | 'coverage';
		} & PaginationParams
	): Observable<PaginationResponse<ItemWithFrequency>> {
		return virtualBrownClient.get(
			`${BASE_URL}/${contract_id}/sow-original/buildings/${area_id}/areatypes/${Params.makeQueryString(
				{ ...params }
			)}`,
			noOpts
		);
	}

	public getV2SOWOriginalTaskBundles(
		contract_id: number,
		area_id: number,
		area_type_id: number,
		params: {
			calc: 'scope' | 'coverage';
		} & PaginationParams
	): Observable<PaginationResponse<ItemWithFrequency>> {
		return virtualBrownClient.get(
			`${BASE_URL}/${contract_id}/sow-original/buildings/${area_id}/areatypes/${area_type_id}/taskbundles/${Params.makeQueryString(
				{
					...params,
				}
			)}`,
			noOpts
		);
	}

	public getV2SOWOriginalRooms(
		contract_id: number,
		area_id: number,
		area_type_id: number,
		params: PaginationParams
	): Observable<PaginationResponse<ContractServiceArea>> {
		return virtualBrownClient.get(
			`${BASE_URL}/${contract_id}/sow-original/buildings/${area_id}/areatypes/${area_type_id}/rooms/${Params.makeQueryString(
				{
					...params,
				}
			)}`,
			noOpts
		);
	}

	public getV2SOWOriginalScopeExpanded(
		contract_id: number,
		params: PaginationParams
	): Observable<ServiceAreaExpandedResponse> {
		return virtualBrownClient.get(
			`${BASE_URL}/${contract_id}/sow-original/scope/expanded/${Params.makeQueryString(
				{ ...params }
			)}`,
			noOpts
		);
	}

	public getV2SOWOriginalCoverageExpanded(
		contract_id: number,
		params: PaginationParams
	): Observable<CoverageExpanded> {
		return virtualBrownClient.get(
			`${BASE_URL}/${contract_id}/sow-original/coverage/expanded/${Params.makeQueryString(
				{ ...params }
			)}`,
			noOpts
		);
	}

	/* Slicer Filters */

	public getV2SOWOriginalSlicerFiltersBuildings(
		contract_id: number,
		params: PaginationParams
	): Observable<PaginationResponse<ServiceArea>> {
		return virtualBrownClient.get(
			`${BASE_URL}/${contract_id}/sow-original/slicer-filters/buildings/${Params.makeQueryString(
				{ ...params }
			)}`,
			noOpts
		);
	}

	public getV2SOWOriginalSlicerFiltersRooms(
		contract_id: number,
		params: PaginationParams
	): Observable<PaginationResponse<ServiceArea>> {
		return virtualBrownClient.get(
			`${BASE_URL}/${contract_id}/sow-original/slicer-filters/rooms/${Params.makeQueryString(
				{ ...params }
			)}`,
			noOpts
		);
	}

	public getV2SOWOriginalSlicerFiltersAreaTypes(
		contract_id: number,
		params: PaginationParams
	): Observable<PaginationResponse<AreaTemplate>> {
		return virtualBrownClient.get(
			`${BASE_URL}/${contract_id}/sow-original/slicer-filters/areatypes/${Params.makeQueryString(
				{ ...params }
			)}`,
			noOpts
		);
	}

	public getV2SOWOriginalSlicerFiltersTaskBundleDefs(
		contract_id: number,
		params: PaginationParams
	): Observable<PaginationResponse<TaskBundle>> {
		return virtualBrownClient.get(
			`${BASE_URL}/${contract_id}/sow-original/slicer-filters/taskbundledefs/${Params.makeQueryString(
				{ ...params }
			)}`,
			noOpts
		);
	}

	public getV2SOWOriginalSlicerFiltersPositions(
		contract_id: number,
		params: PaginationParams
	): Observable<PaginationResponse<Position>> {
		return virtualBrownClient.get(
			`${BASE_URL}/${contract_id}/sow-original/slicer-filters/positions/${Params.makeQueryString(
				{ ...params }
			)}`,
			noOpts
		);
	}

	public getV2SOWOriginalSlicerFiltersFrequencies(
		contract_id: number,
		params: PaginationParams
	): Observable<PaginationResponse<Frequency>> {
		return virtualBrownClient.get(
			`${BASE_URL}/${contract_id}/sow-original/slicer-filters/frequencies/${Params.makeQueryString(
				{ ...params }
			)}`,
			noOpts
		);
	}

	public getV2SOWOriginalSlicerFiltersServices(
		contract_id: number,
		params: PaginationParams
	): Observable<PaginationResponse<Service>> {
		return virtualBrownClient.get(
			`${BASE_URL}/${contract_id}/sow-original/slicer-filters/services/${Params.makeQueryString(
				{ ...params }
			)}`,
			noOpts
		);
	}
}

export const sowOriginal = new V2SOWOriginal();
