export const COLORS = {
	LIGHT: {
		XLABEL: 'var(--neutral-900)',
		YLABEL: 'var(--neutral-600)',
		LINE: 'var(--neutral-300)',
		PLOTLINE: '',
		TEXT: 'var(--neutral-900)',
	},
	DARK: {
		XLABEL: 'var(--neutral-200)',
		YLABEL: 'var(--neutral-500)',
		LINE: 'var(--neutral-900)',
		PLOTLINE: '',
		TEXT: 'var(--neutral-100)',
	},
};
