import { Text } from '@atoms';
import { TimeUtils, truncate } from '@core/helpers';

interface EmployeeDetailsProps {
  jobTitle?: string;
  hiredDate?: Date | string;
  client?: string;
  site?: string;
  notTruncated?: boolean;
}

// use in new MiniProfile where meta data should match the style of the older MiniProfile component
export const EmployeeDetails = ({
  jobTitle,
  hiredDate,
  client,
  site,
  notTruncated,
}: EmployeeDetailsProps) => {
  const formattedHiredDate = hiredDate
    ? TimeUtils.format(hiredDate, 'MM/DD/YYYY')
    : null;

  return (
    <div>
      <Text
        font="body-sm"
        color="neutral-offset"
        className={`tw-font-medium tw-line-clamp-1 ${
          !notTruncated ? 'tw-truncate' : ''
        }`}
      >
        {notTruncated ? jobTitle : truncate(jobTitle, 22)}
        {jobTitle && formattedHiredDate && ' | '}
        {formattedHiredDate && `Hired: ${formattedHiredDate}`}
      </Text>
      <Text font="body-sm" color="neutral-offset" className="tw-truncate">
        {client && client}
        {client && site && ' - '}
        {site}
      </Text>
    </div>
  );
};
