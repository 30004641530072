import { createRef, useContext } from 'react';

// interface
import { createCtx } from '@helpers';

import { Page } from './interfaces';

// helpers

// utils
import { reducer } from './reducer';

const initialState: Page.State = {
	id: null,
	modals: {
		'occupant-report-bottom-sheet': true,
		'occupant-report-attachments': false,
		'accept-occupant-report': false,
		'reject-occupant-report': false,
		'forward-occupant-report': false,
		'cancel-occupant-report': false,
		'create-todo-form': false,
		'create-work-order-form': false,
	},
	isLoading: true,
	isEditing: false,
	workItem: undefined,
	workItemEdit: undefined,
	commentCount: 0,
	botEntities: undefined,
	botProperties: [],
	hasBeenUpdated: createRef(),
	refetch: createRef(),
	twoWayConversation: undefined,
	fieldErrors: [],
};

function init(state: Page.State, props: Partial<Page.State>) {
	return {
		...state,
		id: props.id,
	};
}

export const [context, Provider] = createCtx(reducer, initialState, init);

export function useOccupantReportModalContext() {
	return useContext(context);
}
