export function isNumeric(num: number | string): boolean {
	const checkNum = typeof num === 'number' ? num : parseFloat(num);
	return !isNaN(checkNum) && isFinite(checkNum);
}

export function safeDivide(
	dividend: number,
	divisor: number,
	defaultQuotient: number = 0
) {
	return divisor !== 0 && isNumeric(divisor)
		? dividend / divisor
		: defaultQuotient;
}

export function randomInteger(
	maxExclusive: number = Number.MAX_SAFE_INTEGER,
	minInclusive: number = 0
) {
	return (
		Math.floor((maxExclusive - minInclusive) * Math.random()) + minInclusive
	);
}

export function ordinalSuffix(i) {
	// adds nth, nd, st, rd ordinals
	const j = i % 10;
	const k = i % 100;
	if (j == 1 && k != 11) {
		return `${i}st`;
	}
	if (j == 2 && k != 12) {
		return `${i}nd`;
	}
	if (j == 3 && k != 13) {
		return `${i}rd`;
	}
	return `${i}th`;
}

export function isWithinRange({
	lowerBounds = 0,
	upperBounds = 100,
	number = 0,
}: {
	lowerBounds?: number;
	upperBounds?: number;
	number: number;
}) {
	if (isNaN(number)) return false;
	return lowerBounds <= number && number <= upperBounds;
}

/**
 *
 * @param number - the number to format
 * @param {Object} options - optional config
 * @param {Number} options.decimals - display how many decimal places to show. defaults to 0
 * @param {Boolean} options.hasTrailingZeros - flag to show trailing zeros ie. .90000
 * @returns string
 */
export function prettyNumber(
	number: number,
	{
		decimals,
		hasTrailingZeros,
		isPercent,
		forceTrailingZeros,
	}: {
		decimals?: number;
		hasTrailingZeros?: boolean;
		isPercent?: boolean;
		forceTrailingZeros?: boolean;
	} = {
		decimals: 0,
		hasTrailingZeros: true,
		isPercent: false,
		forceTrailingZeros: false,
	}
): string {
	let numString = number.toString();
	const parts = numString.split('.');

	// 123.123 -> ['123', '123']
	if (parts.length > 1 || forceTrailingZeros) {
		numString = parts[0];
		if (decimals) {
			const decimalStr = parts[1];
			let newStr: string = '';
			for (let i = 0; i < decimals; i++) {
				if (decimalStr?.length > i && decimalStr[i]) {
					newStr += decimalStr[i];
				} else if (hasTrailingZeros) {
					newStr += '0';
				}
			}

			numString += `.${newStr}`;
		}
	}

	return `${numString}${isPercent ? '%' : ''}`;
}
