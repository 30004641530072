import { WorkOrderStatusKey, WorkOrderDueStatusKey } from '@api-interfaces';
import { OverflowMenuButton } from '@components/_globals/components';
import { OverflowMenuButtonProps } from '@components/_globals/components/overflow-menu-button/overflow-menu-button.interfaces';
import { useAppContext } from '@core/hooks';
import { TimeUtils } from '@helpers';
import { StatusBadge } from '@modals/_ds2/work-orders/_components/status-badge';
import { ComplaintModal } from '@modals/detail-modals';
import { MiniProfile } from '@new';
import { modalService } from '@services';

// helpers
import { useWorkOrderModalContext } from '../../../../_context';

// components

// interfaces

const tableHeaders = [
  'Responsible Employee(s)',
  'Status',
  'Customer',
  'Created',
  'Repeat',
  '',
];

export const ServiceIssueTable = () => {
  const {
    state: { user, isSingleSite },
  } = useAppContext();
  const {
    workOrder,
    activeModals,
    setActiveModals,
    setSelectedServiceIssue,
    hasBeenUpdated,
  } = useWorkOrderModalContext();
  const hasServiceIssues = workOrder.complaints.length > 0;
  const hasCreatePermission = user.hasPermission(2, 'Complaints');
  const hasDeletePermissions = user.hasPermission(3, 'Complaints');

  return hasServiceIssues ? (
    <div className="form-common-scroll tw-overflow-x-scroll tw-overflow-y-hidden tw-whitespace-nowrap">
      <table
        className={`tw-w-full ${hasServiceIssues ? 'tw-mt-11' : 'tw-mt-8'}`}
      >
        <thead className="tw-w-full">
          <tr>
            {tableHeaders.map((header) => (
              <th
                key={header}
                className="tw-text-left tw-text-sm tw-text-theme-neutral-600-500 tw-font-medium tw-pb-4 tw-pr-4 tw-border-b-2 tw-border-solid tw-border-neutral-300 dark:tw-border-neutral-600 tw-leading-normal"
              >
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {workOrder.complaints.map((complaint, i) => {
            const {
              at_fault_employees,
              status,
              submitter,
              complaint_date,
              is_repeat_complaint,
            } = complaint;
            const isLastRow = i === workOrder.complaints.length - 1;
            const overflowItems: OverflowMenuButtonProps['items'] = [];

            if (hasCreatePermission) {
              overflowItems.push({
                name: 'Edit',
                onClick: () => {
                  setSelectedServiceIssue(complaint);
                  setActiveModals({
                    ...activeModals,
                    'editing-service-issue': true,
                  });
                },
                disabled: !workOrder.service?.id || !isSingleSite,
              });
            }

            if (hasDeletePermissions) {
              overflowItems.push({
                name: 'Delete',
                onClick: () => {
                  setSelectedServiceIssue(complaint);
                  setActiveModals({
                    ...activeModals,
                    'deleting-service-issue': true,
                  });
                },
              });
            }

            return (
              <tr
                key={i}
                onClick={() => {
                  modalService.open({
                    component: (
                      <ComplaintModal data={{ id: complaint.id, complaint }} />
                    ),
                    callback: (data?: { refetch?: boolean }) => {
                      setActiveModals({
                        ...activeModals,
                        'bottom-sheet': true,
                      });
                      if (data?.refetch) {
                        hasBeenUpdated.current = true;
                      }
                    },
                  });
                  setActiveModals({
                    ...activeModals,
                    'bottom-sheet': false,
                  });
                  setSelectedServiceIssue(complaint);
                }}
                className={`tw-h-[54px] tw-text-sm tw-text-neutral-900-100
                        ${
                          !isLastRow
                            ? 'tw-border-solid tw-border-b-2 tw-border-neutral-300 dark:tw-border-neutral-600'
                            : ''
                        }`}
              >
                <td className="tw-pr-4">
                  {at_fault_employees.length === 0 && (
                    <MiniProfile.Md person={undefined} />
                  )}
                  {at_fault_employees.length === 1 && (
                    <MiniProfile.Md person={at_fault_employees[0]?.person} />
                  )}
                  {at_fault_employees.length > 1 && (
                    <MiniProfile.Md
                      person={{
                        first_name: 'Multiple',
                        last_name: 'Employees',
                      }}
                    />
                  )}
                </td>
                <td className="tw-whitespace-nowrap tw-pr-4">
                  <StatusBadge
                    status={
                      status === 'OPEN'
                        ? WorkOrderDueStatusKey.OPEN
                        : WorkOrderStatusKey.CLOSED
                    }
                    showToolTip
                  />
                </td>
                <td className="tw-pr-4">
                  <MiniProfile.Md person={submitter.person} />
                </td>
                <td className="tw-pr-4">
                  {TimeUtils.format(complaint_date, 'MM/DD/YYYY')}
                </td>
                <td className="tw-pr-4">
                  {is_repeat_complaint ? 'Yes' : 'No'}
                </td>
                <td onClick={(e) => e.stopPropagation()}>
                  <OverflowMenuButton
                    items={overflowItems}
                    flowDirection="left-up"
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  ) : null;
};
