import { Observable } from 'rxjs';

// interfaces
import {
	Inspection,
	InspectionPayload,
	InspectionsParams,
	Media,
	Todo,
} from '@api-interfaces';
import { virtualBrownClient } from '@core/services/django-client';
import { Params } from '@helpers';
import { PaginationParams, PaginationResponse } from '@models';

// services

import { inspectionAreasService } from './inspection-areas';
import { inspectionsSummaryService } from './summary';

// helpers

// consts
const BASE_URL = `inspections`;

const noOpts = {
	noCustomer: true,
	noContract: true,
	noTag: true,
};

class InspectionsService {
	readonly inspectionAreas = inspectionAreasService;

	readonly summary = inspectionsSummaryService;

	public getInspections(params: InspectionsParams) {
		const queryString = Params.makeQueryString(params);
		return virtualBrownClient.get<PaginationResponse<Inspection>>(
			`${BASE_URL}/${queryString}`,
			{
				noCustomer: params.customer != undefined,
				noContract: params.contract != undefined,
			}
		);
	}
	public getLatestAudit(params: InspectionsParams) {
		const queryString = Params.makeQueryString(params);
		return virtualBrownClient.get(
			`${BASE_URL}/dashboard-latestaudit/${queryString}`
		);
	}

	public getLatestAuditsInspections(params: InspectionsParams) {
		const queryString = Params.makeQueryString(params);
		return virtualBrownClient.get(
			`${BASE_URL}/latest-audits-inspections/${queryString}`
		);
	}

	public getInspectionById(id): Observable<Inspection> {
		return virtualBrownClient.get(`${BASE_URL}/${id}/`);
	}

	public createInspection(params: { payload: InspectionPayload }) {
		return virtualBrownClient.post<Inspection>(
			`${BASE_URL}/`,
			params.payload
		);
	}

	public updateInspection(params: {
		id: number;
		payload: InspectionPayload;
	}) {
		return virtualBrownClient.put<Inspection>(
			`${BASE_URL}/${params.id}/`,
			params.payload
		);
	}

	public deleteInspection(params: { id: number }) {
		return virtualBrownClient.delete<Inspection>(
			`${BASE_URL}/${params.id}/`
		);
	}

	public getInspectionTodos(id: number, params: PaginationParams) {
		const queryString = Params.makeQueryString(params);
		return virtualBrownClient.get<PaginationResponse<Todo>>(
			`${BASE_URL}/${id}/todos/${queryString}`,
			noOpts
		);
	}

	public getInspectionMedia(id: number, params: PaginationParams) {
		const queryString = Params.makeQueryString(params);
		return virtualBrownClient.get<PaginationResponse<Media>>(
			`${BASE_URL}/${id}/media/${queryString}`,
			noOpts
		);
	}

	public getInspectionAreasAudited(params: {
		from: Date;
		to: Date;
		contract: number;
		customer: number;
	}) {
		const queryString = Params.makeQueryString(params);
		return virtualBrownClient.get<{
			contract_id: number;
			areas_audited_percentage: number;
			areas: number;
			area_types: number;
		}>(`${BASE_URL}/areas-audited/${queryString}`);
	}
	public getInspectionAreasAuditedByType(
		params: {
			from: Date;
			to: Date;
			contract: number;
			customer: number;
		},
		area_type_id: number
	) {
		const queryString = Params.makeQueryString(params);
		return virtualBrownClient.get<{
			contract_id: number;
			areas_audited_percentage: number;
			areas: number;
			area_types: number;
		}>(`${BASE_URL}/areas-audited/${area_type_id}/${queryString}`);
	}
}

export const inspectionsService = new InspectionsService();
