import { useState, useEffect } from 'react';

// components
import { BehavioralAuditEmployee, Employee } from '@api-interfaces';
import { employeesService, mixpanelService } from '@apis';
import { Icon, LoaderBlocks, Text } from '@atoms';
import Empty from '@components/Empty/Empty';
import MiniProfile from '@components/MiniProfile/MiniProfile';
import { useAppContext } from '@core/hooks';
import {
    toasterService,
    historyService,
    imageService,
    modalService,
} from '@core/services';
import {
    AttendanceForm,
    ComplaintForm,
    ComplimentForm,
    ConductForm,
    EmployeeAuditForm,
    ReportItForm,
    SafetyViolationForm,
    SelectProgramForm,
    TodoForm,
} from '@forms';
import { getFullName } from '@helpers';

import { GmpAuditModal, PersonnelReviewSelectEmployee } from '../_ds2';
import { mixpanelClickModalButton } from '@app/mixpanel/MixpanelPageTrack.tsx';

// services

// helpers

// context

// interfaces

interface Props {
    employee: Employee;
}

const QuickEntryModal = (props: Props) => {
    const {
        state: { user },
    } = useAppContext();
    const [employee, setEmployee] = useState<Employee>(null);
    const [imgUrl, setImgUrl] = useState<string>('/images/default.png');
    const [isMobile, setIsMobile] = useState<boolean>(false);
    const [isFetching, setIsFetching] = useState(false);

    const quickEntryButtons = [
        {
            title: 'TO-DO',
            event: 'Employee-Quick-Entry-To-Do',
            permissions: user.hasPermission(2, 'ToDos'),
            modal: (
                <TodoForm
                    data={{
                        preselect: {
                            employeeId: props.employee.id,
                            employeeName: getFullName(props.employee.person),
                        },
                    }}
                />
            ),
        },
        {
            title: 'COMPLAINT',
            event: 'Employee-Quick-Entry-Complaint',
            permissions: user.hasPermission(2, 'Complaints'),
            modal: (
                <SelectProgramForm
                    data={{ title: 'Enter a New Complaint' }}
                    nextModal={{
                        component: (
                            <ComplaintForm
                                selectedEmployee={{
                                    employeeId: props.employee.id,
                                    employeeName: getFullName(
                                        props.employee.person
                                    ),
                                }}
                                selectedProgram={null}
                            />
                        ),
                    }}
                />
            ),
        },
        {
            title: 'COMPLIMENT',
            event: 'Employee-Quick-Entry-Compliment',
            permissions: user.hasPermission(2, 'Compliments'),
            modal: (
                <SelectProgramForm
                    data={{
                        title: 'Enter a New Compliment',
                    }}
                    nextModal={{
                        component: (
                            <ComplimentForm selectedEmployee={employee} />
                        ),
                    }}
                />
            ),
        },
        {
            title: 'REPORT IT',
            event: 'Employee-Quick-Entry-Report-it',
            permissions: user.hasPermission(2, 'Report Its'),
            modal: <ReportItForm data={{ employee }} />,
        },
        {
            title: 'ATTENDANCE',
            event: 'Employee-Quick-Entry-Attendance',
            permissions: user.hasPermission(2, 'Attendance'),
            modal: <AttendanceForm data={{ employee }} />,
        },
        {
            title: 'CONDUCT',
            event: 'Employee-Quick-Entry-Conduct',
            permissions: user.hasPermission(2, 'Conduct'),
            modal: <ConductForm selectedEmployee={employee} />,
        },
        {
            title: 'PROF AUDIT',
            event: 'Employee-Quick-Entry-Professionalism-Audit',
            permissions: user.hasPermission(
                2,
                'Professionalism Audits',
                'GMP Audits'
            ),
            modal: (
                <PersonnelReviewSelectEmployee
                    mode="quick-entry"
                    onClose={modalService.close}
                    onSelectEmployee={handleSelectBehavioralAuditEmployee}
                />
            ),
        },
        {
            title: 'SAFETY VIOLATIONS',
            event: 'Employee-Quick-Entry-Safety-Violation',
            permissions: user.hasPermission(2, 'Safety'),
            modal: <SafetyViolationForm employee={employee} />,
        },
    ];

    /* PROFESSIONALISM AUDITS */
    // open GMP modal or old employee audit modal
    function handleSelectBehavioralAuditEmployee({
        type,
        employee,
    }: {
        type: 'gmp-audits' | 'professionalism-audits';
        employee: BehavioralAuditEmployee;
    }) {
        if (type === 'gmp-audits') {
            modalService.open({
                component: (
                    <GmpAuditModal
                        mode="create"
                        selectedEmployee={employee}
                        onClose={modalService.close}
                    />
                ),
            });
        } else {
            modalService.open({
                component: <EmployeeAuditForm data={employee} />,
            });
        }
    }
    /* End Prof. Audits */

    useEffect(() => {
        if (!employee) {
            setIsFetching(true);
            employeesService
                .getEmployeeById(props.employee.id)
                .subscribe((res) => {
                    if (!res || (res && (res as any).detail === 'Not found.')) {
                        toasterService.newToast({
                            status: 'fail',
                            message:
                                'There was an error retrieving the quick entry data',
                        });
                        setIsFetching(false);
                        return;
                    }

                    setEmployee(res);
                    setIsFetching(false);
                });
        }

        if (employee) {
            setImgUrl(
                imageService.getImageByUniqueId(employee.person.photo_url)
            );
        }

        // set isMobile to the initial value
        checkWindowSize();

        // on window resize reset isMobile - actual responsiveness
        window.addEventListener('resize', () => {
            checkWindowSize();
        });
    }, [employee]);

    const checkWindowSize = () => {
        if (document.body.clientWidth <= 360) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    };

    if (!props.employee.id) {
        modalService.closeAll();
        toasterService.newToast({
            message:
                'There was a problem with the quick entry. Try again or somewhere else.',
            status: 'warning',
        });
    }

    return (
        <div id="quick-entry-modal" className="tw-py-6 tw-px-10">
            <Text
                font="body-lg"
                color="hi-contrast"
                className="tw-mb-4"
                style={{ fontSize: '24px' }}
            >
                Quick Entry
            </Text>

            {isFetching ? (
                <LoaderBlocks />
            ) : employee ? (
                <>
                    {isMobile ? (
                        <div>
                            <div className="tw-flex tw-mb-4">
                                <Text font="body-lg" color="hi-contrast">
                                    Associate:
                                </Text>
                            </div>
                            <div className="tw-flex tw-justify-start">
                                <MiniProfile
                                    data={{
                                        employeeName: getFullName(
                                            employee?.person
                                        ),
                                        imgUrl,
                                        clientName:
                                            employee?.role_profiles[0]
                                                ?.portfolios[0].name ?? '',
                                        jobTitle:
                                            employee?.positions[0]?.name ?? '',
                                        hiredDate: employee?.hired_date
                                            ? new Date(employee.hired_date)
                                            : null,
                                        employee,
                                    }}
                                    settings={{
                                        size: 'lg',
                                        bg: true,
                                    }}
                                    className="tw-overflow-hidden"
                                />
                            </div>
                        </div>
                    ) : null}
                    <div className="tw-grid tw-grid-cols-2 tw-gap-[10px] tw-justify-items-stretch tw-my-4 sm:tw-grid-cols-4">
                        {quickEntryButtons.map((button, idx) => (
                            <div
                                key={idx}
                                className={`
                                    tw-bg-neutral-200-900 tw-text-neutral-900-100 tw-font-bold tw-h-16 tw-w-full tw-text-center tw-rounded sm:tw-h-[72px] sm:tw-w-[100px]
                                    ${
                                        button.permissions
                                            ? 'tw-cursor-pointer'
                                            : 'tw-cursor-not-allowed'
                                    }
                                `}
                                onClick={() => {
                                    if (button.permissions) {
                                        mixpanelClickModalButton(button.event);
                                        modalService.open({
                                            component: button.modal,
                                        });
                                    }
                                }}
                            >
                                <div className="tw-flex tw-justify-end">
                                    <Icon
                                        icon={['fad', 'plus-circle']}
                                        className="tw-text-neutral-900-500 tw-relative tw-top-[6px] tw-right-[6px]"
                                    />
                                </div>
                                <Text
                                    font="body-sm"
                                    color="hi-contrast"
                                    className="tw-pt-4 tw-flex tw-justify-center"
                                >
                                    {button.title}
                                </Text>
                            </div>
                        ))}
                    </div>

                    <div className="tw-mt-4 tw-flex tw-justify-around">
                        <div
                            className={`t-text-link ${
                                !user.hasPermission(1, 'Employee Scorecards')
                                    ? 'tw-cursor-not-allowed'
                                    : 'tw-cursor-pointer'
                            }`}
                            onClick={() =>
                                user.hasPermission(1, 'Employee Scorecards') &&
                                historyService.navigate(
                                    `/user-profile/${props.employee.id}/report-card`
                                )
                            }
                        >
                            View Scorecard
                        </div>
                        <div
                            className={`t-text-link sm:tw-mx-6 ${
                                !user.hasPermission(1, 'Employee Profiles')
                                    ? 'tw-cursor-not-allowed'
                                    : 'tw-cursor-pointer'
                            }`}
                            onClick={() =>
                                user.hasPermission(1, 'Employee Profiles') &&
                                historyService.navigate(
                                    `/user-profile/${props.employee.id}/profile`
                                )
                            }
                        >
                            View Profile
                        </div>
                    </div>
                </>
            ) : (
                <Empty />
            )}
        </div>
    );
};

export default QuickEntryModal;
