// services

// helpers

// components
import { Formik, FormikActions } from 'formik';
import { Component } from 'react';
import { Subscription, of, forkJoin } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { SubmitButton } from '@atoms/form-ds2/submit-button';
import MiniProfile from '@components/MiniProfile/MiniProfile';

// interfaces
import { App } from '@core/app-context/app.interfaces';
import { Attendance, Employee, ReferenceItem, WorkDay } from '@api-interfaces';
import {
    attendanceIssuesService,
    mixpanelService,
    referenceService,
    workDaysService,
} from '@apis';
import { Text } from '@atoms';
import { DropdownItem } from '@atoms/form-ds2/dropdown/dropdown.interfaces';
import { FieldDropdown } from '@atoms/form-ds2/field-dropdown';
import { FieldInputDate } from '@atoms/form-ds2/field-input-date';
import { FieldInputText } from '@atoms/form-ds2/field-input-text';
import { FieldInputTime } from '@atoms/form-ds2/field-input-time';
import { sendError, getFullName, TimeUtils } from '@helpers';
import { useAppContext } from '@hooks';
import { Button } from '@new';
import {
    toasterService,
    imageService,
    modalService,
    refetchService,
} from '@services';
import {
    mixpanelCreatePersonnelAttendance,
    mixpanelEditPersonnelAttendance,
} from '@app/mixpanel/MixpanelPageTrack.tsx';

type ReferenceDropdownItem = DropdownItem<ReferenceItem>;

interface IProps {
    data: {
        employee: Employee;
        attendanceItem?: Attendance;
    };
    settings?: {
        mode?: 'scorecard';
    };
}

interface State {
    issueTypes: ReferenceDropdownItem[];
    reasonTypes: ReferenceDropdownItem[];
}

type AttendanceFormValues = {
    issueType: ReferenceDropdownItem;
    reasonType: ReferenceDropdownItem;
    timeArrived: string;
    comment: string;
    date: Date;
};

export const AttendanceForm = (props: IProps) => {
    const { state } = useAppContext();
    return <Form {...state} {...props} />;
};

class Form extends Component<IProps & App.State, State> {
    private subscriptions: { [key: string]: Subscription };

    constructor(props) {
        super(props);
        this.subscriptions = {};
    }

    public state: State = {
        issueTypes: [],
        reasonTypes: [],
    };

    public componentDidMount() {
        this.subscriptions.referenceItems = forkJoin([
            referenceService.getItems({
                group_code: 'attendanceissue_reasons',
            }),
            referenceService.getItems({
                group_code: 'attendanceissue_issue_types',
            }),
        ]).subscribe({
            next: ([reasonTypes, issueTypes]) => {
                this.setState({
                    reasonTypes: reasonTypes.map((type) => ({
                        ...type,
                        name: type.display_name,
                    })),
                    issueTypes: issueTypes.map((type) => ({
                        ...type,
                        name: type.display_name,
                    })),
                });
            },
            error: sendError(),
        });
    }

    private handleSuccess = (message: string, res: Attendance) => {
        toasterService.newToast({
            status: 'success',
            message,
        });
        refetchService.fetch('attendance');
        modalService.closeAll({ res });
    };

    public createAttendance = (
        values: AttendanceFormValues,
        formikActions: FormikActions<AttendanceFormValues>
    ) => {
        const { setSubmitting } = formikActions;
        const [hrs, minutes] = values.timeArrived.split(':');

        const date_of_occurrence = TimeUtils.setMinutes(
            TimeUtils.setHours(values.date, parseInt(hrs)),
            parseInt(minutes)
        );

        const reasonType =
            values.reasonType ??
            this.state.reasonTypes.find((type) => type.code === 'Other');

        if (this.props.data.attendanceItem) {
            const body = {
                id: this.props.data.attendanceItem.id,
                contract: {
                    id: this.props.data.attendanceItem.contract.id,
                },
                submitter: {
                    id: this.props.user.employeeId,
                },
                comment: values.comment,
                creator: {
                    id: this.props.user.employeeId,
                },
                issue_employee: {
                    id: this.props.data.employee.id,
                },
                issue_type: {
                    id: values.issueType.id,
                },
                reason: {
                    id: reasonType.id,
                },
                date_of_occurrence: date_of_occurrence.toISOString(),
                is_exempt_from_scorecard: false,
            };

            this.subscriptions.updateAttendance = attendanceIssuesService
                .updateAttendance(this.props.data.attendanceItem.id, body)
                .subscribe({
                    next: (res) => {
                        mixpanelEditPersonnelAttendance();
                        this.handleSuccess(
                            'The attendance issue was successfully updated.',
                            res
                        );
                    },
                    error: sendError({
                        callback: () => {
                            setSubmitting(false);
                        },
                    }),
                });
        } else {
            const startDate = TimeUtils.startOfDay(date_of_occurrence);
            const endDate = TimeUtils.endOfDay(date_of_occurrence);

            this.subscriptions.createAttendance = workDaysService
                .getWorkDays({
                    from: startDate,
                    to: endDate,
                    work_date: date_of_occurrence,
                    limit: 500,
                    offset: 0,
                })
                .pipe(
                    switchMap(({ results: workDays }) => {
                        if (workDays.length) {
                            const workDay = workDays.find(
                                (day) => day.id === this.props.data.employee.id
                            );
                            return !workDay?.employee_workdays?.length
                                ? workDaysService.createWorkDay(
                                      date_of_occurrence.toString(),
                                      workDay.id,
                                      this.props
                                  )
                                : of(workDay.employee_workdays[0]);
                        }
                        return of(null);
                    }),
                    switchMap((workDay: WorkDay | null) => {
                        const firstContract = this.props.selectedContracts?.[0];

                        const payload = {
                            contract: {
                                id: workDay?.contract?.id ?? firstContract?.id,
                            },
                            submitter: {
                                id: this.props.user.employeeId,
                            },
                            comment: values.comment,
                            creator: {
                                id: this.props.user.employeeId,
                            },
                            issue_employee: {
                                id: this.props.data.employee.id,
                            },
                            issue_type: {
                                id: values.issueType.id,
                            },
                            reason: {
                                id: reasonType.id,
                            },
                            work_day: {
                                id: workDay?.id ?? null,
                            },
                            date_of_occurrence,
                            is_exempt_from_scorecard: false,
                        };

                        return attendanceIssuesService.createAttendance(
                            payload
                        );
                    })
                )
                .subscribe({
                    next: (res) => {
                        mixpanelCreatePersonnelAttendance();
                        this.handleSuccess(
                            'The attendance issue was successfully created.',
                            res
                        );
                    },
                    error: sendError({
                        callback: () => {
                            setSubmitting(false);
                        },
                    }),
                });
        }
    };

    componentWillUnmount() {
        Object.values(this.subscriptions).forEach((subscription) =>
            subscription.unsubscribe()
        );
    }

    public render() {
        const firstContract = this.props.selectedContracts?.[0];
        const firstCustomer = this.props.selectedCustomers?.[0];

        const { hired_date, positions, person } = this.props.data.employee;

        return (
            <Formik
                initialValues={{
                    issueType: this.props.data.attendanceItem
                        ? {
                              ...this.props.data.attendanceItem.issue_type,
                              name: this.props.data.attendanceItem.issue_type
                                  .display_name,
                          }
                        : this.state.issueTypes?.[0] ?? null,
                    reasonType: this.props.data.attendanceItem
                        ? {
                              ...this.props.data.attendanceItem.reason,
                              name: this.props.data.attendanceItem.reason
                                  .display_name,
                          }
                        : this.state.reasonTypes?.[0] ?? null,
                    timeArrived: this.props.data.attendanceItem
                        ? TimeUtils.format(
                              this.props.data.attendanceItem.date_of_occurrence,
                              'HH:mm'
                          )
                        : TimeUtils.format(new Date(), 'HH:mm'),
                    comment: this.props.data.attendanceItem
                        ? this.props.data.attendanceItem.comment
                        : '',
                    date: this.props.data.attendanceItem
                        ? new Date(
                              this.props.data.attendanceItem.date_of_occurrence
                          )
                        : new Date(),
                }}
                validate={(values) => {
                    const errors: { [Key in keyof typeof values]: string } =
                        {} as any;
                    if (!values.issueType) {
                        errors.issueType = 'This field is required';
                    }
                    if (
                        values.issueType &&
                        values.issueType.code === 'Absent' &&
                        !values.reasonType
                    ) {
                        errors.reasonType = 'This field is required';
                    }
                    return errors;
                }}
                onSubmit={(values, formikActions) => {
                    this.createAttendance(values, formikActions);
                }}
            >
                {({
                    errors,
                    touched,
                    setFieldValue,
                    handleChange,
                    handleSubmit,
                    values,
                    handleBlur,
                    isSubmitting,
                }) => (
                    <form
                        className="tw-space-y-6 tw-p-4 sm:tw-p-6"
                        onSubmit={handleSubmit}
                    >
                        <Text font="h2" color="hi-contrast">
                            New Attendance Entry
                        </Text>
                        <MiniProfile
                            data={{
                                imgUrl: imageService.getImageByUniqueId(
                                    person.photo_url
                                ),
                                employeeName: getFullName(person),
                                siteName: this.props.data.attendanceItem
                                    ? this.props.data.attendanceItem.contract
                                          .name
                                    : firstContract.name,
                                clientName: this.props.data.attendanceItem
                                    ? this.props.data.attendanceItem.contract
                                          .customer.name
                                    : firstCustomer.name,
                                jobTitle: positions?.length
                                    ? positions[0].name
                                    : null,
                                hiredDate:
                                    hired_date != null
                                        ? new Date(hired_date)
                                        : null,
                                employee: this.props.data.employee,
                            }}
                            settings={{
                                size: 'lg',
                                bgSize: 'bg-lg',
                                bg: true,
                            }}
                        />

                        <FieldInputTime
                            id="time-arrived"
                            name="timeArrived"
                            label="Time Arrived"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.timeArrived}
                        />

                        <div className="tw-grid tw-gap-6 sm:tw-grid-cols-2">
                            <FieldInputDate
                                required
                                disableFuture
                                id="date_of_occurrence"
                                label="Date of Occurrence"
                                value={TimeUtils.format(
                                    values.date,
                                    'MM/DD/YYYY'
                                )}
                                onChange={(date) => {
                                    setFieldValue('date', date);
                                }}
                            />
                            <FieldDropdown
                                required
                                id="attendance-type"
                                label="Type"
                                items={this.state.issueTypes}
                                selectedItem={values.issueType}
                                onChange={(type: ReferenceDropdownItem) => {
                                    setFieldValue('issueType', type);
                                    const reasonOther =
                                        this.state.reasonTypes.find(
                                            (type) => type.code === 'Other'
                                        );
                                    setFieldValue('reasonType', reasonOther);
                                }}
                                disabled={
                                    !!this.props.data.attendanceItem ||
                                    !this.state.issueTypes.length
                                }
                                error={
                                    touched.issueType &&
                                    (errors.issueType as string)
                                }
                            />
                        </div>

                        {values.issueType?.code === 'Absent' ? (
                            <FieldDropdown
                                required
                                id="reason-type"
                                label="Absent Reason"
                                items={this.state.reasonTypes}
                                selectedItem={values.reasonType}
                                onChange={(type) => {
                                    setFieldValue('reasonType', type);
                                }}
                                disabled={!this.state.reasonTypes.length}
                                error={
                                    touched.reasonType &&
                                    (errors.issueType as string)
                                }
                            />
                        ) : null}

                        <FieldInputText
                            fieldType="textarea"
                            id="comment"
                            name="comment"
                            label="Comment (Optional)"
                            placeholder="Enter comment"
                            rows={3}
                            onChange={handleChange}
                            defaultValue={values.comment}
                        />

                        <div className="tw-flex tw-justify-end tw-space-x-4">
                            <Button
                                label="Cancel"
                                onClick={modalService.close}
                                color="secondary"
                            />
                            <SubmitButton disabled={isSubmitting} />
                        </div>
                    </form>
                )}
            </Formik>
        );
    }
}
