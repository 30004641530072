// utils
import { ChartLabel } from '../_components/ChartLabel';

import { COLORS } from './colors.utils';
import { COLUMN_OPTIONS } from './column.utils';
import { PIE_OPTIONS } from './pie.utils';

// components

// DON'T ADD PROPS UNLESS CONFIRMED TO BE CONSTANT ACROSS ALL CHARTS
export const getDefaultOptions = (THEME: 'LIGHT' | 'DARK') => {
	const THEME_COLORS = COLORS[THEME];

	const options = {
		chart: {
			backgroundColor: 'transparent', // remove default white background
		},
		credits: {
			enabled: false, // hides highcharts logo/white mark
		},
		legend: {
			labelFormatter: ChartLabel.Legend,
			padding: 0, // space left of legend (default is 8)
			symbolHeight: 8, // make dot smaller @TODO: center dot by setting all dot sizes to zero and add dot to labelFormatter
			useHTML: true, // allow html to be used in labelFormatter
		},
		plotOptions: {
			column: COLUMN_OPTIONS,
			series: {
				animation: false, // remove animation for less reload hassle
			},
			pie: PIE_OPTIONS,
		},
		title: {
			text: null, // if you need title create one outside component
		},
		tooltip: {
			enabled: false, // usually not a requirement
		},
		xAxis: {
			labels: {
				formatter: ChartLabel.XAxis,
				useHTML: true,
			},
			lineColor: THEME_COLORS.LINE, // color of x-axis line
			tickLength: 0, // removes tick lines
		},
		yAxis: {
			gridLineColor: THEME_COLORS.LINE, // color of horizontal grid lines
			labels: {
				formatter: ChartLabel.YAxis,
				useHTML: true,
			},
			title: {
				text: undefined, // hides y-axis title
			},
		},
	};

	return options;
};

export const DEFAULT_OPTIONS = {
	LIGHT: getDefaultOptions('LIGHT'),
	DARK: getDefaultOptions('DARK'),
};
