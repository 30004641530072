import { useRef, useMemo } from 'react';

// components
import { Box, IconButtonFlat, Tooltip } from '@atoms';

import { Button } from '@components/_new/Button';

import { useBreakpoints } from '@hooks';

import { ModalTitle } from '../modal-title';
import { BottomSheetOverflow } from '../overflow';
import { BottomSheetTabs } from '../tabs';

// helpers
// interfaces
import { BottomSheetContainerProps } from './bottom-sheet.container.interfaces';

import { IconButtonFlatProps } from '@atoms/icon-button-flat/icon-button-flat.interfaces';

import './bottom-sheet.container.css';

/**
 *
 * @description ModalContainer - The DS2 modal. Always wrap the elements you
 * want to render with Modal.Main to get the proper layout.
 * @param tabs - tabs that allow the user to scroll to element.
 * Providing tabs props into the Modal.Container will set a max height
 * on the inner container
 * @param person - Will display a person avatar in the top left, and title if not defined.
 */
export const BottomSheetContainer = ({
    title,
    subtitle,
    icon,
    person,
    avatarSize,
    tabs,
    buttons,
    overflowItems,
    children,
    onClose,
    ...props
}: BottomSheetContainerProps) => {
    const modalRef = useRef<HTMLDivElement>(null);
    const bp = useBreakpoints();
    const isBpXs = bp.xs;

    const modalButtons = useMemo(() => {
        if (!buttons) return null;

        return (
            <div className="tw-flex tw-gap-4 tw-mt-6 tw-z-20 lg:tw-mt-0">
                {buttons.map(
                    ({
                        tooltip,
                        tooltipClassName = '',
                        iconOnly,
                        ...button
                    }) => (
                        <Tooltip
                            key={button.label}
                            render={
                                button.disabled && tooltip ? (
                                    <Box
                                        className={`tw-p-4 tw-text-neutral-900-100 ${tooltipClassName}`}
                                    >
                                        {tooltip}
                                    </Box>
                                ) : null
                            }
                            position="bottom"
                            className="tw-w-full sm:tw-w-auto"
                        >
                            {iconOnly ? (
                                <IconButtonFlat
                                    key={button.label}
                                    icon={button.icon}
                                    {...button}
                                    type={
                                        button.color as IconButtonFlatProps['type']
                                    }
                                    style={{ width: 'inherit' }}
                                    className="tw-justify-center tw-h-10 tw-w-10 tw-text-lg"
                                />
                            ) : (
                                <Button
                                    {...button}
                                    style={{ width: 'inherit' }}
                                    className="tw-justify-center"
                                />
                            )}
                        </Tooltip>
                    )
                )}
            </div>
        );
    }, [buttons]);

    return (
        <div ref={modalRef} data-ds2="bottom-sheet">
            <Box
                ref={modalRef}
                type="modalDefault"
                className="tw-h-full sm:tw-rounded-lg"
                {...props}
            >
                <div className="tw-flex tw-flex-col tw-h-full tw-mx-auto tw-max-w-[1440px] tw-p-4 sm:tw-p-8 md:tw-p-10">
                    <div
                        className={`tw-flex tw-justify-between ${
                            !isBpXs ? 'tw-items-center' : ''
                        }`}
                    >
                        <ModalTitle
                            title={title}
                            subtitle={subtitle}
                            icon={icon}
                            person={person}
                            avatarSize={avatarSize}
                        />
                        <div className="tw-flex tw-space-x-4">
                            {bp.lg && modalButtons}
                            <BottomSheetOverflow
                                overflowItems={overflowItems}
                                onClose={onClose}
                            />
                        </div>
                    </div>
                    {tabs && (
                        <BottomSheetTabs modalRef={modalRef} tabs={tabs} />
                    )}
                    <div
                        className="tw-flex tw-flex-col tw-overflow-hidden tw-mt-4 tw-space-y-6
          sm:tw-flex-row sm:tw-space-y-0 sm:tw-space-x-10"
                    >
                        {children}
                    </div>
                    {!bp.lg && modalButtons}
                </div>
            </Box>
        </div>
    );
};
