import { Modal } from '@templates';
import { useEffect, useState } from 'react';
import { ServiceVerificationsArea } from '@api-interfaces';
import { serviceVerificationsService } from '@apis';
import { sendError } from '@helpers';
import {
    AssociateTile,
    Badge,
    Box,
    Icon,
    LoaderBlocks,
    ScoreChip,
    Text,
    TimeUtils,
    ToggleSwitch,
} from '@insite-next/mocha';
import { verificationHelpers } from '@app/_new/pages/service-verifications/Main/utils/utils';
import { imageService } from '@services';

type VerificationDetailsProps = {
    fileName: string;
    type?: 'ASSOCIATE' | 'MANAGER';
    areaElement?: string;
} & (
    | {
          areaId: number;
          auditId: string;
          verification?: never;
      }
    | {
          areaId?: never;
          auditId?: never;
          verification: ServiceVerificationsArea;
      }
);

export const VerificationDetails = ({
    verification: verificationData,
    areaId,
    auditId,
    fileName,
    type,
    areaElement,
}: VerificationDetailsProps) => {
    const [isLoading, setIsLoading] = useState(!!(areaId && auditId));
    const [verification, setVerification] =
        useState<VerificationDetailsProps['verification']>(verificationData);
    const [showAnnotations, setShowAnnotations] = useState(true);
    const [showOriginal, setShowOriginal] = useState(false);
    const images = verification
        ? areaElement
            ? verification.AreaElements.find(
                  (e) => e.AreaElementType === areaElement
              )?.Images ?? []
            : verification.Images
        : [];

    useEffect(() => {
        if (!areaId || !auditId) return;

        setIsLoading(true);
        setVerification(undefined);

        const subscription = serviceVerificationsService
            .getVerification(areaId, auditId)
            .subscribe({
                next: (res) => {
                    setVerification(res.result[0]);
                    setIsLoading(false);
                },
                error: sendError({
                    toastMessage: 'Failed to fetch verification details',
                    callback: () => setIsLoading(false),
                }),
            });

        return () => subscription.unsubscribe();
    }, [areaId, auditId]);

    useEffect(() => {
        setShowOriginal(false);
    }, [fileName]);

    const image = images.find((img) => img.ImagePath === fileName);

    return (
        <Modal.PhotoViewer.Details
            badge={
                areaElement && verification?.AreaName ? (
                    <Text color="alternate" font="h4">
                        {verification?.AreaName}
                    </Text>
                ) : type ? (
                    <Badge
                        color="orange"
                        label={`${type === 'MANAGER' ? 'Manager' : 'Associate'} Verification`}
                        className="tw-mb-6"
                    />
                ) : undefined
            }
            title={areaElement ?? verification?.AreaName ?? '...'}
            subtitle={`${verification?.AreaTypeName ?? '–'} · ${verification?.BuildingName ?? '–'} · Floor ${
                verification?.FloorNumber ?? '–'
            }`}
        >
            {isLoading ? (
                <LoaderBlocks className="tw-mt-6" />
            ) : (
                <div className="tw-space-y-8 tw-mt-6">
                    <div className="tw-flex tw-justify-between tw-gap-4 tw-pt-2 tw-border-t tw-border-solid tw-border-theme-neutral-900-100 tw-border-opacity-15">
                        <Text
                            color={verificationData ? 'default' : 'alternate'}
                            font={verificationData ? 'h2' : 'h4'}
                        >
                            {verificationData
                                ? image
                                    ? `Photo ${images.indexOf(image) + 1} / ${images.length}`
                                    : 'Cover Photo'
                                : 'Photo Score'}
                        </Text>
                        {image && (
                            <div className="tw-flex tw-items-center tw-gap-1">
                                {image?.IsRevised && (
                                    <Badge color="purple" label="Revised" />
                                )}
                                <ScoreChip
                                    score={
                                        image?.NotApplicable?.length
                                            ? 'N/A'
                                            : (
                                                    image?.IsRevised
                                                        ? image?.RevisedIsClean
                                                        : image?.IsClean
                                                )
                                              ? 'Pass'
                                              : 'Fail'
                                    }
                                    type={
                                        image?.NotApplicable?.length
                                            ? 'neutral'
                                            : (
                                                    image?.IsRevised
                                                        ? image?.RevisedIsClean
                                                        : image?.IsClean
                                                )
                                              ? 'success'
                                              : 'danger'
                                    }
                                    size="sm"
                                />
                            </div>
                        )}
                    </div>
                    {image && (
                        <div className="tw-space-y-2">
                            <Text color="alternate" font="h4">
                                {image?.IsRevised ? 'Revised ' : ''}Comment
                            </Text>
                            <Box rounded className="tw-p-4" type="accent">
                                <Text color="default" font="body-md">
                                    {verificationHelpers.getAreaElementImageDescription(
                                        {
                                            IsRevised:
                                                image?.IsRevised ?? false,
                                            ImageCaption:
                                                image?.ImageCaption ?? '',
                                            RevisedCaption:
                                                image?.RevisedCaption ?? '',
                                            NotApplicable:
                                                image?.NotApplicable ?? [],
                                        }
                                    )}
                                </Text>
                            </Box>
                        </div>
                    )}
                    {image?.BoundingBoxes && image.BoundingBoxes.length > 0 && (
                        <div className="tw-rounded-lg tw-border tw-border-solid tw-border-theme-neutral-900-100 tw-border-opacity-15 tw-p-3 tw-flex tw-items-center tw-justify-between">
                            <Text color="default" font="body-md">
                                Show photo annotations
                            </Text>
                            <ToggleSwitch
                                checked={showAnnotations}
                                onChange={(e) =>
                                    setShowAnnotations(e.target.checked)
                                }
                            />
                            {showAnnotations && (
                                <Modal.PhotoViewer.BoundingBoxes
                                    boxes={image.BoundingBoxes}
                                />
                            )}
                        </div>
                    )}
                    <div className="tw-space-y-2">
                        <Text color="alternate" font="h4">
                            Verified By
                        </Text>
                        <Box rounded className="tw-p-3" type="accent">
                            <AssociateTile
                                employee={{
                                    id: verification!.UserID as number,
                                    person: {
                                        first_name: verification!.FullName,
                                        last_name: '',
                                        photo_url:
                                            imageService.getImageByUniqueId(
                                                verification!.EmployeeImage
                                            ),
                                    },
                                    hired_date: verification!.HiredDate,
                                    positions: verification!.Positions,
                                }}
                                size="lg"
                                fields={['position', 'hired_date']}
                                fieldColor="alternate"
                            />
                        </Box>
                    </div>
                    {image?.IsRevised && (
                        <div className="tw-space-y-4">
                            <button
                                type="button"
                                onClick={() => setShowOriginal(!showOriginal)}
                                className="tw-text-left tw-w-full tw-pt-4 tw-flex tw-items-center tw-justify-between tw-border-t tw-border-solid tw-border-theme-neutral-900-100 tw-border-opacity-15"
                            >
                                <div>
                                    <Text color="default" font="body-md">
                                        Score and comment revised by 4insite
                                        Admin on{' '}
                                        {TimeUtils.format(
                                            verification!.RevisedTimestamp,
                                            'MMM DD, YYYY'
                                        )}
                                    </Text>
                                    <Text color="primary" font="body-md">
                                        {showOriginal ? 'Hide' : 'Show'}{' '}
                                        original feedback
                                    </Text>
                                </div>
                                <Icon
                                    icon={
                                        showOriginal
                                            ? 'chevron-down'
                                            : 'chevron-right'
                                    }
                                    className="tw-text-theme-neutral-600-500"
                                />
                            </button>
                            {showOriginal && (
                                <div className="tw-flex tw-items-start tw-gap-4">
                                    <Text
                                        color="alternate"
                                        font="body-md"
                                        className="tw-flex-1"
                                    >
                                        {image?.ImageCaption}
                                    </Text>
                                    <ScoreChip
                                        score={image.IsClean ? 'Pass' : 'Fail'}
                                        type={
                                            image.IsClean ? 'success' : 'danger'
                                        }
                                        size="sm"
                                    />
                                </div>
                            )}
                        </div>
                    )}
                </div>
            )}
        </Modal.PhotoViewer.Details>
    );
};
