import { useLocation, RouteComponentProps } from '@reach/router';
import { useEffect, useState, useRef } from 'react';
import * as React from 'react';
import env from '@core/environments';

// services

// helpers
import { Subscription, of, forkJoin } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';

// components

import { SurveyFeedbackCard } from './_components/survey-feedback.card';

import { TextArea } from '@atoms/form-ds2/textarea';
import { ErrorText } from '@atoms/form-ds2/error-text';
import { FieldInputText } from '@atoms/form-ds2/field-input-text';
import { FieldDropdown } from '@atoms/form-ds2/field-dropdown';

// assets
import ErrorSurveyLight from './_assets/error_survey_lt.svg';
import ErrorSurveyDark from './_assets/error_survey_dt.svg';
import ExpiredLinkLight from './_assets/expired_survey_lt.svg';
import ExpiredLinkDark from './_assets/expired_survey_dt.svg';
import SuccessSvgLight from './_assets/success_survey_lt.svg';
import SuccessSvgDark from './_assets/success_survey_dt.svg';

import { SEO } from '@components/_globals/components';

// interfaces

import {
    QuestionnaireQuestion,
    Survey,
    SurveyRecipientsContract,
    SurveyResponsePayload,
} from '@api-interfaces';
import { surveysService, utilsService } from '@apis';
import { LoaderBlocks, Text, IconButton } from '@atoms';
import { DropdownItem } from '@atoms/form-ds2/dropdown/dropdown.interfaces';
import { sendError, TimeUtils } from '@helpers';
import { useThemeColor } from '@hooks';
import { Button } from '@new';
import { toasterService, http } from '@services';

import './survey.css';

type Option = DropdownItem<{ value?: string }>;

export const ESATTakeSurveyPage = (_props: RouteComponentProps) => {
    const location = useLocation();
    const [isTranslating, setIsTranslating] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [survey, setSurvey] = useState<null | Survey>(null);
    const [hasError, setHasError] = useState(false);
    const [isInactive, setIsInactive] = useState(false);
    const [isExpired, setIsExpired] = useState(false);
    const [questions, setQuestions] = useState<QuestionnaireQuestion[]>([]);
    const [answerSheet, setAnswerSheet] = useState<
        Map<
            number,
            {
                answer: string | Option;
                options?: Option[];
                otherAnswer?: string;
                error?: string;
            }
        >
    >(new Map());
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);

    const [translatedText, setTranslatedText] = useState({
        noSurveyFound: 'No survey found!',
        fetchSurveyError: 'There was an error retrieving the Survey',
        invalidRecipient:
            'Invalid recipient. Please contact your supervisor for assistance',
        requiredField: 'This field is required',
        submitSurveyError: 'There was an error submitting the Survey',
        loadingSurvey: 'Loading... Please wait.',
        selectSurveyAnswer: 'Select an answer',
        selectedAnswer: 'Your answer',
        makeASelection: 'Please make a selection',
        submit: 'Submit',
        next: 'Next',
        poweredBy: 'Powered by',
        contactManager: 'Please contact your manager for assistance',
        surveyExpired: 'Sorry, the survey has expired!',
        surveyInactive: 'This survey is inactive',
        surveySuccess: 'Success!',
        surveyThankYou: `Thank you for your participation in our ESAT survey!\n We're devoted to providing a positive workplace for you.\n Responses will be carefully evaluated, we will re-connect with results and a plan of action contingent upon the feedback we collect from this survey.\n We appreciate the time you took to help SBM improve`,
    });

    const errorSvg = useThemeColor(ErrorSurveyLight, ErrorSurveyDark);
    const expiredSurveySvg = useThemeColor(ExpiredLinkLight, ExpiredLinkDark);
    const successSurveySvg = useThemeColor(SuccessSvgLight, SuccessSvgDark);

    const scrollContainerRef = useRef<null | HTMLDivElement>(null);
    const questionContainerRefs = useRef<HTMLDivElement[]>([]);
    const subscription = useRef(new Subscription());
    const employeeId = useRef<null | number>(null);
    const domain = 'https://www.' + env.insite.domain;

    useEffect(() => {
        return () => {
            // unsubscribe from submitting survey request
            subscription.current.unsubscribe();
        };
    }, []);

    useEffect(() => {
        // first set content language on http client before making request to translate texts
        const url = new URL(location.href);
        const contentLanguage = url.searchParams.get('content_language');
        if (contentLanguage) {
            http.language = {
                code: contentLanguage,
                display_name: '',
                id: null,
            };
        }

        const subscription = utilsService.translatableTexts
            .getTranslatableTexts({
                limit: 20,
                unique_identifiers: Object.keys(translatedText).join(','),
            })
            .subscribe({
                next: ({ results }) => {
                    if (results.length) {
                        const translatedTexts = results.reduce(
                            (textObj, result) => {
                                textObj[result.unique_identifier] = result.text;
                                return textObj;
                            },
                            {} as typeof translatedText
                        );
                        setTranslatedText({
                            ...translatedText,
                            ...translatedTexts,
                        });
                    }
                    setIsTranslating(false);
                    setIsLoading(true);
                },
                error: sendError({
                    callback: () => {
                        setIsTranslating(false);
                        setIsLoading(true);
                    },
                }),
            });

        return () => {
            subscription.unsubscribe();
        };
    }, []);

    useEffect(() => {
        if (!isLoading) return;
        const url = new URL(location.href);
        const id = +url.searchParams.get('id');
        const recipientId = +url.searchParams.get('recipient_id');

        if (id == undefined || isNaN(id)) {
            toasterService.newToast({
                status: 'fail',
                message: translatedText.noSurveyFound,
            });
            setHasError(true);
            setIsLoading(false);
            return;
        }

        if (recipientId == undefined || isNaN(recipientId)) {
            toasterService.newToast({
                status: 'fail',
                message: translatedText.invalidRecipient,
            });
            setHasError(true);
            setIsLoading(false);
            return;
        }

        employeeId.current = recipientId;

        const subscription = surveysService.public
            .getSurveyById(id)
            .pipe(
                switchMap((res) => {
                    const empty = of({
                        questions: [] as QuestionnaireQuestion[],
                        contracts: [] as SurveyRecipientsContract[],
                    });
                    // on chrome, making a request for a survey that doesn't exists returns an html page
                    // so here we are checking if a property doesn't exist and then setting the error
                    if (!('results' in res) || !res?.results?.length) {
                        setHasError(true);
                        return empty;
                    }

                    const [survey] = res.results;

                    if (survey.status === 'DRAFT') {
                        setIsInactive(true);
                        return empty;
                    }
                    setSurvey(survey);

                    const endOfDay = TimeUtils.endOfDay(new Date());

                    // if the survey expired, no need to make additional request to retrieve survey questions
                    if (TimeUtils.isAfter(endOfDay, survey.end_date)) {
                        setIsExpired(true);
                        return empty;
                    }

                    return forkJoin({
                        questions: surveysService.public
                            .getSurveyQuestions({
                                surveyId: survey.id,
                                questionnaireId: survey.questionnaire,
                                limit: 50,
                            })
                            .pipe(map((res) => res?.results ?? [])),
                        contracts: surveysService.public
                            .getSurveyRecipientsContractsBySurveyId(survey.id)
                            .pipe(map((res) => res?.results ?? [])),
                    });
                })
            )
            .subscribe({
                next: ({ questions: questionsRes, contracts }) => {
                    const demographicQuestions = questionsRes
                        .filter((q) => q.question_group === 'DEMOGRAPHIC')
                        .map((q, index) => ({ ...q, alt_sequence: index + 1 }));
                    const engagementQuestions = questionsRes
                        .filter(
                            (q) => q.question_group === 'EMPLOYEE_ENGAGEMENT'
                        )
                        .map((q, index) => ({ ...q, alt_sequence: index + 1 }));

                    const questions = [
                        ...demographicQuestions,
                        ...engagementQuestions,
                    ];

                    const answerSheetMap: typeof answerSheet = new Map();
                    for (const question of questions) {
                        if (
                            question.answer_type?.answer_type_type ===
                                'SELECTION_SCORE' ||
                            question.answer_type?.answer_type_type ===
                                'SELECTION_TEXT'
                        ) {
                            const options =
                                question.answer_type?.selection_item_answer_type?.map(
                                    (answer) => ({
                                        id: answer.id,
                                        name: answer.name,
                                        value: answer.value,
                                        openFreeText: answer.opens_free_text,
                                    })
                                ) ?? [];
                            answerSheetMap.set(question.id, {
                                options,
                                answer: null,
                            });
                        } else if (
                            question.answer_type?.answer_type_type ===
                            'CONTRACT_ID'
                        ) {
                            const options = contracts.map((contract) => ({
                                id: contract.contract_id,
                                name: contract.contract_name,
                            }));
                            answerSheetMap.set(question.id, {
                                options,
                                answer:
                                    options.length === 1 ? options[0] : null,
                            });
                        } else if (
                            question.answer_type?.answer_type_type ===
                            'OPEN_TEXT'
                        ) {
                            answerSheetMap.set(question.id, { answer: '' });
                        }
                    }

                    setQuestions(questions);
                    setAnswerSheet(answerSheetMap);
                    setIsLoading(false);
                },
                error: sendError({
                    toastMessage: translatedText.fetchSurveyError,
                    callback: () => {
                        setHasError(true);
                        setIsLoading(false);
                    },
                }),
            });

        return () => {
            subscription.unsubscribe();
        };
    }, [location, isLoading, translatedText]);

    function handleQuestionNavigation(index: number) {
        return () => {
            const scrollContainer = scrollContainerRef.current;
            const questionCard = questionContainerRefs.current[index];

            if (scrollContainer && questionCard) {
                scrollContainer.scrollTo({
                    top: questionCard.offsetTop,
                    behavior: 'smooth',
                });
            }
        };
    }

    function handleAnswerOptionChange(questionId: number) {
        return (answer: Option) => {
            const answersSheetCopy = new Map(answerSheet);
            const answerData = answersSheetCopy.get(questionId);
            answerData.answer = answer;
            answerData.error = '';
            setAnswerSheet(answersSheetCopy);
        };
    }

    function handleAnswerTextChange(questionId: number) {
        return (e: React.ChangeEvent<HTMLTextAreaElement>) => {
            const answersSheetCopy = new Map(answerSheet);
            answersSheetCopy.set(questionId, { answer: e.target.value });
            setAnswerSheet(answersSheetCopy);
        };
    }

    function handleAnswerOtherValueChange(questionId: number) {
        return (e: React.ChangeEvent<HTMLInputElement>) => {
            const answersSheetCopy = new Map(answerSheet);
            const { value } = e.target;
            answersSheetCopy.set(questionId, {
                ...answersSheetCopy.get(questionId),
                otherAnswer: value,
            });
            setAnswerSheet(answersSheetCopy);
        };
    }

    function handleLikertChange(questionId: number) {
        return (e: React.MouseEvent<HTMLUListElement>) => {
            const target = e.target as HTMLUListElement;
            const { likertKey } = target.dataset;
            if (likertKey) {
                const answerSheetCopy = new Map(answerSheet);
                const answerData = answerSheetCopy.get(questionId);
                answerData.answer = answerData.options.find(
                    (option) => option.id === +likertKey
                );
                answerData.error = '';
                setAnswerSheet(answerSheetCopy);
            }
        };
    }

    function validateRequiredQuestions() {
        let hasError = false;
        const error = translatedText.requiredField;
        const answerSheetCopy = new Map(answerSheet);
        for (const question of questions) {
            // Demographic questions are omitted for now
            if (
                question.is_required &&
                question.question_group !== 'DEMOGRAPHIC'
            ) {
                const answer = answerSheetCopy.get(question.id);
                if (typeof answer.answer === 'string') {
                    if (!answer.answer.length) {
                        answerSheetCopy.set(question.id, { ...answer, error });
                        hasError = true;
                    }
                } else {
                    const isAnswered = answer.answer;
                    if (!isAnswered) {
                        answerSheetCopy.set(question.id, { ...answer, error });
                        hasError = true;
                    }
                }
            }
        }

        if (hasError) {
            const questionsWithErrors = Array.from(
                answerSheetCopy.entries()
            ).filter(([_, answer]) => answer.error);

            const questionDivElements = questionContainerRefs.current;

            for (const questionDiv of questionDivElements) {
                const questionId = +questionDiv.dataset.questionId;
                if (questionsWithErrors.find(([id]) => id === questionId)) {
                    const scrollContainer = scrollContainerRef.current;
                    if (scrollContainer) {
                        scrollContainer.scrollTo({
                            top: questionDiv.offsetTop,
                            behavior: 'smooth',
                        });
                        break;
                    }
                }
            }
        }

        setAnswerSheet(answerSheetCopy);
        return hasError;
    }

    function handleSubmit() {
        if (validateRequiredQuestions()) {
            return;
        }

        setIsSubmitting(true);

        const payload: SurveyResponsePayload = {
            survey: survey.id,
            employee: { id: employeeId.current },
            survey_response_question_answers: questions
                .map((question) => {
                    // don't do anything to the pre-engagement info and return early
                    if (question.id == null) {
                        return null;
                    }

                    // these are demographic questions that are answered by the BE so we send an empty value
                    if (
                        question.answer_type.answer_type_type ===
                        'SYSTEM_ANSWER'
                    ) {
                        return {
                            question: {
                                id: question.id,
                            },
                            text_value: '',
                        };
                    }

                    const answer = answerSheet.get(question.id)?.answer;

                    if (typeof answer === 'string') {
                        if (
                            question.answer_type.answer_type_type ===
                            'OPEN_TEXT'
                        ) {
                            return {
                                question: {
                                    id: question.id,
                                },
                                text_value: answer,
                            };
                        }
                    } else if (
                        question.answer_type.answer_type_type ===
                        'SELECTION_TEXT'
                    ) {
                        const textValue = answer?.value ?? '';
                        const otherAnswer =
                            textValue === 'Other'
                                ? answerSheet.get(question.id)?.otherAnswer ??
                                  ''
                                : '';
                        return {
                            question: {
                                id: question.id,
                            },
                            text_value: textValue,
                            ...(otherAnswer
                                ? { other_answer: otherAnswer }
                                : undefined),
                        };
                    } else if (
                        question.answer_type.answer_type_type ===
                        'SELECTION_SCORE'
                    ) {
                        return {
                            question: {
                                id: question.id,
                            },
                            scalar_value: answer ? +answer.value : null,
                        };
                    } else if (
                        question.answer_type.answer_type_type === 'CONTRACT_ID'
                    ) {
                        return {
                            question: {
                                id: question.id,
                            },
                            scalar_value: answer ? answer.id : null,
                        };
                    }
                })
                .filter((answer) => answer),
        };

        subscription.current = surveysService.public
            .submitSurveyResponse(survey.id, payload)
            .subscribe({
                next: () => {
                    setIsSubmitted(true);
                    setIsSubmitting(false);
                },
                error: sendError({
                    toastMessage: translatedText.submitSurveyError,
                    callback: () => {
                        setIsSubmitting(false);
                    },
                }),
            });
    }

    // Demographic questions are omitted for now
    const questionsWithoutDemographic = questions.filter(
        (q) => q.question_group !== 'DEMOGRAPHIC'
    );

    return (
        <div className="tw-absolute tw-top-0 tw-left-0 tw-h-full tw-w-full">
            <SEO title="Take Employee Satisfaction Survey" />
            <div className="tw-h-full tw-w-full tw-grid tw-place-items-center">
                {isLoading || isTranslating ? (
                    <div className="tw-flex tw-flex-col tw-items-center tw-justify-center">
                        <Text font="body-md" color="hi-contrast">
                            {translatedText.loadingSurvey}
                        </Text>
                        <LoaderBlocks />
                    </div>
                ) : hasError || isInactive || isSubmitted || isExpired ? (
                    <SurveyFeedbackCard
                        svg={
                            hasError || isInactive
                                ? errorSvg
                                : isSubmitted
                                ? successSurveySvg
                                : expiredSurveySvg
                        }
                        title={
                            hasError
                                ? translatedText.noSurveyFound
                                : isInactive
                                ? translatedText.surveyInactive
                                : isSubmitted
                                ? translatedText.surveySuccess
                                : translatedText.surveyExpired
                        }
                        subtitle={
                            hasError || isInactive || isExpired
                                ? translatedText.contactManager
                                : translatedText.surveyThankYou
                        }
                        poweredBy={translatedText.poweredBy}
                        hasLoginButton={hasError || isInactive}
                    />
                ) : survey && questions.length ? (
                    <div
                        ref={scrollContainerRef}
                        className="scrollbar-none tw-h-full tw-w-full tw-overflow-y-auto tw-overflow-x-hidden"
                    >
                        <div className="tw-min-h-full tw-grid tw-place-items-center">
                            <div
                                className="tw-relative tw-h-full tw-w-full tw-grid tw-place-items-center tw-py-4 tw-px-6 
                    sm:tw-p-8
                    md:tw-flex md:tw-flex-col md:tw-justify-between md:tw-h-auto md:tw-shadow-box
                    md:tw-bg-theme-neutral-100-800 md:tw-p-[60px] md:tw-max-w-[560px] md:tw-min-h-[630px]"
                            >
                                <div className="tw-w-full">
                                    {survey.introduction_text ? (
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: survey.introduction_text,
                                            }}
                                        />
                                    ) : (
                                        <>
                                            <Text
                                                font="h2"
                                                color="hi-contrast"
                                                className="tw-mb-8"
                                            >
                                                Let's Get Started!
                                            </Text>
                                            <Text
                                                font="body-lg"
                                                color="hi-contrast"
                                                className="tw-mb-8"
                                            >
                                                You will rate your responses on
                                                a scale from{' '}
                                                <b>
                                                    strongly agree to strongly
                                                    disagree
                                                </b>
                                                .<br />
                                                <br />
                                                To navigate forward on the
                                                survey, you can click “Next,” or
                                                you can also click the up and
                                                down arrows to go between
                                                questions.
                                            </Text>
                                        </>
                                    )}
                                    <div className="tw-flex md:tw-justify-end">
                                        <Button
                                            label={translatedText.next}
                                            icon="check"
                                            onClick={handleQuestionNavigation(
                                                0
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        {questionsWithoutDemographic.map((question, index) => {
                            const isDropdown =
                                question.answer_type?.answer_type_type ===
                                    'SELECTION_TEXT' ||
                                question.answer_type?.answer_type_type ===
                                    'CONTRACT_ID';
                            const isLikertScale =
                                question.answer_type?.answer_type_type ===
                                'SELECTION_SCORE';
                            const isOpenText =
                                question.answer_type?.answer_type_type ===
                                'OPEN_TEXT';
                            const isLast =
                                questionsWithoutDemographic.length ===
                                index + 1;

                            return (
                                <div
                                    key={index}
                                    ref={(el) => {
                                        questionContainerRefs.current[index] =
                                            el;
                                    }}
                                    data-question-id={question.id}
                                    className="tw-min-h-full tw-grid tw-place-items-center"
                                >
                                    <div
                                        className="tw-relative tw-h-full tw-w-full tw-grid tw-place-items-center tw-py-4 tw-px-6 
                sm:tw-p-8
                md:tw-flex md:tw-flex-col md:tw-justify-between md:tw-h-full md:tw-shadow-box
                md:tw-bg-theme-neutral-100-800 md:tw-p-[60px] md:tw-max-w-[560px] md:tw-max-h-[630px]"
                                    >
                                        <div className="tw-w-full">
                                            <Text
                                                font="h2"
                                                color="hi-contrast"
                                                className="tw-mb-8"
                                            >
                                                {`${question.alt_sequence}. `}
                                                {question.text}
                                                {question.is_required && (
                                                    <Text
                                                        font="body-lg"
                                                        tag="span"
                                                        color="primary"
                                                        className="tw-inline"
                                                    >
                                                        {' '}
                                                        *
                                                    </Text>
                                                )}
                                            </Text>

                                            {isDropdown ? (
                                                <>
                                                    <div className="tw-mb-8">
                                                        <FieldDropdown
                                                            disableSearch
                                                            id={question.id.toString()}
                                                            label={
                                                                translatedText.selectSurveyAnswer
                                                            }
                                                            items={
                                                                answerSheet.get(
                                                                    question.id
                                                                )?.options ?? []
                                                            }
                                                            selectedItem={
                                                                answerSheet.get(
                                                                    question.id
                                                                )
                                                                    .answer as Option
                                                            }
                                                            onChange={handleAnswerOptionChange(
                                                                question.id
                                                            )}
                                                            error={
                                                                answerSheet.get(
                                                                    question.id
                                                                ).error
                                                            }
                                                        />
                                                    </div>
                                                    {(
                                                        answerSheet.get(
                                                            question.id
                                                        ).answer as Option
                                                    )?.value === 'Other' && (
                                                        <div className="tw-mb-8">
                                                            <FieldInputText
                                                                id={question.id.toString()}
                                                                label={
                                                                    translatedText.selectedAnswer
                                                                }
                                                                onChange={handleAnswerOtherValueChange(
                                                                    question.id
                                                                )}
                                                            />
                                                        </div>
                                                    )}
                                                </>
                                            ) : isLikertScale ? (
                                                <>
                                                    <ul
                                                        className="tw-space-y-6 tw-mb-8"
                                                        onClick={handleLikertChange(
                                                            question.id
                                                        )}
                                                    >
                                                        {answerSheet
                                                            .get(question.id)
                                                            .options.map(
                                                                (option) => (
                                                                    <li
                                                                        key={
                                                                            option.id
                                                                        }
                                                                    >
                                                                        <Button
                                                                            data-likert-key={
                                                                                option.id
                                                                            }
                                                                            label={
                                                                                option.name
                                                                            }
                                                                            color={
                                                                                (
                                                                                    answerSheet.get(
                                                                                        question.id
                                                                                    )
                                                                                        .answer as Option
                                                                                )
                                                                                    ?.id ===
                                                                                option.id
                                                                                    ? 'primary'
                                                                                    : 'alternate'
                                                                            }
                                                                            className="tw-w-full"
                                                                        />
                                                                    </li>
                                                                )
                                                            )}
                                                    </ul>
                                                    {!!answerSheet.get(
                                                        question.id
                                                    ).error && (
                                                        <ErrorText
                                                            error={
                                                                translatedText.makeASelection
                                                            }
                                                        />
                                                    )}
                                                </>
                                            ) : isOpenText ? (
                                                <fieldset className="tw-space-y-1.5 tw-mb-8">
                                                    <TextArea
                                                        onChange={handleAnswerTextChange(
                                                            question.id
                                                        )}
                                                        error={
                                                            !!answerSheet.get(
                                                                question.id
                                                            ).error
                                                        }
                                                        rows={5}
                                                    />
                                                    <ErrorText
                                                        error={
                                                            answerSheet.get(
                                                                question.id
                                                            ).error
                                                        }
                                                    />
                                                </fieldset>
                                            ) : null}

                                            <div className="tw-flex md:tw-justify-end">
                                                <Button
                                                    label={
                                                        isLast
                                                            ? translatedText.submit
                                                            : translatedText.next
                                                    }
                                                    icon="check"
                                                    onClick={
                                                        isLast
                                                            ? handleSubmit
                                                            : handleQuestionNavigation(
                                                                  index + 1
                                                              )
                                                    }
                                                    disabled={
                                                        isLast && isSubmitting
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className="tw-absolute tw-bottom-0 tw-left-0 tw-flex tw-items-center tw-justify-between tw-w-full tw-py-4 tw-px-6
                    sm:tw-p-8 md:tw-mt-[52px] md:tw-static md:tw-p-0"
                                        >
                                            <div className="tw-flex tw-space-x-0.5">
                                                <IconButton
                                                    icon={['far', 'angle-up']}
                                                    className={
                                                        index === 0
                                                            ? 'tw-opacity-40'
                                                            : ''
                                                    }
                                                    disabled={index === 0}
                                                    onClick={handleQuestionNavigation(
                                                        index - 1
                                                    )}
                                                    {...(index !== 0
                                                        ? {
                                                              'aria-label': `Go to Prev Question Number ${
                                                                  question.alt_sequence -
                                                                  1
                                                              }`,
                                                          }
                                                        : undefined)}
                                                />
                                                <IconButton
                                                    icon={['far', 'angle-down']}
                                                    type="primary"
                                                    className={
                                                        isLast
                                                            ? 'tw-opacity-40'
                                                            : ''
                                                    }
                                                    disabled={isLast}
                                                    onClick={handleQuestionNavigation(
                                                        index + 1
                                                    )}
                                                    {...(isLast
                                                        ? undefined
                                                        : {
                                                              'aria-label': `Go to Next Question Number ${
                                                                  question.alt_sequence +
                                                                  1
                                                              }`,
                                                          })}
                                                />
                                            </div>
                                            <a href={domain} target="__blank">
                                                <Text
                                                    font="body-md"
                                                    color="hi-contrast"
                                                >
                                                    {translatedText.poweredBy}{' '}
                                                    <Text
                                                        font="body-md"
                                                        tag="span"
                                                        color="primary"
                                                        className="tw-font-bold"
                                                    >
                                                        4insite
                                                    </Text>
                                                </Text>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                ) : null}
            </div>
        </div>
    );
};
