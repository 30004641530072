// services

// helpers
import { Observable } from 'rxjs';

import { TodosSummary, TodosSummaryParams } from '@api-interfaces';
import { Params } from '@helpers';

// interfaces
import { virtualBrownClient } from '@services';

// constants
const BASE_URL = 'todos/summary';
const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

class TodosSummaryService {
	getSummaries<T extends TodosSummary.Chart>(
		params: TodosSummaryParams<T>
	): Observable<TodosSummary.SummaryReturnType[T][]> {
		const query = {
			...params,
			chart: params.chart.join(','),
			time_zone: params.time_zone || timeZone,
		};
		return virtualBrownClient.get(
			`${BASE_URL}/${Params.makeQueryString(query)}`,
			{
				noCustomer: params.customer !== undefined,
				noContract: params.contract !== undefined,
				noTag: params.tag !== undefined,
			}
		);
	}
}

export const todosSummaryService = new TodosSummaryService();
