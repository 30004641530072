import { ServiceIssueForm as Form } from './_components/form';
import { ServiceIssueCancelPrompt } from './_components/service-issue-cancel-prompt';
import {
  ServiceIssueFormProvider,
  useServiceIssueFormContext,
} from './_context';

// interfaces
import { ServiceIssueFormProps } from './_context/service-issue-form.context.interfaces';

export const ServiceIssueForm = (props: ServiceIssueFormProps) => {
  return (
    <ServiceIssueFormProvider {...props}>
      <ServiceIssueFormConsumer />
    </ServiceIssueFormProvider>
  );
};

const ServiceIssueFormConsumer = () => {
  const { isLoading } = useServiceIssueFormContext();

  return (
    !isLoading && (
      <>
        <Form />
        <ServiceIssueCancelPrompt />
      </>
    )
  );
};
