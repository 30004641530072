// configs

// services

// helpers
import jstz from 'jstz'; // old npm module to guess timezone to support browsers without Intl
import { Observable, throwError, timer } from 'rxjs';
import { fromFetch } from 'rxjs/fetch';
import { catchError, switchMap } from 'rxjs/operators';

// interfaces
import { Language } from '@api-interfaces';
import env from '@core/environments';
import { TimeUtils } from '@core/helpers';
import { Daemon, toasterService } from '@services';

const defaultOptions: RequestInit = {
	headers: {
		'Content-Type': 'application/json',
	},
};
interface ExtraOptions {
	// Set to true if you want to use a URL outside of Insite's servers (not sure if this is working)
	customUrl?: boolean;
	// Set to true if you are making a request to a non-Insite server (not sure if this is working)
	noAuth?: boolean;
	// R4 sometimes you don't need contract param (false/appended by default)
	noContract?: boolean;
	noCustomer?: boolean;
	noProvider?: boolean;
	noTag?: boolean;
}

export type RequestOptions = RequestInit & ExtraOptions;

/**
 * @summary Class wrapper around Fetch API to simplify making AJAX requests
 * @member baseUrl Value to use as start of every AJAX request
 */
class HttpClient {
	public timeZone: string;

	private _logout: () => void;

	private _daemon: null | Daemon;

	private _serviceProviderId: number | null;

	constructor(
		private _baseUrl = '',
		private _token: string | null = null,
		private _language: Language = null
	) {
		const timeZone = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone;
		if (timeZone) {
			this.timeZone = timeZone;
		} else {
			this.timeZone = jstz.determine().name();
		}
	}

	public set provider(id: number) {
		this._serviceProviderId = id;
	}

	public set token(token: string | null) {
		this._token = token;
	}

	public set language(language: Language) {
		if (!language) return;
		this._language = language;
	}

	set baseUrl(url: string) {
		this._baseUrl = url;
	}

	set logout(cb: () => void) {
		this._logout = cb;
	}

	public get<T>(
		url,
		options: RequestOptions = defaultOptions
	): Observable<T> {
		return this.baseFetch<T>(url, {
			...options,
			method: 'GET',
		});
	}

	public post<T>(
		url: string,
		bodyData: any,
		options: RequestOptions = defaultOptions
	): Observable<T> {
		if (bodyData instanceof FormData) {
			if (options?.headers?.hasOwnProperty('Content-Type')) {
				delete options.headers['Content-Type'];
			}
			return this.baseFetch<T>(url, {
				...options,
				method: 'POST',
				body: bodyData,
			});
		}
		// Ensure timezone preservation in JSON.stringify when sending dates in the payload
		function dateRangeFieldToJson() {
			return `${TimeUtils.format(this, 'YYYY-MM-DDTHH:mm:ss.SSS')}Z`;
		}

		if (typeof bodyData === 'object' && bodyData !== null) {
			if (bodyData.from && bodyData.from instanceof Date) {
				bodyData.from.toJSON = dateRangeFieldToJson;
			}
			if (bodyData.to && bodyData.to instanceof Date) {
				bodyData.to.toJSON = dateRangeFieldToJson;
			}
		}

		const body =
			typeof bodyData === 'object' ? JSON.stringify(bodyData) : bodyData;
		return this.baseFetch<T>(url, {
			...options,
			method: 'POST',
			body,
		});
	}

	public put<T>(
		url,
		bodyData,
		options: RequestOptions = defaultOptions
	): Observable<T> {
		if (bodyData instanceof FormData) {
			delete options.headers['Content-Type'];
			return this.baseFetch<T>(url, {
				...options,
				method: 'PUT',
				body: bodyData,
			});
		}
		const body =
			typeof bodyData === 'object' ? JSON.stringify(bodyData) : bodyData;
		return this.baseFetch(url, {
			...options,
			method: 'PUT',
			body,
		});
	}

	public delete<T>(
		url,
		options: RequestOptions = defaultOptions
	): Observable<T> {
		return this.baseFetch(url, {
			...options,
			method: 'DELETE',
		});
	}

	public patch<T>(
		url,
		bodyData,
		options: RequestOptions = defaultOptions
	): Observable<T> {
		const body =
			typeof bodyData === 'object' ? JSON.stringify(bodyData) : bodyData;
		return this.baseFetch<T>(url, {
			...options,
			method: 'PATCH',
			body,
		});
	}

	private baseFetch<T>(
		url: string,
		options: RequestOptions,
		maxRetries = 1
	): Observable<T> {
		const finalUrl = new URL(options.customUrl ? url : this._baseUrl + url);

		if (
			!options.customUrl &&
			!options.noProvider &&
			options.method === 'GET' &&
			!finalUrl.searchParams.has('provider') &&
			this._serviceProviderId
		) {
			finalUrl.searchParams.set(
				'provider',
				this._serviceProviderId.toString()
			);
		}

		// all internal api calls has appended time_zone per Mike Brown
		if (
			!options.customUrl &&
			!finalUrl.searchParams.has('time_zone') &&
			this.timeZone
		) {
			finalUrl.searchParams.set('time_zone', this.timeZone);
		}

		finalUrl.searchParams.sort();

		const request = new Request(finalUrl.href, options);

		if (
			!options.noAuth &&
			!request.headers.has('Authorization') &&
			this._token
		) {
			request.headers.set('Authorization', `Token ${this._token}`);
		}
		if (!request.headers.has('Content-Language')) {
			if (this._language?.code) {
				request.headers.set('Content-Language', this._language.code);
			} else if (navigator?.language) {
				const language = navigator.language.split('-')[0];
				request.headers.set('Content-Language', language);
			} else {
				request.headers.set('Content-Language', 'en');
			}
		}
		if (request.headers.has('Content-Type')) {
			const contentType = request.headers.get('Content-Type');
			if (/text\/plain/i.test(contentType)) {
				request.headers.set('Content-Type', 'application/json');
			}
		} else {
			request.headers.set('Content-Type', 'application/json');
		}

		return fromFetch(request).pipe(
			switchMap(async (res) => {
				const contentType = res.headers.get('Content-Type');
				const isApplicationJson = contentType === 'application/json';
				const isBlob = contentType === 'application/octet-stream';
				if (res.ok) {
					if (isApplicationJson) {
						return res.json();
					}
					if (isBlob) {
						return res.blob();
					}
					if (res.status === 204) {
						return Promise.resolve(res.statusText);
					}
					return res.text();
				}
				const clonedRes = res.clone();
				if (isApplicationJson) {
					const data = await res.json();
					return Promise.reject(data);
				}
				const text = await clonedRes.text();
				const error = `${clonedRes.status} - ${clonedRes.statusText} \n ${text}`;
				return Promise.reject(new Error(error));
			}),
			catchError((res: unknown) => {
				if (res && typeof res === 'object') {
					// logic to retry if access token is invalid
					if (
						res.code === 'token_not_valid' &&
						this._daemon?.isInitialized
					) {
						if (maxRetries > 0) {
							this._daemon?.forceCall();
							const retry = maxRetries - 1;
							// delay retry fetch call to give new access token
							// to set up
							return timer(2000).pipe(
								switchMap(() => {
									return this.baseFetch(url, options, retry);
								})
							);
						}
						if (this._logout) {
							toasterService.newToast({
								message: 'Session expired',
							});
							this._logout();
						}
						return throwError(res);
					}
				}

				return throwError(res);
			})
		);
	}

	set refreshTokenDaemon(daemon: Daemon) {
		this._daemon = daemon;
	}

	get refreshTokenDaemon() {
		return this._daemon;
	}
}

export const http = new HttpClient(env.insite.pythonUrl);
