import { WorkOrderPayload } from '@api-interfaces';

export enum WorkOrderFormActionType {
	SET_WORK_ORDER_FORM,
	UPDATE_WORK_ORDER_FORM,
	SET_REQUEST_OPTIONS,
	SET_SERVICE_OPTIONS,
}

export type WorkOrderFormAction =
	| {
			type: WorkOrderFormActionType.SET_WORK_ORDER_FORM;
			payload: Partial<WorkOrderPayload>;
	  }
	| {
			type: WorkOrderFormActionType.UPDATE_WORK_ORDER_FORM;
			payload: Partial<WorkOrderPayload>;
	  }
	| {
			type: WorkOrderFormActionType.SET_WORK_ORDER_FORM;
			payload: Partial<WorkOrderPayload>;
	  };

export interface WorkOrderFormContextState {
	form: Partial<WorkOrderPayload>;
}
