import { forwardRef } from 'react';

import { Icon } from '@atoms';

// helpers
import { IconButtonFlatProps } from './icon-button-flat.interfaces';
import { iconButtonFlatTypeMap } from './icon-button-flat.utils';

// interfaces

export const IconButtonFlat = forwardRef<
  HTMLButtonElement,
  IconButtonFlatProps
>(({ icon, iconSize, type, className, ...props }, ref) => {
  return (
    <button
      ref={ref}
      data-ds2="icon-button-flat"
      type="button"
      className={`tw-flex-shrink-0 tw-h-8 tw-w-8 tw-grid tw-place-items-center ${iconButtonFlatTypeMap[type]} ${className}`}
      {...props}
    >
      <Icon icon={icon} size={iconSize ?? 'lg'} />
    </button>
  );
});

IconButtonFlat.defaultProps = {
  type: 'primary',
  className: '',
};
