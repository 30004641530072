import { useRef, useEffect } from 'react';

// services
import { Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { todosService, workOrdersService } from '@apis';
import { ModalStack } from '@atoms';
import { sendError } from '@helpers';
import { toasterService } from '@services';

// helpers
import { Modal } from '@templates';

import { useWorkOrderModalContext } from '../../../../_context';
import { updateWOStatus } from '../../../../_helpers';

// components

export const CompleteTodo = () => {
  const {
    workOrder,
    setWorkOrder,
    activeModals,
    setActiveModals,
    selectedTodo,
    setSelectedTodo,
    hasBeenUpdated,
  } = useWorkOrderModalContext();
  const subscription = useRef(new Subscription());

  useEffect(() => {
    return () => {
      subscription.current.unsubscribe();
    };
  }, []);

  function handleCompleteTodo() {
    subscription.current = todosService
      .completeTodo(selectedTodo.id)
      .pipe(
        switchMap((res) => {
          if (res) {
            const todoList = [...workOrder.todos];
            const index = todoList.findIndex((todo) => todo.id == res.id);
            todoList[index] = res;
            const woPayload = updateWOStatus(workOrder, todoList);
            return workOrdersService.updateWorkOrderById(
              workOrder.id,
              woPayload
            );
          }
        })
      )
      .subscribe({
        next: (workOrder) => {
          toasterService.newToast({
            status: 'success',
            message: 'Todo successfully closed',
          });
          setWorkOrder(workOrder);
          hasBeenUpdated.current = true;
          handleCancel();
        },
        error: sendError(),
      });
  }

  function handleCancel() {
    setActiveModals({
      ...activeModals,
      'completing-todo': false,
    });
    setSelectedTodo(null);
  }

  return (
    activeModals['completing-todo'] && (
      <ModalStack position="top-half-center">
        <Modal.Prompt
          key="completing"
          title="Close to-do?"
          prompt="Are you sure?"
          onCancel={handleCancel}
          buttons={[
            {
              label: 'Cancel',
              color: 'secondary',
              onClick: handleCancel,
            },
            {
              label: 'Yes',
              onClick: handleCompleteTodo,
            },
          ]}
        />
      </ModalStack>
    )
  );
};
