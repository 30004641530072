import * as React from 'react';

import { Text } from '@atoms';

import { ToggleProps } from './Toggle.interfaces';

export const Toggle = ({
  labels,
  active,
  onClick,
  disabled,
  className,
  style,
  textStyle,
  condensed,
  ...props
}: ToggleProps) => {
  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    const { target } = e;
    if (target instanceof HTMLButtonElement) {
      const id = target.dataset.toggleId;
      if (id) {
        const active = labels.find((label) => {
          if (typeof label.id === 'number') {
            return label.id === Number(id);
          }
          if (label.id == null) {
            const isIdNull = id === 'null' || id === 'undefined';
            return isIdNull;
          }
          if (typeof label.id === 'boolean') {
            if (label.id && id === 'true') {
              return true;
            }
            if (!label.id && id === 'false') {
              return true;
            }
            return false;
          }
          return label.id === id;
        });

        if (active) {
          onClick(active.id);
        }
      }
    }
  };

  return (
    <div
      data-ds2="toggle"
      className={`tw-inline-flex tw-bg-neutral-300 tw-w-full dark:tw-bg-neutral-850 tw-rounded-lg sm:tw-w-max ${
        condensed ? 'tw-h-7' : 'tw-h-10'
      } ${className}`}
      onClick={handleClick}
      style={style}
      {...props}
    >
      {labels.map(({ id, name, className = '', ...labelProps }) => (
        <button
          key={id}
          data-toggle-id={
            typeof id === 'string' || typeof id === 'number' ? id : String(id)
          }
          aria-current={id === active}
          disabled={disabled}
          className={`tw-px-4 tw-flex-1 tw-whitespace-nowrap ${
            condensed ? 'tw-py-1.5' : 'tw-py-2'
          } ${
            id === active
              ? 'tw-text-theme-primary-500-300 tw-bg-neutral-100-700 tw-rounded-lg tw-shadow-toggle'
              : 'tw-text-theme-neutral-600-500 tw-bg-transparent tw-rounded-lg hover:tw-text-theme-primary-300-100 active:tw-text-primary-700 active:tw-bg-neutral-400-900'
          } ${className}`}
          {...labelProps}
        >
          {typeof name === 'string' ? (
            <Text
              font="h4"
              color="current-color"
              className="tw-pointer-events-none"
              style={textStyle}
            >
              {name}
            </Text>
          ) : (
            name
          )}
        </button>
      ))}
    </div>
  );
};

Toggle.defaultProps = {
  className: '',
};
