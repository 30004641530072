// interfaces
import { PartialKpiConfig, GoalText } from '../site-scorecard.config.interface';

export const QUALITY_KPIS: PartialKpiConfig[] = [
	{
		icon: 'clipboard-check',
		title: 'Audits',
		subtitle: 'Avg Score',
		goalType: 'int',
		goalText: GoalText.atLeast,
		key: 'audits',
	},
	{
		icon: 'clipboard-check',
		title: 'Joint Audits',
		subtitle: 'Avg Score',
		goalType: 'int',
		goalText: GoalText.atLeast,
		key: 'joint_audit',
	},
	{
		icon: 'clipboard-check',
		title: 'Internal Audit/Inspection',
		subtitle: 'Avg Score',
		goalType: 'int',
		goalText: GoalText.atLeast,
		key: 'internal_audit',
	},
	{
		icon: 'comment-alt-exclamation',
		title: 'Complaints',
		subtitle: 'Total #',
		goalType: 'int',
		goalText: GoalText.noMoreThan,
		key: 'complaint',
	},
	{
		icon: 'comment-alt-exclamation',
		title: 'Repeat Complaints',
		subtitle: 'Total #',
		goalType: 'int',
		goalText: GoalText.noMoreThan,
		key: 'repeat_complaint',
	},
	{
		icon: 'comment-alt-exclamation',
		title: 'Escalated Complaints',
		subtitle: 'Total #',
		goalType: 'int',
		goalText: GoalText.noMoreThan,
		key: 'escalation',
	},
	{
		icon: 'thumbs-up',
		title: 'Compliments',
		subtitle: 'Total #',
		goalType: 'int',
		goalText: GoalText.atLeast,
		key: 'compliment',
	},
	{
		icon: 'exclamation-triangle',
		title: 'Report Its',
		subtitle: 'Total #',
		goalType: 'int',
		goalText: GoalText.atLeast,
		key: 'reportit',
	},
	{
		icon: 'list-ol',
		title: 'Surveys',
		subtitle: 'Avg. Score',
		goalType: 'int',
		goalText: GoalText.atLeast,
		key: 'survey_score',
	},
	{
		icon: 'list',
		title: 'Todos',
		subtitle: 'On Time %',
		goalType: 'percentage',
		goalText: GoalText.atLeast,
		key: 'todo',
	},
	{
		icon: 'wrench',
		title: 'Work Orders',
		subtitle: 'On Time % (only if in 4insite)',
		goalType: 'percentage',
		goalText: GoalText.atLeast,
		key: 'work_order',
	},
	{
		icon: 'wrench',
		title: 'Missed Cleanings',
		subtitle: 'Total #',
		goalType: 'int',
		goalText: GoalText.noMoreThan,
		key: 'total_missed_cleanings',
	},
	{
		icon: 'wrench',
		title: 'Total Deviations',
		subtitle: 'Total #',
		goalType: 'int',
		goalText: GoalText.noMoreThan,
		key: 'total_deviations',
	},
	{
		icon: 'wrench',
		title: 'Total GDP Errors',
		subtitle: 'Total #',
		goalType: 'int',
		goalText: GoalText.noMoreThan,
		key: 'total_gdp_errors',
	},
	{
		icon: 'wrench',
		title: 'Total SOP Violations',
		subtitle: 'Total #',
		goalType: 'int',
		goalText: GoalText.noMoreThan,
		key: 'total_sop_violations',
	},
	{
		icon: 'wrench',
		title: 'GMP Associate Audits',
		subtitle: 'Total %',
		goalType: 'percentage',
		goalText: GoalText.atLeast,
		key: 'gmp_associate_audits_percent_complete',
	},
	{
		icon: 'wrench',
		title: 'Client Training Compliance',
		subtitle: 'Total %',
		goalType: 'percentage',
		goalText: GoalText.atLeast,
		key: 'training_compliance_client_percent_complete',
	},
	{
		icon: 'wrench',
		title: 'Periodicals Completed',
		subtitle: 'Total %',
		goalType: 'percentage',
		goalText: GoalText.atLeast,
		key: 'periodicals_completed',
	},
	{
		icon: 'chart-line',
		title: 'Percentage of Forecast Accuracy',
		subtitle: 'Total %',
		goalType: 'percentage',
		goalText: GoalText.atLeast,
		key: 'forecast_accuracy',
	},
	{
		icon: 'hand',
		title: 'Business Interruptions',
		subtitle: 'Total #',
		goalType: 'int',
		goalText: GoalText.atLeast,
		key: 'business_interruptions',
	},
	{
		icon: 'list-check',
		title: 'Compliance Tasks Due Number',
		subtitle: 'Total #',
		goalType: 'int',
		goalText: GoalText.atLeast,
		key: 'compliance_tasks_due',
	},
	{
		icon: 'file-contract',
		title: 'Contractor Liaisons Required',
		subtitle: 'Total #',
		goalType: 'int',
		goalText: GoalText.atLeast,
		key: 'contractor_liaisons',
	},
	{
		icon: 'person-dolly',
		title: 'Reliable Delivery',
		subtitle: 'Total #',
		goalType: 'int',
		goalText: GoalText.atLeast,
		key: 'reliable_delivery',
	},
	{
		icon: 'user-helmet-safety',
		title: 'JSAs Required',
		subtitle: 'Total #',
		goalType: 'int',
		goalText: GoalText.atLeast,
		key: 'jsa_required',
	},
	{
		icon: 'search',
		title: 'Corporate Audit Findings',
		subtitle: 'Total %',
		goalType: 'percentage',
		goalText: GoalText.atLeast,
		key: 'corporate_audit_findings',
	},
	{
		icon: 'lightbulb',
		title: 'Strategic Initiatives',
		subtitle: 'Total %',
		goalType: 'percentage',
		goalText: GoalText.atLeast,
		key: 'strategic_initiatives',
	},
	{
		icon: 'dna',
		title: 'CSER Initiatives',
		subtitle: 'Total %',
		goalType: 'percentage',
		goalText: GoalText.atLeast,
		key: 'cser_initiatives',
	},
	{
		icon: 'wrench',
		title: 'High Priority Work Order Response',
		subtitle: 'On time response %',
		goalType: 'percentage',
		goalText: GoalText.moreThan,
		key: 'p1_wo_response',
	},
	{
		icon: 'wrench',
		title: 'Timeliness of Completing and Closing Work Order Tickets',
		subtitle: 'Timeliness %',
		goalType: 'percentage',
		goalText: GoalText.moreThan,
		key: 'wo_timeliness',
	},
	{
		icon: 'file-contract',
		title: 'Operational Report',
		subtitle: 'Compliance %',
		goalType: 'percentage',
		goalText: GoalText.moreThan,
		key: 'operational_report',
	},
	{
		icon: 'file-contract',
		title: 'End of Shift Report',
		subtitle: 'Compliance %',
		goalType: 'percentage',
		goalText: GoalText.moreThan,
		key: 'end_shift_report',
	},
	{
		icon: 'dna',
		title: 'Process Checmical Or Equipment Innovations',
		subtitle: 'Total %',
		goalType: 'int',
		goalText: GoalText.moreThan,
		key: 'process_checmical_or_equipment_innovations',
	},
];
