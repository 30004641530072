import { GetVerifiableTasksParams, VerifiableTask } from '@api-interfaces';
import { Params } from '@helpers';
import { PaginationResponse } from '@models';
import { virtualBrownClient } from '@services';

import { routesVerifiableTasksSummaryService } from './summary';

// services

// helpers

// interfaces

const BASE_URL = 'routes/verifiable_tasks';

class RoutesVerifiableTasksService {
	summary = routesVerifiableTasksSummaryService;

	getVerifiableTasks(params?: GetVerifiableTasksParams) {
		return virtualBrownClient.get<PaginationResponse<VerifiableTask>>(
			`${BASE_URL}/${Params.makeQueryString(params || {})}`
		);
	}
}

export const routesVerifiableTasksService = new RoutesVerifiableTasksService();
