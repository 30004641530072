import { SummaryChart, SummaryParams } from '@models';

export namespace EmployeesSummary {
	export enum Chart {
		GetEmploymentDuration = 0,
		GetEmployeeWorkdayStatusCounts = 1,
		GetEmployeeActiveTotalCounts = 2,
		GetEmployeePositionProfileCounts = 3,
		GetEmployeeHiredCounts = 4,
		GetEmployeeHiredAndTurnoverCountsByMonth = 5,
		GetEmployeePositionCounts = 6,
	}

	export interface EmploymentDuration {}

	export interface EmploymentDurationSummary
		extends SummaryChart<EmploymentDuration> {
		chart: 'GetEmploymentDuration';
		number: '0';
	}

	export interface EmployeeWorkdayStatusCounts {
		employee_count: number;
		clocked_in_count: number;
	}

	export interface EmployeeWorkdayStatusCountsSummary
		extends SummaryChart<EmployeeWorkdayStatusCounts> {
		chart: 'GetEmployeeWorkdayStatusCounts';
		number: '1';
	}

	export interface EmployeeActiveTotalCounts {
		total_count: number;
		active_count: number;
	}

	export interface EmployeeActiveTotalCountsSummary
		extends SummaryChart<EmployeeActiveTotalCounts> {
		chart: 'GetEmployeeActiveTotalCounts';
		number: '2';
	}

	export interface GetEmployeePositionProfileCounts {
		total_count: number;
	}

	export interface GetEmployeePositionProfileCountsSummary
		extends SummaryChart<GetEmployeePositionProfileCounts> {
		chart: 'GetEmployeePositionProfileCounts';
		number: '3';
	}

	export interface GetEmployeePositionCounts {
		total_count: number;
		position_count: number;
	}

	export interface GetEmployeePositionCountsSummary
		extends SummaryChart<GetEmployeePositionCounts> {
		chart: 'GetEmployeePositionCounts';
		number: '6';
	}

	export interface EmployeeHiredCounts {
		total_count: number;
		most_recent_record: string;
	}

	export interface EmployeeHiredCountsSummary
		extends SummaryChart<EmployeeHiredCounts> {
		chart: 'GetEmployeeHiredCounts';
		number: '4';
	}

	export interface EmployeeHiredAndTurnoverCountsByMonth {
		date: string;
		hire_count: number;
		turnover_count: number;
	}

	export interface EmployeeHiredAndTurnoverCountsByMonthSummary
		extends SummaryChart<EmployeeHiredAndTurnoverCountsByMonth[]> {
		chart: 'GetEmployeeHiredAndTurnoverCountsByMonth';
		number: '5';
	}

	export interface EmployeeSummaryParams<T>
		extends Omit<SummaryParams<T>, 'from' | 'to'> {
		use_ifm?: boolean;
		chart: T[];
		from?: Date | string;
		to?: Date | string;
		turnover?: boolean;
	}
	export interface EmployeePositionCountParams<T>
		extends Omit<SummaryParams<T>, 'from' | 'to'> {
		use_ifm?: boolean;
		chart: T[];
		from?: Date | string;
		to?: Date | string;
		top_level?: string;
	}

	export interface SummaryReturnType {
		[Chart.GetEmploymentDuration]: EmploymentDurationSummary;
		[Chart.GetEmployeeWorkdayStatusCounts]: EmployeeWorkdayStatusCountsSummary;
		[Chart.GetEmployeeActiveTotalCounts]: EmployeeActiveTotalCountsSummary;
		[Chart.GetEmployeePositionProfileCounts]: GetEmployeePositionProfileCountsSummary;
		[Chart.GetEmployeeHiredCounts]: EmployeeHiredCountsSummary;
		[Chart.GetEmployeeHiredAndTurnoverCountsByMonth]: EmployeeHiredAndTurnoverCountsByMonthSummary;
		[Chart.GetEmployeePositionCounts]: GetEmployeePositionCountsSummary;
	}
}
