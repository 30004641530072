import { useEffect, useState, useRef } from 'react';

// interfaces
import { Subscription } from 'rxjs';

import { Inspection, InspectionArea } from '@api-interfaces';
import { serviceLogsService } from '@apis/public/service-logs';
import { ServiceLog } from '@apis/public/service-logs/interfaces';

// services

// components
import { LoaderBlocks } from '@atoms';
import { TimeUtils, sendError } from '@helpers';

import { ServiceLogRow, EmptyServiceLogs } from '.';

// helpers

interface ServiceLogTabProps {
    currentArea: InspectionArea;
    maxDate: string;
    inspection: Inspection;
    onViewClick: (stopId: number, areaId: number, date: string) => void;
}

export const ServiceLogTab = ({
    currentArea,
    maxDate,
    inspection,
    onViewClick,
}: ServiceLogTabProps) => {
    const subscriptions = useRef<{ [key: string]: Subscription }>({});
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [serviceLogs, setServiceLogs] = useState<Map<string, ServiceLog[]>>(
        new Map()
    );

    const renderServiceLogs = (svgLogs: typeof serviceLogs) =>
        Array.from(svgLogs).map((serviceLog, index) => (
            <ServiceLogRow
                key={index}
                serviceLog={serviceLog}
                isFirst={index === 0}
                areaId={currentArea?.location?.id}
                onViewClick={onViewClick}
            />
        ));

    useEffect(() => {
        subscriptions.current['audit-area-modal-service-log-init'] =
            serviceLogsService
                .getServiceLogs({
                    contract: inspection?.contract?.id,
                    area: currentArea?.location?.id,
                    max_date: maxDate ?? new Date(),
                    limit: 1000,
                })
                .subscribe({
                    next: (res) => {
                        const fetchedServiceLogs = res.results.service_logs;
                        const sortedServiceLogs = new Map();

                        if (fetchedServiceLogs.length) {
                            res.results.service_logs?.reduce((acc, item) => {
                                const date = TimeUtils.format(
                                    item?.scan?.max_duration_end_time ??
                                        item?.scan?.route_stop_create_date,
                                    'MMMM/DD/YYYY'
                                );

                                if (!sortedServiceLogs.get(date)) {
                                    sortedServiceLogs.set(date, []);
                                }

                                sortedServiceLogs.set(
                                    date,
                                    sortedServiceLogs.get(date).concat(item)
                                );

                                return acc;
                            }, new Map());
                        }

                        setServiceLogs(sortedServiceLogs);
                        setIsLoading(false);
                    },
                    error: sendError({
                        toastMessage:
                            'There was an error retrieving Service Log data.',
                        callback: () => {
                            setIsLoading(false);
                        },
                    }),
                });

        return () =>
            Object.values(subscriptions.current).forEach((subscription) =>
                subscription.unsubscribe()
            );
    }, [currentArea?.id]);

    return !isLoading ? (
        <div>
            {serviceLogs.size ? (
                <>{renderServiceLogs(serviceLogs)}</>
            ) : (
                <EmptyServiceLogs />
            )}
        </div>
    ) : (
        <LoaderBlocks className="tw-w-full tw-py-10" />
    );
};
