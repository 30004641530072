import { inspectionsService, mixpanelService } from '@apis';
import { ModalStack } from '@atoms';
import { useAuditModalContext } from '@modals/_ds2/audit/_context/context';
import { toasterService, refetchService, modalService } from '@services';
import { Modal } from '@templates';
import { mixpanelQualityAuditDelete } from '@app/mixpanel/MixpanelPageTrack.tsx';

// services

// helpers

export const AuditDeleteConfirmation = () => {
    const {
        state: { modals, inspection },
        dispatch,
    } = useAuditModalContext();

    function handleConfirm() {
        inspectionsService
            .deleteInspection({
                id: inspection.id,
            })
            .subscribe((_res) => {
                // res always return true so assuming always deleted
                mixpanelQualityAuditDelete();
                toasterService.newToast({
                    status: 'success',
                    message: 'The audit was successfully deleted.',
                });

                // close via the modal stack
                dispatch({
                    type: 'SET_MODALS',
                    payload: {
                        ...modals,
                        'audit-bottom-sheet': false,
                        'audit-delete-confirmation': false,
                    },
                });

                // will refetch if origin is listening
                refetchService.fetch('audits');

                // close all modals from modalService if audit modal was invoked form it
                modalService.close();
            });
    }

    function handleCancel() {
        dispatch({
            type: 'SET_MODALS',
            payload: {
                ...modals,
                'audit-delete-confirmation': false,
            },
        });
    }

    return (
        modals['audit-delete-confirmation'] && (
            <ModalStack position="top-half-center">
                <Modal.Prompt
                    title="Confirmation"
                    prompt={`Are you sure you want to delete this ${
                        inspection.mode === 'AUDIT' ? 'Audit?' : 'Inspection?'
                    }`}
                    onCancel={handleCancel}
                    buttons={[
                        {
                            label: 'Cancel',
                            color: 'alternate',
                            onClick: handleCancel,
                        },
                        {
                            label: 'Confirm',
                            color: 'primary',
                            onClick: handleConfirm,
                        },
                    ]}
                />
            </ModalStack>
        )
    );
};
