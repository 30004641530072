import { textFontMap } from '@atoms/text/text.utils';

// interfaces
import { LabelProps } from './label.interfaces';

export const Label = ({
    label,
    required,
    font,
    className,
    style,
    ...props
}: LabelProps) => {
    return (
        <label
            data-ds2="label"
            className={`
      ${textFontMap[font].className}
      ${
          font === 'body-lg'
              ? 'tw-font-semibold tw-text-theme-neutral-900-100'
              : 'tw-text-theme-neutral-600-500'
      }
      ${className}`}
            style={{
                ...textFontMap[font].style,
                ...style,
            }}
            {...props}
        >
            {label}
            {required ? (
                <span className="tw-text-theme-primary-500-300"> *</span>
            ) : (
                ''
            )}
        </label>
    );
};

Label.defaultProps = {
    className: '',
    font: 'body-sm',
};
