import { useState, useMemo } from 'react';

// services
import { Media, Todo } from '@api-interfaces';
import { todosService } from '@apis';
import { WorkOrderModal } from '@app/modals/_ds2';
import { Box, ModalStack, Icon } from '@atoms';
import { OverflowMenuButton } from '@components/_globals/components';
import { OverflowMenuButtonProps } from '@components/_globals/components/overflow-menu-button/overflow-menu-button.interfaces';
import MiniProfile from '@components/MiniProfile/MiniProfile';
import {
  DetailsModalWrapper,
  LeftSection,
  RightSection,
  ModalControl,
  ModalTitle,
  ModalContent,
  ModalHeading,
} from '@components/ModalDetail/ModalDetail';
import { TodoForm } from '@forms';
import { Button, MiniProfile as NewMiniProfile } from '@new';
import { imageService, modalService } from '@services';

// components
import ImageContainer from '@core/components/ImageContainer';
import { Comments } from '@new/Comments';
import { Modal } from '@templates';

// helpers
import { TimeUtils, truncate, getFullName, sendError } from '@helpers';
import { useAppContext } from '@hooks';

// interfaces

interface Props {
  todo: Todo;
  handleToDoClose: () => void;
  handleToDoUpdate: (todo: Todo, message: string) => void;
  handleToDoDelete: (todo: Todo) => void;
}
export const ToDoModal = ({
  todo,
  handleToDoClose,
  handleToDoUpdate,
  handleToDoDelete,
}: Props) => {
  const {
    state: { user },
  } = useAppContext();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isCompleting, setIsCompleting] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const handleCompletingConfirmationClose = () => {
    setIsCompleting(false);
  };

  const handleDeleteConfirmationClose = () => {
    setIsDeleting(false);
  };

  const handleToDoUpdateCancel = () => {
    setIsUpdating(false);
  };

  const handleToDoUpdateSuccess = (todo: Todo) => {
    setIsUpdating(false);
    handleToDoUpdate(todo, 'Todo successfully updated');
  };

  const building =
    todo.location.type.name === 'Building'
      ? `Building ${todo.location.name}`
      : todo.location.parent
      ? `Building ${todo.location.parent.name}`
      : '';
  const floor =
    todo.location.type.name === 'Floor' ? ` Floor ${todo.location.name}` : '';

  let extralocation = '';

  if (todo.areainspection_todo_location.length > 0) {
    extralocation = truncate(todo.areainspection_todo_location[0].name);
  } else if (todo.service_task_todo_location.length > 0) {
    extralocation = truncate(todo.service_task_todo_location[0].name);
  }

  const {
    is_complete: isComplete,
    due_date: dueDate,
    closed_date: closedDate,
    due_status: dueStatus,
  } = todo;
  const pastDue = isComplete
    ? TimeUtils.isBefore(dueDate, closedDate)
    : TimeUtils.isBefore(dueDate, new Date());

  let statusText = '';

  if (dueStatus === 'OPEN' || (!isComplete && !pastDue)) {
    statusText = 'Open';
  } else if (dueStatus === 'CLOSED' || (isComplete && !pastDue)) {
    statusText = 'Closed';
  } else if (dueStatus === 'OPEN_PAST_DUE' || (!isComplete && pastDue)) {
    statusText = 'Open Past Due';
  } else if (dueStatus === 'CLOSED_PAST_DUE' || (isComplete && pastDue)) {
    statusText = 'Closed Past Due';
  } else {
    statusText = 'N/A';
  }

  const hasCreatePermissions = user.hasPermission(2, 'ToDos');
  const hasDeletePermissions = user.hasPermission(3, 'ToDos');

  const menuItems = useMemo(() => {
    const menuItems: OverflowMenuButtonProps['items'] = [];
    if (hasCreatePermissions) {
      menuItems.push({
        name: 'Update',
        onClick: () => {
          setIsUpdating(true);
        },
      });
    }
    if (hasDeletePermissions) {
      menuItems.push({
        name: 'Delete',
        onClick: () => {
          setIsDeleting(true);
        },
        className: 'tw-text-theme-danger-500-300',
      });
    }

    return menuItems;
  }, [user]);

  return (
    <DetailsModalWrapper>
      <LeftSection>
        <ModalControl>
          <ModalTitle>To-Do Details</ModalTitle>
        </ModalControl>

        <ModalControl>
          <ModalHeading text="Source" />
          <div className="t-header-on-dark t-text-small tw-mb-1 sm:tw-mb-2 t-text-on-dark">
            Complaint
          </div>
        </ModalControl>

        <ModalControl>
          <ModalHeading text="Assigned By" />
          <Box rounded className="tw-p-2">
            <NewMiniProfile.Md person={todo.creator?.person} />
          </Box>
        </ModalControl>

        <ModalControl>
          <ModalHeading text="Location" />
          <div className="t-header-on-dark t-text-small tw-mb-1 sm:tw-mb-2">
            {building}
            {floor}
          </div>
          {extralocation.length > 0 ? (
            <div className="t-header-on-dark t-text-small tw-mb-1 sm:tw-mb-2">
              {extralocation}
            </div>
          ) : null}
          <div className="t-header-on-dark t-text-small tw-mb-1 sm:tw-mb-2">
            {todo.contract.customer.name}, {todo.contract.name}
          </div>
        </ModalControl>

        <ModalControl>
          <ModalHeading text="Project Work" />
          <div className="t-header-on-dark t-text-small tw-mb-1 sm:tw-mb-2">
            {todo?.is_project_work ? 'Yes' : 'No'}
          </div>
        </ModalControl>

        <ModalControl>
          <ModalHeading text="Work Order" />
          {todo?.work_order_id ? (
            <Button
              color="text-button"
              label={todo?.work_order_id ? `#${todo.work_order_id}` : 'N/A'}
              className="tw-px-0 tw-py-0 tw-h-min"
              onClick={() =>
                modalService.open({
                  component: (
                    <WorkOrderModal
                      workOrderId={todo?.work_order_id}
                      closeModal={() => modalService.close()}
                    />
                  ),
                })
              }
            />
          ) : (
            <div className="t-header-on-dark t-text-small tw-mb-1 sm:tw-mb-2">
              {todo?.work_order_id ? `#${todo.work_order_id}` : 'N/A'}
            </div>
          )}
        </ModalControl>
      </LeftSection>

      <RightSection>
        <ModalControl>
          <div className="tw-flex tw-items-center tw-justify-between">
            <ModalContent>
              Created on:{' '}
              {TimeUtils.format(todo.created_on_date, 'MM/DD/YYYY hh:mm A')}
            </ModalContent>
            <div className="tw-flex tw-items-center tw-space-x-4">
              <OverflowMenuButton
                items={menuItems}
                flowDirection="bottom-left"
              />
              <Icon
                icon="times"
                size="lg"
                className="tw-text-neutral-500 hover:tw-cursor-pointer"
                onClick={handleToDoClose}
              />
            </div>
          </div>
        </ModalControl>

        <ModalControl>
          <ModalHeading text="Description" />
          <ModalContent>{todo.comment}</ModalContent>
        </ModalControl>

        <ModalControl>
          <ModalHeading text="Due Date" />
          <ModalContent>
            {TimeUtils.format(todo.due_date, 'MM/DD/YYYY hh:mm A')}
          </ModalContent>
        </ModalControl>

        <ModalControl>
          <ModalHeading text="Seen" />
          <ModalContent>
            {todo.last_seen_date
              ? TimeUtils.format(todo.last_seen_date, 'MM/DD/YYYY hh:mm A')
              : 'Not seen yet.'}
          </ModalContent>
        </ModalControl>

        <ModalControl>
          <ModalHeading text="Status" />
          <ModalContent
            className={`${pastDue ? 'tw-text-theme-danger-500-300' : ''}`}
          >
            {statusText}
          </ModalContent>
        </ModalControl>

        {todo.closed_date && (
          <ModalControl>
            <ModalHeading text="Closed Date" />
            <ModalContent>
              {TimeUtils.format(todo.closed_date, 'MM/DD/YYYY hh:mm A')}
            </ModalContent>
          </ModalControl>
        )}

        {Boolean(todo && todo.media_list && todo.media_list.length) && (
          <ModalControl>
            <ModalHeading text="Photos" />
            {todo.media_list.map((a: Media) => (
              <ImageContainer
                key={a.id}
                data={{
                  image: imageService.getImageByUniqueId(a.file_name, false),
                  createdDate: new Date(a.create_date),
                }}
                settings={{
                  openInWindow: true,
                }}
              />
            ))}
          </ModalControl>
        )}

        <ModalControl>
          <ModalHeading text="Assigned Employees" />
          <MiniProfile
            data={{
              employeeName: getFullName(todo.assigned_to?.person),
              imgUrl: imageService.getImageByUniqueId(
                todo.assigned_to.person.photo_url
              ),
              siteName: todo.contract.name,
              clientName: todo.contract.customer.name,
              jobTitle: todo?.assigned_to?.positions[0]?.name ?? '',
              hiredDate: todo?.assigned_to?.hired_date ?? null,
              employee: todo?.assigned_to,
            }}
            settings={{ size: 'lg', bg: true }}
          />
        </ModalControl>

        <Comments work_item_type="todo" item_id={todo.id} />

        {!todo.is_complete && hasCreatePermissions && (
          <ModalControl>
            <div className="tw-flex tw-justify-end">
              <Button
                label="Complete"
                onClick={() => {
                  setIsCompleting(true);
                }}
                disabled={isSubmitting}
              />
            </div>
          </ModalControl>
        )}
      </RightSection>
      {isCompleting && (
        <ModalStack
          position="top-half-center"
          onOverlayClick={handleCompletingConfirmationClose}
        >
          <Modal.Prompt
            title="Close Todo?"
            prompt="Are you sure you want to close the todo?"
            onCancel={handleCompletingConfirmationClose}
            buttons={[
              {
                label: 'No',
                onClick: handleCompletingConfirmationClose,
                color: 'secondary',
              },
              {
                label: 'Yes',
                onClick: () => {
                  todosService.completeTodo(todo.id).subscribe({
                    next: (res: Todo) => {
                      handleCompletingConfirmationClose();
                      handleToDoUpdate(res, 'Todo successfully closed');
                    },
                    error: sendError(),
                  });
                },
              },
            ]}
          />
        </ModalStack>
      )}
      {isDeleting && (
        <ModalStack
          position="top-half-center"
          onOverlayClick={handleDeleteConfirmationClose}
        >
          <Modal.Prompt
            title="Delete?"
            prompt="Are you sure you want to delete this todo?"
            onCancel={handleDeleteConfirmationClose}
            buttons={[
              {
                label: 'No',
                onClick: handleDeleteConfirmationClose,
                color: 'secondary',
              },
              {
                label: 'Yes',
                onClick: () => {
                  setIsSubmitting(true);
                  todosService.deleteTodo(todo.id).subscribe({
                    next: (res: Todo) => {
                      handleDeleteConfirmationClose();
                      handleToDoDelete(res);
                    },
                    error: sendError(),
                  });
                },
                color: 'danger',
              },
            ]}
          />
        </ModalStack>
      )}
      {isUpdating && (
        <ModalStack
          position="top-half-center"
          onOverlayClick={handleToDoUpdateCancel}
        >
          <Modal.Generic
            onCancel={handleToDoUpdateCancel}
            className="tw-m-auto"
            style={{ width: '990px', padding: '8px' }}
            hideCloseButton
          >
            <div className="tw-mt-[-40px]">
              <TodoForm
                data={{ todo }}
                handleClose={handleToDoUpdateCancel}
                handleSuccess={handleToDoUpdateSuccess}
              />
            </div>
          </Modal.Generic>
        </ModalStack>
      )}
    </DetailsModalWrapper>
  );
};
