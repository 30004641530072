import { OrganizationAuditScale } from '@api-interfaces';
import { SummaryChart, SummaryParams } from '@models';

export interface InspectionsSummaryParams<T> extends SummaryParams<T> {
	mode: 'AUDIT' | 'INSPECTION' | 'ALL';
	servicearea?: number;
	shift?: string | number;
	shifttype?: string | number;
	verticals?: string | number;
	is_multi_client?: boolean;
}

export namespace InspectionsSummary {
	type Scale = 'ONE_TO_FIVE' | 'ONE_TO_ONE_HUNDRED';
	type ScaleDisplay = '1 - 5' | '1 - 100';

	/* CHARTS */
	export enum Chart {
		GetInspectionCounts = 0,
		GetInspectionCountsByMonth = 1,
		GetInspectionCountsBySite = 2,
		GetInspectionCountsByService = 3,
		GetInspectionCountsByTag = 4,
		GetInspectionCountsByBuilding = 5,
		GetInspectionCountsByServiceAndLocation = 6,
		GetInspectionLeaderboard = 7,
		GetInspectionCountsByOrganization = 8,
		GetInspectionsFrontPageSummary = 9,
		GetInspectionCountsByAreaType = 10,
		GetInspectionCountsByVendor = 12,
		GetInspectionsByAreaType = 13,
		GetInspectionsByAreaGroup = 14,
		GetInspectionCountsByShift = 15,
		GetInspectionCountsByShiftType = 16,
		GetInspectionsByMasterAreaType = 17,
		GetInspectionsByVerticals = 18,
		GetInspectionsByVerticalsOrganizationList = 19,
		GetDashboardInspectionCountsByMonth = 20,
	}

	export interface InspectionCounts {
		avg_audit_score: number;
		avg_audit_score_full: number;
		average_customer_score: number;
		average_customer_score_full: number;
		average_internal_score: number;
		average_internal_score_full: number;
		average_joint_score: number;
		average_joint_score_full: number;
		total_customer_inspections: number;
		total_internal_inspections: number;
		total_joint_inspections: number;
		latest_inspection_date: string;
		audit_scale?: number;
		total_completed: number;
		total_failed: number;
		total_area_scores: number;
		total_customer_area_scores: number;
		total_internal_area_scores: number;
		total_joint_area_scores: number;
	}

	export interface InspectionCountsSummary
		extends SummaryChart<InspectionCounts> {
		chart: 'GetInspectionCounts';
		number: '0';
	}

	export interface InspectionCountsByMonth {
		average_customer_score: number;
		average_internal_score: number;
		average_joint_score: number;
		average_customer_score_full: number;
		average_internal_score_full: number;
		average_joint_score_full: number;
		month: string;
		total_internal_inspections: number;
		total_customer_inspections: number;
		total_joint_inspections: number;
		total_customer_area_scores: number;
		total_internal_area_scores: number;
		total_joint_area_scores: number;
	}

	export interface InspectionCountsByMonthSummary
		extends SummaryChart<InspectionCountsByMonth[]> {
		chart: 'GetInspectionCountsByMonth';
		number: '1';
		time_scale: 'month' | 'day';
	}

	export interface InspectionCountsBySite {
		audit_scale: Scale;
		audit_scale_text: ScaleDisplay;
		average_customer_score: number;
		average_internal_score: number;
		average_joint_score: number;
		average_score: number;
		city: string;
		goal: number;
		has_tag_groups: boolean;
		id: number;
		is_pass_average_customer_score: number;
		is_pass_average_internal_score: number;
		is_pass_average_score: number;
		lat: number;
		latest_inspection_date: string;
		lon: number;
		name: string;
		organization_name: string;
		postal_code: string;
		region: string;
		square_feet: number;
		street1: string;
		street2: string;
		total_customer_inspections: number;
		total_inspections: number;
		total_internal_inspections: number;
		total_joint_inspections: number;
		average_customer_score_full: number;
		average_internal_score_full: number;
		average_joint_score_full: number;
		total_customer_area_scores: number;
		total_internal_area_scores: number;
		total_joint_area_scores: number;
	}

	export interface InspectionCountsBySiteSummary
		extends SummaryChart<InspectionCountsBySite[]> {
		chart: 'GetInspectionCountsBySite';
		number: '2';
	}

	export interface InspectionCountsByService {
		id: number;
		name: string;
		average_internal_score: number;
		average_customer_score: number;
		average_joint_score: number;
		total_customer_inspections: number;
		total_internal_inspections: number;
		total_joint_inspections: number;
		internal_weight: number;
		customer_weight: number;
		average_customer_score_full: number;
		average_internal_score_full: number;
		average_joint_score_full: number;
		total_customer_area_scores: number;
		total_internal_area_scores: number;
		total_joint_area_scores: number;
	}

	export interface InspectionCountsByServiceSummary
		extends SummaryChart<InspectionCountsByService[]> {
		chart: 'GetInspectionCountsByService';
		number: '3';
	}

	export interface InspectionCountsByTag {
		average_customer_score: number;
		average_internal_score: number;
		average_joint_score: number;
		average_score: string;
		contract_id: number;
		id: number;
		is_pass_average_score: boolean;
		lat: number;
		latest_inspection_date: string;
		lon: number;
		name: string;
		total_customer_inspections: number;
		total_inspections: number;
		total_internal_inspections: number;
		total_joint_inspections: number;
		root_areas: {
			area_id: number;
			area_name: string;
		}[];
		average_customer_score_full: number;
		average_internal_score_full: number;
		average_joint_score_full: number;
		total_customer_area_scores: number;
		total_internal_area_scores: number;
		total_joint_area_scores: number;
	}

	export interface InspectionCountsByTagSummary
		extends SummaryChart<InspectionCountsByTag[]> {
		chart: 'GetInspectionCountsByTag';
		number: '4';
	}

	export interface InspectionCountsByBuilding {
		building_id: number;
		name: string;
		lat: number;
		lon: number;
		latest_inspection_date: string;
		total_inspections: number;
		total_customer_inspections: number;
		total_internal_inspections: number;
		total_joint_inspections: number;
		average_score: number;
		average_customer_score: number;
		average_internal_score: number;
		average_joint_score: number;
		average_customer_score_full: number;
		average_internal_score_full: number;
		average_joint_score_full: number;
		total_customer_area_scores: number;
		total_internal_area_scores: number;
		total_joint_area_scores: number;
	}

	export interface InspectionCountsByBuildingSummary
		extends SummaryChart<InspectionCountsByBuilding[]> {
		chart: 'GetInspectionCountsByBuilding';
		number: '5';
	}

	export interface InspectionCountsByServiceAndLocation {
		id: number;
		name: string;
		total_inspections: number;
		total_customer_inspections: number;
		total_internal_inspections: number;
		total_joint_inspections: number;
		average_score: number;
		average_internal_score: number;
		average_customer_score: number;
		average_joint_score: number;
		average_score_full: number;
		average_customer_score_full: number;
		average_internal_score_full: number;
		average_joint_score_full: number;
		total_area_scores: number;
		total_customer_area_scores: number;
		total_internal_area_scores: number;
		total_joint_area_scores: number;
		last_audit_date: string;
	}

	export interface InspectionCountsByServiceAndLocationSummary
		extends SummaryChart<InspectionCountsByServiceAndLocation[]> {
		chart: 'GetInspectionCountsByServiceAndLocation';
		number: '6';
	}

	export interface InspectionLeaderboard {
		average_score: number;
		first_name: string;
		id: number;
		last_name: string;
		photo_url: string;
		total_inspections: number;
	}

	export interface InspectionLeaderboardSummary
		extends SummaryChart<InspectionLeaderboard[]> {
		chart: 'GetInspectionLeaderboard';
		number: '7';
	}

	export interface InspectionCountsByOrganization {
		average_customer_score: number;
		average_internal_score: number;
		average_joint_score: number;
		average_score: number;
		customer_id: number;
		customer_name: string;
		latest_inspection_date: string;
		scale: Scale;
		total_customer_inspections: number;
		total_inspections: number;
		total_internal_inspections: number;
		total_joint_inspections: number;
		average_customer_score_full: number;
		average_internal_score_full: number;
		average_joint_score_full: number;
		total_customer_area_scores: number;
		total_internal_area_scores: number;
		total_joint_area_scores: number;
	}

	export interface InspectionCountsByOrganizationSummary
		extends SummaryChart<InspectionCountsByOrganization[]> {
		chart: 'GetInspectionCountsByOrganization';
		number: '8';
	}

	export interface InspectionsFrontPage {
		lastest_score_raw: number;
		lastest_score_scaled: number;
		is_pass: boolean;
		latest_id: number;
		latest_date: string;
		days_since: number;
	}

	export interface InspectionsFrontPageSummary
		extends SummaryChart<InspectionsFrontPage> {
		chart: 'GetInspectionsFrontPageSummary';
		number: '9';
	}

	export interface InspectionCountsByAreaType {}

	export interface InspectionCountsByAreaTypeSummary
		extends SummaryChart<InspectionCountsByAreaType[]> {
		chart: 'GetInspectionCountsByAreaType';
		number: '10';
	}

	export interface InspectionCountsByVendor {
		id: number;
		name: string;
		average_internal_score: number;
		average_customer_score: number;
		total_internal_inspections: number;
		total_customer_inspections: number;
	}

	export interface InspectionCountsByVendorSummary
		extends SummaryChart<InspectionCountsByVendor[]> {
		chart: 'GetInspectionCountsByVendor';
		number: '12';
	}

	export interface InspectionsByAreaType {
		area_type_id: number;
		area_type_name: string;
		average_score: number;
		average_internal_score: number;
		average_joint_score: number;
		average_customer_score: number;
		total_internal_inspections: number;
		total_joint_inspections: number;
		total_customer_inspections: number;
		average_customer_score_full: number;
		average_internal_score_full: number;
		average_joint_score_full: number;
		total_customer_area_scores: number;
		total_internal_area_scores: number;
		total_joint_area_scores: number;
	}

	export interface InspectionsByAreaTypeSummary
		extends SummaryChart<InspectionsByAreaType[]> {
		chart: 'InspectionsByAreaType';
		number: '13';
	}

	export interface InspectionsByAreaGroup {
		audit_scale: Scale;
		average_customer_score: number;
		average_internal_score: number;
		average_joint_score: number;
		average_score: number;
		contract_area_group_id: number;
		contract_area_group_name: string;
		total_customer_inspections: number;
		total_internal_inspections: number;
		total_joint_inspections: number;
		average_customer_score_full: number;
		average_internal_score_full: number;
		average_joint_score_full: number;
		total_customer_area_scores: number;
		total_internal_area_scores: number;
		total_joint_area_scores: number;
		average_score_full: number;
	}

	export interface InspectionsByAreaGroupSummary
		extends SummaryChart<InspectionsByAreaGroup[]> {
		chart: 'InspectionsByAreaGroup';
		number: '14';
	}

	export interface InspectionCountsByShift {
		shift_id: number;
		shift_name: string;
		average_customer_score: number;
		average_internal_score: number;
		average_joint_score: number;
		total_customer_inspections: number;
		total_internal_inspections: number;
		total_joint_inspections: number;
		average_customer_score_full: number;
		average_internal_score_full: number;
		average_joint_score_full: number;
		total_customer_area_scores: number;
		total_internal_area_scores: number;
		total_joint_area_scores: number;
	}

	export interface InspectionCountsByShiftSummary
		extends SummaryChart<InspectionCountsByShift[]> {
		chart: 'GetInspectionCountsByShift';
		number: '15';
	}

	export interface InspectionCountsByShiftType {
		shifttype_id: number;
		shifttype_name: string;
		average_customer_score: number;
		average_internal_score: number;
		average_joint_score: number;
		total_customer_inspections: number;
		total_internal_inspections: number;
		total_joint_inspections: number;
	}

	export interface InspectionCountsByShiftTypeSummary
		extends SummaryChart<InspectionCountsByShiftType[]> {
		chart: 'GetInspectionCountsByShiftType';
		number: '16';
	}

	export interface InspectionsByMasterAreaType {
		master_area_type_id: number;
		master_area_type_name: string;
		average_customer_score: number;
		average_internal_score: number;
		average_joint_score: number;
		total_customer_inspections: number;
		total_internal_inspections: number;
		total_joint_inspections: number;
		average_customer_score_full: number;
		average_internal_score_full: number;
		average_joint_score_full: number;
		total_customer_area_scores: number;
		total_internal_area_scores: number;
		total_joint_area_scores: number;
		audit_scale: OrganizationAuditScale;
	}

	export interface InspectionsByMasterAreaTypeSummary
		extends SummaryChart<InspectionsByMasterAreaType[]> {
		chart: 'InspectionsByMasterAreaType';
		number: '17';
	}

	export interface InspectionsCountsByVerticals {
		id: number;
		name: string;
		total_joint_inspections: number;
		total_internal_inspections: number;
		total_customer_inspections: number;
		average_internal_score: number;
		average_joint_score: number;
		average_customer_score: number;
	}

	export interface InspectionsCountsByVerticalsSummary
		extends SummaryChart<InspectionsCountsByVerticals[]> {
		chart: 'GetAuditsCountsByVerticals';
		number: '18';
	}

	export interface InspectionsCountsByVerticalsOrganizationList {
		id: number;
		name: string;
		total_joint_inspections: number;
		total_internal_inspections: number;
		total_customer_inspections: number;
		average_internal_score: number;
		average_joint_score: number;
		average_customer_score: number;
	}

	export interface InspectionsCountsByVerticalsOrganizationListSummary
		extends SummaryChart<InspectionsCountsByVerticalsOrganizationList[]> {
		chart: 'GetAuditsCountsByVerticalsOrganizationList';
		number: '19';
	}

	export interface DashboardInspectionCountsByMonth {
		month: string;
		average_customer_score: number;
		average_internal_score: number;
		average_joint_score: number;
		total_internal_inspections: number;
		total_customer_inspections: number;
		total_joint_inspections: number;
		average_customer_score_full: number;
		average_internal_score_full: number;
		average_joint_score_full: number;
		total_internal_area_scores: number;
		total_customer_area_scores: number;
		total_joint_area_scores: number;
	}

	export interface DashboardInspectionCountsByMonthSummary
		extends SummaryChart<DashboardInspectionCountsByMonth[]> {
		chart: 'GetDashboardInspectionCountsByMonth';
		number: '20';
		time_scale: 'month' | 'day';
	}

	export type SummaryReturnType = {
		[Chart.GetInspectionCounts]: InspectionCountsSummary;
		[Chart.GetInspectionCountsByMonth]: InspectionCountsByMonthSummary;
		[Chart.GetInspectionCountsBySite]: InspectionCountsBySiteSummary;
		[Chart.GetInspectionCountsByService]: InspectionCountsByServiceSummary;
		[Chart.GetInspectionCountsByTag]: InspectionCountsByTagSummary;
		[Chart.GetInspectionCountsByBuilding]: InspectionCountsByBuildingSummary;
		[Chart.GetInspectionCountsByServiceAndLocation]: InspectionCountsByServiceAndLocationSummary;
		[Chart.GetInspectionLeaderboard]: InspectionLeaderboardSummary;
		[Chart.GetInspectionCountsByOrganization]: InspectionCountsByOrganizationSummary;
		[Chart.GetInspectionsFrontPageSummary]: InspectionsFrontPageSummary;
		[Chart.GetInspectionCountsByAreaType]: InspectionCountsByAreaTypeSummary;
		[Chart.GetInspectionCountsByVendor]: InspectionCountsByVendorSummary;
		[Chart.GetInspectionsByAreaType]: InspectionsByAreaTypeSummary;
		[Chart.GetInspectionsByAreaGroup]: InspectionsByAreaGroupSummary;
		[Chart.GetInspectionCountsByShift]: InspectionCountsByShiftSummary;
		[Chart.GetInspectionCountsByShiftType]: InspectionCountsByShiftTypeSummary;
		[Chart.GetInspectionsByMasterAreaType]: InspectionsByMasterAreaTypeSummary;
		[Chart.GetInspectionsByVerticals]: InspectionsCountsByVerticalsSummary;
		[Chart.GetInspectionsByVerticalsOrganizationList]: InspectionsCountsByVerticalsOrganizationListSummary;
		[Chart.GetDashboardInspectionCountsByMonth]: DashboardInspectionCountsByMonthSummary;
	};
}
