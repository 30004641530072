// interfaces
import {
	StopDefinition,
	StopDefinitionPayload,
	StopDefinitionBulkPayload,
} from '@api-interfaces';

// services
import { virtualBrownClient } from '@core/services/django-client';

// constants
const BASE_URL = 'routes/stops';

class RoutesStopsService {
	public getStopDefinitionById(params: { id: number }) {
		return virtualBrownClient.get<StopDefinition>(
			`${BASE_URL}/definitions/${params.id}/`
		);
	}

	public createStopDefinition(params: { payload: StopDefinitionPayload }) {
		return virtualBrownClient.post<StopDefinition>(
			`${BASE_URL}/definitions/`,
			params.payload
		);
	}

	public updateStopDefinition(params: {
		id: number;
		payload: StopDefinitionPayload;
	}) {
		return virtualBrownClient.put<StopDefinition>(
			`${BASE_URL}/definitions/${params.id}/`,
			params.payload
		);
	}

	public deleteStopDefinition(params: { id: number }) {
		return virtualBrownClient.delete<any>(
			`${BASE_URL}/definitions/${params.id}/`
		);
	}

	public createStopDefinitionsBulk(payload: StopDefinitionBulkPayload) {
		return virtualBrownClient.post<{
			errors: any[];
			results: StopDefinition[];
		}>(`${BASE_URL}/definitions/bulk/`, payload);
	}

	public updateStopDefinitionsBulk(payload: StopDefinitionBulkPayload) {
		return virtualBrownClient.put<{
			errors: any[];
			results: StopDefinition[];
		}>(`${BASE_URL}/definitions/bulk/`, payload);
	}
}

export const routesStopsService = new RoutesStopsService();
