import { throttle } from 'lodash';
import { useRef, useState, useEffect } from 'react';
import { createPortal } from 'react-dom';

// helpers
import { useUpdate } from 'react-use';

// interfaces
import { TooltipProps } from './tooltip.interfaces';
import { widthMap } from './tooltip.utils';

export const Tooltip = ({
  id,
  render,
  children,
  position,
  width,
  className,
  disabled,
  onClick,
}: TooltipProps) => {
  const [isVisible, setIsVisible] = useState(false);
  const update = useUpdate();
  const tooltipContainerRef = useRef<HTMLDivElement | null>(null);
  const tooltipContentRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    function hideVisibility() {
      setIsVisible(false);
    }
    const throttledHideVisibility = throttle(hideVisibility, 100);
    window.addEventListener('scroll', throttledHideVisibility);
    return () => {
      window.removeEventListener('scroll', throttledHideVisibility);
    };
  }, []);

  function getTooltipStyles() {
    if (!tooltipContentRef.current) return undefined;

    const { left, top, right, bottom, height, width } =
      tooltipContainerRef.current.getBoundingClientRect();
    const { height: popupHeight, width: popupWidth } =
      tooltipContentRef.current.getBoundingClientRect();

    const widthDiff = popupWidth - width;
    const heightDiff = popupHeight - height;

    const styles: { top: string | number; left: string | number } = {
      top: 0,
      left: 0,
    };

    switch (position) {
      case 'top':
        styles.top = `${top - popupHeight}px`;
        styles.left = `${left - widthDiff / 2}px`;
        break;
      case 'left':
        styles.top = `${top - heightDiff / 2}px`;
        styles.left = `${left - popupWidth}px`;
        break;
      default:
      case 'bottom':
        styles.top = `${bottom}px`;
        styles.left = `${left - widthDiff / 2}px`;
        break;
      case 'right':
        styles.top = `${top - heightDiff / 2}px`;
        styles.left = `${right}px`;
        break;
      case 'bottomLeft':
        styles.top = `${bottom}px`;
        styles.left = `${left}px`;
        break;
      case 'bottomRight':
        styles.top = `${bottom}px`;
        styles.left = `${right - popupWidth}px`;
        break;
      case 'topLeft':
        styles.top = `${top - popupHeight}px`;
        styles.left = `${left}px`;
        break;
      case 'topRight':
        styles.top = `${top - popupHeight}px`;
        styles.left = `${right - popupWidth}px`;
        break;
    }
    return styles;
  }

  function handleMouseEnter() {
    setIsVisible(true);
    setTimeout(update, 0);
  }

  function handleMouseLeave() {
    setIsVisible(false);
  }

  if (disabled) {
    return <>{children}</>;
  }

  return (
    <div
      ref={tooltipContainerRef}
      data-ds2="tooltip"
      className={className}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onFocus={handleMouseEnter}
      onBlur={handleMouseLeave}
      onClick={onClick}
      {...(id != undefined
        ? {
            tabIndex: 0,
            'aria-describedby': `tooltip-${id}`,
          }
        : undefined)}
    >
      {children}
      {render && isVisible
        ? createPortal(
            <div
              ref={tooltipContentRef}
              data-ds2="tooltip-content"
              className={`tw-fixed tw-z-50 tw-shadow-lg ${widthMap[width]}`}
              style={getTooltipStyles()}
              {...(id != undefined
                ? {
                    id: `tooltip-${id}`,
                    role: 'tooltip',
                    'aria-hidden': isVisible,
                  }
                : undefined)}
            >
              {render}
            </div>,
            document.body
          )
        : null}
    </div>
  );
};

Tooltip.defaultProps = {
  position: 'top',
  className: '',
};
