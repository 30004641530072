import { InformationDividerProps } from './information.divider.interfaces';

export const InformationDivider = ({
  orientation,
  className,
}: InformationDividerProps) => (
  <div
    className={`tw-border-solid 
        tw-border-neutral-900 tw-border-opacity-15 
        dark:tw-border-neutral-100 dark:tw-border-opacity-15 
        ${
          orientation === 'vertical'
            ? 'tw-w-px tw-border-l'
            : 'tw-h-px tw-border-t'
        }
        ${className}`}
  />
);

InformationDivider.defaultProps = {
  className: '',
  orientation: 'horizontal',
};
