import {
	createHistory,
	History,
	HistorySource,
	HistoryListener,
	HistoryUnsubscribe,
	NavigateFn,
	NavigateOptions,
} from '@reach/router';

class HistoryService {
	readonly routerHistory: History;

	private navigator: NavigateFn;

	private listeners: { id: string; unsubscribe: HistoryUnsubscribe }[];

	constructor() {
		this.routerHistory = createHistory(window as unknown as HistorySource);
		this.navigator = this.routerHistory.navigate;
		this.listeners = [];
	}

	get history() {
		return this.routerHistory;
	}

	navigateBack(pages: number = 1, options?: NavigateOptions<{}>) {
		return this.navigate(-pages as any, options);
	}

	navigate(to: string, options?: NavigateOptions<{}>) {
		return this.navigator(to, options);
	}

	findListener(id: string) {
		return this.listeners.find((listener) => listener.id === id);
	}

	addListener(args: { id: string; listener: HistoryListener }) {
		const { id, listener } = args;
		if (this.listeners.find((listener) => listener.id === id)) {
			throw Error(
				`HistoryService cannot add listener id ${id} because it already exists`
			);
		}
		const unsubscribe = this.routerHistory.listen(listener);
		this.listeners.push({ id, unsubscribe });
	}

	removeListener(id: string) {
		const listener = this.listeners.find((listener) => listener.id === id);
		if (!listener) {
			throw Error(
				`HistoryService cannot remove listener id ${id} because it doesn't exist`
			);
		}
		listener.unsubscribe();
		this.listeners = this.listeners.filter((listener) => listener.id !== id);
	}
}

export const historyService = new HistoryService();
