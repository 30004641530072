import { E164Number, CountryCode } from 'libphonenumber-js/types';
import { debounce } from 'lodash';
import { useEffect, useState } from 'react';
import * as React from 'react';

// services
import { parsePhoneNumber } from 'react-phone-number-input';
import { forkJoin } from 'rxjs';

import { ServiceArea, Service } from '@api-interfaces';
import { referenceService, serviceAreasService, servicesService } from '@apis';

// helpers
import { DropdownItem } from '@atoms/form-ds2/dropdown/dropdown.interfaces';
import { FieldDropdown } from '@atoms/form-ds2/field-dropdown';
import { FieldInputDate } from '@atoms/form-ds2/field-input-date';
import { FieldInputTime } from '@atoms/form-ds2/field-input-time';
import { UserSelect } from '@components/_new/_form';
import { sendError, TimeUtils, titleCase } from '@core/helpers';
import { useLocationsRef } from '@hooks';
import { useWorkOrderModalContext } from '../../../../_context';

// components
import { Priority } from '@components/_new/_form/dropdowns/priority';
import { FieldInputText } from '@atoms/form-ds2/field-input-text';
import { FieldInputPhone } from '@atoms/form-ds2/field-input-phone';

// interfaces

type ProgramDropdownItem = DropdownItem<Service>;
type LocationDropdownItem = DropdownItem<ServiceArea>;

export const WorkOrderDetailsPanelForm = () => {
    const { workOrderEdit, setWorkOrderEdit, isClosing, submissionAttempt } =
        useWorkOrderModalContext();
    const [isLoading, setIsLoading] = useState(true);
    const [requestTypes, setRequestTypes] = useState<DropdownItem[]>([]);
    const [programs, setPrograms] = useState<ProgramDropdownItem[]>([]);
    const [buildings, setBuildings] = useState<LocationDropdownItem[]>([]);
    const [floors, setFloors] = useState<LocationDropdownItem[]>([]);
    const [rooms, setRooms] = useState<LocationDropdownItem[]>([]);
    const [isFloorsLoading, setIsFloorsLoading] = useState(false);
    const [isRoomsLoading, setIsRoomsLoading] = useState(false);

    const locations = useLocationsRef(workOrderEdit?.location);

    useEffect(() => {
        if (!isLoading) return;

        const subscription = forkJoin([
            referenceService.getItems({ group_code: 'workorder_service' }),
            servicesService.getServices({}),
            serviceAreasService.getServiceAreas({
                quick_landing: true,
                limit: 100,
            }),
        ]).subscribe({
            next: ([requestRes, services, serviceAreas]) => {
                const requests = requestRes.map((type) => ({
                    ...type,
                    id: type.id,
                    name: type.display_name,
                }));
                setRequestTypes(requests);
                setBuildings(serviceAreas.results);
                if (locations.building?.id) {
                    setIsFloorsLoading(true);
                }
                if (locations.floor?.id) {
                    setIsRoomsLoading(true);
                }
                setPrograms(
                    services.results.map((program) => ({
                        ...program,
                        name: titleCase(program.name),
                    }))
                );
                setIsLoading(false);
            },
            error: sendError({
                callback: () => {
                    setIsLoading(false);
                },
            }),
        });

        return () => {
            subscription.unsubscribe();
        };
    }, [isLoading]);

    useEffect(() => {
        if (!isFloorsLoading || !locations?.building?.id) return;

        const subscription = serviceAreasService
            .getServiceAreas({
                parent: locations.building.id,
                quick_landing: true,
                limit: 1000,
            })
            .subscribe({
                next: (res) => {
                    setFloors(
                        res.results.filter((area) =>
                            /floor/i.test(area.type.name)
                        )
                    );
                    setIsFloorsLoading(false);
                },
                error: (err) =>
                    sendError({
                        toastMessage: err.error
                            ? err.error
                            : 'Cannot retrieve getFloorList API information',
                        callback: () => {
                            setIsFloorsLoading(false);
                        },
                    }),
            });
        return () => {
            subscription.unsubscribe();
        };
    }, [isFloorsLoading]);

    useEffect(() => {
        if (!isRoomsLoading || !locations?.floor?.id) return;

        const subscription = serviceAreasService
            .getServiceAreas({ parent: locations.floor.id, limit: 1000 })
            .subscribe({
                next: (res) => {
                    setRooms(res.results);
                    setIsRoomsLoading(false);
                },
                error: (err) =>
                    sendError({
                        toastMessage: err.error
                            ? err.error
                            : 'Cannot retrieve getRoomList API information',
                        callback: () => {
                            setIsRoomsLoading(false);
                        },
                    }),
            });
        return () => {
            subscription.unsubscribe();
        };
    }, [isRoomsLoading]);

    const projectWorkItems = [
        { id: 0, name: 'No' },
        { id: 1, name: 'Yes' },
    ];

    const handleDueDateSelect = (date: Date) => {
        // When a due date is set to today the WO will show as past due.  This resets the selected day's time to just before midnight of that day
        const thisDate = new Date(date);
        thisDate.setHours(23, 59, 59, 999);
        const newDateTime = thisDate.toISOString();
        setWorkOrderEdit({
            ...workOrderEdit,
            due_date: newDateTime,
        });
    };

    const handleCompleteDateSelect = (date: Date) => {
        if (date === null) {
            setWorkOrderEdit({
                ...workOrderEdit,
                work_complete_date: null,
            });
        } else {
            const prevTime = workOrderEdit?.work_complete_date
                ? new Date(workOrderEdit.work_complete_date)
                : TimeUtils.endOfDay(new Date());
            const hour = prevTime.getHours();
            const minutes = prevTime.getMinutes();
            const newDate = TimeUtils.setMinutes(
                TimeUtils.setHours(new Date(date), hour),
                minutes
            ).toISOString();

            setWorkOrderEdit({
                ...workOrderEdit,
                work_complete_date: newDate,
            });
        }
    };

    const handleCompleteTimeSelect = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        const prevTime = workOrderEdit?.work_complete_date
            ? new Date(workOrderEdit.work_complete_date)
            : TimeUtils.endOfDay(new Date());

        const [hour, minute] = e.target.value.split(':');

        setWorkOrderEdit({
            ...workOrderEdit,
            work_complete_date: e.target.value
                ? TimeUtils.setMinutes(
                      TimeUtils.setHours(
                          TimeUtils.startOfDay(prevTime),
                          parseInt(hour, 10)
                      ),
                      parseInt(minute, 10)
                  ).toISOString()
                : '',
        });
    };

    function handleEquipmentChange(e: React.ChangeEvent<HTMLInputElement>) {
        setWorkOrderEdit({
            ...workOrderEdit,
            equipment: e.target.value,
        });
    }

    function handlePhoneNumberChange(phoneNumber: E164Number) {
        if (!phoneNumber) return;
        const phone = parsePhoneNumber(phoneNumber);
        if (phone) {
            setWorkOrderEdit({
                ...workOrderEdit,
                contact_number_country_code: phone.country,
                contact_number: phone.number,
            });
        }
    }

    const debouncedEquipmentChange = debounce(handleEquipmentChange, 250);
    const debouncedPhoneNumberChange = debounce(handlePhoneNumberChange, 250);

    return (
        <div className="tw-space-y-6">
            <FieldDropdown
                required
                id="work_order_program"
                label="Program"
                items={programs}
                selectedItem={
                    programs.find(
                        (program) => program.id === workOrderEdit?.service?.id
                    ) || null
                }
                onChange={(program: ProgramDropdownItem) => {
                    setWorkOrderEdit({
                        ...workOrderEdit,
                        service: {
                            id: program.id,
                        },
                        // Most of the time, cost line items will have the same program as the top level work order.
                        // This should save the user clicks
                        cost_line_items: workOrderEdit?.cost_line_items.map(
                            (itm) => ({
                                ...itm,
                                service: {
                                    id: program.id as number,
                                },
                            })
                        ),
                    });
                }}
                error={
                    submissionAttempt &&
                    !workOrderEdit?.service?.id &&
                    'Program is required'
                }
                disabled={!programs.length}
            />

            <FieldDropdown
                required
                id="work_order_is_project_work"
                label="Project Work"
                items={projectWorkItems}
                selectedItem={
                    workOrderEdit?.is_project_work
                        ? projectWorkItems[1]
                        : projectWorkItems[0]
                }
                onChange={(projectWork) => {
                    setWorkOrderEdit({
                        ...workOrderEdit,
                        is_project_work: projectWork?.id === 1,
                    });
                }}
            />

            <div className="tw-flex tw-space-x-4">
                {/* SBM billing uses invoice_numbers to correlate with external systems. Non-SBM billing uses external_work_order_id */}
                {workOrderEdit?.retrieve_from_vendor ? (
                    <FieldInputText
                        id="wo_third_party_id"
                        label="Third Party ID#"
                        disabled
                        value={workOrderEdit?.external_work_order_id ?? '---'}
                    />
                ) : (
                    <FieldInputText
                        id="wo_invoice_number"
                        label="Invoice Number"
                        disabled
                        value={workOrderEdit?.invoice_number ?? '---'}
                    />
                )}
                <FieldInputText
                    id="wo_date_reported"
                    label="Date Reported"
                    disabled
                    value={`${TimeUtils.format(
                        workOrderEdit?.create_date,
                        'MM/DD/YYYY h:mm A'
                    )}`}
                />
            </div>

            <FieldInputDate
                required
                id="expected_due_date"
                label="Expected Due Date"
                value={
                    workOrderEdit.due_date
                        ? TimeUtils.format(workOrderEdit.due_date, 'MM/DD/YYYY')
                        : null
                }
                onChange={handleDueDateSelect}
                error={
                    submissionAttempt &&
                    !workOrderEdit?.due_date &&
                    'Expected Due Date is required'
                }
                disabled={workOrderEdit?.retrieve_from_vendor}
            />

            <div className="tw-flex tw-space-x-4">
                <FieldInputDate
                    required={isClosing}
                    disableFuture
                    id="work_order_complete_date"
                    label="Work Completed"
                    value={
                        workOrderEdit.work_complete_date
                            ? TimeUtils.format(
                                  workOrderEdit.work_complete_date,
                                  'MM/DD/YYYY'
                              )
                            : null
                    }
                    onChange={handleCompleteDateSelect}
                    error={
                        isClosing &&
                        !workOrderEdit?.work_complete_date &&
                        'Work Completed is required'
                    }
                />

                <FieldInputTime
                    required={isClosing}
                    id="work_order_complete_time"
                    label="Completed At"
                    onChange={handleCompleteTimeSelect}
                    value={
                        workOrderEdit?.work_complete_date
                            ? TimeUtils.format(
                                  workOrderEdit.work_complete_date,
                                  'HH:mm'
                              )
                            : ''
                    }
                />
            </div>

            <div className="tw-flex tw-space-x-4">
                <Priority
                    className="tw-w-32 tw-space-y-1.5"
                    title="Priority"
                    onClick={(selected) => {
                        setWorkOrderEdit({
                            ...workOrderEdit,
                            priority: {
                                id: selected.key as number,
                            },
                        });
                    }}
                    valueId={workOrderEdit?.priority?.id}
                />
                <FieldDropdown
                    required
                    id="request-type"
                    label="Request Type"
                    items={requestTypes}
                    selectedItem={
                        requestTypes.find(
                            (requestType) =>
                                requestType.id ===
                                workOrderEdit?.service_type?.id
                        ) || null
                    }
                    onChange={(selectedRequestType) => {
                        setWorkOrderEdit({
                            ...workOrderEdit,
                            service_type: {
                                id: selectedRequestType.id,
                            },
                        });
                    }}
                    error={
                        submissionAttempt &&
                        !workOrderEdit?.service_type?.id &&
                        'Request Type is required'
                    }
                    disabled={!requestTypes.length}
                />
            </div>

            <FieldInputText
                id="work_order_equipment"
                label="Equipment Needed"
                defaultValue={workOrderEdit.equipment ?? ''}
                onChange={debouncedEquipmentChange}
            />

            <FieldDropdown
                id="work_order_building"
                label="Building"
                items={buildings}
                selectedItem={
                    buildings.find(
                        (building) => building.id === locations.building?.id
                    ) || null
                }
                onChange={(building: LocationDropdownItem) => {
                    setWorkOrderEdit({
                        ...workOrderEdit,
                        location: building,
                    });
                    setFloors([]);
                    setIsFloorsLoading(true);
                }}
                disabled={!buildings.length}
            />

            <FieldDropdown
                id="work_order_floor"
                label="Floor"
                items={floors}
                selectedItem={
                    floors.find((floor) => floor.id === locations.floor?.id) ||
                    null
                }
                onChange={(floor: LocationDropdownItem) => {
                    setWorkOrderEdit({
                        ...workOrderEdit,
                        location: floor,
                    });
                    setRooms([]);
                    setIsRoomsLoading(true);
                }}
                disabled={!floors.length}
            />

            <FieldDropdown
                id="work_order_room"
                label="Area"
                items={rooms}
                selectedItem={
                    rooms.find((room) => room.id === locations.room?.id) || null
                }
                onChange={(room: LocationDropdownItem) => {
                    setWorkOrderEdit({
                        ...workOrderEdit,
                        location: room,
                    });
                }}
                disabled={!rooms.length}
            />

            <UserSelect
                userType="customer"
                title="Created By"
                className="tw-w-full"
                onSelect={(employee) => {
                    setWorkOrderEdit({
                        ...workOrderEdit,
                        assigned_to: {
                            id: employee?.id,
                        },
                    });
                }}
                disabled
                defaultValue={workOrderEdit.created_by?.id || undefined}
            />

            <UserSelect
                userType="customer"
                title="Requested By"
                className="tw-w-full"
                onSelect={(employee) => {
                    setWorkOrderEdit({
                        ...workOrderEdit,
                        reporter: {
                            id: employee?.id,
                        },
                    });
                }}
                saveError={workOrderEdit?.reporter?.id == null}
                defaultValue={
                    (workOrderEdit && workOrderEdit?.reporter?.id) || undefined
                }
            />

            <UserSelect
                userType="employee"
                title="Assigned To"
                className="tw-w-full"
                onSelect={(employee) => {
                    setWorkOrderEdit({
                        ...workOrderEdit,
                        assigned_to: {
                            id: employee?.id,
                        },
                    });
                }}
                saveError={!(workOrderEdit?.assigned_to?.id ?? true)}
                defaultValue={workOrderEdit.assigned_to?.id || undefined}
                allowAddUser={false}
            />

            <FieldInputPhone
                id="work_order_phone_number"
                label="Phone Number (Optional)"
                defaultCountry={
                    (workOrderEdit?.contact_number_country_code as CountryCode) ??
                    'US'
                }
                value={workOrderEdit?.contact_number ?? ''}
                onChange={debouncedPhoneNumberChange}
            />
        </div>
    );
};
