import { useEffect, useRef, useState } from 'react';

// components

// helpers
import { Subscription } from 'rxjs';

// interfaces
import { Employee } from '@api-interfaces';
import { ServiceLog } from '@apis/public/service-logs/interfaces';
import { Icon, Link, Text } from '@atoms';
import { TimeUtils } from '@helpers';
import { MiniProfile } from '@new/MiniProfiles';

interface ServiceProps {
    id: number;
    employee: Employee;
    scan: {
        max_duration_end_time: string;
        route_stop_create_date: string;
    };
}

interface ServiceLogRowProps {
    serviceLog: [string, ServiceLog[]];
    isFirst: boolean;
    areaId: number;
    onViewClick: (stopId: number, areaId: number, date: string) => void;
}

export const ServiceLogRow = ({
    serviceLog,
    isFirst,
    areaId,
    onViewClick,
}: ServiceLogRowProps) => {
    const [isExpanded, setIsExpanded] = useState<boolean>(isFirst);
    const subscription = useRef(new Subscription());
    const date =
        serviceLog[1][0]?.scan?.max_duration_end_time ??
        serviceLog[1][0]?.scan?.route_stop_create_date;
    const services = serviceLog[1];
    const numberOfServices = services.length;
    const options: Intl.DateTimeFormatOptions = {
        weekday: 'long',
        month: 'long',
        day: 'numeric',
        year: 'numeric',
    };
    const displayDate = new Date(date)
        .toLocaleTimeString('en', options)
        .toString()
        .split(' at')[0];

    useEffect(() => {
        return () => {
            subscription.current.unsubscribe();
        };
    }, []);

    const getDisplayTime = (service: ServiceProps) => {
        const serviceDate =
            service?.scan?.max_duration_end_time ??
            service?.scan?.route_stop_create_date;
        const now = new Date();
        const hoursAgo = TimeUtils.differenceInHours(now, serviceDate);
        const serviceTime = new Intl.DateTimeFormat('en', {
            timeStyle: 'short',
        }).format(new Date(serviceDate));
        return hoursAgo < 24
            ? `${TimeUtils.distanceInWords(now, serviceDate)} ago`
            : serviceTime;
    };

    const handleViewClick = (service: ServiceProps) => {
        onViewClick(service.id, areaId, date);
    };

    return (
        <div>
            <div className="tw-flex tw-items-center tw-justify-between tw-px-4 tw-py-4 tw-border-b tw-border-solid tw-border-theme-neutral-900-100 tw-border-opacity-15">
                <div className="tw-flex tw-items-center tw-space-x-3">
                    <Icon
                        icon={isExpanded ? 'caret-down' : 'caret-right'}
                        size="lg"
                        onClick={() => setIsExpanded(!isExpanded)}
                        className="tw-text-theme-neutral-500-600 hover:tw-cursor-pointer"
                    />
                    <Text font="h3" color="hi-contrast">
                        {displayDate}
                    </Text>
                </div>
                <Text font="h4" color="neutral-offset">
                    {`${numberOfServices} Service${numberOfServices > 1 ? 's' : ''}`}
                </Text>
            </div>
            <div>
                {isExpanded &&
                    services?.map((service) => {
                        return (
                            <div
                                key={service.id}
                                className="tw-pl-6 tw-border-b tw-border-solid tw-border-theme-neutral-900-100 tw-border-opacity-15"
                            >
                                <div
                                    className="tw-flex tw-space-x-2 tw-border-l tw-border-solid tw-border-theme-neutral-900-100 tw-border-opacity-15"
                                    style={{ height: '70px' }}
                                >
                                    <div
                                        className="tw-border-b tw-border-solid tw-border-theme-neutral-900-100 tw-border-opacity-15"
                                        style={{ width: '11px', height: '50%' }}
                                    />
                                    <div className="tw-py-4 tw-pr-4 tw-grow">
                                        <div className="tw-flex tw-justify-between">
                                            <MiniProfile.Md
                                                person={
                                                    service?.employee?.person
                                                }
                                                detail={
                                                    service?.employee?.positions
                                                        ?.map((pos) => pos.name)
                                                        ?.join(', ') ?? ''
                                                }
                                            />
                                            <div>
                                                <Link
                                                    as="div"
                                                    className="tw-text-sm tw-font-medium tw-text-right"
                                                    onClick={() =>
                                                        handleViewClick(service)
                                                    }
                                                >
                                                    View
                                                </Link>
                                                <Text
                                                    font="body-sm"
                                                    color="neutral-offset"
                                                >
                                                    {getDisplayTime(service)}
                                                </Text>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
            </div>
        </div>
    );
};
