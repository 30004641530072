import { useMemo } from 'react';
import { useMedia } from 'react-use';

// should match tailwind's config ~/config/tailwindcss/base/screens.js
import screens from '../../../config/tailwindcss/base/screens';

const QUERY_XS = `(min-width: ${screens.xs})`;
const QUERY_SM = `(min-width: ${screens.sm})`;
const QUERY_MD = `(min-width: ${screens.md})`;
const QUERY_LG = `(min-width: ${screens.lg})`;
const QUERY_XL = `(min-width: ${screens.xl})`;
const QUERY_2XL = `(min-width: ${screens['2xl']})`;
const QUERY_3XL = `(min-width: ${screens['3xl']})`;

/**
 * @returns {Object} breakpoint
 * @returns {boolean} breakpoint.xs - min-width: 360px
 * @returns {boolean} breakpoint.sm - min-width: 640px
 * @returns {boolean} breakpoint.md - min-width: 768px
 * @returns {boolean} breakpoint.lg - min-width: 1024px
 * @returns {boolean} breakpoint.xl - min-width: 1280px
 * @returns {boolean} breakpoint.2xl - min-width: 1440px
 * @returns {boolean} breakpoint.3xl - min-width: 2560px
 */
export function useBreakpoints() {
	const isXs = useMedia(QUERY_XS);
	const isSm = useMedia(QUERY_SM);
	const isMd = useMedia(QUERY_MD);
	const isLg = useMedia(QUERY_LG);
	const isXl = useMedia(QUERY_XL);
	const is2xl = useMedia(QUERY_2XL);
	const is3xl = useMedia(QUERY_3XL);

	const breakpoints = useMemo(() => {
		return {
			xs: isXs,
			sm: isSm,
			md: isMd,
			lg: isLg,
			xl: isXl,
			xl2: is2xl,
			xl3: is3xl,
			'2xl': is2xl,
			'3xl': is3xl,
		};
	}, [isXs, isSm, isMd, isLg, isXl, is2xl, is3xl]);

	return breakpoints;
}

export function useCurrentBreakpoint() {
	const isXs = useMedia(QUERY_XS);
	const isSm = useMedia(QUERY_SM);
	const isMd = useMedia(QUERY_MD);
	const isLg = useMedia(QUERY_LG);
	const isXl = useMedia(QUERY_XL);
	const is2xl = useMedia(QUERY_2XL);
	const is3xl = useMedia(QUERY_3XL);

	let bp = '';
	if (isXs) bp = 'xs';
	if (isSm) bp = 'sm';
	if (isMd) bp = 'md';
	if (isLg) bp = 'lg';
	if (isXl) bp = 'xl';
	if (is2xl) bp = 'xl2';
	if (is3xl) bp = 'xl3';
	return bp;
}
