import { Text } from '@atoms';

// interfaces
import { ErrorTextProps } from './error-text.interfaces';

export const ErrorText = ({ error, ...props }: ErrorTextProps) => {
  return error ? (
    <Text data-ds2="error-text" font="body-sm" color="danger" {...props}>
      {error}
    </Text>
  ) : null;
};
