import { useMemo } from 'react';

// context
import { Text, Tooltip, Box, IconButton, Breadcrumbs } from '@atoms';
import { DateRange } from '@core/app-context/app.interfaces';
import { useAppContext, useBreakpoints } from '@hooks';

// components
import { DatePickerV2, Button } from '@new';

import { PageHeaderProps } from './PageHeader.interfaces';
import { ProgramPicker } from './ProgramPicker';

// interfaces

export const PageHeader = ({
    title,
    subtitle,
    crumbs,
    className,
    style,
    datePicker,
    hasProgramPicker,
    buttons,
    customNode,
    underline,
}: PageHeaderProps) => {
    const {
        state: { dateRange, user },
        dispatch,
    } = useAppContext();
    const bp = useBreakpoints();

    function handleDateRangeChange(dateRange: DateRange) {
        if (datePicker?.setDateRange) {
            datePicker.setDateRange(dateRange);
        } else {
            dispatch({
                type: 'SET_DATE_RANGE',
                payload: dateRange,
            });
        }
    }

    const programPicker = useMemo(() => {
        if (hasProgramPicker) {
            return (
                <ProgramPicker
                    setProgram={
                        typeof hasProgramPicker === 'object'
                            ? hasProgramPicker.handleProgramPickerChange
                            : undefined
                    }
                    type={
                        typeof hasProgramPicker === 'object'
                            ? hasProgramPicker.type
                            : undefined
                    }
                />
            );
        }
        return null;
    }, [hasProgramPicker]);

    //    const canViewConfigButtons = user.hasPermission(1, 'Configurable Dashboard');

    return (
        <div
            data-ds2="page-header"
            className={`tw-flex tw-flex-col tw-space-y-4 tw-mb-4 md:tw-space-y-0 md:tw-justify-between md:tw-flex-row lg:tw-items-center ${className}`}
            style={style}
        >
            <div className="tw-flex-1 tw-max-w-fit">
                {title && (
                    <Text font="h2" color="primary">
                        {title}
                    </Text>
                )}
                {subtitle && (
                    <Text font="h1" color="hi-contrast">
                        {subtitle}
                    </Text>
                )}
                {crumbs && <Breadcrumbs crumbs={crumbs} />}
                {underline && (
                    <div className="tw-flex tw-bg-theme-neutral-300-700 tw-h-[1px] tw-w-full tw-mt-1" />
                )}
            </div>

            {(buttons || datePicker || hasProgramPicker || customNode) && (
                <div className="tw-flex tw-flex-col tw-items-end tw-gap-4">
                    <div className="tw-flex tw-items-center tw-gap-4 tw-flex-row-reverse md:tw-flex-row lg:tw-justify-end tw-w-full md:tw-w-auto">
                        {customNode}
                        {bp.lg && programPicker}
                        {buttons &&
                            buttons?.map(({ disabledMessage, ...button }) => (
                                <Tooltip
                                    key={button.label}
                                    position="bottom"
                                    render={
                                        button.disabled && disabledMessage ? (
                                            <Box
                                                rounded
                                                className="tw-mt-2 tw-p-4 tw-w-full tw-shadow-lg tw-z-20"
                                            >
                                                <Text
                                                    font="h4"
                                                    color="hi-contrast"
                                                >
                                                    {disabledMessage}
                                                </Text>
                                            </Box>
                                        ) : null
                                    }
                                >
                                    {bp.sm ? (
                                        <Button
                                            color={button.color ?? 'primary'}
                                            {...button}
                                        />
                                    ) : (
                                        <IconButton
                                            icon={button.icon}
                                            onClick={button.onClick}
                                            disabled={button.disabled}
                                            className={button.className}
                                            type={button.color}
                                            style={button.style}
                                        />
                                    )}
                                </Tooltip>
                            ))}

                        {datePicker && (
                            <DatePickerV2
                                dateRange={datePicker?.dateRange ?? dateRange}
                                setDateRange={handleDateRangeChange}
                                allowFutureDates={datePicker?.allowFutureDates}
                                alignPopup="right"
                                className="tw-flex-1"
                                locales={user.language?.code ?? 'en'}
                                lockRange={
                                    datePicker?.type === 'monthPicker'
                                        ? 'this-month'
                                        : datePicker.type === 'today'
                                          ? 'today'
                                          : null
                                }
                            />
                        )}
                    </div>
                    {!bp.lg && programPicker}
                </div>
            )}
        </div>
    );
};

PageHeader.defaultProps = {
    className: '',
};
