import { useEffect, useState, useRef } from 'react';

// interfaces
import { Subscription } from 'rxjs';

import {
  ItemWithFrequency,
  InspectionTask,
  InspectionArea,
  Inspection,
} from '@api-interfaces';

// services
import { contractsService } from '@apis';

// components
import { LoaderBlocks, Text } from '@atoms';

// helpers
import { sendError, titleCase } from '@helpers';
import { useBreakpoints } from '@hooks';
import { Mode } from '@modals/_ds2/audit/_context/interfaces';
import { TextChip } from '@modals/_ds2/audit/helpers';

function getPeriodsCSV(task: ItemWithFrequency): string {
  return task.frequency
    .filter((frequency) => frequency.area_taskcount > 0) // only want frequencies with area_taskcount greater than zero
    .map((frequency) => titleCase(frequency.period)) // get the period of each frequency in title casing
    .filter((period, index, self) => self.indexOf(period) === index) // unique periods
    .join(', '); // combine with comma separated values
}

interface TasksTabProps {
  currentArea: InspectionArea;
  mode: Mode;
  inspection: Inspection;
}

export const TasksTab = ({ currentArea, mode, inspection }: TasksTabProps) => {
  const bp = useBreakpoints();
  const subscriptions = useRef<{ [key: string]: Subscription }>({});
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [auditTasks, setAuditTasks] = useState<ItemWithFrequency[]>([]);
  const [inspectionTasks, setInspectionTasks] = useState<InspectionTask[]>([]);
  const orderedAuditTasks = auditTasks.sort((a, b) =>
    a.name.localeCompare(b.name)
  );
  const orderedInspectionTasks = inspectionTasks.sort((a, b) => {
    const nameA = `${a.task_def?.action?.name ?? ''} ${
      a.task_def?.area_element_type?.name ?? ''
    }`;
    const nameB = `${b.task_def?.action?.name ?? ''} ${
      b.task_def?.area_element_type?.name ?? ''
    }`;
    return nameA.localeCompare(nameB);
  });

  useEffect(() => {
    if (mode === 'INSPECTION') {
      setInspectionTasks(currentArea.task_inspections);
      setIsLoading(false);
    } else {
      subscriptions.current['audit-area-modal-tasks-init'] = contractsService
        .getTaskBundlesByAreaTemplateId({
          contractId: inspection?.contract?.id,
          areaTemplateId: currentArea.location?.area_type?.id,
          serviceAreaId: currentArea?.location?.boundary_id,
          area: currentArea?.location?.id,
          calc: 'area_taskcount',
          limit: 1000,
        })
        .subscribe({
          next: (res) => {
            setAuditTasks(res.results);
            setIsLoading(false);
          },
          error: sendError({
            toastMessage:
              'There was an error retrieving Audit Area Tasks data.',
            callback: () => {
              setIsLoading(false);
            },
          }),
        });
    }

    return () =>
      Object.values(subscriptions.current).forEach((subscription) =>
        subscription.unsubscribe()
      );
  }, [currentArea?.id]);

  const inspectionsTable = () => {
    const inspectionsSplit = [[], []];
    orderedInspectionTasks.forEach((task) => {
      !task.couldnt_score && task.is_pass !== null
        ? inspectionsSplit[0].push(task)
        : inspectionsSplit[1].push(task);
    });
    return inspectionsSplit?.map(
      (array, index) =>
        array.length !== 0 && (
          <div
            key={index}
            className="form-common-scroll tw-overflow-x-scroll tw-whitespace-nowrap"
          >
            <table className="tw-w-full">
              <thead>
                <tr>
                  {[index === 0 ? 'Scored' : 'Un-scored', ''].map(
                    (header, i, self) => (
                      <th
                        key={i}
                        className={`tw-text-left tw-min-w-[100px] tw-pb-4 tw-border-b tw-border-solid tw-border-neutral-300 dark:tw-border-neutral-600 ${
                          i === 0
                            ? 'tw-pr-2'
                            : i === self.length - 1
                            ? 'tw-pl-2'
                            : 'tw-px-2'
                        } ${i === 1 ? 'tw-w-1/4' : ''}`}
                      >
                        <Text font="h4" color="neutral-offset">
                          {header}
                        </Text>
                      </th>
                    )
                  )}
                </tr>
              </thead>
              <tbody>
                {array?.map((task, i) => {
                  const action = task.task_def?.action?.name;
                  const areaElementType =
                    task.task_def?.area_element_type?.name;
                  const name =
                    action && areaElementType
                      ? `${action} ${areaElementType}`
                      : 'N/A';

                  return (
                    <tr
                      key={i}
                      className="tw-border-solid tw-border-b tw-border-neutral-300 dark:tw-border-neutral-600 last:tw-border-none"
                    >
                      <td className="tw-py-5 tw-pr-2">
                        <Text
                          font="h3"
                          color="hi-contrast"
                          className={`tw-whitespace-pre-wrap ${
                            bp.md
                              ? 'tw-max-w-[346px]'
                              : bp.sm
                              ? 'tw-max-w-[295px]'
                              : 'tw-max-w-[198px]'
                          }`}
                          title={name}
                        >
                          {name}
                        </Text>
                      </td>
                      <td className="tw-py-5 tw-pl-2 tw-text-center">
                        <TextChip
                          text={
                            task.couldnt_score
                              ? 'N/A'
                              : task.is_pass === true
                              ? 'Met'
                              : task.is_pass === false
                              ? 'Not Met'
                              : 'N/A'
                          }
                          type={
                            task.couldnt_score
                              ? 'neutral'
                              : task.is_pass === true
                              ? 'success'
                              : task.is_pass === false
                              ? 'danger'
                              : 'neutral'
                          }
                          size="sm"
                          className="tw-inline-flex"
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )
    );
  };
  return !isLoading ? (
    <div>
      {mode === 'INSPECTION' && inspectionTasks?.length ? (
        inspectionsTable()
      ) : mode === 'AUDIT' && auditTasks?.length ? (
        <div className="form-common-scroll tw-overflow-x-scroll tw-whitespace-nowrap">
          <table className="tw-w-full">
            <thead>
              <tr>
                {['Task', 'Frequency'].map((header, i, self) => (
                  <th
                    key={i}
                    className={`tw-text-left tw-min-w-[100px] tw-pb-4 tw-border-b tw-border-solid tw-border-neutral-300 dark:tw-border-neutral-600 ${
                      i === 0
                        ? 'tw-pr-2'
                        : i === self.length - 1
                        ? 'tw-pl-2'
                        : 'tw-px-2'
                    }`}
                  >
                    <Text font="h4" color="neutral-offset">
                      {header}
                    </Text>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {orderedAuditTasks?.map((task, i) => (
                <tr
                  key={i}
                  className="tw-border-solid tw-border-b tw-border-neutral-300 dark:tw-border-neutral-600 last:tw-border-none"
                >
                  <td className="tw-py-5 tw-pr-2">
                    <Text
                      font="h3"
                      color="hi-contrast"
                      className={`tw-whitespace-pre-wrap ${
                        bp.md
                          ? 'tw-max-w-[295px]'
                          : bp.sm
                          ? 'tw-max-w-[198px]'
                          : 'tw-max-w-[164px]'
                      }`}
                      title={task.name}
                    >
                      {task.name}
                    </Text>
                  </td>
                  <td className="tw-py-5 tw-pl-2">
                    <Text font="body-md" color="hi-contrast">
                      {getPeriodsCSV(task)}
                    </Text>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="tw-w-full tw-py-6">
          <Text font="body-md" color="neutral-offset">
            No Tasks Available
          </Text>
        </div>
      )}
    </div>
  ) : (
    <LoaderBlocks className="tw-w-full tw-py-10" />
  );
};
