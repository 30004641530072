import { useState } from 'react';

// interfaces

// services

// components
import { Box, ScoreChip, Text } from '@atoms';

// helpers
import { truncate } from '@core/helpers';
import { awsService } from '@core/services';

import { ImageWithDetailsProps } from './ImageWithDetails.interfaces';

// assets
import '../../ImageCarousel.css';

export const ImageWithDetails = ({
  imageUrl,
  imageTextValues,
  scoreChip,
}: ImageWithDetailsProps) => {
  const [isVisible, setIsVisible] = useState(false);

  function handleMouseEnter() {
    setIsVisible(true);
  }

  function handleMouseLeave() {
    setIsVisible(false);
  }

  return (
    // image displayed via div with background image to use contain, no-repeat, center
    <div
      style={{
        backgroundImage: `url("${awsService.getUrl(imageUrl)}")`,
        width: 'fit-content',
        height: 'fit-content',
      }}
      className="tw-relative tw-mx-auto tw-bg-contain tw-bg-no-repeat tw-bg-center"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onFocus={handleMouseEnter}
      onBlur={handleMouseLeave}
    >
      {/* img with visibility: hidden used to force container div to exact size of image, keeping Box pinned to image */}
      <img
        src={awsService.getUrl(imageUrl)}
        alt={imageTextValues.areaName}
        className="image-carousel-image-size tw-invisible tw-relative tw-object-contain tw-mx-auto"
      />

      <Box
        type="image-details"
        rounded
        className={`tw-absolute tw-bottom-1 tw-left-1 tw-p-4 ${
          isVisible ? '' : 'tw-hidden'
        }`}
      >
        <div className="tw-flex tw-flex-row tw-justify-between">
          <Text font="h3" color="white" className="tw-mr-1">
            {imageTextValues.areaName}
          </Text>
          {scoreChip && (
            <ScoreChip
              score={scoreChip.score}
              size="sm"
              type={scoreChip.type}
            />
          )}
        </div>
        <div className="tw-flex tw-flex-col tw-pt-2">
          <Text font="body-sm" color="white-offset" className="tw-py-1">
            Area Type: {truncate(imageTextValues.areaType, 20)}
          </Text>
          {/* Fri 10/22: Temporarily not using Location data - bringing back next week */}
          {/* <Text font="body-sm" color="white-offset" className="tw-py-1">
                        {imageTextValues.locationType}: {imageTextValues.locationValue}
                    </Text> */}
        </div>
      </Box>
    </div>
  );
};
