import { Media, MediaPayload } from '@api-interfaces';
import { virtualBrownClient } from '@core/services';

const BASE_URL = `media`;

interface EmployeeData {
	employeeId: number;
	organizationId: number;
}

class MediaService {
	public upload(
		file: File,
		employeeData: EmployeeData,
		type?: 'IMAGE' | 'LOGO'
	) {
		const { employeeId, organizationId } = employeeData;

		const formData = new FormData();
		formData.append('organization', organizationId.toString());
		formData.append('employee', employeeId.toString());
		formData.append('file', file);
		if (type) {
			formData.append('type', type);
		}

		return virtualBrownClient.post<Media>(`media/upload/`, formData);
	}

	getMedia = (mediaId: number) =>
		virtualBrownClient.get<Media>(`${BASE_URL}/${mediaId}/`);

	deleteMedia = (mediaId: number) =>
		virtualBrownClient.delete<any>(`${BASE_URL}/${mediaId}/`);

	// if you're uploading files, use media upload, not this one
	createMedia = (payload: MediaPayload) =>
		virtualBrownClient.post<Media>(`${BASE_URL}/`, payload);
}

export const mediaService = new MediaService();
