import React, { forwardRef } from 'react';
import './skeleton.css';

interface LoaderSkeletonProps {
    width?: number | string;
    height?: number | string;
    className?: string;
    style?: React.CSSProperties;
}

export const LoaderSkeleton = forwardRef<HTMLDivElement, LoaderSkeletonProps>(
    ({ className, width, height, style, ...props }, ref) => {
        return (
            <div
                ref={ref}
                data-ds2="loader-skeleton"
                className={`loader-skeleton ${className ?? ''}`}
                style={{
                    width: width ?? '100%',
                    height: height ?? '1rem',
                    ...style,
                }}
                {...props}
            />
        );
    }
);

LoaderSkeleton.displayName = 'LoaderSkeleton';
