// base
import { getPeriodicTaskRepeatText } from '@app/collections/routes/_helpers/time-table-processor/helpers';
import {
  TimeTableRow,
  TIME_TABLE_ROW_TYPE,
} from '@app/collections/routes/_helpers/time-table-processor/interfaces';

// helpers
import { Icon, Tag, TableSimple, Text } from '@atoms';
import { pluralize, toStandardTime, titleCase } from '@helpers';

// services

// components

import './area-details.css';

interface Props {
  timetableRow: TimeTableRow;
  preselect?: number; // frequency id
  onClickBack: () => void;
}

export default function AreaDetails({
  timetableRow,
  onClickBack,
  preselect,
}: Props) {
  return (
    <div id="routes_routes-detail_area-details">
      <div className="tw-flex tw-justify-between tw-items-center tw-mb-8">
        <a className="back-link t-text-link" onClick={onClickBack}>
          <Icon icon="chevron-left" className="tw-mr-2" />
          Back to full route details
        </a>

        {timetableRow.type === TIME_TABLE_ROW_TYPE.REGULAR ? (
          <div className="tw-flex tw-flex-col tw-text-right">
            <span className="t-text-weight-medium tw-mb-1">
              Schedule: {toStandardTime(timetableRow.time.start)} -{' '}
              {toStandardTime(timetableRow.time.end)}
            </span>
            <span className="t-text-weight-medium">
              Total Estimated Time: {timetableRow.time.total} minutes
            </span>
          </div>
        ) : timetableRow.type === TIME_TABLE_ROW_TYPE.PERIODIC ? (
          <div className="tw-flex tw-flex-col tw-text-right">
            <span className="t-text-weight-medium tw-mb-1">
              Period:{' '}
              {timetableRow.period === 'AS_NEEDED'
                ? 'As Needed'
                : titleCase(timetableRow.period)}
            </span>
            <span className="t-text-weight-medium">
              Total Estimated Time: {timetableRow.time.total} minutes
            </span>
          </div>
        ) : null}
      </div>

      <div
        className="tw-flex tw-justify-between tw-items-center tw-mb-8 tw-py-2 tw-border-b tw-border-solid"
        style={{
          borderColor: 'var(--well-background-color)',
        }}
      >
        <h3>{timetableRow.area.name}</h3>

        <div className="tw-flex tw-items-center tw-justify-end tw-space-x-4">
          {timetableRow.references.stop.stop_frequencies.some(
            (stopFreq) => stopFreq.verifiable_recurrence > 0
          ) && (
            <Tag type="warning">
              <Icon icon="qrcode" size="lg" />
              <span>Verifiable</span>
            </Tag>
          )}
          <h3>
            {timetableRow.tasks.count}{' '}
            {pluralize('Task', 'Tasks', timetableRow.tasks.count)}
          </h3>
        </div>
      </div>

      <TableSimple
        headers={[
          { label: 'Task' },
          {
            label:
              timetableRow.type === TIME_TABLE_ROW_TYPE.PERIODIC
                ? 'Every'
                : '' /* 'Task Time' */,
          },
        ]}
        rows={timetableRow.tasks.data
          .filter((task) => task.recurrence_type !== 'NA')
          .map((task, i) => ({
            headerLabel: {
              Task: (
                <Text font="body-lg" color="hi-contrast">
                  {task.name}
                </Text>
              ),
              [timetableRow.type === TIME_TABLE_ROW_TYPE.PERIODIC
                ? 'Every'
                : '']: (
                <Text
                  font="body-lg"
                  color="hi-contrast"
                  className="tw-text-right"
                >
                  {
                    timetableRow.type === TIME_TABLE_ROW_TYPE.PERIODIC
                      ? getPeriodicTaskRepeatText(task.repeat)
                      : null
                    // task.duration
                  }
                </Text>
              ),
            },
          }))}
        className="tw-mt-4"
      />
    </div>
  );
}
