import { Link } from '@reach/router';
import { useCallback, useEffect, useState } from 'react';

// context

// helpers

// interfaces
import {
  ServiceLogItem,
  TrackerResponse,
  TrackerResponseStopArea,
} from '@api-interfaces';

// services
import { routesService } from '@apis';
import { v2AreasService } from '@apis/areas/v2';

// components
import {
  Avatar,
  Badge,
  Icon,
  LoaderBlocks,
  ModalStack,
  ScoreGauge,
  Text,
} from '@atoms';
import { pluralize, TimeUtils, titleCase, truncate } from '@helpers';
import { useAppContext } from '@hooks';
import { RouteModal } from '@modals/detail-modals';
import { getServiceTimeDuration, ServiceLog } from '@new';
import { ShadowScroller } from '@new/ShadowScroller';
import { toasterService } from '@services';

const LOG_LIMIT = 10;

export interface ServiceValidationStopProps {
  stopId: number;
  date: string | Date;
  customer?: number;
  contract?: number;
  className?: string;
  isFullHeight?: boolean;
  setArea?: (area: TrackerResponseStopArea) => void;
}

const isComplete = (task: TrackerResponse['stops'][0]['tasks'][0]) =>
  task.status === 'COMPLETE' || task.status === 'VERIFIED';

export const ServiceValidationStop = ({
  stopId,
  date,
  customer,
  contract,
  className,
  isFullHeight,
  setArea,
}: ServiceValidationStopProps) => {
  const {
    state: { selectedCustomers, selectedContracts, dateRange },
  } = useAppContext();
  const [stop, setStop] = useState<TrackerResponse>();
  const [isLoading, setIsLoading] = useState(true);
  const [logsIsLoading, setLogsIsLoading] = useState(false);
  const [logsIsDisabled, setLogsIsDisabled] = useState(false);
  const [logs, setLogs] = useState<ServiceLogItem[]>([]);
  const [logsOffset, setLogsOffset] = useState(0);
  const [tab, setTab] = useState(0);
  const [taskTab, setTaskTab] = useState(0);
  const [isRouteOpened, setIsRouteOpened] = useState(false);

  const areaId = stop?.stops?.[0]?.area?.id;

  const start =
    stop?.stops?.[0]?.durations === null
      ? stop?.stops?.[0]?.create_date
      : stop?.stops?.[0]?.durations?.[0]?.start_time;
  const end =
    stop?.stops?.[0]?.durations === null
      ? new Date()
      : stop?.stops?.[0]?.durations?.[0]?.end_time;
  // TODO - restore this when we decide to hide Scan tasks again
  // const dailyTasks = stop?.stops?.[0]?.tasks?.filter(task => task.period === 'DAILY' && task.action_name !== 'Scan');
  const dailyTasks = stop?.stops?.[0]?.tasks?.filter(
    (task) => task.period === 'DAILY'
  );
  const periodicsTasks = stop?.stops?.[0]?.tasks?.filter(
    (task) => task.period !== 'DAILY'
  );

  const person = stop?.employee?.person;
  const position = stop?.employee?.positions?.[0]?.name;
  const hiredDate = stop?.employee?.hired_date;

  const routeId = stop?.route_def_id;

  useEffect(() => {
    setIsLoading(true);

    const subscription = routesService.tracker
      .getTrackedRoutes({
        from: TimeUtils.startOfDay(TimeUtils.subDays(date, 1)),
        to: TimeUtils.endOfDay(date),
        route_stop_id: stopId,
        ...(customer && { customer }),
        ...(contract && { contract }),
      })
      .subscribe(
        (trackerStop) => {
          setArea?.(trackerStop.results[0]?.stops?.[0]?.area);
          setStop(trackerStop.results[0]);
          setIsLoading(false);
        },
        () => {
          toasterService.newToast({
            status: 'fail',
            message: 'Failed to load stop data.',
          });
          setIsLoading(false);
        }
      );
    return () => subscription.unsubscribe();
  }, [stopId, date]);

  useEffect(() => {
    if (!areaId || logsIsDisabled) {
      return;
    }
    setLogsIsLoading(true);

    const subscription = v2AreasService
      .getServiceLog({
        all_levels: true,
        area_id: areaId,
        offset: logsOffset,
        limit: LOG_LIMIT,
        from: dateRange.from,
        to: dateRange.to,
        ordering: '-create_date',
        ...(customer && { customer }),
        ...(contract && { contract }),
      })
      .subscribe(
        (serviceLog) => {
          setLogs((log) => [...log, ...serviceLog.results]);
          setLogsIsLoading(false);
          if (!serviceLog.next) {
            setLogsIsDisabled(true);
          }
        },
        () => {
          toasterService.newToast({
            status: 'fail',
            message: 'Failed to load service logs.',
          });
          setLogsIsLoading(false);
        }
      );
    return () => subscription.unsubscribe();
  }, [areaId, logsOffset]);

  const handleOpenRoute = () => {
    if (routeId) {
      setIsRouteOpened(true);
    } else {
      toasterService.newToast({
        message: 'No route found for this employee.',
        status: 'warning',
      });
    }
  };

  const handleCloseRouteModal = () => {
    setIsRouteOpened(false);
  };

  const handleLoadLogs = useCallback(() => {
    setLogsOffset((cur) => cur + LOG_LIMIT);
  }, []);

  return !isLoading ? (
    <div className={`tw-space-y-6 ${className}`}>
      <div className="tw-flex-shrink-0 tw-overflow-x-auto tw-overflow-y-hidden tw-flex tw-space-x-8 tw-whitespace-nowrap">
        {['This Service', 'Service Log'].map((label, index) => (
          <Link
            key={index}
            className={`tw-inline-block tw-cursor-pointer  ${
              tab === index
                ? 'tw-border-b-[3px] tw-border-solid tw-border-theme-primary-500-300'
                : ''
            }`}
            onClick={(e) => {
              e.preventDefault();
              setTab(index);
            }}
            to="#"
          >
            <Text
              font="body-md"
              color={tab === index ? 'hi-contrast' : 'neutral-offset'}
              className="tw-w-full"
            >
              {label}
            </Text>
          </Link>
        ))}
      </div>
      {tab === 0 && (
        <>
          <div>
            <Text font="h4" color="neutral-offset" className="tw-mb-2.5">
              Serviced By
            </Text>
            <div
              onClick={() => handleOpenRoute()}
              className="tw-flex tw-gap-4 tw-items-center tw-cursor-pointer"
            >
              <Avatar person={person} size="lg" />
              <div className="tw-h-full tw-flex tw-flex-col tw-justify-center">
                <Text
                  font="h3"
                  color="hi-contrast"
                  style={{ lineHeight: 1.25 }}
                >
                  {person?.first_name} {person?.last_name}
                </Text>
                {(position || hiredDate) && (
                  <Text font="h4" color="neutral-offset">
                    {position}
                    {position && hiredDate && ' | '}
                    {hiredDate &&
                      `Hired: ${TimeUtils.format(hiredDate, 'MM/DD/YYYY')}`}
                  </Text>
                )}
                <Text font="body-md" color="neutral-offset">
                  {
                    (customer
                      ? selectedCustomers.find((c) => c.id === customer)
                      : selectedCustomers?.[0]
                    )?.name
                  }{' '}
                  —{' '}
                  {
                    (contract
                      ? selectedContracts.find((c) => c.id === contract)
                      : selectedContracts?.[0]
                    )?.name
                  }
                </Text>
              </div>
            </div>
          </div>
          <div>
            <Text color="neutral-offset" font="h4">
              Serviced On
            </Text>
            <Text color="hi-contrast" font="h4">
              {TimeUtils.format(date, 'dddd, MMMM D, YYYY')}
            </Text>
          </div>
          <div className="tw-flex tw-justify-between tw-p-4 tw-bg-theme-neutral-200-700 tw-rounded">
            <div>
              <Text font="h4" color="hi-contrast">
                Start Time
              </Text>
              <Text font="h2" color="primary">
                {start
                  ? `${new Date(start).toLocaleTimeString([], {
                      hour: '2-digit',
                      minute: '2-digit',
                    })}`
                  : 'N/A'}
              </Text>
            </div>
            <div className="tw-text-center">
              <Text font="h4" color="hi-contrast">
                Stop Time
              </Text>
              <Text font="h2" color="primary">
                {end
                  ? `${new Date(end).toLocaleTimeString([], {
                      hour: '2-digit',
                      minute: '2-digit',
                    })}`
                  : 'N/A'}
              </Text>
            </div>
            <div className="tw-text-right">
              <Text font="h4" color="hi-contrast">
                Total Service Time
              </Text>
              <Text font="h2" color="primary">
                {start && end ? `${getServiceTimeDuration(start, end)}` : 'N/A'}
              </Text>
            </div>
          </div>

          <div className="tw-flex-shrink-0 tw-overflow-x-auto tw-overflow-y-hidden tw-flex tw-space-x-8 tw-whitespace-nowrap">
            {['Daily Tasks', 'Periodics Tasks'].map((label, index) => (
              <Link
                key={index}
                className={`tw-inline-block tw-cursor-pointer  ${
                  taskTab === index
                    ? 'tw-border-b-[3px] tw-border-solid tw-border-theme-primary-500-300'
                    : ''
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  setTaskTab(index);
                }}
                to="#"
              >
                <Text
                  font="body-md"
                  color={taskTab === index ? 'hi-contrast' : 'neutral-offset'}
                  className="tw-w-full"
                >
                  {label}
                </Text>
              </Link>
            ))}
          </div>

          {taskTab === 0 && (
            <div className="tw-space-y-1">
              <Text font="h3" color="hi-contrast">
                {dailyTasks?.filter(isComplete)?.length}/{dailyTasks?.length}{' '}
                {pluralize('Task', 'Tasks', dailyTasks?.length)} Completed
              </Text>
              <ScoreGauge
                fullWidth
                hidePercent
                percent={
                  (dailyTasks?.filter(isComplete)?.length /
                    (dailyTasks?.length || 1)) *
                  100
                }
              />
            </div>
          )}

          <ShadowScroller
            axis="y"
            maxHeight={isFullHeight ? undefined : 260}
            height={
              isFullHeight ? 'max(calc(100vh - 570px), 130px)' : undefined
            }
            className="tw--mr-2"
          >
            <div className="tw-divide-y tw-divide-theme-neutral-900-100 tw-divide-solid tw-divide-opacity-15 tw-pr-2">
              {(taskTab === 0 ? dailyTasks : periodicsTasks)?.map((task) => {
                const completed = isComplete(task);
                return (
                  <div
                    key={task.id}
                    className="tw-flex tw-justify-between tw-items-center tw-py-3"
                  >
                    <div className="tw-flex tw-gap-2.5">
                      <Icon
                        className={
                          completed
                            ? 'tw-text-theme-success-900-500'
                            : 'tw-text-danger-900 dark:tw-text-danger-500'
                        }
                        size="lg"
                        icon={completed ? 'check-circle' : 'times-circle'}
                      />
                      <div>
                        <Text font="body-md" color="hi-contrast">
                          {truncate(
                            `${task.action_name} ${task.element_name}`,
                            50
                          )}
                        </Text>
                        <Text font="body-sm" color="neutral-offset">
                          {task.recurrence}x {titleCase(task.period)}
                        </Text>
                      </div>
                    </div>
                    <Badge
                      label={completed ? 'Completed' : 'Not Completed'}
                      color={completed ? 'success' : 'neutral'}
                    />
                  </div>
                );
              })}
            </div>
            {(taskTab === 0 ? dailyTasks : periodicsTasks)?.length === 0 && (
              <Text color="hi-contrast" font="body-md">
                No {taskTab === 0 ? 'Daily' : 'Periodics'} Tasks scheduled for
                this area.
              </Text>
            )}
          </ShadowScroller>
        </>
      )}
      {tab === 1 && (
        <ShadowScroller
          axis="y"
          maxHeight={isFullHeight ? undefined : 640}
          height={isFullHeight ? 'calc(100vh - 164px)' : undefined}
          className="tw--mr-2"
        >
          <ServiceLog
            isLoading={logsIsLoading}
            isDisabled={logsIsDisabled}
            logs={logs}
            onLoadClick={handleLoadLogs}
            altTimeBackground
            showMedia
            className="tw-pr-2"
          />
        </ShadowScroller>
      )}
      {isRouteOpened && (
        <ModalStack position="center" onOverlayClick={handleCloseRouteModal}>
          <RouteModal
            data={{ id: routeId, highlightAreaId: areaId }}
            onClose={handleCloseRouteModal}
          />
        </ModalStack>
      )}
    </div>
  ) : (
    <LoaderBlocks className="tw-pb-8" />
  );
};
