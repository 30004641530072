import { Helmet } from 'react-helmet';

interface Props {
  title: string;
  description?: string;
}

const PREFIX = '4INSITE';

export const SEO = ({ title, description }: Props) => {
  return (
    <Helmet>
      <meta name="description" content={description || ''} />
      <title>
        {PREFIX} | {title}
      </title>
    </Helmet>
  );
};
