import { useEffect, useState } from 'react';

// helpers
import { Storage } from '@helpers';

export const useThemeColor = (lightColor: string, darkColor: string) => {
	const theme = Storage.get('theme');
	const [color, setColor] = useState(
		theme === 'light' ? lightColor : darkColor
	);

	useEffect(() => {
		const body = document.querySelector('body');

		const mutationObserver = new MutationObserver((mutations) => {
			mutations.forEach((mutation) => {
				if (mutation.attributeName === 'class') {
					const target = mutation.target as HTMLDivElement;
					if (target.classList.contains('tw-dark')) {
						setColor(darkColor);
					} else if (target.classList.contains('light-theme')) {
						setColor(lightColor);
					}
				}
			});
		});

		mutationObserver.observe(body, { attributes: true });

		return () => {
			mutationObserver.disconnect();
		};
	}, []);

	return color;
};
