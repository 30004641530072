import { Text } from '@atoms';
import { getIsPassColor, prettyNumber } from '@helpers';

// components

// interfaces
import { ScoreGaugeProps } from './score-gauge.interfaces';

export const ScoreGauge = ({
  percent,
  isPassing,
  fullWidth,
  hidePercent,
  isNotApplicable,
  className,
  ...props
}: ScoreGaugeProps) => {
  return (
    <div
      data-ds2="score-gauge"
      className={`tw-inline-flex tw-items-center
        ${fullWidth ? 'tw-w-full' : ''}
        ${className}`}
      {...props}
    >
      <div
        className={`tw-rounded-full tw-h-4 tw-bg-theme-neutral-400-600 tw-overflow-hidden ${
          fullWidth ? 'tw-w-full' : 'tw-w-20'
        }`}
      >
        <div
          className={`tw-rounded-full tw-h-full ${
            isPassing != undefined
              ? getIsPassColor({
                  isPassing,
                  type: 'bg',
                })
              : 'tw-bg-primary-500'
          }`}
          style={{ width: `${percent}%` }}
        />
      </div>
      {!hidePercent && !isNotApplicable && (
        <Text
          font="body-sm"
          tag="span"
          color="hi-contrast"
          className="tw-text-right tw-w-8"
        >
          {prettyNumber(percent, { isPercent: true })}
        </Text>
      )}
      {isNotApplicable && (
        <Text
          font="body-sm"
          tag="span"
          color="hi-contrast"
          className="tw-text-right tw-w-8"
        >
          N/A
        </Text>
      )}
    </div>
  );
};

ScoreGauge.defaultProps = {
  className: '',
};
