import { useMemo } from 'react';

// helpers
import { Text } from '@atoms';
import { dataVizColors } from '@helpers';

// interfaces
import { DistributionBarProps } from './distribution-bar.interfaces';

// components

export const DistributionBar = ({
  inputs,
  inputType,
  className,
  sortDirection,
  showLabels,
  size,
}: DistributionBarProps) => {
  const barHeight = size === 'sm' ? 'tw-h-4' : 'tw-h-8';

  const distribution = useMemo(() => {
    let percentages = inputs;
    if (inputType === 'value') {
      const sum = inputs.reduce((sum, { value }) => sum + value, 0);
      percentages = inputs.map(({ value, ...rest }) => ({
        ...rest,
        value: value / sum,
      }));
    }
    return percentages
      .sort((a, b) =>
        sortDirection === 'asc'
          ? a.value - b.value
          : sortDirection === 'desc'
          ? b.value - a.value
          : 0
      )
      .map(({ value, label, bgColor }, index) => (
        <div
          key={index}
          className="tw-h-full tw-flex tw-items-center tw-px-4"
          aria-hidden="true"
          title={`${label ? `${label} - ` : ''}${(value * 100).toFixed(2)}%`}
          style={{
            width: `${value * 100}%`,
            backgroundColor:
              bgColor ?? dataVizColors[index % dataVizColors.length],
          }}
        >
          {showLabels && (
            <Text
              font="h4"
              color="hi-contrast-inverted"
              className="tw-text-ellipsis tw-overflow-hidden tw-whitespace-nowrap"
            >
              {`${(value * 100).toFixed(0)}%${label ? ` ${label}` : ''}`}
            </Text>
          )}
        </div>
      ));
  }, [inputs, inputType]);

  return (
    <div
      className={`tw-flex tw-rounded-lg tw-overflow-hidden ${barHeight} ${className}`}
    >
      {distribution}
    </div>
  );
};

DistributionBar.defaultProps = {
  className: '',
  showLabels: false,
  size: 'md',
};
