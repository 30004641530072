// base
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import cloneDeep from 'lodash/cloneDeep';
import { Component } from 'react';

// services
import { Icon } from '@atoms';
import { toasterService } from '@services/toaster';

// helpers

// components

// interfaces

import './Toaster.scss';

type Status = 'success' | 'warning' | 'fail'; // failed is default

export interface CustomToast {
  status?: Status;
  message?: string;
  persist?: boolean;
  key?: number;
}

export interface CustomError extends Error {
  key?: number;
}

export type Toast = CustomToast | CustomError;

interface Props {}

interface State {
  toasts: Toast[];
}

interface Vars {
  keyCounter: number;
  receiver: typeof toasterService.subject;
}

class Toaster extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      toasts: [],
    };
  }

  vars: Vars = {
    keyCounter: 0,
    receiver: toasterService.subject,
  };

  private newToast(toast: Toast = {}) {
    const key = this.vars.keyCounter++;

    this.setState(
      (state) => {
        const newState = cloneDeep(state);
        toast.key = key;
        newState.toasts.push(toast);
        return newState;
      },
      () => {
        const hasNotPersist = !('persist' in toast);
        if (hasNotPersist) {
          setTimeout(() => {
            this.removeToast(key);
          }, 4500); // adjust in css too
        }
      }
    );
  }

  private removeToast(key: number) {
    this.setState((state) => {
      const index = state.toasts.findIndex((toast) => toast.key === key);
      if (index !== -1) state.toasts.splice(index, 1);
      return state;
    });
  }

  public componentDidMount() {
    this.vars.receiver.subscribe((toast) => {
      this.newToast(toast);
    });
  }

  getStatusClass = (toast: Toast) => {
    let className = 'fail';

    if ('status' in toast) {
      switch (toast.status) {
        case 'success':
          className = 'success';
          break;
        case 'warning':
          className = 'warning';
          break;
      }
    }

    return className;
  };

  getStatusIcon = (toast: Toast): IconProp => {
    let icon: IconProp = 'exclamation-circle';

    if ('status' in toast) {
      switch (status) {
        case 'success':
          icon = 'check-circle';
          break;
        case 'warning':
          icon = 'exclamation-triangle';
          break;
      }
    }

    return icon;
  };

  public render() {
    function getStatusMessage(toast: Toast) {
      if (toast.message) {
        return toast.message;
      }
      let message;

      switch (status) {
        case 'success':
          message = 'Success!';
          break;
        case 'warning':
          message = 'Warning!';
          break;
        case 'fail':
          message = 'Failed!';
          break;
        default:
          message = 'Failed!';
      }

      return message;
    }

    return (
      <div className="cm-toaster">
        {this.state.toasts.map((toast) => (
          <div
            key={toast.key}
            className={
              `toaster-toast tw-shadow-md ${this.getStatusClass(toast)}` +
              `${!('persist' in toast) ? ' vfx-fade' : ''}`
            }
            onClick={() => this.removeToast(toast.key)}
          >
            <Icon
              icon={this.getStatusIcon(toast)}
              size="2x"
              className="tw-mr-4"
            />
            <span className="toast-message">{getStatusMessage(toast)}</span>
          </div>
        ))}
      </div>
    );
  }
}

export default Toaster;
