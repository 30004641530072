import {
	SummaryChart,
	TimeScale,
	Location,
	GeoLocation,
	IsPass,
} from '@models';

export interface ComplimentsSummaryParams<T> {
	chart: T[];
	from?: Date;
	to?: Date;
	time_zone?: string;
	chart_type?: number;
	service?: number;
	customer?: number | string;
	contract?: number | string;
	tag?: number | string;
	tag_group?: number | string;
	provider?: number;
	by_verticals?: string;
}

export namespace ComplimentsSummary {
	export enum Chart {
		GetComplimentCounts = 0,
		GetComplimentMonthlyTrend = 1,
		GetComplimentTrendsBySite = 2,
		GetCountsByType = 3,
		GetTrendsByType = 4,
		GetComplimentCountsByTag = 5,
		GetComplimentCountsByBuilding = 6,
		GetComplimentCountsByService = 7,
		GetComplimentCountsByOrganization = 8,
		GetComplimentsFrontPageSummary = 9,
		GetComplimentCountsByMonthByProgram = 10,
		GetComplimentCountsByTypeByBuilding = 11,
		GetComplimentCountsByVendor = 12,
		GetComplimentCountsByAreaGroup = 13,
		GetComplimentCountsByVertical = 14,
		GetComplimentCountsByVerticalOrganizationList = 15,
	}

	export interface ComplimentCounts {
		AveragePerMonth: number;
		TotalCompliments: number;
		latest_compliment_date: string;
	}

	export interface ComplimentCountsSummary
		extends SummaryChart<ComplimentCounts> {
		chart: 'GetComplimentCounts';
		number: '0';
	}

	export interface ComplimentMonthlyTrend {
		compliments: null | number;
		mo: string;
	}

	export interface ComplimentMonthlyTrendSummary
		extends SummaryChart<ComplimentMonthlyTrend[]> {
		chart: 'GetComplimentMonthlyTrend';
		number: '1';
		time_scale: TimeScale;
	}

	export interface ComplimentTrendsBySite
		extends Location,
			GeoLocation,
			IsPass {
		has_tag_groups: boolean;
		id: number;
		last_comment: string;
		latest_compliment_date: string;
		name: string;
		organization_name: string;
		received_count: number;
	}

	export interface ComplimentTrendsBySiteSummary
		extends SummaryChart<ComplimentTrendsBySite[]> {
		chart: 'GetComplimentTrendsBySite';
		max_received_count: number;
	}

	export interface CountsByType {
		count: number;
		name: string;
		percentage: string;
		type_id: number;
	}

	export interface CountsByTypeSummary extends SummaryChart<CountsByType[]> {
		chart: 'GetCountsByType';

		number: '3';
	}

	export interface TrendsByType {
		mo: string;
		total: number;
	}

	export interface TrendsByTypeSummary extends SummaryChart<TrendsByType[]> {
		chart: 'GetTrendsByType';
		number: '4';
		time_scale: TimeScale;
	}

	export interface ComplimentCountsByTag extends GeoLocation, IsPass {
		contract_id: number;
		id: number;
		last_comment: string;
		last_compliment_date: string;
		name: string;
		received_count: number;
	}

	export interface ComplimentCountsByTagSummary
		extends SummaryChart<ComplimentCountsByTag[]> {
		chart: 'GetComplimentCountsByTag';
		max_received_count: number;
	}

	export interface ComplimentCountsByBuilding extends GeoLocation {
		contract_id: number;
		id: number;
		last_comment: string;
		last_compliment_date: string;
		name: string;
		received_count: number;
	}

	export interface ComplimentCountsByBuildingSummary
		extends SummaryChart<ComplimentCountsByBuilding[]> {
		chart: 'GetComplimentCountsByBuilding';
		max_received_count: number;
	}

	export interface ComplimentCountsByService {
		count: number;
		id: number;
		name: string;
	}

	export interface ComplimentCountsByServiceSummary
		extends SummaryChart<ComplimentCountsByService[]> {
		chart: 'GetComplimentCountsByService';
		number: '7';
	}

	export interface ComplimentCountsByOrganization {
		compliment_count: number;
		customer_id: number;
		customer_name: string;
	}

	export interface ComplimentCountsByOrganizationSummary
		extends SummaryChart<ComplimentCountsByOrganization[]> {
		chart: 'GetComplimentCountsByOrganization';
		number: '8';
	}

	export interface ComplimentsFrontPage {
		days_since: number;
		latest_date: string;
		latest_id: number;
		total_in_date_range: number;
	}

	export interface ComplimentsFrontPageSummary
		extends SummaryChart<ComplimentsFrontPage> {
		chart: 'GetComplimentsFrontPageSummary';
		number: '9';
	}

	export interface ComplimentCountsByMonthByProgram {
		id: number;
		month: string;
		name: string;
		total: number;
	}

	export interface ComplimentCountsByMonthByProgramSummary
		extends SummaryChart<ComplimentCountsByMonthByProgram[]> {
		chart: 'GetComplimentCountsByMonthByProgram';
		number: '10';
	}

	export interface ComplimentCountsByTypeByBuilding {
		building_name: string;
		service_count_by_building: {
			service_count: number;
			service_name: string;
		}[];
	}

	export interface ComplimentCountsByTypeByBuildingSummary
		extends SummaryChart<ComplimentCountsByTypeByBuilding[]> {
		chart: 'GetComplimentCountsByTypeByBuilding';
		number: '11';
	}

	export interface ComplimentCountsByVendor {
		id: number;
		name: string;
		count: number;
	}

	export interface ComplimentCountsByVendorSummary
		extends SummaryChart<ComplimentCountsByVendor[]> {
		chart: 'GetComplimentCountsByVendor';
		number: '12';
	}

	export interface ComplimentCountsByAreaGroup {
		contract_area_group_id: number;
		contract_area_group_name: string;
		number_of_records?: number;
		service_types: {
			service_type_id?: number;
			service_type_code?: string;
			number_of_records?: number;
		}[];
	}

	export interface ComplimentCountsByAreaGroupSummary
		extends SummaryChart<ComplimentCountsByAreaGroup[]> {
		chart: 'GetComplimentCountsByAreaGroup';
		number: '13';
	}

	export interface ComplimentCountsByVertical {
		count: number;
		id: number;
		name: string;
	}

	export interface ComplimentCountsByVerticaSummary
		extends SummaryChart<ComplimentCountsByVertical[]> {
		chart: 'GetComplimentCountsByService';
		number: '14';
	}

	export interface ComplimentCountsByVerticalOrganizationList {
		count: number;
		id: number;
		name: string;
	}

	export interface ComplimentCountsByVerticalOrganizationListSummary
		extends SummaryChart<ComplimentCountsByVerticalOrganizationList[]> {
		chart: 'GetComplimentCountsByService';
		number: '15';
	}

	export interface SummaryReturnType {
		[Chart.GetComplimentCounts]: ComplimentCountsSummary;
		[Chart.GetComplimentMonthlyTrend]: ComplimentMonthlyTrendSummary;
		[Chart.GetComplimentTrendsBySite]: ComplimentTrendsBySiteSummary;
		[Chart.GetCountsByType]: CountsByTypeSummary;
		[Chart.GetTrendsByType]: TrendsByTypeSummary;
		[Chart.GetComplimentCountsByTag]: ComplimentCountsByTagSummary;
		[Chart.GetComplimentCountsByBuilding]: ComplimentCountsByBuildingSummary;
		[Chart.GetComplimentCountsByService]: ComplimentCountsByServiceSummary;
		[Chart.GetComplimentCountsByOrganization]: ComplimentCountsByOrganizationSummary;
		[Chart.GetComplimentsFrontPageSummary]: ComplimentsFrontPageSummary;
		[Chart.GetComplimentCountsByMonthByProgram]: ComplimentCountsByMonthByProgramSummary;
		[Chart.GetComplimentCountsByTypeByBuilding]: ComplimentCountsByTypeByBuildingSummary;
		[Chart.GetComplimentCountsByVendor]: ComplimentCountsByVendorSummary;
		[Chart.GetComplimentCountsByAreaGroup]: ComplimentCountsByAreaGroupSummary;
		[Chart.GetComplimentCountsByVertical]: ComplimentCountsByVerticaSummary;
		[Chart.GetComplimentCountsByVerticalOrganizationList]: ComplimentCountsByVerticalOrganizationListSummary;
	}
}
