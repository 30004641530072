import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Component, CSSProperties } from 'react';

import { Icon } from '@atoms';

import './Tabs.scss';

// design 5 are used in the notification center header
export type TabDesign = 1 | 2 | 3 | 4 | 5; // different design types

export interface Tab {
  title?: string;
  icon?: IconProp;
  content: JSX.Element;
  onClick?: Function;
}

interface Props {
  data: {
    tabs: Tab[];
    defaultIndex?: number;
  };
  methods?: {
    tabSwitched?: (index: number) => void;
  };
  settings?: {
    design?: TabDesign; // default is design 3
    dynamicLoad?: boolean; // won't load all tab content at once (default false)
    display?: boolean;
  };
  className?: string;
  style?: CSSProperties;
  backgroundClasses?: {
    tabs?: string;
    content?: string;
  };
}

interface State {
  activeIndex: number;
}

class Tabs extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      activeIndex:
        this.props.data.defaultIndex &&
        this.props.data.defaultIndex < this.props.data.tabs.length
          ? this.props.data.defaultIndex
          : 0,
    };
  }

  public render() {
    const { className, style, settings = {} } = this.props;
    const { tabs } = this.props.data;
    const { dynamicLoad } = settings;
    const { activeIndex } = this.state;

    return (
      <div
        className={
          `cm-tabs` +
          ` design-${
            this.props.settings && this.props.settings.design
              ? this.props.settings.design
              : 3
          }${className ? ` ${className}` : ''}`
        }
        style={style || {}}
      >
        <div
          className={`tab-buttons${
            this.props.backgroundClasses && this.props.backgroundClasses.tabs
              ? ` ${this.props.backgroundClasses.tabs}`
              : ''
          }`}
        >
          {tabs.map((tab, i) => {
            return (settings.display &&
              tab.title !== 'Map' &&
              tab.title !== 'Org Chart') ||
              !settings.display ? (
              <a
                className={`tab-button tw-text-neutral-500-600${
                  location.pathname.includes(tab.title.toLowerCase())
                    ? ' toggle tw-cursor-default tw-shadow-md tw-bg-neutral-300 dark:tw-bg-neutral-850'
                    : ' tw-cursor-pointer'
                }`}
                key={`tab-button${i}`}
                onClick={() => {
                  this.setState({ activeIndex: i });
                  this.props.methods && this.props.methods.tabSwitched
                    ? this.props.methods.tabSwitched(i)
                    : null;
                  if (tab.onClick) tab.onClick();
                }}
              >
                {tab.icon && (
                  <Icon icon={tab.icon} size="lg" className="tw-mr-2.5" />
                )}
                <span className="tab-button-title">{tab.title}</span>
              </a>
            ) : (
              ''
            );
          })}
        </div>
        <div
          className={`tabs-contents${
            this.props.backgroundClasses && this.props.backgroundClasses.content
              ? ` ${this.props.backgroundClasses.content}`
              : ''
          }`}
        >
          {tabs.map((tab, i) => {
            return (
              <div
                className="tabs-content"
                key={`tab-contents${i}`}
                style={{
                  display: i === activeIndex ? 'block' : 'none',
                }}
              >
                {!dynamicLoad
                  ? tab.content
                  : i === activeIndex
                  ? tab.content
                  : null}
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default Tabs;
