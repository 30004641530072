// services

// helpers
import { debounce } from 'lodash';
import { useState, useRef, useEffect } from 'react';
import * as React from 'react';
import { Subscription } from 'rxjs';

// components
import { ModalStack } from '@atoms';
import { FieldMultiSelect } from '@atoms/form-ds2/field-multi-select';
import { FieldRadioGroup } from '@atoms/form-ds2/field-radio-group';
import { FieldInputDate } from '@atoms/form-ds2/field-input-date';
import { FieldDropdown } from '@atoms/form-ds2/field-dropdown';
import { FieldInputText } from '@atoms/form-ds2/field-input-text';

// interfaces
import { Employee, ReferenceItem, ServiceArea, Shift } from '@api-interfaces';
import { complaintsService, serviceAreasService, shiftsService } from '@apis';
import { DropdownItem } from '@atoms/form-ds2/dropdown/dropdown.interfaces';
import { UserSelect } from '@components/_new/_form/dropdowns/user-select';
import { getFullName, sendError, TimeUtils } from '@helpers';
import { useAppContext, useLocationsRef } from '@hooks';
import { Button } from '@new';
import { toasterService } from '@services';
import { Modal } from '@templates';

import { useWorkOrderModalContext } from '../../../work-orders/_context';
import { useServiceIssueFormContext } from '../../_context';
import {
  isValidServiceIssue,
  createServiceIssuePayload,
  isValidClosedServiceIssue,
} from '../../_helpers';

type LocationDropdownItem = DropdownItem<ServiceArea>;
type ReferenceDropdownItem = DropdownItem<ReferenceItem>;

const repeatOptions: DropdownItem[] = [
  { id: 0, name: 'No' },
  { id: 1, name: 'Yes' },
];

const preventableOptions: DropdownItem[] = [
  { id: 0, name: 'Unpreventable' },
  { id: 1, name: 'Preventable' },
];

export const ServiceIssueForm = () => {
  const {
    state: { user, selectedContracts, selectedCustomers },
  } = useAppContext();
  const {
    closeModal,
    serviceIssueTypeOptions,
    classificationTypeOptions,
    buildingOptions,
    floorOptions,
    setFloorOptions,
    roomOptions,
    setRoomOptions,
    serviceIssue,
    serviceIssueEdit,
    setServiceIssueEdit,
    employeeOptions,
    onSuccess,
    service,
    setActiveModals,
    hasBeenEdited,
  } = useServiceIssueFormContext();
  const { workOrder } = useWorkOrderModalContext();

  const [submitError, setSubmitError] = useState(false);
  const [closeNow, setCloseNow] = useState(false);
  const [isFloorsLoading, setIsFloorsLoading] = useState(false);
  const [isRoomsLoading, setIsRoomsLoading] = useState(false);
  const subscription = useRef(new Subscription());

  const locations = useLocationsRef(serviceIssueEdit?.location);

  const [shifts, setshifts] = useState<Shift[]>([]);
  const [selectedShifts, setSelectedShifts] = useState<Shift[]>([]);

  useEffect(() => {
    setServiceIssueEdit({
      ...serviceIssueEdit,
      service: {
        id: service.id,
        name: service.name,
      },
      at_fault_employees:
        serviceIssueEdit?.at_fault_employees?.map((employee) => ({
          ...employee,
          name: getFullName(employee.person),
          subtext: employee.person.email_address,
        })) ?? [],
    });

    const shiftsSubscription = shiftsService
      .getShifts(
        {
          limit: 1000,
          offset: 0,
          ...(!!selectedCustomers.length && {
            customer: selectedCustomers.map((client) => client.id).join(','),
          }),
          ...(!!selectedContracts.length && {
            contract: selectedContracts.map((site) => site.id).join(','),
          }),
        },
        { noOptions: true }
      )
      .subscribe({
        next: ({ results: Shifts }) => {
          setshifts(Shifts ?? []);
          setSelectedShifts(serviceIssueEdit?.shifts ?? []);
        },
        error: sendError({
          toastMessage: 'There was an error retrieving Shifts type data',
        }),
      });
    return () => {
      subscription.current.unsubscribe();
      shiftsSubscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (!isFloorsLoading || locations.building?.id == undefined) return;
    const subscription = serviceAreasService
      .getServiceAreas({ parent: locations.building.id, quick_landing: true })
      .subscribe({
        next: (res) => {
          setFloorOptions(
            res.results.filter((area) => /floor/i.test(area.type.name))
          );
          setIsFloorsLoading(false);
          if (locations.room) {
            setIsRoomsLoading(true);
          }
        },
        error: sendError({
          callback: () => {
            setIsFloorsLoading(false);
          },
        }),
      });
    return () => {
      subscription.unsubscribe();
    };
  }, [isFloorsLoading]);

  useEffect(() => {
    if (!isRoomsLoading || locations.floor?.id == undefined) return;
    const subscription = serviceAreasService
      .getServiceAreas({ parent: locations.floor.id })
      .subscribe({
        next: (res) => {
          setRoomOptions(res.results);
          setIsRoomsLoading(false);
        },
        error: sendError({
          callback: () => {
            setIsRoomsLoading(false);
          },
        }),
      });
    return () => {
      subscription.unsubscribe();
    };
  }, [isRoomsLoading]);

  function handleServiceIssueTypeChange(
    serviceIssueType: ReferenceDropdownItem
  ) {
    setServiceIssueEdit({
      ...serviceIssueEdit,
      type: serviceIssueType,
    });
    hasBeenEdited.current = true;
  }

  function handleClassificationChange(classification: ReferenceDropdownItem) {
    setServiceIssueEdit({
      ...serviceIssueEdit,
      classification,
    });
    hasBeenEdited.current = true;
  }

  function handleRepeatChange(option: DropdownItem) {
    setServiceIssueEdit({
      ...serviceIssueEdit,
      is_repeat_complaint: option.id === 1,
    });
    hasBeenEdited.current = true;
  }

  function handlePreventableChange(option: DropdownItem) {
    setServiceIssueEdit({
      ...serviceIssueEdit,
      is_preventable: option.id === 1,
    });
    hasBeenEdited.current = true;
  }

  function handleBuildingChange(building: LocationDropdownItem) {
    setServiceIssueEdit({
      ...serviceIssueEdit,
      location: building,
    });
    setFloorOptions([]);
    setRoomOptions([]);
    setIsFloorsLoading(true);
    hasBeenEdited.current = true;
  }

  function handleCustomerSelect(customer: Employee) {
    if (!customer) {
      return;
    }

    setServiceIssueEdit({
      ...serviceIssueEdit,
      submitter: {
        id: customer.id,
        person: customer.person,
      },
    });
    hasBeenEdited.current = true;
  }

  function handleDateSelect(date: Date) {
    const prevTime = serviceIssueEdit?.complaint_date
      ? new Date(serviceIssueEdit.complaint_date)
      : new Date();
    const hour = prevTime.getHours();
    const minutes = prevTime.getMinutes();
    const newDate = TimeUtils.setMinutes(
      TimeUtils.setHours(new Date(date), hour),
      minutes
    );
    setServiceIssueEdit({
      ...serviceIssueEdit,
      complaint_date: newDate,
    });
    hasBeenEdited.current = true;
  }

  function handleCompletedDateSelect(date: Date) {
    const prevTime = serviceIssueEdit?.completed_date
      ? new Date(serviceIssueEdit.completed_date)
      : TimeUtils.endOfDay(new Date());
    const hour = prevTime.getHours();
    const minutes = prevTime.getMinutes();
    const newDate = TimeUtils.setMinutes(
      TimeUtils.setHours(new Date(date), hour),
      minutes
    );
    setServiceIssueEdit({
      ...serviceIssueEdit,
      completed_date: newDate.toString(),
    });
    hasBeenEdited.current = true;
  }

  function handleFloorChange(location: LocationDropdownItem) {
    setServiceIssueEdit({
      ...serviceIssueEdit,
      location,
    });
    setRoomOptions([]);
    setIsRoomsLoading(true);
    hasBeenEdited.current = true;
  }

  function handleRoomChange(room: LocationDropdownItem) {
    setServiceIssueEdit({
      ...serviceIssueEdit,
      location: room,
    });
    hasBeenEdited.current = true;
  }

  function handleDescriptionChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
    setServiceIssueEdit({
      ...serviceIssueEdit,
      description: e.target.value,
    });
    hasBeenEdited.current = true;
  }

  function handleImmediateActionChange(
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) {
    setServiceIssueEdit({
      ...serviceIssueEdit,
      immediate_action: e.target.value,
    });
    hasBeenEdited.current = true;
  }

  function handleRootCauseChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
    setServiceIssueEdit({
      ...serviceIssueEdit,
      root_cause: e.target.value,
    });
    hasBeenEdited.current = true;
  }

  function handlePreventativeActionChange(
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) {
    setServiceIssueEdit({
      ...serviceIssueEdit,
      preventative_action: e.target.value,
    });
    hasBeenEdited.current = true;
  }

  function handleAccountableEmployeesChange(employees: typeof employeeOptions) {
    setServiceIssueEdit({
      ...serviceIssueEdit,
      at_fault_employees: employees.map((employee) => ({
        ...employee,
        person: employee.person,
      })),
    });
    hasBeenEdited.current = true;
    if (closeNow) {
      const employeeShifts = [];
      employees.forEach((employee) => {
        employee?.shifts?.forEach((shift) => employeeShifts.push(shift));
      });
      const selectedShifts = shifts.filter((shift) =>
        employeeShifts.some((employeeShift) => employeeShift.id == shift.id)
      );
      setSelectedShifts(selectedShifts ?? []);
    }
  }

  function handleEscalatedChange(e: React.ChangeEvent<HTMLInputElement>) {
    setServiceIssueEdit({
      ...serviceIssueEdit,
      is_escalated: e.target.value === 'true',
    });
    hasBeenEdited.current = true;
  }

  function isValidDate() {
    if (!serviceIssueEdit?.complaint_date) {
      return false;
    }

    const complaintDate = new Date(serviceIssueEdit.complaint_date);
    const tomorrow = TimeUtils.startOfDay(TimeUtils.addDays(new Date(), 1));
    return TimeUtils.isBefore(complaintDate, tomorrow);
  }

  function isValidCompletedDate() {
    if (!serviceIssueEdit?.completed_date) {
      return false;
    }

    const completedDate = new Date(serviceIssueEdit.completed_date);
    const tomorrow = TimeUtils.startOfDay(TimeUtils.addDays(new Date(), 1));
    return TimeUtils.isBefore(completedDate, tomorrow);
  }

  function handleSubmit() {
    const requiredPayload = {
      description: serviceIssueEdit.description,
      customer: serviceIssueEdit.submitter?.id,
      type: serviceIssueEdit.type?.id,
      classification: serviceIssueEdit.classification?.id,
      location: serviceIssueEdit.location?.id,
      is_repeat_complaint: serviceIssueEdit.is_repeat_complaint,
    };
    const closedReqPayload = {
      completed_date: serviceIssueEdit.completed_date,
      is_preventable: serviceIssueEdit.is_preventable,
      immediate_action: serviceIssueEdit.immediate_action,
      root_cause: serviceIssueEdit.root_cause,
      preventative_action: serviceIssueEdit.preventative_action,
    };
    if (
      isValidServiceIssue(requiredPayload) &&
      isValidDate() &&
      (!closeNow ||
        (isValidClosedServiceIssue(closedReqPayload) &&
          isValidCompletedDate())) &&
      !(
        closeNow &&
        serviceIssueEdit?.classification?.code == 'IN_SCOPE' &&
        serviceIssueEdit?.at_fault_employees?.length < 1
      )
    ) {
      setSubmitError(false);
      let serviceIssuePayload = createServiceIssuePayload(serviceIssueEdit);
      serviceIssuePayload = {
        ...serviceIssuePayload,
        ...(closeNow && {
          shifts: selectedShifts.map((shift) => ({ id: shift.id })),
        }),
      };

      if (serviceIssue?.id != undefined) {
        subscription.current = complaintsService
          .updateComplaintByComplaintId(serviceIssue.id, {
            ...serviceIssue,
            ...serviceIssuePayload,
          })
          .subscribe({
            next: (serviceIssue) => {
              toasterService.newToast({
                status: 'success',
                message: 'Complaint successfully updated',
              });
              if (onSuccess) {
                onSuccess(serviceIssue);
              }
              closeModal();
            },
            error: sendError(),
          });
      } else {
        serviceIssuePayload.creator.id = user.employeeId;
        serviceIssuePayload.contract.id = selectedContracts[0].id;
        serviceIssuePayload.service = {
          id: service.id,
        };
        serviceIssuePayload.work_orders = [{ id: workOrder.id }];
        subscription.current = complaintsService
          .createComplaint(serviceIssuePayload)
          .subscribe({
            next: (serviceIssue) => {
              toasterService.newToast({
                status: 'success',
                message: 'Complaint successfully created',
              });
              if (onSuccess) {
                onSuccess(serviceIssue);
              }
              closeModal();
            },
            error: sendError(),
          });
      }
    } else {
      setSubmitError(true);
    }
  }

  function handleClose() {
    if (!hasBeenEdited.current) {
      closeModal();
    } else {
      setActiveModals({
        'cancel-service-issue': true,
      });
    }
  }
  const debouncedDescriptionChange = debounce(handleDescriptionChange, 500);
  const debouncedImmediateActionChange = debounce(
    handleImmediateActionChange,
    500
  );
  const debouncedRootCauseChange = debounce(handleRootCauseChange, 500);
  const debouncedPreventativeActionChange = debounce(
    handlePreventativeActionChange,
    500
  );

  return (
    <ModalStack hasSpacingY>
      <Modal.Generic
        title={
          serviceIssueEdit?.id != undefined
            ? `Edit Complaint #${serviceIssueEdit.id}`
            : 'Create Complaint'
        }
        onCancel={handleClose}
        className="tw-mx-auto tw-w-full tw-space-y-6 tw-max-w-[680px] sm:tw-space-y-8"
      >
        <FieldInputText
          required
          fieldType="textarea"
          id="complaint_description"
          label="Description"
          defaultValue={serviceIssueEdit?.description ?? ''}
          onChange={debouncedDescriptionChange}
          max={2000}
          error={
            submitError &&
            !serviceIssueEdit.description &&
            'This field is required'
          }
        />
        <div className="tw-w-full">
          <UserSelect
            allowAddUser
            addUserOptions={{
              provider: selectedContracts[0].service_provider,
              customer: selectedContracts[0].customer,
            }}
            userType="customer"
            title="Customer"
            onSelect={handleCustomerSelect}
            saveError={submitError && !serviceIssueEdit.submitter?.id}
            defaultValue={
              (serviceIssueEdit && serviceIssueEdit.submitter?.id) || undefined
            }
          />
        </div>
        <div className="tw-w-full tw-space-y-6 sm:tw-space-y-0 sm:tw-gap-4 sm:tw-grid sm:tw-grid-cols-2">
          <FieldInputDate
            required
            disableFuture
            id="complaint_due_date"
            label="Date"
            value={
              serviceIssueEdit?.complaint_date
                ? TimeUtils.format(
                    serviceIssueEdit.complaint_date,
                    'MM/DD/YYYY'
                  )
                : null
            }
            onChange={handleDateSelect}
            error={
              submitError &&
              !isValidDate() &&
              !serviceIssueEdit?.complaint_date &&
              'This field is required'
            }
          />

          <FieldDropdown
            required
            id="complaints_is_repeat"
            label="Repeat"
            items={repeatOptions}
            selectedItem={
              serviceIssueEdit?.is_repeat_complaint !== undefined
                ? repeatOptions.find(
                    (option) =>
                      option.id ===
                      (serviceIssueEdit?.is_repeat_complaint ? 1 : 0)
                  )
                : null
            }
            onChange={handleRepeatChange}
            error={
              submitError &&
              serviceIssueEdit.is_repeat_complaint === undefined &&
              'This field is required'
            }
          />
        </div>
        <div className="tw-w-full tw-space-y-6 sm:tw-space-y-0 sm:tw-gap-4 sm:tw-grid sm:tw-grid-cols-2">
          <FieldDropdown
            required
            id="complaint_type"
            label="Complaint Type"
            items={serviceIssueTypeOptions}
            selectedItem={
              serviceIssueTypeOptions.find(
                (type) => type.id === serviceIssueEdit?.type?.id
              ) || null
            }
            onChange={handleServiceIssueTypeChange}
            error={
              submitError &&
              !serviceIssueEdit.type?.id &&
              'This field is required'
            }
          />
          <FieldDropdown
            required
            id="complaint_classification"
            label="Classification"
            items={classificationTypeOptions}
            selectedItem={
              classificationTypeOptions.find(
                (type) => type.id === serviceIssueEdit?.classification?.id
              ) || null
            }
            onChange={handleClassificationChange}
            error={
              submitError &&
              !serviceIssueEdit.classification?.id &&
              'This field is required'
            }
          />
        </div>
        <div className="tw-w-full tw-space-y-6 sm:tw-space-y-0 sm:tw-gap-4 sm:tw-grid sm:tw-grid-cols-2">
          <FieldDropdown
            required
            id="complaint_building"
            label="Building"
            items={buildingOptions}
            selectedItem={
              buildingOptions.find(
                (building) => building.id === locations.building?.id
              ) || null
            }
            onChange={handleBuildingChange}
            error={
              submitError &&
              !serviceIssueEdit.location?.id &&
              'This field is required'
            }
            disabled={!buildingOptions.length}
          />
          <FieldDropdown
            id="complaint_floor"
            label="Floor (Optional)"
            items={floorOptions}
            selectedItem={
              floorOptions.find((floor) => floor.id === locations.floor?.id) ||
              null
            }
            onChange={handleFloorChange}
            disabled={!floorOptions.length}
          />
        </div>
        <div className="tw-w-full tw-space-y-6 sm:tw-space-y-0 sm:tw-gap-4 sm:tw-grid sm:tw-grid-cols-2">
          <FieldDropdown
            id="complaint-area"
            label="Area (Optional)"
            items={roomOptions}
            selectedItem={
              roomOptions.find((item) => item.id === locations.room?.id) || null
            }
            onChange={handleRoomChange}
            disabled={!roomOptions.length}
          />
          {closeNow && (
            <FieldMultiSelect
              id="shifts"
              label="Please select a Shift"
              items={shifts}
              selectedItems={selectedShifts}
              onChange={(shifts: typeof selectedShifts) => {
                setSelectedShifts(shifts);
                const selectedEmployees =
                  employeeOptions.filter((employee) =>
                    shifts.some((shift) =>
                      employee.shifts.some(
                        (employeeShift) => employeeShift.id == shift.id
                      )
                    )
                  ) ?? [];
                setServiceIssueEdit({
                  ...serviceIssueEdit,
                  at_fault_employees: selectedEmployees.map((employee) => ({
                    ...employee,
                    person: employee.person,
                  })),
                });
              }}
            />
          )}
        </div>
        <FieldMultiSelect
          id="accountable-employees"
          name="accountable-employees"
          label="Accountable Employees"
          items={employeeOptions}
          selectedItems={
            (serviceIssueEdit?.at_fault_employees as typeof employeeOptions) ||
            []
          }
          onChange={handleAccountableEmployeesChange}
          required={
            closeNow &&
            serviceIssueEdit?.classification?.code == 'IN_SCOPE' &&
            serviceIssueEdit?.at_fault_employees?.length < 1
          }
          error={
            submitError &&
            closeNow &&
            serviceIssueEdit?.classification?.code == 'IN_SCOPE' &&
            serviceIssueEdit?.at_fault_employees?.length < 1 &&
            'This field is required'
          }
        />
        {closeNow && (
          <>
            <div className="tw-w-full tw-space-y-6 sm:tw-space-y-0 sm:tw-gap-4 sm:tw-grid sm:tw-grid-cols-2">
              <FieldInputDate
                required
                disableFuture
                id="complaint_completed_date"
                label="Completed Date"
                value={
                  serviceIssueEdit?.completed_date
                    ? TimeUtils.format(
                        serviceIssueEdit.completed_date,
                        'MM/DD/YYYY'
                      )
                    : null
                }
                onChange={handleCompletedDateSelect}
                error={
                  (serviceIssueEdit?.completed_date !== undefined ||
                    submitError) &&
                  !isValidCompletedDate()
                    ? submitError && !serviceIssueEdit?.completed_date
                      ? 'This field is required'
                      : 'Date cannot be in the future'
                    : null
                }
              />

              <FieldDropdown
                required
                id="complaints_preventable_status"
                label="Preventable Status"
                items={preventableOptions}
                selectedItem={
                  serviceIssueEdit?.is_preventable !== undefined
                    ? preventableOptions.find(
                        (option) =>
                          option.id ===
                          (serviceIssueEdit?.is_preventable ? 1 : 0)
                      )
                    : null
                }
                onChange={handlePreventableChange}
                error={
                  submitError &&
                  serviceIssueEdit.is_preventable === undefined &&
                  'This field is required'
                }
              />
            </div>
            <FieldInputText
              required
              fieldType="textarea"
              id="complaint_corrective_action"
              label="Immediate Corrective Action Taken"
              defaultValue={serviceIssueEdit?.immediate_action ?? ''}
              onChange={debouncedImmediateActionChange}
              max={2000}
              error={
                submitError &&
                !serviceIssueEdit.immediate_action &&
                'This field is required'
              }
            />
            <FieldInputText
              required
              fieldType="textarea"
              id="complaint_root_cause"
              label="Actual Root Cause"
              defaultValue={serviceIssueEdit?.root_cause ?? ''}
              onChange={debouncedRootCauseChange}
              max={2000}
              error={
                submitError &&
                !serviceIssueEdit.root_cause &&
                'This field is required'
              }
            />
            <FieldInputText
              required
              fieldType="textarea"
              id="complaint_preventative_action"
              label="Preventative Action"
              defaultValue={serviceIssueEdit?.preventative_action ?? ''}
              onChange={debouncedPreventativeActionChange}
              max={2000}
              error={
                submitError &&
                !serviceIssueEdit.preventative_action &&
                'This field is required'
              }
            />
            <FieldRadioGroup
              id="escalated"
              name="escalated"
              label="Has this Complaint been escalated?"
              radios={[
                { label: 'Yes', value: 'true' },
                { label: 'No', value: 'false' },
              ]}
              onChange={handleEscalatedChange}
              defaultChecked={serviceIssueEdit?.is_escalated ? 'true' : 'false'}
            />
          </>
        )}
        <div className="tw-flex tw-justify-between tw-gap-4">
          {serviceIssue?.status !== 'CLOSED' && (
            <Button
              label={
                closeNow ? 'Continue Without Closing' : 'Close Complaint Now'
              }
              onClick={() => setCloseNow(!closeNow)}
              className="tw-w-full sm:tw-w-auto"
              color="secondary"
            />
          )}
          <Button
            label={
              serviceIssueEdit?.id != undefined
                ? 'Save Complaint'
                : 'Create Complaint'
            }
            onClick={handleSubmit}
            className="tw-w-full sm:tw-w-auto"
            color="primary"
          />
        </div>
      </Modal.Generic>
    </ModalStack>
  );
};
