import { BillingPage } from './interfaces';

export function billingPageReducer(
	state: BillingPage.State,
	action: BillingPage.Action
): BillingPage.State {
	switch (action.type) {
		case 'SET_INVOICE_PROGRAM': {
			return {
				selectedInvoiceProgram: action.payload,
			};
		}
		case 'RESET': {
			return {
				selectedInvoiceProgram: null,
			};
		}
		default: {
			return state;
		}
	}
}
