import {
	SummaryChart,
	SummaryParams,
	PaginationParams,
	PaginationResponse,
} from '@models';

export interface GetServiceAreasSummaryParams<T> extends SummaryParams<T> {
	ddl?: boolean;
	building?: number;
}

export namespace ServiceAreasSummary {
	export enum Chart {
		GetCleaningComplianceCounts = 0,
		GetServiceValidationLandingSummary = 3,
		GetServiceValidationBuildingDetailSummary = 4,
	}

	export interface CleaningComplianceCounts {
		Goal: number;
		areas_completed: null | number;
		areas_not_disinfected: number;
		compliance: null | number;
		total_areas: number;
	}

	export interface CleaningComplianceCountsSummary
		extends SummaryChart<CleaningComplianceCounts> {
		chart: 'GetCleaningComplianceCounts';
		number: '0';
	}

	export interface ServiceValidationLanding {
		Goal: number;
		areas_completed: number;
		areas_not_disinfected: number;
		compliance: number;
		incomplete_tasks: number;
		most_recent_complete_date: null | string;
		total_areas: number;
	}

	export interface ServiceValidationLandingSummary
		extends SummaryChart<ServiceValidationLanding> {
		chart: 'GetServiceValidationLandingSummary';
		number: '3';
	}

	export interface ServiceValidationBuildingDetail {
		areas_completed: number;
		areas_not_disinfected: number;
		building_id: string;
		building_name: string;
		compliance: number;
		contract_name: string;
		incomplete_tasks: number;
		most_recent_complete_date: null | string;
		organization_name: string;
		total_areas: number;
	}

	export interface ServiceValidationBuildingDetailSummary
		extends SummaryChart<ServiceValidationBuildingDetail> {
		chart: 'GetServiceValidationBuildingDetailSummary';
		number: '4';
	}

	export interface SummaryReturnType {
		[Chart.GetCleaningComplianceCounts]: CleaningComplianceCountsSummary;
		[Chart.GetServiceValidationLandingSummary]: ServiceValidationLandingSummary;
		[Chart.GetServiceValidationBuildingDetailSummary]: ServiceValidationBuildingDetailSummary;
	}
}

export interface GetServiceAreaCardParams extends PaginationParams {
	from: Date;
	to: Date;
	exclude_completed?: boolean;
	search?: string;
}

export interface ServiceAreaCardPagination
	extends PaginationResponse<ServiceAreaCard> {
	resultSummary: ServiceAreasSummary.ServiceValidationLanding;
}

export interface ServiceAreaCard {
	building_name: string;
	contract_name: string;
	id: number;
	organization_name: string;
	sub_areas_completed: number;
	sub_compliance: number;
	sub_incomplete_tasks: number;
	sub_most_recent_complete_date: null | string;
	sub_total_areas: number;
}
