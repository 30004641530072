import { forwardRef } from 'react';

// components
import { Icon } from '@atoms';

// interfaces
import { InputFileProps } from './input-file.interfaces';

export const InputFile = forwardRef<HTMLInputElement, InputFileProps>(
  ({ label, className, style, ...props }, ref) => (
    <label
      data-ds2="input-file"
      role="button"
      className={`tw-h-10 tw-px-3 tw-rounded tw-flex tw-items-center tw-justify-center tw-w-max tw-bg-theme-primary-500-300 tw-bg-opacity-15 tw-text-theme-primary-500-300 tw-text-sm tw-font-medium tw-font-roboto ${className}`}
      style={style}
    >
      <Icon icon={['far', 'plus']} size="sm" className="tw-mr-3" />
      {label}
      <input
        ref={ref}
        type="file"
        className="tw-hidden tw-h-0 tw-w-0"
        {...props}
      />
    </label>
  )
);

InputFile.defaultProps = {
  label: 'Add Attachment',
  className: '',
};
