// base
import { Fragment } from 'react';
import { TimeTableRow } from '@app/collections/routes/_helpers/time-table-processor/interfaces';

// helpers
import { Icon, TableSimple, Text } from '@atoms';
import { urlRegex } from '@helpers';
import { toStandardTime } from '@helpers/strings';

// components

interface Props {
    timetableRow: TimeTableRow;
    onClickBack: () => void;
}

export default function StartEndDetails({ timetableRow, onClickBack }: Props) {
    const instructions =
        timetableRow?.references?.stop?.description ??
        'No instructions available';
    const buildingName = timetableRow?.area?.name ?? '';

    function formatUrls(str: string) {
        const parts = str.split(urlRegex);
        return parts.map((part, index) => {
            if (urlRegex.test(part)) {
                return (
                    <a
                        key={index}
                        href={part}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="t-text-link !tw-text-base"
                    >
                        {part}
                    </a>
                );
            }
            return <Fragment key={index}>{part}</Fragment>;
        });
    }

    return (
        <div id="routes_routes-detail_start-end-details">
            <div className="tw-flex tw-justify-between tw-items-center tw-mb-8">
                <a className="back-link t-text-link" onClick={onClickBack}>
                    <Icon icon="chevron-left" className="tw-mr-2" />
                    Back to full route details
                </a>

                <div className="tw-flex tw-flex-col tw-text-right">
                    <span className="t-text-weight-medium tw-mb-1">
                        Schedule: {toStandardTime(timetableRow.time.start)} -{' '}
                        {toStandardTime(timetableRow.time.end)}
                    </span>
                    <span className="t-text-weight-medium">
                        Total Estimated Time: {timetableRow.time.total} minutes
                    </span>
                </div>
            </div>

            <div
                className="tw-flex tw-justify-between tw-items-center tw-mb-8 tw-py-2 tw-border-b tw-border-solid"
                style={{
                    borderColor: 'var(--well-background-color)',
                }}
            >
                <h3>{buildingName}</h3>
            </div>

            <TableSimple
                headers={[{ label: 'Instructions' }]}
                rows={[
                    {
                        headerLabel: {
                            Instructions: (
                                <Text
                                    font="body-lg"
                                    color="hi-contrast"
                                    className="tw-whitespace-pre"
                                >
                                    {formatUrls(instructions)}
                                </Text>
                            ),
                        },
                    },
                ]}
                className="tw-mt-4"
            />
        </div>
    );
}
