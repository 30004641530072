// services
import { toasterService } from '@services/toaster';

interface SendErrorParams {
	callback?: (error?: unknown) => void;
	useToast?: boolean;
	toastMessage?:
		| {
				message: string; // generic message
				priority?: 'high' | 'low'; // high priority will be first message sent, low is last in logic chain
		  }
		| string;
}

const defaultToasterMessage: SendErrorParams['toastMessage'] = {
	priority: 'low',
	message: 'There was an error',
};

const errorProperties = [
	'error',
	'Error',
	'detail',
	'Detail',
	'details',
	'Details',
]; // order matters

export const sendError = (
	params: SendErrorParams = {
		callback: null,
		toastMessage: defaultToasterMessage,
		useToast: true,
	}
) => {
	return (err?: unknown) => {
		if (process.env.NODE_ENV === 'development' && err) {
			console.error(err);
		}

		const {
			callback,
			toastMessage = defaultToasterMessage,
			useToast = true,
		} = params;

		let toastMsg = '';
		if (typeof toastMessage === 'string') {
			toastMsg = toastMessage;
		} else {
			const { message, priority = 'high' } = toastMessage;
			switch (true) {
				case priority === 'high':
					toastMsg = message;
					break;
				case err && err instanceof Error:
					toastMsg = (err as Error).message;
					break;
				case err && typeof err === 'string':
					toastMsg = err as string;
					break;
				case err && typeof err === 'object':
					for (const errorProp of errorProperties) {
						if (err[errorProp] && typeof err[errorProp] === 'string') {
							toastMsg = err[errorProp];
							break;
						}
					}
					break;
				default:
					toastMsg = message;
					break;
			}
		}

		if (useToast) {
			toasterService.newToast({ message: toastMsg });
		}

		if (callback) {
			callback(err);
		}
	};
};
