import { useState, useEffect, useCallback, useRef } from 'react';
import { merge } from 'lodash';

// components
import { CommentPayload, Comment } from '@api-interfaces';
import { commentService, mediaService } from '@apis';
import { Icon, Text } from '@insite-next/mocha';
import { Button } from '@components/_new/Button';
import { InputFile } from '@atoms';
import { AttachmentTile } from './_components/AttachmentTile';

// services
import { imageService, toasterService } from '@core/services';

// hooks
import { useAppContext } from '@hooks';

import { CreateComment } from './_components/CreateComment';
import { Thread } from './_components/Thread';

// utils
import {
    IProps,
    IParams,
    AttachmentsState,
    Attachment,
    initialAttachmentsState,
} from './comment.utils';
import { fullscreenGalleryService } from '@app/main/FullscreenGallery/FullscreenGallery.service';

const limit = 10;
export const Comments = (props: IProps) => {
    // props
    const {
        item_id,
        work_item_type,
        isReadOnly,
        submitterPhoneNumber,
        onCommentCountUpdate,
        allowAttachment,
    } = props;

    const {
        state: { user },
    } = useAppContext();

    // state
    const [comments, setComments] = useState<Comment[]>([]);
    const [commentTotal, setCommentTotal] = useState<number>(0);
    const offset = useRef(0);
    const [params, setParams] = useState<IParams>({
        offset: offset.current,
        limit,
        work_item: item_id,
        work_item_type,
    });
    const [attachmentsState, setAttachmentsState] = useState<AttachmentsState>(
        initialAttachmentsState
    );

    const [isDisabled, setIsDisabled] = useState<boolean>(false);

    const hasCreatePermissions = user.hasPermission(2, 'Comments');
    const scrollRef = useRef(null);
    // end state

    // functions
    useEffect(() => {
        if (onCommentCountUpdate) {
            onCommentCountUpdate(commentTotal);
        }
    }, [commentTotal]);

    useEffect(() => {
        setComments([]);
        setParams({ ...params, work_item: item_id });
    }, [item_id]);

    const scrollTo = () => {
        scrollRef.current.scrollIntoView({ behavior: 'smooth' });
    };
    const getComments = useCallback(() => {
        commentService.getComments(params).subscribe({
            next: (res) => {
                if (!res.next) {
                    setIsDisabled(true);
                }
                const totalChildComments = res?.results.reduce(
                    (prev, curr) => prev + curr.child_comments,
                    0
                );
                setCommentTotal(res?.count + totalChildComments);
                setComments([...comments, ...res.results]);
            },
        });
    }, [params]);

    useEffect(() => {
        getComments();
    }, [getComments]);

    const submitComment = (input: string) => {
        const media = attachmentsState.attachmentsQueue.map((item) => {
            return { id: item.id };
        });

        const payload: CommentPayload = {
            comment: {
                commenter: {
                    id: user.employeeId,
                },
                body: input,
                work_item: {
                    type: work_item_type,
                    id: item_id,
                },
                submitter_phone_number: submitterPhoneNumber,
                media: media, // TURN THIS ON WHEN BE IS DONE FOR ATTACHMENTS
            },
        };

        commentService.createComment(payload).subscribe({
            next: (res) => {
                const append_new = [res, ...comments];
                setComments(append_new);
                setCommentTotal(commentTotal + 1);
            },
            error: (err) => {
                toasterService.newToast({
                    status: 'fail',
                    message: 'Could not create new comment.',
                });
            },
        });
    };

    const editComment = (
        id: number,
        input: string,
        attachments: Attachment[]
    ) => {
        const groomMedia = attachments.map((attachment) => {
            return { id: attachment.id };
        });
        commentService
            .updateCommentById(id, {
                body: input,
                media: groomMedia,
            })
            .subscribe({
                error: (err) => {
                    toasterService.newToast({
                        status: 'fail',
                        message: 'Could not edit the comment.',
                    });
                },
            });
    };

    const deleteParentComment = (id: number) => {
        commentService.deleteCommentById(id).subscribe({
            next: () => {
                toasterService.newToast({
                    status: 'success',
                    message: 'The comment was deleted successfully.',
                });
                const removeThread = comments.filter(
                    (comment) => comment.id !== id
                );
                // get new total of remaining comments + their replies
                const updatedCommentTotal =
                    removeThread.length +
                    removeThread.reduce(
                        (prev, curr) => prev + curr.child_comments,
                        0
                    );
                setComments(removeThread);
                setCommentTotal(updatedCommentTotal);
            },
            error: () => {
                toasterService.newToast({
                    status: 'fail',
                    message: 'There was an error trying to delete the comment.',
                });
            },
        });
    };

    const loadMoreClick = () => {
        offset.current += limit;
        setParams({ ...params, offset: offset.current });
    };

    const handleAddAttachment = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        const { files: fileList } = e.target;
        Array.from(fileList).forEach((file) =>
            mediaService
                .upload(file, {
                    employeeId: user.employeeId,
                    organizationId: user.organization.id,
                })
                .subscribe((res: Media) => {
                    setAttachmentsState({
                        ...attachmentsState,
                        addQueue: [
                            ...attachmentsState.addQueue,
                            {
                                id: res.id,
                                file_name: res.file_name,
                                display_name: file.name,
                            },
                        ],
                        attachmentsQueue: [
                            ...attachmentsState.attachmentsQueue,
                            {
                                id: res.id,
                                file_name: res.file_name,
                                display_name: file.name,
                            },
                        ],
                        attachmentSelected: {
                            id: res.id,
                            file_name: res.file_name,
                            display_name: file.name,
                        },
                    });
                })
        );
    };

    const removeAttachment = (item) => {
        setAttachmentsState({
            ...attachmentsState,
            addQueue: attachmentsState.addQueue.filter((i) => {
                if (i.id !== item.id) {
                    return i;
                }
            }),
            attachmentsQueue: attachmentsState.attachmentsQueue.filter((i) => {
                if (i.id !== item.id) {
                    return i;
                }
            }),
            attachmentSelected:
                attachmentsState.attachmentSelected.id === item.id
                    ? {}
                    : attachmentsState.attachmentSelected,
        });
    };
    // end functions

    return (
        <>
            <div ref={scrollRef} />
            <Text font="body-md" color="neutral-offset-white">
                Comments ({commentTotal})
            </Text>
            {hasCreatePermissions && !isReadOnly ? (
                <>
                    <CreateComment
                        className="tw-my-4"
                        submit={submitComment}
                        title="Leave a comment"
                        attachmentsState={attachmentsState}
                        setAttachmentsState={setAttachmentsState}
                        allowAttachment={allowAttachment}
                        handleAddAttachment={handleAddAttachment}
                    />
                    {attachmentsState?.attachmentsQueue?.length > 0 && (
                        <>
                            <Text
                                font="body-sm"
                                color={'alternate'}
                                className="tw-mb-2"
                            >
                                Attachments (Click send to updload)
                            </Text>
                            <div className="tw-w-full tw-border-b tw-border-solid tw-border-theme-neutral-900-100 tw-border-opacity-15 tw-pb-6 tw-flex tw-gap-3 tw-flex-wrap">
                                {attachmentsState?.attachmentsQueue?.map(
                                    (item, idx) => (
                                        <AttachmentTile
                                            key={idx}
                                            isEditable
                                            item={item}
                                            attachmentsState={attachmentsState}
                                            removeAttachment={removeAttachment}
                                            setAttachmentsState={
                                                setAttachmentsState
                                            }
                                        />
                                    )
                                )}
                            </div>
                        </>
                    )}
                </>
            ) : null}
            <div style={{ marginTop: '33px' }}>
                {comments.map((comment: Comment) => (
                    <Thread
                        key={comment.id}
                        comment={comment}
                        item_id={item_id}
                        editComment={editComment}
                        deleteComment={deleteParentComment}
                    />
                ))}
            </div>
            {!isDisabled && (
                <Button
                    className="tw-w-full tw-justify-center tw-mt-4"
                    color="alternate"
                    label="View More"
                    onClick={() => loadMoreClick()}
                />
            )}
            {comments.length > 5 && (
                <Text
                    className="tw-cursor-pointer tw-mt-4"
                    font="body-sm"
                    color="primary"
                    onClick={scrollTo}
                >
                    Leave a Comment
                </Text>
            )}
        </>
    );
};
