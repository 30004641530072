// helpers
import { DateRange, DateRangeType } from '@core/app-context/app.interfaces';
import { TimeUtils } from '@helpers';
import { addWeeks, isThisWeek } from 'date-fns';

// interfaces
const {
    addMonths,
    subMonths,
    startOfMonth,
    endOfMonth,
    addDays,
    subDays,
    addYears,
    subYears,
    startOfDay,
    endOfDay,
    differenceInSeconds,
    isThisMonth,
    addSeconds,
    startOfWeek,
    endOfWeek,
} = TimeUtils;

type CustomDateRange = Pick<
    DateRange,
    'from' | 'to' | 'customStartTime' | 'customEndTime'
>;

interface IFunction {
    action: string;
    dateRange: CustomDateRange;
    allowFutureDates?: boolean; // DatePickerV2 prop
}

export type DatePickerTypes = Extract<
    DateRangeType,
    | 'today'
    | 'current-week'
    | 'this-month'
    | 'last-month'
    | '3mo'
    | '6mo'
    | '1yr'
    | 'custom'
>;

export type DateRangeConfig = {
    label: string;
    function: (fn: IFunction) => CustomDateRange;
};

type IDateCalculator = {
    [Property in DatePickerTypes]: DateRangeConfig;
};

export const dateCalculator: IDateCalculator = {
    today: {
        label: 'Today',
        function: ({ action, dateRange }) => {
            const from =
                action === 'prev'
                    ? subDays(dateRange.from, 1)
                    : action === 'next'
                      ? addDays(dateRange.from, 1)
                      : new Date();
            return {
                ...dateRange,
                from: startOfDay(from),
                to: endOfDay(from),
            };
        },
    },

    'current-week': {
        label: 'Current Week',
        function: ({ action, dateRange, allowFutureDates }) => {
            const from =
                action === 'prev'
                    ? addWeeks(dateRange.from, -1)
                    : action === 'next'
                      ? addWeeks(dateRange.from, 1)
                      : new Date();
            return {
                ...dateRange,
                from: startOfWeek(from),
                to: isThisWeek(from)
                    ? allowFutureDates
                        ? endOfWeek(from)
                        : endOfDay(new Date())
                    : endOfWeek(from),
            };
        },
    },

    'this-month': {
        label: 'Current Month',
        function: ({ action, dateRange, allowFutureDates }) => {
            const from =
                action === 'prev'
                    ? subMonths(dateRange.from, 1)
                    : action === 'next'
                      ? addMonths(dateRange.from, 1)
                      : new Date();
            return {
                ...dateRange,
                from: startOfMonth(from),
                to: isThisMonth(from)
                    ? allowFutureDates
                        ? endOfMonth(from)
                        : endOfDay(new Date())
                    : endOfMonth(from),
            };
        },
    },

    'last-month': {
        label: 'Last Month',
        function: ({ action, dateRange, allowFutureDates }) => {
            const from =
                action === 'prev'
                    ? subMonths(dateRange.from, 1)
                    : action === 'next'
                      ? addMonths(dateRange.from, 1)
                      : subMonths(new Date(), 1);
            return {
                ...dateRange,
                from: startOfMonth(from),
                to: isThisMonth(from)
                    ? allowFutureDates
                        ? endOfMonth(from)
                        : endOfDay(new Date())
                    : endOfMonth(from),
            };
        },
    },

    '3mo': {
        label: '3 Months',
        function: ({ action, dateRange }) => {
            const from =
                action === 'prev'
                    ? subMonths(dateRange.from, 3)
                    : action === 'next'
                      ? addMonths(dateRange.from, 3)
                      : subMonths(new Date(), 3);
            return {
                ...dateRange,
                from: startOfDay(from),
                to: endOfDay(addMonths(from, 3)),
            };
        },
    },

    '6mo': {
        label: '6 Months',
        function: ({ action, dateRange }) => {
            const from =
                action === 'prev'
                    ? subMonths(dateRange.from, 6)
                    : action === 'next'
                      ? addMonths(dateRange.from, 6)
                      : subMonths(new Date(), 6);
            return {
                ...dateRange,
                from: startOfDay(from),
                to: endOfDay(addMonths(from, 6)),
            };
        },
    },

    '1yr': {
        label: '1 Year',
        function: ({ action, dateRange }) => {
            const from =
                action === 'prev'
                    ? subYears(dateRange.from, 1)
                    : action === 'next'
                      ? addYears(dateRange.from, 1)
                      : subYears(new Date(), 1);
            return {
                ...dateRange,
                from: startOfDay(from),
                to: endOfDay(addYears(from, 1)),
            };
        },
    },

    custom: {
        label: 'Custom',
        function: ({ action, dateRange }) => {
            let difference = differenceInSeconds(dateRange.from, dateRange.to);
            difference = Math.abs(difference);
            let from =
                action === 'prev'
                    ? subDays(dateRange.from, 1)
                    : action === 'next'
                      ? addDays(dateRange.from, 1)
                      : subDays(new Date(), 1);
            let to = endOfDay(addSeconds(from, difference));
            if (dateRange.customStartTime) {
                const [hour, minute] = dateRange.customStartTime
                    .split(':')
                    .map(Number);
                from = new Date(from);
                from.setHours(hour, minute, 0, 0);
            }
            if (dateRange.customEndTime) {
                const [hour, minute] = dateRange.customEndTime
                    .split(':')
                    .map(Number);
                to = new Date(to);
                to.setHours(hour, minute, 59, 999);
            }
            return {
                ...dateRange,
                from,
                to,
            };
        },
    },
};

export default dateCalculator;
