import Svg from 'react-inlinesvg';

import { Text } from '@atoms';
import { Button } from '@new';
import { historyService } from '@services';

export const SurveyFeedbackCard = ({
  svg,
  title,
  subtitle,
  poweredBy,
  hasLoginButton,
}: {
  svg: string;
  title: string;
  subtitle: string;
  poweredBy: string;
  hasLoginButton: boolean;
}) => {
  return (
    <div className="tw-relative tw-grid tw-place-items-center tw-h-full tw-w-full md:tw-bg-theme-neutral-100-800 md:tw-shadow-box md:tw-max-w-[560px] md:tw-max-h-[630px]">
      <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-space-y-6">
        <Svg src={svg} />
        <Text font="h2" color="primary">
          {title}
        </Text>
        <Text
          font="body-md"
          color="hi-contrast"
          className="tw-px-5 tw-text-center"
        >
          {subtitle}
        </Text>
        {hasLoginButton && (
          <Button
            label="Login"
            icon={['far', 'arrow-left']}
            onClick={() => {
              historyService.navigate('/login');
            }}
          />
        )}
      </div>
      <div className="tw-absolute tw-bottom-6 sm:tw-bottom-8 md:tw-bottom-10 tw-left-1/2 tw-transform tw--translate-x-1/2">
        <Text font="body-md" color="hi-contrast">
          {poweredBy}{' '}
          <Text
            font="body-md"
            tag="span"
            color="primary"
            className="tw-font-bold"
          >
            4insite
          </Text>
        </Text>
      </div>
    </div>
  );
};
