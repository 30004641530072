import { memo } from 'react';

// services
import { Text } from '@atoms';
import { useBreakpoints } from '@hooks';

import { CardIcon } from '@new/Card/CardIcon';
import { CardLabel } from '@new/Card/CardLabel';
import { CardScore } from '@new/Card/CardScore';
import { historyService } from '@services';

// interfaces
import { CardDataProps } from './CardData.interfaces';

// components
import './CardData.scss';

/** ************************************************
 * Render Component
 ************************************************* */

export const CardData = memo(
    ({
        layout,
        icon,
        showIcon = true,
        score,
        goal,
        label,
        details,
        extraDetails,
        updatedAt,
        className = '',
        labelClassName = '',
        isComingSoon,
        disabled,
        scoreColor,
        link,
        scoreDataTest,
        detailsDataTest,
        ...props
    }: CardDataProps) => {
        const bp = useBreakpoints();
        const isCompact = layout === 'compact';
        const isComingSoonClassName = isComingSoon ? 'is-coming-soon' : '';

        function handleCardClick(path: string) {
            if (path) historyService.navigate(path);
        }

        return (
            <div
                data-layout={layout}
                className={`_new-cards-contents tw-relative tw-w-full ${isComingSoonClassName} ${className}`}
                {...props}
                onClick={() => handleCardClick(link)}
            >
                {isComingSoon && (
                    <div className="tw-absolute tw-top-6 tw-left-8 tw-py-1 tw-px-2 tw-bg-yelloworange-500 dark:tw-bg-yelloworange-100 tw-bg-opacity-15 dark:tw-bg-opacity-15 tw-rounded">
                        <div className="tw-font-medium tw-text-sm tw-text-yelloworange-500 dark:tw-text-yelloworange-100">
                            Coming Soon
                        </div>
                    </div>
                )}
                {showIcon && icon && (
                    <CardIcon
                        icon={icon}
                        className={
                            isCompact
                                ? 'tw-absolute tw-top-[24px] tw-right-[32px] tw-translate-y-[-15px]'
                                : ''
                        }
                        style={isCompact ? { transform: 'none' } : {}}
                        disabled={disabled}
                    />
                )}
                <div className="block-wrapper">
                    <div className="block">
                        {!(score === undefined || score === null) && (
                            <div className="values">
                                <CardScore
                                    goal={goal}
                                    score={score}
                                    scoreColor={scoreColor}
                                    scoreDataTest={scoreDataTest ?? ''}
                                />
                            </div>
                        )}
                        {label && (
                            <div className="label">
                                <CardLabel
                                    label={label}
                                    className={`${
                                        isComingSoon && isCompact
                                            ? 'tw-max-w-[200px]'
                                            : ''
                                    } ${labelClassName}`}
                                    isComingSoon={isComingSoon}
                                    disabled={disabled}
                                />
                            </div>
                        )}
                        {details && !extraDetails && (
                            <Text
                                font="body-md"
                                color="neutral-offset"
                                className="text"
                                data-test={detailsDataTest ?? ''}
                            >
                                {details}
                            </Text>
                        )}
                        {extraDetails && (
                            <Text
                                font="body-md"
                                color="hi-contrast"
                                className="extra-text"
                            >
                                {extraDetails}
                            </Text>
                        )}
                        {updatedAt && bp.md && (
                            <Text font="body-sm" color="neutral-offset">
                                {updatedAt}
                            </Text>
                        )}
                    </div>
                </div>
            </div>
        );
    }
);
