// interfaces
import { PartialKpiConfig, GoalText } from '../site-scorecard.config.interface';

export const OTHER_KPIS: PartialKpiConfig[] = [
	{
		icon: 'medal',
		title: 'Total ATB Charged',
		subtitle: '',
		goalType: 'percentage',
		goalText: GoalText.noMoreThan,
		key: 'atb_charged',
	},
	{
		icon: 'medal',
		title: '# Of Savings Initiatives Proposed',
		subtitle: '',
		goalType: 'int',
		goalText: GoalText.atLeast,
		key: 'savings_initiatives',
	},
	{
		icon: 'medal',
		title: '# Of Innovations Proposed',
		subtitle: '',
		goalType: 'int',
		goalText: GoalText.atLeast,
		key: 'proposed_innovations',
	},
	{
		icon: 'medal',
		title: 'Total Base Revenue',
		subtitle: '',
		goalType: 'int',
		goalText: GoalText.atLeast,
		key: 'total_base',
	},
	{
		icon: 'clock',
		title: 'On-time Delivery of Quotes',
		subtitle: '',
		goalType: 'percentage',
		goalText: GoalText.atLeast,
		key: 'on_time_atb_quotes',
	},
	{
		icon: 'medal',
		title: 'Impacts to Production (ITP)',
		subtitle: '',
		goalType: 'percentage',
		goalText: GoalText.noMoreThan,
		key: 'impacts_to_production',
	},
	{
		icon: 'medal',
		title: 'Presentation on Sustainability Initiative',
		subtitle: '',
		goalType: 'percentage',
		goalText: GoalText.noMoreThan,
		key: 'presentation_on_sustainability_initiative',
	},
	{
		icon: 'medal',
		title: 'Present Carbon Negative Initiative',
		subtitle: '',
		goalType: 'percentage',
		goalText: GoalText.noMoreThan,
		key: 'present_carbon_negative_initiative',
	},
	{
		icon: 'medal',
		title: 'True Zero Waste Milestones',
		subtitle: '',
		goalType: 'percentage',
		goalText: GoalText.noMoreThan,
		key: 'true_zero_waste_milestones',
	},
	{
		icon: 'medal',
		title: 'Reporting Accuracy',
		subtitle: '',
		goalType: 'percentage',
		goalText: GoalText.noMoreThan,
		key: 'reporting_accuracy',
	},
	{
		icon: 'medal',
		title: 'Reporting on Delivery priorities',
		subtitle: '',
		goalType: 'percentage',
		goalText: GoalText.noMoreThan,
		key: 'reporting_on_delivery_priorities',
	},
	{
		icon: 'medal',
		title: 'Service Validation',
		subtitle: '',
		goalType: 'percentage',
		goalText: GoalText.noMoreThan,
		key: 'service_validation',
	},
	{
		icon: 'medal',
		title: 'Presentation on technology initiatives',
		subtitle: '',
		goalType: 'percentage',
		goalText: GoalText.noMoreThan,
		key: 'presentation_on_technology_initiatives',
	},
	{
		icon: 'medal',
		title: 'Work Order SLA Compliance',
		subtitle: '',
		goalType: 'percentage',
		goalText: GoalText.atLeast,
		key: 'work_order_sla_compliance',
	},
	{
		icon: 'file-contract',
		title: 'Amazon Week 1',
		subtitle: '',
		goalType: 'percentage',
		goalText: GoalText.moreThan,
		key: 'amazon_wk1_compliance',
	},
	{
		icon: 'file-contract',
		title: 'Amazon Week 2',
		subtitle: '',
		goalType: 'percentage',
		goalText: GoalText.moreThan,
		key: 'amazon_wk2_compliance',
	},
	{
		icon: 'file-contract',
		title: 'Amazon Week 3',
		subtitle: '',
		goalType: 'percentage',
		goalText: GoalText.moreThan,
		key: 'amazon_wk3_compliance',
	},
	{
		icon: 'file-contract',
		title: 'Amazon Week 4',
		subtitle: '',
		goalType: 'percentage',
		goalText: GoalText.moreThan,
		key: 'amazon_wk4_compliance',
	},
];
