import { http } from '@services';

const BASE_URL = `token`;

class TokenService {
	getAccessToken(payload: { refresh: string }) {
		return http.post<{ access: string; refresh: string }>(
			`${BASE_URL}/refresh/`,
			payload
		);
	}
}

export const tokenService = new TokenService();
