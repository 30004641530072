// interfaces
import { cloneDeep } from 'lodash';

import {
	InspectionArea,
	InspectionElement,
	InspectionTask,
	InspectionAreaPayload,
	InspectionTaskPayload,
	InspectionComment,
	Media,
	Todo,
} from '@api-interfaces';

// services
import { virtualBrownClient } from '@core/services/django-client';

// helpers
import { Params } from '@helpers';
import { CommonParams, PaginationResponse } from '@models';

// operators

class InspectionAreasService {
	public getInspectionAreas(
		params: {
			inspectionId: number;
			badge_count?: boolean;
			service_employee?: number;
		} & CommonParams
	) {
		const modParams = cloneDeep(params);
		modParams.limit = params.limit ? params.limit : 10000;
		delete modParams.inspectionId;
		const queryString = Params.makeQueryString(modParams);
		return virtualBrownClient.get<PaginationResponse<InspectionArea>>(
			`inspections/${params.inspectionId}/inspectionareas/${queryString}`
		);
	}

	public getInspectionAreaById(params: {
		inspectionId: number;
		inspectionareaId: number;
	}) {
		return virtualBrownClient.get<InspectionArea>(
			`inspections/${params.inspectionId}/inspectionareas/${params.inspectionareaId}/`
		);
	}

	public createInspectionArea(params: {
		inspectionId: number;
		payload: InspectionAreaPayload;
	}) {
		return virtualBrownClient.post<InspectionArea>(
			`inspections/${params.inspectionId}/inspectionareas/`,
			params.payload
		);
	}

	public updateInspectionArea(params: {
		inspectionId: number;
		inspectionareaId: number;
		payload: InspectionAreaPayload;
	}) {
		return virtualBrownClient.put<InspectionArea>(
			`inspections/${params.inspectionId}/inspectionareas/${params.inspectionareaId}/`,
			params.payload
		);
	}

	public deleteInspectionArea(params: {
		inspectionId: number;
		inspectionareaId: number;
	}) {
		return virtualBrownClient.delete<InspectionArea>(
			`inspections/${params.inspectionId}/inspectionareas/${params.inspectionareaId}/`
		);
	}

	public getAreaElements(
		params: {
			inspectionId: number;
			inspectionareaId: number;
		} & CommonParams
	) {
		const modParams = cloneDeep(params);
		modParams.limit = params.limit ? params.limit : 10000;
		delete modParams.inspectionId;
		delete modParams.inspectionareaId;
		const queryString = Params.makeQueryString(modParams);
		return virtualBrownClient.get<PaginationResponse<InspectionElement>>(
			`inspections/${params.inspectionId}/inspectionareas/${params.inspectionareaId}/areaelements/${queryString}`
		);
	}

	public getInspectionTasks(
		params: {
			inspectionId: number;
			inspectionareaId: number;
			areaElementId: number;
		} & CommonParams
	) {
		const modParams: any = cloneDeep(params);
		modParams.limit = params.limit ? params.limit : 10000;
		modParams.area_element = params.areaElementId;
		delete modParams.inspectionId;
		delete modParams.inspectionareaId;
		delete modParams.areaElementId;
		const queryString = Params.makeQueryString(modParams);
		return virtualBrownClient.get<PaginationResponse<InspectionTask>>(
			`inspections/${params.inspectionId}/inspectionareas/${params.inspectionareaId}/taskinspections/${queryString}`
		);
	}

	public updateInspectionTask(params: {
		inspectionId: number;
		inspectionareaId: number;
		taskId: number;
		payload: InspectionTaskPayload;
	}) {
		return virtualBrownClient.put<InspectionTask>(
			`inspections/${params.inspectionId}/inspectionareas/${params.inspectionareaId}/taskinspections/${params.taskId}/`,
			params.payload
		);
	}

	// update mulitple task by area or element
	public updateInspectionTasksStatuses(params: {
		inspectionId: number;
		inspectionareaId: number;
		areaElementId?: number;
		is_pass: boolean | null;
		couldnt_score: boolean;
	}) {
		return virtualBrownClient.put(
			`inspections/${params.inspectionId}/inspectionareas/${params.inspectionareaId}/taskstatus/`,
			(() => {
				const payload: any = {
					is_pass: params.is_pass,
					couldnt_score: params.couldnt_score,
				};

				if (params.areaElementId) {
					payload.area_element = {
						id: params.areaElementId,
					};
				}

				return payload;
			})()
		);
	}

	public getTodos(
		params: {
			inspectionId: number;
			inspectionareaId: number;
		} & CommonParams
	) {
		const modParams = cloneDeep(params);
		modParams.limit = params.limit ? params.limit : 10000;
		delete modParams.inspectionId;
		delete modParams.inspectionareaId;
		const queryString = Params.makeQueryString(modParams);
		return virtualBrownClient.get<PaginationResponse<Todo>>(
			`inspections/${params.inspectionId}/inspectionareas/${params.inspectionareaId}/todos/${queryString}`
		);
	}

	public getPhotos(
		params: {
			inspectionId: number;
			inspectionareaId: number;
		} & CommonParams
	) {
		const modParams = cloneDeep(params);
		modParams.limit = params.limit ? params.limit : 10000;
		delete modParams.inspectionId;
		delete modParams.inspectionareaId;
		const queryString = Params.makeQueryString(modParams);
		return virtualBrownClient.get<PaginationResponse<Media>>(
			`inspections/${params.inspectionId}/inspectionareas/${params.inspectionareaId}/media/${queryString}`
		);
	}

	public getComments(
		params: {
			inspectionId: number;
			inspectionareaId: number;
		} & CommonParams
	) {
		const modParams = cloneDeep(params);
		modParams.limit = params.limit ? params.limit : 10000;
		delete modParams.inspectionId;
		delete modParams.inspectionareaId;
		const queryString = Params.makeQueryString(modParams);
		return virtualBrownClient.get<PaginationResponse<InspectionComment>>(
			`inspections/${params.inspectionId}/inspectionareas/${params.inspectionareaId}/comments/${queryString}`
		);
	}
}

export const inspectionAreasService = new InspectionAreasService();
