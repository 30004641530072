import { BadgeProps } from './badge.interfaces';

export const BADGE_COLORS: {
	[Type in BadgeProps['color']]: { text: string; background: string };
} = {
	danger: {
		text: 'tw-text-danger-700 dark:tw-text-danger-300',
		background: 'tw-bg-danger-700 dark:tw-bg-danger-300',
	},
	neutral: {
		text: 'tw-text-neutral-700 dark:tw-text-neutral-300',
		background: 'tw-bg-neutral-700 dark:tw-bg-neutral-300',
	},
	orange: {
		text: 'tw-text-orange-700 dark:tw-text-orange-100',
		background: 'tw-bg-orange-700 dark:tw-bg-orange-100',
	},
	pinkle: {
		text: 'tw-text-pinkle-700 dark:tw-text-pinkle-100',
		background: 'tw-bg-pinkle-700 dark:tw-bg-pinkle-100',
	},
	primary: {
		text: 'tw-text-theme-primary-500-300',
		background: 'tw-bg-theme-primary-500-300',
	},
	purple: {
		text: 'tw-text-purple-500 dark:tw-text-purple-100',
		background: 'tw-bg-purple-500 dark:tw-bg-purple-100',
	},
	skyblue: {
		text: 'tw-text-skyblue-700 dark:tw-text-skyblue-100',
		background: 'tw-bg-skyblue-700 dark:tw-bg-skyblue-100',
	},
	success: {
		text: 'tw-text-success-900 dark:tw-text-success-500',
		background: 'tw-bg-success-900 dark:tw-bg-success-500',
	},
	warning: {
		text: 'tw-text-warning-700 dark:tw-text-warning-300',
		background: 'tw-bg-warning-700 dark:tw-bg-warning-300',
	},
	'dv-11': {
		text: 'tw-text-neutral-300',
		background: 'tw-bg-dv-11',
	},
	black: {
		text: 'tw-text-neutral-100',
		background: 'tw-bg-black tw-bg-opacity-50 dark:tw-bg-opacity-50',
	},
	bubblegum: {
		text: 'tw-text-bubblegum-700 dark:tw-text-bubblegum-500',
		background: 'tw-bg-bubblegum-700 dark:tw-bg-bubblegum-500',
	},
	redorange: {
		text: 'tw-text-redorange-500',
		background: 'tw-bg-redorange-500',
	},
	yellow: {
		text: 'tw-text-yellow-700 dark:tw-text-yellow-500',
		background: 'tw-bg-yellow-700 dark:tw-bg-yellow-500',
	},
	teal: {
		text: 'tw-text-teal-700 dark:tw-text-teal-500',
		background: 'tw-bg-teal-700 dark:tw-bg-teal-500',
	},
	'matrix-node': {
		// org chart badges, require opacity of 1
		text: 'tw-text-theme-neutral-700-300',
		background: 'tw-bg-theme-neutral-900-100 tw-bg-opacity-15',
	},
};
