import { useContext } from 'react';

import { appContext } from '../app-context';

export function useAppContext() {
	const { state, dispatch } = useContext(appContext);
	return {
		state,
		dispatch,
	};
}
