import { useMemo, useState } from 'react';

// interfaces
import {
  Todo,
  WorkOrderStatusKey,
  WorkOrderDueStatusKey,
} from '@api-interfaces';
import { useAuditModalContext } from '@app/modals/_ds2/audit/_context/context';
import { IconButtonFlat, ModalStack, TableSimple, Text } from '@atoms';
import { TableSimpleProps } from '@atoms/table-simple/table-simple.interfaces';

// components
import { TimeUtils } from '@helpers';
import { useBreakpoints } from '@hooks';
import { StatusBadge } from '@modals/_ds2/work-orders/_components/status-badge';
import { TodoModal } from '@modals/detail-modals';

import { ToDoProgressBar } from '../../../../../_components';

// modals

// helpers

interface Props {
  todos: Todo[];
}

export const ToDos = ({ todos }: Props) => {
  const {
    state: { modals },
    dispatch,
  } = useAuditModalContext();
  const [viewTodo, setViewTodo] = useState<Todo>(null);
  const [isShowing, setIsShowing] = useState(true);
  const bp = useBreakpoints();

  const handleTodoClick = (todo: Todo) => {
    setViewTodo(todo);
    dispatch({
      type: 'SET_MODALS',
      payload: {
        ...modals,
        todo: true,
      },
    });
  };

  const handleTodoClose = () => {
    setViewTodo(null);
    dispatch({
      type: 'SET_MODALS',
      payload: {
        ...modals,
        todo: false,
      },
    });
  };

  const tableData: TableSimpleProps = useMemo(() => {
    const headers: TableSimpleProps['headers'] = [
      { label: 'Description' },
      { label: 'Status' },
    ];
    if (bp.sm) {
      headers.push({
        label: 'Due Date',
      });
    }
    const rows: TableSimpleProps['rows'] = todos.map((todo) => {
      const currentDate = TimeUtils.formatToISO(new Date());
      const isPastDue =
        (!todo.is_complete && TimeUtils.isBefore(todo.due_date, currentDate)) ||
        (todo.is_complete &&
          TimeUtils.isBefore(todo.due_date, todo.closed_date));

      return {
        headerLabel: {
          Description: (
            <Text
              font="body-md"
              color="hi-contrast"
              className="lg:tw-max-w-[174px] md:tw-max-w-[470px] sm:tw-max-w-[260px] tw-max-w-[165px] tw-truncate tw-whitespace-nowrap"
            >
              {todo.comment}
            </Text>
          ),
          Status: (
            <StatusBadge
              status={
                todo.is_complete
                  ? WorkOrderStatusKey.CLOSED
                  : WorkOrderDueStatusKey.OPEN
              }
              isPastDue={isPastDue}
            />
          ),
          'Due Date': (
            <Text color="hi-contrast" font="body-md">
              {TimeUtils.format(todo.due_date, 'M/D/YYYY')}
            </Text>
          ),
        },
        onClick: () => handleTodoClick(todo),
      };
    });

    return {
      headers,
      rows,
    };
  }, [todos, bp.sm]);

  const closedCount = todos.filter((todo) => todo.is_complete).length;
  const totalCount = todos.length;

  return (
    <>
      <div>
        <div className="tw-flex tw-items-center tw-justify-between tw-mb-4 tw-gap-4">
          <Text font="h2" color="primary">
            To-Dos
          </Text>
          {todos.length > 0 && (
            <>
              <div className="tw-flex-grow">
                {bp.sm && (
                  <ToDoProgressBar
                    numerator={closedCount}
                    denominator={totalCount}
                  />
                )}
              </div>
              <Text color="hi-contrast" font="h4">
                {todos.filter((todo) => todo.is_complete).length}/{todos.length}{' '}
                Closed
              </Text>
            </>
          )}
          <IconButtonFlat
            icon={isShowing ? 'chevron-down' : 'chevron-up'}
            onClick={() => setIsShowing(!isShowing)}
          />
        </div>

        {isShowing &&
          (todos?.length ? (
            <TableSimple {...tableData} />
          ) : (
            <div className="tw-w-full">
              <Text font="body-md" color="neutral-offset">
                None
              </Text>
            </div>
          ))}
      </div>

      {viewTodo && (
        <ModalStack position="center" onOverlayClick={handleTodoClose}>
          <TodoModal todo={viewTodo} />
        </ModalStack>
      )}
    </>
  );
};
