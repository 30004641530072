// services
import {
	QuestionnaireQuestion,
	Survey,
	SurveyRecipientsContract,
	SurveyResponsePayload,
} from '@api-interfaces';
import { Params } from '@helpers';
import { PaginationResponse } from '@models';
import { virtualBrownClient } from '@services';

// helpers

// interfaces

const BASE_URL = 'surveys';

class SurveysPublicService {
	getSurveyById(id: number) {
		return virtualBrownClient.get<PaginationResponse<Survey>>(
			`${BASE_URL}/${id}/public/`
		);
	}

	getSurveyQuestions({
		surveyId,
		questionnaireId,
		...params
	}: {
		surveyId: number;
		questionnaireId: number;
		limit?: number;
	}) {
		return virtualBrownClient.get<PaginationResponse<QuestionnaireQuestion>>(
			`${BASE_URL}/${surveyId}/questionnaires/${questionnaireId}/questions/public/${Params.makeQueryString(
				params || {}
			)}`
		);
	}

	deleteSurveyById(surveyId: number) {
		return virtualBrownClient.delete<any>(`${BASE_URL}/${surveyId}/`);
	}

	getSurveyRecipientsContractsBySurveyId(
		surveyId: number,
		params?: { limit?: number }
	) {
		return virtualBrownClient.get<PaginationResponse<SurveyRecipientsContract>>(
			`${BASE_URL}/${surveyId}/recipients/contracts/public/${Params.makeQueryString(
				params || {}
			)}`
		);
	}

	submitSurveyResponse(surveyId: number, payload: SurveyResponsePayload) {
		return virtualBrownClient.post<{
			answer_sheet: number;
			contract: number;
			create_date: string;
			hash_t: null;
			id: number;
			survey: number;
		}>(`${BASE_URL}/${surveyId}/responses/public/`, payload);
	}
}

export const surveysPublicService = new SurveysPublicService();
