import { BottomSheet } from './bottom-sheet';
import { Details } from './details';
import { Generic } from './generic';
import { Information } from './information';
import { PhotoViewer } from './photo-viewer';
import { Prompt } from './prompt';

export const Modal = {
    BottomSheet,
    Details,
    Generic,
    Prompt,
    Information,
    PhotoViewer,
};
