import { SummaryChart, SummaryParams } from '@models';

export interface TurnoverSummaryParams<T> extends SummaryParams<T> {}

export namespace TurnoverSummary {
	export enum Chart {
		GetAnnualizedTurnoverMonthlyTrend = 0,
		GetBusinessConductStandards = 1,
		GetTurnoverBySite = 2,
		GetTurnoverCounts = 3,
		GetEmployeeTurnoverReasons = 4,
		GetEmployeeTurnoverVoluntaryVsInvoluntary = 5,
		GetEmployeeTurnoverReasonCounts = 6,
		GetTurnoverByOrganization = 7,
		GetTurnoverReasonByClient = 8,
		GetTurnoverReasonBySite = 9,
	}

	export interface AnnualizedTurnoverMonthlyTrend {
		month: number;
		past_month_count: number;
		turnover_date: string;
		turnover_rate: number;
		year: number;
	}

	export interface AnnualizedTurnoverMonthlyTrendSummary
		extends SummaryChart<AnnualizedTurnoverMonthlyTrend[]> {
		chart: 'GetAnnualizedTurnoverMonthlyTrend';
		number: '0';
	}

	export interface BusinessConductStandard {
		backgroundcheckpercentage: number;
		drugscreenpercentage: number;
		i9compliancepercentage: number;
		id: number;
		latest_turnover_date: string;
		latest_turnover_rate: number;
		name: string;
	}

	export interface BusinessConductStandardSummary
		extends SummaryChart<BusinessConductStandard[]> {
		chart: 'GetBusinessConductStandards';
		number: '1';
	}

	export interface TurnoverBySite {
		id: number;
		name: string;
		terminated_employee_count: number;
		total_onborading: number;
		turnover_rate: number;
	}

	export interface TurnoverBySitesSummary
		extends SummaryChart<TurnoverBySite[]> {
		chart: 'GetTurnoverBySite';
		number: '2';
	}

	export interface TurnoverCounts {
		average_employment_length: null | number;
		goal: number;
		is_pass: boolean;
		total_exited: number;
		total_onboarding: number;
		turnover_rate: string;
	}

	export interface TurnoverCountsSummary extends SummaryChart<TurnoverCounts> {
		chart: 'GetTurnoverCounts';
		number: '3';
	}

	export interface EmployeeTurnoverReason {
		involuntary: boolean;
		system: boolean;
		terminated_reason_code: string;
		terminated_reason_id: number;
		terminated_reason_name: string;
		total_count: number;
		total_terminated_employees: number;
		uncontrolled: boolean;
		unknown: boolean;
		voluntary: boolean;
	}

	export interface EmployeeTurnoverReasonsSummary
		extends SummaryChart<EmployeeTurnoverReason[]> {
		chart: 'GetEmployeeTurnoverReasons';
		number: '4';
	}

	export interface EmployeeTurnoverVoluntaryVsInvoluntary {
		contract_id: number;
		involuntary_terminated_count: number;
		voluntary_terminated_count: number;
	}

	export interface EmployeeTurnoverVoluntaryVsInvoluntarySummary
		extends SummaryChart<EmployeeTurnoverVoluntaryVsInvoluntary[]> {
		chart: 'GetEmployeeTurnoverVoluntaryVsInvoluntary';
		number: '5';
	}

	export interface EmployeeTurnoverReasonCount {
		contract_id: number;
		is_involuntary: boolean;
		is_system: boolean;
		is_uncontrolled: boolean;
		is_unknown: boolean;
		is_voluntary: boolean;
		termination_code: string;
		termination_reason: string;
		total_count: number;
	}

	export interface EmployeeTurnoverReasonCountsSummary
		extends SummaryChart<EmployeeTurnoverReasonCount[]> {
		chart: 'GetEmployeeTurnoverReasonCounts';
		number: '6';
	}

	export interface TurnoverByOrganization {
		avg_length_of_employment: null | number;
		customer_id: number;
		customer_name: string;
		goal: number;
		is_pass: boolean;
		terminated_employee_count: number;
		total_onborading: number;
		turnover_rate: number;
	}

	export interface TurnoverByOrganizationSummary
		extends SummaryChart<TurnoverByOrganization[]> {
		chart: 'GetTurnoverByOrganization';
		number: '7';
	}

	export interface TurnoverReasonByClient {
		customer_id: number;
		customer_name: string;
		total_count_for_org: number;
		total_turnover_percentage: number;
		reasons: {
			[reason: string]: number;
		};
	}

	export interface TurnoverReasonByClientSummary
		extends SummaryChart<{
			labels: string[];
			results: TurnoverReasonByClient[];
		}> {
		chart: 'GetTurnoverReasonByClient';
		number: '8';
	}

	export interface TurnoverReasonBySite {
		contract_id: number;
		customer_id: number;
		site_name: string;
		total_count_for_site: number;
		total_turnover_percentage: number;
		turnover_percentage_annual: number;
		reasons: {
			[reason: string]: number;
		};
	}

	export interface TurnoverReasonBySiteSummary
		extends SummaryChart<{
			labels: string[];
			results: TurnoverReasonBySite[];
		}> {
		chart: 'GetTurnoverReasonBySite';
		number: '9';
	}

	export interface SummaryReturnType {
		[Chart.GetAnnualizedTurnoverMonthlyTrend]: AnnualizedTurnoverMonthlyTrendSummary;
		[Chart.GetBusinessConductStandards]: BusinessConductStandardSummary;
		[Chart.GetTurnoverBySite]: TurnoverBySitesSummary;
		[Chart.GetTurnoverCounts]: TurnoverCountsSummary;
		[Chart.GetEmployeeTurnoverReasons]: EmployeeTurnoverReasonsSummary;
		[Chart.GetEmployeeTurnoverVoluntaryVsInvoluntary]: EmployeeTurnoverVoluntaryVsInvoluntarySummary;
		[Chart.GetEmployeeTurnoverReasonCounts]: EmployeeTurnoverReasonCountsSummary;
		[Chart.GetTurnoverByOrganization]: TurnoverByOrganizationSummary;
		[Chart.GetTurnoverReasonByClient]: TurnoverReasonByClientSummary;
		[Chart.GetTurnoverReasonBySite]: TurnoverReasonBySiteSummary;
	}
}
