import * as React from 'react';

import './ModalDetail.scss';

interface Common {
    children: React.ReactNode;
    id?: string;
    className?: string;
    withoutTruncate?: boolean;
}

interface Text {
    text: string;
    className?: string;
}

export type ModalLayout = 'default' | 'large-body' | 'wide';

/**
 *
 * @deprecated - do no use, replace with flex box or css grids
 */
export const DetailsModalWrapper: React.SFC<
    {
        layout?: ModalLayout;
    } & Common
> = (props) => (
    <section
        id={props.id}
        className={`details-modal-wrapper${
            props.className ? ` ${props.className}` : ''
        }${props.layout ? ` ${props.layout}` : ' default'}`}
    >
        {props.children}
    </section>
);

/**
 *
 * @deprecated - do no use, replace with flex box or css grids
 */
export const LeftSection: React.SFC<Common> = (props) => (
    <section
        id={props.id}
        className={`details-modal-left${
            props.className ? ` ${props.className}` : ''
        }`}
    >
        {props.children}
    </section>
);

/**
 *
 * @deprecated - do no use, replace with flex box or css grids
 */
export const RightSection: React.SFC<Common> = (props) => (
    <section
        id={props.id}
        className={`details-modal-right${
            props.className ? ` ${props.className}` : ''
        }`}
    >
        {props.children}
    </section>
);

/**
 *
 * @deprecated - do no use, replace with flex box or css grids
 */
export const BottomSection: React.SFC<Common> = (props) => (
    <div
        id={props.id}
        className={`details-modal-bottom tw-border-t tw-border-solid${
            props.className ? ` ${props.className}` : ''
        }`}
        style={{ borderColor: 'var(--well-background-color)' }}
    >
        {props.children}
    </div>
);

/**
 *
 * @deprecated - use import { FormGroup } from '@atoms' instead
 */
export const ModalControl: React.SFC<Common> = (props) => (
    <div
        id={props.id}
        className={`details-modal-control${
            props.className ? ` ${props.className}` : ''
        }`}
    >
        {props.children}
    </div>
);

/**
 *
 * @deprecated - use <h1> tag instead
 */
export const ModalTitle: React.SFC<Common> = (props) => (
    <h1
        id={props.id}
        className={`${
            props.className ? ` ${props.className}` : ''
        } tw-text-neutral-900-100 t-text-header`}
    >
        {props.children}
    </h1>
);

/**
 *
 * @deprecated - use heading tag instead
 */
export const ModalHeading: React.SFC<Text> = (props) => (
    <div
        className={`tw-text-neutral-900-100 tw-text-sm tw-font-semibold tw-mb-2${
            props.className ? ` ${props.className}` : ''
        }`}
    >
        {props.text}
    </div>
);

/**
 *
 * @deprecated - use heading tag instead
 */
export const ModalSubHeading: React.SFC<Text> = (props) => (
    <div
        className={`details-modal-subheading tw-text-secondary-100${
            props.className ? ` ${props.className}` : ''
        }`}
    >
        {props.text}
    </div>
);

/**
 *
 * @deprecated - use p tag instead
 */
export const ModalContent: React.SFC<Common> = (props) => (
    <div
        className={`details-modal-content tw-max-w-[664px] tw-break-words tw-text-neutral-700 dark:tw-text-neutral-400 t-text-medium${
            props.className ? ` ${props.className}` : ''
        }`}
    >
        {props.children}
    </div>
);

/**
 *
 * @deprecated - use p tag with tw-text-{size} instead
 */
export const ModalContentSmall: React.SFC<Common> = (props) => (
    <div
        className={
            (props.className ? ` ${props.className}` : '') +
            (props.withoutTruncate ? '' : 'truncate')
        }
    >
        {props.children}
    </div>
);

/**
 *
 * @deprecated - use heading tag instead
 */
export const ModalLabel: React.SFC<Common> = (props) => (
    <div
        className={`details-modal-label tw-text-secondary-100${
            props.className ? ` ${props.className}` : ''
        }`}
    >
        {props.children}
    </div>
);

/**
 *
 * @deprecated - use a table element instead
 */
export const ModalTableKeyValue: React.SFC<Common> = (props) => (
    <div className="details-modal-table-key-value">{props.children}</div>
);
