// services
import { has } from 'lodash';
import { Observable } from 'rxjs';

import {
	Attestation,
	AttestationResponsePayload,
	AttestationChart,
	AttestationSummaries,
	AttestationOrdering,
} from '@api-interfaces';
import { Params, TimeUtils } from '@core/helpers';
import {
	virtualBrownClient,
	IDjangoResponse,
} from '@core/services/django-client';

import { attestationsSummaryService } from './summary';

// helpers

// interfaces

// constants
const BASE_URL = 'attestations';

const zone = Intl.DateTimeFormat().resolvedOptions().timeZone;

class AttestationService {
	readonly summary = attestationsSummaryService;

	// add filtering when backend ready
	getAttestations = (params: {
		from?;
		to?;
		limit: number;
		offset?: number;
		search?: string;
		ordering?: AttestationOrdering;
		is_closed?: boolean | string;
		question?: string;
		boolean_value?: boolean; // false for negative attestations
		result?: boolean;
		customer?: string | number;
		contract?: string | number;
		tag?: string | number;
		unique_users?: boolean;
		status?: 'pending_review';
	}) => {
		const query = params;

		if (has(params, 'is_closed') && params.is_closed != null) {
			query.is_closed = params.is_closed;
		}

		const qs = Params.makeQueryString(query);
		return virtualBrownClient.get<IDjangoResponse<Attestation>>(
			`attestations/${qs}`,
			{
				noContract: !!params?.contract?.toString(),
				noCustomer: !!params?.customer?.toString(),
				noTag: !!params?.tag?.toString(),
			}
		);
	};

	createResponse = (response: AttestationResponsePayload) => {
		return virtualBrownClient.post(`attestations/responses/`, response);
	};

	getAttestationById = (attestationId: number): Observable<Attestation> => {
		return virtualBrownClient.get(`attestations/${attestationId}/`);
	};

	public getAttestationSummary = (params?: {
		from?: string;
		to?: string;
		time_zone?: string;
		chart?: string | AttestationChart;
		customer?: number;
		provider?: number;
		tag_group?: number;
		contract?: number;
		unique_users?: boolean;
	}): Observable<AttestationSummaries> => {
		let query;
		if (params.from && params.to) {
			query = {
				...params,
				from: TimeUtils.formatToISO(params.from),
				to: TimeUtils.formatToISO(params.to),
				time_zone: zone,
			};
		}

		const queryString = Params.makeQueryString(query);
		return virtualBrownClient.get(`${BASE_URL}/summary/${queryString}`);
	};
}

export const attestationService = new AttestationService();
