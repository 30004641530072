import { forwardRef } from 'react';

// components
import { ErrorText } from '../error-text';
import { InputFile } from '../input-file';
import { Label } from '../label';

// interfaces
import { FieldInputFileProps } from './field-input-file.interfaces';

export const FieldInputFile = forwardRef<HTMLInputElement, FieldInputFileProps>(
  ({ id, label, labelFont, buttonLabel, error, required, ...props }, ref) => {
    return (
      <fieldset data-ds2="field-input-file" className="tw-space-y-1.5">
        <Label
          htmlFor={id}
          label={label}
          font={labelFont}
          required={required}
        />
        <InputFile
          ref={ref}
          id={id}
          label={buttonLabel}
          capture="camera"
          {...props}
        />
        <ErrorText error={error} />
      </fieldset>
    );
  }
);

FieldInputFile.defaultProps = {
  labelFont: 'body-sm',
};
