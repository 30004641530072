/** ************************************************
 * Imports
 ************************************************* */

import { Icon } from '@atoms';

/** ************************************************
 * Interface
 ************************************************* */

import { CardIconProps } from './CardIcon.interfaces';

import './CardIcon.scss';

/** ************************************************
 * Render Component
 ************************************************* */

export const CardIcon = ({
  className = '',
  icon,
  disabled = false,
  ...props
}: CardIconProps) => {
  const backgroundColor = disabled
    ? 'tw-bg-neutral-500 dark:tw-bg-neutral-600'
    : 'tw-bg-primary-500 dark:tw-bg-primary-300';
  const iconColor = disabled
    ? 'tw-text-neutral-500 dark:tw-text-neutral-600'
    : 'tw-text-primary-500 dark:tw-text-primary-300';

  return (
    <div className={`_new-cards-contents-icon ${className}`} {...props}>
      <div
        className={`icon-bg ${backgroundColor}`}
        style={{ width: '64px', height: '64px' }}
      />
      <div className="icon">
        <Icon icon={icon} className={iconColor} style={{ fontSize: '24px' }} />
      </div>
    </div>
  );
};
