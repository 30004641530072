import * as React from 'react';

// components
import { Text } from '@atoms';

import './bottom-sheet.details.scss';

export const BottomSheetDetails = ({
  className,
  children,
  title,
  ...props
}: React.PropsWithChildren<{
  id?: string;
  className?: string;
  title?: string;
}>) => {
  return (
    <div
      className={`bottom-sheet-details tw-sticky tw-w-full tw-top-0 tw-overflow-y-auto md:tw-w-[70%] lg:tw-w-2/5 ${className}`}
    >
      {title && (
        <Text font="h3" color="hi-contrast" className="tw-mb-4">
          {title}
        </Text>
      )}
      <div className="tw-space-y-6" {...props}>
        {children}
      </div>
    </div>
  );
};

BottomSheetDetails.defaultProps = {
  className: '',
};
