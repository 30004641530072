// interfaces
import { virtualBrownClient } from '@core/services/django-client';
import { Params } from '@helpers';
import { PaginationParams, CommonParams, PaginationResponse } from '@models';

// services

import { GetTrackedRoutesParams, TrackerResponse } from './interfaces';

// constants
const BASE_URL = 'routes/tracker';

class RoutesTrackerService {
	public getTrackedRoutes(
		params?: GetTrackedRoutesParams & PaginationParams & CommonParams
	) {
		const queryString = Params.makeQueryString(params);

		return virtualBrownClient.get<PaginationResponse<TrackerResponse>>(
			`${BASE_URL}/${queryString}`,
			{
				noContract: params?.contract != undefined,
				noCustomer: params?.customer != undefined,
			}
		);
	}
}

export const routesTrackerService = new RoutesTrackerService();
