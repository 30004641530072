import { ServiceStopLogModal } from '@modals/_ds2';

// helpers
import { useAuditModalContext } from '@modals/_ds2/audit/_context/context';

export const AuditServiceLog = () => {
  const {
    state: { modals, selectedService, inspection },
    dispatch,
  } = useAuditModalContext();

  const handleClose = () => {
    dispatch({
      type: 'SET_MODALS',
      payload: {
        ...modals,
        'service-log': false,
      },
    });
  };

  return (
    modals['service-log'] && (
      <ServiceStopLogModal
        stopId={selectedService.stopId}
        date={selectedService.date}
        onClose={handleClose}
        customer={inspection.contract.customer.id}
        contract={inspection.contract.id}
      />
    )
  );
};
