import { useState } from 'react';

// interfaces
import {
  Todo,
  WorkOrderStatusKey,
  WorkOrderDueStatusKey,
} from '@api-interfaces';

// components
import { useAuditModalContext } from '@app/modals/_ds2/audit/_context/context';
import { ModalStack, Text } from '@atoms';
import { TimeUtils } from '@helpers';
import { StatusBadge } from '@modals/_ds2/work-orders/_components/status-badge';
import { TodoModal } from '@modals/detail-modals';
import { Button, MiniProfile } from '@new';

// modals

// helpers

const limit = 10;

interface Props {
  todos: Todo[];
}

export const ToDos = ({ todos }: Props) => {
  const {
    state: { modals },
    dispatch,
  } = useAuditModalContext();
  const [displayCount, setDisplayCount] = useState<number>(limit);
  const [viewTodo, setViewTodo] = useState<Todo>(null);
  const displayTodos = todos?.slice(0, displayCount) ?? [];

  const handleTodoClick = (todo: Todo) => {
    setViewTodo(todo);
    dispatch({
      type: 'SET_MODALS',
      payload: {
        ...modals,
        todo: true,
      },
    });
  };

  const handleTodoClose = () => {
    setViewTodo(null);
    dispatch({
      type: 'SET_MODALS',
      payload: {
        ...modals,
        todo: false,
      },
    });
  };

  return (
    <>
      <div>
        <Text font="h3" color="hi-contrast" className="tw-mb-4">
          To-Dos
        </Text>

        {todos?.length ? (
          <div className="form-common-scroll tw-overflow-x-scroll tw-whitespace-nowrap">
            <table className="tw-w-full">
              <thead>
                <tr>
                  {[
                    '#',
                    'Description',
                    'Service Provider',
                    'Assigned To',
                    'Due Date',
                    'Status',
                  ].map((header) => (
                    <th
                      key={header}
                      className="tw-text-left tw-text-sm tw-text-theme-neutral-600-500 tw-font-medium tw-pb-4 tw-pr-4 tw-border-b-2 tw-border-solid tw-border-neutral-300 dark:tw-border-neutral-600 tw-leading-normal"
                    >
                      {header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {displayTodos?.map((todo, i) => {
                  const {
                    id,
                    comment,
                    contract,
                    assigned_to,
                    due_date,
                    closed_date,
                    is_complete,
                  } = todo;
                  const currentDate = TimeUtils.formatToISO(new Date());
                  const isPastDue =
                    (!is_complete &&
                      TimeUtils.isBefore(due_date, currentDate)) ||
                    (is_complete && TimeUtils.isBefore(due_date, closed_date));
                  const isLastRow = i === todos.length - 1;

                  return (
                    <tr
                      key={i}
                      className={`tw-h-[54px] tw-text-sm tw-text-neutral-900-100
                                                tw-cursor-pointer hover:tw-bg-neutral-500 hover:tw-bg-opacity-15
                                                ${
                                                  !isLastRow
                                                    ? 'tw-border-solid tw-border-b-2 tw-border-neutral-300 dark:tw-border-neutral-600'
                                                    : ''
                                                }`}
                      onClick={() => handleTodoClick(todo)}
                    >
                      <td className="tw-pr-4">{id}</td>
                      <td className="tw-pr-4">
                        <div className="tw-truncate tw-max-w-[250px]">
                          {comment}
                        </div>
                      </td>
                      <td className="tw-pr-4">
                        {contract?.service_provider?.name}
                      </td>
                      <td className="tw-pr-4">
                        <MiniProfile.Sm
                          person={assigned_to?.person}
                          className="tw-w-[150px]"
                        />
                      </td>
                      <td className="tw-pr-4">
                        {TimeUtils.format(due_date, 'M/D/YYYY')}
                      </td>
                      <td className="tw-whitespace-nowrap">
                        <StatusBadge
                          status={
                            is_complete
                              ? WorkOrderStatusKey.CLOSED
                              : WorkOrderDueStatusKey.OPEN
                          }
                          isPastDue={isPastDue}
                          showToolTip
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {displayCount < todos?.length && (
              <Button
                color="alternate"
                label="View More"
                onClick={() => setDisplayCount(displayCount + limit)}
                className="tw-my-2 tw-w-full tw-justify-center"
              />
            )}
          </div>
        ) : (
          <div className="tw-w-full">
            <Text font="body-md" color="neutral-offset">
              None
            </Text>
          </div>
        )}
      </div>

      {viewTodo && (
        <ModalStack position="center" onOverlayClick={handleTodoClose}>
          <TodoModal todo={viewTodo} />
        </ModalStack>
      )}
    </>
  );
};
