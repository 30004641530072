import { debounce } from 'lodash';
import { useEffect, useState, useRef, useCallback } from 'react';
import * as React from 'react';
import { createPortal } from 'react-dom';

// interfaces
import { DotScrollerProps, Dot } from './dot-scroller.interfaces';

// helpers

// consts
const body = document.querySelector('body');
const refreshRate = 100; // milliseconds
const incrementPercent = 0.05; // decimal percent

export const DotScroller = ({ dots }: DotScrollerProps) => {
  const [active, setActive] = useState(null);
  const [container, setContainer] = useState<HTMLDivElement>(null);
  const intersections = useRef<{ [key: number]: IntersectionObserver }>({});
  const viewRatios = useRef<{ [key: string]: number }>({});

  // Set container from main
  useEffect(() => {
    const mainDotScrollerContainer = document.querySelector(
      '#main-dot-scroller'
    ) as HTMLDivElement;
    setContainer(mainDotScrollerContainer);
  }, []);

  // Once component is set, detect when sections are in viewport
  const dotScrollerRef = useCallback((node) => {
    if (node) {
      dots.forEach((dot, i) => {
        intersections.current[i] = new IntersectionObserver(
          intersectionCallback,
          {
            threshold: Array(1 / incrementPercent)
              .fill(0)
              .map((_x, i) => i * incrementPercent),
          }
        );
        const view = document.querySelector(dot.anchorTag);
        if (node) intersections.current[i].observe(view);
      });

      return () =>
        Object.values(intersections.current).forEach((intersection) =>
          intersection.disconnect()
        );
    }
  }, []);

  // Update active by largest in view percentage
  const onViewRatioChange = () => {
    const maxEntry: [string, number] = Object.entries(
      viewRatios.current
    ).reduce((a, b) => (a[1] > b[1] ? a : b));
    const maxIndex = dots.findIndex(
      (dot) => dot.anchorTag === `#${maxEntry[0]}`
    );
    if (active !== maxIndex) setActive(maxIndex);
  };

  const debouncedOnViewRatioChange = debounce(onViewRatioChange, refreshRate);

  // Using intersection to update view percentage
  const intersectionCallback: IntersectionObserverCallback = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting && entry.target?.id) {
        viewRatios.current[entry.target.id] = entry.intersectionRatio;
        debouncedOnViewRatioChange();
      }
    });
  };

  // On dot click scroll to anchor tag
  function handleDotClick(anchorTag: Dot['anchorTag']) {
    return (e: React.MouseEvent<HTMLDivElement>) => {
      e.preventDefault();
      if (body) {
        const section = body.querySelector(anchorTag) as HTMLElement;
        if (section) {
          window.scrollTo({
            top: section.offsetTop,
            behavior: 'smooth',
          });
        }
      }
    };
  }

  // Component
  const DotScroller = (
    <div
      ref={dotScrollerRef}
      className="cm-dot-scroller tw-h-full tw-w-full tw-flex tw-flex-col tw-justify-center tw-items-center"
    >
      <div className="tw-flex tw-flex-col tw-space-y-4">
        {dots.map((dot, i) => (
          <div
            key={i}
            className={`tw-h-2 tw-w-2 tw-rounded-full tw-cursor-pointer ${
              active === i
                ? 'tw-bg-theme-primary-500-300'
                : 'tw-bg-theme-neutral-400-600'
            }`}
            onClick={handleDotClick(dot.anchorTag)}
          />
        ))}
      </div>
    </div>
  );

  if (container) {
    return createPortal(DotScroller, container);
  }
  return null;
};
