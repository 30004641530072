import { SummaryChart } from '@models';

type TimeScale = 'month' | 'day';

export interface ComplaintsSummaryParams<T> {
	chart: T[];
	from?: Date;
	to?: Date;
	time_zone?: string;
	provider?: number;
	chart_type?: string | number;
	customer?: string | number;
	contract?: string | number;
	tag?: string | number;
	service?: string | number;
	servicearea?: number;
	area_type?: number;
	top_n_threshold_to_other?: number;
	tag_group?: string | number;
	root_area_ids?: string; // comma separated string
	verticals?: string | number;
}
export namespace ComplaintsSummary {
	export enum Chart {
		GetComplaintsCounts = 0,
		GetComplaintsTrends = 1,
		GetComplaintCountsBySite = 2,
		GetComplaintCountsByType = 3,
		GetComplaintCountsByMonthByType = 4,
		GetComplaintCountsByTag = 5,
		GetComplaintCountsByBuilding = 6,
		GetComplaintCountsByMonth = 7,
		GetComplaintCountsByService = 8,
		GetComplaintsCountsByOrganization = 9,
		GetComplaintsFrontPageSummary = 10,
		GetEscalationsFrontPageSummary = 11,
		GetComplaintCountsByMonthByService = 12,
		GetComplaintCountsBySiteByService = 13,
		GetComplaintCountsByBuildingByService = 14,
		GetComplaintCountsByVendor = 15,
		GetComplaintServiceTypesByBuilding = 16,
		GetComplaintsByAreaType = 17,
		GetComplaintsByAreaGroup = 18,
		GetComplaintsByMasterAreaType = 19,
		GetComplaintsByVerticals = 20,
		GetComplaintsByVerticalsOrganizationList = 21,
	}
	export interface ComplaintsCounts {
		open_count: number;
		closed_count: number;
		total_complaints: number;
		total_repeat_complaints: number;
		in_scope: number;
		out_of_scope: number;
		total_escalated: number;
		latest_complaint_date: string;
		latest_description: string;
		goal: number;
		is_pass: boolean;
		avg_per_month: number;
	}

	export interface ComplaintsCountsSummary
		extends SummaryChart<ComplaintsCounts> {
		chart: 'GetComplaintsCounts';
		number: '0';
	}

	export interface ComplaintsTrends {
		in_scope: number;
		mo: string;
		out_of_scope: number;
	}

	export interface ComplaintsTrendsSummary
		extends SummaryChart<ComplaintsTrends[]> {
		chart: 'GetComplaintsTrends';
		number: '1';
		time_scale: TimeScale;
	}

	export interface ComplaintCountsBySite {
		city: null | string;
		goal: number;
		has_tag_groups: boolean;
		id: number;
		in_scope: number;
		is_pass: boolean;
		lat: number;
		latest_complaint_date: string;
		latest_description: string;
		lon: number;
		name: string;
		organization_name: string;
		out_of_scope: number;
		postal_code: null | string;
		region: null | string;
		square_feet: number;
		street1: null | string;
		street2: null | string;
		total_complaints: number;
		total_repeat_complaints: number;
	}
	export interface ComplaintCountsBySiteSummary
		extends SummaryChart<ComplaintCountsBySite[]> {
		chart: 'GetComplaintCountsBySite';
		max_total_complaints: number;
	}

	export interface ComplaintCountsByType {
		count: number;
		name: string;
		percentage: string;
		type_id: number;
	}

	export interface ComplaintCountsByTypeSummary
		extends SummaryChart<ComplaintCountsByType[]> {
		chart: 'GetComplaintCountsByType';
		number: '3';
	}

	export interface ComplaintCountsByMonthByType {
		mo: string;
		total: number;
	}

	export interface ComplaintCountsByMonthByTypeSummary
		extends SummaryChart<ComplaintCountsByMonthByType[]> {
		chart: 'GetComplaintCountsByMonthByType';
		number: '4';
		time_scale: TimeScale;
	}

	export interface ComplaintCountsByTag {
		contract_id: number;
		id: number;
		is_pass: boolean;
		lat: number;
		latest_complaint_date: string;
		latest_description: string;
		lon: number;
		name: string;
		total_complaints: number;
		total_repeat_complaints: number;
		root_areas: {
			area_id: number;
			area_name: string;
		}[];
		service_types: {
			service_type_id?: number;
			service_type_code?: string;
			number_of_records?: number;
		}[];
	}

	export interface ComplaintCountsByTagSummary
		extends SummaryChart<ComplaintCountsByTag[]> {
		chart: 'GetComplaintCountsByTag';
		max_total_complaints: number;
	}

	export interface ComplaintCountsByBuilding {
		contract_id: number;
		id: number;
		in_scope: null | number;
		lat: number;
		latest_complaint_date: null | string;
		latest_description: null | string;
		lon: number;
		name: string;
		out_of_scope: null | number;
		total_complaints: null | number;
		total_repeat_complaints: null | number;
	}

	export interface ComplaintCountsByBuildingSummary
		extends SummaryChart<ComplaintCountsByBuilding[]> {
		chart: 'GetComplaintCountsByBuilding';
		max_total_complaints: number;
	}

	export interface ComplaintCountsByMonth {
		mo: string;
		total: number;
		total_is_escalated: number;
		total_is_repeat_complaint: number;
	}

	export interface ComplaintCountsByMonthSummary
		extends SummaryChart<ComplaintCountsByMonth[]> {
		chart: 'GetComplaintCountsByMonth';
		number: '7';
		time_scale: TimeScale;
	}

	export interface ComplaintCountsByService {
		id: number;
		count: number;
		name: string;
	}

	export interface ComplaintCountsByServiceSummary
		extends SummaryChart<ComplaintCountsByService[]> {
		chart: 'GetComplaintCountsByService';
		number: '8';
	}

	export interface ComplaintsCountsByOrganization {
		closed_count: number;
		customer_id: number;
		customer_name: string;
		in_scope: number;
		open_count: number;
		out_of_scope: number;
		total_complaints: number;
		total_escalated: number;
		total_repeat_complaints: number;
	}

	export interface ComplaintsCountsByOrganizationSummary
		extends SummaryChart<ComplaintsCountsByOrganization[]> {
		chart: 'GetComplaintsCountsByOrganization';
		number: '9';
	}

	export interface ComplaintsFrontPage {
		days_since: number;
		latest_date: string;
		latest_id: number;
		total_in_date_range: number;
	}

	export interface ComplaintsFrontPageSummary
		extends SummaryChart<ComplaintsFrontPage> {
		chart: 'GetComplaintsFrontPageSummary';
		number: '10';
	}

	export interface EscalationsFrontPage {
		days_since: number;
		latest_date: string;
		latest_id: number;
		total_in_date_range: number;
	}

	export interface EscalationsFrontPageSummary
		extends SummaryChart<EscalationsFrontPage[]> {
		chart: 'GetEscalationsFrontPageSummary';
		number: '11';
	}

	export interface ComplaintCountsByMonthByService {
		month: string;
		total_amounts: Array<{
			service_id: number;
			service_name: string;
			total_amount: number;
		}>;
	}

	export interface ComplaintCountsByMonthByServiceSummary
		extends SummaryChart<ComplaintCountsByMonthByService[]> {
		chart: 'GetComplaintCountsByMonthByService';
		number: '12';
		time_scale: TimeScale;
	}

	export interface ComplaintCountsBySiteByService {
		contract_name: string;
		id: number;
		complaint_count: Array<{
			complaint_count: number;
			service_id: number;
			service_name: string;
		}>;
	}

	export interface ComplaintCountsBySiteByServiceSummary
		extends SummaryChart<ComplaintCountsBySiteByService[]> {
		chart: 'GetComplaintCountsBySiteByService';
		number: '13';
		time_scale: TimeScale;
	}

	export interface ComplaintCountsByBuildingByService {
		id: number;
		complaint_count: Array<{
			complaint_count: number;
			root_id: number;
			root_name: string;
		}>;
	}

	export interface ComplaintCountsByBuildingByServiceSummary
		extends SummaryChart<ComplaintCountsByBuildingByService[]> {
		chart: 'GetComplaintCountsByBuildingByService';
		number: '14';
		time_scale: TimeScale;
	}

	export interface ComplaintCountsByVendor {
		id: number;
		name: string;
		count: number;
	}

	export interface ComplaintCountsByVendorSummary
		extends SummaryChart<ComplaintCountsByVendor[]> {
		chart: 'GetComplaintCountsByVendor';
		number: '15';
	}

	export interface ComplaintServiceTypesByBuildings {
		root_area_id: number;
		root_area_name: string;
		number_of_records?: number;
		service_types: {
			service_type_id?: number;
			service_type_code?: string;
			number_of_records?: number;
		}[];
	}

	export interface ComplaintServiceTypesByBuildingSummary
		extends SummaryChart<ComplaintServiceTypesByBuildings[]> {
		chart: 'GetComplaintServiceTypesByBuilding';
		number: '16';
	}

	export interface ComplaintCountsByAreaType {
		area_type_id: number;
		area_type_name: string;
		number_of_records?: number;
		service_types: {
			service_type_id?: number;
			service_type_code?: string;
			number_of_records?: number;
		}[];
	}

	export interface ComplaintsByAreaTypeSummary
		extends SummaryChart<ComplaintCountsByAreaType[]> {
		chart: 'GetComplaintsByAreaType';
		number: '17';
	}

	export interface ComplaintCountsByAreaGroup {
		contract_area_group_id: number;
		contract_area_group_name: string;
		number_of_records?: number;
		service_types: {
			service_type_id?: number;
			service_type_code?: string;
			number_of_records?: number;
		}[];
	}

	export interface ComplaintsByAreaGroupSummary
		extends SummaryChart<ComplaintCountsByAreaGroup[]> {
		chart: 'GetComplaintsByAreaGroup';
		number: '18';
	}

	export interface ComplaintCountsByMasterAreaType {
		master_area_type_id: number;
		master_area_type_name: string;
		number_of_records?: number;
		service_types: {
			service_type_id?: number;
			service_type_code?: string;
			number_of_records?: number;
		}[];
	}

	export interface ComplaintsByMasterAreaTypeSummary
		extends SummaryChart<ComplaintCountsByMasterAreaType[]> {
		chart: 'GetComplaintsByMasterAreaType';
		number: '17';
	}

	export interface ComplaintCountsByVerticals {
		id: number;
		count: number;
		name: string;
		in_scope: number;
		out_of_scope: number;
	}

	export interface ComplaintCountsByVerticalsSummary
		extends SummaryChart<ComplaintCountsByVerticals[]> {
		chart: 'GetComplaintCountsByService';
		number: '20';
	}
	export interface ComplaintCountsByVerticalsOrganizationList {
		id: number;
		count: number;
		name: string;
		in_scope: number;
		out_of_scope: number;
	}

	export interface ComplaintCountsByVerticalsOrganizationListSummary
		extends SummaryChart<ComplaintCountsByVerticalsOrganizationList[]> {
		chart: 'GetComplaintCountsByService';
		number: '21';
	}

	export type SummaryReturnType = {
		[Chart.GetComplaintsCounts]: ComplaintsCountsSummary;
		[Chart.GetComplaintsTrends]: ComplaintsTrendsSummary;
		[Chart.GetComplaintCountsBySite]: ComplaintCountsBySiteSummary;
		[Chart.GetComplaintCountsByType]: ComplaintCountsByTypeSummary;
		[Chart.GetComplaintCountsByMonthByType]: ComplaintCountsByMonthByTypeSummary;
		[Chart.GetComplaintCountsByTag]: ComplaintCountsByTagSummary;
		[Chart.GetComplaintCountsByBuilding]: ComplaintCountsByBuildingSummary;
		[Chart.GetComplaintCountsByMonth]: ComplaintCountsByMonthSummary;
		[Chart.GetComplaintCountsByService]: ComplaintCountsByServiceSummary;
		[Chart.GetComplaintsCountsByOrganization]: ComplaintsCountsByOrganizationSummary;
		[Chart.GetComplaintsFrontPageSummary]: ComplaintsFrontPageSummary;
		[Chart.GetEscalationsFrontPageSummary]: EscalationsFrontPageSummary;
		[Chart.GetComplaintCountsByMonthByService]: ComplaintCountsByMonthByServiceSummary;
		[Chart.GetComplaintCountsBySiteByService]: ComplaintCountsBySiteByServiceSummary;
		[Chart.GetComplaintCountsByBuildingByService]: ComplaintCountsByBuildingByServiceSummary;
		[Chart.GetComplaintCountsByVendor]: ComplaintCountsByVendorSummary;
		[Chart.GetComplaintServiceTypesByBuilding]: ComplaintServiceTypesByBuildingSummary;
		[Chart.GetComplaintsByAreaType]: ComplaintsByAreaTypeSummary;
		[Chart.GetComplaintsByAreaGroup]: ComplaintsByAreaGroupSummary;
		[Chart.GetComplaintsByMasterAreaType]: ComplaintsByMasterAreaTypeSummary;
		[Chart.GetComplaintsByVerticals]: ComplaintCountsByVerticalsSummary;
		[Chart.GetComplaintsByVerticalsOrganizationList]: ComplaintCountsByVerticalsOrganizationListSummary;
	};
}
