import { forwardRef } from 'react';

// components
import { ErrorText } from '../error-text';
import { InputDate } from '../input-date';
import { Label } from '../label';

// interfaces
import { FieldInputDateProps } from './field-input-date.interfaces';

export const FieldInputDate = forwardRef<HTMLInputElement, FieldInputDateProps>(
  ({ id, label, labelFont, error, required, ...props }, ref) => (
    <fieldset data-ds2="field-input-date" className="tw-w-full tw-space-y-1.5">
      <Label
        htmlFor={id}
        label={label}
        font={labelFont}
        className="tw-leading-4"
        required={required}
      />
      <InputDate id={id} ref={ref} error={!!error} {...props} />
      <ErrorText error={error} />
    </fieldset>
  )
);

FieldInputDate.defaultProps = {
  labelFont: 'body-sm',
};
