import { useState } from 'react';

// components
import { Text, ScoreChip, IconBadge, Icon } from '@atoms';
import { pluralize } from '@helpers';
import { useAuditModalContext } from '@modals/_ds2/audit/_context/context';
import { getScoreChipParams } from '@modals/_ds2/audit/helpers';
import { Button } from '@new';

// helpers

const limit = 10;

export const AreaTypesTable = () => {
    const {
        state: { mode, area_types },
        dispatch,
    } = useAuditModalContext();
    const [displayCount, setDisplayCount] = useState<number>(limit);
    const [scoreOrder, setScoreOrder] = useState<'ASC' | 'DESC'>('DESC');
    const orderedAreaTypes = area_types.sort((a, b) => {
        let scoreA;
        let scoreB;
        if (mode === 'INSPECTION') {
            scoreA = a?.inspection_score_calculated ?? 0;
            scoreB = b?.inspection_score_calculated ?? 0;
        } else {
            scoreA = a?.audit_score_calculated ?? 0;
            scoreB = b?.audit_score_calculated ?? 0;
        }
        return scoreOrder === 'ASC' ? scoreA - scoreB : scoreB - scoreA;
    });
    const displayAreaTypes = orderedAreaTypes.slice(0, displayCount);

    return (
        <>
            <table className="tw-w-full">
                <thead>
                    <tr>
                        {[
                            `${
                                area_types?.length > 0
                                    ? `${area_types.length} ${pluralize(
                                          'Area Type',
                                          'Area Types',
                                          area_types.length
                                      )}`
                                    : 'Area Types'
                            }`,
                            'Photos',
                            'Comments',
                            'To-Dos',
                            'Score',
                        ].map((header, i, self) => (
                            <th
                                key={i}
                                className={`tw-min-w-[100px] tw-pb-4 tw-border-b tw-border-solid tw-border-neutral-300 dark:tw-border-neutral-600 ${
                                    i === 0
                                        ? 'tw-pr-2'
                                        : i === self.length - 1
                                          ? 'tw-pl-2'
                                          : 'tw-px-2'
                                } ${
                                    [1, 2, 3, 4].includes(i)
                                        ? 'tw-text-center'
                                        : 'tw-text-left'
                                }`}
                            >
                                <Text
                                    font="h4"
                                    color="neutral-offset"
                                    className="tw-relative"
                                >
                                    {header}
                                    {i === self.length - 1 && (
                                        <Icon
                                            size="lg"
                                            icon={[
                                                'fas',
                                                scoreOrder === 'DESC'
                                                    ? 'caret-up'
                                                    : 'caret-down',
                                            ]}
                                            className="tw-absolute tw-cursor-pointer tw-ml-2 tw-text-theme-primary-500-300"
                                            onClick={() =>
                                                setScoreOrder(
                                                    scoreOrder === 'DESC'
                                                        ? 'ASC'
                                                        : 'DESC'
                                                )
                                            }
                                        />
                                    )}
                                </Text>
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {displayAreaTypes?.map((area_type, i) => {
                        const {
                            name,
                            total_photos_attached,
                            total_comments_attached,
                            total_todos_attached,
                            audit_score_calculated,
                            audit_couldnt_score,
                            audit_is_pass,
                            audit_scale,
                            goalline,
                            count,
                            inspection_score_calculated,
                        } = area_type;
                        const { type, score } = getScoreChipParams({
                            mode,
                            audit_score_calculated,
                            audit_couldnt_score,
                            audit_is_pass,
                            audit_scale,
                            goalline,
                            inspection_score_calculated,
                        });

                        return (
                            <tr
                                key={i}
                                className={`tw-text-sm tw-text-neutral-900-100 
                                    tw-border-solid tw-border-b tw-border-neutral-300 dark:tw-border-neutral-600 last:tw-border-none
                                    tw-cursor-pointer hover:tw-bg-neutral-500 hover:tw-bg-opacity-15`}
                                onClick={() =>
                                    dispatch({
                                        type: 'SET_SELECTED_AREA_TYPE',
                                        payload: area_type,
                                    })
                                }
                            >
                                <td className="tw-py-4 tw-pr-2">
                                    <div className="tw-flex tw-items-center tw-space-x-3">
                                        <IconBadge
                                            icon="vector-square"
                                            type="primary"
                                        />
                                        <div className="tw-flex tw-flex-col">
                                            <Text
                                                font="h3"
                                                color="hi-contrast"
                                                className="tw-truncate tw-max-w-[275px]"
                                            >
                                                {name}
                                            </Text>
                                            <Text
                                                font="body-md"
                                                color="neutral-offset"
                                                className="tw-truncate tw-max-w-[275px]"
                                            >
                                                {count} Areas
                                            </Text>
                                        </div>
                                    </div>
                                </td>
                                <td className="tw-py-4 tw-px-2 tw-text-center">
                                    {total_photos_attached > 0
                                        ? total_photos_attached
                                        : '-'}
                                </td>
                                <td className="tw-py-4 tw-px-2 tw-text-center">
                                    {total_comments_attached > 0
                                        ? total_comments_attached
                                        : '-'}
                                </td>
                                <td className="tw-py-4 tw-px-2 tw-text-center">
                                    {total_todos_attached > 0
                                        ? total_todos_attached
                                        : '-'}
                                </td>
                                <td className="tw-py-4 tw-pl-2 tw-text-center">
                                    <ScoreChip
                                        size="sm"
                                        type={type}
                                        className="tw-inline-flex"
                                        score={score}
                                    />
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            {displayCount < area_types?.length && (
                <Button
                    color="alternate"
                    label="View More"
                    onClick={() => setDisplayCount(displayCount + limit)}
                    className="tw-my-2 tw-w-full tw-justify-center"
                />
            )}
        </>
    );
};
