import { useMemo } from 'react';

// helpers
import { TextProps } from './text.interfaces';
import { textFontMap, textColorMap } from './text.utils';

// interfaces

export const Text = ({
    font,
    className,
    tag,
    style,
    color,
    ...props
}: TextProps) => {
    const text = useMemo(() => {
        const textColor = textColorMap[color];
        const attr = textFontMap[font];
        switch (font) {
            case 'giant':
                return (
                    <h1
                        data-ds2={font}
                        className={`${attr.className} ${textColor} ${className}`}
                        style={{ ...attr.style, ...style }}
                        {...props}
                    />
                );
            case 'data-point':
            case 'hero':
            case 'h1':
                return (
                    <h1
                        data-ds2={font}
                        className={`${attr.className} ${textColor} ${className}`}
                        style={{ ...attr.style, ...style }}
                        {...props}
                    />
                );
            case 'h2':
                return (
                    <h2
                        data-ds2={font}
                        className={`${attr.className} ${textColor} ${className}`}
                        style={{ ...attr.style, ...style }}
                        {...props}
                    />
                );
            case 'h3':
            case 'status':
                return (
                    <h3
                        data-ds2={font}
                        className={`${attr.className} ${textColor} ${className}`}
                        style={{ ...attr.style, ...style }}
                        {...props}
                    />
                );
            case 'h4':
                return (
                    <h4
                        data-ds2={font}
                        className={`${attr.className} ${textColor} ${className}`}
                        style={{ ...attr.style, ...style }}
                        {...props}
                    />
                );
            case 'body-sm':
            case 'body-md':
            case 'body-lg':
            case 'body-italic':
                if (tag === 'span') {
                    return (
                        <span
                            data-ds2={font}
                            className={`${attr.className} ${textColor} ${className}`}
                            style={{ ...attr.style, ...style }}
                            {...props}
                        />
                    );
                }
                return (
                    <p
                        data-ds2={font}
                        className={`${attr.className} ${textColor} ${className}`}
                        style={{ ...attr.style, ...style }}
                        {...props}
                    />
                );
            default:
                throw Error(`No such type: ${font}`);
        }
    }, [font, className, style, tag, color, props]);
    return text;
};

Text.defaultProps = {
    className: '',
};
