import {
	Contract,
	Media,
	Employee,
	Person,
	ReferenceItem,
	Todo,
	TodoPayload,
	Complaint,
	ServiceArea,
} from '@api-interfaces';
import { titleCase } from '@core/helpers';
import { KeyValue } from '@core/models';

// These must match the keys on the backend
export enum WorkOrderDueStatusKey {
	OPEN = 'OPEN',
	OPEN_PAST_DUE = 'OPEN_PAST_DUE',
	OPEN_WORK_COMPLETE = 'OPEN_WORK_COMPLETE',
	OPEN_WORK_COMPLETE_PAST_DUE = 'OPEN_WORK_COMPLETE_PAST_DUE',
	CLOSED = 'CLOSED',
	CLOSED_PAST_DUE = 'CLOSED_PAST_DUE',
	IN_PROGRESS = 'IN_PROGRESS',
	WORK_COMPLETE = 'WORK_COMPLETE',
	NEW = 'NEW',
	REJECTED = 'REJECTED',
}

export enum WorkOrderStatusKey {
	DRAFT = 'DRAFT',
	SUBMITTED = 'SUBMITTED',
	PENDING = 'PENDING',
	ASSIGNED = 'ASSIGNED',
	WORK_COMPLETE = 'WORK_COMPLETE',
	CLOSED = 'CLOSED',
	REJECTED = 'REJECTED',
	BILLED = 'BILLED',
	PAST_DUE = 'PAST_DUE',
	IS_PROJECT_WORK = 'IS_PROJECT_WORK',
}

export enum WorkOrderStatusFilterKey {
	DRAFT = 'DRAFT',
	SUBMITTED = 'SUBMITTED',
	PENDING = 'PENDING',
	ASSIGNED = 'ASSIGNED',
	WORK_COMPLETE = 'WORK_COMPLETE',
	CLOSED = 'CLOSED',
	REJECTED = 'REJECTED',
	BILLED = 'BILLED',
	PAST_DUE = 'PAST_DUE',
}

// more tags are coming
export enum WorkOrderTagsFilterKey {
	IS_PROJECT_WORK = 'IS_PROJECT_WORK',
}

export type WorkOrderStatus =
	(typeof WorkOrderStatusKey)[keyof typeof WorkOrderStatusKey];

export type WorkOrderDueStatus =
	(typeof WorkOrderDueStatusKey)[keyof typeof WorkOrderDueStatusKey];

export type WorkOrderStatusFilter =
	(typeof WorkOrderStatusFilterKey)[keyof typeof WorkOrderStatusFilterKey];

type WorkOrderStatusString = WorkOrderStatusKey | WorkOrderStatus | string;
export const getWorkOrderStatusDisplayName = (key: WorkOrderStatusString) => {
	return titleCase(key?.replace(/_/g, ' ')) || 'N/A';
};
export const WorkOrderStatusKeyValue: Array<KeyValue> = Object.keys(
	WorkOrderStatusKey
).map((key: WorkOrderStatusKey) => ({
	Key: key,
	Value: getWorkOrderStatusDisplayName(key),
}));

type WorkOrderDueStatusString =
	| WorkOrderDueStatusKey
	| WorkOrderDueStatus
	| string;
export const getWorkOrderDueStatusDisplayName = (
	key: WorkOrderDueStatusString
) => {
	return titleCase(key?.replace(/_/g, ' ')) || 'N/A';
};
export const WorkOrderDueStatusKeyValue: Array<KeyValue> = Object.keys(
	WorkOrderDueStatusKey
).map((key: WorkOrderDueStatusKey) => ({
	Key: key,
	Value: getWorkOrderDueStatusDisplayName(key),
}));

export type WorkOrderWorkType = 'Materials' | 'Service' | 'Subcontractor';
export const WORK_ORDER_WORK_TYPES = ['Materials', 'Service', 'Subcontractor'];

type Service = {
	id: number;
	name?: string;
	correlation_id?: number;
	is_standard?: boolean;
};

export type CostLineItem = {
	id: number;
	cost: number;
	unitcost: number;
	quantity: number;
	work_type: WorkOrderWorkType;
	service: Service;
	billing_description: string;
	workorder_id: number;
};

export interface WorkOrder {
	assigned_date?: string;
	assigned_to?: Employee;
	billing_description_orig?: string;
	billing_description?: string;
	work_complete_date?: string;
	close_date?: string;
	complaints: Complaint[];
	contact_number_country_code?: string;
	contact_number?: string;
	contract?: Contract;
	cost?: number;
	created_by?: Employee;
	create_date?: string;
	update_date?: string;
	description_orig?: string;
	description?: string;
	due_date?: string;
	equipment_orig?: string;
	equipment?: string;
	hash_t?: string;
	id: number;
	invoice_number?: string;
	external_work_order_id?: string;
	is_allocate_cost?: boolean; // use is_billable instead
	is_billable?: boolean;
	is_in_scope?: boolean;
	is_production_effected?: boolean;
	location_name_orig?: string;
	location_name?: string;
	location?: ServiceArea;
	orig_language?: string;
	po_number?: string;
	po_number_id: number;
	priority?: ReferenceItem;
	quantity?: number;
	rejection_other?: string;
	rejection_type?: ReferenceItem;
	reporter?: Employee;
	request_due_date?: string;
	resolution_orig?: string;
	resolution?: string;
	room_number?: string;
	service?: Service;
	service_provider?: {
		correlation_id: string;
		id: number;
		name: string;
		type: string;
		uuid: string;
	};
	service_type?: ReferenceItem;
	status?: ReferenceItem;
	summary_orig?: string;
	summary?: string;
	title?: string;
	todos?: Todo[];
	unitcost?: number;
	is_past_due?: boolean;
	due_status?: WorkOrderDueStatus;
	comment_count: number;
	media_list: (Media & {
		workorder_media_type: string;
		is_after_work_order: boolean;
	})[];
	bu_name?: string;
	net_suite_id: number;
	work_order_status?: WorkOrderStatus;
	work_type: WorkOrderWorkType;
	cost_line_items?: CostLineItem[];
	customer_work_order?: string;
	export_date?: string;
	retrieve_from_vendor: boolean;
	total_line_item_cost: number;
	is_project_work?: boolean;
	source: string;
}

export interface WorkOrderPayload {
	assigned_date: string | Date;
	assigned_to?: {
		id: number;
	};
	billing_description: string;
	work_complete_date?: string | Date;
	close_date?: string | Date;
	contract: {
		id: number;
	};
	contact_number_country_code: string;
	contact_number: string;
	unitcost?: number;
	quantity?: number;
	title?: string;
	description: string;
	media?: (Media & {
		workordermedia_properties?: [{ is_after_work_order: boolean }];
	})[];
	due_date: string | Date;
	equipment?: string;
	is_allocate_cost?: boolean; // use is_billable instead
	is_billable: boolean;
	is_in_scope: boolean;
	is_production_effected: boolean;
	location: {
		id: number;
	};
	location_name?: string;
	po_number?: string;
	po_number_id?: number;
	reporter: {
		id: number;
	};
	created_by: {
		id: number;
	};
	priority: {
		id: number;
	};
	resolution?: string;
	request_due_date?: Date;
	room_number?: string;
	service?: {
		id: number;
	};
	service_type: {
		id: number;
	};
	status: {
		id: number;
	};
	bu_name?: string;
	net_suite?: {
		id: number;
	};
	work_type?: WorkOrderWorkType;
	work_order_status: WorkOrderStatus;
	summary: string;
	todos?: TodoPayload[];
	rejection_type?: {
		id: number;
	};
	rejection_type_id?: number;
	rejection_other?: string;
	cost_line_items?: CostLineItem[];
	customer_work_order?: string;
	is_project_work?: boolean;
	source?: string;
}

export interface WorkOrderCostLineItemPayload {
	cost: number;
	unitcost: number;
	quantity: number;
	work_type: WorkOrderWorkType;
	service: {
		id: number;
	};
	billing_description: string;
	work_order: {
		id: number;
	};
}

export interface EmployeeWorkOrder {
	assigned_to_employee_id: number;
	is_active: boolean;
	submitted_count: number;
	past_due_count: number;
	on_time_completion_count: number;
	contract?: Object;
	location?: Object;
	person: Person;
	on_time_percentage: number;
}

export type WorkOrderOrderingParams =
	| 'created_by'
	| '-created_by'
	| 'reporter'
	| '-reporter'
	| 'assigned_to'
	| '-assigned_to'
	| 'is_billable'
	| '-is_billable'
	| 'is_in_scope'
	| '-is_in_scope'
	| 'create_date'
	| '-create_date'
	| 'assigned_date'
	| '-assigned_date'
	| 'due_date'
	| '-due_date'
	| 'work_complete_date'
	| '-work_complete_date'
	| 'close_date'
	| '-close_date'
	| 'due_status'
	| '-due_status'
	| 'id'
	| '-id'
	| 'invoice_number'
	| '-invoice_number'
	| 'bu_name'
	| '-bu_name'
	| 'title'
	| '-title'
	| 'total_line_item_cost'
	| '-total_line_item_cost'
	| 'is_project_work'
	| '-is_project_work'
	| 'source'
	| '-source';
