import { getWorkOrderStatusDisplayName, WorkOrderStatusKey } from '@api-interfaces';
import { Badge, Tooltip, Box, Text } from '@atoms';

// helpers;
import {
  workOrderStatusDescription,
  workOrderStatusBadgeColors,
} from '@helpers';

// services

// interfaces
import { StatusBadgeProps } from './status-badge.interfaces';

export const StatusBadge = ({
  status,
  isPastDue,
  className,
  tooltipPosition = 'bottom',
  showToolTip = false,
}: StatusBadgeProps) => (
  <div className={className}>
    <Tooltip
      position={tooltipPosition}
      className="tw-z-0 hover:tw-z-[1]"
      render={
        showToolTip ? (
          <Box
            rounded
            className="tw-py-2 tw-px-4 tw-shadow-lg tw-mb-3 tw-min-w-[271px] tw-mt-1"
          >
            <Text font="body-md" color="hi-contrast">
              {workOrderStatusDescription[status]}
            </Text>
          </Box>
        ) : null
      }
    >
      <Badge
        label={getWorkOrderStatusDisplayName(status)}
        color={workOrderStatusBadgeColors[status]}
        className="tw-whitespace-nowrap"
      />
    </Tooltip>
    {isPastDue && (
      <Tooltip
        position={tooltipPosition}
        className="tw-z-0 hover:tw-z-[1]"
        render={
          showToolTip ? (
            <Box
              rounded
              className="tw-py-2 tw-px-4 tw-shadow-lg tw-mb-3 tw-min-w-[271px] tw-mt-1"
            >
              <Text font="body-md" color="hi-contrast">
                {workOrderStatusDescription[WorkOrderStatusKey.PAST_DUE]}
              </Text>
            </Box>
          ) : null
        }
      >
        <Badge
          label="Past Due"
          color="danger"
          className="tw-whitespace-nowrap"
        />
      </Tooltip>
    )}
  </div>
);
