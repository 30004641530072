import { compressToEncodedURIComponent } from 'lz-string';

export class Params {
	public static makeQueryString(params = {}, compressed = true): string {
		const paramString = Object.entries(params).reduce(
			(queryString, [key, value]) => {
				let val = value;
				if (val instanceof Date) {
					val = val.toISOString();
				}
				if (key === 'contract' && compressed) {
					val = this.compressValue(val);
				}
				return `${queryString}${key}=${encodeURIComponent(
					decodeURIComponent(val as string)
				)}&`;
			},
			''
		);

		return `?${paramString.slice(0, paramString.length - 1)}`;
	} // usage: get(url + makeQueryString(params))

	private static compressValue(val: any): string {
		if (Array.isArray(val)) {
			val = val.join(',');
		} else if (typeof val === 'number') {
			val = val.toString();
		} else {
			const contracts = val?.split(',');
			if (
				typeof val === 'string' &&
				contracts.every((item) => typeof item === 'string')
			) {
				return compressToEncodedURIComponent(val);
			}
			val = String(val);
		}

		return compressToEncodedURIComponent(val);
	}
}
