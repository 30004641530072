import { useState, useEffect, ChangeEvent, useRef } from 'react';
import * as React from 'react';

// services
import { Subscription } from 'rxjs';

import {
    Department,
    Employee,
    Position,
    PositionNameOnly,
} from '@api-interfaces';
import {
    departmentsService,
    employeesService,
    positionsService,
    shiftsService,
} from '@apis';
import { Dropdown } from '@atoms/form-ds2/dropdown';
import { DropdownItem } from '@atoms/form-ds2/dropdown/dropdown.interfaces';
import { DropdownMultiSelectItem } from '@atoms/form-ds2/dropdown-multi-select/dropdown-multi-select.interfaces';
import { FieldMultiSelect } from '@atoms/form-ds2/field-multi-select';
import { FieldRadioGroup } from '@atoms/form-ds2/field-radio-group';
import { SubmitButton } from '@atoms/form-ds2/submit-button';
import { getFullName, sendError } from '@helpers';
import { useAppContext } from '@hooks';
import { historyService } from '@services';
import {
    Platform,
    PLATFORMS,
    RELEASE_TYPES,
    ReleaseType,
} from '@collections/communications-center/releases/helpers';

// helpers

// hooks

// components
import { Button } from '@new';
import { Text } from '@atoms';
import { Label } from '@atoms/form-ds2/label';
import { InputCheckbox } from '@atoms/form-ds2/input-checkbox';

import { CommunicationRecipientsFormProps } from './communication-recipients-form.interfaces';
import CommunicationFormsFooter from './components/CommunicationFormsFooter';

// interfaces
import { R4PortfolioContract, R4PortfolioCustomerOrganization } from '@models';

// constants
import { LIMIT, ALL_EMPLOYEES_ITEM } from './config/select-all.config';

interface ErrorsState {
    departments?: string;
    clients?: string;
    platforms?: string;
    releaseTypes?: string;
}

const platformOptions: DropdownItem<{ type: Platform }>[] = Object.entries(
    PLATFORMS
).map(([type, name], id) => ({
    id,
    name,
    type: type as Platform,
}));

const releaseTypeOptions: DropdownItem<{ type: ReleaseType }>[] =
    Object.entries(RELEASE_TYPES).map(([type, { name }], id) => ({
        id,
        name,
        type: type as ReleaseType,
    }));

export const CommunicationRecipientsForm = (
    props: CommunicationRecipientsFormProps
) => {
    const {
        state: { user },
    } = useAppContext();
    const { mode } = props;

    const userClients =
        user?.roleProfile?.portfolios?.[0]?.customer_organizations ?? [];

    // select-all-clients for SBM will message all employees
    const isOrgSBM = user?.roleProfile?.portfolios?.[0]?.id === 9;
    const [isSelectAllSBMClients, setIsSelectAllSBMClients] =
        useState<boolean>(false);

    const [isDepartmentMessage, setIsDepartmentMessage] = useState<boolean>(
        props?.state?.isDepartmentMessage ?? false
    );
    const [departments, setDepartments] = useState<Department[]>(
        props?.state?.departments ?? []
    );

    // available states - dropdowns
    const [sites, setSites] = useState<R4PortfolioContract[]>(
        props?.state?.sites ?? []
    );
    const [positions, setPositions] = useState<PositionNameOnly[]>([]);
    const [employees, setEmployees] = useState<(Employee & { name: string })[]>(
        props?.state?.employees ?? []
    );
    const [shifts, setShifts] = useState<DropdownItem[]>(
        props?.state?.shifts ?? []
    );

    // selected states - dropdowns
    const [selectedPlatforms, setSelectedPlatforms] = useState<
        typeof platformOptions
    >(
        props?.state?.selectedPlatforms
            ? props.state.selectedPlatforms.map(
                  (type) => platformOptions.find((r) => r.type === type)!
              )
            : []
    );
    const [selectedReleaseTypes, setSelectedReleaseTypes] = useState<
        typeof releaseTypeOptions
    >(
        props?.state?.selectedReleaseTypes
            ? props.state.selectedReleaseTypes.map(
                  (type) => releaseTypeOptions.find((r) => r.type === type)!
              )
            : []
    );
    const [selectedDepartment, setSelectedDepartment] =
        useState<DropdownItem<Department> | null>(
            props?.state?.selectedDepartment ?? null
        );
    const [selectedClients, setSelectedClients] = useState<
        R4PortfolioCustomerOrganization[]
    >(props?.state?.selectedClients ?? []);
    const [selectedSites, setSelectedSites] = useState<R4PortfolioContract[]>(
        props?.state?.selectedSites ?? []
    );
    const [selectedShifts, setSelectedShifts] = useState<
        DropdownMultiSelectItem[]
    >(props?.state?.selectedShifts ?? []);
    const [selectedPositions, setSelectedPositions] = useState<Position[]>(
        props?.state?.selectedPositions ?? []
    );
    const [isExempt, setIsExempt] = useState(props?.state?.isExempt ?? false);
    const [selectedEmployees, setSelectedEmployees] = useState<
        (Employee & { name: string })[]
    >(props?.state?.selectedEmployees ?? []);

    // loading states
    const [isLoadingDepts, setIsLoadingDepts] = useState<boolean>(!props.state);
    const [isLoadingEmployees, setIsLoadingEmployees] =
        useState<boolean>(false);

    // disabled states
    const [isDisabledDepts, setIsDisabledDepts] = useState<boolean>(
        !props.state
    );
    const [isDisabledSites, setIsDisabledSites] = useState<boolean>(true);
    const [isDisabledPosition, setIsDisabledPosition] = useState<boolean>(true);
    const [isDisabledShifts, setIsDisabledShifts] = useState<boolean>(true);
    const [isDisabledEmployees, setIsDisabledEmployees] =
        useState<boolean>(true);

    const [errors, setErrors] = useState<ErrorsState>({});

    const isFinancialNotification = useRef<boolean>(
        props?.state?.isFinancialNotification ?? false
    );

    useEffect(() => {
        // prevent fetching positions or employees if user navigates back during select-all-clients SBM
        isOrgSBM &&
            selectedClients?.length === userClients?.length &&
            setIsSelectAllSBMClients(true);
        const subscription = departmentsService
            .getDepartments({
                organization: user.organization.id,
                limit: LIMIT,
            })
            .subscribe({
                next: ({ results: departments }) => {
                    setDepartments(departments);
                    setIsDisabledDepts(false);
                    setIsLoadingDepts(false);
                },
            });

        return () => {
            subscription.unsubscribe();
        };
    }, []);

    // fetches new sites whenever new clients are selected
    useEffect(() => {
        // when all clients selected, keep all other dropdowns disabled
        const isSelectAllClients = selectedClients[0]?.name === 'All Clients';

        const subscription = new Subscription();
        if (selectedClients.length) {
            const sites = user.roleProfile.portfolios[0].contracts.filter(
                (site) =>
                    selectedClients.findIndex(
                        (client) => client.id === site.customer
                    ) >= 0
            );

            const filteredSelectedSites = selectedSites.filter(
                (site) =>
                    selectedClients.findIndex(
                        (client) => client.id === site.customer
                    ) >= 0
            );

            setSites(sites);
            setSelectedSites(filteredSelectedSites);
            setIsDisabledSites(isSelectAllClients);
            setIsDisabledPosition(isSelectAllClients);
            setIsDisabledShifts(isSelectAllClients);
            setIsDisabledEmployees(isSelectAllClients);
        }
        return () => subscription.unsubscribe();
    }, [selectedClients]);

    // fetch shifts by selected customers or contracts if at least one client is selected
    useEffect(() => {
        !selectedClients?.length && setSelectedShifts([]);
        if (selectedClients?.length > 0) {
            setIsDisabledSites(true);
            shiftsService
                .getShifts(
                    {
                        limit: LIMIT,
                        ...(selectedClients?.length > 0 &&
                            !selectedSites?.length && {
                                customer: selectedClients?.map(
                                    (site) => site?.id
                                ),
                            }),
                        ...(selectedSites?.length > 0 && {
                            contract: selectedSites?.map((site) => site?.id),
                        }),
                    },
                    { noOptions: true }
                )
                .subscribe({
                    next: (res) => {
                        if (!res.results?.length) {
                            return;
                        }

                        // add site name to shift name: "Day - 4insite"
                        const shiftsBySite = res.results?.map((shift) => ({
                            id: shift.id,
                            name: `${shift.name} \u2013 ${shift.contract.name}`,
                            groupByName: shift?.contract?.customer_name,
                        }));
                        setShifts(shiftsBySite);

                        // cleanup selectedShifts based on the shifts at the selectedSites
                        const filteredSelectedShifts = selectedShifts.filter(
                            (selected) =>
                                shiftsBySite.some(
                                    (shift) => shift.name === selected.name
                                )
                        );
                        setSelectedShifts(filteredSelectedShifts);
                        setIsDisabledSites(false);
                    },
                });
        }
    }, [selectedClients, selectedSites]);

    // fetches positions whenever new clients/sites are selected
    useEffect(() => {
        if (
            props.mode !== 'news' &&
            (selectedSites?.length > 0 || selectedClients?.length > 0) &&
            !isSelectAllSBMClients
        ) {
            let subscription = new Subscription();

            subscription = positionsService
                .getPositions(
                    {
                        limit: 1000,
                        ...(selectedClients?.length > 0 && {
                            customer: selectedClients
                                .map((client) => client.id)
                                .join(','),
                        }),
                        ...(selectedSites?.length > 0 && {
                            contract: selectedSites
                                .map((site) => site.id)
                                .join(','),
                        }),
                    },
                    true
                )
                .subscribe({
                    next: (res) => {
                        if (!res?.results?.length) {
                            return;
                        }
                        setPositions(res.results);
                        const filteredSelectedPositions =
                            selectedPositions.filter((selected) =>
                                res.results.some(
                                    (result) => selected.id === result.id
                                )
                            );
                        selectedSites?.length
                            ? setSelectedPositions(filteredSelectedPositions)
                            : setSelectedPositions([]);
                    },
                    error: sendError({
                        toastMessage: 'There was an error getting Positions.',
                    }),
                });

            return () => subscription.unsubscribe();
        }
        setSelectedPositions([]);
    }, [selectedClients, selectedSites, selectedShifts]);

    // fetches new employees whenever new clients or positions are selected
    useEffect(() => {
        if (props.mode === 'news') {
            return;
        }
        let subscription = new Subscription();
        // no fetch on select all SBM clients
        isSelectAllSBMClients && setIsDisabledEmployees(true);
        if (selectedClients.length && sites.length && !isSelectAllSBMClients) {
            setIsLoadingEmployees(true);
            subscription = employeesService
                .getEmployees({
                    limit: 999999,
                    customer: selectedClients
                        .map((client) => client.id)
                        .join(','),
                    contract:
                        selectedSites?.length > 0
                            ? selectedSites.map((site) => site.id).join(',')
                            : sites.map((site) => site.id).join(','),
                    use_ifm: false,
                    detail_level: 'for_dropdown',
                    ...(selectedPositions.length > 0 && {
                        position: selectedPositions
                            .map((position) => position.id)
                            .join(','),
                    }),
                    ...(isExempt && {
                        is_active: true,
                        is_exempt: true,
                    }),
                })
                .subscribe({
                    next: ({ results: employeesRes }) => {
                        // format for dropdown
                        let employees: (Employee & { name: string })[] =
                            employeesRes.map((employee) => ({
                                ...employee,
                                name: getFullName(employee.person),
                                subtext: [
                                    employee.person?.email_address,
                                    employee.organization?.name,
                                ].join(' '),
                            }));

                        // filter by selectedShift
                        employees =
                            selectedShifts?.length > 0
                                ? employees.filter((employee) =>
                                      selectedShifts.some((shift) =>
                                          employee?.shifts?.some(
                                              (empShift) =>
                                                  empShift?.id === shift?.id
                                          )
                                      )
                                  )
                                : employees;
                        // filter by selectedPositions
                        employees =
                            selectedPositions?.length > 0
                                ? employees.filter((employee) =>
                                      selectedPositions.some((position) =>
                                          employee?.positions?.some(
                                              (empPosition) =>
                                                  empPosition?.id ===
                                                  position?.id
                                          )
                                      )
                                  )
                                : employees;

                        setEmployees(employees);

                        setIsDisabledEmployees(false);
                        setIsLoadingEmployees(false);
                    },
                    error: sendError({
                        callback: () => {
                            setIsDisabledEmployees(true);
                            setIsLoadingEmployees(false);
                        },
                    }),
                });
        } else if (!selectedClients.length) {
            // clean up if no selected clients
            setIsDisabledSites(true);
            setIsDisabledPosition(true);
            setIsDisabledEmployees(true);
            setSites([]);
            setShifts([]);
            setPositions([]);
            setEmployees([]);
        }
        return () => subscription.unsubscribe();
    }, [selectedPositions, selectedClients, selectedShifts, isExempt]);

    function handleDepartmentMessageChange(e: ChangeEvent<HTMLInputElement>) {
        e.persist();
        const { value } = e.target;
        setIsDepartmentMessage(value === 'true');
        if (value === 'true') {
            setIsDepartmentMessage(true);
        } else {
            setSelectedDepartment(null);
            setIsDepartmentMessage(false);
        }
    }

    function hasErrors() {
        const errors: ErrorsState = {};
        if (isDepartmentMessage && !selectedDepartment) {
            errors.departments = 'This field is required';
        }
        if (selectedClients?.length === 0) {
            errors.clients = 'At least one client must be selected';
        }
        if (selectedPlatforms.length === 0 && mode === 'releases') {
            errors.platforms = 'At least one platform must be selected';
        }
        if (selectedReleaseTypes.length === 0 && mode === 'releases') {
            errors.releaseTypes = 'At least one release type must be selected';
        }
        setErrors(errors);
        return Object.keys(errors).length > 0;
    }

    function onContinueClick(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        if (hasErrors() || (isDepartmentMessage && !selectedDepartment)) {
            return;
        }
        historyService.navigate(
            `/communications-center/publishing/${props.mode}/create/step2`,
            {
                state: {
                    isDepartmentMessage,
                    selectedDepartment,
                    toOrganizations: selectedClients,
                    toContracts: selectedSites?.length ? selectedSites : sites,
                    toShifts: selectedShifts?.length ? selectedShifts : [],
                    toEmployees: selectedEmployees?.length
                        ? selectedEmployees
                        : employees,
                    selectedPlatforms: selectedPlatforms.map(
                        (platform) => platform.type
                    ),
                    selectedReleaseTypes: selectedReleaseTypes.map(
                        (type) => type.type
                    ),
                    isExempt,
                    toPositions: selectedPositions,
                    isFinancialNotification: isFinancialNotification.current,
                    reminder_date: props?.state?.reminder_date,
                    initiatorURL: props?.state?.initiatorURL,
                    state: {
                        ...props?.state,
                    },
                },
            }
        );
    }

    function handleCancel(e: React.FormEvent) {
        e.preventDefault();
        historyService.navigateBack();
    }

    // for SBM - if user selects all clients, message defaults to all sites & employees
    function handleSelectClient(
        selectedClients: R4PortfolioCustomerOrganization[]
    ) {
        if (isOrgSBM && selectedClients?.length === userClients?.length) {
            setIsSelectAllSBMClients(true);
            setSelectedClients(selectedClients);
            const filteredSites = selectedSites.filter(
                (site) =>
                    selectedClients.findIndex(
                        (client) => client.id === site.customer
                    ) >= 0
            );
            setSelectedSites(filteredSites);
            setSelectedEmployees(ALL_EMPLOYEES_ITEM);
        } else if (isOrgSBM && selectedClients?.length === 0) {
            // if user deselects all clients, reset state
            setIsSelectAllSBMClients(false);
            setSelectedClients([]);
            setSelectedSites([]);
            setSelectedShifts([]);
            setSelectedEmployees([]);
        } else {
            // else handle individual selections
            setSelectedClients(selectedClients);
            const filteredSites = selectedSites.filter(
                (site) =>
                    selectedClients.findIndex(
                        (client) => client.id === site.customer
                    ) >= 0
            );
            const filteredEmployees = selectedEmployees.filter(
                (emp) =>
                    selectedClients.findIndex(
                        (client) => client.id === emp?.organization?.id
                    ) >= 0
            );
            setSelectedSites(filteredSites);
            setSelectedEmployees(filteredEmployees);
        }
    }

    return (
        <form
            className={`tw-space-y-6 ${mode === 'releases' ? 'tw-mb-72' : 'tw-mb-20'}`}
            onSubmit={onContinueClick}
        >
            <div className="tw-flex">
                <div className="tw-flex tw-mr-8">
                    <FieldRadioGroup
                        id="send-department"
                        name="send-department"
                        label="Who do you want to send this from?"
                        layout="vertical"
                        radios={[
                            { label: 'From yourself', value: 'false' },
                            {
                                label: 'On behalf of a department',
                                value: 'true',
                            },
                        ]}
                        defaultChecked={isDepartmentMessage ? 'true' : 'false'}
                        onChange={handleDepartmentMessageChange}
                        disabled={isFinancialNotification.current}
                    />
                </div>

                <div className="tw-flex tw-items-end tw-mt-[52px]">
                    <Dropdown
                        id="department"
                        name="department"
                        placeholder="Select Department"
                        items={departments}
                        selectedItem={selectedDepartment}
                        onChange={(selectedDepartment: Department) => {
                            setSelectedDepartment(selectedDepartment);
                        }}
                        disabled={
                            !isDepartmentMessage ||
                            isLoadingDepts ||
                            isDisabledDepts ||
                            isFinancialNotification.current
                        }
                        error={isDepartmentMessage && !selectedDepartment}
                    />
                </div>
            </div>

            {mode === 'releases' && (
                <>
                    <fieldset className="tw-flex tw-flex-col tw-gap-4">
                        <Label label="Who do you want to send this to?" />
                        <div className="tw-flex tw-gap-2">
                            <InputCheckbox
                                id="is-exempt"
                                checked={isExempt}
                                onChange={(e) => {
                                    setIsExempt(e.target.checked);
                                    setSelectedEmployees([]);
                                }}
                            />
                            <label htmlFor="is-exempt">
                                <Text font="body-md" color="hi-contrast">
                                    Exempt Employees
                                </Text>
                            </label>
                        </div>
                    </fieldset>

                    <FieldMultiSelect
                        id="platforms"
                        name="platforms"
                        label="Select Platform(s)"
                        items={platformOptions}
                        selectedItems={selectedPlatforms}
                        onChange={(selection) =>
                            setSelectedPlatforms(
                                selection as typeof platformOptions
                            )
                        }
                        error={errors.platforms}
                        required
                        hasSelectAll
                        selectedCountLabel="platform"
                    />

                    <FieldMultiSelect
                        id="release-types"
                        name="release-types"
                        label="Select Release Type(s)"
                        items={releaseTypeOptions}
                        selectedItems={selectedReleaseTypes}
                        onChange={(selection) =>
                            setSelectedReleaseTypes(
                                selection as typeof releaseTypeOptions
                            )
                        }
                        error={errors.releaseTypes}
                        required
                        hasSelectAll
                        selectedCountLabel="release type"
                    />
                </>
            )}

            <FieldMultiSelect
                id="clients"
                name="clients"
                label="Select Client(s)"
                items={userClients}
                selectedItems={selectedClients}
                onChange={(
                    selectedClients: R4PortfolioCustomerOrganization[]
                ) => handleSelectClient(selectedClients)}
                error={errors.clients}
                required
                hasSelectAll
                selectedCountLabel="client"
                disabled={isFinancialNotification.current}
            />

            <FieldMultiSelect
                id="sites"
                name="sites"
                label="Select Site(s)"
                items={sites}
                selectedItems={selectedSites}
                onChange={(selectedSites: R4PortfolioContract[]) => {
                    setSelectedSites(selectedSites);
                    !selectedSites?.length && setSelectedShifts([]);
                    const filteredEmployees = selectedEmployees.filter(
                        (emp) =>
                            selectedClients.findIndex(
                                (client) => client.id === emp?.organization?.id
                            ) >= 0
                    );
                    !isSelectAllSBMClients &&
                        setSelectedEmployees(filteredEmployees);
                }}
                disabled={isDisabledSites || isFinancialNotification.current}
                error={errors.sites}
                hasSelectAll
                groupBy={(option: R4PortfolioContract) => option.customer_name}
                selectedCountLabel="site"
            />

            <FieldMultiSelect
                id="shifts"
                name="shifts"
                label="Select Shift(s)"
                items={shifts}
                selectedItems={selectedShifts}
                onChange={(selectedShifts: DropdownMultiSelectItem[]) =>
                    setSelectedShifts(selectedShifts)
                }
                disabled={!selectedClients?.length || isDisabledShifts}
                error={errors.shifts}
                hasSelectAll
                selectedCountLabel="shift"
                groupBy={(option: DropdownItem) => option?.groupByName}
            />

            {props.mode !== 'news' && (
                <>
                    {!isFinancialNotification.current && (
                        <FieldMultiSelect
                            id="position"
                            name="position"
                            label="Select Specific Position(s)"
                            items={positions}
                            selectedItems={selectedPositions}
                            onChange={(selectedPositions: Position[]) => {
                                setSelectedPositions(selectedPositions);
                            }}
                            disabled={
                                isDisabledPosition || isSelectAllSBMClients
                            }
                            error={errors.sites}
                            hasSelectAll
                            selectedCountLabel="position"
                        />
                    )}

                    <FieldMultiSelect
                        id="employees"
                        name="employees"
                        label="Send Specific Employee(s)"
                        items={employees}
                        selectedItems={selectedEmployees}
                        onChange={(selectedEmployees) => {
                            setSelectedEmployees(selectedEmployees);
                        }}
                        disabled={
                            isLoadingEmployees ||
                            isDisabledEmployees ||
                            isFinancialNotification.current
                        }
                        error={errors.employees}
                        hasSelectAll
                        selectedCountLabel={
                            selectedEmployees !== ALL_EMPLOYEES_ITEM
                                ? 'employee'
                                : ''
                        }
                    />
                </>
            )}

            <CommunicationFormsFooter
                step={1}
                leftSide={
                    <Button
                        label="Cancel"
                        onClick={handleCancel}
                        color="secondary"
                    />
                }
                rightSide={<SubmitButton label="Continue" />}
            />
        </form>
    );
};
