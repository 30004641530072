import { forwardRef, HTMLAttributes, useState, useRef, useEffect } from 'react';
import * as React from 'react';
import PhoneInput, {
  getCountryCallingCode,
  Country,
} from 'react-phone-number-input';
import flags from 'react-phone-number-input/flags';

import { Icon, Text } from '@atoms';

// interfaces
import { emojiMap } from '@core/helpers/emoji-map';

import { InputPhoneProps } from './input-phone.interfaces';

import './input-phone.css';

export const InputPhone = forwardRef<HTMLInputElement, InputPhoneProps>(
  ({ error, className, name, ...props }, _ref) => {
    const [isOpen, setIsOpen] = useState(false);
    const [countryCode, setCountryCode] = useState<null | Country>(null);
    const countrySelectRef = useRef<null | HTMLDivElement>(null);

    useEffect(() => {
      function handleOutsideCountrySelectClick(e: MouseEvent) {
        if (!countrySelectRef.current.contains(e.target as HTMLElement)) {
          setIsOpen(false);
        }
      }
      if (countrySelectRef.current) {
        window.addEventListener('mousedown', handleOutsideCountrySelectClick);
      }
      return () => {
        window.removeEventListener(
          'mousedown',
          handleOutsideCountrySelectClick
        );
      };
    }, []);

    return (
      <PhoneInput
        focusInputOnCountrySelection
        initialValueFormat="national"
        international={false}
        className={`tw-flex tw-h-10 tw-rounded tw-ring-2 tw-ring-inset
        ${
          props.disabled
            ? 'tw-bg-theme-neutral-200-800 tw-cursor-not-allowed tw-text-theme-neutral-500-600'
            : 'tw-bg-theme-neutral-100-800'
        }
        ${
          error
            ? `tw-ring-theme-danger-500-300 focus-within:tw-shadow-input-error ${
                props.disabled ? 'tw-ring-opacity-20' : ''
              }`
            : ` focus-within:tw-ring-theme-primary-500-300 focus-within:tw-shadow-input-focus ${
                props.disabled
                  ? 'tw-ring-theme-neutral-300-700'
                  : 'tw-ring-theme-neutral-400-600'
              }`
        }
        ${className}
        `}
        flags={flags}
        numberInputProps={{ countryCode }}
        inputComponent={Input}
        countrySelectProps={{
          setCountryCode,
          setIsOpen,
          isOpen,
          countrySelectRef,
          disabled: props.disabled,
        }}
        countrySelectComponent={CountrySelect}
        onCountryChange={(country) => {
          setCountryCode(country);
        }}
        {...props}
      />
    );
  }
);

const Input = forwardRef<
  HTMLInputElement,
  HTMLAttributes<HTMLInputElement> & { countryCode: Country }
>(({ countryCode, className, ...props }, ref) => {
  return (
    <div className="tw-flex tw-items-center tw-w-full tw-space-x-1 tw-px-2">
      {countryCode && (
        <Text font="body-md" color="neutral-offset">
          +{getCountryCallingCode(countryCode)}
        </Text>
      )}
      <input
        ref={ref}
        className="tw-w-full tw-h-full tw-outline-none tw-border-0 tw-bg-transparent tw-text-sm tw-caret-neutral-600 dark:tw-caret-neutral-200 tw-text-theme-neutral-900-100 disabled:tw-text-theme-neutral-500-600"
        {...props}
      />
    </div>
  );
});

const CountrySelect = ({
  iconComponent: IconComponent,
  options,
  value,
  setCountryCode,
  setIsOpen,
  isOpen,
  countrySelectRef,
  disabled,
  ...countrySelectProps
}) => {
  const [search, setSearch] = useState('');
  const [positionClassName, setPositionClassName] = useState('tw-top-full');
  const dropdownContainer = useRef<null | HTMLDivElement>(null);

  function handleClick(e: React.MouseEvent<HTMLLIElement>) {
    const { id } = e.target as HTMLElement;
    if (id) {
      setCountryCode(id as Country);
    }
    countrySelectProps.onChange(id);
    setSearch('');
    setIsOpen(false);
  }

  useEffect(() => {
    if (countrySelectRef.current) {
      const isOverflowing =
        countrySelectRef.current.offsetTop +
          countrySelectRef.current.clientHeight +
          192 >
        window.innerHeight;
      if (isOverflowing) {
        setPositionClassName('tw-bottom-full');
      } else {
        setPositionClassName('tw-top-full');
      }
    }
  }, [isOpen]);

  function getFlagsList(selectedValue) {
    if (!selectedValue) {
      return options;
    }

    const matches = [];
    const nonMatches = [];
    options.forEach((el) => {
      const isMatch = selectedValue === el.value;
      if (isMatch) {
        matches.push(el);
      } else {
        nonMatches.push(el);
      }
    });
    return [...matches, ...nonMatches];
  }

  return (
    <div
      ref={countrySelectRef}
      aria-label={countrySelectProps['aria-label']}
      data-ds2="input-phone"
      className='tw-relative tw-h-full after:tw-block after:tw-content-[""] after:tw-absolute after:tw-top-0.5 after:tw-left-full after:tw-bg-theme-neutral-400-600 after:tw-w-0.5 after:tw-h-[calc(100%-4px)]'
    >
      <button
        className="tw-h-full tw-p-2 tw-flex tw-items-center tw-space-x-1 tw-rounded-l"
        aria-haspopup="listbox"
        aria-expanded={isOpen}
        type="button"
        onClick={(e) => {
          setIsOpen(!isOpen);
        }}
        disabled={disabled}
      >
        {value ? (
          <IconComponent country={value} label={value} title={value} />
        ) : (
          <div>{emojiMap()[`U+1F30E`]}</div>
        )}
        <Icon icon="caret-down" className="tw-text-neutral-500" />
      </button>
      <div
        ref={dropdownContainer}
        className={`tw-absolute tw-space-y-4 tw-w-72 tw-bg-theme-neutral-100-800 tw-rounded  tw-shadow-dropdown ${
          isOpen ? 'tw-z-[1]' : 'tw-hidden'
        } ${positionClassName}`}
      >
        <div className="tw-relative tw-h-full tw-p-2">
          <input
            placeholder="Search"
            type="search"
            className="tw-pl-9 tw-pr-2 tw-h-8 tw-w-full tw-text-sm tw-bg-theme-neutral-200-700 tw-rounded tw-outline-none tw-caret-neutral-600 dark:tw-caret-neutral-200 tw-peer tw-text-theme-neutral-900-100"
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            value={search}
          />
          <div className="tw-absolute tw-m-2 tw-inset-y-0 tw-flex tw-items-center tw-justify-center tw-text-theme-neutral-500-600 peer-focus:tw-text-theme-primary-500-300">
            <Icon icon="search" />
          </div>
        </div>
        <ul
          tabIndex={-1}
          role="listbox"
          aria-activedescendant={value}
          className="tw-overflow-y-auto tw-max-h-48"
          style={{ marginTop: 0 }}
        >
          {getFlagsList(value)
            .filter((option) =>
              option.label.toLowerCase().includes(search.toLowerCase())
            )
            .map(({ value: optionValue, label }) => {
              const Flag = flags[optionValue];
              const isSelected = value
                ? value === optionValue
                : !value === !optionValue;
              return (
                <li
                  role="option"
                  key={label}
                  id={optionValue}
                  aria-selected={isSelected}
                  className={
                    isSelected
                      ? 'tw-flex tw-items-center tw-justify-between tw-bg-theme-primary-500-300 tw-bg-opacity-15'
                      : 'hover:tw-bg-theme-neutral-200-700'
                  }
                  onClick={handleClick}
                >
                  <div className="tw-pointer-events-none tw-flex tw-items-center tw-p-2 tw-space-x-1 ">
                    {Flag && (
                      <div className="tw-w-5">
                        <Flag title={optionValue} />
                      </div>
                    )}
                    {!Flag && !optionValue && label === 'International' && (
                      <div className="tw-w-5">
                        <div>{emojiMap()[`U+1F30E`]}</div>
                      </div>
                    )}
                    <Text font="body-md" color="hi-contrast">
                      {label}
                    </Text>
                  </div>
                  {isSelected && (
                    <Icon
                      icon={['far', 'check']}
                      className="tw-text-theme-primary-500-300 tw-mr-4 tw-text-xl tw-font-black"
                    />
                  )}
                </li>
              );
            })}
        </ul>
      </div>
    </div>
  );
};

InputPhone.defaultProps = {
  className: '',
};
