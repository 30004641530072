import { forwardRef } from 'react';

import { InputCheckboxProps } from './input-checkbox.interfaces';

import './input-checkbox.css';

export const InputCheckbox = forwardRef<HTMLInputElement, InputCheckboxProps>(
    (props, ref) => {
        return (
            <input
                ref={ref}
                data-ds2="checkbox"
                type="checkbox"
                {...props}
                onClick={(e) => e.stopPropagation()}
            />
        );
    }
);

InputCheckbox.defaultProps = {};
