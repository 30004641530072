import axios, {
	AxiosRequestConfig,
	AxiosResponse,
	AxiosError,
	AxiosInstance,
} from 'axios';

import env from '@core/environments';
import { User } from '@models/user';

class ApiClient {
	private client: AxiosInstance;

	constructor(baseURL = '') {
		this.client = axios.create({
			baseURL,
		});
		this.client.defaults.headers.post['Content-Type'] = 'application/json';
	}

	public setOptions(user: User) {
		if (!user) {
			if (this.client.defaults.headers.common.hasOwnProperty('Authorization')) {
				delete this.client.defaults.headers.common.Authorization;
			}
			if (
				this.client.defaults.headers.common.hasOwnProperty('Content-Language')
			) {
				delete this.client.defaults.headers.common['Content-Language'];
			}
			return;
		}
		this.client.defaults.headers.common.Authorization = `Token ${user.token}`;
		this.client.defaults.headers.common['Content-Language'] =
			user?.language?.code ?? 'en';
	}

	cancelToken() {
		return axios.CancelToken;
	}

	get<T>(url: string, conf: AxiosRequestConfig = {}) {
		return this.client
			.get(url, conf)
			.then((response: AxiosResponse<T>) => Promise.resolve(response))
			.catch((error: AxiosError) => Promise.reject(error));
	}

	delete<T>(url: string, conf: AxiosRequestConfig = {}) {
		return this.client
			.delete(url, conf)
			.then((response: AxiosResponse<T>) => Promise.resolve(response))
			.catch((error: AxiosError) => Promise.reject(error));
	}

	post<T>(url: string, data = {}, conf: AxiosRequestConfig = {}) {
		return this.client
			.post(url, data, conf)
			.then((response: AxiosResponse<T>) => Promise.resolve(response))
			.catch((error: AxiosError) => Promise.reject(error));
	}

	put<T>(url: string, data = {}, conf: AxiosRequestConfig = {}) {
		return this.client
			.put(url, data, conf)
			.then((response: AxiosResponse<T>) => Promise.resolve(response))
			.catch((error: AxiosError) => Promise.reject(error));
	}

	patch<T>(url: string, data = {}, conf: AxiosRequestConfig = {}) {
		return this.client
			.patch(url, data, conf)
			.then((response: AxiosResponse<T>) => Promise.resolve(response))
			.catch((error: AxiosError) => Promise.reject(error));
	}
}

export const apiClient = new ApiClient(env.insite.pythonUrl);
