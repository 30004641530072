import { ServiceArea } from '@api-interfaces';

export interface Locations {
	building: null | ServiceArea;
	floor: null | ServiceArea;
	room: null | ServiceArea; // synonymous with "areas"
}

export function getLocations(serviceArea: ServiceArea): Locations {
	const locations: Locations = {
		building: null,
		floor: null,
		room: null,
	};

	if (serviceArea?.parent?.parent) {
		locations.building = serviceArea.parent.parent;
		locations.floor = serviceArea.parent;
		locations.room = serviceArea;
	} else if (serviceArea?.parent) {
		locations.building = serviceArea.parent;
		locations.floor = serviceArea;
	} else {
		locations.building = serviceArea;
	}

	return locations;
}
