// components
import { Icon, Text } from '@insite-next/mocha';

// core
import { useEffect, useRef, useState } from 'react';

// services
import { imageService } from '@core/services';
import { fullscreenGalleryService } from '@app/main/FullscreenGallery/FullscreenGallery.service';

import {
    AttachmentsState,
    QueueAttachment,
    getFileType,
    truncateFileName,
    AttachmentTileProps,
} from '@components/_new/Comments/comment.utils';

export const AttachmentTile = ({
    isEditable,
    isNewComment,
    attachmentsState,
    item,
    setAttachmentsState,
    removeAttachment,
}: AttachmentTileProps) => {
    const [isHovered, setIsHovered] = useState<boolean>();

    const { file_name, id, display_name } = item;

    const timeOut = useRef<ReturnType<typeof setTimeout>>();

    const handleMouseEnter = () => {
        clearTimeout(timeOut.current);
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        timeOut.current = setTimeout(() => {
            setIsHovered(false);
        }, 100);
    };

    return (
        <>
            {getFileType(file_name) === 'Image' ? (
                <div
                    className={`tw-bg-theme-neutral-900-100 tw-bg-opacity-5 tw-rounded tw-h-[80px] tw-w-[80px] tw-relative tw-cursor-pointer  ${
                        id === attachmentsState?.attachmentSelected?.id &&
                        'tw-border tw-border-solid tw-border-theme-neutral-900-100'
                    }`}
                    onClick={() => {
                        isNewComment &&
                            setAttachmentsState({
                                ...attachmentsState,
                                attachmentSelected: item,
                            });
                        fullscreenGalleryService.open(
                            imageService.getImageByUniqueId(file_name, false)
                        );
                    }}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                >
                    <img
                        className="tw-items-center tw-flex tw-h-full tw-w-full tw-content-center tw-relative tw-object-cover tw-rounded-md"
                        src={imageService.getImageByUniqueId(file_name, false)}
                        alt={truncateFileName(display_name, 8)}
                    />
                    {isHovered && isEditable && (
                        <div
                            className="tw-items-center tw-bg-theme-neutral-900-100  tw-rounded-full tw-text-center tw-flex tw-h-4 tw-content-center tw-w-4 tw-absolute -tw-top-2 -tw-right-2 tw-cursor-pointer hover:tw-bg-theme-neutral-600-500 hover:tw-scale-[1.15] tw-transition tw-duration-100"
                            onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                removeAttachment(item);
                            }}
                        >
                            <Icon
                                icon={'close'}
                                size="sm"
                                className="tw-mx-auto tw-text-theme-neutral-100-800"
                            />
                        </div>
                    )}
                </div>
            ) : (
                // The following is for when the file type is not an image.  We will eventually want the doc to download when the user clicks on the tile.  Currently this portion will not render because the file types are turned off in Comments/index
                <div
                    className="tw-rounded tw-bg-theme-primary-500-300 tw-bg-opacity-15 tw-border-2 tw-border-solid tw-border-theme-primary-500-300  tw-h-[80px] tw-w-[80px] tw-flex tw-items-center tw-relative"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    onClick={console.log(
                        imageService.getImageByUniqueId(file_name, false)
                    )}
                >
                    <div className="tw-w-full tw-text-center">
                        <Icon
                            icon="file-alt"
                            size="2xl"
                            className="tw-text-theme-primary-500-300 tw-mx-auto tw-mb-1"
                        />
                        <Text font="body-sm" color="primary">
                            {truncateFileName(display_name, 12)}
                        </Text>
                    </div>
                    {isHovered && isEditable && (
                        <div
                            className="tw-items-center tw-bg-theme-neutral-900-100 tw-rounded-full tw-text-center tw-flex tw-h-4 tw-content-center tw-w-4 tw-absolute -tw-top-2 -tw-right-2 tw-cursor-pointer hover:tw-bg-theme-primary-300-700 hover:tw-scale-[1.15] tw-transition tw-duration-100"
                            onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                removeAttachment(item);
                            }}
                        >
                            <Icon
                                icon={'close'}
                                size="sm"
                                className="tw-mx-auto tw-text-theme-neutral-900-100"
                            />
                        </div>
                    )}
                </div>
            )}
        </>
    );
};
