import { Preset, SimplePreset, PresetPayload } from '@api-interfaces';
import { Params } from '@core/helpers';
import { CommonParams, PaginationResponse } from '@models';
import { virtualBrownClient } from '@services/django-client';
// import { Observable } from 'rxjs';

const noOpts = { noContract: true, noCustomer: true, noTag: true };
const BASE_URL = 'presets';

class PresetsService {
	getPresets(
		params: CommonParams & {
			employee: number;
			customer: number;
			order_by?: string;
			include_shared?: boolean;
		}
	) {
		return virtualBrownClient.get<PaginationResponse<SimplePreset>>(
			`${BASE_URL}/${Params.makeQueryString(params)}`,
			noOpts
		);
	}

	getPresetById(presetId: number) {
		return virtualBrownClient.get<Preset>(`${BASE_URL}/${presetId}/`, noOpts);
	}

	createPreset(payload: PresetPayload) {
		return virtualBrownClient.post<Preset>(`${BASE_URL}/`, payload);
	}

	updatePresetById(presetId: number, payload: Partial<PresetPayload>) {
		return virtualBrownClient.put<Preset>(`${BASE_URL}/${presetId}/`, payload);
	}

	deletePresetById(presetId: number) {
		return virtualBrownClient.delete<any>(`${BASE_URL}/${presetId}/`);
	}
}

export const presetsService = new PresetsService();
