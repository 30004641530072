export const ToDoProgressBar = ({
  numerator,
  denominator,
  className = '',
}: { numerator: number; denominator: number } & CommonReactProps) => {
  let percent =
    denominator > 0 ? Math.round((numerator / denominator) * 100) : 0;
  percent = percent < 0 ? 0 : percent > 100 ? 100 : percent;
  return (
    <div className={`tw-flex tw-w-full tw-items-center ${className}`}>
      <div className="tw-w-full tw-h-3 tw-rounded-full tw-bg-neutral-300 dark:tw-bg-neutral-600 tw-overflow-hidden">
        <div
          className="tw-h-full tw-rounded-full tw-bg-theme-primary-500-300"
          style={{ width: `${percent}%` }}
        />
      </div>
    </div>
  );
};
