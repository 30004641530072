import jwt from 'jsonwebtoken';
import { useEffect } from 'react';

// services
import { Subscription } from 'rxjs';

import { tokenService } from '@apis';
import {
	LocalStorageName,
	StoredAppData,
} from '@core/app-context/app.interfaces';
import { sendError, Storage } from '@helpers';
import { http, apiClient, Daemon } from '@services';

// helpers

import { useAppContext } from '../useAppContext';

export function useRefreshTokenDaemon() {
	const { state } = useAppContext();

	useEffect(() => {
		let subscription = new Subscription();
		if (state.user) {
			if (!http.refreshTokenDaemon) {
				// run this daemon every second
				http.refreshTokenDaemon = new Daemon({ rate: 1000 });
			}

			http.refreshTokenDaemon?.init((daemonCb) => {
				const decoded = jwt.decode(state.user.token);
				const now = Math.floor(+new Date() / 1000);

				if (now >= decoded.exp || http.refreshTokenDaemon.forced) {
					subscription = tokenService
						.getAccessToken({ refresh: state.user.refreshToken })
						.subscribe({
							next: ({ access, refresh }) => {
								http.token = access;
								state.user.token = access;
								state.user.refreshToken = refresh;
								apiClient.setOptions(state.user);
								let localStorage: StoredAppData = Storage.get(LocalStorageName);
								localStorage = {
									...localStorage,
									refreshToken: refresh,
								};
								Storage.set(LocalStorageName, localStorage);

								// this callback makes sure the refreshTokenDaemon.isCalling is set to false
								if (daemonCb) daemonCb();
							},
							error: sendError({
								toastMessage: 'Session terminated',
								callback: () => {
									state.logout();
									http.refreshTokenDaemon.close();
								},
							}),
						});
				} else {
					// this callback makes sure the refreshTokenDaemon.isCalling is set to false
					if (daemonCb) daemonCb();
				}
			});
			http.refreshTokenDaemon?.start();
		}

		return () => {
			subscription.unsubscribe();
			http.refreshTokenDaemon?.close();
		};
	}, [state.user, state.logout]);
}
