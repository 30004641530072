import { cloneDeep } from 'lodash';
import { createContext, useContext, useState, useEffect, useRef } from 'react';

// services
import { Subscription } from 'rxjs';

import { WorkOrder } from '@api-interfaces';
import { workOrdersService } from '@apis';
import { sendError } from '@helpers';
import { refetchService } from '@services';

// helpers

// interfaces
import {
    WorkOrderModalState,
    WorkOrderModalProps,
    WorkOrderActiveModals,
} from './work-order.context.interfaces';

const workOrderModalContext = createContext(null as WorkOrderModalState);

export const WorkOrderModalProvider = (props: WorkOrderModalProps) => {
    const [isLoading, setIsLoading] = useState(true);
    const [isEditing, setIsEditing] = useState(false);
    const [isClosing, setIsClosing] = useState(false);
    const [submissionAttempt, setSubmissionAttempt] = useState(false);

    const [workOrder, setWorkOrder] =
        useState<WorkOrderModalState['workOrder']>(null);
    const [selectedTodo, setSelectedTodo] =
        useState<WorkOrderModalState['selectedTodo']>(null);
    const [selectedServiceIssue, setSelectedServiceIssue] =
        useState<WorkOrderModalState['selectedServiceIssue']>(null);
    const [workOrderEdit, setWorkOrderEdit] =
        useState<WorkOrderModalState['workOrder']>(null);
    const [activeModals, setActiveModals] = useState<WorkOrderActiveModals>({
        'bottom-sheet': true,
        'close-work-order': false,
        'delete-work-order': false,
        'reject-work-order': false,
        'creating-todo': false,
        'viewing-todo': false,
        'editing-todo': false,
        'deleting-todo': false,
        'completing-todo': false,
        'creating-service-issue': false,
        'editing-service-issue': false,
        'deleting-service-issue': false,
    });
    const [activePurchaseOrder, setActivePurchaseOrder] =
        useState<WorkOrderModalState['activePurchaseOrder']>(null);
    const hasBeenUpdated = useRef(false);

    useEffect(() => {
        let subscription = new Subscription();
        if (props.workOrder && !workOrder) {
            setWorkOrder(props.workOrder);
            setIsLoading(false);
        } else if (props.workOrderId != undefined) {
            subscription = workOrdersService
                .getWorkOrderById(props.workOrderId)
                .subscribe({
                    next: (workOrder) => {
                        setWorkOrder(workOrder);
                        setIsLoading(false);
                    },
                    error: sendError({
                        toastMessage: 'Cannot load Work Order Details',
                        callback: () => {
                            setIsLoading(false);
                        },
                    }),
                });
        }

        return () => {
            subscription.unsubscribe();
        };
    }, [isLoading, props.workOrder, props.workOrderId]);

    useEffect(() => {
        setWorkOrderEdit(cloneDeep(workOrder));
    }, [workOrder]);

    return (
        <workOrderModalContext.Provider
            value={{
                closeModal: (workOrder: WorkOrder) => {
                    props.closeModal(workOrder);
                    if (hasBeenUpdated.current) {
                        refetchService.fetch('work-orders');
                    }
                },
                onSuccess: (workOrder: WorkOrder) => {
                    if (props.onSuccess) {
                        props.onSuccess(workOrder);
                    }
                },
                location: props.location,
                isEditing,
                setIsEditing,
                isClosing,
                setIsClosing,
                isLoading,
                setIsLoading,
                workOrder,
                setWorkOrder,
                workOrderEdit,
                setWorkOrderEdit,
                activeModals,
                setActiveModals,
                selectedTodo,
                setSelectedTodo,
                selectedServiceIssue,
                setSelectedServiceIssue,
                activePurchaseOrder,
                setActivePurchaseOrder,
                hasBeenUpdated,
                submissionAttempt,
                setSubmissionAttempt,
            }}
            {...props}
        />
    );
};

export function useWorkOrderModalContext() {
    return useContext(workOrderModalContext);
}
