import { Box, Text, IconButtonFlat } from '@atoms';

// interfaces
import { GenericProps } from './generic.interfaces';

export const Generic = ({
    title,
    titleFontSize,
    titleTruncate,
    onCancel,
    className,
    style,
    subtitle,
    children,
    type,
    hideCloseButton,
    modalRef,
}: GenericProps) => {
    const fontSizeForTitle = titleFontSize || 'h1';
    return (
        <Box
            ref={modalRef}
            className={`tw-p-4 tw-shadow-md sm:tw-rounded-lg sm:tw-p-8 md:tw-p-10 ${className}`}
            type={type}
            style={style}
        >
            <div className="tw-flex tw-justify-between tw-mb-5">
                <div
                    className={`tw-flex tw-flex-col ${
                        titleTruncate ? 'tw-overflow-hidden' : ''
                    }`}
                >
                    {title && typeof title === 'string' ? (
                        <Text
                            font={fontSizeForTitle}
                            color="hi-contrast"
                            className={
                                titleTruncate
                                    ? 'tw-overflow-hidden tw-whitespace-nowrap tw-text-ellipsis'
                                    : ''
                            }
                        >
                            {title}
                        </Text>
                    ) : (
                        title
                    )}
                    {subtitle &&
                        (typeof subtitle === 'string' ? (
                            <Text font="body-md" color="hi-contrast">
                                {subtitle}
                            </Text>
                        ) : (
                            subtitle
                        ))}
                </div>
                {!hideCloseButton && (
                    <IconButtonFlat
                        icon={['far', 'times']}
                        className="tw-text-2xl"
                        onClick={onCancel}
                        type="secondary"
                    />
                )}
            </div>
            {children}
        </Box>
    );
};

Generic.defaultProps = {
    className: '',
};
