import './GalleryCheckBox.scss';
import { imageService } from '@core/services';

type ImageItem = {
    id: number | string;
    file_name: string;
};

interface Props {
    imagesList: ImageItem[];
    selectedImages: ImageItem[];
    setSelectedImages: Function;
}

const GalleryCheckBox = ({
    imagesList,
    selectedImages,
    setSelectedImages,
}: Props) => {
    const getImageUrl = (item: ImageItem) => {
        return imageService.getImageByUniqueId(item?.file_name || '', false);
    };

    const selectImage = (item: ImageItem, checked: boolean) => {
        if (checked) {
            setSelectedImages([...selectedImages, item]);
        } else {
            setSelectedImages(
                selectedImages.filter((img) => img.file_name !== item.file_name)
            );
        }
    };

    return (
        <div className="tw-flex tw-gap-2 tw-mt-5">
            {imagesList.map((item: ImageItem) => (
                <div key={item.id} onClick={() => {}}>
                    <div className="tw-absolute tw-mt-2 tw-ml-2">
                        <input
                            type="checkbox"
                            checked={
                                !!selectedImages.find(
                                    (img) => img.file_name === item.file_name
                                )
                            }
                            onChange={(e) =>
                                selectImage(item, e.target.checked)
                            }
                            className="img-radio-button"
                        />
                    </div>
                    <img
                        src={getImageUrl(item)}
                        alt={item.file_name}
                        className="tw-w-32 tw-h-32"
                    />
                </div>
            ))}
        </div>
    );
};

export default GalleryCheckBox;
