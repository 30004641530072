import { HyperState, HyperActions } from './hyper.interfaces';

export function HyperReducer(state: HyperState, action: HyperActions) {
	switch (action.type) {
		case 'SET_ACTIONS':
			return {
				...state,
				actions: action.payload,
			};
		case 'TOGGLE_HYPER':
			return {
				...state,
				hyperLauncherToggled: action.payload,
			};
	}
}
