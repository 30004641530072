import { useMemo } from 'react';

// utils
import { BubbleScaleProps } from './bubble-scale.interfaces';
import { bubbleBgColor } from './bubble-scale.utils';

// interfaces

const DEFAULT_MAX_R = 120;

export const BubbleScale = ({
  minRadius,
  maxRadius,
  scale,
  value,
  maxValue,
  type,
  className,
  style,
}: BubbleScaleProps) => {
  const bubbleStyle = useMemo(() => {
    const minR = minRadius > 0 ? minRadius : 0;
    const maxR =
      maxRadius < Number.MAX_SAFE_INTEGER ? maxRadius : DEFAULT_MAX_R;
    let radius = minR;
    const range = maxR - minR;
    if (scale === 'percent') {
      radius = range * value + minR;
    } else if (scale === 'value') {
      const percentage = value / maxValue;
      radius = range * percentage + minR;
    }
    radius = Math.min(radius, maxR);
    return {
      ...style,
      minHeight: minR,
      minWidth: minR,
      maxHeight: maxR,
      maxWidth: maxR,
      height: radius,
      width: radius,
    };
  }, [scale, value, maxValue, style]);

  return (
    <div
      className={`tw-rounded-full ${
        type ? bubbleBgColor[type] : ''
      } ${className}`}
      style={bubbleStyle}
    />
  );
};

BubbleScale.defaultProps = {
  className: '',
};
