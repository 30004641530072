import { has } from 'lodash';
import * as React from 'react';

import { fullscreenGalleryService } from '@app/main/FullscreenGallery/FullscreenGallery.service';
import { Icon } from '@atoms';
import { TimeUtils, titleCase } from '@helpers';

import './ImageContainer.scss';

interface IProps {
    data: {
        image: string;
        createdBy?: string;
        createdDate?: Date;
    };
    methods?: {
        onRemove?: () => void;
    };
    settings?: {
        openInWindow: boolean; // default: false
    };
}

const ImageContainer: React.SFC<IProps> = (props) => {
    const { openInWindow } = props.settings
        ? props.settings
        : { openInWindow: null };
    const { image, createdBy, createdDate } = props.data;
    const onRemove = has(props, 'methods.onRemove')
        ? props.methods.onRemove
        : () => null;

    return (
        <div className="responsive-img-wrapper tw-pb-1">
            1
            <div className="tw-flex tw-justify-end">
                {has(props, 'methods.onRemove') ? (
                    <Icon
                        icon="times-circle"
                        onClick={onRemove}
                        className="tw-relative tw-left-2 tw--bottom-2 tw-cursor-pointer tw-text-gray-0 hover:tw-text-gray-40 active:tw-text-gray-0"
                    />
                ) : null}
            </div>
            <img
                src={image}
                className="responsive-img"
                style={openInWindow ? { cursor: 'pointer' } : {}}
                onClick={() => {
                    fullscreenGalleryService.open(image);
                }}
            />
            {createdBy && (
                <div className="t-text image-caption">{`Submitted by ${titleCase(
                    createdBy
                )}`}</div>
            )}
            {Boolean(createdDate) && (
                <div className="t-text image-caption">
                    {TimeUtils.format(createdDate, 'MM/DD/YYYY h:mm A')}
                </div>
            )}
        </div>
    );
};

export default ImageContainer;
