// operators
import { BehaviorSubject } from 'rxjs';

export interface FullscreenGalleryMail {
	url: string;
}

class FullscreenGalleryService {
	private mail: FullscreenGalleryMail = {
		url: null,
	};

	private subject: BehaviorSubject<FullscreenGalleryMail> = new BehaviorSubject(
		this.mail
	);

	public getSubject(): BehaviorSubject<FullscreenGalleryMail> {
		return this.subject;
	}

	public open(url: string) {
		this.mail.url = url;
		this.subject.next(this.mail);
	}
}

export const fullscreenGalleryService = new FullscreenGalleryService();
