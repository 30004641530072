import { SummaryChart } from '@models';

export namespace RoutesVerifiableTasksSummary {
	export enum Chart {
		PercentageVerifiedTrend = 1,
		VerifiableTaskTotalsVerified = 2,
		VerifiedByAreaType = 3,
		RoutesWithVerifiableTasks = 4,
	}

	export interface PercentageVerifiedTrend {
		time_period: string;
		verifiable_tasks: number;
		verified_pct: number;
		verified_tasks: number;
	}

	export interface PercentageVerifiedTrendSummary
		extends SummaryChart<PercentageVerifiedTrend[]> {
		chart: 'PercentageVerifiedTrend';
		number: '1';
	}

	export interface VerifiableTaskTotalsVerified {
		incomplete_tasks: null | number;
		verifiable_tasks: number;
		verified_pct: number;
		verified_tasks: null | number;
		verifiable_areas: null | number;
	}

	export interface VerifiableTaskTotalsVerifiedSummary
		extends SummaryChart<VerifiableTaskTotalsVerified> {
		chart: 'VerifiableTaskTotalsVerified';
		number: '2';
	}

	export interface VerifiedByAreaType {
		area_type_id: number;
		area_type_name: string;
		verifiable_tasks: number;
		verified_pct: number;
		verified_tasks: number;
	}

	export interface VerifiedByAreaTypeSummary
		extends SummaryChart<VerifiedByAreaType[]> {
		chart: 'VerifiedByAreaType';
		number: '3';
	}

	export interface RoutesWithVerifiableTasks {
		routes_with_verifiable_tasks: number;
	}

	export interface RoutesWithVerifiableTasksSummary
		extends SummaryChart<RoutesWithVerifiableTasks> {
		chart: 'RoutesWithVerifiableTasks';
		number: '4';
	}

	export interface SummaryReturnType {
		[Chart.PercentageVerifiedTrend]: PercentageVerifiedTrendSummary;
		[Chart.VerifiableTaskTotalsVerified]: VerifiableTaskTotalsVerifiedSummary;
		[Chart.VerifiedByAreaType]: VerifiedByAreaTypeSummary;
		[Chart.RoutesWithVerifiableTasks]: RoutesWithVerifiableTasksSummary;
	}
}
