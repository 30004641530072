// services

// helpers
import { Observable } from 'rxjs';

// interfaces
import { Account } from '@api-interfaces';
import { http } from '@services';

const BASE_URL = 'accounts';

class AccountsService {
	login(payload: { refresh: string }): Observable<Account>;

	login(payload: { email: string; password: string }): Observable<Account>;

	login(payload?: {
		email?: string;
		password?: string;
		refresh?: string;
	}): Observable<Account> {
		return http.post(`${BASE_URL}/login/`, payload);
	}

	setRoleProfile(
		role_profile: number
	): Observable<Omit<Account, 'token' | 'refresh_token'>> {
		return http.post(`${BASE_URL}/set_role_profile/${role_profile}/`, {});
	}

	logout(payload: { refresh: string }): Observable<{ Success: 'True' }> {
		return http.post(`${BASE_URL}/logout/`, payload);
	}

	getRecoveryEmail(payload: { email: string }) {
		return http.post<{
			detail: string;
		}>(`${BASE_URL}/password_recovery/`, payload);
	}

	updatePassword({
		personId,
		payload,
	}: {
		personId: number;
		payload: {
			password: string;
		};
	}) {
		return http.put<{ Success: 'Updated Password.' }>(
			`${BASE_URL}/setPassword/${personId}/`,
			payload
		);
	}
}

export const accountsService = new AccountsService();
