import { Formik, FormikErrors } from 'formik';
import { debounce } from 'lodash';
import { useState, useEffect, CSSProperties } from 'react';

// services
import { Employee, Organization } from '@api-interfaces';
import { employeesService, organizationsService } from '@apis';
// hooks
import { Text } from '@atoms';
import { FieldDropdown } from '@atoms/form-ds2/field-dropdown';
import { FieldInputText } from '@atoms/form-ds2/field-input-text';
import { useAppContext } from '@hooks';

// components
import { Button } from '@new';
import { toasterService } from '@services';

// interfaces

interface Props {
  customerTitle?: string; // default to 'Customer', but ex. 'Recipient' in Quality/Surveys
  methods: {
    callback: (data: Employee) => void;
    closeForm: () => void;
  };
  data?: {
    contractId?: number;
    organization: {
      id: number;
      name: string;
    };
  };
  className?: string;
  style?: CSSProperties;
}

interface NewCustomerFormikValues {
  customer_first_name: string;
  customer_last_name: string;
  customer_email: string;
  organization: { id: number; name: string };
  contract: number;
  customerEmailEmployee: Employee;
}

export const NewCustomer = (props: Props) => {
  const {
    state: { user, selectedContracts },
  } = useAppContext();
  const { className = '', style = {} } = props;
  const currentOrgId = props.data?.organization?.id ?? user.organization.id;
  const currentContractId =
    (props.data && props.data.contractId) || selectedContracts?.[0]?.id;

  const [organizations, setOrganizations] = useState<Array<Organization>>(
    []
  );
  useEffect(() => {
    const subscription = organizationsService
      .getOrganizationHierarchy({ contract: currentContractId })
      .subscribe({
        next: (res) => setOrganizations(res),
        error: (err) => {
          toasterService.newToast({
            status: 'fail',
            message: 'Error loading organization hierarchy.',
          });
        },
      });
    return () => subscription.unsubscribe();
  }, [currentContractId]);

  const initialFormValues = {
    customer_first_name: '',
    customer_last_name: '',
    customer_email: '',
    organization: props.data?.organization ?? user.organization,
    contract: currentContractId,
    customerEmailEmployee: null,
  };

  const isValidCustomerEmailEmployeeOrg = (employee: Employee) => {
    if (!employee) {
      return true;
    }
    const customerEmailEmployeedOrgId = employee.organization.id;
    return (
      organizations.findIndex(
        (organization: Organization) =>
          organization.id === customerEmailEmployeedOrgId
      ) !== -1
    );
  };

  const getEmailError = (email: string) => {
    const regexToMatch =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const matches = regexToMatch.test(email);

    if (!matches && email !== '') {
      return 'Use the proper email format';
    }
    if (!email.length) {
      return 'An email is required';
    }
    return '';
  };

  const getEmailErrorWithOrgCheck = (values: NewCustomerFormikValues) => {
    const baseError = getEmailError(values.customer_email);
    if (baseError) {
      return baseError;
    }
    return isValidCustomerEmailEmployeeOrg(values.customerEmailEmployee)
      ? ''
      : `Email belongs to a ${
          props.customerTitle || 'customer'
        } at an unselectable organization.`;
  };

  const getEmailEmployee = debounce(
    (
      values: NewCustomerFormikValues,
      setFieldValue: (
        field: string,
        value: any,
        shouldValidate: boolean
      ) => void,
      setStatus: (status: any) => void,
      setErrors: (errors: FormikErrors<NewCustomerFormikValues>) => void
    ) => {
      const noEmployeeResetForm = () => {
        if (values.customerEmailEmployee) {
          setFieldValue('customer_first_name', '', false);
          setFieldValue('customer_last_name', '', false);
          setFieldValue('organization', currentOrgId, false);
        }
        setFieldValue('customerEmailEmployee', null, false);
      };
      const subscription = employeesService
        .getEmployees(
          { email_address: values.customer_email, limit: 1, terminated: 'any' },
          { noCustomer: true, noContract: true, noTag: true }
        )
        .subscribe({
          next: (res) => {
            const customerEmailEmployee = res.results[0];
            if (!isValidCustomerEmailEmployeeOrg(customerEmailEmployee)) {
              setFieldValue(
                'customerEmailEmployee',
                customerEmailEmployee,
                false
              );
              setErrors({
                customer_email: `Email belongs to a ${
                  props.customerTitle || 'customer'
                } at an unselectable organization.`,
              });
            } else if (customerEmailEmployee) {
              setFieldValue(
                'customer_first_name',
                customerEmailEmployee.person.first_name,
                false
              );
              setFieldValue(
                'customer_last_name',
                customerEmailEmployee.person.last_name,
                false
              );
              setFieldValue(
                'organization',
                customerEmailEmployee.organization.id,
                false
              );
              setFieldValue(
                'customerEmailEmployee',
                customerEmailEmployee,
                false
              );
              setErrors({});
            } else {
              noEmployeeResetForm();
            }
            setStatus({ searchingCustomerEmail: false });
            subscription.unsubscribe();
          },
          error: (err) => {
            toasterService.newToast({
              status: 'fail',
              message: 'Error validating email against existing employees.',
            });
            noEmployeeResetForm();
            setStatus({ searchingCustomerEmail: false });
            subscription.unsubscribe();
          },
        });
    },
    250
  );

  return (
    <Formik
      initialValues={initialFormValues}
      validate={(values) => {
        const error: any = {};
        if (
          !values.customerEmailEmployee &&
          !values.customer_first_name.length
        ) {
          error.customer_first_name = 'A first name is required';
        }
        if (
          !values.customerEmailEmployee &&
          !values.customer_last_name.length
        ) {
          error.customer_last_name = 'A last name is required';
        }

        const emailError = getEmailErrorWithOrgCheck(values);
        if (emailError) {
          error.customer_email = emailError;
        }
        return error;
      }}
      onSubmit={(values, { setSubmitting }) => {
        const payload = {
          organization: {
            id: values.organization.id,
          },
          person: {
            first_name: values.customer_first_name,
            last_name: values.customer_last_name,
            email_address: values.customer_email,
            photo_url: '',
            phone_number: '',
          },
          roles: [
            {
              contract_id: values.contract,
              role_id: 2039, // [HARDCODED]: ReferenceDataItem id for Customer
            },
          ],
        };

        employeesService.createCustomerEmployee(payload).subscribe({
          next: (contractRoleEmployee) => {
            toasterService.newToast({
              status: 'success',
              message: contractRoleEmployee.is_new
                ? `The new ${
                    props.customerTitle || 'customer'
                  } was successfully added.`
                : `The existing employee was successfully added to the ${
                    props.customerTitle || 'customer'
                  } list.`,
            });
            setSubmitting(false);
            return props.methods.callback(contractRoleEmployee);
          },
          error: (err) => {
            toasterService.newToast({
              status: 'fail',
              message: `There was an error creating a new ${
                props.customerTitle || 'customer'
              } or adding to the ${props.customerTitle || 'customer'} list.`,
            });
            return setSubmitting(false);
          },
        });
      }}
    >
      {({
        handleChange,
        handleSubmit,
        setFieldValue,
        setErrors,
        setStatus,
        isSubmitting,
        errors,
        status,
        values,
        touched,
      }) => {
        const emailOnlyEnabled: boolean =
          !!values.customerEmailEmployee ||
          !values.customer_email ||
          !!errors.customer_email ||
          status?.searchingCustomerEmail;

        return (
          <div
            className={`tw-space-y-6 t-background-purple tw-p-6 tw-rounded ${className}`}
            style={style}
          >
            <Text font="h2" color="hi-contrast">
              New {props.customerTitle ? props.customerTitle : 'Customer'}
            </Text>
            <FieldInputText
              required
              id="customer_email"
              name="customer_email"
              label="Email Address"
              type="email"
              onChange={(e) => {
                if (!getEmailError(e.target.value)) {
                  setStatus({ searchingCustomerEmail: true });
                  getEmailEmployee(
                    { ...values, customer_email: e.target.value },
                    setFieldValue,
                    setStatus,
                    setErrors
                  );
                }
                handleChange(e);
              }}
              error={touched?.customer_email && errors?.customer_email}
            />

            <div className="tw-grid tw-gap-6 sm:tw-grid-cols-2">
              <FieldInputText
                required
                id="customer_first_name"
                name="customer_first_name"
                label="First Name"
                onChange={handleChange}
                error={
                  touched?.customer_first_name && errors?.customer_first_name
                }
              />
              <FieldInputText
                required
                id="customer_last_name"
                name="customer_last_name"
                label="Last Name"
                onChange={handleChange}
                error={
                  touched?.customer_last_name && errors?.customer_last_name
                }
              />
            </div>

            <FieldDropdown
              id="organization"
              label="Organization"
              items={organizations}
              selectedItem={values.organization}
              onChange={(selectedOrg) => {
                setFieldValue('organization', selectedOrg);
              }}
              error={touched.organization && (errors.organization as string)}
              disabled={emailOnlyEnabled}
            />

            <div className="tw-flex tw-items-center tw-justify-end tw-space-x-4">
              <Button
                label="Cancel"
                onClick={props.methods.closeForm}
                color="secondary"
              />
              <Button
                label={`Add ${
                  props.customerTitle ? props.customerTitle : 'Customer'
                }`}
                onClick={handleSubmit}
                disabled={isSubmitting}
              />
            </div>
          </div>
        );
      }}
    </Formik>
  );
};
