// services
import { map } from 'rxjs/operators';

import {
	Todo,
	TodoPayload,
	TodoEmployee,
	TodoDueStatus,
	GetEmployeeTodoOrdering,
} from '@api-interfaces';
import { TimeUtils, Params } from '@core/helpers';
import {
	virtualBrownClient,
	IDjangoResponse,
} from '@core/services/django-client';
import { CommonParams, PaginationParams } from '@models';

import { todosSummaryService } from './summary';

// helpers

// interfaces

// constants
const defaultTo = TimeUtils.formatToISO(new Date());
const defaultFrom = TimeUtils.formatToISO(TimeUtils.subMonths(new Date(), 6));
const zone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const BASE_URL = 'todos';

class TodosService {
	readonly summary = todosSummaryService;

	public getTodos = (
		params: {
			to: string;
			from: string;
			status?: string;
			ordering?: string;
			search?: string;
			is_past_due?: boolean;
			due_status?: TodoDueStatus;
			parent_service?: number;
			service?: number;
			area?: number;
			is_project_work?: boolean;
			servicearea?: number;
			areatype?: number;
			root_area?: number;
			export_to_excel?: boolean;
		} & PaginationParams = {
			to: defaultTo,
			from: defaultFrom,
			limit: 10,
			offset: 0,
			status: '',
			ordering: '-created_on_date',
			search: '',
		}
	) => {
		const qs = Params.makeQueryString(params);

		return virtualBrownClient
			.get<IDjangoResponse<Todo>>(`${BASE_URL}/${qs}`)
			.pipe(map((res) => (res ? res.results : [])));
	};

	public getTodoCounts = (
		params: {
			parent_service?: number;
			service?: number;
			is_project_work?: boolean;
		} & CommonParams = {
			to: defaultTo,
			from: defaultFrom,
			time_zone: zone,
		}
	) => {
		const qs = Params.makeQueryString({ ...params, chart: 0 });
		return virtualBrownClient.get(`${BASE_URL}/summary/${qs}`).pipe(
			map((res: any) =>
				res.length
					? res[0].results
					: {
							total_count: 0,
							open_count: 0,
							open_past_due_count: 0,
							closed_past_due_count: 0,
							closed_count: 0,
							past_due_count: 0,
							closed_on_time_count: 0,
							last_closed_date: '',
							last_open_date: '',
							oldest_incomplete_date: '',
							closed_on_time_percentage: '0',
							open_past_due_percentage: '0',
							goal: 0,
							is_pass: null,
						}
			)
		);
	};

	public createTodo = (payload: TodoPayload) =>
		virtualBrownClient.post<Todo>(`${BASE_URL}/`, payload);

	public getTodoById(id: number) {
		return virtualBrownClient.get<Todo>(`${BASE_URL}/${id}/`);
	}

	public updateTodoById = (id: number, payload: TodoPayload) =>
		virtualBrownClient.put<Todo>(`${BASE_URL}/${id}/`, payload);

	public deleteTodo = (id: number) =>
		virtualBrownClient.delete<any>(`${BASE_URL}/${id}/`);

	public completeTodo = (id: number) => {
		const body = {
			is_complete: true,
			closed_date: TimeUtils.formatToISO(new Date()),
		};
		return virtualBrownClient.patch<Todo>(`${BASE_URL}/${id}/`, body);
	};

	public getTodoCharts = (
		params: {
			parent_service?: number;
			service?: number;
		} & CommonParams = {
			to: defaultTo,
			from: defaultFrom,
			time_zone: zone,
		}
	) => {
		const qs = Params.makeQueryString({ ...params, chart: '0,1' });
		return virtualBrownClient.get(`${BASE_URL}/summary/${qs}`);
	};

	public getTodoSummary = (
		params: {
			parent_service?: number;
			service?: number;
			chart: any;
		} & CommonParams = {
			to: defaultTo,
			from: defaultFrom,
			time_zone: zone,
			chart: undefined,
		}
	) => {
		const qs = Params.makeQueryString(params);
		return virtualBrownClient.get(`${BASE_URL}/summary/${qs}`);
	};

	public getEmployeeTodoData = (
		params: {
			parent_service?: number;
			service?: number;
			noCustomer?: boolean;
			limit?: number;
			order_by?: GetEmployeeTodoOrdering;
		} & CommonParams = {
			to: defaultTo,
			from: defaultFrom,
			noCustomer: true,
			limit: 500,
			offset: 0,
		}
	) => {
		const qs = Params.makeQueryString(params);
		return virtualBrownClient.get<IDjangoResponse<TodoEmployee>>(
			`${BASE_URL}/employees/${qs}`,
			{ noCustomer: params.noCustomer }
		);
	};
}

export const todosService = new TodosService();
