import { useState, useEffect } from 'react';

// components
import { useEventListener } from '@app/_new/pages/service-tracker/_components/Gantt/useEventHandler';
import { Text, ScoreChip, ModalStack, LoaderBlocks, Icon } from '@atoms';
import { safeDivide } from '@helpers';
import { useBreakpoints } from '@hooks';
import { ServiceLogTab } from '@modals/_ds2/audit/_components';
import { useAuditModalContext } from '@modals/_ds2/audit/_context/context';
import {
    buildLocationName,
    getScoreChipParams,
    TextChip,
} from '@modals/_ds2/audit/helpers';
import { Modal } from '@templates';

// Tabs
import { DetailsTab, TasksTab } from './_tabs';

// helpers

export const AreaInformation = () => {
    const {
        state: {
            inspection,
            mode,
            modals,
            areas,
            selectedArea,
            selectedAreaList,
            isLoading,
            onClose,
        },
        dispatch,
    } = useAuditModalContext();
    const bp = useBreakpoints();
    const [tab, setTab] = useState<number>(0);
    const [building, setBuilding] = useState<string>('-');
    const [score, setScore] = useState(null);
    const [type, setType] = useState(null);
    const selectedAreaIndex = selectedAreaList.findIndex(
        (areaId) => areaId === selectedArea?.id
    );

    useEffect(() => {
        if (selectedArea?.id) {
            let building = buildLocationName(selectedArea.location?.parent);
            building = building || '-';
            setBuilding(building);

            const {
                audit_score_calculated,
                audit_couldnt_score,
                audit_is_pass,
                audit_scale,
                goalline,
                inspection_score_calculated,
            } = selectedArea;
            const { type, score } = getScoreChipParams({
                mode,
                audit_score_calculated,
                audit_couldnt_score,
                audit_is_pass,
                audit_scale,
                goalline,
                inspection_score_calculated,
            });
            setScore(score);
            setType(type);
        }
    }, [selectedArea?.id]);

    const handleClose = () => {
        if (!modals['area-information']) return;
        if (modals['audit-attachments'] || modals.todo) return;
        if (!modals['audit-bottom-sheet']) {
            onClose();
        }

        dispatch({
            type: 'SET_MODALS',
            payload: {
                ...modals,
                'area-information': false,
            },
        });
        dispatch({
            type: 'SET_SELECTED_AREA_LIST',
            payload: [],
        });

        // reset
        setTab(0);
    };

    const openAudit = () => {
        dispatch({
            type: 'SET_MODALS',
            payload: {
                ...modals,
                'audit-bottom-sheet': true,
                'area-information': false,
            },
        });

        // reset
        setTab(0);
    };

    const handleIndexChange = (change: 1 | -1) => {
        if (selectedAreaList.length < 2) return;

        const index = selectedAreaIndex + change;
        if (index < 0) {
            return;
        }
        if (index >= selectedAreaList.length) {
            return;
        }
        dispatch({
            type: 'SET_SELECTED_AREA',
            payload: areas.find((area) => area.id === selectedAreaList[index]),
        });
    };
    const goNext = () => handleIndexChange(1);
    const goPrev = () => handleIndexChange(-1);

    const handleKeyDown = (e: KeyboardEvent) => {
        if (modals.todo || modals['audit-attachments']) return;

        if (e.key === 'ArrowLeft') {
            e.preventDefault();
            goPrev();
        } else if (e.key === 'ArrowRight') {
            e.preventDefault();
            goNext();
        } else if (e.key === 'Escape') {
            handleClose();
        }
    };

    useEventListener('keydown', handleKeyDown, window);

    let maxServiceLogDate;

    if (selectedArea?.media?.length) {
        maxServiceLogDate = new Date(
            Math.max(
                ...selectedArea?.media?.map((media) =>
                    new Date(media?.create_date).getTime()
                )
            )
        );
    }

    const onServiceLogClick = (
        stopId: number,
        areaId: number,
        date: string
    ) => {
        dispatch({
            type: 'SET_SELECTED_SERVICE',
            payload: {
                stopId,
                areaId,
                date,
            },
        });
        dispatch({
            type: 'SET_MODALS',
            payload: {
                ...modals,
                'service-log': true,
            },
        });
    };

    return (
        modals['area-information'] && (
            <ModalStack
                position="top-center"
                hasSpacingY
                onOverlayClick={handleClose}
            >
                {!isLoading ? (
                    <Modal.Information.Container
                        title={selectedArea.location?.name}
                        subtitle={`${building} • ${selectedArea.location?.area_type?.name}`}
                        subLink={
                            !modals['audit-bottom-sheet']
                                ? { label: 'View Audit', onClick: openAudit }
                                : undefined
                        }
                        rightHeader={
                            bp.sm ? (
                                <div className="tw-flex tw-justify-between tw-items-center tw-mb-2">
                                    <Text
                                        font="h2"
                                        color="hi-contrast"
                                        className="tw-mr-2"
                                    >
                                        Area Score
                                    </Text>

                                    {mode === 'INSPECTION' ? (
                                        <TextChip
                                            text={score}
                                            prettyNumberOptions={{
                                                decimals: 2,
                                            }}
                                            type={type}
                                            size="md"
                                        />
                                    ) : (
                                        <ScoreChip
                                            score={score}
                                            prettyNumberOptions={{
                                                decimals: 2,
                                            }}
                                            type={type}
                                            size="extra-md"
                                        />
                                    )}
                                </div>
                            ) : undefined
                        }
                        onClose={handleClose}
                        style={
                            bp.sm ? { maxHeight: 'calc(100vh - 6rem)' } : null
                        }
                        headerPagination={
                            !bp.lg && selectedAreaList.length > 1
                                ? {
                                      previousLabel:
                                          selectedAreaIndex > 0
                                              ? areas.find(
                                                    (area) =>
                                                        area.id ===
                                                        selectedAreaList[
                                                            selectedAreaIndex -
                                                                1
                                                        ]
                                                ).location?.name
                                              : undefined,
                                      previousOnClick: goPrev,
                                      nextLabel:
                                          selectedAreaIndex <
                                          selectedAreaList.length - 1
                                              ? areas.find(
                                                    (area) =>
                                                        area.id ===
                                                        selectedAreaList[
                                                            selectedAreaIndex ===
                                                            selectedAreaList.length -
                                                                1
                                                                ? 0
                                                                : selectedAreaIndex +
                                                                  1
                                                        ]
                                                ).location?.name
                                              : undefined,
                                      nextOnClick: goNext,
                                  }
                                : undefined
                        }
                        className="tw-flex tw-flex-col tw-relative"
                    >
                        {bp.lg &&
                            selectedAreaList.length > 1 &&
                            selectedAreaIndex > 0 && (
                                <button
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        goPrev();
                                    }}
                                    className="tw-absolute tw-top-1/2 tw--translate-y-1/2 tw--translate-x-full tw-z-50 tw-w-10 tw-h-10 tw-bg-neutral-900 tw-bg-opacity-50 tw-rounded-full tw-flex tw-justify-center tw-items-center tw--left-4 tw-text-neutral-100 hover:tw-text-primary-500 tw-cursor-pointer"
                                >
                                    <Icon
                                        icon="chevron-left"
                                        className="tw-text-xl"
                                    />
                                </button>
                            )}
                        {bp.lg &&
                            selectedAreaList.length - 1 > selectedAreaIndex && (
                                <button
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        goNext();
                                    }}
                                    className="tw-absolute tw-top-1/2 tw--translate-y-1/2 tw-translate-x-full tw-z-10 tw-w-10 tw-h-10 tw-bg-neutral-900 tw-bg-opacity-50 tw-rounded-full tw-flex tw-justify-center tw-items-center tw--right-4 tw-text-neutral-100 hover:tw-text-primary-500 tw-cursor-pointer"
                                >
                                    <Icon
                                        icon="chevron-right"
                                        className="tw-text-xl"
                                    />
                                </button>
                            )}
                        {!bp.sm && (
                            <div className="tw-flex tw-items-center tw-mt-4">
                                <Text
                                    font="h2"
                                    color="hi-contrast"
                                    className="tw-mr-2"
                                >
                                    Area Score
                                </Text>

                                <ScoreChip
                                    score={score}
                                    prettyNumberOptions={{ decimals: 2 }}
                                    type={type}
                                    size="sm"
                                />
                            </div>
                        )}

                        <Modal.Information.Tabs
                            className="tw-mt-4 tw-flex-shrink-0"
                            tabs={[
                                { label: 'Details' },
                                { label: 'Tasks' },
                                { label: 'Service Log' },
                            ]}
                            onClick={(index) => setTab(index)}
                        />

                        <Modal.Information.Divider
                            className="tw--mx-10 tw-px-10"
                            orientation="horizontal"
                        />

                        <div className="tw-overflow-y-auto sm:tw-pr-10 sm:tw-mr-[-2.5rem] sm:tw-pb-10 sm:tw-mb-[-2.5rem]">
                            <div
                                className={`tw-mt-6 ${
                                    tab === 0 ? '' : 'tw-hidden'
                                }`}
                            >
                                <DetailsTab />
                            </div>

                            <div
                                className={`tw-mt-10 ${
                                    tab === 1 ? '' : 'tw-hidden'
                                }`}
                            >
                                <TasksTab />
                            </div>

                            <div
                                className={`tw-mt-4 ${
                                    tab === 2 ? '' : 'tw-hidden'
                                }`}
                            >
                                <ServiceLogTab
                                    currentArea={selectedArea}
                                    maxDate={maxServiceLogDate}
                                    inspection={inspection}
                                    onViewClick={onServiceLogClick}
                                />
                            </div>
                        </div>
                    </Modal.Information.Container>
                ) : (
                    <LoaderBlocks className="tw-w-full tw-py-10" />
                )}
            </ModalStack>
        )
    );
};
