import { SummaryChart, SummaryParams } from '@models';

export namespace FrontPageSummary {
	export enum Chart {
		ContractOverallProgramScore = 1,
	}

	export enum KpiChart {
		GetProgramKPIProgramBreakdown = 1,
	}

	export interface ContractOverallProgramScore {
		number_of_service_providers: number;
		number_of_services: number;
		overall_score: number;
		service_list?: ServiceList[];
		number_of_child_services: number;
	}

	export interface ContractOverallProgramScoreSummary
		extends SummaryChart<ContractOverallProgramScore> {
		chart: 'ContractOverallProgramScore';
		number: '1';
	}

	export interface ServiceList {
		billing_amount?: number;
		billing_percent?: number;
		overall_score?: number;
		parent_service_id?: number;
		parent_service_name?: string;
		service_id?: number;
		service_list?: ServiceList[];
		service_name?: string;
		service_provider_id?: number;
		service_provider_name?: string;
	}
	export interface KPIProgramBreakdown {
		billing_amount: number;
		number_of_contracts: number;
		number_of_customers: number;
		number_of_parent_services: number;
		number_of_service_providers: number;
		number_of_services: number;
		number_of_sites: number;
		overall_score: number;
		service_list: ServiceList[];
	}

	export interface KPIProgramBreakdownSummary
		extends SummaryChart<KPIProgramBreakdown> {
		chart: 'GetProgramKPIProgramBreakdown';
		number: '1';
	}
	export interface SummaryReturnType {
		[Chart.ContractOverallProgramScore]: ContractOverallProgramScoreSummary;
	}

	export interface KPISummaryReturnType {
		[KpiChart.GetProgramKPIProgramBreakdown]: KPIProgramBreakdownSummary;
	}

	export interface Params extends SummaryParams {}

	export interface KPIParams extends SummaryParams {
		top_level?: string;
	}
}
