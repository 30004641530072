import {
	ServiceVerificationScheduleAbsenceResponse,
	ServiceVerificationScheduleAllDaysResponse,
	ServiceVerificationScheduleAssignmentEmployee,
	ServiceVerificationScheduleBurdenResponse,
	ServiceVerificationScheduleDayResponse,
	ServiceVerificationScheduleEmployeeVerificationHistory,
	ServiceVerificationScheduleTransferResponse,
	ServiceVerificationsScheduleArea,
	ServiceVerificationsScheduleAreaType,
	ServiceVerificationsScheduleBuilding,
	ServiceVerificationsScheduleEmployee,
} from '@api-interfaces';
import { virtualBrownClient } from '@core/services/django-client';
import { Params, TimeUtils } from '@helpers';
import { PaginationParams, PaginationResponse } from '@models';
import { Observable } from 'rxjs';

const BASE_URL = 'service_verifications/schedules';

const noOpts = {
	noContract: true,
	noCustomer: true,
	noTag: true,
};

class ServiceVerificationsSchedulesService {
	public getBuildings(
		params: {
			contract: number;
			search?: string;
		} & PaginationParams
	): Observable<PaginationResponse<ServiceVerificationsScheduleBuilding>> {
		if (params.search) {
			params.search = encodeURI(params.search);
		}
		return virtualBrownClient.get(
			`${BASE_URL}/admin_v1/daily_editor/buildings/${Params.makeQueryString(params)}`,
			noOpts
		);
	}

	public getAreaTypes(
		params: {
			contract: number;
			building?: number;
			search?: string;
		} & PaginationParams
	): Observable<PaginationResponse<ServiceVerificationsScheduleAreaType>> {
		if (params.search) {
			params.search = encodeURI(params.search);
		}
		return virtualBrownClient.get(
			`${BASE_URL}/admin_v1/daily_editor/area_types/${Params.makeQueryString(params)}`,
			noOpts
		);
	}

	public getAreas(
		params: {
			contract: number;
			building?: number;
			area_type?: number;
			search?: string;
		} & PaginationParams
	): Observable<PaginationResponse<ServiceVerificationsScheduleArea>> {
		if (params.search) {
			params.search = encodeURI(params.search);
		}
		return virtualBrownClient.get(
			`${BASE_URL}/admin_v1/daily_editor/rooms/${Params.makeQueryString(params)}`,
			noOpts
		);
	}

	public getAssociates(
		params: {
			contract: number;
			search?: string;
		} & PaginationParams
	): Observable<PaginationResponse<ServiceVerificationsScheduleEmployee>> {
		if (params.search) {
			params.search = encodeURI(params.search);
		}
		return virtualBrownClient.get(
			`${BASE_URL}/admin_v1/daily_editor/employees/${Params.makeQueryString(params)}`,
			noOpts
		);
	}

	public getAssignments(params: {
		contract: number;
		schedule_date: Date;
	}): Observable<
		PaginationResponse<ServiceVerificationScheduleAssignmentEmployee>
	> {
		const query = Params.makeQueryString({
			...params,
			schedule_date: TimeUtils.format(params.schedule_date, 'YYYY-MM-DD'),
			limit: 1000,
			offset: 0,
		});
		return virtualBrownClient.get(
			`${BASE_URL}/admin_v1/daily_editor/employee_assignments/${query}`,
			noOpts
		);
	}

	public scheduleAllDays(body: {
		method_action: 'ASSIGN_ALL_DAYS' | 'UNASSIGN_ALL_DAYS';
		contract_id: number;
		employee_id: number;
		area_id: number;
	}): Observable<ServiceVerificationScheduleAllDaysResponse> {
		return virtualBrownClient.put(
			`${BASE_URL}/manager_v1/schedule/`,
			body,
			noOpts
		);
	}

	public setBurden(body: {
		contract_id: number;
		employee_id: number;
		day: number;
		burden: number;
	}): Observable<ServiceVerificationScheduleBurdenResponse> {
		return virtualBrownClient.put(
			`${BASE_URL}/manager_v1/schedule_day/`,
			{
				method_action: 'SET_BURDEN',
				...body,
			},
			noOpts
		);
	}

	public setAbsence(body: {
		method_action: 'MARK_ABSENT' | 'MARK_PRESENT';
		contract_id: number;
		employee_id: number;
		schedule_date: Date;
	}): Observable<ServiceVerificationScheduleAbsenceResponse> {
		return virtualBrownClient.put(
			`${BASE_URL}/manager_v1/schedule_date/`,
			{
				...body,
				schedule_date: TimeUtils.format(
					body.schedule_date,
					'YYYY-MM-DD'
				),
				day: body.schedule_date.getDay(),
			},
			noOpts
		);
	}

	public scheduleDay(body: {
		method_action: 'ASSIGN_DAY' | 'UNASSIGN_DAY';
		contract_id: number;
		employee_id: number;
		area_id: number;
		day: number;
	}): Observable<ServiceVerificationScheduleDayResponse> {
		return virtualBrownClient.put(
			`${BASE_URL}/manager_v1/schedule_area/`,
			body,
			noOpts
		);
	}

	public transfer(body: {
		contract_id: number;
		employee_id: number;
		area_id: number;
		transfer_to_id: number;
		schedule_date: Date;
	}): Observable<ServiceVerificationScheduleTransferResponse> {
		return virtualBrownClient.put(
			`${BASE_URL}/manager_v1/schedule_transfer/`,
			{
				...body,
				method_action: 'TRANSFER',
				schedule_date: TimeUtils.format(
					body.schedule_date,
					'YYYY-MM-DD'
				),
				day: body.schedule_date.getDay(),
			},
			noOpts
		);
	}

	public cancelTransfer(body: {
		contract_id: number;
		area_id: number;
		transfer_to_id: number;
		schedule_date: Date;
	}): Observable<ServiceVerificationScheduleTransferResponse> {
		return virtualBrownClient.put(
			`${BASE_URL}/manager_v1/schedule_transfer/`,
			{
				...body,
				method_action: 'CANCEL_TRANSFER',
				schedule_date: TimeUtils.format(
					body.schedule_date,
					'YYYY-MM-DD'
				),
				day: body.schedule_date.getDay(),
			},
			noOpts
		);
	}

	public getVerificationHistory(
		params: {
			contract: number;
			employee: number;
			from: string | Date;
			to: string | Date;
		} & PaginationParams
	): Observable<
		PaginationResponse<ServiceVerificationScheduleEmployeeVerificationHistory>
	> {
		return virtualBrownClient.get(
			`${BASE_URL}/history_v1/viewer/employee/${Params.makeQueryString(params)}`,
			noOpts
		);
	}
}

export const serviceVerificationsSchedulesService =
	new ServiceVerificationsSchedulesService();
