// services

// helpers
import { Observable } from 'rxjs';

import { EmployeesSummary } from '@api-interfaces';
import { Params } from '@helpers';

// interfaces
import { virtualBrownClient } from '@services';

// constants
const BASE_URL = 'employees/summary';

class EmployeesSummaryService {
	getSummaries<T extends EmployeesSummary.Chart>(
		params: EmployeesSummary.EmployeeSummaryParams<T>
	): Observable<EmployeesSummary.SummaryReturnType[T][]> {
		const query = {
			...params,
			chart: params.chart.join(','),
		};
		return virtualBrownClient.get(
			`${BASE_URL}/${Params.makeQueryString(query)}`,
			{
				noCustomer: params.customer !== undefined,
				noContract: params.contract !== undefined,
				noTag: params.tag !== undefined,
			}
		);
	}
	getEmployeePositionCounts<T extends EmployeesSummary.Chart>(
		params: EmployeesSummary.EmployeePositionCountParams<T>
	): Observable<any> {
		const query = {
			...params,
			chart: params.chart.join(','),
		};
		return virtualBrownClient.get(
			`${BASE_URL}/${Params.makeQueryString(query)}`,
			{
				noCustomer: params.customer !== undefined,
				noContract: params.contract !== undefined,
				noTag: params.tag !== undefined,
			}
		);
	}
}

export const employeesSummaryService = new EmployeesSummaryService();
