import { Address } from '@api-interfaces';
import {
	SummaryChart,
	SummaryParams,
	TimeScale,
	GeoLocation,
	IsPass,
} from '@models';

export interface ReportItsSummaryParams<T> extends SummaryParams<T> {
	customer_reports_only?: boolean;
	verticals?: string | number;
}

export namespace ReportItsSummary {
	export enum Chart {
		GetReportItCounts = 0,
		GetReportItCountsByReportType = 1,
		GetReportItCountsByMonth = 2,
		GetReportItCountsByMonthByReportType = 3,
		GetReportItCountsBySite = 4,
		GetGoodCatchCounts = 5,
		GetGoodCatchCountsByReportType = 6,
		GetGoodCatchCountsByMonth = 7,
		GetGoodCatchCountsByMonthByReportType = 8,
		GetReportItCountsByEmployee = 9,
		GetReportItGetAcceptanceRateByService = 10,
		GetReportItCountsByTag = 11,
		GetReportItCountsByBuilding = 12,
		GetReportItLeaderboard = 13,
		GetReportItCountsByOrganization = 14,
		GetReportItCountsByShift = 15,
		GetReportItCountsByAreaType = 16,
		GetReportItCountsByMasterAreaType = 17,
		GetReportItCountsByVertical = 18,
		GetReportItCountsByVerticalOrganizationList = 19,
	}

	export interface ReportItCounts {
		Accepted: number;
		GoodCatches: number;
		Pending: number;
		Rejected: number;
		SentToCustomer: number;
		Submitted: number;
		acceptance_rate: number;
		latest_reportit: string;
	}

	export interface ReportItCountsSummary
		extends SummaryChart<ReportItCounts> {
		chart: 'GetReportItCounts';
		number: '0';
	}

	export interface ReportItCountsByReportType {
		count: number;
		name: string;
		percentage: string;
		type_id: number;
	}

	export interface ReportItCountsByReportTypeSummary
		extends SummaryChart<ReportItCountsByReportType[]> {
		chart: 'GetReportItCountsByReportType';
		number: '1';
	}

	export interface ReportItCountsByMonth {
		accepted: number;
		mo: string;
		rejected: number;
	}

	export interface ReportItCountsByMonthSummary
		extends SummaryChart<ReportItCountsByMonth[]> {
		chart: 'GetReportItCountsByMonth';
		number: '2';
		time_scale: TimeScale;
	}

	export interface ReportItCountsByMonthByReportType {
		report_type: string;
		totals: [{ mo: string; total: number }];
	}

	export interface ReportItCountsByMonthByReportTypeSummary
		extends SummaryChart<ReportItCountsByMonthByReportType[]> {
		chart: 'GetReportItCountsByMonthByReportType';
		number: '3';
		time_scale: TimeScale;
	}

	export interface ReportItCountsBySite extends Address, GeoLocation, IsPass {
		accepted_count: number;
		has_tag_groups: boolean;
		id: number;
		last_submit_date: string;
		name: string;
		organization_name: string;
		pending_review_count: number;
		rejected_count: number;
		total_count: number;
		unknown_count: number;
	}

	export interface ReportItCountsBySiteSummary
		extends SummaryChart<ReportItCountsBySite[]> {
		chart: 'GetReportItCountsBySite';
		max_accepted_count: number;
	}

	export interface GoodCatchCounts {
		latest_good_catch: string;
		total_critical_good_catches: number;
		total_good_catches: number;
	}

	export interface GoodCatchCountsSummary
		extends SummaryChart<GoodCatchCounts> {
		chart: 'GetGoodCatchCounts';
		number: '5';
	}

	export interface GoodCatchCountsByReportType {
		count: number;
		name: string;
		percentage: string;
	}

	export interface GoodCatchCountsByReportTypeSummary
		extends SummaryChart<GoodCatchCountsByReportType[]> {
		chart: 'GetGoodCatchCountsByReportType';
		number: '6';
	}

	export interface GoodCatchCountsByMonth {
		good_catches: number;
		mo: string;
	}

	export interface GoodCatchCountsByMonthSummary
		extends SummaryChart<GoodCatchCountsByMonth[]> {
		chart: 'GetGoodCatchCountsByMonth';
		number: '7';
	}

	export interface GoodCatchCountsByMonthByReportType {
		counts: null | number;
		mo: string;
	}

	export interface GoodCatchCountsByMonthByReportTypeSummary
		extends SummaryChart<GoodCatchCountsByMonthByReportType[]> {
		chart: 'GetGoodCatchCountsByMonthByReportType';
		number: '8';
	}

	export interface ReportItCountsByEmployee {
		acceptance_rate: number;
		accepted_count: number;
		first_name: string;
		id: number;
		last_name: string;
		pending_count: number;
		rejected_count: number;
		total_count: number;
	}

	export interface ReportItCountsByEmployeeSummary
		extends SummaryChart<ReportItCountsByEmployee[]> {
		chart: 'GetReportItCountsByEmployee';
		number: '9';
	}

	export interface ReportItGetAcceptanceRateByService {
		acceptance_rate: number;
		accepted_count: number;
		id: null | number;
		name: null | string;
		total_count: number;
	}

	export interface ReportItGetAcceptanceRateByServiceSummary
		extends SummaryChart<ReportItGetAcceptanceRateByService[]> {
		chart: 'GetReportItGetAcceptanceRateByService';
		number: '10';
	}

	export interface ReportItCountsByTag extends GeoLocation, IsPass {
		accepted_count: number;
		contract_id: number;
		contract_name: string;
		id: number;
		last_submit_date: string;
		name: string;
		pending_review_count: number;
		rejected_count: number;
		start_date: null | string;
		total_count: number;
		unknown_count: number;
	}

	export interface ReportItCountsByTagSummary
		extends SummaryChart<ReportItCountsByTag[]> {
		chart: 'GetReportItCountsByTag';
		max_total_count: number;
	}

	export interface ReportItCountsByBuilding {
		accepted_count: number;
		contract_id: number;
		id: number;
		last_submit_date: string;
		lat: number;
		lon: number;
		name: string;
		pending_review_count: number;
		rejected_count: number;
		total_count: number;
		unknown_count: number;
	}

	export interface ReportItCountsByBuildingSummary
		extends SummaryChart<ReportItCountsByBuilding[]> {
		chart: 'GetReportItCountsByBuilding';
		max_total_count: number;
	}

	export interface ReportItLeaderboard {
		employee_submitted_by_id: number;
		first_name: string;
		last_name: string;
		photo_url: string;
		reportits_accepted: number;
		total_reports: number;
	}

	export interface ReportItLeaderboardSummary
		extends SummaryChart<ReportItLeaderboard[]> {
		chart: 'GetReportItLeaderboard';
		number: '13';
	}

	export interface ReportItCountsByOrganization {
		Accepted: number;
		GoodCatches: number;
		Pending: number;
		Rejected: number;
		Submitted: number;
		customer_id: number;
		customer_name: string;
	}

	export interface ReportItCountsByOrganizationSummary
		extends SummaryChart<ReportItCountsByOrganization[]> {
		chart: 'GetReportItCountsByOrganization';
		number: '14';
	}

	export interface ReportItCountsByShift {
		customer_name: number;
		shift_id: number;
		shift_name: string;
		total_count: number;
		accepted_count: number;
		rejected_count: number;
	}

	export interface ReportItCountsByShiftsSummary
		extends SummaryChart<ReportItCountsByShift[]> {
		chart: 'GetReportItCountsByShift';
		number: '15';
		most_recent_record: string;
	}

	export interface ReportItCountsByAreaType {
		area_type_id: number;
		area_type_name: string;
		accepted_count: number;
		rejected_count: number;
		total_count: number;
	}

	export interface ReportItCountsByAreaTypesSummary
		extends SummaryChart<ReportItCountsByAreaType[]> {
		chart: 'GetReportItCountsByAreaType';
		number: '16';
		most_recent_record: string;
	}

	export interface ReportItCountsByMasterAreaType {
		master_area_type_id: number;
		master_area_type_name: string;
		accepted_count: number;
		rejected_count: number;
		total_count: number;
	}

	export interface ReportItCountsByMasterAreaTypesSummary
		extends SummaryChart<ReportItCountsByMasterAreaType[]> {
		chart: 'GetReportItCountsByMasterAreaType';
		number: '17';
		most_recent_record: string;
	}

	export interface ReportItCountsByVerticals {
		count?: number;
		id?: number;
		name?: string;
		accepted?: number;
		rejected?: number;
	}

	export interface ReportItCountsByVerticalsSummary
		extends SummaryChart<ReportItCountsByVerticals[]> {
		chart: 'GetReportItCountsByVertical';
		number: '18';
	}
	export interface ReportItCountsByVerticalsOrganizationList {
		count?: number;
		id?: number;
		name?: string;
		accepted?: number;
		rejected?: number;
	}

	export interface ReportItCountsByVerticalsOrganizationListSummary
		extends SummaryChart<ReportItCountsByVerticalsOrganizationList[]> {
		chart: 'GetReportItCountsByVerticalOrganizationList';
		number: '19';
	}

	export interface SummaryReturnType {
		[Chart.GetReportItCounts]: ReportItCountsSummary;
		[Chart.GetReportItCountsByReportType]: ReportItCountsByReportTypeSummary;
		[Chart.GetReportItCountsByMonth]: ReportItCountsByMonthSummary;
		[Chart.GetReportItCountsByMonthByReportType]: ReportItCountsByMonthByReportTypeSummary;
		[Chart.GetReportItCountsBySite]: ReportItCountsBySiteSummary;
		[Chart.GetGoodCatchCounts]: GoodCatchCountsSummary;
		[Chart.GetGoodCatchCountsByReportType]: GoodCatchCountsByReportTypeSummary;
		[Chart.GetGoodCatchCountsByMonth]: GoodCatchCountsByMonthSummary;
		[Chart.GetGoodCatchCountsByMonthByReportType]: GoodCatchCountsByMonthByReportTypeSummary;
		[Chart.GetReportItCountsByEmployee]: ReportItCountsByEmployeeSummary;
		[Chart.GetReportItGetAcceptanceRateByService]: ReportItGetAcceptanceRateByServiceSummary;
		[Chart.GetReportItCountsByTag]: ReportItCountsByTagSummary;
		[Chart.GetReportItCountsByBuilding]: ReportItCountsByBuildingSummary;
		[Chart.GetReportItLeaderboard]: ReportItLeaderboardSummary;
		[Chart.GetReportItCountsByOrganization]: ReportItCountsByOrganizationSummary;
		[Chart.GetReportItCountsByShift]: ReportItCountsByShiftsSummary;
		[Chart.GetReportItCountsByAreaType]: ReportItCountsByAreaTypesSummary;
		[Chart.GetReportItCountsByMasterAreaType]: ReportItCountsByMasterAreaTypesSummary;
		[Chart.GetReportItCountsByVertical]: ReportItCountsByVerticalsSummary;
		[Chart.GetReportItCountsByVerticalOrganizationList]: ReportItCountsByVerticalsOrganizationListSummary;
	}
}
