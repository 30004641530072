import { useState, useRef, useEffect } from 'react';

// components
import { IconButtonFlat } from '@atoms';

// helpers
import { useBreakpoints } from '@hooks';
import { Menu } from '@molecules';

// interfaces
import { BottomSheetOverflowProps } from './bottom-sheet.overflow.interfaces';

export const BottomSheetOverflow = ({
  overflowItems,
  onClose,
}: BottomSheetOverflowProps) => {
  const [isOverflowOpen, setIsOverflowOpen] = useState(false);
  const overflowRef = useRef<HTMLDivElement>(null);
  const { xs } = useBreakpoints();
  const hasOverflowItems = overflowItems?.length > 0;

  useEffect(() => {
    if (isOverflowOpen) {
      document.addEventListener('mousedown', handleOutsideOverflowClick);
    }
    return () => {
      document.removeEventListener('mousedown', handleOutsideOverflowClick);
    };
  }, [isOverflowOpen]);

  function handleToggleOverflowMenu() {
    setIsOverflowOpen(!isOverflowOpen);
  }

  function handleOutsideOverflowClick(e: MouseEvent) {
    if (isOverflowOpen) {
      if (!overflowRef.current.contains(e.target as HTMLElement)) {
        setIsOverflowOpen(false);
      }
    }
  }

  return (
    <div className="tw-flex tw-space-x-4">
      {hasOverflowItems && (
        <div ref={overflowRef} className="tw-inline tw-relative">
          <IconButtonFlat
            icon={xs ? 'ellipsis-h' : 'ellipsis-v'}
            onClick={handleToggleOverflowMenu}
            type="secondary"
            className="tw-h-10 tw-w-10 tw-text-xl"
          />
          {isOverflowOpen && (
            <Menu
              className="tw-absolute tw-z-50 tw-right-0 tw-w-32"
              menuItems={overflowItems.map((item) => ({
                ...item,
                onClick: () => {
                  item.onClick?.();
                  setIsOverflowOpen(false);
                },
              }))}
            />
          )}
        </div>
      )}
      <IconButtonFlat
        icon={['far', 'times']}
        onClick={onClose}
        type="secondary"
        className="tw-h-10 tw-w-10 tw-text-2xl"
      />
    </div>
  );
};
