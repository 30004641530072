// interfaces
import isNil from 'lodash/isNil';
import { Observable } from 'rxjs';

// services
import { RequestsSummary, RequestsSummaryParams } from '@api-interfaces';
import { virtualBrownClient } from '@core/services/django-client';

// helpers
import { Params } from '@helpers';

// interfaces

const BASE_URL = 'requests/summary';

const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

class RequestsSummaryService {
	getSummaries<T extends RequestsSummary.Chart>(
		params: RequestsSummaryParams<T>
	): Observable<RequestsSummary.SummaryReturnType[T][]> {
		const query = {
			...params,
			chart: params.chart.join(','),
			time_zone: params.time_zone || timeZone,
		};
		return virtualBrownClient.get(
			`${BASE_URL}/${Params.makeQueryString(query)}`,
			{
				noCustomer: !isNil(params.customer),
				noContract: !isNil(params.contract),
				noTag: !isNil(params.tag_group),
			}
		);
	}
}

export const requestsSummaryService = new RequestsSummaryService();
