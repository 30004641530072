import { forwardRef } from 'react';

// components
import { Text } from '@atoms';
import { pluralize } from '@core/helpers';

import { ErrorText } from '../error-text';
import { Label } from '../label';
import { MultiSelect } from '../multi-select';

// helpers

// interfaces
import { FieldMultiSelectProps } from './field-multi-select.interfaces';

export const FieldMultiSelect = forwardRef<
    HTMLInputElement,
    FieldMultiSelectProps
>(
    (
        {
            id,
            label,
            labelFont,
            error,
            required,
            hasSelectAll,
            selectAllFont,
            selectedCountLabel,
            dataTest,
            ...props
        },
        ref
    ) => {
        const isSelectAll = props.items.length === props.selectedItems.length;

        return (
            <fieldset
                data-ds2="field-multi-select"
                data-test={dataTest}
                className="tw-w-full tw-space-y-1.5"
            >
                <div className="tw-flex tw-justify-between">
                    <Label
                        htmlFor={id}
                        label={label}
                        font={labelFont}
                        required={required}
                    />
                    <div className="tw-flex">
                        {selectedCountLabel?.length > 0 && (
                            <Text
                                font="body-sm"
                                color="hi-contrast"
                                className={`tw-capitalize ${
                                    hasSelectAll
                                        ? 'tw-mr-4 sm:tw-mr-6 md:tw-mr-8'
                                        : ''
                                }`}
                            >
                                {props?.selectedItems?.length}{' '}
                                {pluralize(
                                    selectedCountLabel,
                                    `${selectedCountLabel}s`,
                                    props?.selectedItems?.length
                                )}{' '}
                                <span className="tw-hidden sm:tw-inline-block">
                                    Selected
                                </span>
                            </Text>
                        )}
                        {hasSelectAll && (
                            <button
                                type="button"
                                className="tw-text-theme-primary-500-300 hover:tw-text-theme-primary-700-100 active:tw-text-primary-500 dark:active:tw-text-primary-700 disabled:tw-cursor-not-allowed disabled:tw-pointer-events-none disabled:tw-opacity-40"
                                disabled={props.disabled}
                                onClick={() => {
                                    props.onChange(
                                        isSelectAll ? [] : props.items
                                    );
                                }}
                            >
                                <Text
                                    font={selectAllFont}
                                    color="current-color"
                                    className="tw-pointer-events-none tw-font-bold"
                                >
                                    {props.items.length > 0 && isSelectAll
                                        ? 'Deselect All'
                                        : 'Select All'}
                                </Text>
                            </button>
                        )}
                    </div>
                </div>
                <MultiSelect ref={ref} id={id} error={!!error} {...props} />
                <ErrorText error={error} />
            </fieldset>
        );
    }
);

FieldMultiSelect.defaultProps = {
    labelFont: 'body-sm',
    selectAllFont: 'body-sm',
};
