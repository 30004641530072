// services

// helpers
import { Observable } from 'rxjs';

import { ReportItsSummary, ReportItsSummaryParams } from '@api-interfaces';
import { Params } from '@helpers';

// interfaces
import { virtualBrownClient } from '@services';

// constants
const BASE_URL = 'reportits/summary';
const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

class ReportItsSummaryService {
	getSummaries<T extends ReportItsSummary.Chart>(
		params: ReportItsSummaryParams<T>
	): Observable<ReportItsSummary.SummaryReturnType[T][]> {
		const query = {
			...params,
			chart: params.chart.join(','),
			time_zone: params.time_zone || timeZone,
		};
		return virtualBrownClient.get(
			`${BASE_URL}/${Params.makeQueryString(query)}`,
			{
				noCustomer: params.customer !== undefined,
				noContract: params.contract !== undefined,
				noTag: params.tag !== undefined,
			}
		);
	}
}

export const reportItsSummaryService = new ReportItsSummaryService();
