// DON'T ADD PROPS UNLESS CONFIRMED TO BE CONSTANT ACROSS ALL PIE CHARTS
export const PIE_OPTIONS = {
	borderWidth: 0, // removes white space between slices
	cursor: 'pointer',
	dataLabels: {
		enabled: false, // due to responsiviness try push back design and have these in a legend on bottom
		connectorWidth: 0, // removes connector between label and slice
	},
	states: {
		hover: {
			enabled: false,
		},
	},
};
