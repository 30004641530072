import { ScoringThumb } from '@atoms';

// interfaces
import { ScoringThumbsProps } from './scoring-thumbs.interfaces';

export const ScoringThumbs = ({
  className,
  style,
  checked,
  ...props
}: ScoringThumbsProps) => {
  return (
    <div className={className} style={style}>
      <div className="tw-flex tw-space-x-4">
        <ScoringThumb
          icon="thumbs-down"
          value="fail"
          {...(checked === 'fail' ? { checked: true } : undefined)}
          {...props}
        />
        <ScoringThumb
          icon="thumbs-up"
          value="pass"
          {...(checked === 'pass' ? { checked: true } : undefined)}
          {...props}
        />
      </div>
    </div>
  );
};
