import { SizeProp } from '@fortawesome/fontawesome-svg-core';

import { badgeColors } from '@helpers';

import { IconBadgeProps } from './icon-badge.interfaces';

export const iconBadgeSize: { [K in IconBadgeProps['size']]: string } = {
	sm: 'tw-h-4 tw-w-4',
	md: 'tw-h-9 tw-w-9',
	lg: 'tw-h-12 tw-w-12',
};

export const iconSize: { [K in IconBadgeProps['size']]: SizeProp } = {
	sm: 'xs',
	md: '1x',
	lg: 'lg',
};

export const iconBadgeType: { [K in IconBadgeProps['type']]: string } = {
	default:
		'tw-bg-neutral-500 dark:tw-bg-neutral-600 tw-text-neutral-500 dark:tw-text-neutral-500',
	primary: 'tw-bg-theme-primary-500-300 tw-text-theme-primary-500-300',
	danger: 'tw-bg-theme-danger-500-300 tw-text-theme-danger-500-300',
	success: 'tw-bg-theme-success-700-300 tw-text-theme-success-700-300',
	warning: 'tw-bg-theme-warning-500-300 tw-text-theme-warning-500-300',
	custom: '',
	pinkle: badgeColors.pinkle,
	'sky-blue': badgeColors['sky-blue'],
	purple: badgeColors.purple,
	'yellow-orange': badgeColors['yellow-orange'],
};
