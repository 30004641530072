import { useMemo, useRef, useEffect } from 'react';

// services
import { Subscription } from 'rxjs';

import { CUSTOMER_WORK_ORDER, WorkOrderStatusKey } from '@api-interfaces';
import { mixpanelService, workOrdersService } from '@apis';
import { nullPOOptionName } from '@app/modals/_ds2/work-order-form/_helpers';
import { ModalStack } from '@atoms';
import { sendError } from '@helpers';
import { toasterService } from '@services';

// helpers
import { Modal } from '@templates';

import { useWorkOrderModalContext } from '../../_context';
import { mixpanelCloseQualityWorkOrder } from '@app/mixpanel/MixpanelPageTrack.tsx';

// components

// interfaces

export const CloseWorkOrderPromptModal = () => {
    const {
        workOrder,
        setWorkOrder,
        setIsEditing,
        setIsClosing,
        setActiveModals,
        activeModals,
        hasBeenUpdated,
    } = useWorkOrderModalContext();

    const subscription = useRef(new Subscription());

    useEffect(() => {
        return () => {
            subscription.current.unsubscribe();
        };
    }, []);

    const canClose = useMemo(() => {
        const useCustomerWorkOrderNumber =
            workOrder?.po_number === CUSTOMER_WORK_ORDER;
        const SBMBilling =
            workOrder.po_number !== nullPOOptionName
                ? !!workOrder.po_number && !!workOrder.po_number_id
                : true;

        const isBillable =
            !!workOrder.bu_name &&
            !!workOrder.net_suite_id &&
            SBMBilling &&
            (useCustomerWorkOrderNumber
                ? workOrder?.customer_work_order &&
                  workOrder?.customer_work_order.trim()
                : true);
        workOrder?.cost_line_items.every(
            (cost_line_item) =>
                !!cost_line_item?.quantity &&
                !!cost_line_item?.unitcost &&
                !!cost_line_item?.service?.id &&
                !!cost_line_item?.work_type &&
                !!cost_line_item?.billing_description
        );

        return (
            !!workOrder.title &&
            !!workOrder.description &&
            !!workOrder.due_date &&
            !!workOrder.work_complete_date &&
            (workOrder.is_billable ? isBillable : true)
        );
    }, [workOrder]);

    function handleCloseWorkOrder() {
        subscription.current = workOrdersService
            .updateWorkOrderById(workOrder.id, {
                work_order_status: WorkOrderStatusKey.CLOSED,
                close_date: new Date().toISOString(),
            })
            .subscribe({
                next: (workOrder) => {
                    setWorkOrder(workOrder);
                    hasBeenUpdated.current = true;
                    toasterService.newToast({
                        status: 'success',
                        message: 'Work Order successfully closed',
                    });
                    mixpanelCloseQualityWorkOrder();
                    handleCancel();
                },
                error: sendError(),
            });
    }

    function handleCancel() {
        setActiveModals({
            ...activeModals,
            'close-work-order': false,
        });
    }

    return (
        activeModals['close-work-order'] && (
            <ModalStack position="top-half-center">
                {canClose ? (
                    <Modal.Prompt
                        title="Confirmation?"
                        prompt={`Are you sure you want to close Work Order #${workOrder.id}?`}
                        onCancel={handleCancel}
                        buttons={[
                            {
                                label: 'Cancel',
                                color: 'alternate',
                                onClick: handleCancel,
                            },
                            {
                                label: 'Yes',
                                color: 'primary',
                                onClick: handleCloseWorkOrder,
                            },
                        ]}
                    />
                ) : (
                    <Modal.Prompt
                        title="Unable to Close?"
                        prompt={`To close Work Order #${workOrder.id} you must complete all required fields.`}
                        onCancel={handleCancel}
                        buttons={[
                            {
                                label: 'Cancel',
                                color: 'alternate',
                                onClick: handleCancel,
                            },
                            {
                                label: 'Edit Work Order',
                                color: 'primary',
                                onClick: () => {
                                    setActiveModals({
                                        ...activeModals,
                                        'close-work-order': false,
                                    });
                                    setIsClosing(true);
                                    setIsEditing(true);
                                },
                            },
                        ]}
                    />
                )}
            </ModalStack>
        )
    );
};
