// services
import isNil from 'lodash/isNil';
import { Observable } from 'rxjs';

import { ScorecardsSummary } from '@api-interfaces';
import { virtualBrownClient } from '@core/services/django-client';

// helpers
import { Params } from '@helpers';

// interfaces

// constants
const BASE_URL = 'scorecards/summary';
const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

class ScorecardsSummaryService {
	public getSummaries<T extends ScorecardsSummary.Chart>(params: {
		chart: T[];
		from: string | Date;
		to: string | Date;
		time_zone?: string;
		customer?: string | number;
		contract?: string | number | null;
		tag?: string | number;
		tag_group?: string | number;
		provider?: number;
		employee?: number;
		order_by?: string;
		limit?: number;
	}): Observable<ScorecardsSummary.SummaryReturnType[T][]> {
		const query = {
			...params,
			chart: params.chart.join(','),
			time_zone: params.time_zone || timeZone,
		};

		return virtualBrownClient.get(
			`${BASE_URL}/${Params.makeQueryString(query)}`,
			{
				noCustomer: !isNil(params.customer),
				noContract: !isNil(params.contract),
				noTag: !isNil(params.tag),
			}
		);
	}

	public getGapReportSummaries<T extends ScorecardsSummary.Chart>(params: {
		chart: T[];
		from: string | Date;
		to: string | Date;
		time_zone?: string;
		customer?: string | number;
		contract?: string | number | null;
		tag?: string | number;
		tag_group?: string | number;
		provider?: number;
		employee?: number;
		order_by?: string;
		limit?: number;
		noOptions?: boolean;
		noCustomer?: boolean;
		noContract?: boolean;
		noTag?: boolean;
	}): Observable<ScorecardsSummary.SummaryReturnType[T][]> {
		const query = {
			...params,
			chart: params.chart.join(','),
			time_zone: params.time_zone || timeZone,
		};

		return virtualBrownClient.get(
			`${BASE_URL}/${Params.makeQueryString(query)}`,
			params?.noOptions &&
				(params?.noContract || params?.noCustomer || params?.noTag)
				? {
						noContract: params?.noContract,
						noCustomer: params?.noCustomer,
						noTag: params?.noTag,
					}
				: undefined
		);
	}
}

export const scorecardsSummaryService = new ScorecardsSummaryService();
