// services
import {
  SurveyListItem,
  Survey,
  GetSurveysOrdering,
  SurveyPayload,
  SurveyRecipientsContract,
  SurveyResponsePayload,
  SurveyResponseQuestionAnswer,
  SurveyResponseAnswers,
  SurveyResponse,
  SurveyStatus,
} from '@api-interfaces';
import { Params } from '@helpers';
import { PaginationResponse } from '@models';
import { virtualBrownClient } from '@services';
import { RequestOptions } from '@services/http-client';

import { surveysPublicService } from './public';
import { surveysSummaryService } from './summary';

// helpers

// interfaces

const BASE_URL = 'surveys';

class SurveysService {
  readonly summary = surveysSummaryService;

  readonly public = surveysPublicService;

  getSurveys(params: {
    search?: string;
    from?: Date;
    to?: Date;
    ordering?: GetSurveysOrdering;
    offset?: number;
    limit?: number;
    status?: SurveyStatus | SurveyStatus[];
  }) {
    return virtualBrownClient.get<PaginationResponse<SurveyListItem>>(
      `${BASE_URL}/${Params.makeQueryString(params || {})}`
    );
  }

  getSurveyById(id: number) {
    return virtualBrownClient.get<Survey>(`${BASE_URL}/${id}/`);
  }

  createSurvey(payload: SurveyPayload) {
    return virtualBrownClient.post<Survey>(`${BASE_URL}/`, payload);
  }

  patchSurveyById(surveyId: number, payload: Partial<SurveyPayload>) {
    return virtualBrownClient.patch<Survey>(
      `${BASE_URL}/${surveyId}/`,
      payload
    );
  }

  deleteSurveyById(surveyId: number) {
    return virtualBrownClient.delete<any>(`${BASE_URL}/${surveyId}/`);
  }

  getSurveyRecipientsContractsBySurveyId(
    surveyId: number,
    params?: { limit?: number }
  ) {
    return virtualBrownClient.get<PaginationResponse<SurveyRecipientsContract>>(
      `${BASE_URL}/${surveyId}/recipients/contracts/${Params.makeQueryString(
        params || {}
      )}`
    );
  }

  submitSurveyResponse(surveyId: number, payload: SurveyResponsePayload) {
    return virtualBrownClient.post<{
      answer_sheet: number;
      contract: number;
      create_date: string;
      hash_t: null;
      id: number;
      survey: number;
    }>(`${BASE_URL}/${surveyId}/responses/`, payload);
  }

  getSurveyResponseQuestionAnswers(
    {
      surveyId,
      questionId,
      ...params
    }: {
      surveyId: number;
      questionId: number;
      limit?: number;
      offset?: number;
      contract?: number;
      customer?: number;
    },
    options: RequestOptions = {}
  ) {
    return virtualBrownClient.get<
      PaginationResponse<SurveyResponseQuestionAnswer>
    >(
      `${BASE_URL}/${surveyId}/responses/questions/${questionId}/answers/${Params.makeQueryString(
        params || {}
      )}`,
      options
    );
  }

  getSurveyResponseById({
    surveyId,
    responseId,
    ...params
  }: {
    surveyId: number;
    responseId: number;
    offset?: number;
    limit?: number;
  }) {
    return virtualBrownClient.get<PaginationResponse<SurveyResponseAnswers>>(
      `${BASE_URL}/${surveyId}/responses/${responseId}/answers/${Params.makeQueryString(
        params || {}
      )}`
    );
  }

  getSurveyResponses({
    surveyId,
    ...params
  }: {
    surveyId: number;
    offset?: number;
    limit?: number;
  }) {
    return virtualBrownClient.get<PaginationResponse<SurveyResponse>>(
      `${BASE_URL}/${surveyId}/responses/${Params.makeQueryString(
        params || {}
      )}`
    );
  }

  getSurveyRecepients({
    surveyId,
    ...params
  }: {
    surveyId: number;
    pending: boolean;
    offset?: number;
    limit?: number;
  }) {
    return virtualBrownClient.get<PaginationResponse<SurveyResponse>>(
      `${BASE_URL}/${surveyId}/employees/${Params.makeQueryString(
        params || {}
      )}`
    );
  }
}

export const surveysService = new SurveysService();
