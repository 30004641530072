export function currencyFriendlyString(x: number): string {
	const isNegative = x < 0;

	const parts = Math.abs(x).toFixed(2).toString().split('.');
	parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

	if (isNegative) {
		parts.unshift(`-$`);
	} else {
		parts.unshift('$');
	}

	let ret = '';
	const lastInd = parts.length - 1;
	parts.forEach((p, ind) => {
		if (ind != lastInd && p.indexOf('$') < 0) {
			ret += `${p}.`;
		} else {
			ret += p;
		}
	});

	return ret;
}
