import { useState } from 'react';

// interfaces
import { Text } from '@atoms';

import { InformationTabsProps } from './information.tabs.interfaces';

// components
import './modal-information-tabs.scss';

export const InformationTabs = ({
  tabs,
  className,
  onClick,
}: InformationTabsProps) => {
  const [active, setActive] = useState(0);

  function handleClick(index: number) {
    setActive(index);
    onClick(index);
  }

  return (
    <div
      className={`modal-information-tabs tw-overflow-x-auto tw-flex tw-space-x-8 ${className}`}
    >
      {tabs.map(({ label }, index) => (
        <Text
          key={index}
          font="h4"
          color={active === index ? 'hi-contrast' : 'neutral-offset'}
          className={`tw-whitespace-nowrap tw-cursor-pointer tw-border-b-[3px] tw-border-solid ${
            active === index
              ? 'tw-border-theme-primary-500-300'
              : 'tw-border-transparent'
          }`}
          onClick={(e) => {
            e.preventDefault();
            handleClick(index);
          }}
        >
          {label}
        </Text>
      ))}
    </div>
  );
};
