import { EmployeeDetails } from '@components/_new/MiniProfiles/_components/employee-details';
import { TimeUtils } from '@helpers';
import { useAuditModalContext } from '@modals/_ds2/audit/_context/context';

// components
import { CardComments } from '@new/Card';

// helpers

export const OverallComment = () => {
  const {
    state: { inspection },
  } = useAuditModalContext();

  return (
    <CardComments
      className="tw-p-6"
      style={{ margin: 0 }}
      textClassName="tw-not-italic tw-text-neutral-900 dark:tw-text-neutral-100"
      bgClassName="tw-bg-neutral-200 dark:tw-bg-neutral-900"
      person={inspection.inspected_by?.person}
      detail={
        <EmployeeDetails
          client={inspection.contract?.customer?.name ?? ''}
          site={inspection.contract?.name ?? ''}
          jobTitle={inspection.inspected_by?.positions[0]?.name ?? ''}
          hiredDate={inspection.inspected_by?.hired_date ?? null}
        />
      }
      date={TimeUtils.format(inspection.inspection_date, 'M/D/YYYY h:mm A')}
      comments={inspection.summary}
    />
  );
};
