import { useRef, useEffect } from 'react';

// services
import { Subscription } from 'rxjs';

import { complaintsService } from '@apis';
import { ModalStack } from '@atoms';
import { sendError } from '@core/helpers';
import { toasterService } from '@services';

// helpers
import { Modal } from '@templates';

import { useWorkOrderModalContext } from '../../../../_context';

// components

export const DeleteServiceIssue = () => {
  const {
    workOrder,
    setWorkOrder,
    workOrderEdit,
    setWorkOrderEdit,
    activeModals,
    setActiveModals,
    selectedServiceIssue,
    setSelectedServiceIssue,
    hasBeenUpdated,
  } = useWorkOrderModalContext();
  const subscription = useRef(new Subscription());

  useEffect(() => {
    return () => {
      subscription.current.unsubscribe();
    };
  }, []);

  function handleDeleteTodo() {
    subscription.current = complaintsService
      .deleteComplaintByComplaintId(selectedServiceIssue.id)
      .subscribe({
        next: () => {
          toasterService.newToast({
            status: 'success',
            message: 'Complaint has been deleted',
          });
          const complaints = workOrderEdit.complaints.filter(
            (todo) => todo.id !== selectedServiceIssue.id
          );
          setWorkOrder({ ...workOrder, complaints });
          setWorkOrderEdit({ ...workOrderEdit, complaints });
          hasBeenUpdated.current = true;
          handleCancel();
        },
        error: sendError(),
      });
  }

  function handleCancel() {
    setActiveModals({
      ...activeModals,
      'deleting-service-issue': false,
    });
    setSelectedServiceIssue(null);
  }

  return (
    activeModals['deleting-service-issue'] && (
      <ModalStack position="top-half-center">
        <Modal.Prompt
          title="Delete?"
          prompt="Are you sure?"
          onCancel={handleCancel}
          buttons={[
            {
              label: 'Cancel',
              color: 'secondary',
              onClick: handleCancel,
            },
            {
              label: 'Delete',
              color: 'danger',
              onClick: handleDeleteTodo,
            },
          ]}
        />
      </ModalStack>
    )
  );
};
