import { BottomSheetContainer } from './container';
import { BottomSheetDetails } from './details';
import { BottomSheetDetailsContent } from './details-content';
import { BottomSheetMain } from './main';

export const BottomSheet = {
  Container: BottomSheetContainer,
  Main: BottomSheetMain,
  Details: BottomSheetDetails,
  DetailsContent: BottomSheetDetailsContent,
};
