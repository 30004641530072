import { Box, Text } from '@atoms';
import { EmployeeDetails } from '@components/_new/MiniProfiles/_components/employee-details';

// helpers
import { TimeUtils } from '@helpers';
import { useBreakpoints, useAppContext } from '@hooks';
import { MiniProfile } from '@new/MiniProfiles';
import { Modal } from '@templates';

import { useGmpAuditsModalContext } from '../_context/context';
// import { getBadgeType } from '../helpers';

const NotDefined = () => (
  <Text font="body-md" tag="span" color="hi-contrast">
    &#8212; &#8212; &#8212;
  </Text>
);

interface Props {}

export const Details = ({}: Props) => {
  const {
    state: { selectedContracts },
  } = useAppContext();
  const {
    state: { mode, selectedEmployee, data },
  } = useGmpAuditsModalContext();
  const bp = useBreakpoints();
  const isBpLg = bp.lg;
  const isCreateMode = mode === 'create';
  const isReadMode = mode === 'read';
  const isExempt = data?.is_exempt_from_scorecard;

  let reviewedPerson;
  if (isCreateMode) reviewedPerson = selectedEmployee?.person;
  if (isReadMode) reviewedPerson = data?.subject?.person;

  const Body = () => (
    <Modal.BottomSheet.Main>
      <Modal.BottomSheet.DetailsContent
        label="Month"
        content={TimeUtils.format(data?.audit_date ?? new Date(), 'MMMM YYYY')}
      />

      {/* no Status in designs, will confirm if this is needed */}
      {/* {isReadMode && (
                <Modal.BottomSheet.DetailsContent label="Status" content={<BadgeType type={getBadgeType(data?.is_pass, isExempt)} />} />
            )} */}

      {isReadMode && (
        <Modal.BottomSheet.DetailsContent
          label="Created On"
          content={
            data?.audit_date ? (
              TimeUtils.format(data.audit_date, 'M/D/YY hh:mm A')
            ) : (
              <NotDefined />
            )
          }
        />
      )}

      <Modal.BottomSheet.DetailsContent
        label="Reviewed Employee"
        content={
          reviewedPerson ? (
            <MiniProfile.Md
              person={reviewedPerson}
              detail={
                <EmployeeDetails
                  jobTitle={
                    isCreateMode
                      ? selectedEmployee?.positions[0]?.name
                      : data.subject?.positions[0].name
                  }
                  hiredDate={
                    isCreateMode
                      ? selectedEmployee?.hired_date ?? null
                      : data.subject?.hired_date ?? null
                  }
                  client={data?.contract?.customer_name ?? ''}
                  site={data?.contract?.customer?.name ?? ''}
                />
              }
            />
          ) : (
            <NotDefined />
          )
        }
      />

      <Modal.BottomSheet.DetailsContent
        label="Location"
        content={
          isReadMode &&
          data?.contract?.name &&
          data?.contract?.customer_name ? (
            `${data.contract.customer_name}, ${data.contract.name}`
          ) : selectedContracts?.[0]?.customer_name &&
            selectedContracts?.[0]?.name ? (
            `${selectedContracts[0].customer_name}, ${selectedContracts[0].name}`
          ) : (
            <NotDefined />
          )
        }
      />

      {isReadMode && !isExempt && (
        <Modal.BottomSheet.DetailsContent
          label="Reviewed by"
          content={
            data?.auditor?.person ? (
              <MiniProfile.Md
                person={data.auditor.person}
                detail={
                  <EmployeeDetails
                    jobTitle={
                      data.auditor?.positions[0]?.name
                        ? data.auditor.positions[0].name
                        : ''
                    }
                    hiredDate={data.auditor?.hired_date ?? null}
                    client={data?.contract?.customer_name ?? ''}
                    site={data?.contract?.customer?.name ?? ''}
                  />
                }
              />
            ) : (
              <NotDefined />
            )
          }
        />
      )}
    </Modal.BottomSheet.Main>
  );

  return isBpLg ? (
    <Modal.BottomSheet.Details>
      <Text font="h3" color="hi-contrast">
        GMP Audit Details
      </Text>
      <Body />
    </Modal.BottomSheet.Details>
  ) : (
    <Box
      id="behavioral-review-form-details"
      type="default"
      className="tw-p-4 tw-space-y-6
                sm:tw-p-10"
      rounded
    >
      <Text font="h2" color="primary">
        GMP Audit Details
      </Text>
      <Body />
    </Box>
  );
};
