import { cloneDeep } from 'lodash';

import { Page } from './interfaces';

export function reducer(state: Page.State, action: Page.Action): Page.State {
	switch (action.type) {
		case 'SET_MODALS': {
			return {
				...state,
				modals: action.payload,
			};
		}
		case 'SET_IS_LOADING': {
			return {
				...state,
				isLoading: action.payload,
			};
		}
		case 'SET_WORK_ITEM': {
			return {
				...state,
				workItem: action.payload,
				workItemEdit: cloneDeep(action.payload),
			};
		}
		case 'SET_WORK_ITEM_EDIT': {
			return {
				...state,
				workItemEdit: action.payload,
			};
		}
		case 'SET_COMMENT_COUNT': {
			return {
				...state,
				commentCount: action.payload,
			};
		}
		case 'SET_BOT_ENTITIES': {
			return {
				...state,
				botEntities: action.payload,
			};
		}
		case 'SET_BOT_PROPERTIES': {
			return {
				...state,
				botProperties: action.payload,
			};
		}
		case 'SET_IS_EDITING': {
			return {
				...state,
				isEditing: action.payload,
			};
		}
		case 'SET_FIELD_ERRORS': {
			return {
				...state,
				fieldErrors: action.payload,
			};
		}
		case 'SET_TWO_WAY_CONVERSATION': {
			return {
				...state,
				twoWayConversation: action.payload,
			};
		}
		default:
			return { ...state };
	}
}
