import { Link } from '@reach/router';
import { Fragment } from 'react';

// components
import { Text } from '@atoms';

// interfaces
import { BreadcrumbsProps } from './breadcrumbs.interfaces';

export const Breadcrumbs = ({ crumbs }: BreadcrumbsProps) => {
  return (
    <div className="tw-flex tw-flex-wrap tw-gap-2">
      {crumbs
        .filter((x) => x)
        .map(({ label, link }, i) => {
          const notLast = i < crumbs.length - 1;
          return (
            <Fragment key={label}>
              {link ? (
                <Link {...link}>
                  <Text
                    font="h2"
                    color={notLast ? 'neutral' : 'hi-contrast'}
                    className={
                      notLast ? 'hover:tw-text-theme-neutral-900-100' : ''
                    }
                  >
                    {label}
                  </Text>
                </Link>
              ) : (
                <Text font="h2" color={notLast ? 'neutral' : 'hi-contrast'}>
                  {label}
                </Text>
              )}
              {notLast && (
                <Text font="h2" color="neutral">
                  /
                </Text>
              )}
            </Fragment>
          );
        })}
    </div>
  );
};
