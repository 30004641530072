// base
import { useState, useEffect } from 'react';

import * as React from 'react';

// services

// helpers
import { Subscription } from 'rxjs';

import { presetsService } from '@apis';

// hooks

// components
import { Text, Tooltip, Box, Icon } from '@atoms';
import { FieldInputText } from '@atoms/form-ds2/field-input-text';
import { InputCheckbox } from '@atoms/form-ds2/input-checkbox';

// interfaces
import { App } from '@core/app-context/app.interfaces';
import { useAppContext } from '@hooks';
import { Button } from '@new';
import { toasterService, modalService } from '@services';

// operators
interface Props {
    user: App.State['user'];
    customerIds: number[];
    contractIds: number[];
    areaTagIds: number[];
    areaTagGroupIds: number[];
    customerGroupIds?: any;
}

export default function SavePresetModal(props: Props) {
    const {
        state: { user },
    } = useAppContext();
    const [presetName, setPresetName] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isShared, setIsShared] = useState<boolean>(false);
    const [errors, setErrors] = useState(null);
    const canCreateSharedPresets = user.hasPermission(2, 'Shared Presets');

    useEffect(() => {
        let subscription = new Subscription();
        if (isSubmitting) {
            subscription = presetsService
                .createPreset({
                    name: presetName,
                    employee_owner: {
                        id: props.user.id,
                    },
                    organization_owner: {
                        id: props.user.roleProfile.organization.id,
                    },
                    customer_ids: props.customerIds,
                    contract_ids: props.contractIds,
                    area_tag_group_ids: props.areaTagGroupIds,
                    areatag_ids: props.areaTagIds,
                    is_shared: isShared,
                    last_update_by: {
                        id: props.user.id,
                    },
                    customer_groups: Object.values(
                        props.customerGroupIds
                    )?.[0]?.map((el: any) => el.id),
                })
                .subscribe({
                    next: (res) => {
                        toasterService.newToast({
                            status: 'success',
                            message: 'Successfully created Preset',
                        });

                        modalService.close();
                    },
                    error: () => {
                        toasterService.newToast({
                            message: 'There was an error saving the Preset',
                        });
                        setIsSubmitting(false);
                    },
                });
        }

        return () => subscription.unsubscribe();
    }, [isSubmitting]);

    function handleSave() {
        if (presetName.length) {
            setErrors(null);
            setIsSubmitting(true);
        } else {
            setErrors('This field is required');
        }
    }

    function updatePresetName(e: React.ChangeEvent<HTMLInputElement>) {
        setPresetName(e.target.value);
    }

    function handleCancel() {
        modalService.close();
    }

    return (
        <div className="tw-space-y-6 tw-p-10">
            <h1 className="tw-mb-8">Name your Presets</h1>
            <FieldInputText
                id="preset-name"
                label="Preset Name"
                placeholder="Name your preset..."
                onChange={updatePresetName}
                value={presetName}
                error={errors}
            />

            {canCreateSharedPresets && (
                <div className="tw-flex tw-items-center">
                    <InputCheckbox
                        checked={isShared}
                        onChange={(e) => setIsShared(e.target.checked)}
                        className="tw-mr-2"
                    />
                    <Text
                        font="body-md"
                        color="neutral-offset"
                        className="tw-flex tw-space-x-2"
                    >
                        <span>Share with Org</span>
                        <Tooltip
                            position="bottom"
                            render={
                                <Box
                                    rounded
                                    className="tw-p-2 tw-text-center tw-shadow-lg tw-w-[175px]"
                                >
                                    <Text font="body-md" color="neutral-offset">
                                        Click to share this preset with the
                                        organization
                                    </Text>
                                </Box>
                            }
                        >
                            <Icon icon="question-circle" />
                        </Tooltip>
                    </Text>
                </div>
            )}

            <div className="tw-flex tw-justify-end tw-space-x-4">
                <Button
                    label="Cancel"
                    onClick={handleCancel}
                    color="secondary"
                />
                <Button
                    label="Save"
                    onClick={handleSave}
                    disabled={isSubmitting || !presetName}
                />
            </div>
        </div>
    );
}
