// context
import { Provider } from './_context/context';

// interfaces
import { Page } from './_context/interfaces';

// modals
import {
    AuditBottomSheet,
    AuditDeleteConfirmation,
    AreaInformation,
    AuditPhotoViewer,
    AuditServiceLog,
} from './_modals';
import { useMixpanelQualityAuditsSpecificAudit } from '@app/mixpanel/MixpanelPageTrack.tsx';

export const AuditModal = ({
    areaId,
    areaTypeId,
    ...props
}: Pick<Page.State, 'id' | 'onClose' | 'photoIndexOnLoad'> & {
    areaId?: number;
    areaTypeId?: number;
}) => {
    useMixpanelQualityAuditsSpecificAudit();
    return (
        <Provider
            defaultState={{
                ...props,
                mode: 'AUDIT',
                onClose: props.onClose,
                selectedAreaId: areaId,
                selectedAreaTypeId: areaTypeId,
            }}
        >
            <Modals />
        </Provider>
    );
};

const Modals = () => {
    return (
        <>
            <AuditBottomSheet />
            <AuditDeleteConfirmation />
            <AreaInformation />
            <AuditPhotoViewer />
            <AuditServiceLog />
        </>
    );
};
