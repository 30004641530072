// interfaces
import { Observable } from 'rxjs';

import {
	PatchSafetyConductPayload,
	SafetyConduct,
	SafetyConductPayload,
} from '@api-interfaces';

// services
import { virtualBrownClient } from '@core/services/django-client';

// helpers
import { Params, TimeUtils } from '@helpers';
import { CommonParams, PaginationResponse, SummaryChart } from '@models';

// constants
const BASE_URL = 'safetyconducts';

class SafetyConductsService {
	public getSafetyConducts(
		params: {
			employee?: number | string;
		} & CommonParams
	) {
		const query = params;
		query.from = params.from
			? TimeUtils.format(params.from, 'YYYY-MM-DD')
			: null;
		query.to = params.to ? TimeUtils.format(params.to, 'YYYY-MM-DD') : null;

		const queryString = Params.makeQueryString(query);
		return virtualBrownClient.get<PaginationResponse<SafetyConduct>>(
			`${BASE_URL}/${queryString}`
		);
	}

	public createSafetyConduct(params: { payload: SafetyConductPayload }) {
		return virtualBrownClient.post(`${BASE_URL}/`, params.payload);
	}

	public updateSafetyConduct(params: {
		id: number;
		payload: SafetyConductPayload;
	}) {
		return virtualBrownClient.put(`${BASE_URL}/${params.id}/`, params.payload);
	}

	public patchSafetyConduct(params: {
		id: number;
		payload: PatchSafetyConductPayload;
	}) {
		return virtualBrownClient.patch(
			`${BASE_URL}/${params.id}/`,
			params.payload
		);
	}

	public deleteSafetyConduct(params: { id: number }) {
		return virtualBrownClient.delete(`${BASE_URL}/${params.id}/`);
	}

	public getSafetyConductsSummary(
		params: {
			chart?: string; // e.g. '0' or '0,1'
		} & CommonParams
	): Observable<SummaryChart<any>> {
		const query = params;
		query.from = params.from
			? TimeUtils.format(params.from, 'YYYY-MM-DD')
			: null;
		query.to = params.to ? TimeUtils.format(params.to, 'YYYY-MM-DD') : null;

		const queryString = Params.makeQueryString(query);

		return virtualBrownClient.get(`${BASE_URL}/summary/${queryString}`);
	}
}

export const safetyConductsService = new SafetyConductsService();
