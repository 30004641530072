import { isNil } from 'lodash';

// interfaces
import { CardScore } from '@new/Card/CardScore';
import { MiniProfile } from '@new/MiniProfiles';

import { CardCommentsProps } from './CardComments.interfaces';

// components

// helpers

/** ************************************************
 * Render Component
 ************************************************* */

export const CardComments = (props: CardCommentsProps) => {
    const {
        className = '',
        person,
        detail,
        date,
        comments,
        lineClamp,
        style,
        layout,
        score,
        goal,
        textClassName,
        bgClassName,
        dataTest,
    } = props;
    const hasScore = !isNil(score);

    let lineClampClass = 'tw-line-clamp-none';
    switch (true) {
        case lineClamp === 1:
            lineClampClass = 'tw-line-clamp-1';
            break;
        case lineClamp === 2:
            lineClampClass = 'tw-line-clamp-2';
            break;
        case lineClamp === 3:
            lineClampClass = 'tw-line-clamp-3';
            break;
        case lineClamp === 4:
            lineClampClass = 'tw-line-clamp-4';
            break;
        case lineClamp === 5:
            lineClampClass = 'tw-line-clamp-5';
            break;
        case lineClamp === 6:
            lineClampClass = 'tw-line-clamp-6';
            break;
    }

    return (
        <div
            className={`${
                layout == 'wide' ? 'tw-p-8' : 'tw-p-4'
            } tw-m-2 tw-rounded ${bgClassName} ${className}`}
            style={style}
        >
            <MiniProfile.Lg person={person} detail={detail ?? date} />
            {hasScore && (
                <CardScore
                    goal={goal}
                    score={score}
                    size="sm"
                    className="tw-mt-3"
                    scoreDataTest={dataTest}
                />
            )}
            <div
                className={`tw-text-sm tw-italic tw-leading-5 ${
                    hasScore
                        ? 'tw-mt-3'
                        : layout == 'wide'
                          ? 'tw-mt-6'
                          : 'tw-mt-4'
                }`}
            >
                <span className={`${lineClampClass} ${textClassName}`}>
                    {comments || 'No comments provided'}
                </span>
            </div>
        </div>
    );
};

CardComments.defaultProps = {
    className: '',
    textClassName: 'tw-text-neutral-600 dark:tw-text-neutral-500',
    bgClassName: 'tw-bg-neutral-200 dark:tw-bg-neutral-700',
};
