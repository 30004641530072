import { LoaderBlocksProps } from './loader-blocks.interfaces';

export const loaderSizeMap: { [Key in LoaderBlocksProps['size']]: string } = {
	sm: 'tw-w-8 tw-h-8',
	md: 'tw-w-16 tw-h-16',
};

export const loaderTypeMap: { [Key in LoaderBlocksProps['type']]: string } = {
	default: 'tw-bg-theme-neutral-500-600',
};

export const loaderPositionMap: {
	[Key in LoaderBlocksProps['position']]: string;
} = {
	left: 'tw-flex tw-items-center',
	center: 'tw-flex tw-items-center tw-justify-center',
	right: 'tw-flex tw-items-center tw-justify-end',
	custom: '',
};
