import { useEffect, useState } from 'react';

// helpers
import { InspectionArea, Media } from '@api-interfaces';
import { ScoreChip, Text } from '@atoms';
import { safeDivide } from '@helpers';
import { ServiceLogTab } from '@modals/_ds2/audit/_components';
import { useAuditModalContext } from '@modals/_ds2/audit/_context/context';
import {
    buildLocationName,
    getScoreChipParams,
} from '@modals/_ds2/audit/helpers';

// context

// interfaces

// components
import { Modal } from '@templates';

import { DetailsTab, TasksTab } from './_tabs';

export const AuditPhotoViewer = () => {
    const {
        state: { mode, photoViewerAreas, photoViewerIndex, modals, inspection },
        dispatch,
    } = useAuditModalContext();
    const [tab, setTab] = useState<number>(0);
    const [attachments, setAttachments] = useState<Media[]>([]);

    useEffect(() => {
        setAttachments(
            photoViewerAreas
                ? photoViewerAreas.flatMap((area) => area.media)
                : []
        );
    }, [photoViewerAreas]);

    const handleClose = () => {
        if (!modals['audit-attachments']) return;
        if (modals.todo) {
            return;
        }
        dispatch({
            type: 'SET_MODALS',
            payload: {
                ...modals,
                'audit-attachments': false,
            },
        });
        dispatch({
            type: 'SET_PHOTO_VIEWER_AREAS',
            payload: null,
        });

        dispatch({
            type: 'SET_PHOTO_VIEWER_INDEX',
            payload: 0,
        });

        // reset
        setTab(0);
    };

    const onServiceLogClick = (
        stopId: number,
        areaId: number,
        date: string
    ) => {
        dispatch({
            type: 'SET_SELECTED_SERVICE',
            payload: {
                stopId,
                areaId,
                date,
            },
        });
        dispatch({
            type: 'SET_MODALS',
            payload: {
                ...modals,
                'service-log': true,
            },
        });
    };

    const getScoreChip = (currentArea: InspectionArea) => {
        let score: number | string;
        let type: 'success' | 'danger' | 'neutral';

        const {
            audit_score_calculated,
            audit_couldnt_score,
            audit_is_pass,
            audit_scale,
            goalline,
            inspection_score_calculated,
        } = currentArea;
        const { type: typeRes, score: scoreRes } = getScoreChipParams({
            mode,
            audit_score_calculated,
            audit_couldnt_score,
            audit_is_pass,
            audit_scale,
            goalline,
            inspection_score_calculated,
        });
        score = scoreRes;
        type = typeRes as typeof type;

        return { score, type };
    };

    const groupBy = (image: Media, _: number) => {
        const area = photoViewerAreas.find((area) =>
            area.media.find((am) => am.id === image.id)
        );
        const scoreChip = getScoreChip(area);
        return {
            id: String(area.id),
            label: (
                <div className="tw-flex tw-gap-2 tw-items-center">
                    <ScoreChip
                        score={scoreChip.score}
                        type={scoreChip.type}
                        size="sm"
                        className=""
                    />
                    <div className="tw-whitespace-nowrap">
                        <Text color="hi-contrast" font="h4">
                            {area.location?.name}
                        </Text>
                        <Text color="neutral-offset" font="body-sm">
                            Area Type: {area.location?.area_type?.name}
                        </Text>
                    </div>
                </div>
            ),
        };
    };

    return (
        modals['audit-attachments'] &&
        attachments.length > 0 && (
            <Modal.PhotoViewer.Core
                media={attachments}
                onClose={handleClose}
                initialIndex={photoViewerIndex}
                getGroup={groupBy}
                details={(media) => {
                    let building: string = '-';
                    const currentArea = photoViewerAreas?.find((area) =>
                        area.media.find((am) => am.id === media.id)
                    );
                    const { score, type } = getScoreChip(currentArea);

                    if (currentArea) {
                        building = buildLocationName(
                            currentArea.location?.parent
                        );
                        if (!building) {
                            building = '-';
                        }
                    }

                    return (
                        <Modal.PhotoViewer.Details
                            title={currentArea.location?.name}
                            subtitle={`${building} · ${currentArea.location?.area_type?.name}`}
                            scoreChip={{
                                title: 'Area Score',
                                mode,
                                score,
                                type,
                            }}
                        >
                            <Modal.Information.Tabs
                                className="tw-mt-6 tw-flex-shrink-0"
                                tabs={[
                                    { label: 'Details' },
                                    { label: 'Tasks' },
                                    { label: 'Service Log' },
                                ]}
                                onClick={(index) => setTab(index)}
                            />

                            <div className="tw-overflow-y-auto">
                                <div
                                    className={`tw-mt-6 ${
                                        tab === 0 ? '' : 'tw-hidden'
                                    }`}
                                >
                                    <DetailsTab currentArea={currentArea} />
                                </div>

                                <div
                                    className={`tw-mt-10 ${
                                        tab === 1 ? '' : 'tw-hidden'
                                    }`}
                                >
                                    <TasksTab
                                        currentArea={currentArea}
                                        mode={mode}
                                        inspection={inspection}
                                    />
                                </div>

                                <div
                                    className={`tw-mt-10 ${
                                        tab === 2 ? '' : 'tw-hidden'
                                    }`}
                                >
                                    <ServiceLogTab
                                        currentArea={currentArea}
                                        maxDate={
                                            attachments[photoViewerIndex]
                                                ?.create_date
                                        }
                                        inspection={inspection}
                                        onViewClick={onServiceLogClick}
                                    />
                                </div>
                            </div>
                        </Modal.PhotoViewer.Details>
                    );
                }}
            />
        )
    );
};
