// interfaces
import { has } from 'lodash';

import { StopFrequency } from '@api-interfaces';
import { getOrdinal, truncate } from '@helpers/strings';

import {
	TimeTableRowTaskRepeat,
	TimeTableRow,
	DAYS_OF_WEEK,
	MONTHS,
} from './interfaces';

// services

// helpers

export function getGroupedPeriodicTaskRepeatText(
	row: TimeTableRow,
	shorthand?: boolean
) {
	if (!row.tasks) return null;

	const repeats = row.tasks.data
		.map((task) => task.repeat)
		.sort((a, b) => a.day - b.day)
		.sort((a, b) => a.month - b.month);

	if (repeats && repeats.length) {
		let text = repeats
			.filter((repeat) => repeat.day !== 0) // filter out NA (service day is 0)
			.map((repeat) => getPeriodicTaskRepeatText(repeat, shorthand)) // turn repeat into ordinal string
			.filter((item, i, self) => self.indexOf(item) === i) // filter out dupes
			.join(', '); // join ordinals together comma separated

		if (!text) text = 'N/A';

		return truncate(text, 40);
	}
	return null;
}

export function getPeriodicTaskRepeatText(
	repeat: TimeTableRowTaskRepeat,
	shorthand?: boolean
) {
	// WEEKLY:       day: 1-7;   month:  na
	// MONTHLY:      day: 1-28;  month:  na
	// QUARTERLY:    day: 1-28;  month:  1-3
	// YEARLY:       day: 1-28;  month:  1-12

	switch (repeat.period) {
		case 'WEEKLY':
			return repeat.day >= 1 && repeat.day <= 7
				? shorthand
					? DAYS_OF_WEEK[repeat.day - 1].substr(0, 3)
					: DAYS_OF_WEEK[repeat.day - 1]
				: 'N/A';
		case 'MONTHLY':
			return getOrdinal(repeat.day);
		case 'QUARTERLY':
			return `${getOrdinal(repeat.month)} month / ${getOrdinal(
				repeat.day
			)} day`;
		case 'YEARLY':
			return repeat.month >= 1 && repeat.month <= 12
				? `${
						shorthand
							? MONTHS[repeat.month - 1].substr(0, 3)
							: MONTHS[repeat.month - 1]
				  } ${getOrdinal(repeat.day)}`
				: 'N/A';
		case 'AS_NEEDED':
			return 'As Needed';
		default:
			return null;
	}
}

export function getTrainingText(row: TimeTableRow, shorthand?: boolean) {
	return row.nonWork.day >= 1 && row.nonWork.day <= 7
		? shorthand
			? DAYS_OF_WEEK[row.nonWork.day - 1].substr(0, 3)
			: DAYS_OF_WEEK[row.nonWork.day - 1]
		: 'N/A';
}

export function getNewCoverageCount(stopFrequencies: StopFrequency[]): number {
	let count = 0;

	stopFrequencies.forEach((stopFrequency) => {
		if (!stopFrequency.update_date) return 0;

		const milliseconds = 1000 * 60 * 60;
		const within = new Date(stopFrequency.update_date).getTime() + milliseconds;
		const current = new Date().getTime();

		if (current < within && stopFrequency.recurrence > 0) count++;
	});

	return count;
}

// optional method to sort time table rows by periodic service date
export function sortPeriodicByServiceDate(
	rows: TimeTableRow[]
): TimeTableRow[] {
	interface ServiceDate {
		day: number;
		month: number;
	}

	// get the sort value of 2 service dates
	function checkSort(a: ServiceDate, b: ServiceDate) {
		if (a.month == b.month) {
			if (a.day == b.day) return 0;
			return a.day < b.day ? -1 : 1;
		}

		return a.month < b.month ? -1 : 1;
	}

	// turn a row into array of service dates
	function convertRowToService(row: TimeTableRow): ServiceDate[] {
		if (row.nonWork) {
			// non-work is training, doesn't have individual tasks
			return [row.nonWork];
		}
		if (has(row, 'tasks.data')) {
			// turn our tasks into service dates and sort them
			return row.tasks.data.map((task) => ({
				day: task.repeat.day,
				month: task.repeat.month,
			}));
		}
		// shouldn't happen but return empty if no avilable data
		return [];
	}

	// sort the row's task data
	const newRows = rows.map((row) => {
		if (has(row, 'tasks.data'))
			row.tasks.data.sort((a, b) =>
				checkSort(a.repeat as ServiceDate, b.repeat as ServiceDate)
			);

		return row;
	});

	// sort the 2 rows
	newRows.sort((a, b) => {
		const sortedServicesA = convertRowToService(a);
		const sortedServicesB = convertRowToService(b);

		let index = 0;
		let sortValue = 0;

		// step through each service date to find the first one that is less
		while (index < sortedServicesA.length && index < sortedServicesB.length) {
			sortValue = checkSort(sortedServicesA[index], sortedServicesB[index]);

			if (sortValue === 0) index++;
			// same so check next service date in array
			else return sortValue; // found different so return sortValue
		}

		return sortedServicesA.length < sortedServicesB.length ? -1 : 1; // matching subset so sort by length
	});

	return newRows;
}

// optional method to sort time table rows by periodic name
export function sortPeriodicByName(rows: TimeTableRow[]): TimeTableRow[] {
	const newRows = [...rows];

	newRows.sort((a, b) => {
		const stringA = `${a.area?.type} ${a.area?.name} ${a.area?.building?.name} ${a.area?.floor?.name}`;
		const stringB = `${b.area?.type} ${b.area?.name} ${b.area?.building?.name} ${b.area?.floor?.name}`;

		return stringA.localeCompare(stringB);
	});

	return newRows;
}
