import { forwardRef } from 'react';

// helpers

// components
import { Icon } from '@atoms';

// interfaces
import { ScoringThumbProps } from './scoring-thumb.interfaces';
import { scoringThumbBgColor, scoringThumbColor } from './scoring-thumb.utils';

export const ScoringThumb = forwardRef<
  HTMLInputElement,
  ScoringThumbProps
>(({ icon, className, style, ...props }, ref) => {
  return (
    <label
      className={`tw-relative tw-h-8 tw-w-8 tw-rounded-full tw-flex tw-items-center tw-justify-center tw-overflow-hidden
     ${className}`}
      style={style}
    >
      <input
        ref={ref}
        type="radio"
        className="tw-h-0 tw-w-0 tw-opacity-0 tw-peer"
        {...props}
      />
      <div
        className={`tw-h-full tw-w-full tw-absolute tw-inset-0
      tw-bg-neutral-300 dark:tw-bg-neutral-600
      ${scoringThumbBgColor[icon]}
      `}
      />
      <Icon
        icon={icon}
        className={`tw-relative tw-text-neutral-100 dark:tw-text-neutral-500 ${scoringThumbColor[icon]}`}
      />
    </label>
  );
});

ScoringThumb.defaultProps = {
  className: '',
};
