import { cloneDeep } from 'lodash';

import {
	R4LoginResult,
	Feature,
	FeaturePermission,
	R4RoleProfile,
} from '@models';

import { Employee } from './employee';

export class User extends Employee {
	protected user: Partial<R4LoginResult>;

	private _permissions: FeaturePermission[] = [];

	constructor(loginResults: Partial<R4LoginResult>) {
		super(loginResults.employee);
		this.user = loginResults;
		this.setPermissionsFromRoleProfile(loginResults.role_profile);
	}

	get id() {
		return this.user.user.id;
	}

	get token() {
		return this.user.token;
	}

	set token(token: string) {
		this.user.token = token;
	}

	get refreshToken() {
		return this.user.refresh_token;
	}

	set refreshToken(refreshToken: string) {
		if (!refreshToken) return;
		this.user.refresh_token = refreshToken;
	}

	get isAdmin() {
		// 7 is 'Insite Admin' on the BE
		return this.user.role_profiles.some((profile) => profile.id === 7);
	}

	get roleProfile() {
		return cloneDeep(this.user.role_profile);
	}

	get roleProfiles() {
		return cloneDeep(this.user.role_profiles);
	}

	get permissions() {
		return cloneDeep(this._permissions);
	}

	get organizationGroupTypes() {
		return cloneDeep(this.user.organization_group_types);
	}

	private setPermissionsFromRoleProfile(roleProfile: R4RoleProfile) {
		if (!roleProfile) return;
		const permissionsMap = new Map<number, FeaturePermission>();
		// make feature permissions unique and any duplicate features
		// will always use the one will the higher access level
		for (const role of roleProfile.roles) {
			for (const rolePermission of role.permissions) {
				const permission = permissionsMap.get(rolePermission.id);
				if (permission) {
					if (rolePermission.access_level > permission.access_level) {
						permissionsMap.set(rolePermission.id, rolePermission);
					}
				} else {
					permissionsMap.set(rolePermission.id, rolePermission);
				}
			}
		}
		// this guarantees to always be an array
		const permissions = Array.from(permissionsMap.values());
		this._permissions = permissions;
	}

	public hasPermission(accessLevel: 1 | 2 | 3 | 4, ...features: Feature[]) {
		// this.permissions will always be guaranteed to be an array
		return this._permissions.some(
			(permission) =>
				permission.access_level >= accessLevel &&
				features.includes(permission.feature)
		);
	}
}
