import { Avatar } from '@atoms';
import { Text } from '@atoms';
import { getFullName } from '@helpers';

// interfaces

// helpers
import { highlightText } from '@helpers';

import { MiniProfileSmProps } from './mini-profile.interface';

export const MiniProfileSm = ({
  person,
  isInitials,
  className,
  textToHighlight,
  hasNoBorder,
  ...props
}: MiniProfileSmProps) => {
  return (
    <div
      className={`tw-flex tw-items-center tw-space-x-2 tw-max-w-[150px] ${className}`}
      {...props}
    >
      <Avatar
        size="sm"
        person={person}
        isInitials={isInitials}
        hasNoBorder={hasNoBorder}
      />
      <div className="tw-max-w-[118px] tw-truncate">
        <Text
          font="body-md"
          color="hi-contrast"
          className="tw-capitalize tw-truncate"
        >
          {highlightText({ text: getFullName(person), match: textToHighlight })}
        </Text>
      </div>
    </div>
  );
};

MiniProfileSm.defaultProps = {
  className: '',
  textToHighlight: '',
};
