// interfaces
import { Observable } from 'rxjs';

import {
	CustomerSurvey,
	CustomerSurveyConfig,
	CustomerSurveyPayload,
	CustomerSurveyChart,
	CustomerSurveyConfigPayload,
	SurveyRecipientPayload,
} from '@api-interfaces';
import { virtualBrownClient } from '@core/services/django-client';
import { Params } from '@helpers';
import { CommonParams, PaginationResponse } from '@models';

// services

import { customerSurveysRecipientsService } from './recipients';
import { customerSurveysSummaryService } from './summary';

// helpers

const zone = Intl.DateTimeFormat().resolvedOptions().timeZone;

// constants
const BASE_URL = 'customersurveys';

const noOpts = {
	noContract: true,
	noCustomer: true,
	noTag: true,
};

class CustomerSurveysService {
	readonly recipients = customerSurveysRecipientsService;

	readonly summary = customerSurveysSummaryService;

	public getCustomerSurveys(
		params: {
			detail_level?: 'simple' | 'full';
			status?: 'completed' | 'pending';
		} & CommonParams
	) {
		const queryString = Params.makeQueryString(params);
		return virtualBrownClient.get<PaginationResponse<CustomerSurvey>>(
			`${BASE_URL}/${queryString}`
		);
	}

	public createCustomerSurvey(
		type: 'WEB' | 'EMAIL',
		payload: CustomerSurveyPayload
	): Observable<CustomerSurvey> {
		let body: any = {
			...payload,
			created_on: payload.created_on.toISOString(),
		};
		if (type === 'WEB') {
			body = {
				...body,
				recipients: (body.recipients as SurveyRecipientPayload[]).map(
					(recipient) => ({
						...recipient,
						created_on: recipient.created_on.toISOString(),
						date_completed: recipient.date_completed.toISOString(),
					})
				),
			};
		}
		return virtualBrownClient.post(`${BASE_URL}/`, body);
	}

	public getCustomerSurveyConfig(
		params: CommonParams & {
			customer?: number;
			contract?: number;
			service: number;
			active: boolean;
		},
		settings?: {
			noOpts: boolean;
		}
	): Observable<PaginationResponse<CustomerSurveyConfig>> {
		const queryString = Params.makeQueryString(params);
		return virtualBrownClient.get(
			`${BASE_URL}/customersurveyconfigs/${queryString}`,
			settings && settings.noOpts ? noOpts : {}
		);
	}

	public createCustomerSurveyConfig = (
		payload: CustomerSurveyConfigPayload
	): Observable<CustomerSurveyConfig> =>
		virtualBrownClient.post(`${BASE_URL}/customersurveyconfigs/`, payload);

	public getCustomerSurveysSummary(
		params: {
			chart?: string; // e.g. '0' or '0,1'
			service?: number;
			time_zone?: any;
		} & CommonParams
	): Observable<CustomerSurveyChart[]> {
		const query = params;
		query.from = params.from ? params.from : null;
		query.to = params.to ? params.to : null;
		query.time_zone = zone;
		const queryString = Params.makeQueryString(query);

		return virtualBrownClient.get<CustomerSurveyChart[]>(
			`${BASE_URL}/summary/${queryString}`
		);
	}
}

export const customerSurveysService = new CustomerSurveysService();
