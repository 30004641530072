// services

// helpers
import { Observable } from 'rxjs';

// interfaces
import { WorkOrdersSummary, WorkOrdersSummaryParams } from '@api-interfaces';
import { Params } from '@helpers';
import { virtualBrownClient } from '@services/django-client';

// constants
const BASE_URL = 'workorders/summary';
const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

class WorkOrdersSummaryService {
	getSummaries<T extends WorkOrdersSummary.Chart>(
		params: WorkOrdersSummaryParams<T>
	): Observable<WorkOrdersSummary.SummaryReturnType[T][]> {
		const query = {
			...params,
			chart: params.chart.join(','),
			time_zone: params.time_zone || timeZone,
		};
		return virtualBrownClient.get(
			`${BASE_URL}/${Params.makeQueryString(query)}`,
			{
				noCustomer: params.customer !== undefined,
				noContract: params.contract !== undefined,
				noTag: params.contract !== undefined,
			}
		);
	}
}

export const workOrdersSummaryService = new WorkOrdersSummaryService();
