import { Todo, TodoPayload } from '@api-interfaces';

export const isValidTodo = (payload: {
	site: number;
	service: number;
	assignedTo: number;
	title: string;
	location: number;
	description: string;
}) => {
	const { site, service, assignedTo, title, location, description } = payload;
	if (site && service && assignedTo && title && location && description) {
		return true;
	}
	return false;
};

export const createTodoPayload = (todo: Todo) => {
	const todoPayload: TodoPayload = {
		id: todo?.id ?? null,
		areainspection_todo_location: todo?.areainspection_todo_location,
		assigned_to: {
			id: todo?.assigned_to?.id,
		},
		closed_date: todo?.closed_date,
		comment: todo?.comment,
		title: todo?.title,
		contract: {
			id: todo?.contract?.id,
		},
		created_on_date: todo?.created_on_date,
		creator: {
			id: todo?.creator?.id,
		},
		due_date: todo?.due_date,
		is_complete: todo?.is_complete,
		is_new: todo?.is_new,
		last_seen_date: todo?.last_seen_date,
		location: {
			id: todo?.location?.id,
		},
		media: todo?.media_list,
		service: {
			id: todo?.service?.id,
		},
		service_task_todo_location: todo?.service_task_todo_location,
		source: todo?.source,
		work_order_id: todo?.work_order_id ?? null,
		verification_id: todo?.verification_id ?? undefined,
		shifts:
			todo?.shifts?.map(function (shift) {
				return shift.id;
			}) ?? [],
		is_project_work: todo?.is_project_work ?? false,
	};
	return todoPayload;
};
