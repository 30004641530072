// services
import {
	BehavioralAuditsSummary,
	BehavioralAuditsSummaryParams,
} from '@api-interfaces';
import { Params } from '@helpers';
import { virtualBrownClient } from '@services';

// helpers

// interfaces

const BASE_URL = 'behavioralaudits/summary';

class BehavioralAuditsSummaryService {
	getSummaries<T extends BehavioralAuditsSummary.Chart>(
		params: BehavioralAuditsSummaryParams<T>
	) {
		const query = {
			...params,
			chart: params.chart.join(','),
		};
		return virtualBrownClient.get<
			BehavioralAuditsSummary.SummaryReturnType[T][]
		>(`${BASE_URL}/${Params.makeQueryString(query)}`, {
			noCustomer: params.customer != undefined,
			noContract: params.contract != undefined,
			noTag: params.tag_group != undefined,
		});
	}
}

export const behavioralAuditsSummaryService =
	new BehavioralAuditsSummaryService();
