import { Box, Text, IconButtonFlat } from '@atoms';
import { Button } from '@new';

// interfaces
import { PromptProps } from './prompt.interfaces';

export const Prompt = ({
  title,
  titleColor = 'primary',
  prompt,
  buttons,
  onCancel,
  className,
}: PromptProps) => {
  return (
    <Box
      rounded
      className={`tw-p-4 tw-mx-auto tw-w-full tw-shadow-md tw-max-w-[320px] sm:tw-max-w-[416px] sm:tw-p-8 ${className}`}
    >
      <div className="tw-flex tw-items-center tw-justify-between tw-mb-6">
        <Text font="h2" color={titleColor}>
          {title}
        </Text>
        <IconButtonFlat
          icon={['far', 'times']}
          className="tw-text-2xl"
          onClick={onCancel}
          type="secondary"
        />
      </div>
      {typeof prompt === 'string' ? (
        <Text font="body-md" color="hi-contrast">
          {prompt}
        </Text>
      ) : (
        prompt
      )}
      {buttons && (
        <div className="tw-flex tw-justify-end tw-space-x-4 tw-mt-8">
          {buttons.map((button) => (
            <Button key={button.label} {...button} />
          ))}
        </div>
      )}
    </Box>
  );
};

Prompt.defaultProps = {
  className: '',
};
