import { forwardRef } from 'react';

// components
import { ErrorText } from '../error-text';
import { InputRadio } from '../input-radio';
import { Label } from '../label';

// interfaces
import { FieldRadioGroupProps } from './field-radio-group.interfaces';

export const FieldRadioGroup = forwardRef<
  HTMLInputElement,
  FieldRadioGroupProps
>(
  (
    {
      id,
      name,
      label,
      labelFont,
      error,
      required,
      radios,
      layout,
      disabled,
      defaultChecked,
      checked,
      ...props
    },
    ref
  ) => (
    <fieldset data-ds2="field-radio-group" className="tw-space-y-1.5 tw-w-full">
      <div>
        <Label
          htmlFor={id}
          label={label}
          font={labelFont}
          required={required}
        />
        <ErrorText error={error} />
      </div>
      <ul
        className={`${
          layout === 'horizontal'
            ? 'tw-flex tw-items-center tw-flex-wrap tw-space-x-8'
            : 'tw-space-y-2'
        }`}
      >
        {radios.map((radio, index) => (
          <li key={index}>
            <InputRadio
              id={id}
              ref={ref}
              name={name}
              disabled={disabled}
              checked={
                checked !== undefined ? checked === radio.value : undefined
              }
              defaultChecked={
                defaultChecked !== undefined
                  ? defaultChecked === radio.value
                  : undefined
              }
              {...props}
              {...radio}
            />
          </li>
        ))}
      </ul>
    </fieldset>
  )
);

FieldRadioGroup.defaultProps = {
  labelFont: 'body-sm',
  layout: 'horizontal',
};
