import { Employee } from '@api-interfaces';
import { employeesService } from '@apis';

// interfaces

export const convertOrganizationToFormOrg = (orgs) => {
  const organization = orgs.map((org) => ({
    key: org.id,
    value: org.name,
    active: false,
  }));
  return organization;
};

export const getEmployees = (
  searchQuery: string,
  updateUsers: (payload: Employee[]) => void,
  allOrgEmployees: boolean = false,
  provider?: number
) => {
  employeesService
    .getEmployees(
      {
        search: searchQuery,
        detail_level: allOrgEmployees
          ? 'person_position_simple'
          : 'for_dropdown',
        provider,
      },
      allOrgEmployees && { noContract: true, noCustomer: true }
    )
    .subscribe({
      next: (res) => {
        updateUsers(res.results);
      },
    });
};

export const getCustomerEmployees = (
  searchQuery: string,
  updateUsers: (payload: Employee[]) => void
) => {
  employeesService
    .getCustomerEmployees({
      search: searchQuery,
    })
    .subscribe({
      next: (res) => {
        updateUsers(res.results);
      },
    });
};

export const getManagerEmployees = (
  searchQuery: string,
  provider: number,
  updateUsers: (payload: Employee[]) => void
) => {
  employeesService
    .getEmployees(
      {
        managers: true,
        detail_level: 'person_position',
        provider,
        append_account_directors_to_managers: true,
        search: searchQuery,
      },
      { noContract: true, noCustomer: true }
    )
    .subscribe({
      next: (res) => {
        const openOption = {
          id: null,
          person: {
            id: null,
            first_name: 'Open',
            last_name: '',
            phone_number: null,
            email_address: '',
            photo_url: '',
          },
        };
        res.results.unshift(openOption);
        updateUsers(res.results);
      },
    });
};
