import { useEffect } from 'react';

import { refetchService } from '@services';
import { RefetchCallback } from '@services/refetch-service/refetch-service.interfaces';

/**
 *
 * @param callback - a memoized function for when a refetchService.fetch is emitted
 * The callback must be memoized! or else the useEffect will keep subscribing/unsubscribing
 *
 */
export function useRefetchService(callback: RefetchCallback) {
	useEffect(() => {
		const subscription = refetchService.subscribe(callback);

		return () => {
			subscription.unsubscribe();
		};
	}, [callback]);
}
