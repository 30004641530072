// services
import { GetInvoicesParams, Invoice } from '@api-interfaces';
import { Params, TimeUtils } from '@helpers';
import { PaginationResponse } from '@models';
import { virtualBrownClient } from '@services';

import { financialInvoicesSummaryService } from './summary';

// helpers

// interfaces

const BASE_URL = 'financials/invoices';

class FinancialInvoicesService {
	readonly summary = financialInvoicesSummaryService;

	getInvoices(params?: GetInvoicesParams) {
		const query: any = { ...params };
		if (query.from) {
			query.from = TimeUtils.format(query.from, 'YYYY-MM-DD');
		}
		if (query.to) {
			query.to = TimeUtils.format(query.to, 'YYYY-MM-DD');
		}

		return virtualBrownClient.get<PaginationResponse<Invoice>>(
			`${BASE_URL}/${Params.makeQueryString(query)}`
		);
	}

	getInvoiceById(id: number) {
		return virtualBrownClient.get<Invoice>(`${BASE_URL}/${id}/`);
	}
}

export const financialInvoicesService = new FinancialInvoicesService();
