/* Polyfills */
import 'whatwg-fetch';
import 'core-js/es/array';
import 'core-js/es/map';
import 'core-js/es/string';
import 'core-js/es/promise';
import 'core-js/es/set';
import '@helpers/page-analytics';

import { datadogRum } from '@datadog/browser-rum';
import { library } from '@fortawesome/fontawesome-svg-core';
import { LocationProvider } from '@reach/router';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { createRoot } from 'react-dom/client';

import { StoredPickerProvider } from '@app/main/PickerContext/StoredPickerContext';
import { SEO } from '@components/_globals/components';
import { ErrorBoundary } from '@components/ErrorBoundary';
import { AppProvider } from '@core/app-context';
import SomethingWentWrong from '@core/base/structure/something-went-wrong';

// hyper launcher

// import fontawesome icon library

import env from '@core/environments';
import { Storage } from '@helpers';
import { HyperLauncherProvider } from '@hooks';
import { historyService } from '@services';

import { fas, far, fal, fat, fad } from '../config/icon-library';

import Root from './app';

import '@styles/config/index.css';
import '@components/_new/_form/_shared/style.css'; // Work Order Global Common Styling
import './index.scss';
// @ts-ignore passing in same data types, but still doesn't like
library.add(...fas, ...far, ...fal, ...fat, ...fad);

if (process.env.HOST_ENV === 'prod') {
  datadogRum.init({
    applicationId: env.datadog.appId,
    clientToken: env.datadog.clientToken,
    site: 'datadoghq.com',
    service: 'R4',
    env: process.env.HOST_ENV,
    version: '1.0.0',
    sampleRate: 100,
    trackInteractions: true,
  });
}

const theme = Storage.get('theme');
const body = document.querySelector('body');

if (!theme || theme === 'dark') {
  body.classList.add('tw-dark');
  Storage.set('theme', 'dark');
} else if (theme === 'light') {
  body.classList.add('light-theme');
}

const App = () => {
  return (
    <>
      <SEO title="Work Management Made Easy" />
      <ErrorBoundary fallback={<SomethingWentWrong appRoot />}>
        <LocationProvider history={historyService.history}>
          <AppProvider>
            <StoredPickerProvider>
              <HyperLauncherProvider>
                <DndProvider backend={HTML5Backend}>
                  <Root />
                </DndProvider>
              </HyperLauncherProvider>
            </StoredPickerProvider>
          </AppProvider>
        </LocationProvider>
      </ErrorBoundary>
    </>
  );
};

createRoot(document.getElementById('root')).render(<App />);
