import ElasticTranscoder from 'aws-sdk/clients/elastictranscoder';
import S3 from 'aws-sdk/clients/s3';
import { Credentials, config } from 'aws-sdk/global';
import { from, Observable, forkJoin } from 'rxjs';

import env from '@core/environments';
import {
	AwsSecurityInfo,
	AwsFolders,
	AwsPermissions,
	AwsAttachments,
	AwsAttachmentTypes,
	AwsObjectDetails,
	AwsVideoObject,
	AwsSignedUrl,
	EAwsFolders,
	ATTACHMENT_TYPE,
} from '@core/models/aws';
import { generateUniqueToken } from '@helpers';

import { awsCredentialsService } from './credentials';

// Functions available in browser
declare const atob;
declare const Blob;

class AwsService {
	/**
	 * @description Return configured Credentials object for AWS SDK
	 * @param securityInfo Token, key, etc obtained from awsCredentialsService.getSecurityInfo
	 */
	public getCredentials(securityInfo: AwsSecurityInfo): Credentials {
		const credentials = new Credentials(
			securityInfo.AccessKey,
			securityInfo.AccessSecret,
			securityInfo.AccessToken
		);
		config.update({ credentials });
		config.region = securityInfo.Endpoint.Region;
		return credentials;
	}

	/**
	 * @description Upload a file blob to S3
	 * @param file File uploaded by user, blob of data
	 * @param securityInfo AWS token, key, etc
	 * @param folder Folder to upload to on S3
	 * @param acl Permission / role
	 * @param isBase64
	 * @param fileNameOverride
	 */
	public s3Upload(
		file: File,
		securityInfo: AwsSecurityInfo,
		folder?: AwsFolders,
		acl?: AwsPermissions,
		isBase64?: boolean,
		fileNameOverride?: string
	): Observable<any> {
		const upload = new Promise((resolve, reject) => {
			try {
				// set creds
				this.getCredentials(securityInfo);
				// set required
				const bucket: string = securityInfo.Endpoint.Bucket;
				const fileName = generateUniqueToken();
				let fileNameFull = fileNameOverride
					? `${fileNameOverride}.png`
					: `${fileName}.png`;

				if (!isBase64) {
					const fileExt = file.name.split('.').pop();
					fileNameFull = fileNameOverride || `${fileName}.${fileExt}`;
				} else {
					const binary = atob((file as any).split(',')[1]);
					const array = [];
					for (let i = 0; i < binary.length; i++) {
						array.push(binary.charCodeAt(i));
					}
					file = new Blob([new Uint8Array(array)], {
						type: 'image/png',
					});
				}
				folder = folder || this.getFolderByType(file.type);
				const key = `${folder}/${fileNameFull}`;
				const attachmentType = this.getAttachmentTypeByType(file.type);
				const s3 = new S3({ params: { Bucket: bucket } });
				const params: any = {
					ACL: acl,
					Key: key,
					ContentType: file.type,
					Body: file,
					Bucket: bucket,
				};

				const uploader: any = s3.putObject(
					params,
					(err: any, _data: any) => {
						if (err) {
							reject(err);
						}
						const details: AwsObjectDetails = {
							Url: this.getUrl(key),
							Key: key,
							Name: fileNameFull,
							Guid: fileName,
							Type: file.type,
							Size: file.size,
							Folder: folder,
							AttachmentType: attachmentType,
							AttachmentTypeId:
								AwsAttachmentTypes[attachmentType],
						};
						resolve(details);
					}
				);
				uploader.on('httpUploadProgress', function () {
					// const percent: number = Math.round(progress.loaded / progress.total * 100);
					// def.notify(percent);
				});
			} catch (error) {
				reject(error);
			}
		});
		return from(upload);
	}

	/**
	 * @description Download a file from S3
	 * @param key Name of file to download
	 * @param credentials Aws, token, key, etc
	 * @param bucket Name of bucket file is in
	 */
	public s3Download = (
		key: string,
		credentials: AwsSecurityInfo,
		bucket?: string
	): Observable<any> => {
		const download = new Promise((resolve, reject) => {
			try {
				bucket = bucket || credentials.Endpoint.Bucket;
				// set creds
				this.getCredentials(credentials);
				const s3 = new S3({ params: { Bucket: bucket } });
				s3.getObject(
					{ Bucket: bucket, Key: key },
					function (err, data) {
						if (err) {
							reject(err);
						}
						resolve(data);
					}
				);
			} catch (error) {
				reject(error);
			}
		});
		return from(download);
	};

	public encodeVideo = (
		fileNameFull: string,
		creds: AwsSecurityInfo
	): Observable<any> => {
		const encodePromise = new Promise((resolve, reject) => {
			const elastictranscoder = new ElasticTranscoder();
			const fileNameParts = fileNameFull.split('.');
			const fileName = fileNameParts[0];
			let pipelineId = '1441407359960-m8cz8b'; // prod

			if (creds.Endpoint.Bucket.indexOf('dev') > 0) {
				pipelineId = '1442009080126-z5kg3g';
			}
			const eParams = {
				Input: {
					AspectRatio: 'auto',
					Container: 'auto',
					FrameRate: 'auto',
					Interlaced: 'auto',
					Key: `video/${fileNameFull}`,
					Resolution: 'auto',
				},
				PipelineId: pipelineId,
				OutputKeyPrefix: 'video/',
				Outputs: this.getTranscoderOutputList(fileName),
			};

			elastictranscoder.createJob(eParams, (err, data) => {
				if (err) {
					reject(err);
				}
				resolve(data);
			});
		});

		return from(encodePromise);
	};

	public getSecureUrlByName = (
		uniqueId: string,
		folder: EAwsFolders
	): Promise<any> => {
		const securePromise = new Promise((resolve, reject) => {
			awsCredentialsService
				.getAwsSignedUrl(EAwsFolders[folder], uniqueId)
				.subscribe((data: AwsSignedUrl) => {
					const details: AwsObjectDetails = <any>{
						Key: data.Key,
						Name: uniqueId,
						Url: data.Url,
					};

					resolve(details);
				}, reject);
		});

		return securePromise;
	};

	public getSignedVideoUrl = (
		videoName: string,
		videoSize?: number,
		encoding?: string
	): Observable<AwsVideoObject> => {
		const signedPromise = new Promise<AwsVideoObject>((resolve, reject) => {
			const supportedVideoSizes: Array<number> = [480, 720];
			try {
				const size = videoSize || null;
				encoding = encoding || 'mp4';
				const parts = videoName.split('.');
				const fileName = parts[0];
				if (size && supportedVideoSizes.indexOf(size) < 0) {
					throw new Error('Video size not supported');
				}
				const key = !size
					? videoName
					: `${fileName}-${size.toString()}p` + `.${encoding}`;
				// create tokens for video and thumbs
				forkJoin(
					[
						// video
						awsCredentialsService.getCloudfrontSignedUrl(
							'video',
							key
						),
						// thumbnail
						awsCredentialsService.getCloudfrontSignedUrl(
							'video',
							`${fileName}-tb-${size}-00001.png`
						),
					],
					(err: any, res: Array<AwsSignedUrl>) => {
						if (err) {
							throw err;
						}
						const details: AwsVideoObject = {
							Key: res[0].Key,
							Name: key,
							Thumbnails: [res[1].Url],
							Url: res[0].Url,
						};
						resolve(details);
					}
				);
			} catch (error) {
				reject(error);
			}
		});
		return from(signedPromise);
	};

	/**
	 * @description Returns list of transcoded files.
	 */
	public getTranscoderOutputList = (fileName: string): Array<any> => {
		return [
			{
				Key: `${fileName}-720p.mp4`,
				PresetId: '1449001013255-ergpge',
				Rotate: 'auto',
				ThumbnailPattern: `${fileName}-tb-720-{count}`,
			},
			{
				Key: `${fileName}-480p.mp4`,
				PresetId: '1449001064210-5mciyo',
				Rotate: 'auto',
				ThumbnailPattern: `${fileName}-tb-480-{count}`,
			},
			{
				Key: `${fileName}-720p.webm`,
				PresetId: '1448921162741-jbgm3l',
				Rotate: 'auto',
				ThumbnailPattern: `${fileName}-tb-720-{count}`,
			},
			{
				Key: `${fileName}-480p.webm`,
				PresetId: '1449001109885-92354k',
				Rotate: 'auto',
				ThumbnailPattern: `${fileName}-tb-480-{count}`,
			},
		];
	};

	public getFolderByAttachmentType = (
		attachmentType: string
	): EAwsFolders => {
		let folder: EAwsFolders;
		switch (attachmentType) {
			case ATTACHMENT_TYPE[ATTACHMENT_TYPE.Pdf].toString():
				folder = EAwsFolders.file;
				break;
			case ATTACHMENT_TYPE[ATTACHMENT_TYPE.Audio].toString():
				folder = EAwsFolders.audio;
				break;
			case ATTACHMENT_TYPE[ATTACHMENT_TYPE.Image].toString():
				folder = EAwsFolders.image;
				break;
			case ATTACHMENT_TYPE[ATTACHMENT_TYPE.Video].toString():
				folder = EAwsFolders.video;
				break;
			case ATTACHMENT_TYPE[ATTACHMENT_TYPE.Audio].toString():
				folder = EAwsFolders.audio;
				break;
			default:
				folder = EAwsFolders.file;
				break;
		}

		return folder;
	};

	/**
	 * @description Returns a folder type depending on the file type extension
	 */
	private getFolderByType = (fileType: string): AwsFolders => {
		const typePart: AwsFolders | undefined = fileType.split(
			'/'
		)[0] as AwsFolders;
		return typePart || 'file';
	};

	/**
	 * @description Gets the attachment type to set file type on uplaod to S3
	 */
	private getAttachmentTypeByType = (fileType: string): AwsAttachments => {
		const typeParts = fileType.split('/');
		const type: AwsAttachments = typeParts[0] as AwsAttachments;
		if (['video', 'image', 'audio'].includes(type)) {
			return type;
		}

		const pdfMimeTypes: Array<string> = [
			'pdf',
			'x-pdf',
			'acrobat',
			'vnd.pdf',
			'pdf',
			'x-pdf',
		];
		if (pdfMimeTypes.includes(typeParts[1])) {
			return 'pdf';
		}

		return 'file';
	};

	public getUrl(key: string) {
		if (!key) {
			return null;
		}
		if (key.includes('asset') || key.includes('ai/')) {
			return `${env.aws.cloudfront.url}/${key}`;
		}
		return `${env.aws.cloudfront.url}/image/${key}`;
	}
}

export const awsService = new AwsService();
