// https://app.zeplin.io/styleguide/60369069bde0949bb588b718/components?seid=60369b4c527ce0beb40c75e7
import { TextProps } from '@atoms/text/text.interfaces';

import { AvatarProps } from './avatar.interfaces';

export const avatarSizeMap: { [Size in AvatarProps['size']]: string } = {
	sm: 'tw-h-6 tw-w-6', // 24px
	md: 'tw-h-9 tw-w-9', // 36px
	lg: 'tw-h-14 tw-w-14', // 56px
	xl: 'tw-h-20 tw-w-20', // 80px
	'profile-card': 'tw-h-[120px] tw-w-[120px]',
	'profile-modal': 'tw-h-[216px] tw-w-[216px]',
	'fp-md': 'tw-h-12 tw-w-12',
};

export const avatarBgTypeMap: {
	[Type in AvatarProps['type']]: string;
} = {
	primary: 'tw-bg-theme-primary-500-300',
	secondary: 'tw-bg-theme-neutral-600-500',
	success: 'tw-bg-theme-success-700-300',
	warning: 'tw-bg-theme-warning-500-300',
	danger: 'tw-bg-theme-danger-500-300',
};

export const avatarBorderTypeMap: {
	[Type in AvatarProps['type']]: string;
} = {
	primary: 'tw-border-solid tw-border tw-border-theme-primary-500-300',
	secondary: 'tw-border-solid tw-border tw-border-theme-neutral-600-500',
	success: 'tw-border-solid tw-border tw-border-theme-success-700-300',
	warning: 'tw-border-solid tw-border tw-border-theme-warning-500-300',
	danger: 'tw-border-solid tw-border tw-border-theme-danger-500-300',
};

export const avatarSizeToTextSizeMap: {
	[Size in AvatarProps['size']]: TextProps['font'];
} = {
	sm: 'body-sm',
	md: 'body-md',
	lg: 'body-lg',
	xl: 'h2',
	'profile-card': 'h1',
	'profile-modal': 'data-point',
	'fp-md': 'body-md',
};
