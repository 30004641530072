import { FrequencyPeriod } from '@api-interfaces';
import { SummaryChart } from '@models';

export namespace RoutesSummary {
	export enum Chart {
		GetIncompleteRoutesForDay = 0,
		GetIncompleteRouteCountsByMonth = 1,
		GetIncompleteRouteCountsLast12Mos = 2,
		GetIncompleteRoutesPercentageByMonth = 3,
		GetRouteCounts = 4,
		GetRouteTaskCounts = 5,
		GetTaskCountByContract = 6,
		GetComplianceTaskCountByDay = 7,
		GetComplianceTaskCountByMonth = 8,
		GetIncompleteRoutes = 11,
		GetIncompleteTasks = 12,
		GetRouteComplianceByMonth = 13,
		GetRouteComplianceByDay = 14,
	}

	export interface IncompleteRoutesForDay {
		complete: number;
		incomplete: number;
	}

	export interface IncompleteRoutesForDaySummary
		extends SummaryChart<IncompleteRoutesForDay> {
		chart: 'GeIncompleteRoutesForDay';
		number: '0';
	}

	export interface IncompleteRouteCountsByMonth {
		complete: number;
		day: string;
		incomplete: number;
	}

	export interface IncompleteRouteCountsByMonthSummary
		extends SummaryChart<IncompleteRouteCountsByMonth[]> {
		chart: 'GetIncompleteRouteCountsByMonth';
		number: '1';
	}

	export interface IncompleteRouteCountsLast12Mos {
		complete: number;
		incomplete: number;
	}

	export interface IncompleteRouteCountsLast12MosSummary
		extends SummaryChart<IncompleteRouteCountsLast12Mos> {
		chart: 'GetIncompleteRouteCountsLast12Mos';
		number: '2';
	}

	export interface IncompleteRoutesPercentageByMonth {
		complete: number;
		incomplete: number;
		mo: string;
	}

	export interface IncompleteRoutesPercentageByMonthSummary
		extends SummaryChart<IncompleteRoutesPercentageByMonth[]> {
		chart: 'GetIncompleteRoutesPercentageByMonth';
		number: '3';
	}

	export interface RouteCounts {
		complete_count: number;
		complete_percentage: string;
		days_worked: number;
		incomplete_count: number;
		incomplete_percentage: string;
		total_count: number;
		total_routes: number;
	}

	export interface RouteCountsSummary extends SummaryChart<RouteCounts> {
		chart: 'GetRouteCounts';
		number: '4';
	}

	export interface RouteTaskCounts {
		area_count: number;
		average_daily_unassigned: number;
		program_count: number;
		route_count: number;
		route_count_including_unassigned: number;
		shift_count: number;
		total_annual_tasks: number;
		total_annual_tasks_unassigned: number;
		total_assignments: number;
		total_daily_tasks: number;
		total_daily_tasks_unassigned: number;
	}

	export interface RouteTaskCountsSummary
		extends SummaryChart<RouteTaskCounts[]> {
		chart: 'GetRouteTaskCounts';
	}

	export interface TaskCountByContract {
		as_needed_occurrences: number;
		daily_occurrences: number;
		monthly_occurrences: number;
		occurrences_by_frequency: {
			occurrences: number;
			period: FrequencyPeriod;
			recurrence: number;
		}[];
		quarterly_occurrences: number;
		total_occurrences: number;
		weekly_occurrences: number;
		yearly_occurrences: number;
	}

	export interface TaskCountByContractSummary
		extends SummaryChart<TaskCountByContract[]> {
		chart: 'GetTaskCountByContract';
		number: '6';
	}

	export interface ComplianceTaskCountByDay {
		complete_task_count: number;
		day: string;
		incomplete_task_count: number;
		task_completion_percentage: number;
		total_task_count: number;
	}

	export interface ComplianceTaskCountByDaySummary
		extends SummaryChart<ComplianceTaskCountByDay[]> {
		chart: 'GetComplianceTaskCountByDay';
		number: '7';
	}

	export interface ComplianceTaskCountByMonth {
		complete_task_count: number;
		incomplete_task_count: number;
		month: string;
		task_completion_percentage: number;
		total_task_count: number;
	}

	export interface ComplianceTaskCountByMonthSummary
		extends SummaryChart<ComplianceTaskCountByMonth[]> {
		chart: 'GetComplianceTaskCountByMonth';
		number: '8';
	}

	export interface IncompleteRouteCount {
		total_route: number;
		incomplete_route: number;
		route_compliance: number;
	}

	export interface IncompleteRouteCountSummary
		extends SummaryChart<IncompleteRouteCount[]> {
		chart: 'GetIncompleteRoutes';
		number: '11';
	}

	export interface IncompleteTaskCount {
		total_task: number;
		incomplete_task: number;
		task_compliance: number;
	}

	export interface IncompleteRouteMultipleMonthSummary {
		month: string;
		incomplete: number;
		total: number;
		compliance: number;
	}

	export interface GetRouteComplianceByMonthSummary
		extends SummaryChart<IncompleteRouteMultipleMonthSummary[]> {
		chart: 'GetRouteComplianceByMonth';
		number: '13';
	}

	export interface IncompleteRoutesByDay {
		total_route: number;
		day_worked: string;
		incomplete: number;
		route_compliance: number;
	}

	export interface GetRouteComplianceByDaySummary
		extends SummaryChart<IncompleteRoutesByDay[]> {
		chart: 'GetRouteComplianceByDay';
		number: '14';
	}

	export interface SummaryReturnType {
		[Chart.GetIncompleteRoutesForDay]: IncompleteRoutesForDaySummary;
		[Chart.GetIncompleteRouteCountsByMonth]: IncompleteRouteCountsByMonthSummary;
		[Chart.GetIncompleteRouteCountsLast12Mos]: IncompleteRouteCountsLast12MosSummary;
		[Chart.GetIncompleteRoutesPercentageByMonth]: IncompleteRoutesPercentageByMonthSummary;
		[Chart.GetRouteCounts]: RouteCountsSummary;
		[Chart.GetRouteTaskCounts]: RouteTaskCountsSummary;
		[Chart.GetTaskCountByContract]: TaskCountByContractSummary;
		[Chart.GetComplianceTaskCountByDay]: ComplianceTaskCountByDaySummary;
		[Chart.GetComplianceTaskCountByMonth]: ComplianceTaskCountByMonthSummary;
		[Chart.GetIncompleteRoutes]: IncompleteRouteCountSummary;
	}
}
