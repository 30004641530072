// services

// helpers
import { map } from 'rxjs/operators';

// interfaces
import {
	WorkOrder,
	WorkOrderPayload,
	WorkOrderOrderingParams,
	CostLineItem,
	WorkOrderCostLineItemPayload,
} from '@api-interfaces';
import { Params } from '@core/helpers';
import { virtualBrownClient } from '@core/services/django-client';
import { CommonParams, PaginationResponse } from '@models';

import { workOrdersSummaryService } from './summary';

const zone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const BASE_URL = `workorders`;

class WorkOrdersService {
	readonly summary = workOrdersSummaryService;

	getUpcomingWorkOrders = (
		params: {
			service?: number;
		} & CommonParams
	) => {
		const query = {
			...params,
			upcoming: new Date().toISOString(),
		};
		const qs = Params.makeQueryString(query);
		return virtualBrownClient.get<PaginationResponse<WorkOrder>>(
			`${BASE_URL}/${qs}`
		);
	};

	getWorkOrders = (
		params: {
			service?: number;
			is_billable?: boolean;
			is_in_scope?: boolean;
			ordering?: WorkOrderOrderingParams;
			due_status?: string;
			upcoming?: Date;
			is_project_work?: boolean;
			servicearea?: number;
			areatype?: number;
			root_area?: number;
			export_to_excel?: boolean;
		} & CommonParams
	) => {
		const query = { ...params, time_zone: zone };

		const qs = Params.makeQueryString(query);
		return virtualBrownClient.get<PaginationResponse<WorkOrder>>(
			`${BASE_URL}/${qs}`
		);
	};

	getWorkOrderById(id: number) {
		return virtualBrownClient.get<WorkOrder>(`${BASE_URL}/${id}/`);
	}

	createWorkOrder = (body: WorkOrderPayload) =>
		virtualBrownClient.post<WorkOrder>(`${BASE_URL}/`, body);

	updateWorkOrderById = (id: number, payload: Partial<WorkOrderPayload>) =>
		virtualBrownClient.put<WorkOrder>(`${BASE_URL}/${id}/`, payload);

	deleteWorkOrderById = (id: number) =>
		virtualBrownClient.delete(`${BASE_URL}/${id}/`);

	getCounts = (
		params: {
			service?: number;
		} & CommonParams
	) => {
		const query = {
			...params,
			chart: 0,
			time_zone: zone,
		};

		const qs = Params.makeQueryString(query);
		return virtualBrownClient.get(`${BASE_URL}/summary/${qs}`).pipe(
			map((res: any) =>
				res && res.length
					? res[0].results
					: {
							new_count: 0,
							assigned_count: 0,
							resolved_count: 0,
							in_scope: 0,
							submitted_count: 0,
							out_of_scope: 0,
							past_due_count: 0,
							on_time_completion_count: 0,
					  }
			)
		);
	};

	getCharts = (
		params: {
			service?: number;
			provider?: number;
		} & CommonParams
	) => {
		const query = {
			...params,
			chart: '0,1,2,4,5',
			time_zone: zone,
		};

		const qs = Params.makeQueryString(query);
		return virtualBrownClient.get(`${BASE_URL}/summary/${qs}`);
	};

	getEmployeeWorkOrders = (
		params: {
			order_by?: string;
			service?: number;
		} & CommonParams
	) => {
		const query = {
			...params,
		};

		const qs = Params.makeQueryString(query);
		return virtualBrownClient
			.get(`${BASE_URL}/employees/${qs}`, { noCustomer: true })
			.pipe(map((res: any) => (res ? res.results : [])));
	};

	public createWorkOrderCostLineItem = (body: WorkOrderCostLineItemPayload) => {
		const contract_id = virtualBrownClient.contracts?.[0]?.id;

		// Need to add the contract for permissions
		const bodyWithContract = {
			...body,
			contract: {
				id: contract_id,
			},
		};

		return virtualBrownClient.post<CostLineItem>(
			`${BASE_URL}/cost_line_items/`,
			bodyWithContract
		);
	};

	public updateWorkOrderCostLineItemById = (
		id: number,
		payload: Partial<WorkOrderCostLineItemPayload>
	) => {
		return virtualBrownClient.put<CostLineItem>(
			`${BASE_URL}/cost_line_items/${id}/`,
			payload
		);
	};

	public deleteWorkOrderCostLineItemById = (id: number) => {
		const contract_id = virtualBrownClient.contracts?.[0]?.id;

		// Need to add the contract for permissions
		const bodyWithContract = {
			contract: {
				id: contract_id,
			},
		};

		return virtualBrownClient.delete<any>(
			`${BASE_URL}/cost_line_items/${id}/`,
			bodyWithContract
		);
	};
}

export const workOrdersService = new WorkOrdersService();
