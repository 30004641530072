import { SummaryChart, SummaryParams } from '@models';

interface Site {
	city: string | null;
	id: number;
	is_pass: boolean;
	lat: number;
	lon: number;
	name: string;
	organization_name: string;
	postal_code: string | null;
	region: string | null;
	square_feet: number;
	street1: string | null;
	street2: string | null;
}

export interface FinancialsPurchaseOrdersSummaryParams<T>
	extends SummaryParams<T> {
	is_billable?: boolean;
	is_green?: boolean;
	commodity_id?: number;
	parent_service?: number;
	service?: number;
	customer?: number;
	vertical?: number | string;
}

export namespace FinancialsPurchaseOrdersSummary {
	export enum Chart {
		GetPurchaseOrdersByProgram = 20,
		GetPurchaseOrdersBySite = 21,
		GetPurchaseOrdersBySiteMonthly = 22,
		GetPurchaseOrdersMonthlyTrends = 23,
		GetPurchaseOrdersCounts = 24,
		GetPurchaseOrdersSpendByCategory = 25,
		GetPurchaseOrdersSpendByIsBillable = 26,
		GetPurchaseOrdersSpendByIsGreen = 27,
		GetPurchaseOrdersMonthlyProductTrends = 28,
		GetPurchaseOrderTotalsBySiteMonthly = 29,
		GetPurchaseOrdersSpendBySubCommodityIsBillable = 30,
		GetPurchaseOrdersSpendBySubCommodityIsGreen = 31,
		GetPurchaseOrdersSpendByVertical = 45,
		GetPurchaseOrdersSpendByVerticalOrganization = 44,
	}

	export interface PurchaseOrdersByProgram {
		amount: number;
		percentage: number;
		program_id: number;
		program_name: string;
		total_amount: number;
	}

	export interface PurchaseOrdersByProgramSummary
		extends SummaryChart<PurchaseOrdersByProgram[]> {
		chart: 'GetPurchaseOrdersByProgram';
		number: '20';
	}

	export interface PurchaseOrdersBySite extends Site {
		total_amount: number;
	}

	export interface PurchaseOrdersBySiteSummary
		extends SummaryChart<PurchaseOrdersBySite[]> {
		chart: 'GetPurchaseOrdersBySite';
		max_total_amount: number;
	}

	export interface PurchaseOrdersBySiteMonthly {
		month: string;
		total_amounts: null | Array<{
			contract_id: number;
			contract_name: string;
			service_id: number;
			service_name: string;
			service_total: number;
		}>;
	}

	export interface PurchaseOrdersBySiteMonthlySummary
		extends SummaryChart<PurchaseOrdersBySiteMonthly[]> {
		chart: 'GetPurchaseOrdersBySiteMonthly';
		number: '22';
		time_scale: 'day' | 'month';
	}

	export interface PurchaseOrdersMonthlyTrends {
		month: string;
		total_amount: number;
	}
	export interface PurchaseOrdersMonthlyTrendsSummary
		extends SummaryChart<PurchaseOrdersMonthlyTrends[]> {
		chart: 'GetPurchaseOrdersMonthlyTrends';
		number: '23';
	}

	export interface PurchaseOrdersCounts {
		total_amount: number;
	}

	export interface PurchaseOrdersCountsSummary
		extends SummaryChart<PurchaseOrdersCounts> {
		chart: 'GetPurchaseOrdersCounts';
		number: '24';
	}

	export interface PurchaseOrdersSpendByCategory {
		id: number;
		name: string;
		commodity_id: number;
		commodity_name: string;
		amount: number;
		percentage: number;
		po_total_amount: number;
	}

	export interface PurchaseOrdersSpendByCategorySummary
		extends SummaryChart<PurchaseOrdersSpendByCategory[]> {
		chart: 'GetPurchaseOrdersSpendByCategory';
		number: '25';
	}

	export interface PurchaseOrdersSpendByIsBillable {
		commodity_id: number;
		commodity_name: string;
		is_billable: boolean;
		percentage: number;
		total_amount: number;
		total_po_amount: number;
	}

	export interface PurchaseOrdersSpendByIsBillableSummary
		extends SummaryChart<PurchaseOrdersSpendByIsBillable[]> {
		chart: 'GetPurchaseOrdersSpendByIsBillable';
		number: '26';
	}

	export interface PurchaseOrdersSpendByIsGreen {
		commodity_id: number;
		commodity_name: string;
		is_green: boolean;
		percentage: number;
		total_amount: number;
		total_po_amount: number;
	}

	export interface PurchaseOrdersSpendByIsGreenSummary
		extends SummaryChart<PurchaseOrdersSpendByIsGreen[]> {
		chart: 'GetPurchaseOrdersSpendByIsGreen';
		number: '27';
	}

	export interface PurchaseOrdersMonthlyProductTrends {
		amount: number;
		commodity_name: string;
		month: string;
	}

	export interface PurchaseOrdersMonthlyProductTrendsSummary
		extends SummaryChart<PurchaseOrdersMonthlyProductTrends[]> {
		chart: 'GetPurchaseOrdersMonthlyProductTrends';
		number: '28';
		time_scale: 'day' | 'month';
	}

	export interface PurchaseOrderTotalsBySiteMonthly {
		id: number;
		month: string;
		name: string;
		total_amount: number;
	}

	export interface PurchaseOrderTotalsBySiteMonthlySummary
		extends SummaryChart<PurchaseOrderTotalsBySiteMonthly[]> {
		chart: 'GetPurchaseOrderTotalsBySiteMonthly';
		number: '29';
	}

	export interface PurchaseOrdersSpendBySubCommodityIsBillable {
		amount: number;
		commodity_name: string;
		is_billable: boolean;
		percentage: number;
		subcommodity_name: string;
	}

	export interface PurchaseOrdersSpendBySubCommodityIsBillable {
		amount: number;
		commodity_name: string;
		is_billable: boolean;
		percentage: number;
		subcommodity_name: string;
	}

	export interface PurchaseOrdersSpendBySubCommodityIsBillableSummary
		extends SummaryChart<PurchaseOrdersSpendBySubCommodityIsBillable[]> {
		chart: 'GetPurchaseOrdersSpendBySubCommodityIsBillable';
		number: '30';
	}

	export interface PurchaseOrdersSpendBySubCommodityIsGreen {
		amount: number;
		commodity_name: string;
		is_green: boolean;
		percentage: number;
		subcommodity_name: string;
	}

	export interface PurchaseOrdersSpendBySubCommodityIsGreenSummary
		extends SummaryChart<PurchaseOrdersSpendBySubCommodityIsGreen[]> {
		chart: 'GetPurchaseOrdersSpendBySubCommodityIsGreen';
		number: '31';
	}

	export interface PurchaseOrdersSpendByVertical {
		vertical_id: number;
		vertical_name: string;
		programs: {
			id: number;
			name: string;
			total_amount: number;
		}[];
	}

	export interface PurchaseOrdersSpendByVerticalSummary
		extends SummaryChart<PurchaseOrdersSpendByVertical[]> {
		chart: 'GetPurchaseOrdersSpendByVertical';
		number: '45';
	}

	export interface PurchaseOrdersSpendByVerticalOrganization {
		customer_id: number;
		customer_name: string;
		programs: {
			id: number;
			name: string;
			total_amount: number;
		}[];
	}

	export interface PurchaseOrdersSpendByVerticalOrganizationSummary
		extends SummaryChart<PurchaseOrdersSpendByVerticalOrganization[]> {
		chart: 'GetPurchaseOrdersSpendByVerticalOrganization';
		number: '44';
	}

	export type SummaryReturnType = {
		[Chart.GetPurchaseOrdersByProgram]: PurchaseOrdersByProgramSummary;
		[Chart.GetPurchaseOrdersBySite]: PurchaseOrdersBySiteSummary;
		[Chart.GetPurchaseOrdersBySiteMonthly]: PurchaseOrdersBySiteMonthlySummary;
		[Chart.GetPurchaseOrdersMonthlyTrends]: PurchaseOrdersMonthlyTrendsSummary;
		[Chart.GetPurchaseOrdersCounts]: PurchaseOrdersCountsSummary;
		[Chart.GetPurchaseOrdersSpendByCategory]: PurchaseOrdersSpendByCategorySummary;
		[Chart.GetPurchaseOrdersSpendByIsBillable]: PurchaseOrdersSpendByIsBillableSummary;
		[Chart.GetPurchaseOrdersSpendByIsGreen]: PurchaseOrdersSpendByIsGreenSummary;
		[Chart.GetPurchaseOrdersMonthlyProductTrends]: PurchaseOrdersMonthlyProductTrendsSummary;
		[Chart.GetPurchaseOrderTotalsBySiteMonthly]: PurchaseOrderTotalsBySiteMonthlySummary;
		[Chart.GetPurchaseOrdersSpendBySubCommodityIsBillable]: PurchaseOrdersSpendBySubCommodityIsBillableSummary;
		[Chart.GetPurchaseOrdersSpendBySubCommodityIsGreen]: PurchaseOrdersSpendBySubCommodityIsGreenSummary;
		[Chart.GetPurchaseOrdersSpendByVertical]: PurchaseOrdersSpendByVerticalSummary;
		[Chart.GetPurchaseOrdersSpendByVerticalOrganization]: PurchaseOrdersSpendByVerticalOrganizationSummary;
	};
}
