import { useRef, useEffect } from 'react';

// services
import { Subscription } from 'rxjs';

import { mixpanelService, workOrdersService } from '@apis';

// helpers
import { ModalStack } from '@atoms';
import { toasterService } from '@core/services';
import { sendError } from '@helpers';
import { Modal } from '@templates';

import { useWorkOrderModalContext } from '../../_context';
import { mixpanelDeleteQualityWorkOrder } from '@app/mixpanel/MixpanelPageTrack.tsx';

// components

// toaster

export const DeleteWorkOrderPromptModal = () => {
    const {
        closeModal,
        workOrder,
        setActiveModals,
        activeModals,
        hasBeenUpdated,
    } = useWorkOrderModalContext();
    const subscription = useRef<Subscription>(new Subscription());

    useEffect(() => {
        return () => {
            subscription.current.unsubscribe();
        };
    }, []);

    function handleDelete() {
        subscription.current = workOrdersService
            .deleteWorkOrderById(workOrder.id)
            .subscribe({
                next: () => {
                    mixpanelDeleteQualityWorkOrder();
                    hasBeenUpdated.current = true;
                    toasterService.newToast({
                        status: 'success',
                        message: 'Work order was successfully deleted',
                    });
                    closeModal();
                },
                error: sendError(),
            });
    }

    function handleCancel() {
        setActiveModals({
            ...activeModals,
            'delete-work-order': false,
        });
    }

    return (
        activeModals['delete-work-order'] && (
            <ModalStack position="top-half-center">
                <Modal.Prompt
                    key="delete"
                    title="Delete?"
                    prompt={`Are you sure you want to delete Work Order #${workOrder.id}?`}
                    onCancel={handleCancel}
                    buttons={[
                        {
                            label: 'Cancel',
                            color: 'alternate',
                            onClick: handleCancel,
                        },
                        {
                            label: 'Delete',
                            color: 'primary',
                            onClick: handleDelete,
                        },
                    ]}
                />
            </ModalStack>
        )
    );
};
