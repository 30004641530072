// interfaces
import { Observable } from 'rxjs';

import {
	ServiceArea,
	ServiceAreaPost,
	ServiceAreaPatch,
	GetServiceAreasParams,
	ServiceAreaDDL,
	GetAreaTypes,
} from '@api-interfaces';

// services
import { virtualBrownClient } from '@core/services/django-client';

// helpers
import { Params } from '@helpers';
import { CommonParams, PaginationParams, PaginationResponse } from '@models';

import { serviceAreasSummaryService } from './summary';

// constants
const BASE_URL = 'serviceareas';

const noOpts = {
	noContract: true,
	noCustomer: true,
	noTag: true,
};

class ServiceAreasService {
	readonly summary = serviceAreasSummaryService;

	getServiceAreas(
		params?: GetServiceAreasParams,
		options?: Partial<typeof noOpts>
	) {
		const query = {
			...params,
		};
		if (!query.limit) query.limit = 1000;
		const queryString = Params.makeQueryString(query);

		return virtualBrownClient.get<PaginationResponse<ServiceArea>>(
			`${BASE_URL}/${queryString}`,
			options || { noTag: true, noCustomer: false, noContract: false }
		);
	}

	getServiceAreaDDL(params?: GetServiceAreasParams) {
		return virtualBrownClient.get<ServiceAreaDDL>(
			`${BASE_URL}/${Params.makeQueryString(params || {})}`
		);
	}

	public getServiceAreaById(id: number) {
		return virtualBrownClient.get<ServiceArea>(`${BASE_URL}/${id}/`);
	}

	getAreaTypes = (id: number, params?: { contract } & PaginationParams) => {
		const queryString = Params.makeQueryString(params);

		return virtualBrownClient.get(`areas/${id}/areatypes/${queryString}`, {
			noCustomer: true,
			noTag: true,
			noContract: true,
		});
	};

	create = (body) => virtualBrownClient.post(`${BASE_URL}/`, body);

	update = (id, body) => virtualBrownClient.put(`${BASE_URL}/${id}/`, body);

	createBulk = (body: ServiceAreaPost[]): Observable<ServiceArea[]> =>
		virtualBrownClient.post(`${BASE_URL}/bulk/`, body);

	updateBulk = (body: ServiceAreaPatch[]): Observable<ServiceArea[]> =>
		virtualBrownClient.patch(`${BASE_URL}/bulk/`, body);

	addAreaTypes = (buildingId, areaId, areaTypeId, count) => {
		const body = {
			id: areaId,
			area_type: {
				id: areaTypeId,
				count,
			},
		};

		return virtualBrownClient.post(`areas/${buildingId}/areatypes/`, body);
	};

	deleteServiceArea = (
		id: number
	): Observable<'' | { detail: 'Not found.' }> =>
		virtualBrownClient.delete(`${BASE_URL}/${id}/`, noOpts);

	public getMasterAreaTypes(
		params: GetAreaTypes & CommonParams
	): Observable<PaginationResponse<MasterAreaType>> {
		return virtualBrownClient.get(
			`serviceareas/masterareatypes/${Params.makeQueryString({
				...params,
			})}`,
			noOpts
		);
	}

	public patchMasterAreaType = (body: { id: number; name: string }[]) =>
		virtualBrownClient.patch(`${BASE_URL}/masterareatypes/bulks/`, body);

	public postMasterAreaType = (body: { name: string }[]) =>
		virtualBrownClient.post(`${BASE_URL}/masterareatypes/bulks/`, body);
}

export const serviceAreasService = new ServiceAreasService();
