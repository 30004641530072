import { IconButtonFlatProps } from './icon-button-flat.interfaces';

export const iconButtonFlatTypeMap: {
	[Type in NonNullable<IconButtonFlatProps['type']>]: string;
} = {
	primary: `
	 tw-text-theme-neutral-500-600
	 hover:tw-text-theme-primary-500-300
	 focus:tw-text-theme-primary-500-300
	 active:tw-text-primary-100 dark:active:tw-text-primary-700
	 disabled:tw-opacity-40 disabled:tw-pointer-events-none disabled:tw-cursor-not-allowed
	`,
	secondary: `
	 tw-text-theme-neutral-500-600
	 hover:tw-text-theme-neutral-600-500
	 focus:tw-text-theme-neutral-600-500
	 active:tw-text-neutral-400 dark:active:tw-text-neutral-700
	 disabled:tw-opacity-40 disabled:tw-pointer-events-none disabled:tw-cursor-not-allowed
	`,
	danger: `
	 tw-text-theme-neutral-500-600
	 hover:tw-text-theme-danger-500-300
	 focus:tw-text-theme-danger-500-300
	 active:tw-text-danger-100 dark:active:tw-text-danger-900
	 disabled:tw-opacity-40 disabled:tw-pointer-events-none disabled:tw-cursor-not-allowed
	`,
	'neutral-900-100': `
	 tw-text-theme-neutral-900-100
	 hover:tw-text-theme-primary-500-300
	 focus:tw-text-theme-primary-500-300
	 active:tw-text-primary-100 dark:active:tw-text-primary-700
	 disabled:tw-opacity-40 disabled:tw-pointer-events-none disabled:tw-cursor-not-allowed
	`,
	'neutral-600-500': `
	 tw-text-theme-neutral-600-500
	 hover:tw-text-neutral-700 dark:hover:tw-text-neutral-300
	 focus:tw-text-neutral-700 dark:focus:tw-text-neutral-300
	 active:tw-text-neutral-500 dark:active:tw-text-neutral-600
	 disabled:tw-opacity-40 disabled:tw-pointer-events-none disabled:tw-cursor-not-allowed
	`,
};
