import { Observable } from 'rxjs';

// services
import { Notification } from '@api-interfaces';
import { Params, TimeUtils } from '@core/helpers';
import { PaginationResponse, PaginationParams } from '@models';
import { virtualBrownClient } from '@services/django-client';

import { notificationsSummaryService } from './summary';

// helpers

// interfaces

const BASE_URL = `notifications`;

const defaultTo = TimeUtils.formatToISO(new Date());
const defaultFrom = TimeUtils.formatToISO(TimeUtils.subMonths(new Date(), 6));

class NotificationsService {
	readonly summary = notificationsSummaryService;

	public getNotifications = (
		params: {
			employee?: number;
			from: string;
			to: string;
			ordering?: string;
			filters?:
				| 'bell'
				| 'comms'
				| 'messages'
				| 'comments'
				| 'messages_with_one_recipient';
			exclude_type?: string;
			search?: string;
		} & PaginationParams = {
			to: defaultTo,
			from: defaultFrom,
			limit: 10,
			offset: 0,
		}
	): Observable<PaginationResponse<Notification>> => {
		const query = {
			...params,
			from: TimeUtils.formatToISO(params.from),
			to: TimeUtils.formatToISO(params.to),
		};
		const queryString = Params.makeQueryString(query);
		return virtualBrownClient.get(`${BASE_URL}/${queryString}`);
	};

	public updateAllNotifications = (
		params?: {
			employee: number;
			to: any;
		} & PaginationParams
	): Observable<any> => {
		const query = {
			...params,
			to: TimeUtils.formatToISO(params.to),
		};
		const queryString = Params.makeQueryString(query);
		return virtualBrownClient.put(`${BASE_URL}/read/${queryString}`);
	};

	public readOneNotification = (
		params: {
			employee: number;
			notification: number;
		} & PaginationParams
	): Observable<any> => {
		const query = {
			...params,
			to: TimeUtils.format(new Date(), 'YYYY-MM-DD'),
		};
		const queryString = Params.makeQueryString(query);
		return virtualBrownClient.put(`${BASE_URL}/read/${queryString}`);
	};

	public deleteNotificationByNotificationId = (
		notificationId: number
	): Observable<any> => {
		return virtualBrownClient.delete(`${BASE_URL}/${notificationId}/`);
	};

	public createNotificationSubscriptions(payload): Observable<any> {
		return virtualBrownClient.post(`${BASE_URL}/subscriptions/`, payload);
	}

	public updateNotificationSubscriptionsBulkUpdate(payload): Observable<any> {
		return virtualBrownClient.put(
			`${BASE_URL}/subscriptions/bulk-update/`,
			payload
		);
	}

	public updateNotificationSubscriptionById(id, payload): Observable<any> {
		return virtualBrownClient.put(
			`${BASE_URL}/subscriptions/${id}`,
			payload
		);
	}

	public employeeByIdNotificationsSubscription(id, payload): Observable<any> {
		return virtualBrownClient.post(
			`${BASE_URL}/employee/${id}/subscriptions/`,
			payload
		);
	}
}

export const notificationsService = new NotificationsService();
