import { Core } from './core';
import { PhotoViewerBoundingBoxes } from './core/photo-viewer-bounding-boxes';
import { Details } from './details';
import { AuditDetails } from './details/audit-details';
import { ToDoDetails } from './details/to-do-details';
import { VerificationDetails } from './details/verification-details';

export const PhotoViewer = {
    Core,
    BoundingBoxes: PhotoViewerBoundingBoxes,
    Details,
    ToDoDetails,
    AuditDetails,
    VerificationDetails,
};
