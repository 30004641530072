import { Person } from '@api-interfaces';

import { titleCase } from '.';

export function getFullName(
	p: Partial<Pick<Person, 'first_name' | 'last_name'>>
): string {
	let fullName = '';

	if (p && p.first_name) {
		fullName += p.first_name;

		if (p.last_name) {
			fullName += ` ${p.last_name}`;
		}
	}

	if (fullName) {
		// titlecase for hyphenated names
		if (fullName.includes('-')) {
			const splitName = fullName.split('-');
			return `${titleCase(splitName[0])}-${titleCase(splitName[1])}`;
		}
		return titleCase(fullName);
	}
	return 'Unassigned';
}
