// interfaces
import { UnusedTask } from '@api-interfaces';

// services
import { virtualBrownClient } from '@core/services/django-client';

// helpers
import { Params } from '@helpers';
import { PaginationParams } from '@models';

// constants
const BASE_URL = 'routes/unusedtasks';

class RoutesUnusedTaskService {
	public getUnusedTasks(
		params: {
			search?: string;
			building?: number;
			floor?: number;
			frequency?: number;
			position?: number;
			areaType?: number;
			ordering?:
				| 'building'
				| '-building'
				| 'area'
				| '-area'
				| 'task_bundle'
				| '-task_bundle';
		} & PaginationParams
	) {
		const queryString = Params.makeQueryString(params);
		return virtualBrownClient.get<UnusedTask[]>(`${BASE_URL}/${queryString}`);
	}
}

export const routesUnusedTaskService = new RoutesUnusedTaskService();
