// https://app.zeplin.io/project/606df660991137523a49bec0/styleguide/textstyles?seid=60369069bde0949bb588b718
// style configuration from design system
import { TextProps } from './text.interfaces';

export const textFontMap: {
	[Font in TextProps['font']]: {
		className: string;
		style: React.CSSProperties;
	};
} = {
	giant: {
		className: 'tw-font-lato tw-font-black tw-text-[72px]',
		style: {
			lineHeight: 1.08,
		},
	},
	'data-point': {
		className: 'tw-font-lato tw-font-black tw-text-5xl',
		style: {
			lineHeight: 1.08,
		},
	},
	hero: {
		className: 'tw-font-lato tw-font-black tw-text-5xl',
		style: {
			lineHeight: 1.17,
		},
	},
	h1: {
		className:
			'tw-font-lato tw-font-black tw-text-2xl sm:tw-text-[32px] sm:tw-leading-tight',
		style: {
			lineHeight: 1.17,
		},
	},
	h2: {
		className: 'tw-font-lato tw-font-black tw-text-xl',
		style: {
			lineHeight: 1.4,
		},
	},
	h3: {
		className:
			'tw-font-roboto tw-font-medium tw-text-base tw-leading-normal',
		style: {},
	},
	h4: {
		className: 'tw-font-roboto tw-font-medium tw-text-sm',
		style: {},
	},
	status: {
		className:
			'tw-font-roboto tw-text-base tw-font-black tw-leading-normal',
		style: {},
	},
	'body-sm': {
		className: 'tw-font-roboto tw-text-xs tw-leading-normal',
		style: {},
	},
	'body-md': {
		className: 'tw-font-roboto tw-text-sm',
		style: {
			lineHeight: 1.57,
		},
	},
	'body-lg': {
		className: 'tw-font-roboto tw-text-base tw-leading-normal',
		style: {},
	},
	'body-italic': {
		className: 'tw-font-roboto tw-text-sm tw-italic',
		style: {
			lineHeight: 1.43,
		},
	},
};

export const textColorMap: { [Color in TextProps['color']]: string } = {
	'current-color': 'tw-text-currentColor',
	danger: 'tw-text-theme-danger-500-300',
	'hi-contrast': 'tw-text-neutral-900-100',
	'hi-contrast-inverted': 'tw-text-neutral-100 dark:tw-text-neutral-900',
	'hi-contrast-dimmed': 'tw-text-neutral-900-300',
	neutral: 'tw-text-theme-neutral-500-600',
	'neutral-flat': 'tw-text-neutral-500',
	'neutral-offset': 'tw-text-theme-neutral-600-500',
	'neutral-offset-flat': 'tw-text-neutral-600',
	'neutral-offset-white': 'tw-text-neutral-600-100',
	primary: 'tw-text-theme-primary-500-300',
	success: 'tw-text-theme-success-700-300',
	warning: 'tw-text-warning-700 dark:tw-text-warning-300',
	white: 'tw-text-neutral-100',
	black: 'tw-text-neutral-900',
	'white-offset': 'tw-text-neutral-300',
	orange: 'tw-text-orange-500',
	'yellow-orange': 'tw-text-yelloworange-100',
	pinkle: 'tw-text-pinkle-700 dark:tw-text-pinkle-100',
	purple: 'tw-text-purple-700 dark:tw-text-purple-100',
	'dt-grey': 'tw-text-[#A7B1B9]',
	// TODO - r4 colors to be removed
	'gray-20': 'tw-text-gray-20',
	'gray-40': 'tw-text-gray-40',
	'dv-11': 'tw-text-dv-11',
};
