// https://insitenext.atlassian.net/wiki/spaces/DT/pages/2022146066/Charting+Colors
export const dataVizColors = [
	'var(--data-viz-1)',
	'var(--data-viz-2)',
	'var(--data-viz-3)',
	'var(--data-viz-4)',
	'var(--data-viz-5)',
	'var(--data-viz-6)',
	'var(--data-viz-7)',
	'var(--data-viz-8)',
	'var(--data-viz-9)',
	'var(--data-viz-10)',
	'var(--data-viz-11)',
	'var(--data-viz-12)',
];

export const tailwindBgDataVizColors = [
	'tw-bg-data-viz-1',
	'tw-bg-data-viz-2',
	'tw-bg-data-viz-3',
	'tw-bg-data-viz-4',
	'tw-bg-data-viz-5',
	'tw-bg-data-viz-6',
	'tw-bg-data-viz-7',
	'tw-bg-data-viz-8',
	'tw-bg-data-viz-9',
	'tw-bg-data-viz-10',
	'tw-bg-data-viz-11',
	'tw-bg-data-viz-12',
];

export const tailwindTextDataVizColors = [
	'tw-text-data-viz-1',
	'tw-text-data-viz-2',
	'tw-text-data-viz-3',
	'tw-text-data-viz-4',
	'tw-text-data-viz-5',
	'tw-text-data-viz-6',
	'tw-text-data-viz-7',
	'tw-text-data-viz-8',
	'tw-text-data-viz-9',
	'tw-text-data-viz-10',
	'tw-text-data-viz-11',
	'tw-text-data-viz-12',
];

export function getDataVizColorByIndex({
	index,
	withTailwind,
}: {
	index: number;
	withTailwind?: 'bg' | 'text';
}) {
	switch (withTailwind) {
		case 'bg':
			return tailwindBgDataVizColors[index % tailwindBgDataVizColors.length];
		case 'text':
			return tailwindTextDataVizColors[
				index % tailwindTextDataVizColors.length
			];
		default:
			return dataVizColors[index % dataVizColors.length];
	}
}

export const programListColors = {
	290: 'var(--data-viz-1)', // CUSTODIAL
	289: 'var(--data-viz-3)', // CLEANROOM
	291: 'var(--data-viz-5)', // FLEET MANAGEMENT
	292: 'var(--data-viz-7)', // GMP
	293: 'var(--data-viz-9)', // LAB SERVICES
	294: 'var(--data-viz-11)', // LANDSCAPING
	295: 'var(--data-viz-2)', // MAC
	296: 'var(--data-viz-4)', // MAINTENANCE
	297: 'var(--data-viz-6)', // MRO
	298: 'var(--data-viz-8)', // Pest Control
	299: 'var(--data-viz-10)', // Recycle/Diversion
	300: 'var(--data-viz-12)', // SECURITY
	301: 'var(--success-700)', // shipping and receiving
	302: 'var(--blue-70)', // shredding/ document disposal
	303: 'var(--purple-100)', // uniform service
	304: 'var(--red)', // waste management
	280: 'rgb(167, 167, 178)', // above base
};

export const setProgramColor = (program: {
	id: number;
	materialized_path?: number[];
}) => {
	let color = programListColors[program.id] || 'var(--gray-40)';
	if (program.materialized_path) {
		for (const id of program.materialized_path) {
			if (programListColors[id]) {
				color = programListColors[id];
			}
			break;
		}
	}

	return color;
};

export function getIsPassColor({
	isPassing,
	type,
}: {
	isPassing: null | boolean;
	type: 'bg' | 'text' | 'border';
}) {
	switch (type) {
		case 'bg':
			return isPassing == null
				? 'tw-bg-status-empty'
				: isPassing
				? 'tw-bg-status-pass'
				: 'tw-bg-status-fail';
		case 'border':
			return isPassing == null
				? 'tw-border-status-empty'
				: isPassing
				? 'tw-border-status-pass'
				: 'tw-border-status-fail';
		case 'text':
			return isPassing == null
				? 'tw-text-status-empty'
				: isPassing
				? 'tw-text-status-pass'
				: 'tw-text-status-fail';
	}
}

export const badgeColors = {
	pinkle: 'tw-bg-pinkle-700 tw-bg-opacity-15 tw-text-pinkle-700',
	'sky-blue': 'tw-bg-skyblue-700 tw-bg-opacity-15 tw-text-skyblue-700',
	purple: 'tw-bg-purple-700 tw-bg-opacity-15 tw-text-purple-700',
	'yellow-orange':
		'tw-bg-yelloworange-500 tw-bg-opacity-15 tw-text-yelloworange-500',
};
