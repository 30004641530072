// interfaces
import { Observable } from 'rxjs';

import {
	Address,
	AreaServicer,
	AreaTypeArea,
	AreaValidationBuilding,
	AreaValidationHeader,
	FloorAreaType,
	GetVerificationsByEmployeeParams,
	ServiceLogItem,
	ServiceScheduleArea,
	V2Area,
	VerificationsByEmployee,
} from '@api-interfaces';
import { AdminAreaProfiles } from '@app/admin/pages/areas/area-profiles/_context/interfaces';

// services
import { virtualBrownClient } from '@core/services/django-client';

// helpers
import { Params } from '@helpers';
import { CommonParams, PaginationParams, PaginationResponse } from '@models';

// constants
const BASE_URL = 'v2/areas';

const noOpts = {
	noContract: true,
	noCustomer: true,
	noTag: true,
};

class V2AreasService {
	public getV2Areas(
		params: {
			parent?: number;
			boundary?: number;
			id?: number;
			lat?: string;
			lon?: string;
			sort?: string;
			all_levels?: boolean;
			only_rooms?: boolean;
		} & CommonParams
	): Observable<PaginationResponse<V2Area>> {
		return virtualBrownClient.get(
			`${BASE_URL}/${Params.makeQueryString({ ...params })}`,
			noOpts
		);
	}

	public postV2Areas(
		payload: {
			name: string;
			type?: {
				id: number;
			};
			parent?: {
				id: number;
			};
			address?: Address;
		} & CommonParams
	): Observable<PaginationResponse<V2Area>> {
		return virtualBrownClient.post(`${BASE_URL}/`, payload);
	}

	public putAreas(
		payload: {},
		id: number
	): Observable<PaginationResponse<V2Area>> {
		return virtualBrownClient.put(`${BASE_URL}/${id}`, payload);
	}

	public putBulkEditAreas(
		payload: AdminAreaProfiles.EditedData[]
	): Observable<PaginationResponse<V2Area>> {
		return virtualBrownClient.put(
			`${BASE_URL}/bulk_action/?action=update_area`,
			payload
		);
	}

	public getServicers(
		params: {
			area_id?: number;
			area_type_id?: number;
			root_id?: number;
			customer?: string | number;
			contract?: string | number;
		} & CommonParams
	): Observable<PaginationResponse<AreaServicer>> {
		return virtualBrownClient.get(
			`${BASE_URL}/servicers/${Params.makeQueryString({ ...params })}`,
			{
				noCustomer: params.customer != undefined,
				noContract: params.contract != undefined,
			}
		);
	}

	public getServiceLog(
		params: {
			all_levels?: boolean;
			root_id?: number;
			area_type_id?: number;
			area_id?: number;
			task_bundle_id?: number;
			route_id?: number;
			customer?: string | number;
			contract?: string | number;
		} & CommonParams
	): Observable<PaginationResponse<ServiceLogItem>> {
		return virtualBrownClient.get(
			`${BASE_URL}/service_log/${Params.makeQueryString({ ...params })}`,
			{
				noCustomer: params.customer != undefined,
				noContract: params.contract != undefined,
			}
		);
	}

	public getServiceSchedule(
		params: {
			area_ids: string; // comma separated list of area ids
		} & CommonParams
	): Observable<PaginationResponse<ServiceScheduleArea>> {
		return virtualBrownClient.get(
			`${BASE_URL}/service_schedule/${Params.makeQueryString({
				...params,
			})}`
		);
	}

	public getValidatedTasks = (
		params: {
			contract?: number | string;
			customer?: number | string;
			from?: string | Date;
			to?: string | Date;
			time_zone?: string;
			level?: 'root_area' | 'area_type' | 'area_type_root' | 'area';
			shifts?: string;
		} & PaginationParams
	): Observable<PaginationResponse<AreaValidationBuilding>> => {
		const queryString = Params.makeQueryString(params);
		return virtualBrownClient.get(
			`${BASE_URL}/validated_tasks/${queryString}`,
			{
				noContract: true,
				noCustomer: true,
				noTag: true,
			}
		);
	};

	public getValidatedTasksPerType = (
		type: 'building' | 'areatype',
		params: {
			building_id?: number | string;
			contract?: number | string;
			customer?: number | string;
			from?: string | Date;
			to?: string | Date;
			time_zone?: string;
			level?: 'root_area' | 'area_type' | 'area_type_root' | 'area';
			shifts?: string;
		} & PaginationParams
	): Observable<any> => {
		const queryString = Params.makeQueryString(params);
		return virtualBrownClient.get(
			`${BASE_URL}/validated_tasks/${type}/${queryString}`,
			{
				noContract: true,
				noCustomer: true,
				noTag: true,
			}
		);
	};

	public getValidationHeader = (params: {
		id: number;
		level:
			| 'root_area'
			| 'area_type'
			| 'area_type_root'
			| 'area'
			| 'top_level'
			| 'front_page';
		root_area?: number;
		from?: string | Date;
		to?: string | Date;
		time_zone?: string;
		contract?: string | number;
		shifts?: string;
	}): Observable<AreaValidationHeader> => {
		const queryString = Params.makeQueryString(params);
		return virtualBrownClient.get(
			`${BASE_URL}/validation_header/${queryString}`,
			{
				noContract: true,
				noCustomer: true,
				noTag: true,
				noProvider: true,
			}
		);
	};

	public getVerificationsByEmployee = (
		params: GetVerificationsByEmployeeParams & PaginationParams
	): Observable<PaginationResponse<VerificationsByEmployee>> => {
		const queryString = Params.makeQueryString(params);
		return virtualBrownClient.get(
			`${BASE_URL}/verifications_by_employee/${queryString}`
		);
	};

	public exportServiceValidationAreasToExcel = (params: {
		contract?: number | string;
		from?: String | Date;
		to?: String | Date;
		serviced?: Boolean;
	}): Observable<string> => {
		const queryString = Params.makeQueryString(params);
		return virtualBrownClient.get(
			`${BASE_URL}/excel_export/${queryString}`
		);
	};

	public getAreasAreaElements = (area_id: number): Observable<V2Area[]> => {
		return virtualBrownClient.get(`${BASE_URL}/${area_id}`);
	};

	public updateAreasAreaElements = (
		area_id: number,
		payload: [{ id: number }]
	) => {
		return virtualBrownClient.patch(`${BASE_URL}/${area_id}/`, payload);
	};

	public getFloorAreaTypes(
		params?: CommonParams & {
			floor: number;
			building: number;
			service?: number;
			shift?: any[];
		}
	) {
		const queryString = params ? Params.makeQueryString(params) : '';
		return virtualBrownClient.get<FloorAreaType[]>(
			`${BASE_URL}/areatype_area/${queryString}`
		);
	}

	public getAreaTypeAreas(
		params?: CommonParams & {
			floor: number;
			building: number;
			area_type: number;
			service?: number;
			shift?: any[];
		}
	) {
		const queryString = params ? Params.makeQueryString(params) : '';
		return virtualBrownClient.get<AreaTypeArea[]>(
			`${BASE_URL}/areatype_area/${queryString}`
		);
	}
}

export const v2AreasService = new V2AreasService();
