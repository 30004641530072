import { SummaryChart, SummaryParams } from '@models';

export type BillingDrilldownOrdering =
	| 'program'
	| '-program'
	| 'site'
	| '-site'
	| 'client'
	| '-client'
	| 'billing'
	| '-billing'
	| 'client_group'
	| '-client_group';

type BillingDrilldownClientType = {
	id: number;
	name: string;
	total_amount: number;
	percent_of_total?: number;
	owner_poc?: {
		id: number;
		first_name?: string;
		last_name?: string;
		photo_url?: string;
	};
	owner_poc_full_name?: string;
	customer_poc?: {
		id: number;
		first_name?: string;
		last_name?: string;
		photo_url?: string;
	};
	customer_poc_full_name?: string;
};

export interface FinancialsInvoicesSummaryParams<T> extends SummaryParams<T> {
	service?: number;
	parent_service?: number;
	customer?: number;
	mode?: 'PROGRAM' | 'SITE' | 'CLIENT' | 'VERTICAL';
	ordering?: BillingDrilldownOrdering;
	search?: string;
	vertical?: string;
}

export namespace FinancialsInvoicesSummary {
	export enum Chart {
		GetInvoicesBillingBySiteMonthly = 10,
		GetInvoicesMonthlyTrends = 11,
		GetInvoicesBySite = 12,
		GetInvoicesBillingByProgram = 13,
		GetInvoicesMonthlyTrendsByProgram = 14,
		GetInvoicesMonthlyPaidVsUnpaid = 15,
		GetBillingAging = 16,
		GetBillingCounts = 17,
		GetBillingCountsByOrganization = 18,
		GetInvoicesByCustomerByProgram = 19,
		GetInvoicesBySiteByProgram = 33,
		GetInvoicesBillingCollapsibleTable = 39,
	}

	export interface InvoicesBillingsBySiteMonthly {
		id: number;
		month: string;
		name: string;
		paid_amount: number;
		total_above_base_amount: number;
		total_amount: number;
		total_base_amount: number;
		unpaid_amount: number;
	}

	export interface InvoicesBillingsBySiteMonthlySummary
		extends SummaryChart<InvoicesBillingsBySiteMonthly[]> {
		chart: 'GetInvoicesBillingBySiteMonthly';
		number: '10';
	}

	export interface InvoicesMonthlyTrends {
		month: string;
		total_above_base: number;
		total_amount: number;
		total_base: number;
	}

	export interface InvoicesMonthlyTrendsSummary
		extends SummaryChart<InvoicesMonthlyTrends[]> {
		chart: 'GetInvoicesMonthlyTrends';
		number: '11';
	}

	export interface InvoicesBySite {
		id: number;
		name: string;
		organization_name: string;
		street1: string;
		street2: string;
		city: string;
		region: string;
		postal_code: string;
		square_feet: number;
		lat: number;
		lon: number;
		paid_amount: number;
		unpaid_amount: number;
		total_amount: number;
		is_pass: boolean;
		amount: number;
	}

	export interface InvoicesBySiteSummary
		extends SummaryChart<InvoicesBySite[]> {
		chart: 'GetInvoicesBySite';
		max_total_amount: number;
	}

	export interface InvoicesBillingByProgram {
		amount: number;
		paid_amount: number;
		program_id: number;
		programname: string;
		unpaid_amount: number;
		client_name?: string;
		percent_of_total?: number;
	}

	export interface InvoicesBillingByProgramSummary
		extends SummaryChart<InvoicesBillingByProgram[]> {
		chart: 'GetInvoicesBillingByProgram';
		number: '13';
	}

	export interface InvoicesMonthlyTrendsByProgram {
		month: string;
		total_amounts: Array<{
			service_id: number;
			service_name: string;
			total_above_base: number;
			total_amount: number;
			total_base: number;
		}>;
	}

	export interface InvoicesMonthlyTrendsByProgramSummary
		extends SummaryChart<InvoicesMonthlyTrendsByProgram[]> {
		chart: 'GetInvoicesMonthlyTrendsByProgram';
		number: '14';
	}

	export interface InvoicesMonthlyPaidVsUnpaid {
		month_of_service_date: string;
		total_paid_amount: number;
		total_unpaid_amount: number;
	}

	export interface InvoicesMonthlyPaidVsUnpaidSummary
		extends SummaryChart<InvoicesMonthlyPaidVsUnpaid[]> {
		chart: 'GetInvoicesMonthlyPaidVsUnpaid';
		number: '15';
	}

	export interface BillingAging {
		month: string;
		total_current_due: number;
		total_past_due: number;
	}

	export interface BillingAgingSummary extends SummaryChart<BillingAging[]> {
		chart: 'GetBillingAging';
		number: '16';
	}

	export interface BillingCounts {
		total_amount: number;
	}

	export interface BillingCountsSummary extends SummaryChart<BillingCounts> {
		chart: 'GetBillingCounts';
		number: '17';
	}

	export interface BillingCountsByOrganization {
		customer_id: number;
		customer_name: string;
		total_amount: number;
	}

	export interface BillingCountsByOrganizationSummary
		extends SummaryChart<BillingCountsByOrganization[]> {
		chart: 'GetBillingCountsByOrganization';
		number: '18';
	}

	export interface InvoicesByCustomerByProgram {
		amount: number;
		client_id: number;
		client_name: string;
		paid_amount: number;
		service_id: number;
		service_name: string;
		unpaid_amount: string;
		contract_name?: string;
		programname?: string;
	}

	export interface InvoicesByCustomerByProgramSummary
		extends SummaryChart<InvoicesByCustomerByProgram[]> {
		chart: 'GetInvoicesByCustomerByProgram';
		most_recent_record: null | string;
		number: '19';
	}

	export interface InvoicesBySiteByProgram {
		amount: number;
		contract_name: string;
		program_contract_id: number;
		service_id: number;
		service_name: string;
	}

	export interface InvoicesBySiteByProgramSummary
		extends SummaryChart<InvoicesBySiteByProgram[]> {
		chart: 'GetInvoicesBySiteByProgram';
		number: '33';
	}

	export interface InvoicesBillingDrilldownTable {
		program_id?: number;
		program_name?: string;
		program_count?: number;
		vertical_id?: number;
		vertical_name?: string;
		site_id?: number;
		site_name?: string;
		sites_count?: number;
		client_id?: number;
		client_name?: string;
		client_count?: number;
		logo_url?: string;
		total_amount: number;
		percent_of_total?: number;
		owner_poc?: {
			id: number;
			first_name?: string;
			last_name?: string;
			photo_url?: string;
		};
		owner_poc_full_name?: string;
		customer_poc?: {
			id: number;
			first_name?: string;
			last_name?: string;
			photo_url?: string;
		};
		customer_poc_full_name?: string;
		programs?: {
			id: number;
			name: string;
			total_amount: number;
			percent_of_total?: number;
		}[];
		clients?: BillingDrilldownClientType[];
		sites?: {
			id: number;
			name: string;
			total_amount: number;
			site_start_date: string;
			percent_of_total?: number;
		}[];
		clientGroups?: {
			clients: BillingDrilldownClientType[];
			customer_count?: number;
			total_amount: number;
			vertical_id?: number;
			vertical_name?: string;
		}[];
	}

	export interface InvoicesBillingDrilldownTableSummary
		extends SummaryChart<InvoicesBillingDrilldownTable[]> {
		chart: 'GetInvoicesBillingCollapsibleTable';
		number: '39';
		most_recent_record?: null | string;
	}

	export type SummaryReturnType = {
		[Chart.GetInvoicesBillingBySiteMonthly]: InvoicesBillingsBySiteMonthlySummary;
		[Chart.GetInvoicesMonthlyTrends]: InvoicesMonthlyTrendsSummary;
		[Chart.GetInvoicesBySite]: InvoicesBySiteSummary;
		[Chart.GetInvoicesBillingByProgram]: InvoicesBillingByProgramSummary;
		[Chart.GetInvoicesMonthlyTrendsByProgram]: InvoicesMonthlyTrendsByProgramSummary;
		[Chart.GetInvoicesMonthlyPaidVsUnpaid]: InvoicesMonthlyPaidVsUnpaidSummary;
		[Chart.GetBillingAging]: BillingAgingSummary;
		[Chart.GetBillingCounts]: BillingCountsSummary;
		[Chart.GetBillingCountsByOrganization]: BillingCountsByOrganizationSummary;
		[Chart.GetInvoicesByCustomerByProgram]: InvoicesByCustomerByProgramSummary;
		[Chart.GetInvoicesBySiteByProgram]: InvoicesBySiteByProgramSummary;
		[Chart.GetInvoicesBillingCollapsibleTable]: InvoicesBillingDrilldownTableSummary;
	};
}
