import { uniqBy } from 'lodash';
import { useEffect, useRef, useState } from 'react';

// interfaces
import { forkJoin, Subscription } from 'rxjs';

import {
  InspectionArea,
  InspectionComment,
  Service,
  Todo,
} from '@api-interfaces';

// services
import { inspectionsService } from '@apis';

// helpers
import { sendError } from '@helpers';

// components
import { Modal } from '@templates';

import { Comments, Employees, Programs, ToDos } from './_components';

interface DetailsTabProps {
  currentArea: InspectionArea;
}

export const DetailsTab = ({ currentArea }: DetailsTabProps) => {
  const [todos, setTodos] = useState<Todo[]>([]);
  const [comments, setComments] = useState<InspectionComment[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const subscriptions = useRef<{ [key: string]: Subscription }>({});
  const services: Service[] = uniqBy(
    currentArea?.task_inspections.map(
      (task_inspection) => task_inspection.service
    ) ?? [],
    'id'
  ).filter((s) => s);

  useEffect(() => {
    subscriptions.current['audit-area-modal-details-init'] = forkJoin([
      inspectionsService.inspectionAreas.getTodos({
        inspectionId: currentArea.inspection_id,
        inspectionareaId: currentArea.id,
      }),
      inspectionsService.inspectionAreas.getComments({
        inspectionId: currentArea.inspection_id,
        inspectionareaId: currentArea.id,
      }),
    ]).subscribe({
      next: ([todos, comments]) => {
        setTodos(todos.results);
        setComments(comments.results);
        setIsLoading(false);
      },
      error: sendError({
        toastMessage: 'There was an error retrieving Audit Area Details data.',
        callback: () => {
          setIsLoading(false);
        },
      }),
    });

    return () =>
      Object.values(subscriptions.current).forEach((subscription) =>
        subscription.unsubscribe()
      );
  }, [currentArea?.id]);

  return (
    !isLoading && (
      <div className="tw-mb-6">
        <Comments comments={comments} />

        <Modal.Information.Divider
          className="tw-my-4"
          orientation="horizontal"
        />

        <Programs services={services} />

        <Modal.Information.Divider
          className="tw-my-4"
          orientation="horizontal"
        />

        <Employees employees={currentArea.service_employees} />

        <Modal.Information.Divider
          className="tw-my-4"
          orientation="horizontal"
        />

        <ToDos todos={todos} />
      </div>
    )
  );
};
