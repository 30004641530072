export interface BusinessUnit {
	BU: string;
	'NS ID': string;
}

export interface BusinessUnitLocations {
	Locations: { Location: BusinessUnit }[];
}

export interface BusinessUnitPurchaseOrder {
	customer_po_description: string;
	customer_po_id: string;
	customer_po_number: string;
	funds_remaining: number;
	location_id: string;
	total_funds: number;
}

export interface BusinessUnitPurchaseOrders {
	success: 'true';
	customer_po: BusinessUnitPurchaseOrder[];
}

export const CUSTOMER_WORK_ORDER = 'CUSTOMER WORK ORDER';
