import React, { forwardRef } from 'react';
// components
import { Icon, Text } from '@atoms';
// interfaces
import { BadgeProps } from './badge.interfaces';
// utils
import { BADGE_COLORS } from './badge.utils';
// helpers
import { highlightText } from '@helpers';
/**
 * @description React component that shows an icon and label on translucent background.
 * Use tailwind bg and text colors class names to override default color
 */
export const Badge = forwardRef<HTMLDivElement, BadgeProps>(
    (
        {
            label,
            icon,
            iconPosition,
            iconClick,
            color,
            className,
            highlight,
            labelClassName,
            textSize,
            ...props
        },
        ref
    ) => {
        return (
            <div
                ref={ref}
                className={
                    textSize !== 'body-sm'
                        ? `tw-inline-flex tw-items-center tw-py-1.5 tw-px-3 tw-space-x-2 tw-rounded tw-bg-opacity-15 dark:tw-bg-opacity-15 tw-h-7 ${
                              iconPosition === 'left'
                                  ? ''
                                  : 'tw-flex-row-reverse tw-space-x-reverse'
                          }
      ${props.onClick ? 'tw-cursor-pointer' : 'tw-cursor-default'}
      ${BADGE_COLORS[color].text} ${BADGE_COLORS[color].background}
      ${className}`
                        : `tw-inline-flex tw-items-center tw-px-2 tw-space-x-2 tw-rounded tw-bg-opacity-15 dark:tw-bg-opacity-15 tw-h-5 ${
                              iconPosition === 'left'
                                  ? ''
                                  : 'tw-flex-row-reverse tw-space-x-reverse'
                          }
        ${props.onClick ? 'tw-cursor-pointer' : 'tw-cursor-default'}
        ${BADGE_COLORS[color].text} ${BADGE_COLORS[color].background}
        ${className}`
                }
                {...props}
            >
                {icon && (
                    <Icon
                        icon={icon}
                        className={iconClick ? 'tw-cursor-pointer' : ''}
                        onClick={iconClick}
                    />
                )}
                {label && (
                    <Text
                        font={textSize}
                        color="current-color"
                        className={`tw-whitespace-nowrap ${
                            labelClassName ?? ''
                        }`}
                    >
                        {highlight
                            ? highlightText({ text: label, match: highlight })
                            : label}
                    </Text>
                )}
            </div>
        );
    }
);
Badge.defaultProps = {
    iconPosition: 'left',
    color: 'primary',
    textSize: 'h4',
    className: '',
};
