import * as React from 'react';

// components
import { Comment } from '@api-interfaces';
import { Icon, Text } from '@atoms';

// hooks
import { useAppContext } from '@hooks';

// interfaces

interface IProps {
  comment: Comment;
  isOrigBody: boolean;
  setIsOrigBody: React.Dispatch<React.SetStateAction<boolean>>;
}

export const TranslationBox = ({
  comment,
  isOrigBody,
  setIsOrigBody,
}: IProps) => {
  const {
    state: { user },
  } = useAppContext();

  return (
    <div
      className="comment-thread-translation-box tw-bg-neutral-400-900"
      style={{ maxWidth: `${isOrigBody ? '491px' : '479px'}` }}
    >
      <div className="comment-thread-translation-left">
        <Icon icon="language" className="tw-text-theme-primary-500-300" />
      </div>
      <div className="comment-thread-translation-right">
        <Text font="body-sm" color="neutral-offset-white">
          This comment has been translated from {comment?.orig_language} to{' '}
          {user?.language?.display_name}.
        </Text>
        <Text
          tag="span"
          onClick={() => setIsOrigBody(!isOrigBody)}
          font="body-sm"
          color="primary"
          className="tw-cursor-pointer tw-underline tw-capitalize tw-font-normal"
        >
          {isOrigBody ? 'View Translation' : 'View Original'}
        </Text>
      </div>
    </div>
  );
};
