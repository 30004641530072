import { QuestionGroup, AnswerTypeType, SurveyStatus } from '@api-interfaces';
import { SummaryChart, SummaryParams } from '@models';

export interface GetSurveySummaryParams<T> extends SummaryParams<T> {}

export namespace SurveysSummary {
	export enum Chart {
		GetSurveyMonthlyAverageScore = 0,
		GetSurveyAverageScore = 1,
		GetSurveyAverages = 3,
	}

	export interface SurveyMonthlyAverageScore {
		month: string;
		average: null | number;
	}
	export interface SurveyMonthlyAverageScoreSummary
		extends SummaryChart<SurveyMonthlyAverageScore[]> {
		chart: 'GetSurveyMonthlyAverageScore';
		number: '0';
	}

	export interface SurveyAverageScore {
		average_score: null | number;
	}

	export interface SurveyAverageScoreSummary
		extends SummaryChart<SurveyAverageScore> {
		chart: 'GetAverageSurveyScore';
		number: '1';
	}

	export interface SurveyAverages {
		average_score: number;
		contract_averages: {
			average_score: number;
			id: number;
			name: string;
			customer_id: number;
			customer_name: string;
		}[];
		customer_averages: {
			average_score: number;
			id: number;
			name: string;
		}[];
		number_of_recipients: number;
		number_of_responses: number;
		survey_averages: {
			average_score: number;
			id: number;
			name: string;
		}[];
		more_contracts:
			| null
			| {
					contract_id: number;
			  }[];
	}

	export interface SurveyAveragesSummary extends SummaryChart<SurveyAverages> {
		chart: 'GetSurveyAverages';
		number: '3';
	}

	export interface SummaryReturnType {
		[Chart.GetSurveyMonthlyAverageScore]: SurveyMonthlyAverageScoreSummary;
		[Chart.GetSurveyAverageScore]: SurveyAverageScoreSummary;
		[Chart.GetSurveyAverages]: SurveyAveragesSummary;
	}
}

export namespace SummaryBySurvey {
	export enum Chart {
		GetSurveyFloatingSummary = 2,
		GetSurveyQuestionResponse = 4,
	}

	export interface SurveyFloating {
		id: number;
		status: SurveyStatus;
		start_date: null | string;
		end_date: null | string;
		average_score: null | number;
		number_of_recipients: null | number;
		number_of_responses: null | number;
		number_of_comments: null | number;
		contracts: {
			contract_id: number;
			name: string;
			customer_name: string;
		}[];
		more_contracts: null | { contract_id: number }[];
	}

	export interface SurveyFloatingSummary extends SummaryChart<SurveyFloating> {
		chart: 'GetSurveyFloatingSummary';
		number: '2';
	}

	export interface SurveyQuestionResponse {
		question_summaries:
			| null
			| {
					id: number;
					body: string;
					name: string;
					average_score: number;
					question_group: QuestionGroup;
					answer_type_type: AnswerTypeType;
					number_of_responses: number;
			  }[];
	}

	export interface SurveyQuestionResponseSummary
		extends SummaryChart<SurveyQuestionResponse> {
		chart: 'GetSurveyQuestionResponseSummary';
		number: '4';
	}

	export interface SummaryReturnType {
		[Chart.GetSurveyFloatingSummary]: SurveyFloatingSummary;
		[Chart.GetSurveyQuestionResponse]: SurveyQuestionResponseSummary;
	}
}

export interface GetSummaryBySurveyParams<T> extends SummaryParams<T> {}

export namespace SurveyResponseQuestionsSummary {
	export enum Chart {
		GetSurveyQuestionAnswerSummary = 5,
	}

	export interface SurveyQuestionAnswer {
		id: number;
		name: string;
		question_group: QuestionGroup;
		body: string;
		answer_type_type: AnswerTypeType;
		average_score: number;
		contract_summaries:
			| null
			| {
					contract_id: number;
					customer_id: number;
					average_score: number;
					contract_name: string;
					customer_name: string;
					number_of_responses: number;
					number_of_recipients: number;
			  }[];
	}

	export interface SurveyQuestionAnswerSummary
		extends SummaryChart<SurveyQuestionAnswer> {
		chart: 'GetSurveyQuestionAnswerSummary';
		number: '5';
	}
	export interface SummaryReturnType {
		[Chart.GetSurveyQuestionAnswerSummary]: SurveyQuestionAnswerSummary;
	}
}

export interface GetSurveyResponseQuestionsParams<T> extends SummaryParams<T> {
	surveyId: number;
	questionId: number;
}
