import { useEffect, useRef, useState } from 'react';
import * as React from 'react';

// hooks
import { InspectionArea } from '@api-interfaces';
import { useEventListener } from '@app/_new/pages/service-tracker/_components/Gantt/useEventHandler';
import { Badge, Icon, ModalStack, Text } from '@atoms';
import { TimeUtils } from '@helpers';
import { useBreakpoints } from '@hooks';

// helpers

// services
import { Mode } from '@modals/_ds2/audit/_context/interfaces';
import { ImageBlock } from '@new';
import { imageService } from '@services';

// interfaces

// components
export type PhotoViewerMedia = {
    create_date: string;
    file_name: string;
    id: number | string;
    node?: React.ReactNode;
};

interface PhotoViewerCoreProps {
    media: PhotoViewerMedia[];
    onClose: () => void;
    initialIndex?: number;
    details?:
        | React.ReactNode
        | ((media: PhotoViewerMedia, index: number) => React.ReactNode);
    getGroup?: (
        media: PhotoViewerMedia,
        index: number
    ) => {
        id: string;
        label: React.ReactNode;
        mode?: Mode;
        area?: InspectionArea;
    };
    // boundingBoxes?: BoundingBoxData[];
}

export const Core = ({
    media,
    onClose,
    initialIndex,
    details,
    getGroup,
}: PhotoViewerCoreProps) => {
    const bp = useBreakpoints();
    const [photoIndex, setPhotoIndex] = useState(initialIndex ?? 0);
    const [isFullScreen, setIsFullScreen] = useState(!bp.sm);
    const carouselRef = useRef<HTMLDivElement>(null);
    const carouselItemRefs = useRef<(HTMLDivElement | null)[]>([]);
    const [mediaGroups, setMediaGroups] = useState<
        {
            id?: string;
            label?: React.ReactNode;
            media: (PhotoViewerMedia & { index: number })[];
        }[]
    >([]);

    const [scaleX, setScaleX] = useState(1);
    const [scaleY, setScaleY] = useState(1);
    const imgRef = useRef<HTMLImageElement>(null);

    const handleClose = () => {
        onClose();
    };

    const scrollToIndex = (index) => {
        const carouselItem = carouselItemRefs.current?.[index];
        if (carouselItem) {
            // keep item in the center of the carouselRef
            carouselItem.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
                inline: 'center',
            });
        }
    };

    const goPrev = () => {
        const currentGroupIndex = mediaGroups.findIndex((group) =>
            group.media.some((m) => m.index === photoIndex)
        );
        const currentGroup = mediaGroups[currentGroupIndex];
        const currentGroupMediaIndex = currentGroup.media.findIndex(
            (m) => m.index === photoIndex
        );
        if (currentGroupMediaIndex === 0) {
            if (currentGroupIndex > 0) {
                const prevGroup = mediaGroups[currentGroupIndex - 1];
                setPhotoIndex(
                    prevGroup.media[prevGroup.media.length - 1].index
                );
            } else {
                setPhotoIndex(
                    mediaGroups[mediaGroups.length - 1].media[
                        mediaGroups[mediaGroups.length - 1].media.length - 1
                    ].index
                );
            }
        } else {
            setPhotoIndex(currentGroup.media[currentGroupMediaIndex - 1].index);
        }
    };
    const goNext = () => {
        const currentGroupIndex = mediaGroups.findIndex((group) =>
            group.media.some((m) => m.index === photoIndex)
        );
        const currentGroup = mediaGroups[currentGroupIndex];
        const currentGroupMediaIndex = currentGroup.media.findIndex(
            (m) => m.index === photoIndex
        );
        if (currentGroupMediaIndex === currentGroup.media.length - 1) {
            if (currentGroupIndex < mediaGroups.length - 1) {
                const nextGroup = mediaGroups[currentGroupIndex + 1];
                setPhotoIndex(nextGroup.media[0].index);
            } else {
                setPhotoIndex(mediaGroups[0].media[0].index);
            }
        } else {
            setPhotoIndex(currentGroup.media[currentGroupMediaIndex + 1].index);
        }
    };

    const currentImage = media[photoIndex]
        ? imageService.getImageByUniqueId(media[photoIndex].file_name, false)
        : undefined;

    useEffect(() => {
        setIsFullScreen(!bp.sm);
    }, [bp]);

    useEffect(() => {
        setTimeout(() => {
            scrollToIndex(photoIndex);
        }, 100);
    }, [photoIndex]);

    useEffect(() => {
        if (getGroup) {
            const groups = media.reduce(
                (acc, m, i) => {
                    const { area, ...group } = getGroup(m, i);
                    const groupIndex = acc.findIndex((g) => g.id === group.id);
                    const media = { ...m, index: i };
                    if (groupIndex > -1) {
                        acc[groupIndex].media.push(media);
                    } else {
                        acc.push({ ...group, media: [media] });
                    }
                    return acc;
                },
                [] as typeof mediaGroups
            );
            setMediaGroups(groups);
        } else {
            setMediaGroups([
                {
                    id: undefined,
                    label: undefined,
                    media: media.map((m, index) => ({ ...m, index })),
                },
            ]);
        }
    }, [media, getGroup]);

    const handleKeyDown = (e: KeyboardEvent) => {
        if (e.key === 'ArrowLeft') {
            e.preventDefault();
            goPrev();
        } else if (e.key === 'ArrowRight') {
            e.preventDefault();
            goNext();
        } else if (e.key === 'Escape') {
            handleClose();
        }
    };

    useEventListener('keydown', handleKeyDown, window);

    const handleResize = () => {
        if (imgRef.current) {
            const { naturalWidth, naturalHeight, offsetWidth, offsetHeight } =
                imgRef.current;
            setScaleX(offsetWidth / naturalWidth);
            setScaleY(offsetHeight / naturalHeight);
        }
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <ModalStack position="cover" onOverlayClick={handleClose}>
            <div className="tw-flex lg:tw-flex-row tw-flex-col tw-justify-between tw-h-full lg:tw-overflow-hidden">
                {/* Left side */}
                <div className="tw-relative tw-flex-1" onClick={handleClose}>
                    <div
                        className={`tw-relative ${
                            isFullScreen ? 'tw-h-full' : 'lg:tw-h-full'
                        }`}
                    >
                        {currentImage && (
                            <>
                                <div
                                    className="tw-absolute tw-top-0 tw-left-0 tw-right-0 tw-bottom-0 tw-bg-cover tw-bg-center tw-blur-lg"
                                    style={{
                                        backgroundImage: `url(${currentImage})`,
                                    }}
                                />
                                <div className="tw-absolute tw-top-0 tw-left-0 tw-right-0 tw-bottom-0 tw-bg-black tw-opacity-50" />
                            </>
                        )}
                        <button
                            onClick={handleClose}
                            className="tw-absolute tw-top-4 tw-right-4 tw-text-neutral-100 hover:tw-text-primary-500 tw-cursor-pointer tw-z-50"
                        >
                            <Icon icon="close" className="tw-text-2xl" />
                        </button>
                        <div
                            className={`tw-flex tw-flex-col tw-h-full sm:tw-p-4 tw-gap-6 tw-pb-4 ${
                                isFullScreen ? '' : 'tw-pt-10'
                            }`}
                        >
                            <div className="tw-flex tw-items-center tw-justify-center tw-w-full tw-flex-1 tw-relative">
                                {media.length > 1 && (
                                    <>
                                        <button
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                goPrev();
                                            }}
                                            className="tw-absolute tw-top-1/2 tw-transform tw--translate-y-1/2 tw-z-50 tw-w-14 tw-h-14 tw-flex tw-justify-center tw-items-center tw-left-0  tw-text-neutral-100 hover:tw-text-primary-500 tw-cursor-pointer"
                                        >
                                            <div className="tw-w-10 tw-h-10 tw-bg-neutral-900 tw-bg-opacity-50 tw-rounded-full tw-flex tw-justify-center tw-items-center">
                                                <Icon
                                                    icon="chevron-left"
                                                    className="tw-text-xl"
                                                />
                                            </div>
                                        </button>
                                        <button
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                goNext();
                                            }}
                                            className="tw-absolute tw-top-1/2 tw-transform tw--translate-y-1/2 tw-z-50 tw-w-14 tw-h-14 tw-flex tw-justify-center tw-items-center tw-right-0 tw-cursor-pointer tw-text-neutral-100 hover:tw-text-primary-500 "
                                        >
                                            <div className="tw-w-10 tw-h-10 tw-bg-neutral-900 tw-bg-opacity-50 tw-rounded-full tw-flex tw-justify-center tw-items-center">
                                                <Icon
                                                    icon="chevron-right"
                                                    className="tw-text-xl"
                                                />
                                            </div>
                                        </button>
                                    </>
                                )}
                                {media.length > 0 && (
                                    <div className="tw-flex-1 tw-flex tw-justify-center">
                                        <div
                                            className="tw-relative tw-inline-block"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setIsFullScreen(!isFullScreen);
                                            }}
                                        >
                                            <img
                                                ref={imgRef}
                                                src={currentImage}
                                                onLoad={handleResize}
                                                alt={
                                                    media[photoIndex].file_name
                                                }
                                                className={`tw-rounded-md ${
                                                    isFullScreen && !bp.sm
                                                        ? 'tw-h-full'
                                                        : 'lg:tw-h-[calc(100vh_-_14rem)] tw-h-[calc(100vh_-_17.5rem)]'
                                                }`}
                                            />
                                            <div
                                                id="bounding-boxes-portal"
                                                style={{
                                                    transform: `scaleX(${scaleX}) scaleY(${scaleY})`,
                                                    transformOrigin: 'top left',
                                                    width: `${100 / scaleX}%`,
                                                    height: `${100 / scaleY}%`,
                                                }}
                                                className="tw-absolute tw-inset-0 tw-pointer-events-none"
                                            />
                                            <Badge
                                                color="black"
                                                label={TimeUtils.format(
                                                    media[photoIndex]
                                                        .create_date,
                                                    'dddd, MMMM D, YYYY \\a\\t h:mm A'
                                                )}
                                                icon="clock"
                                                className={`tw-absolute tw-whitespace-nowrap ${
                                                    !bp.sm && isFullScreen
                                                        ? 'tw--bottom-12'
                                                        : 'tw-bottom-3'
                                                } ${
                                                    bp.lg
                                                        ? 'tw-left-3'
                                                        : 'tw-left-1/2 tw--translate-x-1/2'
                                                }`}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                            {(!isFullScreen || bp.sm) && (
                                <div
                                    className="tw-overflow-x-auto tw-shrink-0 tw-flex tw-gap-4 tw-flex-nowrap lg:tw-max-w-[calc(100vw-30.75rem)] tw-max-w-[calc(100vw_-_2rem)] tw-m-auto tw-snap-x tw-relative"
                                    onClick={(e) => e.stopPropagation()}
                                    ref={carouselRef}
                                >
                                    {mediaGroups.map((mediaGroup) => {
                                        const { id, label, media } = mediaGroup;

                                        const items = media.map(
                                            ({ index, ...imageMedia }) => (
                                                <div
                                                    className="tw-relative tw-snap-start"
                                                    ref={(el) => {
                                                        carouselItemRefs.current[
                                                            index
                                                        ] = el;
                                                    }}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        setPhotoIndex(index);
                                                    }}
                                                    key={imageMedia.file_name}
                                                >
                                                    <ImageBlock
                                                        imageUrl={
                                                            imageMedia.file_name
                                                        }
                                                        mainClassName="tw-cursor-pointer !tw-h-28 !tw-w-28 tw-shrink-0"
                                                        imageClassName={`tw-rounded-md tw-bg-cover tw-bg-center ${
                                                            photoIndex === index
                                                                ? 'tw-ring-4 tw-ring-inset tw-ring-theme-primary-500-300'
                                                                : ''
                                                        }`}
                                                    />
                                                    {photoIndex === index && (
                                                        <div className="tw-absolute tw-top-1 tw-left-1 tw-right-1 tw-bottom-1 tw-bg-primary-500 tw-bg-opacity-50 tw-pointer-events-none" />
                                                    )}
                                                    {imageMedia.node}
                                                </div>
                                            )
                                        );

                                        if (
                                            mediaGroups.length === 1 &&
                                            mediaGroups[0].id === undefined
                                        ) {
                                            return items;
                                        }

                                        return (
                                            <div
                                                className="tw-relative tw-snap-start"
                                                key={id}
                                            >
                                                <div className="tw-flex tw-flex-col tw-gap-2 tw-p-2 tw-bg-theme-neutral-100-800 tw-rounded-md">
                                                    {typeof label ===
                                                    'string' ? (
                                                        <Text
                                                            color="hi-contrast"
                                                            font="h4"
                                                            className="tw-whitespace-nowrap"
                                                        >
                                                            {label}
                                                        </Text>
                                                    ) : (
                                                        label
                                                    )}
                                                    <div className="tw-flex tw-gap-2">
                                                        {items}
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                {/* Right Side */}
                {(!isFullScreen || bp.sm) &&
                    (typeof details === 'function'
                        ? details?.(media[photoIndex], photoIndex)
                        : details)}
            </div>
        </ModalStack>
    );
};
