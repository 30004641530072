import { ContractRole } from '@api-interfaces';
import { virtualBrownClient } from '@core/services/django-client';

// helpers
import { Params } from '@helpers';

// interfaces
import { PaginationParams, PaginationResponse } from '@models';

// constants
const BASE_URL = 'contracts';

class ContractRolesService {
	getContractRolesById(
		contractId: number,
		params?: {
			role_group?: 'employee_role';
			ordering?: 'name';
			it?: number;
		} & PaginationParams
	) {
		return virtualBrownClient.get<PaginationResponse<ContractRole>>(
			`${BASE_URL}/${contractId}/roles/${Params.makeQueryString(params || {})}`
		);
	}

	createContractRole(payload) {
		return virtualBrownClient.post(`${BASE_URL}/roles/`, payload);
	}

	updateContractRoleById(contractRoleId: number, payload: {}) {
		return virtualBrownClient.put(
			`${BASE_URL}/roles/${contractRoleId}/`,
			payload
		);
	}

	deleteContractRoleById(contractRoleId: number) {
		return virtualBrownClient.delete(`${BASE_URL}/roles/${contractRoleId}/`);
	}
}

export const contractRolesService = new ContractRolesService();
