import { forwardRef } from 'react';

// interfaces
import { TextAreaProps } from './textarea.interfaces';

export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  ({ error, className, ...props }, ref) => (
    <textarea
      data-ds2="text-area"
      ref={ref}
      className={`tw-text-sm tw-min-h-[40px] tw-w-full tw-px-3 tw-py-2 tw-rounded tw-bg-theme-neutral-100-800 tw-outline-none tw-appearance-none tw-ring-2 tw-ring-inset tw-text-theme-neutral-900-100 tw-caret-neutral-600 dark:tw-caret-neutral-200

    disabled:tw-bg-theme-neutral-200-800 disabled:tw-cursor-not-allowed disabled:tw-text-theme-neutral-500-600
    read-only:tw-bg-theme-neutral-200-800 read-only:tw-text-theme-neutral-500-600
    
    ${
      error
        ? 'tw-appearance-none tw-ring-theme-danger-500-300 focus:tw-shadow-input-error disabled:tw-ring-opacity-20 read-only:tw-ring-opacity-20'
        : 'tw-appearance-none tw-ring-theme-neutral-400-600 focus:tw-ring-theme-primary-500-300 focus:tw-shadow-input-focus disabled:tw-ring-theme-neutral-300-700 read-only:tw-ring-theme-neutral-300-700'
    }

    ${className}
    `}
      {...props}
    />
  )
);

TextArea.defaultProps = {
  className: '',
};
