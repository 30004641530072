import { ModalStack } from '@atoms';
import { Modal } from '@templates';

import { useServiceIssueFormContext } from '../../_context';

export const ServiceIssueCancelPrompt = () => {
  const { activeModals, setActiveModals, closeModal } =
    useServiceIssueFormContext();

  function handleCancel() {
    setActiveModals({
      'cancel-service-issue': false,
    });
  }

  function handleConfirm() {
    setActiveModals({
      'cancel-service-issue': false,
    });
    closeModal();
  }

  return (
    activeModals['cancel-service-issue'] && (
      <ModalStack position="top-half-center">
        <Modal.Prompt
          key="cancel-prompt"
          prompt="Any changes will not be saved"
          title="Cancel?"
          onCancel={handleCancel}
          buttons={[
            {
              label: 'No',
              color: 'secondary',
              onClick: handleCancel,
            },
            {
              label: 'Yes',
              color: 'danger',
              onClick: handleConfirm,
            },
          ]}
        />
      </ModalStack>
    )
  );
};
