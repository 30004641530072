import { Observable } from 'rxjs';

// interfaces
import {
	EmployeeHierarchy,
	EmployeesHierarchyService as I_EmployeesHierarchyService,
} from '@api-interfaces';

// services
import { virtualBrownClient } from '@core/services/django-client';

// helpers
import { Params } from '@helpers';

// constants
const BASE_URL = 'employees/hierarchy';
const noOpts = {
	noContract: true,
	noCustomer: true,
	noTag: true,
};

class EmployeesHierarchyService {
	getHierarchy(params: I_EmployeesHierarchyService.Params): Observable<{
		count: number;
		results: EmployeeHierarchy[];
	}> {
		return virtualBrownClient.get(
			`${BASE_URL}/${Params.makeQueryString(params)}`,
			noOpts
		);
	}
}

export const employeesHierarchyService = new EmployeesHierarchyService();
