// interfaces
import { map } from 'rxjs/operators';

import { Conduct, GetConductsOrdering, PatchConduct } from '@api-interfaces';
import { TimeUtils, Params } from '@core/helpers';
import { IDjangoResponse } from '@core/services/django-client';
import { CommonParams, PaginationResponse } from '@models';

// services
import { virtualBrownClient } from '@services/django-client';

import { conductSummaryService } from './summary';

// helpers

// operators

// constants
const BASE_URL = `conducts`;

const defaultTo = TimeUtils.formatToISO(new Date());
const defaultFrom = TimeUtils.formatToISO(TimeUtils.subMonths(new Date(), 6));

class ConductsService {
	readonly summary = conductSummaryService;

	getConduct = (
		params: {
			to: Date | string;
			from: Date | string;
			limit?: number;
			offset?: number;
			ordering?: GetConductsOrdering;
			search?: string;
			export_to_excel?: boolean;
		} = {
			to: defaultTo,
			from: defaultFrom,
			limit: 10,
			offset: 0,
			ordering: 'occurrence_date',
			search: '',
		}
	) => {
		const qs = Params.makeQueryString(params);
		return virtualBrownClient
			.get<IDjangoResponse<Conduct>>(`conducts/${qs}`)
			.pipe(map((res: any) => (res ? res.results : [])));
	};

	public getConducts2 = (
		params: {
			employee?: string | number;
		} & CommonParams
	) => {
		const query = params;
		query.from = params.from
			? TimeUtils.format(params.from, 'YYYY-MM-DD')
			: undefined;
		query.to = params.to
			? TimeUtils.format(params.to, 'YYYY-MM-DD')
			: undefined;

		const queryString = Params.makeQueryString(query);

		return virtualBrownClient.get<PaginationResponse<Conduct>>(
			`${BASE_URL}/${queryString}`
		);
	};

	getConductById = (id: number) => {
		return virtualBrownClient.get<Conduct>(`${BASE_URL}/${id}/`);
	};

	public getTypeCounts = ({
		to,
		from,
		filters,
	}: {
		to: Date;
		from: Date;
		filters: any;
	}) => {
		const query = {
			to,
			from,
			chart: 0,
			...filters,
		};
		const qs = Params.makeQueryString(query);
		return virtualBrownClient.get(`conducts/summary/${qs}`);
	};

	public deleteReport = (id) => {
		return virtualBrownClient.delete(`conducts/${id}/`);
	};

	public getWarningTypes = () => {
		return virtualBrownClient
			.get(`reference/items/?group_code=Conduct_Warning_Types&limit=100`)
			.pipe(map((res: any) => (res ? res.results : [])));
	};

	public createConduct = (body) => {
		return virtualBrownClient.post<Conduct>(`${BASE_URL}/`, body);
	};

	public updateConduct = (id: number, payload: Conduct) => {
		return virtualBrownClient.put<Conduct>(`${BASE_URL}/${id}/`, payload);
	};

	public patchConduct = (id: number, payload: PatchConduct) => {
		return virtualBrownClient.patch<Conduct>(`${BASE_URL}/${id}/`, payload);
	};

	public getEmployeesData = (
		to = defaultTo,
		from = defaultFrom,
		limit = 500,
		ordering = '',
		sortDirection = 0
	) => {
		const query = {
			to,
			from,
			limit,
		} as any;

		if (ordering && ordering.length) {
			if (sortDirection) {
				query.ordering = `-${ordering}`;
			} else {
				query.ordering = ordering;
			}
		}

		const qs = Params.makeQueryString(query);
		return virtualBrownClient
			.get(`conducts/employees/${qs}`, { noCustomer: true })
			.pipe(map((res: any) => (res ? res.results : [])));
	};

	public getCharts = (
		to = defaultTo,
		from = defaultFrom,
		time_zone = Intl.DateTimeFormat().resolvedOptions().timeZone
	) => {
		const query = {
			to,
			from,
			chart: '0, 1, 2',
			time_zone,
		};
		const qs = Params.makeQueryString(query);
		return virtualBrownClient.get(`conducts/summary/${qs}`);
	};
}

export const conductsService = new ConductsService();
