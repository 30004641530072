import { SummaryChart, SummaryParams } from '@models';

export interface BehavioralAuditsSummaryParams<T> extends SummaryParams<T> {
	type?: 'Professionalism' | 'GMP';
}

export namespace BehavioralAuditsSummary {
	export enum Chart {
		GetBehavioralAuditsCounts = 0,
		GetBehavioralAuditsMonthlyTrends = 1, // chart 1 and 5 are identical 🤷🏽‍♂️🤷🏽‍♂️🤷🏽‍♂️
		GetBehavioralAuditsFocusAreas = 2,
		GetBehavioralAuditsCorrectiveActions = 3,
		GetProfessionalismAuditsCounts = 4,
		GetProfessionalismAuditsTrends = 5,
	}

	export interface BehavioralAuditsCounts {
		total_count: number;
		pass_count: number;
		fail_count: number;
		exempt_count: number;
	}

	export interface BehavioralAuditsCountsSummary
		extends SummaryChart<BehavioralAuditsCounts> {
		chart: 'GetBehavioralAuditsCounts';
		number: '0';
	}

	export interface BehavioralAuditsMonthlyTrends {
		mo: string;
		passing_count: number;
		not_passing_count: number;
		incomplete_count: number;
		exempt_count: number;
	}

	export interface BehavioralAuditsMonthlyTrendsSummary
		extends SummaryChart<BehavioralAuditsMonthlyTrends[]> {
		chart: 'GetBehavioralAuditsMonthlyTrends';
		number: '1';
	}

	export interface BehavioralAuditsFocusAreas {
		name: 'Quality' | 'Safety' | 'People' | string; // @TODO GMP - update, remove string
		questions: any; // same as above, see _new/pages/gmp-audits/feature-page/_components/focus-areas.tsx
	}

	export interface BehavioralAuditsFocusAreasSummary
		extends SummaryChart<BehavioralAuditsFocusAreas[]> {
		chart: 'GetBehavioralAuditsFocusAreas';
		number: '2';
	}

	export interface BehavioralAuditsCorrectiveActions {}

	export interface BehavioralAuditsCorrectiveActionsSummary
		extends SummaryChart<BehavioralAuditsCorrectiveActions[]> {
		chart: 'GetBehavioralAuditsCorrectiveActions';
		number: '3';
	}

	export interface ProfessionalismAuditsCounts {
		passing_count: number;
		not_passing_count: number;
		incomplete_count: number;
		exempt_count: number;
	}

	export interface ProfessionalismAuditsCountsSummary
		extends SummaryChart<ProfessionalismAuditsCounts> {
		chart: 'GetProfessionalismAuditsCounts';
		number: '4';
	}

	export interface ProfessionalismAuditsTrend {
		exempt_count: number;
		incomplete_count: number;
		mo: string;
		passing_count: number;
	}

	export interface ProfessionalismAuditsTrendSummary
		extends SummaryChart<ProfessionalismAuditsTrend[]> {
		chart: 'GetProfessionalismAuditsTrends';
		number: '5';
	}

	export interface SummaryReturnType {
		[Chart.GetBehavioralAuditsCounts]: BehavioralAuditsCountsSummary;
		[Chart.GetBehavioralAuditsMonthlyTrends]: BehavioralAuditsMonthlyTrendsSummary;
		[Chart.GetBehavioralAuditsFocusAreas]: BehavioralAuditsFocusAreasSummary;
		[Chart.GetBehavioralAuditsCorrectiveActions]: BehavioralAuditsCorrectiveActionsSummary;
		[Chart.GetProfessionalismAuditsCounts]: ProfessionalismAuditsCountsSummary;
		[Chart.GetProfessionalismAuditsTrends]: ProfessionalismAuditsTrendSummary;
	}
}
