import SVG from 'react-inlinesvg';
import { Text } from '@atoms';
import { useBreakpoints } from '@core/hooks';

// image assets
import emptyChartSvg from '@assets/Icons/charts-empty-state.svg';
import emptyPng from '@assets/images/backgrounds/details-empty.png';
import thumbsUpSvg from './zerorecordables.svg';
import svgLoungingDark from '@assets/images/backgrounds/dark_lounging.svg';
import svgLoungingLight from '@assets/images/backgrounds/light_lounging.svg';

// interfaces
import { EmptyProps } from './Empty.interfaces';

import './Empty.scss';
import { useThemeColor } from '@core/hooks';

const Empty = ({ className, ...props }: EmptyProps) => {
    const bp = useBreakpoints();
    const twoPartBpText = !bp.md ? 'body-sm' : 'h3';
    const twoPartSvg = useThemeColor(svgLoungingLight, svgLoungingDark);
    const styles = {
        width: props?.settings?.width ?? 250,
        marginBottom: props?.settings?.width ? 4 : 20,
        maxHeight: props?.settings?.width ? 150 : 300, // assuming set width for uncommon uses
    };

    return (
        <div
            className={`cm-empty t-text tw-select-none empty-height-${
                props?.settings?.height ?? 'med'
            } ${className}`}
            style={props?.style}
        >
            {props?.settings?.type === 'twoPart' ? (
                <div className="tw-flex tw-w-full">
                    {props.children ? (
                        // This will render meta elements if wrapped
                        <div>{props.children}</div>
                    ) : props.twoTypeText ? (
                        // Lets us add two lines of text as a font stack.  A default style
                        <div className="tw-flex tw-p-4 md:tw-p-6 lg:tw-p-10 tw-w-full tw-max-w-[456px] tw-items-center">
                            <div>
                                <Text
                                    font={twoPartBpText}
                                    color="hi-contrast"
                                    className="tw-mb-4"
                                >
                                    {props.twoTypeText}
                                </Text>
                                <Text font={twoPartBpText} color="hi-contrast">
                                    {props.twoTypeSubText}
                                </Text>
                            </div>
                        </div>
                    ) : (
                        <div className="tw-flex tw-p-4 md:tw-p-6 lg:tw-p-10 tw-w-full tw-max-w-[456px] tw-items-center">
                            <Text font={twoPartBpText} color="hi-contrast">
                                No data to display
                            </Text>
                        </div>
                    )}
                    <div className="tw-h-full tw-ml-auto">
                        <SVG
                            src={
                                props?.illustration
                                    ? props.illustration
                                    : twoPartSvg
                            }
                            style={{ height: '100%', width: '100%' }}
                        />
                    </div>
                </div>
            ) : props?.icon ? (
                <SVG src={props.icon} style={styles} />
            ) : props?.settings?.type === 'chart' ? (
                <SVG src={emptyChartSvg} style={styles} />
            ) : props?.settings?.type === 'jobWellDone' ? (
                <SVG
                    src={thumbsUpSvg}
                    style={{ ...styles, textAlign: 'center', width: 150 }}
                />
            ) : (
                <img src={emptyPng} style={{ ...styles, marginBottom: 10 }} />
            )}
            {props?.settings?.type !== 'twoPart' && (
                <>
                    <span>{props?.children ?? 'No data to display.'}</span>
                    <span className="subtext">{props?.subText ?? ''}</span>
                </>
            )}
        </div>
    );
};

Empty.defaultProps = {
    className: '',
};

export default Empty;

// <Empty>This is an empty content</Empty>
