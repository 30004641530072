// base
import { useState } from 'react';

// services
import { pluralize } from '@helpers';
import { historyService, modalService } from '@services';

// helpers

interface Props {
  handleReload?: () => void;
  maxRetry?: number;
  retryCount?: number;
  isModal?: boolean;
  appRoot?: boolean;
}

export default function SomethingWentWrong({
  maxRetry = 1,
  retryCount,
  handleReload,
  isModal = false,
  appRoot = false,
}: Props) {
  const [counter, setCounter] = useState(retryCount || 0);

  function onReloadCLick() {
    if (handleReload) {
      setCounter(counter + 1);
      handleReload();
    } else {
      window.location.reload();
    }
  }

  function onHomePageClick() {
    setCounter(counter + 1);
    historyService.navigate('/');
    // close all modals if error boundary was triggered in a modal
    modalService.closeAll();
  }

  function onHardRefresh() {
    window.location.reload();
  }

  return (
    <div className="t-text t-background tw-absolute tw-h-full tw-w-full tw-flex tw-justify-center tw-items-center tw-top-0 tw-left-0">
      <div className="tw-text-center">
        <h1 className="tw-mb-6">Something went wrong.</h1>

        {counter < maxRetry ? (
          <>
            <p className="tw-mb-2">
              We are sorry, if this problem persists contact us.
            </p>
            <p>
              Please try to{' '}
              <span className="t-text-link" onClick={onReloadCLick}>
                reload
              </span>{' '}
              {!appRoot && (
                <>
                  or return to{' '}
                  <span className="t-text-link" onClick={onHomePageClick}>
                    homepage
                  </span>
                  .
                </>
              )}
            </p>
          </>
        ) : (
          <>
            <p>
              We are sorry. We're unable to perform the action after {maxRetry}{' '}
              {pluralize('attempt', 'attempts', maxRetry)}.
            </p>
            <p className="tw-mt-2">
              <span className="t-text-link" onClick={onHardRefresh}>
                Perform hard refresh
              </span>
              {isModal && (
                <>
                  {' or '}
                  <span
                    className="t-text-link"
                    onClick={() => modalService.closeAll()}
                  >
                    Close Modal
                  </span>
                </>
              )}
            </p>
          </>
        )}
      </div>
    </div>
  );
}
