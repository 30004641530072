import { useEffect } from 'react';

// services

// helpers
import { forkJoin, Subscription, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

// interfaces
import {
	ContractGoallines,
	OrganizationGoallines,
	Service,
} from '@api-interfaces';
import { contractsService, organizationsService, servicesService } from '@apis';

import { useAppContext } from '../useAppContext';

export function useFetchContextGoalLinesAndParentPrograms() {
	const { state, dispatch } = useAppContext();

	const servicesResObservable = (params) => {
		// splitting contracts into chunks of 200 per request
		if (params.contract) {
			const contractChunks = chunkArray(params.contract.split(','), 200);
			return forkJoin(
				contractChunks.map((contractChunk) =>
					servicesService
						.getServices(
							{
								contract: contractChunk.join(','),
								customer: params.customer,
								parent_services: true,
							},
							{ noOptions: true }
						)
						.pipe(catchError((err) => of(null as Service)))
				)
			);
		}
		return [];
	};

	const mergeResults = (resultsArray) => {
		return resultsArray
			.flatMap(({ results }) => results)
			.reduce((res, cur) => {
				if (!res.find(({ id }) => id === cur.id)) res.push(cur);
				return res;
			}, []);
	};

	const chunkArray = (array, size) => {
		const result = [];
		for (let i = 0; i < array.length; i += size) {
			const chunk = array.slice(i, i + size);
			result.push(chunk);
		}
		return result;
	};

	useEffect(() => {
		if (!state.user) return;

		let subscription = new Subscription();
		const { selectedCustomers, selectedContracts } = state;

		if (selectedCustomers.length || selectedContracts.length) {
			const params = {
				parent_services: true,
				limit: 50,
			};
			if (selectedCustomers.length) {
				params.customer = selectedCustomers.map((c) => c.id).join(',');
			}
			if (selectedContracts.length) {
				params.contract = selectedContracts.map((c) => c?.id).join(',');
			}

			const organizationsGoalLinesObservable = () => {
				// splitting customers into chunks of 200 per request
				const customerChunks = chunkArray(
					selectedCustomers.map((c) => c.id),
					200
				);
				return forkJoin(
					customerChunks.map((customerChunk) =>
						organizationsService
							.getOrganizationsGoallines({
								organization: customerChunk.join(','),
								limit: 1000,
							})
							.pipe(catchError((err) => of(null as OrganizationGoallines)))
					)
				);
			};

			const contractsGoalLinesObservable = (params) => {
				// splitting contracts into chunks of 200 per request
				if (params.contract) {
					const contractChunks = chunkArray(params.contract.split(','), 200);
					return forkJoin(
						contractChunks.map((contractChunk) =>
							contractsService
								.getMultipleContractGoalLinesList({
									contracts: contractChunk.join(','),
								})
								.pipe(catchError((err) => of(null as ContractGoallines)))
						)
					);
				}
			};

			subscription = forkJoin({
				servicesRes: servicesResObservable(params),
				organizationsGoalLinesRes: selectedCustomers.length
					? organizationsGoalLinesObservable()
					: of([] as OrganizationGoallines[]),
				contractsGoalLinesRes: selectedContracts.length
					? contractsGoalLinesObservable(params)
					: of([] as ContractGoallines[]),
			}).subscribe({
				next: ({
					servicesRes,
					organizationsGoalLinesRes,
					contractsGoalLinesRes,
				}) => {
					dispatch({
						type: 'SET_PROGRAMS_ORGS_AND_CONTRACTS_GOALLINES',
						payload: {
							parentPrograms: mergeResults(servicesRes),
							contractsGoalLines: contractsGoalLinesRes.filter(
								(goalLine) => goalLine
							),
							organizationsGoalLines: mergeResults(organizationsGoalLinesRes),
						},
					});
				},
			});
		}

		return () => {
			subscription.unsubscribe();
		};
	}, [state.user, state.selectedCustomers, state.selectedContracts]);
}
