import { SummaryChart, TimeScale, SummaryParams } from '@models';

export interface WorkOrdersSummaryParams<T> extends SummaryParams<T> {
	all_child?: boolean;
	time_period?: 'hour' | 'day' | 'month';
	top_n_threshold_to_other?: number;
	service?: number;
	is_project_work?: boolean; // get project work counts
	servicearea?: number;
	areatype?: number;
	root_area?: number;
	by_verticals?: string;
}

export namespace WorkOrdersSummary {
	export enum Chart {
		GetWorkOrderCounts = 0,
		GetWorkOrderCountsByType = 1,
		GetWorkOrderTrends = 2,
		Chart3Broken = 3,
		GetWorkOrderCountsByProgram = 4,
		GetWorkOrderCountsByOrganization = 5,
		GetWorkOrderCountsByVendor = 6,
		GetWorkOrderPortionsAndTrendsByServiceType = 8,
		GetWorkOrderServiceTypesByBuildings = 10,
		GetWorkOrderCountsByAreaType = 11,
		GetWorkOrderCountsByMasterAreaType = 12,
		GetWorkOrderCountsByVerticals = 13,
		GetWorkOrderCountsByVerticalsOrganizationList = 14,
	}

	export interface WorkOrderCounts {
		assigned_count: number;
		billing_count: number;
		in_scope: number;
		new_count: number;
		on_time_completion_count: number;
		on_time_completion_percent: number;
		out_of_scope: number;
		past_due_count: number;
		resolved_count: number;
		submitted_count: number;
		is_project_work_count?: number;
		is_project_work_completion_count?: number;
		is_project_work_past_due_count?: number;
	}

	export interface WorkOrderCountsSummary
		extends SummaryChart<WorkOrderCounts> {
		chart: 'GetWorkOrderCounts';
		number: '0';
	}

	export interface WorkOrderCountsByType {
		'1x_Sanitization': number;
		'2x_Sanitization': number;
		'3x_Sanitization': number;
		'A/C_Maintenance': number;
		Access: number;
		Accessories: number;
		Appliances: number;
		'Audio/Visual': number;
		Batteries: number;
		Bin_delivery: number;
		Bins: number;
		Boards: number;
		'Box/Label_Delivery': number;
		Box_Move: number;
		Boxes: number;
		CONFPIK: number;
		'CR-Chevron': number;
		'CR-Classroom': number;
		'CR-Group': number;
		'CR-See_Description': number;
		'CR-Theatre': number;
		'CR-UShape': number;
		Cables: number;
		Cad_Support: number;
		Cafe_Issue: number;
		Cardboard_Issue: number;
		Carpentry: number;
		Carpet_Cleaning: number;
		Carpet_Spotting: number;
		Carpets: number;
		Cart_Delivery: number;
		Ceiling: number;
		Chair_Cleaning: number;
		Change_over_cleaning: number;
		Cleaning: number;
		Cleanning_pipes: number;
		Cleanroom_Issue: number;
		Cleanroom_Reconfigure: number;
		Cobb_web_free: number;
		Comfort_Call: number;
		Complaint: number;
		Conference_Room: number;
		Conference_Rooms: number;
		Construction: number;
		Coolant: number;
		Cooling: number;
		Copier_Call: number;
		Copiers: number;
		'Courtyards/Patios': number;
		Cove_Base: number;
		'Critical_Event_-_Cleaning': number;
		'Cube/Office_Cleaning': number;
		Customer_Service: number;
		Day_Porter: number;
		Delay_of_service_delivery: number;
		Deliveries: number;
		Delivery: number;
		'Delivery/Installation': number;
		Desk_clean_out: number;
		Dispensers: number;
		Disposal: number;
		Dock_Cleaning: number;
		Door_Issue: number;
		'Doors/Locks': number;
		Dusting: number;
		'EH&S_Issue': number;
		ERGOSAFE: number;
		EXCESPIK: number;
		Easels: number;
		Electrical: number;
		Electrical_Issue: number;
		Electrical_Request: number;
		Emergency_Response: number;
		Engineering: number;
		Ergo: number;
		Escort: number;
		Event_Support: number;
		Exterior_Trash_and_Debris: number;
		FCO: number;
		FURNITUR: number;
		Facility_Equipment: number;
		Facility_Issue: number;
		Fire_Systems: number;
		Floor_Cleaning: number;
		Floor_Issue: number;
		Floor_Wax: number;
		Floor_Work: number;
		Flooring_Issue: number;
		Floors: number;
		Flower_beds: number;
		Flowers: number;
		Full_Clean: number;
		Furniture: number;
		Furniture_Delivery: number;
		Furniture_Reconfigure: number;
		Furniture_Request: number;
		Furniture_Systems: number;
		Gardens_maintenance: number;
		General: number;
		General_Appearance: number;
		General_Cleaning: number;
		General_Maintenance: number;
		Good_Catch: number;
		Gravel: number;
		Green_and_white_box_removal: number;
		HVAC: number;
		Hang: number;
		Heating: number;
		Hedges_and_Shrubs: number;
		High_Density_Tracks: number;
		Hygiene: number;
		Installation: number;
		Irrigation: number;
		Irrigation_System: number;
		Janitorial_Complaint: number;
		Key_Request: number;
		Keys_and_Lock: number;
		LAN: number;
		LANINST: number;
		Lab_Cleaning: number;
		Lab_Issue: number;
		Lab_Reconfigure: number;
		Landscape: number;
		Lawn: number;
		'Lawn_-_Fertilization_&_Water': number;
		Leaks: number;
		Light_Change: number;
		Lighting: number;
		Lighting_Issue: number;
		Lights: number;
		Lock_and_Key: number;
		Lotion: number;
		MOVECLN: number;
		Mail_Room: number;
		Maintenance: number;
		Maintenance_Issue: number;
		Maintenance_Techs: number;
		Maintenance_support_: number;
		Manufacturing_Move: number;
		Mechanical_Request: number;
		'Misc.': number;
		'Misc._Maintenance': number;
		Miscellaneous_issue: number;
		Mopping: number;
		Move: number;
		Move_Issue: number;
		Mowing: number;
		NOMACACT: number;
		Near_Miss: number;
		'New_Hire/Move': number;
		New_Hire_Cleaning: number;
		No_debris: number;
		'Non-Landscape_Areas': number;
		OTJ_Training: number;
		Office_Move: number;
		Other: number;
		Other_Vendor_Contractor: number;
		PM: number;
		Painting: number;
		Palletizing: number;
		Pallets: number;
		'Parking/Garages': number;
		Parking_Issue: number;
		Parking_Lots: number;
		Partitions: number;
		'Patch/Paint': number;
		Patio_Cleaning: number;
		Paving: number;
		Pest_Control: number;
		Pest_Control_Indoor: number;
		Pest_Control_Outdoor: number;
		Pest_free: number;
		'Picture/Poster_Hanging': number;
		Plumbing: number;
		Plumbing_Issue: number;
		Posters: number;
		Power: number;
		Pressure_Washing: number;
		Preventative_maintenance: number;
		Print_Shop: number;
		Process_Gasses: number;
		Project_Support: number;
		Projector: number;
		Projects: number;
		Pruning_trees: number;
		Purchasing: number;
		'Purge_Station_Add/Remove': number;
		RECYCPIK: number;
		Reconfigure: number;
		Recycle: number;
		Recycle_Program: number;
		Recycle_issue: number;
		Refrigerator_clean_out: number;
		Regulated_Waste: number;
		Removal_of_debris: number;
		Repair: number;
		Reparación: number;
		Response_Time: number;
		Restrooms: number;
		Retentions: number;
		SAFEHAZ: number;
		SBM_Techs: number;
		SBMinsite: number;
		SOP_Training: number;
		Screen: number;
		Secure_area_request: number;
		Security_Request: number;
		Service_Call: number;
		Setup: number;
		Setups: number;
		'Sidewalk/Walkways': number;
		Sidewalks_and_Walkways: number;
		Silicone_Cleanup: number;
		'Snow/Ice_Removal': number;
		Soap_Dispensers: number;
		'Spill_Clean-up': number;
		Spill_cleanup: number;
		'Spills/Overflows': number;
		Sprinkler_and_Irrigation: number;
		Storage: number;
		Sub_Plenum_Cleaning: number;
		Suckers: number;
		Super_Clean: number;
		Supplies: number;
		Supply_Issue: number;
		Surplus: number;
		Tables: number;
		Temperature_Control_Adjustment: number;
		Terminal_Clean: number;
		Toilet_Over_Flow: number;
		Toilet_Paper: number;
		Tool_Wipe_Down: number;
		Towel_Dispensers: number;
		Transplant_plants: number;
		Trash: number;
		'Trash/Debris': number;
		'Trash_-_Courtyards/Patios': number;
		'Trash_-_Parking_Lots/Garages': number;
		'Trash_-_Removal_at_Entrances': number;
		Trash_Disposal: number;
		Trash_Pickup: number;
		Trash_Removal: number;
		Trashing: number;
		Tree_Care: number;
		Tree_planting: number;
		Trees: number;
		'Trees_-_Health': number;
		'Trim_Hedges/Shrubs': number;
		Trim_Trees: number;
		'Trimmed_&_maintained': number;
		Trimming: number;
		Vacuuming: number;
		Vacuuming_Issue: number;
		Ventilation: number;
		Wall_Cleaning: number;
		Wall_Surfaces: number;
		'Water_Clean-up': number;
		Weeds: number;
		Window_Cleaning: number;
		Windows: number;
		'Windows/Glass': number;
		Work_Surfaces: number;
	}

	export interface WorkOrderCountsByTypeSummary
		extends SummaryChart<WorkOrderCountsByType[]> {
		chart: 'GetWorkOrderCountsByType';
		number: '1';
	}

	export interface WorkOrderTrends {
		in_scope: number;
		month: number;
		out_of_scope: number;
		year: number;
	}

	export interface WorkOrderTrendsSummary
		extends SummaryChart<WorkOrderTrends[]> {
		chart: 'GetWorkOrderTrends';
		number: '2';
		time_scale: TimeScale;
	}

	export interface WorkOrderCountsByProgram {
		count: number;
		id: number;
		name: string;
	}

	export interface WorkOrderCountsByProgramSummary
		extends SummaryChart<WorkOrderCountsByProgram[]> {
		chart: 'GetWorkOrderCountsByProgram';
		number: '4';
	}

	export interface WorkOrderCountsByOrganization {
		assigned_count: number;
		customer_id: number;
		customer_name: string;
		in_scope: number;
		new_count: number;
		on_time_completion_count: number;
		out_of_scope: number;
		past_due_count: number;
		resolved_count: number;
		submitted_count: number;
	}

	export interface WorkOrderCountsByOrganizationSummary
		extends SummaryChart<WorkOrderCountsByOrganization[]> {
		chart: 'GetWorkOrderCountsByOrganization';
		number: '5';
	}

	export interface WorkOrderCountsByVendor {
		id: number;
		name: string;
		in_scope_count: number;
		out_of_scope_count: number;
	}

	export interface WorkOrderCountsByVendorSummary
		extends SummaryChart<WorkOrderCountsByVendor[]> {
		chart: 'GetWorkOrderCountsByVendor';
		number: '6';
	}

	export interface WorkOrderPortionsAndTrendsByServiceType {
		trends: {
			service_type_id: number;
			service_type_code: string;
			trend: {
				time_period: string;
				number_of_records: number;
			}[];
		}[];
		portions: {
			service_type_id: number;
			service_type_code: string;
			number_of_records: number;
			percent_of_total: number;
		}[];
	}

	export interface WorkOrderPortionsAndTrendsByServiceTypeSummary
		extends SummaryChart<WorkOrderPortionsAndTrendsByServiceType> {
		chart: 'GetWorkOrderPortionsAndTrendsByServiceType';
		number: '8';
	}

	export interface WorkOrderServiceTypesByBuilding {
		service_type_id?: number;
		service_type_code?: string;
		number_of_records?: number;
	}

	export interface WorkOrderServiceTypesByBuildings {
		root_area_id: number;
		root_area_name: string;
		number_of_records?: number;
		service_types: WorkOrderServiceTypesByBuilding[];
	}

	export interface WorkOrderServiceTypesByBuildingsSummary
		extends SummaryChart<WorkOrderServiceTypesByBuildings[]> {
		chart: 'GetWorkOrderServiceTypesByBuildings';
		number: '10';
	}

	export interface WorkOrderCountsByAreaType {
		area_type_id: number;
		area_type_name: string;
		total_count: number;
		in_scope_count: number;
		out_of_scope_count: number;
		project_work_count: number;
		on_time_completion_count: number;
		past_due_count: number;
	}

	export interface WorkOrderCountsByAreaTypeSummary
		extends SummaryChart<WorkOrderCountsByAreaType[]> {
		chart: 'GetWorkOrderCountsByAreaType';
		number: '11';
	}

	export interface WorkOrderCountsByMasterAreaType {
		master_area_type_id: number;
		master_area_type_name: string;
		total_count: number;
		in_scope_count: number;
		out_of_scope_count: number;
		project_work_count: number;
		on_time_completion_count: number;
		past_due_count: number;
	}

	export interface WorkOrderCountsByMasterAreaTypeSummary
		extends SummaryChart<WorkOrderCountsByMasterAreaType[]> {
		chart: 'GetWorkOrderCountsByMasterAreaType';
		number: '12';
	}

	export interface WorkOrderCountsByVerticals {
		count?: number;
		id?: number;
		name?: string;
	}

	export interface WorkOrderCountsByVerticalsSummary
		extends SummaryChart<WorkOrderCountsByVerticals[]> {
		chart: 'GetWorkOrderCountsByVerticals';
		number: '13';
	}

	export interface WorkOrderCountsByVerticalsOrganizationList {
		id?: number;
		name?: string;
		count?: number;
	}

	export interface WorkOrderCountsByVerticalsOrganizationListSummary
		extends SummaryChart<WorkOrderCountsByVerticalsOrganizationList[]> {
		chart: 'GetWorkOrderCountsByVerticals';
		number: '14';
	}

	export interface SummaryReturnType {
		[Chart.GetWorkOrderCounts]: WorkOrderCountsSummary;
		[Chart.GetWorkOrderCountsByType]: WorkOrderCountsByTypeSummary;
		[Chart.GetWorkOrderTrends]: WorkOrderTrendsSummary;
		[Chart.Chart3Broken]: any;
		[Chart.GetWorkOrderCountsByProgram]: WorkOrderCountsByProgramSummary;
		[Chart.GetWorkOrderCountsByOrganization]: WorkOrderCountsByOrganizationSummary;
		[Chart.GetWorkOrderCountsByVendor]: WorkOrderCountsByVendorSummary;
		[Chart.GetWorkOrderPortionsAndTrendsByServiceType]: WorkOrderPortionsAndTrendsByServiceTypeSummary;
		[Chart.GetWorkOrderServiceTypesByBuildings]: WorkOrderServiceTypesByBuildingsSummary;
		[Chart.GetWorkOrderCountsByAreaType]: WorkOrderCountsByAreaTypeSummary;
		[Chart.GetWorkOrderCountsByMasterAreaType]: WorkOrderCountsByMasterAreaTypeSummary;
		[Chart.GetWorkOrderCountsByVerticals]: WorkOrderCountsByVerticalsSummary;
		[Chart.GetWorkOrderCountsByVerticalsOrganizationList]: WorkOrderCountsByVerticalsOrganizationListSummary;
	}
}
