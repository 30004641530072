import { useState } from 'react';

// components
import { Box, Text } from '@atoms';
import { useAuditModalContext } from '@modals/_ds2/audit/_context/context';
import { Toggle } from '@new/Toggle';

import { AreaTypesTable } from './area-types-table';
import { AreasTable } from './areas-table';
import { GroupAreasTable } from './group-areas-table';
import { SectionsTable } from './sections-table';

// helpers

export const Areas = () => {
  const {
    state: { selectedAreaType, selectedAreaTypeId, selectedSection, sections },
    dispatch,
  } = useAuditModalContext();
  const hasSections = sections?.length;
  const [tab, setTab] = useState<number>(
    hasSections && !selectedAreaTypeId ? 1 : 2
  );

  const labels = [
    { id: 2, name: 'Area Types' },
    { id: 3, name: 'All Areas' },
  ];

  if (hasSections) labels.unshift({ id: 1, name: 'Sections' });

  return (
    <Box id="audit-modal-areas" className="tw-p-4 sm:tw-p-10" rounded>
      <Text font="h2" color="current-color" className="tw-mb-6">
        {selectedAreaType || selectedSection ? (
          <span>
            <span
              className="tw-cursor-pointer tw-text-neutral-500 dark:tw-text-neutral-100"
              onClick={() => {
                dispatch({
                  type: selectedAreaType
                    ? 'SET_SELECTED_AREA_TYPE'
                    : 'SET_SELECTED_SECTION',
                  payload: null,
                });
              }}
            >
              Areas
            </span>
            <span className="tw-text-neutral-500 dark:tw-text-neutral-100">
              {' '}
              /{' '}
            </span>
            <span className="tw-text-theme-primary-500-300">
              {selectedAreaType ? selectedAreaType.name : selectedSection.name}
            </span>
          </span>
        ) : (
          <span className="tw-text-theme-primary-500-300">Areas</span>
        )}
      </Text>

      {!selectedAreaType && !selectedSection && (
        <Toggle
          labels={labels}
          active={tab}
          onClick={(id: number) => setTab(id)}
          className="tw-mb-8"
        />
      )}

      <div className="form-common-scroll tw-overflow-x-auto tw-whitespace-nowrap">
        {selectedAreaType || selectedSection ? (
          <GroupAreasTable />
        ) : tab === 1 ? (
          <SectionsTable />
        ) : tab === 2 ? (
          <AreaTypesTable />
        ) : (
          <AreasTable />
        )}
      </div>
    </Box>
  );
};
