// services
import { financialHoursSummaryService } from './summary';
import { GetEmployeeParams, Employee } from '@api-interfaces';
import { Params } from '@helpers';
import { CommonParams, PaginationResponse } from '@models';
import { virtualBrownClient } from '@core/services/django-client';

const BASE_URL = 'financials/hours/';

// new api to retrieve the all of the employees in the
// selected month regardless what site they are active in
class FinancialHoursService {
	readonly summary = financialHoursSummaryService;

	public getTimeClockEmployees(
		params: GetEmployeeParams & CommonParams,
		options?: {
			noContract?: boolean;
			noCustomer?: boolean;
			noTag?: boolean;
		}
	) {
		const defaultOptions = {
			noCustomer: true,
			noContract: !!params.contract,
		};

		if (
			params.export_to_excel
				? !params.export_to_excel
				: !params.hasOwnProperty('limit') || !params.limit
		) {
			params.limit = 1000;
		}

		const queryString = Params.makeQueryString({ ...params });

		return virtualBrownClient.get<PaginationResponse<Employee>>(
			`${BASE_URL}employees/${queryString}`,
			options || defaultOptions
		);
	}
}

export const financialHoursService = new FinancialHoursService();
