// interfaces
import { Service, ServicePayload } from '@api-interfaces';

// services
import { virtualBrownClient } from '@core/services/django-client';

// helpers
import { Params } from '@helpers';
import { PaginationParams, PaginationResponse } from '@models';

import { nonservicesService } from './non-services';

// constants
const BASE_URL = 'services';

const noOpts = {
	noProvider: true,
	noCustomer: true,
	noContract: true,
	noTag: true,
};

class ServicesService {
	readonly nonservices = nonservicesService;

	public getServices(
		params?: {
			contract?: string | number;
			customer?: string | number;
			provider?: number;
			check_obligation?: boolean;
			parent_services?: boolean;
			include_sub_contracts?: boolean;
			area?: number[];
			admin_landing?: boolean;
			parent?: number; // available when admin_landing is true
		} & PaginationParams,
		settings?: {
			noOptions?: boolean;
		}
	) {
		const queryString = Params.makeQueryString({
			...params,
			limit: params.limit ? params.limit : 100, // was hard set to 100 by previous dev
		});

		return virtualBrownClient.get<PaginationResponse<Service>>(
			`${BASE_URL}/${queryString}`,
			settings && settings.noOptions ? noOpts : {}
		);
	}

	public getServiceById(params: { id: number }) {
		return virtualBrownClient.get<Service>(`${BASE_URL}/${params.id}/`, noOpts);
	}

	public createService(payload: ServicePayload) {
		return virtualBrownClient.post<Service>(`${BASE_URL}/`, payload);
	}

	public updateService(params: { id: number }, payload: ServicePayload) {
		return virtualBrownClient.put<Service>(
			`${BASE_URL}/${params.id}/`,
			payload
		);
	}
}

export const servicesService = new ServicesService();
