import { createContext, useContext, useState, useEffect, useRef } from 'react';

// services
import { Subscription, forkJoin } from 'rxjs';

import { contractsService, servicesService, todosService } from '@apis';

// helpers
import { sendError } from '@helpers';
import { useAppContext, useLocationsRef } from '@hooks';

import { useWorkOrderModalContext } from '../../work-orders/_context';

// interfaces
import { TodoFormState, TodoFormProps } from './todo-form.context.interfaces';

const todoFormContext = createContext(null as TodoFormState);

export const TodoFormProvider = (props: TodoFormProps) => {
    const {
        state: { selectedCustomers, selectedContracts, isSingleSite },
    } = useAppContext();

    let workOrder;
    const isAttachedToWorkOrder = useWorkOrderModalContext();

    if (isAttachedToWorkOrder) {
        const { workOrder: workOrderModalContext } = useWorkOrderModalContext();
        workOrder = workOrderModalContext;
    }

    const [isLoading, setIsLoading] = useState(true);
    const [todo, setTodo] = useState<TodoFormState['todo']>(null);
    const [todoEdit, setTodoEdit] = useState<TodoFormState['todoEdit']>(null);
    const [serviceProviderOptions, setServiceProviderOptions] = useState<
        TodoFormState['serviceProviderOptions']
    >([]);
    const [serviceOptions, setServiceOptions] = useState<
        TodoFormState['serviceOptions']
    >([]);
    const [activeModals, setActiveModals] = useState({
        'cancel-todo': false,
    });
    const hasBeenEdited = useRef(false);
    const defaultLocation = useLocationsRef(props.location);

    useEffect(() => {
        let subscription = new Subscription();
        if (props.todo && !todo) {
            setTodo(props.todo);
            setIsLoading(false);
        } else if (props.todoId != undefined) {
            subscription = todosService.getTodoById(props.todoId).subscribe({
                next: (todo) => {
                    setTodo(todo);
                    setIsLoading(false);
                },
                error: sendError({
                    toastMessage: 'Cannot load To-Do Details',
                    callback: () => {
                        setIsLoading(false);
                    },
                }),
            });
        } else {
            setIsLoading(false);
        }

        return () => {
            subscription.unsubscribe();
        };
    }, [props.todo, props.todoId]);

    useEffect(() => {
        if (isLoading) return;

        const subscription = forkJoin([
            contractsService.getContracts({
                contract: props.contractId || workOrder?.contract?.id,
                use_ifm: true,
            }),
            servicesService.getServices({ limit: 100, parent_services: false }),
        ]).subscribe({
            next: ([{ results: contracts }, { results: services }]) => {
                contracts.sort((a, b) =>
                    a.service_provider.name.localeCompare(
                        b.service_provider.name
                    )
                );
                services.sort((a, b) => a.name.localeCompare(b.name));

                setServiceProviderOptions(contracts);
                setServiceOptions(services);
            },
        });

        return () => {
            subscription.unsubscribe();
        };
    }, [todo, isLoading, selectedCustomers, selectedContracts, isSingleSite]);

    useEffect(() => {
        setTodoEdit(todo);
    }, [todo]);

    useEffect(() => {
        setTodoEdit({
            ...todoEdit,
            media_list: props.passedMediaList,
            comment: props.submittedLocation
                ? `${props?.description} ${props.submittedLocation}`
                : '',
            location:
                defaultLocation.room ??
                defaultLocation.floor ??
                defaultLocation.building ??
                null,
            shifts: props.passedShiftsList,
        });
    }, [props]);

    return (
        <todoFormContext.Provider
            value={{
                onSuccess: props.onSuccess,
                externalDueDate: props.externalDueDate,
                closeModal: () => {
                    setTodo(null);
                    setActiveModals({
                        'cancel-todo': false,
                    });
                    setIsLoading(true);
                    props.closeModal();
                    hasBeenEdited.current = false;
                },
                isLoading,
                setIsLoading,
                todo,
                setTodo,
                todoEdit,
                setTodoEdit,
                activeModals,
                setActiveModals,
                serviceProviderOptions,
                setServiceProviderOptions,
                serviceOptions,
                setServiceOptions,
                hasBeenEdited,
                occupantReportsPopulate: props.occupantReportsPopulate,
                description: props.submittedLocation
                    ? `${props?.description} ${props.submittedLocation}`
                    : props?.description,
                passedMediaList: props.passedMediaList,
                passedShiftsList: props.passedShiftsList,
                verificationId: props.verificationId,
            }}
            {...props}
        />
    );
};

export function useTodoFormContext() {
    return useContext(todoFormContext);
}
