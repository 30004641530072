import { WorkDayStatusEnum, Employee } from '@api-interfaces';
import { SummaryChart, SummaryParams } from '@models';

export interface WorkDaySummaryParams<T> extends SummaryParams<T> {
	most_recent?: boolean;
}

export namespace WorkDaySummary {
	export enum Chart {
		GetWorkDayCounts = 0,
		GetWorkDayStatusCounts = 1,
		GetDailyStats = 2,
		GetClockedInSummary = 3,
	}

	export interface WorkDayCounts {
		total_manager_approved_hours_hh_mm_ss: string;
		total_holiday_hh_mm_ss: string;
		total_hh_mm_ss: string;
		total_other_hh_mm_ss: string;
		total_sick_hh_mm_ss: string;
		total_unknown_hh_mm_ss: string;
		total_unpaid_hh_mm_ss: string;
		total_vacation_hh_mm_ss: string;
		total_work_hh_mm_ss: string;

		total_holiday_hours: string;
		total_hours: string;
		total_hours_ms: string;
		total_other_hours: string;
		total_sick_hours: string;
		total_unknown_hours: string;
		total_unpaid_hours: string;
		total_vacation_hours: string;
		total_work_hours: string;
	}

	export interface WorkDayCountsSummary extends SummaryChart<WorkDayCounts> {
		chart: 'GetWorkDayCounts';
		number: '0';
	}

	export interface WorkDayStatusCounts {
		manager_approved_workdays: number;
		payroll_approved_workdays: number;
		payroll_submitted_workdays: number;
		pending_workdays: number;
		total_workdays: number;
	}

	export interface WorkDayStatusCountsSummary
		extends SummaryChart<WorkDayStatusCounts> {
		chart: 'GetWorkDayStatusCounts';
		number: '1';
	}

	export interface DailyStats {
		finalized_by: Employee;
		day: string;
		finalized_on: null | string;
		status:
			| WorkDayStatusEnum.PENDING
			| WorkDayStatusEnum.SUBMITTED_TO_PAYROLL
			| WorkDayStatusEnum.ERROR_NO_JDE_ID;
		total_ms: null | string;
		total_manager_approved_ms: string;
	}

	export interface DailyStatsSummary extends SummaryChart<DailyStats[]> {
		chart: 'GetDailyStats';
		number: '2';
	}

	export interface ClockedIn {
		clocked_in_count: number;
		recently_clocked_in: {
			photo_url: string;
			person?: {
				id: number;
				first_name: string;
				last_name: string;
				photo_url: string;
			};
		}[];
		most_recent_record?: string;
	}

	export interface ClockedInSummary extends SummaryChart<ClockedIn> {
		chart: 'GetClockedInSummary';
		number: '3';
	}

	export interface SummaryReturnType {
		[Chart.GetWorkDayCounts]: WorkDayCountsSummary;
		[Chart.GetWorkDayStatusCounts]: WorkDayStatusCountsSummary;
		[Chart.GetDailyStats]: DailyStatsSummary;
		[Chart.GetClockedInSummary]: ClockedInSummary;
	}
}
