import { SummaryChart, SummaryParams } from '@models';

export namespace OrganizationGroupsSummary {
	export enum Chart {
		GetOrganizationGroupDeactivateCounts = 0,
		GetOrganizationGroupTypeDeactivateCounts = 1,
	}

	export interface OrganizationGroupDeactivateCounts {
		organization_groups: string | number;
		organization_group_types: string | number;
		organization_group_organizations: string | number;
	}

	export interface GetOrganizationGroupDeactivateCountsSummary
		extends SummaryChart<OrganizationGroupDeactivateCounts> {
		chart: 'GetOrganizationUserAccounCounts';
		number: '0';
	}

	export interface GetOrganizationGroupTypeDeactivateCountsSummary
		extends SummaryChart<OrganizationGroupDeactivateCounts> {
		chart: 'GetOrganizationGroupTypeDeactivateCounts';
		number: '1';
	}

	export interface OrganizationGroupSummaryParams<T>
		extends SummaryParams<T> {
		chart: T[];
		organization_groups?: string;
		org_group_types?: string;
	}

	export interface SummaryReturnType {
		[Chart.GetOrganizationGroupDeactivateCounts]: GetOrganizationGroupDeactivateCountsSummary;
		[Chart.GetOrganizationGroupTypeDeactivateCounts]: GetOrganizationGroupTypeDeactivateCountsSummary;
	}
}
