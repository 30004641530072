import { useCombobox, useMultipleSelection } from 'downshift';
import { sortBy } from 'lodash';
import { Fragment, useEffect, useState, forwardRef, useCallback } from 'react';

// helpers

// components
import { Badge, IconButtonFlat, Text } from '@atoms';

// interfaces
import { MultiSelectProps, MultiSelectItem } from './multi-select.interfaces';

export const MultiSelect = forwardRef<HTMLInputElement, MultiSelectProps>(
    (
        {
            items,
            onChange,
            selectedItems: controlledSelectedItems,
            disabled,
            error,
            onInputChange,
            placeholder,
            allowKeyAction,
            groupBy,
            ...props
        },
        ref
    ) => {
        const [inputValue, setInputValue] = useState('');

        useEffect(() => {
            if (onInputChange) {
                onInputChange(inputValue);
            }
        }, [onInputChange, inputValue]);

        const {
            getDropdownProps,
            addSelectedItem,
            removeSelectedItem,
            selectedItems,
        } = useMultipleSelection<MultiSelectItem>({
            selectedItems: controlledSelectedItems,
            onSelectedItemsChange: (state) =>
                // CH100650 fix the freeze on mobile view caused from Warning: Maximum update depth exceeded
                setTimeout(() => {
                    onChange(state?.selectedItems ?? []);
                }, 10),
            stateReducer: (state, action) => {
                const { type, changes, selectedItem } = action;
                switch (type) {
                    case useMultipleSelection.stateChangeTypes
                        .FunctionRemoveSelectedItem:
                        return {
                            ...changes,
                            selectedItems: state.selectedItems.filter(
                                (item) => item.id !== selectedItem.id
                            ),
                        };
                    default:
                        return changes;
                }
            },
        });

        const getFilteredItems = useCallback(() => {
            const searchValue = inputValue.toLowerCase();
            let filteredItems: MultiSelectItem[] = items.filter(
                (item) =>
                    (item?.name?.toLowerCase()?.includes(searchValue) ||
                        item.subtext?.toLowerCase()?.includes(searchValue)) &&
                    !selectedItems.find(
                        (selectedItem) => selectedItem.id === item.id
                    )
            );

            if (groupBy) {
                filteredItems = sortBy(filteredItems, groupBy);
            }

            return filteredItems;
        }, [items, inputValue, selectedItems]);

        const {
            isOpen,
            getToggleButtonProps,
            getMenuProps,
            getInputProps,
            getItemProps,
            highlightedIndex,
            openMenu,
        } = useCombobox<MultiSelectItem>({
            inputValue,
            itemToString: (item) => item?.name ?? '',
            items: getFilteredItems(),
            stateReducer: (_, action) => {
                const { type, changes, selectItem } = action;
                const { inputValue, selectedItem } = changes;
                switch (type) {
                    case useCombobox.stateChangeTypes.InputChange:
                        setInputValue(inputValue ?? '');
                        return {
                            ...changes,
                            highlightedIndex: inputValue ? 0 : undefined,
                        };
                    case useCombobox.stateChangeTypes.InputKeyDownEnter:
                    case useCombobox.stateChangeTypes.ItemClick:
                        setInputValue('');
                        if (
                            selectedItem &&
                            items.find((item) => item.id === selectedItem.id)
                        ) {
                            // Check if item exists in the items list
                            const index = selectedItems.findIndex(
                                (item) => item.id === selectedItem.id
                            );
                            if (index < 0) {
                                addSelectedItem(selectedItem);
                            }
                            const itemIndex = getFilteredItems().findIndex(
                                (item) => item.id === selectedItem.id
                            );
                            return {
                                ...changes,
                                highlightedIndex:
                                    type ===
                                    useCombobox.stateChangeTypes
                                        .InputKeyDownEnter
                                        ? itemIndex
                                        : -1,
                                isOpen: true,
                            };
                        }
                        // if item does not exist, return unchanged change
                        return changes;

                    case useCombobox.stateChangeTypes.InputBlur: {
                        setInputValue('');
                        if (selectItem && selectedItem) {
                            const index = selectedItems.findIndex(
                                (item) => item.id === selectedItem.id
                            );
                            if (index < 0) {
                                addSelectedItem(selectedItem);
                            }
                        }
                        return changes;
                    }
                    default:
                        return changes;
                }
            },
        });

        const filteredItems = isOpen ? getFilteredItems() : [];
        let previousGroupByValue = null;
        return (
            <div data-ds2="multi-select" className="tw-relative">
                <div
                    className={`tw-w-full tw-py-1.5 tw-pl-2 tw-pr-11 tw-rounded tw-ring-2 tw-ring-inset tw-flex tw-items-center tw-flex-wrap tw-gap-2 tw-min-h-[40px] ${props?.customClassName}
        ${
            error
                ? `tw-ring-theme-danger-500-300 focus-within:tw-shadow-input-error ${
                      disabled ? 'tw-ring-opacity-20' : ''
                  }`
                : `focus-within:tw-ring-theme-primary-500-300 focus-within:tw-shadow-input-focus ${
                      disabled
                          ? 'tw-ring-theme-neutral-300-700'
                          : 'tw-ring-theme-neutral-400-600'
                  }`
        }

        ${
            disabled
                ? 'tw-bg-theme-neutral-200-800 tw-cursor-not-allowed tw-text-theme-neutral-500-600'
                : 'tw-bg-theme-neutral-100-800 tw-text-theme-neutral-900-100'
        }
    `}
                >
                    {selectedItems.map((selectedItem, index) => (
                        <Badge
                            key={`badge_${selectedItem.id}_${index}`}
                            color={disabled ? 'neutral' : 'primary'}
                            icon={disabled ? undefined : ['far', 'times']}
                            iconPosition="right"
                            iconClick={() => {
                                removeSelectedItem(selectedItem);
                            }}
                            className={disabled ? 'tw-pointer-events-none' : ''}
                            label={`${
                                groupBy && !!groupBy(selectedItem)
                                    ? `${groupBy(selectedItem)} – `
                                    : ''
                            }${selectedItem.name}`}
                            style={{ height: 'auto' }}
                            labelClassName="tw-whitespace-break-spaces"
                        />
                    ))}

                    <div className="tw-flex-grow">
                        <input
                            {...getInputProps(
                                getDropdownProps({
                                    ...props,
                                    preventKeyAction: !allowKeyAction && isOpen,
                                    placeholder:
                                        selectedItems.length === 0
                                            ? placeholder
                                            : undefined,
                                    onPointerDown: !disabled
                                        ? openMenu
                                        : undefined,
                                    ref,
                                })
                            )}
                            className="tw-w-full tw-bg-transparent tw-outline-none tw-text-ellipsis tw-caret-neutral-600 dark:tw-caret-neutral-200 disabled:tw-cursor-not-allowed"
                            disabled={disabled}
                        />
                        <IconButtonFlat
                            {...getToggleButtonProps()}
                            aria-label="toggle menu"
                            icon="caret-down"
                            className="tw-absolute tw-top-1 tw-right-2"
                            disabled={disabled}
                        />
                    </div>
                </div>
                <ul
                    {...getMenuProps({ disabled, tabIndex: -1 })}
                    className="tw-absolute tw-w-full tw-bg-theme-neutral-100-800 tw-rounded tw-max-h-60 tw-overflow-auto tw-shadow-dropdown tw-z-[1]"
                >
                    {filteredItems.map((item, index) => {
                        const isNewGroup = groupBy
                            ? groupBy(item) !== previousGroupByValue
                            : false;
                        previousGroupByValue = groupBy?.(item);

                        const listItem = (
                            <li
                                key={`item_${item.id}_${index}`}
                                className={`tw-space-x-2 tw-px-4 tw-py-2 active:tw-bg-theme-primary-500-300 active:tw-bg-opacity-15 ${
                                    highlightedIndex === index
                                        ? 'tw-bg-theme-neutral-200-700'
                                        : ''
                                } ${groupBy?.(item) ? 'tw-pl-12' : ''}`}
                                {...getItemProps({ item, index })}
                            >
                                <Text
                                    font="body-md"
                                    color="hi-contrast"
                                    tag="span"
                                >
                                    {item.name}
                                </Text>
                                {item.subtext && (
                                    <Text
                                        font="body-sm"
                                        color="neutral-offset"
                                        tag="span"
                                    >
                                        {item.subtext}
                                    </Text>
                                )}
                            </li>
                        );

                        if (isNewGroup) {
                            return (
                                <Fragment key={`group_${item.id}_${index}`}>
                                    <li className="tw-space-x-2 tw-px-4 tw-py-2">
                                        <Text color="hi-contrast" font="h4">
                                            {groupBy(item)}
                                        </Text>
                                    </li>
                                    {listItem}
                                </Fragment>
                            );
                        }
                        return listItem;
                    })}
                    {isOpen &&
                        filteredItems.length === 0 &&
                        selectedItems.length > 0 && (
                            <li className="tw-px-4 tw-py-2">
                                <Text color="neutral-offset" font="body-md">
                                    No remaining options
                                </Text>
                            </li>
                        )}
                </ul>
            </div>
        );
    }
);

MultiSelect.defaultProps = {};
