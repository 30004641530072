import { useEffect, useState } from 'react';
import * as React from 'react';

// service
import { Organization } from '@api-interfaces';
import { organizationsService } from '@apis';

// components
import { FieldDropdown } from '@atoms/form-ds2/field-dropdown';
import { FieldInputText } from '@atoms/form-ds2/field-input-text';
import { UserPayload } from '@components/_new/_form/dropdowns/user-select/user-select.interface';
import { Button } from '@new/Button';

// interfaces

interface Props {
  onSubmit: (payload: UserPayload) => void;
  onCancel: () => void;
  className?: string;
  error?: boolean;
  provider?: number;
  selectedCustomer?: number;
  isUserFormOpen: boolean;
}

export const AddUserForm = ({
  onSubmit,
  onCancel,
  className = '',
  isUserFormOpen,
  error,
  provider = 1,
  selectedCustomer,
}: Props) => {
  const [email, setEmail] = useState<string>('');
  const [organization, setOrganization] = useState<Organization | null>(null);
  const [lastName, setLastName] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [organizations, setOrganizations] = useState<Organization[]>([]);

  useEffect(() => {
    // Make organization api call
    organizationsService.getOrganizations({ provider, limit: 1000 }).subscribe({
      next: (res) => {
        if (selectedCustomer) {
          const org = res.results.find((org) => org.id === selectedCustomer);
          if (org) {
            setOrganization(org);
          }
        }
        setOrganizations(res.results);
      },
    });
  }, []);

  function handleFirstName(e: React.ChangeEvent<HTMLInputElement>) {
    setFirstName(e.target.value);
  }

  function handleLastName(e: React.ChangeEvent<HTMLInputElement>) {
    setLastName(e.target.value);
  }

  function handleEmail(e: React.ChangeEvent<HTMLInputElement>) {
    setEmail(e.target.value);
  }

  function handleOrganization(org: Organization) {
    setOrganization(org);
  }

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const payload: UserPayload = {
      firstName,
      lastName,
      email,
      organization: organization?.id,
    };
    onSubmit(payload);
  }

  function handleCancel() {
    onCancel();
  }

  if (!isUserFormOpen) {
    return <></>;
  }

  return (
    <form onSubmit={handleSubmit}>
      <div
        className={`tw-flex tw-flex-col tw-p-3 tw-bg-neutral-100 dark:tw-bg-neutral-800 tw-shadow-dropdown tw-rounded ${className}`}
      >
        <div className="tw-flex tw-mb-3 tw-gap-2">
          <FieldInputText
            required
            id="new_user_first_name"
            label="First Name"
            error={!firstName.length && error && 'This field is required'}
            value={firstName}
            onChange={handleFirstName}
          />
          <FieldInputText
            required
            id="new_user_last-name"
            label="Last Name"
            error={!lastName.length && error && 'This field is required'}
            value={lastName}
            onChange={handleLastName}
          />
        </div>
        <div className="tw-flex tw-flex-col tw-mb-3">
          <FieldInputText
            required
            id="new_user_email"
            label="Email"
            error={!email.length && error && 'This field is required'}
            value={email}
            onChange={handleEmail}
          />
        </div>
        <div className="tw-flex tw-flex-col tw-mb-3">
          <FieldDropdown
            required
            id="new_user_organization"
            label="Organization"
            items={organizations}
            selectedItem={organization}
            onChange={handleOrganization}
            error={error && 'This field is required'}
            disabled={selectedCustomer && !!organization}
          />
        </div>
        <div className="tw-flex tw-justify-end tw-space-x-4">
          <Button label="Cancel" color="secondary" onClick={handleCancel} />
          <Button label="Save" color="primary" onClick={handleSubmit} />
        </div>
      </div>
    </form>
  );
};
