import { ScoringThumbProps } from './scoring-thumb.interfaces';

export const scoringThumbBgColor: {
	[Key in ScoringThumbProps['icon']]: string;
} = {
	'thumbs-up':
		'peer-checked:tw-bg-theme-success-700-300 peer-checked:tw-bg-opacity-15',
	'thumbs-down':
		'peer-checked:tw-bg-theme-danger-700-300 peer-checked:tw-bg-opacity-15',
};

export const scoringThumbColor: {
	[Key in ScoringThumbProps['icon']]: string;
} = {
	'thumbs-up': 'peer-checked:tw-text-theme-success-700-300',
	'thumbs-down': 'peer-checked:tw-text-theme-danger-500-300',
};
