import { useState, useEffect } from 'react';

// helpers
import { useBreakpoints } from '@core/hooks';
import { ImageBlock, ImageCarousel } from '@new';

// interfaces
import { ImageListProps } from './ImageList.interfaces';

export const ImageList = ({
    media,
    className,
    imageBlockClassName,
    overlayClassName,
    maxPictures,
    modalOpenCallback,
    onMediaClick: handleMediaClick,
}: ImageListProps) => {
    const [isModalOpen, _setModalOpen] = useState<boolean>(false);
    const [selectedImage, setSelectedImage] =
        useState<ImageListProps['media'][0]>(null);
    const breakpoint = useBreakpoints();
    const [sizedMaxPictures, setSizedMaxPictures] = useState(
        maxPictures || (breakpoint.md ? 5 : 3)
    );

    useEffect(() => {
        if (!maxPictures) {
            setSizedMaxPictures(breakpoint.md ? 5 : 3);
        }
    }, [breakpoint]);

    const setModalOpen = (isModalOpen: boolean) => {
        modalOpenCallback?.(isModalOpen);
        _setModalOpen(isModalOpen);
    };

    const onMediaClick = (item: ImageListProps['media'][0]) => () => {
        if (handleMediaClick) {
            handleMediaClick(item, media.indexOf(item));
        } else {
            setSelectedImage(item);
            setModalOpen(true);
        }
    };

    function handleClose() {
        setSelectedImage(null);
        setModalOpen(false);
    }

    return (
        <>
            <div className={`tw-flex tw-space-x-5 ${className}`}>
                {media
                    .filter((_, index) => index < sizedMaxPictures)
                    .map((imageMedia, index) => (
                        <ImageBlock
                            key={imageMedia.link ?? imageMedia.file_name}
                            imageUrl={imageMedia.link ?? imageMedia.file_name}
                            onClick={onMediaClick(imageMedia)}
                            mainClassName={`tw-cursor-pointer ${imageBlockClassName}`}
                            imageClassName="tw-rounded-md tw-bg-cover tw-bg-center"
                            overlayClassName={overlayClassName}
                            overlay={
                                index === sizedMaxPictures - 1 &&
                                media.length > sizedMaxPictures
                                    ? `+${media.length - sizedMaxPictures}`
                                    : undefined
                            }
                            tags={imageMedia?.tags}
                            scoreChip={
                                imageMedia.scoreChip
                                    ? {
                                          score:
                                              // no scorechip on sizedMaxPictures
                                              index === sizedMaxPictures - 1 &&
                                              media.length > sizedMaxPictures
                                                  ? undefined
                                                  : imageMedia.scoreChip.score,
                                          type:
                                              imageMedia.scoreChip.isPass ==
                                                  undefined ||
                                              imageMedia.scoreChip.score == null
                                                  ? 'neutral'
                                                  : imageMedia.scoreChip.isPass
                                                  ? 'success'
                                                  : 'danger',
                                      }
                                    : null
                            }
                        />
                    ))}
            </div>
            {isModalOpen && (
                <ImageCarousel
                    media={media}
                    selectedImage={selectedImage}
                    onClose={handleClose}
                />
            )}
        </>
    );
};

ImageList.defaultProps = {
    className: '',
    imageBlockClassName: 'tw-h-20 tw-w-20 xl:tw-h-[148px] xl:tw-w-[148px]',
    overlayClassName: 'tw-text-[32px] xl:tw-text-5xl',
};
