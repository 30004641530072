// interfaces
import { Params } from '@core/helpers';
import { virtualBrownClient } from '@services/django-client';

import { ConductSummary } from './interfaces';

// services

// helpers

// constants
const BASE_URL = `conducts/summary`;

class ConductSummaryService {
	getSummaries<K extends ConductSummary.Chart>(params: {
		chart: K[];
		from?: Date;
		to?: Date;
	}) {
		const query: any = {
			...params,
			chart: params.chart.join(','),
		};
		if (params.from) {
			query.from = params.from.toISOString();
		}
		if (params.to) {
			query.to = params.to.toISOString();
		}

		return virtualBrownClient.get<ConductSummary.SummaryReturnType[K][]>(
			`${BASE_URL}/${Params.makeQueryString(query)}`
		);
	}
}

export const conductSummaryService = new ConductSummaryService();
