// services

// interfaces
import { PurchaseOrder } from '@api-interfaces';

// services
import { GetPurchaseOrdersParams } from '@api-interfaces';
import { TimeUtils, Params } from '@helpers';
import { PaginationResponse } from '@models';
import { virtualBrownClient } from '@services/django-client';

import { financialPurchaseOrdersSummaryService } from './summary';

// helpers

// interfaces

const BASE_URL = 'financials/purchaseorders';

class FinancialPurchaseOrdersService {
	readonly summary = financialPurchaseOrdersSummaryService;

	getPurchaseOrders(params: GetPurchaseOrdersParams) {
		const query = {
			...params,
			from: TimeUtils.format(params.from, 'YYYY-MM-DD'),
			to: TimeUtils.format(params.to, 'YYYY-MM-DD'),
		};
		return virtualBrownClient.get<PaginationResponse<PurchaseOrder>>(
			`${BASE_URL}/${Params.makeQueryString(query)}`
		);
	}
}

export const financialPurchaseOrdersService =
	new FinancialPurchaseOrdersService();
