import { useLocation } from '@reach/router';
import { useState } from 'react';

// components
import { Tab } from '@atoms';

// assets

// interfaces
import { TabListProps } from './tablist.interfaces';

import './tablist.css';

export const TabList = ({
    tabs,
    isFullWidth,
    withActiveBorderBottom,
    withBorderBottom,
    type,
    size,
    className,
    ...props
}: TabListProps) => {
    const location = useLocation();

    const [activeIndex, setActiveIndex] = useState(null);

    function handleClick(index: number) {
        setActiveIndex(index);
    }

    const borderClass = withBorderBottom
        ? 'tablist-border-b tw-border-solid tw-border-b-2'
        : '';
    const borderActive = withActiveBorderBottom
        ? 'tablist-active-border-b'
        : '';
    const widthClass = isFullWidth ? 'tablist-w-full' : '';

    return (
        <ul
            role="tablist"
            className={`tw-overflow-x-auto tablist tablist-size-${size} tw-flex ${borderClass} ${borderActive} ${widthClass} ${type} ${className} ${
                type === 'stacked-tabs-top' ? 'tw-rounded-t-lg' : ''
            }`}
            {...props}
        >
            {tabs.map((tab, index) => {
                const { active, onClick = () => {}, path, ...tabProps } = tab;
                const slugs = location.pathname.split('/');
                const lastPath = path.split('/').splice(-1).join('');

                const matchPath = Boolean(
                    slugs.find((slug) => slug === lastPath)
                );

                return (
                    <Tab
                        key={tab.path}
                        path={path}
                        active={matchPath || active || index === activeIndex}
                        onClick={() => {
                            handleClick(index);
                            onClick();
                        }}
                        {...tabProps}
                    />
                );
            })}
            <li
                className={`tw-flex-grow tab ${type} ${
                    type === 'stacked-tabs-top' ? 'tw-rounded-t-lg' : ''
                }`}
            />
        </ul>
    );
};

TabList.defaultProps = {
    className: '',
    size: 'md',
    type: 'default',
    withBorderBottom: true,
};
