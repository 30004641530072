import { createCtx, TimeUtils } from '@helpers';

import { App } from './app.interfaces';
import { appReducer, defaultParentProgram } from './app.reducer';

const now = new Date();
const sixMonthsBeforeNow = TimeUtils.subMonths(now, 5);

export const initialAppState: App.State = {
	selectedOrgGroups: [],
	selectedOrgGroupTypes: [],
	selectedCustomers: [],
	selectedContracts: [],
	selectedAreaTags: [],
	selectedAreaTagGroups: [],
	selectedParentProgram: defaultParentProgram,
	selectedSiteGroups: [],
	selectedSiteGroupTypes: [],
	selectedPreset: null,
	parentPrograms: [],
	contractsGoalLines: [],
	organizationsGoalLines: [],
	isMultiClient: false,
	isSingleClient: false,
	isMultiSite: false,
	isSingleSite: false,
	isMultiSiteGroup: false,
	isSingleSiteGroup: false,
	user: null,
	dateRange: {
		from: sixMonthsBeforeNow,
		to: now,
		months: 6,
		range: '6mo',
	},
	isMenuOpen: false,
	isConnectionsPanelOpen: false,
	isPresentationMode: false,
	isModalStackOpen: false,
	isDs2: false,
	isSinglePage: false,
	logout: () => {},
};

export const [appContext, AppProvider] = createCtx(appReducer, initialAppState);

appContext.displayName = 'AppState';
