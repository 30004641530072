import { debounce } from 'lodash';
import { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import * as React from 'react';

// interfaces
import {
  BehavioralAuditEmployee,
  GetBehavioralAuditsEmployeesParams,
} from '@api-interfaces';

// services
import { behavioralAuditsService } from '@apis';

// components
import { Badge, Icon, Text, ModalStack } from '@atoms';
import { InputCheckbox } from '@atoms/form-ds2/input-checkbox';
import { InputSearch } from '@atoms/form-ds2/input-search';
import { Label } from '@atoms/form-ds2/label';
import { EmployeeDetails } from '@components/_new/MiniProfiles/_components/employee-details';
import { InfinityEdge } from '@components/InfinityEdge';

// helpers
import { sendError, TimeUtils } from '@helpers';
import { useAppContext, useRefetchService } from '@hooks';
import { Toggle, MiniProfile } from '@new';
import { Modal } from '@templates';

const LIMIT = 10;

const TOGGLE_BUTTONS = [
  { id: 'professionalism-audits', name: 'Professionalism Audits' },
  { id: 'gmp-audits', name: 'GMP Audits' },
];

const HEADERS: {
  [key: string]: GetBehavioralAuditsEmployeesParams['order_by'];
} = {
  Status: 'last_audit',
  'Date Audited': 'last_audit',
};

const trClassName =
  'tw-border-b tw-border-solid tw-border-neutral-300 dark:tw-border-neutral-600';
const tableElemClassName = 'tw-px-6 tw-py-4 tw-truncate';

interface Props {
  mode: 'professionalism-audits' | 'gmp-audits' | 'quick-entry';
  onClose: () => void;
  onSelectEmployee: (params: {
    type: Props['mode'];
    employee: BehavioralAuditEmployee;
  }) => void;
}

export const PersonnelReviewSelectEmployee = ({
  mode,
  onClose: handleClose,
  onSelectEmployee,
}: Props) => {
  const {
    state: { dateRange, user, selectedContracts },
  } = useAppContext();
  const [behavioralAuditEmployees, setBehavioralAuditEmployees] = useState<
    BehavioralAuditEmployee[]
  >([]);
  const [search, setSearch] = useState('');
  const [modalMode, setModalMode] = useState<Props['mode']>(mode);
  const [orderBy, setOrderBy] =
    useState<GetBehavioralAuditsEmployeesParams['order_by']>('name');
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isAuditedHidden, setIsAuditedHidden] = useState(false);

  // show toggle if user has create permission for both types
  const hasCreateBothPermissions =
    user.hasPermission(2, 'Professionalism Audits') &&
    user.hasPermission(2, 'GMP Audits');

  const tableContainerRef = useRef<HTMLDivElement>(null);
  const offset = useRef(0);

  const reset = useCallback(() => {
    offset.current = 0;
    setIsDisabled(false);
    setBehavioralAuditEmployees([]);
    setIsLoading(true);
  }, []);

  useRefetchService(reset);

  // set mode on load, as this modal can be launched anywhere from Quick Entry
  useEffect(() => {
    setModeFromToggleOrQuickEntry();
  }, []);

  useEffect(() => {
    if (!isLoading) return;

    const subscription = behavioralAuditsService
      .getBehavioralAuditsEmployees({
        from: dateRange.from,
        to: dateRange.to,
        limit: LIMIT,
        offset: offset.current,
        type: modalMode === 'gmp-audits' ? 'GMP' : 'Professionalism',
        order_by: orderBy,
        search,
      })
      .subscribe({
        next: (res) => {
          if (!res.next) {
            setIsDisabled(true);
          }
          offset.current += LIMIT;
          setBehavioralAuditEmployees([
            ...behavioralAuditEmployees,
            ...res.results,
          ]);
          setIsLoading(false);
        },
        error: sendError({
          toastMessage:
            'There was an error retrieving Behavioral Review Employees.',
          callback: () => {
            setIsDisabled(true);
            setIsLoading(false);
          },
        }),
      });
    return () => {
      subscription.unsubscribe();
    };
  }, [modalMode, isLoading]);

  const employees = useMemo(() => {
    if (isAuditedHidden) {
      return behavioralAuditEmployees.filter(
        (emp) => !emp.last_audit_date && !emp.exempt_count
      );
    }
    // sort by status: asc. is Audit, Exempt, then Not Audit; desc. is reversed
    let sortedBehavioralAuditEmployees = behavioralAuditEmployees;
    if (orderBy === 'last_audit' || orderBy === '-last_audit') {
      const employeesAudited = behavioralAuditEmployees.filter(
        (emp) => emp.last_audit_date && emp.exempt_count == 0
      );
      const employeesExempt = behavioralAuditEmployees.filter(
        (emp) => emp.exempt_count !== 0
      );
      const employeesNotAudited = behavioralAuditEmployees.filter(
        (emp) => emp.last_audit_date === null
      );

      if (orderBy === 'last_audit')
        sortedBehavioralAuditEmployees = employeesAudited.concat(
          employeesExempt,
          employeesNotAudited
        );
      else
        sortedBehavioralAuditEmployees = employeesNotAudited.concat(
          employeesExempt,
          employeesAudited
        );
    }
    return sortedBehavioralAuditEmployees;
  }, [isAuditedHidden, behavioralAuditEmployees]);

  const debouncedReset = useMemo(() => debounce(reset, 500), [reset]);

  const handleIntersectChange = () => {
    setIsLoading(true);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    debouncedReset();
  };

  const handleOrderByChange = (orderValue: typeof orderBy) => {
    return () => {
      if (orderValue === orderBy) {
        setOrderBy(`-${orderValue}` as typeof orderBy);
      } else {
        setOrderBy(orderValue);
      }
      reset();
    };
  };

  function handleToggleHideAudited() {
    setIsAuditedHidden(!isAuditedHidden);
  }

  function handleAuditTypeChange(label: typeof modalMode) {
    setModalMode(label);
    reset();
  }

  function handleSelectEmployee(employee: BehavioralAuditEmployee) {
    return () => {
      onSelectEmployee({ type: modalMode, employee });
    };
  }

  // if modalMode is set by feature page toggle, set that mode
  // else modalMode is quick-entry, set mode that matches user.hasPermission OR use location
  function setModeFromToggleOrQuickEntry() {
    if (modalMode === 'professionalism-audits' || modalMode === 'gmp-audits') {
      return modalMode;
    }
    if (
      (!user.hasPermission(2, 'Professionalism Audits') &&
        user.hasPermission(2, 'GMP Audits')) ||
      location.pathname.includes('gmp-audits')
    ) {
      setModalMode('gmp-audits');
      return 'gmp-audits';
    }
    setModalMode('professionalism-audits');
    return 'professionalism-audits';
  }

  return (
    <ModalStack position="center" onOverlayClick={handleClose}>
      <Modal.Generic
        title="Select Employee"
        titleFontSize="h2"
        onCancel={handleClose}
        className="tw-flex tw-flex-col tw-mx-auto tw-h-full tw-w-full
                        tw-max-h-[100vh] sm:tw-max-h-[800px] xs:tw-max-w-[360px] sm:tw-max-w-[680px]"
      >
        {hasCreateBothPermissions && (
          <Toggle
            labels={TOGGLE_BUTTONS}
            active={setModeFromToggleOrQuickEntry()}
            onClick={handleAuditTypeChange}
            className="tw-mb-4"
          />
        )}

        <div
          className="tw-mb-4 tw-space-y-4
                sm:tw-space-y-0 sm:tw-flex sm:tw-items-center sm:tw-justify-between"
        >
          <InputSearch
            className="tw-max-w-xs tw-w-full"
            onChange={handleSearchChange}
            value={search}
            onClearSearch={() => {
              setSearch('');
              reset();
            }}
          />
          <div className="tw-flex">
            <InputCheckbox
              id="hide_audited_employees"
              className="tw-cursor-pointer tw-mt-0.5 tw-mr-2"
              onChange={handleToggleHideAudited}
            />
            <Label
              label="Hide Audited Employees"
              htmlFor="hide_audited_employees"
              className="tw-cursor-pointer"
            />
          </div>
        </div>

        <div ref={tableContainerRef} className="tw-overflow-auto tw-flex-1">
          <table className="tw-w-full tw-text-left">
            <thead>
              <tr className={trClassName}>
                <th className={tableElemClassName}>
                  <div className="tw-flex">
                    <Text
                      font="h4"
                      color="neutral-offset"
                      className="tw-whitespace-nowrap"
                    >
                      Associate
                    </Text>
                    <Icon
                      icon={`${orderBy}` === 'name' ? 'caret-up' : 'caret-down'}
                      size="lg"
                      className="tw-text-theme-neutral-600-500 tw-ml-2 tw-cursor-pointer"
                      onClick={handleOrderByChange('name')}
                    />
                  </div>
                </th>
                {modalMode === 'gmp-audits' &&
                  Object.entries(HEADERS).map(([header, orderValue]) => {
                    return (
                      <th
                        key={header}
                        className={
                          header === 'Date Audited'
                            ? `tw-text-right tw-pl-6 tw-py-4 tw-truncate`
                            : tableElemClassName
                        }
                      >
                        <div className="tw-flex">
                          <Text
                            font="h4"
                            color="neutral-offset"
                            className="tw-whitespace-nowrap"
                          >
                            {header}
                          </Text>
                          <Icon
                            icon={
                              orderValue === `${orderBy}`
                                ? 'caret-up'
                                : 'caret-down'
                            }
                            size="lg"
                            className="tw-text-theme-neutral-600-500 tw-ml-2 tw-cursor-pointer"
                            onClick={handleOrderByChange(orderValue)}
                          />
                        </div>
                      </th>
                    );
                  })}
              </tr>
            </thead>
            <tbody>
              {employees.map((employee) => (
                <tr
                  key={employee.id}
                  className={`tw-cursor-pointer ${trClassName}`}
                  onClick={handleSelectEmployee(employee)}
                >
                  <td className={tableElemClassName}>
                    <MiniProfile.Md
                      person={employee.person}
                      detail={
                        <EmployeeDetails
                          jobTitle={employee?.positions[0]?.name ?? ''}
                          client={selectedContracts[0]?.customer_name ?? ''}
                          site={employee?.contract_name ?? ''}
                          hiredDate={
                            employee?.hired_date
                              ? new Date(employee.hired_date)
                              : null
                          }
                        />
                      }
                      style={{ minWidth: 'min-content' }}
                    />
                  </td>
                  {modalMode === 'gmp-audits' && (
                    <>
                      <td className={tableElemClassName}>
                        <Badge
                          label={
                            employee.exempt_count > 0
                              ? 'Exempt'
                              : TimeUtils.isThisMonth(employee.last_audit_date)
                              ? 'Audited'
                              : 'Not Audited'
                          }
                          color={
                            employee.exempt_count > 0
                              ? 'neutral'
                              : TimeUtils.isThisMonth(employee.last_audit_date)
                              ? 'success'
                              : 'danger'
                          }
                        />
                      </td>
                      <td className={`tw-text-right ${tableElemClassName}`}>
                        {employee.last_audit_date && (
                          <Text font="body-md" color="hi-contrast">
                            {TimeUtils.format(
                              employee.last_audit_date,
                              'MM/DD/YYYY'
                            )}
                          </Text>
                        )}
                      </td>
                    </>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
          <InfinityEdge
            onIntersect={handleIntersectChange}
            isLoading={isLoading}
            isDisabled={isDisabled}
            root={tableContainerRef.current}
          />
        </div>
      </Modal.Generic>
    </ModalStack>
  );
};
