import { WorkOrderStatusKey, WorkOrderPayload } from '@api-interfaces';
import { createCtx } from '@core/helpers';

import {
  WorkOrderFormAction,
  WorkOrderFormActionType,
  WorkOrderFormContextState,
} from './workorder-interface';

export const workOrderFormState: WorkOrderFormContextState = {
  form: {
    contract: {
      id: null,
    },
    assigned_date: new Date(),
    title: '',
    description: '',
    summary: '',
    status: {
      id: 147, // Initialized to new
    },
    reporter: {
      id: null,
    },
    assigned_to: {
      id: null,
    },
    created_by: {
      id: null,
    },
    contact_number_country_code: '',
    contact_number: '',
    due_date: null,
    location: {
      id: null,
    },
    location_name: '',
    service: {
      id: null,
    },
    service_type: {
      id: null,
    },
    priority: {
      id: null, // default to "Normal" priority later (don't want to hard code ids)
    },
    equipment: null,
    is_in_scope: false, // default to "Not in Scope"
    is_billable: true, // default to "Is Billable"
    is_production_effected: false,
    quantity: null,
    unitcost: null,
    po_number: null,
    billing_description: '',
    work_type: null,
    work_order_status: WorkOrderStatusKey.SUBMITTED,
    media: [],
    todos: [],
  } as WorkOrderPayload,
};

export const workOrderFormReducer = (
  state: WorkOrderFormContextState,
  action: WorkOrderFormAction
): Readonly<WorkOrderFormContextState> => {
  switch (action.type) {
    case WorkOrderFormActionType.SET_WORK_ORDER_FORM:
      return {
        ...state,
        form: {
          ...state.form,
          ...action.payload,
        },
      };
  }
};

export const [workOrderFormContext, WorkOrderFormProvider] = createCtx(
  workOrderFormReducer,
  workOrderFormState
);
