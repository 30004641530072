import { useState } from 'react';

// interfaces
import { Employee } from '@api-interfaces';

// components
import { Text } from '@atoms';
import { useBreakpoints } from '@hooks';
import { MiniProfile } from '@new';

// helpers

interface Props {
  employees: Employee[];
}

export const Employees = ({ employees }: Props) => {
  const [showOverflow, setShowOverflow] = useState<boolean>(false);
  const bp = useBreakpoints();
  const displayCount = !bp.lg && (bp.md || bp.sm) ? 4 : 2;
  const uniqueCaseCount = bp.sm ? 2 : 1;
  const showUniqueCase = uniqueCaseCount === employees.length;
  const displayEmployees =
    showOverflow || showUniqueCase
      ? employees
      : employees.slice(0, displayCount);
  const overflowCount = showUniqueCase
    ? 0
    : employees.length - displayCount > 0
    ? employees.length - displayCount
    : 0;

  return (
    <div className="tw-relative">
      <Text font="h4" color="hi-contrast" className="tw-mb-4">
        Accountable Employees
      </Text>
      {employees?.length ? (
        <>
          {showOverflow && (
            <Text
              font="h4"
              color="primary"
              className="tw-absolute tw-top-0 tw-right-0 tw-cursor-pointer"
              onClick={() => setShowOverflow(false)}
            >
              View Less
            </Text>
          )}

          <div className="tw-w-full tw-flex tw-justify-between tw-items-center">
            <div className="tw-flex tw-flex-wrap tw-gap-2">
              {displayEmployees.map((employee, i) => (
                <MiniProfile.Md
                  key={i}
                  person={employee.person}
                  detail={employee.positions[0]?.name}
                  className="tw-w-[175px]"
                />
              ))}
            </div>
            {Boolean(overflowCount && !showOverflow) && (
              <div
                className="tw-cursor-pointer
                                    tw-h-9 tw-w-9
                                    tw-flex tw-justify-center tw-items-center
                                    tw-rounded-full tw-bg-primary-500"
                onClick={() => setShowOverflow(true)}
              >
                <Text font="body-lg" color="white">
                  {`+${overflowCount}`}
                </Text>
              </div>
            )}
          </div>
        </>
      ) : (
        <div className="tw-w-full">
          <Text font="body-md" color="neutral-offset">
            None
          </Text>
        </div>
      )}
    </div>
  );
};
