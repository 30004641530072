import { Fragment } from 'react';

// helpers
import { escapeCharactersRegex } from './strings/escapeCharactersRegex';

export function highlightText({
  text,
  match,
}: {
  text: string;
  match: string;
}) {
  if (!match || !text) return text;
  const escapedMatch = escapeCharactersRegex(match);
  const regex = new RegExp(escapedMatch, 'ig');
  const matches = Array.from(text.matchAll(regex));
  const textParts = text.split(regex);
  const firstParts = textParts.slice(0, textParts.length - 1);
  const lastPart = textParts[textParts.length - 1];
  if (firstParts.length) {
    const highlighter = firstParts.map((part, index) => (
      <Fragment key={index}>
        {part}
        <mark className="tw-bg-theme-primary-500-300 tw-text-neutral-100">
          {matches[index]}
        </mark>
      </Fragment>
    ));
    const combinedParts = (
      <>
        {highlighter}
        {lastPart}
      </>
    );
    return combinedParts;
  }
  return text;
}
