import { Observable } from 'rxjs';

// services
import { AreaTag } from '@api-interfaces';
import { Params } from '@helpers';
import { PaginationParams, PaginationResponse } from '@models';
import { virtualBrownClient } from '@services';

// helpers

// interfaces

const BASE_URL = 'areatags';

class AreaTagService {
	getAreaTags(
		params?: {
			customer?: number | string;
			contract?: number | string;
			group?: number;
		} & PaginationParams
	): Observable<PaginationResponse<AreaTag>> {
		const queryString = Params.makeQueryString(params);
		return virtualBrownClient.get(`${BASE_URL}/${queryString}`, {
			noContract: true,
			noCustomer: true,
			noTag: true,
		});
	}
}

export const areaTagService = new AreaTagService();
