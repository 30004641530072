import { forwardRef } from 'react';

import { Icon } from '@atoms';

// helpers
import { IconButtonProps } from './icon-button.interfaces';
import { iconButtonTypeMap } from './icon-button.utils';

// interfaces

export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  ({ icon, type, className, iconClassName, iconSize, ...props }, ref) => {
    return (
      <button
        ref={ref}
        data-ds2="icon-button"
        type="button"
        className={`tw-h-10 tw-w-10 tw-grid tw-place-items-center tw-rounded ${iconButtonTypeMap[type]} ${className}`}
        {...props}
      >
        <Icon className={iconClassName} icon={icon} size={iconSize} />
      </button>
    );
  }
);

IconButton.defaultProps = {
  type: 'primary',
  className: '',
  iconSize: 'lg',
};
