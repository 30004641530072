import { Complaint, Service } from '@api-interfaces';
import { ServiceIssueForm } from '@modals/_ds2/service-issue-form';

import { useWorkOrderModalContext } from '../../../../_context';

// components

// interfaces

export const EditServiceIssue = () => {
  const {
    workOrder,
    setWorkOrder,
    workOrderEdit,
    setWorkOrderEdit,
    activeModals,
    setActiveModals,
    selectedServiceIssue,
    setSelectedServiceIssue,
    hasBeenUpdated,
  } = useWorkOrderModalContext();

  function handleSuccess(complaint: Complaint) {
    const workOrderCopy: typeof workOrderEdit = {
      ...workOrderEdit,
    };
    const complaintIndex = workOrderCopy.complaints.findIndex(
      (t) => t.id === complaint.id
    );
    if (complaintIndex !== -1) {
      workOrderCopy.complaints[complaintIndex] = complaint;
    }
    setWorkOrder(workOrderCopy);
    setWorkOrderEdit(workOrderCopy);
    hasBeenUpdated.current = true;
  }

  function handleCancel() {
    setActiveModals({
      ...activeModals,
      'editing-service-issue': false,
    });
    setSelectedServiceIssue(null);
  }

  return (
    activeModals['editing-service-issue'] && (
      <ServiceIssueForm
        serviceIssue={selectedServiceIssue}
        service={
          (workOrderEdit.service || workOrder.service) as Pick<
            Service,
            'id' | 'name'
          >
        }
        closeModal={handleCancel}
        onSuccess={handleSuccess}
      />
    )
  );
};
