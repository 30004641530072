import { Observable } from 'rxjs';

// services
import {
	Complaint,
	ComplaintPayload,
	ComplaintsFrontPage,
	GetComplaintsParams,
} from '@api-interfaces';
import { referenceService } from '@apis';
import { Params, TimeUtils } from '@core/helpers';
import { PaginationResponse, CommonParams } from '@models';
import { virtualBrownClient } from '@services/django-client';

import { complaintsSummaryService } from './summary';

// helpers

// interfaces

const BASE_URL = `complaints`;

const zone = Intl.DateTimeFormat().resolvedOptions().timeZone;

class ComplaintsService {
	readonly summary = complaintsSummaryService;

	public getComplaints = (params?: GetComplaintsParams) => {
		const query: any = {
			...params,
		};

		if (params.from) {
			query.from = params.from.toISOString();
		}
		if (params.to) {
			query.to = params.to.toISOString();
		}

		return virtualBrownClient.get<PaginationResponse<Complaint>>(
			`${BASE_URL}/${Params.makeQueryString(query)}`,
			{
				noCustomer: params.customer != undefined,
				noContract: params.contract != undefined,
			}
		);
	};

	public createComplaint = (complaintBody: ComplaintPayload) => {
		return virtualBrownClient.post<Complaint>(`${BASE_URL}/`, complaintBody);
	};

	public getComplaintByComplaintId = (complaintId) => {
		return virtualBrownClient.get<Complaint>(`${BASE_URL}/${complaintId}/`, {
			noContract: true,
			noCustomer: true,
		});
	};

	public updateComplaintByComplaintId = (complaintId, complaintBody?) => {
		return virtualBrownClient.put<Complaint>(
			`${BASE_URL}/${complaintId}/`,
			complaintBody
		);
	};

	public patchComplaintByComplaintId = (complaintId, complaintBody?) => {
		return virtualBrownClient.patch<Complaint>(
			`${BASE_URL}/${complaintId}/`,
			complaintBody
		);
	};

	public deleteComplaintByComplaintId = (
		complaintId: number
	): Observable<any> => {
		return virtualBrownClient.delete(`${BASE_URL}/${complaintId}/`);
	};

	public getComplaintsSummary = (
		params?: {
			from: any;
			to: any;
			time_zone?: string;
			chart_type?: number;
			chart?: string;
			tag_group?: number;
			service?: number;
			top_n_threshold_to_other?: number;
		} & CommonParams
	): Observable<any> => {
		const query = {
			...params,
			from: TimeUtils.formatToISO(params.from),
			to: TimeUtils.formatToISO(params.to),
			time_zone: zone,
		};

		const queryString = Params.makeQueryString(query);

		return virtualBrownClient.get(`${BASE_URL}/summary/${queryString}`);
	};

	public getComplaintTypes = () =>
		referenceService.getItems({ group_code: 'Complaint_Types' });

	public getClassificationTypes = () =>
		referenceService.getItems({
			group_code: 'Complaint_Classifications',
		});

	public getComplaintsSummaryFrontPage = (
		params
	): Observable<ComplaintsFrontPage> => {
		const query = {
			from: TimeUtils.formatToISO(params.from),
			to: TimeUtils.formatToISO(params.to),
			time_zone: zone,
		};
		return virtualBrownClient.get(
			`${BASE_URL}/summary/frontpage/${Params.makeQueryString(query)}`
		);
	};
}

export const complaintsService = new ComplaintsService();
