import * as fasSolid from './fa-solid';
import * as faRegular from './fa-regular';
import * as faLight from './fa-light';
import * as faThin from './fa-thin';
import * as faDuotone from './fa-duotone';

export const fas = Object.values(fasSolid);
export const far = Object.values(faRegular);
export const fal = Object.values(faLight);
export const fat = Object.values(faThin);
export const fad = Object.values(faDuotone);
