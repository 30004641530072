// Two stack implementation
export class Queue<T> {
	readonly enqueueStack: T[];

	readonly dequeueStack: T[];

	constructor() {
		this.enqueueStack = [];
		this.dequeueStack = [];
	}

	public isEmpty = () => {
		return this.dequeueStack.length === 0 && this.enqueueStack.length === 0;
	};

	public enqueue = (item: T) => {
		this.enqueueStack.push(item);
	};

	public dequeue = () => {
		if (this.isEmpty()) {
			throw new Error('Queue is empty.');
		}
		this.switchStacks();
		return this.dequeueStack.pop();
	};

	public peek = () => {
		if (this.isEmpty()) {
			return undefined;
		}
		this.switchStacks();
		return this.dequeueStack[this.dequeueStack.length - 1];
	};

	private switchStacks = () => {
		if (this.dequeueStack.length === 0) {
			while (this.enqueueStack.length !== 0) {
				this.dequeueStack.push(this.enqueueStack.pop());
			}
		}
	};
}

// Queues items in order, but any subsequent items enqueued with the same key removes its old entry
export class LeastRecentKeyQueue<K, V> {
	readonly actionMap: Map<K, V>;

	constructor() {
		this.actionMap = new Map<K, V>();
	}

	public isEmpty = () => {
		return this.actionMap.size === 0;
	};

	public enqueue = (key: K, value: V) => {
		this.actionMap.delete(key);
		this.actionMap.set(key, value);
	};

	public dequeue = () => {
		if (this.isEmpty()) {
			throw new Error('Queue is empty.');
		}
		for (const [key, value] of this.actionMap) {
			this.actionMap.delete(key);
			return value;
		}
	};

	public peek = () => {
		if (this.isEmpty()) {
			return undefined;
		}
		for (const [_, value] of this.actionMap) {
			return value;
		}
	};
}
