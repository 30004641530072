import { Environment } from './environment.interface';

const DOMAIN = '4insite.com';

// default to bay3 since it's normally our "Maintenance Bay"
let build_pythonUrl = `https://bay28.${DOMAIN}/api/`;
let build_cloudfrontUrl = `https://devcdn.${DOMAIN}`;
let build_verifiction_AI_Url = `https://72agerpop2.execute-api.us-west-2.amazonaws.com/test/edit-verification`;

const mode = import.meta.env.MODE;

let hostEnv = window.location.href;

if (mode === 'development') {
	hostEnv = import.meta.env.VITE_HOST_ENV;
}

const bayTest = /bay/i.test(hostEnv);
const qaTest = /qa/i.test(hostEnv);
const localTest = /^local(\d+)?$/i.test(hostEnv);
const demoTest = /demo/i.test(hostEnv);

if (mode === 'production' && !bayTest && !qaTest && !localTest && !demoTest) {
	hostEnv = 'production';
}

const getSubdomain = (url) => {
	let domain = url;
	if (url.includes('://')) {
		domain = url.split('://')[1];
	}
	const subdomain = domain.split('.')[0];
	return subdomain;
};

switch (true) {
	case hostEnv === 'production': {
		build_pythonUrl = `https://api.${DOMAIN}/api/`;
		build_cloudfrontUrl = `https://cdn.${DOMAIN}`;
		build_verifiction_AI_Url = `https://g9v08u6d7f.execute-api.us-west-2.amazonaws.com/prod/edit-verification`;
		break;
	}
	case hostEnv === 'local': {
		build_pythonUrl = 'http://localhost:8000/api/';
		build_cloudfrontUrl = `https://devcdn.${DOMAIN}`;
		break;
	}
	case hostEnv === 'demo': {
		build_pythonUrl = `https://demo.${DOMAIN}/api/`;
		build_cloudfrontUrl = `https://cdn.${DOMAIN}`;
		break;
	}
	default: {
		const isBay = bayTest;
		const isQA = qaTest;
		const isDemo = demoTest;

		// build_cloudfrontUrl = `https://cdn.${DOMAIN}`;
		if (isBay || isQA || isDemo) {
			const subdomain = getSubdomain(hostEnv);
			build_pythonUrl = `https://${subdomain}.${DOMAIN}/api/`;
		}
		if (isQA) {
			// build_cloudfrontUrl = `https://cdn.${DOMAIN}`;
			// build_cloudfrontUrl = `https://qacdn.${DOMAIN}`;
			build_cloudfrontUrl = `https://devcdn.${DOMAIN}`;
		}
		break;
	}
}

const env: Environment = {
	insite: {
		domain: DOMAIN,
		pythonUrl: build_pythonUrl,
	},
	aws: {
		cloudfront: {
			url: build_cloudfrontUrl,
			signedUrl: build_cloudfrontUrl,
			keyPairId: 'APKAIKXCKAHTNENKCPFQ',
		},
	},
	google: {
		translate: {
			key: 'AIzaSyAscpkuiUgOUrUiMJCZgxkP1FwfIO9wVD8',
		},
		maps: {
			key: 'AIzaSyAscpkuiUgOUrUiMJCZgxkP1FwfIO9wVD8',
			adminKey: 'AIzaSyCwbv-FciWMJGE_8fFrzk23ZKh_MowelkY',
		},
	},
	mixpanel: {
		dev: {
			key: '61e2949b14060f08f9d0111f2fd05979',
		},
		prod: {
			key: '54f1217a35e3afe894fcac24b2a140e8',
		},
	},
	froala: {
		editorKey:
			'BWC6D-16B3C1B2F2E1A9vb2EAJLQCVLUVBd1NXNRSSATEXC-8C2H2C4C3B6B2E1C4B1D1==',
		pageKey:
			'UDtqrkOF3gC8C10B5A5D2G3C-8TMIBDIa1NTMNZFFPFZc1d1Ib2a1E1fA4A3G3A2F2B6C3C4D3F3==',
	},
	datadog: {
		appId: 'a5464e1a-6ecc-43c4-b6a3-1572f1399813',
		clientToken: 'pub985d26b902de47c86a2e96b960897fd8',
	},
	verificationsReview: build_verifiction_AI_Url,
};
export default env;
