// interfaces
import { NonService, NonServicePayload } from '@api-interfaces';

// services
import { virtualBrownClient } from '@core/services/django-client';

// helpers
import { Params } from '@helpers';
import { PaginationParams, PaginationResponse } from '@models';

// constants
const BASE_URL = 'services/nonservices';

const noOpts = {
	noProvider: true,
	noCustomer: true,
	noContract: true,
	noTag: true,
};

class NonServicesService {
	public getNonServices(
		params?: {
			contract?: number;
			admin_landing?: boolean;
			ordering?: 'start_date';
			from?: string; // e.g. "2023-01-01"
			to?: string;
		} & PaginationParams,
		settings?: {
			noOptions?: boolean;
		}
	) {
		const queryString = Params.makeQueryString(params);
		return virtualBrownClient.get<PaginationResponse<NonService>>(
			`${BASE_URL}/${queryString}`,
			settings && settings.noOptions ? noOpts : {}
		);
	}

	public getNonServiceById(keys: { id: number }) {
		return virtualBrownClient.get<NonService>(
			`${BASE_URL}/${keys.id}/`,
			noOpts
		);
	}

	public createNonService(payload: NonServicePayload) {
		return virtualBrownClient.post<NonService>(`${BASE_URL}/`, payload);
	}

	public updateNonService(keys: { id: number }, payload: NonServicePayload) {
		return virtualBrownClient.put<NonService>(
			`${BASE_URL}/${keys.id}/`,
			payload
		);
	}

	public deleteNonService(keys: { id: number }) {
		return virtualBrownClient.delete(`${BASE_URL}/${keys.id}/`);
	}

	public updateNonServiceBulk(payload: NonServicePayload[]) {
		return virtualBrownClient.patch<{ results: NonService[] }>(
			`${BASE_URL}/bulk/`,
			payload
		);
	}
}

export const nonservicesService = new NonServicesService();
