// interfaces
import { Observable } from 'rxjs';

// services
import {
	CustomerSurveysSummary,
	CustomerSurveysSummaryParams,
} from '@api-interfaces';
import { virtualBrownClient } from '@core/services/django-client';

// helpers
import { Params } from '@helpers';

// constants
const BASE_URL = 'customersurveys/summary';
const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

class CustomerSurveysSummaryService {
	getSummaries<T extends CustomerSurveysSummary.Chart>(
		params: CustomerSurveysSummaryParams<T>
	): Observable<CustomerSurveysSummary.SummaryReturnType[T][]> {
		const query = {
			...params,
			chart: params.chart.join(','),
			time_zone: params.time_zone || timeZone,
		};

		return virtualBrownClient.get(
			`${BASE_URL}/${Params.makeQueryString(query)}`,
			{
				noCustomer: params.customer !== undefined,
				noContract: params.contract !== undefined,
				noTag: params.tag !== undefined,
			}
		);
	}
}

export const customerSurveysSummaryService =
	new CustomerSurveysSummaryService();
