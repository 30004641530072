import { BoundingBoxData } from '@api-interfaces';
import { createPortal } from 'react-dom';

interface Props {
    boxes: BoundingBoxData[];
}

export const PhotoViewerBoundingBoxes = ({ boxes }: Props) => {
    const boxPortal = document.getElementById('bounding-boxes-portal');

    if (!boxes || !boxPortal) return null;

    return createPortal(
        boxes.map((box, index) => (
            <div
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                className="tw-absolute tw-border-solid tw-border-danger-500 tw-box-content"
                style={{
                    borderWidth: 2,
                    top: box.bbox[1] - 2,
                    left: box.bbox[0] - 2,
                    width: box.bbox[2] - box.bbox[0],
                    height: box.bbox[3] - box.bbox[1],
                }}
            />
        )),
        boxPortal as Element
    );
};
