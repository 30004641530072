import { debounce, drop } from 'lodash';
import { useMemo } from 'react';
import * as React from 'react';
import { Media } from '@api-interfaces';
import { Text } from '@atoms';
import { truncate } from '@helpers';
import { FieldInputText } from '@atoms/form-ds2/field-input-text';
import { Label } from '@atoms/form-ds2/label';
import { AddAttachment } from '@components/_new/_form/attachments';
import { ImageList } from '@new';
import { ImageListMedia } from '@new/ImageList/ImageList.interfaces';
import { awsService } from '@core/services';
import { useWorkOrderModalContext } from '../../../../_context';

export const WorkOrderOverview = () => {
    const {
        workOrder,
        isEditing,
        setWorkOrderEdit,
        workOrderEdit,
        submissionAttempt,
    } = useWorkOrderModalContext();

    const allMedia = useMemo(() => {
        if (!workOrder) return [];

        const images: ImageListMedia[] = [];

        workOrder.media_list.forEach((media) => {
            images.push({
                ...media,
                origin: `Work Order #${workOrder.id}`,
                tags: [media.is_after_work_order ? 'After' : 'Before'],
            });
        });

        workOrder?.todos?.forEach((todo) => {
            todo.media_list.forEach((media) => {
                images.push({ ...media, origin: `Todo #${todo.id}` });
            });
        });

        return images;
    }, [workOrder]);

    const handleFormChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        if (workOrderEdit?.id) {
            setWorkOrderEdit({
                ...workOrderEdit,
                [name]: value,
            });
        }
    };

    const debounceFormChange = debounce(handleFormChange, 500);

    const handleMediaUpload = (media: Media) => {
        if (!workOrderEdit) return; // Check if both are null
        const mediaCopy: (typeof workOrderEdit)['media_list'][0] = {
            ...media,
            is_after_work_order: true,
            workorder_media_type: media.type,
        };
        if (workOrderEdit?.id) {
            setWorkOrderEdit({
                ...workOrderEdit,
                media_list: workOrderEdit.media_list.concat(mediaCopy),
            });
        }
    };

    const handleMediaDelete = (mediaId: number) => {
        const filteredMediaList = workOrderEdit?.media_list?.filter(
            (media) => media.id !== mediaId
        );
        if (workOrderEdit?.id) {
            setWorkOrderEdit({
                ...workOrderEdit,
                media_list: filteredMediaList?.length ? filteredMediaList : [],
            });
        }
    };

    const handleMediaUpdate = (data: { id: number; status: string }) => {
        if (workOrderEdit?.media_list) {
            const attachmentsCopy = [...workOrderEdit.media_list];
            const mediaIndex = attachmentsCopy.findIndex(
                (media) => media.id === data.id
            );
            if (mediaIndex !== -1) {
                attachmentsCopy[mediaIndex].is_after_work_order =
                    data.status === 'after';
            }

            setWorkOrderEdit({
                ...workOrderEdit,
                media_list: attachmentsCopy,
            });
        }
    };

    const checkExt = (image) => {
        const exts = [
            'jpg',
            'JPG',
            'jpeg',
            'JPEG',
            'png',
            'PNG',
            'jfif',
            'JFIF',
        ];
        // 154079 to view the images attahced from corrigo site we will check on mime type Image to view it as images
        if (image.mime_type == 'Image' && image.link) {
            return image;
        } else {
            return exts.some((ext) => image.file_name.endsWith(ext));
        }
    };

    const getIconType = (attachment: { file_name: string }): string => {
        const pdxRegex = /pdf/i;
        const docRegex = /doc|docx/i;
        const pptRegex = /ppt|pptx/i;
        const excelRegex = /xls|xlsx/i;
        let icon = '';
        switch (true) {
            case pdxRegex.test(attachment.file_name): {
                icon = 'fa-file-pdf';
                break;
            }
            case docRegex.test(attachment.file_name): {
                icon = 'fa-file-word';
                break;
            }
            case pptRegex.test(attachment.file_name): {
                icon = 'fa-file-powerpoint';
                break;
            }
            case excelRegex.test(attachment.file_name): {
                icon = 'fa-file-excel';
                break;
            }
            default: {
                icon = 'fa-file';
                break;
            }
        }
        return icon;
    };

    return (
        <div>
            <Text className="tw-mb-6" font="h2" color="primary">
                Overview
            </Text>
            {!isEditing ? (
                <div className="tw-space-y-6 tw-break-words">
                    <div>
                        <Text className="tw-mb-4" font="h3" color="hi-contrast">
                            Title
                        </Text>
                        <Text font="body-lg" color="hi-contrast">
                            {workOrder?.title ?? 'N/A'}
                        </Text>
                    </div>
                    <div>
                        <Text className="tw-mb-4" font="h3" color="hi-contrast">
                            Description
                        </Text>
                        <Text font="body-lg" color="hi-contrast">
                            {workOrder?.description ?? 'N/A'}
                        </Text>
                    </div>
                </div>
            ) : (
                <div className="tw-space-y-6 tw-whitespace-pre-wrap">
                    <FieldInputText
                        required
                        id="work_order_title"
                        label="Title"
                        name="title"
                        defaultValue={workOrderEdit?.title ?? ''}
                        onChange={debounceFormChange}
                        error={
                            submissionAttempt &&
                            workOrderEdit?.title?.length === 0
                                ? 'This field is required'
                                : undefined
                        }
                    />
                    <FieldInputText
                        required
                        fieldType="textarea"
                        id="work_order_description"
                        label="Description"
                        name="description"
                        defaultValue={workOrderEdit?.description ?? ''}
                        onChange={debounceFormChange}
                        max={4096}
                        rows={10}
                        error={
                            submissionAttempt &&
                            workOrderEdit?.description?.length === 0
                                ? 'This field is required'
                                : undefined
                        }
                    />
                    <AddAttachment
                        buttonProps={{ label: 'Add Attachment' }}
                        className="tw-w-full"
                        onUpload={handleMediaUpload}
                        onDelete={handleMediaDelete}
                        title="Add Attachment (Optional)"
                        onMediaUpdate={handleMediaUpdate}
                        attachments={
                            workOrderEdit?.media_list?.map((m) => ({
                                ...m,
                                isAfter: m.is_after_work_order,
                            })) ?? []
                        }
                    />
                </div>
            )}
            {!isEditing && allMedia.length > 0 && (
                <div className="tw-flex tw-gap-4 tw-flex-col tw-mt-6">
                    <Label label="Attachments" font="body-lg" />
                    <ImageList
                        media={allMedia.filter((img) => checkExt(img))}
                    />
                    <div>
                        {allMedia
                            .filter((media) => !checkExt(media))
                            .map((file) => {
                                const fileName = drop(
                                    file.file_name.split('_'),
                                    1
                                ).join('_');
                                const fileNameJSX = (
                                    <div className="tw-mt-2">
                                        {truncate(fileName, 20)}
                                    </div>
                                );
                                const icon = getIconType(file);
                                return (
                                    <a
                                        key={file.id}
                                        target="_blank"
                                        className="attachment-wrapper tw-text-neutral-600-100"
                                        href={
                                            file?.link ??
                                            awsService?.getUrl(
                                                file?.file_name
                                            ) ??
                                            ''
                                        }
                                        download={fileName}
                                        rel="noreferrer"
                                    >
                                        <div
                                            className={`fas ${icon} fa-4x tw-text-neutral-600-100`}
                                        />
                                        {fileNameJSX}
                                    </a>
                                );
                            })}
                    </div>
                </div>
            )}
        </div>
    );
};
