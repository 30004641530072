import Highcharts from 'highcharts';
import { merge } from 'lodash';
import { useEffect, useState, useRef } from 'react';

// interfaces
import { useThemeColor } from '@hooks';

import { DEFAULT_OPTIONS } from './_utils/options.utils';
import { ChartProps } from './Chart.interfaces';

// utils

// helpers

/**
 * Chart
 * @param options - Highchart Options
 * @param callback - Highcharts callback when done loading
 * @param useDefaultOptions - Turn on or off the merging of project pre-defined highchart options
 */

export const Chart = ({
  options,
  callback,
  useDefaultOptions,
  ...props
}: ChartProps) => {
  const THEME = useThemeColor('LIGHT', 'DARK') as 'LIGHT' | 'DARK';
  const [, setHighchart] = useState(null);
  const container = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const mergedOptions = merge({}, DEFAULT_OPTIONS[THEME], options);
    setHighchart(
      Highcharts.chart(
        container.current,
        useDefaultOptions ? mergedOptions : options,
        callback
      )
    );
  }, [useDefaultOptions, options, callback]);

  return <div ref={container} {...props} />;
};

Chart.defaultProps = {
  useDefaultOptions: true,
};
