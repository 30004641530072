import { useCombobox } from 'downshift';
import {
    Fragment,
    useEffect,
    useState,
    forwardRef,
    useCallback,
    useRef,
} from 'react';
import { createPortal } from 'react-dom';

// helpers

// components
import { Text, IconButtonFlat, Icon } from '@atoms';

// interfaces
import { InfinityEdge } from '@components/InfinityEdge';

import { DropdownProps, DropdownItem } from './dropdown.interfaces';

export const Dropdown = forwardRef<HTMLInputElement, DropdownProps>(
    (
        {
            items,
            onChange,
            selectedItem: controlledSelectedItem,
            disabled,
            error,
            onInputChange,
            disableSearch,
            enableClear,
            groupNames,
            placeholder,
            withPortal = false,
            handleOnIntersect,
            isDropdownPaginated = false,
            isLoading = false,
            hasMoreItems = false,
            showSelectedIcon = true,
            ...props
        },
        ref
    ) => {
        const [inputValue, setInputValue] = useState(
            controlledSelectedItem?.name ?? ''
        );
        const root = useRef<HTMLDivElement>();

        useEffect(() => {
            if (onInputChange) {
                onInputChange(inputValue);
            }
        }, [onInputChange, inputValue]);

        const getSearchResults = useCallback((): DropdownItem[] => {
            if (disableSearch) {
                return items;
            }
            const searchValue = inputValue.toLowerCase();
            const matches: DropdownItem[] = [];
            const nonMatches: DropdownItem[] = [];
            items.forEach((item) => {
                const isMatch =
                    item.name.toLowerCase().includes(searchValue) ||
                    item.subtext?.toLowerCase()?.includes(searchValue);
                if (isMatch) {
                    matches.push(item);
                } else {
                    nonMatches.push(item);
                }
            });
            return [...matches, ...nonMatches];
        }, [items, inputValue, disableSearch]);

        const getGroupNames = useCallback(() => {
            const items = getSearchResults();
            return groupNames.filter(
                (groupName) =>
                    items.filter((item) => item?.groupByName === groupName)
                        .length
            );
        }, [groupNames, getSearchResults]);

        const {
            isOpen,
            getToggleButtonProps,
            getMenuProps,
            getInputProps,
            highlightedIndex,
            getItemProps,
            selectedItem,
            openMenu,
            selectItem,
        } = useCombobox({
            inputValue,
            items: getSearchResults(),
            selectedItem: controlledSelectedItem,
            itemToString: (item) => item?.name ?? '',
            onSelectedItemChange: (state) => {
                onChange(state?.selectedItem ?? null);
            },
            stateReducer: (_, action) => {
                const { type, changes } = action;
                const { selectedItem, inputValue } = changes;
                switch (type) {
                    case useCombobox.stateChangeTypes
                        .ControlledPropUpdatedSelectedItem:
                        setInputValue(inputValue);
                        return changes;
                    case useCombobox.stateChangeTypes.InputChange:
                        setInputValue(inputValue ?? '');
                        return changes;
                    case useCombobox.stateChangeTypes.InputKeyDownEnter:
                    case useCombobox.stateChangeTypes.ItemClick:
                    case useCombobox.stateChangeTypes.InputBlur:
                        setInputValue(selectedItem?.name ?? '');
                        return changes;
                    default:
                        return changes;
                }
            },
        });

        const onClearClick = () => {
            setInputValue('');
            selectItem({ id: null, name: '' });
        };

        function renderDropdownList(groupName?: string) {
            return (
                <Fragment key={groupName}>
                    {groupName && (
                        <li className="tw-px-4 tw-py-2">
                            <Text font="h3" color="hi-contrast">
                                {groupName}
                            </Text>
                        </li>
                    )}
                    {getSearchResults().map((item, index) => {
                        if (groupName && item?.groupByName !== groupName) {
                            return null;
                        }
                        const isSelected = selectedItem?.id === item.id;
                        const isHighlighted = highlightedIndex === index;
                        return (
                            <li
                                key={item.id}
                                className={`tw-py-2 tw-flex tw-items-center tw-justify-between
      ${groupName ? 'tw-px-8' : 'tw-px-4'}
      ${
          isSelected
              ? 'tw-bg-theme-primary-500-300 tw-bg-opacity-15'
              : isHighlighted
              ? 'tw-bg-theme-neutral-200-700'
              : 'active:tw-bg-theme-primary-500-300 active:tw-bg-opacity-15'
      }`}
                                {...getItemProps({
                                    item,
                                    index,
                                })}
                            >
                                <div className="tw-space-x-2">
                                    <Text
                                        font="body-md"
                                        color="hi-contrast"
                                        tag="span"
                                    >
                                        {item.name}
                                    </Text>
                                    {item.subtext && (
                                        <Text
                                            font="body-sm"
                                            color="neutral-offset"
                                            tag="span"
                                        >
                                            {item.subtext}
                                        </Text>
                                    )}
                                </div>
                                {isSelected && showSelectedIcon && (
                                    <Icon
                                        icon={['far', 'check']}
                                        className="tw-text-theme-primary-500-300 tw-mr-4"
                                    />
                                )}
                            </li>
                        );
                    })}
                </Fragment>
            );
        }

        const dropdownMenu = (
            <ul
                {...getMenuProps({
                    disabled,
                    tabIndex: -1,
                    ...(withPortal && {
                        style: {
                            top:
                                (root.current?.getBoundingClientRect().bottom ??
                                    0) + window.pageYOffset,
                            left: root.current?.getBoundingClientRect().left,
                            width: root.current?.getBoundingClientRect().width,
                        },
                    }),
                })}
                className={`tw-absolute tw-w-full tw-bg-theme-neutral-100-800 tw-rounded tw-max-h-60 tw-overflow-auto tw-shadow-dropdown ${
                    withPortal ? 'tw-pointer-events-auto' : 'tw-z-10'
                }`}
            >
                {isOpen
                    ? groupNames?.length
                        ? getGroupNames().map((groupName) =>
                              renderDropdownList(groupName)
                          )
                        : renderDropdownList()
                    : null}
                {/* CH100860 dropdown enhancement to support pagination behavior */}
                {isOpen &&
                    isDropdownPaginated &&
                    getSearchResults()?.length > 0 &&
                    hasMoreItems && (
                        <InfinityEdge
                            onIntersect={
                                getSearchResults()?.length > 0 &&
                                handleOnIntersect
                            }
                            isLoading={isLoading}
                            isDisabled={!hasMoreItems}
                            hideNoDataText={!getSearchResults()?.length}
                        />
                    )}
            </ul>
        );

        return (
            <div data-ds2="dropdown" className="tw-relative" ref={root}>
                <div
                    className={`tw-w-full tw-py-1.5 tw-pl-2 tw-pr-11 tw-rounded tw-ring-2 tw-ring-inset tw-flex tw-items-center tw-flex-wrap tw-gap-2 tw-min-h-[40px]
        ${
            error
                ? `tw-ring-theme-danger-500-300 focus-within:tw-shadow-input-error ${
                      disabled ? 'tw-ring-opacity-20' : ''
                  }`
                : `focus-within:tw-ring-theme-primary-500-300 focus-within:tw-shadow-input-focus ${
                      disabled
                          ? 'tw-ring-theme-neutral-300-700'
                          : 'tw-ring-theme-neutral-400-600'
                  }`
        }

        ${
            disabled
                ? 'tw-bg-theme-neutral-200-800 tw-cursor-not-allowed tw-text-theme-neutral-500-600'
                : 'tw-bg-theme-neutral-100-800 tw-text-theme-neutral-900-100'
        }
    `}
                >
                    <div className="tw-flex-grow">
                        <input
                            {...getInputProps({
                                ...props,
                                onPointerDown: !disabled ? openMenu : undefined,
                                ref,
                            })}
                            className="tw-w-full tw-bg-transparent tw-outline-none tw-text-ellipsis tw-caret-neutral-600 dark:tw-caret-neutral-200 disabled:tw-cursor-not-allowed tw-text-sm"
                            disabled={disabled || disableSearch}
                            placeholder={
                                !selectedItem && placeholder
                                    ? placeholder
                                    : undefined
                            }
                        />
                        {enableClear && (
                            <IconButtonFlat
                                aria-label="clear selection"
                                icon="times"
                                className="tw-absolute tw-top-1 tw-right-[2rem]"
                                onClick={onClearClick}
                                disabled={disabled}
                            />
                        )}
                        <IconButtonFlat
                            {...getToggleButtonProps()}
                            aria-label="toggle menu"
                            icon="caret-down"
                            className="tw-absolute tw-top-1 tw-right-2"
                            disabled={disabled}
                        />
                    </div>
                </div>
                {withPortal
                    ? createPortal(
                          dropdownMenu,
                          document.getElementById('dropdown-portal')
                      )
                    : dropdownMenu}
            </div>
        );
    }
);
