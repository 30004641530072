import { Box, Text, IconButtonFlat, Icon } from '@atoms';

// interfaces
import { InformationContainerProps } from './information.container.interfaces';

export const InformationContainer = ({
  title,
  subtitle,
  rightHeader,
  onClose,
  className,
  style,
  children,
  subLink,
  headerPagination,
}: InformationContainerProps) => {
  return (
    <Box
      className={`tw-p-4 tw-mx-auto sm:tw-rounded-lg sm:tw-p-8 md:tw-p-10 tw-w-[100vw] lg:tw-w-[912px] ${className}`}
      style={style}
    >
      <div className="tw-flex tw-justify-between">
        <div className="tw-flex-grow tw-flex tw-flex-col tw-space-y-1">
          <Text font="h1" color="hi-contrast">
            {title}
          </Text>

          <Text font="body-md" color="neutral-offset">
            {subtitle}
          </Text>

          {subLink && (
            <div>
              <button
                className="tw-group tw-font-medium"
                onClick={subLink.onClick}
              >
                <Text
                  color="primary"
                  font="body-md"
                  className="group-hover:tw-underline group-focus:tw-underline"
                >
                  {subLink.label}
                </Text>
              </button>
            </div>
          )}
        </div>

        {Boolean(rightHeader) && <div className="tw-mr-6">{rightHeader}</div>}

        <IconButtonFlat
          icon={['far', 'times']}
          className="tw-text-2xl"
          onClick={onClose}
          type="secondary"
        />
      </div>
      {headerPagination && (
        <div className="tw-flex tw-justify-between">
          {headerPagination.previousLabel ? (
            <button
              className="tw-group tw-font-medium tw-flex tw-items-center tw-gap-1.5"
              onClick={headerPagination.previousOnClick}
            >
              <Icon
                icon="chevron-left"
                className="tw-text-theme-primary-500-300"
              />
              <Text
                color="primary"
                font="body-md"
                className="group-hover:tw-underline group-focus:tw-underline"
              >
                {headerPagination.previousLabel}
              </Text>
            </button>
          ) : (
            <div />
          )}

          {headerPagination.nextLabel && (
            <button
              className="tw-group tw-font-medium tw-flex tw-items-center tw-gap-1.5"
              onClick={headerPagination.nextOnClick}
            >
              <Text
                color="primary"
                font="body-md"
                className="group-hover:tw-underline group-focus:tw-underline"
              >
                {headerPagination.nextLabel}
              </Text>
              <Icon
                icon="chevron-right"
                className="tw-text-theme-primary-500-300"
              />
            </button>
          )}
        </div>
      )}
      {children}
    </Box>
  );
};

InformationContainer.defaultProps = {
  className: '',
};
