import { Avatar, ScoreChip } from '@atoms';
import { Text } from '@atoms';
import { getFullName } from '@helpers';

// interfaces

// helpers
import { highlightText } from '@helpers';

import { MiniProfileLgProps } from './mini-profile.interface';

export const MiniProfileLg = ({
    person,
    detail,
    isInitials,
    className,
    scoreChip,
    textToHighlight,
    fullWidth,
    hideAvatar,
    ...props
}: MiniProfileLgProps) => {
    return (
        <div
            className={`tw-flex tw-items-center tw-space-x-4 ${className}`}
            {...props}
        >
            <div className="tw-relative tw-flex-shrink-0">
                {!hideAvatar && (
                    <Avatar size="lg" person={person} isInitials={isInitials} />
                )}
                {scoreChip != null && (
                    <div className="tw-absolute tw--bottom-0.5 tw-w-full tw-text-center">
                        <ScoreChip
                            score={scoreChip.score}
                            type={scoreChip.type}
                            size="xs"
                            className="tw-inline-flex"
                            prettyNumberOptions={{
                                decimals: 2,
                                hasTrailingZeros: true,
                                forceTrailingZeros: true,
                                isPercent: false,
                            }}
                        />
                    </div>
                )}
            </div>
            <div className={`${!fullWidth ? 'tw-max-w-[150px]' : ''}`}>
                <Text
                    font="h3"
                    color="hi-contrast"
                    className="tw-capitalize tw-truncate"
                >
                    {highlightText({
                        text: getFullName(person),
                        match: textToHighlight,
                    })}
                </Text>
                {typeof detail === 'string' ? (
                    <Text
                        font="body-md"
                        color="neutral-offset"
                        className="tw-truncate"
                    >
                        {detail}
                    </Text>
                ) : (
                    detail
                )}
            </div>
        </div>
    );
};

MiniProfileLg.defaultProps = {
    className: '',
    textToHighlight: '',
};
