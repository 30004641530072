import brandLogoDark from '@assets/images/logos/4Insite_Logo_Dark_Theme.png';
import brandLogoLight from '@assets/images/logos/4Insite_Logo_Light_Theme.png';
import { Storage } from '@helpers';

import './SplashScreen.css';

export const SplashScreen = () => {
  const isLight = Storage.get('theme') === 'light';

  return (
    <div className="splash-screen tw-flex tw-items-center tw-justify-center tw-min-h-screen tw-px-6 tw-bg-neutral-100 dark:tw-bg-neutral-900">
      <div className="splash-screen-image-container">
        <img
          src={isLight ? brandLogoLight : brandLogoDark}
          alt="4Insite Logo"
          loading="lazy"
        />
      </div>
    </div>
  );
};
