import dateFns from 'date-fns';
import { useEffect, useMemo, useState } from 'react';
import * as React from 'react';

// helpers
import { Box, Text, IconButtonFlat } from '@atoms';
import { TimeUtils } from '@helpers';

import { CalendarProps } from './calendar.interfaces';
import { getWeekdaysNameLocals } from './calendar.utils';

// components

// interfaces

type Day = {
  date: Date;
  dayOfMonth: string;
  isCurrentMonth: boolean;
};

const CALENDAR_SIZE = 42;

export const Calendar = ({
  date,
  onDateChange,
  className,
  locales,
  rounded,
  disableFuture,
  disablePast,
  disabled,
  highlightToDate,
  shadows,
  style,
}: CalendarProps) => {
  const [selectedMonth, setSelectedMonth] = useState(
    TimeUtils.startOfMonth(date)
  );

  useEffect(() => {
    if (!TimeUtils.isValid(TimeUtils.parse(date))) return;
    if (TimeUtils.isSameMonth(selectedMonth, date)) return;
    setSelectedMonth(TimeUtils.startOfMonth(date));
  }, [date]);

  const dayOfMonthFormatter = useMemo(() => {
    try {
      return new Intl.NumberFormat([locales, 'en']);
    } catch (err) {
      return new Intl.NumberFormat('en');
    }
  }, [locales]);

  const monthName = useMemo(() => {
    const formatOptions: Intl.DateTimeFormatOptions = {
      month: 'long',
      year: 'numeric',
    };
    try {
      return new Intl.DateTimeFormat(locales, formatOptions).format(
        selectedMonth
      );
    } catch (err) {
      return new Intl.DateTimeFormat('en', formatOptions).format(selectedMonth);
    }
  }, [locales, selectedMonth]);

  const weekdaysNames = useMemo(
    () => getWeekdaysNameLocals({ locales, weekday: 'short' }),
    [locales]
  );

  const dateRows = useMemo(() => {
    const selectedMonthDays = createDaysForSelectedMonth(selectedMonth);
    const previousMonthDays =
      createPreviousDaysForSelectedMonth(selectedMonthDays);
    const remainingSize =
      CALENDAR_SIZE - (selectedMonthDays.length + previousMonthDays.length);

    const nextMonthDays = Array(remainingSize)
      .fill(null)
      .map((_, index) => {
        const lastDayOfCurrentMonth = selectedMonthDays.slice(-1)[0].date;
        const date = TimeUtils.addDays(lastDayOfCurrentMonth, index + 1);
        return {
          date,
          dayOfMonth: dayOfMonthFormatter.format(dateFns.getDate(date)),
          isCurrentMonth: false,
        };
      });

    const days = [...previousMonthDays, ...selectedMonthDays, ...nextMonthDays];

    return days as Day[];
  }, [selectedMonth, locales]);

  function createDaysForSelectedMonth(selectedMonth: Date): Day[] {
    const year = new Date(selectedMonth).getFullYear();
    const month = new Date(selectedMonth).getMonth();
    const daysInMonth = TimeUtils.getDaysInMonth(selectedMonth);
    return [...Array(daysInMonth)].map((_, index) => {
      const date = new Date(`${month + 1}/${index + 1}/${year}`);
      return {
        date,
        dayOfMonth: dayOfMonthFormatter.format(dateFns.getDate(date)),
        isCurrentMonth: true,
      };
    });
  }

  function createPreviousDaysForSelectedMonth(selectedMonthDays: Day[]): Day[] {
    const firstDayOfSelectedMonth = new Date(selectedMonthDays[0].date);
    const selectedMonthWeekday = new Date(firstDayOfSelectedMonth).getDay() + 1;

    const previousMonth = TimeUtils.subMonths(selectedMonth, 1);

    const visibleNumberOfDaysFromPreviousMonth = selectedMonthWeekday
      ? selectedMonthWeekday - 1
      : 6;

    const previousMonthLastMondayDayOfMonth = TimeUtils.subDays(
      firstDayOfSelectedMonth,
      visibleNumberOfDaysFromPreviousMonth
    );

    return [...Array(visibleNumberOfDaysFromPreviousMonth)].map((_, index) => {
      const date = new Date(
        `${previousMonth.getMonth() + 1}/${
          previousMonthLastMondayDayOfMonth.getDate() + index
        }/${previousMonth.getFullYear()}`
      );
      return {
        date,
        dayOfMonth: dayOfMonthFormatter.format(dateFns.getDate(date)),
        isCurrentMonth: false,
      };
    });
  }

  const blockDates = useMemo(() => {
    const isBlocked = {
      blockPast: false,
      blockFuture: false,
    };

    const now = new Date();
    const startOfMonth = TimeUtils.startOfMonth(now);
    const endOfMonth = TimeUtils.endOfMonth(now);
    const startOfSelectedMonth = TimeUtils.startOfMonth(selectedMonth);
    const endOfSelectedMonth = TimeUtils.endOfMonth(selectedMonth);

    if (
      TimeUtils.isBefore(
        TimeUtils.subDays(startOfSelectedMonth, 1),
        startOfMonth
      ) &&
      disablePast
    ) {
      isBlocked.blockPast = true;
    }
    if (
      TimeUtils.isAfter(TimeUtils.addDays(endOfSelectedMonth, 1), endOfMonth) &&
      disableFuture
    ) {
      isBlocked.blockFuture = true;
    }

    return isBlocked;
  }, [selectedMonth, disablePast, disableFuture]);

  function handlePrevMonthChange() {
    setSelectedMonth((prevMonth) =>
      TimeUtils.startOfMonth(TimeUtils.subMonths(prevMonth, 1))
    );
  }

  function handleNextMonthChange() {
    setSelectedMonth((nextMonth) =>
      TimeUtils.startOfMonth(TimeUtils.addMonths(nextMonth, 1))
    );
  }

  function handleDateChange(date: Date) {
    return (_event: React.MouseEvent<HTMLButtonElement>) => {
      if (onDateChange) {
        onDateChange(date);
      }
    };
  }

  return (
    <Box
      data-ds2="calendar"
      rounded={rounded}
      className={`tw-p-4 tw-space-y-5 tw-w-full
        ${shadows ? 'tw-shadow-lg' : ''}
        ${className}
      `}
      style={style}
    >
      <div className="tw-flex tw-items-center tw-justify-between">
        <IconButtonFlat
          icon="chevron-left"
          onClick={handlePrevMonthChange}
          disabled={disabled || blockDates.blockPast}
        />
        <Text font="body-lg" color="hi-contrast">
          {monthName}
        </Text>
        <IconButtonFlat
          icon="chevron-right"
          onClick={handleNextMonthChange}
          disabled={disabled || blockDates.blockFuture}
        />
      </div>
      <div className="tw-space-y-2.5 tw-text-center">
        <div className="tw-grid tw-grid-cols-7 tw-justify-between tw-items-center ">
          {weekdaysNames.map((weekdayName, index) => (
            <Text key={index} font="body-sm" color="neutral-offset">
              {weekdayName}
            </Text>
          ))}
        </div>
        <div className="tw-grid tw-grid-cols-7 tw-justify-between tw-items-center tw-isolate">
          {dateRows.map((day) => {
            const isSelectedDate = TimeUtils.isSameDay(day.date, date);

            let isHighlighted: boolean = false;
            if (highlightToDate) {
              const startOfHighlightedDate =
                TimeUtils.startOfDay(highlightToDate);
              isHighlighted = TimeUtils.isAfter(startOfHighlightedDate, date)
                ? TimeUtils.isWithinRange(
                    day.date,
                    date,
                    startOfHighlightedDate
                  )
                : TimeUtils.isWithinRange(
                    day.date,
                    startOfHighlightedDate,
                    date
                  );
            }

            const isBlockedDate =
              (disablePast &&
                TimeUtils.isBefore(
                  TimeUtils.startOfDay(day.date),
                  TimeUtils.startOfDay(new Date())
                )) ||
              (disableFuture &&
                TimeUtils.isAfter(
                  TimeUtils.startOfDay(day.date),
                  TimeUtils.startOfDay(new Date())
                ));

            const diffMonthOpacity = !day.isCurrentMonth ? 'tw-opacity-50' : '';
            const selectedHighlightedDateClass =
              highlightToDate && TimeUtils.isSameDay(highlightToDate, day.date)
                ? 'tw-ring-1 tw-ring-theme-primary-500-300 tw-rounded-sm tw-z-[1]'
                : '';
            const blockedDateClass = isBlockedDate
              ? 'tw-bg-theme-neutral-200-850 tw-text-opacity-40'
              : '';
            const defaultClass = day.isCurrentMonth
              ? 'tw-text-theme-neutral-600-500'
              : 'tw-text-theme-neutral-400-600';

            return (
              <button
                key={day.date.toISOString()}
                className={`tw-py-0.5 tw-px-2  ${
                  isSelectedDate
                    ? `tw-bg-theme-primary-500-300 tw-text-neutral-100 tw-rounded-sm ${diffMonthOpacity}`
                    : day.isCurrentMonth && isHighlighted
                    ? `tw-bg-theme-primary-500-300 tw-bg-opacity-15 tw-text-theme-primary-500-300 ${selectedHighlightedDateClass} ${diffMonthOpacity}`
                    : `${defaultClass} ${blockedDateClass}`
                }`}
                disabled={disabled || isBlockedDate}
                onClick={handleDateChange(day.date)}
                type="button"
              >
                <Text font="body-sm" color="current-color">
                  {day.dayOfMonth}
                </Text>
              </button>
            );
          })}
        </div>
      </div>
    </Box>
  );
};

Calendar.defaultProps = {
  locales: 'en',
  className: '',
};
