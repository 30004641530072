import { renderToString } from 'react-dom/server';

// components
import { Text } from '@atoms';

export const ChartLabel = {
  Legend() {
    const label = (
      <Text font="h3" color="hi-contrast" className="tw-leading-none">
        {this.name}
      </Text>
    );
    return renderToString(label);
  },
  LegendValue() {
    const label = (
      <div>
        <span className="tw-text-neutral-900-100">{this.name}</span>
        <span className="tw-text-theme-neutral-600-500 tw-ml-2">{this.y}</span>
        {/* <Text font="h4" className="tw-text-neutral-900-100 tw-inline tw-leading-none">{this.name}</Text> */}
        {/* <Text font="h4" className="tw-text-theme-neutral-600-500 tw-inline tw-leading-none tw-ml-2">{this.y}</Text> */}
      </div>
    );
    return renderToString(label);
  },
  XAxis() {
    const label = (
      <Text font="h4" color="hi-contrast">
        {this.value}
      </Text>
    );
    return renderToString(label);
  },
  YAxis() {
    const label = (
      <Text font="body-sm" color="neutral-offset">
        {this.value}
      </Text>
    );
    return renderToString(label);
  },
};
