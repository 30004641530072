import { useState, useEffect, useRef } from 'react';

// helpers

// components
import { MenuItem, IconButton } from '@atoms';

// interfaces
import { SortButtonProps, SortBy } from './sort-button.interfaces';
import { sortButtonIcon } from './sort-button.utils';

export const SortButton = ({
    onChange,
    defaultSort,
    type,
    className,
    disabled,
    anchor,
}: SortButtonProps) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [icon, setIcon] = useState(
        sortButtonIcon[defaultSort || 'alpha-asc']
    );
    const containerRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (!containerRef.current) return;
        function handleOutsideClick(e: MouseEvent) {
            const target = e.target as HTMLElement;
            if (!containerRef.current.contains(target)) {
                setIsMenuOpen(false);
            }
        }
        window.addEventListener('mousedown', handleOutsideClick);
        return () => {
            window.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    useEffect(() => {
        if (disabled && isMenuOpen) {
            setIsMenuOpen(false);
        }
    }, [disabled]);

    function handleSortChange(sortBy: SortBy) {
        return () => {
            setIcon(sortButtonIcon[sortBy]);
            setIsMenuOpen(false);
            onChange(sortBy);
        };
    }

    return (
        <div
            ref={containerRef}
            data-ds2="alpha-sort"
            className={`tw-relative tw-inline-block ${className}`}
        >
            <IconButton
                icon={icon}
                onClick={() => {
                    setIsMenuOpen(!isMenuOpen);
                }}
                type={type}
                disabled={disabled}
                className="tw-transition-shadow tw-shadow-md active:tw-shadow-none"
            />
            {isMenuOpen && (
                <ul
                    className={`tw-absolute tw-top-full tw-mt-1 tw-w-max tw-bg-theme-neutral-100-800 tw-rounded tw-overflow-hidden tw-shadow-md tw-z-50 ${
                        anchor === 'right' ? 'tw-right-0' : ''
                    }`}
                >
                    <MenuItem
                        name="A-Z"
                        withIcon={{
                            position: 'left',
                            fontAwesome: { icon: 'arrow-up-a-z' },
                        }}
                        onClick={handleSortChange('alpha-asc')}
                    />
                    <MenuItem
                        name="Z-A"
                        withIcon={{
                            position: 'left',
                            fontAwesome: { icon: 'arrow-down-z-a' },
                        }}
                        onClick={handleSortChange('alpha-desc')}
                    />
                    <MenuItem
                        name="Low to High"
                        withIcon={{
                            position: 'left',
                            fontAwesome: { icon: 'arrow-up-1-9' },
                        }}
                        onClick={handleSortChange('numeric-asc')}
                    />
                    <MenuItem
                        name="High to Low"
                        withIcon={{
                            position: 'left',
                            fontAwesome: { icon: 'arrow-down-9-1' },
                        }}
                        onClick={handleSortChange('numeric-desc')}
                    />
                </ul>
            )}
        </div>
    );
};

SortButton.defaultProps = {
    defaultSort: 'alpha-asc',
    type: 'primary',
    className: '',
    anchor: 'left',
};
