/** ************************************************
 * Imports
 ************************************************* */

import { CardGoal } from '@new/Card/CardGoal';

import { scoreColorMapper } from './CardScore.helpers';
/** ************************************************
 * Interface
 ************************************************* */

import { CardScoreProps } from './CardScore.interfaces';

import './CardScore.scss';

/** ************************************************
 * Render Component
 ************************************************* */

export const CardScore = (props: CardScoreProps) => {
    const {
        className = '',
        score,
        goal,
        size,
        scoreColor = 'default',
        scoreDataTest,
    } = props;

    return (
        <span
            className={`_new-cards-contents-score data ${
                scoreColorMapper[scoreColor]
            } ${className} ${size === 'sm' ? 'size-sm' : ''}`}
            data-test={scoreDataTest ?? ''}
        >
            {score}
            {goal && (
                <CardGoal
                    goal={goal}
                    className={
                        size !== 'sm' ? 'tw-mt-8 tw-mb-1' : 'tw-mt-2 tw-ml-1'
                    }
                />
            )}
        </span>
    );
};
