// services

// helpers
import { Observable } from 'rxjs';

import { OrganizationGroupsSummary } from '@api-interfaces';
import { Params } from '@helpers';

// interfaces
import { virtualBrownClient } from '@services';

// constants
const BASE_URL = 'organizations/organization_groups/summary';

class OrganizationGroupsSummaryService {
	getSummaries<T extends OrganizationGroupsSummary.Chart>(
		params: OrganizationGroupsSummary.OrganizationGroupSummaryParams<T>
	): Observable<OrganizationGroupsSummary.SummaryReturnType[T][]> {
		const query = {
			...params,
			chart: params.chart.join(','),
		};
		return virtualBrownClient.get(
			`${BASE_URL}/${Params.makeQueryString(query)}`,
			{
				noCustomer: true,
				noContract: true,
				noTag: true,
			}
		);
	}
}

export const organizationGroupsSummaryService =
	new OrganizationGroupsSummaryService();
