export type AwsFolders = 'image' | 'file' | 'video' | 'audio' | 'assets';
export enum EAwsFolders {
	image,
	file,
	video,
	audio,
	assets,
}

export enum ATTACHMENT_TYPE {
	Image = 1,
	Video = 2,
	Audio = 3,
	Pdf = 4,
	File = 5,
}
export type AwsPermissions =
	| 'private'
	| 'public-read'
	| 'public-read-write'
	| 'authenticated-read'
	| 'bucket-owner-read'
	| 'bucket-owner-full-control';
export type AwsAttachments = 'image' | 'video' | 'audio' | 'pdf' | 'file';
