import { Box, IconButton, Text } from '@insite-next/mocha';
import { DetailsModalProps } from './details.interfaces';

export const Details = ({
    title,
    summary,
    onClose,
    children,
    containerClassName = '',
    className = '',
    xl = false,
}: DetailsModalProps) => {
    return (
        <Box
            type="modalDefault"
            className={`tw-relative tw-h-[100vh] lg:tw-h-auto tw-w-full lg:tw-max-w-5xl tw-flex tw-flex-col md:tw-flex-row lg:tw-rounded-lg tw-overflow-auto lg:tw-overflow-visible ${
                xl ? 'xl:tw-max-w-7xl' : ''
            } ${containerClassName}`}
        >
            <IconButton
                icon="close"
                color="neutral-600-500"
                className="tw-fixed md:tw-absolute tw-top-8 tw-right-8 tw-z-10"
                onClick={onClose}
            />
            <div className="tw-relative md:tw-w-[276px] tw-shrink-0 tw-p-8 tw-pr-7 tw-bg-theme-neutral-100-800 lg:tw-rounded-l-lg tw-space-y-5 md:tw-h-auto lg:tw-max-h-[80vh] tw-overflow-visible md:tw-overflow-y-auto">
                <Text
                    color="default"
                    font="h2"
                    className="tw-sticky tw-top-8 md:tw-top-0 tw-bg-theme-neutral-100-800
                     after:tw-content-[''] after:tw-block after:tw-h-8 after:tw-w-full after:tw-absolute after:tw-left-0 after:tw-bottom-0 after:tw-bg-gradient-to-b after:tw-from-neutral-100 dark:after:tw-from-neutral-800 after:tw-to-transparent dark:after:tw-to-transparent after:tw-translate-y-full
                     before:tw-content-[''] before:tw-block before:tw-h-8 before:tw-w-full before:tw-absolute before:tw-left-0 before:tw--top-8 before:tw-bg-theme-neutral-100-800"
                >
                    {title}
                </Text>
                {summary.map((detail, index) => (
                    <div key={detail.label ?? index} className="tw-space-y-1">
                        {detail.label && (
                            <Text color="default" font="h4">
                                {detail.label}
                            </Text>
                        )}
                        {typeof detail.value === 'string' ||
                        typeof detail.value === 'number' ? (
                            <Text color="default" font="body-md">
                                {detail.value}
                            </Text>
                        ) : (
                            detail.value
                        )}
                    </div>
                ))}
            </div>
            <div
                className={`tw-flex-1 md:tw-h-full lg:tw-max-h-[80vh] tw-overflow-visible md:tw-overflow-y-auto tw-p-8 ${className}`}
            >
                {children}
            </div>
        </Box>
    );
};
