import { Box, Text, Tooltip } from '@atoms';
import { useAppContext } from '@hooks';
import { useWorkOrderModalContext } from '@modals/_ds2/work-orders/_context';

// components
import { Button } from '@new';

import { CreateServiceIssue } from './service-issue.create';
import { DeleteServiceIssue } from './service-issue.delete';
import { EditServiceIssue } from './service-issue.edit';
import { ServiceIssueTable } from './service-issue.table';

export const WorkOrderServiceIssues = () => {
  const { setActiveModals, activeModals, workOrder, workOrderEdit } =
    useWorkOrderModalContext();
  const {
    state: { user, isSingleSite },
  } = useAppContext();

  const hasCreatePermission = user.hasPermission(2, 'Complaints');

  const handleCreateServiceIssue = () => {
    setActiveModals({
      ...activeModals,
      'creating-service-issue': true,
    });
  };

  const submitDisabled =
    !isSingleSite || (!workOrder.service && !workOrderEdit.service);

  return (
    <>
      <div className="tw-flex tw-justify-between tw-items-center">
        <Text font="h2" color="primary">
          Complaints
        </Text>

        {hasCreatePermission && (
          <Tooltip
            render={
              submitDisabled ? (
                <Box rounded className="tw-p-4 tw-text-center tw-shadow-lg">
                  <Text font="h4" color="hi-contrast">
                    {!isSingleSite
                      ? 'Must select only one site in order to submit.'
                      : 'Must select a program in order to submit.'}
                  </Text>
                </Box>
              ) : null
            }
            position="top"
          >
            <Button
              color="alternate"
              icon={['far', 'plus']}
              label="Create New Complaint"
              disabled={submitDisabled}
              onClick={handleCreateServiceIssue}
            />
          </Tooltip>
        )}
      </div>

      <CreateServiceIssue />
      <EditServiceIssue />
      <ServiceIssueTable />
      <DeleteServiceIssue />
    </>
  );
};
