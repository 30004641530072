// base
import * as React from 'react';

// services

// helpers

// components
import { usePickerContext } from './StoredPickerContext';

// interfaces
import { AreaTag, SimplePreset } from '@api-interfaces';
import { presetsService } from '@apis';
import { Text, Box, Icon, Tooltip, Link } from '@atoms';
import { FieldInputText } from '@atoms/form-ds2/field-input-text';
import { FieldMultiSelect } from '@atoms/form-ds2/field-multi-select';
import { InputCheckbox } from '@atoms/form-ds2/input-checkbox';
import { MultiSelectItem } from '@atoms/form-ds2/multi-select/multi-select.interfaces';
import { App } from '@core/app-context/app.interfaces';
import { useAppContext } from '@hooks';
import {
    R4PortfolioCustomerOrganization,
    R4PortfolioContract,
    R4PortfolioAreaTagGroup,
    R4OrganizationGroup,
    // R4PortfolioAreaTag,
} from '@models';
import { Button } from '@new';
import { toasterService, modalService } from '@services';

import { InfoTooltip } from './helpers';

interface Props {
    selectedPreset: SimplePreset;
    isShared: boolean;
}

interface State {
    presetName: string;

    clients: MultiSelectItem<R4PortfolioCustomerOrganization>[];
    sites: MultiSelectItem<R4PortfolioContract>[];
    areaTagGroups: MultiSelectItem<R4PortfolioAreaTagGroup>[];
    areaTags: { [tagGroupName: string]: MultiSelectItem<AreaTag>[] };
    allClients: MultiSelectItem<R4PortfolioCustomerOrganization>[];
    selectedClients: MultiSelectItem<R4PortfolioCustomerOrganization>[];
    selectedSites: MultiSelectItem<R4PortfolioContract>[];
    selectedAreaTags: {
        [tagGroupName: string]: MultiSelectItem<AreaTag>[];
    };
    selectedClientGroups: any;
    isShared: boolean;
}

type OrgGroupMap = { [groupTypeId: number]: R4OrganizationGroup[] };
class InnerEditPresetModal extends React.Component<
    Props & { user: App.State['user'] } & { pickerData } & {
        setSelectedOrgGroups;
    } & { setSelectedCustomers },
    State
> {
    constructor(
        props: Props & { user: App.State['user'] } & { pickerData } & {
            setSelectedOrgGroups;
        } & { setSelectedCustomers }
    ) {
        super(props);

        this.state = {
            presetName: '',
            clients: [],
            sites: [],
            areaTagGroups: [],
            areaTags: {},
            allClients: [],
            selectedClients: [],
            selectedSites: [],
            selectedAreaTags: {},
            selectedClientGroups: [],
            isShared: props.isShared,
        };
    }

    public componentDidMount() {
        const {
            roleProfile: { portfolios },
        } = this.props.user;

        const options = portfolios.reduce(
            (options, portfolio) => {
                const areaTagGroups = [
                    ...options.areaTagGroups,
                    ...portfolio.area_tag_groups,
                ].sort((a, b) => a.name.localeCompare(b.name));
                return {
                    clients: [
                        ...options.clients,
                        ...portfolio.customer_organizations,
                    ]
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((el) => {
                            el['org_customer_group'] =
                                this.props.pickerData?.pickerOptionOrgGroups?.[0]?.organization_groups.find(
                                    (group) => {
                                        return group.organizations.some(
                                            (e) => e.organization_id === el.id
                                        );
                                    }
                                );
                            return el;
                        }),
                    sites: [...options.sites, ...portfolio.contracts].sort(
                        (a, b) => a.name.localeCompare(b.name)
                    ),
                    areaTagGroups,
                    areaTags: Object.fromEntries(
                        areaTagGroups.map((group) => {
                            const areaTags = [
                                ...(options.areaTags?.[group.name] ?? []),
                                ...portfolio.area_tags
                                    .flat()
                                    .filter(
                                        (tag) => +tag.tag_group === group.id
                                    )
                                    .map((tag) => ({
                                        ...tag,
                                        id: +tag.id,
                                        tag_group: +tag.tag_group,
                                    })),
                            ].sort((a, b) => a.name.localeCompare(b.name));
                            return [group.name, areaTags];
                        })
                    ),
                };
            },
            {
                clients: [],
                sites: [],
                areaTagGroups: [],
                areaTags: {},
            } as Pick<State, 'clients' | 'sites' | 'areaTagGroups' | 'areaTags'>
        );
        const allClients = options.clients;
        if (this.props.selectedPreset.customer_groups.length > 0) {
            options.clients = options.clients.filter((el) =>
                this.props.selectedPreset.customer_groups.includes(
                    el?.org_customer_group?.organization_group_id
                )
            );
        }

        const selectedAreaTags = Object.fromEntries(
            options.areaTagGroups
                .filter(
                    (tagGroup) =>
                        this.props.selectedPreset.area_tag_groups?.findIndex(
                            (group) => group.id === tagGroup.id
                        ) > -1
                )
                .map((group) => {
                    const areaTags = options.areaTags[group.name].filter(
                        (tag) =>
                            this.props.selectedPreset.areatags?.findIndex(
                                (t) => t.id === tag.id
                            ) > -1
                    );
                    return [group.name, areaTags];
                })
        );

        this.setState({
            presetName: this.props.selectedPreset.name,
            ...options,
            allClients: allClients,
            selectedClients: options.clients.filter((option) =>
                this.props.selectedPreset.customers.includes(option.id)
            ),
            selectedSites: options.sites.filter((option) =>
                this.props.selectedPreset.contracts.includes(option.id)
            ),
            selectedAreaTags,
            selectedClientGroups:
                this.props.pickerData.pickerOptionOrgGroups.flatMap(
                    (groupType) =>
                        groupType.organization_groups
                            .map((group) => ({
                                ...group,
                                id: group.organization_group_id,
                            }))
                            .filter((el) => {
                                return this.props.selectedPreset.customer_groups.includes(
                                    el.id
                                );
                            })
                ),
        });
    }

    getAreaTagGroups = () => {
        return this.state.areaTagGroups.filter(
            (group) =>
                group.contracts.findIndex(
                    (contractId) =>
                        this.state.selectedSites.findIndex(
                            (site) => site.id === contractId
                        ) > -1
                ) > -1
        );
    };

    handlePresetNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        this.setState({
            presetName: value,
        });
    };

    handleClientSelections = (selectedClients: State['clients']) => {
        const selectedSites = this.state.selectedSites.filter(
            (site) =>
                selectedClients.findIndex(
                    (client) => client.id === site.customer
                ) > -1
        );

        const selectedAreaTags = Object.fromEntries(
            Object.entries(this.state.selectedAreaTags).filter(
                ([groupName]) =>
                    this.getAreaTagGroups().findIndex(
                        (group) => group.name === groupName
                    ) > -1
            )
        );

        this.setState({
            selectedClients,
            selectedSites,
            selectedAreaTags,
        });
    };

    handleSiteSelections = (selectedSites: State['sites']) => {
        const selectedAreaTags = Object.fromEntries(
            Object.entries(this.state.selectedAreaTags).filter(
                ([groupName]) =>
                    this.getAreaTagGroups().findIndex(
                        (group) => group.name === groupName
                    ) > -1
            )
        );

        this.setState({
            selectedSites,
            selectedAreaTags,
        });
    };

    handleCancel = () => {
        modalService.close();
    };

    handleDelete = () => {
        presetsService
            .deletePresetById(this.props.selectedPreset.id)
            .subscribe({
                next: () => {
                    // show success msg of  deleted
                    toasterService.newToast({
                        status: 'success',
                        message: 'Successfully deleted preset.',
                    });

                    modalService.close();
                },
                error: () => {
                    toasterService.newToast({
                        message: 'There was an error deleting the Preset',
                    });
                },
            });
    };

    handleSave = () => {
        if (!this.state.presetName) {
            toasterService.newToast({
                status: 'warning',
                message: 'A name is required',
            });
            return;
        }
        if (!this.state.selectedClients.length) {
            toasterService.newToast({
                status: 'warning',
                message: 'Select at least one client to save the Preset',
            });
            return;
        }

        presetsService
            .updatePresetById(this.props.selectedPreset.id, {
                area_tag_group_ids: [
                    ...new Set(
                        Object.keys(this.state.selectedAreaTags).map(
                            (key) =>
                                this.state.areaTagGroups.find(
                                    (group) => group.name === key
                                ).id
                        )
                    ),
                ],
                areatag_ids: Object.values(this.state.selectedAreaTags)
                    .flat()
                    .map((tag) => tag.id),
                contract_ids: this.state.selectedSites.map((site) => site.id),
                customer_ids: this.state.selectedClients.map(
                    (client) => client.id
                ),
                employee_owner: {
                    id: this.props.selectedPreset.employee_owner.id,
                },
                name: this.state.presetName,
                organization_owner: {
                    id: this.props.user.roleProfile.organization.id,
                },
                is_shared: this.state.isShared,
                last_update_by: {
                    id: this.props.user.id,
                },
                customer_groups: this.state.selectedClientGroups.map(
                    (el) => el.id
                ),
            })
            .subscribe({
                next: (res) => {
                    toasterService.newToast({
                        status: 'success',
                        message: 'The Preset was successfully saved',
                    });
                    modalService.close();
                },
                error: () => {
                    toasterService.newToast({
                        message: 'There was an error updating the Preset',
                    });
                },
            });
    };

    setSelectedOrgGroupsInternalFn = (
        item: (R4OrganizationGroup & { id: number })[]
    ) => {
        const selectedOrgGroups = item.reduce((acc, orgGroup) => {
            if (!acc[orgGroup.type_id]) {
                acc[orgGroup.type_id] = [];
            }
            acc[orgGroup.type_id].push(orgGroup);
            return acc;
        }, {} as OrgGroupMap);

        const orgGroupCustomerIds = Object.values(selectedOrgGroups).map(
            (groups) =>
                groups.flatMap((group) =>
                    group.organizations.map((org) => org.organization_id)
                )
        );

        let clients;
        if (orgGroupCustomerIds?.length > 0) {
            clients = [
                ...this.state.allClients.filter((customer) =>
                    orgGroupCustomerIds.every(
                        (groups) =>
                            !groups.length || groups.includes(customer.id)
                    )
                ),
            ];
            clients.map((el) => {
                el['org_customer_group'] = Object.values(
                    selectedOrgGroups
                )?.[0]?.find((group) => {
                    return group.organizations.some(
                        (e) => e.organization_id === el.id
                    );
                });
                return el;
            });
        }

        const selectedClients =
            clients && clients?.length > 0
                ? this.state.selectedClients.filter(
                      (el) =>
                          clients.findIndex((client) => client.id === el.id) >
                          -1
                  )
                : this.state.selectedClients;

        const selectedSites =
            clients && clients?.length > 0
                ? this.state.selectedSites.filter(
                      (site) =>
                          selectedClients.findIndex(
                              (client) => client.id === site.customer
                          ) > -1
                  )
                : this.state.selectedSites;

        this.setState({
            clients: clients ? clients : this.state.clients,
            selectedSites,
            selectedClients,
            selectedClientGroups: item,
        });
    };

    public render() {
        const canCreate = this.props.user.hasPermission(2, 'Shared Presets');
        const canOnlyView =
            this.props.user.hasPermission(1, 'Shared Presets') && !canCreate;
        const canDeleteAndUnshare = this.props.user.hasPermission(
            3,
            'Shared Presets'
        );
        const hideDeleteButton = this.props.isShared && !canDeleteAndUnshare;
        const isReadOnly = this.props.isShared && canOnlyView;

        return (
            <div className="tw-space-y-6 tw-p-10">
                <div className="tw-flex tw-items-center tw-justify-between tw-mb-8">
                    <h2>{`View${isReadOnly ? '' : '/Edit'} Preset`}</h2>
                    {this.props.isShared && (
                        <InfoTooltip
                            preset={this.props.selectedPreset}
                            isInModal
                        />
                    )}
                </div>

                <FieldInputText
                    required
                    id="preset-name"
                    label="Preset Name"
                    onChange={this.handlePresetNameChange}
                    value={this.state.presetName}
                    readOnly={isReadOnly}
                />

                {this.props.pickerData.pickerOptionOrgGroups.length > 0 && (
                    <FieldMultiSelect
                        id="org-client-group"
                        label="Client Group"
                        items={this.props.pickerData.pickerOptionOrgGroups.flatMap(
                            (groupType) =>
                                groupType.organization_groups.map((group) => ({
                                    ...group,
                                    id: group.organization_group_id,
                                }))
                        )}
                        selectedItems={this.state.selectedClientGroups}
                        onChange={this.setSelectedOrgGroupsInternalFn}
                        hasSelectAll
                        selectAllFont="h4"
                        placeholder="Search"
                        allowKeyAction
                    />
                )}

                <FieldMultiSelect
                    required
                    hasSelectAll
                    id="preset-clients"
                    label="Clients"
                    items={this.state.clients}
                    selectedItems={this.state.selectedClients}
                    onChange={this.handleClientSelections}
                    groupBy={
                        this.state.selectedClientGroups.length > 1
                            ? (option: any) => option?.org_customer_group?.name
                            : undefined
                    }
                    disabled={isReadOnly}
                />

                {this.state.selectedClients.length > 0 && (
                    <FieldMultiSelect
                        hasSelectAll
                        id="preset-sites"
                        label="Sites"
                        items={this.state.sites.filter((site) =>
                            this.state.selectedClients.some(
                                (client) => client.id === site.customer
                            )
                        )}
                        selectedItems={this.state.selectedSites}
                        onChange={this.handleSiteSelections}
                        groupBy={
                            this.state.selectedClients.length > 1
                                ? (option: R4PortfolioContract) =>
                                      option.customer_name
                                : undefined
                        }
                        disabled={isReadOnly}
                    />
                )}

                {this.getAreaTagGroups().map((group, index) => (
                    <FieldMultiSelect
                        key={`${group.id}_${index}`}
                        id={`area-group-${group.name.replaceAll(' ', '-')}`}
                        label={group.name}
                        items={this.state.areaTags[group.name] || []}
                        selectedItems={
                            this.state.selectedAreaTags[group.name] ?? []
                        }
                        onChange={(
                            selectedAreaTags: State['selectedAreaTags'][0]
                        ) => {
                            this.setState({
                                selectedAreaTags: {
                                    ...this.state.selectedAreaTags,
                                    [group.name]: selectedAreaTags,
                                },
                            });
                        }}
                        disabled={isReadOnly}
                    />
                ))}

                {canCreate && (
                    <div className="tw-flex tw-items-center">
                        <InputCheckbox
                            checked={this.state.isShared}
                            disabled={
                                this.props.isShared && !canDeleteAndUnshare
                            }
                            onChange={(e) =>
                                this.setState({ isShared: e.target.checked })
                            }
                            className="tw-mr-2"
                        />
                        <Text
                            font="body-md"
                            color="neutral-offset"
                            className="tw-flex tw-space-x-2"
                        >
                            <span>Share with Org</span>
                            <Tooltip
                                position="bottom"
                                render={
                                    <Box
                                        rounded
                                        className="tw-p-2 tw-text-center tw-shadow-lg tw-w-[175px]"
                                    >
                                        <Text
                                            font="body-md"
                                            tag="span"
                                            color="neutral-offset"
                                        >
                                            Click to share this preset with the
                                            organization
                                        </Text>
                                    </Box>
                                }
                            >
                                <Icon icon="question-circle" />
                            </Tooltip>
                        </Text>
                    </div>
                )}

                <div
                    className={`${
                        !hideDeleteButton
                            ? 'tw-flex tw-items-center tw-justify-between'
                            : ''
                    }`}
                >
                    {!hideDeleteButton && (
                        <Button
                            label="Delete Preset"
                            onClick={this.handleDelete}
                            color="danger"
                        />
                    )}
                    {isReadOnly ? (
                        <Link
                            as="div"
                            onClick={this.handleCancel}
                            className="tw-flex tw-justify-end tw-font-medium"
                        >
                            Back
                        </Link>
                    ) : (
                        <div className="tw-flex tw-justify-end tw-space-x-4">
                            <Button
                                label="Cancel"
                                onClick={this.handleCancel}
                                color="secondary"
                            />
                            <Button label="Save" onClick={this.handleSave} />
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export const EditPresetModal = (props: Props) => {
    const {
        state: { user },
    } = useAppContext();

    const {
        state: { pickerOptionOrgGroups },
        setSelectedOrgGroups,
        setSelectedCustomers,
    } = usePickerContext();

    let picker = {
        pickerOptionOrgGroups,
    };

    function setSelectedOrgGroupsInternal(
        item: (R4OrganizationGroup & { id: number })[]
    ) {
        setSelectedOrgGroups(item);
    }

    function setSelectedCustomersInternal(
        item: R4PortfolioCustomerOrganization[]
    ) {
        setSelectedCustomers(item);
    }

    return (
        <InnerEditPresetModal
            {...props}
            user={user}
            pickerData={picker}
            setSelectedOrgGroups={setSelectedOrgGroupsInternal}
            setSelectedCustomers={setSelectedCustomersInternal}
        />
    );
};
