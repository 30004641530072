import dateFns from 'date-fns';
import { useEffect, useState } from 'react';

// helpers
import { useAuditModalContext } from '@app/modals/_ds2/audit/_context/context';
import { ModalStack, Avatar, Text } from '@atoms';
import { MenuItemProps } from '@atoms/menu-item/menu-item.interfaces';
import { OverflowMenuButton } from '@components/_globals/components/overflow-menu-button';
import { MiniProfile } from '@components/_new/MiniProfiles';
import { getFullName } from '@helpers';
import { useAppContext } from '@hooks';
import { Modal } from '@templates';
import { mediaService } from '@core/apis';

import { IComment, Attachment } from '../../comment.utils';

// components
import { CreateComment } from '../CreateComment';
import { Footer } from '../Footer';

import { TranslationBox } from './_components';

// interfaces

import './Comment.css';
import { AttachmentTile } from '../AttachmentTile';

export const Comment = (props: IComment) => {
    const {
        state: { user },
    } = useAppContext();
    const {
        state: { modals },
    } = useAuditModalContext();

    const {
        is_child,
        comment,
        toggleChildren,
        editComment,
        isLastChild,
        createChild,
        childCount,
        threadToggled,
        deleteComment,
    } = props;

    const [isEditing, setIsEditing] = useState(false);
    const [isReplying, setIsReplying] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [edited, setEdited] = useState(false);
    const [body, setBody] = useState(comment.body);
    const [isOrigBody, setIsOrigBody] = useState(false);
    const [attachments, setAttachments] = useState<string[]>([]);

    useEffect(() => {
        setBody(comment.body);
        setAttachments(comment?.media_list || []);
    }, [comment]);

    useEffect(() => {
        const difference = dateFns.differenceInSeconds(
            comment.update_date,
            comment.comment_date
        );
        // Arbitrarily selected amount of seconds difference before we consider it an edit and not a BE recording issue
        if (difference > 3) {
            setEdited(true);
            return;
        }
        setEdited(false);
    }, []);

    const hasCreatePermissions = user.hasPermission(2, 'Comments');
    const hasDeletePermissions = user.hasPermission(3, 'Comments');

    const addtlActions: MenuItemProps[] = [];
    if (
        hasCreatePermissions &&
        comment.commenter.person.id === user.person.id
    ) {
        addtlActions.push({ name: 'Edit', onClick: () => setIsEditing(true) });
    }
    if (hasDeletePermissions) {
        addtlActions.push({
            name: 'Delete',
            onClick: () => setIsDeleting(true),
            className: 'tw-text-theme-danger-500-300',
        });
    }
    const handleEdit = (input: string) => {
        if (input !== '' || attachments.length) {
            editComment(comment.id, input, attachments);
            setBody(input);
            setEdited(true);
            setIsEditing(false);
            setIsOrigBody(false);
        }
    };

    const handleReply = (input: string) => {
        createChild(input);
        setIsReplying(false);
    };

    const handleDelete = () => {
        deleteComment(comment.id);
        handleConfirmationClose();
    };

    const usersMetaInfo = () => {
        const org = user?.organization?.name ?? 'N/A';
        const site = user?.shifts?.[0]?.contract?.name ?? 'N/A';

        const format = `${org}, ${site}`;

        if (format.includes('N/A')) return 'N/A';
        return format;
    };

    const formatSiteInfo = () => {
        const site_info =
            comment?.contract_names?.[0] !== undefined
                ? comment?.contract_names[0]
                : 'N/A';
        const customer =
            comment?.customer_names?.[0] !== undefined
                ? comment.customer_names[0]
                : 'N/A';
        let formatted = `${customer}, ${site_info}`;
        // new comments dont have the site info on them, need to get it from the user
        if (
            formatted.includes('N/A') &&
            comment.commenter.person.id === user.person.id
        ) {
            const customer =
                user?.organization?.name !== undefined
                    ? user.organization.name
                    : 'N/A';
            const site_info =
                user?.employee?.shifts?.[0].contract?.name !== undefined
                    ? user.employee.shifts[0].contract.name
                    : 'N/A';
            formatted = `${customer}, ${site_info}`;
        }
        return !formatted.includes('N/A') ? formatted : 'N/A';
    };

    function handleConfirmationClose() {
        setIsDeleting(false);
    }

    const handleRemoveAttachment = (item: Attachment) => {
        const removeThisItem = attachments.filter((i) => i.id !== item.id);
        setAttachments(removeThisItem);
    };

    const handleCancel = () => {
        setIsReplying(false);
        setIsEditing(false);
    };

    const handleAddAttachment = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        const { files: fileList } = e.target;
        Array.from(fileList).forEach((file) =>
            mediaService
                .upload(file, {
                    employeeId: user.employeeId,
                    organizationId: user.organization.id,
                })
                .subscribe((res: Media) => {
                    setAttachments([
                        ...attachments,
                        { ...res, display_name: file.name },
                    ]);
                })
        );
    };

    return (
        <>
            <div className="comment-box-container">
                <div
                    className={`${is_child ? 'comment-thread-child' : ''} ${
                        isLastChild ? 'comment-thread-last-child' : ''
                    }`}
                >
                    {comment && (
                        <div className="tw-flex tw-justify-between">
                            {/* Left column */}
                            <div className="comment-left-column">
                                <div
                                    className={`${
                                        is_child
                                            ? 'comment-thread-child-horizontal-border'
                                            : ''
                                    } tw-absolute`}
                                />
                                <Avatar person={comment.commenter.person} />
                                <div
                                    className={`${
                                        threadToggled
                                            ? 'comment-thread-vertical-border'
                                            : ''
                                    }`}
                                />
                            </div>
                            {/* right column */}
                            <div className="tw-flex tw-flex-col tw-mx-2 tw-w-full">
                                <div>
                                    <div
                                        className={`${
                                            threadToggled
                                                ? 'comment-thread-parent-border'
                                                : ''
                                        } comment-body-column ${
                                            isEditing
                                                ? 'comment-body-edit-mode'
                                                : ''
                                        } tw-bg-theme-neutral-200-700`}
                                    >
                                        <div className="tw-flex tw-justify-between">
                                            <div className="tw-flex tw-flex-col tw-mr-4">
                                                <Text
                                                    font="h4"
                                                    color="hi-contrast"
                                                    className="tw-capitalize"
                                                >
                                                    {getFullName(
                                                        comment.commenter.person
                                                    )}
                                                </Text>
                                                <Text
                                                    font="body-sm"
                                                    color="neutral-offset"
                                                >
                                                    {formatSiteInfo()}
                                                </Text>
                                            </div>

                                            <span className="tw-text-neutral-600-100">
                                                <OverflowMenuButton
                                                    items={addtlActions}
                                                    flowDirection="bottom-right"
                                                />
                                            </span>
                                        </div>
                                        {isEditing ? (
                                            <div className="tw-flex tw-flex-col">
                                                <CreateComment
                                                    defaultValue={body}
                                                    submit={handleEdit}
                                                    title=""
                                                    isEditBox
                                                    allowAttachment
                                                    handleAddAttachment={
                                                        handleAddAttachment
                                                    }
                                                    setAttachmentsState={() => {}}
                                                />
                                                {attachments?.length > 0 && (
                                                    <div className="tw-flex tw-gap-2 tw-flex-wrap tw-mt-3">
                                                        {attachments?.map(
                                                            (item, idx) => (
                                                                <AttachmentTile
                                                                    key={idx}
                                                                    item={item}
                                                                    isEditable
                                                                    removeAttachment={() =>
                                                                        handleRemoveAttachment(
                                                                            item
                                                                        )
                                                                    }
                                                                />
                                                            )
                                                        )}
                                                    </div>
                                                )}
                                                <Text
                                                    className="cancel-button tw-px-2 hover:tw-bg-opacity-15 tw-rounded
                                                    hover:tw-bg-theme-primary-500-300 dark:hover:tw-bg-opacity-15"
                                                    font="body-md"
                                                    color="primary"
                                                    onClick={handleCancel}
                                                >
                                                    Cancel
                                                </Text>
                                            </div>
                                        ) : (
                                            <div className="tw-flex tw-flex-col">
                                                {comment.body_orig !=
                                                comment.body ? (
                                                    <TranslationBox
                                                        comment={comment}
                                                        isOrigBody={isOrigBody}
                                                        setIsOrigBody={
                                                            setIsOrigBody
                                                        }
                                                    />
                                                ) : null}
                                                <div>
                                                    <Text
                                                        font="body-md"
                                                        color="hi-contrast"
                                                        className="tw-pt-2"
                                                    >
                                                        {isOrigBody
                                                            ? comment.body_orig
                                                            : body}
                                                        {edited && (
                                                            <Text
                                                                font="body-sm"
                                                                color="neutral-offset"
                                                                className="tw-ml-1"
                                                                tag="span"
                                                            >
                                                                (Edited)
                                                            </Text>
                                                        )}
                                                    </Text>
                                                    {attachments?.length >
                                                        0 && (
                                                        <div className="tw-flex tw-gap-2 tw-flex-wrap tw-mt-3">
                                                            {attachments?.map(
                                                                (item, idx) => (
                                                                    <AttachmentTile
                                                                        key={
                                                                            idx
                                                                        }
                                                                        item={
                                                                            item
                                                                        }
                                                                    />
                                                                )
                                                            )}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <Footer
                                    comment={comment}
                                    toggleChildren={toggleChildren}
                                    childCount={childCount}
                                    showReply={() => setIsReplying(true)}
                                    isReplying={isReplying}
                                />
                                {isReplying && (
                                    <div className="tw-flex tw-flex-col">
                                        <MiniProfile.Md
                                            person={user.person}
                                            detail={usersMetaInfo()}
                                            className="tw-my-4"
                                        />
                                        <CreateComment
                                            submit={handleReply}
                                            title="Leave a reply"
                                        />
                                        <Text
                                            className="cancel-button tw-px-2 hover:tw-bg-opacity-15 tw-rounded
                                            hover:tw-bg-theme-primary-500-300 dark:hover:tw-bg-opacity-15"
                                            font="body-md"
                                            color="primary"
                                            onClick={handleCancel}
                                        >
                                            Cancel
                                        </Text>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </div>

            {isDeleting && (
                <ModalStack
                    position={modals.todo ? 'center' : 'top-half-center'}
                    onOverlayClick={handleConfirmationClose}
                >
                    <Modal.Prompt
                        title="Delete?"
                        prompt="Are you sure you want to delete this comment?"
                        onCancel={handleConfirmationClose}
                        buttons={[
                            {
                                label: 'No',
                                onClick: handleConfirmationClose,
                                color: 'secondary',
                            },
                            {
                                label: 'Yes',
                                onClick: handleDelete,
                                color: 'danger',
                            },
                        ]}
                    />
                </ModalStack>
            )}
        </>
    );
};
