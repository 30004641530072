export function getWeekdaysNameLocals({
	locales,
	weekday,
}: {
	locales: string;
	weekday: 'long' | 'short' | 'narrow';
}) {
	try {
		const { format } = new Intl.DateTimeFormat([locales, 'en'], { weekday });
		return [...Array(7).keys()].map((day) =>
			format(new Date(Date.UTC(2021, 5, day)))
		);
	} catch (err) {
		return ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
	}
}
