import { Observable } from 'rxjs';

// interfaces
import {
	Position,
	PositionNameOnly,
	PositionWithSubPositions,
	CreatePositionResponsibility,
	CreatePositionSection,
	CreatePositionTask,
	CreatePositionCustomerDescription,
} from '@api-interfaces';

// services
import { virtualBrownClient } from '@core/services/django-client';

// helpers
import { Params } from '@helpers';
import { PaginationParams, PaginationResponse } from '@models';

// constants
const BASE_URL = 'positions';

const noOpts = {
	noContract: true,
	noCustomer: true,
	noTag: true,
	noProvider: true,
};

class PositionsService {
	getPositions(
		params?: {
			organization?: number;
			corporate?: boolean;
			add_count?: boolean;
			include_related_secondary_positions?: boolean;
			provider?: number;
		} & PaginationParams,
		noOptions?: boolean
	): Observable<PaginationResponse<PositionWithSubPositions>>;

	getPositions(
		params?: {
			contract?: string | number;
			customer?: string | number;
			check_obligation?: boolean;
			include_related_secondary_positions?: boolean;
			field_set?: 'sow_admin';
			detail_level?: 'simple' | 'full' | 'site_settings';
			provider?: number;
			through_shift?: boolean;
		} & PaginationParams,
		noOptions?: boolean
	): Observable<PaginationResponse<Position>>;

	public getPositions(
		params?: {
			contract?: string | number;
			customer?: string | number;
			check_obligation?: boolean;
			detail_level?: 'simple' | 'full';
			provider?: number;
			organization?: number;
			corporate?: boolean;
			add_count?: boolean;
			include_related_secondary_positions?: boolean;
		} & PaginationParams,
		noOptions?: boolean
	) {
		const queryString = Params.makeQueryString(params || {});

		return virtualBrownClient.get<
			PaginationResponse<
				Position | PositionNameOnly | PositionWithSubPositions
			>
		>(`${BASE_URL}/${queryString}`, noOptions ? noOpts : {});
	}

	getPositionById(
		positionId: number,
		params?: { detail_level?: 'simple' | 'full' }
	) {
		return virtualBrownClient.get<Position>(
			`${BASE_URL}/${positionId}/${Params.makeQueryString(params || {})}`,
			noOpts
		);
	}

	getPositionTasksByPositionId(
		positionId: number,
		params: {
			detail_level: 'simple';
		}
	): Observable<PositionNameOnly>;

	getPositionTasksByPositionId(positionId: number): Observable<Position>;

	getPositionTasksByPositionId(
		positionId: number,
		params?: { detail_level?: 'simple' }
	) {
		return virtualBrownClient.get<Position>(
			`${BASE_URL}/${positionId}/${Params.makeQueryString(params || {})}`,
			noOpts
		);
	}

	public updatePositionByPositionId = (positionId: number, payload) => {
		!payload.essential_duties_summary
			? delete payload.essential_duties_summary
			: '';
		!payload.essential_duties_title
			? delete payload.essential_duties_title
			: '';
		!payload.essential_responsibilities_title
			? delete payload.essential_responsibilities_title
			: '';
		!payload.tasks_title ? delete payload.tasks_title : '';
		return virtualBrownClient.put<Position>(
			`${BASE_URL}/${positionId}/`,
			payload
		);
	};

	public createPositionResponsibility = (
		payload: CreatePositionResponsibility
	): Observable<any> =>
		virtualBrownClient.post(`${BASE_URL}/responsibilities/`, payload);

	public deletePositionResponsibilityById = (params: {
		positionId: number;
		responsibilityId: number;
	}): Observable<any> =>
		virtualBrownClient.delete(
			`${BASE_URL}/${params.positionId}/responsibilities/${params.responsibilityId}/`
		);

	public createPositionTask = (
		payload: CreatePositionTask
	): Observable<{
		id: number;
		position: Position;
		task: Position['tasks'][0];
	}> => virtualBrownClient.post(`${BASE_URL}/tasks/`, payload);

	public deletePositionTaskById = (params: {
		positionId: number;
		taskId: number;
	}): Observable<any> =>
		virtualBrownClient.delete(
			`${BASE_URL}/${params.positionId}/tasks/${params.taskId}/`
		);

	public createPositionSection = (
		payload: CreatePositionSection
	): Observable<any> =>
		virtualBrownClient.post(`${BASE_URL}/sections/`, payload);

	public updatePositionSectionById = (
		params: { positionId: number; sectionId: number },
		payload: CreatePositionSection
	): Observable<any> =>
		virtualBrownClient.put(
			`${BASE_URL}/${params.positionId}/sections/${params.sectionId}/`,
			payload
		);

	public deletePositionSectionById = (params: {
		positionId: number;
		sectionId: number;
	}): Observable<any> =>
		virtualBrownClient.delete(
			`${BASE_URL}/${params.positionId}/sections/${params.sectionId}/`
		);

	public getPositionDescription = (
		params: { positionId: number; customerId: number },
		noOptions?: boolean
	): Observable<any> =>
		virtualBrownClient.get(
			`${BASE_URL}/${params.positionId}/customer_descriptions/${params.customerId}/`,
			noOptions ? noOpts : {}
		);

	public createPositionCustomerDescription = (
		payload: CreatePositionCustomerDescription
	): Observable<any> =>
		virtualBrownClient.post(`${BASE_URL}/customer_descriptions/`, payload);

	public updatePositionCustomerDescriptionById = (
		params: { positionId: number; customerDescriptionId: number },
		payload: CreatePositionCustomerDescription
	): Observable<any> =>
		virtualBrownClient.put(
			`${BASE_URL}/${params.positionId}/customer_descriptions/${params.customerDescriptionId}/`,
			payload
		);

	public deletePositionCustomerDescriptionById = (params: {
		positionId: number;
		customerDescriptionId: number;
	}): Observable<any> =>
		virtualBrownClient.delete(
			`${BASE_URL}/${params.positionId}/customer_descriptions/${params.customerDescriptionId}/`
		);
}

export const positionsService = new PositionsService();
