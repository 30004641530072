import { InspectionComment } from '@api-interfaces';

// context
import { Text } from '@atoms';
import { useAuditModalContext } from '@modals/_ds2/audit/_context/context';

// components
import { CardComments } from '@new';
import { EmployeeDetails } from '@new/MiniProfiles/_components/employee-details';

interface Props {
  comments: InspectionComment[];
}

export const Comments = ({ comments }: Props) => {
  const {
    state: { inspection },
  } = useAuditModalContext();
  return comments?.length ? (
    <div className="tw-flex tw-flex-col tw-space-y-3">
      {comments.map((comment, i) => (
        <div key={i}>
          <CardComments
            className="tw-p-6 tw-w-full"
            style={{ margin: 0 }}
            textClassName="tw-not-italic tw-text-neutral-900 dark:tw-text-neutral-100"
            bgClassName="tw-bg-neutral-200 dark:tw-bg-neutral-900"
            person={comment.created_by?.person}
            detail={
              <EmployeeDetails
                client={inspection?.contract?.customer?.name}
                site={inspection?.contract?.name ?? ''}
                jobTitle={comment.created_by?.positions?.[0]?.name}
                hiredDate={comment.created_by?.hired_date}
              />
            }
            comments={comment.comment}
          />
        </div>
      ))}
    </div>
  ) : (
    <div className="tw-w-full tw-py-6">
      <Text font="body-md" color="neutral-offset">
        No Comments Available
      </Text>
    </div>
  );
};
