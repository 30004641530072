import { KnowledgeCenterCategory } from '@api-interfaces';
import { SummaryChart, SummaryParams } from '@models';

export interface KnowledgeCenterTrainingPlansSummaryParams<T>
	extends SummaryParams<T> {
	knowledgecenter?: number;
	employee?: number | string;
	learningpath?: number;
	skill?: number;
	course?: number;
	assigner?: number;
	trainee?: number | string;
	position?: number | string;
	category?: number | string;
	is_site_specific?: boolean;
	is_archived?: boolean;
	is_featured?: boolean;
	is_third_party?: boolean;
	is_new_course?: boolean;
	search?: string;
	status?: string;
	limit?: number;
	offset?: number;
	organization?: number;
	site_specific?: string | number;
	shift_ids?: string;
	from?: Date;
	to?: Date;
}

export namespace KnowledgeCenterTrainingPlansSummary {
	export enum Chart {
		GetAdminAssignedEmployees = 0,
		GetCourseCountsByCategory = 1,
		GetMyTrainingPlanCourses = 2,
		GetMyTranscriptCounts = 3,
		GetCoursesCompletionHistory = 4,
		GetAdminAssignedClients = 6,
		GetCoursesAssignedByAdmin = 7,
		GetMyCourseLibrary = 8,
		GetCourseCountsBySkill = 9,
		GetSkillsAssignedByAdmin = 10,
		GetMyTranscriptCompletedCourses = 11,
		GetMyTranscriptCompletedSkills = 12,
		GetMyAssignedSkills = 13,
		GetMyTranscriptCompletedCoursesBySkill = 14,
		GetSkillList = 15,
		GetSkillConfigurations = 16,
		GetAdminAssignedPositions = 18,
		GetAdminAssignedCourses = 19,
		GetAdminAssignedCategories = 20,
		UnassignSkillsGetPositions = 22,
		UnassignSkillsGetEmployees = 23,
		UnassignSkillsGetSkills = 24,
		GetTranscriptsEmployeesCount = 26,
		GetMyTranscriptCompletedCounts = 27,
		GetMyTranscriptCompleted = 28,
	}

	export interface AdminAssignedEmployee {
		id: number;
		name: string;
	}

	export interface AdminAssignedEmployeesSummary
		extends SummaryChart<AdminAssignedEmployee[]> {
		chart: 'GetAdminAssignedEmployees';
		number: '0';
	}

	export interface CourseCountsByCategory {
		id: number;
		name: string;
		description: string;
		course_count: number;
		file_name: string;
	}

	export interface CourseCountsByCategorySummary
		extends SummaryChart<CourseCountsByCategory[]> {
		chart: 'GetCourseCountsByCategory';
		number: '1';
	}

	export interface MyTrainingPlanCourse {
		course_id: number;
		course_name: string;
		description: string;
		create_date: string;
		course_cover_photo_url: string;
		is_third_party: boolean;
		is_site_specific: boolean;
		is_archived: boolean;
		author: string;
		trainingplan_id: number;
		skill_id: number;
		skill_name: string;
		skill_description: string;
		skill_is_archived: boolean;
		learningpath_id: number;
		learningpath_name: string;
		start_date: string;
		complete_date: null | string;
		cover_photo_url: string;
		is_featured: boolean;
		effective_date: string;
		duration: number;
		recurrence: boolean;
		reassign_after: null | number;
		due_date: string;
		course_categories: {
			id: number;
			name: string;
		}[];
		status: 'Past Due' | '';
	}

	export interface TrainingPlanCoursesSummary
		extends SummaryChart<MyTrainingPlanCourse[]> {
		chart: 'GetMyTrainingPlanCourses';
		assigned_count: number;
		completed_count: number;
		past_due_count: number;
		started_and_past_due_count: number;
		started_count: number;
		training_compliance: number;
	}

	export interface MyTranscriptCounts {
		total_assigned_training_course: number;
		total_past_due: number;
		total_completed_course: number;
		total_completed_self_initiated_course: number;
		training_compliance: number;
	}

	export interface MyTranscriptsCountSummary
		extends SummaryChart<MyTranscriptCounts[]> {
		chart: 'GetMyTranscriptCounts';
		number: '3';
	}

	export interface CoursesCompletionHistory {
		id: number;
		name: string;
		description: string;
		course_count: number;
		completion_percentage: number;
	}

	export interface CoursesCompletionHistorySummary
		extends SummaryChart<CoursesCompletionHistory[]> {
		chart: 'GetCoursesCompletionHistory';
		number: '4';
	}

	export interface AdminAssignedClient {
		id: number;
		name: string;
		description: string;
		is_featured: boolean;
		cover_photo_url: string;
		latest_update_date: string;
		status: string;
	}

	export interface AdminAssignedClientSummary
		extends SummaryChart<AdminAssignedClient[]> {
		chart: 'GetAdminAssignedClients';
		number: '6';
	}

	export interface CoursesAssignedByAdmin {
		id: number;
		name: string;
		description: string;
		cover_photo_url: string;
		course_categories: Array<{
			id: number;
			name: string;
			description: string;
		}>;
		trainee_count: number;
	}

	export interface CoursesAssignedByAdminSummary
		extends SummaryChart<CoursesAssignedByAdmin[]> {
		chart: 'GetCoursesAssignedByAdmin';
		number: '7';
	}

	export interface MyTrainingLibrary {
		id: number;
		name: string;
		description: string;
		photo_url: string;
		categories: Array<{
			id: number;
			name: string;
			description: string;
		}>;
		last_completion_date: string;
		grouping: string;
	}

	export interface MyCourseLibrary {
		id: number;
		name: string;
		create_date: string;
		description: string;
		cover_photo_url: string;
		is_third_party: boolean;
		is_featured: boolean;
		is_site_specific: boolean;
		course_categories: KnowledgeCenterCategory[];
		specific_to_sites: Array<{
			customer: { id: number; name: string };
			id: number;
			name: string;
		}>;
		update_date: string;
	}

	export interface MyCourseLibrarySummary
		extends SummaryChart<MyCourseLibrary[]> {
		chart: 'GetMyCourseLibrary';
		course_category_count: 0;
		featured_course_count: 0;
		site_specific_course_count: 0;
		third_party_course_count: 0;
		new_course_count: 0;
		my_course_count: 0;
	}

	export interface CourseCountBySkill {
		id: number;
		name: string;
		description: string;
		is_site_specific: boolean;
		learningpath_id: number;
		all_course_count: number;
		specific_to_sites:
			| null
			| {
					id: number;
					name: string;
					customer: {
						id: number;
						name: string;
					};
			  }[];
		background_file_name: string;
		icon_file_name: string;
	}

	export interface CourseCountBySkillSummary
		extends SummaryChart<CourseCountBySkill[]> {
		chart: 'GetCourseCountsBySkill';
		number: '9';
	}

	export interface SkillsAssignedByAdmin {
		id: number;
		name: string;
		description: string;
		is_archived: boolean;
		learningpath_id: number;
		course_count: number;
		trainee_count: number;
	}

	export interface SkillsAssignedByAdminSummary
		extends SummaryChart<SkillsAssignedByAdmin[]> {
		chart: 'GetSkillsAssignedByAdmin';
		number: '10';
	}

	export interface MyTranscriptCompletedCourse {
		skill_id: null | number;
		skill_name: null | string;
		learningpath_id: null | number;
		course_id: number;
		course_name: string;
		cover_photo_url: string;
		description: string;
		is_archived: boolean;
		is_self_initiated: boolean;
		course_categories: KnowledgeCenterCategory[];
		latest_complete_date: string;
		recurrence: boolean;
		reassign_after: number;
		due_date: string;
		status: 'Complete';
	}

	export interface MyTranscriptCompletedCoursesSummary
		extends SummaryChart<MyTranscriptCompletedCourse[]> {
		chart: 'GetMyTranscriptCompletedCourses';
		number: '11';
	}

	export interface MyTranscriptCompletedSkill {
		skill_id: null | number;
		skill_name: null | string;
		learningpath_id: null | number;
		course_id: number;
		course_name: string;
		cover_photo_url: string;
		description: string;
		is_archived: boolean;
		is_self_initiated: boolean;
		course_categories: KnowledgeCenterCategory[];
		latest_complete_date: string;
		recurrence: boolean;
		reassign_after: number;
		due_date: string;
		status: 'Complete';
	}

	export interface MyTranscriptCompletedSkillsSummary
		extends SummaryChart<MyTranscriptCompletedSkill[]> {
		chart: 'GetMyTranscriptCompletedSkills';
		number: '12';
	}

	export interface MyAssignedSkill {
		trainingplan_learningpath_id: null;
		learningpath_id: number;
		skill_id: number;
		skill_name: string;
		skill_description: string;
		skill_is_archived: boolean;
		total_completed_courses: number;
		total_courses: number;
		complete_percentage: number;
		background_file_name: string;
		icon_file_name: string;
		training_plan_id: 15849;
	}

	export interface MyAssignedSkillsSummary
		extends SummaryChart<MyAssignedSkill[]> {
		chart: 'GetMyAssignedSkills';
		number: '13';
	}

	export interface MyTranscriptCompletedCoursesBySkill {
		id: number;
		total_courses: number;
		latest_complete_date: string;
		name: string;
		description: string;
	}

	export interface MyTranscriptCompletedCoursesBySkillSummary
		extends SummaryChart<MyTranscriptCompletedCoursesBySkill[]> {
		chart: 'GetMyTranscriptCompletedCoursesBySkill';
		number: '14';
	}

	export interface SkillListItem {
		id: number;
		name: string;
		description: string;
		is_archived: boolean;
		learningpath_id: number;
		total_course: number;
	}

	export interface SkillListSummary extends SummaryChart<SkillListItem[]> {
		chart: 'GetSkillList';
		number: '15';
	}

	export interface SkillConfiguration {
		id: number;
		skill_id: number;
		organizations: Array<{
			id: number;
			name: string;
		}>;
		contracts: Array<{
			id: number;
			name: string;
			customer: {
				id: number;
				name: string;
			};
		}> | null;
		positions: Array<{
			id: number;
			name: string;
		}> | null;
	}

	export interface SkillConfigurationsSummary
		extends SummaryChart<SkillConfiguration[]> {
		chart: 'GetSkillConfigurations';
		number: '16';
	}

	export interface AdminAssignedPosition {
		id: number;
		name: string;
	}

	export interface AdminAssignedPositionsSummary
		extends SummaryChart<AdminAssignedPosition[]> {
		chart: 'GetAdminAssignedPositions';
		number: '18';
	}

	export interface AdminAssignedCourse {
		id: number;
		name: string;
		categories: { id: number; name: string }[];
	}

	export interface AdminAssignedCoursesSummary
		extends SummaryChart<AdminAssignedCourse[]> {
		chart: 'GetAdminAssignedCourses';
		number: '19';
	}

	export interface AdminAssignedCategory {
		id: number;
		name: string;
	}

	export interface AdminAssignedCategoriesSummary
		extends SummaryChart<AdminAssignedCategory[]> {
		chart: 'GetAdminAssignedCategories';
		number: '20';
	}

	export interface UnassignSkillsGetPosition {
		id: number;
		name: string;
	}

	export interface UnassignSkillsGetPositionsSummary
		extends SummaryChart<UnassignSkillsGetPosition[]> {
		chart: 'UnassignSkillsGetPositions';
		number: '22';
	}

	export interface UnassignSkillsGetEmployee {
		id: number;
		name: string;
	}

	export interface UnassignSkillsGetEmployeesSummary
		extends SummaryChart<UnassignSkillsGetEmployee[]> {
		chart: 'UnassignSkillsGetEmployees';
		number: '23';
	}

	export interface UnassignSkillsGetSkill {
		id: number;
		name: string;
		description: string;
		is_archived: boolean;
		learningpath_id: number;
		total_course: number;
	}

	export interface UnassignSkillsGetSkillsSummary
		extends SummaryChart<UnassignSkillsGetSkill[]> {
		chart: 'UnassignSkillsGetSkills';
		number: '24';
	}

	export interface TranscriptsEmployeesCount {
		compliance_percentage: number;
		courses_assigned: number;
		courses_completed: number;
		courses_past_due: number;
		last_completed: null | Date;
		total_compliant_count: number;
		total_employees: number;
	}

	export interface TranscriptsEmployeesCountSummary
		extends SummaryChart<TranscriptsEmployeesCount[]> {
		chart: 'GetTranscriptsEmployeesCount';
		number: '26';
	}

	export interface MyTranscriptCompletedCounts {
		total_completed_courses: number;
		total_completed_skills: number;
		total_self_initiated: number;
		total_assigned_training_course: number;
		total_related_journey: number;
		total_completed_related_journey: number;
		total_training_planned: number;
		total_training_in_progress: number;
		total_completed_course: number;
		total_completed_skills_since_incep: number;
		compliance_percentage: number;
		skill: number;
		total_training_past_due: number;
	}

	export interface MyTranscriptCompletedCountsSummary
		extends SummaryChart<MyTranscriptCompletedCounts> {
		chart: 'GetMyTranscriptCompletedCounts';
		number: '27';
	}

	export interface MyTranscriptCompleted
		extends SummaryChart<MyTrainingLibrary[]> {
		chart: 'GetTranscriptCompleted';
		number: '28';
	}

	export type SummaryReturnType = {
		[Chart.GetAdminAssignedEmployees]: AdminAssignedEmployeesSummary;
		[Chart.GetCourseCountsByCategory]: CourseCountsByCategorySummary;
		[Chart.GetMyTrainingPlanCourses]: TrainingPlanCoursesSummary;
		[Chart.GetMyTranscriptCounts]: MyTranscriptsCountSummary;
		[Chart.GetCoursesCompletionHistory]: CoursesCompletionHistorySummary;
		[Chart.GetAdminAssignedClients]: AdminAssignedClientSummary;
		[Chart.GetCoursesAssignedByAdmin]: CoursesAssignedByAdminSummary;
		[Chart.GetMyCourseLibrary]: MyCourseLibrarySummary;
		[Chart.GetCourseCountsBySkill]: CourseCountBySkillSummary;
		[Chart.GetSkillsAssignedByAdmin]: SkillsAssignedByAdminSummary;
		[Chart.GetMyTranscriptCompletedCourses]: MyTranscriptCompletedCoursesSummary;
		[Chart.GetMyTranscriptCompletedSkills]: MyTranscriptCompletedSkillsSummary;
		[Chart.GetMyAssignedSkills]: MyAssignedSkillsSummary;
		[Chart.GetMyTranscriptCompletedCoursesBySkill]: MyTranscriptCompletedCoursesBySkillSummary;
		[Chart.GetSkillList]: SkillListSummary;
		[Chart.GetSkillConfigurations]: SkillConfigurationsSummary;
		[Chart.GetAdminAssignedPositions]: AdminAssignedPositionsSummary;
		[Chart.GetAdminAssignedCourses]: AdminAssignedCoursesSummary;
		[Chart.GetAdminAssignedCategories]: AdminAssignedCategoriesSummary;
		[Chart.UnassignSkillsGetPositions]: UnassignSkillsGetPositionsSummary;
		[Chart.UnassignSkillsGetEmployees]: UnassignSkillsGetEmployeesSummary;
		[Chart.UnassignSkillsGetSkills]: UnassignSkillsGetSkillsSummary;
		[Chart.GetTranscriptsEmployeesCount]: TranscriptsEmployeesCountSummary;
		[Chart.GetMyTranscriptCompletedCounts]: MyTranscriptCompletedCountsSummary;
		[Chart.GetMyTranscriptCompleted]: MyTranscriptCompleted;
	};
}
