import { forwardRef } from 'react';

// components
import { Dropdown } from '../dropdown';
import { ErrorText } from '../error-text';
import { Label } from '../label';

// interfaces
import { FieldDropdownProps } from './field-dropdown.interfaces';

export const FieldDropdown = forwardRef<HTMLInputElement, FieldDropdownProps>(
  ({ id, label, labelFont, error, required, ...props }, ref) => {
    return (
      <fieldset data-ds2="field-dropdown" className="tw-w-full tw-space-y-1.5">
        <Label
          htmlFor={id}
          label={label}
          font={labelFont}
          className="tw-leading-4"
          required={required}
        />
        <Dropdown ref={ref} id={id} error={!!error} {...props} />
        <ErrorText error={error} />
      </fieldset>
    );
  }
);

FieldDropdown.defaultProps = {
  labelFont: 'body-sm',
};
