import { createContext, useContext } from 'react';
import * as React from 'react';

// interfaces

// services
import { inspectionsService } from '@apis';
import { AuditModal } from '@app/modals/_ds2';
import { Action } from '@components/_new/HyperLauncher/_actions/interfaces';
import { InspectionsParams } from '@core/apis/index.interfaces';
import { sendError } from '@core/helpers';
import { modalService } from '@core/services';
import { DS2_BOTTOM_SHEET_OPTIONS } from '@services/modal-service/modal-service.interfaces';

import { HyperState } from './hyper.interfaces';
import { HyperReducer } from './hyper.reducer';

const initialState: HyperState = {
  actions: [],
  setHyperActions: () => null,
  hyperLauncherToggled: false,
  setHyperLauncherToggled: () => null,
  toggleHyperLauncherKeyboard: () => null,
  searchMostRecentAudits: () => null,
};

const Context = createContext<HyperState>(initialState);

export const HyperLauncherProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(HyperReducer, initialState);

  function setHyperActions(actions: Action[]) {
    dispatch({
      type: 'SET_ACTIONS',
      payload: actions,
    });
  }

  function setHyperLauncherToggled(toggled: boolean) {
    dispatch({
      type: 'TOGGLE_HYPER',
      payload: toggled,
    });
  }

  function toggleHyperLauncherKeyboard(e: React.KeyboardEvent) {
    if (state.hyperLauncherToggled && e.key === 'Escape') {
      e.preventDefault();
      e.stopPropagation();
      setHyperLauncherToggled(false);
    }
    if (navigator.userAgent.includes('Macintosh')) {
      if (e.key === 'k' && e.metaKey) {
        e.preventDefault();
        e.stopPropagation();
        setHyperLauncherToggled(!state.hyperLauncherToggled);
      }
    } else if (e.key === 'k' && e.ctrlKey) {
      e.preventDefault();
      e.stopPropagation();
      setHyperLauncherToggled(!state.hyperLauncherToggled);
    }
  }

  function searchMostRecentAudits() {
    const params: InspectionsParams = {
      limit: 5,
      offset: 0,
      ordering: '-inspection_date',
      most_recent: true,
      status: 'COMPLETE',
      detail_level: 'simple',
    };

    inspectionsService.getInspections(params).subscribe({
      next: (res) => {
        const auditActions: Action[] = res.results.map((audit) => ({
          category: 'most recent audits',
          keyword: `${audit.inspected_by.person.first_name} ${audit.inspected_by.person.last_name}`,
          subtext: audit.audit_score.toString(),
          sortCharacter: '*',
          permissions: ['Quality Audits'],
          dontClose: true,
          onClick: () => {
            setHyperLauncherToggled(false);
            modalService.open({
              component: <AuditModal id={audit.id} />,
              ...DS2_BOTTOM_SHEET_OPTIONS,
            });
          },
        }));

        setHyperActions(auditActions);
      },
      error: sendError(),
    });
  }

  const value = {
    ...state,
    setHyperActions,
    setHyperLauncherToggled,
    toggleHyperLauncherKeyboard,
    searchMostRecentAudits,
  };
  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export function useHyperLauncher() {
  return useContext(Context);
}
