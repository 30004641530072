import { forwardRef } from 'react';

// interfaces
import { textFontMap } from '@atoms/text/text.utils';

import { InputRadioProps } from './input-radio.interfaces';

// helpers

export const InputRadio = forwardRef<HTMLInputElement, InputRadioProps>(
  ({ label, style, className, ...props }, ref) => {
    return (
      <label
        data-ds2="input-radio"
        className={`tw-inline-flex tw-items-center ${className}`}
        style={style}
      >
        <input
          ref={ref}
          type="radio"
          className="tw-opacity-0 tw-w-0 tw-h-0 tw-peer"
          {...props}
        />
        <div
          className={`${textFontMap['body-md'].className} tw-relative tw-inline-flex tw-items-center
          tw-text-theme-neutral-600-500 peer-checked:tw-text-theme-neutral-900-100
          
          before:tw-inline-block before:tw-content-[""]
          before:tw-ring-2 before:tw-ring-inset before:tw-ring-theme-neutral-400-600
          before:tw-w-4 before:tw-h-4 before:tw-rounded-full before:tw-mr-2 
          
          peer-checked:before:tw-ring-4 peer-checked:before:tw-ring-theme-neutral-200-900 peer-checked:before:tw-ring-opacity-75
          peer-checked:before:tw-bg-theme-primary-500-300

          peer-disabled:before:tw-ring-theme-neutral-300-700 peer-disabled:peer-checked:before:tw-bg-theme-neutral-600-500

          after:tw-hidden after:tw-content-[""] after:tw-z-[-1]
          after:tw-absolute after:tw-w-6 after:tw-h-6 after:tw-rounded-full
          after:tw-bg-theme-neutral-200-700 after:tw--left-1
          
          hover:after:tw-inline-block peer-focus:after:tw-inline-block

          peer-checked:hover:after:tw-bg-theme-primary-500-300 peer-checked:hover:after:tw-bg-opacity-8
          peer-checked:peer-focus:after:tw-bg-theme-primary-500-300 peer-checked:peer-focus:after:tw-bg-opacity-8

          peer-disabled:after:tw-hidden peer-disabled:peer-focus:after:tw-hidden
          peer-disabled:tw-cursor-not-allowed
          `}
          style={textFontMap['body-md'].style}
        >
          {label}
        </div>
      </label>
    );
  }
);

InputRadio.defaultProps = {
  className: '',
};
