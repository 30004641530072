import { Box, Text } from '@atoms';

import { Attachments } from './attachments';
import { Employees } from './employees';
import { OverallComment } from './overall-comment';

export const Overview = () => {
  return (
    <Box id="audit-modal-overview" className="tw-p-4 sm:tw-p-10" rounded>
      <Text font="h2" color="primary" className="tw-mb-8">
        Overview
      </Text>

      <OverallComment />

      <Employees />

      <Attachments />
    </Box>
  );
};
