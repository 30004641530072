import { Text } from '@atoms';

// images
import serviceLogEmptySvg from '@core/assets/Icons/Empty/service_log_empty.svg';

export const EmptyServiceLogs = () => (
  <div className="tw-py-6 tw-space-y-6">
    <img src={serviceLogEmptySvg} className="tw-m-auto" />
    <Text font="body-md" color="neutral-offset" className="tw-text-center">
      There is no service history for this area.
    </Text>
  </div>
);
