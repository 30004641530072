// interfaces
import { PartialKpiConfig, GoalText } from '../site-scorecard.config.interface';

export const SAFETY_KPIS: PartialKpiConfig[] = [
	{
		icon: 'medal',
		title: 'TRIR',
		subtitle: '',
		goalType: 'int',
		goalText: GoalText.noMoreThan,
		key: 'trir',
	},
	{
		icon: 'flag',
		title: 'Good Catches',
		subtitle: 'Total #',
		goalType: 'int',
		goalText: GoalText.atLeast,
		key: 'good_catch',
	},
	{
		icon: 'user-hard-hat',
		title: 'Recordables',
		subtitle: 'Total #',
		goalType: 'int',
		goalText: GoalText.noMoreThan,
		key: 'recordable_count',
	},
	{
		icon: 'calendar',
		title: 'Work Related Incidents',
		subtitle: 'Days Since Last',
		goalType: 'int',
		goalText: GoalText.moreThan,
		key: 'work_related_incident_days_since',
	},
	{
		icon: 'calendar',
		title: 'Recordables',
		subtitle: 'Days Since Last',
		goalType: 'int',
		goalText: GoalText.moreThan,
		key: 'recordable_days_since',
	},
	{
		icon: 'file-contract',
		title: 'Immediately Report All Critical Safety Incidents and Accidents',
		subtitle: 'On time count',
		goalType: 'int',
		goalText: GoalText.noMoreThan,
		key: 'critical_csia_report',
	},
	{
		icon: 'file-contract',
		title: 'Report All Non-Medical Incidents',
		subtitle: 'On time count',
		goalType: 'int',
		goalText: GoalText.noMoreThan,
		key: 'non_medical_incident_report',
	},
	{
		icon: 'list-ol',
		title: 'Chemical Management',
		subtitle: 'Guideline adherence count',
		goalType: 'int',
		goalText: GoalText.moreThan,
		key: 'chemical_mgmt',
	},
	{
		icon: 'hand',
		title: 'Interruptions to Service / Operations',
		subtitle: 'Interruption count',
		goalType: 'int',
		goalText: GoalText.noMoreThan,
		key: 'interrupt_operations',
	},
	{
		icon: 'graduation-cap',
		title: 'Training',
		subtitle: 'Avg %',
		goalType: 'percentage',
		goalText: GoalText.atLeast,
		key: 'safety_training',
	},
];
