import { useEffect, useRef } from 'react';

// interfaces
import { Subscription } from 'rxjs';

// context
import { inspectionsService } from '@apis';
import { Text } from '@atoms';
import { sendError } from '@helpers';
import { useAuditModalContext } from '@modals/_ds2/audit/_context/context';

// components
import { ImageList } from '@new';

import { Divider } from './divider';

// services

// helpers

export const Attachments = () => {
  const {
    state: { inspection, areas, attachments, modals },
    dispatch,
  } = useAuditModalContext();
  const subscriptions = useRef<{ [key: string]: Subscription }>({});

  const openPhotoIndex = (photo) => {
    dispatch({
      type: 'SET_PHOTO_VIEWER_AREAS',
      payload: areas,
    });
    dispatch({
      type: 'SET_PHOTO_VIEWER_INDEX',
      payload: areas
        .flatMap((area) => area.media)
        .findIndex((area) => area.id === photo.id),
    });
    dispatch({
      type: 'SET_MODALS',
      payload: {
        ...modals,
        'audit-attachments': true,
      },
    });
  };

  useEffect(() => {
    if (inspection && areas) {
      subscriptions.current['audit-modal-attachments'] = inspectionsService
        .getInspectionMedia(inspection.id, { limit: 1000 })
        .subscribe({
          next: (res) => {
            dispatch({ type: 'SET_ATTACHMENTS', payload: res.results });
          },
          error: sendError({
            toastMessage:
              'There was an error retrieving Audit Attachments data.',
            callback: () => dispatch({ type: 'SET_ATTACHMENTS', payload: [] }),
          }),
        });
    }

    return () =>
      Object.values(subscriptions.current).forEach((subscription) =>
        subscription.unsubscribe()
      );
  }, [inspection, areas]);

  return (
    Boolean(attachments?.length) && (
      <>
        <Divider />
        <div>
          <Text font="h3" color="hi-contrast" className="tw-mb-4">
            Attachments
          </Text>

          <ImageList
            media={attachments.map((attachment) => ({
              ...attachment,
              origin: null,
            }))}
            onMediaClick={openPhotoIndex}
          />
        </div>
      </>
    )
  );
};
