import { WorkItem } from '@api-interfaces';
import { Feedback } from '@apis/public/feedback/interfaces';
import { DropdownItem } from '@atoms/form-ds2/dropdown/dropdown.interfaces';

export const workItemStatuses = [
	{
		key: 'PENDING_REVIEW',
		name: 'Pending',
	},
	{
		key: 'ACCEPTED',
		name: 'Accepted',
	},
	{
		key: 'REJECTED',
		name: 'Rejected',
	},
	{
		key: 'FORWARDED',
		name: 'Forwarded',
	},
].map((status, index) => ({ ...status, id: index })) as DropdownItem<{
	key: Feedback['status'];
}>[];

export const workItemTypes = [
	{
		key: 'report',
		name: 'Problem',
	},
	{
		key: 'request',
		name: 'Service Request',
	},
].map((status, index) => ({ ...status, id: index })) as DropdownItem<{
	key: WorkItem['type'];
}>[];
