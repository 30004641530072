import { Person } from '@api-interfaces';
import { SummaryChart, SummaryParams } from '@models';

export interface ContractScorecardsSummaryParams<T> extends SummaryParams<T> {
	site_scorecard_id?: number;
	top_level?: 'client' | 'vendor' | 'site';
}

export namespace ContractScorecardsSummary {
	export enum Chart {
		GetContractScorecardOverallCounts = 1,
		GetContractScorecardCountsByClient = 2,
		GetContractScorecardCountsByClients = 3,
		GetScorecardViewingSummary = 4,
		GetScorecardFrontPageScores = 5,
	}

	export interface ContractScorecardOverallCounts {
		allocations: {
			service_id: number;
			service_name: string;
			total_pct: number;
		}[];
		service_models_count: number;
		sites_count: number;
		total_sq_ft: number;
		overall_score: number;
	}

	export interface ContractScorecardOverallCountsSummary
		extends SummaryChart<ContractScorecardOverallCounts> {
		chart: 'GetContractScorecardOverallCounts';
		number: '1';
	}

	export interface ContractScorecardCountsByClient {
		average_overall_score: number;
		id: number;
		name: string;
		org_comment: string;
		org_customer_contact: null | Omit<Person, 'id'>[];
		service_allocations: {
			id: number;
			name: string;
			percent_allocation: number;
		}[];
		service_models: {
			description: string;
			display_name: string;
			id: number;
			name: string;
		}[];
		service_models_count: number;
		sites_count: number;
		total_sq_foot_serviced: number;
		logo_url: null | string;
		manager_receives_emails: boolean;
		org_customer_receives_emails: boolean;
		org_manager_contact: null | Omit<Person, 'id'>[];
		org_manager_receives_emails: boolean;
	}

	export interface ContractScorecardCountsByClientSummary
		extends SummaryChart<ContractScorecardCountsByClient[]> {
		chart: 'GetContractScorecardCountsByClient';
		number: '2';
	}

	export interface ContractScorecardCountsByClients {
		average_overall_score: number;
		clients_count: number;
		service_allocation: {
			id: number;
			name: string;
			percent_allocation: number;
		}[];
		service_models_count: number;
		total_sq_foot_serviced: number;
	}

	export interface ContractScorecardCountsByClientsSummary
		extends SummaryChart<ContractScorecardCountsByClients> {
		chart: 'GetContractScorecardCountsByClients';
		number: '3';
	}

	export interface ContractScorecardViewingSummary {
		number_of_contracts: number;
		number_of_customers: number;
		number_of_parent_services: number;
		number_of_service_providers: number;
		number_of_services: number;
		number_of_sites: number;
		overall_score: number;
	}

	export interface ScorecardFrontPageScores {
		results: {
			contract_scores: [
				{
					contract_id: number;
					contract_name: string;
					customer_id: number;
					customer_name: string;
					overall_score: string;
					service_provider_name: string;
				},
			];
			overall_score: number;
		};
	}
	export interface SummaryReturnType {
		[Chart.GetContractScorecardOverallCounts]: ContractScorecardOverallCountsSummary;
		[Chart.GetContractScorecardCountsByClient]: ContractScorecardCountsByClientSummary;
		[Chart.GetContractScorecardCountsByClients]: ContractScorecardCountsByClientsSummary;
		[Chart.GetScorecardViewingSummary]: ContractScorecardViewingSummary;
		[Chart.GetScorecardFrontPageScores]: ScorecardFrontPageScores;
	}
}
