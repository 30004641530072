// helpers
import { Observable } from 'rxjs';

// interfaces
import {
	FinancialsPurchaseOrdersSummary,
	FinancialsPurchaseOrdersSummaryParams,
} from '@api-interfaces';
import { Params, TimeUtils } from '@helpers';
import { virtualBrownClient } from '@services';

const BASE_URL = 'financials/purchaseorders/summary';

class FinancialPurchaseOrdersSummaryService {
	public getSummaries<T extends FinancialsPurchaseOrdersSummary.Chart>(
		params: FinancialsPurchaseOrdersSummaryParams<T>
	): Observable<FinancialsPurchaseOrdersSummary.SummaryReturnType[T][]> {
		const query: any = { ...params };

		if (query.chart) {
			query.chart = query.chart.join(',');
		}

		if (query.from) {
			query.from = TimeUtils.format(query.from, 'YYYY-MM-DD');
		}

		if (query.to) {
			query.to = TimeUtils.format(query.to, 'YYYY-MM-DD');
		}

		return virtualBrownClient.get(
			`${BASE_URL}/${Params.makeQueryString(query)}`,
			{
				noCustomer: params.customer != undefined,
				noContract: params.contract != undefined,
				noTag: params.tag != undefined,
			}
		);
	}
}

export const financialPurchaseOrdersSummaryService =
	new FinancialPurchaseOrdersSummaryService();
