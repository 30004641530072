import { IconBadge, Avatar, Text } from '@atoms';
import { getFullName } from '@helpers';
import { useBreakpoints } from '@hooks';

// components

// interfaces
import { ModalTitleProps } from './modal-title.interfaces';

export const ModalTitle = ({
    icon,
    person,
    title,
    subtitle,
    avatarSize,
}: ModalTitleProps) => {
    const { sm } = useBreakpoints();
    return (
        <div className="tw-inline-flex tw-items-center tw-gap-4">
            {icon && (
                <IconBadge
                    className="tw-flex-shrink-0 tw-hidden sm:tw-grid"
                    type="primary"
                    size={sm ? 'lg' : 'md'}
                    icon={icon}
                />
            )}
            {!icon && person && (
                <Avatar size={avatarSize || 'xl'} person={person} />
            )}
            <div className="tw-flex-col">
                <h1 className="tw-font-lato tw-text-2xl tw-leading-normal tw-tracking-normal tw-text-neutral-900-100 sm:tw-leading-10 sm:tw-text-[32px] tw-font-black">
                    {title ?? (person ? getFullName(person) : '')}
                </h1>
                {subtitle && typeof subtitle === 'string' ? (
                    <Text font="h4" color="neutral-offset">
                        {subtitle}
                    </Text>
                ) : (
                    subtitle
                )}
            </div>
        </div>
    );
};
