export function emojiMap() {
	const emojiMapList = {};

	emojiMapList['o/'] = '👋';
	emojiMapList['</3'] = '💔';
	emojiMapList['<3'] = '❤️';
	emojiMapList['8-D'] = '😁';
	emojiMapList['8D'] = '😁';
	emojiMapList[':-D'] = '😁';
	emojiMapList['=-3'] = '😁';
	emojiMapList['=-D'] = '😁';
	emojiMapList['=3'] = '😁';
	emojiMapList['=D'] = '😁';
	emojiMapList['B^D'] = '😁';
	emojiMapList['X-D'] = '😁';
	emojiMapList.XD = '😁';
	emojiMapList['x-D'] = '😁';
	emojiMapList.xD = '😁';
	emojiMapList[":')"] = '😂';
	emojiMapList[":'-)"] = '😂';
	emojiMapList[':-))'] = '😃';
	emojiMapList['8)'] = '😄';
	emojiMapList[':)'] = '😄';
	emojiMapList[':-)'] = '😄';
	emojiMapList[':3'] = '😄';
	emojiMapList[':D'] = '😄';
	emojiMapList[':] = '] = '😄';
	emojiMapList[':^)'] = '😄';
	emojiMapList[':c)'] = '😄';
	emojiMapList[':o)'] = '😄';
	emojiMapList[':}'] = '😄';
	emojiMapList[':っ)'] = '😄';
	emojiMapList['=)'] = '😄';
	emojiMapList['=] = '] = '😄';
	emojiMapList['0:)'] = '😇';
	emojiMapList['0:-)'] = '😇';
	emojiMapList['0:-3'] = '😇';
	emojiMapList['0:3'] = '😇';
	emojiMapList['0;^)'] = '😇';
	emojiMapList['O:-)'] = '😇';
	emojiMapList['3:)'] = '😈';
	emojiMapList['3:-)'] = '😈';
	emojiMapList['}:)'] = '😈';
	emojiMapList['}:-)'] = '😈';
	emojiMapList['*)'] = '😉';
	emojiMapList['*-)'] = '😉';
	emojiMapList[':-] = '] = '😉';
	emojiMapList[';)'] = '😉';
	emojiMapList[';-)'] = '😉';
	emojiMapList[';-] = '] = '😉';
	emojiMapList[';D'] = '😉';
	emojiMapList[';] = '] = '😉';
	emojiMapList[';^)'] = '😉';
	emojiMapList[':-|'] = '😐';
	emojiMapList[':|'] = '😐';
	emojiMapList[':('] = '😒';
	emojiMapList[':-('] = '😒';
	emojiMapList[':-<'] = '😒';
	emojiMapList[':-['] = '😒';
	emojiMapList[':-c'] = '😒';
	emojiMapList[':<'] = '😒';
	emojiMapList[':['] = '😒';
	emojiMapList[':c'] = '😒';
	emojiMapList[':{'] = '😒';
	emojiMapList[':っC'] = '😒';
	emojiMapList['%);'] = '😖';
	emojiMapList['%-)'] = '😖';
	emojiMapList[':-P'] = '😜';
	emojiMapList[':-b'] = '😜';
	emojiMapList[':-p'] = '😜';
	emojiMapList[':-Þ'] = '😜';
	emojiMapList[':-þ'] = '😜';
	emojiMapList[':P'] = '😜';
	emojiMapList[':b'] = '😜';
	emojiMapList[':p'] = '😜';
	emojiMapList[':Þ'] = '😜';
	emojiMapList[':þ'] = '😜';
	emojiMapList[';('] = '😜';
	emojiMapList['=p'] = '😜';
	emojiMapList['X-P'] = '😜';
	emojiMapList.XP = '😜';
	emojiMapList['d:'] = '😜';
	emojiMapList['x-p'] = '😜';
	emojiMapList.xp = '😜';
	emojiMapList[':-||'] = '😠';
	emojiMapList[':@'] = '😠';
	emojiMapList[':-.'] = '😡';
	emojiMapList[':-/'] = '😡';
	emojiMapList[':/'] = '😡';
	emojiMapList[':L'] = '😡';
	emojiMapList[':S'] = '😡';
	emojiMapList[':\\'] = '😡';
	emojiMapList['=/'] = '😡';
	emojiMapList['=L'] = '😡';
	emojiMapList['=\\'] = '😡';
	emojiMapList[":'("] = '😢';
	emojiMapList[":'-("] = '😢';
	emojiMapList['^5'] = '😤';
	emojiMapList['^<_<'] = '😤';
	emojiMapList['o/\\o'] = '😤';
	emojiMapList['|-O'] = '😫';
	emojiMapList['|;-);'] = '😫';
	emojiMapList[':###..'] = '😰';
	emojiMapList[':-###..'] = '😰';
	emojiMapList["D-':"] = '😱';
	emojiMapList.D8 = '😱';
	emojiMapList['D:'] = '😱';
	emojiMapList['D:<'] = '😱';
	emojiMapList['D;'] = '😱';
	emojiMapList['D='] = '😱';
	emojiMapList.DX = '😱';
	emojiMapList['v.v'] = '😱';
	emojiMapList['8-0'] = '😲';
	emojiMapList[':-O'] = '😲';
	emojiMapList[':-o'] = '😲';
	emojiMapList[':O'] = '😲';
	emojiMapList[':o'] = '😲';
	emojiMapList['O-O'] = '😲';
	emojiMapList.O_O = '😲';
	emojiMapList.O_o = '😲';
	emojiMapList['o-o'] = '😲';
	emojiMapList.o_O = '😲';
	emojiMapList.o_o = '😲';
	emojiMapList[':$'] = '😳';
	emojiMapList['#-);'] = '😵';
	emojiMapList[':#'] = '😶';
	emojiMapList[':&'] = '😶';
	emojiMapList[':-#'] = '😶';
	emojiMapList[':-&'] = '😶';
	emojiMapList[':-X'] = '😶';
	emojiMapList[':X'] = '😶';
	emojiMapList[':-J'] = '😼';
	emojiMapList[':*'] = '😽';
	emojiMapList[':^*'] = '😽';
	emojiMapList['ಠ_ಠ'] = '🙅';
	emojiMapList['*\\0/*'] = '🙆';
	emojiMapList['\\o/'] = '🙆';
	emojiMapList[':>'] = '😄';
	emojiMapList['>.<'] = '😡';
	emojiMapList['>:('] = '😠';
	emojiMapList['>:);'] = '😈';
	emojiMapList['>:-);'] = '😈';
	emojiMapList['>:/'] = '😡';
	emojiMapList['>:O'] = '😲';
	emojiMapList['>:P'] = '😜';
	emojiMapList['>:['] = '😒';
	emojiMapList['>:\\'] = '😡';
	emojiMapList['>;);'] = '😈';
	emojiMapList['>_>^'] = '😤';
	emojiMapList['U+1F30E'] = '🌎';
	return emojiMapList;
}
