import { SummaryChart, SummaryParams } from '@models';

export interface NotificationsSummaryParams<T> extends SummaryParams<T> {
	employee: number;
	filters?: string;
	exclude_type?: string;
}

export namespace NotificationsSummary {
	export enum Chart {
		GetNotificationCounts = 0,
	}

	export interface NotificationCounts {
		total_count: number;
		unread_count: number;
	}

	export interface NotificationCountsSummary
		extends SummaryChart<NotificationCounts> {
		chart: 'GetReportItCounts';
		number: '0';
	}

	export interface SummaryReturnType {
		[Chart.GetNotificationCounts]: NotificationCountsSummary;
	}
}
