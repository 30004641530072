// base
import { useState, useEffect } from 'react';

// interfaces
import { Contract, SimplePreset, AreaTag } from '@api-interfaces';

// services
import { presetsService } from '@apis';

// components
import { Text, ModalStack, Box, Icon, Tooltip, LoaderBlocks } from '@atoms';
import { FieldMultiSelect } from '@atoms/form-ds2/field-multi-select';
import { MultiSelectItem } from '@atoms/form-ds2/multi-select/multi-select.interfaces';
import { Modal } from '@components/_globals/templates';
import Empty from '@components/Empty/Empty';

// helpers

// operators
import { useAppContext, useBreakpoints } from '@core/hooks';
import {
    R4OrganizationGroup,
    R4PortfolioAreaTag,
    R4PortfolioAreaTagGroup,
    R4PortfolioContract,
    R4PortfolioCustomerContractGroup,
    R4PortfolioCustomerOrganization,
} from '@core/models';
import { Button } from '@new';
import { modalService } from '@services';

import { EditPresetModal } from './EditPresetModal';
import { InfoTooltip } from './helpers';
import SavePresetModal from './SavePresetModal';
import {
    getAppSelectedAreaTagGroupsTags,
    getAppSelectedOrgGroupTypesGroups,
    getAppSelectedSiteGroupTypesGroups,
    usePickerContext,
} from './StoredPickerContext';
import {
    mixpanelSitePickerChangeClientSite,
    mixpanelSitePickerPreset,
} from '@app/mixpanel/MixpanelPageTrack.tsx';

export const PickerContext = () => {
    const {
        state: { user },
    } = usePickerContext();
    const hasSharedPresetsPermissions = user.hasPermission(1, 'Shared Presets');
    const [menuToggle, setMenuToggle] = useState<
        '' | 'site picker' | 'presets'
    >('site picker');
    const [presetMenuToggle, setPresetMenutoggle] = useState<
        '' | 'my presets' | 'shared presets'
    >('');
    const includeSharedPresets = presetMenuToggle === 'shared presets';

    const PresetTabs = () => {
        return (
            <div className="tw-pb-4 tw-space-x-4">
                <button
                    className={`tw-inline-block tw-cursor-pointer  ${
                        !includeSharedPresets
                            ? 'tw-border-b-[3px] tw-border-solid tw-border-theme-primary-500-300'
                            : ''
                    }`}
                    onClick={() => setPresetMenutoggle('my presets')}
                >
                    <Text
                        font="body-md"
                        color={
                            !includeSharedPresets
                                ? 'hi-contrast'
                                : 'neutral-offset'
                        }
                        className="tw-w-full"
                    >
                        My Presets
                    </Text>
                </button>
                <button
                    className={`tw-inline-block tw-cursor-pointer  ${
                        includeSharedPresets
                            ? 'tw-border-b-[3px] tw-border-solid tw-border-theme-primary-500-300'
                            : ''
                    }`}
                    onClick={() => setPresetMenutoggle('shared presets')}
                >
                    <Text
                        font="body-md"
                        color={
                            includeSharedPresets
                                ? 'hi-contrast'
                                : 'neutral-offset'
                        }
                        className="tw-w-full"
                    >
                        Shared Presets
                    </Text>
                </button>
            </div>
        );
    };

    const bp = useBreakpoints();

    return (
        <ModalStack
            position={bp.sm ? 'top-center' : 'cover'}
            onOverlayClick={modalService.close}
            hasSpacingY
        >
            <Modal.Generic
                title="Select Location(s)"
                titleFontSize="h2"
                // mobile = {min-height: 100vh} default, then {height: auto} to expand with pickers
                className="tw-min-h-[100dvh] tw-h-auto tw-w-full tw-mx-auto sm:tw-h-auto sm:tw-min-h-min sm:tw-max-w-xl"
                onCancel={modalService.close}
            >
                <Box>
                    <Text
                        font="body-lg"
                        color="hi-contrast"
                        className="tw-mb-6"
                    >
                        Choose the client(s) and site(s) and tags for which you
                        wish to view.
                    </Text>

                    <div className="tw-flex tw-mb-6 tw-border-b tw-border-solid tw-border-theme-neutral-900-100 tw-border-opacity-15">
                        <button
                            className={`tw-py-3 tw-px-4 tw-text-theme-neutral-900-100 ${
                                menuToggle == 'site picker'
                                    ? 'tw-bg-theme-neutral-300-700'
                                    : ''
                            }`}
                            onClick={() => {
                                setMenuToggle('site picker');
                                if (hasSharedPresetsPermissions) {
                                    setPresetMenutoggle('');
                                }
                            }}
                        >
                            <Icon icon="map-marker-alt" className="tw-mr-2.5" />
                            Site Picker
                        </button>
                        <button
                            className={`tw-py-3 tw-px-4 tw-text-theme-neutral-900-100 ${
                                menuToggle == 'presets'
                                    ? 'tw-bg-theme-neutral-300-700'
                                    : ''
                            }`}
                            onClick={() => {
                                setMenuToggle('presets');
                                if (hasSharedPresetsPermissions) {
                                    setPresetMenutoggle('my presets');
                                }
                            }}
                        >
                            <Icon icon="bookmark" className="tw-mr-2.5" />
                            {hasSharedPresetsPermissions
                                ? 'Presets'
                                : 'My Presets'}
                        </button>
                    </div>
                    {presetMenuToggle && <PresetTabs />}
                    {menuToggle == 'site picker' ? (
                        <SitePickerCard />
                    ) : (
                        <PresetPickerCard
                            includeShared={includeSharedPresets}
                        />
                    )}
                </Box>
            </Modal.Generic>
        </ModalStack>
    );
};

const SitePickerCard = () => {
    const {
        state: {
            pickerOptionOrgGroups,
            pickerOptionCustomers,
            pickerOptionContracts,
            pickerOptionAreaTagGroups,
            pickerOptionAreaTags,
            pickerOptionSiteGroupTypes,

            pickerSelectedOrgGroups,
            pickerSelectedCustomers,
            pickerSelectedContracts,
            pickerSelectedAreaTags,
            pickerSelectedSiteGroups,

            user,
        },
        setSelectedOrgGroups,
        setSelectedCustomers,
        setSelectedContracts,
        setSelectedAreaTag,
        setSelectedSiteGroups,
    } = usePickerContext();
    const { dispatch } = useAppContext();

    const openPresetModal = () => {
        const { selectedAreaTagGroups, selectedAreaTags } =
            getAppSelectedAreaTagGroupsTags(
                pickerOptionAreaTagGroups,
                pickerSelectedAreaTags
            );

        const idMap = (idObject: { id: number }) => idObject.id;
        modalService.open({
            size: 'sm',
            component: (
                <SavePresetModal
                    user={user}
                    customerGroupIds={pickerSelectedOrgGroups}
                    customerIds={pickerSelectedCustomers.map(idMap)}
                    contractIds={pickerSelectedContracts.map(idMap)}
                    areaTagGroupIds={selectedAreaTagGroups.map(idMap)}
                    areaTagIds={selectedAreaTags.map(idMap)}
                />
            ),
        });
    };

    // validate if form
    const isValid = () => {
        // to validate just need one customer selected
        return !!pickerSelectedCustomers?.length;
    };

    const save = () => {
        const { selectedAreaTagGroups, selectedAreaTags } =
            getAppSelectedAreaTagGroupsTags(
                pickerOptionAreaTagGroups,
                pickerSelectedAreaTags
            );

        const { selectedSiteGroupTypes, selectedSiteGroups } =
            getAppSelectedSiteGroupTypesGroups(
                pickerOptionSiteGroupTypes,
                pickerSelectedSiteGroups
            );

        const { selectedOrgGroupTypes, selectedOrgGroups } =
            getAppSelectedOrgGroupTypesGroups(
                pickerOptionOrgGroups,
                pickerSelectedOrgGroups
            );

        dispatch({
            type: 'SET_PICKER',
            payload: {
                selectedOrgGroupTypes,
                selectedOrgGroups,
                selectedCustomers: pickerSelectedCustomers,
                selectedContracts:
                    pickerSelectedContracts.length === 0
                        ? pickerOptionContracts
                        : pickerSelectedContracts,
                selectedAreaTagGroups,
                selectedAreaTags,
                selectedSiteGroupTypes,
                selectedSiteGroups,
                selectedPreset: null,
            },
        });

        // reset program back to 'All Programs" every time they change context,
        // may change depending on what the business wants.
        dispatch({
            type: 'SET_PROGRAM',
            payload: null,
        });

        modalService.close();
    };

    const selectedOrgGroups = Object.values(pickerSelectedOrgGroups).flatMap(
        (x) => x
    );

    const selectedSiteGroups = Object.values(pickerSelectedSiteGroups).flatMap(
        (x) => x
    );

    function setSelectedOrgGroupsInternal(
        item: (R4OrganizationGroup & { id: number })[]
    ) {
        setSelectedOrgGroups(item);
    }

    function setSelectedCustomersInternal(
        item: R4PortfolioCustomerOrganization[]
    ) {
        setSelectedCustomers(item);
    }

    function setSelectedSiteGroupsInternal(
        item: (R4PortfolioCustomerContractGroup & { id: number })[]
    ) {
        setSelectedSiteGroups(item);
    }

    function setSelectedContractsInternal(item: R4PortfolioContract[]) {
        setSelectedContracts(item);
    }

    return (
        <div>
            {pickerOptionOrgGroups.length > 0 && (
                <div className="tw-mb-6">
                    <FieldMultiSelect
                        id="org-client-group"
                        label="Client Group"
                        items={pickerOptionOrgGroups.flatMap((groupType) =>
                            groupType.organization_groups.map((group) => ({
                                ...group,
                                id: group.organization_group_id,
                            }))
                        )}
                        selectedItems={selectedOrgGroups.map((group) => ({
                            ...group,
                            id: group.organization_group_id,
                        }))}
                        onChange={setSelectedOrgGroupsInternal}
                        groupBy={
                            pickerOptionOrgGroups.length > 1
                                ? (
                                      item: MultiSelectItem &
                                          R4OrganizationGroup
                                  ) =>
                                      pickerOptionOrgGroups.find(
                                          (g) =>
                                              g.organization_group_type_id ==
                                              item.type_id
                                      )?.name
                                : undefined
                        }
                        hasSelectAll
                        selectAllFont="h4"
                        placeholder="Search"
                        allowKeyAction
                        data-test="site-picker-client-group-selection"
                    />
                </div>
            )}

            {/* Customers (Clients) Dropdown */}
            <div className="tw-mb-6">
                <Tooltip
                    render={
                        <Box rounded className="tw-shadow-lg tw-px-4 tw-py-2">
                            <Text color="hi-contrast" font="body-md">
                                No clients available for selected client groups.
                            </Text>
                        </Box>
                    }
                    position="bottom"
                    disabled={
                        !(
                            selectedOrgGroups.length > 0 &&
                            pickerOptionCustomers.length === 0
                        )
                    }
                >
                    <FieldMultiSelect
                        id="client-group-selection"
                        label="Client"
                        required
                        items={pickerOptionCustomers}
                        selectedItems={pickerSelectedCustomers}
                        onChange={setSelectedCustomersInternal}
                        hasSelectAll
                        selectAllFont="h4"
                        placeholder="Search"
                        allowKeyAction
                        groupBy={
                            selectedOrgGroups.length > 1
                                ? (option: any) =>
                                      option?.org_customer_group?.name
                                : undefined
                        }
                        disabled={
                            selectedOrgGroups.length > 0 &&
                            pickerOptionCustomers.length === 0
                        }
                        data-test="site-picker-client-selection"
                    />
                </Tooltip>
            </div>

            {/* Site Group Dropdown */}
            {pickerOptionSiteGroupTypes.length > 0 && (
                <div className="tw-mb-6">
                    <FieldMultiSelect
                        id="site-groups-selection"
                        label="Site Group"
                        items={pickerOptionSiteGroupTypes.flatMap((groupType) =>
                            groupType.organizationcontractgroups.map(
                                (group) => ({
                                    ...group,
                                    id: group.organizationcontractgroup_id,
                                })
                            )
                        )}
                        selectedItems={selectedSiteGroups.map((group) => ({
                            ...group,
                            id: group.organizationcontractgroup_id,
                        }))}
                        onChange={setSelectedSiteGroupsInternal}
                        groupBy={(
                            item: MultiSelectItem &
                                R4PortfolioCustomerContractGroup
                        ) =>
                            pickerOptionSiteGroupTypes.find(
                                (g) =>
                                    g.organization_contract_group_type_id ==
                                    item.type_id
                            )?.organization_contract_group_type_name
                        }
                        hasSelectAll
                        selectAllFont="h4"
                        placeholder="Search"
                        allowKeyAction
                        data-test="site-picker-site-group-selection"
                    />
                </div>
            )}

            {/* Contracts (Sites) Dropdown */}
            <div className="tw-mb-6">
                <Tooltip
                    render={
                        <Box rounded className="tw-shadow-lg tw-px-4 tw-py-2">
                            <Text color="hi-contrast" font="body-md">
                                {selectedSiteGroups.length > 0 &&
                                pickerOptionContracts.length === 0
                                    ? 'No sites available for selected site groups.'
                                    : 'Please select a client first.'}
                            </Text>
                        </Box>
                    }
                    position="bottom"
                    disabled={
                        !(
                            (selectedSiteGroups.length > 0 &&
                                pickerOptionContracts.length === 0) ||
                            pickerSelectedCustomers.length === 0
                        )
                    }
                >
                    <FieldMultiSelect
                        id="site-group-selection"
                        label="Site"
                        items={pickerOptionContracts}
                        selectedItems={pickerSelectedContracts}
                        onChange={setSelectedContractsInternal}
                        hasSelectAll
                        selectAllFont="h4"
                        placeholder="Search"
                        allowKeyAction
                        groupBy={
                            pickerSelectedCustomers.length > 1
                                ? (option: R4PortfolioContract) =>
                                      option.customer_name
                                : undefined
                        }
                        disabled={
                            (selectedSiteGroups.length > 0 &&
                                pickerOptionContracts.length === 0) ||
                            pickerSelectedCustomers.length === 0
                        }
                        data-test="site-picker-site-selection"
                    />
                </Tooltip>
            </div>

            {/* Area Tags Dropdown */}
            {pickerSelectedContracts.length > 1
                ? null
                : pickerOptionAreaTagGroups.map(
                      (areaTagGroup: R4PortfolioAreaTagGroup, index) => (
                          <div
                              key={`${areaTagGroup.id}_${index}`}
                              className="tw-mb-6"
                          >
                              <FieldMultiSelect
                                  id={`area-tag-${areaTagGroup.id}`}
                                  label={areaTagGroup.name}
                                  items={pickerOptionAreaTags[areaTagGroup.id]}
                                  selectedItems={
                                      pickerSelectedAreaTags[areaTagGroup.id] ||
                                      []
                                  }
                                  onChange={(
                                      selectedItems: R4PortfolioAreaTag[]
                                  ) => {
                                      setSelectedAreaTag(
                                          areaTagGroup,
                                          selectedItems
                                      );
                                  }}
                                  placeholder="Search"
                                  allowKeyAction
                                  data-test="site-picker-area-tag-selection"
                              />
                          </div>
                      )
                  )}

            <div className="tw-flex tw-justify-between">
                <Button
                    label="Save as Preset"
                    onClick={openPresetModal}
                    color="secondary"
                    className="tw-pl-0 tw-pr-4"
                />
                <div className="tw-space-x-4">
                    <Button
                        label="Cancel"
                        onClick={modalService.close}
                        color="secondary"
                    />
                    <Button
                        id="save-site-picker-context"
                        label="Save"
                        onClick={save}
                        disabled={!isValid()}
                    />
                </div>
            </div>
        </div>
    );
};

const PresetPickerCard = ({ includeShared }) => {
    const { state: context, dispatch } = useAppContext();

    const [presets, setPresets] = useState<SimplePreset[]>(null);
    const [userPresets, setUserPresets] = useState<SimplePreset[]>(null);
    const [isLoading, setIsLoading] = useState(false);
    const canOnlyViewSharedPresets =
        context.user.hasPermission(1, 'Shared Presets') &&
        !context.user.hasPermission(2, 'Shared Presets');
    const isReadOnly = includeShared && canOnlyViewSharedPresets;

    /*
  const [activePreset, setActivePreset] = React.useState<SimplePreset>(null);
  const [presetName, setPresetName] = React.useState<string>('');
  */

    useEffect(() => {
        initializePresets();
    }, [context.user, includeShared]);

    const initializePresets = () => {
        if (context.user) {
            setIsLoading(true);
            const userId = context.user.employeeId;
            const orgId = context.user.roleProfile.organization.id;

            presetsService
                .getPresets({
                    employee: userId,
                    customer: orgId,
                    limit: 1000,
                    include_shared: includeShared,
                })
                .subscribe((res) => {
                    if (res.results.length > 0) {
                        let presetDisplayList;

                        if (includeShared) {
                            presetDisplayList = res.results.filter(
                                (preset) => preset.is_shared
                            );
                        } else {
                            presetDisplayList = res.results.filter(
                                (preset) => !preset.is_shared
                            );
                        }

                        setPresets(presetDisplayList);
                        setUserPresets(res.results);
                    }
                    setIsLoading(false);
                });
        }
    };

    const selectPreset = (presetId: number) => {
        presetsService.getPresetById(presetId).subscribe((res) => {
            const {
                customers,
                contracts,
                areatags,
                area_tag_groups: areaTagGroups,
            } = res;
            const contextFormattedContracts = contracts.map(
                formatContractToContext
            );
            const contextFormattedAreaTags = areatags.map(
                formatAreaTagToContext
            );

            if (contextFormattedContracts.length === 0) {
                contextFormattedContracts.push(
                    ...(context.user.roleProfile?.portfolios?.[0]?.contracts?.filter(
                        (contract) =>
                            customers.some(
                                (customer) => customer.id === contract.customer
                            )
                    ) ?? [])
                );
            }

            dispatch({
                type: 'SET_PICKER',
                payload: {
                    selectedOrgGroupTypes: [],
                    selectedOrgGroups: [],
                    selectedCustomers: customers,
                    selectedContracts: contextFormattedContracts,
                    selectedAreaTags: contextFormattedAreaTags,
                    selectedAreaTagGroups: areaTagGroups,
                    selectedSiteGroups: [],
                    selectedSiteGroupTypes: [],
                    selectedPreset: {
                        id: res.id,
                        name: res.name,
                    },
                },
            });
            mixpanelSitePickerPreset();

            modalService.closeAll();
        });
    };

    /*
  // Currently unused
  const deletePreset = (presetId: number) => {
      presetsService.deletePresetById(presetId).subscribe(() => {
          // show success msg of deleted
          toasterService.newToast({
              status: 'fail',
              message: 'Successfully deleted preset.',
          });

          // reset the preset list in the state to force the re-render
          setPresets((prevPresets: any) => prevPresets.filter(preset => preset.id !== presetId));
      });
  }
  */

    const editPreset = (presetId: number) => {
        const selectedPreset = userPresets.filter((p) => p.id === presetId)[0];

        // launch the edit preset modal
        modalService.open({
            size: 'md',
            component: (
                <EditPresetModal
                    selectedPreset={selectedPreset}
                    isShared={includeShared}
                />
            ),
        });
    };

    if (isLoading) {
        return <LoaderBlocks />;
    }
    // if presets exists
    if (presets?.length) {
        return (
            <ul className="tw-flex tw-flex-col tw-h-[252px] tw-overflow-y-scroll tw-rounded tw-border tw-border-solid tw-border-theme-neutral-900-100 tw-border-opacity-15">
                {presets.map((preset, i) => (
                    <li
                        key={`${preset.id}_${i}`}
                        className="tw-flex tw-justify-between tw-h-16 tw-pl-4 tw-border-b tw-border-solid tw-border-theme-neutral-900-100 tw-border-opacity-15 tw-pr-2 tw-py-1"
                    >
                        <div className="tw-flex tw-items-center">
                            <Text color="hi-contrast" font="body-lg">
                                {preset.name}
                            </Text>
                            {includeShared && <InfoTooltip preset={preset} />}
                        </div>
                        <div className="tw-flex tw-items-center tw-space-x-1">
                            <Button
                                id="preset-select"
                                label="Select"
                                onClick={() => selectPreset(preset.id)}
                            />
                            <Button
                                label={isReadOnly ? 'View' : 'View/Edit'}
                                color="alternate"
                                onClick={() => editPreset(preset.id)}
                            />
                        </div>
                    </li>
                ))}
            </ul>
        );
    }
    return <Empty />;
};

// format contract to be match selected contracts found in app context
const formatContractToContext = (contract: Contract): R4PortfolioContract => ({
    id: contract?.id ?? null,
    name: contract?.name ?? null,
    customer_name: contract?.customer?.name ?? null,
    customer: contract?.customer?.id ?? null,
    parent_id: contract?.parent_id ?? null,
    correlation_id: contract?.correlation_id ?? null,
    service_provider: contract?.service_provider?.id ?? null,
    has_SOW: contract?.has_SOW ?? null,
    local_timezone: contract?.local_timezone?.display_name ?? null,
    uuid: contract?.uuid ?? null,
    chatbot_sms_phone_number: contract?.chatbot_sms_phone_number ?? null,
    chatbot_sms_country_code: contract?.chatbot_sms_country_code ?? null,
    chatbot_is_active: contract?.chatbot_is_active ?? false,
    use_area_links: contract?.use_area_links ?? false,
});

// format areatag to be match selected areatag found in app context
export const formatAreaTagToContext = (
    areatag: AreaTag
): R4PortfolioAreaTag => ({
    id: areatag?.id ?? null,
    name: areatag?.name ?? null,
    area_tag_group_id: areatag?.tag_group ?? null,
});
