import { useState } from 'react';

// context

// helpers

// services

// interfaces
import { ServiceLogItem } from '@api-interfaces';

// components
import { Avatar, IconButtonFlat, LoaderBlocks, Text } from '@atoms';
import { pluralize, TimeUtils } from '@helpers';
import { useAppContext } from '@hooks';
import { Button, ServiceTime } from '@new';
import { imageService } from '@services';

interface ServiceLogProps {
  isLoading: boolean;
  isDisabled: boolean;
  logs: ServiceLogItem[];
  onLoadClick: () => void;
  onViewClick?: (log: ServiceLogItem) => void;
  className?: string;
  showTasks?: boolean;
  showMedia?: boolean;
  altTimeBackground?: boolean;
  wideLog?: boolean;
}

interface ServiceLogAccordionProps {
  label: string;
  logs: ServiceLogItem[];
  showTasks?: boolean;
  showMedia?: boolean;
  onViewClick?: (log: ServiceLogItem) => void;
  defaultCollapsed?: boolean;
  altTimeBackground?: boolean;
  wideLog?: boolean;
}

export const ServiceLog = ({
  isLoading,
  isDisabled,
  logs,
  onLoadClick,
  className,
  ...itemProps
}: ServiceLogProps) => {
  const logsByDate = logs.reduce(
    (acc, log) => {
      const date = TimeUtils.format(
        log.duration_data[0]?.start_time ?? log.create_date,
        'MM/DD/YYYY'
      );
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(log);
      return acc;
    },
    {} as Record<string, typeof logs>
  );

  return (
    <div className={className}>
      <div className="tw-divide-y tw-divide-solid tw-divide-theme-neutral-900-100 tw-divide-opacity-15">
        {Object.entries(logsByDate).map(([date, logs], i) => (
          <AccordionItem
            key={date}
            label={TimeUtils.format(date, 'dddd, MMMM D, YYYY')}
            logs={logs}
            defaultCollapsed={i !== 0}
            {...itemProps}
          />
        ))}
      </div>
      <div className="tw-mx-4">
        {isLoading ? (
          <LoaderBlocks />
        ) : isDisabled ? (
          <Text color="hi-contrast" font="body-md" className="tw-text-center">
            No more data to retrieve.
          </Text>
        ) : (
          <Button
            label="Load 10 More"
            onClick={onLoadClick}
            color="alternate"
            className="tw-w-full tw-justify-center"
          />
        )}
      </div>
    </div>
  );
};

const AccordionItem = ({
  label,
  logs,
  onViewClick,
  showTasks,
  showMedia,
  defaultCollapsed,
  altTimeBackground,
  wideLog,
}: ServiceLogAccordionProps) => {
  const {
    state: { selectedContracts },
  } = useAppContext();
  const [collapsed, setCollapsed] = useState(defaultCollapsed === true);

  const contract = selectedContracts[0];

  return (
    <div className="tw-divide-y tw-divide-solid tw-divide-theme-neutral-900-100 tw-divide-opacity-15">
      <div className="tw-w-full tw-flex tw-items-center tw-gap-1 tw-p-4">
        <IconButtonFlat
          icon={collapsed ? 'caret-right' : 'caret-down'}
          type="secondary"
          onClick={() => setCollapsed(!collapsed)}
        />
        <Text font="h3" color="hi-contrast" className="tw-flex-1">
          {label}
        </Text>
        <Text color="neutral-offset" font="h4" className="tw-mr-1">
          {logs.length} {pluralize('Service', 'Services', logs.length)}
        </Text>
      </div>
      {!collapsed &&
        logs.map((log, index) => {
          const person = log.employee?.person;
          const hiredDate = log.employee?.hired_date;
          const tasks =
            log.task_data?.filter((t) => t.status !== 'VERIFIED') ?? []; // hide Scan task
          const completedTasks = tasks.filter((t) => t.status === 'COMPLETE')
            ?.length;
          const totalTasks = tasks.length;
          const taskPercent = Math.round((completedTasks / totalTasks) * 100);
          return (
            <div
              className={`tw-h-[92px] tw-w-full tw-flex tw-items-center ${
                showMedia && !wideLog ? 'tw-gap-1.5' : 'tw-gap-3'
              }`}
              key={index}
            >
              {/* Connector bar */}
              <div
                className='tw-h-full tw-relative tw-min-w-[16px] tw-ml-8 tw-border-l tw-border-solid tw-border-theme-neutral-900-100 tw-border-opacity-15
    before:tw-block before:tw-content-[""] before:tw-absolute before:tw-left-0 before:tw-top-0 before:tw-w-full before:tw-h-1/2 before:tw-border-b before:tw-border-solid before:tw-border-theme-neutral-900-100 before:tw-border-opacity-15'
              />

              {/* Employee */}
              {((!showTasks && !showMedia) || wideLog) && (
                <div className="tw-flex tw-gap-4 tw-items-center tw-flex-1">
                  <Avatar person={person} size="lg" />
                  <div className="tw-h-full tw-flex tw-flex-col tw-justify-center tw-gap-0.5">
                    <Text
                      font="h3"
                      color="hi-contrast"
                      style={{ lineHeight: 1.14 }}
                    >
                      {person?.first_name} {person?.last_name}
                    </Text>
                    {(log.position || hiredDate) && (
                      <Text font="h4" color="neutral-offset">
                        {log.position.name}
                        {log.position && hiredDate && ' | '}
                        {hiredDate &&
                          `Hired: ${TimeUtils.format(hiredDate, 'MM/DD/YYYY')}`}
                      </Text>
                    )}
                    <Text font="body-md" color="neutral-offset">
                      {contract?.customer_name} &mdash; {contract?.name}
                    </Text>
                  </div>
                </div>
              )}

              {/* Service Time */}
              <ServiceTime
                // hide service time and start time when showMedia = true
                startTime={
                  !showMedia
                    ? log.duration_data[0]?.start_time ?? log.create_date
                    : undefined
                }
                endTime={log.duration_data[0]?.end_time ?? log.create_date}
                className={`${
                  onViewClick
                    ? 'tw-border-l tw-border-r tw-border-solid tw-border-theme-neutral-900-100 tw-border-opacity-15'
                    : ''
                } ${
                  altTimeBackground
                    ? 'tw-bg-theme-neutral-200-850 tw-rounded'
                    : ''
                } ${onViewClick || altTimeBackground ? 'tw-px-4 tw-py-2' : ''}`}
              />

              {/* Tasks */}
              {showTasks && (
                <>
                  <div className="tw-flex-1" />
                  <div className="tw-flex tw-flex-col tw-gap-0.5 tw-items-end tw-pr-2">
                    <Text color="hi-contrast" font="h4">
                      {completedTasks} / {totalTasks}{' '}
                      {pluralize('Task', 'Tasks', totalTasks || 0)} Completed
                    </Text>
                    <div className="tw-rounded-full tw-h-1 tw-w-[190px] tw-bg-theme-neutral-400-600 tw-overflow-hidden">
                      <div
                        className={`tw-rounded-full tw-h-full ${
                          taskPercent === 100
                            ? 'tw-bg-theme-success-500-700'
                            : 'tw-bg-theme-primary-500-300'
                        }`}
                        style={{ width: `${taskPercent}%` }}
                      />
                    </div>
                  </div>
                </>
              )}

              {/* Media */}
              {showMedia && (
                <>
                  {!wideLog && <div className="tw-flex-1" />}
                  <div className="tw-flex tw-gap-3 tw-w-[216px]">
                    {(log.media_list ?? []).slice(0, 3).map((media, index) => (
                      <div
                        key={media.id}
                        className="tw-relative tw-w-16 tw-h-16"
                      >
                        <img
                          src={imageService.getImageByUniqueId(media.file_name)}
                          alt={media.file_name}
                          className="tw-w-16 tw-h-16 tw-object-cover tw-rounded"
                        />
                        {index === 2 && log.media_list.length > 3 && (
                          <div className="tw-absolute tw-top-0 tw-left-0 tw-w-full tw-h-full tw-flex tw-items-center tw-justify-center tw-rounded tw-bg-neutral-900 tw-bg-opacity-50">
                            <Text color="white" font="h2">
                              +{log.media_list.length - 3}
                            </Text>
                          </div>
                        )}
                      </div>
                    ))}
                    {!log.media_list?.length && (
                      <Text
                        color="neutral-offset"
                        font="body-md"
                        className="tw-text-center tw-w-full"
                      >
                        No Photos
                      </Text>
                    )}
                  </div>
                </>
              )}

              {/* View */}
              {onViewClick && (
                <div className="tw-pl-3 tw-pr-6">
                  <Text
                    color="primary"
                    font="h4"
                    className="tw-cursor-pointer hover:tw-text-theme-primary-700-100"
                    onClick={() => onViewClick(log)}
                  >
                    View
                  </Text>
                </div>
              )}
            </div>
          );
        })}
    </div>
  );
};
