import { MediaMessage, Person } from '@api-interfaces';
import { SummaryChart, SummaryParams } from '@models';

export interface MessagesSummaryParams<T> extends SummaryParams<T> {
	chart_type?: 'NEWS' | 'MESSAGE';
	recipient?: number;
}

export namespace MessagesSummary {
	export enum Chart {
		GetOpenedVsUnopened = 0,
		GetLatestFrontPageMessage = 1,
		GetMessageCounts = 2,
		MessageTotalCounts = 3,
		GetMostRecentMessages = 4,
	}

	export interface OpenedVsUnopened {
		opened: number;
		percent_opened: number;
		total_recipients: number;
		unopened: number;
	}

	export interface OpenedVsUnopenedSummary
		extends SummaryChart<OpenedVsUnopened> {
		chart: 'GetOpenedVsUnopeneed';
		number: '0';
	}

	export interface LatestFrontPageMessage {
		body: string | null;
		from_employee: {
			id: number;
			person: Person;
			uuid: string;
		} | null;
		id: number | null;
		media: MediaMessage[] | null;
		sent_date: string | null;
		sub_title: string | null;
		title: string | null;
	}

	export interface LatestFrontPageMessageSummary
		extends SummaryChart<LatestFrontPageMessage> {
		chart: 'GetLatestFrontPageMessage';
		number: '1';
	}

	export interface MessageCounts {
		count: number;
		read: number;
		unread: number;
	}

	export interface MessageCountsSummary extends SummaryChart<MessageCounts> {
		chart: 'GetMessageCounts';
		number: '2';
	}

	export interface MessageTotalCounts {
		comments_count: number;
		messages_count: number;
		opened_count: number;
	}

	export interface MessageTotalCountsSummary
		extends SummaryChart<MessageTotalCounts> {
		chart: 'MessageTotalCounts';
		number: '3';
	}

	export interface MostRecentMessages extends LatestFrontPageMessage {}

	export interface MostRecentMessagesSummary
		extends SummaryChart<MostRecentMessages[]> {
		chart: 'GetMostRecentMessages';
		number: '4';
	}

	export interface SummaryReturnType {
		[Chart.GetOpenedVsUnopened]: OpenedVsUnopenedSummary;
		[Chart.GetLatestFrontPageMessage]: LatestFrontPageMessageSummary;
		[Chart.GetMessageCounts]: MessageCountsSummary;
		[Chart.MessageTotalCounts]: MessageTotalCountsSummary;
		[Chart.GetMostRecentMessages]: MostRecentMessagesSummary;
	}
}
