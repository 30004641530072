// services
import { datadogRum } from '@datadog/browser-rum';
import dateFns from 'date-fns';
import ReactGA from 'react-ga4';

import { Service } from '@api-interfaces';
import { mixpanelService } from '@apis';
import { mixpanelSitePickerChangeClientSite } from '@app/mixpanel/MixpanelPageTrack';
// helpers
import { Storage } from '@helpers';

// interfaces
import { User } from '@models/user';
import { http, apiClient, virtualBrownClient } from '@services';

import { App, LocalStorageName, StoredAppData } from './app.interfaces';

import { initialAppState } from '.';

export const defaultParentProgram: Service = {
	id: null,
	name: 'All Programs',
	correlation_id: '0',
	is_standard: false,
};

export function appReducer(
	state: App.State,
	action: App.Action
): Readonly<App.State> {
	switch (action.type) {
		case 'SET_CURRENT_USER': {
			if (!action.payload) {
				return initialAppState;
			}
			const { noRedirect = false, ...loginResults } = action.payload;
			const user = new User(loginResults);

			if (user.token) {
				http.token = user.token;
			}

			http.language = user.language;
			http.provider = user.roleProfile.organization.id;
			mixpanelService.user = user;
			apiClient.setOptions(user);

			let localStorage: StoredAppData = Storage.get(LocalStorageName);

			if (user.refreshToken) {
				localStorage = {
					...localStorage,
					refreshToken: user.refreshToken,
				};
			} else if (localStorage?.refreshToken) {
				user.refreshToken = localStorage.refreshToken;
			}

			Storage.set(LocalStorageName, localStorage);

			if (process.env.HOST_ENV === 'prod') {
				datadogRum.setUser({
					id: user.id.toString(),
					name: user.fullName,
					email: user.email,
				});
			}

			const isDs2 = user.hasPermission(1, 'New Navigation')
				? Storage.get('ds2') ?? true
				: false;
			const isSinglePage = user.hasPermission(1, 'Single Page Dashboard')
				? Storage.get('singlePage') ?? true
				: false;

			let loggedInState: App.State = {
				...state,
				user,
				isDs2,
				isSinglePage,
			};

			if (localStorage?.dateRange) {
				loggedInState.dateRange = {
					from: new Date(localStorage.dateRange.from),
					to: new Date(localStorage.dateRange.to),
					range: localStorage.dateRange.range,
					months:
						dateFns.differenceInMonths(
							localStorage.dateRange.to,
							localStorage.dateRange.from
						) + 1,
				};
			}

			if (localStorage?.selectedParentProgram) {
				loggedInState.selectedParentProgram =
					localStorage.selectedParentProgram;
			}

			if (noRedirect) {
				return loggedInState;
			}

			// Update state with local storage
			const firstPortfolio = user.roleProfile?.portfolios?.[0] ?? null;
			const firstCustomer =
				firstPortfolio?.customer_organizations?.[0] ?? null;
			const firstCustomerContracts =
				firstPortfolio?.contracts?.filter(
					(contract) => contract.customer === firstCustomer?.id
				) ?? [];

			let pickerPayload: Pick<
				App.State,
				| 'selectedOrgGroupTypes'
				| 'selectedOrgGroups'
				| 'selectedCustomers'
				| 'selectedContracts'
				| 'selectedAreaTagGroups'
				| 'selectedAreaTags'
				| 'selectedSiteGroupTypes'
				| 'selectedSiteGroups'
				| 'selectedPreset'
			> = {
				selectedOrgGroupTypes: [],
				selectedOrgGroups: [],
				selectedCustomers: firstCustomer ? [firstCustomer] : [],
				selectedContracts:
					firstCustomerContracts.length === 1
						? [firstCustomerContracts[0]]
						: firstCustomerContracts,
				selectedAreaTagGroups: [],
				selectedAreaTags: [],
				selectedSiteGroupTypes: [],
				selectedSiteGroups: [],
				selectedPreset: null,
			};

			if (localStorage) {
				const {
					selectedAreaTagGroups,
					selectedAreaTags,
					selectedContracts,
					selectedCustomers,
					selectedSiteGroupTypes,
					selectedSiteGroups,
					selectedOrgGroupTypes,
					selectedOrgGroups,
					selectedPreset,
				} = localStorage;
				if (
					selectedCustomers &&
					selectedContracts &&
					selectedAreaTagGroups &&
					selectedAreaTags &&
					selectedSiteGroupTypes &&
					selectedSiteGroups &&
					selectedOrgGroupTypes &&
					selectedOrgGroups
				) {
					pickerPayload = {
						selectedOrgGroupTypes: selectedOrgGroupTypes.filter(
							(x) => x
						),
						selectedOrgGroups: selectedOrgGroups.filter((x) => x),
						selectedCustomers: selectedCustomers.filter((x) => x),
						selectedContracts:
							selectedContracts.length === 0
								? firstCustomerContracts.filter((x) => x)
								: selectedContracts.filter((x) => x),
						selectedAreaTagGroups: selectedAreaTagGroups.filter(
							(x) => x
						),
						selectedAreaTags: selectedAreaTags.filter((x) => x),
						selectedSiteGroupTypes: selectedSiteGroupTypes.filter(
							(x) => x
						),
						selectedSiteGroups: selectedSiteGroups.filter((x) => x),
						selectedPreset,
					};
					mixpanelService.contracts = pickerPayload.selectedContracts;
					mixpanelService.customers = pickerPayload.selectedCustomers;
				}
			}

			loggedInState = {
				...loggedInState,
				...appReducer(loggedInState, {
					type: 'SET_PICKER',
					payload: { ...pickerPayload, omitMixpanel: true },
				}),
			};

			return loggedInState;
		}
		case 'SET_PICKER': {
			const {
				selectedOrgGroupTypes,
				selectedOrgGroups,
				selectedCustomers,
				selectedContracts,
				selectedAreaTags,
				selectedAreaTagGroups,
				selectedSiteGroupTypes,
				selectedSiteGroups,
				selectedPreset,
				omitMixpanel = false,
			} = action.payload;

			virtualBrownClient.customers = selectedCustomers;
			virtualBrownClient.contracts = selectedContracts;
			virtualBrownClient.areaTags = selectedAreaTags;
			mixpanelService.customers = selectedCustomers;
			mixpanelService.contracts = selectedContracts;
			mixpanelService.orgGroups = selectedOrgGroups;
			mixpanelService.siteGroups = selectedSiteGroups;

			if (!omitMixpanel) {
				mixpanelSitePickerChangeClientSite();
			}

			// Update multi/single flags
			const isMultiClient = selectedCustomers.length > 1;
			const isSingleClient = selectedCustomers.length === 1;
			const isMultiSite =
				!selectedContracts.length || selectedContracts.length > 1;
			const isSingleSite = selectedContracts.length === 1;

			const siteGroupCount = new Set(
				selectedCustomers
					.filter((c) => c.org_customer_group?.id)
					.map((c) => c.org_customer_group?.id)
			).size;
			const isMultiSiteGroup =
				selectedOrgGroups.length > 1 && siteGroupCount > 1;
			const isSingleSiteGroup = !isMultiSiteGroup;

			const localStorage: StoredAppData = Storage.get(LocalStorageName);

			if (localStorage) {
				Storage.set(LocalStorageName, {
					...localStorage,
					selectedOrgGroupTypes,
					selectedOrgGroups,
					selectedCustomers,
					selectedContracts,
					selectedAreaTagGroups,
					selectedAreaTags,
					selectedSiteGroupTypes,
					selectedSiteGroups,
					selectedPreset,
				});
			}

			// send user's site picker context
			if (ReactGA.isInitialized) {
				ReactGA.set({
					selectedOrgGroupTypes,
					selectedOrgGroups,
					selectedCustomers,
					selectedContracts,
					selectedAreaTagGroups,
					selectedAreaTags,
					selectedSiteGroupTypes,
					selectedSiteGroups,
				});
				ReactGA.event({
					category: 'interactions',
					action: 'change_clients',
					label: selectedCustomers.map((c) => c.name).join(', '),
				});
				if (selectedCustomers.length) {
					ReactGA.event({
						category: 'interactions',
						action: 'change_sites',
						label: selectedContracts.map((c) => c.name).join(', '),
					});
				}
			}

			return {
				...state,
				parentPrograms: [],
				contractsGoalLines: [],
				organizationsGoalLines: [],
				selectedOrgGroupTypes,
				selectedOrgGroups,
				selectedCustomers,
				selectedContracts,
				selectedAreaTags,
				selectedAreaTagGroups,
				selectedSiteGroupTypes,
				selectedSiteGroups,
				selectedPreset,
				isMultiClient,
				isSingleClient,
				isMultiSite,
				isSingleSite,
				isMultiSiteGroup,
				isSingleSiteGroup,
			};
		}
		case 'SET_DATE_RANGE': {
			const { from, to, range } = action.payload;

			const months =
				dateFns.differenceInMonths(to, from) === 0
					? dateFns.differenceInMonths(to, from) + 1
					: dateFns.differenceInMonths(to, from);

			const quartersSelected: Set<string> = new Set();

			if (months === 1 || months === 0) {
				quartersSelected.add(dateFns.format(from, 'Qo YYYY'));
			} else {
				let dateStep = new Date(from);
				while (dateStep < to) {
					quartersSelected.add(dateFns.format(dateStep, 'Qo YYYY'));
					dateStep = dateFns.addMonths(dateStep, 1);
				}
			}

			const dateRange = {
				from: new Date(from),
				to: new Date(to),
				months,
				range,
				quartersSelected: Array.from(quartersSelected),
			};

			const localStorage = Storage.get(LocalStorageName);
			Storage.set(LocalStorageName, {
				...localStorage,
				dateRange: {
					from,
					to,
					range,
				},
			});

			return {
				...state,
				dateRange,
			};
		}
		case 'SET_PROGRAM': {
			let selectedParentProgram: Service = state.parentPrograms.find(
				(program) => program.id === action.payload
			);

			if (selectedParentProgram == undefined) {
				selectedParentProgram = defaultParentProgram;
			}

			const localStorage = Storage.get(LocalStorageName);
			Storage.set(LocalStorageName, {
				...localStorage,
				selectedParentProgram,
			});

			return {
				...state,
				selectedParentProgram,
			};
		}
		case 'SET_PROGRAM_FROM_LOCAL_STORAGE': {
			return {
				...state,
				selectedParentProgram: action.payload,
			};
		}
		case 'SET_PROGRAMS_ORGS_AND_CONTRACTS_GOALLINES': {
			const {
				parentPrograms,
				organizationsGoalLines,
				contractsGoalLines,
			} = action.payload;
			return {
				...state,
				contractsGoalLines,
				organizationsGoalLines,
				parentPrograms,
			};
		}
		case 'SET_LOGOUT_CALLBACK': {
			http.logout = action.payload;
			return {
				...state,
				logout: action.payload,
			};
		}
		case 'UPDATE_USER_EMPLOYEE_INFO': {
			const employee = action.payload;

			const { user } = state;
			user.employee = employee;
			http.language = user.language;
			mixpanelService.user = user;
			apiClient.setOptions(user);

			return {
				...state,
				user,
			};
		}
		case 'TOGGLE_MENU': {
			let isMenuOpen: boolean;

			if (action && 'payload' in action) {
				isMenuOpen = action.payload;
			} else {
				isMenuOpen = !state.isMenuOpen;
			}

			// prevent body scrolling if menu is open
			document.getElementsByTagName('body')[0].style.overflowY =
				isMenuOpen ? 'hidden' : 'auto';

			return {
				...state,
				isMenuOpen,
			};
		}
		case 'TOGGLE_PRESENTATION_MODE': {
			return {
				...state,
				isPresentationMode: action.payload,
			};
		}
		case 'SET_IS_MODAL_STACK_OPEN': {
			return {
				...state,
				isModalStackOpen: action.payload,
			};
		}

		case 'TOGGLE_CONNECTIONS_PANEL': {
			let isMenuOpen: boolean;

			if (action && 'payload' in action) {
				isMenuOpen = action.payload;
			} else {
				isMenuOpen = !state.isMenuOpen;
			}

			// send google analytics whenever communications center panel is opened
			if (isMenuOpen && ReactGA.isInitialized) {
				ReactGA.event({
					category: 'interactions',
					action: 'view_communication_center_panel',
					label: 'Open Communication Center Panel',
				});
			}

			document.getElementsByTagName('body')[0].style.overflowY =
				isMenuOpen ? 'hidden' : 'auto';
			return {
				...state,
				isConnectionsPanelOpen: action.payload,
			};
		}
		case 'TOGGLE_IS_DS2': {
			Storage.set('ds2', action.payload);
			return {
				...state,
				isDs2: action.payload,
			};
		}
		case 'TOGGLE_IS_SINGLE_PAGE': {
			Storage.set('singlePage', action.payload);
			return {
				...state,
				isSinglePage: action.payload,
			};
		}
		case 'SET_FORWARDED_FILTERS': {
			return {
				...state,
				forwardedFilters: action.payload,
			};
		}
		case 'CLEAR_FORWARDED_FILTERS': {
			return {
				...state,
				forwardedFilters: undefined,
			};
		}
		default: {
			return state;
		}
	}
}
