import { debounce } from 'lodash';
import { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import * as React from 'react';

// components
import { Subscription } from 'rxjs';

import { Employee, EmployeePayload } from '@api-interfaces';
import { employeesService } from '@apis';
import { Icon } from '@atoms';
import { ErrorText } from '@atoms/form-ds2/error-text';
import { Label } from '@atoms/form-ds2/label';
import { AddUserButton } from '@components/_new/_form/dropdowns/user-select/components/AddUserButton';
import { AddUserForm } from '@components/_new/_form/dropdowns/user-select/components/AddUserForm';
import { UserSelectList } from '@components/_new/_form/dropdowns/user-select/components/UserSelectList';
import { UserSelectRow } from '@components/_new/_form/dropdowns/user-select/components/UserSelectRow';

// interface
import {
    getCustomerEmployees,
    getEmployees,
    getManagerEmployees,
} from '@components/_new/_form/dropdowns/user-select/helper';
import { UserPayload } from '@components/_new/_form/dropdowns/user-select/user-select.interface';

// services
import { sendError } from '@helpers';
import { useAppContext } from '@hooks/useAppContext';

// helpers

interface Props {
    title?: string;
    className?: string;
    disabled?: boolean;
    saveError?: boolean /* Use this error prop when you need to display errors in sanitation for parent forms */;
    'data-test'?: string;
    errorMessage?: string;
    defaultValue?: number;
    allowAddUser?: boolean; // only for customers, not employees
    addUserOptions?: {
        provider?: number; // override for provider = 1 on select list
        customer?: number; // lock the customer to this value
    };
    userType: 'employee' | 'customer' | 'manager';
    value?: {
        id: number;
    };
    placeholder?: string; // input placeholder
    required?: boolean; // Label['required']
    onSelect: (user: Employee) => void;
    onSubmit?: (payload: EmployeePayload) => void;
    allOrgEmployees?: boolean;
}

export const UserSelect = ({
    title,
    onSubmit,
    onSelect,
    disabled,
    defaultValue,
    saveError,
    className = '',
    errorMessage = 'This field is required',
    value,
    userType,
    allowAddUser,
    addUserOptions,
    placeholder,
    required,
    allOrgEmployees = false,
    ...props
}: Props) => {
    const [users, setUsers] = useState<Employee[]>([]);
    const [selected, setSelected] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(false);
    const [isUserFormOpen, setIsUserFormOpen] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const searchRef = useRef('');
    const containerRef = useRef<HTMLDivElement>(null);
    const { state } = useAppContext();

    const updateUsers = useCallback((users: Employee[]) => {
        setUsers(users);
        setIsLoading(false);
    }, []);

    useEffect(() => {
        if (!isLoading) return;
        if (userType == 'employee') {
            getEmployees(
                searchRef.current,
                updateUsers,
                allOrgEmployees,
                state.user?.roleProfile?.organization?.id
            );
        } else if (userType == 'customer') {
            getCustomerEmployees(searchRef.current, updateUsers);
        } else if (userType == 'manager') {
            getManagerEmployees(
                searchRef.current,
                state.user?.roleProfile?.organization?.id,
                updateUsers
            );
        }
    }, [isLoading]);

    useEffect(() => {
        let subscription = new Subscription();
        if (defaultValue && users.length) {
            const defaultUser = users.find((user) => user.id == defaultValue);

            if (!defaultUser) {
                subscription = employeesService
                    .getEmployeeById(defaultValue)
                    .subscribe({
                        next: (employee) => {
                            setSelected(employee);
                        },
                        error: (error) => {
                            sendError()(error);
                        },
                    });
            } else {
                setSelected(defaultUser);
            }
        }
        return () => subscription.unsubscribe();
    }, [defaultValue, users]);

    useEffect(() => {
        document.addEventListener('mousedown', handleRefClick);

        return () => {
            document.removeEventListener('mousedown', handleRefClick);
        };
    }, []);

    useEffect(() => {
        if (saveError) {
            setError(saveError);
        }
    }, [saveError]);

    const reset = useCallback(() => {
        setIsLoading(true);
    }, []);

    const debouncedReset = useMemo(() => debounce(reset, 500), []);

    function handleSearch(e: React.ChangeEvent<HTMLInputElement>) {
        searchRef.current = e.target.value;
        debouncedReset();
    }

    function handleRefClick(e) {
        if (containerRef.current && !containerRef.current.contains(e.target)) {
            searchRef.current = '';
            setIsDropdownOpen(false);
            setIsUserFormOpen(false);
        } else {
            setError(false);
            setIsDropdownOpen(true);
        }
    }

    function handleSelect(selectedUser: Employee) {
        setError(false);
        setSelected(selectedUser);
        onSelect(selectedUser);
        setIsDropdownOpen(false);
    }

    function openForm() {
        setSelected(null);
        onSelect(null);
        searchRef.current = '';
        setIsUserFormOpen(true);
    }

    function handleEmployeeSubmit(payload: UserPayload) {
        // handle sanitation here.
        if (
            !payload.firstName.length ||
            !payload.lastName.length ||
            !payload.email.length ||
            !payload.organization
        ) {
            setError(true);
            return;
        }
        const formattedPayload: EmployeePayload = {
            person: {
                first_name: payload.firstName,
                last_name: payload.lastName,
                email_address: payload.email,
            },
            organization: {
                id: payload.organization,
            },
            roles: [
                {
                    contract_id: state.selectedContracts?.[0]?.id,
                    role_id: 2039, // [HARDCODED]: ReferenceDataItem id for Customer
                },
            ],
        };
        employeesService.createCustomerEmployee(formattedPayload).subscribe({
            next: (res) => {
                setError(false);
                setIsUserFormOpen(false);
                setIsLoading(true);
                if (userType == 'employee') {
                    getEmployees(searchRef.current, updateUsers);
                } else if (userType == 'customer') {
                    getCustomerEmployees(searchRef.current, updateUsers);
                }
                setSelected(res);

                onSelect({
                    hired_date: res.hired_date,
                    id: res.id,
                    organization: res.organization,
                    person: res.person,
                    positions: [],
                    uuid: res.uuid,
                });
            },
            error: sendError({
                callback: () => setError(true),
            }),
        });
    }

    function handleCancel() {
        searchRef.current = '';
        setIsUserFormOpen(false);
    }

    return (
        <div
            className={`tw-flex tw-flex-col tw-w-full tw-space-y-1.5 ${className}`}
        >
            {title && <Label label={title} required={required} />}
            <div
                ref={containerRef}
                data-disabled={disabled}
                className="form-common-div tw-relative tw-w-full"
            >
                <div
                    tabIndex={0}
                    data-disabled={disabled}
                    data-error={error}
                    className={`form-common-div tw-flex tw-relative tw-items-center tw-justify-between
                        tw-min-h-[40px] tw-w-full tw-h-full tw-rounded
                        tw-bg-neutral-100 dark:tw-bg-neutral-800 tw-outline-none
                        tw-shadow-border-input focus:tw-shadow-border-input-focus focus-within:tw-shadow-border-input-focus tw-overflow-hidden tw-pl-3 tw-pr-8`}
                >
                    {selected && (
                        <ul className="tw-flex tw-gap-1 tw-items-center tw-h-full tw-max-w-full">
                            <UserSelectRow
                                className="tw-max-w-full"
                                hover={false}
                                user={selected}
                            />
                        </ul>
                    )}
                    {!selected && (
                        <input
                            onChange={handleSearch}
                            disabled={disabled}
                            placeholder={placeholder ?? ''}
                            className="tw-flex-1 tw-bg-transparent tw-outline-none tw-text-sm tw-tracking-normal tw-leading-normal"
                        />
                    )}
                    <Icon
                        className={`form-common-caret tw-flex tw-absolute tw-right-[14px] tw-items-center tw-cursor-pointer ${
                            disabled
                                ? 'tw-text-neutral-400 dark:tw-text-neutral-700'
                                : 'tw-text-neutral-500'
                        }`}
                        icon="caret-down"
                    />
                </div>

                <div
                    className={`${
                        isDropdownOpen ? '' : 'tw-hidden'
                    } tw-absolute tw-rounded tw-bg-neutral-100 dark:tw-bg-neutral-800 tw-w-full tw-z-10 tw-mt-[3px]`}
                >
                    {allowAddUser && (
                        <AddUserButton
                            isUserFormOpen={isUserFormOpen}
                            isDropdownOpen={isDropdownOpen}
                            onClick={openForm}
                        />
                    )}
                    <div className="tw-rounded">
                        <AddUserForm
                            error={error}
                            isUserFormOpen={isUserFormOpen}
                            onCancel={handleCancel}
                            onSubmit={handleEmployeeSubmit}
                            provider={addUserOptions?.provider}
                            selectedCustomer={addUserOptions?.customer}
                        />
                        <UserSelectList
                            {...props}
                            selectedUser={selected}
                            isLoading={isLoading}
                            isUserFormOpen={isUserFormOpen}
                            users={users}
                            onClick={handleSelect}
                        />
                    </div>
                </div>
            </div>
            {error && !disabled && <ErrorText error={errorMessage} />}
        </div>
    );
};
UserSelect.defaultProps = {
    allowAddUser: true,
    disabled: false,
};
