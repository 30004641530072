import { ScoreChip, Text } from '@atoms';
import { imageService } from '@core/services';

// components

// assets

// interfaces
import { ImageBlockProps } from './ImageBlock.interfaces';

import './ImageBlock.css';

export const ImageBlock = (props: ImageBlockProps) => {
    const {
        imageUrl,
        onClick,
        mainClassName,
        imageClassName,
        overlayClassName,
        overlay,
        scoreChip,
        height,
        tags,
    } = props;

    const awsImageUrl = imageService.getImageByUniqueId(imageUrl, false);

    return (
        <div
            className={`image-block tw-h-full tw-w-full ${mainClassName}`}
            style={!height ? {} : { height }}
            onClick={onClick}
        >
            <div
                style={{ backgroundImage: `url("${awsImageUrl}")` }}
                className={`tw-relative tw-w-full tw-h-full ${
                    tags?.length ? 'tw-p-2' : 'tw-p-4'
                } tw-flex ${
                    tags?.length
                        ? 'tw-items-end'
                        : 'tw-justify-center tw-items-center'
                } ${overlay ? 'image-overlay' : ''} ${imageClassName}`}
            >
                {tags &&
                    tags.map((tag) => (
                        <div className="tw-bg-neutral-900 tw-bg-opacity-50 tw-rounded-lg tw-py-3 tw-px-4">
                            <Text font="h4" color="white">
                                {tag}
                            </Text>
                        </div>
                    ))}
                <div className={`tw-text-neutral-100 ${overlayClassName}`}>
                    {overlay}
                </div>
                {/* ScoreChips */}
                {scoreChip?.score >= 0 && (
                    <ScoreChip
                        size="sm"
                        score={scoreChip.score}
                        type={scoreChip.type}
                        className="tw-absolute tw-bottom-1 tw-left-1"
                    />
                )}
            </div>
        </div>
    );
};

ImageBlock.defaultProps = {
    mainClassName: '',
    imageClassName: '',
    overlayClassName: '',
};
