// interfaces
import { Observable } from 'rxjs';

import {
	Service,
	InvoiceLineItem,
	GetInvoiceLineItemsParams,
	GetBillingApprovalsRevenue,
	GetBillingApprovalsRevenueResponse,
} from '@api-interfaces';
import { TimeUtils, Params } from '@helpers';
import { PaginationParams, PaginationResponse } from '@models';

// services
import { virtualBrownClient } from '@services/django-client';

import { financialBusinessUnitsService } from './business-units';
import { financialCostsService } from './costs';
import { financialHoursService } from './hours';
import { financialInvoicesService } from './invoices';
import { financialPurchaseOrdersService } from './purchase-orders';
import { financialSalesOrdersService } from './sales-orders';

// helpers

// interfaces

// constants
const BASE_URL = 'financials';

class FinancialsService {
	readonly costs = financialCostsService;

	readonly hours = financialHoursService;

	readonly invoices = financialInvoicesService;

	readonly purchaseOrders = financialPurchaseOrdersService;

	readonly businessUnits = financialBusinessUnitsService;

	readonly salesOrders = financialSalesOrdersService;

	public getInvoiceLineItems = (params: GetInvoiceLineItemsParams) => {
		const query: any = {
			...params,
		};
		if (query.from) {
			query.from = TimeUtils.format(query.from, 'YYYY-MM-DD');
		}
		if (query.to) {
			query.to = TimeUtils.format(query.to, 'YYYY-MM-DD');
		}
		const qs = Params.makeQueryString(query);
		return virtualBrownClient.get<PaginationResponse<InvoiceLineItem>>(
			`${BASE_URL}/invoicelineitems/${qs}`
		);
	};

	getFinancialInvoiceServices = (
		params: {
			from?: Date;
			to?: Date;
			time_zone?: string | number;
		} & PaginationParams
	): Observable<PaginationResponse<Service>> => {
		const query: any = {
			...params,
		};
		if (query.from) {
			query.from = TimeUtils.format(query.from, 'YYYY-MM-DD');
		}
		if (query.to) {
			query.to = TimeUtils.format(query.to, 'YYYY-MM-DD');
		}

		return virtualBrownClient.get(
			`${BASE_URL}/invoices/services/${Params.makeQueryString(query)}`,
			{ noTag: true }
		);
	};

	getFinancialPurchaseOrderServices = (
		params: {
			from?: Date;
			to?: Date;
			time_zone?: string | number;
		} & PaginationParams
	): Observable<PaginationResponse<Service>> => {
		const query: any = {
			...params,
		};
		if (query.from) {
			query.from = TimeUtils.format(params.from, 'YYYY-MM-DD');
		}
		if (query.to) {
			query.to = TimeUtils.format(params.to, 'YYYY-MM-DD');
		}
		return virtualBrownClient.get(
			`${BASE_URL}/purchaseorders/services/${Params.makeQueryString(
				query
			)}`,
			{ noTag: true }
		);
	};

	getFinancialHourServices = (
		params: {
			from?: Date;
			to?: Date;
			time_zone?: string | number;
		} & PaginationParams
	): Observable<PaginationResponse<Service>> => {
		const query: any = {
			...params,
		};
		if (query.from) {
			query.from = TimeUtils.format(query.from, 'YYYY-MM-DD');
		}
		if (query.to) {
			query.to = TimeUtils.format(query.to, 'YYYY-MM-DD');
		}
		return virtualBrownClient.get(
			`${BASE_URL}/hours/services/${Params.makeQueryString(query)}`,
			{ noTag: true }
		);
	};

	public getBillingApprovalsRevenue = (
		params: GetBillingApprovalsRevenue
	) => {
		const query: any = { ...params };
		if (query.from) query.from = TimeUtils.format(query.from, 'YYYY-MM-DD');
		if (query.to) query.to = TimeUtils.format(query.to, 'YYYY-MM-DD');
		const qs = Params.makeQueryString(query);

		return virtualBrownClient.get<GetBillingApprovalsRevenueResponse>(
			`${BASE_URL}/financials/salesorders/summary/${qs}`
		);
	};
}

export const financialsService = new FinancialsService();
