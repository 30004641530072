import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { has } from 'lodash';
import { useState, useEffect } from 'react';
import * as React from 'react';

// services
import { Employee } from '@api-interfaces';
import QuickEntryModal from '@app/modals/QuickEntryModal';
import { Icon } from '@atoms';
import { getFullName, TimeUtils, truncate } from '@helpers';
import { historyService, modalService } from '@services';

// helpers

// components

// styles

// interfaces

import './MiniProfile.scss';

export interface Props {
    data: {
        employeeName: string;
        imgUrl?: string;
        language_preference?: string;
        buildingName?: string;
        floorName?: string;
        clientName?: string;
        siteName?: string;
        isAssignedText?: boolean;
        jobTitle?: string;
        customMeta?: string | React.ReactNode; // will override jobTitle & hiredDate
        altCaption?: string | React.ReactNode;
        hiredDate?: Date | string;
        scoreCircle?: {
            score?: number | string;
            icon?: IconProp;
            color: string;
        };
        subText?: string;
        hide_quick_entry?: boolean;
        terminatedDate?: string | Date; // will hide language preference
        has_mobile_contract?: boolean;
        employee?: Employee | null;
    };
    settings: {
        size: 'xs' | 'sm' | 'lg';
        useLanguagePreference?: boolean;
        bgSize?: 'bg-xs' | 'bg-sm' | 'bg-lg';
        bg?: boolean;
        useOverFlow?: boolean;
        noTruncateJobTitle?: boolean;
        noEmployeeImage?: boolean;
        customMetaAllowWrap?: boolean;
        closeModalsOnClick?: boolean; // Close all modals when User clicked from a Modal
    };
    className?: string;
}

const MiniProfile = (props: Props) => {
    const { className = '' } = props;
    const [languagePref, setLanguagePref] = useState('');
    const hasBackground =
        props.settings && props.settings.bg ? 'tw-rounded miniprofile-bg' : '';
    const { size, bgSize = 'bg-sm' } = props.settings;
    const { scoreCircle, hide_quick_entry } = props.data;

    const imgUrl =
        props.data.imgUrl && props.data.imgUrl.length
            ? props.data.imgUrl
            : `/images/default.png`;

    let meta: string | React.ReactNode = '';

    if (props.data.jobTitle && typeof props.data.jobTitle === 'string')
        meta += truncate(props.data.jobTitle, 22);

    if (props.data.jobTitle && props.data.hiredDate) meta += ' | ';

    if (props.data.hiredDate)
        meta += `Hired: ${TimeUtils.format(
            props.data.hiredDate,
            'MM/DD/YYYY'
        )}`;

    if (props.data.customMeta) meta = props.data.customMeta; // will replace all meta if custom provided
    let { useOverFlow } = props.settings;
    if (props.data.jobTitle) {
        if (
            props.data.jobTitle.toLowerCase().includes('mgr') ||
            props.data.jobTitle.toLowerCase().includes('manager') ||
            props.data.jobTitle.toLowerCase().includes('director') ||
            props.data.jobTitle.toLowerCase().includes('vp') ||
            props.data.jobTitle.toLowerCase().includes('ceo') ||
            props.data.jobTitle.toLowerCase().includes('president')
        ) {
            useOverFlow = false;
        }
    }

    useEffect(() => {
        const hideLanguageText =
            has(props, 'settings.useLanguagePreference') &&
            props.settings.useLanguagePreference == false;

        if (props.data.employee && !hideLanguageText) {
            setLanguagePref(props.data.language_preference);
        }
    });

    function handleQuickEntryClick(e: React.MouseEvent<HTMLButtonElement>) {
        e.preventDefault();
        if (!props.data.employee) {
            console.error(`You forgot to pass in employee props`);
            console.trace();
            return;
        }
        modalService.open({
            size: 'md',
            component: <QuickEntryModal employee={props.data.employee} />,
        });
    }

    return (
        <div
            className={`miniprofile tw-flex tw-items-center xs:tw-mb-2 sm:tw-mb-0 ${hasBackground} ${className} ${bgSize}`}
        >
            <div className="miniprofile-circles tw-flex tw-items-center">
                {scoreCircle && (
                    <div
                        style={{
                            backgroundColor:
                                scoreCircle.score === 'N/A'
                                    ? ''
                                    : scoreCircle.color,
                        }}
                        className="score-circle b tw-flex tw-items-center- tw-justify-center"
                    >
                        {scoreCircle?.icon ? (
                            <Icon icon={scoreCircle.icon} size="lg" />
                        ) : (
                            <span>{scoreCircle?.score ?? '0'}</span>
                        )}
                    </div>
                )}
                {!props.settings.noEmployeeImage && (
                    <img
                        className={`miniprofile-pic ${size} ${
                            scoreCircle ? 'with-score-circle' : ''
                        }`}
                        src={imgUrl}
                    />
                )}
            </div>
            <div className="miniprofile-info tw-flex tw-flex-col tw-items-start tw-justify-between">
                <div
                    className="profile-heading t-text t-text-light tw-cursor-pointer"
                    onClick={() => {
                        if (props.data.employee) {
                            historyService.navigate(
                                `/user-profile/${props.data.employee.id}/profile`
                            );
                            if (props.settings.closeModalsOnClick)
                                modalService.closeAll();
                        }
                    }}
                >
                    {props.data.employee
                        ? getFullName(props.data.employee.person)
                        : props.data.employeeName ?? 'NO SELECTED EMPLOYEE'}
                </div>
                {Boolean(props.data.subText) && (
                    <div className="profile-subtext">
                        <span className="t-text-small">
                            {props.data.subText}
                        </span>
                    </div>
                )}
                {props.data.isAssignedText && (
                    <div className="t-text-light t-text-small tw-font-semibold">
                        ASSIGNED TO
                    </div>
                )}
                <div style={{ textAlign: 'left' }}>
                    <span className="t-text-small">
                        {props.data.buildingName
                            ? `Building ${props.data.buildingName}`
                            : null}
                        {props.data.buildingName &&
                            props.data.floorName &&
                            ', '}
                        {props.data.floorName
                            ? `Floor ${props.data.floorName}`
                            : ''}
                    </span>
                </div>
                <div style={{ textAlign: 'left' }}>
                    <span className="t-text-small t-text-light">
                        {has(props.data, 'altCaption')
                            ? props.data.altCaption
                            : ''}
                    </span>
                </div>
                <div className="profile-meta">
                    <span
                        className="t-text-small tw-font-medium t-text-light"
                        style={{
                            whiteSpace:
                                props.settings &&
                                props.settings.customMetaAllowWrap
                                    ? 'normal'
                                    : 'nowrap',
                        }}
                    >
                        {meta}
                    </span>
                </div>
                <div>
                    <span
                        className="t-text-small t-text-light"
                        style={{ whiteSpace: 'nowrap' }}
                    >
                        {props.data.clientName}
                        {props.data.clientName && props.data.siteName && ' - '}
                        {props.data.siteName}
                    </span>
                </div>
                <div>
                    <span
                        className="t-text-small tw-font-bold t-text-light"
                        style={{
                            whiteSpace:
                                props.settings &&
                                props.settings.customMetaAllowWrap
                                    ? 'normal'
                                    : 'nowrap',
                        }}
                    >
                        {props.data.terminatedDate ? (
                            <span className="inactive">{`Inactive as of ${TimeUtils.formatToTimeZone(
                                props.data.terminatedDate,
                                'M/D/YY',
                                {
                                    timeZone: 'Etc/GMT',
                                }
                            )}`}</span>
                        ) : languagePref ? (
                            <>
                                {props.data?.has_mobile_contract && (
                                    <span>
                                        <Icon
                                            icon="mobile-android-alt"
                                            className="tw-mr-2 tw-text-general-orange"
                                        />
                                    </span>
                                )}
                                <span>{languagePref}</span>
                            </>
                        ) : null}
                    </span>
                </div>
            </div>
            {useOverFlow &&
                props?.data?.employee != null &&
                !hide_quick_entry && (
                    <button
                        className="tw-absolute tw-top-0 tw-right-0 tw-h-5 tw-w-5 tw-grid tw-place-items-center"
                        onClick={handleQuickEntryClick}
                        aria-label={`Create Quick Entry for ${getFullName(
                            props.data.employee.person
                        )}`}
                    >
                        <Icon icon="ellipsis-v" className="tw-text-purple-30" />
                    </button>
                )}
        </div>
    );
};

export default MiniProfile;
