export function friendlyPercentage({
	percent,
	isDecimal,
}: {
	percent: number;
	isDecimal?: true;
}) {
	let percentage = percent;
	if (isDecimal) {
		percentage *= 100;
	}

	if (percentage === 0) {
		return '0%';
	}
	if (percentage > 0 && percentage < 1) {
		return '<1%';
	}
	return `${percentage.toFixed(0)}%`;
}
