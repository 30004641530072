import { TimeUtils } from '../TimeUtils';

export function toStandardTime(militaryTime): string {
	const dateString = TimeUtils.format(new Date(), 'YYYY-MM-DD');
	const timeString = militaryTime;

	const utcDate = TimeUtils.parseFromTimeZone(`${dateString}T${timeString}`, {
		timeZone: 'Etc/GMT',
	});
	const utcDateDisplayTime = TimeUtils.formatToTimeZone(utcDate, 'h:mm A', {
		timeZone: 'Etc/GMT',
	});

	return utcDateDisplayTime;
}
