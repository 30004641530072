// interfaces
import { Observable } from 'rxjs';

import {
	CustomerSurveyRecipient,
	CustomerSurveyRecipientPayload,
	CustomerSurveysRecipientsParams,
} from '@api-interfaces';

// services
import { virtualBrownClient } from '@core/services/django-client';

// helpers
import { Params } from '@helpers';
import { PaginationParams, PaginationResponse } from '@models';

// constants
const BASE_URL = 'customersurveys/recipients';

class CustomerSurveysRecipientsService {
	public getCustomerSurveysRecipients(
		params: CustomerSurveysRecipientsParams
	): Observable<PaginationResponse<CustomerSurveyRecipient>> {
		const queryString = Params.makeQueryString(params);
		return virtualBrownClient.get(`${BASE_URL}/${queryString}`);
	}

	public getCustomerSurveysRecipientById(
		params: {
			id: number | string;
		} & PaginationParams
	): Observable<CustomerSurveyRecipient> {
		return virtualBrownClient.get(`${BASE_URL}/${params.id}/`);
	}

	public patchCustomerSurveysRecipient(params: {
		id: number;
		payload: CustomerSurveyRecipientPayload;
	}): Observable<CustomerSurveyRecipient> {
		return virtualBrownClient.patch(
			`${BASE_URL}/${params.id}/`,
			params.payload
		);
	}

	public getCustomerSurveysRecipientByGUID(
		params: {
			guid: number | string;
		} & PaginationParams
	): Observable<CustomerSurveyRecipient> {
		return virtualBrownClient.get(`${BASE_URL}/${params.guid}`);
	}

	public deleteCustomerSurveyRecipient(params: {
		id: number;
	}): Observable<any> {
		return virtualBrownClient.delete(`${BASE_URL}/${params.id}/`);
	}
}

export const customerSurveysRecipientsService =
	new CustomerSurveysRecipientsService();
