import { Complaint, ComplaintPayload } from '@api-interfaces';

export const isValidServiceIssue = (payload: {
	description: string;
	customer: number;
	type: number;
	classification: number;
	location: number;
	is_repeat_complaint: boolean;
}) => {
	return Object.values(payload).every((value) => value !== undefined);
};

export const isValidClosedServiceIssue = (payload: {
	completed_date: string;
	is_preventable: boolean;
	immediate_action: string;
	root_cause: string;
	preventative_action: string;
}) => {
	return Object.values(payload).every((value) => value !== undefined);
};

export const createServiceIssuePayload = (serviceIssue: Complaint) => {
	const serviceIssuePayload: ComplaintPayload = {
		contract: {
			id: serviceIssue.contract?.id,
		},
		submitter: {
			id: serviceIssue.submitter?.id,
		},
		creator: {
			id: serviceIssue.creator?.id,
		},
		at_fault_employees:
			serviceIssue.at_fault_employees?.map((emp) => ({ id: emp.id })) ?? [],
		description: serviceIssue.description,
		status: serviceIssue.completed_date ? 'CLOSED' : 'OPEN',
		complaint_date: serviceIssue.complaint_date,
		location: {
			id: serviceIssue.location?.id,
		},
		service: {
			id: serviceIssue.service?.id,
		},
		classification: {
			id: serviceIssue.classification.id,
		},
		type: {
			id: serviceIssue.type.id,
		},
		completed_date: serviceIssue.completed_date
			? new Date(serviceIssue.completed_date)
			: null,
		is_preventable: serviceIssue.is_preventable,
		is_repeat_complaint: serviceIssue.is_repeat_complaint,
		is_exempt_from_scorecard: serviceIssue.is_exempt_from_scorecard,
		is_escalated: serviceIssue.is_escalated,
		root_cause: serviceIssue.root_cause,
		immediate_action: serviceIssue.immediate_action,
		preventative_action: serviceIssue.preventative_action,
		todos: serviceIssue.todos ?? [],
		work_orders: serviceIssue.work_orders ?? [],
	};
	return serviceIssuePayload;
};
