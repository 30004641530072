import { Observable } from 'rxjs';

import { AwsSecurityInfo, AwsFolders, AwsSignedUrl } from '@models/aws';
import { http } from '@services';

/**
 * @description Collection of API calls to get AWS credentials from backend endpoints
 */
class AwsCredentialsService {
	private base = 'Services/Security/Aws/';

	private makeUrl(url) {
		return this.base + url;
	}

	/**
	 * @description Get access key, secret, token, and region info for AWS
	 */
	public getSecurityInfo(): Observable<AwsSecurityInfo> {
		const endpoint = this.makeUrl('Credentials');
		return http.get(endpoint);
	}

	/**
	 * @description Gets link for a restricted item, usually for PDFs
	 * @param folder Name of folder (image, file, video, audio)
	 * @param filename Name file file in format {uniqueId}.{type}
	 */
	public getSignedUrl(
		folder: AwsFolders,
		filename: string
	): Observable<AwsSignedUrl> {
		const nameAndType = filename.split('.');
		const endpoint = this.makeUrl(
			`SignedUrl/${folder}/${nameAndType[1]}/${nameAndType[0]}`
		);
		return http.get(endpoint);
	}

	/**
	 * @description Gets link for a restricted item, usually for videos
	 * @param folder Name of folder (image, file, video, audio)
	 * @param filename Name file file in format {uniqueId}.{type}
	 */
	public getCloudfrontSignedUrl(
		folder: AwsFolders,
		filename: string
	): Observable<AwsSignedUrl> {
		const nameAndType = filename.split('.');
		const endpoint = this.makeUrl(
			`SignedCloudfrontUrl/${folder}/${nameAndType[1]}/${nameAndType[0]}`
		);
		return http.get(endpoint);
	}

	public getAwsSignedUrl = (folder: string, key: string): Observable<any> => {
		const parts = key.split('.');
		const endpoint = this.makeUrl(
			`SignedUrl/${folder}/${parts[1]}/${parts[0]}`
		);
		return http.get(endpoint);
	};
}

export const awsCredentialsService = new AwsCredentialsService();
