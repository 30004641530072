import { SummaryChart, SummaryParams, TimeScale } from '@models';

export interface FinancialsHoursSummaryParams<T> extends SummaryParams<T> {
	employee?: string | number;
	is_billable?: boolean;
	is_green?: boolean;
	commodity_id?: number;
	parent_service?: number;
	service?: number;
	vertical?: string;
}

export namespace FinancialsHoursSummary {
	export enum Chart {
		GetEmployeeHoursBySiteMonthly = 0,
		GetHoursByMonth = 1,
		GetProgramHoursMonthly = 2,
		GetHoursCounts = 3,
		GetHoursCountsBySite = 4,
		GetHoursMonthlyTrends = 5,
		GetHoursTotalsByDay = 6,
		GetHoursCountsByOrganization = 7,
		GetHoursCountAndStatusBySite = 8,
		GetHoursCountsByClientGroup = 9,
		GetSevenDayAvgHours = 40,
		GetHoursMonthlyTrendsSTEHours = 43,
	}

	export interface EmployeeHoursBySiteMonthly {
		contract_id: number;
		correlation_id: string;
		employee_id: number;
		first_name: string;
		hired_date: string;
		last_name: string;
		month: number;
		monthlydoubletimehours: number;
		monthlyextrahours: number;
		monthlyholidayhours: number;
		monthlyovertimehours: number;
		monthlypershours: number;
		monthlyregularhours: number;
		monthlysickhours: number;
		monthlystehours: number;
		monthlyvachours: number;
		photo_url: string;
		position_name: string;
		service_name: string;
	}

	export interface EmployeeHoursBySiteMonthlySummary
		extends SummaryChart<EmployeeHoursBySiteMonthly[]> {
		chart: 'GetEmployeeHoursBySiteMonthly';
		number: '0';
	}

	export interface HoursByMonth {
		month: number;
		regularhours: number;
		stehours: number;
		overtimehours: number;
		doubletimehours: number;
	}

	export interface HoursByMonthSummary extends SummaryChart<HoursByMonth[]> {
		chart: 'GetHoursByMonth';
		number: '1';
	}

	export interface ProgramHoursMonthly {}

	export interface ProgramHoursMonthlySummary
		extends SummaryChart<ProgramHoursMonthly[]> {
		chart: 'GetProgramHoursMonthly';
		number: '2';
	}

	export interface HoursCounts {
		total_count: number;
		total_ste_hours: number;
		total_regular_hours: number;
		total_over_hours: number;
		total_double_hours: number;
		total_triple_hours: number;
		total_all_hours: number;
	}

	export interface HoursCountsSummary extends SummaryChart<HoursCounts> {
		chart: 'GetHoursCounts';
		number: '3';
	}

	export interface HoursCountsBySite {
		id: number;
		name: string;
		organization_name: string;
		street1: string;
		street2: string;
		city: string;
		region: string;
		postal_code: string;
		square_feet: number;
		lat: number;
		lon: number;
		total_ste_hours: number;
		total_regular_hours: number;
		is_pass: boolean;
		total_over_hours: number;
		total_double_hours: number;
		total_triple_hours: number;
		total_holiday_hours: number;
		employee_count: number;
	}

	export interface HoursCountsBySiteSummary
		extends SummaryChart<HoursCountsBySite[]> {
		chart: 'GetHoursCountsBySite';
		number: '4';
		max_total_all_hours: number;
	}

	export interface HoursMonthlyTrends {
		hours_month: string;
		total_double_hours: number;
		total_extra_hours: number;
		total_holiday_hours: number;
		total_overtime_hours: number;
		total_pers_hours: number;
		total_regular_hours: number;
		total_sick_hours: number;
		total_ste_hours: number;
		total_vac_hours: number;
	}

	export interface HoursMonthlyTrendsSummary
		extends SummaryChart<HoursMonthlyTrends[]> {
		chart: 'GetHoursMonthlyTrends';
		number: '5';
		time_scale: TimeScale;
	}

	interface EmployeeHoursByDay {
		employee_id: number;
		total_double_hours: number;
		total_holiday_hours: number;
		total_overtime_hours: number;
		total_pers_hours: number;
		total_regular_hours: number;
		total_sick_hours: number;
		total_ste_hours: number;
		total_triple_hours: number;
		total_vac_hours: number;
	}

	export interface HoursTotalsByDay {
		employee_hours: EmployeeHoursByDay[];
		hours_day: string;
		total_double_hours: number;
		total_extra_hours: number;
		total_holiday_hours: number;
		total_overtime_hours: number;
		total_pers_hours: number;
		total_regular_hours: number;
		total_sick_hours: number;
		total_ste_hours: number;
		total_vac_hours: number;
	}

	export interface HoursTotalsByDaySummary
		extends SummaryChart<HoursTotalsByDay[]> {
		chart: 'GetHoursTotalsByDay';
		number: '6';
	}

	export interface HoursCountsByOrganization {
		customer_id: number;
		customer_name: string;
		total_count: number;
		total_ste_hours: number;
		total_regular_hours: number;
		total_over_hours: number;
		total_double_hours: number;
		total_triple_hours: number;
		total_holiday_hours: number;
	}

	export interface HorusCountsByOrganizationSummary
		extends SummaryChart<HoursCountsByOrganization[]> {
		chart: 'GetHoursCountsByOrganization';
		number: '7';
	}

	export interface HoursCountAndStatusBySite
		extends SummaryChart<Array<any>> {
		chart: 'GetHoursCountAndStatusBySite';
		number: '8';
	}

	export interface HoursCountsByClientGroupSummary
		extends SummaryChart<Array<any>> {
		chart: 'GetHoursCountsByClientGroup';
		number: '9';
	}

	export interface SevenDayAvgHours {
		total_all_hours?: number;
		avg_work_hours: number;
	}
	export interface SevenDayAvgHoursSummary
		extends SummaryChart<SevenDayAvgHours> {
		chart: 'GetSevenDayAvgHours';
		number: '40';
	}

	export interface STEHoursSummary {
		results?: [];
		most_recent_record?: string;
	}

	export interface STEHoursSummaryChart
		extends SummaryChart<STEHoursSummary> {
		chart: 'GetBudgetsMonthlyTrends';
		number: '43';
		time_scale: TimeScale;
	}

	export type SummaryReturnType = {
		[Chart.GetEmployeeHoursBySiteMonthly]: EmployeeHoursBySiteMonthlySummary;
		[Chart.GetHoursByMonth]: HoursByMonthSummary;
		[Chart.GetProgramHoursMonthly]: ProgramHoursMonthlySummary;
		[Chart.GetHoursCounts]: HoursCountsSummary;
		[Chart.GetHoursCountsBySite]: HoursCountsBySiteSummary;
		[Chart.GetHoursMonthlyTrends]: HoursMonthlyTrendsSummary;
		[Chart.GetHoursTotalsByDay]: HoursTotalsByDaySummary;
		[Chart.GetHoursCountsByOrganization]: HorusCountsByOrganizationSummary;
		[Chart.GetHoursCountsByClientGroup]: HoursCountsByClientGroupSummary;
		[Chart.GetHoursCountAndStatusBySite]: HoursCountAndStatusBySite;
		[Chart.GetSevenDayAvgHours]: SevenDayAvgHoursSummary;
	};
}
