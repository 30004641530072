import { Component } from 'react';

// interface
import {
    Complaint,
    WorkOrderStatusKey,
    WorkOrderDueStatusKey,
} from '@api-interfaces';

// services
import { Employee, Todo, ComplaintPayload } from '@api-interfaces';
import { complaintsService, mixpanelService } from '@apis';
import {
    Badge,
    IconButtonFlat,
    Icon,
    LoaderBlocks,
    Box,
    Text,
    ModalStack,
} from '@atoms';
import { OverflowMenuButton } from '@components/_globals/components';
import { OverflowMenuButtonProps } from '@components/_globals/components/overflow-menu-button/overflow-menu-button.interfaces';
import MiniProfile from '@components/MiniProfile/MiniProfile';
import {
    DetailsModalWrapper,
    ModalControl,
    ModalTitle,
    LeftSection,
    RightSection,
    ModalHeading,
    ModalContent,
    ModalContentSmall,
    ModalTableKeyValue,
} from '@components/ModalDetail/ModalDetail';
import { App } from '@core/app-context/app.interfaces';
import { getFullName, TimeUtils, sendError } from '@core/helpers';
import {
    imageService,
    modalService,
    toasterService,
    refetchService,
} from '@core/services';

// components
import { ComplaintForm, TodoForm } from '@forms';
import { useAppContext } from '@hooks';
import { StatusBadge } from '@modals/_ds2/work-orders/_components/status-badge';
import { MiniProfile as NewMiniProfile, Button } from '@new';
import { Comments } from '@new/Comments';
import { Modal } from '@templates';

import { ToDoModal } from './_components/ToDoModal';
import { ToDoProgressBar } from './_components/ToDoProgressBar';
import {
    mixpanelDeleteQualityComplaint,
    mixpanelSpecificComplaint,
} from '@app/mixpanel/MixpanelPageTrack.tsx';

// helpers

// interfaces

interface IProps {
    data: {
        id?: number;
        complaint?: Complaint;
    };
    disableOverflowMenu?: boolean;
    onClose?: () => void;
}

class ComplaintModalComponent extends Component<IProps & App.State> {
    public state = {
        isFetching: true,
        isConfirming: false,
        isCreatingToDo: false,
        isToDoModalOpen: false,
        selectedToDo: null,
        isExpanded: true,
        complaint: null as Complaint,
        atFaultEmployees: {
            employees: [] as Employee[],
            fetched: false,
        },
    };

    componentDidMount() {
        if (this.props.data.complaint) {
            const atFaultEmployees =
                this.props.data.complaint.at_fault_employees;
            this.setState({
                isFetching: false,
                complaint: this.props.data.complaint,
                atFaultEmployees: {
                    employees: atFaultEmployees,
                    fetched: true,
                },
            });
        } else if (this.props.data.id) {
            complaintsService
                .getComplaintByComplaintId(this.props.data.id)
                .subscribe((res: Complaint) => {
                    this.setState({
                        isFetching: false,
                        complaint: res,
                        atFaultEmployees: {
                            employees: res.at_fault_employees,
                            fetched: true,
                        },
                    });
                });
        }
    }

    private handleSuccessToast(message: string) {
        toasterService.newToast({
            status: 'success',
            message,
        });
    }

    private handleConfirmationClose = () => {
        this.setState({
            isConfirming: false,
        });
    };

    private deleteComplaint = (id: number) => {
        complaintsService.deleteComplaintByComplaintId(id).subscribe((_res) => {
            mixpanelDeleteQualityComplaint();
            this.handleSuccessToast('The complaint was successfully deleted.');
            refetchService.fetch('complaints');
            modalService.closeAll();
        });
    };

    private createToDo = () => {
        this.setState({
            isCreatingToDo: true,
        });
    };

    private handleToDoCancel = () => {
        this.setState({
            isCreatingToDo: false,
        });
    };

    private handleToDoSuccess = (todo: Todo) => {
        const params: ComplaintPayload = {
            ...this.state.complaint,
            todos: [{ id: todo.id }],
        };

        complaintsService
            .updateComplaintByComplaintId(this.state.complaint.id, params)
            .subscribe({
                next: (res: Complaint) => {
                    this.setState({
                        complaint: res,
                        isCreatingToDo: false,
                    });

                    refetchService.fetch('complaints');
                    this.handleSuccessToast('Todo successfully created');
                },
                error: sendError(),
            });
    };

    private handleToDoClick = (todo: Todo) => {
        this.setState({
            selectedToDo: todo,
            isToDoModalOpen: true,
        });
    };

    private toggleExpanded = () => {
        this.setState({
            isExpanded: !this.state.isExpanded,
        });
    };

    private handleToDoClose = () => {
        this.setState({
            isToDoModalOpen: false,
        });
    };

    private handleToDoDelete = (res: Todo) => {
        const todoList = [...this.state.complaint.todos].filter(
            (todo) => todo.id !== res.id
        );

        const params: ComplaintPayload = {
            ...this.state.complaint,
            todos: todoList,
        };

        complaintsService
            .updateComplaintByComplaintId(this.state.complaint.id, params)
            .subscribe({
                next: (res: Complaint) => {
                    this.setState({
                        complaint: res,
                        isToDoModalOpen: false,
                    });

                    refetchService.fetch('complaints');
                    this.handleSuccessToast('Todo successfully deleted');
                },
                error: sendError(),
            });
    };

    private handleToDoUpdate = (res: Todo, message: string) => {
        const todoList = [...this.state.complaint.todos];
        const index = todoList.findIndex((todo) => todo.id === res.id);
        todoList[index] = res;

        const params: ComplaintPayload = {
            ...this.state.complaint,
            todos: todoList,
        };

        complaintsService
            .updateComplaintByComplaintId(this.state.complaint.id, params)
            .subscribe({
                next: (res: Complaint) => {
                    this.setState({
                        complaint: res,
                        isToDoModalOpen: false,
                    });

                    refetchService.fetch('complaints');
                    this.handleSuccessToast(message);
                },
                error: sendError(),
            });
    };

    public render() {
        const {
            complaint,
            isCreatingToDo,
            isExpanded,
            isToDoModalOpen,
            selectedToDo,
        } = this.state;
        const closedCount = complaint?.todos?.filter(
            (todo) => todo.is_complete
        ).length;
        const totalCount = complaint?.todos?.length;
        const hasCreatePermissions = this.props.user.hasPermission(
            2,
            'Complaints'
        );
        const hasDeletePermissions = this.props.user.hasPermission(
            3,
            'Complaints'
        );
        const hasToDoViewPermissions = this.props.user.hasPermission(
            1,
            'ToDos'
        );
        const hasToDoCreatePermissions = this.props.user.hasPermission(
            2,
            'ToDos'
        );
        const tableHeaders = [
            'Description',
            'Assigned To',
            'Due Date',
            'Status',
        ];
        const isClosed = complaint?.status === 'CLOSED';

        const editModal = () => {
            const modalOpts = {
                component: (
                    <ComplaintForm
                        selectedProgram={null}
                        editComplaint={this.state.complaint}
                    />
                ),
            };

            modalService.open(modalOpts);
        };

        const menuItems: OverflowMenuButtonProps['items'] = [];

        if (hasCreatePermissions) {
            menuItems.push({ name: 'Edit', onClick: editModal });
        }
        if (hasDeletePermissions) {
            menuItems.push({
                name: 'Delete',
                onClick: () => this.setState({ isConfirming: true }),
                className: 'tw-text-theme-danger-500-300',
            });
        }

        const complaintId =
            this.props.data?.complaint?.id ?? this.props.data.id;
        let building;
        let floor;
        let room;
        if (complaint?.location) {
            const { location } = complaint;
            if (location.type.name === 'Room') {
                room = location;
                floor = room.parent;
                building = floor?.parent;
            } else if (location.type.name === 'Floor') {
                floor = location;
                building = floor.parent;
            } else {
                building = location;
            }
        }

        return this.state.isFetching ? (
            <LoaderBlocks className="tw-p-10" />
        ) : (
            <DetailsModalWrapper>
                <LeftSection>
                    <ModalControl>
                        <ModalTitle>Complaint Details</ModalTitle>
                    </ModalControl>

                    <ModalControl>
                        <ModalHeading text="Customer Name" />
                        <Box rounded className="tw-p-2">
                            <NewMiniProfile.Md
                                person={complaint.submitter.person}
                            />
                        </Box>
                    </ModalControl>

                    <ModalControl>
                        <ModalHeading text="Entered By" />
                        <Box rounded className="tw-p-2">
                            <NewMiniProfile.Md
                                person={complaint.creator.person}
                            />
                        </Box>
                    </ModalControl>

                    <ModalControl className="tw-mb-2">
                        <ModalHeading text="Location" />
                        <ModalContentSmall>
                            <Text
                                color="hi-contrast"
                                font="body-md"
                                className="tw-truncate"
                            >
                                Client:{' '}
                                {complaint?.contract?.customer.name ?? 'N/A'}
                            </Text>
                            <Text
                                color="hi-contrast"
                                font="body-md"
                                className="tw-truncate"
                            >
                                Site: {complaint?.contract?.name ?? 'N/A'}
                            </Text>
                            {room && (
                                <Text color="hi-contrast" font="body-md">
                                    {room.name}{' '}
                                    {room.area_type && (
                                        <Text
                                            color="neutral-offset"
                                            font="body-md"
                                            tag="span"
                                            className="tw-truncate"
                                        >
                                            - {room.area_type.name}
                                        </Text>
                                    )}
                                </Text>
                            )}
                            <Text
                                color="hi-contrast"
                                font="body-md"
                                className="tw-truncate"
                            >
                                Building: {building?.name}
                            </Text>
                            <Text
                                color="hi-contrast"
                                font="body-md"
                                className="tw-truncate"
                            >
                                {floor && `Floor: ${floor.name}`}
                            </Text>
                        </ModalContentSmall>
                    </ModalControl>

                    <ModalControl>
                        <ModalHeading text="Program" />
                        <Badge label={complaint.service.name} />
                    </ModalControl>
                </LeftSection>

                <RightSection>
                    <ModalControl>
                        <div className="tw-flex tw-items-center tw-justify-between">
                            <ModalContent>
                                Filed on:{' '}
                                {TimeUtils.format(complaint.complaint_date)}
                            </ModalContent>
                            {!this.props.disableOverflowMenu &&
                                hasCreatePermissions && (
                                    <OverflowMenuButton
                                        items={menuItems}
                                        flowDirection="bottom-left"
                                    />
                                )}
                            {this.props.disableOverflowMenu &&
                                this.props.onClose && (
                                    <IconButtonFlat
                                        icon="close"
                                        onClick={this.props.onClose}
                                    />
                                )}
                        </div>
                    </ModalControl>

                    <ModalControl>
                        <ModalHeading text="Description" />
                        <ModalContent>
                            {complaint.description
                                ? complaint.description
                                : 'No description.'}
                        </ModalContent>
                    </ModalControl>
                    {!!complaint.shifts?.length && (
                        <ModalControl>
                            <ModalHeading text="Shifts" />
                            {complaint.shifts.map((shift) => (
                                <Badge className="tw-mr-3" label={shift.name} />
                            ))}
                        </ModalControl>
                    )}
                    {complaint.status == 'CLOSED' &&
                        complaint?.preventative_action?.length && (
                            <ModalControl>
                                <ModalHeading text="Preventative Action" />
                                <ModalContent>
                                    {complaint.preventative_action}
                                </ModalContent>
                            </ModalControl>
                        )}

                    {complaint.status == 'CLOSED' &&
                        complaint?.root_cause?.length && (
                            <ModalControl>
                                <ModalHeading text="Actual Root Cause" />
                                <ModalContent>
                                    {complaint.root_cause}
                                </ModalContent>
                            </ModalControl>
                        )}
                    <ModalControl>
                        <ModalHeading text="Immediate Corrective Action Taken" />
                        <ModalContent>
                            {complaint.immediate_action}
                        </ModalContent>
                        {hasToDoViewPermissions && (
                            <div className="tw-pl-3 tw-mt-4">
                                <div className="tw-flex tw-justify-between tw-items-center tw-space-x-4">
                                    <div>
                                        <Text font="h2" color="primary">
                                            To-Dos
                                        </Text>
                                    </div>
                                    <div className="tw-grow">
                                        <ToDoProgressBar
                                            numerator={closedCount}
                                            denominator={totalCount}
                                        />
                                    </div>
                                    <div>
                                        <Text
                                            font="h4"
                                            color="hi-contrast"
                                        >{`${closedCount} / ${totalCount} Closed`}</Text>
                                    </div>
                                    <div className="tw-w-5">
                                        <Icon
                                            icon={[
                                                'far',
                                                `${
                                                    isExpanded
                                                        ? 'chevron-down'
                                                        : 'chevron-right'
                                                }`,
                                            ]}
                                            className="tw-cursor-pointer tw-select-none tw-text-lg tw-text-theme-neutral-500-600"
                                            onClick={this.toggleExpanded}
                                        />
                                    </div>
                                    <div>
                                        {!isClosed &&
                                            hasToDoCreatePermissions && (
                                                <Button
                                                    label="Create To-Do"
                                                    onClick={this.createToDo}
                                                />
                                            )}
                                    </div>
                                </div>
                                <div>
                                    {isExpanded && (
                                        <div
                                            className={`form-common-scroll tw-overflow-x-scroll tw-whitespace-nowrap tw-mt-8 ${
                                                !isExpanded ? 'tw-hidden' : ''
                                            }`}
                                        >
                                            <table className="tw-w-full">
                                                <thead>
                                                    <tr>
                                                        {tableHeaders.map(
                                                            (header) => (
                                                                <th
                                                                    key={header}
                                                                    className="tw-text-left tw-text-sm tw-text-theme-neutral-600-500 tw-font-medium tw-pb-4 tw-pr-4 tw-border-b-2 tw-border-solid tw-border-neutral-300 dark:tw-border-neutral-600 tw-leading-normal"
                                                                >
                                                                    {header}
                                                                </th>
                                                            )
                                                        )}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {complaint?.todos?.map(
                                                        (todo, i) => {
                                                            const {
                                                                comment,
                                                                assigned_to,
                                                                due_date,
                                                                closed_date,
                                                                is_complete,
                                                            } = todo;
                                                            const currentDate =
                                                                TimeUtils.formatToISO(
                                                                    new Date()
                                                                );
                                                            const isPastDue =
                                                                (!is_complete &&
                                                                    TimeUtils.isBefore(
                                                                        due_date,
                                                                        currentDate
                                                                    )) ||
                                                                (is_complete &&
                                                                    TimeUtils.isBefore(
                                                                        due_date,
                                                                        closed_date
                                                                    ));
                                                            const isLastRow =
                                                                i ===
                                                                complaint?.todos
                                                                    ?.length -
                                                                    1;

                                                            return (
                                                                <tr
                                                                    key={i}
                                                                    className={`tw-h-20 tw-text-sm tw-text-neutral-900-100
                                                                    tw-cursor-pointer hover:tw-bg-neutral-500 hover:tw-bg-opacity-15
                                                                    ${
                                                                        !isLastRow
                                                                            ? 'tw-border-solid tw-border-b-2 tw-border-neutral-300 dark:tw-border-neutral-600'
                                                                            : ''
                                                                    }`}
                                                                    onClick={() =>
                                                                        this.handleToDoClick(
                                                                            todo
                                                                        )
                                                                    }
                                                                >
                                                                    <td className="tw-pr-4">
                                                                        <div className="tw-truncate tw-max-w-[150px]">
                                                                            {
                                                                                comment
                                                                            }
                                                                        </div>
                                                                    </td>
                                                                    <td className="tw-pr-4">
                                                                        <NewMiniProfile.Sm
                                                                            person={
                                                                                assigned_to?.person
                                                                            }
                                                                            className="tw-w-[150px]"
                                                                        />
                                                                    </td>
                                                                    <td className="tw-pr-8">
                                                                        {TimeUtils.format(
                                                                            due_date,
                                                                            'M/D/YYYY'
                                                                        )}
                                                                    </td>
                                                                    <td className="tw-whitespace-nowrap">
                                                                        <StatusBadge
                                                                            status={
                                                                                is_complete
                                                                                    ? WorkOrderStatusKey.CLOSED
                                                                                    : WorkOrderDueStatusKey.OPEN
                                                                            }
                                                                            isPastDue={
                                                                                isPastDue
                                                                            }
                                                                            className="tw-flex"
                                                                        />
                                                                    </td>
                                                                </tr>
                                                            );
                                                        }
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </ModalControl>

                    <ModalControl>
                        <ModalTableKeyValue>
                            <table>
                                <tbody>
                                    <tr>
                                        <td>Classification</td>
                                        <td className="tw-text-neutral-700 dark:tw-text-neutral-400">
                                            {
                                                complaint.classification
                                                    .display_name
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Repeat Occurrence</td>
                                        <td className="tw-text-neutral-700 dark:tw-text-neutral-400">
                                            {complaint.is_repeat_complaint
                                                ? 'Yes'
                                                : 'No'}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Escalated Complaint</td>
                                        <td className="tw-text-neutral-700 dark:tw-text-neutral-400">
                                            {complaint.is_escalated
                                                ? 'Yes'
                                                : 'No'}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Complaint Type</td>
                                        <td className="tw-text-neutral-700 dark:tw-text-neutral-400">
                                            {complaint.type.display_name}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Preventable Status</td>
                                        <td className="tw-text-neutral-700 dark:tw-text-neutral-400">
                                            {complaint.is_preventable
                                                ? 'Preventable'
                                                : 'Non-Preventable'}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </ModalTableKeyValue>
                    </ModalControl>

                    <ModalControl>
                        <ModalHeading text="Assigned Employees" />

                        {this.state.atFaultEmployees.fetched &&
                        this.state.atFaultEmployees.employees &&
                        this.state.atFaultEmployees.employees.length ? (
                            <div className="miniprofile-wrapper">
                                {this.state.atFaultEmployees.employees.map(
                                    (employee) => {
                                        return (
                                            <MiniProfile
                                                key={employee.id}
                                                data={{
                                                    employeeName: getFullName(
                                                        employee.person
                                                    ),
                                                    imgUrl: imageService.getImageByUniqueId(
                                                        employee?.person
                                                            ?.photo_url
                                                    ),
                                                    jobTitle:
                                                        employee?.positions?.[0]
                                                            ?.name ?? '',
                                                    hiredDate:
                                                        employee?.hired_date ??
                                                        null,
                                                    siteName:
                                                        complaint?.contract
                                                            ?.name ?? '',
                                                    clientName:
                                                        complaint?.contract
                                                            ?.customer.name ??
                                                        '',
                                                    employee,
                                                }}
                                                settings={{
                                                    size: employee
                                                        ?.positions?.[0]?.name
                                                        ? 'lg'
                                                        : 'sm',
                                                    bg: true,
                                                    bgSize: employee
                                                        ?.positions?.[0]?.name
                                                        ? 'bg-lg'
                                                        : 'bg-sm',
                                                    noTruncateJobTitle: false,
                                                }}
                                            />
                                        );
                                    }
                                )}
                            </div>
                        ) : this.state.atFaultEmployees.fetched ? (
                            <ModalContent>No assigned employees.</ModalContent>
                        ) : (
                            <LoaderBlocks />
                        )}
                    </ModalControl>

                    <Comments
                        work_item_type="complaint"
                        item_id={complaintId}
                    />
                </RightSection>

                {this.state.isConfirming && (
                    <ModalStack
                        position="top-half-center"
                        onOverlayClick={this.handleConfirmationClose}
                    >
                        <Modal.Prompt
                            title="Delete?"
                            prompt="Are you sure you want to delete this Complaint?"
                            onCancel={this.handleConfirmationClose}
                            buttons={[
                                {
                                    label: 'No',
                                    onClick: this.handleConfirmationClose,
                                    color: 'secondary',
                                },
                                {
                                    label: 'Yes',
                                    onClick: () => {
                                        this.handleConfirmationClose();
                                        this.deleteComplaint(complaint.id);
                                    },
                                    color: 'danger',
                                },
                            ]}
                        />
                    </ModalStack>
                )}

                {isCreatingToDo && (
                    <ModalStack
                        position="top-half-center"
                        onOverlayClick={this.handleToDoCancel}
                    >
                        <Modal.Generic
                            onCancel={this.handleToDoCancel}
                            className="tw-m-auto"
                            style={{ width: '990px', padding: '8px' }}
                            hideCloseButton
                        >
                            <div className="tw-mt-[-40px]">
                                <TodoForm
                                    handleClose={this.handleToDoCancel}
                                    handleSuccess={this.handleToDoSuccess}
                                    data={{
                                        preselect: {
                                            building: {
                                                Key: building.id,
                                                Value: building.name,
                                                id: building.id,
                                                name: building.name,
                                            },
                                            floor: {
                                                Key: floor?.id,
                                                Value: floor?.name,
                                                id: floor?.id,
                                                name: floor?.name,
                                            },
                                            description: complaint.description,
                                        },
                                    }}
                                />
                            </div>
                        </Modal.Generic>
                    </ModalStack>
                )}

                {isToDoModalOpen && (
                    <ModalStack
                        position="center"
                        onOverlayClick={this.handleToDoClose}
                    >
                        <ToDoModal
                            todo={selectedToDo}
                            handleToDoClose={this.handleToDoClose}
                            handleToDoUpdate={this.handleToDoUpdate}
                            handleToDoDelete={this.handleToDoDelete}
                        />
                    </ModalStack>
                )}
            </DetailsModalWrapper>
        );
    }
}

export const ComplaintModal = (props: IProps) => {
    const { state } = useAppContext();
    mixpanelSpecificComplaint();
    return <ComplaintModalComponent {...state} {...props} />;
};
