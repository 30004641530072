// services
import { Params } from '@helpers';
import { virtualBrownClient } from '@services';

// helpers

// interfaces
import {
	BusinessUnitLocations,
	BusinessUnitPurchaseOrders,
} from './interfaces';

const BASE_URL = 'financials/business-units';

class FinancialBusinessUnitsService {
	getBusinessUnits(params: { contract: number }) {
		return virtualBrownClient.get<BusinessUnitLocations>(
			`${BASE_URL}/${Params.makeQueryString(params)}`
		);
	}

	getBusinessUnitPurchaseOrders(params: { bu: number; contract?: number }) {
		return virtualBrownClient.get<BusinessUnitPurchaseOrders>(
			`${BASE_URL}/purchase-orders/${Params.makeQueryString(params)}`
		);
	}
}

export const financialBusinessUnitsService =
	new FinancialBusinessUnitsService();
