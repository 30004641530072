import Cleave from 'cleave-forked/react';
import {
    forwardRef,
    useRef,
    useState,
    useEffect,
    ComponentProps,
    useMemo,
} from 'react';
import * as React from 'react';

// helpers

// components
import { Icon, IconButtonFlat } from '@atoms';
import { Calendar } from '@atoms/calendar';
import { TimeUtils } from '@helpers';

// interfaces
import { InputDateProps } from './input-date.interfaces';

// regex for M?M/D?D/YYYY or M?M-D?D-YYYY
const INPUT_TEXT_REGEX =
    /^(0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01])[\/\-]\d{4}$/;

export const InputDate = forwardRef<HTMLInputElement, InputDateProps>(
    (
        {
            className,
            disabled,
            error,
            style,
            onChange,
            onBlur,
            disablePast,
            disableFuture,
            enableClear,
            value,
            readOnly,
            locales,
            disableCalender,
            ...props
        },
        ref
    ) => {
        const [date, setDate] = useState<Date | null>(null);
        const [isCalendarOpen, setIsCalendarOpen] = useState(false);
        const inputDateRef = useRef<HTMLDivElement | null>(null);
        const cleaveRef = useRef<
            Parameters<ComponentProps<typeof Cleave>['onInit']>[0] & {
                lastInputValue?: string;
            }
        >(null);

        const displayName = useMemo(() => {
            if (date && TimeUtils.isValid(new Date(date))) {
                const formatOptions: Intl.DateTimeFormatOptions = {
                    month: 'short',
                    weekday: 'short',
                    year: 'numeric',
                    day: '2-digit',
                };
                try {
                    return new Intl.DateTimeFormat(
                        locales,
                        formatOptions
                    ).format(date);
                } catch (err) {
                    return new Intl.DateTimeFormat('en', formatOptions).format(
                        date
                    );
                }
            }
            return 'Invalid Date';
        }, [date, locales]);

        useEffect(() => {
            if (disableCalender) return;
            function handleOutsideClick(event: MouseEvent) {
                const target = event.target as HTMLElement;
                if (!inputDateRef.current?.contains(target) && isCalendarOpen) {
                    setIsCalendarOpen(false);
                }
            }
            window.addEventListener('mousedown', handleOutsideClick);
            return () => {
                window.removeEventListener('mousedown', handleOutsideClick);
            };
        }, [disableCalender, isCalendarOpen]);

        useEffect(() => {
            if (!value) return;
            const date = new Date(
                TimeUtils.parseISO(
                    TimeUtils.format(
                        TimeUtils.parse(value.toString()),
                        'YYYY-MM-DD'
                    )
                )
            );
            if (cleaveRef.current && TimeUtils.isValid(date)) {
                setDate(date);
            }
        }, [value]);

        function setCleaveRawValue(value: string) {
            try {
                const formattedDate = TimeUtils.format(
                    new Date(value),
                    'MM-DD-YYYY'
                ).replaceAll('-', '');
                cleaveRef.current.setRawValue(formattedDate);
                setDate(new Date(value));
            } catch (err) {
                console.log(err);
                // disabled error output
            }
        }

        function getDateBlockers() {
            const isBlocked = {
                blockPast: false,
                blockFuture: false,
            };

            if (!cleaveRef.current) {
                return isBlocked;
            }

            try {
                const value = cleaveRef.current.lastInputValue
                    ? new Date(cleaveRef.current.lastInputValue).toISOString()
                    : '';
                const date = TimeUtils.parseISO(value);
                if (!TimeUtils.isValid(date)) {
                    return isBlocked;
                }

                const startOfToday = TimeUtils.startOfDay(new Date());

                if (
                    TimeUtils.isBefore(
                        TimeUtils.subDays(date, 1),
                        startOfToday
                    ) &&
                    disablePast
                ) {
                    isBlocked.blockPast = true;
                }
                if (
                    TimeUtils.isAfter(
                        TimeUtils.addDays(date, 1),
                        startOfToday
                    ) &&
                    disableFuture
                ) {
                    isBlocked.blockFuture = true;
                }
                return isBlocked;
            } catch (err) {
                return isBlocked;
            }
        }

        // cleave.setRawValue will always trigger this, don't use setRawValue in here
        function handleValueChange(e: React.ChangeEvent<HTMLInputElement>) {
            const { blockPast, blockFuture } = getDateBlockers();
            const { value } = e.target;
            const date = new Date(value);
            const startOfToday = TimeUtils.startOfDay(new Date());

            if (onChange && INPUT_TEXT_REGEX.test(value)) {
                if (blockPast || blockFuture) {
                    onChange(startOfToday);
                } else {
                    onChange(date);
                }
            }
            if (blockPast || blockFuture) {
                setDate(startOfToday);
            } else {
                setDate(value ? date : null);
            }
        }

        function handleFocusEvent(e: React.FocusEvent<HTMLInputElement>) {
            const { blockPast, blockFuture } = getDateBlockers();
            const { value } = e.target;
            const date = new Date(value);
            const startOfToday = TimeUtils.startOfDay(new Date());

            if (onBlur && INPUT_TEXT_REGEX.test(value)) {
                if (blockPast || blockFuture) {
                    onBlur(startOfToday);
                } else {
                    onBlur(date);
                }
            }
            if (blockPast || blockFuture) {
                const formattedDate = TimeUtils.format(
                    startOfToday,
                    'MM/DD/YYYY'
                );
                setCleaveRawValue(formattedDate);
            } else {
                setDate(value ? date : null);
            }
        }

        function handleCalendarDateChange(date: Date) {
            setCleaveRawValue(TimeUtils.format(date, 'MM/DD/YYYY'));
            if (isCalendarOpen) {
                setIsCalendarOpen(false);
            }
        }

        function onClearClick() {
            // setCleaveRawValue('');
            setDate(null);
            onChange(null);
            if (isCalendarOpen) {
                setIsCalendarOpen(false);
            }
        }

        return (
            <div
                ref={inputDateRef}
                data-ds2="input-date"
                className={`tw-relative tw-h-10 tw-w-full tw-px-3 tw-py-2 tw-rounded tw-ring-2 tw-ring-inset tw-flex tw-items-center
        ${
            error
                ? `tw-ring-theme-danger-500-300 focus-within:tw-shadow-input-error ${
                      disabled ? 'tw-ring-opacity-20' : ''
                  }`
                : `focus-within:tw-ring-theme-primary-500-300 focus-within:tw-shadow-input-focus ${
                      disabled || readOnly
                          ? 'tw-ring-theme-neutral-300-700'
                          : 'tw-ring-theme-neutral-400-600'
                  }`
        }

        ${
            disabled || readOnly
                ? 'tw-bg-theme-neutral-200-800 tw-cursor-not-allowed tw-text-theme-neutral-500-600'
                : 'tw-bg-theme-neutral-100-800 tw-text-theme-neutral-900-100'
        }

        ${className}
    `}
                style={style}
            >
                <Cleave
                    htmlRef={(el: HTMLInputElement) => {
                        if (ref && el) {
                            // @ts-ignores
                            ref(el);
                        }
                    }}
                    onInit={(cleave) => {
                        cleaveRef.current = cleave;
                    }}
                    className="tw-peer tw-text-transparent tw-w-full tw-bg-transparent tw-outline-none tw-caret-neutral-600 dark:tw-caret-neutral-200 tw-placeholder-transparent focus:tw-text-currentColor focus:tw-placeholder-neutral-600 dark:focus:tw-placeholder-neutral-200"
                    placeholder="MM/DD/YYYY"
                    disabled={readOnly || disabled}
                    options={{
                        numericOnly: true,
                        delimiter: '/',
                        blocks: [2, 2, 4],
                    }}
                    onChange={handleValueChange}
                    onBlur={handleFocusEvent}
                    onFocus={
                        !disableCalender
                            ? () => {
                                  if (!isCalendarOpen) {
                                      setIsCalendarOpen(true);
                                  }
                              }
                            : undefined
                    }
                    // Cleave.value behaves like default value
                    // set default value to ' ' since this will circumvent setRawValue errors when
                    // value is initially undefined
                    value={value ?? ' '}
                    {...props}
                />
                <span
                    className={`tw-text-sm tw-absolute tw-pointer-events-none peer-focus:tw-hidden peer-focus:tw-text-transparent ${
                        date && !disabled && !error
                            ? 'tw-text-currentColor'
                            : disabled || readOnly
                            ? 'tw-text-theme-neutral-500-600'
                            : 'tw-text-neutral-600 dark:tw-text-neutral-200'
                    }`}
                >
                    {date ? displayName : 'MM/DD/YYYY'}
                </span>
                <button
                    className="tw-grid tw-place-content-center tw-text-theme-primary-500-300 disabled:tw-text-theme-neutral-500-700"
                    type="button"
                >
                    <Icon
                        icon="calendar-day"
                        className="tw-h-4 tw-w-4 tw-text-xl"
                        fixedWidth
                    />
                </button>
                {enableClear && (
                    <IconButtonFlat
                        aria-label="clear selection"
                        icon="times"
                        className="tw-absolute tw-top-1 tw-right-[3rem]"
                        onClick={onClearClick}
                        disabled={disabled}
                    />
                )}
                {isCalendarOpen &&
                    !disableCalender &&
                    !disabled &&
                    !readOnly && (
                        <Calendar
                            rounded
                            shadows
                            date={
                                date && TimeUtils.isValid(TimeUtils.parse(date))
                                    ? date
                                    : new Date()
                            }
                            onDateChange={handleCalendarDateChange}
                            className="tw-absolute tw-top-full tw-left-0 tw-z-[1]"
                            disablePast={disabled || disablePast}
                            disableFuture={disabled || disableFuture}
                            disabled={disabled}
                        />
                    )}
            </div>
        );
    }
);

InputDate.displayName = 'InputDate';

InputDate.defaultProps = {
    className: '',
    locales: 'en',
};
