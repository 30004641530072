import { Observable } from 'rxjs';

// interfaces
import {
	ReportIt,
	CreateReportIt,
	ReportItsSummaries,
	ReportItsChart,
	ReportItOrdering,
	ReportItsByEmployee,
} from '@api-interfaces';

// services
import { virtualBrownClient } from '@core/services/django-client';

// helpers
import { Params, TimeUtils } from '@helpers';
import { CommonParams, PaginationParams, PaginationResponse } from '@models';

import { reportItsSummaryService } from './summary';

// constants
const BASE_URL = 'reportits';

class ReportItsService {
	readonly summary = reportItsSummaryService;

	public getReportIts = (
		params: {
			to?: Date;
			from?: Date;
			filter?: any;
			customer?: number;
			provider?: number;
			employee?: number | string;
			ordering?: ReportItOrdering;
			search?: string;
			send_to_vendor?: boolean;
			status?: 'good_catch' | 'pending_review' | 'accepted' | 'rejected';
			time_zone?: string;
			contract?: string | number;
			tag?: string | number;
			export_to_excel?: boolean;
		} & PaginationParams
	) => {
		let query;
		if (params.from && params.to) {
			query = {
				from: TimeUtils.formatToISO(params.from),
				to: TimeUtils.formatToISO(params.to),
			};
		}
		if (params.ordering) {
			query = {
				...query,
				ordering: params.ordering,
			};
		}
		params.filter && params.filter.subtype
			? (query = {
					...params,
					...query,
					status: params.filter.subtype
						.replace(' ', '_')
						.toLowerCase(),
				})
			: (query = { ...params, ...query });
		delete query.filter;

		const queryString = Params.makeQueryString(query);
		return virtualBrownClient.get<PaginationResponse<ReportIt>>(
			`${BASE_URL}/${queryString}`,
			{
				noContract: !!params?.contract?.toString(),
				noCustomer: !!params?.customer?.toString(),
				noTag: !!params?.tag?.toString(),
			}
		);
	};

	public createReportIt = (payload: CreateReportIt) => {
		return virtualBrownClient.post<ReportIt>(`${BASE_URL}/`, payload);
	};

	public getReportItById(params: { id: number }) {
		return virtualBrownClient.get<ReportIt>(`${BASE_URL}/${params.id}/`);
	}

	public updateReportIt = (reportItId: number, payload) => {
		return virtualBrownClient.put<ReportIt>(
			`${BASE_URL}/${reportItId}/`,
			payload
		);
	};

	public deleteReportIt = (reportItId: number) => {
		return virtualBrownClient.delete<any>(`${BASE_URL}/${reportItId}/`);
	};

	public sendReportToRecipients = (
		reportItId: number,
		payload: { contract: number }
	): Observable<any> => {
		return virtualBrownClient.post(
			`${BASE_URL}/${reportItId}/send/`,
			payload
		);
	};

	public patchReportIt = (
		reportItId: number,
		payload: {
			status?: 'PENDING_REVIEW' | 'ACCEPTED' | 'REJECTED';
			recipients?: Array<{ id: number }>;
			send_email?: false;
			is_exempt_from_scorecard?: boolean;
		}
	) => {
		return virtualBrownClient.patch<ReportIt>(
			`${BASE_URL}/${reportItId}/`,
			payload
		);
	};

	public getReportItsSummary = (params?: {
		from?: Date;
		to?: Date;
		time_zone?: string;
		chart?: string | ReportItsChart;
		chart_type?: number | string;
		customer?: number;
		provider?: number;
		tag_group?: number;
		contract?: number;
		customer_reports_only?: boolean;
		accepted_only?: boolean;
		limit?: number;
		offset?: number;
	}): Observable<ReportItsSummaries> => {
		const query: any = { ...params };
		if (query.from) {
			query.from = query.from.toISOString();
		}
		if (query.to) {
			query.to = query.to.toISOString();
		}

		const queryString = Params.makeQueryString(query);
		return virtualBrownClient.get(`${BASE_URL}/summary/${queryString}`);
	};

	public getEmployees(
		params: CommonParams
	): Observable<PaginationResponse<ReportItsByEmployee>> {
		return virtualBrownClient.get(
			`${BASE_URL}/employees/${Params.makeQueryString(params)}`
		);
	}
}

export const reportItsService = new ReportItsService();
