import { useEffect, useRef, useState } from 'react';

// interfaces
import { Subscription } from 'rxjs';

import { WorkOrderStatusKey, WorkOrderDueStatusKey } from '@api-interfaces';

// components
import { Todo } from '@api-interfaces';
import { inspectionsService } from '@apis';
import { Box, Text, Icon, ModalStack } from '@atoms';
import { sendError, TimeUtils } from '@helpers';
import { useBreakpoints } from '@hooks';
import { useAuditModalContext } from '@modals/_ds2/audit/_context/context';
import { StatusBadge } from '@modals/_ds2/work-orders/_components/status-badge';
import { TodoModal } from '@modals/detail-modals';
import { Button, MiniProfile } from '@new';

import { ToDoProgressBar } from '../../../../_components';

// modals

// interfaces

// services

// helpers

import './todos.scss';

const limit = 10;

export const ToDos = () => {
  const {
    state: { inspection, areas, modals, todos },
    dispatch,
  } = useAuditModalContext();
  const subscriptions = useRef<{ [key: string]: Subscription }>({});
  const bp = useBreakpoints();
  const isBpXs = !bp.sm;
  const [isExpanded, setIsExpanded] = useState(true);
  const closedCount =
    todos?.reduce((acc, cur) => acc + (cur.is_complete ? 1 : 0), 0) ?? 0;
  const totalCount = todos?.length ?? 0;
  const [displayCount, setDisplayCount] = useState<number>(limit);
  const [viewTodo, setViewTodo] = useState<Todo>(null);
  const displayTodos = todos?.slice(0, displayCount) ?? [];

  useEffect(() => {
    if (inspection && areas) {
      subscriptions.current['audit-modal-todos'] = inspectionsService
        .getInspectionTodos(inspection.id, { limit: 1000 })
        .subscribe({
          next: (res) => {
            dispatch({ type: 'SET_TODOS', payload: res.results });
          },
          error: sendError({
            toastMessage: 'There was an error retrieving Audit To-Dos data.',
            callback: () => dispatch({ type: 'SET_TODOS', payload: [] }),
          }),
        });
    }

    return () =>
      Object.values(subscriptions.current).forEach((subscription) =>
        subscription.unsubscribe()
      );
  }, [inspection, areas]);

  const handleTodoClick = (todo: Todo) => {
    setViewTodo(todo);
    dispatch({
      type: 'SET_MODALS',
      payload: {
        ...modals,
        todo: true,
      },
    });
  };

  const handleTodoClose = () => {
    setViewTodo(null);
    dispatch({
      type: 'SET_MODALS',
      payload: {
        ...modals,
        todo: false,
      },
    });
  };

  return (
    <>
      <Box id="audit-modal-todos" className="tw-p-4 sm:tw-p-10" rounded>
        <div className="tw-flex tw-justify-between tw-items-center tw-space-x-6">
          <Text font="h2" color="primary" className="tw-whitespace-nowrap">
            To-Dos
          </Text>
          <div className="tw-flex-grow">
            {!isBpXs && (
              <ToDoProgressBar
                numerator={closedCount}
                denominator={totalCount}
              />
            )}
          </div>
          <Text
            font="h3"
            color="hi-contrast"
            className="tw-whitespace-nowrap"
          >{`${closedCount} / ${totalCount} Closed`}</Text>
          <Icon
            icon={['far', 'chevron-right']}
            className={`tw-cursor-pointer tw-select-none tw-text-lg tw-text-theme-neutral-500-600 ${
              isExpanded ? 'tw-rotate-90' : ''
            }`}
            onClick={() => setIsExpanded(!isExpanded)}
          />
        </div>
        {isBpXs && (
          <ToDoProgressBar
            numerator={closedCount}
            denominator={totalCount}
            className="tw-mt-6 tw-mb-2"
          />
        )}
        <div
          className={`form-common-scroll tw-overflow-x-scroll tw-whitespace-nowrap tw-mt-8 ${
            !isExpanded ? 'tw-hidden' : ''
          }`}
        >
          <table className="tw-w-full">
            <thead>
              <tr>
                {[
                  '#',
                  'Description',
                  'Service Provider',
                  'Assigned To',
                  'Due Date',
                  'Status',
                ].map((header) => (
                  <th
                    key={header}
                    className="tw-text-left tw-text-sm tw-text-theme-neutral-600-500 tw-font-medium tw-pb-4 tw-pr-4 tw-border-b-2 tw-border-solid tw-border-neutral-300 dark:tw-border-neutral-600 tw-leading-normal"
                  >
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {displayTodos?.map((todo, i) => {
                const {
                  id,
                  comment,
                  contract,
                  assigned_to,
                  due_date,
                  closed_date,
                  is_complete,
                } = todo;
                const currentDate = TimeUtils.formatToISO(new Date());
                const isPastDue =
                  (!is_complete && TimeUtils.isBefore(due_date, currentDate)) ||
                  (is_complete && TimeUtils.isBefore(due_date, closed_date));
                const isLastRow = i === todos.length - 1;

                return (
                  <tr
                    key={i}
                    className={`tw-h-[54px] tw-text-sm tw-text-neutral-900-100
                                            tw-cursor-pointer hover:tw-bg-neutral-500 hover:tw-bg-opacity-15
                                            ${
                                              !isLastRow
                                                ? 'tw-border-solid tw-border-b-2 tw-border-neutral-300 dark:tw-border-neutral-600'
                                                : ''
                                            }`}
                    onClick={() => handleTodoClick(todo)}
                  >
                    <td className="tw-pr-4">{id}</td>
                    <td className="tw-pr-4">
                      <div className="tw-truncate tw-max-w-[250px]">
                        {comment}
                      </div>
                    </td>
                    <td className="tw-pr-4">
                      {contract?.service_provider?.name}
                    </td>
                    <td className="tw-pr-4">
                      <MiniProfile.Md
                        person={assigned_to?.person}
                        className="tw-w-[190px]"
                      />
                    </td>
                    <td className="tw-pr-4">
                      {TimeUtils.format(due_date, 'M/D/YYYY')}
                    </td>
                    <td className="tw-whitespace-nowrap">
                      <StatusBadge
                        status={
                          is_complete
                            ? WorkOrderStatusKey.CLOSED
                            : WorkOrderDueStatusKey.OPEN
                        }
                        isPastDue={isPastDue}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          {displayCount < todos?.length && (
            <Button
              color="alternate"
              label="View More"
              onClick={() => setDisplayCount(displayCount + limit)}
              className="tw-my-2 tw-w-full tw-justify-center"
            />
          )}
        </div>
      </Box>

      {viewTodo && (
        <ModalStack position="center" onOverlayClick={handleTodoClose}>
          {isBpXs && (
            <div className="todo-modal-stack-dialog tw-text-neutral-900-300">
              <Icon
                icon="times"
                aria-label="close"
                className="todo-modal-stack-close"
                tabIndex={1}
                onClick={handleTodoClose}
              />
            </div>
          )}
          <TodoModal todo={viewTodo} />
        </ModalStack>
      )}
    </>
  );
};
