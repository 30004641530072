import { LoaderBarProps } from './loader-bar.interfaces';

import './loader-bar.css';

export const LoaderBar = ({ type }: LoaderBarProps) => {
  return <div className={`loading-bar loader-bar-${type} tw-shadow-inner`} />;
};

LoaderBar.defaultProps = {
  type: 'default',
};
