import { createContext, useContext, useState, useEffect, useRef } from 'react';

// services
import { Subscription, forkJoin, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { ServiceArea } from '@api-interfaces';
import {
  complaintsService,
  employeesService,
  serviceAreasService,
} from '@apis';

// helpers
import { getFullName, sendError, getLocations } from '@helpers';
import { useAppContext } from '@hooks';

// interfaces
import {
  ServiceIssueFormState,
  ServiceIssueFormProps,
} from './service-issue-form.context.interfaces';

const serviceIssueFormContext = createContext(null as ServiceIssueFormState);

export const ServiceIssueFormProvider = (props: ServiceIssueFormProps) => {
  const {
    state: { selectedCustomers, selectedContracts, isSingleSite },
  } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);
  const [serviceIssue, setServiceIssue] =
    useState<ServiceIssueFormState['serviceIssue']>(null);
  const [serviceIssueEdit, setServiceIssueEdit] =
    useState<ServiceIssueFormState['serviceIssueEdit']>(null);
  const [serviceIssueTypeOptions, setServiceIssueTypeOptions] = useState<
    ServiceIssueFormState['serviceIssueTypeOptions']
  >([]);
  const [classificationTypeOptions, setClassificationTypeOptions] = useState<
    ServiceIssueFormState['classificationTypeOptions']
  >([]);
  const [buildingOptions, setBuildingOptions] = useState<
    ServiceIssueFormState['buildingOptions']
  >([]);
  const [floorOptions, setFloorOptions] = useState<
    ServiceIssueFormState['floorOptions']
  >([]);
  const [roomOptions, setRoomOptions] = useState<
    ServiceIssueFormState['roomOptions']
  >([]);
  const [employeeOptions, setEmployeeOptions] = useState<
    ServiceIssueFormState['employeeOptions']
  >([]);
  const [activeModals, setActiveModals] = useState({
    'cancel-service-issue': false,
  });
  const hasBeenEdited = useRef(false);

  useEffect(() => {
    let subscription = new Subscription();
    if (props.serviceIssue && !serviceIssue) {
      setServiceIssue({
        ...props.serviceIssue,
        // workOrder.complaints does not have these fields attached, so we need to override them from the orig
        immediate_action: props.serviceIssue.immediate_action_orig,
        root_cause: props.serviceIssue.root_cause_orig,
        preventative_action: props.serviceIssue.preventative_action_orig,
      });
      setIsLoading(false);
    } else if (props.serviceIssueId != undefined) {
      subscription = complaintsService
        .getComplaintByComplaintId(props.serviceIssueId)
        .subscribe({
          next: (serviceIssue) => {
            setServiceIssue(serviceIssue);
            setIsLoading(false);
          },
          error: sendError({
            toastMessage: 'Cannot load To-Do Details',
            callback: () => {
              setIsLoading(false);
            },
          }),
        });
    } else {
      setIsLoading(false);
    }

    return () => {
      subscription.unsubscribe();
    };
  }, [props.serviceIssue, props.serviceIssueId]);

  useEffect(() => {
    if (!isSingleSite || isLoading) return;

    const contractId = selectedContracts[0].id;
    const locations = getLocations(serviceIssue?.location);

    const subscription = forkJoin({
      complaintTypes: complaintsService.getComplaintTypes(),
      classificationTypes: complaintsService.getClassificationTypes(),
      employees: employeesService.getEmployees({
        limit: 1000,
        contract: contractId,
        detail_level: 'for_dropdown',
      }),
      buildings: serviceAreasService
        .getServiceAreas({ quick_landing: true })
        .pipe(map((response) => response.results)),
      floors: locations.building?.id
        ? serviceAreasService
            .getServiceAreas({
              parent: locations.building.id,
              quick_landing: true,
            })
            .pipe(map((response) => response.results))
        : of([] as ServiceArea[]),
      rooms: locations.floor?.id
        ? serviceAreasService
            .getServiceAreas({
              parent: locations.floor.id,
              quick_landing: true,
            })
            .pipe(map((response) => response.results))
        : of([] as ServiceArea[]),
    }).subscribe({
      next: ({
        complaintTypes,
        classificationTypes,
        buildings,
        floors,
        rooms,
        employees,
      }) => {
        setServiceIssueTypeOptions(
          complaintTypes.map((type) => ({
            ...type,
            name: type.display_name,
          }))
        );
        setClassificationTypeOptions(
          classificationTypes.map((type) => ({
            ...type,
            name: type.display_name,
          }))
        );
        setBuildingOptions(buildings);
        setFloorOptions(floors.filter((area) => /floor/i.test(area.type.name)));
        setRoomOptions(rooms.filter((area) => /room/i.test(area.type.name)));
        setEmployeeOptions(
          employees.results.map((employee) => ({
            ...employee,
            name: getFullName(employee.person),
            subtext: employee.person.email_address,
          }))
        );
      },
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [
    serviceIssue,
    isLoading,
    selectedCustomers,
    selectedContracts,
    isSingleSite,
  ]);

  useEffect(() => {
    setServiceIssueEdit(() => {
      if (serviceIssue) {
        return {
          ...serviceIssue,
          at_fault_employees: serviceIssue.at_fault_employees.map(
            (employee) => ({
              ...employee,
              name: getFullName(employee.person),
              subtext: employee.person.email_address,
            })
          ),
        };
      }
      return serviceIssue;
    });
  }, [serviceIssue]);

  return (
    <serviceIssueFormContext.Provider
      value={{
        onSuccess: props.onSuccess,
        service: props.service,
        closeModal: () => {
          setServiceIssue(null);
          setActiveModals({
            'cancel-service-issue': false,
          });
          setIsLoading(true);
          props.closeModal();
          hasBeenEdited.current = false;
        },
        isLoading,
        setIsLoading,
        serviceIssue,
        setServiceIssue,
        serviceIssueEdit,
        setServiceIssueEdit,
        activeModals,
        setActiveModals,
        serviceIssueTypeOptions,
        classificationTypeOptions,
        buildingOptions,
        floorOptions,
        setFloorOptions,
        roomOptions,
        setRoomOptions,
        employeeOptions,
        setEmployeeOptions,
        hasBeenEdited,
      }}
      {...props}
    />
  );
};

export function useServiceIssueFormContext() {
  return useContext(serviceIssueFormContext);
}
