import {
	QuestionTypes,
	AttestationFalseAnswerCountsSummary,
} from '@api-interfaces';
import { SummaryChart } from '@models';

export namespace AttestationsSummary {
	export enum Chart {
		GetAttestationsCounts = 0,
		GetAttestationsTrends = 1,
		GetAttestationsFalseAnswersCounts = 2,
		GetAttestationsQuestionAnswerCounts = 3,
	}

	export interface AttestationsCounts {
		incomplete_count: number;
		negative_count: number;
		positive_count: number;
		latest_attestation_date: string;
	}

	export interface AttestationsCountsSummary
		extends SummaryChart<AttestationsCounts> {
		chart: 'GetAttestationsCounts';
		number: '0';
	}

	export interface AttestationsTrends {
		mo: string;
		negative_count: null | number;
		positive_count: null | number;
	}

	export interface AttestationsTrendsSummary
		extends SummaryChart<AttestationsTrends[]> {
		chart: 'GetAttestationsTrends';
		number: '1';
	}

	export interface AttestationsFalseAnswersCounts {
		failed_count: null | number;
		latest_date: null | string;
	}

	export interface AttestationsFalseAnswersCountsSummary
		extends SummaryChart<AttestationsFalseAnswersCounts> {
		chart: 'GetAttestationsFalseAnswersCounts';
		number: '2';
	}

	export interface AttestationsQuestionAnswerCounts {
		answer_counts: {
			boolean_value?: {
				count_false: number;
				count_none: number;
				count_true: number;
			};
			// @TODO - add types
			scalar_value?: undefined;
			text_value?: undefined;
		};
		description: string;
		id: number;
		name: string;
		question_type: QuestionTypes;
	}

	export interface AttestationsQuestionAnswerCountsSummary
		extends SummaryChart<AttestationsQuestionAnswerCounts[]> {
		chart: 'GetAttestationsQuestionAnswerCounts';
		number: '3';
	}

	export interface SummaryReturnType {
		[Chart.GetAttestationsCounts]: AttestationsCountsSummary;
		[Chart.GetAttestationsTrends]: AttestationsTrendsSummary;
		[Chart.GetAttestationsFalseAnswersCounts]: AttestationFalseAnswerCountsSummary;
		[Chart.GetAttestationsQuestionAnswerCounts]: AttestationsQuestionAnswerCountsSummary;
	}
}
