import { Component } from 'react';
// import Formik from "formik";

// services

// components
import { Employee, BehavioralAudit } from '@api-interfaces';
import { behavioralAuditsService, mixpanelService } from '@apis';
import { Icon } from '@atoms';
import { FieldInputText } from '@atoms/form-ds2/field-input-text';
import { Label } from '@atoms/form-ds2/label';
import MiniProfile from '@components/MiniProfile/MiniProfile';

// hooks

// interfaces
import { App } from '@core/app-context/app.interfaces';

// style
import { getFullName, TimeUtils } from '@core/helpers';
import {
    imageService,
    modalService,
    refetchService,
    toasterService,
} from '@core/services';
import { useAppContext } from '@hooks';
import { Button } from '@new';

import './EmployeeAudit.scss';
import { mixpanelCreatePersonnelProfessionalismAudit } from '@app/mixpanel/MixpanelPageTrack.tsx';

interface IProps {
    data: Employee;
}

interface IState {
    CheckBoxSelections: Array<number>;
    Comment: string;
    isCommentError: boolean;
    EmployeeId: number;
    IsEventBasedAudit: boolean;
    isDirty: boolean;
    submitting: boolean;
    selectedValues: {
        appearance: {
            isDirty: boolean;
            value: null | number;
        };
        attitude: {
            isDirty: boolean;
            value: null | number;
        };
        equipment: {
            isDirty: boolean;
            value: null | number;
        };
        responsiveness: {
            isDirty: boolean;
            value: null | number;
        };
    };
    isFailing: boolean;
}

export const EmployeeAuditForm = (props: IProps) => {
    const { state } = useAppContext();
    return <EmployeeAuditFormComponent {...state} {...props} />;
};

export class EmployeeAuditFormComponent extends Component<
    IProps & App.State,
    IState
> {
    public state = {
        submitting: false,
        CheckBoxSelections: [],
        Comment: null,
        isCommentError: false,
        EmployeeId: this.props.data.id,
        IsEventBasedAudit: false,
        isDirty: false,
        selectedValues: {
            appearance: {
                isDirty: false,
                value: null,
            },
            attitude: {
                isDirty: false,
                value: null,
            },
            equipment: {
                isDirty: false,
                value: null,
            },
            responsiveness: {
                isDirty: false,
                value: null,
            },
        },
        isFailing: null,
    };

    public _onCommentChange = (e) => {
        const { value } = e.target;
        this.setState({
            Comment: value,
        });
    };

    public _onEventBasedChange = (e) => {
        const { value } = e.target;
        const IsEventBasedAudit = value === 'true';
        this.setState({
            IsEventBasedAudit,
        });
    };

    public _onPassFailChange = (e) => {
        const { name } = e.target;

        const value = parseInt(e.target.value, 10);

        if (value) {
            const selectedValues = {
                ...this.state.selectedValues,
                [name]: {
                    isDirty: true,
                    value,
                },
            };
            const isFailing = Object.values(selectedValues).some(
                (selection) => selection.isDirty && !selection.value
            );
            return this.setState({
                isDirty: true,
                selectedValues,
                isFailing,
            });
        }
        return this.setState({
            isDirty: true,
            selectedValues: {
                ...this.state.selectedValues,
                [name]: {
                    isDirty: true,
                    value: false,
                },
            },
            isFailing: true,
        });
    };

    public _onSubmit = (event) => {
        if (this.state.isFailing && !this.state.Comment) {
            event.preventDefault();
            this.setState({
                isCommentError: true,
            });
            return;
        }
        const CheckBoxSelections = Object.values(this.state.selectedValues)
            .map((x) => x.value)
            .filter((x) => x === 0);

        this.setState(
            {
                submitting: true,
                CheckBoxSelections,
            },
            () => {
                const hasNoSetValue = Object.values(this.state.selectedValues)
                    .map((x) => x.isDirty)
                    .includes(false);

                const is_pass = !!(
                    this.state.selectedValues.appearance.value &&
                    this.state.selectedValues.attitude.value &&
                    this.state.selectedValues.equipment.value &&
                    this.state.selectedValues.responsiveness.value
                );

                if (CheckBoxSelections.length > 0 && !this.state.Comment) {
                    return this.setState({ submitting: false });
                }
                if (hasNoSetValue) {
                    return this.setState({ submitting: false });
                }
                if (!is_pass && !this.state.Comment) {
                    return this.setState({ submitting: false });
                }

                const data = {
                    contract: {
                        id: this.props.selectedContracts?.[0]?.id,
                    },
                    auditor: {
                        id: this.props.user.employeeId,
                    },
                    creator: {
                        id: this.props.user.employeeId,
                    },
                    audit_date: TimeUtils.formatToISO(new Date()),
                    answer_sheet: {
                        answers: [
                            {
                                boolean_value:
                                    !!this.state.selectedValues.appearance
                                        .value,
                                text_value: '',
                                scalar_value: null,
                                question: {
                                    id: 1,
                                },
                            },
                            {
                                boolean_value:
                                    !!this.state.selectedValues.attitude.value,
                                text_value: '',
                                scalar_value: null,
                                question: {
                                    id: 2,
                                },
                            },
                            {
                                boolean_value:
                                    !!this.state.selectedValues.equipment.value,
                                text_value: '',
                                scalar_value: null,
                                question: {
                                    id: 3,
                                },
                            },
                            {
                                boolean_value:
                                    !!this.state.selectedValues.responsiveness
                                        .value,
                                text_value: '',
                                scalar_value: null,
                                question: {
                                    id: 4,
                                },
                            },
                        ],
                    },
                    is_event_based: this.state.IsEventBasedAudit,
                    subject: {
                        id: this.state.EmployeeId,
                    },
                    audit_comment: this.state.Comment,
                    is_pass,
                } as BehavioralAudit;

                behavioralAuditsService
                    .createBehavioralAudit(data)
                    .subscribe((_res) => {
                        mixpanelCreatePersonnelProfessionalismAudit();
                        toasterService.newToast({
                            status: 'success',
                            message:
                                'The Professionalism Audit was successfully submitted!',
                        });
                        refetchService.fetch('employee-audit');
                        modalService.closeAll();
                    });
            }
        );
    };

    public render() {
        let isValid = false;

        const CheckBoxSelections = Object.values(
            this.state.selectedValues
        ).filter((x) => x.value !== null);

        isValid = CheckBoxSelections.length === 4;

        return (
            <form className="tw-space-y-6 tw-p-4 sm:tw-p-6">
                <h1 className="tw-mb-6">New Professionalism Entry</h1>
                <MiniProfile
                    data={{
                        employeeName: getFullName(this.props.data.person),
                        imgUrl: imageService.getImageByUniqueId(
                            this.props.data.person.photo_url
                        ),
                        jobTitle: this.props.data.positions?.[0].name,
                        employee: this.props.data,
                    }}
                    settings={{ size: 'sm', bg: true }}
                />

                <div className="tw-flex tw-space-x-4">
                    <div className="tw-w-full">
                        <Label
                            label="Appearance"
                            htmlFor="appearance"
                            className="tw-mb-1.5 tw-block"
                        />
                        <div className="t-text-light t-text-small">
                            Employee wears an SBM tidy uniform and has good
                            hygiene
                        </div>
                        <label className="radio-icon-button">
                            <input
                                type="radio"
                                name="appearance"
                                value="0"
                                onChange={this._onPassFailChange}
                            />
                            <Icon
                                icon={['far', 'times']}
                                className="radio-alert tw-shadow-md"
                            />
                        </label>
                        <label className="radio-icon-button">
                            <input
                                type="radio"
                                name="appearance"
                                value="1"
                                onChange={this._onPassFailChange}
                            />
                            <Icon
                                icon={['far', 'check']}
                                className="radio-check tw-shadow-md"
                            />
                        </label>
                    </div>
                    <div className="tw-w-full">
                        <Label
                            label="Attitude"
                            htmlFor="attitude"
                            className="tw-mb-1.5 tw-block"
                        />
                        <div className="t-text-light t-text-small">
                            Employee displays a positive approachable demeanor,
                            shows respect and is attentive
                        </div>
                        <label className="radio-icon-button">
                            <input
                                type="radio"
                                name="attitude"
                                value="0"
                                onChange={this._onPassFailChange}
                            />
                            <Icon
                                icon={['far', 'times']}
                                className="radio-alert tw-shadow-md"
                            />
                        </label>
                        <label className="radio-icon-button">
                            <input
                                type="radio"
                                name="attitude"
                                value="1"
                                onChange={this._onPassFailChange}
                            />
                            <Icon
                                icon={['far', 'check']}
                                className="radio-check tw-shadow-md"
                            />
                        </label>
                    </div>
                </div>

                <div className="tw-flex tw-space-x-4">
                    <div className="tw-w-full">
                        <Label
                            label="Responsiveness"
                            htmlFor="responsiveness"
                            className="tw-mb-1.5 tw-block"
                        />
                        <div className="t-text-light t-text-small">
                            Employee displays a quick response time and shows
                            initiative
                        </div>
                        <label className="radio-icon-button">
                            <input
                                type="radio"
                                name="responsiveness"
                                value="0"
                                onChange={this._onPassFailChange}
                            />
                            <Icon
                                icon={['far', 'times']}
                                className="radio-alert tw-shadow-md"
                            />
                        </label>
                        <label className="radio-icon-button">
                            <input
                                type="radio"
                                name="responsiveness"
                                value="1"
                                onChange={this._onPassFailChange}
                            />
                            <Icon
                                icon={['far', 'check']}
                                className="radio-check tw-shadow-md"
                            />
                        </label>
                    </div>
                    <div className="tw-w-full">
                        <Label
                            label="Equipment & Closets"
                            htmlFor="equipment"
                            className="tw-mb-1.5 tw-block"
                        />
                        <div className="t-text-light t-text-small">
                            Employee maintains proper care of equipment and a
                            tidy closet without any hazardous conditions
                        </div>
                        <label className="radio-icon-button">
                            <input
                                type="radio"
                                name="equipment"
                                value="0"
                                onChange={this._onPassFailChange}
                            />
                            <Icon
                                icon={['far', 'times']}
                                className="radio-alert tw-shadow-md"
                            />
                        </label>
                        <label className="radio-icon-button">
                            <input
                                type="radio"
                                name="equipment"
                                value="1"
                                onChange={this._onPassFailChange}
                            />
                            <Icon
                                icon={['far', 'check']}
                                className="radio-check tw-shadow-md"
                            />
                        </label>
                    </div>
                </div>

                <div>
                    <Label
                        label=" Is this an event based audit?"
                        className="tw-mb-1.5 tw-block"
                    />
                    <label
                        htmlFor="eba-true"
                        className="radio-option-container"
                    >
                        <input
                            className="radio-option-button"
                            id="eba-true"
                            name="IsEventBasedAudit"
                            type="radio"
                            value="true"
                            onChange={this._onEventBasedChange}
                        />
                        Yes
                        <span className="radio-option-selected" />
                    </label>
                    <label
                        htmlFor="eba-false"
                        className="radio-option-container"
                    >
                        <input
                            className="radio-option-button"
                            id="eba-false"
                            name="IsEventBasedAudit"
                            type="radio"
                            value="false"
                        />
                        No
                        <span className="radio-option-selected" />
                    </label>
                </div>

                <FieldInputText
                    id="audit-comment"
                    label="Overall Comment"
                    onChange={this._onCommentChange}
                    value={this.state.Comment}
                    required={this.state.isFailing}
                    rows={3}
                    error={
                        this.state.isFailing &&
                        this.state.isCommentError &&
                        !this.state.Comment
                            ? 'Please enter overall comment.'
                            : ''
                    }
                />

                {!isValid ? (
                    <div className="tw-text-theme-danger-500-300">
                        All Checkboxes must be marked.
                    </div>
                ) : null}

                <div className="tw-flex tw-items-center tw-justify-end tw-space-x-4">
                    <Button
                        label="Cancel"
                        onClick={modalService.close}
                        color="secondary"
                    />
                    <Button
                        label="Save"
                        onClick={this._onSubmit}
                        disabled={this.state.submitting || !isValid}
                    />
                </div>
            </form>
        );
    }
}
