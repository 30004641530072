// interfaces
import { Observable } from 'rxjs';

// services
import {
	EmployeeVerificationsByType,
	GetVerificationByEmployeeParams,
	ServiceValidationHeader,
	V2AreaTemplate,
} from '@api-interfaces';
import { virtualBrownClient } from '@core/services/django-client';
import { v2ServiceValidationService } from './v2';

// helpers
import { Params } from '@helpers';
import { PaginationParams, PaginationResponse } from '@models';

// interfaces

// constants
const BASE_URL = 'servicevalidation';

const noOpts = {
	noProvider: true,
	noContract: true,
	noCusomter: true,
	noTag: true,
};

class ServiceValidationService {
	readonly v2 = v2ServiceValidationService;

	public getServiceValidationAreaTypes(
		params: {
			contract?: number | string;
			customer?: number | string;
		} & PaginationParams,
		settings?: {
			noOptions?: boolean;
		}
	): Observable<PaginationResponse<V2AreaTemplate>> {
		return virtualBrownClient.get(
			`${BASE_URL}/areatype/${Params.makeQueryString({ ...params })}`,
			noOpts
		);
	}

	public getServiceValidationBuildingOrArea(
		params: {
			area_type_id: number | string;
			contract?: number | string;
			customer?: number | string;
			root?: number | string;
		} & PaginationParams,
		settings?: {
			noOptions?: boolean;
		}
	): Observable<PaginationResponse<V2AreaTemplate>> {
		if (params.root) {
			return virtualBrownClient.get(
				`${BASE_URL}/area/${Params.makeQueryString({ ...params })}`,
				noOpts
			);
		}
		return virtualBrownClient.get(
			`${BASE_URL}/building/${Params.makeQueryString({ ...params })}`,
			noOpts
		);
	}

	createServiceValidation(payload) {
		return virtualBrownClient.post(`${BASE_URL}/`, payload);
	}

	updateServiceValidationById(id, type, payload) {
		return virtualBrownClient.put(`${BASE_URL}/${type}/${id}`, payload);
	}

	deleteServiceValidation(id, type, areaTypeId) {
		return virtualBrownClient.delete(
			`${BASE_URL}/${type}/${id}${Params.makeQueryString({
				areatype_id: areaTypeId,
			})}`
		);
	}

	clearCustomServiceValidation(contract) {
		return virtualBrownClient.delete(`${BASE_URL}/contract/${contract}`);
	}

	getHeader(params: {
		to: Date | string;
		from: Date | string;
		shifts?: number | string;
		area_groups?: number | string;
		buildings?: number | string;
	}): Observable<PaginationResponse<ServiceValidationHeader>> {
		return virtualBrownClient.get(
			`${BASE_URL}/header/${Params.makeQueryString({ ...params })}`
		);
	}

	public getVerificationByEmployee = (
		params: GetVerificationByEmployeeParams & PaginationParams
	): Observable<PaginationResponse<EmployeeVerificationsByType>> => {
		const queryString = Params.makeQueryString(params);
		return virtualBrownClient.get(
			`${BASE_URL}/verification_by_employee/${queryString}`
		);
	};
}

export const serviceValidationService = new ServiceValidationService();
