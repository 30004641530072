// services

// helpers

// components
import { FieldInputText } from '@atoms/form-ds2/field-input-text';
import { FieldInputTime } from '@atoms/form-ds2/field-input-time';
import { SubmitButton } from '@atoms/form-ds2/submit-button';
import { Formik, FormikActions } from 'formik';
import { Component } from 'react';
import { Subscription, forkJoin } from 'rxjs';
import { Text } from '@atoms';

// interfaces
import { KeyValue } from '@models';
import {
    Media,
    Person,
    Todo,
    TodoPayload,
    Employee,
    ServiceArea,
    Service,
    Shift,
} from '@api-interfaces';
import {
    mediaService,
    employeesService,
    serviceAreasService,
    servicesService,
    todosService,
    mixpanelService,
    shiftsService,
} from '@apis';
import { DropdownItem } from '@atoms/form-ds2/dropdown/dropdown.interfaces';
import { FieldDropdown } from '@atoms/form-ds2/field-dropdown';
import { FieldInputDate } from '@atoms/form-ds2/field-input-date';
import { FieldInputFile } from '@atoms/form-ds2/field-input-file';
import { FieldMultiSelect } from '@atoms/form-ds2/field-multi-select';
import { App } from '@core/app-context/app.interfaces';
import ImageContainer from '@core/components/ImageContainer';
import { TimeUtils, sendError, getFullName, getLocations } from '@helpers';
import { useAppContext } from '@hooks';
import { Button } from '@new';
import {
    toasterService,
    imageService,
    modalService,
    refetchService,
    virtualBrownClient,
} from '@services';
import {
    mixpanelCreateQualityTodo,
    mixpanelEditQualityTodo,
} from '@app/mixpanel/MixpanelPageTrack.tsx';
import GalleryCheckBox from '@components/GalleryCheckBox/GalleryCheckBox';

type EmployeeDropdownItem = DropdownItem<Employee>;
type ServiceAreaDropdownItem = DropdownItem<ServiceArea>;
type ServiceDropdownItem = DropdownItem<Service>;
type ShiftDropdownItem = DropdownItem<Shift>;

interface IState {
    employees: EmployeeDropdownItem[];
    buildings: ServiceAreaDropdownItem[];
    floors: ServiceAreaDropdownItem[];
    rooms: ServiceAreaDropdownItem[];
    services: ServiceDropdownItem[];
    shifts: ShiftDropdownItem[];
    photos: any[];
    selectedPhotos: any[];
    isLoaded: boolean;
}

interface IProps {
    handleClose?: () => void;
    handleSuccess?: (todo: Todo) => void;
    callback?: any;
    data?: {
        todo?: Todo;
        incompleteTask?: {
            employee: KeyValue & Person;
            building: KeyValue & {
                id: number;
                name: string;
                type: string;
            };
            floor: KeyValue & {
                id: number;
                name: string;
                type: string;
            };
            task: {
                id: number;
                routeId: number;
                text: string;
            };
        };
        preselect?: {
            // for preselecting building/floor
            building?: KeyValue & {
                id: number;
                name: string;
            };
            employeeId?: number;
            employeeName?: string;
            description?: string;
            floor?: KeyValue & {
                id: number;
                name: string;
            };
        };
        fromSource?: 'MANAGER' | 'AUDIT' | 'REAUDIT'; // default MANAGER
        inspectionId: number | string;
        inspectionareaId: number | string;
    };
    hideTitle?: boolean;
}

class TodoFormComponent extends Component<IProps & App.State, IState> {
    private subscription: Subscription;

    public state: IState = {
        employees: [],
        buildings: [],
        floors: [],
        rooms: [],
        services: [],
        shifts: [],
        photos: [],
        selectedPhotos: [],
        isLoaded: false,
    };

    constructor(props: IProps & App.State) {
        super(props);
        this.subscription = new Subscription();
    }

    public componentDidMount() {
        if (
            this.props.data?.inspectionId &&
            this.props.data?.inspectionareaId
        ) {
            this.getTodoImages();
        }
        const contract =
            this.props.data?.todo?.contract?.id ??
            this.props.selectedContracts?.[0]?.id;
        const employees = employeesService.getEmployees({
            limit: 500,
            detail_level: 'for_dropdown',
            use_ifm: false,
        });
        const serviceArea = serviceAreasService.getServiceAreas({
            contract,
            quick_landing: true,
        });
        const shifts = shiftsService.getShifts({ limit: 1000, offset: 0 });
        const services = servicesService.getServices({ limit: 1000 });

        this.subscription = forkJoin([
            employees,
            services,
            serviceArea,
            shifts,
        ]).subscribe({
            next: ([employeesRes, servicesRes, buildingRes, shiftsRes]) => {
                this.setState({
                    employees: employeesRes.results.map((emp) => ({
                        ...emp,
                        name: getFullName(emp.person),
                        subtext: [
                            emp.person.email_address,
                            emp.organization.name,
                        ]
                            .filter((x) => x)
                            .join(' - '),
                    })),
                    services: servicesRes.results,
                    buildings: buildingRes.results,
                    shifts: shiftsRes.results,
                });
            },
            error: sendError(),
        });

        const locations = this.getLocations();

        if (locations?.building) {
            serviceAreasService
                .getServiceAreas({
                    parent: locations?.building?.id,
                    quick_landing: true,
                })
                .subscribe({
                    next: ({ results: floors }) => {
                        this.setState({
                            floors,
                        });
                    },
                    error: sendError(),
                });
        }
        if (locations?.floor?.name !== undefined) {
            serviceAreasService
                .getServiceAreas({
                    parent: locations.floor?.id,
                    quick_landing: true,
                })
                .subscribe({
                    next: ({ results: rooms }) => {
                        this.setState({
                            rooms,
                        });
                    },
                    error: sendError(),
                });
        }
    }

    componentWillUnmount() {
        this.subscription.unsubscribe();
    }

    public getTodoImages = () => {
        virtualBrownClient
            .get<
                any[]
            >(`inspections/${this.props.data?.inspectionId}/inspectionareas/${this.props.data?.inspectionareaId}/media/`)
            .subscribe((data: any) => {
                if (!data.results || data.results.length === 0) {
                    this.setState({
                        photos: [],
                        isLoaded: true,
                    });
                    return false;
                }
                this.setState({
                    photos: data.results,
                    isLoaded: true,
                });
            });
    };

    public getFloorList = (building: ServiceAreaDropdownItem) => {
        serviceAreasService
            .getServiceAreas({ parent: building.id, quick_landing: true })
            .subscribe({
                next: (res) => {
                    this.setState({
                        ...this.state,
                        floors: res.results,
                    });
                },
                error: (err) =>
                    sendError({
                        toastMessage: err.error
                            ? err.error
                            : 'Cannot retrieve getFloorList API information',
                    }),
            });
    };

    public getRoomList = (floor: ServiceAreaDropdownItem) => {
        serviceAreasService
            .getServiceAreas({ parent: floor.id, quick_landing: true })
            .subscribe({
                next: (res) => {
                    this.setState({
                        ...this.state,
                        rooms: res.results,
                    });
                },
                error: (err) =>
                    sendError({
                        toastMessage: err.error
                            ? err.error
                            : 'Cannot retrieve getRoomList API information',
                    }),
            });
    };

    private sendSuccessToast = (message: string) => {
        toasterService.newToast({
            status: 'success',
            message,
        });
    };

    private handleSuccess = (message: string, todo: Todo) => {
        refetchService.fetch('todos');

        if (this.props.handleSuccess) {
            this.props.handleSuccess(todo);
        } else if (this.props.data?.incompleteTask) {
            this.sendSuccessToast(message);
            modalService.close(todo);
        } else {
            this.sendSuccessToast(message);
            modalService.closeAll({ updatedTodo: todo });
        }
    };

    public submitTodo = (
        formValues: ReturnType<typeof this.mapPropsToForm>,
        { setSubmitting }: FormikActions<any>
    ) => {
        setSubmitting(true);
        const combinedAttachments = [
            ...formValues.attachments,
            ...this.state.selectedPhotos,
        ];
        const media = combinedAttachments.map((attachment) => {
            const { id, is_after_todo } = attachment;
            const todo = this.props?.data?.todo;
            return {
                id,
                ...(todo && {
                    todomedia_properties: {
                        is_after_todo:
                            is_after_todo !== undefined
                                ? is_after_todo || false
                                : true,
                    },
                }),
            };
        });

        let locationId: number;

        if (formValues?.room?.id) {
            locationId = formValues.room?.id;
        } else if (formValues?.floor?.id) {
            locationId = formValues.floor?.id;
        } else {
            locationId = formValues?.building?.id;
        }

        const todoContract = this.props?.data?.todo
            ? { id: this.props.data.todo.contract.id }
            : this.props.selectedContracts.length
              ? {
                    id: this.props.selectedContracts[0].id,
                }
              : { id: null };

        const todoPayload: TodoPayload = {
            comment: formValues.comment,
            is_complete: this.props?.data?.todo?.is_complete ?? false,
            contract: todoContract,
            service: {
                id: formValues.service.id,
            },
            location: {
                id: locationId,
            },
            creator: {
                id:
                    this.props.data?.todo?.creator?.id ??
                    this.props.user.employeeId,
            },
            assigned_to: {
                id: formValues.assigned.id,
            },
            created_on_date:
                this.props?.data?.todo?.created_on_date ??
                TimeUtils.formatToISO(new Date()), // time is set on client so might be less than create_date when it gets to server
            is_new: this.props?.data?.todo?.is_new ?? true,
            last_seen_date: this.props?.data?.todo?.last_seen_date ?? null,
            due_date: new Date(
                `${TimeUtils.format(
                    new Date(formValues.dueDate),
                    'MM/DD/YYYY'
                )} ${formValues.dueTime}`
            ),
            closed_date: this.props.data?.todo?.closed_date ?? null,
            // @ts-ignore
            media,
            source:
                this.props?.data?.todo?.source ??
                this.props?.data?.fromSource ??
                'MANAGER',
            shifts: formValues.shifts.map(function (shift) {
                return shift.id;
            }),
            is_project_work: formValues.isProjectWork,
            hideTitle: this.props.hideTitle ?? false,
        };

        if (this.props.data?.todo) {
            return todosService
                .updateTodoById(this.props.data.todo.id, todoPayload)
                .subscribe({
                    next: (res) => {
                        if (res && res.id) {
                            mixpanelEditQualityTodo();
                            this.handleSuccess(
                                'The to-do was updated successfully',
                                res
                            );
                        }
                    },
                    error: (err) =>
                        sendError({
                            toastMessage: err.detail
                                ? err.detail
                                : 'There was an error updating the to-do.',
                            callback: () => {
                                setSubmitting(false);
                            },
                        }),
                });
        }
        // before creating check if  this came from incomplete route task
        // if so then attach to the task to the todo so it can link back to it
        if (this.props?.data?.incompleteTask?.task?.id) {
            todoPayload.task = {
                id: this.props.data.incompleteTask.task.id,
            };
        }
        return todosService.createTodo(todoPayload).subscribe({
            next: (res) => {
                // callback is needed for audits, inspections and incomplete routes - don't remove
                if (
                    this.props.callback &&
                    typeof this.props.callback === 'function'
                )
                    this.props.callback(res);
                mixpanelCreateQualityTodo();
                this.handleSuccess(
                    'The to-do item was created successfully',
                    res
                );
            },
            error: (err) =>
                sendError({
                    toastMessage: err.error
                        ? err.error
                        : 'There was an error creating the to-do item.',
                    callback: () => {
                        setSubmitting(false);
                    },
                }),
        });
    };

    private getLocations = () => {
        const incompleteTask = this.props?.data?.incompleteTask;
        const preselect = this.props?.data?.preselect;

        const defaults: ServiceArea = {
            address: null,
            boundary_id: null,
            id: null,
            name: null,
            parent: null,
            parent_id: null,
            services: null,
            sq_ft: null,
            type: null,
        };

        const locations = getLocations(this.props.data?.todo?.location);

        // sometimes a building will have rooms are children
        if (locations?.room && !locations?.floor) {
            locations.floor = locations.room;
        }
        if (incompleteTask) {
            if (incompleteTask.building) {
                locations.building = {
                    ...defaults,
                    id: incompleteTask.building.id,
                    name: incompleteTask.building.name,
                };
            }
            if (incompleteTask.floor) {
                locations.floor = {
                    ...defaults,
                    id: incompleteTask.floor.id,
                    name: incompleteTask.floor.name,
                };
            }
        } else if (preselect) {
            if (preselect.building) {
                locations.building = {
                    ...defaults,
                    id: preselect.building.id,
                    name: preselect.building.name,
                };
            }
            if (preselect.floor) {
                locations.floor = {
                    ...defaults,
                    id: preselect.floor.id,
                    name: preselect.floor.name,
                };
            }
        }

        return locations;
    };

    private mapPropsToForm = () => {
        const todo = this.props?.data?.todo;
        const incompleteTask = this.props?.data?.incompleteTask;
        const comment =
            this.props?.data?.preselect?.description ??
            todo?.comment ??
            incompleteTask?.task?.text ??
            '';
        const preselectedEmployee = this.props?.data?.preselect?.employeeName;
        const shifts = this.props?.data?.todo?.shifts ?? [];
        const assigned: EmployeeDropdownItem = todo
            ? {
                  ...todo.assigned_to,
                  name: getFullName(todo.assigned_to.person),
                  subtext: `${todo.assigned_to.person.email_address} - ${todo.assigned_to.organization.name}`,
              }
            : incompleteTask
              ? {
                    ...incompleteTask.employee,
                    id: +incompleteTask.employee.Key,
                    name: getFullName(incompleteTask.employee),
                }
              : preselectedEmployee
                ? {
                      id: this.props.data.preselect.employeeId,
                      name: preselectedEmployee,
                  }
                : null;
        const service: ServiceDropdownItem = todo?.service
            ? todo.service
            : null;
        const dueDate = this.props?.data?.todo?.due_date
            ? new Date(this.props.data.todo.due_date)
            : new Date();
        const isProjectWork = todo?.is_project_work ?? false;
        return {
            assigned,
            comment,
            service,
            dueDate,
            dueTime: TimeUtils.format(dueDate, 'HH:mm'),
            attachments: this.props.data?.todo?.media_list ?? [],
            ...this.getLocations(),
            shifts,
            isProjectWork,
        };
    };

    private projectWorkItems = [
        { id: 0, name: 'No' },
        { id: 1, name: 'Yes' },
    ];

    public render() {
        return (
            <Formik
                initialValues={{
                    ...this.mapPropsToForm(),
                }}
                validate={(values) => {
                    const errors: {
                        [Key in keyof typeof values]: string;
                    } = {} as any;
                    if (!values.assigned) {
                        errors.assigned = 'An employee is required.';
                    }
                    if (!values?.building) {
                        errors.building = 'A building is required.';
                    }
                    if (!values.service) {
                        errors.service = 'A service type is required.';
                    }

                    if (!values.dueTime) {
                        errors.dueTime = `A Valid Time Due with AM/PM is required.`;
                    }

                    if (!values.comment) {
                        errors.comment = 'A description is required.';
                    }

                    return errors;
                }}
                onSubmit={(values, formikActions) => {
                    this.submitTodo(values, formikActions);
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleSubmit,
                    setFieldValue,
                    isSubmitting,
                }) => (
                    <form
                        className="tw-space-y-6 tw-p-4 sm:tw-p-6"
                        onSubmit={handleSubmit}
                        data-testid="todo-form"
                    >
                        {!this.props.hideTitle && (
                            <Text font="h2" color="hi-contrast">
                                {this?.props?.data?.todo?.id
                                    ? 'Edit To-Do'
                                    : 'New To-Do'}
                            </Text>
                        )}
                        <Text font="body-lg" color="neutral-offset">
                            Fill out form to assign a To-Do to an employee
                        </Text>

                        <FieldDropdown
                            data-testid="assignedEmployeeField"
                            required
                            id="assigned-employee"
                            label="Assigned Employee"
                            items={this.state.employees}
                            selectedItem={values.assigned}
                            onChange={(employee) => {
                                setFieldValue('assigned', employee);
                                setFieldValue('shifts', employee.shifts);
                            }}
                            error={
                                touched.assigned && (errors.assigned as string)
                            }
                        />

                        <div className="tw-flex tw-space-x-4">
                            <FieldDropdown
                                data-testid="projectWorkField"
                                required
                                id="project-work"
                                label="Project Work"
                                items={this.projectWorkItems}
                                selectedItem={
                                    values.isProjectWork === false
                                        ? this.projectWorkItems[0]
                                        : this.projectWorkItems[1]
                                }
                                onChange={(isProjectWork) => {
                                    setFieldValue(
                                        'isProjectWork',
                                        isProjectWork?.name === 'Yes'
                                    );
                                }}
                                error={
                                    touched.isProjectWork &&
                                    (errors.isProjectWork as string)
                                }
                                disabled={
                                    this?.props?.data?.todo?.work_order_id !=
                                    null
                                }
                            />
                            <FieldDropdown
                                data-testid="serviceTypeField"
                                required
                                id="service-type"
                                label="Service Type"
                                items={this.state.services}
                                selectedItem={values.service}
                                onChange={(service) => {
                                    setFieldValue('service', service);
                                }}
                                error={
                                    touched.service &&
                                    (errors.service as string)
                                }
                            />
                        </div>

                        <div className="tw-space-y-6 sm:tw-space-y-0 sm:tw-flex sm:tw-space-x-4">
                            <FieldInputDate
                                data-testid="todoDueDateField"
                                required
                                id="todo_due_date"
                                label="Due Date"
                                value={TimeUtils.format(
                                    values.dueDate,
                                    'MM/DD/YYYY'
                                )}
                                onChange={(date) => {
                                    setFieldValue('dueDate', date);
                                }}
                            />

                            <FieldInputTime
                                data-testid="todoDueTimeField"
                                required
                                id="due-time"
                                name="dueTime"
                                label="Due At"
                                error={touched.dueTime && errors.dueTime}
                                value={values.dueTime}
                                onChange={handleChange}
                            />
                        </div>

                        <FieldDropdown
                            data-testid="buildingField"
                            required
                            id="building"
                            label="Building"
                            items={this.state.buildings}
                            selectedItem={values?.building}
                            onChange={(building: ServiceAreaDropdownItem) => {
                                this.getFloorList(building);
                                setFieldValue('building', building);
                                setFieldValue('floor', null);
                                setFieldValue('room', null);
                            }}
                            disabled={
                                !!this.props?.data?.incompleteTask ||
                                !this.state?.buildings.length
                            }
                            error={
                                touched?.building && (errors.building as string)
                            }
                        />

                        <FieldDropdown
                            data-testid="floorField"
                            id="floor"
                            label="Floor"
                            items={this.state.floors}
                            selectedItem={values?.floor}
                            onChange={(floor: ServiceAreaDropdownItem) => {
                                this.getRoomList(floor);
                                setFieldValue('floor', floor);
                                setFieldValue('room', null);
                            }}
                            disabled={
                                !!this.props?.data?.incompleteTask ||
                                !this.state?.buildings.length ||
                                !values?.building
                            }
                        />

                        <FieldDropdown
                            data-testid="roomField"
                            id="room"
                            label="Area (Optional)"
                            items={this.state.rooms}
                            selectedItem={values?.room}
                            onChange={(room) => {
                                setFieldValue('room', room);
                            }}
                            disabled={
                                !this.state.floors.length || !values?.floor
                            }
                        />

                        <FieldMultiSelect
                            data-testid="shiftsField"
                            id="shifts"
                            label="Shifts"
                            items={this.state.shifts}
                            selectedItems={values?.shifts}
                            onChange={(selectedShifts) => {
                                setFieldValue('shifts', selectedShifts);
                            }}
                            disabled
                            selectAllFont="h4"
                            placeholder="Search"
                            labelFont="body-md"
                        />

                        <FieldInputText
                            data-testid="descriptionField"
                            required
                            fieldType="textarea"
                            id="comment"
                            name="comment"
                            label="Description"
                            onChange={handleChange}
                            placeholder="Enter a description"
                            value={values.comment}
                            rows={3}
                            error={touched.comment && errors.comment}
                        />

                        <div>
                            <FieldInputFile
                                data-testid="attachmentsField"
                                name="photos"
                                label="Add Photos (Optional)"
                                buttonLabel="Add Photos"
                                accept=".png,.jpg,.jpeg,.gif"
                                onChange={(e) => {
                                    const getMedia = async () => {
                                        const attachments: Media[] = [];

                                        for (const file of Array.from(
                                            e.target.files
                                        )) {
                                            const media = await mediaService
                                                .upload(file, {
                                                    employeeId:
                                                        this.props.user
                                                            .employeeId,
                                                    organizationId:
                                                        this.props.user
                                                            .organization.id,
                                                })
                                                .toPromise()
                                                .catch(() => {
                                                    toasterService.newToast({
                                                        status: 'fail',
                                                        message: `Error uploading ${file.name}`,
                                                    });
                                                    return null;
                                                });

                                            if (media) {
                                                attachments.push(media);
                                            }
                                        }

                                        setFieldValue('attachments', [
                                            ...values.attachments,
                                            ...attachments,
                                        ]);
                                    };

                                    getMedia();
                                }}
                            />

                            {values.attachments.map(
                                (attachment, index: number) => (
                                    <ImageContainer
                                        key={attachment.id}
                                        data={{
                                            image: imageService.getImageByUniqueId(
                                                attachment.file_name,
                                                false
                                            ),
                                            createdDate: new Date(
                                                attachment.create_date
                                            ),
                                        }}
                                        methods={{
                                            onRemove: () => {
                                                setFieldValue(
                                                    'attachments',
                                                    values.attachments.filter(
                                                        (_, i) => i !== index
                                                    )
                                                );
                                            },
                                        }}
                                        settings={{
                                            openInWindow: true,
                                        }}
                                    />
                                )
                            )}
                            {this.props.data?.inspectionareaId &&
                                this.props.data?.inspectionId &&
                                this.state.isLoaded && (
                                    <>
                                        <Text
                                            font="body-sm"
                                            color="neutral-offset"
                                            className=" tw-mt-5"
                                        >
                                            Area Photos
                                        </Text>
                                        <GalleryCheckBox
                                            imagesList={this.state.photos}
                                            selectedImages={
                                                this.state.selectedPhotos
                                            }
                                            setSelectedImages={(
                                                updatedSelectedPhotos
                                            ) =>
                                                this.setState({
                                                    selectedPhotos:
                                                        updatedSelectedPhotos,
                                                })
                                            }
                                        />
                                    </>
                                )}
                        </div>

                        <div className="tw-flex tw-justify-end tw-space-x-4">
                            <Button
                                label="Cancel"
                                onClick={() => {
                                    if (this.props.handleClose) {
                                        this.props.handleClose();
                                    } else if (
                                        this.props?.data?.incompleteTask
                                    ) {
                                        modalService.close();
                                    } else {
                                        modalService.closeAll();
                                    }
                                }}
                                disabled={isSubmitting}
                                color="secondary"
                            />
                            <SubmitButton
                                label="Submit"
                                disabled={isSubmitting}
                            />
                        </div>
                    </form>
                )}
            </Formik>
        );
    }
}

export const TodoForm = (props: IProps) => {
    const { state } = useAppContext();

    return <TodoFormComponent {...state} {...props} />;
};
