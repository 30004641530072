import { useState, useEffect } from 'react';
import * as React from 'react';

// services
import { Service } from '@api-interfaces';
import { servicesService } from '@apis';
import { Text } from '@atoms';
import { FieldDropdown } from '@atoms/form-ds2/field-dropdown';
import { sendError } from '@helpers';
import { Button } from '@new';
import { modalService } from '@services';

// helpers

// components

// interfaces

interface Props {
  data: { title: string };
  nextModal: { component: React.ReactElement<any>; callback?: () => void };
}

export const SelectProgramForm = (props: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [programs, setPrograms] = useState<Service[]>([]);
  const [program, setProgram] = useState<Service>(null);

  useEffect(() => {
    const subscription = servicesService.getServices({ limit: 50 }).subscribe({
      next: ({ results: programs }) => {
        setPrograms(programs);
        setIsLoading(false);
      },
      error: sendError({
        toastMessage: 'There was an error retrieving the list of Programs',
        callback: () => {
          setIsLoading(false);
        },
      }),
    });

    return () => subscription.unsubscribe();
  }, []);

  function handleProgramChange(selectedProgram: Service) {
    setProgram(selectedProgram);
  }

  function handleOnSubmit(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    modalService.open({
      component: React.cloneElement(props.nextModal.component, {
        selectedProgram: program,
      }),
    });
  }

  return (
    <form className="tw-space-y-6 tw-p-4 sm:tw-p-6">
      <Text font="h2" color="hi-contrast">
        {props.data.title}
      </Text>

      <FieldDropdown
        id="program"
        label="Please select a Program"
        items={programs}
        selectedItem={program}
        onChange={handleProgramChange}
        disabled={isLoading}
      />

      <div className="tw-flex tw-justify-end">
        <Button
          label="Submit"
          disabled={!program?.id}
          onClick={handleOnSubmit}
        />
      </div>
    </form>
  );
};
