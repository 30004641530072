import { debounce, cloneDeep } from 'lodash';
import { useEffect, useState } from 'react';
import * as React from 'react';

// services
import { forkJoin } from 'rxjs';

import {
    CostLineItem,
    WORK_ORDER_WORK_TYPES,
    WorkOrderWorkType,
    WorkOrdersSummary,
    BusinessUnitPurchaseOrder,
    BusinessUnit,
    CUSTOMER_WORK_ORDER,
} from '@api-interfaces';
import { workOrdersService, financialsService } from '@apis';

// components
import {
    calculatePOFundsRemaining,
    nullPOOptionName,
} from '@app/modals/_ds2/work-order-form/_helpers';
import { Icon, Text } from '@atoms';
import { DropdownItem } from '@atoms/form-ds2/dropdown/dropdown.interfaces';
import { FieldDropdown } from '@atoms/form-ds2/field-dropdown';
import { FieldInputText } from '@atoms/form-ds2/field-input-text';
import { FieldRadioGroup } from '@atoms/form-ds2/field-radio-group';

// helpers
import { currencyFriendlyString, sendError, TimeUtils } from '@helpers';
import { Button } from '@new';

import { useWorkOrderModalContext } from '../../../../_context';

// interfaces

const POOptionsDelimiter = ' -';
const numCheckReg = /^[0-9]*(\.[0-9]*)?$/;
const tableHeaders = ['Quantity', '', 'Unit Cost', '', 'Total Cost'];

const lineItemWorkTypes = WORK_ORDER_WORK_TYPES.map((workType, index) => ({
    id: index,
    name: workType,
}));

type BUDropdownItem = DropdownItem<BusinessUnit>;
type PurchaseOrderDropdownItem = DropdownItem<BusinessUnitPurchaseOrder>;
type LineItemProgramDropdownItem =
    DropdownItem<WorkOrdersSummary.WorkOrderCountsByProgram>;

export const WorkOrderBilling = () => {
    const {
        isEditing,
        isClosing,
        workOrderEdit,
        setWorkOrderEdit,
        activePurchaseOrder,
        setActivePurchaseOrder,
        submissionAttempt,
    } = useWorkOrderModalContext();
    const {
        is_billable,
        po_number,
        contract,
        net_suite_id,
        cost_line_items,
        customer_work_order,
        bu_name,
    } = workOrderEdit;
    const [isLoading, setIsLoading] = useState(true);
    const [BUOptions, setBUOptions] = useState<BUDropdownItem[]>([]);
    const [POOptions, setPOOptions] = useState<PurchaseOrderDropdownItem[]>([]);
    const [lineItemPrograms, setLineItemPrograms] = useState<
        LineItemProgramDropdownItem[]
    >([]);
    const [isPurchaseOrdersLoading, setIsPurchaseOrdersLoading] =
        useState(false);
    const [fundsRemaining, setFundsRemaining] = useState<null | number>(null);

    useEffect(() => {
        if (!isLoading || !is_billable) return;

        const now = new Date();
        const thirtyDaysAgo = TimeUtils.subMonths(now, 1);

        const subscription = forkJoin([
            financialsService.businessUnits.getBusinessUnits({
                contract: contract.id,
            }),
            workOrdersService.summary.getSummaries({
                chart: [WorkOrdersSummary.Chart.GetWorkOrderCountsByProgram],
                from: thirtyDaysAgo,
                to: now,
                all_child: true, // Grab all the child services
            }),
        ]).subscribe({
            next: ([businessUnitsRes, servicesSummaryRes]) => {
                if ('Locations' in businessUnitsRes) {
                    const businessUnits = businessUnitsRes.Locations.map(
                        ({ Location }) => ({
                            ...Location,
                            id: +Location['NS ID'],
                            name: Location.BU,
                        })
                    );
                    setBUOptions(businessUnits);
                }

                setLineItemPrograms(servicesSummaryRes?.[0]?.results ?? []);
                setIsLoading(false);
            },
            error: sendError({
                toastMessage: 'Unable to retrieve the Business Units list',
                callback: () => {
                    setIsLoading(false);
                },
            }),
        });

        return () => {
            subscription.unsubscribe();
        };
    }, [isLoading, is_billable]);

    useEffect(() => {
        if (!is_billable) return;
        if (!net_suite_id && !isPurchaseOrdersLoading) return;

        const subscription = financialsService.businessUnits
            .getBusinessUnitPurchaseOrders({
                bu: net_suite_id,
                contract: contract.id,
            })
            .subscribe({
                next: (res) => {
                    if (res.success === 'true') {
                        // customer_po_description === 'N/A' is a default option created on the BE
                        const purchaseOrders = res.customer_po.map((po) => ({
                            ...po,
                            id: +po.customer_po_id,
                            name:
                                po.customer_po_description === 'N/A'
                                    ? nullPOOptionName
                                    : `${po.customer_po_number} - ${
                                          po.customer_po_description ??
                                          'No Description Available'
                                      }`,
                        }));

                        const activePurchaseOrder = purchaseOrders.find(
                            (po) => +po.customer_po_number === +po_number
                        );
                        setActivePurchaseOrder(activePurchaseOrder || null);
                        setPOOptions(purchaseOrders);
                        setIsPurchaseOrdersLoading(false);
                        setFundsRemaining(
                            activePurchaseOrder?.funds_remaining ?? 0
                        );
                    } else {
                        sendError({
                            toastMessage:
                                'There was an error fetching PO numbers related to the selected location',
                        });
                    }
                },
                error: sendError({
                    toastMessage:
                        'There was an error fetching PO numbers related to the selected location',
                    callback: () => {
                        setIsPurchaseOrdersLoading(false);
                    },
                }),
            });

        return () => {
            subscription.unsubscribe();
        };
    }, [net_suite_id, isPurchaseOrdersLoading, is_billable]);

    const handleQuantityChange =
        (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
            if (e.target.value === '' || numCheckReg.test(e.target.value)) {
                cost_line_items[index] = {
                    ...cost_line_items[index],
                    quantity: Number(e.target.value),
                    cost: +(
                        Number(e.target.value) *
                        Number(cost_line_items?.[index]?.unitcost)
                    ).toFixed(2),
                };

                setWorkOrderEdit({
                    ...workOrderEdit,
                    cost_line_items,
                });
            }
            setFundsRemaining(
                calculatePOFundsRemaining(cost_line_items, activePurchaseOrder)
            );
        };

    const handleUnitCostChange =
        (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
            if (e.target.value === '' || numCheckReg.test(e.target.value)) {
                cost_line_items[index] = {
                    ...cost_line_items[index],
                    unitcost: Number(e.target.value),
                    cost: +(
                        Number(e.target.value) *
                        Number(cost_line_items?.[index]?.quantity)
                    ).toFixed(2),
                };

                setWorkOrderEdit({
                    ...workOrderEdit,
                    cost_line_items,
                });
            }
            setFundsRemaining(
                calculatePOFundsRemaining(cost_line_items, activePurchaseOrder)
            );
        };

    const handleBillingDescriptionChange =
        (index: number) => (e: React.ChangeEvent<HTMLTextAreaElement>) => {
            cost_line_items[index] = {
                ...cost_line_items[index],
                billing_description: e.target.value,
            };

            setWorkOrderEdit({
                ...workOrderEdit,
                cost_line_items,
            });
        };

    const handleCustomerWorkOrderChange = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        setWorkOrderEdit({
            ...workOrderEdit,
            customer_work_order: e.target.value,
        });
    };

    const handleCLIServiceChange = (index: number, service_id: number) => {
        const costLineItemsCopy = cloneDeep(cost_line_items);
        costLineItemsCopy[index] = {
            ...cost_line_items[index],
            service: {
                id: service_id,
            },
        };

        setWorkOrderEdit({
            ...workOrderEdit,
            cost_line_items: costLineItemsCopy,
        });
    };

    function onCostLineItemCreate(e: React.MouseEvent<HTMLButtonElement>) {
        e.preventDefault();

        const selectedProgramOption = lineItemPrograms.find(
            (program) => program.id === workOrderEdit?.service?.id
        );
        const selectedService = selectedProgramOption
            ? {
                  id: selectedProgramOption?.id,
                  name: selectedProgramOption?.name,
              }
            : null;

        const costLineItem: CostLineItem = {
            id: null,
            cost: 0,
            unitcost: 0,
            quantity: 0,
            work_type: null,
            service: selectedService,
            billing_description: '',
            workorder_id: workOrderEdit.id,
        };

        setWorkOrderEdit({
            ...workOrderEdit,
            cost_line_items: workOrderEdit.cost_line_items.concat(costLineItem),
        });
    }

    const debouncedQuantityChange = (index: number) =>
        debounce(handleQuantityChange(index), 500);
    const debouncedUnitCostChange = (index: number) =>
        debounce(handleUnitCostChange(index), 500);
    const debouncedBillingDescriptionChange = (index: number) =>
        debounce(handleBillingDescriptionChange(index), 500);
    const debouncedCustomerWorkOrderChange = debounce(
        handleCustomerWorkOrderChange,
        500
    );

    return (
        <div className="tw-space-y-6 tw-max-w-[456px]">
            <Text font="h2" color="primary">
                Billing
            </Text>
            {isEditing ? (
                <div className="tw-flex tw-justify-between tw-flex-col sm:tw-flex-row tw-gap-1">
                    <FieldRadioGroup
                        required
                        id="work_order_billable"
                        name="work_order_billable"
                        label="Is this Work Order Billable?"
                        radios={[
                            {
                                value: 'No',
                                label: 'No',
                            },
                            {
                                value: 'Yes',
                                label: 'Yes',
                            },
                        ]}
                        onChange={(e) => {
                            const { value } = e.target;
                            setWorkOrderEdit({
                                ...workOrderEdit,
                                is_billable: value === 'Yes',
                            });
                        }}
                        defaultChecked={
                            workOrderEdit?.is_billable ? 'Yes' : 'No'
                        }
                        error={
                            isClosing &&
                            is_billable == null &&
                            'This field is required'
                        }
                    />
                    <FieldRadioGroup
                        id="work_order_in_scope"
                        name="work_order_in_scope"
                        label="Is this Work Order In Scope?"
                        radios={[
                            {
                                value: 'No',
                                label: 'No',
                            },
                            {
                                value: 'Yes',
                                label: 'Yes',
                            },
                        ]}
                        onChange={(e) => {
                            const { value } = e.target;
                            setWorkOrderEdit({
                                ...workOrderEdit,
                                is_in_scope: value === 'Yes',
                            });
                        }}
                        defaultChecked={
                            workOrderEdit?.is_in_scope ? 'Yes' : 'No'
                        }
                    />
                </div>
            ) : (
                <Section
                    label="Is Work Order Billable?"
                    content={is_billable ? 'Yes' : 'No'}
                />
            )}
            {is_billable && !isEditing && (
                <>
                    <Section label="Business Unit" content={bu_name} />
                    <Section
                        label="P.O. #  / Cost Center"
                        content={po_number ?? '--'}
                    />
                    <Section
                        label="Customer Work Order"
                        content={customer_work_order}
                    />
                    <Text font="h3" color="primary">
                        Cost Line Items
                    </Text>

                    {cost_line_items.map((cost_line_item, i) => (
                        <React.Fragment
                            key={cost_line_item?.id ?? `cost_line_item-${i}`}
                        >
                            <table>
                                <thead>
                                    <tr>
                                        {tableHeaders.map((header, i) => (
                                            <th
                                                key={`${header}-${i}`}
                                                className={`tw-text-left tw-pb-4 ${
                                                    !header ? 'tw-w-14' : ''
                                                }`}
                                            >
                                                <Label label={header} />
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="tw-pr-4">
                                            <Content
                                                content={
                                                    cost_line_item?.quantity
                                                }
                                            />
                                        </td>

                                        <td>
                                            {cost_line_item?.quantity !=
                                                undefined && (
                                                <Content content="X" />
                                            )}
                                        </td>

                                        <td className="tw-pr-4">
                                            <Content
                                                content={
                                                    cost_line_item?.unitcost !==
                                                    null
                                                        ? currencyFriendlyString(
                                                              cost_line_item?.unitcost
                                                          )
                                                        : null
                                                }
                                            />
                                        </td>

                                        <td>
                                            {cost_line_item?.quantity !=
                                                undefined && (
                                                <Content content="=" />
                                            )}
                                        </td>

                                        <td>
                                            <Content
                                                content={
                                                    cost_line_item?.cost !==
                                                    null
                                                        ? currencyFriendlyString(
                                                              cost_line_item?.cost
                                                          )
                                                        : null
                                                }
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <Section
                                label="Program"
                                content={cost_line_item?.service?.name}
                            />
                            <Section
                                label="Work Type"
                                content={cost_line_item?.work_type}
                            />
                            <Section
                                label="Billing Description"
                                content={cost_line_item?.billing_description}
                            />
                        </React.Fragment>
                    ))}
                </>
            )}
            {isEditing && is_billable && (
                <>
                    <FieldDropdown
                        required
                        id="work_order_bu"
                        label="Business Unit"
                        items={BUOptions}
                        selectedItem={
                            BUOptions.find((bu) => bu.id === net_suite_id) ||
                            null
                        }
                        onChange={(selectedBU) => {
                            setWorkOrderEdit({
                                ...workOrderEdit,
                                net_suite_id: selectedBU.id,
                                bu_name: selectedBU.name,
                            });

                            setIsPurchaseOrdersLoading(true);
                        }}
                        error={
                            submissionAttempt &&
                            is_billable &&
                            !workOrderEdit?.net_suite_id &&
                            'This field is required'
                        }
                        disabled={!BUOptions.length}
                    />

                    <FieldDropdown
                        required
                        id="po-number"
                        label="P.O. # / Cost Center"
                        enableClear
                        items={POOptions.sort((a, b) =>
                            a.name === 'N/A' ? -1 : 0
                        )}
                        selectedItem={
                            !workOrderEdit?.net_suite_id ||
                            isPurchaseOrdersLoading
                                ? null
                                : activePurchaseOrder
                        }
                        onChange={(po: typeof activePurchaseOrder) => {
                            setWorkOrderEdit({
                                ...workOrderEdit,
                                po_number: po.name.split(POOptionsDelimiter)[0],
                                po_number_id: po.id,
                            });
                            setActivePurchaseOrder(po);
                            setFundsRemaining(po.funds_remaining);
                        }}
                        disabled={
                            !workOrderEdit?.net_suite_id ||
                            isPurchaseOrdersLoading
                        }
                        error={
                            submissionAttempt &&
                            is_billable &&
                            !workOrderEdit?.po_number &&
                            'This field is required'
                        }
                    />

                    {po_number === CUSTOMER_WORK_ORDER && (
                        <FieldInputText
                            required
                            id="work_order_customer_work_order"
                            label="Customer Work Order"
                            defaultValue={
                                workOrderEdit?.customer_work_order ?? ''
                            }
                            onChange={debouncedCustomerWorkOrderChange}
                            max={20}
                            error={
                                is_billable &&
                                (!workOrderEdit?.customer_work_order ||
                                    !workOrderEdit?.customer_work_order.trim()) &&
                                'This field is required'
                            }
                        />
                    )}

                    <Text font="h3" color="primary">
                        Cost Line Items
                    </Text>

                    <div className="tw-space-y-4">
                        <div className="tw-flex tw-justify-between">
                            <Label label="Total PO Funds" />
                            <Label label="Remaining PO Funds" />
                        </div>
                        <div className="tw-flex tw-justify-between">
                            {activePurchaseOrder?.total_funds >= 0 &&
                            fundsRemaining !== null ? (
                                <>
                                    <Content
                                        content={currencyFriendlyString(
                                            activePurchaseOrder.total_funds
                                        )}
                                    />
                                    <Content
                                        content={
                                            fundsRemaining
                                                ? currencyFriendlyString(
                                                      fundsRemaining
                                                  )
                                                : 'N/A'
                                        }
                                        color={
                                            fundsRemaining > 0
                                                ? 'hi-contrast'
                                                : 'danger'
                                        }
                                    />
                                </>
                            ) : net_suite_id && po_number ? (
                                isPurchaseOrdersLoading ? (
                                    // PO and BU are selected, fetching funding info
                                    <Content content="Fetching PO funding information" />
                                ) : (
                                    // PO and BU are selected, but there is no matching funding info
                                    <Content content="No matching PO funding information" />
                                )
                            ) : (
                                // Either PO or BU needs to be selected to populate data
                                <Content content="Please select a Business Unit and a PO" />
                            )}
                        </div>
                    </div>

                    {/* ################################################### */}
                    {/* Cost Line Items */}
                    {/* ################################################### */}

                    {cost_line_items.map((costLineItem, i) => (
                        <React.Fragment
                            key={costLineItem?.id ?? `cost_line_item-${i}`}
                        >
                            <div className="tw-flex tw-flex-col tw-space-y-6 sm:tw-space-y-0 sm:tw-space-x-4 sm:tw-flex-row">
                                <div className="tw-flex">
                                    <div className="tw-w-[74px]">
                                        <FieldInputText
                                            id={`cost_line_item-quantity-${i}`}
                                            label="Quantity"
                                            type="number"
                                            onChange={debouncedQuantityChange(
                                                i
                                            )}
                                            defaultValue={
                                                costLineItem?.quantity || ''
                                            }
                                            error={
                                                is_billable &&
                                                !costLineItem?.quantity &&
                                                'This field is required'
                                            }
                                        />
                                    </div>
                                    <div className="tw-h-full tw-pt-9 tw-mx-3 sm:tw-mx-5 tw-flex tw-justify-center">
                                        <Icon
                                            size="lg"
                                            className="tw-text-neutral-500"
                                            icon={['far', 'times']}
                                        />
                                    </div>
                                    <div className="tw-w-[74px]">
                                        <FieldInputText
                                            id={`cost_line_item-unitcost-${i}`}
                                            label="Unit Cost ($)"
                                            type="number"
                                            onChange={debouncedUnitCostChange(
                                                i
                                            )}
                                            defaultValue={
                                                costLineItem?.unitcost || ''
                                            }
                                            error={
                                                is_billable &&
                                                !costLineItem?.unitcost &&
                                                'This field is required'
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="tw-flex tw-w-full sm:tw-w-auto sm:tw-space-y-0 tw-space-x-4">
                                    <div className="tw-h-full tw-pt-[38px] tw-flex tw-justify-center">
                                        <Icon
                                            className="tw-text-neutral-500 "
                                            icon={['fas', 'equals']}
                                        />
                                    </div>
                                    <FieldInputText
                                        disabled
                                        id={`cost_line_item-cost-${i}`}
                                        label="Total Cost ($)"
                                        value={`${currencyFriendlyString(
                                            costLineItem?.cost
                                        )}`}
                                    />
                                </div>
                            </div>
                            <FieldDropdown
                                required
                                id={`work_order_line_item_${
                                    costLineItem?.id ?? i
                                }`}
                                label="Program"
                                items={lineItemPrograms}
                                selectedItem={
                                    lineItemPrograms.find(
                                        (program) =>
                                            program.id ===
                                            costLineItem?.service?.id
                                    ) ||
                                    lineItemPrograms.find(
                                        (program) =>
                                            program.id ===
                                            workOrderEdit?.service?.id
                                    ) ||
                                    null
                                }
                                onChange={(
                                    lineItemProgram: LineItemProgramDropdownItem
                                ) =>
                                    handleCLIServiceChange(
                                        i,
                                        +lineItemProgram.id
                                    )
                                }
                                error={
                                    !costLineItem?.service?.id &&
                                    'This field is required'
                                }
                                disabled={!lineItemPrograms.length}
                            />
                            <FieldDropdown
                                required
                                id="work_order_work_type"
                                label="Work Type"
                                items={lineItemWorkTypes}
                                selectedItem={
                                    lineItemWorkTypes.find(
                                        (workType) =>
                                            workType.name ===
                                            costLineItem.work_type
                                    ) || null
                                }
                                onChange={(selectedWorkType) => {
                                    const costLineItemsCopy =
                                        cloneDeep(cost_line_items);
                                    costLineItemsCopy[i] = {
                                        ...costLineItem,
                                        work_type:
                                            selectedWorkType.name as WorkOrderWorkType,
                                    };

                                    setWorkOrderEdit({
                                        ...workOrderEdit,
                                        cost_line_items: costLineItemsCopy,
                                    });
                                }}
                                error={
                                    costLineItem.work_type === null &&
                                    'This field is required'
                                }
                            />
                            <FieldInputText
                                required
                                fieldType="textarea"
                                id={`cost_line_item-billing_description-${i}`}
                                label="Billing Description"
                                defaultValue={
                                    costLineItem?.billing_description ?? ''
                                }
                                onChange={debouncedBillingDescriptionChange(i)}
                                max={2048}
                                error={
                                    is_billable &&
                                    !costLineItem.billing_description &&
                                    'This field is required'
                                }
                            />
                            <div className="tw-w-full">
                                <Button
                                    label="Delete Cost Line Item"
                                    color="danger"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setWorkOrderEdit({
                                            ...workOrderEdit,
                                            cost_line_items:
                                                workOrderEdit.cost_line_items.filter(
                                                    (_, index) => index != i
                                                ),
                                        });
                                    }}
                                    icon={['fas', 'minus']}
                                />
                            </div>
                        </React.Fragment>
                    ))}

                    <div className="tw-w-full">
                        <Button
                            label="Cost Line Item"
                            onClick={onCostLineItemCreate}
                            icon={['far', 'plus']}
                        />
                    </div>

                    <div className="tw-flex tw-justify-between">
                        <Label label="Cost Line Item Total" />
                        {workOrderEdit?.cost_line_items.length > 0 ? (
                            <Label
                                label={currencyFriendlyString(
                                    workOrderEdit?.cost_line_items
                                        ?.map((x) => x.cost)
                                        ?.reduce((a, b) => a + b) ?? 0
                                )}
                            />
                        ) : (
                            <Label label={currencyFriendlyString(0)} />
                        )}
                    </div>
                </>
            )}
        </div>
    );
};

const Label = ({ label }) => (
    <Text font="h3" color="hi-contrast">
        {label}
    </Text>
);

const Content = ({ content, color = null }) => {
    if (!content) {
        return (
            <Text font="body-md" color="hi-contrast">
                ---
            </Text>
        );
    }
    return (
        <Text font="body-md" color={color || 'hi-contrast'}>
            {content}
        </Text>
    );
};

const Section = ({ label, content, color = null }) => (
    <div className="tw-space-y-4">
        <Label label={label} />
        <Content content={content} color={color} />
    </div>
);
