import { SummaryChart } from '@models';

export namespace ConductSummary {
	export enum Chart {
		'GetConductTypeCounts' = 0,
		'GetConductTypeCountsByMonth' = 1,
	}

	export interface ConductTypeCounts {
		Final_Warning: number;
		Verbal_Warning: number;
		Written_Warning: number;
	}

	export interface ConductTypeCountsSummary
		extends SummaryChart<ConductTypeCounts> {
		chart: 'GetConductTypeCounts';
		number: '0';
	}

	export interface ConductTypeCountsByMonth {
		mo: string;
		final_warning?: number;
		verbal_warning?: number;
		written_warning?: number;
	}

	export interface GetConductTypeCountsSummaryByMonth
		extends SummaryChart<ConductTypeCountsByMonth[]> {
		chart: 'GetConductTypeCountsByMonth';
		number: '1';
	}

	export interface SummaryReturnType {
		[Chart.GetConductTypeCounts]: ConductTypeCountsSummary;
		[Chart.GetConductTypeCountsByMonth]: GetConductTypeCountsSummaryByMonth;
	}
}
