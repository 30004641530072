import { TranslatableText, TranslatableTextPayload } from '@api-interfaces';
import { virtualBrownClient } from '@core/services/django-client';

// helpers
import { Params } from '@helpers';

// interfaces
import { PaginationParams, PaginationResponse } from '@models';

const BASE_URL = 'utils/translatable_texts';

class TranslatableTextsService {
	createTranslatableText(payload: TranslatableTextPayload) {
		return virtualBrownClient.post<TranslatableText>(`${BASE_URL}/`, payload);
	}

	createBulkTranslatableText(payload: Array<TranslatableTextPayload>) {
		return virtualBrownClient.post(`${BASE_URL}/bulk/`, payload);
	}

	getTranslatableTexts(
		params?: { unique_identifiers?: string } & PaginationParams
	) {
		return virtualBrownClient.get<PaginationResponse<TranslatableText>>(
			`${BASE_URL}/${Params.makeQueryString(params || {})}`
		);
	}
}

export const translatableTextsService = new TranslatableTextsService();
