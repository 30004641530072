// services

// helpers
import { Observable } from 'rxjs';

import {
	NotificationsSummary,
	NotificationsSummaryParams,
} from '@api-interfaces';
import { Params } from '@helpers';

// interfaces
import { virtualBrownClient } from '@services';

// constants
const BASE_URL = 'notifications/summary';
const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

class NotificationsSummaryService {
	getSummaries<T extends NotificationsSummary.Chart>(
		params: NotificationsSummaryParams<T>
	): Observable<NotificationsSummary.SummaryReturnType[T][]> {
		const query = {
			...params,
			chart: params.chart.join(','),
			time_zone: params.time_zone || timeZone,
		};
		return virtualBrownClient.get(
			`${BASE_URL}/${Params.makeQueryString(query)}`,
			{
				noCustomer: params.customer !== undefined,
				noContract: params.contract !== undefined,
				noTag: params.tag !== undefined,
			}
		);
	}
}

export const notificationsSummaryService = new NotificationsSummaryService();
