import { forwardRef, MutableRefObject } from 'react';

import { BoxProps } from './box.interfaces';
import { boxTypeMap } from './box.utils';

export const Box = forwardRef(
  (
    { type, className, rounded, ...props }: BoxProps,
    ref: MutableRefObject<HTMLDivElement>
  ) => {
    return (
      <div
        ref={ref}
        className={`${boxTypeMap[type]} ${
          rounded ? 'tw-rounded-lg' : ''
        } ${className}`}
        {...props}
      />
    );
  }
);

Box.defaultProps = {
  type: 'default',
  className: '',
};
