// interfaces
import { Params } from '@helpers';
import { PaginationResponse } from '@models';
import { virtualBrownClient } from '@services';

import {
	BehavioralAudit,
	BehavioralAuditPayload,
	BehavioralAuditEmployee,
	GetBehavioralAuditsParams,
	GetBehavioralAuditsEmployeesParams,
} from './interfaces';

// services
import { behavioralAuditsSummaryService } from './summary';

// helpers

// const
const BASE_URL = 'behavioralaudits';

class BehavioralAuditsService {
	readonly summary = behavioralAuditsSummaryService;

	getBehavioralAudits = (params: GetBehavioralAuditsParams) =>
		virtualBrownClient.get<PaginationResponse<BehavioralAudit>>(
			`${BASE_URL}/${Params.makeQueryString(params)}`
		);

	getBehavioralAuditById = (id: number) =>
		virtualBrownClient.get<BehavioralAudit>(`${BASE_URL}/${id}/`);

	createBehavioralAudit = (payload: BehavioralAuditPayload) =>
		virtualBrownClient.post<BehavioralAudit>(`${BASE_URL}/`, payload);

	updateBehavioralAuditById = (id: number, payload: BehavioralAuditPayload) =>
		virtualBrownClient.put<BehavioralAudit>(`${BASE_URL}/${id}/`, payload);

	patchBehavioralAuditById = (id: number, payload: BehavioralAuditPayload) =>
		virtualBrownClient.patch<BehavioralAudit>(`${BASE_URL}/${id}/`, payload);

	deleteBehavioralAuditById = (id: number) =>
		virtualBrownClient.delete<any>(`${BASE_URL}/${id}/`);

	getBehavioralAuditsEmployees = (params: GetBehavioralAuditsEmployeesParams) =>
		virtualBrownClient.get<PaginationResponse<BehavioralAuditEmployee>>(
			`${BASE_URL}/employees/${Params.makeQueryString(params)}`
		);
}

export const behavioralAuditsService = new BehavioralAuditsService();
