import { forwardRef } from 'react';

// components
import { Icon } from '@atoms';

// interfaces
import { InputTimeProps } from './input-time.interfaces';

import './input-time.css';

export const InputTime = forwardRef<HTMLInputElement, InputTimeProps>(
  ({ className, disabled, error, style, ...props }, ref) => {
    return (
      <div
        data-ds2="input-time"
        className={`tw-relative tw-h-10 tw-w-full tw-pl-2 tw-pr-4 tw-py-2 tw-rounded tw-ring-2 tw-ring-inset tw-flex tw-items-center tw-text-sm
        ${
          error
            ? `tw-ring-theme-danger-500-300 focus-within:tw-shadow-input-error ${
                disabled ? 'tw-ring-opacity-20' : ''
              }`
            : `focus-within:tw-ring-theme-primary-500-300 focus-within:tw-shadow-input-focus ${
                disabled
                  ? 'tw-ring-theme-neutral-300-700'
                  : 'tw-ring-theme-neutral-400-600'
              }`
        }

        ${
          disabled
            ? 'tw-bg-theme-neutral-200-800 tw-cursor-not-allowed tw-text-theme-neutral-500-600'
            : 'tw-bg-theme-neutral-100-800 tw-text-theme-neutral-900-100'
        }

        ${className}
    `}
        style={style}
      >
        <input
          type="time"
          ref={ref}
          className="tw-w-full tw-bg-transparent tw-outline-none tw-caret-neutral-600 dark:tw-caret-neutral-200 tw-mr-5 tw-peer"
          disabled={disabled}
          {...props}
        />
        <Icon
          role="presentation"
          icon="clock"
          className={`tw-text-neutral-500 peer-disabled:tw-text-neutral-400 dark:peer-disabled:tw-text-neutral-700 ${
            error
              ? 'peer-focus:tw-text-theme-danger-500-300'
              : 'peer-focus:tw-text-theme-primary-500-300'
          }`}
        />
      </div>
    );
  }
);

InputTime.displayName = 'InputTime';

InputTime.defaultProps = {
  className: '',
};
