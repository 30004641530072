import { useRef, useEffect } from 'react';
import * as React from 'react';

// components
import { Icon } from '@atoms';
import { FieldInputText } from '@atoms/form-ds2/field-input-text';

// utils
import { CreateProps } from '../../comment.utils';

import './CreateComment.css';
import { initialAttachmentsState } from '../../comment.utils';

export const CreateComment = ({
    defaultValue,
    submit,
    title,
    isEditBox,
    className,
    icon = 'paper-plane',
    allowAttachment,
    handleAddAttachment,
    attachmentsState,
    setAttachmentsState,
}: CreateProps) => {
    const textRef = useRef<HTMLTextAreaElement | null>(null);

    useEffect(() => {
        if (textRef.current) {
            textRef.current.style.paddingRight = '2.5rem';
        }
    }, []);

    const handleSubmit = () => {
        if (
            textRef.current?.value ||
            attachmentsState?.attachmentsQueue?.length
        ) {
            submit(textRef.current?.value);
            textRef.current.value = '';
            setAttachmentsState(initialAttachmentsState);
        }
    };

    const handleInput = () => {
        textRef.current.style.height = 'auto';
        textRef.current.style.height = `${textRef.current.scrollHeight + 4}px`;
        if (textRef?.current?.value === '') {
            textRef.current.style.height = '44px';
        }
    };

    const keyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.key !== undefined) {
            if (e.key === 'Enter' && e.shiftKey == false) {
                e.preventDefault();
                handleSubmit();
            }
        }
    };

    return (
        <form
            className={`tw-relative ${className}`}
            onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
            }}
        >
            <FieldInputText
                ref={textRef}
                id={title.replaceAll(' ', '-')}
                fieldType="textarea"
                label={title}
                onChange={handleInput}
                onKeyDown={keyDown}
                defaultValue={defaultValue}
                rows={1}
                className={`${allowAttachment && '!tw-pr-[80px]'}`}
            />
            {allowAttachment && (
                <>
                    <label
                        className={`tw-absolute tw-right-12 tw-h-5 tw-w-5 ${
                            isEditBox
                                ? 'comment-thread-edit-send-btn'
                                : 'comment-thread-send-btn'
                        }`}
                        role="button"
                        onChange={handleAddAttachment}
                    >
                        <input
                            type="file"
                            className="tw-hidden"
                            accept=".png,.jpg,.jpeg,.gif" // ",.pdf,.doc,.docx,.xls,.xlsx,.txt,.rtf"  // LATER WE WILL WANT TO ADD THE ABILITY TO ADD DOCS
                        />
                        <Icon
                            icon={'paperclip'}
                            className={` tw-text-xl  tw-text-neutral-500 hover:tw-text-theme-primary-700-100`}
                        />
                    </label>
                </>
            )}
            <button
                className={`tw-absolute tw-right-4 tw-h-5 tw-w-5 ${
                    isEditBox
                        ? 'comment-thread-edit-send-btn'
                        : 'comment-thread-send-btn'
                }`}
                type="button"
                onClick={handleSubmit}
            >
                <Icon
                    icon={icon}
                    className={`tw-text-theme-primary-500-300 ${
                        icon === 'paper-plane' ? 'tw-text-xl' : 'tw-text-2xl'
                    }`}
                />
            </button>
        </form>
    );
};
