import { useMemo } from 'react';

// component
import { Text } from '@atoms';

// interfaces
import { CharacterCounterProps } from './character-counter.interfaces';

export const CharacterCounter = ({
  count,
  max,
  background,
}: CharacterCounterProps) => {
  const progressCircle = useMemo(() => {
    const percentage = (count / max) * 100;
    const circle = {
      percentage,
      color: 'var(--theme-danger-700-500)',
    };
    switch (true) {
      case percentage <= 30:
        circle.color = 'var(--theme-success-500-300)';
        break;
      case percentage <= 50:
        circle.color = 'var(--theme-success-700-500)';
        break;
      case percentage <= 60:
        circle.color = 'var(--warning-300)';
        break;
      case percentage <= 75:
        circle.color = 'var(--warning-500)';
        break;
      case percentage <= 85:
        circle.color = 'var(--theme-danger-500-300)';
        break;
    }
    return circle;
  }, [count, max]);

  return (
    <div
      data-ds2="character-counter"
      className="tw-inline-flex tw-items-center tw-gap-1"
    >
      <Text font="body-sm" color={count > max ? 'danger' : 'neutral-offset'}>
        {count}/{max}
      </Text>
      <div
        role="progressbar"
        className="tw-h-4 tw-w-4 tw-rounded-full tw-grid tw-place-items-center"
        aria-valuenow={progressCircle.percentage}
        aria-valuemin={0}
        aria-valuemax={100}
        style={{
          background: `radial-gradient(closest-side, ${background} 80%, transparent 0 99.9%, ${background} 0),
            conic-gradient(${progressCircle.color} calc(${progressCircle.percentage} * 1%), var(--theme-neutral-400-600) 0)
            `,
        }}
      />
    </div>
  );
};

CharacterCounter.defaultProps = {
  background: 'var(--theme-neutral-200-800)',
};
