import { useEffect, useMemo, useState } from 'react';

// services
import { Observable, Subscription } from 'rxjs';

import { Service } from '@api-interfaces';
import { financialsService } from '@apis';
import { Dropdown } from '@atoms/form-ds2/dropdown';
import { titleCase } from '@helpers';
import { useAppContext } from '@hooks';
import { PaginationResponse } from '@models';
import { toasterService } from '@services';
import { useBillingPageContext } from '@app/collections/financials/billing/_context';

// interfaces

import { ProgramPickerProps, ServiceItem } from './PageHeader.interfaces';

// helpers

// components

const serviceToItem = (service: Service): ServiceItem => ({
    ...service,
    name: titleCase(service.name),
});

export const ProgramPicker = ({
    setProgram,
    className,
    type,
}: ProgramPickerProps) => {
    const {
        state: { parentPrograms, selectedParentProgram },
        dispatch,
    } = useAppContext();

    const { dispatch: billingDispatch } = useBillingPageContext();

    const [serviceOptions, setServiceOptions] = useState<ServiceItem[]>([]);
    const [selectedProgram, setSelectedProgram] = useState<Service>(null);
    const [loading, setLoading] = useState(false);

    const setParentProgram = (service: Service) => {
        if (!type || type === 'parent_programs') {
            dispatch({
                type: 'SET_PROGRAM',
                payload: service.id,
            });
        } else if (setProgram) {
            setProgram(service);
        }

        setSelectedProgram(service);
    };

    useEffect(() => {
        let selectedProgram: Service = {
            id: null,
            name: 'All Programs',
            correlation_id: '0',
            is_standard: false,
        };
        if (!type || type === 'parent_programs') {
            selectedProgram = {
                ...selectedParentProgram,
                name: titleCase(selectedParentProgram.name),
            };
        }
        setSelectedProgram(selectedProgram);
    }, []);

    useEffect(() => {
        let subscription: Subscription;
        let serviceOptions: Service[] = [
            {
                id: null,
                name: 'All Programs',
                correlation_id: '0',
                is_standard: false,
            },
        ];

        if (!type || type == 'parent_programs') {
            // ticket #120757 select the program and set it as default if there is only one program to the site
            if (parentPrograms.length == 1) {
                serviceOptions = parentPrograms;
                let programSelected = {
                    id: parentPrograms[0].id,
                    name:
                        parentPrograms[0].name.charAt(0) +
                        parentPrograms[0].name.substring(1).toLowerCase(),
                    correlation_id: parentPrograms[0].correlation_id,
                    is_standard: parentPrograms[0].is_standard,
                };
                setSelectedProgram(programSelected);
                serviceOptions = [...parentPrograms];
            } else {
                // concat services to an array where first item is "All Programs"
                setSelectedProgram(serviceOptions[0]);
                serviceOptions = [...serviceOptions, ...parentPrograms];
            }
            setServiceOptions(serviceOptions.map(serviceToItem));
        } else {
            const params = { limit: 1000 };
            let request: Observable<PaginationResponse<Service>>;
            switch (type) {
                case 'hours':
                    request =
                        financialsService.getFinancialHourServices(params);
                    break;
                case 'invoices':
                    request =
                        financialsService.getFinancialInvoiceServices(params);
                    break;
                case 'purchase_orders':
                    params.parent_services = true;
                    request =
                        financialsService.getFinancialPurchaseOrderServices(
                            params
                        );
                    break;
                default:
                    break;
            }
            setLoading(true);

            subscription = request.subscribe((res) => {
                setLoading(false);
                if (!res) {
                    toasterService.newToast({
                        status: 'fail',
                        message:
                            'There was an error retrieving the list of available services',
                    });
                    return;
                }

                // map services to key value pairs
                const services = res.results;
                // ticket #120757 select the program and set it as default if there is only one program to the site
                if (services.length == 1) {
                    let programSelected = {
                        id: services[0].id,
                        name:
                            services[0].name.charAt(0) +
                            services[0].name.substring(1).toLowerCase(),
                        correlation_id: services[0].correlation_id,
                        is_standard: services[0].is_standard,
                    };
                    setSelectedProgram(programSelected);
                    serviceOptions = [...services];
                } else {
                    // concat services to an array where first item is "All Programs"
                    setSelectedProgram(serviceOptions[0]);
                    serviceOptions = [...serviceOptions, ...services];
                }
                // concat services to an array where first item is "All Programs"
                setServiceOptions(serviceOptions.map(serviceToItem));
                billingDispatch({
                    type: 'SET_INVOICE_PROGRAM',
                    payload: selectedProgram,
                });
            });
        }

        return () => {
            if (subscription) {
                subscription.unsubscribe();
            }
        };
    }, [parentPrograms, type]);

    const items = useMemo(() => {
        return serviceOptions.map((service) => ({
            ...service,
            active: service.id === selectedProgram?.id,
        }));
    }, [serviceOptions, selectedProgram]);

    return (
        <div
            className={`tw-w-full md:tw-w-60 lg:tw-w-44 tw-shrink-0 ${className}`}
        >
            <Dropdown
                items={items}
                selectedItem={selectedProgram}
                onChange={(item: ServiceItem) => {
                    setParentProgram(item);
                }}
                disabled={loading}
            />
        </div>
    );
};
