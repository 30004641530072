import { Icon } from '@atoms';

import { IconBadgeProps } from './icon-badge.interfaces';
import { iconBadgeSize, iconBadgeType, iconSize } from './icon-badge.utils';

export const IconBadge = ({
  icon,
  size,
  type,
  className,
  ...props
}: IconBadgeProps) => {
  return (
    <div
      className={`tw-grid tw-place-items-center tw-bg-opacity-15 dark:tw-bg-opacity-15 tw-rounded-full tw-flex-shrink-0 ${iconBadgeSize[size]} ${iconBadgeType[type]} ${className}`}
      {...props}
    >
      <Icon icon={icon} size={iconSize[size]} />
    </div>
  );
};

IconBadge.defaultProps = {
  size: 'md',
  type: 'default',
  className: '',
};
