import { Text } from '@atoms';
import { useBreakpoints } from '@core/hooks';
import { ScoringThumbs } from '@molecules';

import { useGmpAuditsModalContext } from '../_context/context';

// interfaces
import { getBadgeType } from '../helpers';
import { GmpAuditFormAuditAnswer } from '../interfaces';

// components
import { GmpAuditBadgeType } from '.';

// helpers

interface Props {
  title: string;
  description: string;
  answer: GmpAuditFormAuditAnswer;
  onMarkChange: (e: boolean) => void;
  isReadOnly: boolean;
}

export const Question = ({
  title,
  description,
  answer,
  onMarkChange,
  isReadOnly,
}: Props) => {
  const {
    state: { mode, attemptSubmit, isSubmitting, data, isEditing },
  } = useGmpAuditsModalContext();
  const isReadMode = (mode === 'read' || isReadOnly) && !isEditing;
  const isProfessionalismAudit =
    data?.questionnaire_name === 'Professionalism_Audit';
  const isExempt = data?.is_exempt_from_scorecard;
  const isErrorMark = answer?.mark === null && attemptSubmit;
  const isValidAnswer = answer?.mark === true || answer?.mark === false;
  const isBpXs = useBreakpoints().xs;
  const isBpSm = useBreakpoints().sm;
  return (
    <div
      className="tw-grid tw-gap-x-24 xl:tw-gap-x-32 tw-items-center"
      style={
        isBpXs && !isBpSm
          ? { gridTemplateColumns: '2fr' }
          : isBpSm && {
              gridTemplateColumns: `1fr ${!isReadMode ? '80px' : '55px'}`,
            }
      }
    >
      <div>
        {!isProfessionalismAudit && (
          <Text
            font="body-md"
            color={isErrorMark ? 'danger' : 'hi-contrast'}
            className="tw-mt-2"
          >
            {description}
          </Text>
        )}
      </div>

      <div className={isBpXs && !isBpSm ? 'tw-mt-4' : 'tw-text-right'}>
        {!isReadMode ? (
          <ScoringThumbs
            name={title}
            disabled={isSubmitting}
            onChange={(e) => {
              const { value } = e.target;
              const mark = value === 'pass';
              onMarkChange(mark);
            }}
            checked={isValidAnswer ? (answer?.mark ? 'pass' : 'fail') : null}
          />
        ) : (
          <GmpAuditBadgeType type={getBadgeType(answer?.mark, isExempt)} />
        )}
      </div>
    </div>
  );
};
