import { LoaderBlocksProps } from './loader-blocks.interfaces';

import {
  loaderSizeMap,
  loaderTypeMap,
  loaderPositionMap,
} from './loader-blocks.utils';

// interfaces
import './loader-blocks.css';

export const LoaderBlocks = ({
  size,
  type,
  position,
  className,
  ...props
}: LoaderBlocksProps) => {
  return (
    <div
      data-ds2="loader-blocks"
      className={`${loaderPositionMap[position]} ${className}`}
      {...props}
    >
      <div
        className={`loader-blocks-${size} tw-relative tw-inline-block ${loaderSizeMap[size]}`}
      >
        {Array(3)
          .fill(null)
          .map((_, i) => (
            <div
              key={i}
              className={`tw-absolute tw-inline-block ${loaderTypeMap[type]}`}
            />
          ))}
      </div>
    </div>
  );
};

LoaderBlocks.defaultProps = {
  size: 'md',
  type: 'default',
  position: 'center',
  className: '',
};
