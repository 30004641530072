import { Avatar, ScoreChip } from '@atoms';
import { Text } from '@atoms';
import { getFullName, truncate } from '@helpers';

// interfaces

// helpers
import { highlightText } from '@helpers';

import { MiniProfileMdProps } from './mini-profile.interface';

export const MiniProfileMd = ({
    person,
    detail,
    isInitials,
    className,
    scoreChip,
    textToHighlight,
    viewName = false,
    internalClassName,
    ...props
}: MiniProfileMdProps) => {
    return (
        <div
            className={`tw-flex tw-items-center tw-space-x-4 tw-max-w-[190px] ${className}`}
            {...props}
        >
            <div className="tw-relative tw-flex-shrink-0">
                <Avatar size="md" person={person} isInitials={isInitials} />
                {scoreChip != null && (
                    <div className="tw-absolute tw--bottom-1 tw-w-full tw-text-center tw-scale-90 tw--left-0.5">
                        <ScoreChip
                            score={scoreChip.score}
                            type={scoreChip.type}
                            size="xs"
                            className="tw-inline-flex"
                            prettyNumberOptions={{
                                decimals: 2,
                                hasTrailingZeros: true,
                                forceTrailingZeros: true,
                                isPercent: false,
                            }}
                        />
                    </div>
                )}
            </div>
            <div
                className={`tw-max-w-[138px] tw-whitespace-nowrap tw-overflow-hidden ${internalClassName}`}
            >
                <Text
                    font="h4"
                    color="hi-contrast"
                    className="tw-capitalize tw-truncate"
                >
                    {highlightText({
                        text: !viewName ? getFullName(person) : person?.name,
                        match: textToHighlight,
                    })}
                </Text>
                {typeof detail === 'string' ? (
                    <Text
                        font="body-sm"
                        color="neutral-offset"
                        className="tw-truncate"
                    >
                        {detail}
                    </Text>
                ) : (
                    detail
                )}
            </div>
        </div>
    );
};

MiniProfileMd.defaultProps = {
    className: '',
    textToHighlight: '',
};
