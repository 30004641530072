import { Observable } from 'rxjs';

// interfaces
import { map } from 'rxjs/operators';

import {
	Contract,
	ContractGoallines,
	ContractServiceArea,
	ServiceAreaShift,
	ServiceAreaPosition,
	ItemWithFrequency,
	ContractChart,
	ContractScopeCountSummary,
	ContractCoverageCountSummary,
	ContractRouteCountSummary,
	DemandManagerCountSummary,
	ContractCoverageFilterCountSummary,
	CoverageExpanded,
	ServiceAreaExpandedResponse,
	ContractFTE,
	ContractAdminBillingCountSummary,
} from '@api-interfaces';
import { virtualBrownClient } from '@core/services/django-client';
import { Params } from '@helpers';
import { PaginationParams, PaginationResponse } from '@models';

// services

import {
	ContractDetailsSummary,
	ContractMediaItem,
	ServiceAreaWithSensorIdResponse,
} from './interfaces';
import { contractKpisService } from './kpis';
import { contractRolesService } from './roles';
import { serviceLinksService } from './service-links';
import { serviceValidationService } from './service-validation';
import { contractServicesService } from './services';
import { v2ContractService } from './v2';

// helpers

// constants
const BASE_URL = 'contracts';

let noOpts = {
	noCustomer: true,
	noContract: true,
	noTag: true,
	noProvider: false,
};

class ContractsService {
	readonly sv = serviceValidationService;

	readonly kpis = contractKpisService;

	readonly roles = contractRolesService;

	readonly v2 = v2ContractService;

	readonly services = contractServicesService;

	readonly servicelinks = serviceLinksService;

	/**
	 * Returns organization's contract(s)
	 * correlation_id could either be a Service Provider's or Customer's id
	 * correlation_id should equal to 'SiteId' from old angular site
	 */
	public getContractsByCorrelationId(
		params: {
			correlation_id: number;
		} & PaginationParams
	) {
		const queryString = Params.makeQueryString(params);
		return virtualBrownClient.get<PaginationResponse<Contract>>(
			`${BASE_URL}/${queryString}`
		);
	}

	/**
	 * Returns organization's contract(s)
	 * customer_correlation_id should be a Customer's id
	 * customer_correlation_id should equal 'ClientId' from old angular site
	 */
	public getContractsByCustomerCorrelationId(
		params: {
			customer_correlation_id: number;
		} & PaginationParams
	) {
		const queryString = Params.makeQueryString(params);

		return virtualBrownClient.get<PaginationResponse<Contract>>(
			`${BASE_URL}/${queryString}`
		);
	}

	public getShiftsByServiceAreaId = (
		ids: { contractId: string | number; serviceAreaId: string | number },
		params?: PaginationParams
	): Observable<PaginationResponse<ServiceAreaShift>> => {
		const queryString = Params.makeQueryString(params || {});
		return virtualBrownClient.get(
			`${BASE_URL}/${ids.contractId}/service_areas/${ids.serviceAreaId}/shifts/${queryString}`
		);
	};

	public getPositionTypesByShiftId = (
		ids: {
			contractId: number | string;
			serviceAreaId: number | string;
			shiftId: number | string;
		},
		params?: PaginationParams
	): Observable<PaginationResponse<ServiceAreaPosition>> => {
		const queryString = Params.makeQueryString(params || {});
		return virtualBrownClient.get(
			`${BASE_URL}/${ids.contractId}/service_areas/${ids.serviceAreaId}/shifts/${ids.shiftId}/positions/${queryString}`
		);
	};

	public getTaskBundlesByAreaTemplateId = (
		params: {
			contractId: number | string;
			serviceAreaId: number;
			areaTemplateId: number;
			calc?: 'taskcount' | 'area_taskcount';
			sum?: 'hours';
			area?: string | number;
		} & PaginationParams
	): Observable<PaginationResponse<ItemWithFrequency>> => {
		const queryString = {
			...params,
		};
		delete queryString.contractId;
		delete queryString.serviceAreaId;
		delete queryString.areaTemplateId;

		return virtualBrownClient.get(
			`${BASE_URL}/${params.contractId}/service_areas/${
				params.serviceAreaId
			}/area_templates/${
				params.areaTemplateId
			}/taskbundles/${Params.makeQueryString(queryString)}`
		);
	};

	public getServiceAreasByContractId = (
		contractId,
		params?: {
			boundary?: number;
			areatemplate?: number;
			calc?: 'taskcount' | 'area_taskcount' | 'num_ftes';
			sum?: 'hours';
			ordering?: 'name' | '-name';
		} & PaginationParams
	): Observable<PaginationResponse<ContractServiceArea>> => {
		const queryString = Params.makeQueryString(params || {});
		return virtualBrownClient.get(
			`${BASE_URL}/${contractId}/service_areas/${queryString}`,
			noOpts
		);
	};

	public getServiceAreasExpandedByContractId = (
		contractId,
		params?: {
			calc?: 'taskcount';
			servicearea?: string;
			areatemplate?: string;
			position?: string;
			task?: string;
			search_sow?: string;
			child_servicearea?: string;
			frequency?: string;
			program?: string;
		} & PaginationParams
	): Observable<ServiceAreaExpandedResponse> =>
		virtualBrownClient.get(
			`${BASE_URL}/${contractId}/service_areas/expanded/${Params.makeQueryString(
				params || {}
			)}`,
			noOpts
		);

	// {{API:REDO}}: Temporary solution
	// Backend is passing in dupe tasks names with unique ids, so we are filting only distinct task names per area per area_type.
	// the lower task id will take precedence (because it was created first, the later is most likely dupe).
	public getCoverageExpandedByContractId = (
		contractId: number | string,
		params?: {
			servicearea?: string;
			areatemplate?: string;
			position?: string;
			task?: string;
			search_sow?: string;
			child_servicearea?: string;
			frequency?: string;
			program?: string;
		} & PaginationParams
	): Observable<CoverageExpanded> =>
		virtualBrownClient.get(
			`${BASE_URL}/${contractId}/coverage/expanded/${Params.makeQueryString(
				params || {}
			)}`,
			noOpts
		);

	public getServiceAreasWithSensorsByContractId = (
		contractId: number | string,
		params?: {
			sensor_types?: string[];
		}
	): Observable<ServiceAreaWithSensorIdResponse> =>
		virtualBrownClient.get(
			`${BASE_URL}/${contractId}/areas/sensors/${Params.makeQueryString(
				params || {}
			)}`,
			noOpts
		);

	public getAreaTemplatesByServiceAreaId = (
		contractId: number,
		serviceAreaId: number,
		params?: {
			calc?: 'taskcount' | 'area_taskcount';
		} & PaginationParams
	): Observable<PaginationResponse<ItemWithFrequency>> => {
		const { limit = 10, offset = 0 } = params;
		const query = {
			limit,
			offset,
			...params,
		};
		const queryString = Params.makeQueryString(query);
		return virtualBrownClient.get(
			`${BASE_URL}/${contractId}/service_areas/${serviceAreaId}/area_templates/${queryString}`
		);
	};

	public getMultipleContractGoalLinesList(params: { contracts: string }) {
		const query = {
			contracts: params.contracts,
		};
		const queryString = Params.makeQueryString(query);
		return virtualBrownClient
			.get<ContractGoallines>(
				`${BASE_URL}/goallineslist/${queryString}`,
				{
					noContract: true,
					noCustomer: true,
					noTag: true,
				}
			)
			.pipe(
				// this endpoint has been updated after FE consumption,
				// so just giving back the original single object instead of list
				map((paginationResults: any) => {
					if (
						paginationResults &&
						paginationResults.results &&
						paginationResults.results.length
					) {
						return paginationResults
							.results[0] as ContractGoallines;
					}
					return null;
				})
			);
	}

	public getContractGoallines(params: { id: number }) {
		return virtualBrownClient
			.get<ContractGoallines>(`${BASE_URL}/${params.id}/goallines/`, {
				noContract: true,
				noCustomer: true,
				noTag: true,
			})
			.pipe(
				// this endpoint has been updated after FE consumption,
				// so just giving back the original single object instead of list
				map((paginationResults: any) => {
					if (
						paginationResults &&
						paginationResults.results &&
						paginationResults.results.length
					) {
						return paginationResults
							.results[0] as ContractGoallines;
					}
					return null;
				})
			);
	}

	public getContractGoallineList() {
		return virtualBrownClient.get<PaginationResponse<ContractGoallines>>(
			`${BASE_URL}/goallines/?limit=100`,
			{
				noTag: true,
			}
		);
	}

	public createContractGoallines(body: Partial<ContractGoallines>) {
		return virtualBrownClient.post<ContractGoallines>(
			`${BASE_URL}/goallines/`,
			body
		);
	}

	public updateContractGoallines(body: Partial<ContractGoallines>) {
		return virtualBrownClient.put<ContractGoallines>(
			`${BASE_URL}/goallines/${body.id}/`,
			body
		);
	}

	// AKA SITES
	getContracts = (
		params: {
			ordering?: string;
			search?: string;
			contract?: number | string;
			customer?: number | string;
			provider?: number;
			organization?: number;
			detail_level?: 'simple' | 'customer_logo';
			is_active?: boolean;
			use_ifm?: boolean;
			portfolio?: number;
			portfolios?: string | number;
		} & PaginationParams,
		extranoOptions = {}
	): Observable<PaginationResponse<Contract>> => {
		const queryString = Params.makeQueryString(params);
		return virtualBrownClient.get(`${BASE_URL}/${queryString}`, {
			...noOpts,
			...extranoOptions,
		});
	};

	getContractById = (contractId: number) =>
		virtualBrownClient.get<Contract>(`${BASE_URL}/${contractId}/`, noOpts);

	getContractsBuilding = (
		params: {
			old_contract_id?: number;
			new_contract_id?: number;
			new_building_id?: number;
		} & PaginationParams
	): Observable<PaginationResponse<Contract>> => {
		const queryString = Params.makeQueryString(params);
		return virtualBrownClient.get(
			`${BASE_URL}/movebuilding/${queryString}`,
			noOpts
		);
	};

	createContract = (body) =>
		virtualBrownClient.post<Contract>(`${BASE_URL}/`, body);

	updateContract = (body) =>
		virtualBrownClient.put<Contract>(`${BASE_URL}/${body.id}/`, body);

	getContractSummaryCounts = (params: {
		chart: ContractChart.GET_CONTRACT_ADMIN_BILLING_COUNT;
	}): Observable<ContractAdminBillingCountSummary[]> =>
		virtualBrownClient.get(
			`${BASE_URL}/summary/${Params.makeQueryString(params)}`,
			noOpts
		);

	public getContractScopeCountSummary = (params: {
		chart: ContractChart.GET_CONTRACT_SCOPE_COUNT;
		contract: number;
	}): Observable<ContractScopeCountSummary[]> =>
		virtualBrownClient.get(
			`${BASE_URL}/summary/${Params.makeQueryString(params)}`,
			noOpts
		);

	getContractCoverageCountSummary = (params: {
		chart: ContractChart.GET_CONTRACT_COVERAGE_COUNT;
		contract?: number;
	}): Observable<ContractCoverageCountSummary[]> =>
		virtualBrownClient.get(
			`${BASE_URL}/summary/${Params.makeQueryString(params)}`
		);

	getContractRouteCountSummary = (params: {
		chart: ContractChart.GET_CONTRACT_ROUTE_COUNT;
		contract?: number;
	}): Observable<ContractRouteCountSummary[]> =>
		virtualBrownClient.get(
			`${BASE_URL}/summary/${Params.makeQueryString(params)}`
		);

	getDemandManagerCountSummary = (params: {
		chart: ContractChart.GET_DEMAND_MANAGER_COUNT;
		contract: number;
	}): Observable<DemandManagerCountSummary[]> =>
		virtualBrownClient.get(
			`${BASE_URL}/summary/${Params.makeQueryString(params)}`,
			noOpts
		);

	getContractSummary = (
		params: {
			chart: string | ContractChart;
			contract?: number;
			servicearea?: string;
			areatemplate?: string;
			position?: string;
			task?: string;
			search_sow?: string;
			child_servicearea?: string;
			frequency?: string;
			program?: string;
		} & PaginationParams
	): Observable<
		Array<
			| ContractScopeCountSummary
			| ContractCoverageCountSummary
			| DemandManagerCountSummary
			| ContractCoverageFilterCountSummary
		>
	> =>
		virtualBrownClient.get(
			`${BASE_URL}/summary/${Params.makeQueryString(params)}`
		);

	getContractMediaList = (
		contractId: number
	): Observable<PaginationResponse<ContractMediaItem>> =>
		virtualBrownClient.get(`${BASE_URL}/${contractId}/media/`, noOpts);

	createContractMediaItem = (
		contractId: number,
		payload: {
			media: Array<{ id: number }>;
		}
	): Observable<any> =>
		virtualBrownClient.post(
			`${BASE_URL}/${contractId}/media/`,
			payload,
			noOpts
		);

	getContractFTE = (
		params: { contract: number; ordering?: string } & PaginationParams
	): Observable<PaginationResponse<ContractFTE>> =>
		virtualBrownClient.get(
			`${BASE_URL}/ftes/${Params.makeQueryString(params)}`,
			noOpts
		);

	updateContractFTE = (
		fteId: number,
		payload: {
			id: number;
			num_ftes: number;
		}
	): Observable<ContractFTE> =>
		virtualBrownClient.put(`${BASE_URL}/ftes/${fteId}/`, payload, noOpts);

	updateBulkContractFTE = (
		payload: Array<{ id: number; num_ftes: number }>
	): Observable<any> =>
		virtualBrownClient.post(`${BASE_URL}/ftes/`, payload, noOpts);

	updateContractBulkAction(params?: {
		contract?: number;
		action?: 'Unsuspended' | 'Suspended' | 'Deactivated';
	}) {
		return virtualBrownClient.put<string>(
			`${BASE_URL}/bulk_actions/${Params.makeQueryString(params || {})}`
		);
	}

	public getContractDetailsSummary(params: {
		contract: number | string;
	}): Observable<ContractDetailsSummary> {
		const queryString = Params.makeQueryString(params);
		return virtualBrownClient.get(
			`${BASE_URL}/details-summary/${queryString}`
		);
	}
}

export const contractsService = new ContractsService();
