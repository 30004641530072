import { useEffect, useState } from 'react';

// context

// services

// interfaces
import { ServiceLogItem } from '@api-interfaces';
import { v2AreasService } from '@apis/areas/v2';
import { useAppContext } from '@hooks';

// components
import { ServiceLog } from '@new';
import { ShadowScroller } from '@new/ShadowScroller';
import { toasterService } from '@services';

interface RouteModalServiceLogProps {
  areaId: number;
  routeId: number;
}

const LOG_LIMIT = 10;

export const RouteModalServiceLog = ({
  areaId,
  routeId,
}: RouteModalServiceLogProps) => {
  const {
    state: { dateRange },
  } = useAppContext();
  const [logs, setLogs] = useState<ServiceLogItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    setIsLoading(true);
    const subscription = v2AreasService
      .getServiceLog({
        all_levels: true,
        area_id: areaId,
        offset,
        limit: LOG_LIMIT,
        from: dateRange.from,
        to: dateRange.to,
        ordering: '-create_date',
        route_id: routeId,
      })
      .subscribe(
        (serviceLog) => {
          setLogs((log) => [...log, ...serviceLog.results]);
          setIsLoading(false);
          if (!serviceLog.next) {
            setIsDisabled(true);
          }
        },
        () => {
          toasterService.newToast({
            status: 'fail',
            message: 'Failed to load service log.',
          });
          setIsLoading(false);
        }
      );

    return () => subscription.unsubscribe();
  }, [areaId, routeId, offset]);

  return (
    <ShadowScroller
      axis="y"
      className="tw-border tw-border-solid tw-border-theme-neutral-900-100 tw-border-opacity-15"
      maxHeight="60vh"
    >
      <ServiceLog
        isLoading={isLoading}
        isDisabled={isDisabled}
        logs={logs}
        onLoadClick={() => setOffset((offset) => offset + LOG_LIMIT)}
        showTasks
        className="tw-w-[900px] tw-pb-2"
        altTimeBackground
      />
    </ShadowScroller>
  );
};
