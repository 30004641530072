import { useState, useRef, useEffect } from 'react';

import { referenceService } from '@apis';
import { Label } from '@atoms/form-ds2/label';
import { Icon } from '@atoms/icon';
import { sendError } from '@core/helpers';
import { Item as I_Item } from '@new/_form/dropdowns/dropdown.interface';

type PriorityItem = I_Item & { record: JSX.Element };
interface Props {
  title?: string;
  disabled?: boolean;
  className?: string;
  'data-test'?: string;
  onClick: (priority: PriorityItem) => void;
  valueId?: number;
  defaultPriority?: priorityType;
}

type priorityType = 'Low' | 'Normal' | 'Critical';
interface ItemProps {
  icon: string;
  text: string;
  className?: string;
}

const Item = (props: ItemProps) => (
  <div className="tw-flex tw-flex-1 tw-items-center">
    <div
      className={`tw-flex tw-justify-center tw-text-base tw-w-4 tw-mr-1 tw-relative tw-top-[1px] ${props.className}`}
    >
      {props.icon}
    </div>
    <span className="tw-text-sm tw-text-neutral-900-100 tw-leading-[22px] tw-tracking-normal">
      {props.text}
    </span>
  </div>
);

export const priorityMap: { [key in priorityType]: JSX.Element } = {
  Low: <Item className="tw-text-primary-500" icon="!" text="Low" />,
  Normal: <Item className="tw-text-warning-500" icon="!!" text="Normal" />,
  Critical: <Item className="tw-text-danger-500" icon="!!!" text="Critical" />,
};

export const Priority = ({
  disabled,
  className,
  onClick,
  title,
  ...props
}: Props) => {
  const containerRef = useRef(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [priorities, setPriorities] = useState<PriorityItem[]>([]);

  useEffect(() => {
    const subscription = referenceService
      .getItems({ group_code: 'workorder_priority' })
      .subscribe({
        next: (res) => {
          res.sort((a, b) => a.alt_sequence - b.alt_sequence);

          const priorityOptions = res.map((option) => {
            const priorityRtn = {
              ...option,
              key: option.id,
              value: option.code,
              active: props?.valueId
                ? props?.valueId === option.id
                : props?.defaultPriority === option.code,
              record: priorityMap[option.code],
            };

            if (priorityRtn.active) {
              onClick(priorityRtn);
            }

            return priorityRtn;
          });
          setPriorities(priorityOptions);
        },
        error: sendError(),
      });
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    function handleRefClick(e: MouseEvent) {
      if (containerRef.current && !containerRef.current.contains(e.target)) {
        setIsDropdownOpen(false);
      }
    }
    document.addEventListener('mousedown', handleRefClick);

    return () => {
      document.removeEventListener('mousedown', handleRefClick);
    };
  }, []);

  function handlePriorityChange(selectedItem: PriorityItem) {
    return () => {
      const prioritiesCopy = priorities.map((p) => ({
        ...p,
        active: selectedItem.key === p.key,
      }));
      setPriorities(prioritiesCopy);

      onClick(selectedItem);
      setIsDropdownOpen(false);
    };
  }

  const selected = priorities.find((priority) => priority.active);

  return (
    <div
      className={`tw-flex tw-flex-col ${className}`}
      ref={containerRef}
      onClick={() => {
        setIsDropdownOpen(!isDropdownOpen);
      }}
    >
      {title && <Label label={title} />}
      <div
        tabIndex={0}
        data-disabled={disabled}
        className={`form-common-div tw-bg-neutral-100-800 tw-flex tw-relative tw-h-10 tw-shadow-border-input focus:tw-shadow-border-input-focus
                tw-outline-none tw-rounded ${className}`}
      >
        <div
          className={`form-common-div tw-flex tw-items-center tw-bg-transparent tw-w-full tw-p-1 tw-pr-[14px]
                    tw-cursor-pointer tw-pl-[12px] tw-rounded`}
        >
          {selected ? selected.record : <div className="tw-flex-1" />}
          <Icon
            className={`form-common-caret ${
              disabled
                ? 'tw-text-neutral-400 dark:tw-text-neutral-700'
                : 'tw-text-neutral-500'
            }`}
            icon="caret-down"
          />
        </div>
        {isDropdownOpen && (
          <div className="tw-absolute tw-w-full tw-z-10 tw-top-[44px] tw-rounded">
            <ul className="tw-max-h-[288px] tw-rounded tw-overflow-hidden tw-cursor-pointer tw-bg-neutral-100 dark:tw-bg-neutral-800 tw-shadow-dropdown">
              {priorities?.map((item) => (
                <li
                  key={item.key}
                  onClick={handlePriorityChange({
                    ...item,
                    record: priorityMap[item.value],
                  })}
                  className="tw-flex tw-items-center tw-text-sm tw-leading-[22px] tw-tracking-normal tw-min-h-[40px] tw-py-2 tw-pl-3 hover:tw-bg-neutral-200 dark:hover:tw-bg-neutral-700"
                >
                  {priorityMap[item.value]}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

Priority.defaultProps = {
  disabled: false,
  className: '',
};
