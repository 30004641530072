import { useState, useEffect, useRef } from 'react';
import * as React from 'react';

// services

// helpers
import { Subscription } from 'rxjs';

// components

// interfaces
import { Conduct, Employee, ReferenceItem } from '@api-interfaces';
import { conductsService, mixpanelService, referenceService } from '@apis';
import { DropdownItem } from '@atoms/form-ds2/dropdown/dropdown.interfaces';
import { FieldDropdown } from '@atoms/form-ds2/field-dropdown';
import { FieldInputDate } from '@atoms/form-ds2/field-input-date';
import MiniProfile from '@components/MiniProfile/MiniProfile';
import { getFullName, sendError, TimeUtils } from '@helpers';
import { useAppContext } from '@hooks';
import { Button } from '@new';
import {
    toasterService,
    imageService,
    modalService,
    refetchService,
} from '@services';
import { mixpanelCreatePersonnelConductType } from '@app/mixpanel/MixpanelPageTrack.tsx';

interface Props {
    selectedEmployee: Employee;
    conduct?: Conduct;
    id?: number;
}

export const ConductForm = ({
    selectedEmployee,
    conduct: conductProp,
    id,
}: Props) => {
    const {
        state: { user, selectedContracts },
    } = useAppContext();
    const [conduct, setConduct] = useState<Conduct>(conductProp);
    const [selectedConductType, setSelectedConductType] = useState<
        (DropdownItem & ReferenceItem) | null
    >(
        conductProp?.warning_type
            ? {
                  ...conductProp.warning_type,
                  name: conductProp.warning_type.display_name,
              }
            : null
    );
    const [date, setDate] = useState(
        conductProp ? new Date(conductProp.occurrence_date) : new Date()
    );
    const [conductTypes, setConductTypes] = useState<ReferenceItem[]>([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isConductTypesLoading, setIsConductTypesLoading] = useState(true);
    const subscription = useRef(new Subscription());

    useEffect(() => {
        const subscription = referenceService
            .getItems({ group_code: 'Conduct_Warning_Types' })
            .subscribe({
                next: (res) => {
                    if (res?.length) {
                        setConductTypes(res);
                        if (!conductProp && !id) {
                            setSelectedConductType(
                                res?.[0]
                                    ? { ...res[0], name: res[0].display_name }
                                    : null
                            );
                        }
                    }
                    setIsConductTypesLoading(false);
                },
                error: sendError({
                    callback: () => setIsConductTypesLoading(false),
                }),
            });
        return () => subscription.unsubscribe();
    }, []);

    useEffect(() => {
        let subscription = new Subscription();
        if (Number.isInteger(id)) {
            subscription = conductsService.getConductById(id).subscribe({
                next: (conduct) => {
                    setConduct(conduct);
                    setSelectedConductType(
                        conduct.warning_type
                            ? {
                                  ...conduct.warning_type,
                                  name: conduct.warning_type.display_name,
                              }
                            : null
                    );
                    setDate(new Date(conduct.occurrence_date));
                },
                error: sendError(),
            });
        }
        return () => subscription.unsubscribe();
    }, [conductProp, id]);

    function handleSubmit(e: React.MouseEvent<HTMLButtonElement>) {
        e.preventDefault();
        setIsSubmitting(true);

        if (conduct) {
            subscription.current = conductsService
                .updateConduct(conduct.id, {
                    ...conduct,
                    violator: { id: selectedEmployee.id },
                    warning_type: { id: selectedConductType.id },
                    occurrence_date: date.toISOString(),
                })
                .subscribe(handleResponse('Edit'));
        } else {
            const payload = {
                contract: {
                    id: selectedContracts?.[0]?.id,
                },
                creator: {
                    id: user.employeeId,
                },
                violator: {
                    id: selectedEmployee.id,
                },
                warning_type: {
                    id: selectedConductType.id,
                },
                occurrence_date: date,
            };

            subscription.current = conductsService
                .createConduct(payload)
                .subscribe(handleResponse('Create'));
        }
    }

    function handleResponse(type: string) {
        return {
            next: () => {
                mixpanelCreatePersonnelConductType(type);
                toasterService.newToast({
                    status: 'success',
                    message: 'Success!',
                });
                refetchService.fetch('conducts');
                modalService.closeAll();
            },
            error: sendError({
                callback: () => setIsSubmitting(false),
            }),
        };
    }

    return (
        <form className="tw-space-y-6 tw-p-4 sm:tw-p-6">
            <h1>New Conduct Entry</h1>

            <MiniProfile
                data={{
                    imgUrl: imageService.getImageByUniqueId(
                        selectedEmployee.person.photo_url
                    ),
                    employeeName: getFullName(selectedEmployee.person),
                    siteName: selectedContracts?.[0]?.name ?? '',
                    hiredDate: selectedEmployee.hired_date
                        ? new Date(selectedEmployee.hired_date)
                        : null,
                    employee: selectedEmployee,
                }}
                settings={{
                    bg: true,
                    size: 'sm',
                }}
            />

            <div className="tw-flex tw-flex-col tw-space-y-4 sm:tw-grid sm:tw-grid-cols-2 sm:tw-gap-4 sm:tw-space-y-0">
                <FieldInputDate
                    required
                    disableFuture
                    id="date_of_occurrence"
                    label="Date of Occurrence"
                    value={TimeUtils.format(date, 'MM/DD/YYYY')}
                    onChange={(date) => {
                        setDate(date);
                    }}
                />

                <FieldDropdown
                    id="conduct-types"
                    label="Type"
                    items={conductTypes.map((type) => ({
                        ...type,
                        name: type.display_name,
                    }))}
                    selectedItem={selectedConductType}
                    onChange={(selectedConductType) => {
                        setSelectedConductType(selectedConductType);
                    }}
                    disabled={isConductTypesLoading}
                />
            </div>

            <div className="tw-flex tw-justify-end tw-space-x-4">
                <Button
                    label="Cancel"
                    onClick={modalService.closeAll}
                    color="secondary"
                />
                <Button
                    label="Submit"
                    onClick={handleSubmit}
                    disabled={isSubmitting}
                />
            </div>
        </form>
    );
};
