import { useContext, Dispatch } from 'react';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

import { AreaTag } from '@api-interfaces';
import { areaTagService, contractsService } from '@apis';
import { initialAppState, appContext } from '@core/app-context';
import { App } from '@core/app-context/app.interfaces';
import {
    createWrappingCtx,
    hasSameElements,
    WrappingState,
} from '@core/helpers';
import {
    R4OrganizationGroup,
    R4OrganizationGroupType,
    R4Portfolio,
    R4PortfolioAreaTag,
    R4PortfolioAreaTagGroup,
    R4PortfolioContract,
    R4PortfolioCustomerContractGroup,
    R4PortfolioCustomerContractGroupType,
    R4PortfolioCustomerOrganization,
    R4Preset,
} from '@core/models';
// import { toasterService } from '@core/services';

import { formatAreaTagToContext } from './PickerContext';

type AreaTagMap = { [areaTagGroupId: number]: R4PortfolioAreaTag[] };
type SiteGroupMap = {
    [groupTypeId: number]: R4PortfolioCustomerContractGroup[];
};
type OrgGroupMap = { [groupTypeId: number]: R4OrganizationGroup[] };

export interface StoredPickerState extends App.State {
    pickerOptionOrgGroups: R4OrganizationGroupType[];
    pickerOptionCustomers: R4PortfolioCustomerOrganization[];
    pickerOptionContracts: R4PortfolioContract[];
    pickerOptionAreaTagGroups: R4PortfolioAreaTagGroup[];
    pickerOptionAreaTags: AreaTagMap;
    pickerOptionSiteGroupTypes: R4PortfolioCustomerContractGroupType[];

    pickerSelectedOrgGroups: OrgGroupMap;
    pickerSelectedCustomers: R4PortfolioCustomerOrganization[];
    pickerSelectedContracts: R4PortfolioContract[];
    pickerSelectedAreaTags: AreaTagMap;
    pickerSelectedSiteGroups: SiteGroupMap;
    pickerSelectedPreset: R4Preset;
}

interface StoredPickerStateInternal
    extends StoredPickerState,
        WrappingState<PickerAction> {}

// format contracts to be consistent with portfolio format
const formatContractToContext = (contract: any) => ({
    id: contract?.id ?? null,
    name: contract?.name ?? null,
    customer_name: contract?.customer?.name ?? null,
    customer: contract?.customer?.id ?? null,
    service_provider: contract?.service_provider?.id ?? null,
    has_SOW: contract?.has_SOW ?? null,
    local_timezone: contract?.local_timezone?.display_name ?? null,
    chatbot_is_active: contract?.chatbot_is_active ?? false,
    use_area_links: contract?.use_area_links ?? false,
});

class StoredInfo {
    private static NAME_SORTER = (a: { name: string }, b: { name: string }) =>
        a.name.localeCompare(b.name);

    private portfolios: R4Portfolio[];

    private organizationGroupTypes: R4OrganizationGroupType[];

    // Only customers is sorted by name
    private customers: Array<R4PortfolioCustomerOrganization>;

    private customerToContracts: Map<number, R4PortfolioContract[]>;

    private contractToAreaTagGroups: Map<number, R4PortfolioAreaTagGroup[]>;

    private areaTagGroupToAreaTags: Map<number, R4PortfolioAreaTag[]>;

    private customerSiteGroupTypes: Map<
        number,
        R4PortfolioCustomerContractGroupType[]
    >;

    public isSet() {
        return !!this.portfolios;
    }

    public resetStoredInfo(
        portfolios: R4Portfolio[],
        orgGroupTypes: R4OrganizationGroupType[]
    ) {
        this.portfolios = portfolios;
        this.customerToContracts = new Map<number, R4PortfolioContract[]>();
        this.contractToAreaTagGroups = new Map<
            number,
            R4PortfolioAreaTagGroup[]
        >();
        this.areaTagGroupToAreaTags = new Map<number, R4PortfolioAreaTag[]>();
        this.customerSiteGroupTypes = new Map<
            number,
            R4PortfolioCustomerContractGroupType[]
        >();

        // Set stored customers
        const customerToCustomer = new Map<
            number,
            R4PortfolioCustomerOrganization
        >();
        portfolios
            .flatMap(
                (portfolio: R4Portfolio) => portfolio.customer_organizations
            )
            .forEach((customer: R4PortfolioCustomerOrganization) =>
                customerToCustomer.set(customer.id, customer)
            );
        this.customers = [...customerToCustomer.values()].sort(
            StoredInfo.NAME_SORTER
        );

        // Set stored area tag groups
        portfolios
            .flatMap((portfolio: R4Portfolio) => portfolio.area_tag_groups)
            .forEach((areaTagGroup: R4PortfolioAreaTagGroup) => {
                areaTagGroup.contracts.forEach((contract: number) => {
                    const areaTagGroups =
                        this.contractToAreaTagGroups.get(contract) || [];
                    areaTagGroups.push(areaTagGroup);
                    this.contractToAreaTagGroups.set(contract, areaTagGroups);
                });
            });
        // set stored site groups
        portfolios.forEach((portfolio) => {
            portfolio.customer_organizations.forEach((customer) => {
                const types = customer.organization_contract_group_types;
                if (types) {
                    // need to initialize the type_id on all the groups
                    types.forEach((type) => {
                        type.organizationcontractgroups.forEach((group) => {
                            group.type_id =
                                type.organization_contract_group_type_id;
                        });
                    });
                    this.customerSiteGroupTypes.set(customer.id, types);
                }
            });
        });

        orgGroupTypes.forEach((type) => {
            type.organization_groups.forEach((group) => {
                group.type_id = type.organization_group_type_id;
            });
        });
        this.organizationGroupTypes = orgGroupTypes;
    }

    private getCombinedChildrenObjects<
        P extends { id: number },
        C extends { id: number; name: string },
    >(storedMap: Map<number, C[]>, keyList: P[]): C[] {
        // Maps output object id to output object to remove duplicates
        const output = new Map<number, C>();
        keyList.forEach((parentObject: P) => {
            const childrenObjects = storedMap.get(parentObject.id);
            if (childrenObjects?.length) {
                childrenObjects.forEach((outputObject: C) =>
                    output.set(outputObject.id, outputObject)
                );
            }
        });
        return [...output.values()].sort(StoredInfo.NAME_SORTER);
    }

    public getSelectableOrgGroupTypes(): R4OrganizationGroupType[] {
        return this.organizationGroupTypes
            ?.filter((type) =>
                type.organization_groups
                    ?.flatMap((group) =>
                        group.organizations.map((org) => org.organization_id)
                    )
                    ?.some((id) =>
                        this.customers.find((customer) => customer.id === id)
                    )
            )
            ?.map((type) => ({
                ...type,
                organization_groups: type.organization_groups.filter((option) =>
                    option.organizations.some((org) =>
                        this.customers.find(
                            (customer) => customer.id === org.organization_id
                        )
                    )
                ),
            }))
            .sort(StoredInfo.NAME_SORTER);
    }

    public getSelectableCustomers(
        selectedOrgGroups: OrgGroupMap
    ): R4PortfolioCustomerOrganization[] {
        const orgGroupCustomerIds = Object.values(selectedOrgGroups).flatMap(
            (groups) =>
                groups.flatMap((group) =>
                    group.organizations.map((org) => org.organization_id)
                )
        );
        if (orgGroupCustomerIds.length > 0) {
            return this.customers.filter((customer) =>
                orgGroupCustomerIds.some((id) => id === customer.id)
            );
        }
        return this.customers;
    }

    public getSelectableContracts(
        customers: R4PortfolioCustomerOrganization[],
        siteGroups: SiteGroupMap
    ): Promise<R4PortfolioContract[]> {
        // Pull from API for any contracts not pulled for the customers
        const contractObservables = [];
        for (const customer of customers) {
            if (!this.customerToContracts?.has(customer?.id)) {
                const relatedCustomerContracts = this.portfolios
                    ?.flatMap((portfolio: R4Portfolio) => portfolio.contracts)
                    ?.filter(
                        (contract: R4PortfolioContract) =>
                            contract.customer === customer?.id
                    );

                if (relatedCustomerContracts?.length) {
                    // Relating portfolio contracts so add what is stored in user's portfolio
                    this.customerToContracts.set(
                        customer?.id,
                        relatedCustomerContracts
                    );
                } else {
                    // No relating portfolio contracts so request them from server
                    contractObservables.push(
                        contractsService
                            .getContracts({
                                customer: customer?.id,
                                limit: 1000,
                            })
                            .pipe(
                                map(
                                    (paginatedResults) =>
                                        paginatedResults?.results || []
                                ),
                                map((contracts) =>
                                    contracts.map((contract) =>
                                        formatContractToContext(contract)
                                    )
                                ),
                                map((contracts: R4PortfolioContract[]) =>
                                    this.customerToContracts.set(
                                        customer?.id,
                                        contracts
                                    )
                                )
                            )
                    );
                }
            }
        }

        return forkJoin(contractObservables)
            .toPromise()
            .then(() => {
                const baseContracts = this.getCombinedChildrenObjects(
                    this.customerToContracts,
                    customers
                );
                // create a map of customer id to a list of contracts based on selected site groups
                const siteGroupContractIds = Object.values(siteGroups)?.map(
                    (groups) =>
                        groups?.flatMap((group) =>
                            group.contracts?.map(
                                (contract) => contract.contract_id
                            )
                        )
                );

                if (siteGroupContractIds.length > 0 && customers.length === 1) {
                    return baseContracts.filter((contract) =>
                        siteGroupContractIds.every(
                            (groups) =>
                                !groups.length || groups.includes(contract.id)
                        )
                    );
                }
                return baseContracts;
            });
    }

    public getSelectableSiteGroups(
        customers: R4PortfolioCustomerOrganization[]
    ): R4PortfolioCustomerContractGroupType[] {
        // do not show site groups when there's more than one customer
        if (customers.length > 1) {
            return [];
        }

        // had to duplicate the getCombinedChildrenObjects because ContractGroupType doesn't use simple id/name fields...
        const output = new Map<number, R4PortfolioCustomerContractGroupType>();
        customers.forEach((customer) => {
            const childrenObjects = this.customerSiteGroupTypes?.get(
                customer.id
            );
            if (childrenObjects?.length) {
                childrenObjects.forEach((outputObject) => {
                    outputObject.organizationcontractgroups.sort(
                        StoredInfo.NAME_SORTER
                    );
                    output.set(
                        outputObject.organization_contract_group_type_id,
                        outputObject
                    );
                });
            }
        });
        return [...output.values()].sort((a, b) =>
            a.organization_contract_group_type_name.localeCompare(
                b.organization_contract_group_type_name
            )
        );
    }

    public getSelectableAreaTags(contracts: R4PortfolioContract[]): Promise<{
        areaTagGroups: R4PortfolioAreaTagGroup[];
        areaTags: AreaTagMap;
    }> {
        // Pull from API if there are any area tags not pulled for the contracts
        const contractAreaTagGroups = this.getCombinedChildrenObjects(
            this.contractToAreaTagGroups,
            contracts
        );
        const areaTagObservables = [];
        for (const areaTagGroup of contractAreaTagGroups) {
            if (!this.areaTagGroupToAreaTags.has(areaTagGroup.id)) {
                areaTagObservables.push(
                    areaTagService
                        .getAreaTags({ group: areaTagGroup.id, limit: 1000 })
                        .pipe(
                            map((paginatedResults) => {
                                return !paginatedResults?.results
                                    ? []
                                    : paginatedResults.results.map(
                                          (areaTag) => {
                                              // {{API_REDO}} manually adding area_tag_group_id since it isn't given in request (would be nice to have)
                                              // @ts-ignore
                                              areaTag.area_tag_group_id =
                                                  areaTagGroup.id;
                                              return areaTag;
                                          }
                                      );
                            }),
                            map((areaTags: AreaTag[]) => {
                                this.areaTagGroupToAreaTags.set(
                                    areaTagGroup.id,
                                    areaTags.map(formatAreaTagToContext)
                                );
                            })
                        )
                );
            }
        }

        // Return area tags promise
        return forkJoin(areaTagObservables)
            .toPromise()
            .then(() => {
                const pickerAreaTags = {};
                contractAreaTagGroups.forEach(
                    (tagGroup: R4PortfolioAreaTagGroup) => {
                        pickerAreaTags[tagGroup.id] =
                            this.areaTagGroupToAreaTags.get(tagGroup.id) || [];
                    }
                );
                return {
                    areaTagGroups: contractAreaTagGroups,
                    areaTags: pickerAreaTags,
                };
            });
    }
}

enum PickerActionType {
    SET_PICKER_SELECTED_ORG_GROUPS = 'SET_PICKER_SELECTED_ORG_GROUPS',
    SET_PICKER_SELECTED_CUSTOMERS = 'SET_PICKER_SELECTED_CUSTOMERS',
    SET_PICKER_SELECTED_CONTRACTS = 'SET_PICKER_SELECTED_CONTRACTS',
    SET_PICKER_SELECTED_AREA_TAG = 'SET_PICKER_SELECTED_AREA_TAG',
    SET_PICKER_SELECTED_SITE_GROUPS = 'SET_PICKER_SELECTED_SITE_GROUPS',

    SET_STATE = 'SET_STATE',
}

type PickerAction =
    | App.Action
    | {
          type: PickerActionType.SET_PICKER_SELECTED_ORG_GROUPS;
          payload: {
              selectedOrgGroups: OrgGroupMap;
              selectableCustomers: R4PortfolioCustomerOrganization[];
          };
      }
    | {
          type: PickerActionType.SET_PICKER_SELECTED_CUSTOMERS;
          payload: {
              selectedCustomers: R4PortfolioCustomerOrganization[];
              selectableContracts: R4PortfolioContract[];
              selectableSiteGroups: R4PortfolioCustomerContractGroupType[];
          };
      }
    | {
          type: PickerActionType.SET_PICKER_SELECTED_CONTRACTS;
          payload: {
              selectedContracts: R4PortfolioContract[];
              selectableAreaTagGroups: R4PortfolioAreaTagGroup[];
              selectableAreaTags: AreaTagMap;
          };
      }
    | {
          type: PickerActionType.SET_PICKER_SELECTED_AREA_TAG;
          payload: {
              areaTagGroup: R4PortfolioAreaTagGroup;
              areaTags: R4PortfolioAreaTag[];
          };
      }
    | {
          type: PickerActionType.SET_PICKER_SELECTED_SITE_GROUPS;
          payload: {
              selectedSiteGroups: SiteGroupMap;
              selectableContracts: R4PortfolioContract[];
          };
      }
    | {
          type: PickerActionType.SET_STATE;
          payload: Partial<StoredPickerState>;
      };

const getInitialPickerState = (
    state: App.State
): StoredPickerStateInternal => ({
    ...state,

    wrappingDispatch: () => {},

    pickerOptionOrgGroups: [],
    pickerOptionCustomers: [],
    pickerOptionContracts: [],
    pickerOptionAreaTagGroups: [],
    pickerOptionAreaTags: {},
    pickerOptionSiteGroupTypes: [],

    pickerSelectedOrgGroups: {},
    pickerSelectedCustomers: [],
    pickerSelectedContracts: [],
    pickerSelectedAreaTags: {},
    pickerSelectedSiteGroups: {},
    pickerSelectedPreset: null,
});

const getClientGroup = (el, selectedOrgGroups) => {
    for (const groupArray of Object.values(selectedOrgGroups)) {
        const foundGroup = groupArray.find((group) => {
            return group.organizations.some((e) => e.organization_id === el.id);
        });
        if (foundGroup) {
            return foundGroup;
        }
    }
    return null;
};

const pickerReducer = (
    state: StoredPickerStateInternal,
    action: PickerAction
): StoredPickerStateInternal => {
    switch (action.type) {
        case PickerActionType.SET_PICKER_SELECTED_ORG_GROUPS: {
            let { selectedOrgGroups, selectableCustomers } = action.payload;

            let stillSelectedCustomers = state.pickerSelectedCustomers.filter(
                (customer) =>
                    selectableCustomers.some(
                        (selectableCustomer) =>
                            selectableCustomer.id === customer.id
                    )
            );
            const stillOptionSiteGroupTypes =
                stillSelectedCustomers.length === 1
                    ? state.pickerOptionSiteGroupTypes
                    : [];
            const stillSelectedSiteGroups =
                stillSelectedCustomers.length === 1
                    ? state.pickerSelectedSiteGroups
                    : {};
            const stillSelectedContracts = state.pickerSelectedContracts.filter(
                (contract) =>
                    stillSelectedCustomers.some(
                        (customer) => contract.customer === customer.id
                    )
            );
            const stillOptionContracts = state.pickerOptionContracts.filter(
                (contract) =>
                    stillSelectedCustomers.some(
                        (customer) => contract.customer === customer.id
                    )
            );
            stillSelectedCustomers = stillSelectedCustomers.map((el) => {
                el['org_customer_group'] = getClientGroup(
                    el,
                    selectedOrgGroups
                );
                return el;
            });
            selectableCustomers = selectableCustomers.map((el) => {
                el['org_customer_group'] = getClientGroup(
                    el,
                    selectedOrgGroups
                );
                return el;
            });
            return {
                ...state,
                pickerSelectedOrgGroups: selectedOrgGroups,
                pickerSelectedCustomers: stillSelectedCustomers,
                pickerSelectedSiteGroups: stillSelectedSiteGroups,
                pickerSelectedContracts: stillSelectedContracts,

                pickerOptionCustomers: selectableCustomers,
                pickerOptionSiteGroupTypes: stillOptionSiteGroupTypes,
                pickerOptionContracts: stillOptionContracts,
            };
        }
        case PickerActionType.SET_PICKER_SELECTED_CUSTOMERS: {
            const {
                selectedCustomers,
                selectableContracts,
                selectableSiteGroups,
            } = action.payload;

            const stillSelectedSiteGroups = filterSelectedSiteGroups(
                selectableSiteGroups,
                state.pickerSelectedSiteGroups
            );
            const stillSelectedContracts = filterSelectedContracts(
                selectableContracts,
                state.pickerSelectedContracts,
                stillSelectedSiteGroups
            );
            const {
                areaTagGroups: stillOptionAreaTagGroups,
                areaTags: stillOptionAreaTags,
            } = filterToAreaTagGroupsPlusMap(
                stillSelectedContracts,
                state.pickerOptionAreaTagGroups,
                state.pickerOptionAreaTags
            );
            const stillSelectedAreaTags = filterToPickerAreaTagMap(
                stillOptionAreaTagGroups,
                state.pickerSelectedAreaTags
            );

            return {
                ...state,
                pickerOptionContracts: selectableContracts,
                pickerOptionAreaTagGroups: stillOptionAreaTagGroups,
                pickerOptionAreaTags: stillOptionAreaTags,
                pickerOptionSiteGroupTypes: selectableSiteGroups,

                pickerSelectedCustomers: selectedCustomers,
                pickerSelectedContracts: stillSelectedContracts,
                pickerSelectedAreaTags: stillSelectedAreaTags,
                pickerSelectedSiteGroups: stillSelectedSiteGroups,
            };
        }

        case PickerActionType.SET_PICKER_SELECTED_CONTRACTS: {
            const {
                selectedContracts,
                selectableAreaTagGroups,
                selectableAreaTags,
            } = action.payload;
            const stillSelectedAreaTags = filterToPickerAreaTagMap(
                selectableAreaTagGroups,
                state.pickerSelectedAreaTags
            );

            return {
                ...state,
                pickerOptionAreaTagGroups: selectableAreaTagGroups,
                pickerOptionAreaTags: selectableAreaTags,

                pickerSelectedContracts: selectedContracts,
                pickerSelectedAreaTags: stillSelectedAreaTags,
            };
        }

        case PickerActionType.SET_PICKER_SELECTED_AREA_TAG: {
            const { areaTagGroup, areaTags } = action.payload;
            return {
                ...state,
                pickerSelectedAreaTags: {
                    ...state.pickerSelectedAreaTags,
                    [areaTagGroup.id]: areaTags,
                },
            };
        }

        case PickerActionType.SET_PICKER_SELECTED_SITE_GROUPS: {
            const { selectedSiteGroups, selectableContracts } = action.payload;

            const stillSelectedContracts = filterSelectedContracts(
                selectableContracts,
                state.pickerSelectedContracts,
                selectedSiteGroups
            );
            const {
                areaTagGroups: stillOptionAreaTagGroups,
                areaTags: stillOptionAreaTags,
            } = filterToAreaTagGroupsPlusMap(
                stillSelectedContracts,
                state.pickerOptionAreaTagGroups,
                state.pickerOptionAreaTags
            );
            const stillSelectedAreaTags = filterToPickerAreaTagMap(
                stillOptionAreaTagGroups,
                state.pickerSelectedAreaTags
            );

            return {
                ...state,
                pickerSelectedSiteGroups: selectedSiteGroups,
                pickerSelectedContracts: stillSelectedContracts,
                pickerSelectedAreaTags: stillSelectedAreaTags,

                pickerOptionContracts: selectableContracts,
                pickerOptionAreaTagGroups: stillOptionAreaTagGroups,
                pickerOptionAreaTags: stillOptionAreaTags,
            };
        }

        case PickerActionType.SET_STATE: {
            return {
                ...state,
                ...action.payload,
            };
        }
        default: {
            return state;
        }
    }
};

const onSetWrappedState = (
    wrappingDispatch: Dispatch<PickerAction>,
    wrappedState: App.State,
    wrappingState: StoredPickerState
) => {
    // When the role profile changes from the app context, update the picker options
    // Also when the app selected customers, contracts, area tag groups, or area tags have changed check their validity and update
    const newRoleProfile = wrappedState.user?.roleProfile;
    const newOrgGroups = wrappedState.selectedOrgGroups || [];
    const newCustomers = wrappedState.selectedCustomers || [];
    const newContracts = wrappedState.selectedContracts || [];
    const newAreaTagGroups = wrappedState.selectedAreaTagGroups || [];
    const newAreaTags = wrappedState.selectedAreaTags || [];
    const newSiteGroups = wrappedState.selectedSiteGroups || [];
    const newPreset = wrappedState.selectedPreset || null;

    const isNewRoleProfile =
        !storedInfo.isSet() ||
        (newRoleProfile &&
            newRoleProfile.id !== wrappingState.user?.roleProfile?.id);
    if (!storedInfo.isSet() && !newRoleProfile?.portfolios) {
        return;
    }

    if (
        isNewRoleProfile ||
        !hasSameElements(
            newCustomers,
            wrappingState.selectedCustomers,
            (customer: R4PortfolioCustomerOrganization) => customer.id
        ) ||
        !hasSameElements(
            newContracts,
            wrappingState.selectedContracts,
            (contract: R4PortfolioContract) => contract.id
        ) ||
        !hasSameElements(
            newAreaTagGroups,
            wrappingState.selectedAreaTagGroups,
            (areaTagGroup: R4PortfolioAreaTagGroup) => areaTagGroup.id
        ) ||
        !hasSameElements(
            newAreaTags,
            wrappingState.selectedAreaTags,
            (areaTag: R4PortfolioAreaTag) => areaTag.id
        )
    ) {
        const portfolios = isNewRoleProfile ? newRoleProfile.portfolios : null;
        const orgGroupTypes = isNewRoleProfile
            ? wrappedState.user.organizationGroupTypes
            : null;
        initializePickerOptions(
            wrappingDispatch,
            portfolios,
            orgGroupTypes,
            newOrgGroups,
            newCustomers,
            newContracts,
            newAreaTagGroups,
            newAreaTags,
            newSiteGroups,
            newPreset
        );
    }
};

const storedInfo = new StoredInfo();

export const [StoredPickerContext, StoredPickerProvider] = createWrappingCtx(
    pickerReducer,
    getInitialPickerState(initialAppState),
    appContext,
    onSetWrappedState
);

// Dispatch is deliberately hidden, updates should go through set functions on the context
export const usePickerContext = (): {
    state: StoredPickerState;

    setSelectedOrgGroups: (selectedOrgGroups: R4OrganizationGroup[]) => void;
    setSelectedCustomers: (
        customers: R4PortfolioCustomerOrganization[]
    ) => void;
    setSelectedContracts: (contract: R4PortfolioContract[]) => void;
    setSelectedAreaTag: (
        areaTagGroup: R4PortfolioAreaTagGroup,
        areaTags: R4PortfolioAreaTag[]
    ) => void;
    setSelectedSiteGroups: (
        selectedSiteGroups: R4PortfolioCustomerContractGroup[]
    ) => void;
    resetSelections: () => void;
} => {
    const { state } = useContext(StoredPickerContext);

    const wrappingState = state as any;
    const setSelectedOrgGroups = (selectedOrgGroups) =>
        setSelectedOrgGroupsInternal(
            wrappingState.wrappingDispatch,
            selectedOrgGroups
        );
    const setSelectedCustomers = (customers) =>
        setSelectedCustomersInternal(
            wrappingState.wrappingDispatch,
            customers,
            state.pickerSelectedSiteGroups
        );
    const setSelectedContracts = (contracts) =>
        setSelectedContractsInternal(wrappingState.wrappingDispatch, contracts);
    const setSelectedAreaTag = (areaTagGroup, areaTags) =>
        setSelectedAreaTagInternal(
            wrappingState.wrappingDispatch,
            areaTagGroup,
            areaTags
        );
    const setSelectedSiteGroups = (selectedSiteGroups) =>
        setSelectedSiteGroupsInternal(
            wrappingState.wrappingDispatch,
            selectedSiteGroups,
            state.pickerSelectedCustomers
        );
    const resetSelections = () =>
        initializePickerOptions(
            wrappingState.wrappingDispatch,
            null,
            null,
            state.selectedOrgGroups,
            state.selectedCustomers,
            state.selectedContracts,
            state.selectedAreaTagGroups,
            state.selectedAreaTags,
            state.selectedSiteGroups,
            state.selectedPreset
        );

    return {
        state,
        setSelectedOrgGroups,
        setSelectedCustomers,
        setSelectedContracts,
        setSelectedAreaTag,
        setSelectedSiteGroups,
        resetSelections,
    };
};

const setSelectedOrgGroupsInternal = (
    wrappingDispatch: Dispatch<PickerAction>,
    orgGroups: R4OrganizationGroup[]
) => {
    const selectedOrgGroups = orgGroups.reduce((acc, orgGroup) => {
        if (!acc[orgGroup.type_id]) {
            acc[orgGroup.type_id] = [];
        }
        acc[orgGroup.type_id].push(orgGroup);
        return acc;
    }, {} as OrgGroupMap);
    const selectableCustomers =
        storedInfo.getSelectableCustomers(selectedOrgGroups);
    wrappingDispatch({
        type: PickerActionType.SET_PICKER_SELECTED_ORG_GROUPS,
        payload: {
            selectedOrgGroups,
            selectableCustomers,
        },
    });
};

const setSelectedCustomersInternal = (
    wrappingDispatch: Dispatch<PickerAction>,
    customers: R4PortfolioCustomerOrganization[],
    siteGroups: SiteGroupMap
) => {
    return storedInfo
        .getSelectableContracts(customers, siteGroups)
        .then((selectableContracts: R4PortfolioContract[]) => {
            wrappingDispatch({
                type: PickerActionType.SET_PICKER_SELECTED_CUSTOMERS,
                payload: {
                    selectedCustomers: customers,
                    selectableContracts,
                    selectableSiteGroups:
                        storedInfo.getSelectableSiteGroups(customers),
                },
            });
        });
};

const setSelectedContractsInternal = (
    wrappingDispatch: Dispatch<PickerAction>,
    contracts: R4PortfolioContract[]
) => {
    return storedInfo
        .getSelectableAreaTags(contracts)
        .then(({ areaTagGroups, areaTags }) => {
            wrappingDispatch({
                type: PickerActionType.SET_PICKER_SELECTED_CONTRACTS,
                payload: {
                    selectedContracts: contracts.sort((a, b) =>
                        a.customer_name.localeCompare(b.customer_name)
                    ),
                    selectableAreaTagGroups: areaTagGroups,
                    selectableAreaTags: areaTags,
                },
            });
        });
};

const setSelectedAreaTagInternal = (
    wrappingDispatch: Dispatch<PickerAction>,
    areaTagGroup: R4PortfolioAreaTagGroup,
    areaTags: R4PortfolioAreaTag[]
) => {
    wrappingDispatch({
        type: PickerActionType.SET_PICKER_SELECTED_AREA_TAG,
        payload: {
            areaTagGroup,
            areaTags,
        },
    });
};

const setSelectedSiteGroupsInternal = (
    wrappingDispatch: Dispatch<PickerAction>,
    siteGroups: R4PortfolioCustomerContractGroup[],
    customers: R4PortfolioCustomerOrganization[]
) => {
    const selectedSiteGroups = siteGroups.reduce((acc, siteGroup) => {
        if (!acc[siteGroup.type_id]) {
            acc[siteGroup.type_id] = [];
        }
        acc[siteGroup.type_id].push(siteGroup);
        return acc;
    }, {} as SiteGroupMap);
    return storedInfo
        .getSelectableContracts(customers, selectedSiteGroups)
        .then((selectableContracts: R4PortfolioContract[]) => {
            wrappingDispatch({
                type: PickerActionType.SET_PICKER_SELECTED_SITE_GROUPS,
                payload: {
                    selectedSiteGroups,
                    selectableContracts,
                },
            });
        });
};

const filterSelectedCustomers = (
    selectableCustomers: R4PortfolioCustomerOrganization[],
    selectedCustomers: R4PortfolioCustomerOrganization[]
) => {
    const selectableCustomerIdSet = new Set<number>(
        selectableCustomers?.map(
            (customer: R4PortfolioCustomerOrganization) => customer.id
        )
    );
    return selectedCustomers?.filter(
        (customer: R4PortfolioCustomerOrganization) =>
            selectableCustomerIdSet.has(customer.id)
    );
};

const filterSelectedContracts = (
    selectableContracts: R4PortfolioContract[],
    selectedContracts: R4PortfolioContract[],
    siteGroups: SiteGroupMap
) => {
    const selectableContractIds = new Set<number>(
        selectableContracts.map((contract: R4PortfolioContract) => contract.id)
    );
    const siteGroupContractIds = Object.values(siteGroups).flatMap((groups) =>
        groups.flatMap((group) =>
            group.contracts.map((contract) => contract.contract_id)
        )
    );

    const contracts = selectedContracts.filter(
        (contract: R4PortfolioContract) =>
            selectableContractIds.has(contract.id)
    );

    if (siteGroupContractIds.length > 0) {
        return contracts.filter((contract: R4PortfolioContract) =>
            siteGroupContractIds.includes(contract.id)
        );
    }
    return contracts;
};

const filterSelectedOrgGroups = (
    selectableOrgGroups: R4OrganizationGroupType[],
    selectedOrgGroups: OrgGroupMap
) => {
    const filteredSiteGroups: R4OrganizationGroup[] = [];
    const selectableSiteGroupIds = new Set<number>(
        selectableOrgGroups?.flatMap((groupType) =>
            groupType.organization_groups.map(
                (group) => group.organization_group_id
            )
        )
    );
    Object.values(selectedOrgGroups).forEach((groupTypes) => {
        groupTypes.forEach((orgGroup) => {
            if (selectableSiteGroupIds.has(orgGroup.organization_group_id)) {
                filteredSiteGroups.push(orgGroup);
            }
        });
    });
    return getOrgGroupMap(filteredSiteGroups);
};

const filterSelectedSiteGroups = (
    selectableSiteGroups: R4PortfolioCustomerContractGroupType[],
    selectedSiteGroups: SiteGroupMap
) => {
    const filteredSiteGroups: R4PortfolioCustomerContractGroup[] = [];
    const selectableSiteGroupIds = new Set<number>(
        selectableSiteGroups.flatMap((groupType) =>
            groupType.organizationcontractgroups.map(
                (group) => group.organizationcontractgroup_id
            )
        )
    );
    Object.values(selectedSiteGroups).forEach((siteGroups) => {
        siteGroups.forEach((siteGroup) => {
            if (
                selectableSiteGroupIds.has(
                    siteGroup.organizationcontractgroup_id
                )
            ) {
                filteredSiteGroups.push(siteGroup);
            }
        });
    });
    return getSiteGroupMap(filteredSiteGroups);
};

const filterToAreaTagGroupsPlusMap = (
    selectedContracts: R4PortfolioContract[],
    toFilterTagGroups: R4PortfolioAreaTagGroup[],
    toFilterAreaTags: AreaTagMap
) => {
    // Since this should still be an option, there shouldn't be a call to the stored info necessary
    const contractIdSet = new Set<number>(
        selectedContracts.map((contract: R4PortfolioContract) => contract.id)
    );
    const areaTagGroups = toFilterTagGroups.filter(
        (tagGroup: R4PortfolioAreaTagGroup) =>
            tagGroup.contracts.some((contractId: number) =>
                contractIdSet.has(contractId)
            )
    );

    const areaTagGroupIdSet = new Set<number>(
        areaTagGroups.map((tagGroup: R4PortfolioAreaTagGroup) => tagGroup.id)
    );
    const areaTags = { ...toFilterAreaTags };
    Object.keys(toFilterAreaTags).forEach((tagGroupId: string) => {
        if (!areaTagGroupIdSet.has(Number(tagGroupId))) {
            delete areaTags[tagGroupId];
        }
    });

    return { areaTagGroups, areaTags };
};

const filterToPickerAreaTagMap = (
    selectableAreaTagGroups: R4PortfolioAreaTagGroup[],
    toFilterAreaTagGroups: AreaTagMap
) => {
    const selectableAreaTagGroupIds = new Set<number>(
        selectableAreaTagGroups.map(
            (areaTagGroup: R4PortfolioAreaTagGroup) => areaTagGroup.id
        )
    );
    const areaTags = {};
    Object.keys(toFilterAreaTagGroups).forEach((areaTagGroupId: string) => {
        if (selectableAreaTagGroupIds.has(Number(areaTagGroupId))) {
            areaTags[areaTagGroupId] = toFilterAreaTagGroups[areaTagGroupId];
        }
    });
    return areaTags;
};

const getPickerSelectedAreaTags = (
    selectedAreaTagGroups: R4PortfolioAreaTagGroup[],
    selectedAreaTags: R4PortfolioAreaTag[]
): AreaTagMap => {
    if (!selectedAreaTagGroups?.length || !selectedAreaTags?.length) {
        return {};
    }

    const areaTagGroupToAreaTags = new Map<number, R4PortfolioAreaTag[]>();
    selectedAreaTags.forEach((areaTag: R4PortfolioAreaTag) => {
        let areaTags = areaTagGroupToAreaTags.get(areaTag.area_tag_group_id);
        if (!areaTags) {
            areaTags = [];
            areaTagGroupToAreaTags.set(areaTag.area_tag_group_id, areaTags);
        }
        areaTags.push(areaTag);
    });

    const areaTagMap = {};
    selectedAreaTagGroups.forEach((areaTagGroup: R4PortfolioAreaTagGroup) => {
        const areaTags = areaTagGroupToAreaTags.get(areaTagGroup.id);
        if (areaTags) {
            areaTagMap[areaTagGroup.id] = areaTags;
        }
    });

    return areaTagMap;
};

const areaTagMapToTags = (areaTagMap: AreaTagMap) => {
    let areaTags = [];
    Object.values(areaTagMap).forEach((areaTagArr: R4PortfolioAreaTag[]) => {
        areaTags = areaTags.concat(areaTagArr);
    });
    return areaTags;
};

export const getAppSelectedAreaTagGroupsTags = (
    optionAreaTagGroups: R4PortfolioAreaTagGroup[],
    selectedAreaTagMap: AreaTagMap
): {
    selectedAreaTagGroups: R4PortfolioAreaTagGroup[];
    selectedAreaTags: R4PortfolioAreaTag[];
} => {
    const selectedAreaTagGroups = optionAreaTagGroups.filter(
        (areaTagGroup: R4PortfolioAreaTagGroup) =>
            selectedAreaTagMap[areaTagGroup.id]?.length
    );
    const selectedAreaTags = Object.keys(selectedAreaTagMap).reduce(
        (accumulator: R4PortfolioAreaTag[], areaTagGroupId: string) => {
            const areaTags = selectedAreaTagMap[areaTagGroupId];
            return areaTags?.length
                ? accumulator.concat(areaTags)
                : accumulator;
        },
        []
    );

    return {
        selectedAreaTagGroups,
        selectedAreaTags,
    };
};

export const getAppSelectedOrgGroupTypesGroups = (
    optionSiteGroupTypes: R4OrganizationGroupType[],
    selectedSiteGroupMap: OrgGroupMap
): {
    selectedOrgGroupTypes: R4OrganizationGroupType[];
    selectedOrgGroups: R4OrganizationGroup[];
} => {
    const selectedOrgGroupTypes = optionSiteGroupTypes.filter(
        (groupType) =>
            selectedSiteGroupMap[groupType.organization_group_type_id]?.length
    );
    const selectedOrgGroups = Object.keys(selectedSiteGroupMap).reduce(
        (accumulator, siteGroupTypeId: string) => {
            const siteGroups = selectedSiteGroupMap[siteGroupTypeId];
            return siteGroups?.length
                ? accumulator.concat(siteGroups)
                : accumulator;
        },
        []
    );
    return {
        selectedOrgGroupTypes,
        selectedOrgGroups,
    };
};

export const getAppSelectedSiteGroupTypesGroups = (
    optionSiteGroupTypes: R4PortfolioCustomerContractGroupType[],
    selectedSiteGroupMap: SiteGroupMap
): {
    selectedSiteGroupTypes: R4PortfolioCustomerContractGroupType[];
    selectedSiteGroups: R4PortfolioCustomerContractGroup[];
} => {
    const selectedSiteGroupTypes = optionSiteGroupTypes.filter(
        (groupType) =>
            selectedSiteGroupMap[groupType.organization_contract_group_type_id]
                ?.length
    );
    const selectedSiteGroups = Object.keys(selectedSiteGroupMap).reduce(
        (accumulator, siteGroupTypeId: string) => {
            const siteGroups = selectedSiteGroupMap[siteGroupTypeId];
            return siteGroups?.length
                ? accumulator.concat(siteGroups)
                : accumulator;
        },
        []
    );

    return {
        selectedSiteGroupTypes,
        selectedSiteGroups,
    };
};

export const getOrgGroupMap = (siteGroups: R4OrganizationGroup[]) => {
    return siteGroups.reduce((obj, orgGroup) => {
        obj[orgGroup.type_id] = [...(obj[orgGroup.type_id] ?? []), orgGroup];
        return obj;
    }, {});
};

export const getSiteGroupMap = (
    siteGroups: R4PortfolioCustomerContractGroup[]
) => {
    return siteGroups.reduce((obj, siteGroup) => {
        obj[siteGroup.type_id] = [...(obj[siteGroup.type_id] ?? []), siteGroup];
        return obj;
    }, {});
};

const initializePickerOptions = (
    wrappingDispatch: Dispatch<PickerAction>,
    portfolios: R4Portfolio[],
    orgGroupTypes: R4OrganizationGroupType[],
    appOrgGroups: R4OrganizationGroup[],
    appCustomers: R4PortfolioCustomerOrganization[],
    appContracts: R4PortfolioContract[],
    appAreaTagGroups: R4PortfolioAreaTagGroup[],
    appAreaTags: R4PortfolioAreaTag[],
    appSiteGroups: R4PortfolioCustomerContractGroup[],
    appPreset: R4Preset
) => {
    if (portfolios && orgGroupTypes) {
        storedInfo.resetStoredInfo(portfolios, orgGroupTypes);
    }

    const toDispatch = (
        selectableOrgGroups: R4OrganizationGroupType[],
        selectableCustomers: R4PortfolioCustomerOrganization[],
        selectableContracts: R4PortfolioContract[],
        selectedOrgGroups: OrgGroupMap,
        selectedCustomers: R4PortfolioCustomerOrganization[],
        selectableSiteGroupTypes?: R4PortfolioCustomerContractGroupType[],
        selectedSiteGroups?: SiteGroupMap,
        selectableAreaTagGroups?: R4PortfolioAreaTagGroup[],
        selectableAreaTags?: AreaTagMap,
        selectedContracts?: R4PortfolioContract[],
        selectedAreaTagGroups?: R4PortfolioAreaTagGroup[],
        selectedAreaTags?: R4PortfolioAreaTag[],
        selectedAreaTagMap?: AreaTagMap,
        selectedPreset?: R4Preset
    ) => {
        wrappingDispatch({
            type: PickerActionType.SET_STATE,
            payload: {
                pickerSelectedOrgGroups: selectedOrgGroups,
                pickerSelectedCustomers: selectedCustomers,
                pickerSelectedContracts: selectedContracts || [],
                pickerSelectedAreaTags: selectedAreaTagMap || {},
                pickerSelectedSiteGroups: selectedSiteGroups || {},
                pickerSelectedPreset: selectedPreset || null,

                pickerOptionOrgGroups: selectableOrgGroups,
                pickerOptionCustomers: selectableCustomers,
                pickerOptionContracts: selectableContracts || [],
                pickerOptionAreaTagGroups: selectableAreaTagGroups || [],
                pickerOptionAreaTags: selectableAreaTags || {},
                pickerOptionSiteGroupTypes: selectableSiteGroupTypes || [],
            },
        });
        // If what the app had needs to be changed, dispatch to the app context
        // It will come back to this method again dispatching the stored picker context
        // Otherwise dispatch the updates to the stored picker context
        if (
            !hasSameElements(
                appCustomers,
                selectedCustomers,
                (customer: R4PortfolioCustomerOrganization) => customer.id
            ) ||
            !hasSameElements(
                appContracts,
                selectedContracts,
                (contract: R4PortfolioContract) => contract.id
            ) ||
            !hasSameElements(
                appAreaTagGroups,
                selectedAreaTagGroups,
                (areaTagGroup: R4PortfolioAreaTagGroup) => areaTagGroup.id
            ) ||
            !hasSameElements(
                appAreaTags,
                selectedAreaTags,
                (areaTag: R4PortfolioAreaTag) => areaTag.id
            )
        ) {
            wrappingDispatch({
                type: 'SET_PICKER',
                payload: {
                    selectedOrgGroupTypes: selectableOrgGroups?.filter(
                        (groupType) =>
                            selectedOrgGroups[
                                groupType.organization_group_type_id
                            ]?.length
                    ),
                    selectedOrgGroups: Object.values(selectedOrgGroups).flatMap(
                        (orgGroups) => orgGroups
                    ),
                    selectedCustomers,
                    selectedContracts:
                        selectedContracts || selectableContracts || [],
                    selectedAreaTagGroups: selectedAreaTagGroups || [],
                    selectedAreaTags: selectedAreaTags || [],
                    selectedSiteGroups: Object.values(
                        selectedSiteGroups
                    ).flatMap((siteGroups) => siteGroups),
                    selectedSiteGroupTypes: selectableSiteGroupTypes.filter(
                        (groupType) =>
                            selectedSiteGroups[
                                groupType.organization_contract_group_type_id
                            ]?.length
                    ),
                    selectedPreset,
                },
            });
        }
    };

    const selectableOrgGroupTypes = storedInfo.getSelectableOrgGroupTypes();
    const selectedOrgGroups = filterSelectedOrgGroups(
        selectableOrgGroupTypes,
        appOrgGroups ? getOrgGroupMap(appOrgGroups) : {}
    );

    // Only use valid customers
    const selectableCustomers =
        storedInfo.getSelectableCustomers(selectedOrgGroups);
    let selectedCustomers = filterSelectedCustomers(
        selectableCustomers,
        appCustomers || []
    );
    if (!selectedCustomers?.length) {
        selectedCustomers = [selectableCustomers?.[0]];
    }

    const selectableSiteGroups =
        storedInfo.getSelectableSiteGroups(selectedCustomers);
    const selectedSiteGroups: SiteGroupMap = filterSelectedSiteGroups(
        selectableSiteGroups,
        appSiteGroups ? getSiteGroupMap(appSiteGroups) : {}
    );

    const selectedPreset = appPreset;

    storedInfo
        .getSelectableContracts(selectedCustomers, selectedSiteGroups)
        .then((selectableContracts: R4PortfolioContract[]) => {
            // Only use valid contracts
            // If the user is a vendor account (contract has parent) and no site is selected, need to select all the sites
            let selectedContracts = filterSelectedContracts(
                selectableContracts,
                appContracts || [],
                selectedSiteGroups
            );
            if (!selectedContracts?.length) {
                const isVendor = selectableContracts.some(
                    (contract) => contract.parent_id
                );
                selectedContracts = isVendor ? selectableContracts : [];
            }

            if (!selectedContracts?.length) {
                toDispatch(
                    selectableOrgGroupTypes,
                    selectableCustomers,
                    selectableContracts,
                    selectedOrgGroups,
                    selectedCustomers,
                    selectableSiteGroups,
                    selectedSiteGroups
                );
            } else {
                storedInfo
                    .getSelectableAreaTags(selectedContracts)
                    .then(
                        ({
                            areaTagGroups: selectableAreaTagGroups,
                            areaTags: selectableAreaTags,
                        }) => {
                            // Update area tag groups and area tags valid for the selected contracts
                            const appSelectedAreaTagMap =
                                getPickerSelectedAreaTags(
                                    appAreaTagGroups || [],
                                    appAreaTags || []
                                );

                            const {
                                areaTagGroups: selectedAreaTagGroups,
                                areaTags: selectedAreaTagMap,
                            } = filterToAreaTagGroupsPlusMap(
                                selectedContracts,
                                appAreaTagGroups || [],
                                appSelectedAreaTagMap
                            );
                            const selectedAreaTags =
                                areaTagMapToTags(selectedAreaTagMap);

                            toDispatch(
                                selectableOrgGroupTypes,
                                selectableCustomers,
                                selectableContracts,
                                selectedOrgGroups,
                                selectedCustomers,
                                selectableSiteGroups,
                                selectedSiteGroups,
                                selectableAreaTagGroups,
                                selectableAreaTags,
                                selectedContracts,
                                selectedAreaTagGroups,
                                selectedAreaTags,
                                selectedAreaTagMap,
                                selectedPreset
                            );
                        }
                    );
            }
        });
};
