import { forwardRef, useState, useRef } from 'react';

// components
import { Icon } from '@atoms';

// interfaces
import { InputSearchProps } from './input-search.interfaces';

import './input-search.css';

export const InputSearch = forwardRef<HTMLInputElement, InputSearchProps>(
    (
        {
            className,
            disabled,
            error,
            onChange,
            onClearSearch,
            style,
            ...props
        },
        ref
    ) => {
        const [showClearButton, setShowClearButton] = useState(false);
        const inputRef = useRef<HTMLInputElement>(null);
        return (
            <div
                data-ds2="input-search"
                className={`tw-relative tw-h-10 tw-w-full tw-p-2 tw-rounded tw-ring-2 tw-ring-inset tw-flex tw-items-center tw-flex-row-reverse 
        ${
            error
                ? `tw-ring-theme-danger-500-300 focus-within:tw-shadow-input-error ${
                      disabled ? 'tw-ring-opacity-20' : ''
                  }`
                : `focus-within:tw-ring-theme-primary-500-300 focus-within:tw-shadow-input-focus ${
                      disabled
                          ? 'tw-ring-theme-neutral-300-700'
                          : 'tw-ring-theme-neutral-400-600'
                  }`
        }

        ${
            disabled
                ? 'tw-bg-theme-neutral-200-800 tw-cursor-not-allowed tw-text-theme-neutral-500-600'
                : 'tw-bg-theme-neutral-100-800 tw-text-theme-neutral-900-100'
        }

        ${className}
    `}
                style={style}
            >
                <input
                    type="search"
                    pattern="^[a-zA-Z][\sa-zA-Z]*"
                    ref={(el) => {
                        inputRef.current = el;
                        if (ref) {
                            // @ts-ignore
                            ref.current = el;
                        }
                    }}
                    className="tw-w-full tw-bg-transparent tw-outline-none tw-caret-neutral-600 dark:tw-caret-neutral-200 tw-mr-5 tw-peer"
                    disabled={disabled}
                    onChange={(e) => {
                        if (e.target.value.startsWith(' ')) {
                            return;
                        }

                        if (onClearSearch) {
                            setShowClearButton(e.target.value.length > 0);
                        }

                        if (onChange) {
                            onChange(e);
                        }
                    }}
                    {...props}
                />
                <Icon
                    role="presentation"
                    icon="search"
                    className={`tw-mr-2.5 tw-text-neutral-500 peer-disabled:tw-text-neutral-400 dark:peer-disabled:tw-text-neutral-700 ${
                        error
                            ? 'peer-focus:tw-text-theme-danger-500-300'
                            : 'peer-focus:tw-text-theme-primary-500-300'
                    }`}
                />
                {showClearButton && (
                    <button
                        aria-label="Clear Search"
                        className="tw-h-5 tw-w-5 tw-absolute tw-top-1/2 tw-right-2 tw--translate-y-1/2 tw-text-theme-neutral-600-500"
                        onClick={() => {
                            setShowClearButton(false);

                            if (inputRef.current) {
                                inputRef.current.focus();
                            }

                            if (onClearSearch) {
                                onClearSearch();
                            }
                        }}
                    >
                        <Icon
                            role="presentation"
                            icon={['far', 'times']}
                            className="tw-pointer-events-none"
                        />
                    </button>
                )}
            </div>
        );
    }
);

InputSearch.displayName = 'InputSearch';

InputSearch.defaultProps = {
    className: '',
};
