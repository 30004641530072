import { Icon, Text, Tooltip, Box } from '@atoms';

// interfaces
import { MenuItemProps } from './menu-item.interfaces';

export const MenuItem = ({
    name,
    dividerAfter,
    dividerBefore,
    withIcon,
    className,
    onClick,
    disabled,
    tooltip,
    ...props
}: MenuItemProps) => {
    let dividerClassName =
        'tw-border-solid tw-border-theme-neutral-900-100 tw-border-opacity-15';

    if (dividerBefore) {
        dividerClassName += ' tw-border-t';
    }

    if (dividerAfter) {
        dividerClassName += ' tw-border-b';
    }

    const menuItem = (
        <li
            aria-disabled={disabled}
            data-ds2="menu-item"
            role="menuitem"
            className={`tw-py-2 tw-px-2 tw-relative tw-flex tw-items-center tw-min-w-[128px] sm:tw-px-4
                ${
                    disabled
                        ? 'tw-cursor-not-allowed tw-pointer-events-none tw-text-theme-neutral-500-600'
                        : 'tw-cursor-pointer hover:tw-bg-neutral-300 dark:hover:tw-bg-primary-300 hover:tw-text-primary-500 dark:hover:tw-text-neutral-900 active:tw-bg-primary-900 active:tw-text-neutral-100 dark:active:tw-bg-primary-700 dark:active:tw-text-neutral-900'
                }
                ${className}
                ${
                    withIcon && withIcon.position === 'left'
                        ? ''
                        : 'tw-flex-row-reverse tw-justify-end'
                }
                ${dividerClassName}`}
            onClick={(e) => {
                e.stopPropagation();
                onClick(e);
            }}
            {...props}
        >
            {withIcon && (
                <Icon
                    {...withIcon.fontAwesome}
                    className={`${
                        withIcon.position === 'left' ? 'tw-mr-2' : 'tw-ml-2'
                    }`}
                />
            )}
            <Text font="body-md" color="current-color">
                {name}
            </Text>
        </li>
    );

    return Boolean(tooltip) ? (
        <Tooltip
            position="left"
            className={disabled ? 'tw-cursor-not-allowed' : ''}
            render={
                <Box rounded className="tw-p-4 tw-shadow-md">
                    <Text font="body-sm" color="hi-contrast">
                        {tooltip}
                    </Text>
                </Box>
            }
        >
            {menuItem}
        </Tooltip>
    ) : (
        menuItem
    );
};

MenuItem.defaultProps = {
    className: '',
    disabled: false,
};
