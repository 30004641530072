import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Compliment, ComplimentPayload } from '@api-interfaces';
import { TimeUtils, Params } from '@core/helpers';
import { virtualBrownClient } from '@core/services/django-client';
import { PaginationResponse, CommonParams } from '@models';

import { complimentsSummaryService } from './summary';

const defaultTo = TimeUtils.formatToISO(new Date());
const defaultFrom = TimeUtils.formatToISO(TimeUtils.subMonths(new Date(), 6));

const zone = Intl.DateTimeFormat().resolvedOptions().timeZone;

class ComplimentsService {
	readonly summary = complimentsSummaryService;

	public getCompliments = (
		params: CommonParams & { export_to_excel?: boolean }
	) => {
		const qs = Params.makeQueryString(params);
		const result = virtualBrownClient.get(`compliments/${qs}`);
		if (params.export_to_excel) {
			return result as Observable<any>;
		}
		return result.pipe<Compliment[]>(
			map((res: any) => (res.results ? res.results : []))
		);
	};

	public getCompliment = (id: number) => {
		return virtualBrownClient.get<Compliment>(`compliments/${id}/`);
	};

	public deleteCompliment = (id: number) => {
		return virtualBrownClient.delete(`compliments/${id}/`);
	};

	public createCompliment = (compliment: ComplimentPayload) => {
		return virtualBrownClient.post<Compliment>(`compliments/`, compliment);
	};

	public updateCompliment = (id: number, compliment: ComplimentPayload) => {
		return virtualBrownClient.put<Compliment>(`compliments/${id}/`, compliment);
	};

	// for rollup table
	public getComplimentCounts = (params: CommonParams) => {
		const query: any = params;
		query.chart = 0;

		const qs = Params.makeQueryString(query);
		return virtualBrownClient.get(`compliments/summary/${qs}`).pipe(
			map((res: any) => {
				if (res.length) {
					// remove because causing nulls to cast as 12/31/1969
					// res[0].results.latest_compliment_date = TimeUtils.format(res[0].results.latest_compliment_date, 'MM/DD/YYYY'); //format for display format
					return res[0];
				}
				return {
					TotalCompliments: 0,
					AveragePerMonth: 0,
					latest_compliment_date: 'No Data',
				};
			})
		);
	};

	public getComplimentTypes = () => {
		return virtualBrownClient.get<PaginationResponse<any>>(
			`reference/items/?group_code=Compliment_Types&limit=100`
		);
	};

	// charts page
	public getComplimentCharts = (params: {
		from?: Date;
		to?: Date;
		time_zone?: string;
		chart_type?: number;
		chart: string;
		tag_group?: number;
		service?: number;
		provider?: number;
	}): Observable<any> => {
		const query = {
			...params,
			from:
				params && params.from
					? TimeUtils.formatToISO(params.from)
					: defaultFrom,
			to: params && params.to ? TimeUtils.formatToISO(params.to) : defaultTo,
			time_zone: zone,
		};

		const queryString = Params.makeQueryString(query);
		return virtualBrownClient.get(`compliments/summary/${queryString}`);
	};
}

export const complimentsService = new ComplimentsService();
