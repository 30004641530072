import { cloneDeep } from 'lodash';

// context
import { Box, Text } from '@atoms';

import { useGmpAuditsModalContext } from '../_context/context';

// interfaces
import { GmpAuditForm } from '../interfaces';

// components
import { Question } from '.';

// helpers

interface Props {
  isReadOnly: boolean;
}

export const Audit = ({ isReadOnly }: Props) => {
  const {
    state: { form, questionnaire },
    dispatch,
  } = useGmpAuditsModalContext();

  return (
    <>
      {questionnaire?.sections.map((section, i) => (
        <Box
          key={i}
          id={`behavioral-review-form-${section.id}`}
          type="default"
          className="tw-p-4 tw-mt-4 tw-space-y-6
                        md:tw-p-10 md:tw-mt-6"
          rounded
        >
          <Text font="h2" color="primary">
            {section.name}
          </Text>

          {Object.values(section.questions).map((question, j) => (
            <Question
              key={j}
              title={question.name}
              description={question.description}
              answer={form?.audit?.[section.id]?.[question.id]}
              onMarkChange={(mark) => {
                const newForm: GmpAuditForm = cloneDeep(form);
                newForm.audit[section.id][question.id].mark = mark;
                dispatch({ type: 'SET_FORM', payload: newForm });
              }}
              isReadOnly={isReadOnly}
            />
          ))}
        </Box>
      ))}
    </>
  );
};
