import { Formik } from 'formik';
import { Component } from 'react';

// services
import { Employee, ReferenceItem, SafetyConduct } from '@api-interfaces';
import { referenceService, safetyConductsService } from '@apis';
import { Text } from '@atoms';
import { DropdownItem } from '@atoms/form-ds2/dropdown/dropdown.interfaces';
import { FieldDropdown } from '@atoms/form-ds2/field-dropdown';
import { FieldInputDate } from '@atoms/form-ds2/field-input-date';
import { FieldInputText } from '@atoms/form-ds2/field-input-text';
import MiniProfile from '@components/MiniProfile/MiniProfile';
import { App } from '@core/app-context/app.interfaces';
import {
  imageService,
  toasterService,
  modalService,
  refetchService,
} from '@core/services';

// helpers
import { TimeUtils } from '@helpers';
import { useAppContext } from '@hooks';

// components
import { Button } from '@new';

// interfaces

interface IProps {
  employee: Employee;
  safetyConduct?: SafetyConduct;
}

interface IState {
  date: Date;
  safetyTypes: DropdownItem[];
  safetyType: DropdownItem | null;
}

class SafetyViolationFormComponent extends Component<
  IProps & App.State,
  IState
> {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
      safetyTypes: [],
      safetyType: null,
    };
  }

  private handleSuccess = (message: string, res) => {
    toasterService.newToast({
      status: 'success',
      message,
    });
    refetchService.fetch('safety-incidents');
    modalService.closeAll({ res });
  };

  public createSafetyViolation = async (values, setSubmitting) => {
    const safetyViolation = this.props.safetyConduct;

    const payload = {
      contract: {
        id: this.props.selectedContracts?.[0]?.id,
      },
      violator: {
        id: this.props.employee.id,
      },
      occurrence_date: this.state.date.toISOString(),
      occurrence_type: {
        id: this.state.safetyType.id,
      },
      details_of_violation: values.description,
      creator: {
        id: this.props.user.employeeId,
      },
      created_on_date: new Date().toISOString(),
    };

    // editting existing
    if (safetyViolation) {
      payload.id = safetyViolation.id;
      return safetyConductsService
        .updateSafetyConduct({ id: safetyViolation.id, payload })
        .subscribe((res: any) => {
          if (res.id) {
            this.handleSuccess(
              'The safety violation was successfully updated.',
              res
            );
            return;
          }
          setSubmitting(false);
          toasterService.newToast({
            status: 'fail',
            message: 'There was an error updating the safety violation.',
          });
        });
    }
    // creating new
    return safetyConductsService
      .createSafetyConduct({ payload })
      .subscribe((res: any) => {
        if (!(res && res.id)) {
          toasterService.newToast({
            status: 'fail',
            message: 'There was an error creating the safety violation.',
          });
        }
        setSubmitting(false);

        this.handleSuccess(
          'The safety violation was successfully created.',
          res
        );
      });
  };

  public componentDidMount() {
    referenceService
      .getItems({ group_code: 'SafetyConduct_OccurrenceTypes' })
      .subscribe((res: Array<ReferenceItem>) => {
        this.setState({
          safetyTypes: res.map((type) => ({
            ...type,
            name: type.display_name,
          })),
        });
      });
  }

  public render() {
    const { employee } = this.props;
    const { safetyConduct } = this.props;

    return (
      <Formik
        initialValues={{
          description: safetyConduct ? safetyConduct.details_of_violation : '',
        }}
        validate={(values) => {
          const errors = {} as any;
          if (!values.description.length) {
            errors.description = 'A description is required.';
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          this.createSafetyViolation(values, setSubmitting);
        }}
      >
        {({
          errors,
          handleChange,
          handleBlur,
          touched,
          handleSubmit,
          isSubmitting,
        }) => (
          <form
            className="tw-space-y-6 tw-p-4 sm:tw-p-6"
            onSubmit={handleSubmit}
          >
            <Text font="h2" color="hi-contrast">
              Safety Violation
            </Text>
            <MiniProfile
              data={{
                imgUrl: imageService.getImageByUniqueId(
                  employee.person.photo_url
                ),
                employeeName: `${employee.person.first_name} ${employee.person.last_name}`,
                clientName: employee.organization.name,
                siteName: this.props.selectedContracts?.[0]?.name ?? '',
                jobTitle: employee.positions
                  .map((position) => position.name)
                  .join(),
                employee,
              }}
              settings={{
                bg: true,
                size: 'sm',
              }}
            />

            <FieldInputDate
              required
              disableFuture
              id="date_of_violation"
              label="Date of Violation"
              value={TimeUtils.format(this.state.date, 'MM/DD/YYYY')}
              onChange={(date) => {
                this.setState({ date });
              }}
            />

            <FieldDropdown
              id="conduct-type"
              label="Type"
              items={this.state.safetyTypes}
              selectedItem={this.state.safetyType}
              onChange={(selectedType) => {
                this.setState({
                  safetyType: selectedType,
                });
              }}
            />

            <FieldInputText
              fieldType="textarea"
              id="description"
              name="description"
              placeholder="Provide summary of violation"
              label="Description of Violation"
              onChange={handleChange}
              onBlur={handleBlur}
              rows={3}
              error={errors?.description}
            />

            <div className="tw-flex tw-justify-end tw-space-x-4">
              <Button
                label="Cancel"
                onClick={modalService.close}
                color="secondary"
              />
              <Button
                label="Submit"
                onClick={handleSubmit}
                disabled={isSubmitting}
              />
            </div>
          </form>
        )}
      </Formik>
    );
  }
}

export const SafetyViolationForm = (props: IProps) => {
  const { state } = useAppContext();
  return <SafetyViolationFormComponent {...state} {...props} />;
};
