import { Icon, Text } from '@atoms';

// utils
import { ButtonProps } from './button.interfaces';
import { buttonTypeMap } from './button.utils';

// interfaces
export const Button = ({
    color,
    label,
    icon,
    iconPosition,
    iconSize,
    className,
    type,
    ...props
}: ButtonProps) => {
    const iconPositionClass =
        iconPosition === 'right'
            ? 'tw-flex-row-reverse tw-space-x-reverse'
            : '';

    return (
        <button
            color={color}
            type={type}
            className={`tw-inline-flex tw-items-center tw-h-10 tw-space-x-2 tw-py-2 tw-px-4 tw-rounded ${iconPositionClass} ${buttonTypeMap(
                color
            )} ${className}`}
            {...props}
        >
            {icon && (
                <Icon
                    icon={icon}
                    fixedWidth
                    size={iconSize}
                    className="tw-pointer-events-none"
                />
            )}
            <Text
                font="h4"
                color="current-color"
                className="tw-line-clamp-1 tw-pointer-events-none"
            >
                {label}
            </Text>
        </button>
    );
};

Button.defaultProps = {
    color: 'primary',
    className: '',
    iconSize: 'xs',
    type: 'button',
};
