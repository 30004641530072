import { Formik, FormikActions } from 'formik';
import { Component } from 'react';

// services
import { Subscription, forkJoin } from 'rxjs';

import { Media, Employee, ReportIt, ServiceArea, Shift } from '@api-interfaces';
import {
    mediaService,
    mixpanelService,
    reportItsService,
    serviceAreasService,
    shiftsService,
} from '@apis';

// helpers

// components
import { Text } from '@atoms';
import { DropdownItem } from '@atoms/form-ds2/dropdown/dropdown.interfaces';
import { FieldDropdown } from '@atoms/form-ds2/field-dropdown';
import { FieldInputFile } from '@atoms/form-ds2/field-input-file';
import { FieldInputText } from '@atoms/form-ds2/field-input-text';
import { SubmitButton } from '@atoms/form-ds2/submit-button';
import ImageContainer from '@core/components/ImageContainer';
import MiniProfile from '@components/MiniProfile/MiniProfile';

// interfaces
import { App } from '@core/app-context/app.interfaces';
import { FieldMultiSelect } from '@atoms/form-ds2/field-multi-select';
import { TimeUtils, getLocations, sendError } from '@helpers';
import { useAppContext } from '@hooks';
import { Button } from '@new';
import {
    toasterService,
    imageService,
    modalService,
    refetchService,
} from '@services';
import {
    mixpanelCreateQualityReportIts,
    mixpanelEditQualityReportIts,
} from '@app/mixpanel/MixpanelPageTrack.tsx';

type ServiceAreaDropdownItem = DropdownItem<ServiceArea>;

interface IProps {
    data: {
        employee: Employee;
        reportIt?: ReportIt;
    };
}

interface IState {
    buildings: ServiceAreaDropdownItem[];
    floors: ServiceAreaDropdownItem[];
    rooms: ServiceAreaDropdownItem[];
    shifts: Shift[];
}

export class ReportItFormComponent extends Component<
    IProps & App.State,
    IState
> {
    private contractId =
        this.props.data.reportIt?.contract?.id ??
        this.props.selectedContracts?.[0]?.id;

    private subscription: Subscription;

    constructor(props: IProps & App.State) {
        super(props);
        this.subscription = new Subscription();
        this.state = {
            buildings: [],
            floors: [],
            rooms: [],
            shifts: [],
        };
    }

    public componentDidMount() {
        const buildings = serviceAreasService.getServiceAreas({
            contract: this.contractId,
            quick_landing: true,
        });
        const shifts = shiftsService.getShifts({ limit: 1000, offset: 0 });

        this.subscription = forkJoin([buildings, shifts]).subscribe({
            next: ([buildingRes, shiftsRes]) => {
                this.setState({
                    buildings: buildingRes.results,
                    shifts: shiftsRes.results,
                });
            },
            error: sendError(),
        });

        if (this.props.data.reportIt) {
            const locations = getLocations(this.props.data.reportIt.location);
            if (locations.building) {
                this.onBuildingSelect(locations.building);
            }
            if (locations.floor) {
                this.onFloorSelect(locations.floor);
            }
        }
    }

    componentWillUnmount() {
        this.subscription.unsubscribe();
    }

    private handleSuccess = (message: string) => {
        toasterService.newToast({
            status: 'success',
            message,
        });
        refetchService.fetch('report-its');
        modalService.closeAll();
    };

    public submitForm = (
        values: {
            building: ServiceArea;
            floor: ServiceAreaDropdownItem;
            room: ServiceAreaDropdownItem;
            description: string;
            attachments: Media[];
            shifts: Shift[];
        },
        { setSubmitting }: FormikActions<any>
    ) => {
        const body = {
            contract: {
                id: this.contractId,
            },
            employee_submitted_by: { id: this.props.data.employee.id },
            description: values.description,
            location: {
                id: values.room?.id ?? values.floor?.id ?? values.building?.id,
            },
            create_date: this.props.data.reportIt?.create_date
                ? new Date(this.props.data.reportIt.create_date)
                : new Date(),
            submit_date: this.props.data.reportIt?.submit_date
                ? new Date(this.props.data.reportIt.submit_date)
                : new Date(),
            media: values.attachments.map((attachment) => ({
                id: attachment.id,
            })),
            shifts: values.shifts.map(function (shift) {
                return shift.id;
            }),
        };

        if (this.props.data.reportIt) {
            reportItsService
                .updateReportIt(this.props.data.reportIt.id, body)
                .subscribe({
                    next: (res) => {
                        mixpanelEditQualityReportIts();
                        this.handleSuccess(
                            'The report-it was updated successfully.'
                        );
                    },
                    error: sendError({
                        toastMessage:
                            'There was an error updating the report-it.',
                        callback: () => {
                            setSubmitting(false);
                        },
                    }),
                });
            return;
        }

        reportItsService.createReportIt(body).subscribe({
            next: (res) => {
                mixpanelCreateQualityReportIts();
                this.handleSuccess('The report-it was created successfully.');
            },
            error: sendError({
                toastMessage: 'There was an error creating the report-it.',
                callback: () => {
                    setSubmitting(false);
                },
            }),
        });
    };

    public onBuildingSelect = (building: ServiceAreaDropdownItem) => {
        serviceAreasService
            .getServiceAreas({ parent: building.id, quick_landing: true })
            .subscribe((res) => {
                this.setState({
                    floors: res.results,
                });
            });
    };

    public onFloorSelect = (floor: ServiceAreaDropdownItem) => {
        serviceAreasService
            .getServiceAreas({ parent: floor.id, quick_landing: true })
            .subscribe((res) => {
                this.setState({
                    rooms: res.results,
                });
            });
    };

    public render() {
        const { reportIt, employee } = this.props.data;
        const contracts = this.props.selectedContracts;

        return (
            <Formik
                initialValues={{
                    description: reportIt?.description ?? '',
                    attachments: reportIt?.media_list ?? [],
                    shifts: reportIt ? reportIt?.shifts : employee?.shifts,
                    ...getLocations(reportIt?.location),
                }}
                validate={(values) => {
                    const errors: { [Key in keyof typeof values]: string } =
                        {} as any;
                    if (!values.building) {
                        errors.building = 'A building is required.';
                    }
                    if (!values.floor) {
                        errors.floor = 'A floor is required.';
                    }

                    if (!values.description.length) {
                        errors.description = 'A description is required.';
                    }

                    return errors;
                }}
                onSubmit={(values, formikActions) =>
                    this.submitForm(values, formikActions)
                }
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                }) => (
                    <form
                        className="tw-space-y-6 tw-p-4 sm:tw-p-6"
                        onSubmit={handleSubmit}
                        data-testid="reportIt-form"
                    >
                        <Text font="h2" color="hi-contrast">
                            {this.props.data.reportIt
                                ? 'Edit Report It Entry'
                                : 'New Report It Entry'}
                        </Text>

                        <MiniProfile
                            data={{
                                imgUrl: imageService.getImageByUniqueId(
                                    this.props.data.employee.person.photo_url
                                ),
                                employeeName: `${this.props.data.employee.person.first_name} ${this.props.data.employee.person.last_name}`,
                                siteName: contracts?.[0]?.name ?? '',
                                clientName: contracts?.[0]?.customer_name ?? '',
                                hiredDate: this.props.data.employee.hired_date
                                    ? new Date(
                                          TimeUtils.setHours(
                                              this.props.data.employee
                                                  .hired_date,
                                              13
                                          )
                                      )
                                    : null,
                                employee: this.props.data.employee,
                            }}
                            settings={{
                                bg: true,
                                size: 'sm',
                            }}
                        />

                        <FieldInputText
                            data-testid="descriptionField"
                            required
                            fieldType="textarea"
                            id="description"
                            label="Description"
                            placeholder="Enter a description"
                            rows={3}
                            onChange={handleChange}
                            value={values.description}
                            error={touched.description && errors.description}
                        />

                        <FieldDropdown
                            data-testid="buildingField"
                            required
                            id="building"
                            label="Building"
                            items={this.state.buildings}
                            selectedItem={values.building}
                            onChange={(building: ServiceAreaDropdownItem) => {
                                this.onBuildingSelect(building);
                                setFieldValue('building', building);
                                setFieldValue('floor', null);
                                setFieldValue('room', null);
                            }}
                            error={
                                touched.building && (errors.building as string)
                            }
                        />

                        <FieldDropdown
                            data-testid="floorField"
                            required
                            id="floor"
                            label="Floor"
                            items={this.state.floors}
                            selectedItem={values.floor}
                            onChange={(floor: ServiceAreaDropdownItem) => {
                                this.onFloorSelect(floor);
                                setFieldValue('floor', floor);
                                setFieldValue('room', null);
                            }}
                            error={touched.floor && (errors.floor as string)}
                        />

                        <FieldDropdown
                            data-testid="roomField"
                            id="room"
                            label="Area (Optional)"
                            items={this.state.rooms}
                            selectedItem={values.room}
                            onChange={(room) => {
                                setFieldValue('room', room);
                            }}
                        />

                        <FieldMultiSelect
                            data-testid="shiftsField"
                            id="shifts"
                            label="Shifts"
                            items={this.state.shifts}
                            selectedItems={values.shifts}
                            onChange={(selectedShifts) => {
                                setFieldValue('shifts', selectedShifts);
                            }}
                            disabled
                            selectAllFont="h4"
                            placeholder="Search"
                            labelFont="body-md"
                        />

                        <div>
                            <FieldInputFile
                                label="Add Photos (Optional)"
                                buttonLabel="Add Photos"
                                onChange={(e) => {
                                    e.preventDefault();
                                    const { files } = e.target;

                                    const getMedia = async () => {
                                        const attachments: Media[] = [];
                                        for (const file of Array.from(files)) {
                                            const media = await mediaService
                                                .upload(file, {
                                                    employeeId:
                                                        this.props.user
                                                            .employeeId,
                                                    organizationId:
                                                        this.props.user
                                                            .organization.id,
                                                })
                                                .toPromise()
                                                .catch(() => {
                                                    toasterService.newToast({
                                                        status: 'fail',
                                                        message: `Error uploading ${file.name}`,
                                                    });
                                                    return null;
                                                });

                                            if (media) {
                                                attachments.push(media);
                                            }
                                        }
                                        setFieldValue('attachments', [
                                            ...values.attachments,
                                            ...attachments,
                                        ]);
                                    };

                                    getMedia();
                                }}
                                accept=".png,.jpg,.jpeg,.gif"
                                multiple
                            />

                            {values.attachments.map((attachment) => (
                                <ImageContainer
                                    key={attachment.id}
                                    data={{
                                        image: imageService.getImageByUniqueId(
                                            attachment.file_name,
                                            false
                                        ),
                                        createdDate: new Date(
                                            attachment.create_date
                                        ),
                                    }}
                                    methods={{
                                        onRemove: () => {
                                            setFieldValue(
                                                'attachments',
                                                values.attachments.filter(
                                                    (atchmt) =>
                                                        atchmt.id !=
                                                        attachment.id
                                                )
                                            );
                                        },
                                    }}
                                    settings={{
                                        openInWindow: true,
                                    }}
                                />
                            ))}
                        </div>

                        <div className="tw-flex tw-justify-end tw-space-x-4">
                            <Button
                                label="Cancel"
                                onClick={modalService.closeAll}
                                color="secondary"
                            />
                            <SubmitButton
                                label="Submit"
                                disabled={isSubmitting}
                            />
                        </div>
                    </form>
                )}
            </Formik>
        );
    }
}

export const ReportItForm = (props: IProps) => {
    const { state } = useAppContext();
    return <ReportItFormComponent {...state} {...props} />;
};
