import { virtualBrownClient } from '@core/services/django-client';
import { Params } from '@helpers';
import { PaginationResponse } from '@models';

import { WorkItem, WorkItemPayload, WorkItemsParams } from './interfaces';

const BASE_URL = 'workitems';

class WorkItemsService {
	public getWorkItems(params: WorkItemsParams) {
		const qs = Params.makeQueryString(params);
		return virtualBrownClient.get<PaginationResponse<WorkItem>>(
			`${BASE_URL}/${qs}`
		);
	}

	public getWorkItemById(workItemId: number) {
		return virtualBrownClient.get<WorkItem>(`${BASE_URL}/${workItemId}/`);
	}

	public updateWorkItem(workItemId: number, params: WorkItemPayload) {
		return virtualBrownClient.put<WorkItem>(
			`${BASE_URL}/${workItemId}/`,
			params
		);
	}

	public patchWorkItem(workItemId: number, payload: WorkItemPayload) {
		return virtualBrownClient.patch<WorkItem>(
			`${BASE_URL}/${workItemId}/`,
			payload
		);
	}

	public sendWorkItem(
		workItemId: number,
		payload: { contract: number; sendSMS?: boolean; submit_date?: any }
	) {
		return virtualBrownClient.post<WorkItem>(
			`${BASE_URL}/${workItemId}/send/`,
			payload
		);
	}

	public sendToVendor(workItemId: number, contract: number) {
		return virtualBrownClient.post<string>(
			`${BASE_URL}/${workItemId}/send_to_vendor/`,
			{ contract }
		);
	}
}

export const workItemsService = new WorkItemsService();
