import { useEffect, useRef, useState } from 'react';

// helpers
import { forkJoin } from 'rxjs';

// services

// interfaces
import { Inspection, InspectionArea } from '@api-interfaces';
import { inspectionsService } from '@apis';

// components
import { Badge, LoaderBlocks } from '@atoms';
import { safeDivide, sendError } from '@helpers';
import { ServiceStopLogModal } from '@modals/_ds2';
import { ServiceLogTab } from '@modals/_ds2/audit/_components';
import {
    DetailsTab,
    TasksTab,
} from '@modals/_ds2/audit/_modals/photo-viewer/_tabs';
import { getScoreChipParams } from '@modals/_ds2/audit/helpers';
import { Modal } from '@templates';

interface AuditDetailsProps {
    auditId: number;
    areaId: number;
    area?: {
        name?: string;
        type?: string;
        floor?: string;
        building?: string;
    };
}

export const AuditDetails = ({ auditId, areaId, area }: AuditDetailsProps) => {
    const [audit, setAudit] = useState<Inspection>();
    const [inspectionAreas, setInspectionAreas] = useState<InspectionArea[]>(
        []
    );
    const [inspectionArea, setInspectionArea] = useState<InspectionArea>();
    const [isLoading, setIsLoading] = useState(true);
    const [tab, setTab] = useState(0);
    const [serviceStop, setServiceStop] = useState<{
        stopId: number;
        areaId: number;
        date: string;
    }>();
    const scoreRef = useRef(null);
    const typeRef = useRef(null);

    const areaName = area?.name ?? inspectionArea?.location?.name;
    const areaType = area?.type ?? inspectionArea?.location?.area_type?.name;
    const floorName = area?.floor ?? inspectionArea?.location?.parent?.name;
    const buildingName =
        area?.building ?? inspectionArea?.location?.parent?.parent?.name;

    useEffect(() => {
        setIsLoading(true);
        const subscription = forkJoin([
            inspectionsService.getInspectionById(auditId),
            inspectionsService.inspectionAreas.getInspectionAreas({
                inspectionId: auditId,
            }),
        ]).subscribe(([audit, inspections]) => {
            setAudit(audit);
            setInspectionAreas(inspections.results);
        }, sendError);

        return () => subscription.unsubscribe();
    }, [auditId]);

    useEffect(() => {
        if (inspectionAreas.length > 0) {
            setInspectionArea(
                inspectionAreas.find(
                    (inspection) => inspection.location.id === areaId
                )
            );
        }
    }, [inspectionAreas, areaId]);

    useEffect(() => {
        if (inspectionArea) {
            const {
                audit_score_calculated,
                audit_couldnt_score,
                audit_is_pass,
                audit_scale,
                goalline,
                inspection_score_calculated,
            } = inspectionArea;
            const { type: typeRes, score: scoreRes } = getScoreChipParams({
                mode: audit.mode,
                audit_score_calculated,
                audit_couldnt_score,
                audit_is_pass,
                audit_scale,
                goalline,
                inspection_score_calculated,
            });
            scoreRef.current = scoreRes;
            typeRef.current = typeRes;
            setIsLoading(false);
        }
    }, [inspectionArea]);

    return (
        <Modal.PhotoViewer.Details
            badge={
                <Badge
                    color="skyblue"
                    label={`${audit?.joint_inspected_by ? 'Joint ' : ''}Audit`}
                    className="tw-mb-6"
                />
            }
            scoreChip={
                !isLoading && {
                    title: 'Area Score',
                    mode: audit.mode,
                    score: scoreRef.current,
                    type: typeRef.current,
                }
            }
            title={areaName}
            subtitle={`${areaType ?? '–'} · ${buildingName ?? '–'} · Floor ${
                floorName ?? '–'
            }`}
        >
            {!isLoading ? (
                <>
                    <Modal.Information.Tabs
                        className="tw-mt-6 tw-flex-shrink-0"
                        tabs={[
                            { label: 'Details' },
                            { label: 'Tasks' },
                            { label: 'Service Log' },
                        ]}
                        onClick={(index) => setTab(index)}
                    />

                    <div className="tw-overflow-y-auto">
                        <div
                            className={`tw-mt-6 ${
                                tab === 0 ? '' : 'tw-hidden'
                            }`}
                        >
                            <DetailsTab currentArea={inspectionArea} />
                        </div>

                        <div
                            className={`tw-mt-6 ${
                                tab === 1 ? '' : 'tw-hidden'
                            }`}
                        >
                            <TasksTab
                                currentArea={inspectionArea}
                                mode={audit.mode}
                                inspection={audit}
                            />
                        </div>

                        <div
                            className={`tw-mt-6 ${
                                tab === 2 ? '' : 'tw-hidden'
                            }`}
                        >
                            <ServiceLogTab
                                currentArea={inspectionArea}
                                maxDate={new Date().toISOString()}
                                inspection={audit}
                                onViewClick={(stopId, areaId, date) =>
                                    setServiceStop({ stopId, areaId, date })
                                }
                            />
                        </div>
                    </div>
                    {serviceStop && (
                        <ServiceStopLogModal
                            {...serviceStop}
                            customer={audit.contract?.customer?.id}
                            contract={audit.contract?.id}
                            onClose={() => setServiceStop(undefined)}
                        />
                    )}
                </>
            ) : (
                <LoaderBlocks className="tw-pb-8" />
            )}
        </Modal.PhotoViewer.Details>
    );
};
