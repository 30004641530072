import { Icon } from '@atoms/icon/index';

interface Props {
  onClick: () => void;
  isUserFormOpen: boolean;
  isDropdownOpen: boolean;
  className?: string;
}
export const AddUserButton = ({
  onClick,
  isUserFormOpen,
  isDropdownOpen,
  className,
}: Props) => {
  if (isUserFormOpen) {
    return <></>;
  }
  return (
    <div
      onClick={onClick}
      className={`${
        isDropdownOpen ? '' : ''
      } tw-rounded-tl tw-rounded-tr ${className}`}
    >
      <div
        className="tw-flex tw-items-center tw-py-2.5 tw-px-3 tw-cursor-pointer"
        style={{ boxShadow: '0px 4px 24px rgba(24, 30, 36, 0.16)' }}
      >
        <div className="tw-flex tw-justify-center tw-items-center tw-h-6 tw-w-6 tw-mr-2.5 tw-rounded-full dark:tw-bg-opacity-15 tw-bg-opacity-15">
          <Icon
            className="dark:tw-text-primary-100 tw-text-primary-500 tw-h-6 tw-w-6"
            icon="plus-circle"
          />
        </div>
        <span className="tw-text-sm tw-text-theme-primary-500-300 tw-text-roboto tw-leading-[22px]">
          New Recipient
        </span>
      </div>
    </div>
  );
};
