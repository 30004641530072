import { useContext } from 'react';

import { createCtx } from '@helpers';

import { BillingPage } from './interfaces';
import { billingPageReducer } from './reducer';

export const billingPageState: BillingPage.State = {
	selectedInvoiceProgram: null,
};

export const [billingPageContext, BillingPageProvider] = createCtx(
	billingPageReducer,
	billingPageState
);

export function useBillingPageContext() {
	return useContext(billingPageContext);
}

billingPageContext.displayName = 'BillingPageContext';
