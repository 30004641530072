import { debounce } from 'lodash';
import { useEffect, useState, useRef } from 'react';
import * as React from 'react';

// services
import { Subscription } from 'rxjs';

import { ReferenceItem, WorkOrderStatusKey } from '@api-interfaces';
import { referenceService, workOrdersService } from '@apis';
import { createWorkOrderPayload } from '@app/modals/_ds2/work-order-form/_helpers';
import { ModalStack } from '@atoms';
import { DropdownItem } from '@atoms/form-ds2/dropdown/dropdown.interfaces';
import { FieldDropdown } from '@atoms/form-ds2/field-dropdown';
import { FieldInputText } from '@atoms/form-ds2/field-input-text';
import { toasterService } from '@core/services';

// helpers
import { sendError, titleCase } from '@helpers';
import { Button } from '@new';
import { Modal } from '@templates';

import { useWorkOrderModalContext } from '../../_context';

// components

// interfaces

export const RejectWorkOrderForm = () => {
  const {
    workOrder,
    closeModal,
    setActiveModals,
    activeModals,
    setWorkOrderEdit,
    workOrderEdit,
    hasBeenUpdated,
  } = useWorkOrderModalContext();
  const [reasons, setReasons] = useState<DropdownItem<ReferenceItem>[]>([]);
  const [selectedReason, setSelectedReason] =
    useState<DropdownItem<ReferenceItem> | null>(null);
  const [error, setError] = useState(false);
  const subscription = useRef(new Subscription());

  useEffect(() => {
    return () => {
      subscription.current.unsubscribe();
    };
  }, []);

  useEffect(() => {
    const subscription = referenceService
      .getItems({ group_code: 'reportit_rejection_types' })
      .subscribe({
        next: (items: ReferenceItem[]) => {
          const reasons = items.map((item) => ({
            ...item,
            name: titleCase(item.display_name),
          }));

          const reason = items.find(
            (item) => item.code === workOrder.rejection_type?.code
          );
          if (reason) {
            setSelectedReason({
              ...reason,
              name: titleCase(reason.display_name),
            });
          }

          setReasons(reasons);
        },
        error: sendError({
          toastMessage: 'There was an error retrieving the Rejection reasons',
        }),
      });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const handleSubmit = () => {
    if (
      !selectedReason ||
      (selectedReason?.code === 'Other' &&
        !workOrderEdit.rejection_other?.trim()?.length)
    ) {
      setError(true);
      return;
    }

    const workOrderPayload = createWorkOrderPayload(workOrderEdit);
    workOrderPayload.work_order_status = WorkOrderStatusKey.REJECTED;
    workOrderPayload.rejection_type_id = selectedReason.id;

    subscription.current = workOrdersService
      .updateWorkOrderById(workOrder.id, workOrderPayload)
      .subscribe({
        next: () => {
          toasterService.newToast({
            status: 'success',
            message: 'Work Order Successfully Rejected',
          });

          hasBeenUpdated.current = true;
          closeModal();
        },
        error: sendError(),
      });
  };

  const handleRejectionOther = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setWorkOrderEdit({
      ...workOrderEdit,
      rejection_other: e.target.value,
    });
    if (error && e.target.value) {
      setError(false);
    }
  };

  const debouncedHandleRejectionChange = debounce(handleRejectionOther, 500);

  function handleCancel() {
    setActiveModals({
      ...activeModals,
      'reject-work-order': false,
    });
  }

  return (
    activeModals['reject-work-order'] && (
      <ModalStack hasSpacingY>
        <Modal.Generic
          title="Reject Work Order"
          onCancel={handleCancel}
          className="tw-mx-auto tw-w-full tw-space-y-6 tw-max-w-[680px] sm:tw-space-y-8"
        >
          <div className="tw-space-y-6 sm:tw-space-y-8 tw-mt-6">
            <div className="tw-w-full tw-space-y-6 sm:tw-space-y-0 sm:tw-gap-4 sm:tw-grid sm:tw-grid-cols-1">
              <FieldDropdown
                id="work_order_rejection_reason"
                label="Reason"
                items={reasons}
                selectedItem={selectedReason}
                onChange={(reason) => {
                  setSelectedReason(reason);
                }}
                disabled={!reasons.length}
              />
            </div>
            {selectedReason?.code === 'Other' && (
              <div className="tw-w-full tw-space-y-6 sm:tw-space-y-0 sm:tw-gap-4">
                <FieldInputText
                  required
                  fieldType="textarea"
                  id="work_order_other_description"
                  label="Other - Description"
                  defaultValue={workOrderEdit?.rejection_other ?? ''}
                  onChange={debouncedHandleRejectionChange}
                  max={500}
                  error={error && 'This field is required'}
                />
              </div>
            )}
            <div className="tw-flex tw-justify-end tw-space-x-4">
              <Button label="Cancel" color="secondary" onClick={handleCancel} />
              <Button label="Save" color="primary" onClick={handleSubmit} />
            </div>
          </div>
        </Modal.Generic>
      </ModalStack>
    )
  );
};
