import { merge, cloneDeep } from 'lodash';
import { BehaviorSubject } from 'rxjs';

import {
	ModalOptions,
	ModalCallbackParams,
	ModalState,
} from './modal-service.interfaces';

class ModalService {
	private state: ModalState = {};

	private _modals: ModalOptions[] = [];

	readonly modals: BehaviorSubject<ModalOptions[]> = new BehaviorSubject(
		this._modals
	);

	constructor() {
		this.modals.subscribe((modals) => {
			if (!modals.length) {
				this.clearState();
			}
		});
	}

	private notify = () => {
		this.modals.next(this._modals);
	};

	public getState = (key?: string) => {
		if (key) return this.state[key];
		return this.state;
	};

	public updateState = <T>(nextState: { [key: string]: T }) => {
		const state: ModalState = { ...cloneDeep(this.state), ...nextState };
		this.state = state;
	};

	public clearState = (key?: string) => {
		if (key) delete this.state[key];
		else {
			for (const key in this.state) {
				delete this.state[key];
			}
		}
	};

	public getModalContainer = (options: ModalOptions) => {
		const containerId = (options && options.containerId) || 'modal-container';
		return document.getElementById(containerId);
	};

	private setBodyOverflow = (isOpen?: boolean) => {
		const body = document.querySelector('body') as HTMLBodyElement;
		if (!body) return;

		if (isOpen) {
			body.style.overflowY = 'hidden';
		} else {
			body.style.overflowY = 'auto';
		}
	};

	public open = (modalOptions: ModalOptions) => {
		const options: ModalOptions = merge(
			{}, // create new object instead of mutating by reference
			modalOptions,
			{
				// override options with these defaults
				size: modalOptions.size ? modalOptions.size : 'lg',
				width: modalOptions.width ? modalOptions.width : null,
				backdrop: modalOptions.backdrop ?? true, // value is true unless explicitly passed false, false === blurred bg
				containerId: modalOptions.containerId
					? modalOptions.containerId
					: 'main-modal',
			}
		);
		this.setBodyOverflow(true);
		this._modals.push(options);
		this.notify();
	};

	public close = (args?: ModalCallbackParams | Event) => {
		if (args instanceof Event) {
			args.preventDefault();
		}
		const lastModal = this._modals.pop();
		if (lastModal && lastModal.callback) lastModal.callback(args);
		// set the props of the next component
		// useful for preserving state for modals
		if (lastModal && lastModal.passPropsOnClose && this._modals.length) {
			const lastItemIndex = this._modals.length - 1;
			const nextComponent = this._modals[lastItemIndex].component;

			nextComponent.props = {
				...nextComponent.props,
				...(typeof lastModal.passPropsOnClose !== 'function'
					? lastModal.passPropsOnClose
					: lastModal.passPropsOnClose()),
			};

			this._modals[lastItemIndex].component = nextComponent;
		}
		if (this._modals.length === 0) {
			this.setBodyOverflow();
		}
		this.notify();
	};

	public closeAll = (args?: ModalCallbackParams | Event) => {
		if (args instanceof Event) {
			args.preventDefault();
		}
		const modalLength = this._modals.length;
		for (let i = 0; i < modalLength; i++) {
			const lastModal = this._modals.pop();
			if (lastModal && lastModal.callback) {
				lastModal.callback(args);
			}
			this.notify();
		}
		this.setBodyOverflow();
	};
}

export const modalService = new ModalService();
