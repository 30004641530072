import { Icon, Text } from '@atoms';
import { prettyNumber } from '@helpers';

// components

// interfaces
import { ScoreChipProps } from './score-chip.interfaces';
import {
    scoreChipTypeMap,
    scoreChipBorderRadiusMap,
    scoreChipSizeMap,
    scoreChipLabelSizeMap,
    scoreChipScoreSizeMap,
    scoreChipPaddingMap,
} from './score-chip.utils';

export const ScoreChip = ({
    label,
    score,
    size,
    type,
    denominator,
    prettyNumberOptions,
    scoreTextColor,
    scoreIcon,
    scoreColor,
    className,
    customTextColor,
    ...props
}: ScoreChipProps) => {
    const textColor = customTextColor
        ? customTextColor
        : type === 'neutral'
          ? 'neutral-offset'
          : 'hi-contrast';
    const isSizeXsOrSm = size === 'sm' || size === 'xs';
    return (
        <div
            data-ds2={`score-chip-${size}`}
            className={`tw-flex tw-items-center tw-text-center tw-flex-shrink-0
      sm:tw-flex-col sm:tw-justify-center
      ${scoreChipTypeMap[type]}
      ${scoreChipBorderRadiusMap[size]}
      ${scoreChipSizeMap[size]}
      ${scoreChipPaddingMap[size]}
      ${!label || isSizeXsOrSm ? 'tw-justify-center' : 'tw-justify-between'}
      ${props.onClick ? 'tw-cursor-pointer' : ''}
      ${className}
      `}
            {...props}
        >
            {!isSizeXsOrSm && label && (
                <Text
                    font={scoreChipLabelSizeMap[size]}
                    color={scoreTextColor || textColor}
                    className="sm:tw-mb-2"
                >
                    {label}
                </Text>
            )}
            <div className="tw-flex tw-items-end">
                <Text
                    font={scoreChipScoreSizeMap[size]}
                    color={scoreTextColor || textColor}
                    style={size === 'lg' ? { lineHeight: 1 } : undefined}
                >
                    {score == null || Number.isNaN(score)
                        ? 'N/A'
                        : typeof score === 'string'
                          ? score
                          : prettyNumber(score, prettyNumberOptions)}
                    {scoreIcon && scoreColor && (
                        <Icon
                            icon={scoreIcon}
                            color={scoreColor}
                            className="tw-ml-1"
                        />
                    )}
                </Text>
                {score != null &&
                    denominator != undefined &&
                    denominator > 0 && (
                        <Text
                            font="h3"
                            color={scoreTextColor || textColor}
                            className={`${
                                isSizeXsOrSm ? 'tw-ml-1' : 'tw-ml-2'
                            }`}
                        >
                            / {denominator}
                        </Text>
                    )}
            </div>
        </div>
    );
};

ScoreChip.defaultProps = {
    size: 'md',
    className: '',
    prettyNumberOptions: {
        decimals: 2,
        hasTrailingZeros: false,
        isPercent: false,
        forceTrailingZeros: false,
    },
} as ScoreChipProps;
